const ElementType = {
    CHECKBOX: "cb",
    DATE: "date",
    DATE_RANGE: "date_range",
    DATETIME: "dt",
    EMAIL: "email",
    FILE: "file",
    IMAGE: "img",
    NUMBER: "nmb",
    PASSWORD: "pwd",
    RADIO: "rdo",
    TEL: "tel",
    TEXT: "txt",
    URL: "url",
    SELECT : "slct",
    ADDRESS: "addr",
    TEXTAREA: "textarea",
    AUTO: "auto",
};

export default ElementType;