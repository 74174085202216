const FareCalculationAction = {
    GET_CROSS_BORDER_COURIER_SERVICE : "GET_CROSS_BORDER_COURIER_SERVICE",
    GET_CROSS_BORDER_COURIER_SERVICE_SUCCESSFUL : "GET_CROSS_BORDER_COURIER_SERVICE_SUCCESSFUL",
    GET_CROSS_BORDER_COURIER_SERVICE_FAILED : "GET_CROSS_BORDER_COURIER_SERVICE_FAILED",
    REMOVE_CROSS_BORDER_COURIER_SERVICE : "REMOVE_CROSS_BORDER_COURIER_SERVICE",

    GET_CALCULATION_SERVICE : "GET_CALCULATION_SERVICE",
    GET_CALCULATION_SERVICE_SUCCESSFUL : "GET_CALCULATION_SERVICE_SUCCESSFUL",
    GET_CALCULATION_SERVICE_FAILED : "GET_CALCULATION_SERVICE_FAILED",

    GET_FARE_CALCULATION_GLOBAL_VARIABLE : "GET_FARE_CALCULATION_GLOBAL_VARIABLE",
    GET_FARE_CALCULATION_GLOBAL_VARIABLE_SUCCESSFUL : "GET_FARE_CALCULATION_GLOBAL_VARIABLE_SUCCESSFUL",
    GET_FARE_CALCULATION_GLOBAL_VARIABLE_FAILED : "GET_FARE_CALCULATION_GLOBAL_VARIABLE_FAILED",

    GET_FARE_CALCULATION_USER_INPUT : "GET_FARE_CALCULATION_USER_INPUT",
    GET_FARE_CALCULATION_USER_INPUT_SUCCESSFUL : "GET_FARE_CALCULATION_USER_INPUT_SUCCESSFUL",
    GET_FARE_CALCULATION_USER_INPUT_FAILED : "GET_FARE_CALCULATION_USER_INPUT_FAILED",

    CREATE_FARE_CALCULATION : "CREATE_FARE_CALCULATION",
    CREATE_FARE_CALCULATION_SUCCESSFUL : "CREATE_FARE_CALCULATION_SUCCESSFUL",
    CREATE_FARE_CALCULATION_FAILED : "CREATE_FARE_CALCULATION_FAILED",

    UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE : "UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE",
    UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_SUCCESSFUL : "UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_SUCCESSFUL",
    UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED : "UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED",

    CREATE_FARE_DETAIL_MANAGEMENT_SERVICE : "CREATE_FARE_DETAIL_MANAGEMENT_SERVICE",
    CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_SUCCESSFUL : "CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_SUCCESSFUL",
    CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED : "CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED",

    GET_FARE_CALC_MANAGEMENT_CALCULATE: "GET_FARE_CALC_MANAGEMENT_CALCULATE",
    GET_FARE_CALC_MANAGEMENT_CALCULATE_SUCCESSFUL: "GET_FARE_CALC_MANAGEMENT_CALCULATE_SUCCESSFUL",
    GET_FARE_CALC_MANAGEMENT_CALCULATE_FAILED: "GET_FARE_CALC_MANAGEMENT_CALCULATE_FAILED",
    REMOVE_FARE_CALC_MANAGEMENT_CALCULATE: "REMOVE_FARE_CALC_MANAGEMENT_CALCULATE",
    SAVE_VALUE_ESTIMATED: "SAVE_VALUE_ESTIMATED",

    GET_FARE_CALC_USER_INPUT: "GET_FARE_CALC_USER_INPUT",
    GET_FARE_CALC_USER_INPUT_SUCCESSFUL: "GET_FARE_CALC_USER_INPUT_SUCCESSFUL",
    GET_FARE_CALC_USER_INPUT_FAILED: "GET_FARE_CALC_USER_INPUT_FAILED",

    GET_DETAIL_FARE_CAL_SERVICE_CONTENT: "GET_DETAIL_FARE_CAL_SERVICE_CONTENT",
    GET_DETAIL_FARE_CAL_SERVICE_CONTENT_SUCCESSFUL: "GET_DETAIL_FARE_CAL_SERVICE_CONTENT_SUCCESSFUL",
    GET_DETAIL_FARE_CAL_SERVICE_CONTENT_FAILED: "GET_DETAIL_FARE_CAL_SERVICE_CONTENT_FAILED",

    DELETE_FARE_CALC_SERVICE: "DELETE_FARE_CALC_SERVICE",
    DELETE_FARE_CALC_SERVICE_SUCCESSFUL: "DELETE_FARE_CALC_SERVICE_SUCCESSFUL",
    DELETE_FARE_CALC_SERVICE_FAILED: "DELETE_FARE_CALC_SERVICE_FAILED",
    
}
export default FareCalculationAction;