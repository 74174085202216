import React, { useEffect, useRef } from 'react'
import Constants from 'utils/Constants';
import Validator from 'utils/Validator';
import FormInput from './FormInput';
import FormFile from './FormFile';
import FormImage from './FormImage';
import FormDatePicker from './FormDatePicker';
import FormSelectTemplate from './FormSelectTemplate';
import { convertRegexToOptions } from 'utils/Utils';
import FormSelect from './FormSelect';
import FormRadio from './FormRadio';
import FormTextArea from './FormTextArea';
import { useFormContext } from 'react-hook-form';
import FormEditor from './FormEditor';
import FormSelectInstance from './FormSelectInstance';
import FormMonthYearPicker from './FormMonthYearPicker';
import FormDateTimePicker from './FormDateTimePicker';
import FormListCheckbox from './FormListCheckbox';
import { getFunction } from '../common/Util';
import { useDispatch } from 'react-redux';

function FormAttribute({ value, index, isEdit = true, isCreate = false, dataAttribute }) {
    const refPass = useRef(false);
    const methods = useFormContext();
    const dispatch = useDispatch();

    const getFieldNameAttributes = (field) => {
        let indexJsonKey = dataAttribute?.findIndex(x => x?.key == field?.replace("$this.", '')?.replace(/"/g, ''));
        return `"$this:attributes.${indexJsonKey}.${field?.replace("$this.", '')?.replace(/"/g, '')}"`
    }

    const convertDefaultValue = ({ value }) => {
        let jsonParameter = value?.defaultValue;
        let JsonDefaultValue = value?.defaultValue;
        const pattern = new RegExp(/"(\$)([^"\s]*)"/gm);
        let listJson = jsonParameter.match(pattern);

        listJson = listJson?.map(x => {
            return {
                key: x,
                value: getFieldNameAttributes(x)
            }
        });
        listJson?.map(x => {
            JsonDefaultValue = JsonDefaultValue?.replace(x?.key, x?.value);
        });
        return JsonDefaultValue;
    }
    const renderInputAttributes = (value, index, methods) => {
        switch (value?.elementType) {
            case Constants.ELEMENT_TYPE.TEXT.VALUE:
                let validate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validate.push(Validator.required)
                }

                useEffect(() => {
                    if (value?.defaultValue && !value[value?.key]) {
                        let indexJsonKey = dataAttribute?.findIndex(x => x?.key == value?.key);
                        try {
                            if (value?.defaultValue?.indexOf("{") != -1) {
                                let json = JSON.parse(value?.defaultValue);
                                methods.setValue(`attributes.${indexJsonKey}.${value.key}`, json["default"]);
                                if (json["default"]) {
                                    getFunction(json["trigger"], convertDefaultValue({value: value}), dispatch, methods?.setValue, methods?.getValues, ":");
                                }
                            }
                        } catch (error) {
                        }
                    }
                }, [value?.defaultValue]);

                const onChangeValue = () => {
                    getFunction("onChange", convertDefaultValue({ value: value }), dispatch, methods.setValue, methods.getValues, ":");
                }

                const onBlurInput = () => {
                    getFunction("onBlur", convertDefaultValue({ value: value }), dispatch, methods.setValue, methods.getValues, ":");
                }

                return <FormInput onChangeValue={onChangeValue} validationFE onCustomBlur={onBlurInput} isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.ADDRESS.VALUE:
                let validateAddress = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAddress.push(Validator.required)
                }
                const dynamicOnChange = async (_targetVl) => {
                    try {
                        const json = JSON.parse(value?.defaultValue);
                        let functionName = json.onChange;
                        let countryId = json.countryId;
                        let indexJsonKey = dataAttribute?.findIndex(x => x?.key == json?.key);
                        if (window[functionName] && typeof window[functionName] === 'function') {
                            const response = await window[functionName](_targetVl, countryId);
                            if (response?.addressMain && response?.addressMain != null) {

                                methods.setValue(`attributes.${indexJsonKey}.${json.key}`, response?.addressMain);
                                methods.setError(`attributes.${indexJsonKey}.${json.key}`, {
                                    message: undefined,
                                })
                                methods.clearErrors(`attributes.${indexJsonKey}.${json.key}`)
                            } else {
                                methods.setValue(`attributes.${indexJsonKey}.${json.key}`, "");
                            }
                        }
                    } catch (error) {

                    }
                }
                return <FormInput callback={dynamicOnChange} validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateAddress]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.EMAIL.VALUE:
                let validateEmail = [];
                validateEmail.push(Validator.email)
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateEmail.push(Validator.required)
                }
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateEmail]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.FILE.VALUE:
                let validateFile = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateFile.push(Validator.required)
                }
                return <FormFile validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateFile]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.IMAGE.VALUE:
                let validateImage = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateImage.push(Validator.requiredImage)
                }
                return <FormImage validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateImage]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.NUMBER.VALUE:
                let validateNumber = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateNumber.push(Validator.required)
                }
                // 
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} format={Constants.FormInputFormat.NUMBER.VALUE} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateNumber]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.URL.VALUE:
                let validateUrl = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUrl.push(Validator.required)
                }
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateUrl]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATE.VALUE:
                let validateDate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDate.push(Validator.required)
                }
                return <FormDatePicker validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATETIME.VALUE:
                let validateDateTime = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDateTime.push(Validator.required)
                }
                return <FormDateTimePicker validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDateTime]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.AUTOGEN.VALUE:
                let validateAutogen = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAutogen.push(Validator.required)
                }
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled validate={[Validator.customValidator(value?.regex, value?.msg), ...validateAutogen]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.UNIQUE.VALUE:
                let validateUnique = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUnique.push(Validator.required)
                }
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateUnique]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE:
                let validateSelectTemplate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateSelectTemplate.push(Validator.requiredSelectTemplate)
                }
                return <FormSelectTemplate validationFE isRegexLabel={value?.label} placeholder={value?.explain} isBorder={false} isShowButtonEdit disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} isMulti={value?.isMultiSelected == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateSelectTemplate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT.VALUE:
                let optionSelect = convertRegexToOptions(value?.regex);
                let validateSelect = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateSelect.push(Validator.required)
                }
                useEffect(() => {
                    if (value?.defaultValue) {
                        let indexJsonKeySelect = dataAttribute?.findIndex(x => x?.key == value?.key);
                        if (value?.defaultValue?.indexOf("{") != -1) {
                            try {
                                let json = JSON.parse(value?.defaultValue);                                
                                if (!value[value?.key]) {
                                    methods.setValue(`attributes.${indexJsonKeySelect}.${value.key}`, json["default"]);
                                }
    
                                if (json["default"] || value[value?.key]) {
                                    getFunction(json["trigger"], convertDefaultValue({ value: value }), dispatch, methods?.setValue, methods?.getValues, ":");
                                }
                            } catch (error) {
                            }
                        }
                    }
                }, [value?.defaultValue]);

                const onChangeSelect = () => {
                    getFunction("onChange", convertDefaultValue({ value: value }), dispatch, methods.setValue, methods.getValues, ":");
                }

                const onBlurSelect = () => {
                    getFunction("onBlur", convertDefaultValue({ value: value }), dispatch, methods.setValue, methods.getValues, ":");
                }

                if (value?.isMultiSelected == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    return <FormSelect validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} isMultiSelected options={optionSelect} validate={[...validateSelect]} fieldName={`attributes.${index}.${value?.key}`} attributeKey={value?.key} />
                } else {
                    return <FormSelect onBlurSelect={onBlurSelect} onChangeSelect={onChangeSelect} validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} options={optionSelect} validate={[...validateSelect]} fieldName={`attributes.${index}.${value?.key}`} attributeKey={value?.key} />
                }
            case Constants.ELEMENT_TYPE.RADIO.VALUE:
                let validateRadio = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateRadio.push(Validator.required)
                }
                let optionRadio = convertRegexToOptions(value?.regex);
                return <FormRadio validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} options={optionRadio} validate={[...validateRadio]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.TEL.VALUE:
                let validateTel = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateTel.push(Validator.required)
                }
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateTel]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.PASSWORD.VALUE:

                if (isCreate) {
                    let validatePass = [];
                    if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                        validatePass.push(Validator.required)
                    }
                    return <div style={{
                        width: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} type="password" validate={[Validator.customValidator(value?.regex, value?.msg), ...validatePass]} fieldName={`attributes.${index}.${value?.key}`} />
                        <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} type="password" validate={[Validator.confirmPassword(methods.watch(`attributes.${index}.${value?.key}`))]} fieldName={`attributes.${index}.confirmPassword`} />
                    </div>
                } else {
                    let validatePass = [];
                    let validatePassConfirm = [
                        Validator.confirmPassword(methods.watch(`attributes.${index}.${value?.key}`))
                    ];

                    if (!refPass.current) {
                        validatePass = [];
                        validatePassConfirm = [];
                    } else {
                        validatePass.push(Validator.customValidator(value?.regex, value?.msg))
                        validatePassConfirm.push(Validator.confirmPassword(methods.watch(`attributes.${index}.${value?.key}`)))
                    }

                    if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                        validatePass.push(Validator.required)
                    }

                    const onFocus = () => {
                        refPass.current = true;
                    }
                    return <div style={{
                        width: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} onFocus={onFocus} showIconPassword={refPass.current} valueCurrent={value[value?.key]} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} type="password" validate={[...validatePass]} fieldName={`attributes.${index}.${value?.key}`} />
                        <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} showIconPassword={refPass.current} disabled={
                            (isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false) || refPass.current ? false : true} type="password" validate={validatePassConfirm} fieldName={`attributes.${index}.confirmPassword`} />
                    </div>
                }
            case Constants.ELEMENT_TYPE.CHECKBOX.VALUE:
                let validateCheckbox = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateCheckbox.push(Validator.requiredCheckbox)
                }

                let optionCheckBox = convertRegexToOptions(value?.regex)
                return <FormListCheckbox validationFE isRegexLabel={value?.label} options={optionCheckBox} label={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[...validateCheckbox]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.TEXT_AREA.VALUE:
                let validateTxtArea = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateTxtArea.push(Validator.required)
                }
                return <FormTextArea validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[...validateTxtArea]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.RICH_TEXT.VALUE:
                let validateRichText = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateRichText.push(Validator.required)
                }
                return <FormEditor validationFE isRegexLabel={value?.label} placeholder={value?.explain} height='100px' readOnly={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[...validateRichText]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE:
                let validateSelectInstance = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateSelectInstance.push(Validator.requiredSelectTemplate)
                }
                return <FormSelectInstance
                    validationFE
                    isRegexLabel={value?.label}
                    isCreate={isCreate}
                    isMulti={value?.isMultiSelected == "1" ? true : false}
                    // disabled={(x?.holonTemplate?.length == 0 || x?.isEditabled == "0") ? true : false} 
                    // holonTemplate={x?.holonTemplate}
                    disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false}
                    validate={validateSelectInstance}
                    placeholder={value?.explain}
                    fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.MONEY.VALUE:
                let validateInputMoney = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateInputMoney.push(Validator.required)
                }
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} format={Constants.FormInputFormat.NUMBER.VALUE} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[...validateInputMoney]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.PERCENT.VALUE:
                let validateInputPercent = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateInputPercent.push(Validator.required)
                }
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} max={100} maxLength={3} format={Constants.FormInputFormat.PERCENT.VALUE} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[...validateInputPercent]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.YEAR_MONTH_PICKER.VALUE:
                let validateInputYearMonthPicker = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateInputYearMonthPicker.push(Validator.required)
                }
                return <FormMonthYearPicker validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[...validateInputYearMonthPicker]} fieldName={`attributes.${index}.${value?.key}`} />
            default:
                let validateDefault = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDefault.push(Validator.required)
                }
                return <FormInput validationFE isRegexLabel={value?.label} placeholder={value?.explain} disabled={isEdit ? (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDefault]} fieldName={`attributes.${index}.${value?.key}`} />
        }
    }

    return (
        <>{renderInputAttributes(value, index, methods)}</>
    )
}

export default FormAttribute