import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import CustomCheckBox from '../form-input/checkbox/Checkbox';

const FormCheckbox = (props) => {
    const {
        fieldName,
        validate = [],
        placeholder = '',
        disabled = false,
        size = '',
        className,
        styleFormCheckbox,
        label,
        isRegexLabel,
        validationFE= false,
        onChangeValue
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        //Input w-100
        <div className={['Input w-100', styleFormCheckbox]}>
            <Controller
                control={control}
                name={fieldName}
                rules={{ validate: Validator.genValidate(validate, fieldName) }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <CustomCheckBox
                            inputRef={ref}
                            onChange={(e) => {
                                onChange(e);
                                onChangeValue(e)
                            }}
                            value={Boolean(value)}
                            errors={errors}
                            disabled={disabled}
                            placeholder={placeholder}
                            name={fieldName}
                            onBlur={onBlur}
                            size={size}
                            className={className}
                            label={label}
                            isRegexLabel={isRegexLabel}
                            validationFE={validationFE}
                        />
                    );
                }}
            />
        </div>
    );
};

FormCheckbox.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    textAlign: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
};
export default FormCheckbox;
