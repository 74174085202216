import React from "react";
import classes from "./CrossBorderCourierService.module.scss"
import useTrans from "hooks/use-trans";
import FormInput from 'shared/components/custom-form/FormInput';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import IconPlusWhite from 'assets/icon/IconPlusWhite';

const TrialCalculationItem = (props) => {

    const { appendFareCalcFormulas, removeFareCalcFormulas, fieldsFareCalcFormulas } = props
    const { t } = useTrans();
    return (
        <div className={classes["constant-table"]}>
            <div className={classes["header-constant-table"]}>
                <p className={classes['title-service-name']}>
                    {t('fare_calculation.trial_calculation_item')}
                </p>
                <div className={classes['icon-plus']} style={{ cursor: "pointer" }} onClick={() => {

                    appendFareCalcFormulas({
                        name: "",
                        key: "",
                        resultRange: "",
                        defaultValue: "",
                        formula: "",
                        id: "",
                        idCreated: fieldsFareCalcFormulas?.length + 1
                    })
                }}>
                    <IconPlusWhite />
                </div>
            </div>
            {
                fieldsFareCalcFormulas.length !== 0 &&
                <table>
                    <thead>
                        <tr className={classes['form-header']}>
                            <th className={''}>{t("fare_calculation.name")}</th>
                            <th className={''}>{t("fare_calculation.key")}</th>
                            <th className={''}>{t("fare_calculation.trial_calculation_result_range")}</th>
                            <th className={''}>{t("fare_calculation.default")}</th>
                            <th className={classes['formula']}>{t("fare_calculation.formula")} </th>
                            <th className={''}>{t("Holon_Template.delete")} </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fieldsFareCalcFormulas.map((x, key) => {
                                return <tr key={x?.id} className={classes['form-body']} index={key}>
                                    <td>
                                        <FormInput validationFE isRegexLabel={t("fare_calculation.name")} fieldName={`fareCalcFormulas.${key}.name`} />
                                    </td>
                                    <td>
                                        <FormInput validationFE isRegexLabel={t("fare_calculation.key")} fieldName={`fareCalcFormulas.${key}.key`} />
                                    </td>
                                    <td>
                                        <FormInput validationFE isRegexLabel={t("fare_calculation.trial_calculation_result_range")} fieldName={`fareCalcFormulas.${key}.resultRange`} />
                                    </td>
                                    <td>
                                        <FormInput validationFE isRegexLabel={t("fare_calculation.default")} fieldName={`fareCalcFormulas.${key}.defaultValue`} />
                                    </td>
                                    <td>
                                        <FormInput validationFE className={classes['formula']} isRegexLabel={t("fare_calculation.formula")} fieldName={`fareCalcFormulas.${key}.formula`} />
                                    </td>
                                    <td style={{ minWidth: ' 50px', cursor: 'pointer' }} className={classes['button-action-remove']} onClick={() => removeFareCalcFormulas(key)}>
                                        <IconMinusAccordion />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            }
        </div>
    )
};

export default TrialCalculationItem;
