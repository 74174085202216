import React, { useEffect, useState } from 'react'
import classes from './WarehouseContainer.module.scss';
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import { useDispatch, useSelector } from 'react-redux'
import Constants from 'utils/Constants';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination';
import { useForm } from 'react-hook-form';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE, EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE } from 'utils/EventRegister';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import actionWareHouse from 'redux/warehouse/action';
import moment from 'moment';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';
import { convertRegexToOptions } from 'utils/Utils';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import CommonText from 'shared/components/common/text/CommonText';
import { CommonDataFilter, CommonRowTableHolonInstance } from 'shared/components/holon-instance/CommonDataFilter';

function WarehouseContainer() {
    const dispatch = useDispatch();
    const [header, setHeader] = useState([]);
    const router = useRouter();
    const { listWarehouse, loadingListWarehouse = false, holonTemplateDetail } = useSelector(state => state.warehouse);
    const { t } = useTrans();
    const methods = useForm();

    useEffect(() => {
        if (holonTemplateDetail?.id) {
            let dataHeadCell = [
            ];
            holonTemplateDetail?.validAttributes?.map(x => {
                dataHeadCell?.push({
                    label: <CommonText>{x?.label}</CommonText>,
                    field: x?.key,
                    minWidth: '150px',
                    width: '150px',
                    maxWidth: '150px',
                    styleBody: {
                        padding: '5px 10px'
                    },
                    styleHeader: {
                        padding: '10px 10px'
                    },
                    align: 'left',
                    alignHeader: 'left',
                    component: (_props) => {
                        return <CommonText>
                            {
                                CommonRowTableHolonInstance({
                                    attributeHeader: x,
                                    attributes: _props?.data?.attributes
                                })
                            }
                        </CommonText>
                    }
                })
            });
            
            dataHeadCell.unshift({
                label: "",
                field: "contributors",
                minWidth: '100px',
                width: '100px',
                maxWidth: '100px',
                styleBody: {
                    padding: '5px 10px'
                },
                styleHeader: {
                    padding: '10px 10px'
                },
                align: 'left',
                alignHeader: 'left',
                component: (_props) => {
                    return <ButtonLink onClick={() => {
                        EventRegister.emit(EVENT_SHOW_POPUP, {
                            open: true,
                            type: EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE,
                            payload: {
                                showHeader: false,
                                customStyle: {
                                    padding: 0,
                                    borderTop: 0,
                                },
                                dataBox: {
                                    boxEdit: {
                                        titleBoxUpdate: t("warehouse.update_warehouse")
                                    },
                                    boxDetail: {
                                        titleBoxDetail: t("warehouse.warehouse_detail"),
                                    },
                                    callback: {
                                        callbackSuccess: () => {
                                            dispatch({
                                                type: actionWareHouse.GET_WAREHOUSE_LIST,
                                                payload: {
                                                    ...router.getAll(),
                                                }
                                            })
                                        },
                                    },
                                },
                                holonInstanceId: _props?.data?.id,
                                holonTemplateId: holonTemplateDetail?.id,
                            }
                        })
                    }} title={t('warehouse.detail')} />
                }
            })

            dataHeadCell.unshift({
                label: "No",
                field: "stt",
                minWidth: '50px',
                width: '50px',
                maxWidth: '50px',
                alignHeader: 'left',
                styleBody: {
                    padding: '5px 20px'
                },
                styleHeader: {
                    padding: '10px 20px'
                },
                align: 'left',
                component: (_props) => {
                    return <>{_props?.stt + 1}</>
                }
            })
            setHeader(dataHeadCell);
        }
    }, [holonTemplateDetail])

    const handleSubmit = (data) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: router.get([Constants.QueryParams.LIMIT.VALUE]),
                [Constants.QueryParams.SEARCH.VALUE]: data?.search,
                [Constants.QueryParams.BRAND.VALUE]: data?.brand,
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: actionWareHouse.GET_WAREHOUSE_LIST,
            payload: {
                ...router.getAll()
            }
        })
    }, [
        router.get(Constants.QueryParams.SEARCH.VALUE),
        router.get(Constants.QueryParams.BRAND.VALUE),
        router.get(Constants.QueryParams.ATTRIBUTES.VALUE),
        router.get(Constants.QueryParams.LIMIT.VALUE),
        router.get(Constants.QueryParams.OFFSET.VALUE),
    ])

    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            },
        });
    }

    const onChangePage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e,
            },
        });
    };

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = [...holonTemplateDetail?.validAttributes?.filter(x =>
            x?.elementType != Constants.ELEMENT_TYPE.FILE.VALUE
            && x?.elementType != Constants.ELEMENT_TYPE.IMAGE.VALUE
            && x?.elementType != Constants.ELEMENT_TYPE.PASSWORD.VALUE
        )?.map(x => {
            return {
                elementType: x?.elementType,
                options: convertRegexToOptions(x?.regex),
                label: x?.label,
                key: x?.key,
                id: x?.id,
                [x?.key]: router.get(Constants.QueryParams.ATTRIBUTES.VALUE) && JSON.parse(router.get(Constants.QueryParams.ATTRIBUTES.VALUE))[x?.key] ?
                    JSON.parse(router.get(Constants.QueryParams.ATTRIBUTES.VALUE))[x?.key] : null
            }
        })];
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("warehouse.search"),
                listFormItem: listFormItem,
                callback: (_props) => {
                    let dataMain = [];

                    _props?.map(x => {
                        if (x[x?.key]) {
                            let index = holonTemplateDetail?.validAttributes?.findIndex(at => at?.id == x.id);
                            if (index != -1) {
                                if (holonTemplateDetail?.validAttributes[index]?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
                                    dataMain.push({
                                        ...x,
                                        [x?.key]: moment(x?.[x?.key]).format("YYYY-MM-DD")
                                    })
                                }
                                else if (holonTemplateDetail?.validAttributes[index]?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                                    dataMain.push({
                                        ...x,
                                        [x?.key]: moment(x?.[x?.key]).format("YYYY-MM-DD")
                                    })
                                } else {
                                    dataMain.push(x);
                                }
                            }
                        }
                    })
                    let params = {
                        ...router.getAll(),
                        [Constants.QueryParams.OFFSET.VALUE]: 1,
                    }

                    let _dataMain = []
                    dataMain?.map(x => {
                        if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                            if (x?.[x?.key] == "1") {
                                return _dataMain.push(x);
                            }
                            return;
                        }
                        return _dataMain.push(x)
                    })

                    if (_dataMain?.length > 0) {
                        params[Constants.QueryParams.ATTRIBUTES.VALUE] = JSON.stringify(_dataMain?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur?.[cur?.key] }), {}))
                    } else {
                        delete params[Constants.QueryParams.ATTRIBUTES.VALUE]
                    }

                    router.replace({
                        params: {
                            ...params
                        }
                    })
                },
                formArray: true
            }
        })
    }

    const handleAddWarehouse = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            payload: {
                title: t("warehouse.warehouse_created"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                dataBox: {
                    actionPayload: {
                        mode: router.get(Constants.QueryParams.TYPE.VALUE) ?? 1
                    },
                    callback: () => {
                        dispatch({
                            type: actionWareHouse.GET_WAREHOUSE_LIST,
                            payload: {
                                ...router.getAll(),
                            }
                        })
                    },
                },
                templateId: holonTemplateDetail?.id
            }
        })
    }

    useEffect(() => {
        if (router.get(Constants.QueryParams.SEARCH.VALUE)) {
            methods.setValue("search", router.get(Constants.QueryParams.SEARCH.VALUE));
        } else {
            methods.setValue("search", null);
        }
    }, [router.get(Constants.QueryParams.SEARCH.VALUE)])

    useEffect(() => {
        dispatch({
            type: actionWareHouse.GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE
        })
    }, [])


    return (
        <ContentLayout>
            <Breadcrumb>{t("warehouse.warehouse_management")}</Breadcrumb>
            <div className={classes['warehouse-container']}>
                <div className={classes['custom-search']}>
                    <CustomFormProvider {...methods}>
                        <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                            <FormSearch className={classes['custom-form-search']}>
                                <FormGroup flexWrap>
                                    <FormItem>
                                        <FormInput
                                            placeholder={t("search_by_word")}
                                            iconSearch={<IconSearch fontSize={14} />}
                                            isSearch
                                            fieldName='search' />
                                    </FormItem>
                                    {
                                        router.get(Constants.QueryParams.ATTRIBUTES.VALUE) && CommonDataFilter(router, Constants.QueryParams.ATTRIBUTES.VALUE, holonTemplateDetail)
                                    }
                                    <FormItem>
                                        <ButtonFilter onClick={handleOpenAddSearchCriteria} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                    </FormItem>
                                </FormGroup>
                                <ButtonAdd onClick={handleAddWarehouse} size='large' color='yellow' endIcon={<IconPlusWhite />} />
                            </FormSearch>
                            <button hidden type='submit' />
                        </form>
                    </CustomFormProvider>
                </div>
                <div className={classes['custom-body']}>
                    <CustomTable loading={loadingListWarehouse} data={listWarehouse?.records ?? []} headCell={header} />
                    <div className={classes['custom-pagination']}>
                        <CustomPagination
                            isRowPerpage
                            onChangeRowPerpage={onChangeRowPerpage}
                            limit={router.get(Constants.QueryParams.LIMIT.VALUE)}
                            onChange={onChangePage} total={listWarehouse?.total} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default WarehouseContainer