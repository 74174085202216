import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import useTrans from 'hooks/use-trans';
import Actions from 'redux/holon/action';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import actionBlInstance from 'redux/bl-instance/action';
import appActions from 'redux/app/action';
import Constants from 'utils/Constants';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister'
import FormSelectInstance from 'shared/components/custom-form/FormSelectInstance';
import FormSelect from 'shared/components/custom-form/FormSelect';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import Button from 'shared/components/form-input/button/Button';
import styles from './OrganizationalCollaborationBlPopup.module.scss'
import Validator from 'utils/Validator';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';

const OrganizationalCollaborationBlPopup = (props) => {
    const { showVisible, payload } = props;
    const { 
        companyOptions,
        defaultCompany,
        isDisabledCompanyOptions, 
        roleOptions, 
        templateIdsForUsers,
    } = payload;
    const { blCompany, blDepartments, blPositions } = useSelector(state => state.blInstance);
    const dispatch = useDispatch();
    const { trans } = useTrans();
    const methods = useForm();
    const { setValue, getValues } = methods;
    const [userList, setUserList] = useState([]);

    const departmentOptions = Array.isArray(blDepartments) ? blDepartments.map((department) => {
        return {
            value: department.holons?.[0].id,
            label: department.holons?.[0].name
        }
    }) : [];

    const positionOptions = Array.isArray(blPositions) ? blPositions.map((position) => {
        return {
            value: position.holons?.[0].id,
            label: position.holons?.[0].name
        }
    }) : [];

    useEffect(() => {
        setValue('company', defaultCompany);
        // setValue(department?.name, departmentHolon?.id);
        // setValue(position?.name, positionHolon?.id);
        dispatch({
            type: actionBlInstance.GET_BL_DEPARTMENT_BY_COMPANY_ID,
            payload: defaultCompany
        })

        return () => {
            dispatch({
                type: actionBlInstance.CLEAR_BL_DEPARTMENT
            })
            dispatch({
                type: actionBlInstance.CLEAR_BL_POSITION
            })
        }
    }, []);

    const onSubmit = (data) => {
        dispatch({
            type: appActions.SHOW_LOADING
        });
        dispatch({
            type: actionBlInstance.ADD_POSITION_FOR_USER,
            payload: {
                "position_id" : data['position'],
                "employee_ids": userList.toString(),
                "template_role": data['role']
            },
            callback: () => {
                dispatch({
                    type: appActions.HIDE_LOADING
                });
                showVisible(false);
                payload?.callback && payload?.callback();
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: trans("successful_register"),
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            },
            fallback: () => {
                dispatch({
                    type: appActions.HIDE_LOADING
                });
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: trans("system_error"),
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            }
        });
    }

    const onChange = (data) => {
        const ids = data.map(object => object.id);
        setUserList(ids)
    }

    return <>
        {/* <div className={`${styles['wrap-container']}`}> */}
        <BoxContentPopup width='700px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        title={props?.payload?.title}
                        showVisible={showVisible}
                        actionSectionBottom={<Button color='gray' loading={false} type='submit' title={trans('save')} />}
                    >
                        <div className={styles['wrap-body']}>
                            <div className={styles['wrap-form']}>
                                <div className={styles['data-attributes']}>
                                    <div className={styles['data-attributes-header']}>
                                        <p>{trans("holon.basic_info")}</p>
                                    </div>
                                    <div className={styles['data-attributes-body']}>
                                        <div className={styles['item-attributes']}>
                                            <p className={styles['item-attributes-title']}>
                                                {trans("Organizational_Collaboration.company")}
                                            </p>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName={'company'}
                                                disabled={isDisabledCompanyOptions}
                                                placeholder={trans("Organizational_Collaboration.placeholder_company")}
                                                options={companyOptions}   
                                            />
                                        </div>
                                        <div className={styles['item-attributes']}>
                                            <p className={styles['item-attributes-title']}>
                                                {trans("Organizational_Collaboration.department")}  
                                            </p>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName={'department'}
                                                placeholder={trans("Organizational_Collaboration.placeholder_department")}
                                                options={departmentOptions}
                                                onChangeSelect={(e) => {
                                                    dispatch({
                                                        type: actionBlInstance.GET_BL_POSITION_BY_DEPARTMENT_ID,
                                                        payload: Number(e)
                                                    })
                                                }}
                                            />  
                                        </div>
                                        <div className={styles['item-attributes']}>
                                            <p className={styles['item-attributes-title']}>
                                                {trans("Organizational_Collaboration.position")}
                                            </p>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName={'position'}
                                                placeholder={trans("Organizational_Collaboration.placeholder_position")}
                                                options={positionOptions}
                                            />
                                        </div>
                                        <div className={styles['item-attributes']}>
                                            <p className={styles['item-attributes-title']}>
                                                {trans("Organizational_Collaboration.role")}
                                            </p>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName={'role'}
                                                placeholder={trans("Organizational_Collaboration.placeholder_role")}
                                                options={roleOptions}
                                            />
                                        </div>
                                        <div className={styles['item-attributes']}>
                                            <p className={styles['item-attributes-title']}>
                                                {trans("Organizational_Collaboration.employee")}
                                            </p>
                                            {templateIdsForUsers && <FormSelectInstance onChangeSelect={onChange} disabled={false} holonTemplate={templateIdsForUsers} fieldName={`connections.instance`} validate={[Validator.required]} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
        {/* </div> */}
    </>
};

export default OrganizationalCollaborationBlPopup;