import { all, call, fork, takeLatest, put, takeEvery } from "redux-saga/effects";
import Actions from "./action";
import Factories from "./factory";
import { getToast, getToastError } from "utils/Utils";
import Constants from "utils/Constants";

function* getBlCompany() {
    yield takeLatest(Actions.GET_BL_COMPANY, function* (payload) {
        try {
            const response = yield call(() => Factories.getBlDetail(payload?.payload));
            if (response?.data) {
                yield put({
                    type: Actions.GET_BL_COMPANY_SUCCESS,
                    payload: response?.data
                })
            }
        } catch (error) {
            console.log("xxx Get holon saga error: ", error);
        }
    });
}

function* getBlDepartmentByCompanyId() {
    yield takeLatest(Actions.GET_BL_DEPARTMENT_BY_COMPANY_ID, function* (payload) {
        try {
            const response = yield call(() => Factories.getBlDepartmentByCompanyId(payload?.payload));

            if (response?.data?.connections) {
                yield put({
                    type: Actions.GET_BL_DEPARTMENT_BY_COMPANY_ID_SUCCESS,
                    payload: response?.data?.connections
                })
            }
        } catch (error) {
            console.log("xxx Get holon saga error: ", error);
        }
    });
}

function* getBlPositionByDepartmentId() {
    yield takeLatest(Actions.GET_BL_POSITION_BY_DEPARTMENT_ID, function* (payload) {
        try {
            const response = yield call(() => Factories.getBlPositionByDepartmentId(payload?.payload));

            if (response?.data?.connections) {
                yield put({
                    type: Actions.GET_BL_POSITION_BY_DEPARTMENT_ID_SUCCESS,
                    payload: response?.data?.connections
                })
            }
        } catch (error) {
            console.log("xxx Get holon saga error: ", error);
        }
    });
}

function* addPositionForUser() {
    yield takeLatest(Actions.ADD_POSITION_FOR_USER, function* (payload) {
        try {
            const response = yield call(() => Factories.addPositionForUser(payload?.payload));

            if (response?.data) {
                yield put({
                    type: Actions.ADD_POSITION_FOR_USER_SUSSESS,
                    payload: response?.data
                })
                payload?.callback && payload?.callback()
            }
            else {
                payload?.fallback && payload?.fallback()
            }
        } catch (error) {
            console.log("xxx Get holon saga error: ", error);
        }
    });
}

function* registerBlAccount() {
    yield takeLatest(Actions.REGISTER_BL_ACCOUNT, function* (payload) {
        try {
            const response = yield call(() => Factories.registerBlAccount(payload?.payload));

            if (response?.data) {
                yield put({
                    type: Actions.REGISTER_BL_ACCOUNT_SUCCESS,
                    payload: response?.data
                })
                payload?.callback && payload?.callback()
            }
            else {
                yield put({
                    type: Actions.REGISTER_BL_ACCOUNT_FAILURE,
                    payload: response?.error?.detail
                })
                payload?.fallback && payload?.fallback(response?.error?.detail)
            }
        } catch (error) {
            console.log("xxx Get holon saga error: ", error);
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getBlCompany),
        fork(getBlPositionByDepartmentId),
        fork(getBlDepartmentByCompanyId),
        fork(addPositionForUser),
        fork(registerBlAccount),
    ]);
}
