import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import useTrans from 'hooks/use-trans';
import actionBlInstance from 'redux/bl-instance/action';
import actionHolon from 'redux/holon/action';
import actionHolonTemplate from 'redux/holon-template/action';
import appActions from 'redux/app/action';
import Validator from 'utils/Validator';
import Button from 'shared/components/form-input/button/Button';
import classes from './AddBlAccountPopup.module.scss';
import FormInput from 'shared/components/custom-form/FormInput';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import Constants from 'utils/Constants';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import FormSelect from 'shared/components/custom-form/FormSelect';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_ADD_BL_ACCOUNT_SUCCESS_POPUP } from 'utils/EventRegister';
import FormRadio from 'shared/components/custom-form/FormRadio';
import FormSelectInstance from 'shared/components/custom-form/FormSelectInstance';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import FormFile from 'shared/components/custom-form/FormFile';
import FormImage from 'shared/components/custom-form/FormImage';
import WrapContentBody from 'shared/components/common/popup/custom-body/WrapContentBody';
import BoxContentPopup from 'shared/components/common/popup/custom-body/BoxContentPopup';
import FormAttributeV2 from 'shared/components/custom-form/FormAttributeV2';
import FormAttribute from 'shared/components/custom-form/FormAttribute';
import BoxRow from 'shared/components/common/box/BoxRow';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import BoxContent from 'shared/components/common/box/BoxContent';

const fields = {
    PCODE: 'address_code',
    MAIN: 'address_main',
    CHOMEBANCHIGO: 'address_chomebanchigo',
    BUILDING: 'address_building'
}

function AddBlAccountPopup(props) {
    const { showVisible, payload } = props;
    const {
        companyOptions,
        defaultCompany,
        isDisabledCompanyOptions,
        templateOptions,
    } = payload;
    const { blDepartments, blPositions } = useSelector(state => state.blInstance);
    const { templates } = useSelector(state => state.holon);
    const { holonTemplateDetail } = useSelector(state => state.holonTemplate);
    const dispatch = useDispatch();
    const { t } = useTrans();
    const methods = useForm();
    const { setValue, setError, clearErrors } = methods;
    const [btnLoading, setBtnLoading] = useState(false);
    const watchSelectedTemplate = methods.watch('templateRole');

    const departmentOptions = Array.isArray(blDepartments) ? blDepartments?.map((department) => {
        return {
            value: department.holons?.[0].id,
            label: department.holons?.[0].name
        }
    }) : [];

    const positionOptions = Array.isArray(blPositions) ? blPositions?.map((position) => {
        return {
            value: position.holons?.[0].id,
            label: position.holons?.[0].name
        }
    }) : [];

    useEffect(() => {
        setValue('companyId', defaultCompany);
        setValue('templateRole', templateOptions?.[0]?.value);

        dispatch({
            type: actionBlInstance.GET_BL_DEPARTMENT_BY_COMPANY_ID,
            payload: defaultCompany
        })

        dispatch({
            type: actionHolon.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.USER_TEMPLATE,
        });

        return () => {
            dispatch({
                type: actionHolon.CLEAR_HOLON_TEMPLATES
            })
            dispatch({
                type: actionBlInstance.CLEAR_BL_DEPARTMENT
            })
            dispatch({
                type: actionBlInstance.CLEAR_BL_POSITION
            })
        }
    }, []);

    useEffect(() => {
        if (watchSelectedTemplate) {
            dispatch({
                type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT,
                payload: watchSelectedTemplate,
            })
        }

        return () => {
            dispatch({
                type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_SUCCESS,
                payload: {},
            })
        }
    }, [watchSelectedTemplate])

    useEffect(() => {
        if (holonTemplateDetail?.id) {
            holonTemplateDetail?.validAttributes?.map(x => {
                if (x?.elementType == Constants.ELEMENT_TYPE.AUTOGEN.VALUE) {
                    methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, "")
                    return;
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS.VALUE) {
                    methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, "")
                    return;
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                    let _value = "";
                    if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE) {
                        _value = new Date();
                    }
                    methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, _value)
                    return;
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
                    let _value = "";
                    if (x?.defaultValue == Constants.ELEMENT_TYPE.DATETIME.DEFAULT_VALUE) {
                        _value = new Date();
                    }
                    methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, _value)
                    return;
                }

                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                    methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, [])
                    return;
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                    methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, [])
                    return;
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
                    if (x?.defaultValue?.indexOf("{") == 0) {
                        methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, "")
                        return;
                    }
                    methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, x?.defaultValue || "")
                    return;
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.TEXT.VALUE) {
                    if (x?.defaultValue?.indexOf("{") == 0) {
                        methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, "")
                        return;
                    }
                    return {
                        ...x,
                        [x?.key]: x?.defaultValue || ""
                    }
                }
                methods.setValue(`requestData.${holonTemplateDetail?.id}.${x?.key}`, x?.defaultValue || "")
                return;
            })
        }

    }, [holonTemplateDetail?.id])

    const onSubmit = (data) => {
        const formData = {
            ...data,
            "holonCode": `${Constants.HOLON_MASTER_CODE.BL_PERSON_IN_CHARGE},${Constants.HOLON_MASTER_CODE.USER_TEMPLATE}`
        }
        setBtnLoading(true);
        dispatch({
            type: actionBlInstance.REGISTER_BL_ACCOUNT,
            payload: formData,
            callback: () => {
                setBtnLoading(false);
                showVisible(false);
                payload?.callback && payload?.callback();
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_ADD_BL_ACCOUNT_SUCCESS_POPUP,
                    newWindow: true,
                    payload: {
                        title: t("account_registration_title"),
                        showHeader: true,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            },
            fallback: (error) => {
                setBtnLoading(false);
                if (Object.keys(error).length > 0) {
                    Object.keys(error).forEach((key) => {
                        setError(key, {
                            type: 'custom',
                            message: error[key]?.[0]
                        })
                    })
                } else {
                    EventRegister.emit(EVENT_SHOW_POPUP, {
                        open: true,
                        type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                        newWindow: true,
                        payload: {
                            title: t("system_error"),
                            showHeader: false,
                            customStyle: {
                                padding: 0,
                                borderTop: 0
                            },
                        }
                    })

                }
            }
        });
    }

    const getAddress = (value) => {
        if (value.length >= 7) {
            const formattedString = value.substring(0, 3) + "-" + value.substring(3);
            dispatch({
                type: appActions.GET_ADDRESS,
                code: formattedString,
                callback: (data) => {
                    if (data?.['addressMain']) {
                        setValue(`address_main`, data['addressMain']);
                        clearErrors(`address_main`, data['addressMain']);
                    }
                }
            })
        }
    }

    return (
        <BoxContentPopup width='700px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        title={props?.payload?.title}
                        showVisible={showVisible}
                        actionSectionBottom={<Button color='gray' loading={btnLoading} type='submit' title={t('save')} />}
                    >
                        <div className={classes['wrap-body']}>
                            <div className={classes['wrap-form']}>
                                <div className={classes['data-attributes']}>
                                    <div className={classes['data-attributes-header']}>
                                        <p>{t("holon.basic_info")}</p>
                                    </div>
                                    <div className={classes['data-attributes-body']}>
                                        <div className={classes['item-attributes']}>
                                            <p className={classes['item-attributes-title']}>
                                                {t("Organizational_Collaboration.company")}
                                                <span style={{ color: 'red' }}>*</span>
                                            </p>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName={'companyId'}
                                                disabled={isDisabledCompanyOptions}
                                                placeholder={t("Organizational_Collaboration.placeholder_company")}
                                                options={companyOptions}
                                                isRegexLabel={t("Organizational_Collaboration.company")}
                                                validationFE={true}
                                            />
                                        </div>
                                        <div className={classes['item-attributes']}>
                                            <p className={classes['item-attributes-title']}>
                                                {t("Organizational_Collaboration.department")}
                                                <span style={{ color: 'red' }}>*</span>
                                            </p>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName={'departmentId'}
                                                placeholder={t("Organizational_Collaboration.placeholder_department")}
                                                options={departmentOptions ?? []}
                                                onChangeSelect={(e) => {
                                                    dispatch({
                                                        type: actionBlInstance.GET_BL_POSITION_BY_DEPARTMENT_ID,
                                                        payload: Number(e)
                                                    })
                                                }}
                                                isRegexLabel={t("Organizational_Collaboration.department")}
                                                validationFE={true}
                                            />
                                        </div>
                                        <div className={classes['item-attributes']}>
                                            <p className={classes['item-attributes-title']}>
                                                {t("Organizational_Collaboration.position")}
                                                <span style={{ color: 'red' }}>*</span>
                                            </p>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName={'positionId'}
                                                placeholder={t("Organizational_Collaboration.placeholder_position")}
                                                options={positionOptions ?? []}
                                                isRegexLabel={t("Organizational_Collaboration.position")}
                                                validationFE={true}
                                            />
                                        </div>
                                        <div className={classes['item-attributes']}>
                                            <p className={classes['item-attributes-title']}>
                                                {t("template")}
                                                <span style={{ color: 'red' }}>*</span>
                                            </p>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName={'templateRole'}
                                                placeholder={t("Holon_Template.Select_Template")}
                                                options={templateOptions ?? []}
                                                isRegexLabel={t("template")}
                                                validationFE={true}
                                            />
                                        </div>
                                        {
                                            templates && templates?.map(template => template?.attributes?.map((x, index) => {
                                                return (
                                                    x?.key == Constants.ELEMENT_TYPE.EMAIL.VALUE ?
                                                        <BoxRow key={index}>
                                                            <BoxLabel>
                                                                {x?.label}
                                                                {x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                                            </BoxLabel>
                                                            <BoxContent>
                                                                <FormAttributeV2 prefix={`requestData.${template?.id}`} value={x} index={index} />
                                                            </BoxContent>
                                                        </BoxRow>
                                                        :
                                                        null
                                                )
                                            }))
                                        }
                                        {
                                            holonTemplateDetail?.validAttributes && holonTemplateDetail?.validAttributes?.map((x, index) => {
                                                return (
                                                    <BoxRow key={index}>
                                                        <BoxLabel>
                                                            {x?.label}
                                                            {x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                                        </BoxLabel>
                                                        <BoxContent>
                                                            <FormAttributeV2 prefix={`requestData.${holonTemplateDetail?.id}`} value={x} index={index} />
                                                        </BoxContent>
                                                    </BoxRow>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default AddBlAccountPopup;