import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, Controller, useFormContext } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/form-input/button/Button';
import styles from './Tabs.module.scss'
import useTrans from 'hooks/use-trans';

function OthersTab(props) {
    const { } = props
    const { trans } = useTrans();
    const methods = useForm();
    const { register, handleSubmit } = methods;
    const { hex_id, status_user, updateUserError, loading: {loadingUpdateAccount} } = useSelector(state => state.user);

    const onSubmit = (data) => {
    }

    return (
        <>
        </>
    );
}

export default OthersTab;