import React, { useRef, useState } from 'react'
import classes from './ContentMessage.module.scss';
import moment from 'moment';
import { Popover } from '@mui/material';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_PREVIEW_FILE_POPUP } from 'utils/EventRegister';
import ImageMessage from 'shared/components/common/image/ImageMessage';
import RenderFileAttachmentLink from './AttachmentLink';
import AttachmentLinkReply from './AttachmentLinkReply';
import IconForward from 'assets/icon/IconForward';
import useTrans from 'hooks/use-trans';
import CustomTooltip from 'shared/components/common/tooltip/CustomTooltip';

function Content(props) {
    const { x, handleRepMsg, handleForwardMsg } = props;
    const ref = useRef();
    const { t } = useTrans();

    const getTypeAttachmentLink = (filePath) => {
        let fileExtension = "";
        if (filePath) {
            fileExtension = filePath.substring(filePath.lastIndexOf('.') + 1)
        }
        return fileExtension;
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mouseX, setMouseX] = useState();
    const [mouseY, setMouseY] = useState();

    const handleShowOptionAnchorEl = (event) => {
        setAnchorEl(event.currentTarget);
        setMouseX(event.clientX);
        setMouseY(event.clientY);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openAnchorEl = Boolean(anchorEl);
    const id = openAnchorEl ? 'simple-popover' : undefined;

    const handleOpenPreview = (link, attachmentFileName) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_PREVIEW_FILE_POPUP,
            payload: {
                linkFile: link,
                linkFileName: attachmentFileName,
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                isMessage: true
            }
        })
    }

    const checkFile = (x) => {
        return getTypeAttachmentLink(x?.attachmentLink) == 'jpeg' ||
            getTypeAttachmentLink(x?.attachmentLink) == 'png' ||
            getTypeAttachmentLink(x?.attachmentLink) == 'jpg' ||
            getTypeAttachmentLink(x?.attachmentLink) == 'svg' ||
            getTypeAttachmentLink(x?.attachmentLink) == 'gif' ||
            getTypeAttachmentLink(x?.attachmentLink) == 'webp' ||
            getTypeAttachmentLink(x?.attachmentLink) == 'tiff' ||
            getTypeAttachmentLink(x?.attachmentLink) == 'bmp' ? true : false
    }

    const handleOpenPreviewReply = () => {
        if (x?.repToAttachmentLink) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_PREVIEW_FILE_POPUP,
                payload: {
                    linkFile: x?.repToAttachmentLink,
                    linkFileName: x?.repToAttachmentFileName,
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                    isMessage: true
                }
            })
        }
    }
    const handleOpenPreviewForward = () => {
        if (x?.forwardToAttachmentLink) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_PREVIEW_FILE_POPUP,
                payload: {
                    linkFile: x?.forwardToAttachmentLink,
                    linkFileName: x?.forwardToAttachmentFileName,
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                    isMessage: true
                }
            })
        }
    }

    const onHandleForwardMsg = (x) => {
        handleForwardMsg(x)
    }

    const checkRenderContent = (x) => {
        if (x?.messageContent || x?.forwardId || x?.repId) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <React.Fragment key={x?.messageId}>
            {
                !x?.isMe ? <>
                    <div className={classes['Message_Item']}>
                        <div className={classes['Message_Item_Content']}>
                            <div className={classes['User_Img']}>
                                <CustomTooltip styleTooltip={{
                                    height: '100%'
                                }} textTooltip={x?.displayName}>
                                    <div className={classes['Avatar']}>
                                        <ImageMessage src={x?.avatar || ''} />
                                    </div>
                                </CustomTooltip>
                            </div>
                            {
                                x?.attachmentLink && checkFile(x) && !x?.forwardId ? <></> : <div className={classes['Triangle']}></div>
                            }
                            <div onContextMenu={(e) => {
                                e.preventDefault();
                                handleShowOptionAnchorEl(e);
                            }} className={classes['Content']} style={{
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}
                            >
                                {
                                    !x?.forwardId && x?.attachmentLink ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 10 }}>
                                        <div style={{
                                            borderBottomLeftRadius: x?.messageContent ? 0 : 10
                                        }} className={classes['content-attachment']} onClick={(e) => {
                                            e.stopPropagation()
                                            handleOpenPreview(x?.attachmentLink, x?.attachmentFileName)
                                        }}>
                                            {
                                                x?.attachmentLink && <RenderFileAttachmentLink type={getTypeAttachmentLink(x?.attachmentLink)} x={x} />
                                            }
                                        </div>
                                        {
                                            checkRenderContent(x) ? <></> : <div className={classes['Current_Time']}>
                                                <p>{moment(x?.updatedAt).format("HH:mm")}</p>
                                            </div>
                                        }
                                    </div> : <></>
                                }
                                {
                                    checkRenderContent(x) && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 10 }}>
                                        <div style={{
                                            backgroundColor: x?.messageContent == "" && checkFile(x) && !x?.repId && !x?.forwardId ? 'transparent' : '',
                                            borderTopLeftRadius: x?.attachmentLink && !x?.forwardId ? 0 : 10
                                        }} className={classes['User_Message']}>
                                            {
                                                x?.forwardId ? <div className={`${classes['User_Message_Forward']}`}>
                                                    <div className={classes['Forward_Icon']}>
                                                        <IconForward color='#000000' />
                                                    </div>
                                                    <div onClick={handleOpenPreviewForward} className={classes['User_Message_Forward_Info']}>
                                                        {x?.forwardToMessage && x?.forwardToAttachmentLink ?
                                                            <div>
                                                                <AttachmentLinkReply x={{
                                                                    isMe: true
                                                                }} type={x?.forwardToAttachmentLink} repToAttachmentFileName={x?.forwardToAttachmentFileName} />
                                                                <div style={{ marginTop: 5 }} className={classes['Message_Forward_Text']}>{x?.forwardToMessage}</div>
                                                            </div>
                                                            :
                                                            x?.forwardToMessage ?
                                                                <div className={classes['Message_Forward_Text']}>{x?.forwardToMessage}</div>
                                                                : <>
                                                                    {x?.forwardToAttachmentLink ? <AttachmentLinkReply x={{
                                                                        isMe: true
                                                                    }} type={x?.forwardToAttachmentLink} repToAttachmentFileName={x?.forwardToAttachmentFileName} /> : <></>}
                                                                </>}
                                                        <div className={classes['Info_Message_Forward']}>
                                                            <p>
                                                                {x?.forwardName},
                                                            </p>
                                                            <p>
                                                                {moment(x?.forwardMessageUpdateAt).format("DD/MM/YYYY HH:mm")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> : x?.repId ? <div className={classes['User_Message_Reply']}>
                                                    <div className={classes['User_Message_Reply_Avatar']}>
                                                        <div className={classes['Avatar']}>
                                                            <ImageMessage src={x?.repAvatar} />
                                                        </div>
                                                    </div>
                                                    <div className={classes['User_Message_Reply_Info']}>
                                                        <p className={classes['User_Message_Reply_Name']}>{x?.repName}</p>
                                                        <p onClick={handleOpenPreviewReply} className={classes['User_Message_Reply_Message']}>
                                                            {x?.repToMessage && x?.repToMessage ? <>
                                                                <AttachmentLinkReply x={{
                                                                    isMe: true
                                                                }} type={x?.repToAttachmentLink} repToAttachmentFileName={x?.repToAttachmentFileName} />
                                                                {x?.repToMessage}
                                                            </> :
                                                                x?.repToMessage ? x?.repToMessage : <>
                                                                    {x?.repToAttachmentLink ? <AttachmentLinkReply repToAttachmentFileName={x?.repToAttachmentFileName} type={x?.repToAttachmentLink} /> : <></>}
                                                                </>}</p>
                                                    </div>
                                                </div> : <></>
                                            }
                                            {
                                                x?.messageContent && !x?.forwardId && <div className={classes['content-message']}>
                                                    <p>{x?.messageContent}</p>
                                                </div>
                                            }
                                        </div>
                                        <div className={classes['Current_Time']}>
                                            <p>{moment(x?.updatedAt).format("HH:mm")}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Popover
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        id={id}
                        open={openAnchorEl}
                        onClose={handleClose}
                        anchorPosition={{ top: mouseY, left: mouseX }}
                        anchorReference="anchorPosition"
                    >
                        <div ref={ref} className={classes['Message_Option']}>
                            <div onClick={(e) => {
                                handleRepMsg(x);
                                handleClose(e)
                            }} className={classes['Option_Reply']}>{t("message.reply")}</div>
                            <div onClick={(e) => {
                                handleClose(e);
                                handleForwardMsg(x);
                            }} className={classes['Option_Reply']}>{t("message.transfer")}</div>
                        </div>
                    </Popover>
                </>
                    :
                    <>
                        <div className={`${classes['Message_Item']} ${classes['Message_Item_Me']}`}>
                            <div className={classes['Message_Item_Content']}>
                                <div onContextMenu={(e) => {
                                    e.preventDefault();
                                    handleShowOptionAnchorEl(e)
                                }} className={classes['Content']}>
                                    {
                                        x?.attachmentLink && !x?.forwardId && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 10 }}>
                                            {
                                                checkRenderContent(x) ? <></> : <div className={classes['Current_Time']}>
                                                    <p>{moment(x?.updatedAt).format("HH:mm")}</p>
                                                </div>
                                            }
                                            <div style={{
                                                borderBottomRightRadius: x?.messageContent ? 0 : 10
                                            }} className={`${classes['content-attachment']} ${!checkFile(x) ? classes['content-attachment-me'] : ''}`} onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenPreview(x?.attachmentLink, x?.attachmentFileName)
                                            }}>
                                                {
                                                    x?.attachmentLink && <RenderFileAttachmentLink type={getTypeAttachmentLink(x?.attachmentLink)} x={x} />
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        checkRenderContent(x) && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 10 }}>
                                            <div className={classes['Current_Time']}>
                                                <p>{moment(x?.updatedAt).format("HH:mm")}</p>
                                            </div>
                                            <div style={{
                                                backgroundColor: x?.messageContent == "" && checkFile(x) && !x?.repId && !x?.forwardId ? 'transparent' : '',
                                                borderTopRightRadius: x?.attachmentLink && !x?.forwardId ? 0 : 10
                                            }} className={classes['User_Message']}>
                                                {
                                                    x?.forwardId ? <div className={`${classes['User_Message_Forward']} ${classes['User_Message_Forward_Me']}`}>
                                                        <div className={classes['Forward_Icon']}>
                                                            <IconForward color='#0E525E' />
                                                        </div>
                                                        <div onClick={handleOpenPreviewForward} className={classes['User_Message_Forward_Info']}>
                                                            {x?.forwardToMessage && x?.forwardToAttachmentLink ?
                                                                <div>
                                                                    <AttachmentLinkReply x={{
                                                                        isMe: true
                                                                    }} type={x?.forwardToAttachmentLink} repToAttachmentFileName={x?.forwardToAttachmentFileName} />
                                                                    <div style={{ marginTop: 5 }} className={classes['Message_Forward_Text']}>{x?.forwardToMessage}</div>
                                                                </div>
                                                                :
                                                                x?.forwardToMessage ?
                                                                    <div className={classes['Message_Forward_Text']}>{x?.forwardToMessage}</div>
                                                                    : <>
                                                                        {x?.forwardToAttachmentLink ? <AttachmentLinkReply x={{
                                                                            isMe: true
                                                                        }} type={x?.forwardToAttachmentLink} repToAttachmentFileName={x?.forwardToAttachmentFileName} /> : <></>}
                                                                    </>}
                                                            <div className={classes['Info_Message_Forward']}>
                                                                <p>
                                                                    {x?.forwardName},
                                                                </p>
                                                                <p>
                                                                    {moment(x?.forwardMessageUpdateAt).format("DD/MM/YYYY HH:mm")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div> : x?.repId ? <div className={`${classes['User_Message_Reply']} ${classes['User_Message_Reply_Me']}`}>
                                                        <div className={classes['User_Message_Reply_Avatar']}>
                                                            <div className={classes['Avatar']}>
                                                                <ImageMessage src={x?.repAvatar} />
                                                            </div>
                                                        </div>
                                                        <div className={classes['User_Message_Reply_Info']}>
                                                            <p className={classes['User_Message_Reply_Name']}>{x?.repName}</p>
                                                            <p onClick={handleOpenPreviewReply} className={`${classes['User_Message_Reply_Message']} ${classes['User_Message_Reply_Message_Me']}`}>
                                                                {x?.repToMessage && x?.repToMessage ? <>
                                                                    <AttachmentLinkReply x={{
                                                                        isMe: true
                                                                    }} type={x?.repToAttachmentLink} repToAttachmentFileName={x?.repToAttachmentFileName} />
                                                                    {x?.repToMessage}
                                                                </> :
                                                                    x?.repToMessage ? x?.repToMessage : <>
                                                                        {x?.repToAttachmentLink ? <AttachmentLinkReply x={{
                                                                            isMe: true
                                                                        }} type={x?.repToAttachmentLink} repToAttachmentFileName={x?.repToAttachmentFileName} /> : <></>}
                                                                    </>}
                                                            </p>
                                                        </div>
                                                    </div> : <></>
                                                }
                                                {x?.messageContent && !x?.forwardId && <div style={{
                                                    paddingTop: !x?.attachmentLink ? '11px' : '5px'
                                                }} className={classes['content-message']}>
                                                    <p>{x?.messageContent}</p>
                                                </div>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    x?.attachmentLink && checkFile(x) && !x?.forwardId ? <></> : <div className={classes['Triangle_Me']}></div>
                                }
                            </div>
                        </div>
                        <Popover
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            id={id}
                            open={openAnchorEl}
                            onClose={handleClose}
                            anchorPosition={{ top: mouseY, left: mouseX }}
                            anchorReference="anchorPosition"
                        >
                            <div ref={ref} className={classes['Message_Option']}>
                                <div onClick={(e) => {
                                    handleRepMsg(x);
                                    handleClose(e)
                                }} className={classes['Option_Reply']}>{t("message.reply")}</div>
                                <div onClick={(e) => {
                                    handleClose(e);
                                    onHandleForwardMsg(x);
                                }} className={classes['Option_Reply']}>{t("message.transfer")}</div>
                            </div>
                        </Popover>
                    </>
            }
        </React.Fragment>
    )
}

export default Content