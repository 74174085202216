import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import PolicySettingAction from './action';
import factory from './factory';

function* getPolicySetting() {
    yield takeEvery(
        PolicySettingAction.GET_POLICY_SETTING,
        function* (payload) {
            try {
                const response = yield call(() =>
                    factory.getPolicySetting(payload?.payload),
                );
                if (response) {
                    yield put({
                        type: PolicySettingAction.GET_POLICY_SETTING_SUCCESS,
                        payload: response?.data,
                    });
                }
            } catch (error) {
                yield put({
                    type: PolicySettingAction.GET_POLICY_SETTING_FAILED,
                });
            }
        },
    );
}
function* updatePolicySetting() {
    yield takeEvery(
        PolicySettingAction.UPDATE_POLICY_SETTING,
        function* (payload) {
            try {
                const response = yield call(() =>
                    factory.updatePolicySetting(payload?.payload),
                );
                if (response?.data == 1) {
                    payload?.callback?.success && payload?.callback?.success()
                    yield put({
                        type: PolicySettingAction.UPDATE_POLICY_SETTING_SUCCESS,
                        payload: response?.data,
                    });
                }else{
                    payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
                    yield put({
                        type: PolicySettingAction.UPDATE_POLICY_SETTING_FAILED,
                    });
                }
            } catch (error) {
                yield put({
                    type: PolicySettingAction.UPDATE_POLICY_SETTING_FAILED,
                });
            }
        },
    );
}
export default function* rootSaga() {
    yield all([fork(getPolicySetting), fork(updatePolicySetting)]);
}
