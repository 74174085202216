import React from "react";

import classes from "./RowInput.module.scss";
import FormInput from "shared/components/custom-form/FormInput";
import FormRadio from "shared/components/custom-form/FormRadio";
import FormTextArea from "shared/components/custom-form/FormTextArea";
import FormSelect from "shared/components/custom-form/FormSelect";
import FormCheckbox from "shared/components/custom-form/FormCheckbox";

const RowInput = (props) => {
    const { label, inputType, checkboxLabel, ...rest } = props;

    const FormCheckboxCustom = () => {
        return (
            <div className={classes["CheckboxCustom"]}>
                <FormCheckbox {...rest}  styleFormCheckbox={"width-0"} label={checkboxLabel}/>
            </div>
        );
    };
    const renderInputElement = () => {
        let element = "";
        switch (inputType) {
            case "text":
                element = <FormInput {...rest} validationFE={true}/>;
                break;
            case "radio":
                element = <FormRadio {...rest} />;
                break;
            case "textarea":
                element = <FormTextArea {...rest} validationFE={true} />;
                break;
            case "select":
                element = <FormSelect {...rest}  validationFE={true}/>;
                break;
            case "checkbox":
                element = <FormCheckboxCustom />;
                break;
            default:
                break;
        }
        return element;
    };

    return (
        <div className={classes["RowInput"]}>
            <p className={classes["Label"]}>{label}</p>
            {renderInputElement()}
        </div>
    );
};

export default RowInput;
