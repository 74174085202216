import React from 'react'

function IconSortRight({ fontSize = 24 }) {
    return (
        <svg fontSize={fontSize} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8L20.5 12L16 16" stroke="#3A3A3A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.5 12L20.5 12" stroke="#3A3A3A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconSortRight