import React from 'react'
import classes from './Button.module.scss';
import IconPlusWhite from 'assets/icon/IconPlusWhite';

function ButtonAdd(props) {
    const {
        title,
        variant = 'contained',
        onClick = () => { },
        children,
        color = 'blue',
        startIcon = null,
        endIcon = null,
        disabled = false,
        type = 'button',
        backgroundTransparent = false,
        size = 'nomarl',
        className = '',
        loading = false
    } = props;
    // variant : outlined | contained
    // color : gray | default | green | red | violet |
    // size : small | normal | large
    const getColor = (_color) => {
        if (_color == 'default') {
            return 'button_color_default';
        }
        if (_color == 'red') {
            return 'button_color_red';
        }
        if (_color == 'gray') {
            return 'button_color_gray';
        }
        if (_color == 'yellow') {
            return 'button_color_yellow';
        }
        if (_color == 'green') {
            return 'button_color_green';
        }
        if (_color == 'white') {
            return 'button_color_white';
        }
        if (_color == 'hippie_blue') {
            return 'button_color_hippie_blue';
        }
        if (_color == 'transparent') {
            return 'button_color_transparent';
        }
        return '';
    };

    return (
        <div
            className={`
            ${classes['custom-button']} ${classes['custom-button-add']} ${classes[getColor(color)]} 
            ${backgroundTransparent ? classes['background-transparent'] : ''} ${disabled ? classes['disabled'] : ''} ${loading ? classes['disabled'] : ''} ${classes[size]} ${!children && !title ? classes['custom-button-icon'] : ''} ${className}`}
        >
            <button
                className={`${classes[variant]}`}
                type={type}
                onClick={onClick}
                disabled={disabled ? disabled : (loading ? true : false)}
            >
                <IconPlusWhite fontSize={18} />
            </button>
        </div>
    );
}

export default ButtonAdd