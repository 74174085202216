import React, { useEffect } from 'react'
import useTrans from 'hooks/use-trans';
import { useDispatch, useSelector } from 'react-redux'
import actionReceiver from 'redux/receiver/action';
import HolonInstance from 'shared/components/holon-instance/HolonInstance';

function ReceiverContainer() {
    const { t } = useTrans();
    const dispatch = useDispatch();

    const { holonTemplateDetail } = useSelector(state => state.receiver);

    useEffect(() => {
        dispatch({
            type: actionReceiver.GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER
        })
    }, [])

    return (
        <>
            {
                holonTemplateDetail?.id && <HolonInstance
                    holonTemplateId={holonTemplateDetail?.id}
                    titleCreatePopup={t("receiver.receiver_created")}
                    titleDetailPopup={t("receiver.receiver_detail")}
                    titleUpdatePopup={t("receiver.update_receiver")}
                    title={t("receiver.receiver_management")}
                />
            }
        </>
    )
}

export default ReceiverContainer