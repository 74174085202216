import React, { useEffect, useState } from "react";
import $ from 'jquery';
import Actions from 'redux/holon/action';
import jpFlag from 'assets/img/jp-flag.png';
import { useDispatch, useSelector } from 'react-redux';
import actionDashboard from 'redux/dashboard/action';
import { getCurrencyFormat, getLabel, getResourceByName } from 'shared/components/common/Util.js';
import styles from './DashboardContainer.module.scss';
import moment from "moment";
import 'moment/locale/ja';
import 'moment/locale/zh-cn'; // Import Chinese locale
import useTrans from "hooks/use-trans";
import Constants from "utils/Constants";
import IconStatsPencil from "assets/icon/IconStatsPencil";
import IconStatsCycle from "assets/icon/IconStatsCycle";
import IconStatsPack from "assets/icon/IconStatsPack";
import IconStatsAlerts from "assets/icon/IconStatsAlerts";
import useRouter from "hooks/use-router";
import RouterPath from "router/RouterPath";

const orderIcons = {
    "ORDER_CREATING": <IconStatsPencil />,
    "ORDER_WAITING_FOR_CHECK": <IconStatsCycle />,
    "ORDER_CHECKED": <IconStatsPack />,
    "ORDER_STOCKED": <IconStatsAlerts />,
    "ORDER_EXPORTED": <IconStatsPencil />,
    "ORDER_JP_CUSTOMS_CLEARANCE": <IconStatsPencil />,
    "ORDER_CN_CUSTOMS_CLEARANCE": <IconStatsPencil />,
    "ORDER_SHIPPING": <IconStatsPencil />,
    "ORDER_DELIVERED": <IconStatsPencil />,
    "ORDER_CANCELED": <IconStatsPencil />,
    "ORDER_WAITING_FOR_PAYMENT": <IconStatsPencil />,
    "ORDER_WITHDRAWAL": <IconStatsPencil />,
    "ORDER_ABNORMALITY_WITHDRAWAL": <IconStatsPencil />,
    "ORDER_ABNORMALITY_OTHER": <IconStatsPencil />,
}

function DashboardContainer() {
    const dispatch = useDispatch();
    const [orderTemplate, setOrderTemplate] = useState();
    const { wallets, orders, notifications, numberOfOrderedUser, numberOfUser, walletInfo, inquiryCount } = useSelector((state) => state.dashboard);
    const { full_name_user } = useSelector(state => state.user);
    const lang = localStorage.getItem('i18nextLng');
    const { t } = useTrans();
    const date = moment();
    const weekArray = [
        t('sunday'),
        t('monday'),
        t('tuesday'),
        t('wednesday'),
        t('thursday'),
        t('friday'),
        t('saturday'),
    ]

    const handleInputChange = (value) => {
        const inputText = value.toLowerCase();
        const foundObject = orderTemplate?.find(item => item.name.toLowerCase().includes(inputText));
        if (foundObject) {
            return foundObject.id
        } else {
            return orderTemplate?.map(obj => obj.id).join('-');
        }
    }

    useEffect(() => {
        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.ORDER,
            callback: (data) => {
                setOrderTemplate(data);
            }
        })
    }, []);

    const router = useRouter()

    const handleClickIcon = (status, name) => {
        router.push({
            pathname: RouterPath.PORTAL_ORDER_MANAGEMENT,
            params: {
                [Constants.QueryParams.ATTRIBUTES.VALUE]: JSON.stringify({
                    status: status
                }),
                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: handleInputChange(name)
            },
        })
    }

    const handleClickInquiryCount = () => {
        router.push({
            pathname: RouterPath.PORTAL_INQUIRY_LIST,
            params: {
                [Constants.QueryParams.STATUS.VALUE]: Constants.INQUIRY_PROCESS_STATUS.REQUESTING.VALUE
            },
        })
    }

    var formattedDate = date.format('MM月 DD日');
    let currentTime;
    if (lang == "jp") {
        date.locale('ja');
    } else if (lang == 'en') {
        date.locale('en');
        formattedDate = date.format('MM/DD');
    } else {
        date.locale('zh-cn');
        formattedDate = date.format('MM月 DD日');
    }

    let checkTime = moment().format("HH:mm:ss");
    if (checkTime >= moment(Constants.TIME_IN_DATE.MORNING, "HH:mm:ss").format("HH:mm:ss") && checkTime < moment(Constants.TIME_IN_DATE.AFTERNOON, "HH:mm:ss").format("HH:mm:ss")) {
        currentTime = t('good_morning');
    } else if (checkTime < moment(Constants.TIME_IN_DATE.EVENING, "HH:mm:ss").format("HH:mm:ss")) {
        currentTime = t('good_afternoon');
    } else {
        currentTime = t('good_evening');
    }

    useEffect(() => {
        dispatch({
            type: actionDashboard.GET_NOTIFICATIONS,
        })
        dispatch({
            type: actionDashboard.GET_ORDERS,
        })
        dispatch({
            type: actionDashboard.GET_WALLETS,
        })
        dispatch({
            type: actionDashboard.GET_NUMBER_OF_USER,
        })
        dispatch({
            type: actionDashboard.GET_NUMBER_OF_ORDERED_USER,
        })
        dispatch({
            type: actionDashboard.GET_DASHBOARD_WALLET_INFO,
        })
        dispatch({
            type: actionDashboard.GET_INQUIRY_COUNT,
        })
    }, []);

    const WalletTab = () => (
        <div className={`${styles['wallet-container']}`}>
            <div className={`${styles['wallet-row-header']}`}>
                <div className={styles['container-header']}><span>{t('dashboard.wallet')}</span></div>
            </div>
            <div className={`row ${styles['wallet-row-content']}`}>
                {wallets?.map((wallet, index) => (
                    <div className={`col ${styles['wallet-card']}`} key={index}>
                        <div className={`${styles['inner-wallet-card']}`}>
                            <div className={`${styles['wallet-card-header']}`}>
                                <img src={wallet.currency.includes("円") ? jpFlag : (wallet.currency.includes("USD") ? "" : "")}
                                    className='flag-icon' alt={wallet.currency.includes("円") ? "JP-Flag" : (wallet.currency.includes("USD") ? "US-FLag" : "CN-Flag")}
                                /> {getLabel(wallet.name)}
                            </div>
                            <div className={`list-group ${styles['wallet-card-content']}`}>
                                <div className={`list-group-item ${styles['wallet-row-content']}`}>
                                    <div className={`${styles['wallet-row-label']}`}>
                                        <div className={`col ${styles['content-label']}`}>{t('dashboard.credit_limit')}</div>
                                        <div className="col-auto"><span className={styles['currency-number']}>{getCurrencyFormat(walletInfo.creditLimit, wallet.currency)}</span><span className={styles['currency-label']}> {getLabel(wallet.currency)} </span></div>
                                    </div>
                                </div>
                                <div className={`list-group-item ${styles['wallet-row-content']}`}>
                                    <div className={`${styles['wallet-row-label']}`}>
                                        <div className={`col ${styles['content-label']}`}>{t('dashboard.balance')}</div>
                                        <div className="col-auto"><span className={styles['currency-number']}>{getCurrencyFormat(walletInfo.amount, wallet.currency)}</span><span className={styles['currency-label']}> {getLabel(wallet.currency)} </span></div>
                                    </div>
                                </div>
                                <div className={`list-group-item ${styles['wallet-row-content']} ${styles['wallet-last-row-content']}`}>
                                    <div className={`${styles['wallet-row-label']}`}>
                                        <div className={`col ${styles['content-label']}`}>{t('dashboard.total_pay')}</div>
                                        <div className="col-auto"><span className={styles['currency-number']}>{getCurrencyFormat(walletInfo.totalPay, wallet.currency)}</span><span className={styles['currency-label']}> {getLabel(wallet.currency)} </span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    const OrderTab = () => (
        <div className={`dash-col col-1 pt-0 ${styles['number-of-orders']}`}>
            <div className={`row ${styles['header-content']}`}>
                <div className={`${styles['number-of-orders-header']}`}><div className={styles['container-header']}>{t('dashboard.number_of_hex_order')}</div></div>
            </div>
            {orders?.map((order, index) => (
                <div className={`row ${styles['number-of-orders-content']}`} key={index}>
                    <div className={`col ${styles['order-name']}`}>{t(`dashboard.${order.serviceName}`)}</div>
                    <div className={`col ${styles['order-btn-container']} flex-wrap`}>
                        {
                            order?.orderCountItems?.map((orderItem, orderItemIndx) => {
                                return orderItem?.display ? <div key={orderItemIndx} className={`col col-xxl-3 col-lg-3 ${styles['order-button-col']}`}>
                                    <div className={`${styles['icon-button']}`} onClick={() => handleClickIcon(orderItem?.value, order.serviceName)}>
                                        <div >
                                            {orderIcons[orderItem?.key]}
                                        </div>
                                        <div className={`col ${styles['icon-label']}`}>
                                            <div className={styles['button-name']}>{t(orderItem?.key)}</div>
                                            <div className={`${styles['number-text-label']}`}>
                                                <span className={`${styles['number-label']}`}>{orderItem.count}</span>
                                                <span className={`${styles['subject-label']}`}>{t('dashboard.subject')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    : null
                            })
                        }
                    </div>
                </div>
            ))}
        </div>
    );

    const NotiTab = () => (
        <div className={`dash-col col-2 ${styles['receiver-noti-container']}`}>
            <div className={`row ${styles['receiver-container']}`}>
                <div className={`generic-sec pt-0 ${styles['inner-receiver-container']}`}>
                    <div className="row">
                        <div className={styles['receiver-header-label']}> <span>{t('dashboard.receiver')}</span></div>
                    </div>
                    <div className={`row ${styles['content-label']}`}>
                        <div className={`col-auto me-auto${styles['receiver-content-label']}`}>{t('dashboard.number_of_people')}</div>
                        <div className="col-auto"><span className={styles['receiver-number-label']}>{numberOfOrderedUser}</span><span className={styles['receiver-man-label']}>人</span></div>
                    </div>
                    <div className={`row ${styles['content-label']}`}>
                        <div className={`col-auto me-auto${styles['receiver-content-label']}`}>{t('dashboard.total_number_people')}</div>
                        <div className="col-auto"><span className={styles['receiver-number-label']}>{numberOfUser}</span><span className={styles['receiver-man-label']}>人</span></div>
                    </div>
                </div>
            </div>
            <div className={`row ${styles['message-noti-container']}`}>
                <div className={`col generic-sec pt-0 ${styles['message-noti']}`}>
                    <div className={`row ${styles['message-noti-row-header']}`}>
                        <div className={`col-auto ${styles['message-noti-header-label']}`}><span className={styles['container-header']}>{getLabel('[LABEL_MESSAGE]')} {getLabel('[LABEL_NOTIFICATION]')}</span></div>
                    </div>
                    <div className={`row ${styles['message-noti-row-content']}`}>
                        <div className={`col ${styles['inner-message-noti-row-content']}`}>
                            {notifications?.map((noti, index) => (
                                <div className={`row ${styles['message-noti-row']}`} key={index} data-bs-toggle="modal" data-bs-target="#alertsModal" data-noti-id={noti.subject} onClick={(event) => getNotification(event.target)}>
                                    <div className={`col-auto ${styles['message-noti-date']}`}><span>{moment(noti.alarmTime).format("yyyy-MM-DD")}</span>
                                    </div>
                                    <div className={`col-auto ${styles['message-noti-title']}`}><span className={styles['message-noti-title-label']}>{noti.messageText}</span></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`row ${styles['inquiry-contaier']}`}>
                <div className={`generic-sec pt-0 ${styles['inner-inquiry-container']}`}>
                    <div>
                        <div className={styles['receiver-header-label']}> <span>{t('dashboard.inquiry')}</span></div>
                    </div>
                    <div className={`row ${styles['content-label']}`}>
                        <div className={`col-auto me-auto${styles['receiver-content-label']} ${styles['inquiry-count-label']}`} onClick={handleClickInquiryCount}>{t('dashboard.requesting')}</div>
                        <div className={`col-auto ${styles['inquiry-count-content']}`} onClick={handleClickInquiryCount}><span className={styles['receiver-number-label']}>{inquiryCount}</span><span className={styles['receiver-man-label']}>{t('dashboard.object')}</span></div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={`col container-fluid ${styles['dashboard-container']}`}>
            <div>
                <div className={`container-fluid ${styles['dashboard-content']}`}>
                    {
                        full_name_user && <div className={styles['dashboard-header']}>
                            <span className={styles['header-welcome-user']}>{full_name_user ? full_name_user : ""} {t('dashboard.auxiliary_particle')} {currentTime}</span>
                            <span className={styles['header-date']}>{`${formattedDate} ${weekArray[moment().day()]}`}</span>
                        </div>
                    }
                    <div className="dash-col container-fluid">
                        <div className={` row ${styles['dashboard-first-row']}`}>
                            {OrderTab()}
                            {NotiTab()}
                        </div>
                    </div>
                    {Object.keys(walletInfo).length > 0 && WalletTab()}
                </div>
            </div>
        </div>
    );
}
function getNotification(name) {
    name = $(name).parent().attr('data-noti-id')
    getResourceByName(name, 'holon/', 'notifications')
}
export default DashboardContainer;