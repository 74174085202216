import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react';
import PrivacyPolicyContainer from 'shared/container/privacy-policy/PrivacyPolicyContainer';

function PrivacyPolicyPage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t("faq_management.Hex_personal_info");
    }, []);
    return <PrivacyPolicyContainer />;
}

export default PrivacyPolicyPage;
