import React, { useEffect, useState } from "react";
import useTrans from "hooks/use-trans";
import Box from "shared/components/form-input/box/Box";
import classes from "./OrcTable.module.scss";
import FormInput from 'shared/components/custom-form/FormInput';
import BoxBody from 'shared/components/common/box/BoxBody';
import Button from 'shared/components/form-input/button/Button';
import { useFieldArray, useForm } from "react-hook-form";
import ContentLayout from "shared/components/common/content-layout/ContentLayout";
import BoxRow from "shared/components/common/box/BoxRow";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import Constants from "utils/Constants";
import actionBPFare from 'redux/bp-fare/action';
import { useDispatch } from "react-redux";
import { showPopupNotification } from "utils/Utils";
import Validator from "utils/Validator";

const OrcTable = (props) => {
    const { bpFareUnitPrices, price } = props;
    const dispatch = useDispatch();
    const [header, setHeader] = useState([]);
    const { t } = useTrans()
    const [loading, setLoading] = useState(false)
    const methods = useForm();
    const control = methods.control;
    const { fields } = useFieldArray({
        control,
        name: "Ocr",
    });

    const uniqueArray = (arrayWithDuplicates) => arrayWithDuplicates.filter((obj, index, self) => {
        return (
            index ===
            self.findIndex(
                (item) =>
                    item.largeItemCode === obj.largeItemCode
            )
        );
    });

    const groupedByCategory = (arrayToGroup, _key = "mediumItemCode") =>
        arrayToGroup.reduce((result, item) => {
            const key = item[_key];
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(item);
            return result;
        }, {});

    useEffect(() => {
        let data = [];
        if (bpFareUnitPrices && price) {
            data = bpFareUnitPrices.map(x => {
                return {
                    ...x,
                    [x?.unitPriceCode]: Math.round(price[x?.unitPriceCode]),
                    price: Math.round(price[x?.unitPriceCode])
                }
            })

            let header = uniqueArray(data?.map(x => {
                return {
                    ...x,
                    key: x?.largeItemCode
                }
            }))?.sort((a, b) => {
                const nameA = a.largeItemCode.toLowerCase();
                const nameB = b.largeItemCode.toLowerCase();

                // Compare the names
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1;
                }
            });

            setHeader(header);
            let dataArray = Object.keys(groupedByCategory(data)).map(key => {
                return {
                    key: key,
                    data: groupedByCategory(data)[key],
                    mediumItemName: groupedByCategory(data)[key][0]?.mediumItemName,
                    mediumItemRemark: groupedByCategory(data)[key][0]?.mediumItemRemark,
                }
            })

            dataArray = dataArray.map(x => {
                return {
                    ...x,
                    data: x?.data?.reduce((obj, cur) => ({ ...obj, [cur?.largeItemCode]: cur }), {})
                }
            });

            dataArray = dataArray?.sort((a, b) => parseInt(a?.key) - parseInt(b?.key));
            methods.reset({
                Ocr: dataArray,
            });
        }

    }, [bpFareUnitPrices, price]);

    const onSubmit = (data) => {
        setLoading(true)
        let dataSubmit = [];
        data?.Ocr?.map(x => {
            header.map(h => {
                dataSubmit.push({
                    unitPriceCode: x?.data[h?.largeItemCode]?.unitPriceCode,
                    price: x?.data[h?.largeItemCode]?.[x?.data[h?.largeItemCode]?.unitPriceCode]
                });
                return h;
            })
            return x;
        });

        dispatch({
            type: actionBPFare.UPDATE_BP_FARE_BY_UNIT_PRICE_CODES,
            payload: {
                unitPriceCodes: dataSubmit
            },
            callback: {
                success: () => {
                    setLoading(false)
                    showPopupNotification({
                        callback: () => { },
                        typePopup: 'message',
                        newWindow: false,
                        message: t("E0043")
                    })
                },
                failed: (msg) => {
                    setLoading(false);
                    showPopupNotification({
                        typePopup: 'message',
                        message: msg
                    })
                }
            }
        })
    }

    let ignoreScrollEvents = false;

    const syncScroll = (...selectors) => {
        const wrapper = document.getElementById('product-left');
        if (wrapper && selectors.length > 1) {
            wrapper.addEventListener(
                'scroll',
                ({ target }) => {
                    if (target.classList.contains(selectors[0])) {
                        const ignore = ignoreScrollEvents;
                        ignoreScrollEvents = false;
                        if (ignore) {
                            return;
                        }
                        ignoreScrollEvents = true;
                        const { scrollLeft } = target;
                        selectors.forEach((selector) => {
                            if (wrapper.querySelector(`.${selector}`)) {
                                wrapper.querySelector(`.${selector}`).scrollLeft = scrollLeft;
                            }
                        });
                    }
                },
                true,
            );
        }
    };

    useEffect(() => {
        const executeSyncScroll = () => {
            syncScroll(
                '_custom_scroll_footer',
                '_custom_scroll_body',
                '_custom_scroll_header',
            );
            syncScroll(
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_footer',
            );
        };
        executeSyncScroll();
    }, []);

    return (
        <CustomFormProvider {...methods}>
            <form id="product-left" onSubmit={methods.handleSubmit(onSubmit)}>
                <ContentLayout>
                    <Box className={classes["freight-calculation-management"]}>
                        <BoxBody padding={0} style={{ gap: 0 , overflow: 'unset'}}>
                            <div className={`${classes['custom-table-edit-estimate']} _custom_scroll_body`}>
                                <table>
                                    <thead>
                                        {
                                            header?.length > 0 &&
                                            <tr className={classes['form-body']}>
                                                <th></th>
                                                <th></th>
                                                {header?.map((item, index) => (
                                                    <th key={`top_${index}`}>{item.largeItemName}</th>
                                                ))}
                                            </tr>
                                        }
                                        {
                                            header?.length > 0 &&
                                            <tr className={classes['form-body']}>
                                                <th>{t("bp_fare_table_management.ocr_table.name")}</th>
                                                <th>{t("bp_fare_table_management.ocr_table.supplement")}</th>
                                                {header?.map((item, index) => (
                                                    <th style={{ wordBreak: 'keep-all' }} key={`bottom_${index}`}>{item.largeItemRemark}</th>
                                                ))}
                                            </tr>
                                        }
                                    </thead>
                                    <tbody>
                                        {
                                            fields?.map((value, key) => {
                                                return <tr key={`table_${key}`} className={classes['form-body']}>
                                                    <td style={{ wordBreak: 'keep-all' }}>{value?.mediumItemName}</td>
                                                    <td style={{ wordBreak: 'keep-all' }}>{value?.mediumItemRemark}</td>
                                                    {header?.map((item, index) => (
                                                        <td key={`filed_${index}`}>
                                                            <FormInput
                                                                isTooltip
                                                                format={Constants.FormInputFormat.NUMBER.VALUE}
                                                                fieldName={`Ocr.${key}.data.${item?.largeItemCode}.${value?.data[item?.largeItemCode]?.unitPriceCode}`}
                                                                validate={[Validator.number]}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    position: 'sticky',
                                    zIndex: 11,
                                    maxHeight: 15,
                                    overflowY: 'hidden',
                                    bottom: 0,
                                }}
                                className={`${classes['custom-table-edit-estimate']} ${classes['custom-table-edit-estimate-footer']} _custom_scroll_footer`}>
                                <table>
                                    <thead>
                                        {
                                            header?.length > 0 &&
                                            <tr className={classes['form-body']}>
                                                <th></th>
                                                <th></th>
                                                {header?.map((item, index) => (
                                                    <th key={`top_${index}`}>{item.largeItemName}</th>
                                                ))}
                                            </tr>
                                        }
                                    </thead>
                                </table>
                            </div>
                        </BoxBody>
                    </Box>
                    <BoxRow style={{ flex: 1, justifyContent: "center" }}>
                        <Button title={t("keep")} type='submit' loading={loading} />
                    </BoxRow>
                </ContentLayout>
            </form>
        </CustomFormProvider>
    );
}

export default OrcTable;
