import React from 'react'

function IconClose({ fontSize = 37, color = '#FFFFFF' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 37 37"
            fill="none"
            fontSize={fontSize}
        >
            <path d="M9.19238 9.19238L27.5772 27.5772" stroke={color} strokeWidth="3" strokeLinecap="round" />
            <path d="M9.19238 27.5771L27.5772 9.19237" stroke={color} strokeWidth="3" strokeLinecap="round" />
        </svg>
    )
}

export default IconClose