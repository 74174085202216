const MenuManagementAction = {

    GET_MENU_MANAGEMENT : "GET_MENU_MANAGEMENT",
    GET_MENU_MANAGEMENT_SUCCESS : "GET_MENU_MANAGEMENT_SUCCESS",
    GET_MENU_MANAGEMENT_FAILED : "GET_MENU_MANAGEMENT_FAILED",


    GET_HOLON_TEMPLATE_MENU_MANAGEMENT : "GET_HOLON_TEMPLATE_MENU_MANAGEMENT",
    GET_HOLON_TEMPLATE_MENU_MANAGEMENT_SUCCESS : "GET_HOLON_TEMPLATE_MENU_MANAGEMENT_SUCCESS",
    GET_HOLON_TEMPLATE_MENU_MANAGEMENT_FAILED : "GET_HOLON_TEMPLATE_MENU_MANAGEMENT_FAILED",


    UPDATE_PERMISTION_MENU_MANAGEMENT : "UPDATE_PERMISTION_MENU_MANAGEMENT",
    UPDATE_PERMISTION_MENU_MANAGEMENT_SUCCESS : "UPDATE_PERMISTION_MENU_MANAGEMENT_SUCCESS",
    UPDATE_PERMISTION_MENU_MANAGEMENT_FAILED : "UPDATE_PERMISTION_MENU_MANAGEMENT_FAILED",

    GET_MENU_MANAGEMENT_BY_CODE : "GET_MENU_MANAGEMENT_BY_CODE",
    GET_MENU_MANAGEMENT_BY_CODE_SUCCESS : "GET_MENU_MANAGEMENT_BY_CODE_SUCCESS",
    GET_MENU_MANAGEMENT_BY_CODE_FAILURE : "GET_MENU_MANAGEMENT_BY_CODE_FAILURE",

    CREATE_MENU_MANAGEMENT : "CREATE_MENU_MANAGEMENT",
    CREATE_MENU_MANAGEMENT_SUCCESS : "CREATE_MENU_MANAGEMENT_SUCCESS",
    CREATE_MENU_MANAGEMENT_FAILURE : "CREATE_MENU_MANAGEMENT_FAILURE",

    UPDATE_MENU_MANAGEMENT : "UPDATE_MENU_MANAGEMENT",
    UPDATE_MENU_MANAGEMENT_SUCCESS : "UPDATE_MENU_MANAGEMENT_SUCCESS",
    UPDATE_MENU_MANAGEMENT_FAILURE : "UPDATE_MENU_MANAGEMENT_FAILURE",

    UPDATE_SORT_MENU_MANAGEMENT : "UPDATE_SORT_MENU_MANAGEMENT",
    UPDATE_SORT_MENU_MANAGEMENT_SUCCESS : "UPDATE_SORT_MENU_MANAGEMENT_SUCCESS",
    UPDATE_SORT_MENU_MANAGEMENT_FAILURE : "UPDATE_SORT_MENU_MANAGEMENT_FAILURE",

    DELETE_MENU_MANAGEMENT : "DELETE_MENU_MANAGEMENT",
    DELETE_MENU_MANAGEMENT_SUCCESS : "DELETE_MENU_MANAGEMENT_SUCCESS",
    DELETE_MENU_MANAGEMENT_FAILURE : "DELETE_MENU_MANAGEMENT_FAILURE",
}

export default MenuManagementAction;