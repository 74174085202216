import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useFormContext,
} from "react-hook-form";
import useTrans from "hooks/use-trans";
import actionAddress from "redux/address/action";
import Validator from "utils/Validator";
import Constants from "utils/Constants";
import FormSelect from "shared/components/custom-form/FormSelect";
import FormInput from "shared/components/custom-form/FormInput";
import PropTypes from 'prop-types';
import BoxRow from "shared/components/common/box/BoxRow";
import BoxLabel from "shared/components/common/box/BoxLabel";
import BoxContent from "shared/components/common/box/BoxContent";
import BoxBody from "shared/components/common/box/BoxBody";

function FormAddressPort(props) {
    const { fieldName, portType } = props;
    const { continents, countries, ports } = useSelector((state) => state.address);
    const { trans } = useTrans();
    const dispatch = useDispatch();
    const {
        setValue,
        watch,
        getValues,
        clearErrors,
    } = useFormContext();

    const data = useRef({
        0: '', //continent
        1: '', //country
        2: '', //port
        3: '', //address code
        4: '', //address main
        5: '', //address building
        6: '', // address chomemibango
    })

    const watchFieldName = watch(fieldName)
    const watchContinent = watch(`${fieldName}_tmp.0`);
    const watchCountry = watch(`${fieldName}_tmp.1`);

    const continentOptions = Array.isArray(continents) ? continents.map((continent) => {
        return {
            value: continent?.continentId,
            label: continent?.continentName,
        };
    }) : [];

    const countryOptions = Array.isArray(countries) ? countries.map((country) => {
        return {
            value: country?.countryId,
            label: country?.countryName,
        };
    }) : [];

    const portOptions = Array.isArray(ports) ? ports.map((port) => {
        return {
            value: port?.portId,
            label: port?.portName,
        };
    }) : [];

    useEffect(() => {
        setValue(`${fieldName}`, Object.values(data.current).join("|"))

        dispatch({
            type: actionAddress.GET_CONTINENTS,
        });


    }, []);

    useEffect(() => {
        if (fieldName && watchFieldName) {
            setValue(`${fieldName}_tmp`, watchFieldName?.toString()?.split('|'))
            data.current = watchFieldName?.toString()?.split('|')
        }
    }, [watchFieldName, fieldName]);

    useEffect(() => {
        const continent = getValues(`${fieldName}_tmp.0`);

        if (continent) {
            // setValue(`${fieldName}_tmp.1`, null);
            // onChange('', 1);
            dispatch({
                type: actionAddress.GET_COUNTRIES_BY_CONTINENT_ID,
                payload: continent,
            });
        }
    }, [watchContinent]);

    useEffect(() => {
        const countryId = getValues(`${fieldName}_tmp.1`);
        if (countryId) {
            dispatch({
                type: actionAddress.GET_PORTS_BY_PORT_TYPE,
                portType,
                countryId
            });
        }
    }, [watchCountry])

    const getAddress = (postal_code) => {
        const country_id = getValues(`${fieldName}_tmp`)?.[1];
        if (postal_code.length >= 5 && country_id) {
            // const formattedString = postal_code.substring(0, 3) + "-" + postal_code.substring(3);
            dispatch({
                type: actionAddress.GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID,
                payload: {
                    postal_code: postal_code,
                    country_id: country_id,
                },
                callback: (data) => {
                    setValue(`${fieldName}_tmp.4`, data["addressMain"]);
                    if (data?.["addressMain"]) {
                        onChange(data["addressMain"], 4)
                        clearErrors(`${fieldName}_tmp.4`, data["addressMain"]);
                    }
                },
            });
        }
    };

    const onChange = (value, index) => {
        data.current[index] = value
        let _tmp = Object.values(data.current).join("|");
        setValue(`${fieldName}`, _tmp);
    };

    const resetValue = (index) => {
        // setValue(`${fieldName}_tmp.1`, null);
        // onChange('', 1);
        // setValue(`${fieldName}_tmp.2`, null);
        // onChange('', 2);
        // setValue(`${fieldName}_tmp.3`, null);
        // onChange('', 3);
        // setValue(`${fieldName}_tmp.4`, null);
        // onChange('', 4);
        // setValue(`${fieldName}_tmp.5`, null);
        // onChange('', 5);
        // setValue(`${fieldName}_tmp.6`, null);
        // onChange('', 6);
        for (let i = index; i <= 6; i++) {
            setValue(`${fieldName}_tmp.${i}`, null);
            onChange('', i);
        }
    }

    let minWidth = 70;
    return (
        <>
            <BoxBody className={props?.className}>
                <BoxRow>
                    <BoxLabel isRequired minWidth={minWidth}>{trans('continent')}</BoxLabel>
                    <BoxContent>
                        <FormSelect
                            options={continentOptions ?? []}
                            fieldName={`${fieldName}_tmp.0`}
                            validate={[Validator.required]}
                            isRegexLabel={trans('continent')}
                            validationFE
                            placeholder={trans('placeholder_continent')}
                            onChangeSelect={(e) => {
                                onChange(e, 0);
                                resetValue(1);
                                // dispatch({
                                //     type: actionAddress.GET_COUNTRIES_BY_CONTINENT_ID,
                                //     payload: watchContinent,
                                // });
                            }}
                        />
                    </BoxContent>
                </BoxRow>
                <BoxRow>
                    <BoxLabel isRequired minWidth={minWidth}>{trans('country')}</BoxLabel>
                    <BoxContent>
                        <FormSelect
                            options={countryOptions ?? []}
                            fieldName={`${fieldName}_tmp.1`}
                            validate={[Validator.required]}
                            isRegexLabel={trans('country')}
                            validationFE
                            placeholder={trans('placeholder_country')}
                            onChangeSelect={(e) => {
                                onChange(e, 1);
                                resetValue(2);
                            }}
                        />
                    </BoxContent>
                </BoxRow>
                <BoxRow>
                    <BoxLabel isRequired minWidth={minWidth}>{portType == Constants.PORT_TYPE.AIRPORT ? trans('airport') : trans('seaport')}</BoxLabel>
                    <BoxContent>
                        <FormSelect
                            options={portOptions ?? []}
                            fieldName={`${fieldName}_tmp.2`}
                            validate={[Validator.required]}
                            isRegexLabel={portType == Constants.PORT_TYPE.AIRPORT ? trans('airport') : trans('seaport')}
                            validationFE
                            placeholder={portType == Constants.PORT_TYPE.AIRPORT ? trans('placeholder_airport') : trans('placeholder_seaport')}
                            onChangeSelect={(e) => {
                                onChange(e, 2);
                                // resetValue(3);
                            }}
                        />
                    </BoxContent>
                </BoxRow>
                <BoxRow>
                    <BoxLabel minWidth={minWidth}>{trans('address_code')}</BoxLabel>
                    <BoxContent>
                        <FormInput
                            fieldName={`${fieldName}_tmp.3`}
                            // validate={[Validator.required]}
                            // isRegexLabel={trans('address_code')}
                            // validationFE
                            // callback={getAddress}
                            placeholder={trans('placeholder_address_code')}
                            onChangeValue={(e) => {
                                getAddress(e);
                                onChange(e, 3);
                            }}
                        />
                    </BoxContent>
                </BoxRow>
                <BoxRow>
                    <BoxLabel minWidth={minWidth}>{trans('address_main')}</BoxLabel>
                    <BoxContent>
                        <FormInput
                            fieldName={`${fieldName}_tmp.4`}
                            // validate={[Validator.required]}
                            // isRegexLabel={trans('address_main')}
                            // validationFE
                            placeholder={trans('placeholder_address_main')}
                            onChangeValue={(e) => {
                                onChange(e, 4);
                            }}
                        />
                    </BoxContent>
                </BoxRow>
                <BoxRow>
                    <BoxLabel minWidth={minWidth}>{trans('address_building')}</BoxLabel>
                    <BoxContent>
                        <FormInput
                            fieldName={`${fieldName}_tmp.5`}
                            // validate={[Validator.required]}
                            // isRegexLabel={trans('address_building')}
                            // validationFE
                            placeholder={trans('placeholder_address_building')}
                            onChangeValue={(e) => {
                                onChange(e, 5);
                            }}
                        />
                    </BoxContent>
                </BoxRow>
                <BoxRow>
                    <BoxLabel minWidth={minWidth}>{trans('address_chomebanchigo')}</BoxLabel>
                    <BoxContent>
                        <FormInput
                            fieldName={`${fieldName}_tmp.6`}
                            // validate={[Validator.required]}
                            // isRegexLabel={trans('address_chomebanchigo')}
                            // validationFE
                            placeholder={trans('placeholder_address_chomebanchigo')}
                            onChangeValue={(e) => {
                                onChange(e, 6);
                            }}
                        />
                    </BoxContent>
                </BoxRow>
            </BoxBody>
        </>
    );
}

FormAddressPort.defaultProps = {
    fieldName: 'fieldName',
    portType: '0',
    placeholder: '',
};
FormAddressPort.propTypes = {
    fieldName: PropTypes.string,
    portType: PropTypes.string,
};

// portType: 0:AIR, 1:SEA


export default FormAddressPort;
