import React, { useEffect, useState } from 'react'
import classes from './CreateHolonTemplate.module.scss';
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormInput from 'shared/components/custom-form/FormInput';
import { useFieldArray, useForm } from 'react-hook-form';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import Button from 'shared/components/form-input/button/Button';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import { Close } from '@mui/icons-material';
import Constants from 'utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import actionHolonTemplate from 'redux/holon-template/action';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_POPUP_CONFIRM_UPDATE, EVENT_SHOW_POPUP_DETAIL_HOLON_TEMPLATE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import useTrans from 'hooks/use-trans';
import useRouter from 'hooks/use-router';
import Utils, { showPopupNotification } from 'utils/Utils';
import Validator from 'utils/Validator';
import WrapContentBody from '../custom-body/WrapContentBody';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid'
import IconDropDrap from 'assets/icon/IconDropdrap';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import CustomBox from '../../box/Box';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import IconPlusYellow from 'assets/icon/IconPlusYellow';
import BoxContentPopup from '../custom-body/BoxContentPopup';

let grid = 1;
const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "",
    // padding: grid,
    // width: 250
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const Box = ({ title, showButton = true, children, onClick }) => {
    return <div className={classes['Box']}>
        <div className={classes['Title_Box']}>
            <p>
                {title}
            </p>
            {
                showButton && <div onClick={(e) => onClick && onClick(e)} className={classes['Icon']}>
                    <IconPlusWhite />
                </div>
            }
        </div>
        <div className={classes['Content_Box']}>{children}</div>
    </div>
}
function UpdateHolonTemplate(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const control = methods.control;
    const dispatch = useDispatch();
    const { t } = useTrans();
    const router = useRouter();

    const [holonTemplateDetail, setHolonTemplateDetail] = useState(null)
    const [loadingUpdateTemplate, setLoadingUpdateTemplate] = useState(false);

    const { categories, actionType } = useSelector(state => state.holonTemplate)

    const {
        append: appendHas,
        fields: fieldsHas,
        remove: removeHas,
    } = useFieldArray({
        control, name: "attributes"
    });

    const {
        append: appendActions,
        fields: fieldsActions,
        remove: removeActions,
    } = useFieldArray({
        control, name: "actions"
    })

    const {
        append: appendConnects,
        fields: fieldsConnects,
        remove: removeConnects,
    } = useFieldArray({
        control, name: "connections"
    })

    useEffect(() => {
        if (payload?.holonTemplateId) {
            dispatch({
                type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP,
                payload: payload?.holonTemplateId,
                callback: {
                    success: (data) => {
                        setHolonTemplateDetail(data);
                    }
                }
            });
        }
    }, [payload?.holonTemplateId])

    const onSubmit = (data) => {
        let attributes = data?.attributes?.map(x => {
            return {
                ...x,
                isRequired: x?.isRequired ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isMultiSelected: x?.isMultiSelected ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isEditabled: x?.isEditabled ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                id: x?.id || null,
                elementType: x?.elementType ?? Constants.ELEMENT_TYPE.TEXT.VALUE
            }
        })
        let _connections = data?.connections?.map(x => {
            return {
                ...x,
                isRequired: x?.isRequired ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isMultiSelected: x?.isMultiSelected ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isEditabled: x?.isEditabled ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                id: x?.id || null
            }
        })
        let actions = data?.actions?.map(x => {
            return {
                ...x,
                id: x?.id || null
            }
        })
        let params = {
            id: holonTemplateDetail?.id,
            category: data?.category || "",
            name: data?.name || "",
            status: data?.status || Constants.STATUS_CATEGORY.PUBLIC.VALUE,
            note: data?.note || "",
            library: data?.library || "",
            attributes: attributes,
            actions: actions,
            connections: _connections,
        }

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                // title: t("Holon_Template.confirm_update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoadingUpdateTemplate(true);
                    dispatch({
                        type: actionHolonTemplate.UPDATE_HOLON_TEMPLATE_MANAGEMENT,
                        payload: params,
                        callback: {
                            success: () => {
                                setLoadingUpdateTemplate(false);
                                payload?.callback();
                                showVisible(false);
                                showPopupNotification({
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    typePopup: 'message',
                                    message: t("E0043"),
                                })
                                dispatch({
                                    type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT,
                                    payload: {
                                        ...router.getAll()
                                    }
                                })
                            },
                            failed: (msg) => {
                                setLoadingUpdateTemplate(false);
                                showPopupNotification({
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    typePopup: 'message',
                                    message: msg,
                                    newWindow: true,
                                })
                            }
                        }
                    })
                }
            }
        })

    }

    let unitRotation = [
        {
            value: "s",
            key: "s",
            label: "S",
        },
        {
            value: "day",
            key: "day",
            label: "Day",
        },
        {
            value: "minute",
            key: "minute",
            label: "Minute",
        },
        {
            value: "hour",
            key: "hour",
            label: "Hour",
        }
    ]

    let statusTemplate = [
        {
            value: Constants.STATUS_CATEGORY.PUBLIC.VALUE,
            key: Constants.STATUS_CATEGORY.PUBLIC.VALUE,
            label: t("Holon_Template.valid"),
        },
        {
            value: Constants.STATUS_CATEGORY.PRIVATE.VALUE,
            key: Constants.STATUS_CATEGORY.PRIVATE.VALUE,
            label: t("Holon_Template.invalid"),
        }
    ]

    useEffect(() => {
        if (holonTemplateDetail?.id) {
            let attributes = holonTemplateDetail.attributes?.map(x => {
                return {
                    ...x,
                    isRequired: x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isMultiSelected: x?.isMultiSelected == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isEditabled: x?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                }
            })
            let connections = holonTemplateDetail.connections?.map(x => {
                return {
                    ...x,
                    isRequired: x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isMultiSelected: x?.isMultiSelected == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isEditabled: x?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                }
            })
            holonTemplateDetail.attributes = attributes;
            holonTemplateDetail.connections = connections;
            methods.reset(holonTemplateDetail)
        } else {
            methods.reset({})
        }

        return () => {
            methods.reset({})
        }
    }, [holonTemplateDetail?.id])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let items = reorder(
            methods.watch("attributes"),
            result.source.index,
            result.destination.index
        );

        items = items?.map((x, index) => {
            return {
                ...x,
                sortNo: index
            }
        })
        methods.setValue("attributes", items)
    }

    const handleGotoDetail = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_DETAIL_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                title: t("Holon_Template.holon_template_detail"),
                holonTemplateId: payload?.holonTemplateId,
                callback: () => {
                    dispatch({
                        type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT,
                        payload: {
                            ...router.getAll()
                        }
                    })
                }
            }
        })
    }
    return (
        <BoxContentPopup width='1000px' borderRadius="20px 0px 0px 20px">
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        title={payload?.title}
                        showVisible={showVisible}
                        handleBtnBack={() => {
                            handleGotoDetail()
                        }}
                        actionSectionBottom={<Button loading={loadingUpdateTemplate} type='submit' color='gray' title='保存' />}
                    >
                        <div className={classes['create-holon-template']}>
                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.basic_information")}</BoxTitle>
                                </BoxHeader>
                                <BoxBody>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.category")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormSelect validationFE textTrans='Holon_Template.field_name' placeholder={t("Holon_Template.category")} fieldName='category' options={Object.keys(categories)?.map(key => {
                                                return {
                                                    value: key,
                                                    key: key,
                                                    label: categories[key]
                                                }
                                            })} validate={[Validator.required]} />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.template_name")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormInput validationFE textTrans='Holon_Template.field_name' validate={[Validator.required, Validator.maxLength(200)]} placeholder={t("Holon_Template.template_name")} fieldName="name" />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.status")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormSelect validationFE textTrans='Holon_Template.field_name' validate={[Validator.required]} options={statusTemplate} placeholder={t("Holon_Template.status")} fieldName='status' />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.explanation")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormInput placeholder={t("Holon_Template.please_enter")} validate={[Validator.maxLength(500)]} fieldName='note' />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.library")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormSelect placeholder={t("Holon_Template.please_select")} fieldName='library' />
                                        </BoxContent>
                                    </BoxRow>
                                </BoxBody>
                            </CustomBox>
                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.attribute")}</BoxTitle>
                                    <div style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }} onClick={() => {
                                        appendHas({
                                            key: "",
                                            explain: "",
                                            label: "",
                                            regex: "",
                                            msg: "",
                                            defaultValue: "",
                                            isRequired: false,
                                            isMultiSelected: false,
                                            isEditabled: false,
                                            reader: [],
                                            writer: [],
                                            elementType: null,
                                            idCreated: uuidv4(),
                                            sortNo: fieldsHas?.length + 1
                                        })
                                    }}>
                                        <IconPlusYellow />
                                    </div>
                                </BoxHeader>
                                <BoxBody padding={0}>
                                    <div className={classes['custom-table']}>
                                        <table>
                                            <thead>
                                                <tr className={classes['form-header']}>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.key")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.key_name")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explanation")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.regex")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.default_value")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.message_key")}</th>
                                                    <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.require")}</th>
                                                    <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.multiple_allowed")}</th>
                                                    {/* <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.editable")}</th> */}
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.element_type")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-action']}`}>{t("Holon_Template.delete")} </th>
                                                    <th className={`${classes['head-title']} ${classes['button-sort']}`}></th>
                                                </tr>
                                            </thead>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable direction='vertical' droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <tbody
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                            className='overflow-y-auto'
                                                        >
                                                            {
                                                                fieldsHas?.map((x, key) => {
                                                                    return <Draggable key={x?.id} draggableId={x?.id} index={key}>
                                                                        {(provided, snapshot) => (
                                                                            <tr
                                                                                ref={provided.innerRef}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                key={x?.idCreated}
                                                                                className={classes['form-body']}>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.key")} validate={[Validator.required, Validator.stringKey, Validator.maxLength(50)]} fieldName={`attributes.${key}.key`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.key_name")} validate={[Validator.required, Validator.maxLength(1000)]} fieldName={`attributes.${key}.label`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.explanation")} validate={[Validator.maxLength(1000)]} fieldName={`attributes.${key}.explain`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.regex")} validate={[Validator.maxLength(1000)]} fieldName={`attributes.${key}.regex`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.default_value")} validate={[Validator.maxLength(1000)]} fieldName={`attributes.${key}.defaultValue`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.message_key")} validate={[Validator.maxLength(1000)]} fieldName={`attributes.${key}.msg`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormCheckbox fieldName={`attributes.${key}.isRequired`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormCheckbox fieldName={`attributes.${key}.isMultiSelected`} />
                                                                                </td>
                                                                                {/* <td>
                                                                                    <FormCheckbox fieldName={`attributes.${key}.isEditabled`} />
                                                                                </td> */}
                                                                                <td>
                                                                                    <FormSelect options={Utils.convertObjectKeyToArr(Constants.ELEMENT_TYPE, "NAME", "VALUE", "Holon_Template.list_element_type.")} fieldName={`attributes.${key}.elementType`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormSelectTemplate masterCode="READER_WRITER" fieldName={`attributes.${key}.reader`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormSelectTemplate masterCode="READER_WRITER" fieldName={`attributes.${key}.writer`} />
                                                                                </td>
                                                                                <td className={classes['button-action-remove']} onClick={() => removeHas(key)}>
                                                                                    <IconMinusAccordion />
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: '100%', cursor: 'grab' }}>
                                                                                        <IconDropDrap />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </Draggable>
                                                                })
                                                            }
                                                            {provided.placeholder}
                                                        </tbody>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </table>
                                    </div>
                                </BoxBody>
                            </CustomBox>
                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.action")}</BoxTitle>
                                    <div style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }} onClick={() => {
                                        appendActions({
                                            key: "",
                                            note: "",
                                            inputValue: "",
                                            outputValue: "",
                                            timeout: "0",
                                            unit: "s",
                                            api: "",
                                            reader: [],
                                            writer: [],
                                            idCreated: fieldsActions?.length + 1,
                                            type: "",
                                            actionType: "0"
                                        })
                                    }}>
                                        <IconPlusYellow />
                                    </div>
                                </BoxHeader>
                                <BoxBody padding={0}>
                                    <div className={classes['custom-table']}>
                                        <table>
                                            <thead>
                                                <tr className={classes['form-header']}>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.action_name")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explanation")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.type")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.input")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.output")}</th>
                                                    <th style={{ minWidth: 90 }} className={`${classes['head-title']}`}>{t("Holon_Template.timeout")}</th>
                                                    <th style={{ minWidth: 50 }} className={`${classes['head-title']}`}>{t("Holon_Template.unit")}</th>
                                                    <th style={{ minWidth: 70 }} className={`${classes['head-title']}`}>{t("Holon_Template.api")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.action_type")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-action']}`}>{t("Holon_Template.delete")} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fieldsActions?.map((x, key) => {
                                                        return <tr key={x?.id} className={classes['form-body']}>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.action_name")} validate={[Validator.required, Validator.maxLength(50), Validator.stringKey]} fieldName={`actions.${key}.key`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.explanation")} validate={[Validator.maxLength(200)]} fieldName={`actions.${key}.note`} />
                                                            </td>
                                                            <td>
                                                                <FormSelect options={Utils.convertObjectKeyToArr(Constants.TYPE_ACTIONS, "NAME", "VALUE", "Holon_Template.actions.")} fieldName={`actions.${key}.type`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.input")} validate={[Validator.required, Validator.maxLength(100)]} fieldName={`actions.${key}.inputValue`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.output")} validate={[Validator.required, Validator.maxLength(100)]} fieldName={`actions.${key}.outputValue`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.timeout")} validate={[Validator.maxLength(10)]} format={Constants.FormInputFormat.NUMBER.VALUE} fieldName={`actions.${key}.timeout`} />
                                                            </td>
                                                            <td>
                                                                <FormSelect options={unitRotation} fieldName={`actions.${key}.unit`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.api")} validate={[Validator.required, Validator.maxLength(200)]} fieldName={`actions.${key}.api`} />
                                                            </td>
                                                            <td>
                                                                <FormSelect options={Object.keys(actionType).map(key => ({ value: key?.toString(), label: actionType[key] }))} fieldName={`actions.${key}.actionType`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate masterCode="READER_WRITER" fieldName={`actions.${key}.reader`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate masterCode="READER_WRITER" fieldName={`actions.${key}.writer`} />
                                                            </td>
                                                            <td className={classes['button-action-remove']} onClick={() => removeActions(key)}>
                                                                <IconMinusAccordion />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </BoxBody>
                            </CustomBox>
                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.connect")}</BoxTitle>
                                    <div style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }} onClick={() => {
                                        appendConnects({
                                            key: "",
                                            note: "",
                                            isRequired: false,
                                            isMultiSelected: false,
                                            isEditabled: false,
                                            reader: [],
                                            writer: [],
                                            holonTemplate: [],
                                            idCreated: fieldsConnects?.length + 1
                                        })
                                    }}>
                                        <IconPlusYellow />
                                    </div>
                                </BoxHeader>
                                <BoxBody padding={0}>
                                    <div className={classes['custom-table']}>
                                        <table>
                                            <thead>
                                                <tr className={classes['form-header']}>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.key")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explanation")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.holon_template")}</th>
                                                    <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.multiple_allowed")}</th>
                                                    <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.require")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-action']}`}>{t("Holon_Template.delete")} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fieldsConnects.map((x, key) => {
                                                        return <tr key={x?.idCreated} className={classes['form-body']}>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.key")} validate={[Validator.required, Validator.maxLength(1000), Validator.stringKey]} fieldName={`connections.${key}.key`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.explanation")} validate={[Validator.maxLength(1000)]} fieldName={`connections.${key}.note`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate fieldName={`connections.${key}.holonTemplate`} />
                                                            </td>
                                                            <td>
                                                                <FormCheckbox fieldName={`connections.${key}.isMultiSelected`} />
                                                            </td>
                                                            <td>
                                                                <FormCheckbox fieldName={`connections.${key}.isRequired`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate masterCode="READER_WRITER" fieldName={`connections.${key}.reader`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate masterCode="READER_WRITER" fieldName={`connections.${key}.writer`} />
                                                            </td>
                                                            <td className={classes['button-action-remove']} onClick={() => removeConnects(key)}>
                                                                <IconMinusAccordion />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </BoxBody>
                            </CustomBox>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default UpdateHolonTemplate