import actionWareHouseIn from "./action";

const initialState = {
    loadingListWarehouseIn: false,
    loadingCreateWarehouseIn: false,
    loadingUpdateWarehouseIn: false,
    loadingWarehouseDetail: false,
    listWarehouseIn: {},
    holonTemplateDetail: {}
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionWareHouseIn.GET_WAREHOUSE_IN_LIST:
            return {
                ...state,
                loadingListWarehouseIn: true
            }
        case actionWareHouseIn.GET_WAREHOUSE_IN_LIST_SUCCESS:
            return {
                ...state,
                loadingListWarehouseIn: false,
                listWarehouseIn: action.payload
            }
        case actionWareHouseIn.GET_WAREHOUSE_IN_LIST_FAILED:
            return {
                ...state,
                loadingListWarehouseIn: false
            }
        case actionWareHouseIn.GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN_SUCCESS:
            return {
                ...state,
                holonTemplateDetail: action.payload
            }
        case actionWareHouseIn.CREATE_WAREHOUSE:
            return {
                ...state,
                loadingCreateWarehouseIn: true
            }
        case actionWareHouseIn.CREATE_WAREHOUSE_IN_FAILED:
            return {
                ...state,
                loadingCreateWarehouseIn: false
            }
        case actionWareHouseIn.CREATE_WAREHOUSE_IN_SUCCESS:
            return {
                ...state,
                loadingCreateWarehouseIn: false
            }
        case actionWareHouseIn.UPDATE_WAREHOUSE_IN:
            return {
                ...state,
                loadingUpdateWarehouseIn: true
            }
        case actionWareHouseIn.UPDATE_WAREHOUSE_IN_FAILED:
            return {
                ...state,
                loadingUpdateWarehouseIn: false
            }
        case actionWareHouseIn.UPDATE_WAREHOUSE_IN_SUCCESS:
            return {
                ...state,
                loadingUpdateWarehouseIn: false
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;