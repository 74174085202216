import React, { useEffect, useState } from 'react'
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import BoxRow from '../../box/BoxRow';
import BoxContent from '../../box/BoxContent';
import CommonText from '../../text/CommonText';
import BoxLabel from '../../box/BoxLabel';
import Box from '../../box/Box';
import Constants from 'utils/Constants';
import { CommonRowTableHolonInstance } from 'shared/components/holon-instance/CommonDataFilter';
import IconRowRight from 'assets/icon/IconRowRight';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import classes from './HistoryDetail.module.scss';

function HistoryDetailPopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const [dataAttributes, setDataAttributes] = useState([])
    const [dataConnection, setDataConnection] = useState([])
    const [dataAttachment, setDataAttachment] = useState({
        value: {},
        valueChange: {}
    })
    const empty = t("holon.empty")
    useEffect(() => {
        let obj = payload?.data[payload?.index];
        let objCompare = payload?.data[payload?.index - 1];
        let attributeTemplate = payload?.holonTemplate?.validAttributes?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur }), {});

        let attributes = null;
        let connections = null;
        let attachments = null;
        try {
            attributes = JSON.parse(obj?.details)?.attributes;
            connections = JSON.parse(obj?.details)?.connections;
            attachments = JSON.parse(obj?.details)?.attachments;
        } catch (error) {
            attributes = {};
            connections = [];
            attachments = {};
        }

        let attributesCompare = null;
        let connectionCompare = null;
        let attachmentCompare = null;
        if (payload?.index == payload?.data?.length - 2) {
            attributesCompare = JSON.parse(objCompare?.details)?.attributes;
            connectionCompare = JSON.parse(objCompare?.details)?.connections;
            attachmentCompare = JSON.parse(objCompare?.details)?.attachments;
        } else {
            try {
                attributesCompare = JSON.parse(objCompare?.details)?.attributes;
                connectionCompare = JSON.parse(objCompare?.details)?.connections;
                attachmentCompare = JSON.parse(objCompare?.details)?.attachments;
            } catch (error) {
                attributesCompare = [];
                connectionCompare = [];
                attachmentCompare = {};
            }
        }

        let dataAttributes = [];
        if (typeof attributes == 'undefined' || typeof attributesCompare == 'undefined') {
            return;
        }

        Object.keys(attributes).map(key => {
            if (attributeTemplate[key]?.label) {
                if (attributesCompare[key] == undefined ||
                    attributesCompare[key] == null) {
                    attributesCompare[key] = "";
                }
                if (attributes[key] == undefined ||
                    attributes[key] == null) {
                    attributes[key] = "";
                }
                
                if (attributesCompare[key] != attributes[key]) {
                    dataAttributes.push({
                        label: attributeTemplate[key]?.label,
                        value: payload?.index == 0 && attributeTemplate[key]?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE ?
                            <>{empty}</>
                            : CommonRowTableHolonInstance({
                                attributeHeader: attributeTemplate[key],
                                attributes: Object.keys(attributes).map(_k => {
                                    return { value: attributes[_k], key: _k ,}
                                })?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur }), {}),
                                isCompare: true
                            }),
                        valueChange: CommonRowTableHolonInstance({
                            attributeHeader: attributeTemplate[key],
                            attributes: Object.keys(attributesCompare).map(_k => {
                                return { value: attributesCompare[_k], key: _k }
                            })?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur }), {}),
                            isCompare: true
                        })
                    })
                }
            }
        })

        let dataConnections = [];
        if (payload?.holonTemplate?.connections?.length > 0) {
            payload?.holonTemplate?.connections?.map(item => {
                let value = [];
                let valueChange = [];
                let data = connections[item?.key]?.filter(con => con?.id != payload?.holonInstanceId);
                let dataCompare = connectionCompare[item?.key]?.filter(con => con?.id != payload?.holonInstanceId);
                let map = false;
                if (dataCompare?.length > 0) {
                    dataCompare?.map(x => {
                        if (map) {
                            return;
                        }
                        let index = data?.findIndex(v => v?.id == x?.id);
                        if (index == -1) {
                            valueChange = dataCompare;
                            value = data;
                            map = true;
                        }

                        if (typeof index == 'undefined') {
                            if (x) {
                                valueChange.push(x)
                            }
                            if (data && data[index]) {
                                value.push(data[index])
                            }
                        }
                    });
                } else
                    if (data?.length > 0 && !dataCompare) {
                        value = data;
                    }
                if (value?.length != 0 || valueChange?.length != 0) {
                    dataConnections.push({
                        label: item?.note,
                        value: value,
                        valueChange: valueChange
                    })
                }
            });
        }

        setDataConnection(dataConnections);

        if ((attachments && Object.keys(attachments ?? {})?.length) !=
            (attachmentCompare && Object.keys(attachmentCompare ?? {})?.length)) {
            setDataAttachment({
                value: attachments,
                valueChange: attachmentCompare
            });
        } else {
            let checked = false;
            (attachmentCompare && Object.keys(attachmentCompare ?? {}))?.map(x => {
                let index = (attachments && Object.keys(attachments ?? {}))?.findIndex(att => att?.id == x?.id);
                if (index == -1) {
                    checked = true;
                }
            })

            if (checked) {
                setDataAttachment({
                    value: attachments,
                    valueChange: attachmentCompare
                });
            }
        }

        setDataAttributes(dataAttributes);

    }, [payload])

    const getConnectionName = (holon) => {
        return holon?.name;
    }

    function condenseFilename(initial) {
        initial = initial?.split("/")?.[initial?.split("/")?.length - 1]
        var split = initial?.split('.');
        if (split?.length > 0) {
            let filename = split?.[0];
            var extension = initial?.split(".")[1];
            if (filename.length > 55) {
                return filename.substring(0, 50) + '...' + extension;
            }
        }
        return initial;
    }

    return (
        <>
            <BoxContentPopup borderRadius='20px 0px 0px 20px' width='1000px'>
                <WrapContentBody
                    title={t("holon.life_cycle")}
                    handleBtnBack={() => showVisible()}
                    showVisible={showVisible}
                    isUseActionSectionTop={false}
                    actionSectionBottom={<Button color='gray' onClick={() => showVisible(false)} title={t("Holon_Template.close")} />}
                >
                    {
                        dataAttributes?.length > 0 && <Box>
                            <BoxHeader>
                                <BoxTitle>
                                    {t("holon.basic_info")}
                                </BoxTitle>
                            </BoxHeader>
                            <BoxBody style={{
                                width: '100%'
                            }}>
                                {
                                    dataAttributes?.map((x, key) => {
                                        return <BoxRow style={{
                                            width: '100%',
                                        }} key={key}>
                                            <BoxLabel style={{
                                                width: 200,
                                            }}>{x?.label}
                                            </BoxLabel>
                                            <BoxContent style={{
                                                flex: 1,
                                                width: 170
                                            }}>
                                                <CommonText style={{
                                                    fontStyle: 'italic'
                                                }}>
                                                    {x?.value ? x?.value : empty}
                                                </CommonText>
                                            </BoxContent>
                                            <BoxContent width={70}>
                                                <IconRowRight fontSize={24} color='black' />
                                            </BoxContent>
                                            <BoxContent style={{
                                                flex: 1,
                                                width: 170
                                            }}>
                                                <CommonText>
                                                    {x?.valueChange ? x?.valueChange : empty}
                                                </CommonText>
                                            </BoxContent>
                                        </BoxRow>
                                    })
                                }
                            </BoxBody>
                        </Box>
                    }
                    {
                        dataConnection?.length > 0 && <Box>
                            <BoxHeader>
                                <BoxTitle>
                                    {t("holon.related_information")}
                                </BoxTitle>
                            </BoxHeader>
                            <BoxBody style={{
                                width: '100%'
                            }}>
                                {
                                    Object.keys(dataConnection)?.map((x, key) => {
                                        return <BoxRow style={{
                                            width: '100%',
                                        }} key={key}>
                                            <BoxLabel style={{
                                                width: 200,
                                            }}>{dataConnection[x]?.label}
                                            </BoxLabel>
                                            <BoxContent style={{
                                                flex: 1,
                                                width: 170,
                                                alignSelf: 'flex-start'
                                            }}>
                                                <div className={classes['box-content']}>
                                                    {dataConnection[x]?.value?.length > 0 ? dataConnection[x]?.value?.map((holon, key) => {
                                                        return <div key={key} title={holon?.name} className={classes['box-name']}>
                                                            <p style={{ marginBottom: 0 }}>{getConnectionName(holon)}</p>
                                                        </div>
                                                    }) : <BoxContent style={{
                                                        fontStyle: 'italic',
                                                        minHeight: 30,
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>{empty}</BoxContent>}
                                                </div>
                                            </BoxContent>
                                            <BoxContent style={{
                                                alignSelf: 'flex-start'
                                            }} width={70}>
                                                <IconRowRight fontSize={24} color='black' />
                                            </BoxContent>
                                            <BoxContent style={{
                                                flex: 1,
                                                width: 170,
                                                alignSelf: 'flex-start'
                                            }}>
                                                <div className={classes['box-content']}>
                                                    {dataConnection[x]?.valueChange?.length > 0 ? dataConnection[x]?.valueChange?.map((holon, key) => {
                                                        return <div key={key} title={holon?.name} className={classes['box-name']}>
                                                            <p style={{ marginBottom: 0 }}>{getConnectionName(holon)}</p>
                                                        </div>
                                                    }) : <BoxContent>{empty}</BoxContent>}
                                                </div>
                                            </BoxContent>
                                        </BoxRow>
                                    })
                                }
                            </BoxBody>
                        </Box>
                    }
                    {
                        Object.keys(dataAttachment?.valueChange ?? {})?.length > 0 && <Box>
                            <BoxHeader>
                                <BoxTitle>
                                    {t("holon.attachment")}
                                </BoxTitle>
                            </BoxHeader>
                            <BoxBody style={{
                                width: '100%',
                                flexDirection: 'row',
                                gap: 20,
                                alignItems: 'stretch'
                            }}>
                                <BoxLabel style={{
                                    width: 200,
                                }} />
                                <div style={{ width: '170px', display: 'flex', flexDirection: 'column', gap: 7, alignItems: 'flex-start', flex: 1 }}>
                                    {
                                        Object.keys(dataAttachment.value ?? {})?.length > 0 ? Object.keys(dataAttachment.value ?? {})?.map((key, index) => {
                                            return <BoxContent key={index}>
                                                <Button color='gray' className={classes['btn-attachment']} key={index}>
                                                    <CommonText>{condenseFilename(key)}</CommonText>
                                                </Button>
                                            </BoxContent>
                                        }) :
                                            <CommonText style={{
                                                fontStyle: 'italic',
                                                fontSize: 12
                                            }}>
                                                {empty}
                                            </CommonText>
                                    }
                                </div>
                                <div style={{ display: 'flex', width: '70px' }}>
                                    <IconRowRight fontSize={24} />
                                </div>
                                <div style={{ width: '170px', display: 'flex', flexDirection: 'column', gap: 7, alignItems: 'flex-start', flex: 1 }}>
                                    {
                                        Object.keys(dataAttachment.valueChange ?? {})?.length > 0 ? Object.keys(dataAttachment.valueChange ?? {})?.map((key, index) => {
                                            return <BoxContent key={index}>
                                                <Button color='gray' className={classes['btn-attachment']} key={index}>
                                                    <CommonText>{condenseFilename(key)}</CommonText>
                                                </Button>
                                            </BoxContent>
                                        }) : <CommonText style={{
                                            fontStyle: 'italic',
                                            fontSize: 12
                                        }}>
                                            {empty}
                                        </CommonText>
                                    }
                                </div>
                            </BoxBody>
                        </Box>
                    }
                </WrapContentBody>
            </BoxContentPopup>
        </>
    )
}

export default HistoryDetailPopup