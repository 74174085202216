import { request } from "adapter/ApiProvider";
import axios from "axios";
import Cookies from "js-cookie";
import { getCoreAPI } from "shared/components/common/Util";
import ApiOperation from "adapter/ApiOperation";

const Factories = {
    register: async (data) => {
        var url = `${getCoreAPI()}/users/register`;
        return request({
            url: url,
            method: 'POST',
            data: data
        })
    },
    login: async (data) => {
        var url = `${getCoreAPI()}/users/auth/login`;

        return await axios.post(url, data).then(res => res.data);
    },
    forgotIDPassword: async (data) => {
        var url = `${getCoreAPI()}/users/forget-password`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: data
        })
    },
    resetPassword: async (data) => {
        var url = `${getCoreAPI()}/users/change-password`;

        return await axios.post(url, data).then(res => res.data);
    },

    getUserInfo: () => {
        const url = `${getCoreAPI()}/users/account-info`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    uploadImage: (data) => {
        const formData = new FormData();
        formData.append('file', data);

        const url = `${getCoreAPI()}/storage/upload`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: formData,
        })
    },
    uploadImageCommon: (data) => {
        const formData = new FormData();
        formData.append('file', data?.file);
        if (data?.format) {
            formData.append('format', data?.format);
        }

        const url = `${getCoreAPI()}/storage/upload`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: formData,
        })
    },

    updateUserInfo: (data) => {
        const url = `${getCoreAPI()}/users/account-setting`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: data
        })
    },

    updateAlarmUser: (data) => {
        const url = `${getCoreAPI()}/users/account-setting/alarm`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: data
        })
    },

    inquiry: async (data) => {
        var url = `${getCoreAPI()}/users/inquiry`;

        return await axios.post(url, data).then(res => res.data);
    },
    refreshToken: async () => {
        var refreshToken = Cookies.get("refreshToken");

        var url = `${getCoreAPI()}/users/auth/refreshToken`

        return await request({ url, method: "POST", data: { refreshToken } });
    },
    validateToken: async (formData) => {
        var url = `${getCoreAPI()}/users/validate-token/${formData.token}`;

        return await axios.get(url).then(res => res.data);
    },
    getUserStatusByHexId: (data) => {
        const url = `${getCoreAPI()}/users/${data}/status`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    getSaleList: (params) => {
        const url = `${getCoreAPI()}/users/sales`;
        return ApiOperation.request({
            url: url,
            method: "GET",
            params: params,
        });
    },

    getManagerList: async (params) => {
        const url = `${getCoreAPI()}/users/managers`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params,
        })
    },

    getPositionsOfBusinessCustomer: (data) => {
        const url = `${getCoreAPI()}/users/positions/${data}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
}

export default Factories;