import ApiOperation from "adapter/ApiOperation";
import { getCoreAPI } from "shared/components/common/Util";
import Constants from "utils/Constants";

const factory = {
    getNotifications: () => {
        var url = `${getCoreAPI()}/management/alarm`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                [Constants.QueryParams.TYPE.VALUE]: "T",
                [Constants.QueryParams.LIMIT.VALUE]: Constants.PAGINATION.LIMIT
            }
        })
    },
    getWallets: () => {
        var url = `${getCoreAPI()}/dashboard/wallet`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getOrders: () => {
        var url = `${getCoreAPI()}/management/dashboard/statistic-order`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    countUser: () => {
        var url = `${getCoreAPI()}/users/count-user`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    countOrderedUser: () => {
        var url = `${getCoreAPI()}/users/count-ordered-user`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    walletInfo: () => {
        var url = `${getCoreAPI()}/management/dashboard/wallet-info`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    countInquiry: () => {
        var url = `${getCoreAPI()}/inquiry/count`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    }

}
export default factory;