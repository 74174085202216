import React, { useEffect, useState } from 'react';
import Menu from '../../menu/Menu';
import classes from './MobileLayout.module.scss';
import Action from 'redux/user/action';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import useCustomState from 'hooks/use-custom-state';
import Constants from 'utils/Constants';
import i18n from "i18nextInit";
import useTrans from 'hooks/use-trans';
import IconChevronRight from 'assets/icon/IconChevronRight';

function MobileLayout(props) {
    const dispatch = useDispatch();
    const [lang, setLang] = useCustomState(localStorage.getItem("i18nextLng") ?? Constants.T001.JP.CODE)
    const [optionLanguages, setOptionLanguages] = useCustomState([]);
    const { t } = useTrans();
    useEffect(() => {
        const interval = setInterval(() => {
            const token = Cookies.get("accessToken");
            const now = Date.now();
            if (token) {
                const expiredAt = Cookies.get("expiredAt") ? new Date(Cookies.get("expiredAt")) : new Date();
                if (0 < expiredAt - now && expiredAt - now <= 60 * 1000) {
                    dispatch({
                        type: Action.REQUEST_REFRESH_TOKEN
                    });
                }
            }

        }, 1000 * 300);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: Constants.T001[key].NAME,
            })
        });
        setOptionLanguages(data)
        i18n.on("languageChanged", (val) => {
            setLang(val)
        })
        return () => {
            i18n.off("languageChanged")
        }
    }, [])
    const [openMenu, setOpenMenu] = useState(false)
    const handleShowMenu = () => {
        setOpenMenu(!openMenu)
    }
    return (
        <div className={``}>
            <div className={`col-auto ${classes['menu']} ${classes['expand-menu']} ${openMenu ? classes['open'] : ''}`}>
                <Menu />
                <div className={classes["icon"]} onClick={handleShowMenu}><IconChevronRight/></div>
            </div>
            <div className={`col ${classes['layout-content']}`}>
                <div className={`${classes['button-lang']}`}>
                    <select value={lang} className='w-100' onChange={(val) => {
                        i18n.changeLanguage(val.target.value);
                        window.location.reload();
                    }}>
                        <option value="jp">{t("faq_management.lang.Jp")}</option>
                        <option value="en">{t("faq_management.lang.En")}</option>
                        <option value="cn">{t("faq_management.lang.Cn")}</option>
                    </select>
                </div>
                {props.children}
            </div>
        </div>
    )
}
export default MobileLayout;