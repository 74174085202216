import React, { useEffect, useState } from 'react'
import BoxContentPopup from '../custom-body/BoxContentPopup'
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider'
import WrapContentBody from '../custom-body/WrapContentBody'
import { useForm } from 'react-hook-form';
import useTrans from 'hooks/use-trans';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxContent from '../../box/BoxContent';
import BoxLabel from '../../box/BoxLabel';
import FormSelect from 'shared/components/custom-form/FormSelect';
import Validator from 'utils/Validator';
import Button from 'shared/components/form-input/button/Button';
import { useDispatch } from 'react-redux';
import actionOCR from 'redux/ocr/action';
import { showPopupNotification } from 'utils/Utils';

function ListTemplateOcrPopup(props) {
    const {
        payload, showVisible
    } = props;
    const { t } = useTrans();
    const [dataTemplate, setDataTemplate] = useState([])
    const [loading, setLoading] = useState(false)
    const methods = useForm();
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        setLoading(true)
        dispatch({
            type: actionOCR.LOAD_DATA_FROM_TEMPLATE,
            payload: {
                file: payload?.attachment,
                document_id: data?.ocr
            },
            callback: {
                success: (_data) => {
                    setLoading(false)
                    showVisible()
                    if (payload?.callback?.loadDataFromTemplate) {
                        payload?.callback?.loadDataFromTemplate(dataTemplate?.find(x => x?.id == data?.ocr)?.templateId, _data)
                    }
                },
                failed: (msg) => {
                    setLoading(false);
                    showPopupNotification({
                        typePopup: 'message',
                        message: msg
                    })
                }
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: actionOCR.GET_TEMPLATE_OCR,
            callback: {
                success: (data) => {
                    if (data?.length > 0) {
                        setDataTemplate(data?.map(x => {
                            return {
                                label: x?.document_name,
                                value: x?.document_id,
                                id: x?.document_id,
                                templateId: x?.holon_template_id,
                            }
                        }))
                    }
                }
            }
        })
    }, [])

    return (
        <>
            <BoxContentPopup borderRadius='20px 0px 0px 20px' width='700px'>
                <CustomFormProvider {...methods} >
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <WrapContentBody
                            showVisible={showVisible}
                            title={t("ocr.ocr_title")}
                            actionSectionBottom={<Button loading={loading} type='submit' title={t("Holon_Template.ok")} />}
                        >
                            <Box>
                                <BoxBody style={{
                                    borderRadius: 10
                                }}>
                                    <BoxRow>
                                        <BoxLabel>
                                            {t("ocr.ocr_document")}
                                        </BoxLabel>
                                        <BoxContent>
                                            <FormSelect
                                                fieldName='ocr'
                                                options={[...dataTemplate]}
                                                placeholder={t("ocr.ocr_document")}
                                                validate={[Validator.required]}
                                                validationFE
                                                isRegexLabel={t("ocr.ocr_document")}
                                            />
                                        </BoxContent>
                                    </BoxRow>
                                </BoxBody>
                            </Box>
                        </WrapContentBody>
                    </form>
                </CustomFormProvider>
            </BoxContentPopup>
        </>
    )
}

export default ListTemplateOcrPopup