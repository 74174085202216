import React, { useEffect } from "react";
import CustomHeader from "./CustomHeader";
import classes from "./CustomTable.module.scss";
import CustomTableRow from "./CustomTableRow";
import PropTypes from 'prop-types';
import $ from 'jquery';
import TableSkeleton from "../skeleton/TableSkeleton";
import useTrans from "hooks/use-trans";
import CustomHeaderTable from "./CustomHeaderTable";

const CustomTable = (props) => {
    const {
        data,
        headCell,
        onChangeSelect,
        dataSelected,
        fieldId = "Id",
        isCheckBox = false,
        onChangeSortColumn,
        isBorder = false,
        loading,
        elementId = "TableId",
        dataSort,
        maxHeight,
        headerCustom,
        totalRow
    } = props;

    const { t } = useTrans();
    const handleSelect = (e) => {
        if (dataSelected) {
            let data = [...dataSelected];
            let index = data?.findIndex((x) => x[fieldId] == e[fieldId]);
            if (index != -1) {
                data.splice(index, 1);
            } else {
                data.push(e);
            }
            onChangeSelect && onChangeSelect(data);
        }
    };

    const handleSelectAll = () => {
        if (dataSelected && dataSelected.length == data.length) {
            onChangeSelect && onChangeSelect([]);
        } else {
            onChangeSelect && onChangeSelect(data?.map((x) => x));
        }
    };
    let ignoreScrollEvents = false;

    const syncScroll = (...selectors) => {
        const wrapper = document.getElementById(elementId);
        if (wrapper && selectors.length > 1) {
            wrapper.addEventListener(
                'scroll',
                ({ target }) => {
                    if (target.classList.contains(selectors[0])) {
                        const ignore = ignoreScrollEvents;
                        ignoreScrollEvents = false;
                        if (ignore) {
                            return;
                        }
                        ignoreScrollEvents = true;
                        const { scrollLeft } = target;
                        selectors.forEach((selector) => {
                            if (wrapper.querySelector(`.${selector}`)) {
                                wrapper.querySelector(`.${selector}`).scrollLeft = scrollLeft;
                            }
                        });
                    }
                },
                true,
            );
        }
    };

    useEffect(() => {
        const executeSyncScroll = () => {
            syncScroll(
                '_custom_scroll_footer',
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_total',
            );
            syncScroll(
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_footer',
                '_custom_scroll_total',
            );
        };
        executeSyncScroll();
    }, []);

    useEffect(() => {
        $(() => {
            $('._custom_scroll_body').scrollLeft(
                $('._custom_scroll_header').scrollLeft(),
            );
            $('._custom_scroll_footer').scrollLeft(
                $('._custom_scroll_header').scrollLeft(),
            );
            $('._custom_scroll_footer').scrollLeft(
                $('._custom_scroll_total').scrollLeft(),
            );
        });
    }, [loading]);

    return (
        <div id={elementId}>
            {
                totalRow?.length > 0 && <div style={{
                    maxHeight: maxHeight,
                    overflow: 'auto'
                }} className={`${classes["_custom_scroll_total"]} _custom_scroll_total`}>
                    <table className={classes.TableRowTotalV2} style={{ width: "100%" }}>
                        <tbody>
                            {totalRow?.map((row, _index) => {
                                const labelId = `enhanced-wrap-table-${_index}`;

                                return (
                                    <CustomTableRow
                                        index={_index}
                                        row={row}
                                        dataHead={headCell}
                                        key={labelId}
                                        handleSelect={handleSelect}
                                        fieldId={fieldId}
                                        dataSelected={dataSelected}
                                        isCheckBox={isCheckBox}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            }
            {
                !headerCustom && <div style={{
                    position: 'sticky',
                    zIndex: 11,
                    overflowY: 'hidden',
                    top: 0
                }} className={`_custom_scroll_header ${classes['_custom_scroll_header']}`}>
                    <table style={{ width: '100%' }}>
                        <CustomHeader handleSelectAll={handleSelectAll}
                            dataTable={data}
                            dataSelected={dataSelected}
                            headCell={headCell}
                            isCheckBox={isCheckBox}
                            onChangeSortColumn={onChangeSortColumn}
                            isBorder={isBorder}
                            dataSort={dataSort}
                        />
                    </table>
                </div>
            }
            {
                headerCustom && <div style={{
                    position: 'sticky',
                    zIndex: 11,
                    overflowY: 'hidden',
                    top: 0
                }} className={`_custom_scroll_header ${classes['_custom_scroll_header']}`}>
                    <CustomHeaderTable
                        headerColumn={headCell}
                        Component={headerCustom}
                    />
                </div>
            }
            {
                loading ? <TableSkeleton /> : <div style={{
                    maxHeight: maxHeight,
                    overflow: 'auto'
                }} className={`${classes["_custom_scroll_body"]} _custom_scroll_body`}>
                    <table className={classes.TableRowTotalV2} style={{ width: "100%" }}>
                        <tbody>
                            {data?.map((row, _index) => {
                                const labelId = `enhanced-wrap-table-${_index}`;

                                return (
                                    <CustomTableRow
                                        index={_index}
                                        row={row}
                                        dataHead={headCell}
                                        key={labelId}
                                        handleSelect={handleSelect}
                                        fieldId={fieldId}
                                        dataSelected={dataSelected}
                                        isCheckBox={isCheckBox}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            }
            {
                !loading && data?.length == 0 ? <div className={`_custom_scroll_body ${classes['custom-table-empty']}`}>
                    {t("custom_table.empty_table")}
                </div>
                    : <></>
            }
            <div
                className={`_custom_scroll_footer ${classes._custom_scroll_footer}`}
                style={{
                    position: 'sticky',
                    zIndex: 11,
                    maxHeight: 15,
                    overflowY: 'hidden',
                    bottom: 0,
                }}
            >
                <table
                    className="table"
                    style={{ width: '100%', visibility: 'hidden' }}
                >
                    <CustomHeader
                        handleSelectAll={handleSelectAll}
                        dataTable={data}
                        dataSelected={dataSelected}
                        headCell={headCell}
                        isCheckBox={isCheckBox}
                        onChangeSortColumn={onChangeSortColumn}
                        isBorder={isBorder}
                    />
                </table>
            </div>
        </div>
    );
};

CustomTable.propTypes = {
    handleOnCheckBox: PropTypes.func,
    selectedCheckBox: PropTypes.array,
    fieldKey: PropTypes.string,
    fieldName: PropTypes.string,
    isShowCheckBox: PropTypes.bool,
    headCell: PropTypes.array,
    data: PropTypes.array,
    componentHeadCell: PropTypes.element,
    fitContent: PropTypes.bool,
    isBackgroundRow: PropTypes.bool,
    customBorderRight: PropTypes.bool,
    onChangeSortColumn: PropTypes.func,
    loading: PropTypes.bool,
    dataSort: PropTypes.any,
    elementId: PropTypes.string,
    maxHeight: PropTypes.number
};
export default CustomTable;
