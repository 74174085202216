import React from 'react';

function IconCopy() {
    return (
        <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.93669 10.8287H3.20703V11.3733H8.93669V10.8287Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M8.93669 8.62329H3.20703V9.16607H8.93669V8.62329Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M8.93669 6.41589H3.20703V6.96046H8.93669V6.41589Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M8.93667 4.21021H6.2373V4.75478H8.93667V4.21021Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M11.1461 12.6071V2.16057C11.1461 2.09336 11.1403 2.02571 11.1287 1.96229C11.0338 1.41572 10.5535 1 9.97671 1H5.23846C4.89769 1 4.57237 1.13485 4.33235 1.37345L1.37572 4.31014C1.13547 4.55096 1 4.87407 1 5.21301V12.6071C1 13.2458 1.52484 13.7674 2.16968 13.7674H9.97668C10.6213 13.7674 11.1461 13.2458 11.1461 12.6071ZM4.82427 1.80651V4.4161C4.82427 4.69917 4.71389 4.80865 4.4311 4.80865H1.80562L4.82427 1.80651ZM1.65651 12.6071V5.38794H4.7815C4.88425 5.38794 4.98099 5.36304 5.06609 5.31874C5.18632 5.25909 5.28306 5.16276 5.34104 5.04546C5.38581 4.96067 5.40889 4.86631 5.40889 4.76638V1.6525H9.97671C10.1876 1.6525 10.3696 1.77936 10.4471 1.96229C10.4742 2.02393 10.4877 2.08935 10.4877 2.16057V12.6071C10.4877 12.8859 10.2593 13.1151 9.97671 13.1151H2.16971C1.88692 13.1151 1.65651 12.8859 1.65651 12.6071Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M12.5126 3.23254H11.7247L11.7652 3.88504H12.5126C12.7954 3.88504 13.0239 4.11204 13.0239 4.39311V14.8396C13.0239 15.1185 12.7954 15.3477 12.5126 15.3477H4.70378C4.49264 15.3477 4.3106 15.2186 4.23311 15.0377C4.20806 14.9762 4.19261 14.9108 4.19261 14.8396V14.3854H3.53613V14.8396C3.53613 14.9068 3.54197 14.9741 3.55361 15.0377C3.64852 15.5823 4.12883 16 4.70381 16H12.5126C13.1575 16 13.6805 15.4783 13.6805 14.8396V4.39314C13.6805 3.75221 13.1574 3.23254 12.5126 3.23254Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
        </svg>
    );
}

export default IconCopy;
