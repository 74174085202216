import React, { useEffect, useState } from 'react'
import Constants from 'utils/Constants';
import classes from './SearchConditions.module.scss';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import useTrans from 'hooks/use-trans';
import useRouter from 'hooks/use-router';
import { useDispatch } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import IconChevronDown from 'assets/icon/IconChevronDown';
import IconChevronRight from 'assets/icon/IconChevronRight';
import BoxRow from 'shared/components/common/box/BoxRow';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import BoxContent from 'shared/components/common/box/BoxContent';
import { useSelector } from 'react-redux';
import actionHolonTemplate from 'redux/holon-template/action';
import FormReactSelect from 'shared/components/custom-form/FormReactSelect';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import { CommonDataFilterHolonInstance } from '../CommonDataFilter';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import { convertRegexToOptions } from 'utils/Utils';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE } from 'utils/EventRegister';
import moment from 'moment';

function SearchConditions(props) {
    const {
        holonTemplateId,
        holonTemplate,
        subHolonTemplate,
        holonTemplateDetail,
        subHolonTemplateDetail,
        holonTemplateID,
        template_id,
        onClearDataSubHolon,
        onChangeSelectTemplate,
        commonFiled,
        setListCheckbox,
        dataSearchWithAttributeTemp,
        setDataSearchWithAttributeTemp,
        refSubmitData,
        onChangeSelectSubTemplate,
        onResetDataHolonInstance,
        disabledSubHolonTemplate
    } = props;

    const { t } = useTrans();
    const router = useRouter();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const { categories } = useSelector(state => state?.holonTemplate);

    const [category, setCategory] = useState([]);

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_MASTER_HOLON_TEMPLATE,
            payload: {}
        })
    }, [])

    useEffect(() => {
        if (categories) {
            setCategory(Object.keys(categories)?.map(key => {
                return {
                    value: key,
                    key: key,
                    label: categories[key]
                }
            }));
        }

        return () => {
            setCategory([]);
        }
    }, [categories])

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = [];
        if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE || holonTemplateID?.split("-")?.length > 1) {
            let attributesList = [...holonTemplateDetail?.validAttributes?.filter(x =>
                x?.elementType != Constants.ELEMENT_TYPE.FILE.VALUE
                && x?.elementType != Constants.ELEMENT_TYPE.IMAGE.VALUE
                && x?.elementType != Constants.ELEMENT_TYPE.PASSWORD.VALUE
                // && x?.elementType != Constants.ELEMENT_TYPE.UNIQUE.VALUE
            )];

            commonFiled?.map(x => {
                let index = attributesList?.findIndex(at => at?.key == x);
                if (index != -1) {
                    listFormItem.push({
                        ...attributesList[index],
                        isHidden: false
                    })
                }
            });
            listFormItem = listFormItem?.map(x => {
                return {
                    elementType: x?.elementType,
                    options: convertRegexToOptions(x?.regex),
                    label: x?.label,
                    key: x?.key,
                    id: x?.id,
                    [x?.key]: dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES.VALUE] && JSON.parse(dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES.VALUE])[x?.key] ?
                        JSON.parse(dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES.VALUE])[x?.key] : null
                }
            })
        } else {
            listFormItem = [...holonTemplateDetail?.validAttributes?.filter(x =>
                x?.elementType != Constants.ELEMENT_TYPE.FILE.VALUE
                && x?.elementType != Constants.ELEMENT_TYPE.IMAGE.VALUE
                && x?.elementType != Constants.ELEMENT_TYPE.PASSWORD.VALUE
                // && x?.elementType != Constants.ELEMENT_TYPE.UNIQUE.VALUE
            )?.map(x => {
                return {
                    elementType: x?.elementType,
                    options: convertRegexToOptions(x?.regex),
                    label: x?.label,
                    key: x?.key,
                    id: x?.id,
                    [x?.key]: dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES.VALUE] && JSON.parse(dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES.VALUE])[x?.key] ?
                        JSON.parse(dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES.VALUE])[x?.key] : null
                }
            }), ...subHolonTemplateDetail?.validAttributes?.filter(x =>
                x?.elementType != Constants.ELEMENT_TYPE.FILE.VALUE
                && x?.elementType != Constants.ELEMENT_TYPE.IMAGE.VALUE
                && x?.elementType != Constants.ELEMENT_TYPE.PASSWORD.VALUE
                // && x?.elementType != Constants.ELEMENT_TYPE.UNIQUE.VALUE
            )?.map(x => {
                return {
                    elementType: x?.elementType,
                    options: convertRegexToOptions(x?.regex),
                    label: x?.label,
                    key: x?.key,
                    id: x?.id,
                    [x?.key]: dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES_SUB.VALUE] && JSON.parse(dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES_SUB.VALUE])[x?.key] ?
                        JSON.parse(dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES_SUB.VALUE])[x?.key] : null
                }
            })];
        }

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                callback: (_props) => {
                    let dataMain = [];
                    let dataSub = [];

                    _props?.map(x => {
                        if (x[x?.key]) {
                            let index = holonTemplateDetail?.attributes?.findIndex(at => at?.id == x.id);
                            if (index != -1) {
                                if (holonTemplateDetail?.attributes[index]?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
                                    dataMain.push({
                                        ...x,
                                        [x?.key]: moment(x?.[x?.key]).format("YYYY-MM-DD HH:mm:ss")
                                    })
                                }
                                else if (holonTemplateDetail?.attributes[index]?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                                    dataMain.push({
                                        ...x,
                                        [x?.key]: moment(x?.[x?.key]).format("YYYY-MM-DD")
                                    })
                                } else {
                                    dataMain.push(x);
                                }
                            } else {
                                if (subHolonTemplateDetail?.attributes[index]?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
                                    dataSub.push({
                                        ...x,
                                        [x?.key]: moment(x?.[x?.key]).format("YYYY-MM-DD HH:mm:ss")
                                    })
                                }
                                else if (subHolonTemplateDetail?.attributes[index]?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                                    dataSub.push({
                                        ...x,
                                        [x?.key]: moment(x?.[x?.key]).format("YYYY-MM-DD")
                                    })
                                } else {
                                    dataSub.push(x);
                                }
                            }
                        }
                    })

                    let params = {
                        [Constants.QueryParams.OFFSET.VALUE]: 1,
                    };

                    if (dataMain?.length > 0) {
                        params[Constants.QueryParams.ATTRIBUTES.VALUE] = JSON.stringify(dataMain?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur?.[cur?.key] }), {}))
                    } else {
                        delete params[Constants.QueryParams.ATTRIBUTES.VALUE]
                    }
                    if (dataSub?.length > 0) {
                        params[Constants.QueryParams.ATTRIBUTES_SUB.VALUE] = JSON.stringify(dataSub?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur?.[cur?.key] }), {}))
                    } else {
                        delete params[Constants.QueryParams.ATTRIBUTES_SUB.VALUE]
                    }
                    setDataSearchWithAttributeTemp({ ...params })
                },
                formArray: true
            }
        })
    }
    return (
        <>
            <div className={classes['custom-table-attributes']}>
                <div className={classes['custom-header']}>
                    <div onClick={() => setOpen(!open)} className={classes['button-arrow']}>
                        {
                            open ? <IconChevronDown /> : <IconChevronRight color="currentColor" fontSize={11} />
                        }
                    </div>
                    <p>{t("holon.search_conditions")}</p>
                </div>
            </div>
            {
                open &&
                <>
                    <FormSearch>
                        {
                            !holonTemplateId &&
                            <>
                                {
                                    template_id ? <></> :
                                        <FormGroup>
                                            <BoxRow style={{ flex: 1 }}>
                                                <BoxLabel minWidth={120}>{t("category")}</BoxLabel>
                                                <BoxContent>
                                                    <FormReactSelect
                                                        isMenuPortal
                                                        fieldName='category'
                                                        options={category ?? []}
                                                        onChangeSelect={(e) => {
                                                            if (refSubmitData.current) {
                                                                refSubmitData.current = false
                                                            }

                                                            setListCheckbox([]);
                                                            let params = { ...router.getAll() };
                                                            delete params[Constants.QueryParams.ATTRIBUTES.VALUE];
                                                            delete params[Constants.QueryParams.ATTRIBUTES_SUB.VALUE];
                                                            delete params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE];
                                                            delete params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE];
                                                            delete params[Constants.QueryParams.KEYWORD.VALUE];

                                                            params[Constants.QueryParams.OFFSET.VALUE] = 1;
                                                            params[Constants.QueryParams.CATEGORY.VALUE] = e;
                                                            router.replace({
                                                                params: {
                                                                    ...params
                                                                }
                                                            });

                                                            onChangeSelectTemplate(null);
                                                            onResetDataHolonInstance()

                                                            dispatch({
                                                                type: actionsHolonInstance.GET_SUB_HOLON_TEMPLATE_SUCCESS,
                                                                payload: {
                                                                    attributes: [],
                                                                    validAttributes: []
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </BoxContent>
                                            </BoxRow>
                                            <BoxRow style={{ flex: 1 }} />
                                        </FormGroup>
                                }
                                <FormGroup>
                                    <BoxRow style={{ flex: 1 }}>
                                        <BoxLabel minWidth={120}>{t("holon.main_template")}</BoxLabel>
                                        <BoxContent>
                                            <FormReactSelect
                                                isMenuPortal
                                                isMulti
                                                fieldName='holonTemplate'
                                                options={holonTemplate ?? []}
                                                closeMenuOnSelect={false}
                                                onChangeSelect={(e) => {

                                                    if (refSubmitData.current) {
                                                        refSubmitData.current = false
                                                    }
                                                    setListCheckbox([]);
                                                    onClearDataSubHolon();

                                                    if (!e && !router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)) {
                                                        onChangeSelectTemplate(null);
                                                    }

                                                    if (e?.length > 0) {
                                                        if (e[e?.length - 1] == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                                                            onChangeSelectTemplate(Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)
                                                        } else if (e[0] == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE && e?.length > 1) {
                                                            e?.splice(0, 1);
                                                            onChangeSelectTemplate(e?.join("-"));

                                                        } else {
                                                            onChangeSelectTemplate(e?.join("-"));

                                                        }
                                                    } else {
                                                        onChangeSelectTemplate(null);

                                                    }

                                                    dispatch({
                                                        type: actionsHolonInstance.GET_SUB_HOLON_TEMPLATE_SUCCESS,
                                                        payload: {
                                                            attributes: [],
                                                            validAttributes: []
                                                        }
                                                    })
                                                }}
                                                disabled={holonTemplateId ? true : false}
                                            />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow style={{ flex: 1 }}>
                                        <BoxLabel minWidth={120}>{t("holon.accompanying_template")}</BoxLabel>
                                        <BoxContent>
                                            <FormReactSelect
                                                isMenuPortal
                                                fieldName='subHolonTemplate'
                                                options={subHolonTemplate ?? []}
                                                onChangeSelect={(e) => {
                                                    if (refSubmitData.current) {
                                                        refSubmitData.current = false
                                                    }
                                                    onChangeSelectSubTemplate(e);
                                                }}
                                                disabled={(
                                                    holonTemplateId || holonTemplateID?.split("-")?.length > 1 || holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE
                                                    || disabledSubHolonTemplate
                                                ) ? true : false}
                                            />
                                        </BoxContent>
                                    </BoxRow>
                                </FormGroup>
                            </>
                        }
                        <FormGroup>
                            <BoxRow>
                                <BoxLabel minWidth={120}>
                                    {t("holon.search_conditions")}
                                </BoxLabel>
                                <FormGroup flexWrap>
                                    <FormItem minWidth={183} maxWidth={183}>
                                        <FormInput isSearch fieldName='keyword' placeholder={t("Holon_Template.search_keyword")} />
                                    </FormItem>
                                    {
                                        dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES.VALUE] && CommonDataFilterHolonInstance(dataSearchWithAttributeTemp, [Constants.QueryParams.ATTRIBUTES.VALUE], holonTemplateDetail, setDataSearchWithAttributeTemp, dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES.VALUE])
                                    }
                                    {
                                        dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES_SUB.VALUE] && CommonDataFilterHolonInstance(dataSearchWithAttributeTemp, [Constants.QueryParams.ATTRIBUTES_SUB.VALUE], subHolonTemplateDetail, setDataSearchWithAttributeTemp, dataSearchWithAttributeTemp?.[Constants.QueryParams.ATTRIBUTES_SUB.VALUE])
                                    }
                                    <FormItem>
                                        <ButtonFilter
                                            disabled={holonTemplateDetail?.id && [...holonTemplateDetail?.attributes ?? [], ...subHolonTemplateDetail?.attributes ?? []]?.length > 0 ? false : true}
                                            onClick={handleOpenAddSearchCriteria}
                                            size='large'
                                            title={t("Holon_Template.add_search_conditions")}
                                            color='white'
                                            endIcon={<IconPlusAccordion />}
                                        />
                                    </FormItem>
                                </FormGroup>
                            </BoxRow>
                        </FormGroup>
                    </FormSearch>
                </>
            }
        </>
    )
}

export default SearchConditions