import React, { useEffect, useRef, useState } from 'react'
import classes from './AlarmListPopup.module.scss';
import CustomTab from '../../custom-tab/CustomTab';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSearch from '../../form-search/FormSearch';
import FormGroup from '../../form-search/FormGroup';
import TableAlarm from 'shared/components/alarm/TableAlarm';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import Constants from 'utils/Constants';
import actionAlarm from 'redux/alarm/action';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ALARM, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE } from 'utils/EventRegister';
import { useForm } from 'react-hook-form';
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import { useDispatch, useSelector } from 'react-redux';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import IconClose from 'assets/icon/IconClose';
import IconMinimize from 'assets/icon/IconMiniMize';
import FormItem from '../../form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import IconMinusAccordionFilter from 'assets/icon/IconMinusAccordionFilter';
import { convertDate } from 'utils/Utils';

function AlarmListPopup(props) {
    const { showVisible } = props;
    const methods = useForm();
    const router = useRouter();
    const { t } = useTrans();
    const [valueTab, setValueTab] = useState(null)
    const [params, setParams] = useState({});
    const ref = useRef(false);
    const [dataTab, setDataTab] = useState([])
    const { loading: {
        loadingGetAlarm = false
    }, listAlarm, alarmMaster } = useSelector(state => state.alarm);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: actionAlarm.GET_ALARM_MASTER,
            payload: {}
        })
    }, [])

    const handleSubmit = (data) => {
        let paramsRemove = { ...params };
        if (data?.keyword) {
            setParams({
                ...paramsRemove,
                [Constants.QueryParams.KEYWORD.VALUE]: data?.keyword
            })
        } else {
            delete paramsRemove[Constants.QueryParams.KEYWORD.VALUE]
            setParams({
                ...paramsRemove
            })
        }
    }

    const renderDataFilter = () => {
        const handleRemoveRouter = (_key) => {
            let paramsRemove = { ...params };
            delete paramsRemove[_key]

            if (_key == 'date') {
                delete paramsRemove[Constants.QueryParams.FROM.VALUE]
                delete paramsRemove[Constants.QueryParams.TO.VALUE]
            }

            setParams({
                ...paramsRemove,
                [Constants.QueryParams.OFFSET.VALUE]: 1
            })
        }

        return <>
            {
                params[Constants.QueryParams.SUBJECT.VALUE] && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.SUBJECT.VALUE)} color='white' size='large' title={`${t("Holon_Template.subject")}: ${params[Constants.QueryParams.SUBJECT.VALUE]}`} endIcon={<IconMinusAccordionFilter />} />
            }
            {
                params[Constants.QueryParams.MESSAGE.VALUE] && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.MESSAGE.VALUE)} color='white' size='large' title={`${t("Holon_Template.content")}: ${params[Constants.QueryParams.MESSAGE.VALUE]}`} endIcon={<IconMinusAccordionFilter />} />
            }
            {
                (
                    params[Constants.QueryParams.FROM.VALUE] || params[Constants.QueryParams.TO.VALUE]
                ) &&
                <ButtonFilter
                    onClick={() => handleRemoveRouter('date')}
                    color='white' size='large'
                    title={`${t("Holon_Template.created")}：${convertDate(params[Constants.QueryParams.FROM.VALUE])} ${params[Constants.QueryParams.FROM.VALUE] && params[Constants.QueryParams.TO.VALUE] ? '〜' : ''} ${convertDate(params[Constants.QueryParams.TO.VALUE])} `}
                    endIcon={<IconMinusAccordionFilter />} />
            }
        </>
    }

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = [
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'subject',
                label: t("Holon_Template.subject"),
                subject: params[Constants.QueryParams.SUBJECT.VALUE] || ""
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'message',
                label: t("Holon_Template.message"),
                message: params[Constants.QueryParams.MESSAGE.VALUE] || ""
            },
            {
                elementType: Constants.VIEW_SEARCH.ROW.VALUE,
                key: 'date',
                label: t("Holon_Template.date"),
                component: [
                    {
                        elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                        key: 'from',
                        label: t("Holon_Template.from"),
                        from: params[Constants.QueryParams.FROM.VALUE] || null
                    },
                    {
                        elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                        key: 'to',
                        label: t("Holon_Template.to"),
                        to: params[Constants.QueryParams.TO.VALUE] || null
                    }
                ]
            }
        ]
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                formArray: false,
                callback: (_props) => {
                    setParams({
                        [Constants.QueryParams.OFFSET.VALUE]: 1,
                        [Constants.QueryParams.SUBJECT.VALUE]: _props?.subject,
                        [Constants.QueryParams.MESSAGE.VALUE]: _props?.message,
                        [Constants.QueryParams.FROM.VALUE]: _props?.from ? _props?.from : null,
                        [Constants.QueryParams.TO.VALUE]: _props?.to ? _props?.to : null,
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (valueTab && ref) {
            dispatch({
                type: actionAlarm.GET_LIST_ALARM,
                payload: {
                    [Constants.QueryParams.TAB.VALUE]: valueTab,
                    ...params
                }
            })
        }
    }, [
        params,
        valueTab,
        ref
    ])

    useEffect(() => {
        if (alarmMaster?.alarmTypes) {
            let masterType = Object.keys(alarmMaster?.alarmTypes).map(key => {
                return {
                    key: key,
                    id: key,
                    label: alarmMaster?.alarmTypes[key],
                }
            });
            masterType = masterType?.sort((a,b) => a.key.localeCompare(b.key));
            setDataTab(masterType);
            setValueTab(masterType[0]?.id);
            // dispatch({
            //     type: actionAlarm.GET_LIST_ALARM,
            //     payload: {
            //         [Constants.QueryParams.TAB.VALUE]: masterType[0]?.id
            //     }
            // })
        }
    }, [alarmMaster?.alarmTypes])

    useEffect(() => {
        return () => {
            dispatch({
                type: actionAlarm.GET_LIST_ALARM_SUCCESS,
                payload: {}
            });
            ref.current = false;
        }
    }, [])


    const handleOpenPopup = () => {
        EventRegister.emit(EVENT_SHOW_POPUP_ALARM);
        showVisible(false);
    }
    return (
        <>
            {/* <WrapContentBody
                showVisible={showVisible}
                isUseBtnBottom={false}
                title={t("alarm.notification_alarm")}
            > */}
            <div className={classes['AlarmListPopup']}>
                <CustomTab data={dataTab} value={valueTab} onChange={(e) => {
                    setValueTab(e)
                }} action={<div className={classes['section-right']}>
                    <div onClick={handleOpenPopup} className={classes['icon_zoom']}>
                        <IconMinimize />
                    </div>

                    <div onClick={() => showVisible(false)} className={classes['icon_close']}>
                        <IconClose fontSize={20} color='white' />
                    </div></div>} />
                <div className={classes['custom-search']}>
                    <CustomFormProvider {...methods}>
                        <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                            <FormSearch className={classes['custom-form-search']}>
                                <FormGroup flexWrap>
                                    <FormItem minWidth={200} maxWidth={200}>
                                        <FormInput placeholder={t("search_by_word")} isSearch iconSearch={<IconSearch fontSize={14} />} fieldName='keyword' validate={[]} />
                                    </FormItem>
                                    {
                                        renderDataFilter(params)
                                    }
                                    <ButtonFilter onClick={handleOpenAddSearchCriteria} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                </FormGroup>
                            </FormSearch>
                            <button hidden type='submit' />
                        </form>
                    </CustomFormProvider>
                </div>
                <div className={classes['custom-body']}>
                    <TableAlarm loadingGetAlarm={loadingGetAlarm} listAlarm={listAlarm} />
                    {/* <div className={classes['custom-pagination']}>
                        <CustomPagination
                            isRowPerpage
                            onChangeRowPerpage={onChangeRowPerpage} onChange={onPageChange} total={listAlarm?.total} limit={router.get(Constants.QueryParams.LIMIT.VALUE)} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
                    </div> */}
                </div>
            </div>
            {/* </WrapContentBody> */}
        </>
    )
}

export default AlarmListPopup