import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react';
import FaqContainer from 'shared/container/faq/FaqContainer';

function FaqPage() {
    const { t } = useTrans()
    useEffect(() => {
        document.title = "FAQ"
    }, [])

    return <FaqContainer />
}

export default FaqPage;
