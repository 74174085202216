import React from 'react';
import FormWarehouseOut from 'shared/components/warehouse/warehouse-out/FormWarehouseOut';
import { BrowserView, MobileView } from 'react-device-detect';

function WarehouseOutContainer() {
    return (
        <>
            <BrowserView>
                <FormWarehouseOut minWidth="250px" />
            </BrowserView>
            <MobileView>
                <FormWarehouseOut minWidth='100%' propsLayout={{
                    padding: 10, margin: 15, 
                    width: "calc(100% - 30px)"
                }} />
            </MobileView>
        </>
    )
}

export default WarehouseOutContainer