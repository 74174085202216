import React from 'react'

function IconDropDrap() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 8 18" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M4 0.25C4.2022 0.25 4.39584 0.316457 4.537 0.434299L7.537 2.93877C7.82616 3.18017 7.82015 3.56667 7.52358 3.80203C7.227 4.0374 6.75216 4.0325 6.463 3.7911L4 1.73493L1.537 3.7911C1.24784 4.0325 0.773002 4.0374 0.476425 3.80203C0.179848 3.56667 0.173838 3.18017 0.463 2.93877L3.463 0.434299C3.60416 0.316457 3.7978 0.25 4 0.25Z" fill="#707070" />
                <path fillRule="evenodd" clipRule="evenodd" d="M4 0.25C4.41421 0.25 4.75 0.523315 4.75 0.860465V17.1395C4.75 17.4767 4.41421 17.75 4 17.75C3.58579 17.75 3.25 17.4767 3.25 17.1395V0.860465C3.25 0.523315 3.58579 0.25 4 0.25Z" fill="#707070" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.476425 14.1978C0.773002 13.9624 1.24784 13.9673 1.537 14.2087L4 16.2649L6.463 14.2087C6.75216 13.9673 7.227 13.9624 7.52358 14.1978C7.82015 14.4332 7.82616 14.8196 7.537 15.061L4.537 17.5655C4.39584 17.6834 4.2022 17.7498 4 17.7498C3.7978 17.7498 3.60416 17.6834 3.463 17.5655L0.463 15.061C0.173838 14.8196 0.179848 14.4332 0.476425 14.1978Z" fill="#707070" />
            </svg></>
    )
}

export default IconDropDrap