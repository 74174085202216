import React, { useState } from 'react'
import classes from './ListChannel.module.scss';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageMessage from 'shared/components/common/image/ImageMessage';

function ListChannel(props) {
    const { listChannel, handleSelectChannel, loadMoreChannel } = props;
    const router = useRouter();
    const [processing, setProcessing] = useState(false);

    const loadMore = () => {
        const nextOffset = listChannel?.page * Constants.PAGINATION.LIMIT_MESSAGE;
        if (!processing && nextOffset < listChannel?.total && listChannel?.records?.length > 0) {
            setProcessing(true);
            loadMoreChannel(listChannel?.page, listChannel, "", () => {
                setProcessing(false)
            })
        }
    }
    return (
        <div id='scrollableDiv' className={classes['ListChannel']}>
            {
                listChannel?.records?.length > 0 && <InfiniteScroll
                    dataLength={listChannel?.records?.length}
                    hasMore={
                        listChannel?.records?.length < listChannel?.total
                    }
                    next={loadMore}
                    scrollableTarget="scrollableDiv"
                >
                    {
                        listChannel?.records && listChannel?.records?.map((x, key) => {
                            return <div onClick={(e) => {
                                e.stopPropagation();
                                handleSelectChannel(x?.id)
                            }} key={key} className={`${classes['item-message']} ${router.get(Constants.QueryParams.CHANNEL.VALUE) == x?.id ? classes['item-message-active'] : ''}`}>
                                <div className={classes['item-message-info']}>
                                    <div className={classes['avatar-user']}>
                                        <ImageMessage src={x?.avatar || ''} />
                                    </div>
                                    <div className={classes['message-info']}>
                                        <div className={classes['username']}>
                                            {x?.name}
                                        </div>
                                        <div className={classes['message-content']}>
                                            <p className={classes['message-new']}>
                                                {x?.messageContent ? x?.messageContent : x?.attachmentLink}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes['message-time']}>
                                    <p className={classes['time']}>{x?.updatedAt ? moment(x?.updatedAt).format("HH:mm") : ''}</p>
                                    {
                                        x?.countUnseen ? <div className={classes['count']}>
                                            {x?.countUnseen}
                                        </div> : <></>
                                    }
                                </div>
                            </div>
                        })
                    }
                </InfiniteScroll>
            }
        </div>
    )
}

export default ListChannel