import React from 'react';
import styles from "./Box.module.scss";
import PropsType from 'prop-types';
function BoxRow(props) {
    return (
        <div style={props?.style} className={styles["BoxRow"]}>
            {props?.children}
        </div>
    )
}

BoxRow.propTypes = {
    id: PropsType.string,
    style: PropsType.any,
}

export default BoxRow;