import useRouter from 'hooks/use-router';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Constants from 'utils/Constants';

export default function TableSkeleton() {
    const router = useRouter();
    return (
        <div style={{ lineHeight: 1.5 }}>
            <Skeleton count={router.get(Constants.QueryParams.LIMIT.VALUE) ?? 10} height={22} />
        </div>
    );
}
