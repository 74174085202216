const SystemSettingAction = {
    GET_HOLON_MASTER_SYSTEM : "GET_HOLON_MASTER_SYSTEM",
    GET_HOLON_MASTER_SYSTEM_SUCCESS : "GET_HOLON_MASTER_SYSTEM_SUCCESS",
    GET_HOLON_MASTER_SYSTEM_FAILED : "GET_HOLON_MASTER_SYSTEM_FAILED",

    UPDATE_HOLON_MASTER_SYSTEM : "UPDATE_HOLON_MASTER_SYSTEM",
    UPDATE_HOLON_MASTER_SYSTEM_SUCCESS : "UPDATE_HOLON_MASTER_SYSTEM_SUCCESS",
    UPDATE_HOLON_MASTER_SYSTEM_FAILED : "UPDATE_HOLON_MASTER_SYSTEM_FAILED",
}

export default SystemSettingAction;