import ApiOperation from "adapter/ApiOperation"

const factory = {
    getAllTemplateOcr: () => {
        let url = "/rest/api/v1/management/ocr/ocr-template-select";
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getOcrTable: () => {
        let url = "/rest/api/v1/management/ocr/ocr-table";
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getDataFromTemplate: (payload) => {
        let url = `/rest/api/v1/management/ocr/coordinates`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: {
                file: payload?.file,
                document_id: payload?.document_id
            }
        })
    },
    getDataByDocumentIdAndMultipartFile: (payload) => {
        let url = `/rest/api/v1/management/ocr/coordinates-v2`;
        const formData = new FormData();
        formData.append('file', payload?.file);
        formData.append('document_id', payload?.document_id);
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: formData
        })
    },
}

export default factory;