import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionWallet from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";
import i18n from "i18nextInit"

function* getListWallet() {
    yield takeEvery(actionWallet.GET_WALLET_LIST, function* (payload) {
        try {
            const response = yield call(() => factory.getListWallet(payload?.payload));
            const data = response?.records?.map(x => {
                x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                return x;
            });
            yield put({
                type: actionWallet.GET_WALLET_LIST_SUCCESS,
                payload: {
                    ...response,
                    records: data
                }
            })
        } catch (error) {

        }
    })
}

function* permission() {
    yield takeEvery(actionWallet.GET_PERMISSION_WALLET, function* () {
        try {
            const response = yield call(() => factory.permission());
            yield put({
                type: actionWallet.GET_PERMISSION_WALLET_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}

function* updateListWallet() {
    yield takeEvery(actionWallet.UPDATE_WALLET, function* (payload) {
        try {
            const response = yield call(() => factory.updateWallet(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionWallet.UPDATE_WALLET_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield put({
                    type: actionWallet.UPDATE_WALLET_FAILED,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {

        }
    })
}

function* createWallet() {
    yield takeEvery(actionWallet.CREATE_WALLET, function* (payload) {
        try {
            const response = yield call(() => factory.createWallet(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionWallet.CREATE_WALLET_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                yield put({
                    type: actionWallet.CREATE_WALLET_FAILED,
                })
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {

        }
    })
}

function* deleteWallet() {
    yield takeEvery(actionWallet.DELETE_WALLET, function* (payload) {
        try {
            const response = yield call(() => factory.deleteWallet(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionWallet.DELETE_WALLET_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                yield put({
                    type: actionWallet.DELETE_WALLET_FAILED,
                })
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed()
            yield put({
                type: actionWallet.DELETE_WALLET_FAILED,
            })

            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

function* getWalletDetail() {
    yield takeEvery(actionWallet.GET_WALLET_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailWallet(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data);
            }
            yield put({
                type: actionWallet.GET_WALLET_DETAIL_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* getHolonTemplateWallet() {
    yield takeEvery(actionWallet.GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplateDetail());
            yield put({
                type: actionWallet.GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}

function* createWalletCharge() {
    yield takeEvery(actionWallet.CREATION_WALLET_CHARGE, function* (payload) {
        try {
            const response = yield call(() => factory.creationWalletCharge(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data?.message)
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.message ? i18n.t(response?.error?.message ) : response?.error?.detail?.exception[0])
            }
        } catch (error) {

        }
    })
}

function* createWalletWithDraw() {
    yield takeEvery(actionWallet.CREATION_WALLET_WITHDRAW, function* (payload) {
        try {
            const response = yield call(() => factory.creationWalletWithdraw(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data?.message)
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.message ? i18n.t(response?.error?.message ) : response?.error?.detail?.exception[0])
            }
        } catch (error) {

        }
    })
}

function* paymentInfo() {
    yield takeEvery(actionWallet.PAYMENT_INFO, function* (payload) {
        try {
            const response = yield call(() => factory.paymentInfo(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data)
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.message ? i18n.t(response?.error?.message ) : response?.error?.detail?.exception[0])
            }
        } catch (error) {

        }
    })
}

function* paymentInfoWithdraw() {
    yield takeEvery(actionWallet.PAYMENT_INFO_WITHDRAW, function* (payload) {
        try {
            const response = yield call(() => factory.paymentInfoWithDraw(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data)
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.message ? i18n.t(response?.error?.message ) : response?.error?.detail?.exception[0])
            }
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListWallet),
        fork(updateListWallet),
        fork(createWallet),
        fork(deleteWallet),
        fork(getWalletDetail),
        fork(getHolonTemplateWallet),
        fork(createWalletCharge),
        fork(createWalletWithDraw),
        fork(paymentInfo),
        fork(paymentInfoWithdraw),
        fork(permission)
    ])
}