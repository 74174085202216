import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionReceiver from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";

function* getListReceiver() {
    yield takeEvery(actionReceiver.GET_RECEIVER_LIST, function* (payload) {
        try {
            const response = yield call(() => factory.getListReceiver(payload?.payload));
            if (response?.records) {
                const data = response?.records?.map(x => {
                    x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                    x.connection = x?.connection?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur }), {});
                    return x;
                });
                yield put({
                    type: actionReceiver.GET_RECEIVER_LIST_SUCCESS,
                    payload: {
                        ...response,
                        records: data
                    }
                })
            } else {

                yield put({
                    type: actionReceiver.GET_RECEIVER_LIST_SUCCESS,
                    payload: {
                        ...response,
                    }
                })
            }
        } catch (error) {

        }
    })
}

function* updateListReceiver() {
    yield takeEvery(actionReceiver.UPDATE_RECEIVER, function* (payload) {
        try {
            const response = yield call(() => factory.updateReceiver(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionReceiver.UPDATE_RECEIVER_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield put({
                    type: actionReceiver.UPDATE_RECEIVER_FAILED,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

function* createReceiver() {
    yield takeEvery(actionReceiver.CREATE_RECEIVER, function* (payload) {
        try {
            const response = yield call(() => factory.createReceiver(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success()
                yield put({
                    type: actionReceiver.CREATE_RECEIVER_SUCCESS,
                    payload: response
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
                yield put({
                    type: actionReceiver.CREATE_RECEIVER_FAILED,
                })
            }
        } catch (error) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

function* getReceiverDetail() {
    yield takeEvery(actionReceiver.GET_RECEIVER_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailReceiver(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data);
            }
            yield put({
                type: actionReceiver.GET_RECEIVER_DETAIL_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* deleteReceiver() {
    yield takeEvery(actionReceiver.DELETE_RECEIVER, function* (payload) {
        try {
            const response = yield call(() => factory.deleteReceiver(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed();
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed();
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

function* getHolonTemplateReceiver() {
    yield takeEvery(actionReceiver.GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplateDetail(payload?.payload));
            yield put({
                type: actionReceiver.GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListReceiver),
        fork(updateListReceiver),
        fork(createReceiver),
        fork(getReceiverDetail),
        fork(getHolonTemplateReceiver),
        fork(deleteReceiver),
    ])
}