import React from 'react';

function IconInput({ color = 'white' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="41"
            viewBox="0 0 40 40"
            fill="none"
        >
            <path d="M38.8465 6.90697L33.0933 1.15582C31.5524 -0.384337 29.058 -0.385743 27.5165 1.15442L23.787 4.88394L5.56236 23.1127C4.80673 23.8683 4.22894 24.7801 3.86734 25.7849L0.173438 36.0657C-0.211758 37.1385 0.0562384 38.3366 0.862338 39.1427C1.66781 39.9481 2.86723 40.2162 3.93938 39.8302L14.2188 36.1351C15.2236 35.7741 16.1361 35.195 16.891 34.4407L34.1432 17.1877L34.1439 17.1884L35.119 16.2119L38.8465 12.483L38.8478 12.4817C40.382 10.9422 40.388 8.44916 38.8465 6.90697ZM7.51264 25.063L25.6013 6.97096L28.8284 10.1981L10.461 28.5655L7.25066 25.3559C7.33481 25.2556 7.41966 25.1559 7.51264 25.063ZM13.286 33.5389L6.73615 35.8933L4.11041 33.2675L6.46409 26.7182C6.48089 26.6704 6.50449 26.6253 6.52261 26.5781L13.4254 33.4803C13.3783 33.4992 13.3332 33.5221 13.286 33.5389ZM14.9407 32.4904C14.8477 32.5827 14.7481 32.6682 14.6478 32.7524L11.436 29.5408L29.8035 11.1733L33.03 14.3997L14.9407 32.4904ZM36.8955 10.5334L34.1432 13.2865L34.0052 13.4245L26.5764 5.99578L29.4675 3.1047C29.9295 2.64473 30.679 2.6441 31.1431 3.10673L36.8956 8.85725C37.3583 9.31855 37.3589 10.0682 36.8936 10.5348L36.8955 10.5334Z" fill="white" />
        </svg>
    );
}

export default IconInput;
