import React from 'react';

function IconMinusAccordion({ fontSize = 18, color = "#929292" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fontSize={fontSize}
            viewBox="0 0 19 18"
            fill="none"
        >
            <ellipse cx="9.98821" cy="9" rx="8.99211" ry="9" fill={color} />
            <path
                d="M5.99121 9H13.9842"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconMinusAccordion;
