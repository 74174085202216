import useRouter from 'hooks/use-router';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Constants from 'utils/Constants';
import useCustomState from 'hooks/use-custom-state';
import { useDispatch, useSelector } from 'react-redux';
import FaqAction from 'redux/faq/action';
import Faq from 'shared/components/faq/Faq';

function FaqContainer() {
    const router = useRouter();
    const methods = useForm();
    const { faqCategoryMaster, faq } = useSelector(state => state.faq);
    const [activeCategory, setActiveCategory] = useCustomState(3);
    const [activeAccordion, setActiveAccordion] = useCustomState(null);
    const [dataFaqCategory, setFaqCategory] = useCustomState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (router.get(Constants.QueryParams.SEARCH.VALUE)) {
            methods.setValue("Search", router.get(Constants.QueryParams.SEARCH.VALUE))
        } else {
            methods.setValue("Search", "")
        }
    }, [router.get(Constants.QueryParams.SEARCH.VALUE)])


    useEffect(() => {
        if (faqCategoryMaster) {
            setFaqCategory(faqCategoryMaster?.records);
        }
    }, [faqCategoryMaster])

    useEffect(() => {
        if (faqCategoryMaster?.records?.length == 0) {
            dispatch({
                type: FaqAction.GET_FAQ_WITH_CATEGORY_SUCCESS,
                payload: {
                    records: [],
                    limit: 0,
                    page: 1,
                    total: 0,
                    totalPage: 1
                }
            })

            return;
        }
        if (router.get(Constants.QueryParams.TAB.VALUE)) {
            setActiveCategory(router.get(Constants.QueryParams.TAB.VALUE));
            dispatch({
                type: FaqAction.GET_FAQ_WITH_CATEGORY,
                payload: {
                    ...router.getAll(),
                }
            })
        } else {
            setActiveCategory(faqCategoryMaster?.records?.[0]?.id)
            dispatch({
                type: FaqAction.GET_FAQ_WITH_CATEGORY,
                payload: {
                    categoryId: faqCategoryMaster?.records?.[0]?.id,
                    [Constants.QueryParams.SEARCH.VALUE]: router.get(Constants.QueryParams.SEARCH.VALUE)
                }
            })
        }
    }, [router.get(Constants.QueryParams.TAB.VALUE),
        faqCategoryMaster?.records?.[0]?.id, router.get(Constants.QueryParams.SEARCH.VALUE)]);

    const onSubmit = (data) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.SEARCH.VALUE]: data?.Search,
            },
        });
    };

    const onChangeCategory = (e) => {
        router.replace({
            params: {
                [Constants.QueryParams.TAB.VALUE]: e,
            },
        });
    };

    return (
        <Faq
            onSubmit={onSubmit} onChangeCategory={onChangeCategory}
            activeCategory={activeCategory}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
            dataFaqCategory={dataFaqCategory}
            faq={faq}
            methods={methods}
        />
    );
}

export default FaqContainer;
