import React from 'react'
import WrapContentBody from '../custom-body/WrapContentBody'
import classes from './StockInventoryPopup.module.scss';
import Button from 'shared/components/form-input/button/Button';
import IconClose from 'assets/icon/IconClose';
import useTrans from 'hooks/use-trans';
import CustomTable from '../../custom-table/CustomTable';
import BoxContentPopup from '../custom-body/BoxContentPopup';

function StockInventoryPopup(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const handleOke = () => {

    }
    return (
        <BoxContentPopup >
            <WrapContentBody
                isUseBackground={false}
                showVisible={showVisible} title={payload?.title} isUseBtnBack={false}
                actionSectionBottom={<Button onClick={handleOke} title={t("save")} />}
                useForm={false}
                actionSectionTop={<div onClick={() => showVisible()} className={classes['icon']}>
                    <IconClose color="#929292" fontSize={20} />
                </div>}
            >
                <CustomTable headCell={[]} data={[]} />
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default StockInventoryPopup