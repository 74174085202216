import useTrans from 'hooks/use-trans'
import React from 'react'
import AlarmContainer from 'shared/container/alarm/AlarmContainer'

function AlarmPage() {
    const {t} = useTrans()
    document.title = t("Holon_Template.alarm_holon_setting")
    return (
        <AlarmContainer />
    )
}

export default AlarmPage