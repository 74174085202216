import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import Utils from 'utils/Utils';
import SelectInstance from '../form-input/select-instance/SelectInstance';

function FormSelectInstance(props) {
    const {
        fieldName,
        validate = [],
        placeholder = '',
        disabled = false,
        options = [],
        textAlign = 'left',
        size = '',
        onChangeSelect,
        holonTemplate,
        isMulti,
        validationFE,
        isRegexLabel,
        connectionKey,
        id,
        isSelectForConnection
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const wrapRef = useRef();
    return (
        <div className='w-100' ref={wrapRef}>
            <Controller
                control={control}
                name={fieldName}
                rules={{ validate: Validator.genValidate(validate, fieldName) }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <SelectInstance
                            inputRef={ref}
                            onChange={(e) => {
                                onChange(e);
                                onChangeSelect && onChangeSelect(e);
                                Utils.triggerSubmit(wrapRef);
                            }}
                            value={value}
                            errors={errors}
                            disabled={disabled}
                            options={options}
                            placeholder={placeholder}
                            textAlign={textAlign}
                            name={fieldName}
                            onBlur={onBlur}
                            size={size}
                            isMulti={isMulti}
                            holonTemplate={holonTemplate}
                            validationFE={validationFE}
                            isRegexLabel={isRegexLabel}
                            connectionKey={connectionKey}
                            id={id}
                            isSelectForConnection={isSelectForConnection}
                        />
                    );
                }}
            />
        </div>
    );
}

export default FormSelectInstance