import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";
import Utils from "utils/Utils";

const factory = {
    getAlarmHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/alarm-setting-master`;
        let params = {}

        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE];
        }
        if (payload[Constants.QueryParams.MESSAGE.VALUE]) {
            params['content'] = payload[Constants.QueryParams.MESSAGE.VALUE];
        }
        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload[Constants.QueryParams.FROM.VALUE]) {
            params['fromDate'] = payload[Constants.QueryParams.FROM.VALUE]
        }
        if (payload[Constants.QueryParams.TO.VALUE]) {
            params['toDate'] = payload[Constants.QueryParams.TO.VALUE]
        }

        if (payload[Constants.QueryParams.TAB.VALUE]) {
            params['type'] = payload[Constants.QueryParams.TAB.VALUE];
        }

        if (payload[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.KEYWORD.VALUE];
        }

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    deleteAlarmHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/alarm-setting-master/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'DELETE',
        })
    },
    createAlarmHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/alarm-setting-master`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    updateAlarmHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/alarm-setting-master`;

        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    getAlarmHolonTemplateDetail: (payload) => {
        var url = `/rest/api/v1/management/alarm-setting-master/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getOwnerCollectionHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/holon-template/owner-connection/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getHolonTemplateDetail: (payload) => {
        var url = `/rest/api/v1/management/holon-template/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getMasterAlarmHolonTemplate: () => {
        var url = `/rest/api/v1/management/alarm-setting-master/master`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getMasterHolonTemplate: (payload) => {
        var url = '/rest/api/v1/management/holon-template';
        let params = {
            isAll: true,
            sortColumn: 'id',
            sortType: "asc",
            masterCodes: Constants.CODE_MASTER.USER_TEMPLATE.VALUE
        };

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
};

export default factory