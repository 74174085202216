import React, { useEffect, useState, useRef } from 'react'
import useOnClickOutside from 'hooks/use-onclick-outside';
import Button from 'shared/components/form-input/button/Button';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import classes from './Dropdown.module.scss'

function DropdownOptions(prop) {
    const { list = [] } = prop;
    return (
        <div>
            {
                list.map((item, index) => {
                    return <div
                        key={index}
                        onClick={item?.onClick}
                        className={classes['dropdown-option-container']}
                    >
                        <div className={classes['option']}>
                            {item?.name}
                        </div>
                    </div>
                })
            }
        </div>
    )
}

function Dropdown(props) {
    const { list=[], anchorEl, setAnchorEl } = props
    const dropDownMenuRef = useRef()

    useOnClickOutside(dropDownMenuRef, () => {
        setAnchorEl(false);
    })

    return (
        <div ref={dropDownMenuRef} className={classes['dropdown-container']}>
            <Button onClick={() => setAnchorEl(!anchorEl)} color='yellow' size='large' startIcon={<IconPlusWhite />}/>
            {anchorEl && (
                <div className={classes['dropdown-option-list']}>
                    <DropdownOptions list={list} />
                </div>
            )}
        </div>
    )
}

export default Dropdown;
