import React from 'react';
import useTrans from 'hooks/use-trans';
import styles from './PrivacyPolicyPopup.module.scss'

const PrivacyPolicyPopup = () => {
    const { trans } = useTrans();

    return <>
        <div className={`modal-body ${styles['policy-container']}`}>
            <div className={styles.title}>
                {trans("privacy_policy.title")}
            </div>
            <div className={styles.content}>
                <div >
                    {trans("privacy_policy.first_row")}
                </div>
                <div>
                    {trans("privacy_policy.second_row")}
                </div>
                <div>
                    {trans("privacy_policy.third_row")}
                </div>
            </div>
        </div>
    </>
};

export default PrivacyPolicyPopup;