import React, { useEffect, useState } from 'react';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import Box from 'shared/components/common/box/Box';
import FormInput from 'shared/components/custom-form/FormInput';
import BoxRow from 'shared/components/common/box/BoxRow';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import BoxContent from 'shared/components/common/box/BoxContent';
import Validator from 'utils/Validator';
import Button from 'shared/components/form-input/button/Button';
import BoxBody from 'shared/components/common/box/BoxBody';
import { getMessageCommon, showPopupNotification } from 'utils/Utils';
import { useDispatch } from 'react-redux';
import actionWareHouse from 'redux/warehouse-in/action';
import FormFileWarehouse from 'shared/components/custom-form/FormFileWarehouse';
import classes from './FormWarehouseOut.module.scss'
import { useParams } from 'react-router-dom';
import CommonQrReader from 'shared/components/common/qr-reader/CommonQrReader';

function FormWarehouseOut(props) {
    const { minWidth = 250, propsLayout } = props;
    const { t } = useTrans();
    const methods = useForm();
    const methodsScan = useForm();
    const dispatch = useDispatch();

    const { id } = useParams();
    const [openQr, setOpenQR] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingInfo, setLoadingInfo] = useState(false)
    const [infoWarehouse, setInfoWarehouse] = useState(null)

    const handleSubmit = (data) => {
        showPopupNotification({
            typePopup: 'confirm',
            message: Validator.renderMessage(t("I0006"), {
                0: t("warehouse_out.confirmation_of_shipping")
            }),
            callback: () => {
                setLoading(true)
                dispatch({
                    type: actionWareHouse.CREATE_WAREHOUSE_OUT,
                    payload: {
                        ...data,
                    },
                    callback: {
                        success: (data, error) => {
                            setLoading(false);
                            showPopupNotification({
                                typePopup: 'message',
                                message: getMessageCommon({
                                    response: data,
                                    error: error,
                                    actionName: t("warehouse_out.confirmation_of_shipping")
                                }),
                                newWindow: true,
                            })

                            methods.reset({});
                            methodsScan.setValue("Scan", "");
                            setInfoWarehouse(null)
                        },
                    }
                })
            }
        })
    }

    useEffect(() => {
        if (id) {
            methodsScan.setValue("Scan", id)
            setLoadingInfo(true)
            dispatch({
                type: actionWareHouse.GET_WAREHOUSE_OUT_BARCODE,
                payload: id,
                callback: {
                    success: (data) => {
                        setLoadingInfo(false)
                        if (data?.errorCode) {
                            showPopupNotification({
                                typePopup: 'message',
                                message: data?.errorCode
                            });
                            setInfoWarehouse(null)
                        } else {
                            methods.setValue("hexOrderNo", data?.hexOrderNo)
                            methods.setValue("cargoNo", data?.cargoNo)
                            methods.setValue("slipNumber", data?.slipNumber)
                            setInfoWarehouse(data)
                        }
                    },
                    failed: () => {
                        setLoadingInfo(true);
                        setInfoWarehouse(null)
                    }
                }
            })
        }
    }, [id])


    const handleGetDataFromBarcode = (e) => {
        setLoadingInfo(true);
        dispatch({
            type: actionWareHouse.GET_WAREHOUSE_OUT_BARCODE,
            payload: e,
            callback: {
                success: (data) => {
                    setLoadingInfo(false)
                    if (data?.errorCode) {
                        showPopupNotification({
                            typePopup: 'message',
                            message: data?.errorCode
                        });
                        setInfoWarehouse(null)
                    } else {
                        methods.setValue("hexOrderNo", data?.hexOrderNo)
                        methods.setValue("cargoNo", data?.cargoNo)
                        methods.setValue("slipNumber", data?.slipNumber);
                        setInfoWarehouse(data)
                    }
                },
                failed: () => {
                    setLoadingInfo(true);
                    setInfoWarehouse(null)
                }
            }
        })
    }

    const onSubmitScan = (data) => {
        handleGetDataFromBarcode(data?.Scan);
    }

    return (
        <ContentLayout {...propsLayout}>
            <Breadcrumb>{t("warehouse_out.confirmation_of_shipping")}</Breadcrumb>
            <CustomFormProvider {...methodsScan}>
                <form className={classes['form-action']} onSubmit={methodsScan.handleSubmit(onSubmitScan)}>
                    <BoxRow style={{
                        flexWrap: 'nowrap',
                        gap: 20
                    }}>
                        <FormInput
                            validationFE
                            validate={[]}
                            isRegexLabel=""
                            fieldName='Scan'
                            disabled={id ? true : false}
                        />
                        <Button className='btn-form' disabled={id ? true : false} loading={loadingInfo} type='button' onClick={() => setOpenQR(!openQr)} title={t("warehouse_in.scan")} />
                    </BoxRow>
                </form>
            </CustomFormProvider>
            <CustomFormProvider {...methods}>
                <form className={classes['quick-submit']} onSubmit={methods.handleSubmit(handleSubmit)}>
                    <Box>
                        <BoxBody padding='10px 0px'>
                            {
                                openQr && <BoxRow style={{
                                    justifyContent: 'center'
                                }}>
                                    <CommonQrReader openQR={openQr} handleOpenQrCode={(e) => setOpenQR(e)} callback={(e) => {
                                        methodsScan.setValue("Scan", e);
                                        handleGetDataFromBarcode(e)
                                    }} />
                                </BoxRow>
                            }
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.hex_order_number")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("warehouse_in.hex_order_number")}
                                        fieldName='hexOrderNo'
                                        disabled
                                        placeholder={t("warehouse_in.hex_order_number")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.cargo_number")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("warehouse_in.cargo_number")}
                                        fieldName='cargoNo'
                                        disabled
                                        placeholder={t("warehouse_in.cargo_number")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.cargo_tracking_number")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("warehouse_in.cargo_tracking_number")}
                                        fieldName='slipNumber'
                                        disabled
                                        placeholder={t("warehouse_in.cargo_tracking_number")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth} isRequired>{t("warehouse_out.out_going_photo")}</BoxLabel>
                                <BoxContent >
                                    <FormFileWarehouse
                                        validationFE
                                        validate={[Validator.required]}
                                        isRegexLabel={t("warehouse_out.out_going_photo")}
                                        fieldName='attachments'
                                        format={infoWarehouse?.cargoHexId ? `${infoWarehouse?.cargoHexId}_out_[ddMMyy(date)]_[HHmmss(date)].[ext]` : null}
                                        placeholder={t("warehouse_out.out_going_photo")} />
                                </BoxContent>
                            </BoxRow>
                        </BoxBody>
                    </Box>
                    <div className={classes['action-button']}>
                        <Button className='btn-action' loading={loading} title={t("warehouse_out.confirm_shipping")} type='submit' />
                    </div>
                </form>
            </CustomFormProvider>
        </ContentLayout>
    )
}

export default FormWarehouseOut