const { default: FaqAction } = require("./action");

const initialState = {
    faqCategory: {
        records: [],
        limit: 0,
        page: 1,
        total: 0,
        totalPage: 1
    },
    faqCategoryMaster: {
        records: [],
        limit: 0,
        page: 1,
        total: 0,
        totalPage: 1
    },
    faqCategoryDetail: {},
    faq: {
        records: [],
        limit: 0,
        page: 1,
        total: 0,
        totalPage: 1
    },
    faqDetail: {},
    loadingAddFaq: false,
    loadingUpdateFaq: false,
    loadingDeleteFaq: false,
    loadingAddCategoryFaq: false,
    loadingUpdateCategoryFaq: false,
    loadingDeleteCategoryFaq: false,
    faqCategoryPopup: {},
    loadingGetList: false
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case FaqAction.GET_FAQ_CATEGORIES_SUCCESS:
            return {
                ...state,
                faqCategory: action?.payload
            }
        case FaqAction.GET_FAQ_CATEGORIES_POPUP_SUCCESS:
            return {
                ...state,
                faqCategoryPopup: action?.payload
            }
        case FaqAction.GET_FAQ_CATEGORIES_MASTER_SUCCESS:
            return {
                ...state,
                faqCategoryMaster: action?.payload
            }
        case FaqAction.GET_FAQ_WITH_CATEGORY_SUCCESS:
            return {
                ...state,
                faq: action?.payload,
                loadingGetList: false
            }
        case FaqAction.GET_FAQ_WITH_CATEGORY_FAILED:
            return {
                ...state,
                loadingGetList: false
            }
        case FaqAction.GET_FAQ_WITH_CATEGORY:
            return {
                ...state,
                loadingGetList: true
            }
        case FaqAction.GET_DETAIL_FAQ_CATEGORIES_SUCCESS:
            return {
                ...state,
                faqCategoryDetail: action?.payload
            }
        case FaqAction.GET_DETAIL_FAQ_WITH_CATEGORIES_SUCCESS:
            return {
                ...state,
                faqDetail: action?.payload
            }
        case FaqAction.CREATE_FAQ_WITH_CATEGORY:
            return {
                ...state,
                loadingAddFaq: true
            }
        case FaqAction.CREATE_FAQ_WITH_CATEGORY_SUCCESS:
            return {
                ...state,
                loadingAddFaq: false
            }
        case FaqAction.CREATE_FAQ_WITH_CATEGORY_FAILED:
            return {
                ...state,
                loadingAddFaq: false
            }
        case FaqAction.ADD_FAQ_CATEGORY:
            return {
                ...state,
                loadingAddCategoryFaq: true
            }
        case FaqAction.ADD_FAQ_CATEGORY_SUCCESS:
            return {
                ...state,
                loadingAddCategoryFaq: false
            }
        case FaqAction.ADD_FAQ_CATEGORY_FAILED:
            return {
                ...state,
                loadingAddCategoryFaq: false
            }
        case FaqAction.DELETE_FAQ_WITH_CATEGORY:
            return {
                ...state,
                loadingDeleteFaq: true
            }
        case FaqAction.DELETE_FAQ_WITH_CATEGORY_SUCCESS:
            return {
                ...state,
                loadingDeleteFaq: false
            }
        case FaqAction.DELETE_FAQ_WITH_CATEGORY_FAILED:
            return {
                ...state,
                loadingDeleteFaq: false
            }

        case FaqAction.DELETE_FAQ_CATEGORY:
            return {
                ...state,
                loadingDeleteCategoryFaq: true
            }
        case FaqAction.DELETE_FAQ_CATEGORY_SUCCESS:
            return {
                ...state,
                loadingDeleteCategoryFaq: false
            }
        case FaqAction.DELETE_FAQ_CATEGORY_FAILED:
            return {
                ...state,
                loadingDeleteCategoryFaq: false
            }
        case FaqAction.UPDATE_FAQ_WITH_CATEGORY:
            return {
                ...state,
                loadingUpdateFaq: true
            }
        case FaqAction.UPDATE_FAQ_WITH_CATEGORY_SUCCESS:
            return {
                ...state,
                loadingUpdateFaq: false
            }
        case FaqAction.UPDATE_FAQ_WITH_CATEGORY_FAILED:
            return {
                ...state,
                loadingUpdateFaq: false
            }
        case FaqAction.UPDATE_FAQ_CATEGORY:
            return {
                ...state,
                loadingUpdateCategoryFaq: true
            }
        case FaqAction.UPDATE_FAQ_CATEGORY_SUCCESS:
            return {
                ...state,
                loadingUpdateCategoryFaq: false
            }
        case FaqAction.UPDATE_FAQ_CATEGORY_FAILED:
            return {
                ...state,
                loadingUpdateCategoryFaq: false
            }
        default:
            return { ...state }
    }
}
export default myReducer;