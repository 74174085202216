import React from 'react';

function IconChevronRight({ fontSize = 10, color = "#929292" }) {
    // 7
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 9 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fontSize={fontSize}
        >
            <path
                d="M1 0.999999L7 6L0.999999 11"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconChevronRight;
