import React, { useEffect, useState, useRef } from 'react';
import MenuItem from "./MenuItem";
import profile from '../../../assets/img/profile.png';
import logo from '../../../assets/img/hexlogo.png';
import useCustomState from 'hooks/use-custom-state';
import Constants from 'utils/Constants';
import { useForm } from 'react-hook-form';
import useRouter from 'hooks/use-router';
import i18n from "i18nextInit";
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux/app/action';
import UserAction from "redux/user/action";
import styles from './Menu.module.scss';
import useTrans from 'hooks/use-trans';
import actionUser from 'redux/user/action';
import CustomTooltip from 'shared/components/common/tooltip/CustomTooltip.js';
import Loading from 'shared/components/form-input/button/loading/Loading.js';
import Image from '../common/image/Image';
import IconChevronDownV2 from "assets/icon/IconChevronDownV2";
import MenuTree from './MenuTree';


function CustomDropdown() {
    const router = useRouter()
    const dispatch = useDispatch();
    const { t } = useTrans();
    const options = [
        {
            name: "menu_bar.user_dropdown.settings",
            path: "/account-settings",
        },
        {
            name: "menu_bar.user_dropdown.sign_out",
            isDivider: true,
            action: () => {
                dispatch({
                    type: UserAction.LOGOUT,
                    callback: {
                        success: () => {
                            router.push({
                                pathname: "/"
                            })
                        },
                        failed: () => {
                        }
                    }
                })
            }
        }
    ]
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { avatar_user, full_name_user } = useSelector(state => state.user);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        dispatch({
            type: actionUser.GET_ACCOUNT,
        })
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles['custom-dropdown']} ref={dropdownRef}>
            <div className={styles['dropdown-header']} onClick={toggleDropdown}>
                <div className={`${styles['user-logo']}`}>
                    <Image defaultImage={profile} src={avatar_user} alt="profile" width="40" height="40" className="rounded-circle" />
                    {/* <img src={(avatar_user == null || avatar_user == undefined || avatar_user == "") ? profile : avatar_user} alt="profile" width="40" height="40" className="rounded-circle" /> */}
                    <span className={`${styles['user-name']}`}>
                        {full_name_user ?? t("register_form.button.submit")}
                    </span>
                    <IconChevronDownV2 />
                    <CustomTooltip
                        textTooltip={full_name_user ?? 'BL Holon管理者'}
                        style={{
                            color: "#fff",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '150px',
                            margin: '0px 0px 0px -80px',
                            height: '20px',
                            fontSize: '16px',
                            position: 'absolute'
                        }}
                    />
                </div>
            </div>
            {isOpen && (
                <ul className={`${styles['dropdown-list']} ms-3`}>
                    {options.map((option, index) => (
                        <React.Fragment key={index}>
                            {option.isDivider &&
                                <li>
                                    <hr className={`dropdown-divider ${styles['divider']}`} />
                                </li>}
                            <li
                                key={index}
                                className={styles['dropdown-item']}
                                onClick={option.action && option.action}
                            >
                                <a onClick={e => option.path ?? e.preventDefault()} href={option.path}>{t(option.name)}</a>
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            )}
        </div>
    )
}

function Menu() {
    const [lang, setLang] = useCustomState(localStorage.getItem("i18nextLng") ?? Constants.T001.JP.CODE)
    const { menus, loadMenu, functions } = useSelector(state => state.app);
    const [collapseStates, setCollapseStates] = useState(Array(32).fill(false));
    const [optionLanguages, setOptionLanguages] = useCustomState([]);

    const methods = useForm();
    const router = useRouter()
    const dispatch = useDispatch();

    const toggleContent = (index) => {
        const collapsedStatesCopy = [...collapseStates];
        for (let i = 0; i < collapsedStatesCopy.length; i++) {
            if (i === index) {
                collapsedStatesCopy[i] = !collapsedStatesCopy[i];
            }
            else {
                collapsedStatesCopy[i] = false;
            }
        }
        setCollapseStates(collapsedStatesCopy);
    };

    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: Constants.T001[key].NAME,
            })
        });
        setOptionLanguages(data)
        i18n.on("languageChanged", (val) => {
            setLang(val)
        })
        return () => {
            i18n.off("languageChanged")
        }
    }, [])

    useEffect(() => {
        if (menus?.length == 0) {
            dispatch({
                type: Actions.GET_MENU
            });
        }

        return () => {
            dispatch({
                type: Actions.GET_MENU_RESPONSE,
                payload: []
            })
        };
    }, []);

    const GotoHome = (e) => {
        e.preventDefault()
        e.stopPropagation();
        router.push({
            pathname: "/portal"
        })
    }
    return (
        <div id='side-menu' className={`align-items-center align-items-sm-start text-white min-vh-100 h-100 ${styles['bg-menu']}`}>
            <div className="d-flex justify-content-center align-items-center w-100">
                <a onClick={GotoHome} href='/'>
                    <div className={`${styles['company-logo']} d-flex justify-content-center align-items-center`}>
                        {/* <span className="fs-5 d-sm-inline">ロゴ位置</span> */}
                        <img src={logo} />
                    </div>
                </a>
            </div>
            <CustomDropdown />
            {!loadMenu && (
                <div className={styles['loading']}>
                    <Loading />
                </div>
            )}
            {/* {menus?.map((route, index) => (
                route.length !== 0 ? (
                    <MenuItem item={route} key={index} index={index} isOpen={collapseStates[index]} toggleContent={toggleContent} />
                ) : null
            ))} */}
            <MenuTree items={menus} />
            {/* <div className={`${styles['button-lang']}`}>
                <select value={lang} className='w-100' onChange={(val) => {
                    i18n.changeLanguage(val.target.value)
                }}>
                    <option value="jp">JP</option>
                    <option value="en">EN</option>
                    <option value="cn">CN</option>
                </select>
            </div> */}
        </div>
    );
}


export default Menu;