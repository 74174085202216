import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import actionCargo from 'redux/cargo/action';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import Constants from 'utils/Constants';
import jsonpath from 'jsonpath';
import { actionsHolonInstance } from 'redux/holon-instance/action';

function CargoContainer() {
    const dispatch = useDispatch();
    const [list, setList] = useState([])
    const [warehouse, setWarehouse] = useState(null)
    const { user } = useSelector(state => state?.user);
    const { t } = useTrans();
    const router = useRouter();
    const [listTemplateCreation, setListTemplateCreation] = useState([]);

    useEffect(() => {
        dispatch({
            type: actionCargo.GET_TEMPLATE_CARGO,
            payload: {
                masterCodes: Constants.CARGO_MASTER_CODE.CARD_BOARD.MASTER_CODE
            },
            callback: {
                success: (data) => {
                    setList(data?.records?.map(x => x?.id));
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_TEMPLATE_WITH_IDS,
                        payload: data?.records?.map(x => x?.id)?.join(","),
                        callback: (_data) => {
                            setListTemplateCreation(_data?.map(x => ({ id: x?.id, name: x?.name})));
                        }
                    })
                }
            }
        })
        dispatch({
            type: actionCargo.GET_WARE_HOUSE_DEFAULT,
            callback: {
                success: (data) => {
                    if (data?.id) {
                        setWarehouse(data)
                    }
                }
            }
        })
    }, [])

    let dataPreset = {};
    if (router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)) {
        if (user?.id) {
            let holonUser = jsonpath.query(user?.connections, "$[?(@.type=='account')].holons[0]");
            dataPreset = {
                templateId: router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE),
                data: {
                    attributes: {},
                    connections: {
                        [Constants.CARGO_MASTER_CODE.CARD_BOARD.CONNECTION_KEY.ACCOUNT]: [{
                            value: holonUser[0]?.id,
                            name: holonUser[0]?.name
                        }]
                    }
                }
            };
        }
        if (warehouse?.id) {
            dataPreset = {
                ...dataPreset,
                data: {
                    ...dataPreset.data,
                    connections: {
                        ...dataPreset?.data?.connections,
                        [Constants.CARGO_MASTER_CODE.CARD_BOARD.CONNECTION_KEY.WAREHOUSE]: [{
                            value: warehouse?.id,
                            name: warehouse?.name
                        }],
                    }
                }
            }
        }
    }

    return (
        <>
            {
                list?.length > 0 && <HolonInstance
                    isCargo
                    template_id={list?.join(",")}
                    titleCreatePopup={t("cargo.cargo_creation")}
                    titleDetailPopup={t("cargo.cargo_detail")}
                    titleUpdatePopup={t("cargo.cargo_edit")}
                    title={t("cargo.cargo_management")}
                    isShowCategory
                    dataPreset={dataPreset}
                    typeCategory={Constants.CARGO_MASTER_CODE.CARD_BOARD.CATEGORY}
                    listTemplateCreation={listTemplateCreation}
                />
            }
        </>
    )
}

export default CargoContainer