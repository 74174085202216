import React, { useEffect, useState } from 'react'
import classes from './Stocks.module.scss';
import { useDispatch } from 'react-redux';
import useTrans from 'hooks/use-trans';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import IconEdit from 'assets/icon/IconEdit';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_UPDATE_PRODUCT_POPUP } from 'utils/EventRegister';
import { convertNumberToString } from 'utils/String';
import useRouter from 'hooks/use-router';
import actionStocks from 'redux/stocks/action';

function StocksDetailPopup(props) {
    const { payload, showVisible } = props;
    const router = useRouter();
    const dispatch = useDispatch();
    const { t } = useTrans();
    const [stock, setStock] = useState(null)
    const [loadingGetDetail, setLoadingGetDetail] = useState(false)

    useEffect(() => {
        if (payload?.stockId) {
            setLoadingGetDetail(true)
            dispatch({
                type: actionStocks.GET_DETAIL_STOCKS_POPUP,
                payload: payload?.stockId,
                callback: (data) => {
                    setStock(data);
                    setLoadingGetDetail(false)
                }
            })
        }
    }, [payload?.stockId])

    const handleEdit = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_UPDATE_PRODUCT_POPUP,
            payload: {
                title: t("product.product_update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                stockId: payload?.stockId,
                callback: () => {
                },
            }
        })
    }

    const handleDelete = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            newWindow: true,
            payload: {
                title: t("Holon_Template.confirm_delete"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionStocks.DELETE_STOCKS,
                        payload: payload?.stockId,
                        callback: {
                            success: () => {
                                dispatch({
                                    type: actionStocks.GET_LIST_STOCKS,
                                    payload: {
                                        ...router.getAll()
                                    }
                                })
                            }
                        },
                    })
                }
            }
        })
    }

    return (
        <BoxContentPopup>
            <WrapContentBody
                title={payload?.title} showVisible={showVisible} payload={payload}
                actionSectionTop={<>
                    <Button onClick={handleEdit} color='gray' startIcon={<IconEdit color="currentColor" />} /></>}
                actionSectionBottom={<Button onClick={handleDelete} loading={loadingGetDetail} type='button' title={t("Holon_Template.delete")} />}
            >
                <div className={classes['created-holon-instance']}>
                    <Box>
                        <BoxBody className={classes['data-attributes-body']}>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.gs1_code")}</BoxLabel>
                                <BoxContent>
                                    {stock?.gs1_code}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.product_name")}</BoxLabel>
                                <BoxContent>
                                    {stock?.item_name}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.product_price")}</BoxLabel>
                                <BoxContent>
                                    {convertNumberToString(stock?.price, ",")}
                                </BoxContent>
                            </BoxRow>
                        </BoxBody>
                    </Box>
                </div>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default StocksDetailPopup