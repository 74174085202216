import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import uncheckedIcon from 'assets/img/unchecked-icon.svg';
import checkedIcon from 'assets/img/checked-icon.svg';
import useTrans from 'hooks/use-trans';

function FormRadio(props) {
    const {
        name,
        validate = [],
        fieldName = "label",
        fieldValue = "value",
        master = [],
        callback,
        value,
        checkedValue = null,
        defaultValue = null,
        disabled = false,
        field = ''
    } = props
    const { formState: { errors }, control } = useFormContext();
    const { trans } = useTrans();

    const [checked, setChecked] = useState(checkedValue);
    const onChangeRadio = (e, onChange) => {
        if (callback) {
            callback(e[fieldValue]);
        }
        if (onChange) {
            onChange(e[fieldValue]);
        }
        setChecked(e[fieldValue])
    };

    useEffect(() => {
        if (value) {
            // onChange(value)
            setChecked(value)
        }
    }, [value]);
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{ validate: Validator.genValidate(validate, name) }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return <React.Fragment>
                    <div className='radio-btn-container'>
                        {master?.map((item, index) => {
                            return <div className='custom-radio' key={index}
                                onClick={() => !disabled ? onChangeRadio(item, onChange) : null}
                            >
                                <div
                                    className='d-flex align-items-center radio-button-container'
                                >
                                    <input
                                        _props={{ inputRef: ref }}
                                        name={name}
                                        id={item[fieldName]}
                                        type='radio'
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={item[fieldValue]}
                                        checked={`${((checked || value) == item[fieldValue] ? 'checked' : '')}`}
                                    />
                                    <div className={`${!disabled ? 'radio-icon pointer' : 'radio-icon'}`}
                                    >
                                        <img className="unchecked-icon" src={uncheckedIcon} alt="Uncheck Icon" />
                                        <img className="checked-icon" src={checkedIcon} alt="Check Icon" />
                                    </div>
                                </div>
                                <label htmlFor={item[fieldName]} className={`${!disabled ? 'radio-name pointer' : 'radio-name'}`}>
                                    {trans(item[fieldName])}
                                </label>
                            </div>
                        })}
                    </div>
                    {errors[name] && <span className='error-msg'>{
                        Validator.renderMessage(trans(errors[name]?.message), { 0: field?.messageKey ? trans(field?.labelKey) : trans(name)})
                    }</span>}
                </React.Fragment>
            }}
        />
    )
}

export default FormRadio;