import React from 'react';
import classes from './ContentMessage.module.scss';

import IconXlsx from 'assets/icon/IconXlsx';
import IconDoc from 'assets/icon/IconDoc';
import IconDocx from 'assets/icon/IconDocx';
import IconZip from 'assets/icon/IconZip';
import IconXls from 'assets/icon/IconXls';
import IconPtt from 'assets/icon/IconPtt';
import IconPttX from 'assets/icon/IconPttx';
import IconRar from 'assets/icon/IconRar';
import IconTar from 'assets/icon/IconTar';
import IconGzip from 'assets/icon/IconGzip';
import IconPdf from 'assets/icon/IconPdf';
import IconTxt from 'assets/icon/IconTxt';
import useTrans from 'hooks/use-trans';
import ResizeImage from './ResizeImage';
import IconCsv from 'assets/icon/IconCsv';

const RenderFileAttachmentLink = ({ type, x }) => {
    const { t } = useTrans();
    let fileName = x?.attachmentFileName;
    function condenseFilename(initial) {
        var split = initial.split('.');
        var filename = split[0];
        var extension = split[1];

        if (filename.length > 35) {
            return filename.substring(0, 30) + '...' + extension;
        }
        return initial;
    }

    function nameFile(_fileName) {
        return <div title={_fileName} className={classes['Custom_Name_File']}>
            {condenseFilename(_fileName)}
        </div>
    }

    const fileMessage = (_fileName, size) => {
        return <div className={classes['File_Info']}>
            {nameFile(_fileName)}
            <span className={`${classes['File_Size']} ${x?.isMe ? classes['File_Size_Me'] : ''}`}>
                {`${t("message.size")}: ${size}KB`}
            </span>
        </div>
    }

    switch (type?.toUpperCase()) {
        case "ZIP":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconZip />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "CSV":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconCsv />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "DOCX":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconDocx />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "DOC":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconDoc />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "XLSX":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconXlsx />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "XLS":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconXls />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "PPT":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconPtt />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "PPTX":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconPttX />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "RAR":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconRar />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "TAR":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconTar />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "GZIP":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconGzip />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "TXT":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconTxt />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
        case "JPEG":
        case "JPG":
        case "PNG":
        case "GIF":
        case "BMP":
        case "TIFF":
        case "WEBP":
        case "SVG":
            return <ResizeImage url={x?.attachmentLink} />
        case "PDF":
            return <div className={classes['File_Link']}>
                <div className={classes['Icon_File']}>
                    <IconPdf />
                </div>
                {fileMessage(fileName, x?.attachmentFileSizeInKb)}
            </div>
    }
}

export default RenderFileAttachmentLink;