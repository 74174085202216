import ApiOperation from "adapter/ApiOperation"
import Constants from "utils/Constants";

const factory = {

    getListProduct: (payload) => {

        let params = {};

        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE];
        }
        if (payload[Constants.QueryParams.CODE.VALUE]) {
            params['code'] = payload[Constants.QueryParams.CODE.VALUE];
        }
        if (payload[Constants.QueryParams.PRICE.VALUE]) {
            params['price'] = payload[Constants.QueryParams.PRICE.VALUE];
        }
        if (payload[Constants.QueryParams.URL.VALUE]) {
            params['productUrl'] = payload[Constants.QueryParams.URL.VALUE];
        }
        if (payload[Constants.QueryParams.CURRENCY.VALUE]) {
            params['currency'] = payload[Constants.QueryParams.CURRENCY.VALUE];
        }
        if (payload[Constants.QueryParams.ORIGIN.VALUE]) {
            params['origin'] = payload[Constants.QueryParams.ORIGIN.VALUE];
        }

        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }
        if (payload[Constants.QueryParams.SORT.VALUE]) {
            params['sortColumn'] = payload[Constants.QueryParams.SORT.VALUE];
            params['sortType'] = payload[Constants.QueryParams.TYPE_SORT.VALUE];
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload?.isAll) {
            params['isAll'] = true;
        }

        let url = `/rest/api/v1/management/product/filter`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            params: params
        })
    },
    updateProduct: (payload) => {
        let url = "/rest/api/v1/management/product";
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    deleteProduct: (payload) => {
        let url = `/rest/api/v1/management/product/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'DELETE',
        })
    },
    createProduct: (payload) => {
        let url = "/rest/api/v1/management/product";
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    getDetailProduct: (payload) => {
        let url = `/rest/api/v1/management/product/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getCategory: (payload) => {
        let url = `/rest/api/v1/management/product/genre`;
        let params = {};
        if (payload?.[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload?.[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }

        if (payload?.[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload?.[Constants.QueryParams.LIMIT.VALUE];
        }

        if (payload?.[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload?.[Constants.QueryParams.KEYWORD.VALUE];
        }
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    importProduct: (payload) => {
        const url = `/rest/api/v1/management/product/import`;
        const formData = new FormData();
        formData.append('file', payload?.file);
        formData.append('isIgnoreError', payload?.isIgnoreError ?? false);
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: formData
        })
    },
}

export default factory;