import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import FreightCalculationManagement from 'shared/container/freight-calcula-management/FreightCalculationManagementContainer'
import classes from "./FreightCalculationManagementPage.module.scss";

function FreightCalculationManagementPage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t("fare_calculation.freight_calculation_management");
    }, [])
    return (
        <div className={classes["FreightCalculationManagement"]}>
            <FreightCalculationManagement />
        </div>
    )
}

export default FreightCalculationManagementPage