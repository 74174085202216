import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import FormInput from 'shared/components/common/form/home/FormInput'
import Validator from 'utils/Validator'
import Action from 'redux/user/action';
import Actions from 'redux/app/action';
import actionUser from 'redux/user/action';
import useTrans from 'hooks/use-trans';
import styles from './UserForgetIDPasswordPopup.module.scss'
import Loading from 'shared/components/form-input/button/loading/Loading';

const UserForgetIDPasswordPopup = () => {
    const dispatch = useDispatch();
    const methods = useForm();
    const { register, handleSubmit, setError } = methods;
    const [isInputVisible, setInputVisible] = useState(false);
    const { forgotIdPasswordError } = useSelector(state => state.user);
    const [isLoading, setIsLoading] = useState(false)
    const { trans } = useTrans();

    const handleSubmitForgotIDPasswordForm = (data) => {
        setInputVisible(false);
        setIsLoading(true);
        dispatch({
            type: Action.FORGOT_ID_PASSWORD,
            formData: data,
            callback: () => {
                setInputVisible(true);
                setIsLoading(false);
            },
            fallback: () => {
                setIsLoading(false);
            }
        });
    }
    const onSubmit = (data) => {
        handleSubmitForgotIDPasswordForm(data)
    }

    useEffect(() => {
        if (forgotIdPasswordError) {
            Object.keys(forgotIdPasswordError).forEach((key) => {
                setError("email", {
                    type: 'custom',
                    message: forgotIdPasswordError[key]?.[0] ?? trans('E0030')
                })
            })
        }
    }, [forgotIdPasswordError]);

    useEffect(() => {
        return () => {
            dispatch({
                type: actionUser.CLEAR_FORGOT_ID_PASSWORD_ERROR
            });
        }
    }, []);

    return <>
        <div className={`modal-body ${styles['forgot-id-pw-model-body']}`}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <center>
                        <div>
                            <label className={styles['login-form-label']}>{trans("Forget_ID_Password.popup_title")}</label>
                        </div>
                        <div className={styles['small-description-above']}>
                            <div className={styles['small-content-label']}>
                                <span>{trans("Forget_ID_Password.instruction_section")}</span>
                            </div>
                            <div className={styles['small-content-label']}>
                                <span>{trans("Forget_ID_Password.second_instruction_section")}</span>
                            </div>
                        </div>
                    </center>
                    <div>
                        <label className={styles['login-form-label']}>{trans("email")}</label>
                        <div className={`${styles['input-container']}`}>
                            <FormInput
                                fieldName="email"
                                register={register}
                                placeholder={trans("Forget_ID_Password.placeholder")}
                                validate={[Validator.required, Validator.email]}
                                disabled={isInputVisible}
                            />
                        </div>
                    </div>
                    {isInputVisible &&
                        <center className={styles['send-email-noti']}>
                            <div className={styles['small-description']}>
                                <div className={styles['small-content-label']}>
                                    <span>{trans("Forget_ID_Password.notification_section")}</span>
                                </div>
                                <div className={styles['small-content-label']}>
                                    <span>{trans("Forget_ID_Password.second_notification_section")}</span>
                                </div>
                            </div>
                        </center>}
                    {!isInputVisible &&
                        <center className={styles['registration-btn-container']}>
                            <button disabled={isLoading} type="submit" className={`${styles['registration-btn']}`} >{isLoading ? <Loading /> : trans("Forget_ID_Password.btn_label")}</button>
                        </center>
                    }
                </form>
            </FormProvider>
        </div>
    </>
};

export default UserForgetIDPasswordPopup;