import React from 'react'
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import CustomTable from '../../custom-table/CustomTable';
import CommonText from '../../text/CommonText';
import { convertNumberToString } from 'utils/String';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import Constants from 'utils/Constants';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import { insertIntoSpecificIndex } from 'utils/Utils';

function ProductDetailCargoPopup(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const handleOke = () => {
        showVisible()
    }

    let headCell = [
        {
            label: "No",
            field: 'No',
            minWidth: '10px',
            width: '10px',
            maxWidth: '10px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'center',
            alignHeader: 'center',
            component: (_props) => {
                return _props?.stt + 1
            }
        },
        {
            label: <CommonText>
                {t("product.product_name")}
            </CommonText>,
            field: 'productName',
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
        {
            label: <CommonText>
                {t("product.quantity")}
            </CommonText>,
            field: 'quantity',
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'right',
            alignHeader: 'right',
            component: (_props) => {
                return <>{convertNumberToString(_props?.children)}</>
            },
        },
        {
            label: <CommonText>
                {t("product.product_price")}
            </CommonText>,
            field: 'unitPrice',
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'right',
            alignHeader: 'right',
            component: (_props) => {
                return <>{convertNumberToString(_props?.children)}</>
            },
        },
        {
            label: <CommonText>
                {t("product.note")}
            </CommonText>,
            field: 'note',
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
    ];

    if(payload?.cardboard_type == Constants.CARGO_MASTER_CODE.CARD_BOARD.CARD_BOARD_TYPE.CROSS_BORDER_COURIER_SERVICE){
        headCell = insertIntoSpecificIndex(headCell, {
            label: <CommonText>
                {t("product.individual_export_declaration")}
            </CommonText>,
            field: 'individualExportDeclaration',
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'center',
            alignHeader: 'center',
            component: (_props) => {
                return <>{_props?.data?.individualExportDeclaration == Constants.STATUS_CHECKBOX.YES.VALUE ? <IconChecked /> : <IconCheck />}</>
            },
        }, 4)
    }
    return (
        <>
            <BoxContentPopup borderRadius='20px 0px 0px 20px' width='1000px'>
                <WrapContentBody
                    title={payload?.title}
                    showVisible={showVisible}
                    actionSectionBottom={<Button onClick={handleOke} title={t("Holon_Template.close")} />}
                    isUseBackground={false}
                    isUseBtnBack={true}
                    isUseActionSectionTop={false}
                >
                    <CustomTable headCell={headCell} data={payload?.data} />
                </WrapContentBody>
            </BoxContentPopup>
        </>
    )
}

export default ProductDetailCargoPopup