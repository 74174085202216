import useTrans from 'hooks/use-trans';
import React from 'react'
import BpRequestPaymentContainer from 'shared/container/bp-request-payment/BpRequestPaymentContainer';

function BpRequestPaymentPage() {
    const { trans } = useTrans();
    document.title = trans("bp_request_payment.bp_bill_management")
    return (
        <BpRequestPaymentContainer />
    )
}

export default BpRequestPaymentPage