import IconSearch from 'assets/icon/IconSearch';
import React from 'react'
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider'
import FormInput from 'shared/components/custom-form/FormInput';
import classes from './FormSearchUser.module.scss';
import useTrans from 'hooks/use-trans';

function FormSearchUser(props) {
    const { t } = useTrans();
    const onSearchChannel = (e) => {
        props?.searchChannel(e);
    }
    return (
        <div className={classes['form_search_user']}>
            <CustomFormProvider {...props?.methodsChannel}>
                <form onSubmit={props?.methodsChannel.handleSubmit(() => {})}>
                    <FormInput fieldName='search_channel' onChangeValue={onSearchChannel} isSearch placeholder={t("message.search_message")} iconSearch={<IconSearch fontSize={16} />} />
                </form>
            </CustomFormProvider>
        </div>
    )
}

export default FormSearchUser