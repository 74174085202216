import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import UpdatePolicySettingContainer from 'shared/container/policy-setting/UpdatePolicySettingContainer';

function UpdatePolicyPage() {
    const { lang, key } = useParams();
    useEffect(() => {
        document.title = 'ポリシー設定';
    }, []);
    return <UpdatePolicySettingContainer lang={lang} type={key} />;
}

export default UpdatePolicyPage;
