import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useTrans from 'hooks/use-trans';
import useRouter from 'hooks/use-router';
import actionHolon from 'redux/holon/action';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import actionsBlInstance from 'redux/bl-instance/action';
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POPUP_ADD_INSTANCE,
    EVENT_SHOW_ORGANIZATIONAL_COLLABORATION_BL_POPUP,
    EVENT_SHOW_ADD_BL_ACCOUNT_POPUP,
} from 'utils/EventRegister';
import Constants from 'utils/Constants';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import Dropdown from 'shared/components/common/drop-down/Dropdown'

function BlInstanceContainer() {
    const dispatch = useDispatch();
    const { t } = useTrans()
    const router = useRouter();
    const { templatesNoAttributes } = useSelector(state => state.holon);
    const { blCompany } = useSelector(state => state.blInstance);
    const mainTemplateIds = templatesNoAttributes.map((template) => { return template.id })
    const USER_TEMPLATE_ID = 'T011'
    const templateIdsForUsers = [...mainTemplateIds, USER_TEMPLATE_ID]
    const [anchorAddOptions, setAnchorAddOptions] = useState(false);

    const {
        hoLonTemplateId 
    } = useSelector(state =>state?.holonInstance)

    useEffect(() => {
        dispatch({
            type: actionHolon.GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.BL_PERSON_IN_CHARGE,
        });

        dispatch({
            type: actionHolon.GET_HOLON_MASTER_BY_CODE,
            code: Constants.HOLON_MASTER_CODE.BL_INSTANCE,
            callback: (holonMaster) => {
                dispatch({
                    type: actionsBlInstance.GET_BL_COMPANY,
                    payload: holonMaster?.data[0],
                })
            }
        })

    }, []);

    const handleAddHolonInstance = (holon_instance) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            payload: {
                title: t("holon.create_holon_instance"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                templateId: holon_instance,
                callback: () => {
                    if(router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)){
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holon_instance
                            }
                        });
                    }else {
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: mainTemplateIds?.join(",")
                            }
                        });
                    }
                },
            }
        })
    }

    const handleAddPositionForUser = () => {
        const companyOptions = [
            {
                value: blCompany?.id,
                label: blCompany?.name,
            }
        ]
        const roleOptions = templatesNoAttributes.map((template) => {
            return {
                value: template.id,
                label: template.name
            }
        })
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_ORGANIZATIONAL_COLLABORATION_BL_POPUP,
            open: true,
            payload: {
                title: t('Organizational_Collaboration.title'),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                companyOptions: companyOptions,
                defaultCompany: blCompany?.id,
                isDisabledCompanyOptions: true,
                roleOptions: roleOptions,
                templateIdsForUsers: templateIdsForUsers
            }
        })

    }

    const handleAddBlUser = () => {
        const companyOptions = [
            {
                value: blCompany?.id,
                label: blCompany?.name,
            }
        ]

        const templateOptions = templatesNoAttributes.map((template) => {
            return {
                value: template.id,
                label: template.name
            }
        })

        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_ADD_BL_ACCOUNT_POPUP,
            open: true,
            payload: {
                showHeader: false,
                title: t('account_registration_title'),
                companyOptions: companyOptions,
                defaultCompany: blCompany?.id,
                isDisabledCompanyOptions: true,
                templateOptions: templateOptions,
                callback: () => {
                    if(router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)){
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)
                            }
                        });
                    }else if(hoLonTemplateId == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: mainTemplateIds?.join(",")
                            }
                        });
                    }
                }

            }
        })
    }

    const dropDownMenu = [
        {
            name: t('add_department'),
            onClick: () => handleAddHolonInstance('T211'),
        },
        {
            name: t('add_position'),
            onClick: () => handleAddHolonInstance('T212'),
        },
        {
            name: t('add_position_for_user'),
            onClick: handleAddPositionForUser,
        },
        {
            name: t('add_bl_user'),
            onClick: handleAddBlUser,
        },
    ];

    return (
        <>
            {
                mainTemplateIds?.length > 0 && <HolonInstance
                    title={t('bl_instance')}
                    template_id={mainTemplateIds.join(',')}
                    isUseAddFunction={false}
                    ActionSection={
                        <Dropdown
                            list={dropDownMenu}
                            anchorEl={anchorAddOptions}
                            setAnchorEl={setAnchorAddOptions}
                        />
                    }
                    listTemplateCreation={mainTemplateIds?.map(x => ({id: x}))}
                />
            }
        </>
    )
}

export default BlInstanceContainer;
