import React from 'react';
import IconInput from 'assets/icon/IconInput';
import IconConFirm from 'assets/icon/IconConfirm';
import IconCompletion from 'assets/icon/IconCompletion';
import RouterPath from 'router/RouterPath';

export default class Constants {
    static CUSTOMER = {
        PERSONAL: {
            VALUE: 'individual'
        },
        BUSINESS: {
            VALUE: 'corporate'
        }
    }
    static PHONE_NUMBER_iHOLON = {
        TEL: {
            VALUE: '03-5808-9648'
        },
        FAX: {
            VALUE: '03-5808-9649'
        }
    }
    static BLOCKED_FIELD_REGISTER = ["status", "name"];
    static LABEL = {
        member_type: 'メンバーズタイプ',
        last_name: '氏名：姓（全角）',
        first_name: '氏名：名（全角）',
        hex_id: 'HEX ID',
        password: 'パスワード',
        password_confirm: 'パスワード再入力',
        last_name_kana: '氏名フリガナ：セイ（全角）',
        first_name_kana: '氏名フリガナ：メイ（全角）',
        avatar: 'プロファイル写真',
        gender: '性別',
        email: 'メールアドレス',
        phone: '電話番号',
        birthday: '生年月日',
        address_code: '郵便番号',
        address_main: 'ご住所・マイン',
        address_banchi: 'ご住所・番地',
        address_building: 'ご住所・号',
        address_chomebanchigo: '丁目・番地・号',
    }

    static INQUIRY = [
        {
            id: 1,
            key: "type",
            isRequired: "1",
            placeholder: "company",
            questions: [
                { value: 1, 'label': 'corporation' },
                { value: 2, 'label': 'individual' },
            ],
            type: "rdo",
            isShow: true
        },
        {
            id: 2,
            key: "companyName",
            isRequired: "1",
            placeholder: "company",
            type: "txt",
            maxLength: 60,
            isShow: true
        },
        {
            id: 3,
            key: "departmentName",
            isRequired: "0",
            placeholder: "department",
            type: "txt",
            maxLength: 60,
            isShow: true
        },
        {
            id: 4,
            key: "fullName",
            isRequired: "1",
            placeholder: "name",
            type: "txt",
            maxLength: 40,
            isShow: false
        },
        {
            id: 5,
            key: "fullNameFuri",
            isRequired: "1",
            placeholder: "name",
            type: "txt",
            maxLength: 40,
            isShow: false
        },
        {
            id: 6,
            key: "phone",
            isRequired: "0",
            placeholder: "phone",
            type: "txt",
            isShow: true
        },
        {
            id: 7,
            key: "email",
            isRequired: "1",
            placeholder: "email",
            type: "txt",
            maxLength: 255,
            isShow: true
        },
        {
            id: 8,
            key: "title",
            isRequired: "1",
            placeholder: "email",
            type: "txt",
            maxLength: 255,
            isShow: true
        },
        {
            id: 9,
            key: "content",
            isRequired: "1",
            placeholder: "text_area",
            type: "textarea",
            maxLength: 1000,
            isShow: true
        }
    ];

    static INQUIRY_STEP = [
        {
            label: "Inquiry.step.input",
            icon: <IconInput />
        },
        {
            label: "Inquiry.step.confirmation",
            icon: <IconConFirm />
        },
        {
            label: "Inquiry.step.completion",
            icon: <IconCompletion />
        },
    ]

    static REGISTER_STEP = [
        {
            label: "register_form.stepper.step1",
        },
        {
            label: "register_form.stepper.step2",
        },
        {
            label: "register_form.stepper.step3",
        },
    ]
    static REGEX = {
        formatMoney: /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
        isNumber: /^\d+$/,
        isOnlyDecimals: /^\.\d+/, // ex: .1
    }
    static FormInputFormat = {
        NUMBER: {
            VALUE: 'NUMBER'
        },
        PERCENT: {
            VALUE: 'PERCENT'
        },
        TEXT: {
            VALUE: 'text'
        },
        MONEY: {
            VALUE: 'MONEY'
        }
    }
    static QueryParams = {
        OFFSET: {
            VALUE: 'page'
        },
        LIMIT: {
            VALUE: 'limit'
        },
        TAB: {
            VALUE: 'tab'
        },
        FROM: {
            VALUE: 'from'
        },
        TO: {
            VALUE: 'to'
        },
        SUBJECT: {
            VALUE: 'subject'
        },
        SEARCH: {
            VALUE: 'search',
        },
        KEYWORD: {
            VALUE: 'keyword',
        },
        TYPE_SEARCH: {
            VALUE: 'type-search'
        },
        LANGUAGE: {
            VALUE: 'lang'
        },
        RELEASE: {
            VALUE: 'release'
        },
        CATEGORY: {
            VALUE: 'category',
            NAME: 'Category'
        },
        STATUS: {
            VALUE: 'status',
            NAME: 'Status'
        },
        FILTER: {
            VALUE: 'filter_set'
        },
        CREATED_DATE: {
            VALUE: 'created_date',
            Name: 'Created Date'
        },
        CREATED_DATE_FROM: {
            VALUE: 'created_date_from',
            Name: 'Created Date'
        },
        CREATED_DATE_TO: {
            VALUE: 'created_date_to',
            Name: 'Created Date'
        },
        CREATOR: {
            VALUE: 'creator',
            NAME: 'creator'
        },
        RENEWALDATE: {
            VALUE: 'renewal_date',
            NAME: 'Renewal Date'
        },
        SORT: {
            VALUE: 'sort',
        },
        TYPE_SORT: {
            VALUE: 'sort_type',
        },
        MASTER_CODES: {
            NAME: 'Private Policy',
            VALUE: 'masterCodes',
        },
        HOLON_TEMPLATE_ID: {
            VALUE: 'selected_template_id',
            NAME: 'selected_template_id'
        },
        SUB_HOLON_TEMPLATE_ID: {
            VALUE: 'sub_template_id',
            NAME: 'sub_template_id'
        },
        ATTRIBUTES: {
            VALUE: 'attribute',
            NAME: 'attributes'
        },
        ATTRIBUTES_SUB: {
            VALUE: 'attributes_Sub',
            NAME: 'attributes_Sub'
        },
        MESSAGE: {
            VALUE: 'message',
            NAME: 'message'
        },
        CHANNEL: {
            VALUE: 'channelId'
        },
        BRAND: {
            VALUE: 'brand'
        },
        CONTRIBUTOR: {
            VALUE: 'contributors'
        },
        TEMPLATE_ID: {
            VALUE: 'template_id'
        },
        TYPE: {
            VALUE: 'type'
        },
        CREATE_BY_TEMPLATE_ID: {
            VALUE: 'create_by_template_id'
        },
        POSTAL_CODE: {
            VALUE: 'postal_code',
        },
        COUNTRY_ID: {
            VALUE: 'country_id',
        },
        PORT_TYPE: {
            VALUE: 'port_type',
        },
        WAREHOUSE_ID: {
            VALUE: 'warehouseId'
        },
        VIEW: {
            VALUE: 'view'
        },
        CONNECTION_NAME: {
            VALUE: 'cname'
        },
        CONNECTION_WIDTH_HOLON_ID: {
            VALUE: 'cid'
        },
        CONNECTION_KEY_FOR_MULTI: {
            VALUE: 'ckey'
        },
        HOLON_ID_FOR_MULTIPLE: {
            VALUE: 'holonIdForMultiple'
        },
        CONNECTION_CONTRACT_MASTER: {
            VALUE: 'contract_master'
        },
        IS_SELECT_FOR_CONNECTION: {
            VALUE: 'isSelectForConnection'
        },
        CODE: {
            VALUE: 'code'
        },
        CURRENCY: {
            VALUE: 'currency'
        },
        ORIGIN: {
            VALUE: 'origin'
        },
        URL: {
            VALUE: 'url'
        },
        PRICE: {
            VALUE: 'price'
        },
        TRANSACTION_ID: {
            VALUE: 'transaction_id'
        },
        TRANSACTION_TYPE: {
            VALUE: 'transaction_type'
        },
        AMOUNT: {
            VALUE: 'transaction_amount'
        },
        TRANSACTION_TIME: {
            VALUE: 'transaction_time'
        },
        TRANSACTION_BY: {
            VALUE: 'transaction_by'
        },
        WALLET: {
            VALUE: 'transaction_wallet'
        },
        AUTHORIZE_NAME: {
            VALUE: 'transaction_authorize_name'
        },
        USER_IN_COMPANY: {
            VALUE: 'userInCompanyIds'
        },
        IS_SEARCH_ALL_TEMPLATE: {
            VALUE: 'isSearchAllTemplate'
        },
        REQUIRE_CONNECTION_KEY: {
            VALUE: 'requireConnectionKey'
        },
        REQUIRE_CONNECTION_HOLON_ID: {
            VALUE: 'requireConnectionHolonId'
        },
        EXCLUDE_IDS: {
            VALUE: 'excludeIds'
        },
    };
    static T002 = {
        TERM_OF_SERVICE: {
            NAME: 'Term of Service',
            CODE: 'TS',
            KEY: 'faq_management.term_of_service'
        },
        PRIVICY_POLICY: {
            NAME: 'Privacy Policy',
            CODE: 'PY',
            KEY: 'faq_management.privacy_policy'
        },
        PRIVATE_POLICY: {
            NAME: 'Private Policy',
            CODE: 'PE',
            KEY: 'faq_management.private_policy'
        },
    };
    static T001 = {
        JP: {
            NAME: 'Jp',
            CODE: 'JP',
        },
        EN: {
            NAME: 'En',
            CODE: 'EN',
        },
        CN: {
            NAME: 'Cn',
            CODE: 'CN',
        },
    };
    static STATUS_CATEGORY = {
        PRIVATE: {
            VALUE: "0"
        },
        PUBLIC: {
            VALUE: "1"
        }
    }
    static STATUS_CATEGORY_FAQ = {
        PRIVATE: {
            VALUE: "1"
        },
        PUBLIC: {
            VALUE: "0"
        }
    }
    static STATUS_ALARM = {
        ACTIVE: {
            VALUE: "1"
        },
        UNACTIVE: {
            VALUE: "0"
        }
    }
    static STATUS_CHECKBOX = {
        NO: {
            VALUE: "0"
        },
        YES: {
            VALUE: "1"
        }
    }
    static USER_STATUS = {
        TEMPORARY: {
            VALUE: "1"
        },
        REGULAR: {
            VALUE: "2"
        },
        WITHDRAWAL: {
            VALUE: "3"
        },
        INACTIVE: {
            VALUE: "9"
        }
    }
    static IS_REQUIRED = "1";
    static PAGINATION = {
        LIMIT: 10,
        LIMIT_MESSAGE: 15,
        OFFSET: 1
    }
    static SEARCH_CONFIG = {
        MANAGEMENT_HOLON_TEMPLATE: {
            KEY: 'HolonTemplateManagement',
            ALL: {
                VALUE: "all"
            },
            CREATED_DATE: {
                VALUE: 'created_date'
            },
            UPDATED_DATE: {
                VALUE: 'updated_date'
            },
        }
    }
    static ELEMENT_TYPE = {
        CHECKBOX: {
            VALUE: 'cb',
            NAME: 'Checkbox'
        },
        DATE: {
            VALUE: 'date',
            NAME: 'Date',
            DEFAULT_VALUE: 'now',
            DEFAULT_VALUE_60D: '60d',
            DEFAULT_VALUE_2M: '2m',
            DEFAULT_VALUE_1Y: '1y',
        },
        DATETIME: {
            VALUE: 'dt',
            NAME: 'Datetime',
            DEFAULT_VALUE: 'now'
        },
        EMAIL: {
            VALUE: 'email',
            NAME: 'Email'
        },
        FILE: {
            VALUE: 'file',
            NAME: 'File'
        },
        IMAGE: {
            VALUE: 'img',
            NAME: 'Image'
        },
        NUMBER: {
            VALUE: 'nmb',
            NAME: 'Number'
        },
        PASSWORD: {
            VALUE: 'pwd',
            NAME: 'Password'
        },
        RADIO: {
            VALUE: 'rdo',
            NAME: 'Radio'
        },
        TEL: {
            VALUE: 'tel',
            NAME: 'Tel'
        },
        TEXT: {
            VALUE: 'txt',
            NAME: 'Input'
        },
        URL: {
            VALUE: 'url',
            NAME: 'Url'
        },
        SELECT: {
            VALUE: 'slct',
            NAME: 'Select'
        },
        ADDRESS: {
            VALUE: 'addr',
            NAME: 'Address'
        },
        AUTOGEN: {
            VALUE: 'auto',
            NAME: 'Autogen'
        },
        UNIQUE: {
            VALUE: 'uniq',
            NAME: 'Unique'
        },
        SELECT_TEMPLATE: {
            VALUE: 'slct-tpl',
            NAME: 'Select_Template'
        },
        TEXT_AREA: {
            VALUE: 'txt-area',
            NAME: 'TxtArea'
        },
        RICH_TEXT: {
            VALUE: 'richtext',
            NAME: 'RichText'
        },
        SELECT_INSTANCE: {
            VALUE: 'slct-it',
            NAME: 'Select_Instance'
        },
        MONEY: {
            VALUE: 'money',
            NAME: 'Money'
        },
        PERCENT: {
            VALUE: 'per',
            NAME: 'Percent'
        },
        YEAR_MONTH_PICKER: {
            VALUE: 'ymp',
            NAME: 'Year_month_picker'
        },
        ADDRESS_SEA: {
            VALUE: 'addr-sea',
            NAME: 'Address_Sea',
            CODE: 1
        },
        ADDRESS_AIR: {
            VALUE: 'addr-air',
            NAME: 'Address_Air',
            CODE: 1
        },
    }

    static ELEMENT_TYPE_ESTIMATE = {
        CHECKBOX: {
            VALUE: 'cb',
            NAME: 'Checkbox'
        },
        NUMBER: {
            VALUE: 'nmb',
            NAME: 'Number'
        },
        RADIO: {
            VALUE: 'rdo',
            NAME: 'Radio'
        },
        TEXT: {
            VALUE: 'txt',
            NAME: 'Input'
        },
        SELECT: {
            VALUE: 'slct',
            NAME: 'Select'
        },
    }

    static CODE_MASTER = {
        // HONNIN_TEMPLATE: {
        //     VALUE: "HONNIN_TEMPLATE"
        // },
        PERSONAL_CUSTOMER: {
            VALUE: "PERSONAL_CUSTOMER"
        },
        BL_PERSON_IN_CHARGE: {
            VALUE: "BL_PERSON_IN_CHARGE"
        },
        BUSINESS_CUSTOMER: {
            VALUE: "BUSINESS_CUSTOMER"
        },
        HEX_ADMIN: {
            VALUE: "HEX_ADMIN"
        }
        // USER_TEMPLATE: {
        //     VALUE: "USER_TEMPLATE"
        // },	
    }
    static PASSWORD = {
        VALUE: "********"
    }
    static HOLON_MASTER_CODE = {
        HONNIN_TEMPLATE: "HONNIN_TEMPLATE",
        USER_TEMPLATE: "USER_TEMPLATE",
        PERSONAL_CUSTOMER: "PERSONAL_CUSTOMER",
        BUSINESS_CUSTOMER: "BUSINESS_CUSTOMER",
        BL_PERSON_IN_CHARGE: "BL_PERSON_IN_CHARGE",
        HEX_ADMIN: "HEX_ADMIN",
        COMPANY: "COMPANY",
        ORDER: "ORDER",
        BP_ORDER: "BP_ORDER",
        DEPARTMENT: "DEPARTMENT",
        POSITION: "POSITION",
        BL_INSTANCE: "BL_INSTANCE",
        ESTIMATION: "ESTIMATION",
        ESTIMATION_REQUEST: "ESTIMATION_REQUEST",
        CONTRACT: "CONTRACT",
        WALLET: "WALLET",
    }
    static HEX_SERVICE = {
        1: 'global_shipping_service',
        2: 'global_air_flight',
        3: 'cross_border_courier',
        4: 'jp_post_agency',
        5: 'fedex_agency'
    }

    static INQUIRY_PROCESS_STATUS = {
        REQUESTING: {
            NAME: "inquiry.inquiry_process_status_type.requesting",
            VALUE: "1"
        },
        PROCESSING: {
            NAME: "inquiry.inquiry_process_status_type.processing",
            VALUE: "2"
        },
        COMPLETED: {
            NAME: "inquiry.inquiry_process_status_type.completed",
            VALUE: "3"
        },
        REJECTED: {
            NAME: "inquiry.inquiry_process_status_type.rejected",
            VALUE: "4"
        }
    };

    static INQUIRY_YES_NO_CHECKBOX = {
        NO: {
            NAME: "inquiry.can_not",
            VALUE: "0"
        },
        YES: {
            NAME: "inquiry.can",
            VALUE: "1"
        }
    }
    static ISSUANCE_TEMPORARY_ACCOUNT_INQUIRER = {
        YES: {
            NAME: "inquiry.can_not",
            VALUE: "0"
        },
        NO: {
            NAME: "inquiry.can",
            VALUE: "1"
        },
    }
    static CROSS_BORDER_COURIER = {
        YES: {
            NAME: "Holon_Template.valid",
            VALUE: "1"
        },
        NO: {
            NAME: "Holon_Template.invalid",
            VALUE: "0"
        },
    }

    static TYPE_CHARACTER = {
        CHARACTER: {
            VALUE: "character"
        },
        YES: {
            VALUE: '1',
            TYPE: 'x'
        },
        NO: {
            VALUE: '0',
            TYPE: 'o'
        },
        EMPTY: {
            TYPE: '-'
        }
    }

    static INQUIRY_USER_TYPE = {
        CORPORATION: {
            NAME: "corporation",
            VALUE: 1
        },
        INDIVIDUAL: {
            NAME: "individual",
            VALUE: 2
        }
    }
    static TYPE_ACTIONS = {
        REDIRECT: {
            NAME: "redirect",
            VALUE: "REDIRECT"
        },
        GET: {
            NAME: "get",
            VALUE: "GET"
        },
        POST: {
            NAME: "post",
            VALUE: "POST"
        },
        ASYNCGET: {
            NAME: "async_get",
            VALUE: "ASYNCGET"
        },
        ASYNCPOST: {
            NAME: "async_post",
            VALUE: "ASYNCPOST"
        },
        CRON: {
            NAME: "cron",
            VALUE: "CRON"
        },
        POPUP: {
            NAME: "popup",
            VALUE: "POPUP"
        },
    }
    static STYLE = {
        input: {
            height: "30px"
        }
    }

    static FORMAT_DATE_TIME = {
        DATE: 'DD/MM/YYYY',
        DATE_TIME: 'DD/MM/YYYY HH:mm:ss'
    }

    static PORT_TYPE = {
        AIRPORT: '0',
        SEAPORT: '1'
    }
    static COMMON_POPUP = {
        CONTENT_WIDTH: '800px',
        BORDER_RADIUS: '0px'
    }

    static SIZE = {
        DEFAULT: 'DEFAULT',
        SMALL: 'SMALL',
        MEDIUM: 'MEDIUM',
        LARGE: 'LARGE',
    }

    static MENU_MASTER = {
        MENU_DESKTOP: {
            CODE: 'M004'
        }
    }

    static TIME_IN_DATE = {
        MORNING: "00:00:00",
        AFTERNOON: '13:00:00',
        EVENING: '20:00:00',
    }

    static CARGO_MASTER_CODE = {
        CARD_BOARD: {
            MASTER_CODE: 'CARDBOARD_FOR_SEINO,CARDBOARD_FOR_SEA,CARDBOARD_FOR_AIR,CARDBOARD_FOR_S345,CONTAINER,PALLET',
            TEMPLATE_ID: 'T303',
            CONNECTION_KEY: {
                ACCOUNT: 'owner',
                WAREHOUSE: 'warehouse_product',
                WAREHOUSE_CARBOAD: 'warehouse_cardboard'
            },
            ATTRIBUTES_KEY: {
                BRING_IN_METHODS: 'bring_in_method',
                CARDBOARD_TYPE: 'cardboard_type',
            },
            CATEGORY: 'object_real',
            CARGO_PRODUCT: {
                CODE: 'CARDBOARD_FOR_S345'
            },
            CARD_BOARD_TYPE: {
                CROSS_BORDER_COURIER_SERVICE: 1
            },
            SHIP_TO_WAREHOUSE: {
                IN_STOCK: 1
            }
        },
        PRODUCT: {
            MASTER_CODE: 'PRODUCT',
            CATEGORY: 'object_real'
        },
        REGEX_PRODUCT: {
            CREATE: 'create',
            UPDATE: 'update',
            DELETE: 'delete'
        }
    }
    static REQUEST_PAYMENT_MASTER_CODE = {
        MASTER_CODE: {
            VALUE: 'CUSTOMER_REQUEST_PAYMENT'
        },
        CATEGORY: {
            VALUE: 'document'
        }
    }
    static MASTER_CODE_PRODUCT = {
        MASTER_CODE: {
            VALUE: 'PRODUCT'
        },
    }

    static BP_REQUEST_PAYMENT_MASTER_CODE = {
        MASTER_CODE: {
            VALUE: 'BP_REQUEST_PAYMENT'
        },
        CATEGORY: {
            VALUE: 'document'
        }
    }

    static HOLON = {
        TEMPLATE: "0",
        INSTANCE: '1',
    }

    static ACTION_TYPE = {
        INDIVIDUAL: {
            VALUE: '0',
            NAME: 'Individual (1 time)'
        },
        INDIVIDUALS: {
            VALUE: '1',
            NAME: 'Individual (several times)'
        },
        BULK: {
            VALUE: '5',
            NAME: 'Bulk'
        },
    }

    static TRANSACTION_CODE = {
        TRANSACTION_TYPE: {
            CHARGE: {
                VALUE: '1',
                NAME: 'チャージ'
            },
            WITHDRAW: {
                VALUE: '2',
                NAME: '引出'
            },
            PAYMENT: {
                VALUE: '3',
                NAME: '支払'
            },
        },
        STATUS: {
            NEW: {
                VALUE: '1',
                NAME: '新規'
            },
            COMPLETED: {
                VALUE: '2',
                NAME: '完了'
            },
            REJECTED: {
                VALUE: '3',
                NAME: '却下'
            },
        },
        TRANSACTION_BY: {
            BANK_TRANSFER: {
                VALUE: '1',
                NAME: '銀行振込'
            },
            ALIPAY: {
                VALUE: '2',
                NAME: 'Alipay'
            },
            WECHAT_PAY: {
                VALUE: '3',
                NAME: 'Wechat Pay'
            },
            HEX_WALLET: {
                VALUE: '4',
                NAME: 'HEXウオレット '
            },
            OTHERS: {
                VALUE: '9',
                NAME: 'その他'
            },
        },
        ACCOUNT_TYPE: {
            ORDINARY_DEPOSIT: {
                VALUE: '1',
                NAME: '普通預金'
            },
            CURRENT_ACCOUNT: {
                VALUE: '2',
                NAME: '当座預金'
            }
        },
        TEMPLATE_ID: {
            ORDER: ['T110', 'T111', 'T112', 'T113', 'T114'],
            WALLET: ['T213']
        }
    }

    static RESPONSE_ACTION = {
        RELOAD: {
            VALUE: 1
        },
    }

    static ACTION_TYPE_CHARGE = {
        ORDINARY_DEPOSIT: {
            VALUE: 1,
            NAME: '普通預金 '
        }
    }

    static TABLE_ATTRIBUTES = {
        TOP: {
            VALUE: 0,
            NAME: 'top'
        },
        UP: {
            VALUE: 1,
            NAME: 'up'
        },
        DOWN: {
            VALUE: 2,
            NAME: 'down'
        },
        BOTTOM: {
            VALUE: 3,
            NAME: 'bottom'
        }
    }

    static ACTIONS_INSTANCE_TYPE = {
        ACTION_TYPE: {
            ACTION_HOLON_TEMPLATE: {
                VALUE: 5
            },
            ACTION_HOLON_INSTANCE: {
                VALUE: 1
            },
        },
    }
    static SCREEN_ACTION = {
        G1660: {
            VALUE: RouterPath.PORTAL_WAREHOUSE_IN_PARAMS,
            NAME: 'In stock confirm',
            ID: 'G1660'
        },
        G1670: {
            VALUE: RouterPath.PORTAL_WAREHOUSE_OUT_PARAMS,
            NAME: 'Out stock confirm',
            ID: 'G1670'
        },
    }
    static CONNECTION_KEY = {
        ORDER: {
            ORDER_OWNER: 'order_owner',
        },
        CREATE_BY: {
            VALUE: 'created_by',
        },
    }
    static VIEW_SEARCH = {
        ROW: {
            VALUE: 'row'
        },
        COLUMN: {
            VALUE: 'column'
        }
    }
    static STATUS_REQUEST = {
        SUCCESS: {
            VALUE: 200,
            NAME: 'STATUS_REQUEST'
        },
        REQUEST_TIME_OUT: {
            VALUE: 503,
            NAME: 'REQUEST_TIME_OUT'
        },
    }

    static BP_TO_PAYMENT = {
        ATTRIBUTES: {
            PAYMENT_AMOUNT_WITH_TAX: 'payment_amount_with_tax'
        }
    }
    static TITLE_PAGE = "Higashi Express"
    static MASTER_HOLON_CATEGORY = {
        document: "document",
        object_digital: "object_digital",
        object_real: "object_real",
        organization: "organization",
        person: "person"
    }
}
