const actionDashboard = {
    GET_NOTIFICATIONS : "GET_NOTIFICATIONS",
    GET_NOTIFICATIONS_SUCCESS : "GET_NOTIFICATIONS_SUCCESS",
    GET_NOTIFICATIONS_FAILED : "GET_NOTIFICATIONS_FAILED",

    GET_WALLETS : "GET_WALLETS",
    GET_WALLETS_SUCCESS : "GET_WALLETS_SUCCESS",
    GET_WALLETS_FAILED : "GET_WALLETS_FAILED",

    GET_ORDERS : "GET_ORDERS",
    GET_ORDERS_SUCCESS : "GET_ORDERS_SUCCESS",
    GET_ORDERS_FAILED : "GET_ORDERS_FAILED",

    GET_NUMBER_OF_USER : "GET_NUMBER_OF_USER",
    GET_NUMBER_OF_USER_SUCCESS : "GET_NUMBER_OF_USER_SUCCESS",
    GET_NUMBER_OF_USER_FAILURE : "GET_NUMBER_OF_USER_FAILURE",

    GET_NUMBER_OF_ORDERED_USER : "GET_NUMBER_OF_ORDERED_USER",
    GET_NUMBER_OF_ORDERED_USER_SUCCESS : "GET_NUMBER_OF_ORDERED_USER_SUCCESS",
    GET_NUMBER_OF_ORDERED_USER_FAILURE : "GET_NUMBER_OF_ORDERED_USER_FAILURE",
    
    GET_DASHBOARD_WALLET_INFO : "GET_DASHBOARD_WALLET_INFO",
    GET_DASHBOARD_WALLET_INFO_SUCCESS : "GET_DASHBOARD_WALLET_INFO_SUCCESS",
    GET_DASHBOARD_WALLET_INFO_FAILURE : "GET_DASHBOARD_WALLET_INFO_FAILURE",

    GET_INQUIRY_COUNT: "GET_INQUIRY_COUNT",
    GET_INQUIRY_COUNT_SUCCESS: "GET_INQUIRY_COUNT_SUCCESS",
    GET_INQUIRY_COUNT_FAILURE: "GET_INQUIRY_COUNT_FAILURE"
}

export default actionDashboard;
