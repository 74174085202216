import React from 'react';
import useTrans from 'hooks/use-trans';
import styles from './OrganizationalCollaborationPopup.module.scss'
import { useForm } from 'react-hook-form';
import Actions from 'redux/holon/action';
import Constants from 'utils/Constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import FormSelectInstance from 'shared/components/custom-form/FormSelectInstance';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_UPDATE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import FormSelect from 'shared/components/custom-form/FormSelect';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import Button from 'shared/components/form-input/button/Button';
import Loading from 'shared/components/form-input/button/loading/Loading';
import { showPopupNotification } from 'utils/Utils';

const OrganizationalCollaborationPopup = (props) => {
    const dispatch = useDispatch();
    const [personalCustomer, setPersonalCustomer] = useState();
    const [businessCustomer, setBusinessCustomer] = useState();
    const [userList, setUserList] = useState([]);
    const { showVisible, payload } = props;
    const { companyData, departmentHolon, positionHolon, handleReloadCompanyData, } = payload;
    const company = companyData?.attributes?.length > 0 ? companyData?.attributes.find(item => item.name === "name") : null;
    const department = departmentHolon?.attributes?.length > 0 ? departmentHolon?.attributes.find(item => item.name === "name") : null;
    const position = positionHolon?.attributes?.length > 0 ? positionHolon?.attributes.find(item => item.name === "name") : null;
    const [isLoading, setIsLoading] = useState(false)
    const { trans } = useTrans();
    const methods = useForm();
    const { setValue } = methods;

    const optionsCompany = [
        {
            label: company?.value ?? '',
            value: companyData?.id ?? '',
            key: companyData?.id ?? '',
        },
    ]
    const optionsDepartment = [
        {
            label: department?.value ?? '',
            value: departmentHolon?.id ?? '',
            key: departmentHolon?.id ?? '',
        },
    ]
    const optionsPosition = [
        {
            label: position?.value ?? '',
            value: positionHolon?.id ?? '',
            key: positionHolon?.id ?? '',
        },
    ]

    useEffect(() => {
        setValue(company?.name, companyData?.id);
        setValue(department?.name, departmentHolon?.id);
        setValue(position?.name, positionHolon?.id);

        dispatch({
            type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.PERSONAL_CUSTOMER,
            callback: (data) => {
                setPersonalCustomer(data?.[0])
            }
        })

        dispatch({
            type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.BUSINESS_CUSTOMER,
            callback: (data) => {
                setBusinessCustomer(data?.[0])
            }
        })
    }, []);

    const onSubmit = (data) => {

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setIsLoading(true)
                    dispatch({
                        type: Actions.CONNECT_EMPLOYEE,
                        companyId: companyData?.id,
                        formData: {
                            positionId: optionsPosition?.[0]?.value,
                            listEmployeeId: userList.toString()
                        },
                        callback: () => {
                            setIsLoading(false);
                            handleReloadCompanyData && handleReloadCompanyData(true);
                            showVisible(false);
                            showPopupNotification({
                                typePopup: 'message',
                                message: trans("E0043"),
                                newWindow: true
                            })
                        },
                        fallback: (detailError) => {
                            setIsLoading(false)
                            let messageError = "";
                            Object.keys(detailError).forEach((key) => {
                                detailError[key]?.map((msg) => {
                                    messageError += msg;
                                });
                            });
                            EventRegister.emit(EVENT_SHOW_POPUP, {
                                open: true,
                                newWindow: true,
                                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                payload: {
                                    title: messageError,
                                    newWindow: true,
                                    showHeader: false,
                                    customStyle: {
                                        padding: 0,
                                        borderTop: 0,
                                    },
                                },
                            });
                        }
                    })
                }
            }
        })
    }

    const onChange = (data) => {
        console.log(data);
        const ids = data.map(object => object.id);
        setUserList(ids)
    }

    return <>
        <div className={`modal-body ${styles['form-container']}`}>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={styles['inner-form-container']}>
                        <div className={styles['form-group']}>
                            <div className={styles['form-label-left']}>
                                <p>
                                    {trans("Organizational_Collaboration.company")}
                                </p>
                            </div>
                            <div className={styles['form-item-input']}>
                                <FormSelect
                                    fieldName={company?.name ?? ''}
                                    disabled={true}
                                    options={optionsCompany} />
                            </div>
                        </div>
                        <div className={styles['form-group']}>
                            <div className={styles['form-label-left']}>
                                <p>
                                    {trans("Organizational_Collaboration.department")}
                                </p>
                            </div>
                            <div className={styles['form-item-input']}>
                                <FormSelect
                                    validate={[]}
                                    fieldName={department?.name}
                                    disabled={true}
                                    options={optionsDepartment}
                                />
                            </div>
                        </div>
                        <div className={styles['form-group']}>
                            <div className={styles['form-label-left']}>
                                <p>
                                    {trans("Organizational_Collaboration.position")}
                                </p>
                            </div>
                            <div className={styles['form-item-input']}>
                                <FormSelect
                                    validate={[]}
                                    fieldName={position?.name}
                                    disabled={true}
                                    placeholder=''
                                    options={optionsPosition}
                                />
                            </div>
                        </div>
                        <div className={styles['form-group']}>
                            <div className={styles['form-label-left']}>
                                <p>
                                    {trans("Organizational_Collaboration.employee")}
                                </p>
                            </div>
                            <div className={styles['form-item-input']}>
                                <div className={styles['SelectTemplate']}>
                                    {!(personalCustomer && businessCustomer) && <span className={styles['custom-loading']}><Loading color='black' /></span>}
                                    {personalCustomer && businessCustomer
                                        && <FormSelectInstance
                                            onChangeSelect={onChange}
                                            disabled={false}
                                            holonTemplate={[personalCustomer, businessCustomer]}
                                            fieldName={`connections.instance`}
                                        />}
                                </div>
                            </div>
                        </div>
                        <div className={styles['form-group-row']}>
                            <div className={styles['form-group_button']}>
                                <Button
                                    loading={isLoading}
                                    disabled={(userList.length == 0) || ((!(personalCustomer && businessCustomer)) ? true : false)}
                                    size="large"
                                    type="submit"
                                    title={trans("Organizational_Collaboration.save")}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </CustomFormProvider>
        </div>
    </>
};

export default OrganizationalCollaborationPopup;