import React, { useEffect, useState } from "react";

import classes from "./UpdateInquiryPopup.module.scss";

import useTrans from "hooks/use-trans";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useRouter from "hooks/use-router";

import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import InquiryAction from "redux/inquiry/action";
import UserAction from "redux/user/action";
import InquiryMapper from "redux/inquiry/mapper";
import Validator from "utils/Validator";
import Utils, { showPopupNotification } from "utils/Utils";
import Constants from "utils/Constants";
import FormRadio from "shared/components/custom-form/FormRadio";

import Button from "shared/components/form-input/button/Button";
import IconLeftArrow from "assets/icon/IconLeftArrow";
import IconClose from "assets/icon/IconClose";

import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
    EVENT_SHOW_INQUIRY_DETAIL_POPUP,
    EVENT_SHOW_POPUP_CONFIRM_UPDATE,
} from "utils/EventRegister";

import RowInput from "./component/RowInput";
import RowItem from "./component/RowItem";
import Section from "./component/Section";
import Permission from "utils/Permission";

const UpdateInquiryPopup = (props) => {
    const { showVisible } = props;
    const { t } = useTrans();
    const methods = useForm();
    const router = useRouter();
    const [isNeedEstimate, setIsNeedEstimate] = useState(false);
    const [isIndividualCustomer, setIsIndividualCustomer] = useState(Constants.INQUIRY_USER_TYPE.CORPORATION.VALUE);

    const {
        inquiryDetail,
        loading: { loadingUpsertInquiry },
    } = useSelector((state) => state.inquiry);
    const { saleList, managerList } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const inquiryStatusList = Utils.convertObjectKeyToArr(
        Constants.INQUIRY_PROCESS_STATUS
    );

    const yesNoOptions = Utils.convertObjectKeyToArr(
        Constants.INQUIRY_YES_NO_CHECKBOX
    );

    const userTypeOptions = Utils.convertObjectKeyToArr(
        Constants.INQUIRY_USER_TYPE
    );

    const temporaryAccount = Utils.convertObjectKeyToArr(
        Constants.ISSUANCE_TEMPORARY_ACCOUNT_INQUIRER
    );

    const inquiryData = {
        status: {
            inputType: "select",
            inputName: "status",
            payloadName: "status",
        },
        userType: {
            inputType: "radio",
            inputName: "userType",
            payloadName: "type",
        },
        companyName: {
            inputType: "text",
            inputName: "companyName",
            payloadName: "companyName",
        },
        departmentName: {
            inputType: "text",
            inputName: "departmentName",
            payloadName: "departmentName",
        },
        fullName: {
            inputType: "text",
            inputName: "fullName",
            payloadName: "fullName",
        },
        fullNameFuri: {
            inputType: "text",
            inputName: "fullNameFuri",
            payloadName: "fullNameFuri",
        },
        phone: {
            inputType: "text",
            inputName: "phone",
            payloadName: "phone",
        },
        email: {
            inputType: "text",
            inputName: "email",
            payloadName: "email",
        },
        title: {
            inputType: "text",
            inputName: "title",
            payloadName: "topic",
        },
        content: {
            inputType: "textarea",
            inputName: "content",
            payloadName: "content",
        },
        salePerson: {
            inputType: "text",
            inputName: "chosenPICSale",
            payloadName: "saleId",
        },
        manager: {
            inputType: "text",
            inputName: "chosenPICManager",
            payloadName: "managerId",
        },
        hasAccount: {
            inputType: "checkbox",
            inputName: "isCreateAccount",
            payloadName: "hasAccount",
        },
        needEstimate: {
            inputType: "radio",
            inputName: "needEstimate",
            payloadName: "needEstimate",
        },
        estimateCode: {
            inputType: "text",
            inputName: "estimateCode",
            payloadName: "estimateCode",
        },
        remark: {
            inputType: "text",
            inputName: "remark",
            payloadName: "remark",
        },
    };


    useEffect(() => {
        methods.setValue("userType", 0);
        //
        dispatch({
            type: UserAction.GET_SALE_LIST,
        });
        dispatch({
            type: UserAction.GET_MANAGER_LIST,
        });
    }, []);

    useEffect(() => {
        Object.keys(inquiryData).forEach(function (prop) {
            const inputName = inquiryData[prop].inputName;
            const payloadName = inquiryData[prop].payloadName;
            methods.setValue(inputName, inquiryDetail[payloadName]);
        });

        setIsNeedEstimate(
            inquiryDetail?.needEstimate?.toString() ===
            Constants.INQUIRY_YES_NO_CHECKBOX.YES.VALUE
        );

        setIsIndividualCustomer(
            inquiryDetail?.type
        );
    }, [inquiryDetail]);

    useEffect(() => {
        if(isIndividualCustomer === Constants.INQUIRY_USER_TYPE.INDIVIDUAL.VALUE){
            methods.setValue('companyName', '')
            methods.setValue('departmentName', '')
        }
    },[isIndividualCustomer])

    const handleClose = () => {
        showVisible();
    };

    const onUpdateSuccess = () => {
        showPopupNotification({
            type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
            typePopup: 'message',
            message: t("E0043"),
        })
        dispatch({
            type: InquiryAction.GET_INQUIRY_LIST,
            payload: {
                ...router.getAll(),
            },
        });
    };

    const onUpdateFailure = (detailError) => {
        let messageError = "";

        Object.keys(detailError).forEach((key) => {
            detailError[key]?.map((msg) => {
                messageError += msg;
            });
        });

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
            payload: {
                title: messageError,
                newWindow: true,
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
            },
        });
    };

    const showDetail = () => {
        dispatch({
            type: InquiryAction.GET_INQUIRY_DETAIL,
            payload: inquiryDetail?.id,
        });
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_INQUIRY_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                callback: () => { },
            },
        });
    };

    const updateInquiry = (formData) => {
        const updateData = {
            ...formData,
            id: inquiryDetail.id,
            inquiryCode: inquiryDetail?.inquiryCode,
        };

        dispatch({
            type: InquiryAction.UPDATE_INQUIRY,
            payload: updateData,
            onSuccess: onUpdateSuccess,
            onFailure: onUpdateFailure,
        });
    };

    const onSubmit = (formData) => {
        if (inquiryDetail?.id) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                newWindow: true,
                type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
                payload: {
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0,
                    },
                    callback: () => updateInquiry(formData),
                },
            });
        }
    };

    const onChangeEstimateRadio = (value) => {
        setIsNeedEstimate(
            value === Constants.INQUIRY_YES_NO_CHECKBOX.YES.VALUE
        );
        if (!(value === Constants.INQUIRY_YES_NO_CHECKBOX.YES.VALUE)) {
            methods.resetField("estimateCode")
        }else{
            methods.setValue('estimateCode', inquiryDetail?.estimateCode)
        }
    };

    const renderHeaderPopup = () => {
        return (
            <div className={classes["Header"]}>
                <div className={classes["HeaderLeft"]}>
                    <div className={classes["Icon"]}>
                        <Button onClick={showDetail}>
                            <IconLeftArrow />
                        </Button>
                    </div>
                    <div className={classes["PopupTitle"]}>
                        <p>{t("inquiry.update_inquiry")}</p>
                    </div>
                </div>
                <div className={classes["HeaderRight"]}>
                    <Button
                        onClick={() => handleClose()}
                        color="gray"
                        startIcon={
                            <IconClose
                                fontSize={14}
                                color="#106574"
                            />
                        }
                    />
                </div>
            </div>
        );
    };

    const onChangeUserTypeRadio = (value) => {
        setIsIndividualCustomer(value);
    };

    const renderBodyPopup = () => {
        return (
            <div className={classes["Body"]}>
                <Section sectionTitle={t("inquiry.contact_information")}>
                    <RowItem
                        label={t("inquiry.inquiry_code")}
                        value={inquiryDetail.inquiryCode}
                    />
                    <RowInput
                        label={t("inquiry.inquiry_status")}
                        inputType="select"
                        fieldName="status"
                        options={inquiryStatusList}
                    />
                    <RowInput
                        label={t("type")}
                        inputType="radio"
                        fieldName="userType"
                        options={userTypeOptions}
                        onChangeValue={onChangeUserTypeRadio}
                    />
                    {isIndividualCustomer == Constants.INQUIRY_USER_TYPE.CORPORATION.VALUE && (
                        <RowInput
                            label={t("inquiry.companyName")}
                            inputType="text"
                            fieldName="companyName"
                            maxLength={100}
                            validate={[Validator.required]}
                        />
                    )}
                    {isIndividualCustomer == Constants.INQUIRY_USER_TYPE.CORPORATION.VALUE && (
                        <RowInput
                            label={t("departmentName")}
                            inputType="text"
                            fieldName="departmentName"
                            maxLength={255}
                        />
                    )}

                    <RowInput
                        label={t("fullName")}
                        inputType="text"
                        fieldName="fullName"
                        maxLength={100}
                        validate={[Validator.required]}
                    />
                    <RowInput
                        label={t("Inquiry.nameFurigana")}
                        inputType="text"
                        fieldName="fullNameFuri"
                        maxLength={100}
                        validate={[Validator.required]}
                    />
                    <RowInput
                        label={t("phone")}
                        inputType="text"
                        fieldName="phone"
                        maxLength={10}
                    />
                    <RowInput
                        label={t("Inquiry.email")}
                        inputType="text"
                        fieldName="email"
                        maxLength={50}
                        validate={[Validator.required, Validator.email]}
                    />
                    <RowInput
                        label={t("inquiry.inquiry_topic")}
                        inputType="text"
                        fieldName="title"
                        maxLength={255}
                        validate={[Validator.required]}
                    />
                    <RowInput
                        label={t("inquiry.inquiry_content")}
                        inputType="textarea"
                        fieldName="content"
                        maxLength={1000}
                        validate={[Validator.required]}
                        readOnly
                    />
                </Section>
                {/* {
                    Permission .IsEnabledFunction(Permission.FUNCTIONS.UPDATE_MANAGEMENT_INFO_INQUIRY) && */}
                <Section sectionTitle={t("inquiry.inquiry_process_management")}>
                    <RowInput
                        label={t("inquiry.chosen_pic_sale")}
                        inputType="select"
                        fieldName="chosenPICSale"
                        options={InquiryMapper.handleOption(
                            saleList?.records || []
                        )}
                    />
                    <RowInput
                        label={t("inquiry.chosen_pic_manager")}
                        inputType="select"
                        fieldName="chosenPICManager"
                        options={InquiryMapper.handleOption(
                            managerList?.records || []
                        )}
                    />
                    <div className={classes["RowInput"]}>
                        <p className={classes["Label"]}>
                            {t("inquiry.create_account")}
                        </p>
                        <FormRadio
                            fieldName="isCreateAccount"
                            options={temporaryAccount}
                        // onChangeValue={onChangeTemporaryAccount}
                        />
                    </div>
                    <div className={classes["RowInput"]}>
                        <p className={classes["Label"]}>
                            {t("inquiry.is_need_estimate")}
                        </p>
                        <FormRadio
                            fieldName="needEstimate"
                            options={yesNoOptions}
                            onChangeValue={onChangeEstimateRadio}
                        />
                    </div>
                    <RowInput
                        label={t("inquiry.estimate_no")}
                        inputType="text"
                        fieldName="estimateCode"
                        maxLength={15}
                        disabled={!isNeedEstimate}
                        validate={[isNeedEstimate ? Validator.required : () => { }]}
                    />
                    <RowInput
                        label={t("remark")}
                        inputType="textarea"
                        fieldName="remark"
                        maxLength={1000}
                    />
                </Section>
                {/* } */}
            </div>
        );
    };

    const renderFooterPopup = () => {
        return (
            <div className={classes["Footer"]}>
                <Button
                    type="submit"
                    title={t("Holon_Template.save")}
                    loading={loadingUpsertInquiry}
                />
            </div>
        );
    };

    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={classes["UpdateInquiryPopup"]}>
                    {renderHeaderPopup()}
                    <div className={classes['UpdateInquiryPopupContent']}>
                        {renderBodyPopup()}
                    </div>
                    {renderFooterPopup()}
                </div>
            </form>
        </CustomFormProvider>
    );
};

export default UpdateInquiryPopup;
