const actionWallet  = {
    GET_WALLET_LIST : "GET_WALLET_LIST",
    GET_WALLET_LIST_SUCCESS : "GET_WALLET_LIST_SUCCESS",
    GET_WALLET_LIST_FAILED : "GET_WALLET_LIST_FAILED",
    
    GET_WALLET_DETAIL : "GET_WALLET_DETAIL",
    GET_WALLET_DETAIL_SUCCESS : "GET_WALLET_DETAIL_SUCCESS",
    GET_WALLET_DETAIL_FAILED : "GET_WALLET_DETAIL_FAILED",

    GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET : "GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET",
    GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET_SUCCESS : "GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET_SUCCESS",
    GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET_FAILED : "GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET_FAILED",

    UPDATE_WALLET : "UPDATE_WALLET",
    UPDATE_WALLET_SUCCESS : "UPDATE_WALLET_SUCCESS",
    UPDATE_WALLET_FAILED : "UPDATE_WALLET_FAILED",

    DELETE_WALLET : "DELETE_WALLET",
    DELETE_WALLET_SUCCESS : "DELETE_WALLET_SUCCESS",
    DELETE_WALLET_FAILED : "DELETE_WALLET_FAILED",

    CREATE_WALLET : "CREATE_WALLET",
    CREATE_WALLET_SUCCESS : "CREATE_WALLET_SUCCESS",
    CREATE_WALLET_FAILED : "CREATE_WALLET_FAILED",


    LOAD_DATA_WALLET_CHARGE : "LOAD_DATA_WALLET_CHARGE",
    LOAD_DATA_WALLET_CHARGE_SUCCESS : "LOAD_DATA_WALLET_CHARGE_SUCCESS",
    LOAD_DATA_WALLET_CHARGE_FAILED : "LOAD_DATA_WALLET_CHARGE_FAILED",

    CREATION_WALLET_CHARGE : "CREATION_WALLET_CHARGE",
    CREATION_WALLET_CHARGE_SUCCESS : "CREATION_WALLET_CHARGE_SUCCESS",
    CREATION_WALLET_CHARGE_FAILED : "CREATION_WALLET_CHARGE_FAILED",

    LOAD_DATA_WALLET_WITHDRAW : "LOAD_DATA_WALLET_WITHDRAW",
    LOAD_DATA_WALLET_WITHDRAW_SUCCESS : "LOAD_DATA_WALLET_WITHDRAW_SUCCESS",
    LOAD_DATA_WALLET_WITHDRAW_FAILED : "LOAD_DATA_WALLET_WITHDRAW_FAILED",

    CREATION_WALLET_WITHDRAW : "CREATION_WALLET_WITHDRAW",
    CREATION_WALLET_WITHDRAW_SUCCESS : "CREATION_WALLET_WITHDRAW_SUCCESS",
    CREATION_WALLET_WITHDRAW_FAILED : "CREATION_WALLET_WITHDRAW_FAILED",

    PAYMENT_INFO : "PAYMENT_INFO",

    PAYMENT_INFO_WITHDRAW : "PAYMENT_INFO_WITHDRAW",

    GET_PERMISSION_WALLET : "GET_PERMISSION_WALLET",
    GET_PERMISSION_WALLET_SUCCESS : "GET_PERMISSION_WALLET_SUCCESS",
}
export default actionWallet;