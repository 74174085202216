import React from 'react';

function IconChevronUpV2 ({ fontSize = 13 }) {
    return (
        <svg width={fontSize} height="100%" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8L6.5 2L1 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>

    );
}

export default IconChevronUpV2;
