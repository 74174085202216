import React, { useEffect, useState } from 'react'
import ElementType from 'utils/ElementType';
import Validator from 'utils/Validator';
import FormCheckbox from '../common/form/checkbox/FormCheckbox';
import FormRadio from '../common/form/FormRadio';
import FormAddress from '../custom-form/FormAddress';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import Actions from 'redux/app/action';
import { useDispatch } from 'react-redux';
import FormInput from '../common/form/home/FormInput';
import FormDatePicker from '../common/form/datepicker/FormDatePicker';
import FormDateRangePicker from '../common/form/datepicker/FormDateRangePicker';
import FormSelect from '../common/form/FormSelect';
import FormDateTimePicker from '../common/form/datepicker/FormDateTimePicker';
import FormAddressPort from './form-address/FormAddressPort';
import moment from 'moment';

const fields = {
    PCODE: 'address_code',
    MAIN: 'address_main',
    CHOMEBANCHIGO: 'address_chomebanchigo',
    BUILDING: 'address_building'
}

const Field = (props) => {
    const {field, data = "", register, setValue, ...rest} = props;
    const [master, setMaster] = useState(props?.master || []);
    const { trans } = useTrans();

    const dispatch = useDispatch();

    const getValidate = () => {
        var validates = [];
        if (field?.isRequired == Constants.IS_REQUIRED) {
            if (field?.elementType == ElementType.RADIO) {
                validates.push(Validator.requiredCheckbox);
            } else {
                validates.push(Validator.required);
            }
        }
        if (field?.elementType != ElementType.SELECT
            && field?.elementType != ElementType.CHECKBOX
            && field?.elementType != ElementType.RADIO && !field?.messageKey
        ) {
            if (field?.regex) {
                var isRequired = field?.isRequired == Constants.IS_REQUIRED;
                validates.push(Validator.regex(field?.regex, field?.msg, isRequired));
            }
        }
        if (field?.key == "password_confirm" && !field?.messageKey) {
            validates.push(Validator.confirmPassword);
        }

        if (field?.elementType == ElementType.NUMBER && !field?.messageKey) {
            validates.push(Validator.number)
        }
        if (field?.elementType == ElementType.EMAIL && !field?.messageKey) {
            validates.push(Validator.email);
        }
        if (field?.isRequired == Constants.IS_REQUIRED && field?.messageKey) {
            validates.push(Validator.regex(field?.regex, field?.messageKey, isRequired));
        }
        if (field?.regex && field?.messageKey) {
            validates.push(Validator.regex(field?.regex, field?.messageKey, isRequired));
        }

        return validates;
    }

    useEffect(() => {
        if (field?.elementType == ElementType.SELECT
            || field?.elementType == ElementType.CHECKBOX
            || field?.elementType == ElementType.RADIO
        ) {
            var _temp = field?.regex?.split("｜")?.map(item => {
                const [value, name] = item.split("：");
                return {
                    label: name,
                    value: value
                }
            });
            setMaster(_temp);
        }
    }, []);

    // useEffect(() => {
    //     if (field?.elementType == ElementType.DATE) {
    //         let _date = ''
    //         if (field?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE) {
    //             _date = moment(new Date(), "DD/MM/yyyy").toDate();
    //             setValue(field?.key, _date)
    //         }
    //     } else if (field?.elementType == ElementType.DATETIME) {
    //         let _datetime = ''
    //         if (field?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE) {
    //             _datetime = moment(new Date(), "DD/MM/yyyy HH:mm:ss").toDate();
    //             setValue(field?.key, _datetime)
    //         }
    //     } else if (field?.elementType == ElementType.SELECT) {
    //         if (field?.defaultValue?.indexOf("{") == -1) {
    //             setValue(field?.key, field?.defaultValue || '')
    //         } 
    //     } else if (field?.elementType == ElementType.RADIO) {
    //         setValue(field?.key, field?.defaultValue || '')
    //     }
    // }, [])

    const renderField = () => {
        switch (field?.elementType) {
            case ElementType.TEXT:
                return <FormInput
                    field={field}
                    fieldName={field.key}
                    placeholder={field?.explain ?? field?.explainKey}
                    register={register}
                    validate={getValidate()}
                    defaultValue={field?.defaultValue}
                />
            case ElementType.CHECKBOX:
                return <FormCheckbox
                    register={register}
                    name={field.key}
                    label={field?.labelList?.message}
                    validate={getValidate()}
                    field={field}
                    master={master}
                    fieldName={field?.key}
                />
            case ElementType.DATE:
                // let _date = ''
                // if (field?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE) {
                //     _date = moment(new Date(), "DD/MM/yyyy").toDate();
                // }
                // setValue(field?.key, _date)
                return <FormDatePicker
                    name={field.key}
                    validate={getValidate()}
                    register={register}
                    placeholder={field?.explain ?? field?.explainKey}
                    defaultValue={data}
                    maxDate={new Date()}
                    field={field}
                />
            case ElementType.DATE_RANGE:
                return <FormDateRangePicker
                    register={register}
                    name={field.key}
                    validate={getValidate()}
                    defaultValue={data}
                    field={field}
                />
            case ElementType.DATETIME:
                // let _datetime = ''
                // if (field?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE) {
                //     _datetime = moment(new Date(), "DD/MM/yyyy HH:mm:ss").toDate();
                // }
                // setValue(field?.key, _datetime)
                return <FormDateTimePicker
                    name={field.key}
                    validate={getValidate()}
                    register={register}
                    defaultValue={data}
                    field={field}
                />
            case ElementType.PASSWORD:
                return <FormInput
                    field={field}
                    isShowRevealPasswordButton={true}
                    fieldName={field.key}
                    register={register}
                    validate={getValidate()}
                    defaultValue={data}
                    placeholder={field?.explain ?? field?.explainKey}
                    type="password"
                />
            case ElementType.FILE:
                // TODO: Coding new component
                return <FormInput
                    field={field}
                    fieldName={field.key}
                    placeholder={field?.explain ?? field?.explainKey}
                    register={register}
                    validate={getValidate()}
                    defaultValue={data}
                    type="file"
                />
            case ElementType.IMAGE:
                // TODO: Coding new component
                return <FormInput
                    field={field}
                    fieldName={field.key}
                    placeholder={field?.explain ?? field?.explainKey}
                    register={register}
                    validate={getValidate()}
                    defaultValue={data}
                    type="file"
                />
            case ElementType.RADIO:
                // setValue(field?.key, field?.defaultValue || '')
                return <FormRadio
                    name={field.key}
                    master={master}
                    value={data}
                    register={register}
                    validate={getValidate()}
                    field={field}
                />
            case ElementType.SELECT:
                // if (field?.defaultValue?.indexOf("{") == -1) {
                //     setValue(field?.key, field?.defaultValue || '')
                // } 
                return <FormSelect
                    master={master}
                    field={field}
                    name={field.key}
                    register={register}
                    validate={getValidate()}
                    placeholder={field?.explain ?? field?.explainKey}
                />
            // case ElementType.ADDRESS:
            //     return <FormInput
            //         field={field}
            //         fieldName={field.key}
            //         placeholder={field?.explain ?? field?.explainKey}
            //         register={register}
            //         validate={getValidate()}
            //         defaultValue={data}
            //         callback={dynamicOnChange}
            //     />
            case "addr-air": 
                return <FormAddressPort 
                    fieldName={field.key}
                    portType='0'
                />
            case "addr-sea": 
                return <FormAddressPort 
                    fieldName={field.key}
                    portType='1'
                />
            case ElementType.EMAIL:
            case ElementType.NUMBER:
            case ElementType.URL:
            case ElementType.TEL:
            default:
                // setValue(field?.key, field?.defaultValue || '')
                return <FormInput
                    field={field}
                    fieldName={field.key}
                    placeholder={field?.explain ?? field?.explainKey}
                    register={register}
                    validate={getValidate()}
                    defaultValue={field?.defaultValue}
                />
        }
    }

    return <React.Fragment>
        {
            renderField()
        }
    </React.Fragment>
}

export default Field;