const actionHome = {
    GET_LIST_HEX_SERVICE : "GET_LIST_HEX_SERVICE",
    GET_LIST_HEX_SERVICE_SUCCESS : "GET_LIST_HEX_SERVICE_SUCCESS",
    GET_LIST_HEX_SERVICE_FAILED : "GET_LIST_HEX_SERVICE_FAILED",

    GET_TERM_OF_USE_HOME: "GET_TERM_OF_USE_HOME",
    GET_TERM_OF_USE_HOME_SUCCESS: "GET_TERM_OF_USE_HOME_SUCCESS",

    GET_PRIVATE_POLICY_HOME: "GET_PRIVATE_POLICY_HOME",
    GET_PRIVATE_POLICY_HOME_SUCCESS: "GET_PRIVATE_POLICY_HOME_SUCCESS",
}

export default actionHome;