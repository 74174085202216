import SystemSetting from "./action";

const initialState = {
    loading: {
        loadingHolonInstanceSystem: false,
        loadingUpdateHolonInstance: false
    },
    holonInstanceSystem: []
}

const myReducer = (state = initialState, action) => {
    switch (action?.type) {
        case SystemSetting.GET_HOLON_MASTER_SYSTEM:
            return {
                ...state,
                loading:{
                    ...state.loading,
                    loadingHolonInstanceSystem: true
                }
            };
        case SystemSetting.GET_HOLON_MASTER_SYSTEM_SUCCESS:
            return {
                ...state,
                loading:{
                    ...state.loading,
                    loadingHolonInstanceSystem: false
                },
                holonInstanceSystem: action?.payload,
            };
        case SystemSetting.GET_HOLON_MASTER_SYSTEM_FAILED:
            return {
                ...state,
                loading:{
                    ...state.loading,
                    loadingHolonInstanceSystem: false
                },
                holonInstanceSystem: [],
            };
        case SystemSetting.UPDATE_HOLON_MASTER_SYSTEM:
            return {
                ...state,
                loading:{
                    ...state.loading,
                    loadingUpdateHolonInstance: true
                }
            };
        case SystemSetting.UPDATE_HOLON_MASTER_SYSTEM_SUCCESS:
            return {
                ...state,
                loading:{
                    ...state.loading,
                    loadingUpdateHolonInstance: false
                },
            };
        case SystemSetting.UPDATE_HOLON_MASTER_SYSTEM_FAILED:
            return {
                ...state,
                loading:{
                    ...state.loading,
                    loadingUpdateHolonInstance: false
                },
                holonInstanceSystem: [],
            };
        default:
            return {
                ...state,
            };
    }
};

export default myReducer;