const actionAlarm = {
    GET_ALARM_HOLON_SETTING : "GET_ALARM_HOLON_SETTING",
    GET_ALARM_HOLON_SETTING_LOADING : "GET_ALARM_HOLON_SETTING_LOADING",
    GET_ALARM_HOLON_SETTING_SUCCESS : "GET_ALARM_HOLON_SETTING_SUCCESS",
    GET_ALARM_HOLON_SETTING_FAILED : "GET_ALARM_HOLON_SETTING_FAILED",

    CREATE_ALARM_HOLON_SETTING : "CREATE_ALARM_HOLON_SETTING",
    CREATE_ALARM_HOLON_SETTING_LOADING : "CREATE_ALARM_HOLON_SETTING_LOADING",
    CREATE_ALARM_HOLON_SETTING_SUCCESS : "CREATE_ALARM_HOLON_SETTING_SUCCESS",
    CREATE_ALARM_HOLON_SETTING_FAILED : "CREATE_ALARM_HOLON_SETTING_FAILED",

    DELETE_ALARM_HOLON_SETTING : "DELETE_ALARM_HOLON_SETTING",
    DELETE_ALARM_HOLON_SETTING_LOADING : "DELETE_ALARM_HOLON_SETTING_LOADING",
    DELETE_ALARM_HOLON_SETTING_SUCCESS : "DELETE_ALARM_HOLON_SETTING_SUCCESS",
    DELETE_ALARM_HOLON_SETTING_FAILED : "DELETE_ALARM_HOLON_SETTING_FAILED",

    UPDATE_ALARM_HOLON_SETTING : "UPDATE_ALARM_HOLON_SETTING",
    UPDATE_ALARM_HOLON_SETTING_LOADING : "UPDATE_ALARM_HOLON_SETTING_LOADING",
    UPDATE_ALARM_HOLON_SETTING_SUCCESS : "UPDATE_ALARM_HOLON_SETTING_SUCCESS",
    UPDATE_ALARM_HOLON_SETTING_FAILED : "UPDATE_ALARM_HOLON_SETTING_FAILED",

    GET_ALARM_HOLON_SETTING_DETAIL : "GET_ALARM_HOLON_SETTING_DETAIL",
    GET_ALARM_HOLON_SETTING_DETAIL_POPUP : "GET_ALARM_HOLON_SETTING_DETAIL_POPUP",
    GET_ALARM_HOLON_SETTING_DETAIL_LOADING : "GET_ALARM_HOLON_SETTING_DETAIL_LOADING",
    GET_ALARM_HOLON_SETTING_DETAIL_SUCCESS : "GET_ALARM_HOLON_SETTING_DETAIL_SUCCESS",
    RESET_GET_ALARM_HOLON_SETTING_DETAIL : "RESET_GET_ALARM_HOLON_SETTING_DETAIL",
    GET_ALARM_HOLON_SETTING_DETAIL_FAILED : "GET_ALARM_HOLON_SETTING_DETAIL_FAILED",

    GET_MASTER_ALARM : "GET_MASTER_ALARM",
    GET_MASTER_ALARM_LOADING : "GET_MASTER_ALARM_LOADING",
    GET_MASTER_ALARM_SUCCESS : "GET_MASTER_ALARM_SUCCESS",
    GET_MASTER_ALARM_FAILED : "GET_MASTER_ALARM_FAILED",
    
    GET_HOLON_TEMPLATE_DETAIL: "GET_HOLON_TEMPLATE_DETAIL",
    GET_HOLON_TEMPLATE_DETAIL_SUCCESS: "GET_HOLON_TEMPLATE_DETAIL_SUCCESS",
    GET_HOLON_TEMPLATE_DETAIL_FAILED: "GET_HOLON_TEMPLATE_DETAIL_FAILED",

    GET_OWNER_COLLECTION_HOLON_TEMPLATE: "GET_OWNER_COLLECTION_HOLON_TEMPLATE",
    GET_OWNER_COLLECTION_HOLON_TEMPLATE_SUCCESS: "GET_OWNER_COLLECTION_HOLON_TEMPLATE_SUCCESS",
    GET_OWNER_COLLECTION_HOLON_TEMPLATE_FAILED: "GET_OWNER_COLLECTION_HOLON_TEMPLATE_FAILED",
        
    GET_HOLON_TEMPLATE_WITH_CODE_MASTER: "GET_HOLON_TEMPLATE_WITH_CODE_MASTER",
    GET_HOLON_TEMPLATE_WITH_CODE_MASTER_SUCCESS: "GET_HOLON_TEMPLATE_WITH_CODE_MASTER_SUCCESS",
    GET_HOLON_TEMPLATE_WITH_CODE_MASTER_FAILED: "GET_HOLON_TEMPLATE_WITH_CODE_MASTER_FAILED",
}

export default actionAlarm;