import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import UploadFile from '../form-input/upload-file/UploadFile';

function FormFile(props) {
    const {
        fieldName,
        validate = [],
        placeholder,
        disabled = false,
        isRegexLabel,
        validationFE = false
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className="Input w-100">
            <div className="w-100">
                <Controller
                    control={control}
                    name={fieldName}
                    rules={{
                        validate: Validator.genValidate(validate, fieldName),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                            <UploadFile
                                refInput={ref}
                                name={fieldName}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                onBlur={onBlur}
                                value={value}
                                placeholder={placeholder}
                                disabled={disabled}
                                errors={errors}
                                maximumUpload={100}
                                maximumMb={100}
                                isRegexLabel={isRegexLabel}
                                validationFE={validationFE}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
}
FormFile.defaultProps = {
    placeholder: '',
    fieldName: 'fieldName',
};
FormFile.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    iconSearch: PropTypes.element,
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    isRegexLabel: PropTypes.string,
    isSearch: PropTypes.bool,
};
export default FormFile;
