import actionReceiver from "./action";

const initialState = {
    loadingListReceiver: false,
    loadingCreateReceiver: false,
    loadingUpdateReceiver: false,
    loadingReceiverDetail: false,
    listReceiver: {},
    holonTemplateDetail: {}
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionReceiver.GET_RECEIVER_LIST:
            return {
                ...state,
                loadingListReceiver: true
            }
        case actionReceiver.GET_RECEIVER_LIST_SUCCESS:
            return {
                ...state,
                loadingListReceiver: false,
                listReceiver: action.payload
            }
        case actionReceiver.GET_RECEIVER_LIST_FAILED:
            return {
                ...state,
                loadingListReceiver: false
            }
        case actionReceiver.GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER_SUCCESS:
            return {
                ...state,
                holonTemplateDetail: action.payload
            }
        case actionReceiver.CREATE_RECEIVER:
            return {
                ...state,
                loadingCreateReceiver: true
            }
        case actionReceiver.CREATE_RECEIVER_FAILED:
            return {
                ...state,
                loadingCreateReceiver: false
            }
        case actionReceiver.CREATE_RECEIVER_SUCCESS:
            return {
                ...state,
                loadingCreateReceiver: false
            }
        case actionReceiver.UPDATE_RECEIVER:
            return {
                ...state,
                loadingUpdateReceiver: true
            }
        case actionReceiver.UPDATE_RECEIVER_FAILED:
            return {
                ...state,
                loadingUpdateReceiver: false
            }
        case actionReceiver.UPDATE_RECEIVER_SUCCESS:
            return {
                ...state,
                loadingUpdateReceiver: false
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;