import useTrans from 'hooks/use-trans'
import React from 'react'
import AlarmHolonTemplateContainer from 'shared/container/alarm-holon-setting/AlarmHolonSettingContainer'

function AlarmHolonSettingPage() {
    const {t} = useTrans()
    document.title = t("Holon_Template.alarm")
    return (
        <AlarmHolonTemplateContainer />
    )
}

export default AlarmHolonSettingPage