import IconPlusWhite from 'assets/icon/IconPlusWhite';
import IconSearch from 'assets/icon/IconSearch';
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import actionTransaction from 'redux/transaction/action';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormInput from 'shared/components/custom-form/FormInput';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import Constants from 'utils/Constants';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE, EVENT_SHOW_TRANSACTION_CREATE_POPUP, EVENT_SHOW_TRANSACTION_DETAIL_POPUP } from 'utils/EventRegister';
import { convertNumberToString, convertEnumValueToDisplay } from 'utils/String';
import classes from './TransactionContainer.module.scss';
import { useForm } from 'react-hook-form';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import moment from 'moment';
import IconMinusAccordionFilter from 'assets/icon/IconMinusAccordionFilter';

function TransactionContainer() {
    const dispatch = useDispatch();
    const router = useRouter();
    const { t } = useTrans();
    const { transaction, loadingList } = useSelector(state => state?.transaction);
    const methods = useForm();

    useEffect(() => {
        dispatch({
            type: actionTransaction.GET_LIST_TRANSACTION,
            payload: {
                ...router.getAll()
            }
        })
    }, [
        router.get(Constants.QueryParams.LIMIT.VALUE),
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.SEARCH.VALUE),
        router.get(Constants.QueryParams.TRANSACTION_BY.VALUE),
        router.get(Constants.QueryParams.TRANSACTION_ID.VALUE),
        router.get(Constants.QueryParams.TRANSACTION_TYPE.VALUE),
        router.get(Constants.QueryParams.STATUS.VALUE),
        router.get(Constants.QueryParams.TRANSACTION_TIME.VALUE),
        router.get(Constants.QueryParams.WALLET.VALUE),
        router.get(Constants.QueryParams.AMOUNT.VALUE),
        router.get(Constants.QueryParams.AUTHORIZE_NAME.VALUE),
    ])

    const handleSubmit = (data) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.SEARCH.VALUE]: data?.keyword
            }
        })
    }

    let dataHeadCell = [
        {
            label: t('STT'),
            field: "stt",
            minWidth: '10px',
            width: '10px',
            maxWidth: '10px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (_props) => {
                return <>{_props?.stt + 1}</>
            }
        },
        {
            label: "",
            field: "",
            minWidth: '80px',
            width: '80px',
            maxWidth: '80px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => {
                return <ButtonLink title={t('product.detail')} onClick={() => {
                    handleGotoDetail(_props?.data?.id)
                }} />
            }
        },
        {
            label: t('transaction.transaction_id'),
            field: "transactionId",
            minWidth: '180px',
            width: '180px',
            maxWidth: '180px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
        {
            label: t('transaction.transaction_type'),
            field: "transactionType",
            minWidth: '150px',
            width: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => <>{convertEnumValueToDisplay(_props?.data?.transactionType, Constants.TRANSACTION_CODE.TRANSACTION_TYPE)}</>
        },
        {
            label: t('transaction.status'),
            field: "status",
            minWidth: '130px',
            maxWidth: '130px',
            width: '130px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => <>{convertEnumValueToDisplay(_props?.data?.status, Constants.TRANSACTION_CODE.STATUS)}</>
        },
        {
            label: t('transaction.amount_of_money'),
            field: "amount",
            minWidth: '100px',
            maxWidth: '100px',
            width: '100px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'right',
            alignHeader: 'right',
            component: (_props) => {
                return <>{`${_props?.data?.transactionType == Constants.TRANSACTION_CODE.TRANSACTION_TYPE.WITHDRAW.VALUE 
                    || _props?.data?.transactionType == Constants.TRANSACTION_CODE.TRANSACTION_TYPE.PAYMENT.VALUE
                    ? '-' : ''} ${convertNumberToString(_props?.children)}`}</>
            },
        },
        {
            label: t('transaction.transaction_date_and_time'),
            field: "transactionTime",
            minWidth: '200px',
            maxWidth: '200px',
            width: '200px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => {
                return <>
                    {_props?.children ? moment(_props?.children).format("DD/MM/YYYY") : ''}
                </>
            },
        },
        {
            label: t('transaction.transaction_system'),
            field: "transactionBy",
            minWidth: '170px',
            width: '170px',
            maxWidth: '170px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => <>{convertEnumValueToDisplay(_props?.data?.transactionBy, Constants.TRANSACTION_CODE.TRANSACTION_BY)}</>
        },
        {
            label: t('transaction.wallet'),
            field: "wallet",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
        {
            label: t('transaction.authorizer'),
            field: "authorizerName",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
    ];

    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            },
        });
    }

    const onChangePage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e,
            },
        });
    };

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = [
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'transaction_id',
                label: t("transaction.transaction_ID"),
                transaction_id: router.get(Constants.QueryParams.TRANSACTION_ID.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'transaction_type',
                label: t("transaction.transaction_type"),
                transaction_type: router.get(Constants.QueryParams.TRANSACTION_TYPE.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'status',
                label: t("transaction.status"),
                status: router.get(Constants.QueryParams.STATUS.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'transaction_time',
                label: t("transaction.transaction_date_and_time"),
                transaction_time: router.get(Constants.QueryParams.TRANSACTION_TIME.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'amount',
                label: t("transaction.amount_of_money"),
                amount: router.get(Constants.QueryParams.AMOUNT.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'transaction_by',
                label: t("transaction.transaction_system"),
                transaction_by: router.get(Constants.QueryParams.TRANSACTION_BY.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'authorizer_name',
                label: t("transaction.authorizer"),
                authorizer_name: router.get(Constants.QueryParams.AUTHORIZE_NAME.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'wallet',
                label: t("transaction.wallet"),
                wallet: router.get(Constants.QueryParams.WALLET.VALUE) || "",
                options: []
            },
        ]
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                formArray: false,
                callback: (_props) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.TRANSACTION_ID.VALUE]: _props?.transaction_id,
                            [Constants.QueryParams.TRANSACTION_TYPE.VALUE]: _props?.transaction_type,
                            [Constants.QueryParams.STATUS.VALUE]: _props?.status,
                            [Constants.QueryParams.TRANSACTION_TIME.VALUE]: _props?.transaction_time,
                            [Constants.QueryParams.TRANSACTION_BY.VALUE]: _props?.transaction_by,
                            [Constants.QueryParams.AUTHORIZE_NAME.VALUE]: _props?.authorizer_name,
                            [Constants.QueryParams.WALLET.VALUE]: _props?.wallet,
                            [Constants.QueryParams.AMOUNT.VALUE]: _props?.amount,
                        }
                    })
                }
            }
        })
    }

    const handleGotoDetail = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_TRANSACTION_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                id: id,
                isShowOption: false,
                title: t("transaction.transaction_details"),
            }
        })
    }

    const handleAddTransaction = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_TRANSACTION_CREATE_POPUP,
            payload: {
                title: t("transaction.transaction_create"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionTransaction.GET_LIST_TRANSACTION,
                        payload: {
                            ...router.getAll(),
                        }
                    })
                },
            }
        })
    }

    const onChangeSortColumn = (data) => {
        let params = { ...router.getAll() }
        if (data?.type) {
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1,
                    [Constants.QueryParams.SORT.VALUE]: data?.field,
                    [Constants.QueryParams.TYPE_SORT.VALUE]: data?.type,
                }
            })
        }

        if (!data?.type) {
            delete params[Constants.QueryParams.SORT.VALUE];
            delete params[Constants.QueryParams.TYPE_SORT.VALUE];
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1
                }
            })
        }
    }

    const dataSort = (router) => {
        if (router[Constants.QueryParams.SORT.VALUE]) {
            return {
                field: router[Constants.QueryParams.SORT.VALUE],
                type: router[Constants.QueryParams.TYPE_SORT.VALUE]
            }
        } else {
            return {
                field: null,
                type: null
            }
        }
    }

    const handleRemoveRouter = (value) => {
        let params = { ...router.getAll() };
        delete params[value];
        router.replace({
            params: {
                ...params
            }
        })
    }

    return (
        <>
            <ContentLayout>
                <Breadcrumb>{t("transaction.transaction_management")}</Breadcrumb>
                <div className={classes['product-container']}>
                    <div className={classes['custom-search']}>
                        <CustomFormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(handleSubmit)}>
                                <FormSearch className={classes['custom-form-search']}>
                                    <FormGroup>
                                        <FormItem>
                                            <FormInput iconSearch={<IconSearch fontSize={16} />} isSearch fieldName='keyword' placeholder={t("search_by_word")} />
                                        </FormItem>
                                        {
                                            router.get(Constants.QueryParams.TRANSACTION_ID.VALUE) && <FormItem>
                                                <ButtonFilter onClick={() => handleRemoveRouter('transaction_id')} color='white' size='large' title={`${t("transaction.transaction_ID")}：${router.get(Constants.QueryParams.TRANSACTION_ID.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                            </FormItem>
                                        }
                                        {
                                            router.get(Constants.QueryParams.TRANSACTION_TYPE.VALUE) && <FormItem>
                                                <ButtonFilter onClick={() => handleRemoveRouter('transaction_type')} color='white' size='large' title={`${t("transaction.transaction_type")}：${router.get(Constants.QueryParams.TRANSACTION_TYPE.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                            </FormItem>
                                        }
                                        {
                                            router.get(Constants.QueryParams.STATUS.VALUE) && <FormItem>
                                                <ButtonFilter onClick={() => handleRemoveRouter('status')} color='white' size='large' title={`${t("transaction.status")}：${router.get(Constants.QueryParams.STATUS.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                            </FormItem>
                                        }
                                        {
                                            router.get(Constants.QueryParams.TRANSACTION_TIME.VALUE) && <FormItem>
                                                <ButtonFilter onClick={() => handleRemoveRouter('transaction_time')} color='white' size='large' title={`${t("transaction.transaction_date_and_time")}：${router.get(Constants.QueryParams.TRANSACTION_TIME.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                            </FormItem>
                                        }
                                        {
                                            router.get(Constants.QueryParams.AMOUNT.VALUE) && <FormItem>
                                                <ButtonFilter onClick={() => handleRemoveRouter('transaction_amount')} color='white' size='large' title={`${t("transaction.amount_of_money")}：${router.get(Constants.QueryParams.AMOUNT.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                            </FormItem>
                                        }
                                        {
                                            router.get(Constants.QueryParams.TRANSACTION_BY.VALUE) && <FormItem>
                                                <ButtonFilter onClick={() => handleRemoveRouter('transaction_by')} color='white' size='large' title={`${t("transaction.transaction_system")}：${router.get(Constants.QueryParams.TRANSACTION_BY.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                            </FormItem>
                                        }
                                        {
                                            router.get(Constants.QueryParams.WALLET.VALUE) && <FormItem>
                                                <ButtonFilter onClick={() => handleRemoveRouter('transaction_wallet')} color='white' size='large' title={`${t("transaction.wallet")}：${router.get(Constants.QueryParams.WALLET.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                            </FormItem>
                                        }
                                        {
                                            router.get(Constants.QueryParams.AUTHORIZE_NAME.VALUE) && <FormItem>
                                                <ButtonFilter onClick={() => handleRemoveRouter('transaction_authorize_name')} color='white' size='large' title={`${t("transaction.authorizer")}：${router.get(Constants.QueryParams.AUTHORIZE_NAME.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                            </FormItem>
                                        }
                                        <FormItem>
                                            <ButtonFilter onClick={handleOpenAddSearchCriteria} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                        </FormItem>
                                    </FormGroup>
                                    <ButtonAdd onClick={handleAddTransaction} size='large' color='yellow' endIcon={<IconPlusWhite />} />
                                </FormSearch>
                                <button hidden type='submit' />
                            </form>
                        </CustomFormProvider>
                    </div>
                    <div className={classes['custom-body']}>
                        <CustomTable
                            dataSort={dataSort({ ...router.getAll() })} onChangeSortColumn={onChangeSortColumn}
                            loading={loadingList} data={transaction?.records ?? []} headCell={dataHeadCell} />
                        <div className={classes['custom-pagination']}>
                            <CustomPagination
                                isRowPerpage
                                onChangeRowPerpage={onChangeRowPerpage}
                                limit={router.get(Constants.QueryParams.LIMIT.VALUE)}
                                onChange={onChangePage} total={transaction?.total} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </>
    )
}

export default TransactionContainer  