import React from 'react';
import classes from './CustomTab.module.scss';

function CustomTab(props) {
    const { data = [], value, onChange = () => {}, action } = props;
    const handleChange = (e) => {
        onChange(e.id);
    };
    return (
        <div className={`${classes['CommonTab']} CommonTab`}>
            <div className={`${classes['CommonTab_Content']}`}>
                <div className={classes['TabList']}>
                    {data.map((x, key) => (
                        <div
                            className={`${classes['TabListItem']} ${
                                value == x.id ? classes['TabListActive'] : ''
                            }`}
                            key={key}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChange(x);
                            }}
                        >
                            <span>{x?.label}</span>
                        </div>
                    ))}
                </div>
                <div className={classes['TabList_Action']}>{action}</div>
            </div>
        </div>
    );
}

export default CustomTab;
