import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import actionCargo from "./action";
import factory from "./factory";

function* getHolonTemplateCargo() {
    yield takeEvery(actionCargo.GET_TEMPLATE_CARGO, function* (payload) {
        try {
            const response = yield call(() => factory.getMasterHolonTemplate(payload?.payload));
            yield payload?.callback?.success(response)
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
        }
    })
}

function* createProductDetailCargo() {
    yield takeEvery(actionCargo.CREATE_PRODUCT_DETAIL_CARGO, function* (payload) {
        try {
            const response = yield call(() => factory.createProductDetailCargo(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response)
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response)
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error);
        }
    })
}

function* getProductDetailCargo() {
    yield takeEvery(actionCargo.GET_PRODUCT_DETAIL_CARGO, function* (payload) {
        try {
            const response = yield call(() => factory.getProductDetailCargo(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data)
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
        }
    })
}

function* getListProductCargo() {
    yield takeLatest(actionCargo.GET_LIST_PRODUCT_CARGO_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getListProductCargo(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data)
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
        }
    })
}

function* getWarehouseDefault() {
    yield takeEvery(actionCargo.GET_WARE_HOUSE_DEFAULT, function* (payload) {
        try {
            const response = yield call(() => factory.getWarehouseDefault());
            if(response?.data?.id){
                localStorage.setItem("belong_to_warehouse", JSON.stringify(response?.data))
            }else{
                localStorage.removeItem("belong_to_warehouse");
            }
            yield payload?.callback?.success && payload?.callback?.success(response?.data)
        } catch (error) {
            yield payload?.callback?.success && payload?.callback?.success(null)
        }
    })
}

function* returnRequest() {
    yield takeEvery(actionCargo.RETURN_REQUEST, function* (payload) {
        try {
            const response = yield call(() => factory.returnRequest(payload?.payload));
            yield payload?.callback?.success && payload?.callback?.success(response, null)
        } catch (error) {
            yield payload?.callback?.success && payload?.callback?.success(null, error)
        }
    })
}

function* returnRequestReason() {
    yield takeEvery(actionCargo.RETURN_REQUEST_REASON, function* (payload) {
        try {
            const response = yield call(() => factory.returnRequestReason(payload?.payload));
            yield payload?.callback?.success && payload?.callback?.success(response, null)
        } catch (error) {
            yield payload?.callback?.success && payload?.callback?.success(null, error)
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getHolonTemplateCargo),
        fork(createProductDetailCargo),
        fork(getProductDetailCargo),
        fork(getListProductCargo),
        fork(getWarehouseDefault),
        fork(returnRequest),
        fork(returnRequestReason)
    ])
}