import React from 'react';
import classes from './ErrorMessage.module.scss';
import Validator from 'utils/Validator';

function ErrorMessageHandleRegex(props) {
    const { message, style, isRegexLabel } = props;
    return (
        <div className={classes['ErrorMessage']}>
            <p style={style}>
                {Validator.renderMessage(message, { 0: isRegexLabel })}
            </p>
        </div>
    );
}

export default ErrorMessageHandleRegex;
