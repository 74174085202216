import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const BASE_URL = process.env.REACT_APP_HOLON_CORE_URL
const requestApiWithAction = ({
    path, params, data, method, responseType = null
}) => {
    let request = {
        url: `${BASE_URL}${path}`,
        method: method,
        params,
        data,
        responseAll: true,
        responseType: responseType
    };

    if (responseType) {
        request['responseType'] = responseType;
    }
    return ApiOperation.request(request)
        .then((resp) => {
            if (resp?.error) {
                return {
                    error: resp?.error
                };
            }
            if (resp?.headers?.['content-disposition']) {
                let filename = resp?.headers['content-disposition'];
                filename = filename.split(/;(.+)/)[1].split(/=(.+)/)[1]
                if (filename.toLowerCase().startsWith("utf-8''")) {
                    filename = decodeURIComponent(filename.replace("utf-8''", ''))
                } else if (filename.toLowerCase().startsWith("UTF-8''")) {
                    filename = decodeURIComponent(filename.replace("UTF-8''", ''))
                } else {
                    filename = filename.replace(/['"]/g, '');
                }
                return { data: resp?.data, filename: filename?.split(";")[0], type: 'blob', contentType: resp?.headers?.['content-type'] }
            } else {
                return resp?.data;
            }
        })
        .catch((error) => {
            return error;
        });
}


const factory = {
    getMasterHolonTemplate: (payload) => {
        var url = '/rest/api/v1/management/holon-template';
        let params = {
            isAll: true,
            sortColumn: 'id',
            sortType: "asc",
            ...payload
        };

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    getHolonTemplateDetail: (payload) => {
        var url = `/rest/api/v1/management/holon-template/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getDetailHolonInstance: (payload) => {
        var url = `/rest/api/v1/management/holon-instance/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    userCompany: () => {
        var url = `/rest/api/v1/master/user-in-company`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getHolonInstance: (payload) => {
        var url = `/rest/api/v1/management/holon-instance/filter`;
        let params = {
            limit: 10,
        }

        let data = {}

        if (payload[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.KEYWORD.VALUE]?.trim();
        }
        if (payload[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]) {
            params['templateId'] = payload[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE];
        }
        if (payload[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE]) {
            params['subTemplateId'] = payload[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE];
        }
        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        }
        if (payload[Constants.QueryParams.VIEW.VALUE]) {
            params['view'] = payload[Constants.QueryParams.VIEW.VALUE];
        }
        if (payload[Constants.QueryParams.CONNECTION_NAME.VALUE]) {
            params['connectionName'] = payload[Constants.QueryParams.CONNECTION_NAME.VALUE];
        }
        if (payload[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE]) {
            params['connectionWithHolonId'] = payload[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE];
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        }
        if (payload[Constants.QueryParams.USER_IN_COMPANY.VALUE]) {
            params['userInCompanyIds'] = payload[Constants.QueryParams.USER_IN_COMPANY.VALUE];
        }
        if (payload[Constants.QueryParams.IS_SEARCH_ALL_TEMPLATE.VALUE]) {
            params['isSearchAllTemplate'] = payload[Constants.QueryParams.IS_SEARCH_ALL_TEMPLATE.VALUE];
        }
        if (payload[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE]) {
            params['requireConnectionKey'] = payload[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE];
        }
        if (payload[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE]) {
            params['requireConnectionHolonId'] = payload[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE];
        }
        if (payload[Constants.QueryParams.EXCLUDE_IDS.VALUE]) {
            params['excludeIds'] = payload[Constants.QueryParams.EXCLUDE_IDS.VALUE];
        }

        if (payload[Constants.QueryParams.ATTRIBUTES.VALUE]) {
            data['mainAttribute'] = JSON.parse(payload[Constants.QueryParams.ATTRIBUTES.VALUE]);
        }

        if (payload[Constants.QueryParams.ATTRIBUTES_SUB.VALUE]) {
            data['subAttribute'] = JSON.parse(payload[Constants.QueryParams.ATTRIBUTES_SUB.VALUE]);
        }

        return ApiOperation.request({
            url: url,
            method: 'POST',
            params: params,
            data: data
        })
    },
    getHolonInstanceWithHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/holon-instance/filter`;
        let params = {
            // isAll: true
        }

        let data = {}

        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE];
        }
        if (payload[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]) {
            params['templateId'] = payload[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE];
        }
        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        }
        if (payload[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]) {
            params['holonIdForMultiple'] = payload[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE];
        }
        if (payload[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]) {
            params['connectionKeyForMultiple'] = payload[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE];
        }
        if (payload[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]) {
            params['isSelectForConnection'] = payload[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE];
        }
        if (payload[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.KEYWORD.VALUE];
        }
        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload[Constants.QueryParams.ATTRIBUTES.VALUE]) {
            data['mainAttribute'] = JSON.parse(payload[Constants.QueryParams.ATTRIBUTES.VALUE]);
        }

        return ApiOperation.request({
            url: url,
            method: 'POST',
            params: params,
            data: data
        })
    },
    getHolonTemplateWithIds: (payload) => {
        var url = `/rest/api/v1/management/holon-template`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                ids: payload,
                isAll: true
            }
        })
    },
    createHolonInstance: (payload) => {
        var url = `/rest/api/v1/management/holon-instance`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    updateHolonInstance: (payload) => {
        var url = `/rest/api/v1/management/holon-instance`;

        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    getListTemplateIsWithIdStance: (payload) => {
        var url = `/rest/api/v1/management/holon-instance/by-ids`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                ids: payload
            }
        })
    },
    cloneHolonInstance: (payload) => {
        var url = `/rest/api/v1/management/holon-instance/clone/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
        })
    },
    deleteHolonInstance: (payload) => {
        var url = `/rest/api/v1/management/holon-instance/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'DELETE',
        })
    },
    requestApiWithUrl: (payload) => {
        return ApiOperation.request({
            url: payload?.url,
            method: payload?.method,
            data: payload?.data,
            params: payload?.params
        });
    },
    requestApiWithAction: (payload) => {
        return requestApiWithAction({
            path: payload?.url,
            method: payload?.method,
            data: payload?.data,
            params: payload?.params,
            responseType: payload?.data?.responseType
        })
    },
    getPackageTracking: (payload) => {
        var url = `/rest/api/v1/management/tracking-information`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                hexOrderNumbers: payload
            }
        })
    },
    bpToPayment: (payload) => {
        var url = `/rest/api/v1/management/bp/payment`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    getCommonField: (payload) => {
        var url = `/rest/api/v1/management/holon-template/common-field`;

        let params = {}

        if (payload) {
            params['templateIds'] = payload;
        } else {
            params['templateIds'] = ""
        }
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    getCommonAction: (payload) => {
        var url = `/rest/api/v1/management/holon-template/common-action`;

        let params = {}

        if (payload) {
            params['templateIds'] = payload;
        } else {
            params['templateIds'] = ""
        }
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
}

export default factory;