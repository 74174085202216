import React from 'react'

function IconTar() {
    return (
        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5519 0H7.55198H6.93204L6.49403 0.438002L0.438002 6.49441L0 6.93241V7.55203V20.0381C0 22.2653 1.81178 24.0775 4.03931 24.0775H15.5519C17.7787 24.0775 19.5905 22.2653 19.5905 20.0381V4.03936C19.5905 1.81183 17.7787 0 15.5519 0ZM18.0953 20.0381C18.0953 21.4431 16.9566 22.5818 15.5519 22.5818H4.03931C2.63398 22.5818 1.49529 21.4431 1.49529 20.0381V7.55198H5.43218C6.60243 7.55198 7.55198 6.60309 7.55198 5.43251V1.49562H15.5519C16.9566 1.49562 18.0953 2.63431 18.0953 4.03936V20.0381Z" fill="#0E525E" />
            <text style={{fontSize:'6px', fontWeight: '500'}} fill="#0E525E" x="6" y ="15">tar</text>
        </svg>

    )
}

export default IconTar