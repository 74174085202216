import React, { useEffect, useState } from 'react'
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination'
import CustomTable from 'shared/components/common/custom-table/CustomTable'
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider'
import classes from './CustomFormTable.module.scss';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import actionStocks from 'redux/stocks/action';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_UPDATE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import useTrans from 'hooks/use-trans';
import Button from 'shared/components/form-input/button/Button';

function CustomFormTable(props) {
    const { loadingGetList, listProduct, dataHeadCell, id } = props;
    const router = useRouter();
    const { t } = useTrans();
    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            },
        });
    }

    const onChangePage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e,
            },
        });
    };

    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const methodsTable = useForm();
    const dispatch = useDispatch();
    const control = methodsTable.control;
    const { fields } = useFieldArray({
        control: control,
        name: 'stocks'
    })
    const onSubmit = (data) => {
        let stocks = data?.stocks?.map(x => {
            return {
                id: x?.id,
                note: x?.note,
                actualQuantity: x?.inventoryQuantity,
            }
        });
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoading(true);
                    dispatch({
                        type: actionStocks.UPDATE_INVENTORY,
                        payload: {
                            data: stocks
                        },
                        callback: {
                            success: () => {
                                setLoading(false);
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: t("successful_update"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                });
                                dispatch({
                                    type: actionStocks.GET_LIST_STOCKS,
                                    payload: {
                                        ...router.getAll(),
                                        [Constants.QueryParams.WAREHOUSE_ID.VALUE]: id
                                    }
                                })
                            },
                            failed: () => {
                                setLoading(false)
                            }
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (listProduct?.records) {
            methodsTable.reset({
                stocks: listProduct?.records
            })
        }
    }, [listProduct?.records])

    useEffect(() => {
        let data = methodsTable.watch(["stocks"])[0];
        if (data) {
            let sum = data?.reduce((accumulator, currentValue) => {
                return accumulator + (parseInt(currentValue?.inventoryQuantity, 10) || 0)
            }, 0);
            setQuantity(sum)
        }
    }, [methodsTable.watch(["stocks"])])

    let totalRow = [
        {
            ...listProduct?.summary,
            total: true,
            productUrl: t("stocks.total"),
            quantity: quantity
        }
    ]

    return (
        <>
            <CustomFormProvider {...methodsTable}>
                <form onSubmit={methodsTable.handleSubmit(onSubmit)}>
                    <CustomTable totalRow={totalRow} loading={loadingGetList} data={fields ?? []} headCell={dataHeadCell} />
                    <div className={classes['custom-pagination']}>
                        <CustomPagination
                            isRowPerpage
                            onChangeRowPerpage={onChangeRowPerpage}
                            limit={router.get(Constants.QueryParams.LIMIT.VALUE)}
                            onChange={onChangePage} total={listProduct?.total} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
                    </div>
                    <div className={classes['section-action']}>
                        <Button type='submit' loading={loading} title={t("Holon_Template.save")} />
                    </div>
                </form>
            </CustomFormProvider>
        </>
    )
}

export default CustomFormTable