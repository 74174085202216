const actionProduct = {
    GET_LIST_PRODUCT : "GET_LIST_PRODUCT",
    GET_LIST_PRODUCT_SUCCESS : "GET_LIST_PRODUCT_SUCCESS",
    GET_LIST_PRODUCT_FAILED : "GET_LIST_PRODUCT_FAILED",

    DELETE_PRODUCT : "DELETE_PRODUCT",
    GET_DETAIL_PRODUCT_POPUP : "GET_DETAIL_PRODUCT_POPUP",
    DELETE_PRODUCT_SUCCESS : "DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_FAILED : "DELETE_PRODUCT_FAILED",

    UPDATE_PRODUCT : "UPDATE_PRODUCT",
    UPDATE_PRODUCT_SUCCESS : "UPDATE_PRODUCT_SUCCESS",
    UPDATE_PRODUCT_FAILED : "UPDATE_PRODUCT_FAILED",

    CREATE_PRODUCT : "CREATE_PRODUCT",
    CREATE_PRODUCT_SUCCESS : "CREATE_PRODUCT_SUCCESS",
    CREATE_PRODUCT_FAILED : "CREATE_PRODUCT_FAILED",

    GET_DETAIL_PRODUCT : "GET_DETAIL_PRODUCT",
    GET_DETAIL_PRODUCT_SUCCESS : "GET_DETAIL_PRODUCT_SUCCESS",
    GET_DETAIL_PRODUCT_FAILED : "GET_DETAIL_PRODUCT_FAILED",

    GET_LIST_PRODUCT_POPUP: "GET_LIST_PRODUCT_POPUP",

    GET_CATEGORY: 'GET_CATEGORY',

    UPLOAD_FILE_PRODUCT: 'UPLOAD_FILE_PRODUCT',
}

export default actionProduct;