import React, { useEffect, useState } from 'react'
import BoxRow from '../../box/BoxRow'
import BoxLabel from '../../box/BoxLabel'
import BoxContent from '../../box/BoxContent'
import FormInput from 'shared/components/custom-form/FormInput'
import useTrans from 'hooks/use-trans'
import Validator from 'utils/Validator'
import FormSelect from 'shared/components/custom-form/FormSelect'
import Constants from 'utils/Constants'
import FormTextArea from 'shared/components/custom-form/FormTextArea'
import FormSelectInstance from 'shared/components/custom-form/FormSelectInstance'
import FormFileWarehouse from 'shared/components/custom-form/FormFileWarehouse'
import classes from './Transaction.module.scss';
import IconChevronDown from 'assets/icon/IconChevronDown'
import IconChevronUp from 'assets/icon/IconChevronUp'
import actionWallet from 'redux/wallet/action';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

const convertObjectToArray = (obj) => {
    return Object.keys(obj).map(key => {
        return {
            label: obj[key]?.NAME,
            value: obj[key]?.VALUE,
            key: obj[key]?.VALUE
        }
    })
}

const RowBank = ({ }) => {
    const { t } = useTrans();
    return (
        <div className={classes['row']}>
            <div className={classes['body']}>
                <ul className={classes['body-info']}>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("transaction.bank_name")}</label>
                            <FormInput validationFE validate={[]} isRegexLabel={t("transaction.bank_name")} fieldName='bankName' />
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("transaction.branch_name")}</label>
                            <FormInput validationFE validate={[]} isRegexLabel={t("transaction.branch_name")} fieldName='branchName' />
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("transaction.account_type")}</label>
                            <FormSelect options={convertObjectToArray(Constants.TRANSACTION_CODE.ACCOUNT_TYPE)} validationFE validate={[]} isRegexLabel={t("transaction.account_type")} fieldName='accountType' />
                        </div >
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("transaction.account_no")}</label>
                            <FormInput maxLength={20} validationFE validate={[]} isRegexLabel={t("transaction.account_no")} fieldName='accountNo' />
                        </div >
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("transaction.account_owner")}</label>
                            <FormInput validationFE validate={[]} isRegexLabel={t("transaction.account_owner")} fieldName='accountOwner' />
                        </div >
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("transaction.account_owner_kana")}</label>
                            <FormInput validationFE validate={[]} isRegexLabel={t("transaction.account_owner_kana")} fieldName='accountOwnerKana' />
                        </div >
                    </li>
                </ul >
            </div >
        </div >
    )
}

function FormData() {
    const methods = useFormContext();
    const { setValue } = methods;
    const { t } = useTrans()
    const [open, setOpen] = useState(false)
    const [bankInfo, setBankInfo] = useState({})
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: actionWallet.PAYMENT_INFO,
            callback: {
                success: (data) => {
                    setBankInfo(data)
                }
            }
        })
    }, [])
    const handleChangeTransactionType = (transactionType) => {
        if (transactionType == Constants.TRANSACTION_CODE.TRANSACTION_TYPE.CHARGE.VALUE || transactionType == Constants.TRANSACTION_CODE.TRANSACTION_TYPE.PAYMENT.VALUE) {
            changeAccountInfo(bankInfo)
            setOpen(true)
        } else {
            changeAccountInfo(null)
        }

        methods.setError("order", {
            message: undefined
        })
        methods.clearErrors("order");
    }
    const changeAccountInfo = (accountInfo) => {
        setValue("bankName", accountInfo?.bankName ?? '')
        setValue("branchName", accountInfo?.branchName ?? '')
        setValue("accountType", accountInfo?.accountType ?? '')
        setValue("accountNo", accountInfo?.accountNo ?? '')
        setValue("accountOwner", accountInfo?.accountOwner ?? '')
        setValue("accountOwnerKana", accountInfo?.accountOwnerKana ?? '')
    }
    let currency = [
        {
            label: 'JPY',
            value: 'JPY',
            key: 'JPY'
        }
    ];

    let watchData = methods.watch(['transactionType']);

    let orderValidator = [];
    if (watchData[0] == Constants.TRANSACTION_CODE.TRANSACTION_TYPE.PAYMENT.VALUE) {
        orderValidator.push(Validator.requiredSelectTemplate);
    } else {
        orderValidator = [];
    }
    return (
        <>
            <BoxRow >
                <BoxLabel isRequired>
                    {t("transaction.transaction_id")}
                </BoxLabel>
                <BoxContent>
                    <FormInput
                        fieldName='transactionId'
                        validate={[]}
                        disabled
                    />
                </BoxContent>
            </BoxRow>
            <BoxRow >
                <BoxLabel isRequired>
                    {t("transaction.transaction_type")}
                </BoxLabel>
                <BoxContent>
                    <FormSelect
                        fieldName='transactionType'
                        validate={[Validator.required]}
                        options={convertObjectToArray(Constants.TRANSACTION_CODE.TRANSACTION_TYPE)}
                        validationFE
                        isRegexLabel={t("transaction.transaction_type")}
                        onChangeSelect={handleChangeTransactionType}
                    />
                </BoxContent>
            </BoxRow>
            <BoxRow >
                <BoxLabel isRequired>
                    {t("status")}
                </BoxLabel>
                <BoxContent isRequired>
                    <FormSelect
                        fieldName='status'
                        validate={[Validator.required]}
                        options={convertObjectToArray(Constants.TRANSACTION_CODE.STATUS)}
                        validationFE
                        isRegexLabel={t("status")}
                    />
                </BoxContent>
            </BoxRow>
            <BoxRow >
                <BoxLabel isRequired>
                    {t("transaction.amount_of_money")}
                </BoxLabel>
                <BoxContent style={{
                    flexDirection: 'row',
                    display: 'flex'
                }}>
                    <FormInput
                        fieldName='amount'
                        validate={[Validator.required]}
                        validationFE
                        isRegexLabel={t("transaction.amount_of_money")}
                        format={Constants.FormInputFormat.NUMBER.VALUE}
                    />
                    <div style={{ width: 100 }}>
                        <FormSelect
                            validationFE
                            validate={[]}
                            isRegexLabel={t("wallet_charge.charge_amount")}
                            fieldName='currency'
                            placeholder={t("wallet_charge.charge_amount")}
                            options={currency}
                        />
                    </div>
                </BoxContent>
            </BoxRow>
            <BoxRow >
                <BoxLabel isRequired>
                    {t("transaction.transaction_system")}
                </BoxLabel>
                <BoxContent>
                    <FormSelect
                        fieldName='transactionBy'
                        validate={[Validator.required]}
                        options={convertObjectToArray(Constants.TRANSACTION_CODE.TRANSACTION_BY)}
                        validationFE
                        isRegexLabel={t("transaction.transaction_system")}
                    />
                </BoxContent>
            </BoxRow>
            <BoxRow>
                <BoxLabel
                    style={{
                        gap: 10,
                        cursor: 'pointer'
                    }} onClick={() => setOpen(!open)}>
                    {t("transaction.account_info")}
                    {
                        open ? <IconChevronDown /> : <IconChevronUp fontSize={12} />
                    }
                </BoxLabel>
                <BoxContent>
                    {
                        open && <RowBank />
                    }
                </BoxContent>
            </BoxRow>
            <BoxRow >
                <BoxLabel isRequired>
                    {t("transaction.wallet")}
                </BoxLabel>
                <BoxContent>
                    <FormSelectInstance
                        fieldName='wallet'
                        validate={[Validator.requiredSelectTemplate]}
                        holonTemplate={Constants.TRANSACTION_CODE.TEMPLATE_ID.WALLET}
                        isMulti={false}
                        validationFE
                        isRegexLabel={t("transaction.wallet")}
                    />
                </BoxContent>
            </BoxRow>
            <BoxRow >
                <BoxLabel isRequired={watchData[0] == Constants.TRANSACTION_CODE.TRANSACTION_TYPE.PAYMENT.VALUE ? true : false}>
                    {t("transaction.order")}
                </BoxLabel>
                <BoxContent>
                    <FormSelectInstance
                        fieldName='order'
                        validate={[...orderValidator]}
                        holonTemplate={Constants.TRANSACTION_CODE.TEMPLATE_ID.ORDER}
                        isMulti={false}
                        validationFE
                        isRegexLabel={t("transaction.order")}
                    />
                </BoxContent>
            </BoxRow>
            <BoxRow >
                <BoxLabel>
                    {t("transaction.remark")}
                </BoxLabel>
                <BoxContent>
                    <FormTextArea
                        fieldName='remark'
                        validate={[]}
                    />
                </BoxContent>
            </BoxRow>
            <BoxRow >
                <BoxLabel>
                    {t("transaction.evidence")}
                </BoxLabel>
                <BoxContent>
                    <FormFileWarehouse
                        buttonTitle={<div className={classes['btn-upload']}>{t("wallet_charge.upload_new_photo")}</div>}
                        validationFE
                        validate={[]}
                        isRegexLabel={t("transaction.evidence")}
                        fieldName='evidence'
                        placeholder={t("transaction.evidence")} />
                </BoxContent>
            </BoxRow>
        </>
    )
}

export default FormData