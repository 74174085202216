import React from "react";
import useTrans from "hooks/use-trans";
import Box from "shared/components/form-input/box/Box";
import classes from "./FreightCalculationManagementContainer.module.scss";
import FormInput from 'shared/components/custom-form/FormInput';
import BoxBody from 'shared/components/common/box/BoxBody';
import CustomBox from 'shared/components/common/box/Box';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import Validator from 'utils/Validator';
import FormSelect from 'shared/components/custom-form/FormSelect';
import Utils from 'utils/Utils';
import Constants from 'utils/Constants';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "",
    ...draggableStyle,
    zIndex: 1,
    width: 100000
});

const getListStyle = isDraggingOver => ({
    zIndex: 1,
    padding: '8px 19px 12px 19px',
    flexShrink: 0,
    width: 100000
});
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
const EditFreightEstimate = (props) => {
    const { removeUserInput, appendUserInput, fieldsUserInput, method } = props
    const { t } = useTrans()
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let items = reorder(
            method.watch("fareCalcUserInputs"),
            result.source.index,
            result.destination.index
        );
        items = items?.map((x, index) => {
            return {
                ...x,
                sortNo: index
            }
        })
        props.callback(items)
    }

    const data = method.watch("fareCalcUserInputs")

    return (
        <Box className={classes["freight-calculation-management"]}>
            <div className={classes["title-input-item"]}>
                <p>
                    {t('fare_calculation.screen_input_items')}
                </p>
                <div className={classes['icon-plus']} style={{ cursor: "pointer" }} onClick={() => {
                    appendUserInput({
                        key: "",
                        explainKey: "",
                        regex: "",
                        defaultValue: "",
                        isRequired: "",
                        isMultiSelected: "",
                        isEditable: "",
                        labelKey: "",
                        messageKey: "",
                        sortNo: fieldsUserInput?.length + 1,
                        id: "",
                        idCreated: fieldsUserInput?.length + 1
                    })
                }}>
                    <IconPlusWhite />
                </div>
            </div>
            <CustomBox>
                <BoxBody padding={0}>
                    {
                        fieldsUserInput.length !== 0 &&
                        <div className={classes['custom-table-edit-estimate']}>
                            <table>
                                <thead>
                                    <tr className={classes['form-body-fare']}>
                                        <th className={classes['tableEditFreight']}>{t("fare_calculation.name")}</th>
                                        <th className={classes['tableEditFreight']}>{t("fare_calculation.key")}</th>
                                        <th className={classes['tableEditFreight']}>{t("fare_calculation.default")}</th>
                                        <th className={classes['tableEditFreight']}>{t("Holon_Template.regex")}</th>
                                        <th className={classes['tableEditFreight']}>{t("fare_calculation.input_range")}</th>
                                        <th className={classes['tableEditFreight']}>{t("fare_calculation.place_holder")}</th>
                                        <th className={classes['checkbox-width']}>{t("Holon_Template.message_key")}</th>
                                        <th style={{ width: '40px' }} className={classes['col-delete']}>{t("Holon_Template.require")} </th>
                                        <th className={classes['col-delete']}>{t("Holon_Template.delete")} </th>
                                    </tr>
                                </thead>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable direction='vertical' droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <tbody
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {
                                                    fieldsUserInput.map((x, key) => {
                                                        return <Draggable key={x?.id} draggableId={x?.id} index={key}>
                                                            {
                                                                (provided, snapshot) => (
                                                                    <tr index={key}
                                                                        ref={provided.innerRef}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        key={x?.idCreated}>
                                                                        <td>
                                                                            <FormInput className={classes['tableEditFreight']} validationFE validate={[Validator.required]} isRegexLabel={t("fare_calculation.name")} fieldName={`fareCalcUserInputs.${key}.labelKey`} />
                                                                        </td>
                                                                        <td>
                                                                            <FormInput className={classes['tableEditFreight']} validationFE validate={[Validator.required, Validator.duplicate(data)]} isRegexLabel={t("fare_calculation.key")} fieldName={`fareCalcUserInputs.${key}.key`} />
                                                                        </td>
                                                                        <td>
                                                                            <FormInput className={classes['tableEditFreight']} isRegexLabel={t("fare_calculation.default")} fieldName={`fareCalcUserInputs.${key}.defaultValue`} />
                                                                        </td>
                                                                        <td>
                                                                            <FormInput className={classes['tableEditFreight']} isRegexLabel={t("Holon_Template.regex")} fieldName={`fareCalcUserInputs.${key}.regex`} />
                                                                        </td>
                                                                        <td>
                                                                            <FormSelect className={classes['tableEditFreight']} validationFE isRegexLabel={t("fare_calculation.input_range")} validate={[Validator.required]} options={Utils.convertObjectKeyToArr(Constants.ELEMENT_TYPE_ESTIMATE, "NAME", "VALUE", "Holon_Template.list_element_type.")} fieldName={`fareCalcUserInputs.${key}.elementType`} />
                                                                        </td>
                                                                        <td>
                                                                            <FormInput className={classes['tableEditFreight']} isRegexLabel={t("fare_calculation.place_holder")} fieldName={`fareCalcUserInputs.${key}.explainKey`} />
                                                                        </td>
                                                                        <td>
                                                                            <FormInput className={classes['tableEditFreight']} isRegexLabel={t("Holon_Template.message_key")} fieldName={`fareCalcUserInputs.${key}.messageKey`} />
                                                                        </td>
                                                                        <td style={{ width: '40px' }} className={classes['checkbox-width']}>
                                                                            <FormCheckbox fieldName={`fareCalcUserInputs.${key}.isRequired`} />
                                                                        </td>
                                                                        <td style={{ width: '40px' }} onClick={() => removeUserInput(key)}>
                                                                            <p style={{ cursor: 'pointer' }}>
                                                                                <IconMinusAccordion />
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </Draggable>
                                                    })
                                                }
                                            </tbody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </table>
                        </div>
                    }
                </BoxBody>
            </CustomBox>
        </Box>
    );
}

export default EditFreightEstimate;
