import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import TrackingInformationAction from "./action";
import factory from "./factory";

function* getTrackingInformationHistory() {
    yield takeEvery(TrackingInformationAction.GET_TRACKING_INFORMATION_HISTORY, function* (payload) {
        try {
            const response = yield call(() => factory.getTrackingInformationHistory(payload?.payload));

            if (response?.data) {
                yield put({
                    type: TrackingInformationAction.GET_TRACKING_INFORMATION_HISTORY_SUCCESS,
                    payload: response?.data
                })
                yield payload?.callback?.success && payload?.callback?.success()
            }
            else {
                yield put({
                    type: TrackingInformationAction.GET_TRACKING_INFORMATION_HISTORY_FAILURE,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            yield put({
                type: TrackingInformationAction.GET_TRACKING_INFORMATION_HISTORY_FAILURE,
            })
        }
    })
}

function* updateTrackingInformationHistory() {
    yield takeEvery(TrackingInformationAction.UPDATE_TRACKING_INFORMATION_HISTORY, function* (payload) {
        try {
            const response = yield call(() => factory.updateTrackingInformationHistory(payload?.payload));

            if (response?.data) {
                yield put({
                    type: TrackingInformationAction.UPDATE_TRACKING_INFORMATION_HISTORY_SUCCESS,
                    payload: response?.data
                })
                yield payload?.callback?.success && payload?.callback?.success()
            }
            else {
                yield put({
                    type: TrackingInformationAction.UPDATE_TRACKING_INFORMATION_HISTORY_FAILURE,
                    payload: response?.error?.detail,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            yield put({
                type: TrackingInformationAction.UPDATE_TRACKING_INFORMATION_HISTORY_FAILURE,
            })
        }
    })
}

function* checkOrderStatus() {
    yield takeEvery(TrackingInformationAction.CHECK_ORDER_STATUS, function* (payload) {
        try {
            const response = yield call(() => factory.checkOrderStatus(payload?.payload));

            if (response?.data) {
                if (payload?.callback) {
                    payload?.callback(response?.data);
                }
            } else {
                payload?.fallback && payload?.fallback(response?.error?.detail)
            }
        }
        catch (e) {
            payload?.fallback && payload?.fallback(e)
        }
    })
}

function* cancelOrder() {
    yield takeEvery(TrackingInformationAction.CANCEL_ORDER, function* (payload) {
        try {
            const response = yield call(() => factory.cancelOrder(payload?.payload));

            if (response?.data) {
                if (payload?.callback) {
                    payload?.callback(response?.data);
                }
            } else {
                payload?.fallback && payload?.fallback(response?.error?.detail)
            }
        }
        catch (e) {
            payload?.fallback && payload?.fallback(e)
        }
    })
}

function* importOrder() {
    yield takeEvery(TrackingInformationAction.UPLOAD_FILE_ORDER, function* (payload) {
        try {
            const response = yield call(() => factory.importOrder(payload?.payload));
            yield payload?.callback?.success(response, null);
        }
        catch (e) {
            payload?.callback?.success && payload?.callback?.success(null, e)
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getTrackingInformationHistory),
        fork(updateTrackingInformationHistory),
        fork(checkOrderStatus),
        fork(cancelOrder),
        fork(importOrder)
    ])
}