import useTrans from 'hooks/use-trans';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import actionCargo from 'redux/cargo/action';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import HolonInstance from 'shared/components/holon-instance/HolonInstance';
import Constants from 'utils/Constants';

function BpRequestPaymentContainer() {
    const dispatch = useDispatch();
    const [list, setList] = useState(null)
    const { t } = useTrans();
    const [listTemplateCreation, setListTemplateCreation] = useState([]);

    useEffect(() => {
        dispatch({
            type: actionCargo.GET_TEMPLATE_CARGO,
            payload: {
                masterCodes: Constants.BP_REQUEST_PAYMENT_MASTER_CODE.MASTER_CODE.VALUE
            },
            callback: {
                success: (data) => {
                    setList(data?.records?.map(x => x?.id)?.join(","));
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_TEMPLATE_WITH_IDS,
                        payload: data?.records?.map(x => x?.id)?.join(","),
                        callback: (_data) => {
                            setListTemplateCreation(_data?.map(x => ({ id: x?.id, name: x?.name})));
                        }
                    })
                }
            }
        })
    }, [])

    return (
        <>
            {
                list && <HolonInstance
                    isCargo
                    template_id={list}
                    titleCreatePopup={t("bp_request_payment.bp_request_payment_creation")}
                    titleDetailPopup={t("bp_request_payment.bp_request_payment_detail")}
                    titleUpdatePopup={t("bp_request_payment.bp_request_payment_edit")}
                    title={t("bp_request_payment.bp_bill_management")}
                    typeCategory={Constants.BP_REQUEST_PAYMENT_MASTER_CODE.CATEGORY.VALUE}
                    isShowCategory
                    listTemplateCreation={listTemplateCreation}
                />
            }
        </>
    )
}

export default BpRequestPaymentContainer