import React, { useEffect, useState } from 'react'
import classes from './ReceiverDetail.module.scss';
import Button from 'shared/components/form-input/button/Button';
import { Close } from '@mui/icons-material';
import IconEdit from 'assets/icon/IconEdit';
import { useDispatch } from 'react-redux';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_UPDATE_RECEIVER_POPUP } from 'utils/EventRegister';
import useTrans from 'hooks/use-trans';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import Constants from 'utils/Constants';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import moment from 'moment';
import WrapContentBody from '../custom-body/WrapContentBody';
import actionReceiver from 'redux/receiver/action';
import actionWareHouse from 'redux/warehouse/action';
import useRouter from 'hooks/use-router';
import Box from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import CommonValueAttributes from 'shared/components/form-input/common-value-attributes/CommonValueAttributes';

function ReceiverDetailPopup(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const router = useRouter();
    const dispatch = useDispatch();
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [dataHolonInstanceDetail, setHolonInstanceDetail] = useState([]);
    const handleEdit = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_UPDATE_RECEIVER_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                title: t("receiver.update_receiver"),
                holonTemplateDetail: payload?.holonTemplateDetail,
                receiver: payload?.receiver,
            }
        })
    }

    useEffect(() => {
        if (payload?.holonTemplateDetail?.attributes?.length > 0 && payload?.receiver.id) {
            setHolonInstanceDetail(payload?.holonTemplateDetail?.attributes?.map(x => {
                let indexDetail = payload?.receiver.attributes?.findIndex(attribute => attribute.name == x?.key);
                return {
                    ...x,
                    [x?.key]: payload?.receiver.attributes[indexDetail]?.value,
                    value: payload?.receiver.attributes[indexDetail]?.value,
                    name: payload?.receiver.attributes[indexDetail]?.name,
                }
            }))
        }
    }, [payload?.holonTemplateDetail, payload?.receiver])

    const handleClose = () => {
        showVisible()
    }

    const handleDelete = () => {
        setLoadingDelete(true);
        dispatch({
            type: actionReceiver.DELETE_RECEIVER,
            payload: payload?.receiver?.id,
            callback: {
                success: () => {
                    dispatch({
                        type: actionReceiver.GET_RECEIVER_LIST,
                        payload: {
                            ...router.getAll()
                        }
                    })
                    handleClose();
                    setLoadingDelete(false);
                },
                failed: () => {
                    setLoadingDelete(false);
                }
            }
        })
    }

    const onClose = () => {
        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL_SUCCESS,
            payload: {}
        })
    }


    return (
        <BoxContentPopup>
            <WrapContentBody
                title={payload?.title}
                showVisible={showVisible}
                actionSectionTop={<>
                    <Button onClick={handleEdit} color='gray' startIcon={<IconEdit color="currentColor" />} /></>}
                actionSectionBottom={<Button loading={loadingDelete} onClick={handleDelete} title={t("Holon_Template.delete")} />}
            >
                <div className={classes['Wrap-Content']}>
                    <div className={classes['FormHas']}>
                        <Box>
                            <BoxHeader>
                                <BoxTitle>{t("receiver.basic_information")}</BoxTitle>
                            </BoxHeader>
                            <BoxBody>
                                {
                                    dataHolonInstanceDetail?.map((x, key) => {
                                        return <BoxRow key={key} className={classes['FormTable-Row']}>
                                            <BoxLabel minWidth="140px">
                                                {x?.label}
                                            </BoxLabel>
                                            <BoxContent className={`${classes['Body_Title']} ${classes['Body_Title-Value']}`}>
                                                <CommonValueAttributes x={x} />
                                            </BoxContent>
                                        </BoxRow>
                                    })
                                }
                            </BoxBody>
                        </Box>
                    </div>
                </div>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default ReceiverDetailPopup