import React, { useEffect } from 'react';
import useTrans from 'hooks/use-trans';
import ResetPasswordContainer from 'shared/container/user/id-password-reset/ResetPasswordContainer';

function ResetPasswordPage() {
    const { t } = useTrans()
    useEffect(() => {
        document.title = t("ResetPassword.title");
    }, [])
    return <ResetPasswordContainer />
}

export default ResetPasswordPage;
