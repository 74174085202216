import React, { useState } from 'react';
import styles from "./Box.module.scss";
import BoxHeader from './BoxHeader';

function BoxSection(props) {
    const [tab, setTab] = useState(1)
    return (
        <div className={styles["Box"]} style={props?.style}>
            <BoxHeader id={props?.id}>
                <div className={`${styles['Section_Tab']}`}>
                    {props?.BoxHeader?.map(x => {
                        return <div key={x?.id} className={`${styles['Section_Title']} ${tab == x?.id ? styles['Section_Title_Active'] : ''}`} onClick={() => setTab(x?.id)}>
                            {x?.component}
                        </div>
                    })}
                </div>
            </BoxHeader>
            {props?.BoxBody[tab]}
        </div>
    )
}
export default BoxSection;