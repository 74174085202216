import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import Constants from './Constants';
import i18n from "i18nextInit"

import EventRegister, {
    EVENT_SHOW_POPUP,
    FIRST_POPUP,
    POPUP_TEXT_TYPE,
    EVENT_SHOW_POPUP_CANCEL_POST,
    EVENT_SHOW_POPUP_DELETE,
    EVENT_SHOW_POPUP_ACCEPT,
    EVENT_SHOW_POPUP_COMMENT,
    EVENT_SHOW_POPUP_EDIT,
    EVENT_SHOW_POPUP_CREATE_POST,
    EVENT_SHOW_POPUP_CREATE_GROUP,
    EVENT_SHOW_ADD_MEMBER,
    EVENT_SHOW_UPDATE_SHORTCUTS,
    EVENT_SHOW_POPUP_CONFIRM_DELETE,
    EVENT_SHOW_POPUP_CONFIRM_UPDATE,
    EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
    EVENT_SHOW_POPUP_CONFIRM

}
    from './EventRegister';
import { toast } from 'react-toastify';
import moment from 'moment';
import Validator from './Validator';
import RouterPath from 'router/RouterPath';
import FileSaver from 'file-saver';


export default class Utils {
    /**
    @argument: none
    @returns: boolean
    @description: check user is logged
  */
    static isLogged() {
        const token = Cookies.get('user') && JSON.parse(Cookies.get('user'))?.Token || '';
        const jwtDecodeValue = Utils.decodeJWT(token);
        if (!token || jwtDecodeValue.isExpired) {
            return false;
        }
        return true;
    }

    static getCurrentRole() {
        const token = Cookies.get('accessToken') && Cookies.get('accessToken') || '';
        if (!token) {
            return null;
        }
        const value = jwtDecode(token);
        return value.role;
    }

    /**
    @argument: token - string
    @returns: object
    @description: decode jwt token
  */
    static decodeJWT(token) {
        if (!token) {
            return null;
        }
        const value = jwtDecode(token);
        return {
            isExpired: value.exp < new Date().getTime() / 1000,
        };
    }

    static ConvertType(type) {
        switch (type) {
            case Constants.STATUS_POST.STATUS_APPROVE:
                return <span style={{ color: '#138300', fontSize: 14 }}>{Constants.TEXT_POST_TYPE.STATUS_APPROVE}</span>;
            case Constants.STATUS_POST.STATUS_NOT_APPROVE:
                return <span style={{ color: '#FF4D4D', fontSize: 14 }}>{Constants.TEXT_POST_TYPE.STATUS_NOT_APPROVE}</span>;
            case Constants.STATUS_POST.STATUS_WAIT_APPROVE:
                return <span style={{ color: '#DAB600', fontSize: 14 }}>{Constants.TEXT_POST_TYPE.STATUS_WAIT_APPROVE}</span>;
            case Constants.STATUS_POST.STATUS_REPORT:
                return <span style={{ color: '#FF2C00', fontSize: 14 }}>{Constants.TEXT_POST_TYPE.STATUS_WAIT_APPROVE}</span>;
            default:
                return <span style={{ color: '#138300', fontSize: 14 }}>{Constants.TEXT_POST_TYPE.STATUS_APPROVE}</span>;
        }
    }

    static alertPopup(
        title = 'Title',
        type = POPUP_TEXT_TYPE,
        callback = null,
        layer = FIRST_POPUP,
        backdropCallback = null
    ) {
        let eventName = layer == FIRST_POPUP ? EVENT_SHOW_POPUP : EVENT_SHOW_POPUP
        let typePopup = POPUP_TEXT_TYPE;
        switch (type) {
            case POPUP_TEXT_TYPE:
                typePopup = POPUP_TEXT_TYPE;
                break;
            case EVENT_SHOW_POPUP_CANCEL_POST:
                typePopup = EVENT_SHOW_POPUP_CANCEL_POST;
                break;
            case EVENT_SHOW_POPUP_DELETE:
                typePopup = EVENT_SHOW_POPUP_DELETE;
                break;
            case EVENT_SHOW_POPUP_ACCEPT:
                typePopup = EVENT_SHOW_POPUP_ACCEPT;
                break;
            case EVENT_SHOW_POPUP_COMMENT:
                typePopup = EVENT_SHOW_POPUP_COMMENT;
                break;
            case EVENT_SHOW_POPUP_EDIT:
                typePopup = EVENT_SHOW_POPUP_EDIT;
                break;
            case EVENT_SHOW_POPUP_CREATE_POST:
                typePopup = EVENT_SHOW_POPUP_CREATE_POST;
                break;
            case EVENT_SHOW_POPUP_CREATE_GROUP:
                typePopup = EVENT_SHOW_POPUP_CREATE_GROUP;
                break;
            case EVENT_SHOW_ADD_MEMBER:
                typePopup = EVENT_SHOW_ADD_MEMBER;
                break;
            case EVENT_SHOW_UPDATE_SHORTCUTS:
                typePopup = EVENT_SHOW_UPDATE_SHORTCUTS;
                break;
            default:
                typePopup = POPUP_TEXT_TYPE;
                break;
        }
        EventRegister.emit(eventName, {
            type: typePopup,
            open: true,
            payload: {
                title: title,
                callback,
                backdropCallback
            },
        });
    }

    static asyncEvery = async (arr, predicate) => {
        for (let e of arr) {
            if (!await predicate(e)) { return false; }
        }
        return true;
    };

    static handlePagination(oldData, newData, isLoadMore, nameClass) {
        let _newData = [...oldData[nameClass]];
        let newOffset = oldData.Offset;
        if (isLoadMore) {
            if (
                newData[nameClass].length > 0 &&
                newData.Total > _newData.length
            ) {
                _newData = _newData.concat([...newData[nameClass]]);
            }
            newOffset = newData.Offset;
        } else {
            _newData = [...newData[nameClass]];
        }
        let newList = {
            Limit: newData.Limit,
            Offset: newOffset,
            Total: newData.Total,
        };
        newList[nameClass] = [..._newData];
        return newList;
    }
    static triggerSubmit = (wrapRef) => {
        try {
            const listBtn = wrapRef.current
                .closest('form.quick-submit-form')
                .querySelectorAll('button[type=submit]');
            if (listBtn.length > 0) {
                listBtn[0].click();
            }
        } catch (error) {
            return error;
        }
    };

    static convertObjectKeyToArr = (object, name = "NAME", value = 'VALUE', transText = '') => {
        return Object.keys(object)?.map(key => {
            return {
                value: object[key][value],
                key: object[key][value],
                label: transText ? i18n.t(`${transText}${object[key][name]}`) : i18n.t(`${object[key][name]}`),
            }
        })
    }
}

export const getLoggedInUserInfoFromToken = () => {
    const token = Cookies.get('accessToken') && Cookies.get('accessToken') || '';
    if (!token) {
        return null;
    }
    const value = jwtDecode(token);
    return value;
}

export const getToast = (message, typeToast, iconMessage, timeClose) => {
    switch (typeToast) {
        case "success":
            return toast.success(message ?? 'Demo', {
                type: typeToast,
                icon: iconMessage,
                autoClose: timeClose,
                onOpen: toast.dismiss(),
            });
        case "error":
            return toast.error(message ?? 'Demo', {
                type: typeToast,
                icon: iconMessage,
                autoClose: timeClose,
                onOpen: toast.dismiss(),
            });
        case "warning":
            return toast.error(message ?? 'Demo', {
                type: typeToast,
                icon: iconMessage,
                autoClose: timeClose,
                onOpen: toast.dismiss(),
            });
        case "info":
            return toast.error(message ?? 'Demo', {
                type: typeToast,
                icon: iconMessage,
                autoClose: timeClose,
                onOpen: toast.dismiss(),
            });
        default:
            return toast.success(message ?? 'Demo', {
                type: typeToast,
                icon: iconMessage,
                autoClose: timeClose,
                onOpen: toast.dismiss(),
            });
    }
}

export const getToastError = (detail = {}) => {
    Object.keys(detail).forEach(key => {
        detail[key]?.map(msg => {
            getToast(msg, "error");
        })
    })
}

export const getLng = () => {
    let lng = localStorage.getItem("i18nextLng");
    if (lng) {
        let data = Object.keys(Constants.T001)?.map(key => {
            return {
                value: Constants.T001[key]?.CODE?.toLowerCase(),
                // label: i18n.t(`faq_management.lang.${Constants.T001[key]?.NAME}`),
            }
        })?.reduce((obj, cur) => ({ ...obj, [cur?.value]: cur }), {});
        if (data[lng]) {
            return data[lng]?.value;
        } else {
            return "jp";
        }
    } else {
        return "jp";
    }
}

export const convertRegexToOptions = (_string = "") => {
    // TODO: [0:なし｜1:軽｜3:中｜5:高]
    _string = _string?.replace("[", '');
    _string = _string?.replace("]", '');

    let array = [];
    if (_string?.indexOf("|") != -1) {
        array = _string?.split("|");
    } else {
        array = _string?.split("｜");
    }
    array = array?.map(x => {
        let index = x.indexOf(":")
        if (index != -1) {
            return {
                label: x?.split(":")[1],
                key: x?.split(":")[0],
                value: x?.split(":")[0]
            }
        } else {
            return {
                label: x?.split("：")[1],
                key: x?.split("：")[0],
                value: x?.split("：")[0]
            }
        }
    })
    return array;
}

export function arrayToObject(arr) {
    var rv = {};
    for (var i = 0; i < arr.length; ++i) {
        rv[arr[i]?.key] = arr[i]?.value
    }
    return rv;
}

export function stringParamsToObject(string) {
    let arr = string?.split("&")
    var rv = {};
    for (var i = 0; i < arr.length; ++i) {
        rv[arr[i]?.split("=")?.[0]] = arr[i]?.split("=")?.[1]
    }
    return rv;
}

export const convertDate = (date) => {
    return date ? date?.split("-").reverse()?.join("/") : ""
}

export function RenderDateTime(date) {

    const lang = localStorage.getItem('i18nextLng');
    const _moment = moment(date);
    var formattedDate = _moment;
    if (lang == "jp") {
        formattedDate = _moment.format('DD/MM/YYYY');
        _moment.locale('ja');
    } else if (lang == 'en') {
        _moment.locale('en');
        formattedDate = _moment.format('DD/MM/YYYY');
    } else {
        _moment.locale('zh-cn');
        formattedDate = _moment.format('DD/MM/YYYY');
    }

    return formattedDate;
}

export const showPopupNotification = ({
    type,
    title = null,
    message = null,
    callback,
    newWindow = false,
    typePopup = 'confirm',
    payload,
    option
}) => {
    switch (typePopup) {
        case 'delete':
            type = EVENT_SHOW_POPUP_CONFIRM_DELETE
            break;
        case 'update':
            type = EVENT_SHOW_POPUP_CONFIRM_UPDATE
            break;
        case 'message':
            type = EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE
            break;
        case 'confirm':
            type = EVENT_SHOW_POPUP_CONFIRM
            break;
        case 'custom':
            break;
        default:
            break;
    }

    EventRegister.emit(EVENT_SHOW_POPUP, {
        open: true,
        type: type,
        newWindow: newWindow,
        payload: {
            showHeader: false,
            customStyle: {
                padding: 0,
                borderTop: 0
            },
            callback: callback,
            title: title,
            message: message,
            option: option,
            ...payload
        }
    })
}

export function replaceCondenseFilename(initial) {
    initial = initial?.split("/")?.[initial?.split("/")?.length - 1]
    return initial;
}

export function insertIntoSpecificIndex(arr, obj, index = 0) {
    arr?.splice(index, 0, obj)
    return arr;
}

export const getMessageCommon = ({ response, error = null, actionName = "", isAction = true, callback,
    messageSuccess = '', messageFailed = '' }) => {
    let message = null;
    if (isAction) {
        if (error) {
            if(error?.status == Constants.STATUS_REQUEST.REQUEST_TIME_OUT.VALUE){
                message = i18n.t("E0089");
            }else{
                message = error?.error?.detail?.exception[0];
            }
        }

        if (response?.error) {
            if (response?.error?.code == 202) {
                message = response?.error?.message;
            } else {
                message = response?.error?.message ? response?.error?.message : Validator.renderMessage(i18n.t("I0005"), {
                    0: actionName
                });
            }
        }

        if (response?.errors) {
            if (response?.errors?.length > 0) {
                message = response?.errors?.[0]?.errorMessage;
            } else {
                message = response?.message ? response?.message : Validator.renderMessage(i18n.t("I0004"), {
                    0: actionName
                });
            }
        }

        if (response?.data) {
            if (response?.data?.errors && response?.data?.errors?.length > 0) {
                message = response?.data?.errors?.[0]?.errorMessage;
            } else if (response?.data?.data && response?.data?.data?.length > 0) {
                if (response?.data?.data?.length == 1) {
                    if (!response?.data?.data?.[0]?.isSuccess) {
                        message = response?.data?.data?.[0]?.errorMessage;
                    } else {
                        message = Validator.renderMessage(i18n.t("I0004"), {
                            0: actionName
                        });
                    }
                }
            } else {
                message = response?.data?.message ? response?.data?.message : Validator.renderMessage(i18n.t("I0004"), {
                    0: actionName
                });
            }
        }

        if(response?.status == Constants.STATUS_REQUEST.REQUEST_TIME_OUT.VALUE){
            message = i18n.t("E0089");
        }
    } else {
        if (error) {
            if(error?.status == Constants.STATUS_REQUEST.REQUEST_TIME_OUT.VALUE){
                message = i18n.t("E0089");
            }else{
                message = error?.error?.detail?.exception[0];
            }
        }

        if (response?.error) {
            if (response?.error?.code == 202) {
                message = response?.error?.message;
            } else {
                message = response?.error?.message ? response?.error?.message : messageFailed;
            }
        }

        if (response?.data) {
            message = response?.data?.message ? response?.data?.message : messageSuccess;
            callback && callback();
        }

        if (response?.id) {
            message = messageSuccess;
            callback && callback();
        }

        if(response?.status == Constants.STATUS_REQUEST.REQUEST_TIME_OUT.VALUE){
            message = i18n.t("E0089");
        }
    }

    return message;
}

export const downloadFileWithFileName = (data, fileName, contentTypeProps) => {
    if (data) {
        let contentType = 'text/csv';
        if (contentTypeProps) {
            contentType = contentTypeProps;
        }
        const blob = new Blob([data], { type: contentType });
        FileSaver.saveAs(blob, fileName);
    }
}

export const ReloadActionWithScreen = ({
    screenId,
    router,
    id
}) => {
    if (screenId == Constants.SCREEN_ACTION.G1660.ID) {
        window.open(RouterPath.getRouteWithId(Constants.SCREEN_ACTION.G1660.VALUE, id))
    }
    if (screenId == Constants.SCREEN_ACTION.G1670.ID) {
        window.open(RouterPath.getRouteWithId(Constants.SCREEN_ACTION.G1670.VALUE, id))
    }
}
export const isRouteRoleUser = (route) => {
    let menus = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : [];
    const result = [];

    function recursiveFlatten(items) {
        for (const item of items) {
            result.push(item);
            if (item.children && item.children.length > 0) {
                recursiveFlatten(item.children);
            }
        }
    }
    recursiveFlatten(menus);
    var isExist = false;
    var isEnable = false;
    for (let index = 0; index < result.length; index++) {
        const item = result[index];
        if (item?.href == route) {
            isExist = true;
            if (item?.isEnable) {
                isEnable = true;
            }
            break;
        }
    }
    return isExist && isEnable || !isExist;
}