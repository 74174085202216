import React from "react";

function IconUpload({ fontSize = 40}) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            fontSize={fontSize}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.00016 23.3335C5.92064 23.3335 6.66683 24.0797 6.66683 25.0002V31.6668C6.66683 32.1089 6.84242 32.5328 7.15498 32.8453C7.46755 33.1579 7.89147 33.3335 8.3335 33.3335H31.6668C32.1089 33.3335 32.5328 33.1579 32.8453 32.8453C33.1579 32.5328 33.3335 32.1089 33.3335 31.6668V25.0002C33.3335 24.0797 34.0797 23.3335 35.0002 23.3335C35.9206 23.3335 36.6668 24.0797 36.6668 25.0002V31.6668C36.6668 32.9929 36.14 34.2647 35.2024 35.2024C34.2647 36.14 32.9929 36.6668 31.6668 36.6668H8.3335C7.00741 36.6668 5.73564 36.14 4.79796 35.2024C3.86028 34.2647 3.3335 32.9929 3.3335 31.6668V25.0002C3.3335 24.0797 4.07969 23.3335 5.00016 23.3335Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.5118 13.6798C28.861 14.3307 27.8057 14.3307 27.1548 13.6798L20 6.52499L12.8452 13.6798C12.1943 14.3307 11.139 14.3307 10.4882 13.6798C9.83728 13.0289 9.83728 11.9737 10.4882 11.3228L18.8215 2.98946C19.4724 2.33858 20.5276 2.33858 21.1785 2.98946L29.5118 11.3228C30.1627 11.9737 30.1627 13.0289 29.5118 13.6798Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0002 3.3335C20.9206 3.3335 21.6668 4.07969 21.6668 5.00016V25.0002C21.6668 25.9206 20.9206 26.6668 20.0002 26.6668C19.0797 26.6668 18.3335 25.9206 18.3335 25.0002V5.00016C18.3335 4.07969 19.0797 3.3335 20.0002 3.3335Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default IconUpload;
