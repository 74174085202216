import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Constants from 'utils/Constants'
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import InputMessage from '../form-input/input-message/InputMessage';
import InputUploadMessage from '../form-input/input-message/InputUploadMessage';

function FormInputUploadMessage(props) {
    const {
        defaultValue,
        fieldName,
        validate = [],
        placeholder,
        format = '',
        disabled = false,
        isTooltip = false,
        iconSearch,
        width,
        height,
        background,
        className,
        onChangeFile,
        loading = false,
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <Controller
            control={control}
            name={fieldName}
            rules={{
                validate: Validator.genValidate(validate, fieldName),
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                const onChangeValue = (e) => {
                    onChange(e);
                    onChangeFile && onChangeFile(e)
                };
                return (
                    <InputUploadMessage
                        refInput={ref}
                        name={fieldName}
                        onChange={onChangeValue}
                        onBlur={onBlur}
                        value={value ?? ""}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        errors={errors}
                        required={false}
                        disabled={disabled}
                        isTooltip={isTooltip}
                        maxLength={props.maxLength}
                        iconSearch={iconSearch}
                        width={width}
                        height={height}
                        background={background}
                        className={className}
                        loading={loading}
                    />
                );
            }}
        />
    );
}
FormInputUploadMessage.defaultProps = {
    placeholder: '',
    fieldName: 'fieldName',
};
FormInputUploadMessage.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    iconSearch: PropTypes.element,
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
};
export default FormInputUploadMessage;
