const Action = {

    GET_TRACKING_INFORMATION_HISTORY: 'GET_TRACKING_INFORMATION_HISTORY',
    GET_TRACKING_INFORMATION_HISTORY_SUCCESS: 'GET_TRACKING_INFORMATION_HISTORY_SUCCESS',
    GET_TRACKING_INFORMATION_HISTORY_FAILURE: 'GET_TRACKING_INFORMATION_HISTORY_FAILURE',

    UPDATE_TRACKING_INFORMATION_HISTORY: 'UPDATE_TRACKING_INFORMATION_HISTORY',
    UPDATE_TRACKING_INFORMATION_HISTORY_SUCCESS: 'UPDATE_TRACKING_INFORMATION_HISTORY_SUCCESS',
    UPDATE_TRACKING_INFORMATION_HISTORY_FAILURE: 'UPDATE_TRACKING_INFORMATION_HISTORY_FAILURE',

    CHECK_ORDER_STATUS: 'CHECK_ORDER_STATUS',
    CHECK_ORDER_STATUS_SUCCESS: 'CHECK_ORDER_STATUS_SUCCESS',
    CHECK_ORDER_STATUS_FAILURE: 'CHECK_ORDER_STATUS_FAILURE',

    CANCEL_ORDER: 'CANCEL_ORDER',
    CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
    CANCEL_ORDER_FAILURE: 'CANCEL_ORDER_FAILURE',

    UPLOAD_FILE_ORDER: 'UPLOAD_FILE_ORDER',
}

export default Action;