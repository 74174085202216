import React from 'react';
import classes from './ErrorMessage.module.scss';
import Validator from 'utils/Validator';
import useTrans from 'hooks/use-trans';

function ErrorMessageHandleFE(props) {
    const { trans } = useTrans();
    const { message, style, fieldName , textTrans} = props;
    let name =  fieldName;
    if(textTrans){
        name = `${textTrans}.${fieldName}`
    }
    return (
        <div className={classes['ErrorMessage']}>
            <p style={style}>
                <p className={classes['ErrorMessage']}>
                    {Validator.renderMessage(trans(message), { 0: trans(name) })}
                </p>
            </p>
        </div>
    );
}

export default ErrorMessageHandleFE;
