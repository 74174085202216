import React from 'react';
import classes from './FormSearch.module.scss';

function FormGroup(props) {
    return <div className={`${classes['FormGroup']} FormGroup`} style={{
        flexWrap: props?.flexWrap ? 'wrap' : 'nowrap',
        ...props?.styles
    }}>{props?.children}</div>;
}

export default FormGroup;
