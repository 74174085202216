import React, { useEffect, useState } from 'react'
import styles from './CompanyTemplateContainer.module.scss'
import Actions from 'redux/holon/action';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import { useDispatch } from 'react-redux';
import actionHolon from 'redux/holon/action';
import { actionsHolonInstance } from 'redux/holon-instance/action';

const CompanyTemplateContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTrans()
    const [companyTemplate, setCompanyTemplate] = useState();
    const [idAndNameConnection, setIddAndNameConnection] = useState();
    const [listTemplateCreation, setListTemplateCreation] = useState();
    useEffect(() => {
        dispatch({
            type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.COMPANY,
            callback: (data) => {
                setCompanyTemplate(data.toString());
                dispatch({
                    type: actionsHolonInstance.GET_HOLON_TEMPLATE_WITH_IDS,
                    payload: data?.join(","),
                    callback: (_data) => {
                        setListTemplateCreation(_data?.map(x => ({ id: x?.id, name: x?.name})));
                    }
                })
            }
        })
    }, []);

    useEffect(() => {
        dispatch({
            type: actionHolon.GET_OUTSOURCE_COMPANY_CLIENT_CONNECTION,
            callback: (data) => {
                setIddAndNameConnection(data)
            },
            fallback:() =>{
                const errors = {
                    cname: '',
                    cid: '',
                    holonMasterName: ''
                }
                setIddAndNameConnection(errors)
            }
        })
    },[])

    return (
        <div className={`${styles['order-management-container']}`}>
            <div className={styles['holon-instance']} >
                {companyTemplate && idAndNameConnection?.cid &&
                    <HolonInstance
                        title={t("company_template.title")}
                        template_id={companyTemplate}
                        cname={idAndNameConnection?.cname}
                        cid={idAndNameConnection?.cid}
                        dataPreset={
                            {
                                templateId: companyTemplate,
                                data: {
                                    attributes: {},
                                    connections: {
                                        [idAndNameConnection?.cname]: [{
                                            value: idAndNameConnection?.cid,
                                            name: idAndNameConnection?.holonMasterName
                                        }]
                                    }
                                }
                            }
                        }
                        titleDetailPopup={t("company_template.popup_detail_title")}
                        titleCreatePopup={t("company_template.popup_create_title")}
                        titleUpdatePopup={t("company_template.popup_update_title")}
                        listTemplateCreation={listTemplateCreation?.map(x => ({ id: x?.id, name: x?.name}))}
                    />}
            </div>
        </div>
    )
}

export default CompanyTemplateContainer