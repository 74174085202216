import DashboardContainer from 'shared/container/dashboard/DashboardContainer'
import React, { useEffect } from 'react'
import Constants from 'utils/Constants';

function DashboardPage() {
    useEffect(() => {
        document.title = Constants.TITLE_PAGE;
    }, [])
    return (
        <DashboardContainer />
    )
}

export default DashboardPage