import React, { useEffect, useState } from 'react'
import classes from './AlarmContainer.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import Button from 'shared/components/form-input/button/Button';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination';
import { useForm } from 'react-hook-form';
import useRouter from 'hooks/use-router';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import useTrans from 'hooks/use-trans';
import Constants from 'utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import CustomTab from 'shared/components/common/custom-tab/CustomTab';
import actionAlarm from 'redux/alarm/action';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE } from 'utils/EventRegister';
import moment from 'moment';
import TableAlarm from 'shared/components/alarm/TableAlarm';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';

function AlarmContainer() {
    const methods = useForm();
    const router = useRouter();
    const { t } = useTrans();
    const [valueTab, setValueTab] = useState(1)
    const [dataTab, setDataTab] = useState([])
    const { loading: {
        loadingGetAlarm = false
    }, listAlarm, alarmMaster } = useSelector(state => state.alarm);

    const dispatch = useDispatch();

    const handleSubmit = (data) => {
        router.replace({
            params: {
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.KEYWORD.VALUE]: data?.keyword,
            }
        })
    }

    const renderDataFilter = () => {
        const handleRemoveRouter = (_key) => {
            let params = { ...router.getAll() };
            delete params[_key]
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1
                }
            })
        }

        return <>
            {
                router.get(Constants.QueryParams.SUBJECT.VALUE) && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.SUBJECT.VALUE)} color='white' size='large' title={`${t("Holon_Template.subject")}: ${router.get(Constants.QueryParams.SUBJECT.VALUE)}`} endIcon={<IconMinusAccordion />} />
            }
            {
                router.get(Constants.QueryParams.MESSAGE.VALUE) && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.MESSAGE.VALUE)} color='white' size='large' title={`${t("alarm.content")}: ${router.get(Constants.QueryParams.MESSAGE.VALUE)}`} endIcon={<IconMinusAccordion />} />
            }
            {
                router.get(Constants.QueryParams.FROM.VALUE) && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.FROM.VALUE)} color='white' size='large' title={`${t("Holon_Template.from")}: ${router.get(Constants.QueryParams.FROM.VALUE)}`} endIcon={<IconMinusAccordion />} />
            }
            {
                router.get(Constants.QueryParams.TO.VALUE) && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.TO.VALUE)} color='white' size='large' title={`${t("Holon_Template.to")}: ${router.get(Constants.QueryParams.TO.VALUE)}`} endIcon={<IconMinusAccordion />} />
            }
        </>
    }

    const handleOpenSearch = () => {
        let listFormItem = [
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'subject',
                label: t("alarm.subject"),
                subject: router.get(Constants.QueryParams.SUBJECT.VALUE) || ""
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'message',
                label: t("alarm.content"),
                message: router.get(Constants.QueryParams.MESSAGE.VALUE) || ""
            },
            {
                elementType: Constants.ELEMENT_TYPE.DATETIME.VALUE,
                key: 'from',
                label: t("Holon_Template.from"),
                from: router.get(Constants.QueryParams.FROM.VALUE) || null
            },
            {
                elementType: Constants.ELEMENT_TYPE.DATETIME.VALUE,
                key: 'to',
                label: t("Holon_Template.to"),
                to: router.get(Constants.QueryParams.TO.VALUE) || null
            }
        ]
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                formArray: false,
                callback: (_props) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.SUBJECT.VALUE]: _props?.subject,
                            [Constants.QueryParams.MESSAGE.VALUE]: _props?.message,
                            [Constants.QueryParams.FROM.VALUE]: _props?.from ? moment(_props?.from).format("YYYY-MM-DD") : null,
                            [Constants.QueryParams.TO.VALUE]: _props?.to ? moment(_props?.to).format("YYYY-MM-DD") : null,
                        }
                    })
                }
            }
        })
    }

    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e
            }
        })
    }

    const onPageChange = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e
            }
        })
    }

    useEffect(() => {
        if (router.get(Constants.QueryParams.TAB.VALUE)) {
            setValueTab(router.get(Constants.QueryParams.TAB.VALUE));
        }
    }, [router.get(Constants.QueryParams.TAB.VALUE)])


    useEffect(() => {
        if (router.get(Constants.QueryParams.TAB.VALUE)) {
            dispatch({
                type: actionAlarm.GET_LIST_ALARM,
                payload: {
                    ...router.getAll()
                }
            })
        }
    }, [
        router.get(Constants.QueryParams.SUBJECT.VALUE),
        router.get(Constants.QueryParams.TAB.VALUE),
        router.get(Constants.QueryParams.FROM.VALUE),
        router.get(Constants.QueryParams.TO.VALUE),
        router.get(Constants.QueryParams.MESSAGE.VALUE),
        router.get(Constants.QueryParams.KEYWORD.VALUE),
    ])

    useEffect(() => {
        dispatch({
            type: actionAlarm.GET_ALARM_MASTER
        })
    }, [])

    useEffect(() => {
        if (alarmMaster?.alarmTypes) {
            let masterType = Object.keys(alarmMaster?.alarmTypes).map(key => {
                return {
                    key: key,
                    id: key,
                    label: alarmMaster?.alarmTypes[key],
                }
            });
            masterType = masterType?.sort((a,b) => a.key.localeCompare(b.key));
            setDataTab(masterType);
            router.replace({
                params: {
                    ...router.getAll(),
                    [Constants.QueryParams.TAB.VALUE]: masterType[0]?.id
                }
            })
        }
    }, [alarmMaster?.alarmTypes])

    useEffect(() => {
        if (router.get(Constants.QueryParams.KEYWORD.VALUE)) {
            methods.setValue("keyword", router.get(Constants.QueryParams.KEYWORD.VALUE))
        } else {
            methods.setValue("keyword", "")
        }
    }, [
        router.get(Constants.QueryParams.KEYWORD.VALUE)
    ])

    return (
        <ContentLayout padding={0}>
            <div className={classes['AlarmContainer']}>
                <CustomTab data={dataTab} value={valueTab} onChange={(e) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.TAB.VALUE]: e
                        }
                    })
                }} />
                <div className={classes['alarm-container-content']}>
                    <div className={classes['custom-search']}>
                        <CustomFormProvider {...methods}>
                            <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                                <FormSearch className={classes['custom-form-search']}>
                                    <FormGroup>
                                        <FormItem>
                                            <FormInput iconSearch={<IconSearch fontSize={16} />} isSearch fieldName='keyword' placeholder={t("Holon_Template.search_keyword")} />
                                        </FormItem>
                                        {
                                            renderDataFilter(router.get(Constants.QueryParams.ATTRIBUTES.VALUE))
                                        }
                                        <ButtonFilter onClick={handleOpenSearch} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                    </FormGroup>
                                </FormSearch>
                                <button hidden type='submit' />
                            </form>
                        </CustomFormProvider>
                    </div>
                    <div className={classes['custom-body']}>
                        <TableAlarm loadingGetAlarm={loadingGetAlarm} listAlarm={listAlarm} />
                        <div className={classes['custom-pagination']}>
                            <CustomPagination
                                isRowPerpage
                                onChangeRowPerpage={onChangeRowPerpage} onChange={onPageChange} total={listAlarm?.total} limit={router.get(Constants.QueryParams.LIMIT.VALUE)} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
                        </div>
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default AlarmContainer