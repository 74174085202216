import React, { useEffect, useState } from 'react'
import classes from './ManagementSearchPopup.module.scss';
import CustomHeader from '../custom-header/CustomHeader';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import Button from 'shared/components/form-input/button/Button';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import FormSelect from 'shared/components/custom-form/FormSelect';
import { useSelector } from 'react-redux';
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';

function ManagementHolonTemplateSearchPopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const methods = useForm();
    const [category, setCategory] = useState([])
    const { categories } = useSelector(state => state?.holonTemplate);
    const onSubmit = (data) => {
        showVisible();
        payload?.callback((data));
        // let params = { ...router.getAll() };
        // if (data?.created_date?.end) {
        //     params[Constants.QueryParams.CREATED_DATE_FROM.VALUE] = ;
        //     params[Constants.QueryParams.CREATED_DATE_TO.VALUE] = moment(data?.created_date?.end).format("YYYY-MM-DD");
        // }
        // router.replace({
        //     params: {
        //         ...params,
        //         [Constants.QueryParams.SEARCH.VALUE]: params[Constants.QueryParams.SEARCH.VALUE],
        //         [Constants.QueryParams.OFFSET.VALUE]: 1,
        //         [Constants.QueryParams.CATEGORY.VALUE]: data?.category,
        //         [Constants.QueryParams.STATUS.VALUE]: data?.status,
        //     }
        // })
    }
    useEffect(() => {
        if (categories) {
            // let data = [];
            // categories?.map(x => {
            //     if (x?.code) {
            //         data.push({
            //             value: x?.code,
            //             key: x?.code,
            //             label: x?.code,
            //         })
            //     }
            // })
            setCategory(Object.keys(categories)?.map(key => {
                return {
                    value: key,
                    key: key,
                    label: categories[key]
                }
            }));
        }
    }, [categories])


    useEffect(() => {
        if (payload?.value?.[Constants.QueryParams.CREATED_DATE_FROM.VALUE]) {
            methods.setValue("created_date_from", new Date(payload?.value[Constants.QueryParams.CREATED_DATE_FROM.VALUE]))
        } else {
            methods.setValue("created_date_from", null)
        }

        if (payload?.value?.[Constants.QueryParams.CREATED_DATE_TO.VALUE]) {
            methods.setValue("created_date_to", new Date(payload?.value[Constants.QueryParams.CREATED_DATE_TO.VALUE]))
        } else {
            methods.setValue("created_date_to", null)
        }

        if (payload?.value?.[Constants.QueryParams.STATUS.VALUE]) {
            methods.setValue("status", payload?.value[Constants.QueryParams.STATUS.VALUE])
        } else {
            methods.setValue("status", null)
        }

        if (payload?.value?.[Constants.QueryParams.CATEGORY.VALUE]) {
            methods.setValue("category", payload?.value[Constants.QueryParams.CATEGORY.VALUE])
        } else {
            methods.setValue("category", null)
        }
    }, [
        payload?.value
    ]);

    const OptionRelease = [
        {
            value: "1",
            key: "1",
            label: t("Holon_Template.valid")
        },
        {
            value: "0",
            key: "0",
            label: t("Holon_Template.invalid")
        },
    ]

    const checkField = (data, field) => {
        return data?.findIndex(x => x == field) == -1 ? false : true
    }

    return (
        <BoxContentPopup borderRadius='20px 0px 0px 20px' width='700px'>
            <CustomFormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        showVisible={showVisible}
                        title={t("Holon_Template.add_search_conditions")}
                        actionSectionBottom={<Button type='submit' title={t("Holon_Template.ok")} />}
                    >
                        <div className={classes['ManagementSearchPopup']}>
                            {
                                checkField(payload?.listField, "category") && <div className={classes['wrap-content']}>
                                    <div className={classes['wrap-content-left']}>
                                        <p>{t("Holon_Template.category")}</p>
                                    </div>
                                    <FormSelect options={category} placeholder={t("Holon_Template.category")} fieldName={"category"} />
                                </div>
                            }
                            {
                                checkField(payload?.listField, "status") && <div className={classes['wrap-content']}>
                                    <div className={classes['wrap-content-left']}>
                                        <p>{t("Holon_Template.status")}</p>
                                    </div>
                                    <FormSelect options={OptionRelease} placeholder={t("Holon_Template.status")} fieldName={"status"} />
                                </div>
                            }
                            {
                                checkField(payload?.listField, "created_date_from") && <div className={classes['wrap-content']}>
                                    <div className={classes['wrap-content-left']}>
                                        <p>{t("Holon_Template.created")}</p>
                                    </div>
                                    <div className={classes['wrap-content-right']}>
                                        <FormDatePicker withPortal placeholder={t("Holon_Template.created")} fieldName={"created_date_from"} />
                                        <FormDatePicker withPortal placeholder={t("Holon_Template.created")} fieldName={"created_date_to"} />
                                    </div>
                                </div>
                            }
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default ManagementHolonTemplateSearchPopup