import React from 'react';
import styles from "./CommonQrReader.module.scss";
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import Button from 'shared/components/form-input/button/Button';
import IconClose from 'assets/icon/IconClose';
function CommonQrReader({ callback, handleOpenQrCode }) {
    function handleScan(data) {
        handleOpenQrCode(false)
        callback && callback(data)
    }

    return (
        <div className={styles.container}>
            <div className={styles['button-close']}>
                <Button size='small' onClick={() => handleOpenQrCode(false)} color='white' startIcon={<IconClose fontSize={20} color='#333333' />} />
            </div>
            {
                <BarcodeScannerComponent
                    width={'100%'}
                    height={'100%'}
                    onUpdate={(err, result) => {
                        if (result) {
                            handleScan(result.text)
                        }

                        if (err) {
                        }
                    }}
                    facingMode="environment"
                />
            }
        </div>
    )
}

export default CommonQrReader