import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionHexService from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";

function* getHexService() {
    yield takeEvery(actionHexService.GET_HEX_SERVICE, function* (payload) {
        try {
            const response = yield call(() => factory.getHexService(payload?.payload));
            yield put({
                type: actionHexService.GET_HEX_SERVICE_SUCCESS,
                payload: response?.data
            })
        } catch (error) {
            yield put({
                type: actionHexService.GET_HEX_SERVICE_FAILED,
            })
        }
    })
}

function* updateHexService() {
    yield takeEvery(actionHexService.UPDATE_HEX_SERVICE, function* (payload) {
        try {
            const response = yield call(() => factory.updateHexService(payload?.payload));
            yield put({
                type: actionHexService.UPDATE_HEX_SERVICE_SUCCESS,
                payload: response
            })
            yield payload?.callback?.success && payload?.callback?.success();
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
            yield put({
                type: actionHexService.UPDATE_HEX_SERVICE_FAILED,
            })
        }
    })
}

function* getListHexService() {
    yield takeEvery(actionHexService.GET_HEX_SERVICE_LIST, function* (payload) {
        const { callback, fallback } = payload;
        try {
            const response = yield call(() => factory.getListHexService());
            if (response?.data) {
                callback && callback()
                yield put({
                    type: actionHexService.GET_HEX_SERVICE_LIST_SUCCESS,
                    payload: response?.data
                })
            }
        } catch (error) {
            fallback && fallback()
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getHexService),
        fork(updateHexService),
        fork(getListHexService),
    ])
}