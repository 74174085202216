import axios from 'axios'
import { setupInterceptersTo } from './AxiosConfig'
import i18n from "i18nextInit"
import Constants from 'utils/Constants'


const BASE_URL = process.env.REACT_APP_HOLON_CORE_URL

const api = setupInterceptersTo(
    axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': undefined,
        },
    }),
)

export const fetchAll = (path, params) => {
    return api
        .get(`${BASE_URL}/${path}`, { params })
        .then((resp) => resp.data)
        .catch((error) => console.log(error))
}

export const fetchSingle = (path, id) => {
    return api
        .get(`${BASE_URL}/${path}/${id}`)
        .then((resp) => resp.data)
        .catch((error) => console.log(error))
}

export const post = (path, model) => {
    return api
        .post(`${BASE_URL}/${path}`, model)
        .then((resp) => resp.data)
        .catch((error) => console.log(error))
}

export const patch = (path, model) => {
    return api
        .patch(`${BASE_URL}/${path}`, model)
        .then((resp) => resp.data)
        .catch((error) => console.log(error))
}

export const remove = (path, id) => {
    return api
        .delete(`${BASE_URL}/${path}/${id}`)
        .then((resp) => resp.data)
        .catch((error) => console.log(error))
}
export const request = ({ url = '', method = 'GET', data = {}, params = {}, responseType, responseAll = false }) => {
    return api
        .request({
            url: url,
            method: method,
            data: data,
            params: params,
            responseType: responseType
        })
        .then((resp) => {
            if (responseAll) {
                return resp;
            }
            return resp.data;
        })
        .catch((error) => {
            if (error?.response?.status == Constants.STATUS_REQUEST.REQUEST_TIME_OUT.VALUE) {
                let error = {
                    error : {
                        detail : {
                            exception: [i18n.t("E0089")]
                        }
                    },
                    ...error?.response
                };
                
                throw error
            }
            return error?.response?.data;
        })
}