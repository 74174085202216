import $ from 'jquery';
import Actions from 'redux/app/action';
var dictionary = {
    '[LABEL_ACCOUNT]': "口座",
    '[LABEL_ACTION]': "アクション",
    '[LABEL_ACTION_NAME]': "動作名",
    '[LABEL_ALERT]': "警告",
    '[LABEL_BACK]': "戻る",
    '[LABEL_CATEGORY]': "カテゴリ",
    '[LABEL_CHINA]': "人民",
    '[LABEL_CREATE]': "作成",
    '[LABEL_DEFAULT]': "デフォルト値",
    '[LABEL_DESCRIPTION]': "説明",
    '[LABEL_DOLLAR]': "ドル",
    '[LABEL_EDITABLE]': "編集可",
    '[LABEL_EDIT]': "編集",
    '[LABEL_INPUT]': "入力",
    '[LABEL_JAPAN]': "日本",
    '[LABEL_KEY]': "キー",
    '[LABEL_LIBRARY]': "ライブラリー",
    '[LABEL_MANAGEMENT]': "管理",
    '[LABEL_METADATA]': "メタデータ",
    '[LABEL_MULTIPLE]': "複数可",
    '[LABEL_NAME]': "名前",
    '[LABEL_NEXT]': "次へ",
    '[LABEL_NOTIFICATION]': "通知",
    '[LABEL_ORDER]': "オーダー",
    '[LABEL_OUTPUT]': "出力",
    '[LABEL_PERSON]': "人",
    '[LABEL_PREVIOUS]': "前へ",
    '[LABEL_READ]': "読取り",
    '[LABEL_REGEX_VALIDATION]': "値の正規表現",
    '[LABEL_REQUIRED]': "必須",
    '[LABEL_SAVE]': "保存",
    '[LABEL_SEARCH]': "フリーワードで検索",
    '[LABEL_SEARCH_LABEL]': "検索条件の追加",
    '[LABEL_STATUS]': "ステータス",
    '[LABEL_TEMPLATE]': "HOLONテンプレート",
    '[LABEL_TEMPLATE_NAME]': "テンプレート名称",
    '[LABEL_TIMEOUT]': "タイムアウト",
    '[LABEL_TOTAL]': "合計",
    '[LABEL_UNIT]': "単位",
    '[LABEL_USA]': "US",
    '[LABEL_VIEW]': "詳細",
    '[LABEL_WRITE]': "書込み",
    '[LABEL_YEN]': "円",
    '[LABEL_YUAN]':"元"
};
//var  REACT_APP_HOLON_CORE_URL= 'http://ec2-18-180-84-137.ap-northeast-1.compute.amazonaws.com:8081'
var  REACT_APP_HOLON_CORE_URL=process.env.REACT_APP_HOLON_CORE_URL;
var  REACT_APP_HOLON_MESSAGE_URL=process.env.REACT_APP_HOLON_MESSAGE_URL;
var coreAPIAddress = REACT_APP_HOLON_CORE_URL+'/rest/api/v1'
var coreAPIMessenger = REACT_APP_HOLON_MESSAGE_URL


$.get(coreAPIAddress+'/language/jp', function(data){
    dictionary = data;
})

export function setDictionary(data){
    dictionary = data;
}

export function getDictionary(lang){
    $.get(coreAPIAddress+'/language/'+lang, function(data){
        dictionary = data;
    })
}
    
export function getWord(word){
    if(dictionary[word] === undefined)
    {return word;}
    return dictionary[word];
}

export function getHolonAttributes(holon,attrib){
    let attribs = [];
    holon.attributes.map((attribute)=>attribs[attribute.name]=attribute.value)
    var value=attribs[attrib];
    return value;
}

export function getHolonAttribute(holon){
    let attribs = [];
    holon.attributes.map((attribute)=>attribs[attribute.name]=attribute.value)
    return attribs;
}

export function getLabel(label){
    var n = 0;
    if(label.indexOf('[LABEL_') < 0)
    {return label;}
    while(label.indexOf('[LABEL_') >= 0 && n < 10){
        var search = label.substring(label.indexOf('[LABEL_'),label.indexOf(']')+1)
        label = label.replace(search,getWord(search))
        n++;
    }
    return label;
}

export function getCurrencyFormat(number, currency){
    var formattedCurrency = "";
    const money = Math.max(number, 0)
    switch(currency){
        case '円':formattedCurrency = new Intl.NumberFormat("jp-JP", {
            style: "currency",
            currency: "JPY"
        }).format(money);break;
        case 'USD':formattedCurrency =  new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
        }).format(money);break;
        case '元':formattedCurrency = new Intl.NumberFormat("zh-CN", {
            style: "currency",
            currency: "CNY"
        }).format(money);break;
        default:formattedCurrency = new Intl.NumberFormat("jp-JP", {
            style: "currency",
            currency: "JPY"
        }).format(money);break;
    }
    return formattedCurrency;
}

export function sendHolon(targetForm,methodVal,endpoint,holonType,holonDesc,actions,callback){
    $('resultModalLabel').html()
    $('#result-message').html('')
    $('#result-ok').addClass('d-none')
    $('#result-nope').addClass('d-none')
    $('#result-loading').removeClass('d-none')
    var jsonObj = [];
    var jsonAction = [];
    var jsonActions = [];
    var jsonAttribs = [];
    var jsonAttrib = [];
    var jsonHolon = [];
    
    actions.map((actionName)=> {
        jsonAction=[];
        jsonAction['name']=actionName;
        jsonActions.push(Object.assign({}, jsonAction));
    })
    let fields=$('#'+targetForm).find('.form-control')
    let fieldsChecks=$('#'+targetForm).find('.form-check-input')
    fields.map((field)=> {
        jsonObj[fields[field].id]=fields[field].value;
        jsonAttrib = [];
        jsonAttrib['name']=fields[field].id;
        jsonAttrib['value']=fields[field].value;
        jsonAttribs.push(Object.assign({}, jsonAttrib));
    })
    fieldsChecks.map((field)=>{
        jsonAttrib = [];
        if(fieldsChecks[field].type === "radio" ){
            if(fieldsChecks[field].checked === true){
                jsonAttrib["name"]=fieldsChecks[field].id;
                jsonAttrib["value"]=fieldsChecks[field].value;
                jsonAttribs.push(Object.assign({}, jsonAttrib));
            }
        }else{
            jsonObj[fieldsChecks[field].id]=(fieldsChecks[field].checked === true) ? true : false;
            jsonAttrib["name"]=fieldsChecks[field].id;
            jsonAttrib["value"]=(fieldsChecks[field].checked === true) ? true : false;
            jsonAttribs.push(Object.assign({}, jsonAttrib));
        }
        
    })
    jsonHolon['name']=jsonObj['name'];
    jsonHolon['type']=holonType;
    jsonHolon['description']=holonDesc;
    jsonHolon['attributes']=Object.assign([], jsonAttribs);
    jsonHolon['actions']=Object.assign([], jsonActions);
    
    $.ajax({
        url: getCoreAPI()+endpoint,
        method: methodVal,
        data: JSON.stringify(Object.assign({}, jsonHolon)),
        dataType : 'json',
        contentType: 'application/json',
        success:function(data){
            $('#result-loading').addClass('d-none')
            $('#result-ok').removeClass('d-none')
            $('#result-message').html('Request has been successfully applied.')
            //setTimeout(window.location.reload(),3000)
            callback()
        },
        error:function(error){
            $('#result-loading').addClass('d-none')
            $('#result-nope').removeClass('d-none')
            $('#result-message').html('Something went wrong, the request was not applied.')
            //setTimeout(window.location.reload(),3000)
        }
    });
}

export function sendAction(targetForm,methodVal,endpoint){
    $('resultModalLabel').html()
    $('#result-message').html('')
    $('#result-ok').addClass('d-none')
    $('#result-nope').addClass('d-none')
    $('#result-loading').removeClass('d-none')
    var jsonObj = [];
    let fields=$('#'+targetForm).find('.form-control')
    let fieldsChecks=$('#'+targetForm).find('.form-check-input')
    
    $.ajax({
        url: getCoreAPI()+endpoint,
        method: methodVal,
        data: JSON.stringify(Object.assign({}, jsonObj)),
        dataType : 'json',
        contentType: 'application/json',
        success:function(data){
            $('#result-loading').addClass('d-none')
            $('#result-ok').removeClass('d-none')
            $('#result-message').html('Request has been successfully applied.')
            //setTimeout(window.location.reload(),3000)
        },
        error:function(error){
            $('#result-loading').addClass('d-none')
            $('#result-nope').removeClass('d-none')
            $('#result-message').html('Something went wrong, the request was not applied.')
            //setTimeout(window.location.reload(),3000)
        }
    });
}

export function createConnection(name,holonA,holonB,connectionType,callback){
    var endpoint='/connect/holons';
    var methodVal='POST';
    $('resultModalLabel').html()
    $('#result-message').html('')
    $('#result-ok').addClass('d-none')
    $('#result-nope').addClass('d-none')
    $('#result-loading').removeClass('d-none')

    var jsonHolons = [];
    var jsonConnect = [];
    jsonHolons.push(Object.assign({}, holonA))
    jsonHolons.push(Object.assign({}, holonB))    
    jsonConnect['name']=name;
    jsonConnect['type']=connectionType;
    jsonConnect['fromName']=holonA.name;
    jsonConnect['toName']=holonB.name;
    jsonConnect['holons']=Object.assign([], jsonHolons);
    
    $.ajax({
        url: getCoreAPI()+endpoint,
        method: methodVal,
        data: JSON.stringify(Object.assign({}, jsonConnect)),
        dataType : 'json',
        contentType: 'application/json',
        success:function(data){
            $('#result-loading').addClass('d-none')
            $('#result-ok').removeClass('d-none')
            $('#result-message').html('Request has been successfully applied.')
            callback();
            //setTimeout(window.location.reload(),3000)
        },
        error:function(error){
            $('#result-loading').addClass('d-none')
            $('#result-nope').removeClass('d-none')
            $('#result-message').html('Something went wrong, the request was not applied.')
            //setTimeout(window.location.reload(),3000)
        }
    });
}

export function getResourceByName(name, resource,resourceType){
    $('.dynamic-data').html("Loading... Data")
    $('.dynamic-data').addClass("placeholder")
    $('.dynamic-data').parent().addClass("placeholder-glow")
    $.get(getCoreAPI()+resource+name,
        function(data){
            switch(resourceType){
                case "order":break;
                case "orders":break;
                case "persons":break;
                case "users":break;
                case "notifications":
                    $('#notification-status').html(getLabel(data.attributes[1].value))
                    $('#notification-id').html(data.attributes[0].value)
                    $('#notification-desc').html(data.description)
                    break;
                default:break;
            }
            $('.dynamic-data').removeClass("placeholder")
            $('.dynamic-data').parent().removeClass("placeholder-glow")
        })

}

export function openCreateForm(id){
    $(id).find("#access-mode").html(getLabel('[LABEL_CREATE]'))
}

export function createLabel(id,name){
    var button = document.createElement('button');
    $(button).attr('type','button')
    $(button).attr('class','btn btn-outline-dark btn-sm')
    $(button).html(name)
    $(button).attr('data-id',id);
    $(button).attr('data-name',name);
    return button;
}

export function formatDate(dateStr,detail){
    var dateFormat = dateStr.substring(0,dateStr.indexOf('.'));
    switch(detail){
        case 'time':dateFormat = dateFormat.substring(dateFormat.indexOf('T')+1);break;
        case 'date':dateFormat = dateFormat.substring(0,dateFormat.indexOf('T')-1);break;
        default:dateFormat = dateFormat.replace('T',' ');break;
    }
    return dateFormat;
}

export function truncateStr(str,size){
    if(str.length < size)
    {return str;}
    return str.slice(0,size)+'...';
}

export function getCoreAPI(){
    return coreAPIAddress;
}

export function getCoreAPIMessenger(){
    return coreAPIMessenger;
}

export function formatJpDate(date) {
    if (typeof date == "string") {
        date = new Date(date);
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}年${month}月${day}日`;
}

export const getFunction = async (triggerType, defaultValue, dispatch, setValue, getValues, splitKey = ".") => {
    try {
        const json =  JSON.parse(defaultValue);
        if (json["trigger"] != triggerType) {
            return false;
        }
        let method = json["method"];
        if (window[method] && typeof window[method] === 'function') {
            let parameter = {};
            json["parameter"]?.map(param => {
                Object?.keys(param)?.forEach(key => {
                    parameter[key] = param[key];
                    if (param[key]?.startsWith("$")) {
                        let fieldKey = param[key]?.split(splitKey)[1];
                        parameter[key] = getValues(fieldKey);
                    }
                })
            });
            const response = await window[method](parameter);
            if (response) {
                if (json["return"]?.startsWith("$")) {
                    let fieldKey = json["return"]?.split(splitKey)[1];
                    var element = $(`[name="${fieldKey}"]`);
                    if (element.prop("nodeName") == "SELECT" 
                            || (element.prop("nodeName") == "INPUT" && (element.prop("type") == "checkbox" || element.prop("type") == "radio"))) {
                        dispatch({
                            type: Actions.SET_FORM_FIELD_MASTER_DATA,
                            field: fieldKey,
                            payload: response
                        })
                        // setValue(fieldKey, null);
                        // if (json["reset"] && json["reset"]?.length > 0) {
                        //     json["reset"]?.forEach((field) => {
                        //         setValue(field?.split(splitKey)[1], null);
                        //     })
                        // }
                    } else {
                        setValue(fieldKey, response);
                    }
                }
            }
        }
    } catch (error) {
    }
}

export const convertObjectToArray = (obj) => {
    return Object.keys(obj).map(key => {
        return {
            label: obj[key]?.NAME,
            value: obj[key]?.VALUE,
            key: obj[key]?.VALUE
        }
    })
}