import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import useTrans from 'hooks/use-trans';
import actionBlInstance from 'redux/bl-instance/action';
import Validator from 'utils/Validator';
import Button from 'shared/components/form-input/button/Button';
import classes from './AddBlAccountPopup.module.scss';
import Constants from 'utils/Constants';
import WrapContentBody from '../custom-body/WrapContentBody';

function AddBlAccountSuccessPopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const { blAccount } = useSelector(state => state.blInstance);

    const hexId = blAccount?.attributes?.find(attribute => attribute?.name == 'hex_id')
    const email = blAccount?.attributes?.find(attribute => attribute?.name == 'email')

    return (
        <div className={classes['wrap-success-container']}>
            <div className={classes['notify-header']}>
                {t("successful_registration")}
            </div>
            <div className={classes['notify']}>
                <div className={classes['notify-content']}>
                    <p>{t("successful_registration_notify")}</p>
                    <div className='d-flex'>
                        <div className='col-4 label fw-semibold'>HEX ID</div>
                        <div className='col-6'>{hexId?.value ?? ''}</div>
                    </div>
                    <div className='d-flex'>
                        <div className='col-4 label fw-semibold'>{t('email')}</div>
                        <div className='col-6'>{email?.value ?? ''}</div>
                    </div>
                    <div className='d-flex'>
                        <div className='col-4 label fw-semibold'>{t('temp_password')}</div>
                        <div className='col-6'>{t('write_by_email')}</div>
                    </div>
                </div>
                <div className={classes['form-group-row']}>
                    <div className={classes['form-group-button']}>
                        <Button
                            size="large"
                            type="submit"
                            title={t("Holon_Template.close")}
                            onClick={showVisible}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBlAccountSuccessPopup;