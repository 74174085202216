import React from 'react';
import classes from './ContentMessage.module.scss';

import IconXlsx from 'assets/icon/IconXlsx';
import IconDoc from 'assets/icon/IconDoc';
import IconDocx from 'assets/icon/IconDocx';
import IconZip from 'assets/icon/IconZip';
import IconXls from 'assets/icon/IconXls';
import IconPtt from 'assets/icon/IconPtt';
import IconPttX from 'assets/icon/IconPttx';
import IconRar from 'assets/icon/IconRar';
import IconTar from 'assets/icon/IconTar';
import IconGzip from 'assets/icon/IconGzip';
import IconPdf from 'assets/icon/IconPdf';
import IconTxt from 'assets/icon/IconTxt';
import useTrans from 'hooks/use-trans';
import IconCsv from 'assets/icon/IconCsv';
import ResizeImage from './ResizeImage';

const AttachmentLinkReply = ({ type, x, repToAttachmentFileName }) => {
    const { t } = useTrans();
    var extension = repToAttachmentFileName?.split(".")[1];
    function condenseFilename(initial) {
        var split = initial?.split('.');
        if (split?.length > 0) {
            let filename = split?.[0];
            if (filename.length > 35) {
                return filename.substring(0, 30) + '...' + extension;
            }
        }
        return initial;
    }

    function nameFile(_fileName) {
        return <div title={_fileName} className={`${classes['Custom_Name_File']} ${!x?.isMe ? classes['Custom_Name_File_Me'] : ''}`}>
            {condenseFilename(_fileName)}
        </div>
    }

    const fileMessage = (_fileName, size) => {
        return <div className={classes['File_Info']}>
            {nameFile(_fileName)}
            <span className={`${classes['File_Size']} ${x?.isMe ? classes['File_Size_Me'] : ''}`}>
                {`${t("message.size")}: ${size}KB`}
            </span>
        </div>
    }

    const convertSize = (type) => {
        let fileName = type?.split('/')[type?.split('/')?.length - 1];
        var path = fileName?.split(")")?.[fileName?.split(")")?.length - 2];
        path = path?.split("(")?.[path?.split("(")?.length - 1];

        if (path) {
            return path?.split("KB")[0];
        }

        return 0;
    }

    switch (extension?.toUpperCase()) {
        case "ZIP":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconZip />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "CSV":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconCsv />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "DOCX":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconDocx />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "DOC":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconDoc />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "XLSX":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconXlsx />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "XLS":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconXls />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "PPT":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconPtt />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "PPTX":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconPttX />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "RAR":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconRar />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "TAR":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconTar />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "GZIP":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconGzip />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "TXT":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconTxt />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
        case "JPEG":
        case "JPG":
        case "PNG":
        case "GIF":
        case "BMP":
        case "TIFF":
        case "SVG":
        case "WEBP":
            return <div className={`${classes['Image_Link_Reply']}`}>
                <ResizeImage url={type} />
            </div>
        case "PDF":
            return <div className={`${classes['File_Link']} ${classes['File_Link_Reply']}`}>
                <div className={classes['Icon_File']}>
                    <IconPdf />
                </div>
                {fileMessage(repToAttachmentFileName, convertSize(type))}
            </div>
    }
}

export default AttachmentLinkReply;