const InquiryAction = {
    GET_INQUIRY_LIST: "GET_INQUIRY_LIST",
    GET_INQUIRY_LIST_LOADING: "GET_INQUIRY_LIST_LOADING",
    GET_INQUIRY_LIST_FAILED: "GET_INQUIRY_LIST_FAILED",
    GET_INQUIRY_LIST_SUCCESS: "GET_INQUIRY_LIST_SUCCESS",

    GET_INQUIRY_DETAIL: "GET_INQUIRY_DETAIL",
    GET_INQUIRY_DETAIL_LOADING: "GET_INQUIRY_DETAIL_LOADING",
    GET_INQUIRY_DETAIL_FAILED: "GET_INQUIRY_DETAIL_FAILED",
    GET_INQUIRY_DETAIL_SUCCESS: "GET_INQUIRY_DETAIL_SUCCESS",

    UPDATE_INQUIRY: "UPDATE_INQUIRY",
    UPDATE_INQUIRY_FAILED: "UPDATE_INQUIRY_FAILED",
    UPDATE_INQUIRY_SUCCESS: "UPDATE_INQUIRY_SUCCESS",
    
    INSERT_INQUIRY: "INSERT_INQUIRY",
    INSERT_INQUIRY_FAILED: "INSERT_INQUIRY_FAILED",
    INSERT_INQUIRY_SUCCESS: "INSERT_INQUIRY_SUCCESS",

    DELETE_INQUIRY: "DELETE_INQUIRY",
    DELETE_INQUIRY_FAILED: "DELETE_INQUIRY_FAILED",
    DELETE_INQUIRY_SUCCESS: "DELETE_INQUIRY_SUCCESS",

    CREATE_USER: "CREATE_USER",
    CREATE_USER_FAILED: "CREATE_USER_FAILED",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",


    UPSERT_INQUIRY_LOADING: "UPSERT_INQUIRY_LOADING",
};

export default InquiryAction;
