import React, { useEffect, Fragment } from "react";

import { useForm } from "react-hook-form";
import useRouter from "hooks/use-router";
import useTrans from "hooks/use-trans";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import Constants from "utils/Constants";
import { formatDate } from "utils/DateTime";
import InquiryAction from "redux/inquiry/action";
import Utils from "utils/Utils";

import Box from "shared/components/form-input/box/Box";
import classes from "./InquiryListContainer.module.scss";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import FormSearch from "shared/components/common/form-search/FormSearch";
import FormGroup from "shared/components/common/form-search/FormGroup";
import FormItem from "shared/components/common/form-search/FormItem";
import FormInput from "shared/components/custom-form/FormInput";
import IconPlusAccordion from "assets/icon/IconPlusAccordion";
import IconMinusAccordion from "assets/icon/IconMinusAccordion";
import Button from "shared/components/form-input/button/Button";
import IconPlusWhite from "assets/icon/IconPlusWhite";
import IconSearch from "assets/icon/IconSearch";
import CustomTable from "shared/components/common/custom-table/CustomTable";
import CustomPagination from "shared/components/common/custom-pagination/CustomPagination";
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_INQUIRY_CONDITION_SEARCH_POPUP,
    EVENT_SHOW_INQUIRY_DETAIL_POPUP,
    EVENT_SHOW_CREATE_INQUIRY_POPUP,
} from "utils/EventRegister";
import Permission from "utils/Permission";

const InquiryListContainer = () => {
    const { t } = useTrans();
    const methods = useForm();
    const dispatch = useDispatch();
    const router = useRouter();

    const {
        inquiryList,
        loading: { loadingGetInquiryList },
    } = useSelector((state) => state.inquiry);

    const inquiryStatusList = Utils.convertObjectKeyToArr(
        Constants.INQUIRY_PROCESS_STATUS
    );

    useEffect(() => {
        dispatch({
            type: InquiryAction.GET_INQUIRY_LIST,
            payload: {
                ...router.getAll(),
            },
        });

        //
        const searchKeyword =
            router.get(Constants.QueryParams.SEARCH.VALUE) ?? "";
        methods.setValue("search", searchKeyword);
    }, [
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.LIMIT.VALUE),
        router.get(Constants.QueryParams.CREATED_DATE_FROM.VALUE),
        router.get(Constants.QueryParams.CREATED_DATE_TO.VALUE),
        router.get(Constants.QueryParams.STATUS.VALUE),
        router.get(Constants.QueryParams.SEARCH.VALUE),
    ]);

    const handleSubmit = (formData) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.SEARCH.VALUE]: formData,
            },
        });
    };

    const handleSearchChange = (formData) => {
        debouncedSearch(formData);
    };

    const debouncedSearch = _.debounce(handleSubmit, 500);

    const parseSearchConditionFromPopupToURL = (searchConditions) => {
        let queryParams = { ...router.getAll() };
        if (searchConditions?.created_date_from) {
            queryParams[Constants.QueryParams.CREATED_DATE_FROM.VALUE] =
                formatDate(searchConditions.created_date_from, "YYYY-MM-DD");
        }
        if (searchConditions?.created_date_to) {
            queryParams[Constants.QueryParams.CREATED_DATE_TO.VALUE] =
                formatDate(searchConditions.created_date_to, "YYYY-MM-DD");
        }
        if (searchConditions?.status) {
            queryParams[Constants.QueryParams.STATUS.VALUE] =
                searchConditions.status;
        }
        router.replace({ params: queryParams });
    };

    const handleOpenAddSearchCriteria = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_INQUIRY_CONDITION_SEARCH_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                value: {
                    ...router.getAll(),
                },
                callback: (params) => {
                    parseSearchConditionFromPopupToURL(params);
                },
            },
        });
    };

    const openCreateInquiryPopup = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_CREATE_INQUIRY_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                callback: () => { },
            },
        });
    };

    const showDetail = (id) => {
        dispatch({
            type: InquiryAction.GET_INQUIRY_DETAIL,
            payload: id,
        });
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_INQUIRY_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                callback: () => { },
            },
        });
    };

    const dataHeadCell = [
        {
            label: t("inquiry.inquiry_code"),
            field: "inquiryCode",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (props) => {
                return (
                    <p className={classes["Id_showDetail_inquiry"]} onClick={() => showDetail(props?.data?.id)}>
                        {props?.data?.inquiryCode}
                    </p>
                );
            },
        },
        {
            label: t("inquiry.inquiry_date"),
            field: "inquiryDate",
            minWidth: "200px",
            width: "200px",
            maxWidth: "200px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (props) => {
                return (
                    <p className={classes["column-detail-inquiry-p"]}>
                        {formatDate(props?.data?.inquiryDate, "DD/MM/YYYY")}
                    </p>
                );
            },
        },
        {
            label: t("inquiry.created_user"),
            field: "createdBy",
            minWidth: "200px",
            width: "200px",
            maxWidth: "200px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
        },
        {
            label: t("inquiry.companyName"),
            field: "companyName",
            minWidth: "200px",
            width: "200px",
            maxWidth: "200px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
        },
        {
            label: t("inquiry.phone"),
            field: "phone",
            minWidth: "200px",
            width: "200px",
            maxWidth: "200px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
        },
        {
            label: t("inquiry.sale"),
            field: "picSale",
            minWidth: "200px",
            width: "200px",
            maxWidth: "200px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
        },

        {
            label: t("inquiry.inquiry_status"),
            field: "inquiryStatus",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (props) =>
                _.find(inquiryStatusList, { value: props?.data?.inquiryStatus })
                    ?.label,
        },
    ];

    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            },
        });
    };

    const onPageChange = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e,
            },
        });
    };

    const renderSearchCondition = () => {
        const createdFromDate = router.get(
            Constants.QueryParams.CREATED_DATE_FROM.VALUE
        );
        const createdToDate = router.get(
            Constants.QueryParams.CREATED_DATE_TO.VALUE
        );

        const inquiryValue = router.get(Constants.QueryParams.STATUS.VALUE);
        const inquiryStatusObj = _.find(inquiryStatusList, {
            value: inquiryValue,
        });

        const removeParamsFromRouter = (paramsName) => {
            const currentRouterParams = { ...router.getAll() };
            switch (paramsName) {
                case "created_date":
                    delete currentRouterParams[
                        Constants.QueryParams.CREATED_DATE_FROM.VALUE
                    ];
                    delete currentRouterParams[
                        Constants.QueryParams.CREATED_DATE_TO.VALUE
                    ];
                    break;
                case "status":
                    delete currentRouterParams[
                        Constants.QueryParams.STATUS.VALUE
                    ];
                    break;
                default:
                    break;
            }
            router.replace({
                params: {
                    ...currentRouterParams,
                },
            });
        };

        return (
            <Fragment>
                {(createdFromDate || createdToDate) && (
                    <FormItem>
                        <Button
                            onClick={() =>
                                removeParamsFromRouter("created_date")
                            }
                            color="white"
                            size="large"
                            title={`${t(
                                "Holon_Template.created"
                            )}：${formatDate(createdFromDate, "DD/MM/YYYY")} 〜 ${formatDate(createdToDate, "DD/MM/YYYY")} `}
                            endIcon={<IconMinusAccordion />}
                        />
                    </FormItem>
                )}
                {inquiryStatusObj && (
                    <FormItem>
                        <Button
                            onClick={() => removeParamsFromRouter("status")}
                            color="white"
                            size="large"
                            title={`${t("Holon_Template.status")}：${inquiryStatusObj.label}`}
                            endIcon={<IconMinusAccordion />}
                        />
                    </FormItem>
                )}
            </Fragment>
        );
    };

    return (
        <Box className={classes["InquiryList"]}>
            <div className={classes["HeaderSection"]}>
                {t("inquiry.title_inquiry")}
            </div>
            <div className={classes["ConditionSearchSection"]}>
                <CustomFormProvider {...methods}>
                    <form>
                        <FormSearch className={classes["CustomFormSearch"]}>
                            <FormGroup>
                                <FormItem>
                                    <FormInput
                                        fieldName="search"
                                        background="white"
                                        placeholder={t("search_by_word")}
                                        iconSearch={
                                            <IconSearch fontSize={14} />
                                        }
                                        isSearch
                                        onChangeValue={(e) => handleSearchChange(e)}
                                    />
                                </FormItem>
                                {renderSearchCondition()}
                                <Button
                                    onClick={handleOpenAddSearchCriteria}
                                    size="large"
                                    title={t(
                                        "Holon_Template.add_search_conditions"
                                    )}
                                    color="white"
                                    endIcon={<IconPlusAccordion />}
                                />
                            </FormGroup>
                            {/* {   Permission.IsEnabledFunction(Permission.FUNCTIONS.CREATE_INQUIRY) && */}
                            <Button
                                onClick={openCreateInquiryPopup}
                                size="large"
                                color="yellow"
                                endIcon={<IconPlusWhite />}
                            />
                            {/* } */}
                        </FormSearch>
                    </form>
                </CustomFormProvider>
            </div>
            <div className={classes["SearchResultSection"]}>
                <CustomTable
                    loading={loadingGetInquiryList}
                    data={inquiryList?.records ?? []}
                    headCell={dataHeadCell}
                />
                <div className={classes["PaginationSection"]}>
                    <CustomPagination
                        isRowPerpage
                        onChangeRowPerpage={onChangeRowPerpage}
                        onChange={onPageChange}
                        total={inquiryList?.total ?? 0}
                        limit={router.get(Constants.QueryParams.LIMIT.VALUE)}
                        page={router.get([Constants.QueryParams.OFFSET.VALUE])}
                    />
                </div>
            </div>
        </Box>
    );
};

export default InquiryListContainer;
