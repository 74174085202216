import React, { useState } from 'react';
import UserLoginPopup from 'shared/components/popup/login/UserLoginPopup'
import UserRegisterPopup from 'shared/components/popup/register/UserRegisterPopup';
import UserRegisterResultPopup from 'shared/components/popup/register/UserRegisterResultPopup';
import UserRegisterStep2Popup from 'shared/components/popup/register/UserRegisterStep2Popup';
import UserInquiryPopup from 'shared/components/popup/inquiry/UserInquiryPopup';
import UserInquiryConfirmationPopup from 'shared/components/popup/inquiry/UserInquiryConfirmationPopup';
import UserInquiryCompletionPopup from 'shared/components/popup/inquiry/UserInquiryCompletionPopup';
import PrivacyPolicyPopup from 'shared/components/popup/privacy/PrivacyPolicyPopup';
import CompanyDetailPopup from 'shared/components/common/popup/company/CompanyDetailPopup';
import CustomDialog from "./CustomDialog";

import {
    EVENT_SHOW_POPUP_USER_LOGIN,
    EVENT_SHOW_POPUP_USER_REGISTER,
    EVENT_SHOW_POPUP_USER_REGISTER_2,
    EVENT_SHOW_POPUP_USER_REGISTER_RESULT,
    EVENT_SHOW_POPUP_USER_INQUIRY,
    EVENT_SHOW_POPUP_USER_INQUIRY_CONFIRMATION,
    EVENT_SHOW_POPUP_USER_INQUIRY_COMPLETION,
    EVENT_SHOW_POPUP_UPDATE_FAQ_CATEGORY,
    EVENT_SHOW_POPUP_ADD_FAQ,
    EVENT_SHOW_POPUP_UPDATE_FAQ,
    EVENT_SHOW_POPUP_USER_FORGOT_ID_PASSWORD,
    EVENT_SHOW_POPUP_FAQ_CATEGORY,
    EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_SELECT_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_CONFIRM_DELETE,
    EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
    EVENT_SHOW_POPUP_PRIVACY_POLICY,
    EVENT_SHOW_POPUP_UPDATE_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_DETAIL_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_HOLON_TEMPLATE_SEARCH,
    EVENT_SHOW_POPUP_ADD_INSTANCE,
    EVENT_SHOW_POPUP_LIST_HOLON_INSTANCE,
    EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE,
    EVENT_SHOW_POPUP_UPDATE_HOLON_INSTANCE,
    EVENT_SHOW_POPUP_ADD_ALARM_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_ALARM_DETAIL_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_UPDATE_ALARM_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
    EVENT_SHOW_ALARM_LIST_POPUP,
    EVENT_SHOW_USER_LIST_POPUP,
    EVENT_SHOW_INQUIRY_CONDITION_SEARCH_POPUP,
    EVENT_SHOW_INQUIRY_DETAIL_POPUP,
    EVENT_SHOW_CREATE_INQUIRY_POPUP,
    EVENT_SHOW_UPDATE_INQUIRY_POPUP,
    EVENT_SHOW_POPUP_COMPANY_INFORMATION_SETTINGS,
    EVENT_SHOW_POPUP_ORGANIZATIONAL_COLLABORATION,
    EVENT_SHOW_CREATE_WAREHOUSE_POPUP,
    EVENT_SHOW_UPDATE_WAREHOUSE_POPUP,
    EVENT_SHOW_WAREHOUSE_DETAIL_POPUP,
    EVENT_SHOW_SERVICE_DETAIL_POPUP,
    EVENT_SHOW_ORGANIZATIONAL_COLLABORATION_BL_POPUP,
    EVENT_SHOW_SERVICE_SELECTION_POPUP,
    EVENT_SHOW_PREVIEW_FILE_POPUP,
    EVENT_SHOW_CREATE_SENDER_POPUP,
    EVENT_SHOW_UPDATE_SENDER_POPUP,
    EVENT_SHOW_SENDER_DETAIL_POPUP,
    EVENT_SHOW_CREATE_RECEIVER_POPUP,
    EVENT_SHOW_UPDATE_RECEIVER_POPUP,
    EVENT_SHOW_RECEIVER_DETAIL_POPUP,
    EVENT_SHOW_ADD_BL_ACCOUNT_POPUP,
    EVENT_SHOW_ADD_BL_ACCOUNT_SUCCESS_POPUP,
    EVENT_SHOW_TEMPLATE_SELECTION_POPUP,
    EVENT_SHOW_POPUP_CONFIRM_UPDATE,
    EVENT_SHOW_FAQ_DETAIL_POPUP,
    EVENT_SHOW_COMPANY_DETAIL_POPUP,
    EVENT_SHOW_CREATE_PRODUCT_POPUP,
    EVENT_SHOW_UPDATE_PRODUCT_POPUP,
    EVENT_SHOW_PRODUCT_DETAIL_POPUP,
    EVENT_SHOW_CREATE_STOCKS_POPUP,
    EVENT_SHOW_UPDATE_STOCKS_POPUP,
    EVENT_SHOW_STOCK_DETAIL_POPUP,
    EVENT_SHOW_LIST_CHANNEL_POPUP,
    EVENT_SHOW_COPY_DATA_POPUP,
    EVENT_SHOW_HISTORY_DETAIL_POPUP,
    EVENT_SHOW_STOCK_INVENTORY_POPUP,
    EVENT_SHOW_LIST_PRODUCT_POPUP,
    EVENT_SHOW_CREATE_HEX_MENU_POPUP,
    EVENT_SHOW_UPDATE_HEX_MENU_POPUP,
    EVENT_SHOW_CROSS_BORDER_COURIER_SERVICE,
    EVENT_SHOW_POST_DETAIL,
    EVENT_SHOW_LIST_PRODUCT_DETAIL_CARGO_POPUP,
    EVENT_SHOW_FAQ_POPUP,
    EVENT_SHOW_LIST_TEMPLATE_OCR_POPUP,
    EVENT_SHOW_POPUP_CONFIRM,
    EVENT_SHOW_POPUP_ESTIMATE_FARE,
    LIST_INSTANCE_UPDATE,
    EVENT_SHOW_TRANSACTION_DETAIL_POPUP,
    G0100,
    G1382,
    G1381,
    EVENT_SHOW_TRANSACTION_UPDATE_POPUP,
    EVENT_SHOW_TRANSACTION_CREATE_POPUP,
    OVERVIEW_IMPORT_ORDER,
    G1511_ACTION2,
    G1381_U,
    EVENT_SHOW_POPUP_AUTO_BILL_PAY,
    EVENT_SHOW_BP_SERVICE_SELECTION_POPUP,
    EVENT_SHOW_POPUP_ERROR_UPLOAD_ORDER,
    G1511_ADD_PRODUCT,
    G1811_PAYMENT,
    G1600_U,
    OVERVIEW_IMPORT_PRODUCT
}
    from 'utils/EventRegister';
import UpdateFaqCategoryPopup from './faq-category-popup/UpdateFaqCategoryPopup';
import CreateFAQPopup from './faq-popup/CreateFAQPopup';
import UpdateFAQPopup from './faq-popup/UpdateFAQPopup';
import FaqCategoryPopup from './faq-category-popup/FaqCategoryPopup';
import CreateHolonTemplate from './create-holon-tem/CreateHolonTemplate';
import DeletePopup from './confirm-delete-popup/DeletePopup';
import NotificationMessagePopup from './notification-message-popup/NotificationMessagePopup';
import UpdateHolonTemplate from './create-holon-tem/UpdateHolonTemplate';
import HolonTemplateDetail from './create-holon-tem/HolonTemplateDetail';
import ManagementHolonTemplateSearchPopup from './management-search-popup/ManagementHolonTemplateSearchPopup';
import CreatedHolonInstance from './holon-instance-popup/CreatedHolonInstancePopup';
import ListHolonInstancePopup from './list-intance-management-popup/ListHolonInstancePopup';
import HolonInstanceDetailPopup from './holon-instance-popup/HolonInstanceDetailPopup';
import UpdateHolonInstancePopup from './holon-instance-popup/UpdateHolonInstancePopup';
import CreateAlramPopup from './alarm-popup/CreateAlarmPopup';
import AlramDetailPopup from './alarm-popup/AlarmDetailPopup';
import UpdateAlarmPopup from './alarm-popup/UpdateAlarmPopup';
import FormSearchAttributesTemplate from './form-search-attributes-template/FormSearchAttributesTemplate';
import "./CommonPopup.scss";
import AlarmListPopup from './alarm-list-popup/AlarmListPopup';
import UserListPopup from './user-list-popup/UserListPopup';
import InquiryConditionSearchPopup from './inquiry/InquiryConditionSearchPopup';
import InquiryDetailPopup from './inquiry/InquiryDetailPopup';
import CreateInquiryPopup from './inquiry/CreateInquiryPopup';
import UpdateInquiryPopup from './inquiry/UpdateInquiryPopup';
import CreateWarehousePopup from './warehouse-popup/CreateWarehousePopup';
import UpdatedWarehousePopup from './warehouse-popup/UpdatedWarehouse';
import WarehouseDetailPopup from './warehouse-popup/WarehouseDetailPopup';
import ServiceDetailPopup from './service-detail-popup/ServiceDetailPopup';
import OrganizationalCollaborationBlPopup from 'shared/components/common/popup/organizational-collaboration-bl-popup/OrganizationalCollaborationBlPopup';
import PreviewFilePopup from './preview-file-popup/PreviewFilePopup';
import CreateSenderPopup from './sender-popup/CreateSenderPopup';
import UpdatedSenderPopup from './sender-popup/UpdatedSenderPopup';
import SenderDetailPopup from './sender-popup/SenderDetailPopup';
import CreateReceiverPopup from './receiver-popup/CreateReceiverPopup';
import UpdatedReceiverPopup from './receiver-popup/UpdatedReceiverPopup';
import ReceiverDetailPopup from './receiver-popup/ReceiverDetailPopup';
import ServiceSelectionPopup from './service-selection-popup/ServiceSelectionPopup';
import CompanyInformationSettingsPopup from './company/CompanyInformationSettingsPopup';
import OrganizationalCollaborationPopup from './organizational-collaboration/OrganizationalCollaborationPopup';
import AddBlAccountPopup from './add-bl-account-popup/AddBlAccountPopup';
import AddBlAccountSuccessPopup from './add-bl-account-popup/AddBlAccountSuccessPopup';
import UserForgetIDPasswordPopup from 'shared/components/popup/id-password-forget/UserForgetIDPasswordPopup';
import ConfirmUpdatePopup from './confirm-update-popup/ConfirmUpdatePopup';
import TemplateSelectionPopup from './template-selection-popup/TemplateSelectionPopup';
import FaqDetailPopup from './faq-popup/FaqDetailPopup';
import CreateProductPopup from './product-popup/CreateProductPopup';
import UpdateProductPopup from './product-popup/UpdateProductPopup';
import ProductDetailPopup from './product-popup/ProductDetailPopup';
import CreateStockPopup from './stocks-popup/CreateStockPopup';
import UpdateStockPopup from './stocks-popup/UpdateStockPopup';
import StocksDetailPopup from './stocks-popup/StocksDetailPopup';
import ChannelListPopup from './user-list-popup/ChannelListPopup';
import ConfirmCopyPopup from './confirm-update-popup/ConfirmCopyPopup';
import HistoryDetailPopup from './confirm-update-popup/HistoryDetailPopup';
import StockInventoryPopup from './stock-inventory/StockInventoryPopup';
import ListProductSelectionPopup from './cargo/ListProductSelectionPopup';
import CreateHexMenuPopup from './hex-menu-popup/CreateHexMenuPopup';
import UpdateHexMenuPopup from './hex-menu-popup/UpdateHexMenuPopup';
import CrossBorderCourierService from './freight-calculation-management/CrossBorderCourierService'
import PostDetail from './post-detail/PostDetail';
import ProductDetailCargoPopup from './cargo/ProductDetailCargoPopup';
import FaqManagementPopup from './faq/FaqManagementPopup';
import ListTemplateOcrPopup from './list-template-ocr-popup/ListTemplateOcrPopup';
import ConfirmPopup from './confirm-popup/ConfirmPopup';
import EstimatedFareContainer from './estimated-fare-container/estimated-fare-container';
import ActionPopup from './action-popup/ActionPopup';
import TrackingInfoRegistrationPopup from './tracking-info-registration-popup/TrackingInfoRegistrationPopup';
import CancelOrderPopup from './cancel-order-popup/CancelOrderPopup';
import ListInstanceUpdate from './list-instance-update/ListInstanceUpdate';
import TransactionDetailPopup from './transaction/TransactionDetailPopup';
import TransactionCreatePopup from './transaction/TransactionCreatePopup';
import TransactionUpdatePopup from './transaction/TransactionUpdatePopup';
import UploadOrder from './upload-order/UploadOrder';
import OverviewImportOrder from './upload-order/OverviewImportOrder';
import ReturnRequestAction from './action-popup/ReturnRequestAction';
import ListSelectHolonTemplate from './create-holon-tem/ListSelectHolonTemplate';
import AutoBillPayPopup from './auto-bill-pay-popup/AutoBillPayPopup';
import BPServiceSelectionPopup from './bp-service-selection-popup/BPServiceSelectionPopup';
import ListErrorPopup from './upload-order/ListErrorPopup';
import PaymentToBp from './payment-to-bp/PaymentToBp';
import UploadProduct from './upload-product/UploadProduct';
import OverviewImportProduct from './upload-product/OverviewImportProduct';
import EventPopup from 'utils/EventPopup';

const CommonPopup = (props) => {
    const { type, payload } = props;
    const [footer] = useState(null);
    const showHeader = payload?.showHeader ?? true;

    const hiddenPopup = () => {
        EventPopup.removeLastPopup();
    };

    const getType = () => {
        switch (type) {
            case EVENT_SHOW_POPUP_USER_LOGIN:
                return <UserLoginPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_USER_REGISTER:
                return <UserRegisterPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_USER_REGISTER_2:
                return <UserRegisterStep2Popup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_USER_REGISTER_RESULT:
                return <UserRegisterResultPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_UPDATE_FAQ_CATEGORY:
                return <UpdateFaqCategoryPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_ADD_FAQ:
                return <CreateFAQPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_UPDATE_FAQ:
                return <UpdateFAQPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_USER_FORGOT_ID_PASSWORD:
                return <UserForgetIDPasswordPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_USER_INQUIRY:
                return <UserInquiryPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_USER_INQUIRY_CONFIRMATION:
                return <UserInquiryConfirmationPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_USER_INQUIRY_COMPLETION:
                return <UserInquiryCompletionPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_FAQ_CATEGORY:
                return <FaqCategoryPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE:
                return <CreateHolonTemplate payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_SELECT_HOLON_TEMPLATE:
                return <ListSelectHolonTemplate payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_LIST_PRODUCT_POPUP:
                return <ListProductSelectionPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_CONFIRM_DELETE:
                return <DeletePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_CONFIRM_UPDATE:
                return <ConfirmUpdatePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE:
                return <NotificationMessagePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_PRIVACY_POLICY:
                return <PrivacyPolicyPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_UPDATE_HOLON_TEMPLATE:
                return <UpdateHolonTemplate payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_DETAIL_HOLON_TEMPLATE:
                return <HolonTemplateDetail payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_HOLON_TEMPLATE_SEARCH:
                return <ManagementHolonTemplateSearchPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_ADD_INSTANCE:
                return <CreatedHolonInstance payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_LIST_HOLON_INSTANCE:
                return <ListHolonInstancePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE:
                return <HolonInstanceDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_UPDATE_HOLON_INSTANCE:
                return <UpdateHolonInstancePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_ADD_ALARM_HOLON_TEMPLATE:
                return <CreateAlramPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_ALARM_DETAIL_HOLON_TEMPLATE:
                return <AlramDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_UPDATE_ALARM_HOLON_TEMPLATE:
                return <UpdateAlarmPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE:
                return <FormSearchAttributesTemplate payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_COMPANY_INFORMATION_SETTINGS:
                return <CompanyInformationSettingsPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_ORGANIZATIONAL_COLLABORATION:
                return <OrganizationalCollaborationPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_ALARM_LIST_POPUP:
                return <AlarmListPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_USER_LIST_POPUP:
                return <UserListPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_INQUIRY_CONDITION_SEARCH_POPUP:
                return <InquiryConditionSearchPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_INQUIRY_DETAIL_POPUP:
                return <InquiryDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_CREATE_INQUIRY_POPUP:
                return <CreateInquiryPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_UPDATE_INQUIRY_POPUP:
                return <UpdateInquiryPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_CREATE_WAREHOUSE_POPUP:
                return <CreateWarehousePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_UPDATE_WAREHOUSE_POPUP:
                return <UpdatedWarehousePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_WAREHOUSE_DETAIL_POPUP:
                return <WarehouseDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_SERVICE_DETAIL_POPUP:
                return <ServiceDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_ORGANIZATIONAL_COLLABORATION_BL_POPUP:
                return <OrganizationalCollaborationBlPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_ADD_BL_ACCOUNT_POPUP:
                return <AddBlAccountPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_ADD_BL_ACCOUNT_SUCCESS_POPUP:
                return <AddBlAccountSuccessPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_PREVIEW_FILE_POPUP:
                return <PreviewFilePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_CREATE_SENDER_POPUP:
                return <CreateSenderPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_UPDATE_SENDER_POPUP:
                return <UpdatedSenderPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_SENDER_DETAIL_POPUP:
                return <SenderDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_CREATE_RECEIVER_POPUP:
                return <CreateReceiverPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_UPDATE_RECEIVER_POPUP:
                return <UpdatedReceiverPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_RECEIVER_DETAIL_POPUP:
                return <ReceiverDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_SERVICE_SELECTION_POPUP:
                return <ServiceSelectionPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_TEMPLATE_SELECTION_POPUP:
                return <TemplateSelectionPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_BP_SERVICE_SELECTION_POPUP:
                return <BPServiceSelectionPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_FAQ_DETAIL_POPUP:
                return <FaqDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_COMPANY_DETAIL_POPUP:
                return <CompanyDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_CREATE_PRODUCT_POPUP:
                return <CreateProductPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_UPDATE_PRODUCT_POPUP:
                return <UpdateProductPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_PRODUCT_DETAIL_POPUP:
                return <ProductDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_CREATE_STOCKS_POPUP:
                return <CreateStockPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_UPDATE_STOCKS_POPUP:
                return <UpdateStockPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_STOCK_DETAIL_POPUP:
                return <StocksDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_LIST_CHANNEL_POPUP:
                return <ChannelListPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_COPY_DATA_POPUP:
                return <ConfirmCopyPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_HISTORY_DETAIL_POPUP:
                return <HistoryDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_STOCK_INVENTORY_POPUP:
                return <StockInventoryPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_CREATE_HEX_MENU_POPUP:
                return <CreateHexMenuPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_UPDATE_HEX_MENU_POPUP:
                return <UpdateHexMenuPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_CROSS_BORDER_COURIER_SERVICE:
                return <CrossBorderCourierService payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POST_DETAIL:
                return <PostDetail payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_LIST_PRODUCT_DETAIL_CARGO_POPUP:
                return <ProductDetailCargoPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_FAQ_POPUP:
                return <FaqManagementPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_LIST_TEMPLATE_OCR_POPUP:
                return <ListTemplateOcrPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_CONFIRM:
                return <ConfirmPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_ESTIMATE_FARE:
                return <EstimatedFareContainer payload={payload} showVisible={hiddenPopup} />
            case G0100:
                return <ActionPopup payload={payload} showVisible={hiddenPopup} />
            case G1382:
                return <TrackingInfoRegistrationPopup payload={payload} showVisible={hiddenPopup} />
            case G1381:
                return <CancelOrderPopup payload={payload} showVisible={hiddenPopup} />
            case LIST_INSTANCE_UPDATE:
                return <ListInstanceUpdate payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_TRANSACTION_DETAIL_POPUP:
                return <TransactionDetailPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_TRANSACTION_CREATE_POPUP:
                return <TransactionCreatePopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_TRANSACTION_UPDATE_POPUP:
                return <TransactionUpdatePopup payload={payload} showVisible={hiddenPopup} />
            case G1381_U:
                return <UploadOrder payload={payload} showVisible={hiddenPopup} />
            case OVERVIEW_IMPORT_ORDER:
                return <OverviewImportOrder payload={payload} showVisible={hiddenPopup} />
            case G1511_ACTION2:
                return <ReturnRequestAction payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_AUTO_BILL_PAY:
                return <AutoBillPayPopup payload={payload} showVisible={hiddenPopup} />
            case EVENT_SHOW_POPUP_ERROR_UPLOAD_ORDER:
                return <ListErrorPopup payload={payload} showVisible={hiddenPopup} />
            case G1511_ADD_PRODUCT:
                return <ListProductSelectionPopup payload={payload} showVisible={hiddenPopup} />
            case G1811_PAYMENT:
                return <PaymentToBp payload={payload} showVisible={hiddenPopup} />
            case G1600_U:
                return <UploadProduct payload={payload} showVisible={hiddenPopup} />
            case OVERVIEW_IMPORT_PRODUCT:
                return <OverviewImportProduct payload={payload} showVisible={hiddenPopup} />
            default:
                return <></>
        }
    };

    const ListTypeClickBackdrop = [
        EVENT_SHOW_POPUP_USER_LOGIN,
        EVENT_SHOW_POPUP_USER_REGISTER,
        EVENT_SHOW_POPUP_USER_REGISTER_2,
        EVENT_SHOW_POPUP_USER_REGISTER_RESULT,
        EVENT_SHOW_POPUP_USER_INQUIRY,
        EVENT_SHOW_POPUP_USER_INQUIRY_CONFIRMATION,
        EVENT_SHOW_POPUP_UPDATE_FAQ_CATEGORY,
        EVENT_SHOW_POPUP_ADD_FAQ,
        EVENT_SHOW_POPUP_UPDATE_FAQ,
        EVENT_SHOW_POPUP_USER_FORGOT_ID_PASSWORD,
        EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE,
        EVENT_SHOW_POPUP_SELECT_HOLON_TEMPLATE,
        EVENT_SHOW_POPUP_UPDATE_HOLON_TEMPLATE,
        EVENT_SHOW_POPUP_HOLON_TEMPLATE_SEARCH,
        EVENT_SHOW_POPUP_ADD_INSTANCE,
        EVENT_SHOW_POPUP_LIST_HOLON_INSTANCE,
        EVENT_SHOW_POPUP_UPDATE_HOLON_INSTANCE,
        EVENT_SHOW_POPUP_ADD_ALARM_HOLON_TEMPLATE,
        EVENT_SHOW_POPUP_UPDATE_ALARM_HOLON_TEMPLATE,
        EVENT_SHOW_USER_LIST_POPUP,
        EVENT_SHOW_CREATE_INQUIRY_POPUP,
        EVENT_SHOW_UPDATE_INQUIRY_POPUP,
        EVENT_SHOW_POPUP_COMPANY_INFORMATION_SETTINGS,
        EVENT_SHOW_POPUP_ORGANIZATIONAL_COLLABORATION,
        EVENT_SHOW_CREATE_WAREHOUSE_POPUP,
        EVENT_SHOW_UPDATE_WAREHOUSE_POPUP,
        EVENT_SHOW_ORGANIZATIONAL_COLLABORATION_BL_POPUP,
        EVENT_SHOW_CREATE_SENDER_POPUP,
        EVENT_SHOW_UPDATE_SENDER_POPUP,
        EVENT_SHOW_CREATE_RECEIVER_POPUP,
        EVENT_SHOW_UPDATE_RECEIVER_POPUP,
        EVENT_SHOW_ADD_BL_ACCOUNT_POPUP,
        EVENT_SHOW_CREATE_PRODUCT_POPUP,
        EVENT_SHOW_UPDATE_PRODUCT_POPUP,
        EVENT_SHOW_CREATE_STOCKS_POPUP,
        EVENT_SHOW_UPDATE_STOCKS_POPUP,
        EVENT_SHOW_LIST_CHANNEL_POPUP,
        EVENT_SHOW_CREATE_HEX_MENU_POPUP,
        EVENT_SHOW_UPDATE_HEX_MENU_POPUP,
        EVENT_SHOW_CROSS_BORDER_COURIER_SERVICE,
        EVENT_SHOW_POPUP_ESTIMATE_FARE,
        LIST_INSTANCE_UPDATE,
        G0100,
        G1382,
        G1381,
        EVENT_SHOW_TRANSACTION_UPDATE_POPUP,
        EVENT_SHOW_TRANSACTION_CREATE_POPUP,
        G1511_ACTION2,
        G1381_U,
        EVENT_SHOW_POPUP_AUTO_BILL_PAY,
        G1511_ADD_PRODUCT,
        G1811_PAYMENT,
        G1600_U,
    ]
    return <CustomDialog
        payload={payload}
        hiddenPopup={hiddenPopup}
        showHeader={showHeader}
        footer={footer}
        getType={getType()}
        type={type}
        ListTypeClickBackdrop={ListTypeClickBackdrop}
    />
}

export default CommonPopup;