import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import CustomCheckBox from '../form-input/checkbox/Checkbox';

const FormHidden = (props) => {
    const {
        fieldName,
        validate = [],
        placeholder = '',
        disabled = false,
        size = '',
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div hidden>
            <Controller
                control={control}
                name={fieldName}
                rules={{ validate: Validator.genValidate(validate, fieldName) }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <></>
                    );
                }}
            />
        </div>
    );
};

FormHidden.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    textAlign: PropTypes.string,
    size: PropTypes.string,
};
export default FormHidden;
