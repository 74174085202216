import React from "react";
import classes from "./CustomRadio.module.scss";
import IconSimpleLineCheck from "assets/icon/IconSimpleLineCheck";
import IconSimpleCheck from "assets/icon/IconSimpleCheck";
import ErrorMessage from "../error-message/ErrorMessage";
import _ from "lodash";
import IconCheck from "assets/icon/IconCheck";
import IconChecked from "assets/icon/IconChecked";
import ErrorMessageHandleFE from "../error-message/ErrorMessageHandleFE";
import ErrorMessageHandleRegex from "../error-message/ErrorMessageHandleRegex";

const CustomRadio = (props) => {
    const {
        options = [],
        onChange,
        ref,
        value,
        className,
        disabled = false,
        errors,
        inputRef,
        name,
        textTrans,
        validationFE,
        isRegexLabel
    } = props;
    const onChangeRadio = (
        e,
        value
    ) => {
        e.stopPropagation();
        onChange(value);
    };

    let showError = false;
    let error = {
        message: "",
    };

    let arr = name.split(".");
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    return (
        <>
            <div
                ref={inputRef ? inputRef : ref}
                className={`${classes["CustomRadio"]} ${disabled ? classes["CustomRadio_Disabled"] : ""} ${className}`}
            >
                {options.map((item, key) => {
                    return (
                        <div
                            className={`${classes["Radio_Item"]} radio-item`}
                            onClick={(e) => onChangeRadio(e, item.value)}
                            key={key}
                        >
                            <div
                                className={`${classes["Radio_Icon"]} ${item.value == value ? classes["Radio_Active"] : ""}`}
                            >
                                {item.value == value ? (
                                    <IconChecked />
                                ) : (
                                    <IconCheck
                                        color={"black"}
                                    />
                                )}
                            </div>
                            <p className={`${classes["Radio_Item_Label"]} radio-item-label`}>{item.label}</p>
                        </div>
                    );
                })}
            </div>
            {/* {showError ? <ErrorMessage message={error?.message} /> : <></>} */}
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} textTrans={textTrans} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default CustomRadio;
