import React from "react";
import classes from "./NotificationMessagePopup.module.scss";
import Button from "shared/components/form-input/button/Button";
import useTrans from "hooks/use-trans";
import CustomHeaderConfirm from "../custom-header/CustomHeaderConfirm";

function NotificationMessagePopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    return (
        <div className={classes["NotificationMessagePopup"]}>
            <CustomHeaderConfirm
                title={t("Holon_Template.notification")}
                showVisible={showVisible}
                payload={payload}
            />
            <div className={classes["DeletePopup"]}>
                <div className={classes["Body"]}>
                    <p>{payload?.title ?? payload?.message}</p>
                    <div className={classes["Footer"]}>
                        <Button
                            onClick={() => showVisible(false)}
                            variant="outlined"
                            title={t("Holon_Template.close")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotificationMessagePopup;
