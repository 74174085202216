import React, { useEffect, useRef } from 'react';
import classes from "./Box.module.scss";

function AutoScroll (props) {
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            if (ref.current) {
                var hasVerticalScrollbar = ref.current?.scrollHeight > ref.current?.clientHeight;
                if (hasVerticalScrollbar) {
                    ref.current.style.marginRight = "-25px"
                    ref.current.style.paddingRight = "15px";
                    resizeObserver.disconnect();
                } else {
                    ref.current.style.marginRight = "-15px"
                    ref.current.style.paddingRight = "15px"
                }
            }
        });
        resizeObserver.observe(ref.current);
        return () => {
            resizeObserver.disconnect();
        }
    }, [])
    return (
        <div className={`${classes["AutoScroll"]} ${props.className}`} style={{
            maxHeight: props?.style?.maxHeight
        }} ref={ref}>
            {props.children}
        </div>
    )
}
export default AutoScroll;