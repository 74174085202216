import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const factory = {
    getListWarehouse: (payload) => {

        let params = {
            subAttribute: ""
        };

        let data = {
            subAttribute: {}
        }

        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE];
        }

        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload[Constants.QueryParams.ATTRIBUTES.VALUE]) {
            data['mainAttribute'] = JSON.parse(payload[Constants.QueryParams.ATTRIBUTES.VALUE]);
        }

        let url = `/rest/api/v1/management/warehouse/filter`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            params: params,
            data: data
        })
    },
    updateWarehouse: (payload) => {
        let url = "/rest/api/v1/management/warehouse";
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    deleteWarehouse: (payload) => {
        let url = `/rest/api/v1/management/warehouse/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'DELETE',
        })
    },
    createWarehouse: (payload) => {
        let url = "/rest/api/v1/management/warehouse";
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    getDetailWarehouse: (payload) => {
        let url = `/rest/api/v1/management/warehouse/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getHolonTemplateDetail: () => {
        let url = `/rest/api/v1/management/warehouse/template`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
}

export default factory;