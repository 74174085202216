import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import uncheckedIcon from 'assets/img/unchecked-icon.svg';
import checkedIcon from 'assets/img/checked-icon.svg';
import useTrans from 'hooks/use-trans';
import { useEffect } from 'react';
import styles from './Form.module.scss'

function FormCheckbox(props) {
    const { trans } = useTrans();
    const {
        fieldName,
        validate = [],
        label,
        register,
        callback = null,
        checked = false,
        field = ''
    } = props
    const { formState: { errors }, control } = useFormContext();
    const [isChecked, setIsChecked] = useState(checked);
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])
    return (
        <Controller
            control={control}
            name={fieldName}
            rules={{ validate: Validator.genValidate(validate, fieldName) }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return <div>
                    <div className={`h-100 ${styles['checkbox-container']}`}>
                        <div className={styles['form-check']} >
                            <input
                                _props={{ inputRef: ref }}
                                {...register(fieldName, { value: value ?? isChecked })}
                                name={fieldName}
                                type='checkbox'
                                onChange={onChange}
                                onBlur={onBlur}
                                checked={isChecked}
                                value={Boolean(value)}
                            />
                            <div className={styles['icon-container']}>
                                <div onClick={() => {
                                    onChange(!isChecked)
                                    setIsChecked(!isChecked)
                                    callback && callback()
                                }}>
                                    <img className={styles['unchecked-icon']} src={uncheckedIcon} alt="Uncheck Icon" />
                                    <img className={styles['checked-icon']} src={checkedIcon} alt="Check Icon" />
                                </div>
                            </div>
                        </div>
                        <label onClick={() => {
                            onChange(!isChecked)
                            setIsChecked(!isChecked)
                            callback && callback()
                        }} className={styles['checkbox-label']} > {label}</label>
                    </div>
                    <div>
                        {errors[fieldName] && <span className='error-msg'>{Validator.renderMessage(trans(errors[fieldName]?.message), { 0: field?.messageKey ? trans(field?.labelKey) : trans(fieldName) })}</span>}
                    </div>
                </div>
            }}
        />
    )
}

export default FormCheckbox;
