const Actions = {
    GET_HOLON_TEMPLATE: "GET_HOLON_TEMPLATE",
    GET_HOLON_TEMPLATE_RESPONSE: "GET_HOLON_TEMPLATE_RESPONSE",
    CLEAR_HOLON_TEMPLATE: "CLEAR_HOLON_TEMPLATE",

    GET_HOLON_TEMPLATES_BY_TEMPLATE_IDS: "GET_HOLON_TEMPLATES_BY_TEMPLATE_IDS",
    GET_HOLON_TEMPLATES_BY_TEMPLATE_IDS_SUCCESS: "GET_HOLON_TEMPLATES_BY_TEMPLATE_IDS_SUCCESS",
    CLEAR_HOLON_TEMPLATES: "CLEAR_HOLON_TEMPLATES",

    GET_HOLON_TEMPLATES_IN_USER_BY_MASTER_CODES: "GET_HOLON_TEMPLATES_IN_USER_BY_MASTER_CODES",

    GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES: "GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES",
    GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES_SUCCESS: "GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES_SUCCESS",
    GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES_FAILURE: "GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES_FAILURE",

    GET_HOLON_TEMPLATES_BY_MASTER_CODES: "GET_HOLON_TEMPLATES_BY_MASTER_CODES",
    GET_HOLON_TEMPLATES_BY_MASTER_CODES_SUCCESS: "GET_HOLON_TEMPLATES_BY_MASTER_CODES_SUCCESS",
    GET_HOLON_TEMPLATES_BY_MASTER_CODES_FAILURE: "GET_HOLON_TEMPLATES_BY_MASTER_CODES_FAILURE",

    GET_TEMPLATE_IDS_BY_MASTER_CODES: "GET_TEMPLATE_IDS_BY_MASTER_CODES",
    GET_TEMPLATE_IDS_BY_MASTER_CODES_SUCCESS: "GET_TEMPLATE_IDS_BY_MASTER_CODES_SUCCESS",
    GET_TEMPLATE_IDS_BY_MASTER_CODES_FAILURE: "GET_TEMPLATE_IDS_BY_MASTER_CODES_FAILURE",

    GET_HOLON_TEMPLATES: "GET_HOLON_TEMPLATES",
    GET_HOLON_TEMPLATES_SUCCESS: "GET_HOLON_TEMPLATES_SUCCESS",

    GET_TEMPLATE_ID_BY_MASTER_CODE: "GET_TEMPLATE_ID_BY_MASTER_CODE",

    GET_HOLON_TEMPLATE_BY_CODE: "GET_HOLON_TEMPLATE_BY_CODE",
    GET_HOLON_TEMPLATE_CODE_RESPONSE: "GET_HOLON_TEMPLATE_CODE_RESPONSE",

    GET_COMPANY: "GET_COMPANY",
    UPDATE_COMPANY: "UPDATE_COMPANY",
    UPDATE_COMPANY_FAILURE: "UPDATE_COMPANY_FAILURE",
    CLEAR_UPDATE_COMPANY_ERROR: "CLEAR_UPDATE_COMPANY_ERROR",

    CONNECT_EMPLOYEE: "CONNECT_EMPLOYEE",
    CONNECT_EMPLOYEE_FAILURE: "CONNECT_EMPLOYEE_FAILURE",
    CLEAR_CONNECT_EMPLOYEE_ERROR: "CLEAR_CONNECT_EMPLOYEE_ERROR",

    REMOVE_CONNECTION: "REMOVE_CONNECTION",
    REMOVE_CONNECTION_FAILURE: "REMOVE_CONNECTION_FAILURE",
    CLEAR_REMOVE_CONNECTION_ERROR: "CLEAR_REMOVE_CONNECTION_ERROR",

    DELETE_INSTANCE_REMOVE_CONNECTION: "DELETE_INSTANCE_REMOVE_CONNECTION",
    DELETE_INSTANCE_REMOVE_CONNECTION_FAILURE: "DELETE_INSTANCE_REMOVE_CONNECTION_FAILURE",
    CLEAR_DELETE_INSTANCE_REMOVE_CONNECTION_ERROR: "CLEAR_DELETE_INSTANCE_REMOVE_CONNECTION_ERROR",

    GET_HOLON_INSTANCE_BY_ATTRIBUTE_VALUE: "GET_HOLON_INSTANCE_BY_ATTRIBUTE_VALUE",
    GET_HOLON_INSTANCE_BY_ATTRIBUTE_VALUE_RESPONSE: "GET_HOLON_INSTANCE_BY_ATTRIBUTE_VALUE_RESPONSE",

    GET_HOLON_MASTER_BY_CODE: "GET_HOLON_MASTER_BY_CODE",
    GET_HOLON_MASTER_BY_CODE_SUCCESS: "GET_HOLON_MASTER_BY_CODE_SUCCESS",
    GET_HOLON_MASTER_BY_CODE_FAILURE: "GET_HOLON_MASTER_BY_CODE_FAILURE",

    GET_OUTSOURCE_COMPANY_CLIENT_CONNECTION: "GET_OUTSOURCE_COMPANY_CLIENT_CONNECTION",
    GET_OUTSOURCE_COMPANY_CLIENT_CONNECTION_SUCCESS: "GET_OUTSOURCE_COMPANY_CLIENT_CONNECTION_SUCCESS",
    GET_OUTSOURCE_COMPANY_CLIENT_CONNECTION_FAILURE: "GET_OUTSOURCE_COMPANY_CLIENT_CONNECTION_FAILURE",

}

export default Actions;