import React, { useEffect, useState } from 'react'
import styles from './RequestEstimationContainer.module.scss'
import Actions from 'redux/holon/action';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_TEMPLATE_SELECTION_POPUP } from 'utils/EventRegister';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import { useDispatch } from 'react-redux';
import useRouter from 'hooks/use-router';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';

const RequestEstimationContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTrans()
    const [estimationRequestTemplate, setEstimationRequestTemplate] = useState(null);
    const router = useRouter();
    const [templateId, setTemplateId] = useState(router.get(Constants.QueryParams.CREATE_BY_TEMPLATE_ID.VALUE));
    
    useEffect(() => {
        dispatch({
            type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.ESTIMATION_REQUEST,
            callback: (data) => {
                setEstimationRequestTemplate(data?.toString())
            }
        })
    }, []);

    useEffect(() => {
        setTemplateId(router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE))
    }, [router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)]);

    const handleOpenRequestEstimationSelectionPopup = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_TEMPLATE_SELECTION_POPUP,
            payload: {
                showHeader: false,
                title: t("request_estimation.popup_select_title"),
                holonMasterCode: Constants.HOLON_MASTER_CODE.ESTIMATION_REQUEST,
                titleCreatePopup: t("request_estimation.popup_create_title"),
                listTemplate: estimationRequestTemplate
            },
        })
    }

    const handleClickAddButton = () => {
        return <ButtonAdd size='large' onClick={handleOpenRequestEstimationSelectionPopup} color='yellow' startIcon={<IconPlusWhite />} />
    }

    return (
        <div className={`${styles['main-container']}`}>
            <div className={styles['holon-instance']} >
                {estimationRequestTemplate &&
                    <HolonInstance
                        isUseAddFunction={false}
                        ActionSection={handleClickAddButton()}
                        title={t("request_estimation.title")}
                        template_id={estimationRequestTemplate || ''}
                        titleDetailPopup={t("request_estimation.popup_detail_title")}
                        titleCreatePopup={t("request_estimation.popup_create_title")}
                        titleUpdatePopup={t("request_estimation.popup_update_title")}
                        listTemplateCreation={estimationRequestTemplate?.split(",")?.map(x => ({id: x}))}
                    />}
            </div>
        </div>
    )
}

export default RequestEstimationContainer