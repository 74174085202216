import React, { useEffect, useState } from 'react'
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider'
import BoxContentPopup from '../custom-body/BoxContentPopup'
import WrapContentBody from '../custom-body/WrapContentBody'
import Button from 'shared/components/form-input/button/Button';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import FormInput from 'shared/components/custom-form/FormInput';
import useTrans from 'hooks/use-trans';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import Constants from 'utils/Constants';
import Validator from 'utils/Validator';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import moment from 'moment';
import { getMessageCommon, showPopupNotification } from 'utils/Utils';
import classes from './PaymentToBp.module.scss';

function PaymentToBp(props) {
    const { showVisible, payload } = props;
    const [loading, setLoading] = useState(false);
    const [loadingHolonInstanceDetail, setLoadingHolonInstanceDetail] = useState(false);
    const { t } = useTrans();

    const dispatch = useDispatch();
    const methods = useForm();

    useEffect(() => {
        setLoadingHolonInstanceDetail(true);
        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
            payload: payload?.['holon_id'],
            callback: (data) => {
                let price = data?.attributes?.find(x => x?.name == Constants.BP_TO_PAYMENT.ATTRIBUTES.PAYMENT_AMOUNT_WITH_TAX)?.value;
                price = price?.replace(/¥/g, '')
                price = price?.replace(/,/g, '')
                methods.setValue("bill_amount", price)
                setLoadingHolonInstanceDetail(false);
            }
        })

        return () => {
            methods.reset({})
        }
    }, [
        payload?.['holon_id']
    ])


    const onSubmit = (data) => {

        setLoading(true);
        dispatch({
            type: actionsHolonInstance.BP_TO_PAYMENT,
            payload: {
                ...data,
                holonId: payload?.['holon_id'],
                date: moment(data?.date)?.format("DD/MM/YYYY")
            },
            callback: {
                success: (response, error) => {
                    showVisible(false);
                    if (response?.data?.reload == Constants.RESPONSE_ACTION.RELOAD.VALUE) {
                        payload?.callback();
                    }
                    showPopupNotification({
                        typePopup: 'message',
                        message: getMessageCommon({
                            response: response,
                            error: error,
                            actionName: payload?.option?.actionName
                        }),
                        newWindow: true,
                    });
                }
            }
        })
    }

    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <BoxContentPopup >
                    <WrapContentBody
                        isUseBackground={false}
                        showVisible={showVisible}
                        title={t("bp_request_payment.payment_to_bp")}
                        actionSectionBottom={<Button disabled={loadingHolonInstanceDetail} loading={loading} type='submit' title={t("save")} />}
                        useForm={false}
                    >

                        <BoxBody style={{
                            borderRadius: 10,
                            padding: '10px 0px',
                        }}>
                            <BoxRow style={{
                                padding: '0px 40px 0px 19px',
                                position: 'relative'
                            }}>
                                <BoxLabel style={{
                                    height: 40
                                }}>{t("bp_request_payment.bill_amount")}</BoxLabel>
                                <BoxContent style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 5,
                                    alignItems: 'center'
                                }}>
                                    <FormInput
                                        fieldName='bill_amount'
                                        format={Constants.FormInputFormat.NUMBER.VALUE}
                                        disabled
                                    />
                                    <span className={classes['circle']}>{t("bp_request_payment.circle")}</span>
                                </BoxContent>
                            </BoxRow>
                            <BoxRow style={{
                                padding: '0px 40px 0px 19px',
                                position: 'relative'
                            }}>
                                <BoxLabel isRequired style={{
                                    height: 40
                                }}>{t("bp_request_payment.transfer_amount_of_money")}</BoxLabel>
                                <BoxContent style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 5,
                                    alignItems: 'center'
                                }}>
                                    <FormInput
                                        fieldName='transferAmount'
                                        format={Constants.FormInputFormat.NUMBER.VALUE}
                                        validate={[Validator.required]}
                                        validationFE
                                        isRegexLabel={t("bp_request_payment.transfer_amount_of_money")}
                                        max={methods.getValues("bill_amount")}
                                    />
                                    <span className={classes['circle']}>{t("bp_request_payment.circle")}</span>
                                </BoxContent>
                            </BoxRow>
                            <BoxRow style={{
                                padding: '0px 40px 0px 19px',
                            }}>
                                <BoxLabel isRequired style={{
                                    height: 40
                                }}>{t("bp_request_payment.day_of_money")}</BoxLabel>
                                <BoxContent>
                                    <FormDatePicker
                                        fieldName='date'
                                        validate={[Validator.required]}
                                        validationFE
                                        isRegexLabel={t("bp_request_payment.day_of_money")}
                                        withPortal
                                    />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow style={{
                                padding: '0px 40px 0px 19px',
                            }}>
                                <BoxLabel style={{
                                    height: 40
                                }}>{t("bp_request_payment.note")}</BoxLabel>
                                <BoxContent>
                                    <FormTextArea
                                        fieldName='remark'
                                    />
                                </BoxContent>
                            </BoxRow>
                        </BoxBody>

                    </WrapContentBody>
                </BoxContentPopup>
            </form>
        </CustomFormProvider>
    )
}

export default PaymentToBp