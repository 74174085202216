import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import EstimationContainer from 'shared/container/estimation/EstimationContainer';

function EstimationPage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t('estimation.title');
    }, []);
    return (
        <EstimationContainer />
    )
}

export default EstimationPage