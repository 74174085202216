import React, { useEffect } from "react";
import useTrans from "hooks/use-trans";
import InquiryListContainer from "shared/container/inquiry/InquiryListContainer";
import classes from "./InquiryListPage.module.scss";

const InquiryListPage = () => {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t("inquiry.title_inquiry");
    }, []);
    return (
        <div className={classes["InquiryListPage"]}>
            <InquiryListContainer />
        </div>
    );
};

export default InquiryListPage;
