import React from 'react';

function IconInput({ color = 'white' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="41"
            viewBox="0 0 38 32"
            fill="none"
        >
            <path d="M24.9111 27.7477H3.96818V6.80482H21.6049L25.5731 2.83656H0V31.7159H28.879V29.7318V21.028L24.9111 24.9963V27.7477Z" fill="white" />
            <path d="M11.7936 9.12081L7.71234 13.2028L14.3564 19.8462L18.4376 23.928L22.5188 19.8462L38 4.36599L33.9181 0.284103L18.4382 15.7642L11.7936 9.12081Z" fill="white" />
        </svg>
    );
}

export default IconInput;
