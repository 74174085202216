import { Step, StepLabel, Stepper, StepIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconStepConnector from 'assets/icon/IconStepConnector';
import React from 'react'
import Image from './Image';
import useTrans from 'hooks/use-trans';

const StepperComponent = (props) => {
    const { steps, activeStep = 0, errorStep, diameter = 100 } = props;
    const {trans} = useTrans();

    const CustomStepConnector = () => (
        <IconStepConnector/>
    );

    const CircleIcon = styled('div')(({ theme, active, completed }) => ({
        display: 'flex',
        color: '#FFF',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: diameter,
        height: diameter,
        borderRadius: '50%',
        backgroundColor: active || completed ? '#106574' : '#67BFCE',
        '& > svg': {
            padding: '4px',
        },
        '& > div': {
            overflowX: 'hidden',
            paddingRight: 0,
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            fontSize: '14px',
            fontWeight: 400,
            letterSpacing: '1.4px',
        },
    }));

    const CustomStep = styled(Step)({
        padding: 0,
    });

    const CustomStepLabel = styled(StepLabel)({
        padding: 0,

        '& span': {
            paddingRight: 0
        }
    });

    const CustomStepIcon = (props) => {
        const { active, completed, label } = props;
        return (
            <CircleIcon active={active} completed={completed}>
                {props.icon}
                <div>
                    {label}
                </div>
            </CircleIcon>
        );
    }

    return <Stepper activeStep={activeStep} connector={<CustomStepConnector />}>
        {steps.map((item) => (
            <CustomStep key={item.label}>
                <CustomStepLabel
                    icon={item.icon}
                    StepIconComponent={CustomStepIcon}
                    StepIconProps={{
                        label: trans(item.label)
                    }}
                >
                </CustomStepLabel>
            </CustomStep>
        ))}
    </Stepper>
}

export default StepperComponent;