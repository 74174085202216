import React, { useState } from 'react'
import classes from './ConfirmPopup.module.scss';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import CustomHeaderConfirm from '../custom-header/CustomHeaderConfirm';

function ConfirmPopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const [loading, setLoading] = useState(false)

    const handleAccept = () => {
        if (!payload?.isLoading) {
            payload?.callback && payload?.callback();
            showVisible(false);
        } else {
            setLoading(true);
            payload?.callback && payload?.callback({
                hiddenPopup: () => {
                    showVisible(false);
                    setLoading(false)
                }
            });
        }
    }
    return (
        <>
            <div className={classes['ConfirmPopup']}>
                <CustomHeaderConfirm title={payload?.title} showVisible={showVisible} payload={payload} />
                <div className={classes['Body']}>
                    <p>{payload?.message}</p>
                    <div className={classes['Footer']}>
                        <Button loading={loading} onClick={handleAccept} title={t("confirm_popup.yes")} />
                        <Button disabled={loading} onClick={() => showVisible(false)} color='gray' title={t("confirm_popup.no")} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmPopup