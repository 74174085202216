import React, { useEffect, useState } from 'react'
import BoxContentPopup from '../custom-body/BoxContentPopup';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import actionProduct from 'redux/product/action';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_PRODUCT_DETAIL_POPUP } from 'utils/EventRegister';
import useRouter from 'hooks/use-router';
import FormData from './FormData';

function UpdateProductPopup(props) {
    const router = useRouter();
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const [product, setProduct] = useState(null)
    const dispatch = useDispatch();
    const { loadingGetDetail, loadingUpdate } = useSelector(state => state.product);
    const methods = useForm({
        defaultValues: {
            item_name: "",
            price: 0,
            manual_update_flag: "",
            jan_code: ""
        }
    });

    const onSubmit = (data) => {
        const params = {
            id: product?.id,
            item_name: data?.item_name,
            genre_id: data?.genre_id?.key,
            price: parseInt(data?.price, 10),
            product_url: data?.product_url,
            origin: data?.origin,
            note: data?.note,
            currency: data?.currency,
            jan_code: data.jan_code,
            brand_name: data?.brand_name,
            maker_name: data?.maker_name,
        }
        dispatch({
            type: actionProduct.UPDATE_PRODUCT,
            payload: {
                ...params
            },
            callback: {
                success: () => {
                    dispatch({
                        type: actionProduct.GET_LIST_PRODUCT,
                        payload: {
                            ...router.getAll(),
                        }
                    });
                    showVisible()
                },
                failed: () => {

                }
            }
        })
    }

    useEffect(() => {
        if (product?.id) {
            methods.reset({
                jan_code: product.jan_code,
                item_name: product.item_name,
                manual_update_flag: product.manual_update_flag == "1" ? true : false,
                price: product.price,
                product_url: product?.product_url,
                origin: product?.origin,
                note: product?.note,
                currency: product?.currency,
                brand_name: product?.brand_name,
                maker_name: product?.maker_name,
                genre_id: {
                    key: product?.genre_id,
                    id: product?.genre_id,
                    label: product?.genre_name
                },
            })
        }
    }, [product])

    useEffect(() => {
        if (payload?.productId) {
            dispatch({
                type: actionProduct.GET_DETAIL_PRODUCT_POPUP,
                payload: payload?.productId,
                callback: (data) => {
                    setProduct(data)
                }
            })
        }
    }, [payload?.productId])


    const gotoDetail = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_PRODUCT_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                productId: payload?.productId,
                isShowOption: false,
                title: t("product.product_detail"),
            }
        })
    }
    return (
        <BoxContentPopup minWidth="1000px">
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        handleBtnBack={gotoDetail}
                        title={payload?.title} showVisible={showVisible} payload={payload}
                        actionSectionBottom={<Button loading={loadingUpdate || loadingGetDetail} type='submit' title={t("product.submit")} />}
                    >
                        <FormData labelValue={product?.genre_name} />
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default UpdateProductPopup