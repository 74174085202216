import React, { useState } from 'react'
import BoxContentPopup from '../custom-body/BoxContentPopup'
import WrapContentBody from '../custom-body/WrapContentBody'
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import classes from './UploadOrder.module.scss';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ERROR_UPLOAD_ORDER } from 'utils/EventRegister';
import Box from '../../box/Box';
import { getMessageCommon, showPopupNotification } from 'utils/Utils';
import Action from 'redux/order/action';
import { useDispatch } from 'react-redux';

function OverviewImportOrder(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const handleBtnBack = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: payload?.api,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                ...payload
            }
        })
    }

    const handleSubmit = () => {
        setLoading(true)
        dispatch({
            type: Action.UPLOAD_FILE_ORDER,
            payload: {
                file: payload?.file,
                templateId: payload?.templateId,
                isIgnoreError: true,
                warehouseId: payload?.warehouse?.id
            },
            callback: {
                success: (_data) => {
                    setLoading(false);
                    showVisible(false);
                    payload?.callback()
                    showPopupNotification({
                        typePopup: 'message',
                        message: getMessageCommon({
                            response: _data,
                            error: null,
                            actionName: t("warehouse_in.in_confirm")
                        }),
                        newWindow: true,
                    })
                },
            }
        })
    }

    const handleOpenPopupError = () => {
        showPopupNotification({
            typePopup: 'custom',
            newWindow: true,
            type: EVENT_SHOW_POPUP_ERROR_UPLOAD_ORDER,
            payload: {
                data: payload?.data?.errors,
                errorType: "ORDER"
            }
        })
    }
    return (
        <BoxContentPopup >
            <WrapContentBody
                isUseBackground={false}
                showVisible={showVisible}
                title={t("orders.csv_file_contains_error_records")}
                actionSectionBottom={
                    <div className={classes['row-action']}>
                        <Button onClick={handleBtnBack} color='gray' type='submit' title={t("orders.csv_file_exchange")} />
                        <Button disabled={payload?.data?.totalError > 0 && payload?.data?.totalSuccess == 0 ? true : false} loading={loading} onClick={handleSubmit} type='submit' title={t("orders.import_only_normal_record")} />
                    </div>
                }
                useForm={false}
                handleBtnBack={handleBtnBack}

            >
                <Box>
                    <BoxBody style={{
                        borderRadius: 10
                    }}>
                        <BoxRow>
                            <BoxLabel minWidth={140}>{t("orders.total")}</BoxLabel>
                            <BoxContent>
                                {payload?.data?.totalRow}{t("orders.subject")}
                            </BoxContent>
                        </BoxRow>
                        <BoxRow>
                            <BoxLabel minWidth={140}>{t("orders.normal")}</BoxLabel>
                            <BoxContent>
                                {payload?.data?.totalSuccess}{t("orders.subject")}
                            </BoxContent>
                        </BoxRow>
                        <BoxRow>
                            <BoxLabel minWidth={140}>{t("orders.abnormality")}</BoxLabel>
                            <BoxContent style={{
                                flexDirection: 'row',
                                gap: 20,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {payload?.data?.totalError}{t("orders.subject")}
                                <Button onClick={handleOpenPopupError}>{t("orders.abnormality_location")}</Button>
                            </BoxContent>
                        </BoxRow>
                    </BoxBody>
                </Box>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default OverviewImportOrder