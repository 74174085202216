import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionProduct from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";
import { showPopupNotification } from "utils/Utils";

function* getListProduct() {
    yield takeEvery(actionProduct.GET_LIST_PRODUCT, function* (payload) {
        try {
            const response = yield call(() => factory.getListProduct(payload?.payload));
            yield put({
                type: actionProduct.GET_LIST_PRODUCT_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* getListProductPopup() {
    yield takeEvery(actionProduct.GET_LIST_PRODUCT_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getListProduct(payload?.payload));
            yield payload?.callback?.success(response)
        } catch (error) {

        }
    })
}

function* getCategory() {
    yield takeEvery(actionProduct.GET_CATEGORY, function* (payload) {
        try {
            const response = yield call(() => factory.getCategory(payload?.payload));
            yield payload?.callback?.success(response)
        } catch (error) {

        }
    })
}

function* updateProduct() {
    yield takeEvery(actionProduct.UPDATE_PRODUCT, function* (payload) {
        try {
            const response = yield call(() => factory.updateProduct(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionProduct.UPDATE_PRODUCT_SUCCESS,
                    payload: response
                })
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.message,
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield put({
                    type: actionProduct.UPDATE_PRODUCT_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: error?.error?.detail?.exception[0]
            });
        }
    })
}

function* createProduct() {
    yield takeEvery(actionProduct.CREATE_PRODUCT, function* (payload) {
        try {
            const response = yield call(() => factory.createProduct(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionProduct.CREATE_PRODUCT_SUCCESS,
                    payload: response
                })
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.message,
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield put({
                    type: actionProduct.CREATE_PRODUCT_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: error?.error?.detail?.exception[0]
            });            yield put({
                type: actionProduct.CREATE_PRODUCT_FAILED,
                payload: error
            })
        }
    })
}

function* deleteProduct() {
    yield takeEvery(actionProduct.DELETE_PRODUCT, function* (payload) {
        try {
            const response = yield call(() => factory.deleteProduct(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionProduct.DELETE_PRODUCT_SUCCESS
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.message);
                yield put({
                    type: actionProduct.DELETE_PRODUCT_FAILED,
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed();
            yield put({
                type: actionProduct.DELETE_PRODUCT_FAILED
            });
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

function* getDetailProduct() {
    yield takeEvery(actionProduct.GET_DETAIL_PRODUCT, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailProduct(payload?.payload));
            yield put({
                type: actionProduct.GET_DETAIL_PRODUCT_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}

function* getDetailProductPopup() {
    yield takeEvery(actionProduct.GET_DETAIL_PRODUCT_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailProduct(payload?.payload));
            yield payload?.callback(response?.data);
        } catch (error) {

        }
    })
}

function* importOrder() {
    yield takeEvery(actionProduct.UPLOAD_FILE_PRODUCT, function* (payload) {
        try {
            const response = yield call(() => factory.importProduct(payload?.payload));
            yield payload?.callback?.success(response, null);
        }
        catch (e) {
            payload?.callback?.success && payload?.callback?.success(null, e)
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getListProduct),
        fork(updateProduct),
        fork(createProduct),
        fork(deleteProduct),
        fork(getDetailProduct),
        fork(getDetailProductPopup),
        fork(getListProductPopup),
        fork(getCategory),
        fork(importOrder),
    ])
}