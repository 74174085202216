import React from 'react';
import classes from './FormSearch.module.scss';

function FormItem(props) {
    return <div style={{
        maxWidth: props?.maxWidth,
        minWidth: props?.minWidth,
    }} className={`${classes['FormItem']} FormItem`}>{props?.children}</div>;
}

export default FormItem;
