import React from "react";
import useTrans from "hooks/use-trans";
import Box from "shared/components/form-input/box/Box";
import classes from "./FreightCalculationManagementContainer.module.scss";
import FormInput from 'shared/components/custom-form/FormInput';
import BoxBody from 'shared/components/common/box/BoxBody';
import CustomBox from 'shared/components/common/box/Box';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import Validator from 'utils/Validator';

const FreightCalculationManagementContainer = (props) => {
    const { appendGlobalVariable, fieldsGlobalVariable, removeGlobalVariable } = props
    const { t } = useTrans()
    return (
        <Box className={classes["freight-calculation-management"]}>
            <div className={classes["title-input-item"]}>
                <p>
                    {t('fare_calculation.list_station_constant')}
                </p>
                <div className={classes['icon-plus']} style={{ cursor: "pointer" }} onClick={() => {
                    appendGlobalVariable({
                        id: "",
                        name: "",
                        key: "",
                        value: "",
                        remark: "",
                        isGlobal: "",
                        serviceId: "",
                        idCreated: fieldsGlobalVariable?.length + 1
                    })
                }}>
                    <IconPlusWhite />
                </div>
            </div>
            <CustomBox>
                <BoxBody padding={0}>
                    {
                        fieldsGlobalVariable?.length !== 0 &&
                        <div className={classes['custom-table-edit-estimate']}>
                            <table>
                                <thead>
                                    <tr className={classes['form-header']} >
                                        <th className={''}>{t("fare_calculation.name")}</th>
                                        <th className={''}>{t("fare_calculation.key")}</th>
                                        <th className={''}>{t("fare_calculation.value")}</th>
                                        <th className={''}>{t("fare_calculation.remark")}</th>
                                        <th className={''}>{t("Holon_Template.delete")} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fieldsGlobalVariable.map((x, key) => {
                                            return <tr key={x?.id} className={classes['form-body']} index={key}>
                                                <td>
                                                    <FormInput validationFE validate={[Validator.required]} isRegexLabel={t("fare_calculation.name")} fieldName={`fareCalcConstants.${key}.name`} />
                                                </td>
                                                <td>
                                                    <FormInput validationFE validate={[Validator.required]} isRegexLabel={t("fare_calculation.key")} fieldName={`fareCalcConstants.${key}.key`} />
                                                </td>
                                                <td>
                                                    <FormInput validationFE validate={[Validator.required, Validator.number]} isRegexLabel={t("fare_calculation.value")} fieldName={`fareCalcConstants.${key}.value`} />
                                                </td>
                                                <td>
                                                    <FormInput validationFE validate={[Validator.required]} isRegexLabel={t("fare_calculation.remark")} fieldName={`fareCalcConstants.${key}.remark`} />
                                                </td>
                                                <td style={{ width: ' 50px', cursor: 'pointer' }} className={classes['button-action-remove']} onClick={() => removeGlobalVariable(key)}>
                                                    <IconMinusAccordion />
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </BoxBody>
            </CustomBox>
        </Box>
    );
}

export default FreightCalculationManagementContainer;
