import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionWareHouse from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";
import { showPopupNotification } from "utils/Utils";

function* getListWarehouse() {
    yield takeEvery(actionWareHouse.GET_WAREHOUSE_LIST, function* (payload) {
        try {
            const response = yield call(() => factory.getListWarehouse(payload?.payload));
            const data = response?.records?.map(x => {
                x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                return x;
            });
            yield put({
                type: actionWareHouse.GET_WAREHOUSE_LIST_SUCCESS,
                payload: {
                    ...response,
                    records: data
                }
            })
        } catch (error) {

        }
    })
}

function* updateListWarehouse() {
    yield takeEvery(actionWareHouse.UPDATE_WAREHOUSE, function* (payload) {
        try {
            const response = yield call(() => factory.updateWarehouse(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionWareHouse.UPDATE_WAREHOUSE_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield put({
                    type: actionWareHouse.UPDATE_WAREHOUSE_FAILED,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: error?.error?.detail?.exception[0]
            })
            yield payload?.callback?.failed && payload?.callback?.failed();
        }
    })
}

function* createWarehouse() {
    yield takeEvery(actionWareHouse.CREATE_WAREHOUSE, function* (payload) {
        try {
            const response = yield call(() => factory.createWarehouse(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionWareHouse.CREATE_WAREHOUSE_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                yield put({
                    type: actionWareHouse.CREATE_WAREHOUSE_FAILED,
                })
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: error?.error?.detail?.exception[0]
            })
            yield payload?.callback?.failed && payload?.callback?.failed();
        }
    })
}

function* deleteWarehouse() {
    yield takeEvery(actionWareHouse.DELETE_WAREHOUSE, function* (payload) {
        try {
            const response = yield call(() => factory.deleteWarehouse(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionWareHouse.DELETE_WAREHOUSE_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                yield put({
                    type: actionWareHouse.DELETE_WAREHOUSE_FAILED,
                })
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            yield put({
                type: actionWareHouse.DELETE_WAREHOUSE_FAILED,
            })

            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: error?.error?.detail?.exception[0]
            })
            yield payload?.callback?.failed && payload?.callback?.failed();
        }
    })
}

function* getWarehouseDetail() {
    yield takeEvery(actionWareHouse.GET_WAREHOUSE_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailWarehouse(payload?.payload));
            if(response?.data?.id){
                yield payload?.callback?.success && payload?.callback?.success(response?.data);
            }
            yield put({
                type: actionWareHouse.GET_WAREHOUSE_DETAIL_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* getHolonTemplateWarehouse() {
    yield takeEvery(actionWareHouse.GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplateDetail());
            yield put({
                type: actionWareHouse.GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListWarehouse),
        fork(updateListWarehouse),
        fork(createWarehouse),
        fork(deleteWarehouse),
        fork(getWarehouseDetail),
        fork(getHolonTemplateWarehouse),
    ])
}