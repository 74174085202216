import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import useTrans from 'hooks/use-trans';
import Constants from 'utils/Constants';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister'
import MenuManagementAction from 'redux/menu-management/action';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import Button from 'shared/components/form-input/button/Button';
import Validator from 'utils/Validator';
import WrapContentBody from 'shared/components/common/popup/custom-body/WrapContentBody';
import BoxContentPopup from 'shared/components/common/popup/custom-body/BoxContentPopup';
import CustomBox from 'shared/components/common/box/Box';
import BoxHeader from 'shared/components/common/box/BoxHeader';
import BoxTitle from 'shared/components/common/box/BoxTitle';
import BoxBody from 'shared/components/common/box/BoxBody';
import BoxRow from 'shared/components/common/box/BoxRow';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import FormInput from 'shared/components/custom-form/FormInput';
import styles from './HexMenuPopup.module.scss'

const UpdateHexMenuPopup = (props) => {
    const { showVisible, payload } = props;
    const dispatch = useDispatch();
    const { t } = useTrans();
    const { menuMaster, loadingUpdate } = useSelector(state => state?.menuManagement)
    const methods = useForm();

    useEffect(() => {
        if (payload?.menu?.code) {
            dispatch({
                type: MenuManagementAction.GET_MENU_MANAGEMENT_BY_CODE,
                payload: payload?.menu?.code,
            })
        }
    }, [payload?.menu?.code])

    useEffect(() => {
        if (menuMaster) {
            methods.reset(menuMaster);
        }
    }, [menuMaster])

    const onSubmit = (data) => {
        dispatch({
            type: MenuManagementAction.CREATE_MENU_MANAGEMENT,
            payload: data,
            callback: {
                success: () => {
                    payload?.callback();
                    showVisible(false);
                },
                failed: () => {
                    EventRegister.emit(EVENT_SHOW_POPUP, {
                        open: true,
                        type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                        newWindow: true,
                        payload: {
                            title: t("system_error"),
                            showHeader: false,
                            customStyle: {
                                padding: 0,
                                borderTop: 0
                            },
                        }
                    })
                }
            }
        })
    }

    return (
        <>
            <BoxContentPopup>
                <CustomFormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <WrapContentBody
                            title={props?.payload?.title}
                            showVisible={showVisible}
                            actionSectionBottom={<Button color='gray' loading={loadingUpdate} type='submit' title={t('save')} />}
                        >
                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("menu_management.basic_information")}</BoxTitle>
                                </BoxHeader>
                                <BoxBody>
                                    <BoxRow>
                                        <BoxLabel minWidth="160px">{t("menu_management.menu_name_jp")}</BoxLabel>
                                        <FormInput
                                            fieldName='menuDesc'
                                            placeholder={t("menu_management.menu_name_jp")}
                                            validate={[Validator.required]} 
                                            validationFE
                                            isRegexLabel={t("menu_management.menu_name_jp")}
                                        />
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="160px">{t("menu_management.menu_name_en")}</BoxLabel>
                                        <FormInput
                                            fieldName='menuDescEN' 
                                            placeholder={t("menu_management.menu_name_en")}
                                            validate={[Validator.required]} 
                                            validationFE
                                            isRegexLabel={t("menu_management.menu_name_en")}
                                        />
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="160px">{t("menu_management.menu_name_cn")}</BoxLabel>
                                        <FormInput
                                            fieldName='menuDescCN' 
                                            placeholder={t("menu_management.menu_name_cn")}
                                            validate={[Validator.required]} 
                                            validationFE
                                            isRegexLabel={t("menu_management.menu_name_cn")}
                                        />
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="160px">{t("menu_management.url")}</BoxLabel>
                                        <FormInput
                                            fieldName='href'
                                            placeholder={t("menu_management.url")}
                                            validate={[Validator.required]} 
                                            validationFE
                                            isRegexLabel={t("menu_management.url")}
                                        />
                                    </BoxRow>
                                </BoxBody>
                            </CustomBox>
                        </WrapContentBody>
                    </form>
                </CustomFormProvider>
            </BoxContentPopup>
        </>
    )
};

export default UpdateHexMenuPopup;