import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, Controller, useFormContext } from "react-hook-form"
import _ from 'lodash';
import styles from './Tabs.module.scss'
import Constants from 'utils/Constants';
import actionHolon from 'redux/holon/action';
import actionUser from 'redux/user/action';
import useTrans from 'hooks/use-trans';
import Button from 'shared/components/form-input/button/Button';
import FieldAccountSetting from 'shared/components/form/field-account-setting/FieldAccountSetting';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import ElementType from 'utils/ElementType';

function AlarmTab(props) {
    const { } = props
    const dispatch = useDispatch();
    const { trans } = useTrans();
    const methods = useForm();
    const { register, handleSubmit, setValue, reset, watch, getValues, setError, clearErrors } = methods;
    const { templates } = useSelector(state => state.holon);
    const { user, user_id, hex_id, status_user, updateUserError, loading: {loadingUpdateAccount} } = useSelector(state => state.user);
    const masterCode = [Constants.HOLON_MASTER_CODE.USER_TEMPLATE];
    const [dataDefault, setDataDefault] = useState({})
    // const dataDefault = useRef({})

    useEffect(() => {
        return () => {
            dispatch({
                type: actionUser.CLEAR_UPDATE_ACCOUNT_ERROR,
            });
            dispatch({
                type: actionHolon.CLEAR_HOLON_TEMPLATES,
            })
        }
    }, []);

    // useEffect(() => {
    //     if (user?.templateId) {
    //         dispatch({
    //             type: actionHolon.GET_HOLON_TEMPLATE,
    //             holonType: user?.templateId
    //         })
    //     }
    // }, [user]);

    useEffect(() => {
        if (user?.id) {
            dispatch({
                type: actionHolon.GET_HOLON_TEMPLATES_IN_USER_BY_MASTER_CODES,
                payload: {
                    masterCode: masterCode.join(','),
                    user: user
                },
            })
        }
    }, [user]);

    useEffect(() => {
        if (updateUserError) {
            Object.keys(updateUserError).forEach((key) => {
                setError(key, {
                    type: 'custom',
                    message: updateUserError[key]?.[0]
                })
            })
        }
    }, [updateUserError]);

    // useEffect(() => {
    //     const formData = {};
    //     user?.attributes?.map((attribute) => {
    //         const att = template?.attributes?.find((att, index) => attribute?.name == att?.key && att?.key?.startsWith("setting"))
    //         if (att && attribute?.value != null && attribute?.value != '') {
    //             formData[`${attribute?.name}`] = attribute?.value;
    //         }
    //     })

    //     reset(formData);

    // }, [user, template]);

    useEffect(() => {
        const formData = {};
        const templateIds = [];
        templates.map(template => {
            templateIds.push(template.id);
        })
        if (masterCode.join(',').includes(Constants.HOLON_MASTER_CODE.USER_TEMPLATE)) {
            user?.attributes?.map((attribute) => {
                for (const template of templates) {
                    const att = template?.attributes?.find((att, index) => attribute?.name == att?.key && att?.key?.startsWith("setting")) 
                    if (att && attribute?.value != null && attribute?.value != '' && attribute?.value != undefined) {
                        // formData[`${attribute?.name}`] = attribute?.value;
                        if ((att?.elementType == ElementType.CHECKBOX || att?.elementType == ElementType.RADIO || att?.elementType == ElementType.SELECT) && (attribute?.value == 'null' || attribute?.value == 'undefined')) {
                            continue;
                        } else {
                            formData[`${attribute?.name}`] = attribute?.value;
                        }
                    }
                }
            })
        }

        // user?.connections?.map((connection) => {
        //     connection?.holons?.[0]?.attributes?.map((attribute) => {
        //         for (const template of templates) {
        //             const att = template?.attributes?.find((att, index) => attribute?.name == att?.key)
        //             if (att && template?.id == connection?.holons?.[0]?.templateId && attribute?.value != null && attribute?.value != '') {
        //                 formData[`${connection?.holons?.[0]?.templateId}__${attribute?.name}`] = attribute?.value;
        //             }
        //         }
        //     })
        // })
        formData[`template_id`] = templateIds.join(',');

        // dataDefault.current = formData
        setDataDefault(formData)

        reset(formData);

    }, [user, templates]);

    const onSubmit = (data) => {
        const formData = [];
        Object?.keys(data).forEach((key, index) => {
            formData.push({
                name: key,
                value: data[key],
                description: localStorage.getItem(key) ?? ""
            });
            localStorage.removeItem(key);
        })
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            newWindow: true,
            payload: {
                title: trans("E0042"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionUser.UPDATE_ALARM_ACCOUNT,
                        payload: {
                            user_id: user_id,
                            hex_id: hex_id,
                            data: formData,
                        },
                        callback: {
                            success: () => {
                                // getToast(trans('successful_update'));
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: trans("E0043"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                                dispatch({
                                    type: actionUser.GET_ACCOUNT,
                                })
                            },
                            failed: () => {
                                // getToast(trans('failed_update'), 'error');
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: trans("system_error"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    const watchFormData = methods.watch()
    // let isEdited = _.isEqual(dataDefault.current, watchFormData) ? false : true;
    let isEdited = _.isEqual(dataDefault, watchFormData) ? false : true;

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {
                        templates?.length > 0 &&
                        <div className="container-fluid">
                            {
                                templates?.map((template, i) =>
                                    template && template?.attributes?.map((field, index) => {
                                        return field && (field?.key?.startsWith("setting")) &&
                                            <FieldAccountSetting
                                                key={index}
                                                template={template?.id}
                                                field={field}
                                                register={register}
                                                setValue={setValue}
                                                defaultValue={getValues(`${field?.key}`)}
                                                watch={watch}
                                                getValues={getValues}
                                                clearErrors={clearErrors}
                                                attributeKey={field?.key}
                                            />
                                    })
                                )
                            }
                            <div className={styles['profile-submit-btn-end']}>
                                {/* <button className={styles['profile-btn']} type="submit">{trans('account_settings.keep_btn')}</button> */}
                                <Button color='lightblue' disabled={!isEdited} loading={loadingUpdateAccount} type='submit' title={trans('keep')} />
                            </div>
                        </div>
                    }
                </form>
            </FormProvider>
        </>
    );
}

export default AlarmTab;