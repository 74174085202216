import React, { useEffect, useState } from 'react'
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_LIST_HOLON_INSTANCE } from 'utils/EventRegister'
import classes from './SelectInstance.module.scss';
import Button from '../button/Button';
import IconEdit from 'assets/icon/IconEdit';
import useTrans from 'hooks/use-trans';
import _ from 'lodash';
import ErrorMessage from '../error-message/ErrorMessage';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';
import jsonPath from 'jsonpath';
import Constants from 'utils/Constants';

export default function SelectInstance(props) {
    const { value, onChange, holonTemplate, disabled = false,
        inputRef, isMulti = true, isRegexLabel, validationFE, errors, name,
        id, connectionKey , isSelectForConnection} = props;
    const [ValueInstance, setValueInstance] = useState([]);
    const { t } = useTrans();
    const handleOpenInstance = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_LIST_HOLON_INSTANCE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: (data) => {
                    onChange && onChange(data ?? []);
                    setValueInstance(data ?? [])
                },
                // Value : List id attribute
                value: value ? value?.map(x => x?.id) : [],
                // List holoTemplateId
                ListHolonTemplate: holonTemplate,
                title: t("holon.holon_instance_selection"),
                isMulti: isMulti,
                [Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]: connectionKey,
                [Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]: id,
                [Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]: isSelectForConnection,
            }
        })
    }

    useEffect(() => {
        if (value) {
            setValueInstance(value)
        } else {
            setValueInstance([])
        }
    }, [value])

    let showError = false;
    let error = {
        message: '',
    };

    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    const getConnectionName = (holon) => {
        // var name = holon?.id;
        // if(holon?.attributes?.length > 0) {
        //     name = jsonPath.query(holon, "$.attributes[?(@.name == 'name')].value");
        // }
        return holon?.name;
    }
    return (
        <>
            <div
                className={`${classes['SelectTemplate']} SelectTemplate`}>
                <div className={classes['template-row']}>
                    {
                        ValueInstance?.length > 0 ? [...ValueInstance]?.slice(0, 3)?.map((x, key) => {
                            return <div title={x?.name ?? x?.id} key={key} className={`${classes['template-item']} template-item`}>
                                <p>{getConnectionName(x)}</p>
                            </div>
                        }) : <></>
                    }
                    {
                        ValueInstance?.length > 3 && <div className={classes['button-readmore']}>&#8230;</div>
                    }
                </div>
                <Button refButton={inputRef} disabled={disabled} onClick={handleOpenInstance} startIcon={<IconEdit />} />
            </div>
            {showError ? (
                <div className={classes['Custom_Select_Error_Message']}>
                    {
                        !validationFE ? (<ErrorMessage message={error?.message} />) : (
                            isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                        )
                    }
                </div>) : (
                <></>
            )}
        </>
    )
}
