import React, { useEffect } from 'react'
import classes from './FaqCategory.module.scss';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FaqAction from 'redux/faq/action';
import IconEdit from 'assets/icon/IconEdit';
import IconDelete from 'assets/icon/IconDelete';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_POPUP_UPDATE_FAQ_CATEGORY } from 'utils/EventRegister';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import { Close } from '@mui/icons-material';
import FormSelect from 'shared/components/custom-form/FormSelect';
import useCustomState from 'hooks/use-custom-state';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import FormInput from 'shared/components/custom-form/FormInput';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import WrapContentBody from '../custom-body/WrapContentBody';
import CustomPagination from '../../custom-pagination/CustomPagination';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import BoxContentPopup from '../custom-body/BoxContentPopup';

function FaqCategoryPopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const methods = useForm({
        defaultValues: {
            lang: null
        }
    });
    const router = useRouter();
    const dispatch = useDispatch();
    const { faqCategoryPopup, loadingAddCategoryFaq, loadingUpdateCategoryFaq, loadingDeleteCategoryFaq } = useSelector(state => state.faq);
    const [optionLanguages, setOptionLanguages] = useCustomState([]);

    useEffect(() => {
        dispatch({
            type: FaqAction.GET_FAQ_CATEGORIES_POPUP,
            payload: {
                [Constants.QueryParams.LANGUAGE.VALUE]: Constants.T001.JP.CODE
            }
        });

        methods.setValue("lang", Constants.T001.JP.CODE)
    }, [
    ])

    const handleSearchFaqCategory = (e) => {
        dispatch({
            type: FaqAction.GET_FAQ_CATEGORIES_POPUP,
            payload: {
                [Constants.QueryParams.LANGUAGE.VALUE]: e
            }
        })
    }


    const onSubmit = (_data) => {
        if (!_data?.categoryName) {
            return;
        }
        dispatch({
            type: FaqAction.ADD_FAQ_CATEGORY,
            payload: {
                ..._data,
                status: _data?.status ? Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE : Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE,
                sort: 1
            },
            callback: {
                success: () => {
                    methods.setValue("categoryName", "");
                    let params = {};
                    if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
                        params['lang'] = router.get(Constants.QueryParams.LANGUAGE.VALUE);
                    } else {
                        params['lang'] = Constants.T001.JP.CODE;
                    }
                    if (router.get(Constants.QueryParams.RELEASE.VALUE)) {
                        params['status'] = router.get(Constants.QueryParams.RELEASE.VALUE) == 1 ? Constants.STATUS_CATEGORY.PUBLIC.VALUE : Constants.STATUS_CATEGORY.PRIVATE.VALUE;
                    }
                    if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
                        dispatch({
                            type: FaqAction.GET_FAQ_CATEGORIES,
                            payload: {
                                isAll: true,
                                ...params
                            }
                        })
                    }

                    payload?.callback && payload?.callback()
                },
                failed: () => {
                }
            },
        });
    }

    const handleOpenEditCategory = (id) => {
        dispatch({
            type: FaqAction.GET_DETAIL_FAQ_CATEGORIES,
            payload: {
                id: id
            }
        })
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_UPDATE_FAQ_CATEGORY,
            newWindow: true,
            payload: {
                id,
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                title: t("update_faq_category"),
                callback: () => {
                    dispatch({
                        type: FaqAction.GET_FAQ_CATEGORIES_POPUP,
                        payload: {
                            [Constants.QueryParams.LANGUAGE.VALUE]: methods.watch("lang")
                        }
                    })

                    payload?.callback && payload?.callback()

                    let params = {};
                    if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
                        params['lang'] = router.get(Constants.QueryParams.LANGUAGE.VALUE);
                    } else {
                        params['lang'] = Constants.T001.JP.CODE;
                    }
                    if (router.get(Constants.QueryParams.RELEASE.VALUE)) {
                        params['status'] = router.get(Constants.QueryParams.RELEASE.VALUE) == 1 ? Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE : Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE;
                    }
                    if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
                        dispatch({
                            type: FaqAction.GET_FAQ_CATEGORIES,
                            payload: {
                                isAll: true,
                                ...params
                            }
                        })
                    }
                }
            }
        })
    }
    const handleDeleteCategory = (id, lang) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            newWindow: true,
            payload: {
                // title: t("Holon_Template.confirm_delete"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: FaqAction.DELETE_FAQ_CATEGORY,
                        payload: {
                            id,
                            lang: methods.watch("lang") ?? null
                        },
                        callback: {
                            success: () => {
                                let params = {};
                                if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
                                    params['lang'] = router.get(Constants.QueryParams.LANGUAGE.VALUE);
                                } else {
                                    params['lang'] = Constants.T001.JP.CODE;
                                }
                                if (router.get(Constants.QueryParams.RELEASE.VALUE)) {
                                    params['status'] = router.get(Constants.QueryParams.RELEASE.VALUE) == 1 ? Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE : Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE;
                                }
                                if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
                                    dispatch({
                                        type: FaqAction.GET_FAQ_CATEGORIES,
                                        payload: {
                                            isAll: true,
                                            ...params
                                        }
                                    })
                                }

                                payload?.callback && payload?.callback()
                            },
                            failed: (message) => {
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: t("Holon_Template.system_error"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                            }
                        },
                    })
                }
            }
        })
    }

    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: t(`faq_management.lang.${Constants.T001[key].NAME}`),
            })
        });
        setOptionLanguages(data)
    }, [])

    const handleUpdateStatus = (data) => {
        dispatch({
            type: FaqAction.UPDATE_FAQ_CATEGORY,
            payload: {
                ...data,
                status: data?.status == "0" ? "1" : "0"
            },
            callback: {
                success: () => {
                    dispatch({
                        type: FaqAction.GET_FAQ_CATEGORIES_POPUP,
                        payload: {
                            lang: methods.watch("lang") ?? null
                        }
                    })
                    let params = {};
                    if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
                        params['lang'] = router.get(Constants.QueryParams.LANGUAGE.VALUE);
                    } else {
                        params['lang'] = Constants.T001.JP.CODE;
                    }
                    if (router.get(Constants.QueryParams.RELEASE.VALUE)) {
                        params['status'] = router.get(Constants.QueryParams.RELEASE.VALUE) == 1 ? Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE : Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE;
                    }
                    if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
                        dispatch({
                            type: FaqAction.GET_FAQ_CATEGORIES,
                            payload: {
                                isAll: true,
                                ...params
                            }
                        })
                    }
                },
                failed: () => {
                }
            },
            paramsUrl: { ...router.getAll() }
        })
    }

    const onChangePage = (e) => {
        dispatch({
            type: FaqAction.GET_FAQ_CATEGORIES_POPUP,
            payload: {
                [Constants.QueryParams.LANGUAGE.VALUE]: methods.watch("lang"),
                [Constants.QueryParams.OFFSET.VALUE]: e
            }
        })
    }

    function capitalizeFLetter(string = "") {
        string = string?.toLowerCase();
        return string[0].toUpperCase() +
            string.slice(1)
    }

    return (
        <BoxContentPopup >
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        title={payload?.title}
                        showVisible={showVisible}
                        actionSectionBottom={<div className={classes['action-button']}>
                            <div />
                            <CustomPagination onChange={onChangePage} page={faqCategoryPopup?.page} total={faqCategoryPopup?.total} />
                            <Button loading={loadingAddCategoryFaq} disabled={methods.watch("categoryName") ? false : true} type='submit' title={t("faq_management.save")} />
                        </div>}
                    >
                        <div className={classes['FaqManagement-Content']}>
                            {/* <div className={classes['FaqManagement-Heading']}>
                                <p>{t("faq_management.add_new_category")}</p>
                            </div> */}
                            <div className={classes['FaqManagement-FormSearch']}>
                                <div className={classes['Form-Group']}>
                                    <div className={classes['Form-Item_Left']}>
                                        <p>
                                            {t("faq_management.lang_choice")}
                                        </p>
                                    </div>
                                    <div className={classes['Form-Item-Center']}>
                                        <FormSelect
                                            fieldName="lang"
                                            placeholder={t("lang")}
                                            background="#FFFFFF"
                                            options={optionLanguages}
                                            onChangeSelect={(e) => {
                                                handleSearchFaqCategory(e)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes['FaqManagement_body']}>
                                <div className={classes['FaqManagement_table']}>
                                    <div className={classes['row-header']}>
                                        <div className={classes['row-number']}><p>No</p></div>
                                        <div className={classes['row-lang']}><p>{t('faq_management.language')}</p></div>
                                        <div className={classes['row-name']}><p>{t('faq_management.category_name')}</p></div>
                                        <div className={classes['row-release']}><p>{t('faq_management.release')}</p></div>
                                        <div className={classes['row-release']}></div>
                                    </div>
                                    {
                                        faqCategoryPopup?.records?.map((x, index) => {
                                            return <div key={index} className={classes['row-table']}>
                                                <div className={classes['row-number']}><p>{index + 1}</p></div>
                                                <div className={classes['row-lang']}><p>{t(`faq_management.lang.${capitalizeFLetter(x?.lang)}`)}</p></div>
                                                <div className={classes['row-name']}><p>{x?.categoryName}</p></div>
                                                <div className={classes['row-release']}>
                                                    {x?.status == Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE ?
                                                        <Button onClick={() => handleUpdateStatus(x)} color='white' startIcon={<IconChecked />} />
                                                        : <Button onClick={() => handleUpdateStatus(x)} color='white' startIcon={<IconCheck />} />}
                                                </div>
                                                <div className={classes['row-release']}>
                                                    <div className={classes['row-action']}>
                                                        <div className={classes['btn-action']} onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenEditCategory(x?.id)
                                                        }}>
                                                            <IconEdit color="#0E525E" fontSize={18} />
                                                        </div>
                                                        <div className={classes['btn-action']} onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteCategory(x?.id, x?.lang)
                                                        }}><IconMinusAccordion color="#929292" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <div className={classes['row-add']}>
                                        <div className={classes['row-number']}></div>
                                        <div className={classes['row-name']}>
                                            <FormInput placeholder={`⊕${t("faq_management.add_new_category")}`} background='#FFFFFF' fieldName='categoryName' />
                                        </div>
                                        <div className={classes['row-release']}>
                                            <FormCheckbox fieldName='status' />
                                        </div>
                                        <div className={classes['row-release']}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default FaqCategoryPopup;