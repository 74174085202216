import React, { useCallback, useEffect, useRef, useState } from 'react'
import classes from './MessageContainer.module.scss';
import FormSearchUser from 'shared/components/message/form-search-user/FormSearchUser';
import ListChannel from 'shared/components/message/message-list/ListChannel';
import ContentMessage from 'shared/components/message/content-message/ContentMessage';
import IconAddChannel from 'assets/icon/IconAddChannel';
import { useDispatch, useSelector } from 'react-redux';
import { actionMessage } from 'redux/message/action';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import EventRegister, { EVENT_SHOW_LIST_CHANNEL_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_USER_LIST_POPUP } from 'utils/EventRegister';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import useWindowSize from 'hooks/use-window-size';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import CustomTooltip from 'shared/components/common/tooltip/CustomTooltip';
import useTrans from 'hooks/use-trans';

function MessageContainer() {
    const { listChannel } = useSelector(state => state?.message);

    const router = useRouter();
    const { t } = useTrans();
    const dispatch = useDispatch();
    const refChannel = useRef(false);
    const refChannelInfoFirst = useRef(false);
    const refChannelLoadMore = useRef(false);
    const refMessageInterval = useRef(false);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [channelInfo, setChannelInfo] = useState(null);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [isDataCompany, setIsDataCompany] = useState(false);
    const methodsChannel = useForm();
    const methodsFilterMessage = useForm();

    const { height } = useWindowSize();
    const limitChannel = parseInt(height / 60);

    useEffect(() => {
        dispatch({
            type: actionMessage.SYNC_USER,
            payload: {},
            callback: () => {
                setDisabledBtn(false)
            }
        })

        dispatch({
            type: actionsHolonInstance.GET_USER_IN_COMPANY,
            payload: {}
        });

        dispatch({
            type: actionMessage.REQUEST_SYNC_CHANNEL,
            payload: {
                [Constants.QueryParams.LIMIT.VALUE]: limitChannel
            },
            callback: {
                success: () => {
                    refChannel.current = true;
                    refChannelLoadMore.current = true;
                }
            }
        })

        let userInCompany = localStorage.getItem("user-in-company");
        userInCompany = userInCompany ? JSON.parse(userInCompany) : null

        dispatch({
            type: actionMessage.GET_LIST_USER_COMPANY,
            payload: {
                [Constants.QueryParams.LIMIT.VALUE]: Constants.PAGINATION.LIMIT,
                [Constants.QueryParams.USER_IN_COMPANY.VALUE]: userInCompany
            },
            callback: {
                success: (data) => {
                    if (data?.total > 0) {
                        setIsDataCompany(true);
                    }
                },
                failed: () => {
                }
            },
        })

    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (refChannel.current) {
                dispatch({
                    type: actionMessage.REQUEST_SYNC_CHANNEL,
                    payload: {
                        limit: listChannel?.limit
                    },
                    callback: {
                        success: () => {
                            refChannel.current = true;
                        },
                        failed: () => {
                            refChannel.current = false;
                        }
                    }
                })
            }
        }, 5000);

        if (!refChannel.current) {
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [refChannel, listChannel?.records, listChannel?.limit])

    useEffect(() => {
        if (listChannel?.records?.length > 0 && router.get(Constants.QueryParams.CHANNEL.VALUE)) {
            setSelectedChannel(router.get(Constants.QueryParams.CHANNEL.VALUE))
        }
    }, [listChannel, router.get(Constants.QueryParams.CHANNEL.VALUE)])

    useEffect(() => {
        if (listChannel?.records?.length > 0 && router.get(Constants.QueryParams.CHANNEL.VALUE) && !refChannelInfoFirst.current) {
            refChannelInfoFirst.current = true;
            setChannelInfo(listChannel?.records?.find(x => x?.id == router.get(Constants.QueryParams.CHANNEL.VALUE)));
        }
    }, [listChannel, router.get(Constants.QueryParams.CHANNEL.VALUE), refChannelInfoFirst])

    useEffect(() => {
        if (router.get(Constants.QueryParams.CHANNEL.VALUE)) {
            dispatch({
                type: actionMessage.REQUEST_GET_FIRST_MESSAGE,
                payload: {
                    channelId: router.get(Constants.QueryParams.CHANNEL.VALUE),
                    date: moment(new Date()).format("YYYY-DD-MM"),
                    messageFilter: "",
                    lastMessageId: null
                },
                callback: {
                    success: () => {
                        refMessageInterval.current = true;
                    },
                    failed: () => {
                        refMessageInterval.current = false;
                    }
                }
            })
        }
    }, [router.get(Constants.QueryParams.CHANNEL.VALUE)])

    const handleSyncChannel = () => {
        refChannel.current = false;
        dispatch({
            type: actionMessage.REQUEST_SYNC_CHANNEL,
            payload: {
                limit: listChannel?.limit
            },
            callback: {
                success: () => {
                    refChannel.current = true;
                }
            }
        })
    }

    const handleSelectChannel = (e) => {
        if (e == router.get(Constants.QueryParams.CHANNEL.VALUE)) {
            return;
        }
        dispatch({
            type: actionMessage.RESET_GET_MESSAGE_SUCCESS,
            payload: {
                listMessage: [],
            },
        });
        methodsChannel.setValue("search_channel", "");
        router.replace({
            params: {
                [Constants.QueryParams.CHANNEL.VALUE]: e
            }
        });
        refChannel.current = true;
        dispatch({
            type: actionMessage.REQUEST_SYNC_CHANNEL,
            payload: {
                limit: listChannel?.limit
            },
        });

        setChannelInfo(listChannel?.records?.find(x => x?.id == e));
        methodsFilterMessage.setValue("search_message", "");
    }

    const handleCreatedChannel = (_isDataCompany) => {

        let userInCompany = localStorage.getItem("user-in-company");
        userInCompany = userInCompany ? JSON.parse(userInCompany) : null

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_USER_LIST_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                isDataCompany: _isDataCompany,
                userInCompany: userInCompany,
                callback: (data) => {
                    let dataUser = data?.map(x => ({ id: x?.id }));
                    dispatch({
                        type: actionMessage.CREATE_CHANNEL,
                        payload: {
                            id: 0,
                            name: "",
                            status: "",
                            users: dataUser
                        },
                        callback: {
                            success: (e) => {
                                dispatch({
                                    type: actionMessage.REQUEST_SYNC_CHANNEL,
                                    payload: {
                                        limit: listChannel?.limit + 1
                                    },
                                    callback: {
                                        success: (_listChannel) => {
                                            setChannelInfo(_listChannel?.records?.find(x => x?.id == e));
                                        }
                                    }
                                })
                                router.replace({
                                    params: {
                                        [Constants.QueryParams.CHANNEL.VALUE]: e
                                    }
                                })

                                setChannelInfo(listChannel?.records?.find(x => x?.id == e));
                            },
                            failed: () => {

                            },
                        }
                    })
                }
            }
        })
    }

    const onSearchChannel = useCallback(
        debounce(async (e) => {
            if (e) {
                refChannel.current = false
            } else {
                refChannel.current = true
            }
            setKeyword(e)
            dispatch({
                type: actionMessage.REQUEST_SYNC_CHANNEL,
                payload: {
                    keyword: e,
                    limit: listChannel?.limit
                }
            })
        }, 500),
        [],
    );

    const addUserToChannel = (channelId) => {
        let userInCompany = localStorage.getItem("user-in-company");
        userInCompany = userInCompany ? JSON.parse(userInCompany) : null
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_USER_LIST_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                userInCompany: userInCompany,
                channelId: router.get(Constants.QueryParams.CHANNEL.VALUE),
                isDataCompany: isDataCompany,
                callback: (data) => {
                    refChannel.current = false;
                    let dataUser = data?.map(x => ({ id: x?.id }));

                    dispatch({
                        type: actionMessage.ADD_USER_CHANNEL,
                        payload: {
                            id: channelId,
                            users: dataUser
                        },
                        callback: {
                            success: () => {
                                dispatch({
                                    type: actionMessage.REQUEST_SYNC_CHANNEL,
                                    payload: {
                                        limit: listChannel?.limit
                                    },
                                    callback: {
                                        success: (data) => {
                                            refChannel.current = true;
                                            setChannelInfo(data?.records?.find(x => x?.id == channelId));
                                        }
                                    }
                                })
                                router.replace({
                                    params: {
                                        [Constants.QueryParams.CHANNEL.VALUE]: channelId
                                    }
                                })
                            },
                            failed: () => {
                                refChannel.current = true;
                            }
                        }
                    })
                }
            }
        })
    }

    const loadMoreChannel = (nextOffset, dataPost, type, callback) => {
        if (refChannelLoadMore.current) {
            if (refChannel.current) {
                refChannel.current = false;
                dispatch({
                    type: actionMessage.LOAD_MORE_CHANNEL,
                    payload: {
                        [Constants.QueryParams.OFFSET.VALUE]: nextOffset + 1,
                        [Constants.QueryParams.LIMIT.VALUE]: Constants.PAGINATION.LIMIT_MESSAGE,
                        keyword: keyword,
                    },
                    callback: () => {
                        refChannel.current = true;
                        callback();
                    }
                });
            } else {
                dispatch({
                    type: actionMessage.LOAD_MORE_CHANNEL,
                    payload: {
                        [Constants.QueryParams.OFFSET.VALUE]: nextOffset + 1,
                        [Constants.QueryParams.LIMIT.VALUE]: Constants.PAGINATION.LIMIT_MESSAGE,
                        keyword: keyword,
                    },
                    callback: () => {
                        callback();
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (router.get("userId")) {
            let dataUser = [{ id: router.get("userId") }]
            dispatch({
                type: actionMessage.CREATE_CHANNEL,
                payload: {
                    id: 0,
                    name: "",
                    status: "",
                    users: dataUser
                },
                callback: {
                    success: (e) => {
                        dispatch({
                            type: actionMessage.REQUEST_SYNC_CHANNEL,
                            payload: {
                                limit: listChannel?.limit + 1
                            },
                        })
                        router.replace({
                            params: {
                                [Constants.QueryParams.CHANNEL.VALUE]: e
                            }
                        })
                    },
                    failed: () => {

                    },
                }
            })
        }
    }, [
        router.get("userId")
    ])


    const handleForwardMsg = (x) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_LIST_CHANNEL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                isMulti: false,
                callback: (data) => {
                    refChannel.current = false;
                    let dataUser = data;

                    dispatch({
                        type: actionMessage.FORWARD_MESSAGE,
                        payload: {
                            messageId: x?.messageId,
                            channelId: dataUser[0]
                        },
                        callback: {
                            success: (channelId) => {
                                dispatch({
                                    type: actionMessage.REQUEST_SYNC_CHANNEL,
                                    payload: {
                                        limit: listChannel?.limit
                                    },
                                    callback: {
                                        success: (data) => {
                                            refChannel.current = true;
                                            setChannelInfo(data?.records?.find(x => x?.id == channelId));
                                        }
                                    }
                                })
                                dispatch({
                                    type: actionMessage.RESET_GET_MESSAGE_SUCCESS,
                                    payload: {
                                        listMessage: [],
                                    },
                                });
                                router.replace({
                                    params: {
                                        [Constants.QueryParams.CHANNEL.VALUE]: channelId
                                    }
                                })
                            },
                            failed: () => {
                                refChannel.current = true;
                            }
                        }
                    })
                }
            }
        })
    }
    return (
        <div className={classes['MessageContainer']}>
            <div className={classes['list-user']}>
                <div className={classes['list-user-content']}>
                    <div className={classes['form-search-user']}>
                        <FormSearchUser methodsChannel={methodsChannel} searchChannel={onSearchChannel} />
                    </div>
                    <ListChannel loadMoreChannel={loadMoreChannel} selectedChannel={selectedChannel} handleSelectChannel={handleSelectChannel} listChannel={listChannel} />
                    <div style={{
                        pointerEvents: disabledBtn ? 'none' : 'fill'
                    }} onClick={(e) => {
                        e.stopPropagation();
                        handleCreatedChannel(isDataCompany);
                    }} className={classes['button-add-channel']}>
                        <CustomTooltip
                            textTooltip={t("message.create_new_group_chat")}
                            placement='top'
                        >
                            <span>
                                <IconAddChannel />
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            </div>
            <div className={classes['content-message']}>
                <ContentMessage handleForwardMsg={handleForwardMsg} channelInfo={channelInfo} addUserToChannel={addUserToChannel} refMessageInterval={refMessageInterval} methodsFilterMessage={methodsFilterMessage} handleSyncChannel={handleSyncChannel} listChannel={listChannel} selectedChannel={selectedChannel} />
            </div>
        </div>
    )
}

export default MessageContainer