import React from 'react'
import classes from './ProductContainer.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormInput from 'shared/components/custom-form/FormInput';
import { useForm } from 'react-hook-form';
import actionProduct from 'redux/product/action';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import Validator from 'utils/Validator';
import useRouter from 'hooks/use-router';
import RouterPath from 'router/RouterPath';
import FormItem from 'shared/components/common/form-search/FormItem';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import Constants from 'utils/Constants';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';

function CreateProductContainer() {
    const dispatch = useDispatch();
    const router = useRouter();
    const { t } = useTrans();
    const { loadingCreate } = useSelector(state => state.product);
    const methods = useForm({
        defaultValues: {
            item_name: "",
            price: 0,
            manual_update_flag: "",
            gs1_code: ""
        }
    });

    const onSubmit = (data) => {
        const payload = {
            id: 0,
            item_name: data?.item_name,
            price: parseInt(data?.price, 10),
            manual_update_flag: data?.manual_update_flag ? "1" : "0",
            gs1_code: data?.gs1_code,
        }
        dispatch({
            type: actionProduct.CREATE_PRODUCT,
            payload: {
                ...payload
            },
            callback: {
                success: () => {
                    router.push({
                        pathname: RouterPath.PORTAL_PRODUCT
                    })
                },
                failed: () => {

                }
            }
        })
    }

    return (
        <ContentLayout>
            <Breadcrumb>
                {t("product.product_create")}
            </Breadcrumb>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={classes['create-product-container']}>
                        <FormItem>
                            <div>{t("product.product_name")}</div>
                            <FormInput validate={[Validator.required]} fieldName='item_name' />
                        </FormItem>
                        <FormItem>
                            <div>{t("product.product_price")}</div>
                            <FormInput format={Constants.FormInputFormat.NUMBER.VALUE} validate={[Validator.required]} fieldName='price' />
                        </FormItem>
                        <FormItem>
                            <div className={classes['row-item']}>
                                {t("product.product_manual_update_flag")}
                                <FormCheckbox validate={[]} fieldName='manual_update_flag' />
                            </div>
                        </FormItem>
                        <FormItem>
                            <div>{t("product.gs1_code")}</div>
                            <FormInput validate={[Validator.required]} fieldName='gs1_code' />
                        </FormItem>
                        <Button loading={loadingCreate} type='submit' title={t("product.submit")} />
                    </div>
                </form>
            </CustomFormProvider>
        </ContentLayout>
    )
}

export default CreateProductContainer