import Actions from "./action";

const initialState = {
    trackingInfo: {},
    loading: {
        loading: false,
        loadingUpdate: false
    }
}

const myReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_TRACKING_INFORMATION_HISTORY:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loading: true,
                },
            }
        case Actions.GET_TRACKING_INFORMATION_HISTORY_SUCCESS:
            return {
                ...state,
                trackingInfo: action?.payload,
                loading: {
                    ...state.loading,
                    loading: false,
                },
            }
        case Actions.GET_TRACKING_INFORMATION_HISTORY_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loading: false,
                },
            }
        case Actions.UPDATE_TRACKING_INFORMATION_HISTORY:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdate: true,
                },
            }
        case Actions.UPDATE_TRACKING_INFORMATION_HISTORY_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdate: false,
                },
            }
        case Actions.UPDATE_TRACKING_INFORMATION_HISTORY_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdate: false,
                },
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;