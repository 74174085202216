import React from 'react'

function IconCamera({ fontSize = 14 }) {
    return (
        <>
            <svg fontSize={fontSize} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.76607 2.6264C4.99508 2.36611 5.32552 2.1875 5.70928 2.1875H8.28941C8.67317 2.1875 9.00362 2.36611 9.23263 2.6264C9.24668 2.64237 9.25954 2.65934 9.27112 2.67718L9.99792 3.79683C10.0899 3.89195 10.1985 3.9375 10.2806 3.9375H11.9352C12.2858 3.9375 12.6241 4.07316 12.8752 4.31803C13.1268 4.56327 13.2702 4.89828 13.2702 5.25V10.5C13.2702 10.8517 13.1268 11.1867 12.8752 11.432C12.6241 11.6768 12.2858 11.8125 11.9352 11.8125H2.06345C1.71293 11.8125 1.3746 11.6768 1.12345 11.432C0.871923 11.1867 0.728516 10.8517 0.728516 10.5V5.25C0.728516 4.89828 0.871923 4.56327 1.12345 4.31803C1.3746 4.07316 1.71293 3.9375 2.06345 3.9375H3.74614C3.81357 3.9375 3.89962 3.90478 4.0001 3.79787L4.72758 2.67718C4.73916 2.65934 4.75202 2.64237 4.76607 2.6264ZM5.4415 3.18441L4.71495 4.30368C4.70343 4.32142 4.69064 4.33831 4.67668 4.35421C4.47075 4.58862 4.15795 4.8125 3.74614 4.8125H2.06345C1.93795 4.8125 1.81974 4.86121 1.73429 4.94453C1.64921 5.02748 1.60352 5.13759 1.60352 5.25V10.5C1.60352 10.6124 1.64921 10.7225 1.73429 10.8055C1.81974 10.8888 1.93795 10.9375 2.06345 10.9375H11.9352C12.0608 10.9375 12.179 10.8888 12.2644 10.8055C12.3495 10.7225 12.3952 10.6124 12.3952 10.5V5.25C12.3952 5.13759 12.3495 5.02748 12.2644 4.94453C12.179 4.86121 12.0608 4.8125 11.9352 4.8125H10.2806C9.8831 4.8125 9.5411 4.6036 9.32202 4.35421C9.30806 4.33831 9.29527 4.32142 9.28375 4.30368L8.55719 3.18441C8.47569 3.10091 8.38206 3.0625 8.28941 3.0625H5.70928C5.61664 3.0625 5.52301 3.10091 5.4415 3.18441Z" fill="currentColor" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7 5.6875C6.0335 5.6875 5.25 6.471 5.25 7.4375C5.25 8.404 6.0335 9.1875 7 9.1875C7.9665 9.1875 8.75 8.404 8.75 7.4375C8.75 6.471 7.9665 5.6875 7 5.6875ZM4.375 7.4375C4.375 5.98775 5.55025 4.8125 7 4.8125C8.44975 4.8125 9.625 5.98775 9.625 7.4375C9.625 8.88725 8.44975 10.0625 7 10.0625C5.55025 10.0625 4.375 8.88725 4.375 7.4375Z" fill="currentColor" />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.29688 3.77344C2.29688 3.53181 2.49275 3.33594 2.73437 3.33594H3.39062C3.63225 3.33594 3.82812 3.53181 3.82812 3.77344V4.375C3.82812 4.61662 3.63225 4.8125 3.39062 4.8125C3.25996 4.8125 3.14267 4.75521 3.0625 4.66439C2.98233 4.75521 2.86504 4.8125 2.73437 4.8125C2.49275 4.8125 2.29688 4.61662 2.29688 4.375V3.77344Z" fill="currentColor" />
            </svg>
        </>
    )
}

export default IconCamera