import React, { useEffect, useState } from "react";
import useTrans from "hooks/use-trans";
import Box from "shared/components/form-input/box/Box";
import classes from "./FreightCalculationManagementContainer.module.scss";
import BoxBody from 'shared/components/common/box/BoxBody';
import CustomBox from 'shared/components/common/box/Box';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import CustomTable from "shared/components/common/custom-table/CustomTable";
import { useDispatch } from 'react-redux';
import actionFareCalculation from 'redux/freight-calculation/action';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_CROSS_BORDER_COURIER_SERVICE,
    EVENT_SHOW_POPUP_CONFIRM_UPDATE
} from "utils/EventRegister";
import { showPopupNotification } from 'utils/Utils';
import Constants from "utils/Constants";


const TrialCalculationList = (props) => {
    const { fareCalculationService } = props
    const { t } = useTrans()
    const dispatch = useDispatch();

    const [dataCalculationList, setDataCalculationList] = useState([])
    const dataHeadCell = [
        {
            label: t("fare_calculation.service_name"),
            field: "name",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "center",
            align: "center",
            styleBody: {
                padding: "15px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (props) => {
                return (
                    <p className={classes["show-detail-cross-border"]} onClick={() => editCrossCourierService(props?.data?.id)}>
                        {props?.data?.name}
                    </p>
                );
            },

        },
        {
            label: t("fare_calculation.constant"),
            field: "fareCalcConstants",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "center",
            align: "center",
            styleBody: {
                padding: "15px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },

        },
        {
            label: t("fare_calculation.trial_calculation_item"),
            field: "fareCalcFormulas",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "center",
            align: "center",
            styleBody: {
                padding: "15px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
        },
        {
            label: t("fare_calculation.estimated_target"),
            field: "isCalculationObject",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "center",
            align: "center",
            styleBody: {
                padding: "15px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (props) => {
                return (
                    <p style={{ margin: '0px' }}>
                        {props?.data?.isCalculationObject === Constants.CROSS_BORDER_COURIER?.YES?.VALUE ? `${t('Holon_Template.valid')}` : `${t('Holon_Template.invalid')}`}
                    </p>
                );
            },
        },
        {
            label: t("fare_calculation.service_contents"),
            field: "content",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "center",
            align: "center",
            styleBody: {
                padding: "15px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (props) => {
                return (
                    <div className={classes["contentService"]}
                        dangerouslySetInnerHTML={{
                            __html: props?.data?.content,
                        }}
                    >

                    </div >
                );
            },
        },
        {
            label: t("fare_calculation.remark"),
            field: "remark",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "center",
            align: "center",
            styleBody: {
                padding: "15px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
        },
        {
            label: t("Holon_Template.delete"),
            field: "remark",
            minWidth: "150px",
            width: "150px",
            maxWidth: "150px",
            alignHeader: "center",
            align: "center",
            styleBody: {
                padding: "15px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (props) => {
                return (
                    <div className={classes["show-detail-cross-border"]}
                        onClick={() => deleteService(props?.data?.id)}
                    >
                        <IconMinusAccordion />
                    </div >
                );
            },
        },
    ];

    const deleteService = (id) => {

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                title: t("update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionFareCalculation.DELETE_FARE_CALC_SERVICE,
                        payload: id,
                        onSuccess: onCreateSuccess,
                        onFailure: onCreateFailure,
                    })
                }
            }
        })
    }

    const onCreateSuccess = () => {
        showPopupNotification({
            typePopup: 'message',
            newWindow: true,
            message: t("E0046")
        })
        getFareCalculation()
    }

    const onCreateFailure = (detail = {}) => {
        Object.keys(detail).forEach(key => {
            detail[key]?.map(msg => {
                showPopupNotification({
                    typePopup: 'message',
                    newWindow: true,
                    message: msg
                })
            })
        })
        getFareCalculation()
    }

    const getFareCalculation = () => {
        dispatch({
            type: actionFareCalculation.GET_CALCULATION_SERVICE,
        })
        dispatch({
            type: actionFareCalculation.GET_FARE_CALCULATION_GLOBAL_VARIABLE,
        })
        dispatch({
            type: actionFareCalculation.GET_FARE_CALCULATION_USER_INPUT,
        })
    }

    const editCrossCourierService = (id) => {
        dispatch({
            type: actionFareCalculation.GET_CROSS_BORDER_COURIER_SERVICE,
            payload: id,
        });
        openCrossCourierService()
    }

    const openCrossCourierService = () => {
        dispatch({
            type: actionFareCalculation.REMOVE_CROSS_BORDER_COURIER_SERVICE,
        });
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_CROSS_BORDER_COURIER_SERVICE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                callback: () => {

                },
            },
        });
    }

    useEffect(() => {
        const updatedData = fareCalculationService?.data?.map(item => {
            const fareCalcConstantNames = item.fareCalcConstants.map(constant => constant.name);
            const fareCalcConstantString = fareCalcConstantNames.join(", ");
            const fareCalcFormulasNames = item.fareCalcFormulas.map(constant => constant.name);
            const fareCalcFormulasString = fareCalcFormulasNames.join(", ");
            return {
                ...item,
                fareCalcConstants: fareCalcConstantString,
                fareCalcFormulas: fareCalcFormulasString,
            };
        });
        setDataCalculationList(updatedData);
    }, [fareCalculationService]);

    return (
        <Box className={classes["freight-calculation-management"]}>
            <div className={classes["title-input-item"]}>
                <p>
                    {t("fare_calculation.trial_calculation_item_list")}
                </p>
                <div className={classes['icon-plus']} style={{ cursor: "pointer" }} onClick={() => {
                    openCrossCourierService()
                }}>
                    <IconPlusWhite />
                </div>
            </div>
            <CustomBox>
                <BoxBody padding={0}>
                    <CustomTable
                        // loading={''}
                        data={dataCalculationList ?? []}
                        headCell={dataHeadCell}
                    />
                </BoxBody>
            </CustomBox>
        </Box>
    );
};

export default TrialCalculationList;
