import React from 'react';

function IconRightArrow({ color = 'white', fontSize = 14 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 10 14"
            fill="none"
            fontSize={fontSize}
        >
            <path d="M1 1L8 7L1 13" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}

export default IconRightArrow;
