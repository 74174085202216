import { getCoreAPI } from "shared/components/common/Util";
import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const Factories = {
    getBlDetail: (payload) => {
        const url = `${getCoreAPI()}/management/hex-account/get-detail/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    getBlDepartmentByCompanyId: (payload) => {
        const url = `${getCoreAPI()}/management/hex-account/get-department/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    getBlPositionByDepartmentId: (payload) => {
        const url = `${getCoreAPI()}/management/hex-account/get-position/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    addPositionForUser: (payload) => {
        const url = `${getCoreAPI()}/management/hex-account/users/connect`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },

    registerBlAccount: (payload) => {
        const url = `${getCoreAPI()}/management/hex-account/users/register`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    }

}

export default Factories;