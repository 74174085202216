import useRouter from 'hooks/use-router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import RouterPath from 'router/RouterPath';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import TextLabel from 'shared/components/common/form-search/TextLabel';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormInput from 'shared/components/custom-form/FormInput';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import Validator from 'utils/Validator';
import classes from './HexServiceContainer.module.scss';
import FormEditor from 'shared/components/custom-form/FormEditor';
import useTrans from 'hooks/use-trans';
import actionHexService from 'redux/hex-service/action';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import FormImage from 'shared/components/custom-form/FormImage';
import Action from 'redux/user/action';
import FormColorPicker from 'shared/components/custom-form/FormColorPicker';

function UpdateHexServiceContainer(props) {
    const { lang, id } = props;
    const defaultValues = {};
    const methods = useForm({
        defaultValues: defaultValues,
    });

    const router = useRouter();
    const { t } = useTrans()
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const dispatch = useDispatch();
    const { hexService, loading: {
        loadingGetHexService,
        loadingUpdateHexService
    } } = useSelector((state) => state.hexService);

    useEffect(() => {
        if (lang && id) {
            dispatch({
                type: actionHexService.GET_HEX_SERVICE,
                payload: {
                    lang,
                    id: id,
                },
            });
        }
    }, [lang, id]);

    useEffect(() => {
        if (hexService?.id) {
            methods.setValue('title', hexService?.title);
            methods.setValue('content', hexService?.content);
            methods.setValue('description', hexService?.description);
            methods.setValue('backgroundColorCode', hexService?.backgroundColorCode);
            methods.setValue('logo', hexService?.logo);
            methods.setValue('id', id);
            methods.setValue('lang', lang);
            methods.setValue('textColor', hexService?.textColor);
        }
    }, [hexService]);

    const onSubmit = (_data) => {
        // if (_data?.logo == hexService?.logo) {
        //     logo = _data?.logo;
        //     setLoadingUpdate(true);
            
        // } else {
        //     setLoadingUpdate(true);
        //     dispatch({
        //         type: Action.UPLOAD_IMAGE,
        //         payload: _data?.logo,
        //         callback: (url) => {
        //             dispatch({
        //                 type: actionHexService.UPDATE_HEX_SERVICE,
        //                 payload: {
        //                     lang: lang,
        //                     id: id,
        //                     content: _data?.content,
        //                     title: _data?.title,
        //                     description: _data?.description,
        //                     logo: url,
        //                     backgroundColorCode: _data?.backgroundColorCode,
        //                     textColor: _data?.textColor,
        //                 },
        //                 callback: {
        //                     success: () => {
        //                         setLoadingUpdate(false);
        //                         router.push({
        //                             pathname: RouterPath.PORTAL_HEX_SERVICE,
        //                             params: {
        //                                 [Constants.QueryParams.LANGUAGE.VALUE]: lang,
        //                                 [Constants.QueryParams.TYPE_SEARCH.VALUE]:
        //                                     id,
        //                             },
        //                         });
        //                     },
        //                     failed: (msg) => {
        //                         setLoadingUpdate(false);
        //                         EventRegister.emit(EVENT_SHOW_POPUP, {
        //                             open: true,
        //                             type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
        //                             payload: {
        //                                 title: msg,
        //                                 showHeader: false,
        //                                 customStyle: {
        //                                     padding: 0,
        //                                     borderTop: 0
        //                                 },
        //                             }
        //                         })
        //                     }
        //                 },
        //             });
        //         }
        //     })
        // }
        dispatch({
            type: actionHexService.UPDATE_HEX_SERVICE,
            payload: {
                lang: lang,
                id: id,
                content: _data?.content,
                title: _data?.title,
                description: _data?.description,
                logo: _data?.logo,
                backgroundColorCode: _data?.backgroundColorCode,
                textColor: _data?.textColor,
            },
            callback: {
                success: () => {
                    setLoadingUpdate(false);
                    router.push({
                        pathname: RouterPath.PORTAL_HEX_SERVICE,
                        params: {
                            [Constants.QueryParams.LANGUAGE.VALUE]: lang,
                            [Constants.QueryParams.TYPE_SEARCH.VALUE]:
                                id,
                        },
                    });
                },
                failed: (msg) => {
                    setLoadingUpdate(false);
                    EventRegister.emit(EVENT_SHOW_POPUP, {
                        open: true,
                        type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                        payload: {
                            title: msg,
                            showHeader: false,
                            customStyle: {
                                padding: 0,
                                borderTop: 0
                            },
                        }
                    })
                }
            },
        });
    };
    return (
        <div className={classes['PolicySetting']}>
            <div className={classes['Update-PolicySetting-FormSearch']}>
                <CustomFormProvider {...methods}>
                    <form
                        className="quick-submit"
                        onSubmit={methods.handleSubmit(onSubmit)}
                    >
                        <FormSearch>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel title={t('faq_management.title')} />
                                    <FormInput validate={[Validator.required]} fieldName="title" />
                                </FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel title={t('faq_management.background_color_code')} />
                                    <FormColorPicker validate={[]} fieldName="backgroundColorCode" />
                                </FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel title={t('faq_management.text_color')} />
                                    <FormColorPicker validate={[]} fieldName="textColor" />
                                </FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel title={t('faq_management.logo')} />
                                    <FormImage validate={[Validator.required]} fieldName="logo" />
                                </FormItem>
                                <FormItem></FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel title={t("faq_management.description")} />
                                    <FormTextArea validate={[Validator.required]} fieldName="description" />
                                </FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel title={t("faq_management.content")} />
                                    <FormEditor height='300px' validate={[Validator.required]} fieldName="content" />
                                </FormItem>
                            </FormGroup>
                        </FormSearch>
                        <div className={classes['action-submit']}>
                            <Button disabled={loadingGetHexService} loading={loadingUpdateHexService || loadingUpdate} type="submit" title={t("save")} />
                        </div>
                    </form>
                </CustomFormProvider>
            </div>
        </div>
    );
}

export default UpdateHexServiceContainer;
