import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/locales/en/translation.json';
import translationJP from './assets/locales/jp/translation.json';
import translationCN from './assets/locales/cn/translation.json';
import { getLng } from 'utils/Utils';

// const i18n = i18next.createInstance();
const i18n = i18next
const fallbackLng = ['jp','en', 'cn'];

export const resources = {
    jp: {
        translation: translationJP,
    },
    en: {
        translation: translationEN,
    },
    cn: {
        translation: translationCN,
    },
};
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        debug: false,
        lng: getLng(), 
    });

export default i18n;
