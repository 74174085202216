import React from "react";

function IconCircular() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path d="M224 96a160 160 0 100 320 160 160 0 100-320zm224 160a224 224 0 11-448 0 224 224 0 11448 0z"></path>
        </svg>
    );
}

export default IconCircular;
