import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actionProduct from 'redux/product/action';
import Constants from 'utils/Constants';
import classes from './ProductContainer.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination';
import { useForm } from 'react-hook-form';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import EventRegister, { EVENT_SHOW_CREATE_PRODUCT_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE, EVENT_SHOW_PRODUCT_DETAIL_POPUP } from 'utils/EventRegister';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import { convertNumberToString } from 'utils/String';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import ProductActions from './action/ProductActions';

function ProductContainer() {
    const dispatch = useDispatch();
    const router = useRouter();
    const { listProduct, loadingGetList = false } = useSelector(state => state.product);
    const { t } = useTrans();
    const methods = useForm();
    const handleSubmit = (data) => {
        let params = { ...router.getAll() };
        router.replace({
            params: {
                ...params,
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: router.get([Constants.QueryParams.LIMIT.VALUE]),
                [Constants.QueryParams.SEARCH.VALUE]: data?.search,
            }
        })
    }

    useEffect(() => {
        if (router.get(Constants.QueryParams.SEARCH.VALUE)) {
            methods.setValue("search", router.get(Constants.QueryParams.SEARCH.VALUE))
        } else {
            methods.setValue("search", "");
        }
    }, [
        router.get(Constants.QueryParams.SEARCH.VALUE)
    ])


    useEffect(() => {
        dispatch({
            type: actionProduct.GET_LIST_PRODUCT,
            payload: {
                ...router.getAll()
            }
        })
    }, [
        router.get(Constants.QueryParams.SEARCH.VALUE),
        router.get(Constants.QueryParams.LIMIT.VALUE),
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.SORT.VALUE),
        router.get(Constants.QueryParams.TYPE_SORT.VALUE),
        router.get(Constants.QueryParams.CODE.VALUE),
        router.get(Constants.QueryParams.URL.VALUE),
        router.get(Constants.QueryParams.PRICE.VALUE),
        router.get(Constants.QueryParams.CURRENCY.VALUE),
        router.get(Constants.QueryParams.ORIGIN.VALUE),
    ])

    let dataHeadCell = [
        {
            label: t('STT'),
            field: "stt",
            minWidth: '10px',
            width: '10px',
            maxWidth: '10px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (_props) => {
                return <>{_props?.stt + 1}</>
            }
        },
        {
            label: t('product.gs1_code'),
            field: "jan_code",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            sort: true,
            component: (_props) => {
                return <ButtonLink title={_props?.children} onClick={() => {
                    handleGotoDetail(_props?.data?.id)
                }} />
            }
        },
        {
            label: t('product.category'),
            field: "genre_name",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            sort: true,
        },
        {
            label: t('product.product_name'),
            field: "item_name",
            minWidth: '200px',
            width: '200px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            sort: true,
        },
        {
            label: t('product.unit_price'),
            field: "price",
            minWidth: '150px',
            maxWidth: '150px',
            width: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'right',
            alignHeader: 'right',
            component: (_props) => {
                return <>{convertNumberToString(_props?.children)}{_props?.data?.currency}</>
            },
            sort: true,
        },
        {
            label: t('product.origin'),
            field: "origin",
            minWidth: '150px',
            maxWidth: '150px',
            width: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
        {
            label: t('URL'),
            field: "product_url",
            minWidth: '150px',
            maxWidth: '150px',
            width: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => {
                return <ButtonLink isHref>
                    {_props?.children}
                </ButtonLink>
            },
        },
        {
            label: t('product.remark'),
            field: "note",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => {
                return _props?.data?.manual_update_flag == "1" ? t("product.editing") : ""
            }
        },
        {
            label: t('product.brand_name'),
            field: "brand_name",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
        {
            label: t('product.maker_name'),
            field: "maker_name",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
    ];

    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            },
        });
    }

    const onChangePage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e,
            },
        });
    };

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = [
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'code',
                label: t("product.gs1_code"),
                code: router.get(Constants.QueryParams.CODE.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'currency',
                label: t("product.currency"),
                currency: router.get(Constants.QueryParams.CURRENCY.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'origin',
                label: t("product.origin"),
                origin: router.get(Constants.QueryParams.ORIGIN.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'productUrl',
                label: t("product.url"),
                productUrl: router.get(Constants.QueryParams.URL.VALUE) || "",
                options: []
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'price',
                label: t("product.unit_price"),
                price: router.get(Constants.QueryParams.PRICE.VALUE) || "",
                options: []
            },
        ]
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                formArray: false,
                callback: (_props) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.CODE.VALUE]: _props?.code,
                            [Constants.QueryParams.CURRENCY.VALUE]: _props?.currency,
                            [Constants.QueryParams.ORIGIN.VALUE]: _props?.origin,
                            [Constants.QueryParams.PRICE.VALUE]: _props?.price,
                            [Constants.QueryParams.URL.VALUE]: _props?.productUrl,
                        }
                    })
                }
            }
        })
    }

    const handleGotoDetail = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_PRODUCT_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                productId: id,
                isShowOption: false,
                title: t("product.product_detail"),
            }
        })
    }

    const handleAddProduct = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_CREATE_PRODUCT_POPUP,
            payload: {
                title: t("product.product_create"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionProduct.GET_LIST_PRODUCT,
                        payload: {
                            ...router.getAll(),
                        }
                    })
                },
            }
        })
    }

    const onChangeSortColumn = (data) => {
        let params = { ...router.getAll() }
        if (data?.type) {
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1,
                    [Constants.QueryParams.SORT.VALUE]: data?.field,
                    [Constants.QueryParams.TYPE_SORT.VALUE]: data?.type,
                }
            })
        }

        if (!data?.type) {
            delete params[Constants.QueryParams.SORT.VALUE];
            delete params[Constants.QueryParams.TYPE_SORT.VALUE];
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1
                }
            })
        }
    }

    const dataSort = (router) => {
        if (router[Constants.QueryParams.SORT.VALUE]) {
            return {
                field: router[Constants.QueryParams.SORT.VALUE],
                type: router[Constants.QueryParams.TYPE_SORT.VALUE]
            }
        } else {
            return {
                field: null,
                type: null
            }
        }
    }

    return (
        <ContentLayout>
            <Breadcrumb>{t("product.product_management")}</Breadcrumb>
            <div className={classes['product-container']}>
                <div className={classes['custom-search']}>
                    <CustomFormProvider {...methods}>
                        <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                            <FormSearch className={classes['custom-form-search']}>
                                <FormGroup>
                                    <FormItem>
                                        <FormInput iconSearch={<IconSearch fontSize={16} />} isSearch fieldName='search' placeholder={t("search_by_word")} />
                                    </FormItem>
                                    <FormItem>
                                        <ButtonFilter onClick={handleOpenAddSearchCriteria} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                    </FormItem>
                                </FormGroup>
                                <ProductActions />
                                <ButtonAdd onClick={handleAddProduct} size='large' color='yellow' endIcon={<IconPlusWhite />} />
                            </FormSearch>
                            <button hidden type='submit' />
                        </form>
                    </CustomFormProvider>
                </div>
                <div className={classes['custom-body']}>
                    <CustomTable
                        dataSort={dataSort({ ...router.getAll() })} onChangeSortColumn={onChangeSortColumn}
                        loading={loadingGetList} data={listProduct?.records ?? []} headCell={dataHeadCell} />
                    <div className={classes['custom-pagination']}>
                        <CustomPagination
                            isRowPerpage
                            onChangeRowPerpage={onChangeRowPerpage}
                            limit={router.get(Constants.QueryParams.LIMIT.VALUE)}
                            onChange={onChangePage} total={listProduct?.total} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default ProductContainer