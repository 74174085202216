import React, { useEffect, useState, useRef } from 'react'
import classes from './MenuManagement.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux/app/action';
import MenuManagementAction from 'redux/menu-management/action';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import TableSkeleton from 'shared/components/common/skeleton/TableSkeleton';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FormInput from 'shared/components/custom-form/FormInput';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import Button from 'shared/components/form-input/button/Button';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import IconEdit from 'assets/icon/IconEdit';
import Validator from 'utils/Validator';
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
    EVENT_SHOW_POPUP_CONFIRM_DELETE,
    EVENT_SHOW_CREATE_HEX_MENU_POPUP,
    EVENT_SHOW_UPDATE_HEX_MENU_POPUP,
} from 'utils/EventRegister';

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "",
    // padding: grid,
    // width: 250
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const CustomCheckAll = (props) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    let checked5 = 0;
    let checked3 = 0;
    props?.head?.map(x => {
        if (props.menu?.menuSettings[x?.id]?.permission == 5) {
            checked5++;
        }
    })
    props?.head?.map(x => {
        if (props.menu?.menuSettings[x?.id]?.permission == 3) {
            checked3++;
        }
    })

    const onChange = (_value) => {
        setLoading(true);
        dispatch({
            type: MenuManagementAction.UPDATE_PERMISTION_MENU_MANAGEMENT,
            payload: {
                menuCode: props.menu?.code,
                userRole: [...props?.head]?.map(x => x?.id)?.join(","),
                permission: _value
            },
            callback: {
                success: () => {
                    setLoading(false);
                    dispatch({
                        type: MenuManagementAction.GET_MENU_MANAGEMENT,
                        payload: {
                            ...router.getAll(),
                        }
                    })
                    // dispatch({
                    //     type: Actions.GET_MENU
                    // });
                },
                failed: () => {
                    setLoading(false);
                }
            }
        })
    }

    return <>
        <td
            onClick={() => {
                if (props?.isDisabled) {
                    return;
                }
                if (props?.menu?.isDisabled != "1" && !props?.loadingUpdate) {

                    // if (checked3 == props?.head?.length) {
                    //     onChange("5")
                    // }

                    if (checked5 == props?.head?.length) {
                        onChange("0")
                    }

                    if (checked3 != props?.head?.length && checked5 != props?.head?.length) {
                        onChange("5")
                    }

                }
            }}
            style={{
                minWidth: 44,
                maxWidth: 44,
                width: 44,
                textAlign: "center",
                fontSize: 12,
                color: "#000",
                padding: "0px",
                verticalAlign: "middle",
                borderRight: '0.5px solid #929292',
                cursor: props?.menu?.isDisabled == "1" || props?.loadingUpdate || props?.isDisabled ? 'default' : 'pointer',
                background: props?.menu?.isDisabled == "1" ? '#D9D9D9' :
                    checked5 == props?.head?.length ||
                        checked3 == props?.head?.length ? "rgba(112, 176, 187, 0.90)" : "#FFFFFF",
                opacity: !props?.loadingUpdate ? 1 : .8
            }}
        >
            {props?.menu?.isDisabled != "1" ? (checked5 == props?.head?.length ? <>O</> : checked3 == props?.head?.length ? <>&#8710;</> : <></>) : <></>}
        </td>
    </>
}
const Permission = (props) => {
    const dispatch = useDispatch();
    const [permission, setPermission] = useState("0");
    const router = useRouter();
    useEffect(() => {
        if (!props?.menu?.menuSettings?.[props?.template?.id]?.permission) {
            setPermission("0")
        } else {
            setPermission(props?.menu?.menuSettings?.[props?.template?.id]?.permission)
        }
    }, [props?.menu, props?.template]);

    const onChange = (_value) => {
        dispatch({
            type: MenuManagementAction.UPDATE_PERMISTION_MENU_MANAGEMENT,
            payload: {
                menuCode: props?.menu?.code,
                userRole: props?.template?.id,
                permission: _value
            },
            callback: {
                success: () => {
                    dispatch({
                        type: MenuManagementAction.GET_MENU_MANAGEMENT,
                        payload: {
                            ...router.getAll(),
                        }
                    })
                    // dispatch({
                    //     type: Actions.GET_MENU
                    // });
                }
            }
        })
    }

    const renderPermission = (permission) => {
        if (permission == "0") {
            return "X"
        }

        if (permission == "5") {
            return "O"
        }

        // if (permission == "3") {
        //     return <>&#8710;</>
        // }
    }

    return <td style={{
        minWidth: 44,
        maxWidth: 44,
        width: 44,
        textAlign: "center",
        fontSize: 12,
        color: "#000",
        padding: "0px",
        verticalAlign: "middle",
        borderRight: '0.5px solid #929292',
        cursor: props?.menu?.isDisabled == "1" || props?.loadingUpdate || props?.isDisabled ? 'default' : 'pointer',
        background: props?.menu?.isDisabled == "1" ? '#D9D9D9' :
            permission == "0" ? "#FFFFFF" : "rgba(112, 176, 187, 0.90)",
        opacity: !props?.loadingUpdate ? 1 : .9
    }} onClick={() => {
        if (props?.isDisabled) {
            return;
        }
        if (props?.menu?.isDisabled == "1") {
            return;
        }
        if (permission == "0") {
            setPermission("5");
            onChange("5")
        }

        // if (permission == "3") {
        //     setPermission("5");
        //     onChange("5")
        // }

        if (permission == "5") {
            setPermission("0")
            onChange("0")
        }
    }}>
        <div className={classes['custom-button-permission']}>{props?.menu?.isDisabled == "1" ? <></> : renderPermission(permission)}</div>
    </td>
}
function MenuManagementContainer() {

    const { listMenu, holonTemplate, loading, loadingUpdate } = useSelector(state => state?.menuManagement)
    const dispatch = useDispatch();
    const router = useRouter();
    const { t } = useTrans();
    const methods = useForm();
    const control = methods.control;
    const { handleSubmit, setValue, reset, watch, getValues, setError, clearErrors } = methods;
    const isDirty = methods.formState.isDirty;

    const {
        append: appendHas,
        insert: insertHas,
        fields: fieldsHas,
        remove: removeHas,
        move: moveHas,
    } = useFieldArray({
        control, name: "menus"
    });

    useEffect(() => {
        if (listMenu?.records?.length > 0) {
            reset({
                menus: listMenu?.records,
            });
        }
    }, [listMenu])

    useEffect(() => {
        dispatch({
            type: MenuManagementAction.GET_MENU_MANAGEMENT,
            payload: {
                ...router.getAll(),
            }
        })
    }, [
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.LIMIT.VALUE),
    ])

    // const onPageChange = (e) => {
    //     router.replace({
    //         params: {
    //             ...router.getAll(),
    //             [Constants.QueryParams.OFFSET.VALUE]: e,
    //         },
    //     });
    // };

    useEffect(() => {
        const executeSyncScroll = () => {
            syncScroll(
                '_custom_scroll_footer',
                '_custom_scroll_body',
                '_custom_scroll_header',
            );
            syncScroll(
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_footer'
            );
        };
        executeSyncScroll();
    }, []);

    let ignoreScrollEvents = false;

    const syncScroll = (...selectors) => {
        const wrapper = document.getElementById("Menu_Table");
        if (wrapper && selectors.length > 1) {
            wrapper.addEventListener(
                'scroll',
                ({ target }) => {
                    if (target.classList.contains(selectors[0])) {
                        const ignore = ignoreScrollEvents;
                        ignoreScrollEvents = false;
                        if (ignore) {
                            return;
                        }
                        ignoreScrollEvents = true;
                        const { scrollLeft } = target;
                        selectors.forEach((selector) => {
                            if (wrapper.querySelector(`.${selector}`)) {
                                wrapper.querySelector(`.${selector}`).scrollLeft = scrollLeft;
                            }
                        });
                    }
                },
                true,
            );
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.source.index == result.destination.index) {
            return;
        }

        const sourceItem = listMenu?.records[result.source.index];
        const destinationItem = listMenu?.records[result.destination.index];

        if (!sourceItem?.parentCode?.trim() && !destinationItem?.parentCode.trim()) {
            return;
        }

        if (sourceItem.parentCode != destinationItem.parentCode) {
            return;
        }

        if (sourceItem?.parentCode?.trim() && destinationItem?.parentCode?.trim() && sourceItem?.parentCode == destinationItem?.parentCode) {
            // moveHas(result.source.index, result.destination.index)
            // setTimeout(() => {
            //     updateSortMenus(methods.watch('menus'))
            // }, 1)
            let items = reorder(
                methods.watch("menus"),
                result.source.index,
                result.destination.index
            );

            items = items?.map((x, index) => {
                return {
                    ...x,
                    sort: index + 1
                }
            })
            methods.setValue("menus", items)
            setTimeout(() => {
                updateSortMenus(items)
            }, 1)
        }
    }

    const onSubmit = (data) => {
    }

    const handleAddMenu = (menu, key) => {
        // let count = 0;
        // listMenu?.records?.forEach((item, index) => {
        //     if (item?.parentCode == menu?.code) {
        //         count++
        //     }
        // })
        // let insertedItem = {}
        // if (count == 0) {
        //     insertedItem = {
        //         code: ``,
        //         href: "",
        //         isDisabled: "0",
        //         level: menu?.level+1,
        //         menuDesc: "",
        //         menuSettings: [],
        //         parentCode: menu?.code,
        //         sort: menu?.sort+1,
        //         isAdded: true
        //     }
        //     // insertHas(key+1, {
        //     //     code: ``,
        //     //     href: "",
        //     //     isDisabled: "0",
        //     //     level: menu?.level+1,
        //     //     menuDesc: "",
        //     //     menuSettings: [],
        //     //     parentCode: menu?.code,
        //     //     sort: menu?.sort+1,
        //     //     isAdded: true
        //     // })
        // } else {
        //     const lastChild = listMenu?.records?.[key+count];
        //     insertedItem = {
        //         ...listMenu?.records?.[key+count],
        //         menuSettings: [],
        //         code: ``,
        //         href: '',
        //         menuDesc: '',
        //         sort: lastChild?.sort + 1,
        //         isAdded: true,
        //     }
        //     // insertHas(count+key+1, {
        //     //     ...listMenu?.records?.[key+count],
        //     //     menuSettings: [],
        //     //     code: ``,
        //     //     href: '',
        //     //     menuDesc: '',
        //     //     sort: lastChild?.sort + 1,
        //     //     isAdded: true,
        //     // })
        // }    
        // dispatch({
        //     type: MenuManagementAction.CREATE_MENU_MANAGEMENT,
        //     payload: insertedItem,
        //     callback: {
        //         success: () => {
        //             dispatch({
        //                 type: MenuManagementAction.GET_MENU_MANAGEMENT,
        //                 payload: {
        //                     ...router.getAll(),
        //                 }
        //             })
        //             dispatch({
        //                 type: Actions.GET_MENU
        //             });
        //         },
        //         failed: () => {
        //             EventRegister.emit(EVENT_SHOW_POPUP, {
        //                 open: true,
        //                 type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
        //                 newWindow: true,
        //                 payload: {
        //                     title: t("system_error"),
        //                     showHeader: false,
        //                     customStyle: {
        //                         padding: 0,
        //                         borderTop: 0
        //                     },
        //                 }
        //             })
        //         }
        //     }
        // })
        let count = 0;
        listMenu?.records?.forEach((item, index) => {
            if (item?.parentCode == menu?.code) {
                count++
            }
        })
        let insertedItem = {
            code: ``,
            parentCode: menu?.code,
            sort: '',
            menuDesc: '',
            href: '',
            level: menu?.level + 1,
            isDisabled: "0",
        }
        if (count == 0) {
            insertedItem['sort'] = menu?.sort + 1;
        } else {
            const lastChild = listMenu?.records?.[key + count];
            insertedItem['sort'] = lastChild?.sort + 1;
        }
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_CREATE_HEX_MENU_POPUP,
            open: true,
            payload: {
                title: t('menu_management.menu_management'),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                menu: insertedItem,
                callback: () => {
                    dispatch({
                        type: MenuManagementAction.GET_MENU_MANAGEMENT,
                        payload: {
                            ...router.getAll(),
                        }
                    })
                }
            }
        })
    }

    const handleEditMenu = (menu) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_UPDATE_HEX_MENU_POPUP,
            open: true,
            payload: {
                title: t('menu_management.menu_management'),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                menu: menu,
                callback: () => {
                    dispatch({
                        type: MenuManagementAction.GET_MENU_MANAGEMENT,
                        payload: {
                            ...router.getAll(),
                        }
                    })
                }
            }
        })
    }

    const handleDeleteMenu = (menu) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            payload: {
                title: t("E0044"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: MenuManagementAction.DELETE_MENU_MANAGEMENT,
                        payload: menu?.code,
                        callback: {
                            success: () => {
                                dispatch({
                                    type: MenuManagementAction.GET_MENU_MANAGEMENT,
                                    payload: {
                                        ...router.getAll(),
                                    }
                                })
                                // dispatch({
                                //     type: Actions.GET_MENU
                                // }); 
                            },
                            failed: () => {
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: t("system_error"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    const hasItemTemp = () => {
        return fieldsHas.some((item) => !item?.code?.trim())
    }

    const updateSortMenus = (menus) => {
        if (menus) {
            dispatch({
                type: MenuManagementAction.UPDATE_SORT_MENU_MANAGEMENT,
                payload: menus,
                callback: {
                    success: () => {
                        dispatch({
                            type: MenuManagementAction.GET_MENU_MANAGEMENT,
                            payload: {
                                ...router.getAll(),
                            }
                        })
                        // dispatch({
                        //     type: Actions.GET_MENU
                        // });
                    },
                    failed: () => {
                        EventRegister.emit(EVENT_SHOW_POPUP, {
                            open: true,
                            type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                            newWindow: true,
                            payload: {
                                title: t("system_error"),
                                showHeader: false,
                                customStyle: {
                                    padding: 0,
                                    borderTop: 0
                                },
                            }
                        })
                    }
                }
            })
        }
    }

    return (
        <ContentLayout>
            <div id='Menu_Table' className={classes['MenuManagementContainer']}>
                <div className={classes['MenuManagementContainer_Title']}>
                    {t("menu_management.menu_management")}
                </div>

                <div className={classes['MenuManagementContainer_Explain']}>
                    {t("menu_management.menu_management_explain")}
                </div>
                {
                    <div className={`${classes.container_table}`}
                        style={{ position: 'relative' }}>
                        <div style={{
                            position: 'sticky',
                            zIndex: 12,
                            overflowY: 'hidden',
                            top: 0,
                        }} className={`_custom_scroll_header ${classes['_custom_scroll_header']}`}>
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th style={{
                                            textAlign: "center",
                                            padding: "10px 0px",
                                            height: 50,
                                            borderRight: "0.5px solid #929292",
                                            width: '200px',
                                            minWidth: '200px',
                                            maxWidth: '200px',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 999,
                                            overflow: 'hidden',
                                            top: 0
                                        }} className={`${classes["title_header"]} ${classes['connect-line']}`}>
                                            <div className={`${classes['custom-content']}`}>
                                                <p className={classes['custom-content-menu-name']}>{t("menu_management.menu_name")}</p>
                                                <div className={classes['connect-line']}></div>
                                                <p className={classes['custom-content-user-authority']}>{t("menu_management.user_authority")}</p>
                                            </div>
                                        </th>
                                        <th style={{
                                            minHeight: 38,
                                            borderRight: "0.5px solid #929292",
                                            width: '44px',
                                            minWidth: '44px',
                                            maxWidth: '44px',
                                            position: 'sticky',
                                            height: '200px',
                                        }} className={classes["title_header"]}>
                                            <div className={classes['rotatabletext']}>
                                                {t("menu_management.one_set")}
                                            </div>
                                        </th>
                                        {
                                            holonTemplate?.records?.map((v, index) => {
                                                return <th key={index} style={{
                                                    minHeight: 38,
                                                    borderRight: "0.5px solid #929292",
                                                    width: '44px',
                                                    minWidth: '44px',
                                                    maxWidth: '44px',
                                                    height: '230px',
                                                    position: 'relative'
                                                }} className={classes["title_header"]}>
                                                    <div className={classes['rotatabletext']}>{v?.name}</div>
                                                </th>
                                            })}
                                        <th style={{
                                            textAlign: "center",
                                            padding: "10px 0px",
                                            height: 50,
                                            borderRight: "0.5px solid #929292",
                                            width: '200px',
                                            minWidth: '200px',
                                            maxWidth: '200px',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 999,
                                            overflow: 'hidden',
                                            top: 0
                                        }} className={`${classes["title_header"]}`}>
                                            {t('url')}
                                        </th>
                                        <th style={{
                                            textAlign: "center",
                                            padding: "10px 0px",
                                            height: 50,
                                            borderRight: "0.5px solid #929292",
                                            width: '100px',
                                            minWidth: '100px',
                                            maxWidth: '100px',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 999,
                                            overflow: 'hidden',
                                            top: 0
                                        }} className={`${classes["title_header"]}`}>
                                            {/* {t('action')} */}
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className={`_custom_scroll_body ${classes['_custom_scroll_body']}`}>
                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <table className={classes['content-container']}>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable direction='vertical' droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <tbody
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                        className='overflow-y-auto'
                                                    >
                                                        {
                                                            fieldsHas?.map((menu, key) => {
                                                                return <Draggable
                                                                    isDragDisabled={menu?.isDisabled == '1' || !menu?.parentCode?.trim() || !menu?.menuDesc?.trim() || menu?.parentCode?.trim() != Constants.MENU_MASTER.MENU_DESKTOP.CODE || loadingUpdate}
                                                                    key={menu?.id}
                                                                    draggableId={menu?.id}
                                                                    index={key}>
                                                                    {(provided, snapshot) => (
                                                                        <tr key={key}
                                                                            ref={provided.innerRef}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <td style={{
                                                                                minWidth: 200,
                                                                                maxWidth: 200,
                                                                                width: 200,
                                                                                textAlign: "left",
                                                                                fontSize: 12,
                                                                                color: "#000",
                                                                                verticalAlign: "middle",
                                                                                borderRight: '0.5px solid #929292',
                                                                                padding: '12px 10px',
                                                                                position: 'sticky',
                                                                                left: 0,
                                                                                zIndex: 11,
                                                                                paddingLeft: menu?.level == 0 ? "10px" : (menu?.level * 25),
                                                                                cursor: menu?.isDisabled == "1" ? 'default' : '',
                                                                                background: menu?.isDisabled == "1" ? '#D9D9D9' : '#FFFFFF',
                                                                            }}>
                                                                                {
                                                                                    menu?.menuDesc
                                                                                }
                                                                            </td>
                                                                            <CustomCheckAll loadingUpdate={loadingUpdate} menu={menu} head={holonTemplate?.records} holonTemplate={holonTemplate} />
                                                                            {
                                                                                holonTemplate?.records?.map((template, index) => {
                                                                                    return <Permission loadingUpdate={loadingUpdate} key={index} menu={menu} template={template} />
                                                                                })}
                                                                            <td style={{
                                                                                minWidth: 200,
                                                                                maxWidth: 200,
                                                                                width: 200,
                                                                                textAlign: "left",
                                                                                fontSize: 12,
                                                                                color: "#000",
                                                                                verticalAlign: "middle",
                                                                                borderRight: '0.5px solid #929292',
                                                                                padding: '12px 10px',
                                                                                position: 'sticky',
                                                                                left: 0,
                                                                                zIndex: 11,
                                                                                paddingLeft: menu?.level == 0 ? "10px" : (menu?.level * 25),
                                                                                cursor: menu?.isDisabled == "1" ? 'default' : '',
                                                                                background: menu?.isDisabled == "1" ? '#D9D9D9' : '#FFFFFF',
                                                                            }}>
                                                                                {
                                                                                    menu?.href
                                                                                }
                                                                            </td>
                                                                            <td style={{
                                                                                minWidth: 100,
                                                                                maxWidth: 100,
                                                                                width: 100,
                                                                                fontSize: 12,
                                                                                color: "#000",
                                                                                verticalAlign: "middle",
                                                                                borderRight: '0.5px solid #929292',
                                                                                padding: '12px 10px',
                                                                                position: 'sticky',
                                                                                left: 0,
                                                                                zIndex: 11,
                                                                                paddingLeft: "10px",
                                                                                cursor: menu?.isDisabled == "1" ? 'default' : '',
                                                                                background: menu?.isDisabled == "1" ? '#D9D9D9' : '#FFFFFF',
                                                                            }}
                                                                            >
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    gap: "10px",
                                                                                }}>
                                                                                    {
                                                                                        (menu?.isDisabled == '1' && !menu?.parentCode?.trim() && menu?.code?.trim() == Constants.MENU_MASTER.MENU_DESKTOP.CODE) ?
                                                                                            // btn add
                                                                                            <Button
                                                                                                color='lightblue'
                                                                                                loading={false}
                                                                                                type="button"
                                                                                                title={t('add')}
                                                                                                onClick={() => handleAddMenu(menu, key)}
                                                                                            />
                                                                                            :
                                                                                            (menu.parentCode?.trim() && menu?.parentCode?.trim() == Constants.MENU_MASTER.MENU_DESKTOP.CODE) ?
                                                                                                // btn edit && delete
                                                                                                <>
                                                                                                    <Button
                                                                                                        color='lightblue'
                                                                                                        loading={false}
                                                                                                        type="button"
                                                                                                        endIcon={<IconEdit color="#0E525E" />}
                                                                                                        onClick={() => handleEditMenu(menu)}
                                                                                                    />
                                                                                                    <Button
                                                                                                        color='lightblue'
                                                                                                        loading={false}
                                                                                                        type="button"
                                                                                                        endIcon={<IconMinusAccordion color="#0E525E" />}
                                                                                                        onClick={() => handleDeleteMenu(menu)}
                                                                                                    />
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </Draggable>
                                                            })}
                                                        {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        {/* <tbody>
                                    {listMenu?.records?.map((menu, key) => {
                                        return <tr key={key}>
                                            <td style={{
                                                minWidth: 200,
                                                maxWidth: 200,
                                                width: 200,
                                                textAlign: "left",
                                                fontSize: 12,
                                                color: "#000",
                                                verticalAlign: "middle",
                                                borderRight: '0.5px solid #929292',
                                                padding: '12px 10px',
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 11,
                                                paddingLeft: menu?.level == 0 ? "10px" : (menu?.level * 25),
                                                cursor: menu?.isDisabled == "1" ? 'default' : '',
                                                background: menu?.isDisabled == "1" ? '#D9D9D9' : '#FFFFFF',
                                            }}>{menu?.menuDesc}</td>
                                            <CustomCheckAll loadingUpdate={loadingUpdate} menu={menu} head={holonTemplate?.records} holonTemplate={holonTemplate} />
                                            {
                                                holonTemplate?.records?.map((template, index) => {
                                                    return <Permission loadingUpdate={loadingUpdate} key={index} menu={menu} template={template} />
                                                })}
                                            <td style={{
                                                minWidth: 200,
                                                maxWidth: 200,
                                                width: 200,
                                                textAlign: "left",
                                                fontSize: 12,
                                                color: "#000",
                                                verticalAlign: "middle",
                                                borderRight: '0.5px solid #929292',
                                                padding: '12px 10px',
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 11,
                                                paddingLeft: menu?.level == 0 ? "10px" : (menu?.level * 25),
                                                cursor: menu?.isDisabled == "1" ? 'default' : '',
                                                background: menu?.isDisabled == "1" ? '#D9D9D9' : '#FFFFFF',
                                            }}>{menu?.href}</td>
                                        </tr>
                                    })}
                                </tbody> */}
                                    </table>
                                </form>
                            </FormProvider>
                        </div>
                        {
                            loading && listMenu?.records?.length == 0 ? <TableSkeleton /> : <div
                                className={`_custom_scroll_footer ${classes._customListFooter}`}
                                style={{
                                    position: 'sticky',
                                    zIndex: 11,
                                    maxHeight: 15,
                                    overflowY: 'hidden',
                                    bottom: 0,
                                }}
                            >
                                <table
                                    className="table"
                                    style={{ width: '100%', visibility: 'hidden' }}
                                >
                                    {/* <thead>
                                        <tr>
                                            <th style={{
                                                textAlign: "center",
                                                padding: "10px 0px",
                                                minHeight: 38,
                                                borderRight: "0.5px solid #929292",
                                                width: '200px',
                                                minWidth: '200px',
                                                maxWidth: '200px',
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 999,
                                                overflow: 'hidden',
                                                top: 0
                                            }} className={`${classes["title_header"]} ${classes['connect-line']}`}>
                                            </th>
                                            <th style={{
                                                textAlign: "center",
                                                padding: "10px 0px",
                                                minHeight: 38,
                                                borderRight: "0.5px solid #929292",
                                                width: '44px',
                                                minWidth: '44px',
                                                maxWidth: '44px',
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 999,
                                                overflow: 'hidden',
                                                top: 0
                                            }} className={`${classes["title_header"]} ${classes['connect-line']}`}>
                                            </th>
                                            {
                                                holonTemplate?.records?.map((v, index) => {
                                                    return <th key={index} style={{
                                                        minHeight: 38,
                                                        borderRight: "0.5px solid #929292",
                                                        width: '50px',
                                                        minWidth: '50px',
                                                        maxWidth: '50px',
                                                        height: '200px',
                                                        position: 'relative'
                                                    }} className={classes["title_header"]}>
                                                        <div className={classes['rotatabletext']}>{v?.name}</div>
                                                    </th>
                                                })}
                                            <th style={{
                                                textAlign: "center",
                                                padding: "10px 0px",
                                                minHeight: 38,
                                                borderRight: "0.5px solid #929292",
                                                width: '200px',
                                                minWidth: '200px',
                                                maxWidth: '200px',
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 999,
                                                overflow: 'hidden',
                                                top: 0
                                            }} className={`${classes["title_header"]} ${classes['connect-line']}`}>
                                            </th>
                                        </tr>
                                    </thead> */}
                                </table>
                            </div>
                        }

                    </div>
                }
            </div>
        </ContentLayout>
    )
}

export default MenuManagementContainer;