import useTrans from 'hooks/use-trans';
import React from 'react'
import BpFareTableManagementContainer from 'shared/container/bp-fare-table-management/BpFareTableManagementContainer';

function BpFareTableManagementPage() {
    const { trans } = useTrans();
    document.title = trans("bp_request_payment.bp_bill_management")
    return (
        <BpFareTableManagementContainer />
    )
}

export default BpFareTableManagementPage