import React, { useEffect, useMemo, useRef, useState } from 'react';
import EventPopup from 'utils/EventPopup';
import EventRegister, { EVENT_RENDER_POPUP, EVENT_SHOW_POPUP } from 'utils/EventRegister';
import { v4 as uuidv4 } from 'uuid'
import CommonPopup from '../common/popup/CommonPopup';

function PopupSection (props) {
    const ref = useRef(null)
    const [etag, setEtag] = useState(uuidv4())
    useEffect(() => {
        EventPopup.init(ref)
        var event = EventRegister.on(EVENT_RENDER_POPUP, () => {
            setEtag(uuidv4());
            handleScroll()
        })
        return () => {
            EventRegister.off(event)
        }
    }, [])
    const handleScroll = () => {
        if (Object.keys(EventPopup.popupList.refs).length > 0) {
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = "0px";
        } else {
            document.body.style.overflow = "visible";
            document.body.style.paddingRight = "0px";
        }
    }
    const handleClose = () => {
        EventPopup.removeLastPopup();
        handleScroll()
    }
    const handleCloseAllPopup = () => {
        EventPopup.removeAllPopup();
    }
    useEffect(() => {
        let eventName = EVENT_SHOW_POPUP;
        const reloadEvent = EventRegister.on(eventName, (params) => {
            if (params.newWindow) {
                EventPopup.open({ component: <CommonPopup type={params.type} payload={params.payload} closeAll={handleCloseAllPopup} close={handleClose} /> }, () => { })
            } else {
                EventPopup.replace({ component: <CommonPopup type={params.type} payload={params.payload} closeAll={handleCloseAllPopup} close={handleClose} /> }, () => { })
            }
        });
        return () => {
            EventRegister.off(reloadEvent);
        };
    }, []);
    return useMemo(() => {
        return <div className="PopupSection" ref={ref}>
            {
                Object.keys(EventPopup.popupList.refs).map((id) => {
                    return <div key={id}>
                        {EventPopup.popupList.refs[id]?.component}
                    </div>
                })
            }
        </div>
    }, [etag])
}
export default PopupSection;