import React, { useEffect } from "react";
// import IconCheckBoxTable from "../common/icons/IconCheckBoxTable";
// import IconChecked from "../common/icons/IconChecked";
import classes from "./CustomTable.module.scss";
import useCustomState from "hooks/use-custom-state";
import IconChevronDown from "assets/icon/IconChevronDown";
import IconChevronUp from "assets/icon/IconChevronUp";
import IconChecked from "assets/icon/IconChecked";
import IconCheck from "assets/icon/IconCheck";
function CustomHeader(props) {
    const {
        isTop,
        headCell,
        dataSort,
        onChangeSortColumn,
        dataSelected,
        dataTable = [],
        handleSelectAll,
        isCheckBox
    } = props;

    const [fieldSort, setFieldSort] = useCustomState({
        field: null,
        type: null,
    });

    useEffect(() => {
        if (dataSort?.field && dataSort?.type) {
            setFieldSort({
                field: dataSort?.field,
                type: dataSort?.type,
            });
        } else {
            setFieldSort({
                field: null,
                type: null,
            });
        }
    }, [dataSort]);

    const handleFilter = (_field, _type, _options) => {
        setFieldSort({
            field: _field,
            type: _type,
        });
        onChangeSortColumn &&
            onChangeSortColumn({
                field: _field,
                type: _type,
                optionSort: _options,
            });
    };

    const handleResetFilter = (_field) => {
        setFieldSort({
            field: _field,
            type: null,
        });
        onChangeSortColumn &&
            onChangeSortColumn({
                field: _field,
                type: null,
            });
    };

    let isCheckedAll = dataSelected?.length > 0 && dataSelected?.length == dataTable?.length ? true : false;
    return (
        <thead
            className={`${classes.TableHeaderFilter} _custom_scroll_header`}
            style={{ top: isTop ? 0 : 50 }}
        >
            <tr>
                {isCheckBox && (
                    <td
                        style={{
                            minWidth: 90,
                            maxWidth: 90,
                            width: 90,
                            textAlign: "center",
                            color: "#000",
                            padding: "12px 0px",
                            verticalAlign: "middle",
                        }}
                        className={`${classes["title_header"]} ${classes["title_header_checkbox"]}`}
                    >
                        <div
                            onClick={() => {
                                handleSelectAll();
                            }}
                        >
                            {!isCheckedAll ? (
                                <IconCheck fontSize={15} />
                            ) : (
                                <IconChecked fontSize={15} />
                            )}
                        </div>
                    </td>
                )}
                {headCell?.map((x, index) => {
                    return (
                        <td
                            key={index}
                            style={{
                                minWidth: index == 0 ? x?.minWidth : x?.minWidth,
                                width: index == 0 ? x?.width : x?.width,
                                maxWidth: index == 0 ? x?.maxWidth : x?.maxWidth,
                                alignItems: "center",
                                position: x?.isSticky ? "sticky" : "unset",
                                left: x?.isSticky ? "0px" : "unset",
                                zIndex: x?.isSticky ? 2 : 1,
                                textAlign: x?.alignHeader ?? "center",
                                ...x?.styleHeader,
                                padding: "0px 20px",
                                fontWeight: 400,
                                height: 30,
                            }}
                            className={classes["title_header"]}
                        >
                            <div className={classes["title_header_content"]}
                                style={{
                                    justifyContent: x?.alignHeader == 'left' ? 'flex-start' : x?.alignHeader == 'right' ? 'flex-end' : 'center',
                                    fontWeight: 400,
                                    height: 30,
                                }}
                            >
                                {typeof x?.label == "function" ? <x.label /> : x.label}
                                {x?.sort ? (
                                    <div
                                        style={{
                                            pointerEvents: x?.disabledSort ? "none" : "fill",
                                            cursor: x?.disabledSort ? "default" : "unset",
                                        }}
                                    >
                                        {((fieldSort?.field == x?.field && !fieldSort?.type) ||
                                            fieldSort?.field != x?.field) && (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleFilter(
                                                        x?.field,
                                                        "DESC",
                                                        x?.optionSort ?? { SortBy: x["field"] }
                                                    );
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className={classes["Icon_Sort"]}
                                            >
                                                <IconChevronDown fontSize={12} color="white" />
                                            </div>
                                        )}
                                        {fieldSort?.type?.toUpperCase() == "DESC".toUpperCase() &&
                                            fieldSort?.field == x?.field && (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleFilter(
                                                        x?.field,
                                                        "ASC",
                                                        x?.optionSort ?? { SortBy: x["field"] }
                                                    );
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className={`${classes["Icon_Sort"]} ${classes["Icon_Sort_DESC"]}`}
                                            >
                                                <IconChevronDown fontSize={12} color="red" />
                                            </div>
                                        )}
                                        {fieldSort?.type?.toUpperCase() == "ASC".toUpperCase() &&
                                            fieldSort?.field == x?.field && (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleResetFilter(x?.field);
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className={`${classes["Icon_Sort"]} ${classes["Icon_Sort_ASC"]}`}
                                            >
                                                <IconChevronUp fontSize={12} color="red" />
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </td>
                    );
                })}
            </tr>
        </thead>
    );
}

export default CustomHeader;
