import React from 'react';

export default function IconCheck({ fontSize = 18 }) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fontSize={fontSize}
        >
            <ellipse cx="8.9926" cy="9" rx="8.99211" ry="9" fill="#D9D9D9" />
            <path
                d="M4.99609 8L8.49303 12L13.4886 6.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}
