import React from "react";

function IconCalendar() {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 5C4.17157 5 3.5 5.67157 3.5 6.5V20.5C3.5 21.3284 4.17157 22 5 22H19C19.8284 22 20.5 21.3284 20.5 20.5V6.5C20.5 5.67157 19.8284 5 19 5H5ZM2.5 6.5C2.5 5.11929 3.61929 4 5 4H19C20.3807 4 21.5 5.11929 21.5 6.5V20.5C21.5 21.8807 20.3807 23 19 23H5C3.61929 23 2.5 21.8807 2.5 20.5V6.5Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 2C16.2761 2 16.5 2.22386 16.5 2.5V6.5C16.5 6.77614 16.2761 7 16 7C15.7239 7 15.5 6.77614 15.5 6.5V2.5C15.5 2.22386 15.7239 2 16 2Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 2C8.27614 2 8.5 2.22386 8.5 2.5V6.5C8.5 6.77614 8.27614 7 8 7C7.72386 7 7.5 6.77614 7.5 6.5V2.5C7.5 2.22386 7.72386 2 8 2Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 10.5C2.5 10.2239 2.72386 10 3 10H21C21.2761 10 21.5 10.2239 21.5 10.5C21.5 10.7761 21.2761 11 21 11H3C2.72386 11 2.5 10.7761 2.5 10.5Z"
                fill="black"
            />
        </svg>
    );
}

export default IconCalendar;
