import React from 'react'
import classes from './CustomHeaderConfirm.module.scss';
import useTrans from 'hooks/use-trans';
import IconClose from 'assets/icon/IconClose';

function CustomHeaderConfirm(props) {
    const { showVisible, title } = props;
    const { t } = useTrans()
    return (
        <div className={`${classes["custom-header"]} custom-header-popup`}>
            <div>
                <p>{title ?? t("confirm_popup.confirm")}</p>
            </div>
            <div onClick={() => showVisible(false)} className={classes['icon']}>
                <IconClose fontSize={16} />
            </div>
        </div>
    )
}

export default CustomHeaderConfirm