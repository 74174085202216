import React from 'react';

export default function IconDelete({ fontSize = 17}) {
    return <svg
        width="1em"
        height="1em"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        fontSize={fontSize}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.2373 6.00012C3.2373 5.72398 3.46258 5.50012 3.74047 5.50012H21.8544C22.1323 5.50012 22.3576 5.72398 22.3576 6.00012C22.3576 6.27626 22.1323 6.50012 21.8544 6.50012H3.74047C3.46258 6.50012 3.2373 6.27626 3.2373 6.00012Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7848 2.50012C10.3845 2.50012 10.0005 2.65816 9.71744 2.93946C9.43436 3.22077 9.27532 3.6023 9.27532 4.00012V6.00012C9.27532 6.27626 9.05005 6.50012 8.77215 6.50012C8.49426 6.50012 8.26899 6.27626 8.26899 6.00012V4.00012C8.26899 3.33708 8.53405 2.7012 9.00586 2.23235C9.47767 1.76351 10.1176 1.50012 10.7848 1.50012H14.8101C15.4774 1.50012 16.1173 1.76351 16.5891 2.23235C17.0609 2.7012 17.326 3.33708 17.326 4.00012V6.00012C17.326 6.27626 17.1007 6.50012 16.8228 6.50012C16.5449 6.50012 16.3196 6.27626 16.3196 6.00012V4.00012C16.3196 3.6023 16.1606 3.22077 15.8775 2.93946C15.5944 2.65816 15.2105 2.50012 14.8101 2.50012H10.7848ZM5.75316 5.50012C6.03106 5.50012 6.25633 5.72398 6.25633 6.00012V20.0001C6.25633 20.3979 6.41537 20.7795 6.69845 21.0608C6.98154 21.3421 7.36548 21.5001 7.76582 21.5001H17.8291C18.2295 21.5001 18.6134 21.3421 18.8965 21.0608C19.1796 20.7795 19.3386 20.3979 19.3386 20.0001V6.00012C19.3386 5.72398 19.5639 5.50012 19.8418 5.50012C20.1197 5.50012 20.3449 5.72398 20.3449 6.00012V20.0001C20.3449 20.6632 20.0799 21.299 19.6081 21.7679C19.1363 22.2367 18.4964 22.5001 17.8291 22.5001H7.76582C7.09859 22.5001 6.45868 22.2367 5.98687 21.7679C5.51506 21.299 5.25 20.6632 5.25 20.0001V6.00012C5.25 5.72398 5.47527 5.50012 5.75316 5.50012Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7849 10.5001C11.0628 10.5001 11.2881 10.724 11.2881 11.0001V17.0001C11.2881 17.2763 11.0628 17.5001 10.7849 17.5001C10.507 17.5001 10.2817 17.2763 10.2817 17.0001V11.0001C10.2817 10.724 10.507 10.5001 10.7849 10.5001Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8103 10.5001C15.0882 10.5001 15.3135 10.724 15.3135 11.0001V17.0001C15.3135 17.2763 15.0882 17.5001 14.8103 17.5001C14.5324 17.5001 14.3071 17.2763 14.3071 17.0001V11.0001C14.3071 10.724 14.5324 10.5001 14.8103 10.5001Z"
            fill="currentColor"
        />
    </svg>
}
