import React from 'react'

function IconSendMsg() {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.68434 2.61226C1.83107 2.4928 2.03266 2.4664 2.2052 2.54404L22.2052 11.544C22.3846 11.6248 22.5 11.8033 22.5 12C22.5 12.1968 22.3846 12.3752 22.2052 12.456L2.2052 21.456C2.03266 21.5336 1.83107 21.5072 1.68434 21.3878C1.53761 21.2683 1.47088 21.0762 1.51192 20.8915L3.48782 12L1.51192 3.10847C1.47088 2.92377 1.53761 2.73172 1.68434 2.61226ZM4.4011 12.5L2.70451 20.1347L19.6705 12.5H4.4011ZM19.6705 11.5H4.4011L2.70451 3.86532L19.6705 11.5Z" fill="#333333" />
            </svg>
        </>
    )
}

export default IconSendMsg