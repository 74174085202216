import useTrans from 'hooks/use-trans'
import React from 'react'
import InventoryManagementContainer from 'shared/container/stocks/InventoryManagementContainer';

function InventoryManagementPage() {
    const { t } = useTrans();
    document.title=t("stocks.stocks_management")
    return (
        <InventoryManagementContainer />
    )
}

export default InventoryManagementPage