import React, { useEffect, useState } from 'react'
import Button from 'shared/components/form-input/button/Button'
import WrapContentBody from '../custom-body/WrapContentBody'
import BoxContentPopup from '../custom-body/BoxContentPopup';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import useTrans from 'hooks/use-trans';
import FormData from './FormData';
import BoxBody from '../../box/BoxBody';
import { useDispatch } from 'react-redux';
import actionTransaction from 'redux/transaction/action';
import { showPopupNotification } from 'utils/Utils';
import Box from '../../box/Box';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_TRANSACTION_DETAIL_POPUP } from 'utils/EventRegister';
import Constants from 'utils/Constants';

function TransactionUpdatePopup(props) {
    const { showVisible, payload } = props;
    const methods = useForm({
        defaultValues: {
            currency: 'JPY'
        }
    });
    const [loadingCreateInstance, setloadingCreateInstance] = useState(false);
    const [transactionDetail, setTransactionDetail] = useState(null);
    const { t } = useTrans();
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        let params = {
            ...data,
            evidence: data?.evidence?.map(x => x?.content)?.join(","),
            order: data?.order?.map(x => x?.name)?.join(","),
            wallet: data?.wallet?.map(x => x?.name)?.join(","),
            transactionType: parseInt(data?.transactionType),
            transactionBy: parseInt(data?.transactionBy),
            amount: parseInt(data?.amount),
            status: parseInt(data?.status),
            accountType: parseInt(data?.accountType),
            accountNo: data?.accountNo,
            id: transactionDetail?.id
        }
        showPopupNotification({
            message: t("E0042"),
            typePopup: 'confirm',
            newWindow: true,
            callback: () => {
                setloadingCreateInstance(true)
                dispatch({
                    type: actionTransaction.UPDATE_TRANSACTION_DETAIL,
                    payload: params,
                    callback: {
                        success: () => {
                            showVisible()
                            showPopupNotification({
                                message: t("E0043"),
                                typePopup: 'message',
                            });
                            setloadingCreateInstance(false);
                            payload?.callback();
                        },
                        failed: (msg) => {
                            showPopupNotification({
                                message: msg,
                                typePopup: 'message',
                                newWindow: true
                            });
                            setloadingCreateInstance(false)
                        }
                    }
                })
            }
        })
    }

    function condenseFilename(initial) {
        initial = initial?.split("/")?.[initial?.split("/")?.length - 1]
        var split = initial?.split('.');
        if (split?.length > 0) {
            let filename = split?.[0];
            var extension = initial?.split(".")[1];
            if (filename.length > 105) {
                return filename.substring(0, 100) + '...' + extension;
            }
        }
        return initial;
    }

    useEffect(() => {
        if (transactionDetail?.id) {
            methods.reset({
                ...transactionDetail,
                wallet: [],
                order: [],
                evidence: transactionDetail?.evidence?.split(",")?.map(x => ({ content: x, name: condenseFilename(x) }))
            })
        }
    }, [transactionDetail])

    useEffect(() => {
        if (payload?.id) {
            dispatch({
                type: actionTransaction.GET_TRANSACTION_DETAIL,
                payload: payload?.id,
                callback: (detail) => {
                    setTransactionDetail(detail);
                    dispatch({
                        type: actionTransaction.GET_HOLON_INSTANCE_ORDER_BY_CODE,
                        payload: {
                            code: Constants.HOLON_MASTER_CODE.ORDER,
                            name: detail?.order,
                        },
                        callback: (_order) => {
                            methods.setValue("order", [
                                {
                                    id: _order?.id,
                                    name: _order?.name,
                                }
                            ])
                        }
                    })
                    dispatch({
                        type: actionTransaction.GET_HOLON_INSTANCE_WALLET_BY_CODE,
                        payload: {
                            code: Constants.HOLON_MASTER_CODE.WALLET,
                            name: detail?.wallet,
                        },
                        callback: (_wallet) => {
                            methods.setValue("wallet", [
                                {
                                    id: _wallet?.id,
                                    name: _wallet?.name,
                                }
                            ])
                        }
                    })
                }
            });
        }
    }, [payload?.id])

    const handleOpenDetail = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_TRANSACTION_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                id: payload?.id,
                isShowOption: false,
                title: t("transaction.transaction_update"),
            }
        })
    }

    return (
        <BoxContentPopup minWidth={payload?.minWidth} maxWidth={payload?.maxWidth} width={payload?.width ? payload?.width : '1000px'} borderRadius='20px 0px 0px 20px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        style={payload?.style}
                        title={t("transaction.transaction_new")}
                        showVisible={showVisible}
                        handleBtnBack={() => {
                            handleOpenDetail(payload?.holonInstanceId, payload?.holonTemplateId)
                        }}
                        actionSectionBottom={<Button color='gray' loading={loadingCreateInstance} type='submit' title={t('save')} />}
                    >
                        <Box>
                            <BoxBody style={{
                                borderRadius: 10
                            }}>
                                <FormData />
                            </BoxBody>
                        </Box>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default TransactionUpdatePopup