import React from 'react'

function IconZoom({ fontSize = 16}) {
    return (
        <svg fontSize={fontSize} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6263 9.52136C14.5157 9.52136 14.4066 9.53795 14.3021 9.57042C14.2343 9.59155 14.1684 9.61936 14.106 9.6532C14.0137 9.70352 13.9292 9.76661 13.8547 9.84095C13.7803 9.91539 13.7171 10 13.6669 10.0921C13.5806 10.251 13.535 10.4309 13.535 10.6125V11.4256L13.5328 11.7575L13.5279 11.9846L9.86234 8.31898C9.4368 7.89367 8.74455 7.89367 8.31915 8.31898C7.89362 8.74452 7.89362 9.43689 8.31915 9.86217L10.4716 12.0146L11.3596 12.9059L11.9814 13.5349H10.6127C10.5021 13.5349 10.3931 13.5515 10.2884 13.584C10.2207 13.6051 10.1548 13.6329 10.0925 13.6667C10.0001 13.717 9.91546 13.7801 9.84112 13.8546C9.76677 13.929 9.70355 14.0135 9.65337 14.1059C9.56705 14.2645 9.52143 14.4444 9.52155 14.626C9.52155 14.7734 9.55037 14.9162 9.60737 15.0509C9.63471 15.1155 9.66865 15.1779 9.70809 15.2364C9.7868 15.3529 9.8858 15.452 10.0024 15.5308C10.1831 15.6528 10.394 15.7174 10.6127 15.7174H14.6366C14.9252 15.7174 15.1967 15.6048 15.4008 15.4007C15.6051 15.1966 15.7175 14.9251 15.7175 14.6364V10.6125C15.7174 10.011 15.2281 9.52148 14.6263 9.52136Z" fill="#EBEBEB" />
            <path d="M7.39831 5.85494L5.23572 3.69234L4.35094 2.80441L3.73597 2.18222H5.10484C5.21531 2.18222 5.32447 2.16562 5.42906 2.13316C5.49669 2.11203 5.56262 2.08422 5.625 2.05041C5.71741 2.00034 5.80188 1.93709 5.87634 1.86266C5.95078 1.78819 6.01391 1.70372 6.06409 1.61144C6.15041 1.45262 6.19591 1.27269 6.19591 1.09106C6.19591 0.943719 6.16697 0.800999 6.10997 0.666249C6.08278 0.601562 6.04894 0.539187 6.00953 0.480719C5.93069 0.36425 5.83156 0.265156 5.71509 0.186406C5.53441 0.0644686 5.32337 0 5.10484 0H1.08094C0.79225 0 0.52075 0.112437 0.316656 0.316531C0.112437 0.520625 0 0.792 0 1.08081V5.10462C0 5.70644 0.4895 6.19581 1.09119 6.19581C1.20178 6.19581 1.31091 6.17944 1.41541 6.14675C1.48303 6.12562 1.54906 6.09794 1.61144 6.06397C1.70375 6.01391 1.78834 5.95069 1.86278 5.87622C1.93713 5.80191 2.00034 5.71716 2.05053 5.62503C2.13684 5.46634 2.18247 5.28641 2.18234 5.10462L2.18247 4.29556L2.18466 3.95875L2.18953 3.73256L5.85519 7.39819C6.2805 7.8235 6.97287 7.8235 7.39828 7.39819C7.82372 6.97259 7.82372 6.28044 7.39831 5.85494Z" fill="#EBEBEB" />
        </svg>
    )
}

export default IconZoom