import useTrans from 'hooks/use-trans';
import React from 'react'
import CreateProductContainer from 'shared/container/product/CreateProductContainer'

function CreateProductPage() {
    const { t } = useTrans();
    document.title = t("product.product_create")
    return (
        <CreateProductContainer />
    )
}

export default CreateProductPage