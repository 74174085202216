import React from 'react'
import Box from '../../box/Box'
import BoxHeader from '../../box/BoxHeader'
import BoxTitle from '../../box/BoxTitle'
import BoxBody from '../../box/BoxBody'
import BoxRow from '../../box/BoxRow'
import BoxLabel from '../../box/BoxLabel'
import BoxContent from '../../box/BoxContent'
import FormSelectInstance from 'shared/components/custom-form/FormSelectInstance'
import useTrans from 'hooks/use-trans'
import Constants from 'utils/Constants'
import Validator from 'utils/Validator'

function FormConnections({ data = [], isCreate = false , id, connections_key}) {
    const { t } = useTrans();
    return (
        <Box>
            <BoxHeader id={connections_key}>
                <BoxTitle>{t("holon.related_information")}</BoxTitle>
            </BoxHeader>
            <BoxBody style={{ gap: 0 }} padding="14px 0px" id={connections_key}>
                {
                    data?.map((x, index) => {
                        let validate = [];
                        if (x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                            validate.push(Validator.requiredSelectTemplate)
                        }
                        return <BoxRow style={{
                            padding: '5px 19px',
                            background: index % 2 == 0 ? 'white' : '#F2F2F2'
                        }} key={index}>
                            <BoxLabel isRequired={x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE}>{x?.note}</BoxLabel>
                            <BoxContent>
                                <FormSelectInstance 
                                    connectionKey={x?.key} 
                                    id={id} 
                                    isRegexLabel={x?.note} 
                                    validationFE 
                                    validate={validate} 
                                    isCreate={isCreate} 
                                    isMulti={x?.isMultiSelected == "1" ? true : false} 
                                    disabled={(x?.holonTemplate?.length == 0 || x?.isEditabled == "0") ? true : false} 
                                    holonTemplate={x?.holonTemplate} 
                                    fieldName={`connections.${index}.instance`}
                                    isSelectForConnection={"1"}
                                />
                            </BoxContent>
                        </BoxRow>
                    })
                }
            </BoxBody>
        </Box>
    )
}

export default FormConnections