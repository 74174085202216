import React from 'react'
import BoxContentPopup from '../custom-body/BoxContentPopup'
import WrapContentBody from '../custom-body/WrapContentBody'
import useTrans from 'hooks/use-trans'
import classes from './AutoBillPayPopup.module.scss'
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import Validator from 'utils/Validator';
import CustomTable from '../../custom-table/CustomTable';
import Button from 'shared/components/form-input/button/Button'

function AutoBillPayPopup(props) {
    const { t } = useTrans();
    const { payload, showVisible } = props;

    let headCell = [
        {
            label: t('holon.hex_id'),
            field: "code",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            align: 'left',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            component: (_props) => {
                return <>{_props?.children}</>
            }
        },
        {
            label: t('holon.execution_result'),
            field: "isSuccess",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            alignHeader: 'left',
            align: 'left',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            component: (_props) => {
                return <>{_props?.children ? t("holon.success") : t('holon.there_is_an_error')}</>
            }
        },
        {
            label: t('holon.message'),
            field: "errorMessage",
            minWidth: '330px',
            width: '330px',
            maxWidth: '330px',
            alignHeader: 'left',
            align: 'left',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            component: (_props) => {
                return <>{_props?.children}</>
            }
        },
    ]
    return (
        <BoxContentPopup >
            <WrapContentBody
                isUseBackground={false}
                showVisible={showVisible}
                title={t("holon.batch_result_processing")}
                useForm={false}
                actionSectionBottom={<Button color='gray' onClick={() => showVisible(false)} title={t("Holon_Template.close")} />}
            >

                <div className={classes['wrap-body']}>
                    <BoxRow>
                        <BoxLabel minWidth='100%' >{Validator.renderMessage(t("holon.please_check_the_result"), {
                            0: payload?.note
                        })}</BoxLabel>
                    </BoxRow>
                    <CustomTable headCell={headCell} data={payload?.data} />
                </div>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default AutoBillPayPopup