import React from 'react'

function IconSortDown({ fontSize = 24 }) {
    return (
        <svg fontSize={fontSize} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16L12 20.5L16 16" stroke="#3A3A3A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 3.5V20.33" stroke="#3A3A3A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconSortDown