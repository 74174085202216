import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import classes from './CreatedHolonInstance.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useFieldArray, useForm } from 'react-hook-form';
import Constants from 'utils/Constants';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import WrapContentBody from '../custom-body/WrapContentBody';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxLabel from '../../box/BoxLabel';
import BoxRow from '../../box/BoxRow';
import BoxContent from '../../box/BoxContent';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import FormConnections from './FormConnections';
import FormAttributes from './FormAttributes';
import FormAttachment from './FormAttachment';
import FormSelect from 'shared/components/custom-form/FormSelect';
import actionHolonTemplate from 'redux/holon-template/action';
import actionCargo from 'redux/cargo/action';
import { getLoggedInUserInfoFromToken, showPopupNotification } from 'utils/Utils';
import { v4 as uuidv4 } from 'uuid'
import Validator from 'utils/Validator';
import moment from 'moment';
import EventPopup from 'utils/EventPopup';

function CreatedHolonInstancePopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const methods = useForm();
    const control = methods.control;
    const dispatch = useDispatch();
    const [holonTemplateDetail, setHolonTemplateDetail] = useState({});
    const [loadingCreateInstance, setLoadingCreateInstance] = useState(false);

    const [category, setCategory] = useState([])
    const [listHolonTemplate, setListHolonTemplate] = useState([])
    const { categories } = useSelector(state => state?.holonTemplate);

    const {
        fields: fieldsHas,
    } = useFieldArray({
        control, name: "attributes"
    });

    const {
        fields: fieldsConnection,
    } = useFieldArray({
        control, name: "connections"
    });

    const {
        fields: fieldsAttachment,
        append: appendAttachment,
        remove: removeAttachments,
    } = useFieldArray({
        control, name: "attachments"
    });

    useEffect(() => {
        if (payload?.typeCategory) {
            methods.setValue("selectedCategory", payload?.typeCategory);
            if (payload?.dataTemplate?.length > 0) {
                setListHolonTemplate(payload?.dataTemplate?.map(x => {
                    return {
                        key: x?.id,
                        label: x?.name,
                        value: x?.id
                    }
                }))
            } else {
                dispatch({
                    type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT_POPUP,
                    payload: {
                        [Constants.QueryParams.CATEGORY.VALUE]: payload?.typeCategory,
                        isAll: true
                    },
                    callback: {
                        success: (data) => {
                            if (data?.length > 0) {
                                setListHolonTemplate(data?.map(x => {
                                    return {
                                        key: x?.id,
                                        label: x?.name,
                                        value: x?.id
                                    }
                                }));
                            }
                        }
                    }
                })
            }
        }
    }, [payload?.typeCategory, payload?.dataTemplate])

    useEffect(() => {
        const loggedInUser = getLoggedInUserInfoFromToken();
        let connection =
            [{
                key_connection: Constants.CONNECTION_KEY.CREATE_BY.VALUE,
                instance: [{
                    name: loggedInUser?.rolename,
                    id: loggedInUser?.roleid,
                }]
            },
            {
                key_connection: Constants.CARGO_MASTER_CODE.CARD_BOARD.CONNECTION_KEY.ACCOUNT,
                instance: [{
                    name: loggedInUser?.rolename,
                    id: loggedInUser?.roleid,
                }]
            }];
        let warehouse = localStorage.getItem("belong_to_warehouse");
        warehouse = warehouse ? JSON.parse(warehouse) : null;
        if (warehouse?.id) {
            connection.push({
                key_connection: Constants.CARGO_MASTER_CODE.CARD_BOARD.CONNECTION_KEY.WAREHOUSE,
                instance: [{
                    id: warehouse?.id,
                    name: warehouse?.name
                }]
            },)
            connection.push({
                key_connection: Constants.CARGO_MASTER_CODE.CARD_BOARD.CONNECTION_KEY.WAREHOUSE_CARBOAD,
                instance: [{
                    id: warehouse?.id,
                    name: warehouse?.name
                }]
            },)
        }
        let _dataPreset = payload?.dataPreset?.data?.connections ?
            Object.keys(payload?.dataPreset?.data?.connections)?.map(key => ({
                key_connection: key,
                instance: payload?.dataPreset?.data?.connections[key]
            })) : [];

        connection = connection?.map(x => {
            let index = _dataPreset?.findIndex(v => v?.key_connection == x?.key_connection);
            if (index != -1) {
                return {
                    ...x,
                    instance: _dataPreset[index]?.instance?.map(key => {
                        return {
                            id: key?.value,
                            name: key?.name
                        }
                    })
                }
            }
            return x;
        });

        if (!payload?.dataPreset?.templateId && !payload?.dataClone?.id && !payload?.dataOcr) {
            if (holonTemplateDetail?.id) {
                methods.setValue("attributes", holonTemplateDetail?.validAttributes?.map(x => {
                    if (x?.elementType == Constants.ELEMENT_TYPE.AUTOGEN.VALUE) {
                        return {
                            ...x,
                            [x?.key]: ""
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                        let _value = "";
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE) {
                            _value = new Date();
                        }
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_60D) {
                            _value = moment(new Date()).add(60, 'days');
                        }
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_2M) {
                            _value = moment(new Date()).add(2, 'months');
                        }
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_1Y) {
                            _value = moment(new Date()).add(1, 'years');
                        }
                        return {
                            ...x,
                            [x?.key]: _value
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
                        let _value = "";
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATETIME.DEFAULT_VALUE) {
                            _value = new Date();
                        }
                        return {
                            ...x,
                            [x?.key]: _value
                        }
                    }

                    if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                        return {
                            ...x,
                            [x?.key]: []
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                        return {
                            ...x,
                            [x?.key]: []
                        }
                    }

                    if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_AIR.VALUE) {
                        return {
                            ...x,
                            [x?.key]: ["", "", "", "", "", "", ""]
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_SEA.VALUE) {
                        return {
                            ...x,
                            [x?.key]: ["", "", "", "", "", "", ""]
                        }
                    }

                    if (x?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
                        if (x?.defaultValue?.indexOf("{") == 0) {
                            return {
                                ...x,
                                [x?.key]: ""
                            }
                        }
                        return {
                            ...x,
                            [x?.key]: x?.defaultValue || ""
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.TEXT.VALUE) {
                        if (x?.defaultValue?.indexOf("{") == 0) {
                            return {
                                ...x,
                                [x?.key]: ""
                            }
                        }
                        return {
                            ...x,
                            [x?.key]: x?.defaultValue || ""
                        }
                    }

                    return {
                        ...x,
                        [x?.key]: x?.defaultValue || ""
                    }
                }))
                methods.setValue("connections", holonTemplateDetail?.validConnections?.map(x => {
                    let indexConnectionKey = connection?.findIndex(ck => ck?.key_connection == x?.key);
                    if (indexConnectionKey != -1) {
                        return {
                            ...x,
                            instance: connection[indexConnectionKey].instance
                        }
                    }
                    return {
                        ...x,
                    }
                }))
            }
        } else if (payload?.dataClone?.id) {
            let dataClone = payload?.dataClone;
            let objectDataCloneAttributes = dataClone?.attributes;

            methods.setValue("attributes", holonTemplateDetail?.validAttributes?.map(x => {
                let indexDetail = objectDataCloneAttributes?.findIndex(attribute => attribute.name == x?.key);
                if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                    return {
                        ...x,
                        [x?.key]: Constants.PASSWORD.VALUE,
                        currentPassword: objectDataCloneAttributes[indexDetail]?.value ?? "",
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.AUTOGEN.VALUE) {
                    return {
                        ...x,
                        [x?.key]: "",
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_AIR.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_SEA.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value?.split(",")
                    }
                }
                return {
                    ...x,
                    [x?.key]: objectDataCloneAttributes[indexDetail]?.value ?? ""
                }
            }))

            let holonInstanceConnection = payload?.dataClone?.connections;

            const grouped = holonInstanceConnection.reduce((rv, x) => {
                const groupIndex = rv?.findIndex((a) => a.key === x['type']);
                if (groupIndex !== -1) {
                    if (x?.holons?.length > 0) {
                        x?.holons?.map(_data => {
                            rv[groupIndex]?.holons?.push(_data);
                        });
                    }
                } else {
                    const newGroup = {
                        key: x['type'],
                        ...x,
                        holons: [],
                    };
                    if (x?.holons?.length > 0) {
                        newGroup.holons = x?.holons;
                    }
                    rv.push(newGroup);
                }
                return rv;
            }, []);

            methods.setValue("connections", holonTemplateDetail?.validConnections?.map(x => {
                // let indexDetail = grouped?.findIndex(connection => connection.type == x?.key);
                return {
                    ...x,
                    instance: []
                }
            }));

            // methods.setValue("attachments", dataClone?.attachments)
        } else if (payload?.dataOcr) {
            let objectDataCloneAttributes = Object.keys(payload?.dataOcr?.attributes).map(key => {
                return {
                    name: key,
                    value: payload?.dataOcr?.attributes[key]
                }
            });

            methods.setValue("attributes", holonTemplateDetail?.validAttributes?.map(x => {
                let indexDetail = objectDataCloneAttributes?.findIndex(attribute => attribute.name == x?.key);
                if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                    return {
                        ...x,
                        [x?.key]: Constants.PASSWORD.VALUE,
                        currentPassword: objectDataCloneAttributes[indexDetail]?.value ?? "",
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                    let _value = "";
                    if (objectDataCloneAttributes[indexDetail]?.value) {
                        _value = objectDataCloneAttributes[indexDetail]?.value;
                    } else {
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE) {
                            _value = new Date();
                        }
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_60D) {
                            _value = moment(new Date()).add(60, 'days');
                        }
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_2M) {
                            _value = moment(new Date()).add(2, 'months');
                        }
                        if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_1Y) {
                            _value = moment(new Date()).add(1, 'years');
                        }
                    }
                    return {
                        ...x,
                        [x?.key]: _value
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.AUTOGEN.VALUE) {
                    return {
                        ...x,
                        [x?.key]: "",
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_SEA.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_AIR.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectDataCloneAttributes[indexDetail]?.value?.split(",")
                    }
                }
                return {
                    ...x,
                    [x?.key]: objectDataCloneAttributes[indexDetail]?.value ?? x?.defaultValue
                }
            }));

            methods.setValue("connections", holonTemplateDetail?.validConnections?.map(x => {
                let indexConnectionKey = connection?.findIndex(ck => ck?.key_connection == x?.key);
                if (indexConnectionKey != -1) {
                    return {
                        ...x,
                        instance: connection[indexConnectionKey].instance
                    }
                }
                return {
                    ...x,
                }
            }))
        }
        else {
            let dataPreset = payload?.dataPreset?.data;
            let objectPresetAttributes = Object.keys(dataPreset?.attributes)?.map(key => {
                return {
                    name: key,
                    value: dataPreset?.attributes[key]
                }
            });
            methods.setValue("attributes", holonTemplateDetail?.validAttributes?.map(x => {
                let indexDetail = objectPresetAttributes?.findIndex(attribute => attribute.name == x?.key);
                if (x?.elementType == Constants.ELEMENT_TYPE.AUTOGEN.VALUE) {
                    return {
                        ...x,
                        [x?.key]: ""
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectPresetAttributes[indexDetail]?.value == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                    return {
                        ...x,
                        [x?.key]: Constants.PASSWORD.VALUE,
                        currentPassword: objectPresetAttributes[indexDetail]?.value ?? "",
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectPresetAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectPresetAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_AIR.VALUE) {
                    return {
                        ...x,
                        [x?.key]: ["", "", "", "", "", "", ""]
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_SEA.VALUE) {
                    return {
                        ...x,
                        [x?.key]: ["", "", "", "", "", "", ""]
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                    let _value = "";
                    if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE) {
                        _value = new Date();
                    }
                    if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_60D) {
                        _value = moment(new Date()).add(60, 'days');
                    }
                    if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_2M) {
                        _value = moment(new Date()).add(2, 'months');
                    }
                    if (x?.defaultValue == Constants.ELEMENT_TYPE.DATE.DEFAULT_VALUE_1Y) {
                        _value = moment(new Date()).add(1, 'years');
                    }
                    return {
                        ...x,
                        [x?.key]: _value
                    }
                }

                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
                    if (!objectPresetAttributes[indexDetail]?.value) {
                        if (x?.defaultValue?.indexOf("{") == 0) {
                            return {
                                ...x,
                                [x?.key]: ""
                            }
                        }
                        return {
                            ...x,
                            [x?.key]: x?.defaultValue || ""
                        }
                    }
                }

                if (x?.elementType == Constants.ELEMENT_TYPE.TEXT.VALUE) {
                    if (x?.defaultValue?.indexOf("{") == 0) {
                        return {
                            ...x,
                            [x?.key]: ""
                        }
                    }
                    return {
                        ...x,
                        [x?.key]: x?.defaultValue || ""
                    }
                }

                return {
                    ...x,
                    [x?.key]: objectPresetAttributes[indexDetail]?.value ?? x?.defaultValue
                }
            }))

            let holonInstance = Object.keys(dataPreset?.connections)?.map(key => {
                return {
                    instanceList: dataPreset?.connections[key]?.map(x => {
                        return {
                            id: x?.value,
                            name: x?.name
                        }
                    }),
                    key: key
                };
            });

            let presetConnection = [];
            holonTemplateDetail?.validConnections?.forEach(x => {
                let index = holonInstance?.findIndex(v => v?.key == x?.key);

                let indexConnectionKey = connection?.findIndex(ck => ck?.key_connection == x?.key);
                if (indexConnectionKey != -1) {
                    return presetConnection.push({
                        ...x,
                        instance: connection[indexConnectionKey].instance
                    })
                }

                if (index != -1) {
                    return presetConnection.push({
                        ...x,
                        holons: holonInstance[index]?.instanceList,
                        instance: holonInstance[index]?.instanceList
                    })
                }
                return presetConnection.push({
                    ...x,
                    holons: [],
                    instance: []
                })
            })
            methods.setValue("connections", presetConnection);

        }
    }, [holonTemplateDetail, payload?.dataPreset, payload?.dataClone])

    useEffect(() => {
        if (payload?.dataPreset?.templateId) {
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
                payload: payload?.dataPreset?.templateId,
                callback: (_data) => {
                    setHolonTemplateDetail(_data);
                }
            })
        }
        if (payload?.templateId && payload?.templateId != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
                payload: payload?.templateId,
                callback: (_data) => {
                    setHolonTemplateDetail(_data)
                }
            })
        }
    }, [payload?.dataPreset?.templateId, payload?.templateId])

    const onSubmit = (data) => {
        let attributes = data?.attributes?.map(x => {
            if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                return {
                    value: x?.[x?.key]?.length > 0 ? x?.[x?.key]?.join(",") : "",
                    name: x?.key,
                    elementType: x?.elementType
                }
            }
            if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                return {
                    value: x?.[x?.key]?.length > 0 ? x?.[x?.key]?.join(",") : "",
                    name: x?.key,
                    elementType: x?.elementType
                }
            }
            if (x.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
                let description = "";
                if (localStorage.getItem(x?.key) && localStorage.getItem(x?.key) != "undefined") {
                    description = localStorage.getItem(x?.key);
                }
                return {
                    value: x?.[x?.key],
                    name: x?.key,
                    description: description,
                    elementType: x?.elementType
                }
            }
            return {
                value: x?.[x?.key],
                name: x?.key,
                elementType: x?.elementType
            }
        });

        let connections = data?.connections?.map(x => {
            return {
                name: x?.key,
                type: x?.key,
                fromName: x?.fromName,
                toName: x?.toName,
                role: x?.role,
                holons: x?.instance?.map(holon => {
                    return {
                        id: holon?.id
                    }
                })
            }
        });
        let params = {
            name: holonTemplateDetail?.name,
            description: holonTemplateDetail?.description,
            templateId: holonTemplateDetail?.id,
            type: holonTemplateDetail?.type,
            attributes: attributes,
            connections: connections,
            attachments: data?.attachments
        }

        if (payload?.dataOcr?.attachments) {
            params['attachments'] = payload?.dataOcr?.attachments
        }
        if (payload?.callbackResult) {
            payload?.callbackResult(params);
            EventPopup.removeLastPopup()
        } else
            if (payload?.dataBox?.actionType) {
                setLoadingCreateInstance(true);
                dispatch({
                    type: payload?.dataBox?.actionType,
                    payload: {
                        ...params,
                        mode: payload?.dataBox?.actionPayload?.mode
                    },
                    callback: {
                        success: () => {
                            setLoadingCreateInstance(false);
                            showVisible(false)
                            if (payload?.dataBox?.callback) {
                                payload?.dataBox?.callback()
                            }
                            showPopupNotification({
                                callback: () => { },
                                typePopup: 'message',
                                newWindow: false,
                                message: t("E0041")
                            })
                        },
                        failed: (msg) => {
                            setLoadingCreateInstance(false)
                            showPopupNotification({
                                callback: () => { },
                                typePopup: 'message',
                                newWindow: true,
                                message: msg
                            })
                        }
                    }
                })
            } else {
                setLoadingCreateInstance(true)
                dispatch({
                    type: actionsHolonInstance.CREATE_HOLON_INSTANCE,
                    payload: params,
                    callback: {
                        success: () => {
                            setLoadingCreateInstance(false)
                            if (payload?.callback) {
                                payload?.callback();
                            }
                            if (payload?.dataBox?.callback) {
                                payload?.dataBox?.callback()
                            }
                            showVisible(false)

                            showPopupNotification({
                                callback: () => { },
                                typePopup: 'message',
                                newWindow: true,
                                message: t("E0041")
                            })
                        },
                        failed: (msg) => {
                            setLoadingCreateInstance(false)
                            showPopupNotification({
                                callback: () => { },
                                typePopup: 'message',
                                newWindow: true,
                                message: msg
                            })
                        }
                    }
                })
            }
    }

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_MASTER_HOLON_TEMPLATE,
            payload: {}
        });
        dispatch({
            type: actionCargo.GET_WARE_HOUSE_DEFAULT,
            callback: {}
        })
    }, [])

    useEffect(() => {
        if (categories) {
            setCategory(Object.keys(categories)?.map(key => {
                return {
                    value: key,
                    key: key,
                    label: categories[key]
                }
            }));
        }

        return () => {
            setCategory([]);
        }
    }, [categories])

    useEffect(() => {
        return () => {
            setListHolonTemplate([]);
        }
    }, [])


    let attribute_key = `attribute_${uuidv4()}`;
    let cargo_key = `cargo_${uuidv4()}`;
    let connections_key = `connections_${uuidv4()}`;
    let attachment_key = `attachment_${uuidv4()}`;

    const renderTitlePopup = () => {
        if (payload?.isHolonTemplateName) {
            if (!holonTemplateDetail?.name) {
                return;
            }
            return Validator.renderMessage(t("holon.create_holon_instance_name"), {
                0: holonTemplateDetail?.name
            });
        }
        return payload?.title ?? t("holon.create_holon_instance");
    }

    return (
        <BoxContentPopup minWidth={payload?.minWidth} maxWidth={payload?.maxWidth} width={payload?.width ? payload?.width : '1000px'} borderRadius='20px 0px 0px 20px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        style={payload?.style}
                        title={renderTitlePopup()}
                        showVisible={showVisible}
                        actionSectionBottom={<Button disabled={holonTemplateDetail?.id ? false : true} color='gray' loading={loadingCreateInstance} type='submit' title={t('save')} />}
                    >
                        <div className={classes['wrap-body']}>
                            <div className={classes['wrap-form']}>
                                {
                                    payload?.isShowCategory && !(payload?.dataPreset?.templateId || payload?.templateId && payload?.templateId != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) && <Box>
                                        <BoxBody style={{
                                            borderRadius: 10
                                        }}>
                                            <BoxRow>
                                                <BoxLabel>
                                                    {t("Holon_Template.category")}
                                                </BoxLabel>
                                                <BoxContent>
                                                    <FormSelect
                                                        fieldName='selectedCategory'
                                                        validate={[]}
                                                        disabled={(payload?.typeCategory ? true : !payload?.templateId ? false : true)}
                                                        onChangeSelect={(e) => {
                                                            methods.setValue("selectedHolonTemplate", null);
                                                            setHolonTemplateDetail(null);
                                                            methods.setValue("attributes", [])
                                                            methods.setValue("connections", [])
                                                            dispatch({
                                                                type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT_POPUP,
                                                                payload: {
                                                                    [Constants.QueryParams.CATEGORY.VALUE]: e,
                                                                    isAll: true
                                                                },
                                                                callback: {
                                                                    success: (data) => {
                                                                        setListHolonTemplate(data?.map(x => {
                                                                            return {
                                                                                key: x?.id,
                                                                                label: x?.name,
                                                                                value: x?.id
                                                                            }
                                                                        }));
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        options={[...category]}
                                                    />
                                                </BoxContent>
                                            </BoxRow>
                                            <BoxRow>
                                                <BoxLabel>
                                                    {t("Holon_Template.template")}
                                                </BoxLabel>
                                                <BoxContent>
                                                    <FormSelect
                                                        fieldName='selectedHolonTemplate'
                                                        validate={[]}
                                                        disabled={(!payload?.templateId || payload?.templateId == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) ? false : true}
                                                        onChangeSelect={(e) => {
                                                            setHolonTemplateDetail(null);
                                                            methods.setValue("attributes", [])
                                                            methods.setValue("connections", [])
                                                            dispatch({
                                                                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
                                                                payload: e,
                                                                callback: (_data) => {
                                                                    setHolonTemplateDetail(_data)
                                                                }
                                                            })
                                                        }}
                                                        options={[...listHolonTemplate]}
                                                    />
                                                </BoxContent>
                                            </BoxRow>
                                        </BoxBody>
                                    </Box>
                                }
                                <FormAttributes attribute_key={attribute_key} isCreate={true} data={fieldsHas} />
                                {
                                    fieldsConnection?.length > 0 ? <FormConnections connections_key={connections_key} data={fieldsConnection} /> : <></>
                                }
                                {/* {
                                    (holonTemplateDetail?.id == Constants.CARGO_MASTER_CODE.CARD_BOARD.TEMPLATE_ID || payload?.isCargo) && <FormCargoProduct holonTemplateId={holonTemplateDetail?.id} cargo_key={cargo_key} attributes={methods.watch("attributes")} connections={methods.watch("connections")} />
                                } */}
                                {
                                    !payload?.dataOcr?.attachments && <FormAttachment attachment_key={attachment_key} removeActions={removeAttachments} appendAttachment={appendAttachment} data={fieldsAttachment} />
                                }
                            </div>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default CreatedHolonInstancePopup