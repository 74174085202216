import { convertDate, convertRegexToOptions } from "utils/Utils";
import CommonText from "../common/text/CommonText";
import ButtonFilter from "../form-input/button/ButtonFilter";
import Constants from "utils/Constants";
import IconMinusAccordionFilter from "assets/icon/IconMinusAccordionFilter";
import moment from "moment";
import { convertNumberToString } from "utils/String";
import React from 'react';
import ButtonLink from "../form-input/button/ButtonLink";

export const CommonDataFilter = (router, paramsTemplate, holonTemplateDetail) => {
    if (!holonTemplateDetail) {
        return;
    }
    try {
        let string = router.get(paramsTemplate);
        let data = JSON.parse(string);
        data = Object.keys(data)?.map(key => {
            return {
                label: key,
                key: key,
                value: data[key],
            }
        });

        const handleRemoveRouter = (_key) => {
            let index = data?.findIndex(x => x?.key == _key);
            if (index != -1) {
                data?.splice(index, 1);
            }

            let object = {};
            data.forEach((element) => {
                object[`${element?.key}`] = element?.value;
            });

            let params = { ...router.getAll() };
            if (Object.keys(object)?.length > 0) {
                params[paramsTemplate] = JSON.stringify(object)
            } else {
                delete params[paramsTemplate]
            }
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1
                }
            })
        }

        const mapLabel = (key) => {
            return [...holonTemplateDetail?.attributes]?.find(x => x?.key == key)?.label;
        }
        const mapValue = (_item) => {
            let data = [...holonTemplateDetail?.attributes]?.find(x => x?.key == _item?.key);

            if (data?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
                return convertRegexToOptions(data?.regex)?.find(option => option?.value == _item?.value)?.label
            }
            if (data?.elementType == Constants.ELEMENT_TYPE.RADIO.VALUE) {
                return convertRegexToOptions(data?.regex)?.find(option => option?.value == _item?.value)?.label
            }
            if (data?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
                return `${convertDate(_item?.value?.split(" ")?.[0])} ${_item?.value?.split(" ")?.[1]}`
            }
            if (data?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                return convertDate(_item?.value)
            }

            if (data?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                let options = convertRegexToOptions(data?.regex);
                let valueOptions = _item?.value?.split(",");
                return valueOptions?.map((_opValue, _index) => {
                    let index = options?.findIndex(item => item?.value == _opValue);
                    if (index != -1) {
                        return `${options[index]?.label}${valueOptions[_index + 1] ? ', ' : ''}`
                    }
                })
            }

            return _item?.value;
        }

        return data?.map((x, key) => {
            return <ButtonFilter key={key} onClick={() => handleRemoveRouter(x?.key)} color='white' size='large' endIcon={<IconMinusAccordionFilter />} >
                <div style={{
                    whiteSpace: 'nowrap'
                }}>{mapLabel(x?.key)}&nbsp;&nbsp;:</div>
                <CommonText>
                    {mapValue(x)}
                </CommonText>
            </ButtonFilter>
        })
    } catch (error) {
        return;
    }
}

export const CommonDataFilterHolonInstance = (router, paramsTemplate, holonTemplateDetail, setDataSearchWithAttributeTemp, dataWithParamTemplate) => {
    if (!holonTemplateDetail) {
        return;
    }
    try {
        let string = router[paramsTemplate];
        let data = JSON.parse(string);
        data = Object.keys(data)?.map(key => {
            return {
                label: key,
                key: key,
                value: data[key],
            }
        });

        const handleRemoveRouter = (_key) => {
            let index = data?.findIndex(x => x?.key == _key);
            if (index != -1) {
                data?.splice(index, 1);
            }

            let object = {};
            data.forEach((element) => {
                object[`${element?.key}`] = element?.value;
            });
            let params = {}
            if (router) {
                params = router;
            }

            if (Object.keys(object)?.length > 0) {
                params[paramsTemplate] = JSON.stringify(object)
            } else {
                delete params[paramsTemplate];
            }
            setDataSearchWithAttributeTemp({
                ...params,
                [Constants.QueryParams.OFFSET.VALUE]: 1
            });
        }

        const mapLabel = (key) => {
            return [...holonTemplateDetail?.attributes]?.find(x => x?.key == key)?.label;
        }
        const mapValue = (_item) => {
            let data = [...holonTemplateDetail?.attributes]?.find(x => x?.key == _item?.key);

            if (data?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
                return convertRegexToOptions(data?.regex)?.find(option => option?.value == _item?.value)?.label
            }
            if (data?.elementType == Constants.ELEMENT_TYPE.RADIO.VALUE) {
                return convertRegexToOptions(data?.regex)?.find(option => option?.value == _item?.value)?.label
            }
            if (data?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
                return `${convertDate(_item?.value?.split(" ")?.[0])} ${_item?.value?.split(" ")?.[1]}`
            }
            if (data?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                return convertDate(_item?.value)
            }

            if (data?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                let options = convertRegexToOptions(data?.regex);
                let valueOptions = _item?.value?.split(",");
                return valueOptions?.map((_opValue, _index) => {
                    let index = options?.findIndex(item => item?.value == _opValue);
                    if (index != -1) {
                        return `${options[index]?.label}${valueOptions[_index + 1] ? ', ' : ''}`
                    }
                })
            }

            return _item?.value;
        }

        return data?.map((x, key) => {
            return <ButtonFilter key={key} onClick={() => handleRemoveRouter(x?.key)} color='white' size='large' endIcon={<IconMinusAccordionFilter />} >
                <div style={{
                    whiteSpace: 'nowrap'
                }}>{mapLabel(x?.key)}&nbsp;&nbsp;:</div>
                <CommonText>
                    {mapValue(x)}
                </CommonText>
            </ButtonFilter>
        })
    } catch (error) {
        return;
    }
}

export const CommonRowTableHolonInstance = ({ attributeHeader, attributes, isCompare = false, isTitle = false }) => {
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
        return "*******";
    }

    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
        let options = convertRegexToOptions(attributeHeader?.regex);
        let valueOptions = attributes[attributeHeader?.key]?.value?.split(",");
        return valueOptions?.map((_opValue, _index) => {
            let index = options?.findIndex(item => item?.value == _opValue);
            if (index != -1) {
                return `${options[index]?.label}${valueOptions[_index + 1] ? ', ' : ''}`
            }
        })
    }

    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
        if (new Date(attributes[attributeHeader?.key]?.value) == "Invalid Date") {
            return ''
        }
        return attributes[attributeHeader?.key]?.value ? moment(attributes[attributeHeader?.key]?.value).format(Constants.FORMAT_DATE_TIME.DATE) : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
        if (new Date(attributes[attributeHeader?.key]?.value) == "Invalid Date") {
            return ''
        }
        return attributes[attributeHeader?.key]?.value ? moment(attributes[attributeHeader?.key]?.value).format(Constants.FORMAT_DATE_TIME.DATE_TIME) : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.MONEY.VALUE) {
        return attributes[attributeHeader?.key]?.value ? convertNumberToString(attributes[attributeHeader?.key]?.value, ",") : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.NUMBER.VALUE) {
        return attributes[attributeHeader?.key]?.value ? convertNumberToString(attributes[attributeHeader?.key]?.value, ",") : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.PERCENT.VALUE) {
        return attributes[attributeHeader?.key]?.value ? `${attributes[attributeHeader?.key]?.value}%` : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.URL.VALUE) {
        return isTitle ? attributes[attributeHeader?.key]?.value ? `${attributes[attributeHeader?.key]?.value}` : '' : <ButtonLink isHref>
            {attributes[attributeHeader?.key]?.value ? `${attributes[attributeHeader?.key]?.value}` : ''}
        </ButtonLink>
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
        if (isCompare) {
            let _find = convertRegexToOptions(attributeHeader?.regex)?.find(_option => _option?.value == attributes[attributeHeader?.key]?.value);
            return _find?.label ?? attributes[attributeHeader?.key]?.value;
        } else {
            if (attributes[attributeHeader?.key]?.description && !attributeHeader?.regex) {
                return attributes[attributeHeader?.key]?.description;
            }
            let _find = convertRegexToOptions(attributeHeader?.regex)?.find(_option => _option?.value == attributes[attributeHeader?.key]?.value);
            return _find?.label;
        }
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.RADIO.VALUE) {
        let _find = convertRegexToOptions(attributeHeader?.regex)?.find(_option => _option?.value == attributes[attributeHeader?.key]?.value);
        return _find?.label;
    }

    return attributes[attributeHeader?.key]?.value;
}