import React, { useEffect } from 'react'
import classes from './ListProductPopup.module.scss';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import FormInput from 'shared/components/custom-form/FormInput';
import Constants from 'utils/Constants';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
function TableRight(props) {
    const {
        errorMessage,
        fieldsChoice,
        cardboardType,
        onChangeQuantity,
        onChangePrice,
        onChangeCheckbox,
        onChangeNote,
        handleRemove } = props;
    let ignoreScrollEvents = false;
    const { t } = useTrans();

    const syncScroll = (...selectors) => {
        const wrapper = document.getElementById('product-right');
        if (wrapper && selectors.length > 1) {
            wrapper.addEventListener(
                'scroll',
                ({ target }) => {
                    if (target.classList.contains(selectors[0])) {
                        const ignore = ignoreScrollEvents;
                        ignoreScrollEvents = false;
                        if (ignore) {
                            return;
                        }
                        ignoreScrollEvents = true;
                        const { scrollLeft } = target;
                        selectors.forEach((selector) => {
                            if (wrapper.querySelector(`.${selector}`)) {
                                wrapper.querySelector(`.${selector}`).scrollLeft = scrollLeft;
                            }
                        });
                    }
                },
                true,
            );
        }
    };

    useEffect(() => {
        const executeSyncScroll = () => {
            syncScroll(
                '_custom_scroll_footer',
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_total',
            );
            syncScroll(
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_footer',
                '_custom_scroll_total',
            );
        };
        executeSyncScroll();
    }, []);

    return (
        <div style={{
            borderRadius: '10px 10px 0px 0px',
        }}>

            <div id='product-right' className={classes['custom-table']}>
                <div style={{
                    backgroundColor: '#FFC30D'
                }} className={`_custom_scroll_header ${classes['header']} ${classes['header-left']}`}>
                    <div style={{ minWidth: 400, width: 400, maxWidth: 400 }} className={classes['content-with']}>
                        {t("product.product_name")}
                    </div>
                    <div style={{ minWidth: 100, width: 100, maxWidth: 100 }} className={classes['content-with']}>
                        {t("product.quantity")}
                    </div>
                    <div style={{ minWidth: 100, width: 100, maxWidth: 100 }} className={classes['content-with']}>
                        {t("product.product_price")}
                    </div>
                    {
                        cardboardType && <div title={t("product.individual_export_declaration")} style={{ minWidth: 50, width: 50, maxWidth: 50 }} className={classes['content-with']}>
                            {t("product.individual_export_declaration")}
                        </div>
                    }
                    <div style={{ minWidth: 200, width: 200, maxWidth: 200 }} className={classes['content-with']}>
                        {t("product.note")}
                    </div>
                    <div style={{
                        width: 40,
                        minWidth: 40,
                        maxWidth: 40,
                        position: 'sticky',
                        right: 0,
                        background: '#FFC30D'
                    }} className={`${classes['content-with']} ${classes['content-remove']}`}></div>
                </div>
                <div className={`_custom_scroll_body ${classes['body']}`}>
                    {
                        fieldsChoice?.map((x, index) => {
                            return <div style={{ padding: '0px 0px' }} className={classes['custom-row']} key={index}>
                                <div title={x?.name} style={{ minWidth: 400, width: 400, maxWidth: 400 }} className={classes['content-with']}>
                                    {x?.name}
                                </div>
                                <div style={{ minWidth: 100, width: 100, maxWidth: 100 }} className={classes['content-with']}>
                                    <FormInput height="27px" max={x?.maxQuantity} onChangeValue={(e) => onChangeQuantity(e, x)} format={Constants.FormInputFormat.NUMBER.VALUE} fieldName={`listChoice.${index}.quantity`} />
                                </div>
                                <div style={{ minWidth: 100, width: 100, maxWidth: 100 }} className={classes['content-with']}>
                                    <FormInput format={Constants.FormInputFormat.NUMBER.VALUE} height="27px" onChangeValue={(e) => onChangePrice(e, x)} fieldName={`listChoice.${index}.price`} />
                                </div>
                                {
                                    cardboardType && <div style={{ minWidth: 50, width: 50, maxWidth: 50, display: 'flex', justifyContent: 'center' }} className={classes['content-with']}>
                                        <FormCheckbox onChangeValue={(e) => onChangeCheckbox(e, x)} fieldName={`listChoice.${index}.individualExportDeclaration`} />
                                    </div>
                                }
                                <div style={{ minWidth: 200, width: 200, maxWidth: 200 }} className={classes['content-with']}>
                                    <FormInput height="27px" onChangeValue={(e) => onChangeNote(e, x)} fieldName={`listChoice.${index}.note`} />
                                </div>
                                <div style={{
                                    width: 40,
                                    minWidth: 40,
                                    maxWidth: 40,
                                    position: 'sticky',
                                    right: '-1px',
                                }} className={`${classes['content-with']} ${classes['content-remove']}`}>
                                    <Button disabled={errorMessage ? true : false} className='btn-add' backgroundTransparent size='small' onClick={() => handleRemove(x)} color='white' startIcon={<IconMinusAccordion color="currentColor" />} />
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className={`_custom_scroll_footer ${classes['footer-left']}`}>
                    <div style={{ minWidth: 400, width: 400, maxWidth: 400 }} className={classes['content-with']}>
                        {t("product.product_name")}
                    </div>
                    <div style={{ minWidth: 100, width: 100, maxWidth: 100 }} className={classes['content-with']}>
                        {t("product.quantity")}
                    </div>
                    <div style={{ minWidth: 100, width: 100, maxWidth: 100 }} className={classes['content-with']}>
                        {t("product.product_price")}
                    </div>
                    {
                        cardboardType && <div style={{ minWidth: 50, width: 50, maxWidth: 50 }} className={classes['content-with']}>
                            {t("product.individual_export_declaration")}
                        </div>
                    }
                    <div style={{ minWidth: 200, width: 200, maxWidth: 200 }} className={classes['content-with']}>
                        {t("product.note")}
                    </div>
                    <div style={{
                        width: 40,
                        minWidth: 40,
                        maxWidth: 40,
                    }} className={classes['content-with']}></div>
                </div>
            </div>
        </div >
    )
}

export default TableRight