import React, { useEffect, useState } from 'react'
import classes from './HolonTemplateDetail.module.scss';
import useTrans from 'hooks/use-trans';
import Constants from 'utils/Constants';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import { useDispatch } from 'react-redux';
import Button from 'shared/components/form-input/button/Button';
import EventRegister, { EVENT_SHOW_COPY_DATA_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_POPUP_UPDATE_HOLON_TEMPLATE } from 'utils/EventRegister';
import actionHolonTemplate from 'redux/holon-template/action';
import IconEdit from 'assets/icon/IconEdit';
import useRouter from 'hooks/use-router';
import Utils, { getMessageCommon, showPopupNotification } from 'utils/Utils';
import WrapContentBody from '../custom-body/WrapContentBody';
import IconCopy from 'assets/icon/IconCopy';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import CustomBox from '../../box/Box';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import BoxTemplate from '../../box/BoxTemplate';
import { useSelector } from 'react-redux';
import Validator from 'utils/Validator';

function HolonTemplateDetail(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const router = useRouter();
    const dispatch = useDispatch();
    const [holonTemplateDetail, setHolonTemplateDetail] = useState(null);
    const [loadingDeleteTemplate, setLoadingDeleteTemplate] = useState(false);
    const [loadingCloneHolonTemplate, setLoadingCloneHolonTemplate] = useState(false);
    const { actionType } = useSelector(state => state.holonTemplate)

    useEffect(() => {
        if (payload?.holonTemplateId) {
            dispatch({
                type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP,
                payload: payload?.holonTemplateId,
                callback: {
                    success: (data) => {
                        setHolonTemplateDetail(data);
                    }
                }
            });
        }
    }, [payload?.holonTemplateId])

    const handleDelete = () => {
        showPopupNotification({
            typePopup: 'delete',
            newWindow: true,
            callback: () => {
                setLoadingDeleteTemplate(true);
                dispatch({
                    type: actionHolonTemplate.DELETE_HOLON_TEMPLATE_MANAGEMENT,
                    payload: holonTemplateDetail?.id,
                    callback: {
                        success: (res, error) => {
                            setLoadingDeleteTemplate(false);

                            showPopupNotification({
                                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                typePopup: 'message',
                                message: getMessageCommon({
                                    response: res,
                                    error: error,
                                    callback: () => {
                                        dispatch({
                                            type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT,
                                            payload: {
                                                ...router.getAll()
                                            }
                                        })
                                        showVisible(false);
                                    },
                                    messageFailed: Validator.renderMessage(t("I0005"), {
                                        0: t("Holon_Template.delete")
                                    }),
                                    messageSuccess: t('E0045'),
                                    isAction: false
                                }),
                                newWindow: res?.id ? false : true
                            })
                        },
                        failed: (msg) => {
                            setLoadingDeleteTemplate(false);
                            showPopupNotification({
                                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                typePopup: 'message',
                                message: msg,
                                newWindow: true,
                            })
                        }
                    }
                })
            }
        })
    }

    const handleEdit = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_UPDATE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                title: t("Holon_Template.HOLON_template_edit"),
                holonTemplateId: payload?.holonTemplateId,
                callback: () => {
                    dispatch({
                        type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT,
                        payload: {
                            ...router.getAll()
                        }
                    })
                }
            }
        })
    }

    const handleCloneHolonTemplate = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_COPY_DATA_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoadingCloneHolonTemplate(true);
                    dispatch({
                        type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP,
                        payload: id,
                        callback: {
                            success: (data) => {
                                setLoadingCloneHolonTemplate(false);
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE,
                                    payload: {
                                        title: t("Holon_Template.create_holon_template"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                        callback: () => {
                                            dispatch({
                                                type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT,
                                                payload: {
                                                    ...router.getAll()
                                                }
                                            });
                                        },
                                        dataClone: data,
                                    }
                                })
                            },
                            failed: () => {
                                setLoadingCloneHolonTemplate(false)
                            }
                        }
                    })
                }
            }
        })
    }

    return (
        <BoxContentPopup width='1000px' borderRadius='20px 0px 0px 20px'>
            <WrapContentBody
                actionSectionBottom={<Button loading={loadingDeleteTemplate} onClick={handleDelete} type='button' color='gray' title={t("Holon_Template.delete")} />}
                title={payload?.title} showVisible={showVisible} actionSectionTop={<>
                    <Button onClick={handleEdit} color='gray' startIcon={<IconEdit color="currentColor" />} />
                    <Button loading={loadingCloneHolonTemplate} onClick={() => {
                        handleCloneHolonTemplate(payload?.holonTemplateId)
                    }} color='gray' startIcon={<IconCopy color="currentColor" />} />
                </>}>
                <div className={classes['Wrap-Content']}>
                    <div className={classes['CreateHolonTemplate']}>
                        <CustomBox>
                            <BoxHeader>
                                <BoxTitle>{t("Holon_Template.basic_information")}</BoxTitle>
                            </BoxHeader>
                            <BoxBody>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>
                                        {t("Holon_Template.category")}
                                    </BoxLabel>
                                    <BoxContent width={300}>
                                        {holonTemplateDetail?.category}
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>
                                        {t("Holon_Template.template_name")}
                                    </BoxLabel>
                                    <BoxContent width={300}>
                                        {holonTemplateDetail?.name}
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>
                                        {t("Holon_Template.status")}
                                    </BoxLabel>
                                    <BoxContent width={300}>
                                        {holonTemplateDetail?.status == Constants.STATUS_CATEGORY.PUBLIC.VALUE ?
                                            t("Holon_Template.valid")
                                            : t("Holon_Template.invalid")
                                        }
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>
                                        {t("Holon_Template.explanation")}
                                    </BoxLabel>
                                    <BoxContent width={300}>
                                        {holonTemplateDetail?.note}
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>
                                        {t("Holon_Template.library")}
                                    </BoxLabel>
                                    <BoxContent width={300}>
                                        {holonTemplateDetail?.library}
                                    </BoxContent>
                                </BoxRow>
                            </BoxBody>
                        </CustomBox>

                        <CustomBox>
                            <BoxHeader>
                                <BoxTitle>{t("Holon_Template.attribute")}</BoxTitle>
                            </BoxHeader>
                            <BoxBody padding={0}>
                                <div className={classes['custom-table']}>
                                    <table>
                                        <thead>
                                            <tr className={classes['form-header']}>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.key")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.key_name")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explain")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.regex")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.default_value")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.message_key")}</th>
                                                <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.require")}</th>
                                                <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.multiple_allowed")}</th>
                                                <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.editable")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.element_type")}</th>
                                                <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                holonTemplateDetail?.attributes?.map((x, key) => {
                                                    return <tr key={key} className={classes['form-body']}>
                                                        <td>
                                                            {x?.key}
                                                        </td>
                                                        <td>
                                                            {x?.label}
                                                        </td>
                                                        <td>
                                                            {x?.explain}
                                                        </td>
                                                        <td>
                                                            {x?.regex}
                                                        </td>
                                                        <td>
                                                            {x?.defaultValue}
                                                        </td>
                                                        <td>
                                                            {x?.msg}
                                                        </td>
                                                        <td className={classes['checkbox-width']}>
                                                            {x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <IconChecked /> : <IconCheck />}
                                                        </td>
                                                        <td className={classes['checkbox-width']}>
                                                            {x?.isMultiSelected == Constants.STATUS_CHECKBOX.YES.VALUE ? <IconChecked /> : <IconCheck />}
                                                        </td>
                                                        <td className={classes['checkbox-width']}>
                                                            {x?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? <IconChecked /> : <IconCheck />}
                                                        </td>
                                                        <td>
                                                            {Utils.convertObjectKeyToArr(Constants.ELEMENT_TYPE, "NAME", "VALUE", "Holon_Template.list_element_type.")?.find(v => v?.value == x?.elementType)?.label}
                                                        </td>
                                                        <td>
                                                            <BoxTemplate data={x?.reader} />
                                                        </td>
                                                        <td>
                                                            <BoxTemplate data={x?.writer} />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </BoxBody>
                        </CustomBox>

                        <CustomBox>
                            <BoxHeader>
                                <BoxTitle>{t("Holon_Template.action")}</BoxTitle>
                            </BoxHeader>
                            <BoxBody padding={0}>
                                <div className={classes['custom-table']}>
                                    <table>
                                        <thead>
                                            <tr className={classes['form-header']}>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.action_name")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explanation")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.type")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.input")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.output")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.timeout")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.unit")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.api")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.action_type")}</th>
                                                <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                holonTemplateDetail?.actions?.map((x, key) => {
                                                    return <tr key={key} className={classes['form-body']}>
                                                        <td>
                                                            {x?.key}
                                                        </td>
                                                        <td>
                                                            {x?.note}
                                                        </td>
                                                        <td>
                                                            {Utils.convertObjectKeyToArr(Constants.TYPE_ACTIONS, "NAME", "VALUE", "Holon_Template.actions.")?.find(v => v?.value == x?.type)?.label}
                                                        </td>
                                                        <td>
                                                            {x?.inputValue}
                                                        </td>
                                                        <td>
                                                            {x?.outputValue}
                                                        </td>
                                                        <td>
                                                            {x?.timeout}
                                                        </td>
                                                        <td>
                                                            {x?.unit}
                                                        </td>
                                                        <td>
                                                            {x?.api}
                                                        </td>
                                                        <td>
                                                            {actionType?.[x?.actionType]}
                                                        </td>
                                                        <td>
                                                            <BoxTemplate data={x?.reader} />
                                                        </td>
                                                        <td>
                                                            <BoxTemplate data={x?.writer} />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </BoxBody>
                        </CustomBox>

                        <CustomBox>
                            <BoxHeader>
                                <BoxTitle>{t("Holon_Template.connect")}</BoxTitle>
                            </BoxHeader>
                            <BoxBody padding={0}>
                                <div className={classes['custom-table']}>
                                    <table>
                                        <thead>
                                            <tr className={classes['form-header']}>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.key")}</th>
                                                <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explanation")}</th>
                                                <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.holon_template")}</th>
                                                <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.require")}</th>
                                                <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.multiple_allowed")}</th>
                                                <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.editable")}</th>
                                                <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                holonTemplateDetail?.connections?.map((x, key) => {
                                                    return <tr key={key} className={classes['form-body']}>
                                                        <td>
                                                            {x?.key}
                                                        </td>
                                                        <td>
                                                            {x?.note}
                                                        </td>
                                                        <td>
                                                            <BoxTemplate data={x?.holonTemplate} />
                                                        </td>
                                                        <td className={classes['checkbox-width']}>
                                                            {x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <IconChecked /> : <IconCheck />}
                                                        </td>
                                                        <td className={classes['checkbox-width']}>
                                                            {x?.isMultiSelected == Constants.STATUS_CHECKBOX.YES.VALUE ? <IconChecked /> : <IconCheck />}
                                                        </td>
                                                        <td className={classes['checkbox-width']}>
                                                            {x?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? <IconChecked /> : <IconCheck />}
                                                        </td>
                                                        <td>
                                                            <BoxTemplate data={x?.reader} />
                                                        </td>
                                                        <td>
                                                            <BoxTemplate data={x?.writer} />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </BoxBody>
                        </CustomBox>
                    </div>
                </div>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default HolonTemplateDetail