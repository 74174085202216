import useTrans from 'hooks/use-trans';
import React, { useState } from 'react'
import BoxContentPopup from '../custom-body/BoxContentPopup';
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Action from 'redux/order/action';
import EventRegister, { EVENT_SHOW_POPUP, OVERVIEW_IMPORT_ORDER } from 'utils/EventRegister';
import FormUploadMultipartFile from 'shared/components/custom-form/FormUploadMultipartFile';
import { getMessageCommon, showPopupNotification } from 'utils/Utils';

function UploadOrder(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const methods = useForm();

    const onSubmit = (data) => {
        setLoading(true)
        dispatch({
            type: Action.UPLOAD_FILE_ORDER,
            payload: {
                file: data?.file,
                templateId: payload?.templateId,
                warehouseId: payload?.warehouse?.id
            },
            callback: {
                success: (_data) => {
                    if (_data?.error) {
                        payload?.callback();
                        showVisible(false);
                        showPopupNotification({
                            typePopup: 'message',
                            message: getMessageCommon({
                                response: _data,
                                error: null,
                            }),
                            newWindow: true,
                        })
                    } else {
                        setLoading(false)
                        if (_data?.isFileEmpty) {
                            payload?.callback();
                            showPopupNotification({
                                typePopup: 'message',
                                message: _data?.message,
                                newWindow: true,
                            })
                        } else {
                            if (_data?.errors?.length == 0) {
                                payload?.callback();
                                showVisible(false);
                                showPopupNotification({
                                    typePopup: 'message',
                                    message: getMessageCommon({
                                        response: _data,
                                        error: null,
                                    }),
                                    newWindow: true,
                                })
                            } else {
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: OVERVIEW_IMPORT_ORDER,
                                    payload: {
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                        ...payload,
                                        data: _data,
                                        file: data?.file,
                                    }
                                })
                            }
                        }
                    }
                },
                failed: () => {
                    EventRegister.emit(EVENT_SHOW_POPUP, {
                        open: true,
                        type: OVERVIEW_IMPORT_ORDER,
                        payload: {

                        }
                    })
                }
            }
        })
    }

    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <BoxContentPopup >
                    <WrapContentBody
                        isUseBackground={false}
                        showVisible={showVisible}
                        title={t("orders.import_file")}
                        actionSectionBottom={<Button disabled={methods.watch("file") ? false : true} loading={loading} type='submit' title={t("save")} />}
                        useForm={false}
                    >

                        <BoxBody style={{
                            borderRadius: 10
                        }}>
                            <BoxRow>
                                <BoxLabel style={{
                                    height: 40
                                }}>{t("orders.import_file")}</BoxLabel>
                                <BoxContent>
                                    <FormUploadMultipartFile fieldName='file' />
                                </BoxContent>
                            </BoxRow>
                        </BoxBody>

                    </WrapContentBody>
                </BoxContentPopup>
            </form>
        </CustomFormProvider>
    )
}

export default UploadOrder