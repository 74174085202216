import React, { useEffect, useState } from 'react'
import classes from './NotifyAlarmContainer.module.scss';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import { useSelector } from 'react-redux';
import actionAlarm from 'redux/alarm/action';
import { useDispatch } from 'react-redux';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE } from 'utils/EventRegister';
import actionHolonTemplate from 'redux/holon-template/action';
import IconMinusAccordionFilter from 'assets/icon/IconMinusAccordionFilter';
import { convertDate } from 'utils/Utils';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import TableAlarm from 'shared/components/alarm/TableAlarm';
import CustomTab from 'shared/components/common/custom-tab/CustomTab';

function NotifyAlarmContainer() {
    const { t } = useTrans();
    const methods = useForm();
    const dispatch = useDispatch();
    const [valueTab, setValueTab] = useState(null)
    const [dataTab, setDataTab] = useState([]);
    const router = useRouter();
    let params = { ...router.getAll() }
    const handleSubmit = (data) => {
        let paramsRemove = { ...params };
        if (data?.keyword) {
            router.replace({
                params: {
                    ...paramsRemove,
                    [Constants.QueryParams.KEYWORD.VALUE]: data?.keyword
                }
            })
        } else {
            delete paramsRemove[Constants.QueryParams.KEYWORD.VALUE]
            router.replace({
                params: {
                    ...paramsRemove,
                }
            })
        }
    }

    const { loading: {
        loadingGetAlarm = false
    }, listAlarm, alarmMaster } = useSelector(state => state.alarm);


    useEffect(() => {
        dispatch({
            type: actionAlarm.GET_ALARM_MASTER,
            payload: {}
        })
    }, [])

    useEffect(() => {
        if (alarmMaster?.alarmTypes) {
            let masterType = Object.keys(alarmMaster?.alarmTypes).map(key => {
                return {
                    key: key,
                    id: key,
                    label: alarmMaster?.alarmTypes[key],
                }
            });
            masterType = masterType?.sort((a, b) => a.key.localeCompare(b.key));
            setDataTab(masterType);
            router.replace({
                params: {
                    ...router.getAll(),
                    [Constants.QueryParams.TAB.VALUE]: masterType[0]?.id
                }
            })
        }
    }, [alarmMaster?.alarmTypes])

    useEffect(() => {
        if (router.get(Constants.QueryParams.TAB.VALUE)) {
            dispatch({
                type: actionAlarm.GET_LIST_ALARM,
                payload: {
                    ...router.getAll()
                }
            })
        }
    }, [
        router.get(Constants.QueryParams.SUBJECT.VALUE),
        router.get(Constants.QueryParams.TAB.VALUE),
        router.get(Constants.QueryParams.FROM.VALUE),
        router.get(Constants.QueryParams.TO.VALUE),
        router.get(Constants.QueryParams.MESSAGE.VALUE),
        router.get(Constants.QueryParams.KEYWORD.VALUE),
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.LIMIT.VALUE)
    ]);

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = [
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'subject',
                label: t("Holon_Template.subject"),
                subject: params[Constants.QueryParams.SUBJECT.VALUE] || ""
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'message',
                label: t("Holon_Template.message"),
                message: params[Constants.QueryParams.MESSAGE.VALUE] || ""
            },
            {
                elementType: Constants.VIEW_SEARCH.ROW.VALUE,
                key: 'date',
                label: t("Holon_Template.date"),
                component: [
                    {
                        elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                        key: 'from',
                        label: t("Holon_Template.from"),
                        from: params[Constants.QueryParams.FROM.VALUE] || null
                    },
                    {
                        elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                        key: 'to',
                        label: t("Holon_Template.to"),
                        to: params[Constants.QueryParams.TO.VALUE] || null
                    }
                ]
            }
        ]
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                formArray: false,
                callback: (_props) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.SUBJECT.VALUE]: _props?.subject,
                            [Constants.QueryParams.MESSAGE.VALUE]: _props?.message,
                            [Constants.QueryParams.FROM.VALUE]: _props?.from ? _props?.from : null,
                            [Constants.QueryParams.TO.VALUE]: _props?.to ? _props?.to : null,
                        }
                    })
                }
            }
        })
    }

    const renderDataFilter = () => {
        const handleRemoveRouter = (_key) => {
            let paramsRemove = { ...router.getAll() };
            delete paramsRemove[_key]

            if (_key == 'date') {
                delete paramsRemove[Constants.QueryParams.FROM.VALUE]
                delete paramsRemove[Constants.QueryParams.TO.VALUE]
            }

            router.replace({
                params: {
                    ...paramsRemove,
                    [Constants.QueryParams.OFFSET.VALUE]: 1
                }
            })
        }

        return <>
            {
                params[Constants.QueryParams.SUBJECT.VALUE] && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.SUBJECT.VALUE)} color='white' size='large' title={`${t("Holon_Template.subject")}: ${params[Constants.QueryParams.SUBJECT.VALUE]}`} endIcon={<IconMinusAccordionFilter />} />
            }
            {
                params[Constants.QueryParams.MESSAGE.VALUE] && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.MESSAGE.VALUE)} color='white' size='large' title={`${t("Holon_Template.content")}: ${params[Constants.QueryParams.MESSAGE.VALUE]}`} endIcon={<IconMinusAccordionFilter />} />
            }
            {
                (
                    params[Constants.QueryParams.FROM.VALUE] || params[Constants.QueryParams.TO.VALUE]
                ) &&
                <ButtonFilter
                    onClick={() => handleRemoveRouter('date')}
                    color='white' size='large'
                    title={`${t("Holon_Template.created")}：${convertDate(params[Constants.QueryParams.FROM.VALUE])} ${params[Constants.QueryParams.FROM.VALUE] && params[Constants.QueryParams.TO.VALUE] ? '〜' : ''} ${convertDate(params[Constants.QueryParams.TO.VALUE])} `}
                    endIcon={<IconMinusAccordionFilter />} />
            }
        </>
    }

    useEffect(() => {
        if (router.get([Constants.QueryParams.KEYWORD.VALUE])) {
            methods.setValue("keyword", router.get([Constants.QueryParams.KEYWORD.VALUE]))
        } else {
            methods.setValue("keyword", null);
        }
    }, [
        router.get([Constants.QueryParams.KEYWORD.VALUE]),
    ])


    useEffect(() => {
        if (router.get(Constants.QueryParams.TAB.VALUE)) {
            setValueTab(router.get(Constants.QueryParams.TAB.VALUE));
        } else {
            if (dataTab?.length > 0) {
                setValueTab(dataTab[0].id);
            }
        }
    }, [router.get(Constants.QueryParams.TAB.VALUE), dataTab])


    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e
            }
        })
    }

    const onPageChange = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e
            }
        })
    }

    return (
        <ContentLayout>
            <Breadcrumb>{t("alarm.notification_display")}</Breadcrumb>
            <CustomTab data={dataTab} value={valueTab} onChange={(e) => {
                router.replace({
                    params: {
                        ...router.getAll(),
                        [Constants.QueryParams.TAB.VALUE]: e,
                        [Constants.QueryParams.OFFSET.VALUE]: 1,
                    }
                })
            }} />
            <div className={classes['product-container']}>
                <div className={classes['custom-search']}>
                    <CustomFormProvider {...methods}>
                        <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                            <FormSearch className={classes['custom-form-search']}>
                                <FormGroup flexWrap>
                                    <FormItem minWidth={200} maxWidth={200}>
                                        <FormInput placeholder={t("search_by_word")} isSearch iconSearch={<IconSearch fontSize={14} />} fieldName='keyword' validate={[]} />
                                    </FormItem>
                                    {
                                        renderDataFilter(params)
                                    }
                                    <ButtonFilter onClick={handleOpenAddSearchCriteria} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                </FormGroup>
                            </FormSearch>
                            <button hidden type='submit' />
                        </form>
                    </CustomFormProvider>
                </div>
                <div className={classes['custom-body']}>
                    <TableAlarm loadingGetAlarm={loadingGetAlarm} listAlarm={listAlarm} />
                    <div className={classes['custom-pagination']}>
                        <CustomPagination
                            isRowPerpage
                            onChangeRowPerpage={onChangeRowPerpage}
                            onChange={onPageChange}
                            total={listAlarm?.total}
                            limit={router.get(Constants.QueryParams.LIMIT.VALUE)}
                            page={router.get([Constants.QueryParams.OFFSET.VALUE])}
                        />

                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default NotifyAlarmContainer