import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionFareCalculation from "./action";
import factory from "./factory";
import { getToastError } from "utils/Utils";

function* getFareCalculationServiceCrossBorder() {
    yield takeEvery(actionFareCalculation.GET_CROSS_BORDER_COURIER_SERVICE, function* (payload) {
        try {
            const response = yield call(() => factory.getFareCalculationServiceCrossBorder(payload?.payload));
            if (!payload?.payload) {
                yield put({
                    type: actionFareCalculation.GET_CROSS_BORDER_COURIER_SERVICE_FAILED,
                });
                getToastError(response?.error?.detail)
            }else{
                yield put({
                    type: actionFareCalculation.GET_CROSS_BORDER_COURIER_SERVICE_SUCCESSFUL,
                    payload: response
                })
            }
            
        } catch (error) {
            yield put({
                type: actionFareCalculation.GET_CROSS_BORDER_COURIER_SERVICE_FAILED,
            });
            getToastError(error?.detail)
        }
    })
}

function* getFareCalculationService() {
    yield takeEvery(actionFareCalculation.GET_CALCULATION_SERVICE, function* () {
        try {
            const response = yield call(() => factory.getFareCalculationService());
            yield put({
                type: actionFareCalculation.GET_CALCULATION_SERVICE_SUCCESSFUL,
                payload: response
            })
        } catch (error) {
            yield put({
                type: actionFareCalculation.GET_CALCULATION_SERVICE_FAILED,
            })
        }
    })
}

function* getFareCalServiceContent() {
    yield takeEvery(actionFareCalculation.GET_DETAIL_FARE_CAL_SERVICE_CONTENT, function* (payload) {
        try {
            const response = yield call(() => factory.getFareCalServiceContent(payload?.payload));
            yield put({
                type: actionFareCalculation.GET_DETAIL_FARE_CAL_SERVICE_CONTENT_SUCCESSFUL,
                payload: response
            })
        } catch (error) {
            yield put({
                type: actionFareCalculation.GET_DETAIL_FARE_CAL_SERVICE_CONTENT_FAILED,
            })
        }
    })
}

function* getFareCalculationGlobalVariable() {
    const dataDummy = {
        data: [
            {
                id: "",
                name: "",
                key: "",
                value: "",
                remark: "",
                isGlobal: "",
                serviceId: "",
            }
        ]
    }
    yield takeEvery(actionFareCalculation.GET_FARE_CALCULATION_GLOBAL_VARIABLE, function* () {
        try {
            const response = yield call(() => factory.getFareCalculationGlobalVariable());
            if (response?.data) {
                yield put({
                    type: actionFareCalculation.GET_FARE_CALCULATION_GLOBAL_VARIABLE_SUCCESSFUL,
                    payload: response
                })
            }
            else {
                yield put({
                    type: actionFareCalculation.GET_FARE_CALCULATION_GLOBAL_VARIABLE_FAILED,
                    payload: dataDummy
                })
            }
        } catch (error) {
            yield put({
                type: actionFareCalculation.GET_FARE_CALCULATION_GLOBAL_VARIABLE_FAILED,
                payload: dataDummy
            })
        }
    })
}

function* getFareCalculationUserInput() {
    const dataDummy = {
    }
    yield takeEvery(actionFareCalculation.GET_FARE_CALCULATION_USER_INPUT, function* () {
        try {
            const response = yield call(() => factory.getFareCalculationUserInput());
            if (response?.data) {
                yield put({
                    type: actionFareCalculation.GET_FARE_CALCULATION_USER_INPUT_SUCCESSFUL,
                    payload: response
                })
            }
            else {
                yield put({
                    type: actionFareCalculation.GET_FARE_CALCULATION_USER_INPUT_FAILED,
                    payload: dataDummy
                })
            }
        } catch (error) {
            yield put({
                type: actionFareCalculation.GET_FARE_CALCULATION_USER_INPUT_FAILED,
                payload: dataDummy
            })
        }
    })
}

function* getFareUserInput() {
    yield takeEvery(actionFareCalculation.GET_FARE_CALC_USER_INPUT, function* () {
        try {
            const response = yield call(() => factory.getFareUserInput());
            if (response?.data) {
                yield put({
                    type: actionFareCalculation.GET_FARE_CALC_USER_INPUT_SUCCESSFUL,
                    payload: response
                })
            }
            else {
                yield put({
                    type: actionFareCalculation.GET_FARE_CALC_USER_INPUT_FAILED,
                    payload: {}
                })
            }
        } catch (error) {
            yield put({
                type: actionFareCalculation.GET_FARE_CALC_USER_INPUT_FAILED,
                payload: {}
            })
        }
    })
}

function* createFareCalculation() {
    yield takeEvery(actionFareCalculation.CREATE_FARE_CALCULATION, function* (formData) {
        const {
            onSuccess = () => { },
            onFailure = () => { },
        } = formData;
        try {

            const response = yield call(() => factory.createFareCalculation(formData?.payload));
            const requestError = response.error ?? null;
            if (requestError) {
                const detailError = requestError.detail ?? {};
                typeof onFailure === "function" && onFailure(detailError);
                yield put({
                    type: actionFareCalculation.CREATE_FARE_CALCULATION_FAILED,
                })
                getToastError(response?.error?.detail)
            } else {
                typeof onSuccess === "function" && onSuccess();
                yield put({
                    type: actionFareCalculation.CREATE_FARE_CALCULATION_SUCCESSFUL,
                    payload: response
                })
            }
        } catch (error) {
            yield put({
                type: actionFareCalculation.CREATE_FARE_CALCULATION_FAILED,
            })
            typeof onFailure === "function" && onFailure(error?.detail);
            getToastError(error?.detail)
        }
    })
}

function* updateFareDetailManagementService() {
    yield takeEvery(actionFareCalculation.UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE, function* (formData) {
        const {
            onSuccess = () => { },
            onFailure = () => { },
        } = formData;
        try {
            const response = yield call(() => factory.updateFareDetailManagementService(formData?.payload));
            const requestError = response.error ?? null;
            if (requestError) {
                const detailError = requestError.detail ?? {};
                typeof onFailure === "function" && onFailure(detailError);
                yield put({
                    type: actionFareCalculation.UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED,
                })
                getToastError(response?.error?.detail)
            } else {
                typeof onSuccess === "function" && onSuccess();
                yield put({
                    type: actionFareCalculation.UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_SUCCESSFUL,
                    payload: response
                })
            }
        } catch (error) {
            yield put({
                type: actionFareCalculation.UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED,
            })
            typeof onFailure === "function" && onFailure(error?.detail);
        }
    })
}

function* createFareDetailManagementService() {
    yield takeEvery(actionFareCalculation.CREATE_FARE_DETAIL_MANAGEMENT_SERVICE, function* (formData) {
        const {
            onSuccess = () => { },
            onFailure = () => { },
        } = formData;
        try {
            const response = yield call(() => factory.createFareDetailManagementService(formData?.payload));
            const requestError = response.error ?? null;
            if (requestError) {
                const detailError = requestError.detail ?? {};
                typeof onFailure === "function" && onFailure(detailError);
                yield put({
                    type: actionFareCalculation.CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED,
                })
                getToastError(response?.error?.detail)
            } else {
                typeof onSuccess === "function" && onSuccess();
                yield put({
                    type: actionFareCalculation.CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_SUCCESSFUL,
                    payload: response
                })
            }
        } catch (error) {
            yield put({
                type: actionFareCalculation.CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED,
            })
            typeof onFailure === "function" && onFailure(error?.detail);
        }
    })
}

function* createFareCalcManagementCalculate() {
    yield takeEvery(actionFareCalculation.GET_FARE_CALC_MANAGEMENT_CALCULATE, function* (formData) {
        const {
            onSuccess = () => { },
            onFailure = () => { },
        } = formData;
        try {
            const response = yield call(() => factory.createFareCalcManagementCalculate(formData?.payload));
            const requestError = response.error ?? null;
            if (requestError) {
                const detailError = requestError.detail ?? {};
                typeof onFailure === "function" && onFailure(detailError);
                yield put({
                    type: actionFareCalculation.GET_FARE_CALC_MANAGEMENT_CALCULATE_FAILED,
                })
                getToastError(response?.error?.detail)
            } else {
                typeof onSuccess === "function" && onSuccess();
                yield put({
                    type: actionFareCalculation.GET_FARE_CALC_MANAGEMENT_CALCULATE_SUCCESSFUL,
                    payload: response
                })
            }
        } catch (error) {
            yield put({
                type: actionFareCalculation.GET_FARE_CALC_MANAGEMENT_CALCULATE_FAILED,
            })
            getToastError(error?.detail)
            typeof onFailure === "function" && onFailure(error?.detail);
        }
    })
}

function* deleteFareCalcManagementCalculate() {
    yield takeEvery(actionFareCalculation.DELETE_FARE_CALC_SERVICE, function* (formData) {
        const {
            onSuccess = () => { },
            onFailure = () => { },
        } = formData;
        try {
            const response = yield call(() => factory.deleteFareCalcManagementCalculate(formData?.payload));
            const requestError = response.error ?? null;
            if (requestError) {
                const detailError = requestError.detail ?? {};
                typeof onFailure === "function" && onFailure(detailError);
                yield put({
                    type: actionFareCalculation.GET_FARE_CALC_MANAGEMENT_CALCULATE_FAILED,
                })
                getToastError(response?.error?.detail)
            } else {
                typeof onSuccess === "function" && onSuccess();
                yield put({
                    type: actionFareCalculation.GET_FARE_CALC_MANAGEMENT_CALCULATE_SUCCESSFUL,
                })
            }
        } catch (error) {
            yield put({
                type: actionFareCalculation.GET_FARE_CALC_MANAGEMENT_CALCULATE_FAILED,
            })
            getToastError(error?.detail)
            typeof onFailure === "function" && onFailure(error?.detail);
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getFareCalculationServiceCrossBorder),
        fork(getFareCalculationService),
        fork(getFareCalculationGlobalVariable),
        fork(getFareCalculationUserInput),
        fork(createFareCalculation),
        fork(updateFareDetailManagementService),
        fork(createFareCalcManagementCalculate),
        fork(getFareUserInput),
        fork(getFareCalServiceContent),
        fork(createFareDetailManagementService),
        fork(deleteFareCalcManagementCalculate),
    ])
}