import React, { useEffect, useState } from 'react'
import classes from './Stocks.module.scss';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import FormInput from 'shared/components/custom-form/FormInput';
import Validator from 'utils/Validator';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_PRODUCT_DETAIL_POPUP } from 'utils/EventRegister';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import actionStocks from 'redux/stocks/action';

function UpdateStockPopup(props) {
    const router = useRouter();
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const [stock, setStock] = useState(null)
    const dispatch = useDispatch();
    const { loadingGetDetail, loadingUpdate } = useSelector(state => state.stocks);
    const methods = useForm({
        defaultValues: {
            item_name: "",
            price: 0,
            manual_update_flag: "",
            gs1_code: ""
        }
    });

    const onSubmit = (data) => {
        const params = {
            id: stock?.id,
            item_name: data?.item_name,
            manual_update_flag: data?.manual_update_flag ? "1" : "0",
            gs1_code: data?.gs1_code,
            price: parseInt(data?.price, 10)
        }
        dispatch({
            type: actionStocks.UPDATE_STOCKS,
            payload: {
                ...params
            },
            callback: {
                success: () => {
                    dispatch({
                        type: actionStocks.GET_LIST_STOCKS,
                        payload: {
                            ...router.getAll(),
                        }
                    });
                    showVisible()
                },
                failed: () => {

                }
            }
        })
    }

    useEffect(() => {
        if (stock?.id) {
            methods.reset({
                gs1_code: stock.gs1_code,
                item_name: stock.item_name,
                manual_update_flag: stock.manual_update_flag == "1" ? true : false,
                price: stock.price,
            })
        }
    }, [stock])

    useEffect(() => {
        if (payload?.stockId) {
            dispatch({
                type: actionStocks.GET_DETAIL_STOCKS_POPUP,
                payload: payload?.stockId,
                callback: (data) => {
                    setStock(data)
                }
            })
        }
    }, [payload?.stockId])


    const gotoDetail = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_PRODUCT_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                stockId: payload?.stockId,
                isShowOption: false,
                title: t("product.product_detail"),
            }
        })
    }
    return (
        <BoxContentPopup>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        handleBtnBack={gotoDetail}
                        title={payload?.title} showVisible={showVisible} payload={payload}
                        actionSectionBottom={<Button loading={loadingUpdate || loadingGetDetail} type='submit' title={t("product.submit")} />}
                    >
                        <div className={classes['created-holon-instance']}>
                            <Box>
                                <BoxBody className={classes['data-attributes-body']}>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("product.gs1_code")}</BoxLabel>
                                        <BoxContent>
                                            <FormInput disabled placeholder={t("product.gs1_code")} validate={[Validator.required]} fieldName='gs1_code' />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("product.product_name")}</BoxLabel>
                                        <BoxContent>
                                            <FormInput placeholder={t("product.product_name")} validate={[Validator.required]} fieldName='item_name' />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("product.product_price")}</BoxLabel>
                                        <BoxContent>
                                            <FormInput format={Constants.FormInputFormat.NUMBER.VALUE} placeholder={t("product.product_price")} validate={[Validator.required]} fieldName='price' />
                                        </BoxContent>
                                    </BoxRow>
                                    {/* <BoxRow>
                                        <BoxLabel>{t("product.product_manual_update_flag")}</BoxLabel>
                                        <BoxContent>
                                            <FormInput disabled placeholder={t("product.product_manual_update_flag")} validate={[Validator.required]} fieldName='manual_update_flag' />
                                        </BoxContent>
                                    </BoxRow> */}
                                </BoxBody>
                            </Box>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default UpdateStockPopup