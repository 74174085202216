import React from 'react';

function IconChevronLeft({ fontSize = 10}) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 9 12"
            fill="none"
            fontSize={fontSize}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 11L2 6L8 1"
                stroke="#929292"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconChevronLeft;
