import React from 'react';

function IconOpenInNewWindow({ color = 'white' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            viewBox="0 0 13 13"
            fill="none"
        >
            <path d="M3.0625 0.9H2.9625V1V9.9375V10.0375H3.0625H12H12.1V9.9375V1V0.9H12H3.0625ZM11.0406 8.97813H4.02188V1.95938H11.0406V8.97813Z" fill="#0E525E" stroke="#0E525E" strokeWidth="0.2" />
            <path d="M9.07812 11.0406H1.95938V7.35938V3.92188V3.0625V2.9625H1.85938H1H0.9V3.0625V12V12.1H1H9.9375H10.0375V12V11.1406V11.0406H9.9375H9.07812Z" fill="#0E525E" stroke="#0E525E" strokeWidth="0.2" />
            <path d="M5.93111 7.79163L6.00182 7.86235L6.07254 7.79164L8.43395 5.4302V7.01059V7.11059H8.53395H9.39333H9.49333V7.01059V3.7218V3.6218H9.39333H6.10456H6.00456V3.7218V4.58118V4.68118H6.10456H7.68493L5.32352 7.04259L5.25281 7.1133L5.32351 7.18401L5.93111 7.79163Z" fill="#0E525E" stroke="#0E525E" strokeWidth="0.2" />
        </svg>
    );
}

export default IconOpenInNewWindow;
