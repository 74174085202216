import React, { useEffect, useState } from 'react'
import classes from './FAQ.module.scss';
import { useSelector } from 'react-redux';
import FormGroup from '../../form-search/FormGroup';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxContent from '../../box/BoxContent';
import BoxLabel from '../../box/BoxLabel';
import useRouter from 'hooks/use-router';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_POPUP_UPDATE_FAQ } from 'utils/EventRegister';
import FaqAction from 'redux/faq/action';
import { useDispatch } from 'react-redux';
import IconEdit from 'assets/icon/IconEdit';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import Constants from 'utils/Constants';
import useCustomState from 'hooks/use-custom-state';

function FaqDetailPopup(props) {
    const { showVisible, payload } = props;
    const [faqDetail, setFaqDetail] = useState(null)
    const [loadingDelete, setLoadingDelete] = useState(null)
    const [categoryWithLanguage, setCategoryWithLanguage] = useCustomState([]);
    const { trans, t } = useTrans();
    const router = useRouter();
    const dispatch = useDispatch();


    const handleDeleteFaq = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            payload: {
                // title: t("Holon_Template.confirm_delete"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoadingDelete(true)
                    dispatch({
                        type: FaqAction.DELETE_FAQ_WITH_CATEGORY,
                        payload: {
                            id,
                        },
                        callback: {
                            success: () => {
                                setLoadingDelete(false)
                            },
                            failed: () => {
                                setLoadingDelete(false)
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    payload: {
                                        title: t("Holon_Template.system_error"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                            }
                        },
                        paramsUrl: { ...router.getAll() }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (payload?.id) {
            dispatch({
                type: FaqAction.GET_DETAIL_FAQ_WITH_CATEGORIES_POPUP,
                payload: {
                    id: payload?.id
                },
                callback: (res) => {
                    setFaqDetail(res)
                }
            })
        }
    }, [payload?.id])

    const handleEdit = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_UPDATE_FAQ,
            payload: {
                id,
                title: t("faq_management.faq_update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                }
            }
        })
    }

    useEffect(() => {
        if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
            dispatch({
                type: FaqAction.GET_FAQ_CATEGORIES_WITH_LANGUAGE,
                payload: {
                    isAll: true,
                    [Constants.QueryParams.LANGUAGE.VALUE]: router.get(Constants.QueryParams.LANGUAGE.VALUE)
                },
                callback: (data) => {
                    setCategoryWithLanguage(data?.records);
                }
            })
        }
    }, [
        router.get(Constants.QueryParams.LANGUAGE.VALUE)
    ])

    return (
        <BoxContentPopup width='760px' borderRadius="20px 0px 0px 20px" >
            <WrapContentBody
                actionSectionBottom={<Button onClick={() => handleDeleteFaq(faqDetail?.id)} loading={loadingDelete} title={trans("Holon_Template.delete")} />}
                showVisible={showVisible}
                title={payload?.title}
                actionSectionTop={<Button onClick={() => handleEdit(faqDetail?.id)} color='gray' startIcon={<IconEdit color="currentColor" />} />}
            >
                <div className={classes['FaqDetail']}>
                    <Box>
                        <BoxBody style={{
                            borderRadius: 10
                        }}>
                            <BoxRow style={{ flex: 1 }}>
                                <BoxLabel minWidth="100px" width="100px" require>
                                    {trans("faq_management.language")}
                                </BoxLabel>
                                <BoxContent>
                                    {Object.keys(Constants.T001).map(key => {
                                        return {
                                            key: Constants.T001[key].CODE,
                                            value: Constants.T001[key].CODE,
                                            label: t(`faq_management.lang.${Constants.T001[key].NAME}`),
                                        }
                                    })?.find(x => x?.key == router.get(Constants.QueryParams.LANGUAGE.VALUE))?.label}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow style={{ flex: 1 }}>
                                <BoxLabel minWidth="100px" width="100px" require>
                                    {trans("faq_management.category")}
                                </BoxLabel>
                                <BoxContent>
                                    {categoryWithLanguage?.find(x => x?.id == faqDetail?.categoryId)?.categoryName}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="100px" width="100px">
                                    {trans("faq_management.publish_setting")}
                                </BoxLabel>
                                <BoxContent>
                                    {faqDetail?.status == "1" ? <IconChecked /> : <IconCheck />}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="100px" width="100px">
                                    {trans("question")}
                                </BoxLabel>
                                <BoxContent>
                                    {faqDetail?.question}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="100px" width="100px">{trans("answer")}</BoxLabel>
                                <BoxContent>
                                    <span dangerouslySetInnerHTML={{
                                        __html: faqDetail?.answer
                                    }} />
                                </BoxContent>
                            </BoxRow>
                        </BoxBody>
                    </Box>
                </div>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default FaqDetailPopup;