import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionAlarm from "./action";
import factory from "./factory";

function* getListAlarm() {
    yield takeEvery(actionAlarm.GET_LIST_ALARM, function* (payload) {
        try {
            const response = yield call(() => factory.getListAlarm(payload?.payload));
            yield put({
                type: actionAlarm.GET_LIST_ALARM_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* getAlarmMaster() {
    yield takeEvery(actionAlarm.GET_ALARM_MASTER, function* (payload) {
        try {
            const response = yield call(() => factory.getMasterAlarmH(payload?.payload));
            yield put({
                type: actionAlarm.GET_ALARM_MASTER_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListAlarm),
        fork(getAlarmMaster)
    ])
}