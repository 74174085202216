import React from 'react'

function IconPlusYellow ({ fontSize = 18 }) {
    return (
        <svg fontSize={fontSize} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="8.99211" cy="9" rx="8.99211" ry="9" fill="#FFC30D" />
            <path d="M4.99609 9H12.9891" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.99219 5L8.99219 13" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}

export default IconPlusYellow