import ApiOperation from "adapter/ApiOperation"
import Constants from "utils/Constants";

const factory = {

    getListStocks: (payload) => {
        let params = {};

        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE];
        }

        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload[Constants.QueryParams.FROM.VALUE]) {
            params['fromDate'] = payload[Constants.QueryParams.FROM.VALUE]
        }
        if (payload[Constants.QueryParams.TO.VALUE]) {
            params['toDate'] = payload[Constants.QueryParams.TO.VALUE]
        }
        
        if (payload[Constants.QueryParams.WAREHOUSE_ID.VALUE]) {
            params['warehouseId'] = payload[Constants.QueryParams.WAREHOUSE_ID.VALUE]
        }

        let url = `/rest/api/v1/management/stock`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    updateStocks: (payload) => {
        let url = "/rest/api/v1/management/stock";
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    deleteStocks: (payload) => {
        let url = `/rest/api/v1/management/stock/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'DELETE',
        })
    },
    createStocks: (payload) => {
        let url = "/rest/api/v1/management/stock";
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    getDetailStocks: (payload) => {
        let url = `/rest/api/v1/management/stock/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    updateInventory: (payload) => {
        let url = `/rest/api/v1/management/stock/update-inventory`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    }
}

export default factory;