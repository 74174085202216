import React, { useEffect, useState } from 'react';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import Box from 'shared/components/common/box/Box';
import FormInput from 'shared/components/custom-form/FormInput';
import BoxRow from 'shared/components/common/box/BoxRow';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import BoxContent from 'shared/components/common/box/BoxContent';
import Validator from 'utils/Validator';
import Button from 'shared/components/form-input/button/Button';
import BoxBody from 'shared/components/common/box/BoxBody';
import { useDispatch } from 'react-redux';
import classes from './WalletWithdrawContainer.module.scss'
import FormSelect from 'shared/components/custom-form/FormSelect';
import Constants from 'utils/Constants';
import actionWallet from 'redux/wallet/action';
import { useParams } from 'react-router-dom';
import { showPopupNotification } from 'utils/Utils';
import IconCheck from 'assets/icon/IconCheck';
import IconChecked from 'assets/icon/IconChecked';
import { convertObjectToArray } from 'shared/components/common/Util';

const RowBank = ({  }) => {
    const { t } = useTrans();
    return (
        <div className={classes['row']}>
            <div className={classes['body']}>
                <ul className={classes['body-info']}>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.bank_name")} <span>*</span></label>
                            <FormInput validationFE validate={[Validator.required]} isRegexLabel={t("wallet_charge.bank_name")} fieldName='bankName' />
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.branch_name")} <span>*</span></label>
                            <FormInput validationFE validate={[Validator.required]} isRegexLabel={t("wallet_charge.branch_name")} fieldName='branchName' />
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.course_type")} <span>*</span></label>
                            <FormSelect options={convertObjectToArray(Constants.TRANSACTION_CODE.ACCOUNT_TYPE)} validationFE validate={[Validator.required]} isRegexLabel={t("wallet_charge.course_type")} fieldName='accountType' />
                        </div >
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.account_number")} <span>*</span></label>
                            <FormInput validationFE validate={[Validator.required]} isRegexLabel={t("wallet_charge.account_number")} fieldName='accountNo' />
                        </div >
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.account_holder")} <span>*</span></label>
                            <FormInput validationFE validate={[Validator.required]} isRegexLabel={t("wallet_charge.account_holder")} fieldName='accountOwner' />
                        </div >
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.account_name")} <span>*</span></label>
                            <FormInput validationFE validate={[Validator.required]} isRegexLabel={t("wallet_charge.account_name")} fieldName='accountOwnerKana' />
                        </div >
                    </li>
                </ul >
            </div >
        </div >
    )
}

function WalletWithdrawContainer() {
    const { t } = useTrans();
    const { id } = useParams();
    const methods = useForm({
        defaultValues: {
            currency: 'JPY',
            status: 1,
            accountType: Constants.TRANSACTION_CODE.ACCOUNT_TYPE.ORDINARY_DEPOSIT.VALUE
        }
    });
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(1)
    const [withdrawFailed, setWithdrawFailed] = useState(null)
    const watchData = methods.watch(['status'])

    const [bankInfo, setBankInfo] = useState({})

    useEffect(() => {
        dispatch({
            type: actionWallet.PAYMENT_INFO,
            callback: {
                success: (data) => {
                    setBankInfo(data)
                }
            }
        })
    }, [])

    const handleSubmit = (data) => {

        let bank = {};
        bank['bankName'] = data?.bankName;
        bank['branchName'] = data?.branchName;
        bank['accountType'] = parseInt(data?.accountType);
        bank['accountNo'] = data?.accountNo;
        bank['accountOwner'] = data?.accountOwner;
        bank['accountOwnerKana'] = data?.accountOwnerKana;
        setLoading(true);
        let payload = {
            ...data,
            // status: data?.status?.toString(),
            walletId: id,
            attachments: data?.attachments ?? [],
            ...bank
        };

        if (selected == 1) {
            payload['amount'] = 0;
            payload['isFullAmount'] = "1";
        } else {
            payload['amount'] = parseInt(data.amount);
            payload['isFullAmount'] = "0";
        }

        dispatch({
            type: actionWallet.CREATION_WALLET_WITHDRAW,
            payload: payload,
            callback: {
                success: (msg) => {
                    setLoading(false);
                    showPopupNotification({
                        typePopup: 'message',
                        message: t(msg)
                    });
                    methods.reset({})
                },
                failed: (msg) => {
                    setLoading(false);
                    // showPopupNotification({
                    //     typePopup: 'message',
                    //     message: msg
                    // });
                    setWithdrawFailed(msg)
                }
            }
        })
    }

    let currency = [
        {
            label: 'JPY',
            value: 'JPY',
            key: 'JPY'
        }
    ]

    useEffect(() => {
        if (id) {
            methods.setValue("walletId", id)
        }
    }, [id])

    useEffect(() => {
        if (watchData[0]) {
            if (watchData[0] == "1") {
                methods.setValue("transactionBy", t("wallet_charge.bank_transfer"))
            }
            if (watchData[0] == "2") {
                methods.setValue("transactionBy", "Alipay")
            }
            if (watchData[0] == "3") {
                methods.setValue("transactionBy", "WeChatPay")
            }
        }
    }, [watchData])

    const handleCancel = () => {
        methods.reset({

        });
    }

    useEffect(() => {
        if (id) {
            dispatch({
                type: actionWallet.PAYMENT_INFO_WITHDRAW,
                payload: id,
                callback: {
                    success: () => {

                    },
                    failed: (msg) => {
                        // setWithdrawFailed(msg)
                    }
                }
            })
        }
    }, [id])

    return (
        <ContentLayout>
            <Breadcrumb>{t("wallet_charge.wallet_withdrawal_screen")}</Breadcrumb>
            <CustomFormProvider {...methods}>
                <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                    <Box>
                        <BoxBody style={{
                            padding: '0px'
                        }}>
                            <BoxRow>
                                <BoxLabel>{t("wallet_charge.wallet_id")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("wallet_charge.wallet_id")}
                                        fieldName='walletId'
                                        placeholder={t("wallet_charge.wallet_id")}
                                        disabled
                                    />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel isRequired>{t("wallet_charge.withdrawal_amount")}</BoxLabel>
                                <BoxContent style={{
                                    flexDirection: 'column',
                                    display: 'flex'
                                }}>
                                    <BoxContent style={{
                                        flexDirection: 'row',
                                        display: 'flex'
                                    }}>
                                        <div onClick={() => setSelected(1)} className={classes['icon-check']}>
                                            {
                                                selected == 1 ? <IconChecked /> : <IconCheck />
                                            }
                                        </div>
                                        <BoxLabel minWidth="100px">
                                            {t("wallet_charge.full_amount")}
                                        </BoxLabel>
                                    </BoxContent>
                                    <BoxContent style={{
                                        flexDirection: 'row',
                                        display: 'flex'
                                    }}>
                                        <div onClick={() => setSelected(2)} className={classes['icon-check']}>
                                            {
                                                selected == 2 ? <IconChecked /> : <IconCheck />
                                            }
                                        </div>
                                        <BoxLabel minWidth="100px">
                                            {t("wallet_charge.partial_amount")}
                                        </BoxLabel>
                                        <FormInput
                                            validationFE
                                            validate={selected == 2 ? [Validator.required] : []}
                                            isRegexLabel={t("wallet_charge.charge_amount")}
                                            fieldName='amount'
                                            format={Constants.FormInputFormat.NUMBER.VALUE}
                                            placeholder={t("wallet_charge.charge_amount")}
                                            disabled={selected == 1 ? true : false}
                                        />
                                        <div style={{ width: 100 }}>
                                            <FormSelect
                                                validationFE
                                                validate={[]}
                                                isRegexLabel={t("wallet_charge.charge_amount")}
                                                fieldName='currency'
                                                placeholder={t("wallet_charge.charge_amount")}
                                                options={currency}
                                                disabled={selected == 1 ? true : false}
                                            />
                                        </div>
                                    </BoxContent>
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel>{t("wallet_charge.transfer_information")}</BoxLabel>
                                <BoxContent>
                                    <RowBank bankInfo={bankInfo} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel></BoxLabel>
                                <p className={classes['error-message']}>{t(withdrawFailed)}</p>
                            </BoxRow>
                        </BoxBody>
                    </Box>
                    <div className={classes['action-button']}>
                        <Button loading={loading} title={t("wallet_charge.withdrawal_application")} type='submit' />
                        <Button onClick={handleCancel} variant="outlined" title={t("wallet_charge.cancel")} type='button' />
                    </div>
                </form>
            </CustomFormProvider>
        </ContentLayout>
    )
}

export default WalletWithdrawContainer