import ReCAPTCHA from "react-google-recaptcha";
import _ from 'lodash';
import React from 'react';
import ErrorMessageHandleFE from "shared/components/form-input/error-message/ErrorMessageHandleFE.js";
import Constants from 'utils/Constants';
import ErrorMessageHandleRegex from "shared/components/form-input/error-message/ErrorMessageHandleRegex";

function ReCaptCha(props) {
    const {
        onChange,
        errors,
        name,
        validationFE = false,
        textTrans,
        isRegexLabel
    } = props;

    const KEY_RECAPTCHA = process.env.REACT_APP_KEY_RECAPTCHA;
    const language = localStorage.getItem("i18nextLng") ?? Constants.T001.JP.CODE

    const onSubmitCapCha = (value) => {
        onChange(value);
    };

    let showError = false;
    let error = {
        message: "",
    };

    let arr = name.split(".");
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }
  
    const changeLanguage = () => {
        switch (language) {
            case 'jp':
                return 'ja'
            case 'en':
                return 'en'
            default:
                return 'zh-CN'
        }
    }
    return (
        <div>
            <div>
                <ReCAPTCHA
                    sitekey={KEY_RECAPTCHA}
                    onChange={onSubmitCapCha}
                    hl={changeLanguage()}
                />
            </div>
            <div>
                {showError ? (
                    !validationFE ? (<ErrorMessageHandleFE message={error?.message} />) : (
                        isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} textTrans={textTrans} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={name} />
                    )
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default ReCaptCha;

