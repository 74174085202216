import React from "react";

function IconChecked({ fontSize = 18}) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fontSize={fontSize}
        >
            <g id="&#227;&#131;&#129;&#227;&#130;&#167;&#227;&#131;&#131;&#227;&#130;&#175;&#227;&#131;&#156;&#227;&#131;&#131;&#227;&#130;&#175;&#227;&#130;&#185;">
                <ellipse
                    id="Ellipse 5"
                    cx={8.9926}
                    cy={9}
                    rx={8.99211}
                    ry={9}
                    fill="#FFC30D"
                />
                <path
                    id="Vector 529"
                    d="M4.99609 8L8.49303 12L13.4886 6.5"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
}

export default IconChecked;
