import useTrans from 'hooks/use-trans';
import React from 'react'
import WalletWithdrawContainer from 'shared/container/wallet-withdraw/WalletWithdrawContainer';

function WalletWithdrawPage() {
    const { t } = useTrans();
    document.title = t("wallet.wallet_withdraw");
    return (
        <WalletWithdrawContainer />
    )
}

export default WalletWithdrawPage