import React, { useCallback, useEffect, useRef, useState } from 'react'
import classes from './ContentHeader.module.scss';
import IconSearch from 'assets/icon/IconSearch';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import useOnClickOutside from 'hooks/use-onclick-outside';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import { debounce } from 'lodash';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import IconClose from 'assets/icon/IconClose';
import IconAddChannel from 'assets/icon/IconAddChannel';
import CustomTooltip from 'shared/components/common/tooltip/CustomTooltip';

function ContentHeader(props) {
    const router = useRouter();
    const { listChannel, methodsFilterMessage, onFilterMessage, onCloseFilterMessage, addUserToChannel, channelInfo } = props;
    const ref = useRef();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const { t } = useTrans();

    const handleOpenSearchMessage = () => {
        setOpen(true);
    }

    // useEffect(() => {
    //     if (router?.get(Constants.QueryParams.CHANNEL.VALUE) && listChannel?.records?.length > 0) {
    //         if(!name && id != router?.get(Constants.QueryParams.CHANNEL.VALUE)){
    //             setId(router?.get(Constants.QueryParams.CHANNEL.VALUE))
    //             setName(listChannel?.records?.find(x => x?.id == router?.get(Constants.QueryParams.CHANNEL.VALUE))?.name)
    //         }
    //     }
    // }, [listChannel, router?.get(Constants.QueryParams.CHANNEL.VALUE), name])

    useOnClickOutside(ref, () => {
        setOpen(false);
    })

    const onSearchChannel = useCallback(
        debounce(async (e) => {
            onFilterMessage(e, router.get(Constants.QueryParams.CHANNEL.VALUE))
        }, 500),
        [
            router.get(Constants.QueryParams.CHANNEL.VALUE)
        ],
    );

    const handleAddUserToChannel = () => {
        addUserToChannel(listChannel?.records?.find(x => x?.id == router?.get(Constants.QueryParams.CHANNEL.VALUE))?.id);
    }
    let isGroup = listChannel?.records?.find(x => x?.id == router?.get(Constants.QueryParams.CHANNEL.VALUE))?.isGroup;
    return (
        <CustomFormProvider {...methodsFilterMessage}>
            <form onSubmit={methodsFilterMessage.handleSubmit(() => { })}>
                <div className={classes['ContentHeader']}>
                    <p className={classes['ContentName']}>
                        {channelInfo?.name}
                    </p>
                    <div className={classes['ContentIcon']}>
                        {
                            open && router.get(Constants.QueryParams.CHANNEL.VALUE) ?
                                <div className={classes['WrapFormSearch']}>
                                    <FormInput onChangeValue={onSearchChannel} placeholder={t("message.search_msg")} fieldName='search_message' isSearch iconSearch={<IconSearch fontSize={16} />} />
                                    <Button size='small' startIcon={<IconClose color="currentColor" fontSize={18} />} backgroundTransparent onClick={() => {
                                        setOpen(false);
                                        onCloseFilterMessage(router.get(Constants.QueryParams.CHANNEL.VALUE));
                                        methodsFilterMessage.setValue("search_message", "")
                                    }} />
                                </div>
                                :
                                <div style={{
                                    cursor: 'pointer'
                                }} onClick={handleOpenSearchMessage} ref={ref}>
                                    <IconSearch fontSize={16} />
                                </div>
                        }
                        <div style={{
                            marginLeft: 10
                        }} />
                        {
                            channelInfo?.isGroup == "1" && <div onClick={handleAddUserToChannel} >
                                <CustomTooltip
                                    textTooltip={t("message.add_user")}
                                    placement='top'
                                >
                                    <span>
                                        <IconAddChannel fontSize={40} />
                                    </span>
                                </CustomTooltip>
                            </div>
                        }
                    </div>
                </div>
            </form>
        </CustomFormProvider>
    )
}

export default ContentHeader