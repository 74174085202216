import React from 'react';

function IconEmail({ color = 'white'}) {
    return (
        <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19.9484 2.23488C19.8582 1.78074 19.6568 1.36516 19.3756 1.02184C19.3163 0.947031 19.2544 0.880039 19.1872 0.813047C18.689 0.312344 17.9897 0 17.2257 0H2.77426C2.01031 0 1.31352 0.312344 0.81293 0.813047C0.745938 0.880039 0.683984 0.947031 0.624531 1.02184C0.343164 1.36512 0.141797 1.78074 0.0541406 2.23488C0.018125 2.41016 0 2.59129 0 2.77441V12.9241C0 13.3135 0.0825781 13.6878 0.229844 14.0258C0.366445 14.3485 0.567852 14.6404 0.812891 14.8852C0.875 14.9472 0.93668 15.0041 1.00398 15.0608C1.48391 15.4582 2.1034 15.6983 2.77426 15.6983H17.2257C17.8968 15.6983 18.519 15.4582 18.9962 15.0583C19.0633 15.0041 19.1253 14.9472 19.1872 14.8852C19.4321 14.6404 19.6335 14.3485 19.7729 14.0258V14.0233C19.9202 13.6853 20 13.3135 20 12.9242V2.77441C20 2.59129 19.9821 2.41016 19.9484 2.23488ZM1.81676 1.81676C2.06461 1.56918 2.39746 1.4193 2.77426 1.4193H17.2257C17.6025 1.4193 17.9383 1.56918 18.1832 1.81676C18.2271 1.86086 18.2684 1.91 18.3048 1.95883L10.7303 8.5602C10.5214 8.74328 10.2634 8.83371 10 8.83371C9.7393 8.83371 9.48141 8.74328 9.26973 8.5602L1.69813 1.95602C1.73164 1.90719 1.77293 1.86086 1.81676 1.81676ZM1.4193 12.9241V3.42473L6.9007 8.20656L1.42207 12.9833C1.4193 12.9652 1.4193 12.9448 1.4193 12.9241ZM17.2257 14.2787H2.77426C2.52891 14.2787 2.29934 14.2142 2.1034 14.1009L7.88379 9.06344L8.42344 9.53289C8.87504 9.92531 9.44016 10.1241 10 10.1241C10.5627 10.1241 11.1278 9.92531 11.5794 9.53289L12.1187 9.06344L17.8969 14.1009C17.7007 14.2142 17.4711 14.2787 17.2257 14.2787ZM18.5807 12.9241C18.5807 12.9448 18.5807 12.9652 18.578 12.9833L13.0995 8.20934L18.5807 3.42727V12.9241Z" fill="#263238" />
        </svg>
    );
}

export default IconEmail;
