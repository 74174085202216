import React from 'react';

function IconEdit({ color = 'white', fontSize = 14}) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fontSize={fontSize}
        >
            <path
                d="M12.7591 2.25484L10.8695 0.377328C10.3634 -0.12547 9.54407 -0.125929 9.03777 0.376869L7.81281 1.59441L1.82695 7.54534C1.57877 7.792 1.38899 8.08969 1.27022 8.41771L0.0569656 11.774C-0.0695518 12.1242 0.0184714 12.5153 0.283234 12.7785C0.547791 13.0414 0.94174 13.1289 1.29388 13.0029L4.67015 11.7966C5.00018 11.6788 5.29989 11.4897 5.54784 11.2435L11.2143 5.61108L11.2145 5.61131L11.5348 5.29252L12.7591 4.07519L12.7595 4.07475C13.2634 3.57218 13.2654 2.7583 12.7591 2.25484ZM2.46752 8.18203L8.40873 2.27573L9.46868 3.32925L3.4359 9.32546L2.38147 8.27765C2.40911 8.2449 2.43698 8.21235 2.46752 8.18203ZM4.36379 10.9491L2.21248 11.7177L1.35006 10.8605L2.12313 8.7224C2.12864 8.70679 2.13639 8.69204 2.14235 8.67666L4.40957 10.9299C4.3941 10.9361 4.37927 10.9436 4.36379 10.9491ZM4.90725 10.6068C4.87671 10.6369 4.84399 10.6648 4.81104 10.6923L3.75615 9.64384L9.78895 3.64761L10.8487 4.7009L4.90725 10.6068ZM12.1183 3.43873L11.2143 4.33748L11.169 4.38255L8.729 1.95737L9.67857 1.01355C9.83032 0.863394 10.0765 0.86319 10.2289 1.01422L12.1183 2.89152C12.2703 3.04212 12.2705 3.28683 12.1177 3.43916L12.1183 3.43873Z"
                fill={color}
            />
        </svg>
    );
}

export default IconEdit;
