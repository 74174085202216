import useTrans from 'hooks/use-trans'
import React from 'react'
import WarehouseContainer from 'shared/container/warehouse/WarehouseContainer';

function WarehousePage() {
    const { trans } = useTrans();

    document.title=trans("warehouse.warehouse_management")
    return (
        <WarehouseContainer />
    )
}

export default WarehousePage