import React from 'react';
import styles from "./Box.module.scss";

function BoxContent(props) {
    const {width ="100%"} = props
    return (
        <div className={styles["BoxContent"]} style={{width: width, ...props?.style}}>
            {props?.children}
        </div>
    )
}
export default BoxContent;