import React, { useEffect, useState } from 'react'
import classes from './NotifyAlarm.module.scss';
import IconZoom from 'assets/icon/IconZoom';
import IconClose from 'assets/icon/IconClose';
import EventRegister, { EVENT_SHOW_ALARM_LIST_POPUP, EVENT_SHOW_POPUP } from 'utils/EventRegister';
import { useDispatch, useSelector } from 'react-redux';
import TableAlarm from 'shared/components/alarm/TableAlarm';
import CustomTab from '../custom-tab/CustomTab';
import Constants from 'utils/Constants';
import actionAlarm from 'redux/alarm/action';

function NotifyAlarmContent({ setShowVisible }) {
    const { loading: {
        loadingGetAlarm = false
    }, listAlarm, alarmMaster } = useSelector(state => state.alarm);

    const [valueTab, setValueTab] = useState(null)
    const [dataTab, setDataTab] = useState([])
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        setShowVisible(false);
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_ALARM_LIST_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    backgroundColor: 'transparent'
                }
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: actionAlarm.GET_ALARM_MASTER,
            payload: {}
        })
    }, [])


    useEffect(() => {
        if (alarmMaster?.alarmTypes) {
            let masterType = Object.keys(alarmMaster?.alarmTypes).map(key => {
                return {
                    key: key,
                    id: key,
                    label: alarmMaster?.alarmTypes[key],
                }
            });
            masterType = masterType?.sort((a,b) => a.key.localeCompare(b.key));
            setDataTab(masterType);
            setValueTab(masterType[0].key);
        }
    }, [alarmMaster?.alarmTypes])


    useEffect(() => {
        if (valueTab && dataTab) {
            dispatch({
                type: actionAlarm.GET_LIST_ALARM,
                payload: {
                    [Constants.QueryParams.TAB.VALUE]: valueTab
                }
            })
        }
    }, [valueTab, dataTab]);
    
    return (
        <>
            <div className={classes['layout']}>
                <div className={classes['layout-content']}>
                    <CustomTab action={<div className={classes['tab-right']}>
                        <div onClick={handleOpenPopup} className={classes['icon_zoom']}>
                            <IconZoom fontSize={14} />
                        </div>

                        <div onClick={() => setShowVisible(false)} className={classes['icon_close']}>
                            <IconClose fontSize={18} color='white' />
                        </div>
                    </div>} data={dataTab} value={valueTab} onChange={(e) => {
                        setValueTab(e)
                    }} />
                    <div className={classes['body']}>
                        <div className={classes['Custom-body']}>
                            <TableAlarm maxHeight={90} loadingGetAlarm={loadingGetAlarm} listAlarm={listAlarm} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotifyAlarmContent