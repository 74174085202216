import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import SystemSettingAction from './action';
import factory from './factory';

function* getHolonMasterSystem() {
    yield takeEvery(
        SystemSettingAction.GET_HOLON_MASTER_SYSTEM,
        function* (payload) {
            try {
                const response = yield call(() =>
                    factory.getHolonMasterSystem(),
                );
                if (response) {
                    yield put({
                        type: SystemSettingAction.GET_HOLON_MASTER_SYSTEM_SUCCESS,
                        payload: response,
                    });
                }
            } catch (error) {
                yield put({
                    type: SystemSettingAction.GET_HOLON_MASTER_SYSTEM_FAILED,
                });
            }
        },
    );
}

function* updateHolonMasterSystem() {
    yield takeEvery(SystemSettingAction.UPDATE_HOLON_MASTER_SYSTEM, function* (formData) {
        const {
            payload,
            onSuccess = () => {},
            onFailure = () => {},
        } = formData;
        try {
            const response = yield call(() =>
                factory.putHolonMasterSystem(payload)
            );
            const requestError = response.error ?? null;
            if(requestError && response){
                yield put({
                    type: SystemSettingAction.UPDATE_HOLON_MASTER_SYSTEM_FAILED,
                    payload: response,
                });
                const detailError = requestError.detail ?? {};
                typeof onFailure === "function" && onFailure(detailError);
            }else{
                typeof onSuccess === "function" && onSuccess();
                yield put({
                    type: SystemSettingAction.UPDATE_HOLON_MASTER_SYSTEM_SUCCESS,
                });
            }

        } catch (res) {
            const { data: { error } } = res?.response;
            yield put({
                type: SystemSettingAction.UPDATE_HOLON_MASTER_SYSTEM_FAILED,
            });
            typeof onFailure === "function" && onFailure(error?.detail);
        }
    });
}
export default function* rootSaga() {
    yield all([
        fork(getHolonMasterSystem),
        fork(updateHolonMasterSystem),
    ]);
}
