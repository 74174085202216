import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import classes from "./UploadFile.module.scss";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Loading from "../button/loading/Loading";
import Action from 'redux/user/action';
import ErrorMessage from "../error-message/ErrorMessage";
import ErrorMessageHandleRegex from "../error-message/ErrorMessageHandleRegex";
import ErrorMessageHandleFE from "../error-message/ErrorMessageHandleFE";
import Button from "../button/Button";
import IconEdit from "assets/icon/IconEdit";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_PREVIEW_FILE_POPUP } from "utils/EventRegister";
import IconDelete from "assets/icon/IconDelete";
import { v4 as uuidv4 } from 'uuid'
import { replaceCondenseFilename } from "utils/Utils";

const UploadFileAttachment = (props) => {
    const {
        name,
        disabled = false,
        value,
        refInput,
        onChange,
        placeholder,
        onFocus,
        style,
        maxLength,
        onBlur,
        errors,
        maximumUpload = 100,
        isRegexLabel,
        validationFE
    } = props;
    const [listFile, setListFile] = useState([])
    const [dragActive, setDragActive] = React.useState(false);
    const [loadingUpload, setLoadingUpload] = React.useState(false);
    const refImport = useRef();
    const dispatch = useDispatch();

    const onChangeUpload = async (data) => {
        setLoadingUpload(true);
        let _listFile = listFile ?? [];
        for (let index = 0; index < data.length; index++) {
            const result = await new Promise((resolve, reject) => {
                uploadFile(data[index], (url) => {
                    if (url) {
                        resolve(url)
                    } else {
                        reject(url)
                    }
                })
            });
            _listFile.push({
                name: replaceCondenseFilename(result),
                content: result,
                type: "",
                id: uuidv4()
            });
            setLoadingUpload(false);
        }
        onChange(_listFile ?? []);
        refImport.current.value = null;
    };

    const uploadFile = (data, callback) => {
        dispatch({
            type: Action.UPLOAD_IMAGE,
            payload: data,
            callback: (url) => {
                callback(url);
            }
        })
    }
    const onChangeImport = (e) => {
        if (e.target.files?.length == 0) {
            return;
        }
        if (e.target.files) {
            onChangeUpload(e.target.files);
        }
    };

    useEffect(() => {
        if (!value) {
            setListFile(null);
        } else {
            setListFile(value);
            refImport.current.value = null;
        }
    }, [value]);

    let showError = false;
    let error = {
        message: "",
    };

    let arr = name.split(".");
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    const handleDrag = function (
        e
    ) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files) {
            if (e.dataTransfer.files.length == 1) {
                if (e.dataTransfer.files[0]) {
                    onChangeUpload(e.dataTransfer.files[0]);
                }
            } else {
                onChangeUpload(e.dataTransfer.files[0]);
            }
        }
    };

    const handleOpenPreview = (x) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_PREVIEW_FILE_POPUP,
            payload: {
                linkFile: x?.content,
                linkFileName: x?.name,
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
            }
        })
    }

    const handleDelete = (id) => {
        let data = [...listFile];
        let index = data?.findIndex(x => x?.id == id);
        if (index != -1) {
            data.splice(index, 1)
        }
        onChange(data ?? []);
        setListFile(data);
    }
    return (
        <>
            <div
                style={{
                    pointerEvents: loadingUpload || disabled ? 'none' : 'fill'
                }}
                ref={refInput !== null ? refInput : null}
                className={`${classes["Custom_Input"]} ${showError && classes["Custom_Input_Error"]} ${(value?.length ?? 0) == maximumUpload && classes["Custom_Input_Max"]}`}
            >
                {
                    listFile?.length > 0 && <div className={classes['Content_File']}>
                        {
                            listFile?.map((value, key) => {
                                return <Button className="BtnFile" color="white" key={key} title={<span className={classes['ContentFile']}>{value?.name}</span>}
                                    endIcon={<span className={classes['Action_Delete']} onClick={() =>
                                        handleDelete(value?.id)}><IconDelete fontSize={20} /></span>} />
                            })
                        }
                    </div>
                }
                <div>
                    <label htmlFor="upload_Attachment" id="dropReceiver">
                        <div className={classes['Btn_Add']}>
                            {
                                loadingUpload ? <Loading /> : <IconEdit />
                            }
                        </div>
                    </label>
                    <input
                        name={name}
                        disabled={disabled}
                        ref={refImport}
                        type={"file"}
                        onChange={(e) => {
                            onChangeImport(e);
                        }}
                        style={style}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        autoComplete="off"
                        hidden
                        id="upload_Attachment"
                        multiple={true}
                        accept="*"
                    />
                </div>
            </div>
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default UploadFileAttachment;
