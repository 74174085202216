import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import actionCargo from 'redux/cargo/action';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import Constants from 'utils/Constants';
import jsonpath from 'jsonpath';
import { useParams } from 'react-router-dom';

function InventoryManagementContainer() {
    const dispatch = useDispatch();
    const [list, setList] = useState([])
    const [warehouse, setWarehouse] = useState(null)
    const { t } = useTrans();
    const { id } = useParams()

    useEffect(() => {
        dispatch({
            type: actionCargo.GET_TEMPLATE_CARGO,
            payload: {
                masterCodes: Constants.CARGO_MASTER_CODE.PRODUCT.MASTER_CODE
            },
            callback: {
                success: (data) => {
                    setList(data?.records)
                }
            }
        })
        dispatch({
            type: actionCargo.GET_WARE_HOUSE_DEFAULT,
            callback: {
                success: (data) => {
                    if (data?.id) {
                        setWarehouse(data)
                    }
                }
            }
        })
    }, [])

    return (
        <>
            {
                list?.length > 0 && <HolonInstance
                    isCargo
                    template_id={list?.map(x => x?.id)?.join(",")}
                    titleCreatePopup={t("cargo.cargo_creation")}
                    titleDetailPopup={t("cargo.cargo_detail")}
                    titleUpdatePopup={t("cargo.cargo_edit")}
                    title={t("stocks.stocks_management")}
                    isShowCategory
                    typeCategory={Constants.CARGO_MASTER_CODE.PRODUCT.CATEGORY}
                    requireCKey={Constants.CARGO_MASTER_CODE.CARD_BOARD.CONNECTION_KEY.WAREHOUSE}
                    requireCId={id ? id : warehouse?.id}
                    isUseAddFunction={true}
                    permissionClone={true}
                    permissionDelete={true}
                    permissionUpdate={true}
                    listTemplateCreation={list}
                />
            }
        </>
    )
}

export default InventoryManagementContainer