import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionDashboard from "./action";
import factory from "./factory";

function* getNotifications() {
    yield takeEvery(actionDashboard.GET_NOTIFICATIONS, function* (payload) {
        try {
            const response = yield call(() => factory.getNotifications(payload?.payload));
            if (response?.records) {
                yield put({
                    type: actionDashboard.GET_NOTIFICATIONS_SUCCESS,
                    payload: response?.records
                })
            } else {
                yield put({
                    type: actionDashboard.GET_NOTIFICATIONS_FAILED,
                })
            }
        } catch (error) {
            yield put({
                type: actionDashboard.GET_NOTIFICATIONS_FAILED,
            })
        }
    })
}

function* getWallets() {
    yield takeEvery(actionDashboard.GET_WALLETS, function* (payload) {
        try {
            const response = yield call(() => factory.getWallets(payload?.payload));
            yield put({
                type: actionDashboard.GET_WALLETS_SUCCESS,
                payload: response
            })
        } catch (error) {
            yield put({
                type: actionDashboard.GET_WALLETS_FAILED,
            })
        }
    })
}

function* getOrders() {
    yield takeEvery(actionDashboard.GET_ORDERS, function* (payload) {
        try {
            const response = yield call(() => factory.getOrders(payload?.payload));
            yield put({
                type: actionDashboard.GET_ORDERS_SUCCESS,
                payload: response
            })
        } catch (error) {
            yield put({
                type: actionDashboard.GET_ORDERS_FAILED,
            })
        }
    })
}

function* getNumberOfUser() {
    yield takeEvery(actionDashboard.GET_NUMBER_OF_USER, function* (payload) {
        try {
            const response = yield call(() => factory.countUser());
            if (response?.data) {
                yield put({
                    type: actionDashboard.GET_NUMBER_OF_USER_SUCCESS,
                    payload: response?.data
                })
            } else {
                yield put({
                    type: actionDashboard.GET_NUMBER_OF_USER_FAILURE,
                })    
            }
        } catch (error) {
            yield put({
                type: actionDashboard.GET_NUMBER_OF_USER_FAILURE,
            })
        }
    })
}

function* getNumberOfOrderedUser() {
    yield takeEvery(actionDashboard.GET_NUMBER_OF_ORDERED_USER, function* (payload) {
        try {
            const response = yield call(() => factory.countOrderedUser());
            if (response?.data) {
                yield put({
                    type: actionDashboard.GET_NUMBER_OF_ORDERED_USER_SUCCESS,
                    payload: response?.data
                })
            } else {
                yield put({
                    type: actionDashboard.GET_NUMBER_OF_ORDERED_USER_FAILURE,
                })
            }
        } catch (error) {
            yield put({
                type: actionDashboard.GET_NUMBER_OF_ORDERED_USER_FAILURE,
            })
        }
    })
}


function* getWalletInfoUser() {
    yield takeEvery(actionDashboard.GET_DASHBOARD_WALLET_INFO, function* (payload) {
        try {
            const response = yield call(() => factory.walletInfo());
            if (response?.data) {
                yield put({
                    type: actionDashboard.GET_DASHBOARD_WALLET_INFO_SUCCESS,
                    payload: response?.data
                })
            } else {
                yield put({
                    type: actionDashboard.GET_DASHBOARD_WALLET_INFO_FAILURE,
                })
            }
        } catch (error) {
            yield put({
                type: actionDashboard.GET_DASHBOARD_WALLET_INFO_FAILURE,
            })
        }
    })
}

function* countInquiry() {
    yield takeEvery(actionDashboard.GET_INQUIRY_COUNT, function* (payload) {
        try {
            const response = yield call(() => factory.countInquiry());
            if (response?.data) {
                yield put({
                    type: actionDashboard.GET_INQUIRY_COUNT_SUCCESS,
                    payload: response?.data
                })
            } else {
                yield put({
                    type: actionDashboard.GET_INQUIRY_COUNT_FAILURE,
                })
            }
        } catch (error) {
            yield put({
                type: actionDashboard.GET_INQUIRY_COUNT_FAILURE,
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getNotifications),
        fork(getWallets),
        fork(getOrders),
        fork(getNumberOfUser),
        fork(getNumberOfOrderedUser),
        fork(getWalletInfoUser),
        fork(countInquiry)
    ])
}