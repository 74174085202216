import useTrans from 'hooks/use-trans';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import actionCargo from 'redux/cargo/action';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import HolonInstance from 'shared/components/holon-instance/HolonInstance';
import Constants from 'utils/Constants'

function RequestPaymentContainer() {
    const dispatch = useDispatch();
    const [list, setList] = useState([])
    const { t } = useTrans();
    const [listTemplateCreation, setListTemplateCreation] = useState([]);

    useEffect(() => {
        dispatch({
            type: actionCargo.GET_TEMPLATE_CARGO,
            payload: {
                masterCodes: Constants.REQUEST_PAYMENT_MASTER_CODE.MASTER_CODE.VALUE
            },
            callback: {
                success: (data) => {
                    setList(data?.records);
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_TEMPLATE_WITH_IDS,
                        payload: data?.records?.map(x => x?.id)?.join(","),
                        callback: (_data) => {
                            setListTemplateCreation(_data?.map(x => ({ id: x?.id, name: x?.name})));
                        }
                    })
                }
            }
        })
    }, [])
    return (
        <>
            {
                list?.length > 0 && <HolonInstance
                    isCargo
                    template_id={list?.map(x => x?.id)?.join(",")}
                    titleCreatePopup={t("request_payment.request_payment_creation")}
                    titleDetailPopup={t("request_payment.request_payment_detail")}
                    titleUpdatePopup={t("request_payment.request_payment_edit")}
                    title={t("request_payment.customer_request_payment")}
                    typeCategory={Constants.REQUEST_PAYMENT_MASTER_CODE.CATEGORY.VALUE}
                    isShowCategory
                    listTemplateCreation={listTemplateCreation}
                />
            }
        </>
    )
}

export default RequestPaymentContainer