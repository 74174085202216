const FaqAction = {
    GET_FAQ_CATEGORIES : "GET_FAQ_CATEGORIES",
    GET_FAQ_CATEGORIES_LOADING : "GET_FAQ_CATEGORIES_LOADING",
    GET_FAQ_CATEGORIES_SUCCESS : "GET_FAQ_CATEGORIES_SUCCESS",
    GET_FAQ_CATEGORIES_FAILED : "GET_FAQ_CATEGORIES_FAILED",

    GET_FAQ_CATEGORIES_WITH_LANGUAGE : "GET_FAQ_CATEGORIES_WITH_LANGUAGE",
    GET_FAQ_CATEGORIES_WITH_LANGUAGE_LOADING : "GET_FAQ_CATEGORIES_WITH_LANGUAGE_LOADING",
    GET_FAQ_CATEGORIES_WITH_LANGUAGE_SUCCESS : "GET_FAQ_CATEGORIES_WITH_LANGUAGE_SUCCESS",
    GET_FAQ_CATEGORIES_WITH_LANGUAGE_FAILED : "GET_FAQ_CATEGORIES_WITH_LANGUAGE_FAILED",
    
    GET_FAQ_CATEGORIES_POPUP : "GET_FAQ_CATEGORIES_POPUP",
    GET_FAQ_CATEGORIES_POPUP_LOADING : "GET_FAQ_CATEGORIES_POPUP_LOADING",
    GET_FAQ_CATEGORIES_POPUP_SUCCESS : "GET_FAQ_CATEGORIES_POPUP_SUCCESS",
    GET_FAQ_CATEGORIES_POPUP_FAILED : "GET_FAQ_CATEGORIES_POPUP_FAILED",

    GET_FAQ_CATEGORIES_MASTER : "GET_FAQ_CATEGORIES_MASTER",
    GET_FAQ_CATEGORIES_MASTER_LOADING : "GET_FAQ_CATEGORIES_MASTER_LOADING",
    GET_FAQ_CATEGORIES_MASTER_SUCCESS : "GET_FAQ_CATEGORIES_MASTER_SUCCESS",
    GET_FAQ_CATEGORIES_MASTER_FAILED : "GET_FAQ_CATEGORIES_MASTER_FAILED",

    ADD_FAQ_CATEGORY : "ADD_FAQ_CATEGORY",
    ADD_FAQ_CATEGORY_LOADING : "ADD_FAQ_CATEGORY_LOADING",
    ADD_FAQ_CATEGORY_SUCCESS : "ADD_FAQ_CATEGORY_SUCCESS",
    ADD_FAQ_CATEGORY_FAILED : "ADD_FAQ_CATEGORY_FAILED",

    UPDATE_FAQ_CATEGORY : "UPDATE_FAQ_CATEGORY",
    UPDATE_FAQ_CATEGORY_LOADING : "UPDATE_FAQ_CATEGORY_LOADING",
    UPDATE_FAQ_CATEGORY_SUCCESS : "UPDATE_FAQ_CATEGORY_SUCCESS",
    UPDATE_FAQ_CATEGORY_FAILED : "UPDATE_FAQ_CATEGORY_FAILED",

    DELETE_FAQ_CATEGORY : "DELETE_FAQ_CATEGORY",
    DELETE_FAQ_CATEGORY_LOADING : "DELETE_FAQ_CATEGORY_LOADING",
    DELETE_FAQ_CATEGORY_SUCCESS : "DELETE_FAQ_CATEGORY_SUCCESS",
    DELETE_FAQ_CATEGORY_FAILED : "DELETE_FAQ_CATEGORY_FAILED",

    GET_DETAIL_FAQ_CATEGORIES : "GET_DETAIL_FAQ_CATEGORIES",
    GET_DETAIL_FAQ_CATEGORIES_LOADING : "GET_DETAIL_FAQ_CATEGORIES_LOADING",
    GET_DETAIL_FAQ_CATEGORIES_SUCCESS : "GET_DETAIL_FAQ_CATEGORIES_SUCCESS",
    GET_DETAIL_FAQ_CATEGORIES_FAILED : "GET_DETAIL_FAQ_CATEGORIES_FAILED",

    // GET_FAQ_WITH_CATEGORY 

    GET_FAQ_WITH_CATEGORY : "GET_FAQ_WITH_CATEGORY",
    GET_FAQ_WITH_CATEGORY_LOADING : "GET_FAQ_WITH_CATEGORY_LOADING",
    GET_FAQ_WITH_CATEGORY_SUCCESS : "GET_FAQ_WITH_CATEGORY_SUCCESS",
    GET_FAQ_WITH_CATEGORY_FAILED : "GET_FAQ_WITH_CATEGORY_FAILED",


    UPDATE_FAQ_WITH_CATEGORY : "UPDATE_FAQ_WITH_CATEGORY",
    UPDATE_FAQ_WITH_CATEGORY_LOADING : "UPDATE_FAQ_WITH_CATEGORY_LOADING",
    UPDATE_FAQ_WITH_CATEGORY_SUCCESS : "UPDATE_FAQ_WITH_CATEGORY_SUCCESS",
    UPDATE_FAQ_WITH_CATEGORY_FAILED : "UPDATE_FAQ_WITH_CATEGORY_FAILED",

    CREATE_FAQ_WITH_CATEGORY : "CREATE_FAQ_WITH_CATEGORY",
    CREATE_FAQ_WITH_CATEGORY_LOADING : "CREATE_FAQ_WITH_CATEGORY_LOADING",
    CREATE_FAQ_WITH_CATEGORY_SUCCESS : "CREATE_FAQ_WITH_CATEGORY_SUCCESS",
    CREATE_FAQ_WITH_CATEGORY_FAILED : "CREATE_FAQ_WITH_CATEGORY_FAILED",

    DELETE_FAQ_WITH_CATEGORY : "DELETE_FAQ_WITH_CATEGORY",
    DELETE_FAQ_WITH_CATEGORY_LOADING : "DELETE_FAQ_WITH_CATEGORY_LOADING",
    DELETE_FAQ_WITH_CATEGORY_SUCCESS : "DELETE_FAQ_WITH_CATEGORY_SUCCESS",
    DELETE_FAQ_WITH_CATEGORY_FAILED : "DELETE_FAQ_WITH_CATEGORY_FAILED",

    GET_DETAIL_FAQ_WITH_CATEGORIES : "GET_DETAIL_FAQ_WITH_CATEGORIES",
    GET_DETAIL_FAQ_WITH_CATEGORIES_POPUP : "GET_DETAIL_FAQ_WITH_CATEGORIES_POPUP",
    GET_DETAIL_FAQ_WITH_CATEGORIES_LOADING : "GET_DETAIL_FAQ_WITH_CATEGORIES_LOADING",
    GET_DETAIL_FAQ_WITH_CATEGORIES_SUCCESS : "GET_DETAIL_FAQ_WITH_CATEGORIES_SUCCESS",
    GET_DETAIL_FAQ_WITH_CATEGORIES_FAILED : "GET_DETAIL_FAQ_WITH_CATEGORIES_FAILED",

    GET_FAQ_CATEGORIES_WITH_LANGUAGE_POPUP : "GET_FAQ_CATEGORIES_WITH_LANGUAGE_POPUP",
}
export default FaqAction;