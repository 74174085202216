import React, { useEffect, useState, useRef } from 'react'
import classes from './InputMessage.module.scss';
import IconTag from 'assets/icon/IconTag';
import IconClose from 'assets/icon/IconClose';
import { useDispatch } from 'react-redux';
import Action from 'redux/user/action';
import { replaceCondenseFilename } from 'utils/Utils';
import { v4 as uuidv4 } from 'uuid'
import Loading from 'shared/components/form-input/button/loading/Loading.js';

function InputUploadMessage(props) {
    const {
        onChange
    } = props;
    const [files, setFiles] = useState([]);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const refFile = useRef(null);
    const dispatch = useDispatch();

    const uploadFile = (data, callback) => {
        dispatch({
            type: Action.UPLOAD_IMAGE,
            payload: data,
            callback: (url) => {
                callback(url);
            }
        })
    }

    const handleUploadFileToURL = async (data, files) => {
        let buttonAll = document.querySelectorAll('button');
        let arrButton = [];
        buttonAll.forEach(element => {
            if (element.disabled == false) {
                element.disabled = true;
                arrButton.push(element);
            }
        });
        setLoadingUpload(true);
        let _listFile = files ?? [];
        for (let index = 0; index < data.length; index++) {
            const result = await new Promise((resolve, reject) => {
                uploadFile(data[index], (url) => {
                    if (url) {
                        resolve(url)
                    } else {
                        reject(url)
                    }
                })
            });
            _listFile.push({
                name: replaceCondenseFilename(result),
                content: result,
                type: "",
                id: uuidv4()
            });
            setLoadingUpload(false);
            arrButton.forEach(element => {
                element.disabled = false;
            });
        }
        onChange(_listFile ?? []);
        refFile.current.value = null;
    }

    const handleRemoveItem = (x) => {
        let index = [...files]?.findIndex(v => v?.id == x?.id);
        if (index != -1) {
            files?.splice(index, 1);
        }
        props?.onChange(files ?? []);
    }

    useEffect(() => {
        if (props?.value) {
            setFiles([...props?.value]);
        } else {
            setFiles([]);
            refFile.current.value = null;
        }
    }, [props?.value])

    const onChangeImport = (e) => {
        if (e?.target?.files?.length > 0) {
            handleUploadFileToURL(e.target.files, files);
        }

        if (e?.clipboardData?.files?.length > 0) {
            handleUploadFileToURL(e.clipboardData.files);
        }
    };


    return (
        <div style={{
            pointerEvents: props?.disabled ? 'none' : 'fill'
        }} className={classes['InputUploadMessage']}>
            <div className={classes['ListFile_Upload']}>
                {
                    files?.length > 0 && <div className={classes['ListFile_Upload_Content']}>
                        {
                            files?.map((x, key) => {
                                return <div className={`${classes['Item_Name']} ${props?.loading ? classes['Loading'] : ''}`} key={key}>
                                    <p>{x?.name}</p>
                                    <div className={classes['Icon_Close']} onClick={() => !props?.disabled && handleRemoveItem(x)}>
                                        <IconClose color="currentColor" fontSize={14} />
                                    </div>
                                    {props?.loading ? <div className={classes['Loading_Upload']}><Loading /></div> : <></>}
                                </div>
                            })
                        }
                    </div>
                }
                <label className={classes['Icon_Tag']} htmlFor='uploadFile'>
                    {
                        loadingUpload ? <Loading color='hippie_blue' /> : <IconTag />
                    }
                </label>
            </div>
            <input ref={refFile} onChange={onChangeImport} id='uploadFile' hidden accept='file' type='file' />
        </div>
    )
}

export default InputUploadMessage