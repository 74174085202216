import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionStock from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";
import { showPopupNotification } from "utils/Utils";

function* getListStocks() {
    yield takeEvery(actionStock.GET_LIST_STOCKS, function* (payload) {
        try {
            const response = yield call(() => factory.getListStocks(payload?.payload));
            yield put({
                type: actionStock.GET_LIST_STOCKS_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* updateStocks() {
    yield takeEvery(actionStock.UPDATE_STOCKS, function* (payload) {
        try {
            const response = yield call(() => factory.updateStocks(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionStock.UPDATE_STOCKS_SUCCESS,
                    payload: response
                })
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield put({
                    type: actionStock.UPDATE_STOCKS_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: error?.error?.detail?.exception[0]
            });
        }
    })
}

function* updateInventory() {
    yield takeEvery(actionStock.UPDATE_INVENTORY, function* (payload) {
        try {
            const response = yield call(() => factory.updateInventory(payload?.payload));
            if (response?.data) {
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed();
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            }
        } catch (error) {
            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: error?.error?.detail?.exception[0]
            });
        }
    })
}

function* createStocks() {
    yield takeEvery(actionStock.CREATE_STOCKS, function* (payload) {
        try {
            const response = yield call(() => factory.createStocks(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionStock.CREATE_STOCKS_SUCCESS,
                    payload: response
                })
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield put({
                    type: actionStock.CREATE_STOCKS_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: error?.error?.detail?.exception[0]
            });            yield put({
                type: actionStock.CREATE_STOCKS_FAILED,
                payload: error
            })
        }
    })
}

function* deleteStocks() {
    yield takeEvery(actionStock.DELETE_STOCKS, function* (payload) {
        try {
            const response = yield call(() => factory.deleteStocks(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionStock.DELETE_STOCKS_SUCCESS
                })
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield payload?.callback?.failed && payload?.callback?.failed();
                yield put({
                    type: actionStock.DELETE_STOCKS_FAILED,
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed();
            yield put({
                type: actionStock.DELETE_STOCKS_FAILED
            });
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

function* getDetailStocks() {
    yield takeEvery(actionStock.GET_DETAIL_STOCKS, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailStocks(payload?.payload));
            yield put({
                type: actionStock.GET_DETAIL_STOCKS_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}

function* getDetailStocksPopup() {
    yield takeEvery(actionStock.GET_DETAIL_STOCKS_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailStocks(payload?.payload));
            yield payload?.callback(response?.data);
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListStocks),
        fork(updateStocks),
        fork(createStocks),
        fork(deleteStocks),
        fork(getDetailStocks),
        fork(getDetailStocksPopup),
        fork(updateInventory)
    ])
}