import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import ColorPicker from '../form-input/color-picker/ColorPicker';

function FormColorPicker(props) {
    const {
        fieldName,
        validate = [],
        placeholder,
        disabled = false,
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className="Input w-100">
            <div className="w-100">
                <Controller
                    control={control}
                    name={fieldName}
                    rules={{
                        validate: Validator.genValidate(validate, fieldName),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                            <ColorPicker refField={ref} fieldName={fieldName} onChange={onChange} onBlur={onBlur} value={value} />
                        );
                    }}
                />
            </div>
        </div>
    );
}
FormColorPicker.defaultProps = {
    placeholder: '',
    fieldName: 'fieldName',
};
FormColorPicker.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    iconSearch: PropTypes.element,
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    isSearch: PropTypes.bool,
};
export default FormColorPicker;
