import classes from './CreateReceiver.module.scss';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import CustomHeader from '../custom-header/CustomHeader';
import FormInput from 'shared/components/custom-form/FormInput';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useFieldArray, useForm } from 'react-hook-form';
import Constants from 'utils/Constants';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import FormSelect from 'shared/components/custom-form/FormSelect';
import Button from 'shared/components/form-input/button/Button';
import Validator from 'utils/Validator';
import FormRadio from 'shared/components/custom-form/FormRadio';
import useTrans from 'hooks/use-trans';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import FormFile from 'shared/components/custom-form/FormFile';
import FormImage from 'shared/components/custom-form/FormImage';
import useRouter from 'hooks/use-router';
import moment from 'moment';
import actionReceiver from 'redux/receiver/action';
import WrapContentBody from '../custom-body/WrapContentBody';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_UPDATE, EVENT_SHOW_RECEIVER_DETAIL_POPUP } from 'utils/EventRegister';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import Box from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import { convertRegexToOptions } from 'utils/Utils';

function UpdatedReceiverPopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const [loading, setLoading] = useState(false)
    const methods = useForm();
    const router = useRouter();
    const control = methods.control;
    const { holonTemplateDetail, receiver } = payload;
    const refPass = useRef(false);
    const dispatch = useDispatch();

    const renderInput = (value, index) => {
        switch (value?.elementType) {
            case Constants.ELEMENT_TYPE.TEXT.VALUE:
                let validate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validate.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.ADDRESS.VALUE:
                let validateAddreess = [Validator.email];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAddreess.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateAddreess]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.EMAIL.VALUE:
                let validateEmail = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateEmail.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validateEmail]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.FILE.VALUE:
                let validateFile = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateFile.push(Validator.requiredInput)
                }
                return <FormFile disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateFile]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.IMAGE.VALUE:
                let validateImage = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateImage.push(Validator.requiredInput)
                }
                return <FormImage disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateImage]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.NUMBER.VALUE:
                let validateNumber = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateNumber.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} format={Constants.FormInputFormat.NUMBER.VALUE} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateNumber]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.URL.VALUE:
                let validateUrl = [Validator.URL];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUrl.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateUrl]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATE.VALUE:
                let validateDate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDate.push(Validator.requiredInput)
                }
                return <FormDatePicker disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATETIME.VALUE:
                let validateDateTime = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDateTime.push(Validator.requiredInput)
                }
                return <FormDatePicker disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDateTime]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.AUTOGEN.VALUE:
                let validateAutogen = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAutogen.push(Validator.requiredInput)
                }
                return <FormInput disabled validate={[Validator.customValidator(value?.regex, value?.msg), ...validateAutogen]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.UNIQUE.VALUE:
                let validateUnique = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUnique.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateUnique]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE:
                let validatSelectTemplate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validatSelectTemplate.push(Validator.requiredInput)
                }
                return <FormSelectTemplate isMulti={value?.isMultiSelected == "1" ? true : false} disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validatSelectTemplate]} fieldName={`attributes.${index}.${value?.key}`} />

            case Constants.ELEMENT_TYPE.SELECT.VALUE:
                let optionSelect = convertRegexToOptions(value?.regex);
                if (value?.isMultiSelected == "1") {
                    return <FormSelect disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} isMultiSelected options={optionSelect} validate={[]} fieldName={`attributes.${index}.${value?.key}`} />
                } else {
                    return <FormSelect disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} options={optionSelect} validate={[]} fieldName={`attributes.${index}.${value?.key}`} />
                }
            case Constants.ELEMENT_TYPE.RADIO.VALUE:
                let validateRadio = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateRadio.push(Validator.requiredInput)
                }
                let optionRadio = convertRegexToOptions(value?.regex);
                return <FormRadio disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} options={optionRadio} validate={[...validateRadio]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.TEL.VALUE:
                let validateTel = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateTel.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateTel]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.PASSWORD.VALUE:
                let validatePass = [];
                let validatePassConfirm = [
                    Validator.confirmPassword(methods.watch(`attributes.${index}.${value?.key}`))
                ];

                if (!refPass.current) {
                    validatePass = [];
                    validatePassConfirm = [];
                } else {
                    validatePass.push(Validator.customValidator(value?.regex, value?.msg))
                    validatePassConfirm.push(Validator.confirmPassword(methods.watch(`attributes.${index}.${value?.key}`)))
                }

                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validatePass.push(Validator.requiredInput)
                }

                const onFocus = () => {
                    refPass.current = true;
                }
                return <div className={classes['attributes-password']}>
                    <FormInput onFocus={onFocus} showIconPassword={refPass.current} valueCurrent={value[value?.key]} disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} type="password" validate={[...validatePass]} fieldName={`attributes.${index}.${value?.key}`} />
                    <FormInput showIconPassword={refPass.current} disabled={
                        (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) || refPass.current ? false : true} type="password" validate={validatePassConfirm} fieldName={`attributes.${index}.confirmPassword`} />
                </div>
            case Constants.ELEMENT_TYPE.CHECKBOX.VALUE:
                let validateCheckbox = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateCheckbox.push(Validator.requiredCheckbox)
                }
                return <FormCheckbox disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[...validateCheckbox]} fieldName={`attributes.${index}.${value?.key}`} />
            default:
                let validateDefault = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDefault.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDefault]} fieldName={`attributes.${index}.${value?.key}`} />
        }
    }

    const {
        fields: fieldsHas,
    } = useFieldArray({
        control, name: "attributes"
    });

    const {
        fields: fieldsConnection,
    } = useFieldArray({
        control, name: "connections"
    });


    useEffect(() => {
        if (holonTemplateDetail?.attributes?.length > 0 && receiver?.id) {
            methods.setValue("attributes", holonTemplateDetail?.attributes?.map(x => {
                let indexDetail = receiver?.attributes?.findIndex(attribute => attribute.name == x?.key);
                if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                    return {
                        ...x,
                        [x?.key]: receiver.attributes[indexDetail]?.value == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                    return {
                        ...x,
                        [x?.key]: Constants.PASSWORD.VALUE,
                        currentPassword: receiver.attributes[indexDetail]?.value,
                    }
                }
                return {
                    ...x,
                    [x?.key]: receiver.attributes[indexDetail]?.value
                }
            }))

            let connections = receiver?.connections?.map(x => {
                return {
                    ...x,
                    holonsId: x?.holons?.length > 0 ? x?.holons[0]?.id : null,
                }
            });
            const grouped = connections.reduce((rv, x) => {
                const groupIndex = rv?.findIndex((a) => a.key === x['type']);
                if (groupIndex !== -1) {

                    if (x?.holons[0]?.id) {
                        rv[groupIndex].holons.push({ ...x?.holons[0] });
                    }
                } else {
                    const newGroup = {
                        key: x['type'],
                        ...x,
                        holons: [],
                    };
                    if (x?.holons[0]?.id) {
                        newGroup.holons.push({ ...x?.holons[0] });
                    }
                    rv.push(newGroup);
                }
                return rv;
            }, []);

            // methods.setValue("connections", holonTemplateDetail?.connections?.map(x => {
            //     let indexDetail = grouped?.findIndex(connection => connection.type == x?.key);
            //     return {
            //         ...x,
            //         holons: grouped[indexDetail]?.holons
            //     }
            // }));
        }
    }, [holonTemplateDetail, receiver])

    const onSubmit = (data) => {
        let attributes = data?.attributes?.map(x => {
            if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                return {
                    value: x?.[x?.key] ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                    name: x?.key,
                    elementType: x?.elementType
                }
            }
            if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                return {
                    value: x?.[x?.key]?.length > 0 ? x?.[x?.key]?.join(",") : "",
                    name: x?.key,
                    elementType: x?.elementType
                }
            }
            return {
                value: x?.[x?.key],
                name: x?.key,
                elementType: x?.elementType
            }
        });

        // let connections = data?.connections?.map(x => {
        //     return {
        //         name: x?.key,
        //         type: x?.key,
        //         fromName: x?.fromName,
        //         toName: x?.toName,
        //         holons: x?.instance?.map(holon => {
        //             return {
        //                 id: holon?.id
        //             }
        //         })
        //     }
        // });
        let params = {
            name: holonTemplateDetail?.name,
            description: holonTemplateDetail?.description,
            templateId: holonTemplateDetail?.id,
            type: holonTemplateDetail?.type,
            attributes: attributes,
            id: receiver?.id,
            connections: []
        }

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoading(true)
                    dispatch({
                        type: actionReceiver.UPDATE_RECEIVER,
                        payload: params,
                        callback: {
                            success: () => {
                                dispatch({
                                    type: actionReceiver.GET_RECEIVER_LIST,
                                    payload: {
                                        ...router.getAll()
                                    }
                                })
                                showVisible();
                                setLoading(false)
                            },
                            failed: () => {
                                setLoading(false)
                            }
                        }
                    })
                }
            }
        })

    }

    const gotoDetail = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_RECEIVER_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                title: t("receiver.receiver_detail"),
                holonTemplateDetail: holonTemplateDetail,
                receiver: receiver,
            }
        })
    }
    return (
        <BoxContentPopup>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        handleBtnBack={() => {
                            gotoDetail()
                        }}
                        title={payload?.title} showVisible={showVisible} payload={payload}
                        actionSectionBottom={<Button loading={loading} type='submit' title={t('save')} />}
                    >
                        <div className={classes['created-holon-instance']}>
                            <Box>
                                <BoxHeader>
                                    <BoxTitle>{t("receiver.basic_information")}</BoxTitle>
                                </BoxHeader>
                                <BoxBody>
                                    {
                                        fieldsHas?.map((x, index) => {
                                            return <BoxRow key={index}>
                                                <BoxLabel minWidth={140}>{x?.label}
                                                    {x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                                </BoxLabel>
                                                {renderInput(x, index)}
                                            </BoxRow>
                                        })
                                    }
                                </BoxBody>
                            </Box>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default UpdatedReceiverPopup