import React, { useEffect } from 'react'
import useTrans from 'hooks/use-trans';
import { useDispatch, useSelector } from 'react-redux';
import actionSender from 'redux/sender/action';
import HolonInstance from 'shared/components/holon-instance/HolonInstance';

function SenderContainer() {
    const { t } = useTrans();
    const dispatch = useDispatch();

    const { holonTemplateDetail } = useSelector(state => state.sender);

    useEffect(() => {
        dispatch({
            type: actionSender.GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER
        })
    }, [])

    return (
        <>
            {
                holonTemplateDetail?.id && <HolonInstance
                    holonTemplateId={holonTemplateDetail?.id}
                    titleCreatePopup={t("sender.sender_created")}
                    titleDetailPopup={t("sender.sender_detail")}
                    titleUpdatePopup={t("sender.update_sender")}
                    title={t("sender.sender_management")}
                />
            }
        </>
    )
}

export default SenderContainer