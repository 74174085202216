import React from 'react'
import EstimatedFareContainer from 'shared/container/estimated-fare-container/EstimatedFareContainer.js'
function EstimatedFareContainerPopup(props) {
    const { showVisible, payload } = props;
    return (
        <>
            <EstimatedFareContainer />
        </>
    )
}

export default EstimatedFareContainerPopup