import useTrans from 'hooks/use-trans';
import React from 'react'
import WalletContainer from 'shared/container/wallet/WalletContainer'

function WalletPage() {
    const { t } = useTrans();
    document.title = t("wallet.wallet_management");
    return (
        <WalletContainer />
    )
}

export default WalletPage