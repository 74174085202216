import actionStocks from "./action"

const initialState = {
    listProduct: {},
    loadingGetDetail: false,
    loadingCreate: false,
    loadingUpdate: false,
    loadingDelete: false,
    loadingGetList: false,
    product: {}
}

const myReducer = (state = initialState, action) => {
    switch (action?.type) {
        case actionStocks.CREATE_STOCKS:
            return {
                ...state,
                loadingCreate: true
            }
        case actionStocks.CREATE_STOCKS_SUCCESS:
            return {
                ...state,
                loadingCreate: false
            }
        case actionStocks.CREATE_STOCKS_FAILED:
            return {
                ...state,
                loadingCreate: false
            }
        case actionStocks.UPDATE_STOCKS:
            return {
                ...state,
                loadingUpdate: true
            }
        case actionStocks.UPDATE_STOCKS_SUCCESS:
            return {
                ...state,
                loadingUpdate: false
            }
        case actionStocks.UPDATE_STOCKS_FAILED:
            return {
                ...state,
                loadingUpdate: false
            }
        case actionStocks.DELETE_STOCKS:
            return {
                ...state,
                loadingDelete: true
            }
        case actionStocks.DELETE_STOCKS_SUCCESS:
            return {
                ...state,
                loadingDelete: false
            }
        case actionStocks.DELETE_STOCKS_FAILED:
            return {
                ...state,
                loadingDelete: false
            }
        case actionStocks.GET_LIST_STOCKS:
            return {
                ...state,
                loadingGetList: true
            }
        case actionStocks.GET_LIST_STOCKS_SUCCESS:
            return {
                ...state,
                listProduct: action.payload,
                loadingGetList: false
            }
        case actionStocks.GET_DETAIL_STOCKS:
            return {
                ...state
            }
        case actionStocks.GET_DETAIL_STOCKS_SUCCESS:
            return {
                ...state,
                product: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;