import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const factory = {
    getListWallet: (payload) => {

        let params = {
            subAttribute: ""
        };

        let data = {
            subAttribute: {}
        }

        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE];
        }

        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload[Constants.QueryParams.ATTRIBUTES.VALUE]) {
            data['mainAttribute'] = JSON.parse(payload[Constants.QueryParams.ATTRIBUTES.VALUE]);
        }

        let url = `/rest/api/v1/management/wallet/filter`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            params: params,
            data: data
        })
    },
    updateWallet: (payload) => {
        let url = "/rest/api/v1/management/wallet";
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    deleteWallet: (payload) => {
        let url = `/rest/api/v1/management/wallet/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'DELETE',
        })
    },
    createWallet: (payload) => {
        let url = "/rest/api/v1/management/wallet";
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    getDetailWallet: (payload) => {
        let url = `/rest/api/v1/management/wallet/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getHolonTemplateDetail: () => {
        let url = `/rest/api/v1/management/wallet/template`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    creationWalletCharge: (data) => {
        let url = `/rest/api/v1/management/wallet/request-charge`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: data
        })
    },
    creationWalletWithdraw: (data) => {
        let url = `/rest/api/v1/management/wallet/request-withdraw`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: data
        })
    },
    paymentInfo: () => {
        let url = `/rest/api/v1/management/wallet/payment-info`;
        return ApiOperation.request({
            url: url,
            method: 'GET'
        })
    },
    permission: () => {
        let url = `/rest/api/v1/management/wallet/permision-create`;
        return ApiOperation.request({
            url: url,
            method: 'GET'
        })
    },
    paymentInfoWithDraw: (payload) => {
        let url = `/rest/api/v1/management/wallet/wallet-info/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET'
        })
    },
}

export default factory;