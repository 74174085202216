import React, { useEffect, useState } from 'react'
import useRouter from 'hooks/use-router';
import { useDispatch, useSelector } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import useTrans from 'hooks/use-trans';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE } from 'utils/EventRegister';
import Button from 'shared/components/form-input/button/Button';
import styles from './BPServiceSelectionPopup.module.scss';
import Loading from 'shared/components/form-input/button/loading/Loading';
import Constants from 'utils/Constants';
import CustomHeader from '../custom-header/CustomHeader';
import actionHolonTemplate from 'redux/holon-template/action';

function BPServiceSelectionPopup(props) {
    const { showVisible, payload } = props;
    const router = useRouter();
    const { t } = useTrans();
    const [listTemplate, setListTemplate] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_ALL_HOLON_TEMPLATE,
            payload: {
                masterCode: Constants.HOLON_MASTER_CODE.BP_ORDER
            },
            callback: {
                success: (data) => {
                    const listTemplateInfo = data?.records;
                    const serviceIdList = payload?.list_service?.map(String);
                    const filteredServiceList = listTemplateInfo?.filter(obj => serviceIdList?.includes(obj.id));
                    setListTemplate(filteredServiceList?.length ? filteredServiceList : listTemplateInfo);
                }
            }
        })
    }, []);

    const handleAddInstance = (templateId) => {
        const presetProps = payload.preset;
        const dataPreset = {
            templateId: templateId,
            data: {
                attributes: {
                    ...presetProps?.attributes
                },
                connections: {
                    ...presetProps?.connections,
                }
            }
        }

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            payload: {
                title: t("order_management.popup_create_title"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                templateId: templateId,
                dataPreset: dataPreset,
                callback: () => {
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE,
                        payload: {
                            ...router.getAll(),
                        }
                    });
                }
            }
        })
    }

    return (
        <div>
            <CustomHeader
                title={t("service_selection.title")}
                showVisible={showVisible}
                payload={payload}
            />
            <ul className={styles['two-column-list']}>
                {!listTemplate && <li><Loading color="black" /></li>}
                {listTemplate && listTemplate?.map((item, index) => (
                    <li key={index}>
                        <Button size='large' color="green" onClick={() => handleAddInstance(item?.id)}>{item?.name}</Button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default BPServiceSelectionPopup