import React from 'react'
import classes from './CustomHeader.module.scss';
import { Close } from '@mui/icons-material';
import useTrans from 'hooks/use-trans';

function CustomHeader(props) {
    const { showVisible, title } = props;
    const { t } = useTrans()
    return (
        <div className={`${classes["custom-header"]} custom-header-popup`}>
            <div className={classes['icon']}></div>
            <div>
                <p>{title ?? t("Holon_Template.confirm")}</p>
            </div>
            <div className={classes['icon']}>
                <Close onClick={() => showVisible(false)} style={{ cursor: 'pointer', color: 'white' }} aria-label="Close" />
            </div>
        </div>
    )
}

export default CustomHeader