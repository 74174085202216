import React from 'react';
import styles from "./Box.module.scss";

function BoxTitle(props) {
    return (
        <div className={styles["BoxTitle"]}>
            {props?.children}
        </div>
    )
}
export default BoxTitle;