import React from "react";
import classes from "./CrossBorderCourierService.module.scss"
import useTrans from "hooks/use-trans";
import FormInput from 'shared/components/custom-form/FormInput';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import Validator from 'utils/Validator';

const ConstantsTable = (props) => {

    const { appendFareCalcConstants, removeFareCalcConstants, fieldsFareCalcConstants, } = props
    const { t } = useTrans();

    return (
        <div className={classes["constant-table"]}>
            <div className={classes["header-constant-table"]}>
                <p className={classes['title-header-constant']}>
                    {t('fare_calculation.constant')}
                </p>
                <div className={classes['icon-plus']} style={{ cursor: "pointer" }} onClick={() => {
                    appendFareCalcConstants({
                        name: "",
                        key: "",
                        value: "",
                        remark: "0",
                        isGlobal: "",
                        id: '',
                        idCreated: fieldsFareCalcConstants?.length + 1
                    })
                }}>
                    <IconPlusWhite />
                </div>
            </div>
            {
                fieldsFareCalcConstants.length !== 0 &&
                <table>
                    <thead>
                        <tr className={classes['form-header']}>
                            <th className={''}>{t("fare_calculation.name")}</th>
                            <th className={''}>{t("fare_calculation.key")}</th>
                            <th className={''}>{t("fare_calculation.value")}</th>
                            <th className={''}>{t("fare_calculation.remark")}</th>
                            <th className={''}>{t("Holon_Template.delete")} </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fieldsFareCalcConstants.map((x, key,) => {
                                return <tr key={x?.id} index={key}>
                                    <td>
                                        <FormInput validationFE isRegexLabel={t("fare_calculation.name")} fieldName={`fareCalcConstants.${key}.name`} />
                                    </td>
                                    <td>
                                        <FormInput validationFE isRegexLabel={t("fare_calculation.key")} fieldName={`fareCalcConstants.${key}.key`} />
                                    </td>
                                    <td>
                                        <FormInput validationFE validate={[Validator.required, Validator.number]} isRegexLabel={t("fare_calculation.value")} fieldName={`fareCalcConstants.${key}.value`} />
                                    </td>
                                    <td>
                                        <FormInput validationFE isRegexLabel={t("fare_calculation.remark")} fieldName={`fareCalcConstants.${key}.remarks`} />
                                    </td>
                                    <td style={{ minWidth: ' 50px', cursor: 'pointer' }} className={classes['button-action-remove']} onClick={() => removeFareCalcConstants(key)}>
                                        <IconMinusAccordion />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            }
        </div>
    )
};

export default ConstantsTable;
