import React, { useState } from 'react'
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider'
import BoxContentPopup from '../custom-body/BoxContentPopup'
import WrapContentBody from '../custom-body/WrapContentBody'
import Box from '../../box/Box'
import BoxBody from '../../box/BoxBody'
import BoxLabel from '../../box/BoxLabel'
import BoxRow from '../../box/BoxRow'
import BoxContent from '../../box/BoxContent'
import FormInput from 'shared/components/custom-form/FormInput'
import Button from 'shared/components/form-input/button/Button'
import { useForm } from 'react-hook-form'
import useTrans from 'hooks/use-trans'
import classes from './ActionPopup.module.scss';
import { useDispatch } from 'react-redux'
import actionCargo from 'redux/cargo/action'
import { getMessageCommon, showPopupNotification } from 'utils/Utils'
import Constants from 'utils/Constants'

function ReturnRequestAction(props) {
    const {
        payload, showVisible
    } = props;
    const { t } = useTrans();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const methods = useForm();

    const onSubmit = (data) => {
        setLoading(true);
        dispatch({
            type: actionCargo.RETURN_REQUEST_REASON,
            payload: {
                returnReason: data?.returnReason,
                name: payload?.['0']
            },
            callback: {
                success: (response, error) => {
                    showVisible(false);
                    if (response?.data?.reload == Constants.RESPONSE_ACTION.RELOAD.VALUE) {
                        payload?.callback();
                    }
                    showPopupNotification({
                        typePopup: 'message',
                        message: getMessageCommon({
                            response: response,
                            error: error,
                            actionName: payload?.option?.actionName
                        }),
                        newWindow: true,
                    });
                }
            }
        })
    };

    const onClear = () => {
        showVisible(false);
    }
    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <BoxContentPopup>
                    <WrapContentBody
                        actionSectionBottom={<div className={classes['gap_20']}>
                            <Button
                                loading={loading} type='submit' title={t("Holon_Template.save")} /></div>}
                        showVisible={props?.showVisible} handleBtnBack={() => props?.showVisible(false)}
                        title={payload?.option?.actionName}
                        useForm={false}
                    >
                        <Box>
                            <BoxBody style={{
                                borderRadius: '10px'
                            }}>
                                <BoxRow>
                                    <BoxLabel minWidth="70px">
                                        {t("orders.reason")}
                                    </BoxLabel>
                                    <BoxContent>
                                        <FormInput placeholder={t("orders.reason")} fieldName='returnReason' />
                                    </BoxContent>
                                </BoxRow>
                            </BoxBody>
                        </Box>
                    </WrapContentBody>
                </BoxContentPopup>
            </form>
        </CustomFormProvider>
    )
}

export default ReturnRequestAction