import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import actionWallet from 'redux/wallet/action';
import HolonInstance from 'shared/components/holon-instance/HolonInstance';
import Constants from 'utils/Constants';

function WalletContainer() {
    const { t } = useTrans();
    const dispatch = useDispatch();

    const { holonTemplateDetail, permission } = useSelector(state => state.wallet);

    useEffect(() => {
        dispatch({
            type: actionWallet.GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET
        })
        dispatch({
            type: actionWallet.GET_PERMISSION_WALLET
        })
    }, [])

    return (
        <>
            {
                holonTemplateDetail?.id && <HolonInstance
                    holonTemplateId={holonTemplateDetail?.id}
                    titleCreatePopup={t("wallet.wallet_created")}
                    titleDetailPopup={t("wallet.wallet_detail")}
                    titleUpdatePopup={t("wallet.update_wallet")}
                    title={t("wallet.wallet_management")}
                    // view="all"
                    isUseAddFunction={permission == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false}
                    permissionClone={permission == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false}
                    permissionUpdate={permission == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false}
                />
            }
        </>
    )
}

export default WalletContainer