import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import TermOfUseAction from './action';
import factory from './factory';

function* getTermOfUse() {
    yield takeEvery(
        TermOfUseAction.GET_TERM_OF_USE,
        function* (payload) {
            try {
                const response = yield call(() =>
                    factory.getTermOfUse(payload?.payload),
                );
                if (response) {
                    yield put({
                        type: TermOfUseAction.GET_TERM_OF_USE_SUCCESS,
                        payload: response?.data,
                    });
                }
            } catch (error) {
                yield put({
                    type: TermOfUseAction.GET_TERM_OF_USE_FAILED,
                });
            }
        },
    );
}
export default function* rootSaga() {
    yield all([fork(getTermOfUse)]);
}
