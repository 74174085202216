import React, { useEffect, Fragment, useState } from "react";
import useTrans from "hooks/use-trans";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import Box from "shared/components/form-input/box/Box";
import classes from "./EstimatedFareContainer.module.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import actionFareCalculation from 'redux/freight-calculation/action';
import Field from 'shared/components/form/Field.js';
import RequiredLabel from 'shared/components/common/RequiredLabel.js';
import Constants from 'utils/Constants';
import { useStateMachine } from "little-state-machine";
import { persistAction } from 'utils/Helper';
import Cookies from 'js-cookie';
import { showPopupNotification } from 'utils/Utils';
import Button from 'shared/components/form-input/button/Button';

const EstimateFare = () => {
    const { t } = useTrans()
    const token = Cookies.get("accessToken");
    const {
        fareCalculationUserInput,
        fareValueEstimated,
        loading: {
            loadingFareCalcManagementCalculate
        }
    } = useSelector((state) => state.fareCalculation);
    const dispatch = useDispatch();
    const methods = useForm({
        defaultValues: fareValueEstimated
    });
    const { register, handleSubmit, setValue } = methods;
    const { state } = useStateMachine({ persistAction });
    const [dataUserInput, setDataUserInput] = useState(fareCalculationUserInput?.data)

    useEffect(() => {
        if (token) {
            dispatch({
                type: actionFareCalculation.GET_FARE_CALCULATION_USER_INPUT,
            })
        }
        else {
            dispatch({
                type: actionFareCalculation.GET_FARE_CALC_USER_INPUT,
            })
        }
    }, [token])
    useEffect(() => {
        if(Object.keys(fareValueEstimated).length !== 0){
            Object.keys(fareValueEstimated).map(key =>{
                setValue(key, fareValueEstimated[key])
            })
        } else {
            if(dataUserInput){
                dataUserInput?.map((field, index) => {
                    methods.setValue(field?.key, field?.defaultValue)
                })
            }
        }
    }, [dataUserInput, fareValueEstimated])

    useEffect(() => {
        setDataUserInput(fareCalculationUserInput?.data)
    }, [fareCalculationUserInput])

    const renderUserInput = () => {
        return (
            <>
                {
                    dataUserInput && dataUserInput?.map((field, index) => (
                        <div className={classes['estimated-fare-render']} key={index}>
                            <div className={classes['title-estimated-fare']}>
                                <span className='d-flex'>
                                    {field?.labelKey}
                                </span>
                                <div className={classes["require"]}>
                                    {
                                        field?.isRequired == Constants.IS_REQUIRED ? <RequiredLabel /> : null
                                    }
                                </div>
                            </div>
                            <div className='col h-100'>
                                <Field
                                    field={field}
                                    data={state[field?.key] ? state[field?.key] : undefined}
                                    setValue={setValue}
                                    register={register}
                                />
                            </div>
                        </div>
                    ))
                }
            </>
        )
    }

    const onCreateSuccess = () => {
        // todo
    }

    const onCreateFailure = (detail = {}) => {
        Object.keys(detail).forEach(key => {
            detail[key]?.map(msg => {
                showPopupNotification({
                    typePopup: 'message',
                    newWindow: true,
                    message: msg
                })
            })
        })
    }

    const onSubmit = (data) => {
        dispatch({
            type: actionFareCalculation.GET_FARE_CALC_MANAGEMENT_CALCULATE,
            payload: data,
            onSuccess: onCreateSuccess,
            onFailure: onCreateFailure,
        })
        dispatch({
            type: actionFareCalculation.SAVE_VALUE_ESTIMATED,
            payload: data,
        })
    }

    return (
        <Box className={classes["estimated-fare-container"]}>
            <CustomFormProvider {...methods}>
                {
                    dataUserInput?.length > 0 && <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes["HeaderSection"]}>
                            <p className={classes['title-section']}>
                                {t("fare_calculation.freight_calculation_result")}
                            </p>
                            <div className={classes['icon-note']}>
                            </div>
                        </div>
                        <div className={classes["estimated-fare"]}>
                            {renderUserInput()}
                            <Button loading={loadingFareCalcManagementCalculate} className={classes["btn-submit"]} type="submit">
                                {t('fare_calculation.estimate')}
                            </Button>
                        </div>
                    </form>
                }
            </CustomFormProvider>
        </Box>
    );
};

export default EstimateFare;
