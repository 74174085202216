import React from 'react';
import FormWarehouseIn from 'shared/components/warehouse/warehouse-in/FormWarehouseIn';
import { BrowserView, MobileView } from 'react-device-detect';

function WarehouseInContainer() {
    return (
        <>
            <BrowserView>
                <FormWarehouseIn minWidth="250px" />
            </BrowserView>
            <MobileView>
                <FormWarehouseIn minWidth='100%' propsLayout={{
                    padding: 10, margin: 15, width: "calc(100% - 30px"
                }} />
            </MobileView>
        </>
    )
}

export default WarehouseInContainer