/* eslint-disable react/react-in-jsx-scope */
import { StateMachineProvider } from 'little-state-machine';
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Loader from 'shared/components/common/loader/Loader';
import Notify from 'shared/components/common/notify-alarm/NotifyAlarm';
import PopupSection from 'shared/components/popup/PopupSection';
import ToastifyCommon from 'shared/components/toastify/ToastifyCommon';
import CustomRoute from './CustomRoute';
import { ProtectedRoute } from './ProtectedRoute';
import RouterPath from "./RouterPath";
import Routes from './Routes';
import WrapRouter from './WrapRouter';
import Factories from 'redux/address/factory';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileRoutes from './MobileRoutes';
import CustomViewport from 'shared/components/custom-viewport/CustomViewport';
import DefaultRoute from './DefaultRoute';

const Layout = ({ layout: LayoutComponent, routes }) => {
    const paths = routes.map(x => RouterPath[x.id]);

    useEffect(() => {
        window.getAddressByPostalCode = async function ({ postal_code, country_id }) {
            if (postal_code?.length >= 7 && postal_code?.length <= 9) {
                try {
                    const { data } = await Factories.getAddressByPostalCodeAndCountryId({
                        postal_code: postal_code,
                        country_id: country_id
                    });
                    return data;
                } catch (error) {

                }
            }
            return undefined;
        }
        window.getPortByCountry = async function ({ country_id, port_type }) {
            try {
                const { data } = await Factories.getPortsByPortType({ countryId: country_id, portType: port_type })
                return data;
            } catch (error) {

            }
            return undefined;
        }

        window.getPrefectureByCountry = async function ({ country_id }) {
            try {
                const { data } = await Factories.getPrefecturesByCountryCode(country_id);
                return data;
            } catch (error) {

            }
            return undefined;
        }

        window.getCityByPrefecture = async function ({ prefecture_id }) {
            try {
                const { data } = await Factories.getCitiesByPrefectureId(prefecture_id);
                return data;
            } catch (error) {

            }
            return undefined;
        }
    }, []);

    const layout = <LayoutComponent children={<Switch>
        {
            routes.map((route, index) => {
                route.path = RouterPath[route.id] ?? '';
                return route?.guards?.length > 0 ? <ProtectedRoute
                    key={index}
                    path={route.path}
                    route={route}
                >
                    {route.component}
                </ProtectedRoute > : <CustomRoute key={index} path={route.path} route={route}>
                    {route.component}
                </CustomRoute>
            })
        }
    </Switch>} />;
    return <WrapRouter>
        <Route path={paths} children={layout} exact />
    </WrapRouter>
}
function MainRouter (props) {
    const { loading } = useSelector(state => state.app);

    return (
        <Router>
            {loading && <Loader />}
            <Suspense fallback={<Loader />}>
                <StateMachineProvider>
                    <ToastifyCommon />
                    <CustomViewport />
                    <BrowserView>
                        <Route path={[...Routes].reduce((total, value) => {
                            return total.concat(value.routes);
                        }, []).map(x => RouterPath[x.id])} >
                            {Routes.map((x, i) => <Layout key={i} {...x} />)}
                            <PopupSection />
                            <Notify />
                        </Route>
                        <DefaultRoute routes={Routes}/>
                    </BrowserView>
                    <MobileView>
                        <Route path={[...MobileRoutes].reduce((total, value) => {
                            return total.concat(value.routes);
                        }, []).map(x => RouterPath[x.id])} >
                            {MobileRoutes.map((x, i) => <Layout key={i} {...x} />)}
                            <PopupSection />
                            <Notify />
                        </Route>
                        <DefaultRoute routes={MobileRoutes}/>
                    </MobileView>
                    
                </StateMachineProvider>
            </Suspense>
        </Router>
    )
}
export default MainRouter;