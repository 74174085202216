import React, { useEffect, useState } from 'react'
import styles from './OrdersManagementContainer.module.scss'
import Actions from 'redux/holon/action';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import { useDispatch } from 'react-redux';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import useRouter from 'hooks/use-router';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_SERVICE_SELECTION_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE } from 'utils/EventRegister';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';

const OrdersManagementContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTrans()
    const [companyTemplate, setCompanyTemplate] = useState(null);
    const router = useRouter();
    const [templateId, setTemplateId] = useState(router.get(Constants.QueryParams.CREATE_BY_TEMPLATE_ID.VALUE));

    useEffect(() => {
        const templateId = router.get(Constants.QueryParams.CREATE_BY_TEMPLATE_ID.VALUE);
        dispatch({
            type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.ORDER,
            callback: (data) => {
                setCompanyTemplate(data.toString());
                data?.includes(templateId) && handleAddInstance(templateId)
            }
        })

    }, []);

    useEffect(() => {
        setTemplateId(router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE))
    }, [router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)]);

    const handleOpenServiceSelection = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_SERVICE_SELECTION_POPUP,
            payload: {
                showHeader: false,
                title: t("service_selection.title"),
                headerSize: Constants.SIZE.SMALL,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                listTemplate: companyTemplate
            }
        })
    }

    const handleAddInstance = (templateId) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            payload: {
                title: t("order_management.popup_create_title"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                templateId: templateId,
                callback: () => {
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE,
                        payload: {
                            ...router.getAll(),
                        }
                    });
                }
            }
        })
    }

    const handleClickAddButton = () => {
        return <ButtonAdd size='large' onClick={handleOpenServiceSelection} color='yellow' startIcon={<IconPlusWhite />} />
    }

    return (
        <div className={`${styles['order-management-container']}`}>
            <div className={styles['holon-instance']} >
                {companyTemplate &&
                    <HolonInstance
                        title={t("order_management.title")}
                        template_id={companyTemplate}
                        isUseAddFunction={false}
                        ActionSection={handleClickAddButton()}
                        titleDetailPopup={t("order_management.popup_detail_title")}
                        titleCreatePopup={t("order_management.popup_create_title")}
                        titleUpdatePopup={t("order_management.popup_update_title")}
                        listTemplateCreation={companyTemplate?.split(",")?.map(x => ({id: x}))}
                    />}
            </div>
        </div>
    )
}

export default OrdersManagementContainer