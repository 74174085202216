import useTrans from 'hooks/use-trans'
import React from 'react'
import MenuManagementContainer from 'shared/container/menu-management/MenuManagementContainer'

function MenuManagement() {
    const {t} = useTrans();
    document.title = t("menu_management.menu_management");
    
    return (
        <MenuManagementContainer />
    )
}

export default MenuManagement