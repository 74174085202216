import React from 'react';
import Constants from 'utils/Constants';
import StepperComponent from '../../common/Stepper';
import useTrans from 'hooks/use-trans'
import styles from "./UserInquiryPopup.module.scss";

const UserInquiryCompletionPopup = () => {
    const { trans } = useTrans();

    return <div className={styles['inquiry-container']}>
        <div className={styles['stepper-container']}>
            <div className={styles['stepper-content']}>
                <StepperComponent
                    activeStep={2}
                    steps={Constants.INQUIRY_STEP}
                />
            </div>
        </div>
        <div className={styles['open-time']}>
            <div className={styles['open-time-content']}>
                <p>{trans("Inquiry.completionFirstLabel")}</p>
                <p>{trans("Inquiry.completionSecondLabel")}</p>
                <p>{trans("Inquiry.completionThirdLabel")}</p>
            </div>
        </div>
    </div>
}

export default UserInquiryCompletionPopup