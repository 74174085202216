import React from 'react'
import classes from './Stocks.module.scss';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import FormInput from 'shared/components/custom-form/FormInput';
import Validator from 'utils/Validator';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Constants from 'utils/Constants';
import actionStocks from 'redux/stocks/action';

function CreateStockPopup(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();

    const dispatch = useDispatch();
    const { loadingCreate } = useSelector(state => state.stocks);
    const methods = useForm({
        defaultValues: {
            item_name: "",
            price: 0,
            manual_update_flag: "",
            gs1_code: ""
        }
    });

    const onSubmit = (data) => {
        const params = {
            id: 0,
            item_name: data?.item_name,
            price: parseInt(data?.price, 10),
            manual_update_flag: data?.manual_update_flag ? "1" : "0",
            gs1_code: data?.gs1_code,
        }
        dispatch({
            type: actionStocks.CREATE_STOCKS,
            payload: {
                ...params
            },
            callback: {
                success: () => {
                    payload?.callback();
                    showVisible()
                },
                failed: () => {

                }
            }
        })
    }

    return (
        <BoxContentPopup>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody title={payload?.title} showVisible={showVisible} payload={payload}
                        actionSectionBottom={<Button loading={loadingCreate} type='submit' title={t("product.submit")} />}
                    >
                        <div className={classes['created-holon-instance']}>
                            <Box>
                                <BoxBody style={{
                                    borderRadius: 10
                                }} className={classes['data-attributes-body']}>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("product.gs1_code")}</BoxLabel>
                                        <BoxContent>
                                            <FormInput disabled placeholder={t("product.gs1_code")} validate={[Validator.required]} fieldName='gs1_code' />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("product.product_name")}</BoxLabel>
                                        <BoxContent>
                                            <FormInput placeholder={t("product.product_name")} validate={[Validator.required]} fieldName='item_name' />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("product.product_price")}</BoxLabel>
                                        <BoxContent>
                                            <FormInput format={Constants.FormInputFormat.NUMBER.VALUE} placeholder={t("product.product_price")} validate={[Validator.required]} fieldName='price' />
                                        </BoxContent>
                                    </BoxRow>
                                </BoxBody>
                            </Box>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default CreateStockPopup