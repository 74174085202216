import PrivatePolicyAction from './action';

const initialState = {
    data: {},
    loading: false
};
const myReducer = (state = initialState, action) => {
    switch (action?.type) {
        case PrivatePolicyAction.GET_PRIVATE_POLICY_SUCCESS:
            return {
                ...state,
                data: action?.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default myReducer;
