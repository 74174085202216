import useTrans from 'hooks/use-trans'
import React from 'react'
import HolonInstanceContainer from 'shared/container/holon-instance/HolonInstanceContainer'

function HolonInstancePage() {
    const { t } = useTrans();
    document.title = t("holon.holon_instance");
    return (
        <HolonInstanceContainer />
    )
}

export default HolonInstancePage