import useTrans from 'hooks/use-trans';
import React from 'react'
import Box from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxContent from '../../box/BoxContent';
import Validator from 'utils/Validator';
import FormFileAttachment from 'shared/components/custom-form/FormFileAttachment';

function FormAttachment({ attachment_key }) {
    const { t } = useTrans();
    return (
        <Box>
            <BoxHeader id={attachment_key}>
                <BoxTitle>{t("holon.attachment")}</BoxTitle>
            </BoxHeader>
            <BoxBody id={attachment_key}>
                <BoxRow>
                    <BoxContent style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        alignItems: 'center'
                    }}>
                        <FormFileAttachment
                            validate={[Validator.required]}
                            validationFE
                            isRegexLabel={t("holon.attachment_name")}
                            placeholder={t("holon.attachment_content")}
                            fieldName={`attachments`}
                        />
                    </BoxContent>
                </BoxRow>
            </BoxBody>
        </Box>
    )
}

export default FormAttachment