import React, { useEffect, useState } from 'react'
import classes from './AlarmDetailPopup.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import actionAlarm from 'redux/alarm-holon-setting/action';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import IconEdit from 'assets/icon/IconEdit';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_POPUP_UPDATE_ALARM_HOLON_TEMPLATE } from 'utils/EventRegister';
import useRouter from 'hooks/use-router';
import WrapContentBody from '../custom-body/WrapContentBody';
import Box from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import BoxLabel from '../../box/BoxLabel';
import BoxRow from '../../box/BoxRow';
import BoxContent from '../../box/BoxContent';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import actionHolonTemplate from 'redux/holon-template/action';
import HolonTemplateName from 'shared/components/holon-instance/holon-template-name/HolonTemplateName';
import { showPopupNotification } from 'utils/Utils';

function AlarmDetailPopup(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const router = useRouter();
    const { t } = useTrans();
    const [listAttributes, setListAttributes] = useState([])
    const [listType, setListType] = useState([])
    const [holonTemplateDetail, setHolonTemplateDetail] = useState(null)
    const [alarmHolonTemplateDetail, setAlarmHolonTemplateDetail] = useState(null);
    const [listConnections, setListConnection] = useState([]);
    const dispatch = useDispatch();
    const {
        master, loading: {
            loadingAlarmDetail,
            loadingDeleteAlarm,
        }
    } = useSelector(state => state.alarmHolonTemplate);

    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            if (name == "holonTemplateId" && value['holonTemplateId']?.length > 0) {
                dispatch({
                    type: actionAlarm.GET_HOLON_TEMPLATE_DETAIL,
                    payload: value['holonTemplateId'][0]
                })
            }
        }
        )
        return () => subscription.unsubscribe()
    }, [methods.watch])


    useEffect(() => {
        if (alarmHolonTemplateDetail?.id) {
            dispatch({
                type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP,
                payload: alarmHolonTemplateDetail?.holonTemplateId,
                callback: {
                    success: (data) => {
                        setHolonTemplateDetail(data)
                        setListAttributes(data?.attributes)
                    }
                }
            })
        }
    }, [alarmHolonTemplateDetail?.id])

    useEffect(() => {
        if (payload?.alarmId) {
            dispatch({
                type: actionAlarm.GET_ALARM_HOLON_SETTING_DETAIL_POPUP,
                payload: payload?.alarmId,
                callback: (data) => {
                    setAlarmHolonTemplateDetail(data);
                }
            })
        }
    }, [payload?.alarmId])

    useEffect(() => {
        return () => {
            setAlarmHolonTemplateDetail(null);
            setHolonTemplateDetail(null);
        }
    }, [])

    useEffect(() => {
        if (master?.alarmTypes) {
            setListType(Object.keys(master?.alarmTypes).map(key => {
                return {
                    value: key,
                    key: key,
                    label: master?.alarmTypes[key]
                }
            }))
        }
    }, [master])

    const onSubmit = (data) => {
        let params = {
            type: data?.type,
            holonTemplateId: holonTemplateDetail?.id,
            holonTemplateName: holonTemplateDetail?.name,
            holonKeyLabel: holonTemplateDetail?.attributes?.find(x => x?.id == data?.holonKey)?.label,
            holonKeyValue: holonTemplateDetail?.attributes?.find(x => x?.id == data?.holonKey)?.key,
            conditionsText: data?.conditionsText,
            conditionsLogic: data?.conditionsLogic,
            messageText: data?.messageText,
            alarmLevel: parseInt(data?.alarmLevel, 10),
            alarmOnScreen: data?.alarmOnScreen,
            alarmOnMail: data?.alarmOnMail,
            subjectText: 'subjectKey',
            status: data?.status
        }

        dispatch({
            type: actionAlarm.CREATE_ALARM_HOLON_SETTING,
            payload: params,
            callback: {
                success: () => {
                    showVisible();
                    payload?.callback()
                },
                failed: (msg) => {
                    EventRegister.emit(EVENT_SHOW_POPUP, {
                        open: true,
                        type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                        newWindow: true,
                        payload: {
                            title: msg,
                            showHeader: false,
                            customStyle: {
                                padding: 0,
                                borderTop: 0
                            },
                        }
                    })
                }
            }
        })
    }

    let statusTemplate = [
        {
            value: Constants.STATUS_ALARM.UNACTIVE.VALUE,
            key: Constants.STATUS_ALARM.UNACTIVE.VALUE,
            label: t("Holon_Template.invalid"),
        },
        {
            value: Constants.STATUS_ALARM.ACTIVE.VALUE,
            key: Constants.STATUS_ALARM.ACTIVE.VALUE,
            label: t("Holon_Template.valid"),
        }
    ]

    const handleEdit = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_UPDATE_ALARM_HOLON_TEMPLATE,
            payload: {
                title: t("Holon_Template.alarm_setting_update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                alarmId: payload?.alarmId,
                callback: () => {
                    dispatch({
                        type: actionAlarm.GET_ALARM_HOLON_SETTING,
                        payload: {
                            ...router.getAll()
                        }
                    })
                }
            }
        })
    }

    const handleDelete = () => {
        showPopupNotification({
            typePopup: 'delete',
            newWindow: true,
            callback: () => {
                dispatch({
                    type: actionAlarm.DELETE_ALARM_HOLON_SETTING,
                    payload: alarmHolonTemplateDetail?.id,
                    callback: {
                        success: () => {
                            showVisible();
                            dispatch({
                                type: actionAlarm.GET_ALARM_HOLON_SETTING,
                                payload: {
                                    ...router.getAll()
                                }
                            })
                            payload?.callback();
                            showPopupNotification({
                                typePopup: 'message',
                                newWindow: true,
                                message: t("E0045")
                            })
                        },
                        failed: (msg) => {
                            showPopupNotification({
                                typePopup: 'message',
                                newWindow: true,
                                message: msg
                            })
                        }
                    }
                })
            }
        })

    }

    useEffect(() => {
        if (holonTemplateDetail?.id) {
            dispatch({
                type: actionAlarm.GET_OWNER_COLLECTION_HOLON_TEMPLATE,
                payload: holonTemplateDetail?.id,
                callback: (_data) => {
                    setListConnection(_data?.map(x => {
                        return {
                            label: x?.note,
                            key: x?.key,
                            value: x?.key,
                            key_option: x?.key,
                        }
                    }))
                }
            })
        }
    }, [holonTemplateDetail])

    return (
        <BoxContentPopup width='1000px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        title={payload?.title}
                        showVisible={showVisible}
                        handleBtnBack={() => showVisible()}
                        actionSectionBottom={<Button onClick={handleDelete} color='gray' loading={loadingAlarmDetail || loadingDeleteAlarm} type='button' title={t("Holon_Template.delete")} />}
                        actionSectionTop={<>
                            <Button onClick={handleEdit} color='gray' startIcon={<IconEdit color="currentColor" />} />
                        </>}
                    >
                        <div className={classes['content-alarm']}>
                            <Box>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.setting")}</BoxTitle>
                                </BoxHeader>
                                <BoxBody>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.Warning/notification")}</ BoxLabel>
                                        <BoxContent>
                                            <p>{master?.alarmTypes[alarmHolonTemplateDetail?.type]}</p>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.holon_template")}</ BoxLabel>
                                        <BoxContent>
                                            <p className={classes['border']}>{alarmHolonTemplateDetail?.holonTemplateName}</p>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.key")}</ BoxLabel>
                                        <BoxContent>
                                            <p>{listAttributes?.find(x => x?.key == alarmHolonTemplateDetail?.holonKeyValue)?.label}</p>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.condition_explanation")}</ BoxLabel>
                                        <BoxContent>
                                            <p>{alarmHolonTemplateDetail?.conditionsText}</p>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.conditional_expression")}</ BoxLabel>
                                        <BoxContent>
                                            <p>{alarmHolonTemplateDetail?.conditionsLogic}</p>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.target_key")}</ BoxLabel>
                                        <BoxContent>
                                            <p>{listConnections?.find(x => x?.key == alarmHolonTemplateDetail?.subjectKey)?.label ?? alarmHolonTemplateDetail?.subjectKey}</p>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.msg")}</ BoxLabel>
                                        <BoxContent>
                                            <p>{alarmHolonTemplateDetail?.message}</p>
                                        </BoxContent>
                                    </BoxRow>

                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.alarm_lv")}</ BoxLabel>
                                        <BoxContent>
                                            <p>{master?.alarmLevel[alarmHolonTemplateDetail?.alarmLevel]}</p>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.user_screen_notification")}</ BoxLabel>
                                        <BoxContent>
                                            <div className={classes['box-template']}>
                                                {alarmHolonTemplateDetail?.alarmOnScreen?.map((x, index) => {
                                                    return <div key={index} className={classes['border']}>
                                                        <HolonTemplateName id={x} />
                                                    </div>
                                                })}
                                            </div>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.email_notification")}</BoxLabel>
                                        <BoxContent>
                                            <div className={classes['box-template']}>
                                                {alarmHolonTemplateDetail?.alarmOnMail?.map((x, index) => {
                                                    return <div key={index} className={classes['border']}>
                                                        <HolonTemplateName id={x} />
                                                    </div>
                                                })}
                                            </div>
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="140px">{t("Holon_Template.status")}</ BoxLabel>
                                        <BoxContent>
                                            <p>{statusTemplate?.find(x => x?.key == alarmHolonTemplateDetail?.status)?.label}</p>
                                        </BoxContent>
                                    </BoxRow>
                                </BoxBody>
                            </Box>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default AlarmDetailPopup