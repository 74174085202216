import React, { useEffect } from 'react'
import UpdateHexServiceContainer from 'shared/container/hex-service/UpdateHexServiceContainer'
import { useParams } from 'react-router';
import useTrans from 'hooks/use-trans';

function UpdateHexServicePage() {
    const { lang, key } = useParams();
    const {t} = useTrans();
    useEffect(() => {
        document.title = t('faq_management.hex_service');
    }, []);
    return (
        <UpdateHexServiceContainer lang={lang} id={key} />
    )
}

export default UpdateHexServicePage