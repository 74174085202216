import actionHome from "./action";

const initialState = {
    services: [],
    dataOrder: {},
    policy: {},
    termOfUse: {},
}

const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionHome.GET_LIST_HEX_SERVICE_SUCCESS:
            return {
                ...state,
                services: action.payload?.services,
                dataOrder: action?.payload?.dataOrder
            }
        case actionHome.GET_PRIVATE_POLICY_HOME_SUCCESS:
            return {
                ...state,
                policy: action.payload
            }
        case actionHome.GET_TERM_OF_USE_HOME_SUCCESS:
            return {
                ...state,
                termOfUse: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;