import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Constants from 'utils/Constants';
import { convertNumberToString } from 'utils/String';
import Validator from 'utils/Validator';
import Input from '../form-input/input/Input';
import PropTypes from 'prop-types';

function FormInput(props) {
    const {
        defaultValue,
        fieldName,
        validate = [],
        placeholder,
        format = '',
        disabled = false,
        isTooltip = false,
        iconSearch,
        width,
        height,
        background,
        className,
        type,
        showIconPassword,
        onFocus = () => { },
        onCustomBlur = () => { },
        callback = () => { },
        isSearch = false,
        max,
        min,
        validationFE = false,
        textTrans,
        isRegexLabel
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className="Input w-100">
            <div className="w-100">
                <Controller
                    control={control}
                    name={fieldName}
                    rules={{
                        validate: Validator.genValidate(validate, fieldName),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        const onChangeValue = (e) => {
                            if (
                                format == Constants.FormInputFormat.NUMBER.VALUE
                            ) {
                                const val = e.target.value.replace(
                                    /[^0-9.]+/g,
                                    '',
                                );

                                if (val == '') {
                                    onChange(val);
                                    props?.onChangeValue && props?.onChangeValue(e.target.value)
                                    return;
                                }
                                if (parseInt(val) < parseInt(min) && min != null) {
                                    onChange(min);
                                    props?.onChangeValue && props?.onChangeValue(min)
                                } else if (parseInt(val) > parseInt(max) && max != null) {
                                    onChange(max);
                                    props?.onChangeValue && props?.onChangeValue(max)
                                } else {
                                    onChange(val);
                                    props?.onChangeValue && props?.onChangeValue(e.target.value)
                                }
                            } else if (
                                format == Constants.FormInputFormat.PERCENT.VALUE
                            ) {
                                const val = e.target.value.replace(
                                    /[^0-9]+/g,
                                    '',
                                );
                                onChange(val);
                                props?.onChangeValue && props?.onChangeValue(e.target.value)
                            } else {
                                onChange(e.target.value);
                                props?.onChangeValue && props?.onChangeValue(e.target.value)
                            }
                        };
                        const onKeyPress = (e) => {
                            if (
                                format == Constants.FormInputFormat.NUMBER.VALUE
                            ) {
                                if (!/^[0-9.]*$/i.test(e.key)) {
                                    e.preventDefault();
                                    return false;
                                }
                            }
                        };
                        const convertValue = (val) => {
                            if (
                                format == Constants.FormInputFormat.NUMBER.VALUE
                            ) {
                                return val ? convertNumberToString(val.toString())
                                    : '';
                            }
                            if (
                                format == Constants.FormInputFormat.PERCENT.VALUE
                            ) {
                                return val ? `${val}%`
                                    : '';
                            }
                            return val;
                        };
                        return (
                            <Input
                                validationFE={validationFE}
                                refInput={ref}
                                name={fieldName}
                                onChange={onChangeValue}
                                onBlur={(e) => {
                                    onBlur(e);
                                    onCustomBlur();
                                }}
                                value={convertValue(value) || ""}
                                defaultValue={defaultValue}
                                placeholder={placeholder}
                                errors={errors}
                                required={false}
                                onKeyPress={onKeyPress}
                                disabled={disabled}
                                isTooltip={isTooltip}
                                onFocus={(e) => {
                                    // e.target.select();
                                    onFocus()
                                }}
                                showIconPassword={showIconPassword}
                                style={{
                                    textAlign:
                                        format ==
                                            Constants.FormInputFormat.NUMBER.VALUE ||
                                            format ==
                                            Constants.FormInputFormat.PERCENT.VALUE
                                            ? 'right'
                                            : 'left',
                                }}
                                maxLength={props.maxLength}
                                iconSearch={iconSearch}
                                width={width}
                                height={height}
                                background={background}
                                className={className}
                                type={type}
                                isSearch={isSearch}
                                callback={callback}
                                max={max}
                                textTrans={textTrans}
                                isRegexLabel={isRegexLabel}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
}
FormInput.defaultProps = {
    placeholder: '',
    fieldName: 'fieldName',
};
FormInput.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    iconSearch: PropTypes.element,
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    textTrans: PropTypes.string,
    isSearch: PropTypes.bool,
    validationFE: PropTypes.bool,
    isRegexLabel: PropTypes.string,
    maxLength: PropTypes.any
};
export default FormInput;
