import React, { useCallback, useEffect, useState } from 'react'
import classes from './UserListPopup.module.scss';
import Button from 'shared/components/form-input/button/Button';
import IconPlus from 'assets/icon/IconPlus';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import CustomPagination from '../../custom-pagination/CustomPagination';
import FormSearch from '../../form-search/FormSearch';
import FormItem from '../../form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import FormGroup from '../../form-search/FormGroup';
import { debounce } from 'lodash';
import Loading from 'shared/components/form-input/button/loading/Loading';
import { useDispatch } from 'react-redux';
import WrapContentBody from '../custom-body/WrapContentBody';
import { actionMessage } from 'redux/message/action';
import IconClose from 'assets/icon/IconClose';
import CommonText from '../../text/CommonText';

const UserListCommon = (props) => {
    const isMulti = props?.isMulti == false ? props?.isMulti : true;
    const { dataHeader,
        data,
        onChangeSaveListSelected,
        listDataActive,
        dataActive,
        loading,
        handleChangePageListData, onSearchListData,
        sectionTab
    } = props;
    const [currentListAll, setCurrentListAll] = useState([]);
    const [currentList, setCurrentList] = useState([]);
    const [paginationListSelected, setPaginationListSelected] = useState(1);
    const { t } = useTrans();
    const [listSelected, setListSelected] = useState([])
    const [listSelectedAll, setListSelectedAll] = useState([]);
    const [keySearchSelect, setKeySearchSelect] = useState(null);

    useEffect(() => {
        if (data?.records?.length > 0) {
            if (listDataActive?.length > 0) {
                let newList = [...data?.records];
                setCurrentListAll(data?.records)
                let temp = [];
                [...newList]?.map(x => {
                    if (listDataActive?.findIndex(v => v?.id == x?.id) == -1) {
                        temp.push(x);
                    }
                })
                setCurrentList(temp);
            } else {
                setCurrentList(data?.records);
                setCurrentListAll(data?.records);
            }
        } else {
            setCurrentList([]);
            setCurrentListAll([]);
        }
    }, [data?.records, listDataActive]);

    useEffect(() => {
        if (listDataActive?.length > 0) {
            setListSelectedAll(listDataActive);
            setListSelected(sliceArray(convertListSelect(listDataActive, keySearchSelect), paginationListSelected));
        } else {
            setListSelected([]);
            setListSelectedAll([])
        }
    }, [listDataActive, keySearchSelect, paginationListSelected])

    let convertListSelect = (_listSelectTemplateAll, _keySearchSelect) => {
        if (_keySearchSelect) {
            let list = [..._listSelectTemplateAll]?.filter(item => item?.name?.toLowerCase().indexOf(_keySearchSelect?.toLowerCase()) > -1 || item?.category?.toLowerCase().indexOf(_keySearchSelect?.toLowerCase()) > -1);
            return list;
        }
        return _listSelectTemplateAll;
    }

    let sliceArray = (data = [], from) => {
        return [...data]?.slice((from - 1) * 10, from * 10);
    }

    const handleAdd = (item, keySearchSelect) => {
        let newList = [...currentList];
        let indexRemove = newList?.findIndex(x => x?.id == item?.id);
        if (indexRemove != -1) {
            let newListChoiceTemplateAll = [...listSelectedAll]
            newListChoiceTemplateAll.push(item)
            setListSelectedAll(newListChoiceTemplateAll);
            onChangeSaveListSelected({
                id: dataActive,
                data: newListChoiceTemplateAll
            });
            setListSelected(sliceArray(convertListSelect(newListChoiceTemplateAll, keySearchSelect), paginationListSelected));
            let temp = [];
            [...newList]?.map(x => {
                if (newListChoiceTemplateAll?.findIndex(v => v?.id == x?.id) == -1) {
                    temp.push(x);
                }
            })
            setCurrentList(temp)
        }
    }

    const handleRemove = (item, keySearchSelect) => {
        let newListChoiceTemplateAll = [...listSelectedAll];
        let indexRemove = newListChoiceTemplateAll?.findIndex(x => x?.id == item?.id);
        if (indexRemove != -1) {

            let newListTemplate = [...currentList];
            let indexTemplateAll = currentListAll?.findIndex(x => x?.id == item?.id);
            if (indexTemplateAll != -1) {
                newListTemplate.push(item);
                setCurrentList(newListTemplate);
            }

            let page = paginationListSelected;

            newListChoiceTemplateAll.splice(indexRemove, 1);
            setListSelectedAll(newListChoiceTemplateAll);
            if (paginationListSelected > Math.ceil(newListChoiceTemplateAll?.length / 10)) {
                page = page - 1;
                setPaginationListSelected(page == 0 ? 1 : page)
            }

            onChangeSaveListSelected({
                id: dataActive,
                data: newListChoiceTemplateAll
            });

            let _pageListSelect = paginationListSelected;
            if (newListChoiceTemplateAll?.length == 0) {
                setPaginationListSelected(1)
            } else
                if (_pageListSelect > Math.ceil(newListChoiceTemplateAll?.length / 10)) {
                    _pageListSelect = _pageListSelect - 1;
                    setPaginationListSelected(_pageListSelect)
                }


            setListSelected(sliceArray(convertListSelect(newListChoiceTemplateAll, keySearchSelect), _pageListSelect));
        }
    }

    const handleChangePaginationListChoice = (e, keySearchSelect) => {
        currentList.current = true;
        setListSelected(sliceArray(convertListSelect(listSelectedAll, keySearchSelect), e));
        setPaginationListSelected(e);
    }

    const handleChangePaginationList = (e, x) => {
        handleChangePageListData(e, x);
    }

    const searchHolonTemplate = useCallback(
        debounce(async (e) => {
            onSearchListData(e, dataActive);
        }, 500),
        [dataActive],
    );

    const onChangeList = (e) => {
        currentList.current = true;
        searchHolonTemplate(e)
    }

    const searchSelectedHolonTemplate = useCallback(
        debounce(async (e) => {
            if (e) {
                let list = [];
                list = [...listDataActive]?.filter(item => item?.name?.toLowerCase().indexOf(e?.toLowerCase()) > -1 || item?.category?.toLowerCase().indexOf(e?.toLowerCase()) > -1);
                setListSelected(list?.slice(0, 10));
                setPaginationListSelected(1);
            } else {
                let list = [...listDataActive];
                setListSelected(list?.slice(0, 10));
                setPaginationListSelected(1)
                setKeySearchSelect(null)
            }
        }, 500),
        [listDataActive],
    );

    const onChangeListChoice = (e) => {
        currentList.current = true;
        searchSelectedHolonTemplate(e);
        setKeySearchSelect(e)
    }

    return <>
        <div className={classes['wrap-content']}>
            {sectionTab}
            <div className={classes['selected-container']}>
                <div className={classes['list']}>
                    <div className={classes['content-list']}>
                        <FormSearch className={classes['custom-form-search']}>
                            <FormGroup>
                                <FormItem >
                                    <FormInput
                                        fieldName='Search'
                                        background='white'
                                        className="custom-input"
                                        placeholder={t("search_by_word")}
                                        iconSearch={<IconSearch fontSize={14} />}
                                        onChangeValue={(e) => {
                                            onChangeList(e)
                                        }}
                                        isSearch
                                    />
                                </FormItem>
                            </FormGroup>
                        </FormSearch>

                        <div style={{
                            borderRadius: '10px 10px 0px 0px',
                            position: 'relative',
                        }}>
                            {
                                dataHeader?.length > 0 && loading ? <div className={classes['custom-loading']}>
                                    <Loading />
                                </div> : <></>
                            }
                            {
                                dataHeader?.length > 0 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className={classes['content-stt']}>
                                                    <div className={classes['title-header']}>
                                                        No
                                                    </div>
                                                </th>
                                                {
                                                    dataHeader?.slice(0, 3)?.map((x, index) => {
                                                        return <th key={index} className={classes['content_with']}>
                                                            <div className={classes['title-header']}>
                                                                <CommonText>{x?.label}</CommonText>
                                                            </div>
                                                        </th>
                                                    })
                                                }
                                                <th className={classes['content-with-action']}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !loading && currentList?.map((menu, index) => {
                                                    return <tr key={index}>
                                                        <td className={classes['content_with']} >
                                                            {index + 1}
                                                        </td>
                                                        {
                                                            dataHeader?.slice(0, 3)?.map((_item, _index) => {
                                                                return <td key={_index} className={classes['content_with']} >
                                                                    <div className={classes['custom-content-table']}>
                                                                        <CommonText>{menu[_item?.field]}</CommonText>
                                                                    </div>
                                                                </td>
                                                            })}
                                                        <td className={classes['content-with-action']}>
                                                            <Button isShadow={false} size='small' backgroundTransparent disabled={isMulti ? false : (listSelected?.length == 1 ? true : false)} onClick={() => handleAdd(menu, keySearchSelect)} color='white' startIcon={<IconPlus />} />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    : <></>
                            }
                        </div>

                    </div>
                    <div className={classes['custom-pagination-popup']}>
                        <CustomPagination page={data?.page ?? 1} limit={Constants.PAGINATION.LIMIT} total={data?.total ?? 0} onChange={(e) => handleChangePaginationList(e, dataActive)} />
                    </div>
                </div>
                <div className={`${classes['list']} ${classes['list-selected']}`}>
                    <div className={classes['content-list']}>
                        <FormSearch className="custom-form-search">
                            <FormGroup>
                                <FormItem >
                                    <FormInput
                                        fieldName='Search_choice'
                                        background='white'
                                        className="custom-input"
                                        placeholder={t("search_by_word")}
                                        iconSearch={<IconSearch fontSize={14} />}
                                        onChangeValue={(e) => {
                                            onChangeListChoice(e)
                                        }}
                                        isSearch
                                    />
                                </FormItem>
                            </FormGroup>
                        </FormSearch>
                        <div style={{
                            borderRadius: '10px 10px 0px 0px',
                            position: 'relative',
                        }}>
                            {
                                dataHeader?.length == 0 && loading ? <div className={classes['custom-loading']}>
                                    <Loading />
                                </div> : <></>
                            }
                            {
                                dataHeader?.length > 0 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className={classes['content-stt']}>
                                                    <div className={classes['title-header']}>
                                                        No
                                                    </div>
                                                </th>
                                                {
                                                    dataHeader?.slice(0, 3)?.map((x, index) => {
                                                        return <th key={index} className={classes['content-with']}>
                                                            <div className={classes['title-header']}>
                                                                <CommonText>{x?.label}</CommonText>
                                                            </div>
                                                        </th>
                                                    })
                                                }
                                                <th className={classes['content-with-action']}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listSelected?.map((menu, key) => {
                                                    return <tr key={key}>
                                                        <td>
                                                            {key + 1}
                                                        </td>
                                                        {
                                                            dataHeader?.slice(0, 3)?.map((_item, _index) => {
                                                                return <td key={_index}>
                                                                    <div className={classes['custom-content-table']}>
                                                                        <CommonText>{menu[_item?.field]}</CommonText>
                                                                    </div>
                                                                </td>
                                                            })}
                                                        <td>
                                                            <Button isShadow={false} size='small' backgroundTransparent onClick={() => handleRemove(menu, keySearchSelect)} color='white' startIcon={<IconMinusAccordion />} />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    : <></>
                            }
                        </div>
                    </div>
                    <div className={classes['custom-pagination-popup']}>
                        <CustomPagination page={paginationListSelected} limit={10} total={listSelectedAll?.length ?? 0} onChange={(e) => handleChangePaginationListChoice(e, keySearchSelect)} />
                    </div>
                </div>
            </div>
        </div>
    </>
}

function UserListPopup(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const [listSelectedAll, setListSelectedAll] = useState([]);
    const [data, setData] = useState({});
    const [tab, setTab] = useState([]);
    const [active, setActive] = useState(1);
    const [loadingGetList, setLoadingGetList] = useState(false);
    const [keyword, setKeyword] = useState(null);
    const { t } = useTrans();
    const dispatch = useDispatch();

    const handleOke = () => {
        let data = [];
        listSelectedAll?.map(x => {
            x?.data?.map(_l => {
                data.push({
                    id: _l?.id,
                    name: _l?.name
                })
            })
        })
        payload?.callback(data);
        showVisible()
    }

    const handleSaveHolonInstance = (_items) => {
        let data = [...listSelectedAll];
        let index = data?.findIndex(x => x?.id == _items?.id);
        if (index != -1) {
            data[index].data = _items?.data;
        } else {
            data.push(_items);
        }

        setListSelectedAll(data);
    }

    const handleChangePageListData = (e, x) => {
        let params = {};
        if (keyword) {
            params[Constants.QueryParams.KEYWORD.VALUE] = keyword;
        }
        setLoadingGetList(true);

        if (x == 1) {
            dispatch({
                type: actionMessage.GET_LIST_USER_INSTANCE,
                payload: {
                    [Constants.QueryParams.OFFSET.VALUE]: e,
                    [Constants.QueryParams.CHANNEL.VALUE]: payload?.channelId,
                    ...params
                },
                callback: {
                    success: (data) => {
                        setData(data);
                        setLoadingGetList(false)
                    },
                    failed: () => {
                        setLoadingGetList(false)
                    }
                }
            })
        }

        if (x == 2) {
            dispatch({
                type: actionMessage.GET_LIST_USER_COMPANY,
                payload: {
                    [Constants.QueryParams.OFFSET.VALUE]: e,
                    [Constants.QueryParams.CHANNEL.VALUE]: payload?.channelId,
                    [Constants.QueryParams.USER_IN_COMPANY.VALUE]: payload?.userInCompany,
                    ...params
                },
                callback: {
                    success: (data) => {
                        setData(data);
                        setLoadingGetList(false)
                    },
                    failed: () => {
                        setLoadingGetList(false)
                    }
                }
            })
        }
    }
    const onSearchListData = (e, x) => {
        setKeyword(e);
        setLoadingGetList(true);

        if (x == 1) {
            dispatch({
                type: actionMessage.GET_LIST_USER_INSTANCE,
                payload: {
                    [Constants.QueryParams.OFFSET.VALUE]: 1,
                    [Constants.QueryParams.KEYWORD.VALUE]: e,
                    [Constants.QueryParams.CHANNEL.VALUE]: payload?.channelId,
                },
                callback: {
                    success: (data) => {
                        setData(data);
                        setLoadingGetList(false)
                    },
                    failed: () => {
                        setLoadingGetList(false)
                    }
                }
            })
        }

        if (x == 2) {
            dispatch({
                type: actionMessage.GET_LIST_USER_COMPANY,
                payload: {
                    [Constants.QueryParams.OFFSET.VALUE]: 1,
                    [Constants.QueryParams.KEYWORD.VALUE]: e,
                    [Constants.QueryParams.CHANNEL.VALUE]: payload?.channelId,
                    [Constants.QueryParams.USER_IN_COMPANY.VALUE]: payload?.userInCompany,
                },
                callback: {
                    success: (data) => {
                        setData(data);
                        setLoadingGetList(false)
                    },
                    failed: () => {
                        setLoadingGetList(false)
                    }
                }
            })
        }
    }


    useEffect(() => {
        return () => {
            setData([])
        }
    }, [])

    let dataHeader = [
        {
            id: 2,
            field: 'name',
            label: t("message.name"),
            component: (_props) => {
                return <>{_props?.children?.name}</>
            }
        }
    ]

    useEffect(() => {
        setLoadingGetList(true)
        dispatch({
            type: actionMessage.GET_LIST_USER_INSTANCE,
            payload: {
                [Constants.QueryParams.LIMIT.VALUE]: Constants.PAGINATION.LIMIT,
                [Constants.QueryParams.CHANNEL.VALUE]: payload?.channelId,
            },
            callback: {
                success: (data) => {
                    setData(data);
                    setLoadingGetList(false)
                },
                failed: () => {
                    setLoadingGetList(false)
                }
            },
        })
    }, [])

    const onSubmit = () => {

    }

    const handleChangeTab = (x) => {
        methods.setValue("Search", "");
        methods.setValue("Search_Choice", "");
        setKeyword(null);
        setActive(x);
        setLoadingGetList(true)
        if (x == 1) {
            dispatch({
                type: actionMessage.GET_LIST_USER_INSTANCE,
                payload: {
                    // [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: x
                    [Constants.QueryParams.CHANNEL.VALUE]: payload?.channelId,
                },
                callback: {
                    success: (data) => {
                        setData(data);
                        setLoadingGetList(false)
                    },
                    failed: () => {
                        setLoadingGetList(false)
                    }
                }
            })
        }

        if (x == 2) {
            dispatch({
                type: actionMessage.GET_LIST_USER_COMPANY,
                payload: {
                    // [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: x
                    [Constants.QueryParams.CHANNEL.VALUE]: payload?.channelId,
                    [Constants.QueryParams.USER_IN_COMPANY.VALUE]: payload?.userInCompany,
                },
                callback: {
                    success: (data) => {
                        setData(data);
                        setLoadingGetList(false)
                    },
                    failed: () => {
                        setLoadingGetList(false)
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (payload?.isDataCompany) {
            setTab([
                {
                    id: 1,
                    name: t("message.hex_user"),
                    data: []
                },
                {
                    id: 2,
                    name: t("message.hex_company"),
                    data: []
                }
            ])
        } else {
            setTab([
                {
                    id: 1,
                    name: t("message.hex_user"),
                    data: []
                },
            ])
        }
    }, [payload?.isDataCompany])

    return (
        <div className={classes['UserListPopup']}>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        actionSectionBottom={<Button onClick={handleOke} title={t("save")} />}
                        showVisible={showVisible} handleBtnBack={() => showVisible(false)}
                        title={t("message.user_selection")}
                        isUseBtnBack={false}
                        useForm={false}
                        actionSectionTop={<div onClick={() => showVisible()} className={classes['icon-close']}>
                            <IconClose color="#929292" fontSize={20} />
                        </div>}
                    >
                        <UserListCommon
                            isMulti={payload?.isMulti}
                            onChangeSaveListSelected={handleSaveHolonInstance}
                            listDataActive={listSelectedAll?.find(x => x?.id == active)?.data ?? []}
                            loading={loadingGetList}
                            dataActive={active}
                            handleChangePageListData={handleChangePageListData}
                            onSearchListData={onSearchListData}
                            data={{
                                records: data?.records,
                                page: data?.page,
                                total: data?.total
                            }}
                            keyword={keyword}
                            dataHeader={dataHeader}
                            sectionTab={tab?.length == 2 ? <div className={classes['custom-tab-holon-template']}>
                                {tab?.map((x, index) => {
                                    return <div key={index} style={{
                                        pointerEvents: loadingGetList ? 'none' : 'fill'
                                    }} onClick={() => {
                                        !loadingGetList && handleChangeTab(x?.id)
                                    }} className={`${classes['custom-tab']} ${active == x?.id ? classes['custom-tab-active'] : ''}`}>
                                        <span>{x?.name}</span>
                                        {
                                            listSelectedAll?.find(v => v?.id == x?.id)?.data?.length > 0 ? <div className={classes['custom-tab-number']}>
                                                <p>{listSelectedAll?.find(v => v?.id == x?.id)?.data?.length}</p>
                                            </div> : <></>
                                        }
                                    </div>
                                })}
                            </div> : <></>}
                        />
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </div>
    )
}

export default UserListPopup