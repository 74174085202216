import React, { useEffect } from 'react'
import Box from 'shared/components/common/box/Box'
import BoxBody from 'shared/components/common/box/BoxBody'
import BoxContentPopup from 'shared/components/common/popup/custom-body/BoxContentPopup'
import WrapContentBody from 'shared/components/common/popup/custom-body/WrapContentBody'
import classes from './PackageTracking.module.scss';
import { useState } from 'react'
import BoxRow from 'shared/components/common/box/BoxRow'
import BoxLabel from 'shared/components/common/box/BoxLabel'
import useTrans from 'hooks/use-trans'
import BoxContent from 'shared/components/common/box/BoxContent'
import CustomTable from 'shared/components/common/custom-table/CustomTable'
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_USER_INQUIRY } from 'utils/EventRegister'
import moment from 'moment'
import Button from 'shared/components/form-input/button/Button';
import IconClose from 'assets/icon/IconClose'

function PackageTrackingSearch(props) {
    const [holonActive, setHolonActive] = useState(null);
    const [dataSearch, setDataSearch] = useState([]);

    const handleChangeTab = (item) => {
        setHolonActive(item);
    };

    const { t } = useTrans();

    const headCell = [
        {
            label: t('package_tracking.time_stamp'),
            field: "oldestTimestamp",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (data) => {
                return <>{data?.data?.timestamp ? moment(data?.data?.timestamp).format("DD/MM/YYYY HH:mm") : ''}</>
            }
        },
        {
            label: t('package_tracking.actor'),
            field: "packagePlace",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
        },
        {
            label: t('package_tracking.content'),
            field: "status",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
        }
    ]

    const openContactUs = () => {

        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_INQUIRY,
            open: true,
            payload: {
                title: t("Inquiry.inquiry_user"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
            }
        })
    }

    useEffect(() => {
        if (props?.data?.length > 0) {
            setHolonActive(props?.data[0]?.item)
        }
    }, [props?.data])

    useEffect(() => {
        if (props?.dataRes?.listTrackingInfos) {
            setDataSearch(props?.dataRes?.listTrackingInfos)
        }
    }, [props?.dataRes])

    let items = dataSearch?.find(x => x?.hexOrderNumber == holonActive);
    return (
        <BoxContentPopup>
            <WrapContentBody
                showVisible={props?.showVisible}
                title={props?.title}
                useForm={false}
                isUseBtnBottom={false}
                handleBtnBack={props?.handleBtnBack}
                actionSectionTop={
                    <Button
                        onClick={() => props?.showVisible && props?.showVisible()}
                        color="gray"
                        startIcon={
                            <IconClose
                                fontSize={14}
                                color="#106574"
                            />
                        }
                    />
                }
            >
                <Box>
                    <BoxBody style={{
                        borderRadius: '10px'
                    }}>
                        <div className={classes['custom-tab-holon-template']}>
                            {props?.data?.map((x, key) => {
                                return <div key={key} style={{
                                }} onClick={() => {
                                    handleChangeTab(x?.item)
                                }} className={`${classes['custom-tab']} ${holonActive == x?.item ? classes['custom-tab-active'] : ''}`}>
                                    <span>{x?.item}</span>
                                </div>
                            })}
                        </div>
                        {
                            props?.data?.length > 0 && <>
                                {
                                    items?.message && <BoxRow>
                                        <BoxLabel style={{ color: 'red', width: '100%' }}>※{items?.message}</BoxLabel>
                                    </BoxRow>
                                }
                                <BoxRow>
                                    <BoxLabel>{t("package_tracking.delivery_information")}</BoxLabel>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth='150px'>{t("package_tracking.ship_date")}</BoxLabel>:
                                    <BoxContent>
                                        {items?.oldestTimestamp ? moment(items?.oldestTimestamp).format("DD/MM/YYYY") : ''}
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth='150px'>{t("package_tracking.shipping_address")}</BoxLabel>:
                                    <BoxContent>
                                        {items?.senderAddress}
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth='150px'>{t("package_tracking.delivery_address")}</BoxLabel>:
                                    <BoxContent>
                                        {items?.receiverAddress}
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel>{t("package_tracking.tracking_information")}</BoxLabel>
                                </BoxRow>

                                <BoxRow>
                                    <div className={classes['custom-table']}>
                                        <CustomTable headCell={headCell} data={items?.trackingInfos ?? []} />
                                    </div>
                                </BoxRow>
                                <BoxRow>
                                    <div onClick={() => openContactUs()} className={classes['text-note']} dangerouslySetInnerHTML={{
                                        __html: t("package_tracking.question_or_concerns")
                                    }} />
                                </BoxRow>
                            </>
                        }
                    </BoxBody>
                </Box>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default PackageTrackingSearch