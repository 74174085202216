import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom';

const WrapRouter = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [location.pathname]);

    return children
}

export default WrapRouter