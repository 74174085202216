import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import AppConfig from 'utils/AppConfig'
import Constants from 'utils/Constants';
import { getToast, getToastError } from 'utils/Utils';
import i18n from "i18nextInit"

const onRequest = (config) => {
    const token = Cookies.get("accessToken");

    config.headers.Authorization = `Bearer ${token}`
    let lang = localStorage.getItem("i18nextLng") ?? Constants.T001.JP.CODE;
    config.headers['lang'] = lang.toUpperCase();
    return config
}

const onRequestError = (error) => {
    Promise.reject(error)
}

const onResponse = (response) => response

const onResponseError = async (res) => {

    if (res?.code === "ERR_NETWORK") {
        getToast(i18n.t("server_internal_error"), "error");
    }
    const {response} = res;

    if (response.status == 401) {
        getToast(i18n.t("unauthorized"), "error");
        return Redirect("/");
    }
    // const { data: { error } } = res?.response;
    // getToastError(error?.detail);
    // if (error.response) {
    //     // Access Token expired
    //     if (
    //         error.response.status === 401 &&
    //         error.response.data.message === 'jwt expired'
    //     ) {
    //         try {
    //             // const rs = await axios.post(
    //             //     `${BASE_URL}/${BASE_PREFIX}/auth/login/refresh-token`,
    //             //     {
    //             //         JwtToken: TokenService.getUser().Token,
    //             //         RefreshToken: TokenService.getUser().RefreshToken,
    //             //     },
    //             // )
    //             // console.log(rs.data)

    //             // const { token, user } = rs.data

    //             // localStorage.setItem('token', JSON.stringify(token))
    //             // localStorage.setItem('user', JSON.stringify(user))

    //             // return;
    //         } catch (_error) {
    //             return Promise.reject(_error)
    //         }
    //     }
    // }
    return Promise.reject(res)
}

export const setupInterceptersTo = (axiosInstance) => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError)
    axiosInstance.interceptors.response.use(onResponse, onResponseError)
    return axiosInstance
}
