import React, { useEffect, useState } from 'react'
import useRouter from 'hooks/use-router';
import { useDispatch, useSelector } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import actionHexService from 'redux/hex-service/action';
import useTrans from 'hooks/use-trans';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE } from 'utils/EventRegister';
import Button from 'shared/components/form-input/button/Button';
import styles from './ServiceSelectionPopup.module.scss';
import Loading from 'shared/components/form-input/button/loading/Loading';
import Constants from 'utils/Constants';
import { getLoggedInUserInfoFromToken } from 'utils/Utils';
import actionsHolon from 'redux/holon/action';
import CustomHeader from '../custom-header/CustomHeader';

function ServiceSelectionPopup(props) {
    const { showVisible, payload } = props;
    const router = useRouter();
    const { t } = useTrans();
    const { hexServiceList } = useSelector(state => state?.hexService);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const [personalCustomerTemplateIds, setPersonalCustomerTemplateIds] = useState();
    const [businessCustomerTemplateIds, setBusinessCustomerTemplateIds] = useState();
    const loggedInUser = getLoggedInUserInfoFromToken();
    const serviceIdList = payload?.list_service?.map(String);
    const filteredServiceList = hexServiceList.filter(obj => serviceIdList?.includes(obj.id));
    const serviceDisplayList = filteredServiceList?.length ? filteredServiceList : hexServiceList;

    const {
        hoLonTemplateId 
    } = useSelector(state =>state?.holonInstance)

    useEffect(() => {
        setIsLoading(true)
        dispatch({
            type: actionHexService.GET_HEX_SERVICE_LIST,
            callback: () => {
                setIsLoading(false);
            },
            fallback: () => {
                setIsLoading(false);
            }
        })

        dispatch({
            type: actionsHolon.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.PERSONAL_CUSTOMER,
            callback: (data) => {
                setPersonalCustomerTemplateIds(data)
            }
        })

        dispatch({
            type: actionsHolon.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.BUSINESS_CUSTOMER,
            callback: (data) => {
                setBusinessCustomerTemplateIds(data)
            }
        })
    }, [])

    const hasDuplicateElements = (listUserRole, listTemplateId) => {
        return listUserRole?.some(template => listTemplateId?.includes(template));
    }

    const handleAddInstance = (templateId) => {
        let presetValue = null
        let presetName = null
        if (hasDuplicateElements(loggedInUser?.role, personalCustomerTemplateIds)) {
            presetValue = loggedInUser?.roleid;
            presetName = loggedInUser?.rolename;
        }
        if (hasDuplicateElements(loggedInUser?.role, businessCustomerTemplateIds)) {
            presetValue = loggedInUser?.companyId;
            presetName = loggedInUser?.companyName;
        }
        const presetProps = payload.preset;
        const orderOwnerConnection = {
            [Constants.CONNECTION_KEY.ORDER.ORDER_OWNER]: [{
                name: presetName,
                value: presetValue
            }]
        }

        const dataPreset = {
            templateId: templateId,
            data: {
                attributes: {
                    ...presetProps?.attributes
                },
                connections: {
                    ...presetProps?.connections,
                    ...(presetValue || presetValue === 0) ? orderOwnerConnection : null
                }
            }
        }

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            payload: {
                handleBtnBack: showVisible(false),
                title: t("order_management.popup_create_title"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                dataPreset: dataPreset,
                callback: () => {
                    if(router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)){
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)
                            }
                        });
                    }else if(hoLonTemplateId == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE){
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: payload?.listTemplate
                            }
                        });
                    }
                },
                templateId: templateId
            }
        })
    }

    return (
        <div>
            <CustomHeader
                title={t("service_selection.title")}
                showVisible={showVisible}
                payload={payload}
            />
            <ul className={styles['two-column-list']}>
                {isLoading && <li><Loading color="black" /></li>}
                {!isLoading && serviceDisplayList?.map((item, index) => (
                    <li key={index}>
                        <Button size='large' color="green" onClick={() => handleAddInstance(item?.orderTemplate)}>{item?.title}</Button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ServiceSelectionPopup