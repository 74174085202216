import actionSender from "./action";

const initialState = {
    loadingListSender: false,
    loadingCreateSender: false,
    loadingUpdateSender: false,
    loadingSenderDetail: false,
    listSender: {},
    holonTemplateDetail: {}
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionSender.GET_SENDER_LIST:
            return {
                ...state,
                loadingListSender: true
            }
        case actionSender.GET_SENDER_LIST_SUCCESS:
            return {
                ...state,
                loadingListSender: false,
                listSender: action.payload
            }
        case actionSender.GET_SENDER_LIST_FAILED:
            return {
                ...state,
                loadingListSender: false
            }
        case actionSender.GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER_SUCCESS:
            return {
                ...state,
                holonTemplateDetail: action.payload
            }
        case actionSender.CREATE_SENDER:
            return {
                ...state,
                loadingCreateSender: true
            }
        case actionSender.CREATE_SENDER_FAILED:
            return {
                ...state,
                loadingCreateSender: false
            }
        case actionSender.CREATE_SENDER_SUCCESS:
            return {
                ...state,
                loadingCreateSender: false
            }
        case actionSender.UPDATE_SENDER:
            return {
                ...state,
                loadingUpdateSender: true
            }
        case actionSender.UPDATE_SENDER_FAILED:
            return {
                ...state,
                loadingUpdateSender: false
            }
        case actionSender.UPDATE_SENDER_SUCCESS:
            return {
                ...state,
                loadingUpdateSender: false
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;