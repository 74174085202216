const Action = {
    REGISTER: "REGISTER",
    REGISTER_RESPONSE: "REGISTER_RESPONSE",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_RESPONSE: "LOGIN_RESPONSE",
    FORGOT_ID_PASSWORD: "FORGOT_ID_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    LOGOUT: "LOGOUT",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

    GET_ACCOUNT: "GET_ACCOUNT",
    GET_ACCOUNT_SUCCESS: "GET_ACCOUNT_SUCCESS",
    GET_ACCOUNT_FAILURE: "GET_ACCOUNT_FAILURE",

    GET_USER_STATUS_BY_HEX_ID: "GET_USER_STATUS_BY_HEX_ID",

    UPLOAD_IMAGE: "UPLOAD_IMAGE",
    UPLOAD_IMAGE_COMMON: "UPLOAD_IMAGE_COMMON",
    UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

    UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
    UPDATE_ACCOUNT_SUCCESS: "UPDATE_ACCOUNT_SUCCESS",
    UPDATE_ACCOUNT_FAILURE: "UPDATE_ACCOUNT_FAILURE",
    CLEAR_UPDATE_ACCOUNT_ERROR: 'CLEAR_UPDATE_ACCOUNT_ERROR',
    
    UPDATE_ALARM_ACCOUNT: "UPDATE_ALARM_ACCOUNT",
    UPDATE_ALARM_ACCOUNT_SUCCESS: "UPDATE_ALARM_ACCOUNT_SUCCESS",
    UPDATE_ALARM_ACCOUNT_FAILURE: "UPDATE_ALARM_ACCOUNT_FAILURE",
    
    CHECK_TOKEN_EXPIRATION: "CHECK_TOKEN_EXPIRATION",

    INQUIRY: "INQUIRY",
    SAVE_INQUIRY_DATA: "SAVE_INQUIRY_DATA",
    REMOVE_INQUIRY_DATA: "REMOVE_INQUIRY_DATA",
    REQUEST_REFRESH_TOKEN: 'REQUEST_REFRESH_TOKEN',
    RECEIVE_REFRESH_TOKEN: 'RECEIVE_REFRESH_TOKEN',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    CLEAR_LOGIN_ERROR: 'CLEAR_LOGIN_ERROR',
    FORGOT_ID_PASSWORD_FAILURE: 'FORGOT_ID_PASSWORD_FAILURE',
    CLEAR_FORGOT_ID_PASSWORD_ERROR: 'CLEAR_FORGOT_ID_PASSWORD_ERROR',

    GET_SALE_LIST: "GET_SALE_LIST",
    GET_SALE_LIST_SUCCESS: "GET_SALE_LIST_SUCCESS",
    GET_SALE_LIST_FAILURE: "GET_SALE_LIST_FAILURE",

    GET_MANAGER_LIST: "GET_MANAGER_LIST",
    GET_MANAGER_LIST_SUCCESS: "GET_MANAGER_LIST_SUCCESS",
    GET_MANAGER_LIST_FAILURE: "GET_MANAGER_LIST_FAILURE",

    GET_POSITIONS_OF_BUSINESS_CUSTOMER: "GET_POSITIONS_OF_BUSINESS_CUSTOMER",
    GET_POSITIONS_OF_BUSINESS_CUSTOMER_SUCCESS: "GET_POSITIONS_OF_BUSINESS_CUSTOMER_SUCCESS",
    GET_POSITIONS_OF_BUSINESS_CUSTOMER_FAILURE: "GET_POSITIONS_OF_BUSINESS_CUSTOMER_FAILURE",
}

export default Action;