import React, { useEffect, useRef, useState } from 'react'
import classes from './ChoiceHolonTemplate.module.scss';
import Button from 'shared/components/form-input/button/Button';
import IconPlus from 'assets/icon/IconPlus';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import CustomPagination from '../../custom-pagination/CustomPagination';
import FormSearch from '../../form-search/FormSearch';
import FormItem from '../../form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import IconClose from 'assets/icon/IconClose';
import FormGroup from '../../form-search/FormGroup';
import WrapContentBody from '../custom-body/WrapContentBody';
import { useDispatch } from 'react-redux';
import actionHolonTemplate from 'redux/holon-template/action';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';


export default function ListSelectHolonTemplate(props) {
    const { payload, showVisible } = props;
    const isMulti = payload?.isMulti == false ? payload?.isMulti : true;
    const [listTemplate, setListTemplate] = useState([]);
    const [listTemplateAll, setListTemplateALL] = useState([]);
    const [pageList, setPageList] = useState(1);
    const [pageListSelect, setPageListSelect] = useState(1);

    const [listPage, setListPage] = useState([]);
    const { t } = useTrans();
    const currentList = useRef(false);

    const [keySearch, setKeySearch] = useState(null);
    const [keySearchSelect, setKeySearchSelect] = useState(null);
    const [listHolonTemplate, setListHolonTemplate] = useState([]);

    const [listSelectTemplateAll, setListSelectTemplateAll] = useState([]);
    const [listSelect, setListSelect] = useState([]);

    const methods = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT,
            payload: {
                masterCode: payload?.masterCode
            },
            callback: {
                success: (data) => {
                    setListHolonTemplate(data?.records)
                }
            }
        })
    }, [payload?.masterCode])

    useEffect(() => {
        if (listHolonTemplate) {
            if (payload?.value?.length > 0) {
                // **** LIST *****//
                setListTemplate(listHolonTemplate);
                let newList = [...listHolonTemplate];
                let temp = [];
                [...newList]?.map(x => {
                    if (payload?.value?.findIndex(v => v == x?.id) == -1) {
                        temp.push(x);
                    }
                })
                setListTemplateALL(temp);
                setListPage(sliceArray(temp, 1));

                // **** SELECT *****//
                let tempSelect = [];
                [...newList]?.map(x => {
                    if (payload?.value?.findIndex(v => v == x?.id) != -1) {
                        tempSelect.push(x);
                    }
                });
                setListSelectTemplateAll(tempSelect);
                setListSelect(sliceArray(tempSelect, 1))
            } else {
                setListTemplateALL(listHolonTemplate);
                setListTemplate(listHolonTemplate);
                setListPage(sliceArray(listHolonTemplate, 1));
            }
        }
    }, [listHolonTemplate, payload?.value])

    let convertListSelect = (_listSelectTemplateAll, _keySearchSelect) => {
        if (_keySearchSelect) {
            let list = [..._listSelectTemplateAll]?.filter(item => item?.name.toLowerCase().indexOf(_keySearchSelect?.toLowerCase()) > -1 || item?.category.toLowerCase().indexOf(_keySearchSelect?.toLowerCase()) > -1);
            return list;
        }
        return _listSelectTemplateAll;
    }

    let sliceArray = (data = [], from) => {
        return [...data]?.slice((from - 1) * 10, from * 10);
    }

    const handleAdd = (item) => {
        let newList = [...listTemplateAll];
        let indexRemove = newList?.findIndex(x => x?.id == item?.id);
        if (indexRemove != -1) {

            /// **** SELECT *****//
            let newListSelectTemplateAll = [...listSelectTemplateAll]
            newListSelectTemplateAll.push(item)
            setListSelectTemplateAll(newListSelectTemplateAll);
            setListSelect(sliceArray(convertListSelect(newListSelectTemplateAll, keySearchSelect), pageListSelect));


            /// **** LIST *****//
            let temp = [];
            [...newList]?.map(x => {
                if (newListSelectTemplateAll?.findIndex(v => v?.id == x?.id) == -1) {
                    temp.push(x);
                }
            });

            // + CHECK PAGE +
            let page = pageList;
            if (temp?.length == 0) {
                setPageList(1);
            } else
                if (pageList > Math.ceil(temp?.length / 10)) {
                    page = page - 1;
                    setPageList(page)
                }

            setListTemplateALL(temp);
            setListPage(sliceArray(convertListSelect(temp, keySearch), page));
        }
    }

    const handleRemove = (item) => {
        let newListSelectAll = [...listSelectTemplateAll];
        let indexRemove = newListSelectAll?.findIndex(x => x?.id == item?.id);
        if (indexRemove != -1) {

            /// **** LIST *****//
            let newListTemplateAll = [...listTemplateAll];
            newListTemplateAll.push(item);
            setListTemplateALL(newListTemplateAll);
            setListPage(sliceArray(convertListSelect(newListTemplateAll, keySearch), pageList));


            /// **** SELECT *****//
            newListSelectAll?.splice(indexRemove, 1);
            setListSelectTemplateAll(newListSelectAll);
            // CHECK PAGE
            let _pageListSelect = pageListSelect;
            if (newListSelectAll?.length == 0) {
                setPageListSelect(1)
            } else
                if (pageListSelect > Math.ceil(newListSelectAll?.length / 10)) {
                    _pageListSelect = _pageListSelect - 1;
                    setPageListSelect(_pageListSelect)
                }
            // SET DATA IN PAGE
            setListSelect(sliceArray(convertListSelect(newListSelectAll, keySearchSelect), _pageListSelect));
        }
    }

    const handleOke = () => {
        payload?.callback([...listSelectTemplateAll]?.map(x => x?.id));
        showVisible()
    }

    const onChangePageListSelect = (e) => {
        currentList.current = true;
        setListSelect(sliceArray(convertListSelect(listSelectTemplateAll, keySearchSelect), e));
        setPageListSelect(e)
    }

    const onChangePageList = (e) => {
        currentList.current = true;
        let temp = [];
        [...listTemplateAll]?.map(x => {
            if (listSelectTemplateAll?.findIndex(v => v?.id == x?.id) == -1) {
                temp.push(x);
            }
        })
        setListPage(sliceArray(convertListSelect(temp, keySearch), e))
        setPageList(e)
    }

    const onChangeList = (e) => {
        currentList.current = true;
        if (e) {
            setKeySearch(e);
            let list = [...listTemplate];
            list = list?.filter(item => item?.name.toLowerCase().indexOf(e?.toLowerCase()) > -1 || item?.category.toLowerCase().indexOf(e?.toLowerCase()) > -1);

            let temp = [];
            [...list]?.map(x => {
                if (listSelectTemplateAll?.findIndex(v => v?.id == x?.id) == -1) {
                    temp.push(x);
                }
            })
            setListTemplateALL(temp);
            setListPage(temp?.slice(0, 10));
            setPageList(1)
        } else {
            setKeySearch(null);
            let list = [...listTemplate];

            let temp = [];
            [...list]?.map(x => {
                if (listSelectTemplateAll?.findIndex(v => v?.id == x?.id) == -1) {
                    temp.push(x);
                }
            })
            setListTemplateALL(temp);
            setListPage(temp?.slice(0, 10));
            setPageList(1)
        }
    }

    const onChangeListSelect = (e) => {
        currentList.current = true;
        if (e) {
            setKeySearchSelect(e);
            let list = [];
            list = [...listSelectTemplateAll]?.filter(item => item?.name.toLowerCase().indexOf(e?.toLowerCase()) > -1 || item?.category.toLowerCase().indexOf(e?.toLowerCase()) > -1);
            setListSelect(list?.slice(0, 10));
            setPageListSelect(1);
        } else {
            setKeySearchSelect(null);
            let list = [...listSelectTemplateAll];
            setListSelect(list?.slice(0, 10));
            setPageListSelect(1)
        }

    }

    return (
        <>
            <div className={classes['container']}>
                <CustomFormProvider {...methods}>
                    <WrapContentBody
                        title={payload?.title}
                        showVisible={showVisible}
                        actionSectionBottom={<Button onClick={handleOke} title={t("Holon_Template.save")} />}
                        isUseBackground={false}
                        isUseBtnBack={false}
                        actionSectionTop={<div onClick={() => showVisible()} className={classes['icon']}>
                            <IconClose color="#929292" fontSize={20} />
                        </div>}
                    >
                        <div className={classes['selected-holon-template']}>
                            <div className={classes['selected-container']}>
                                <div className={classes['list']}>
                                    <div className={classes['content-list']}>
                                        <FormSearch className={classes['custom-form-search']}>
                                            <FormGroup>
                                                <FormItem width="183px">
                                                    <FormInput
                                                        fieldName='Search'
                                                        background='white'
                                                        className="custom-input"
                                                        placeholder={t("search_by_word")}
                                                        iconSearch={<IconSearch fontSize={14} />}
                                                        onChangeValue={(e) => {
                                                            onChangeList(e)
                                                        }}
                                                        isSearch
                                                    />
                                                </FormItem>
                                            </FormGroup>
                                        </FormSearch>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className={classes['content-with']}>
                                                        <div className={classes['title-header']}>{t("Holon_Template.category")}</div>
                                                    </th>
                                                    <th className={classes['content-with']}>
                                                        <div className={classes['title-header']}>{t("Holon_Template.name")}</div>
                                                    </th>
                                                    <th className={classes['content-with']}>
                                                        <div className={classes['title-header']}>{t("Holon_Template.status")}
                                                        </div>
                                                    </th>
                                                    <th className={classes['content-with-action']}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    convertListSelect(listPage, keySearch)?.map(x => {
                                                        return <tr key={x?.id}>
                                                            <td className={classes['content-width']}>
                                                                <p title={x?.category}>{x?.category}</p>
                                                            </td>
                                                            <td className={classes['content-width']}>
                                                                <p title={x?.name}>{x?.name}</p>
                                                            </td>
                                                            <td>{
                                                                <div className={classes['Custom-Row-CheckBox']}>
                                                                    {x?.status == Constants.STATUS_CATEGORY.PUBLIC.VALUE ?
                                                                        t("Holon_Template.valid")
                                                                        : t("Holon_Template.invalid")
                                                                    }
                                                                </div>
                                                            }</td>
                                                            <td className={classes['content-with-action']}>
                                                                <Button isShadow={false} backgroundTransparent size='small' disabled={isMulti ? false : (listSelect?.length == 1 ? true : false)} onClick={() => handleAdd(x)} color='white' startIcon={<IconPlus />} />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={classes['custom-pagination']}>
                                        <CustomPagination page={pageList} limit={10} total={listTemplateAll?.length ?? 0} onChange={onChangePageList} />
                                    </div>
                                </div>
                                <div className={`${classes['list']} ${classes['list-selected']}`}>
                                    <div className={classes['content-list']}>
                                        <FormSearch className="custom-form-search">
                                            <FormGroup>
                                                <FormItem width="183px">
                                                    <FormInput
                                                        fieldName='Search_choice'
                                                        background='white'
                                                        className="custom-input"
                                                        placeholder={t("search_by_word")}
                                                        iconSearch={<IconSearch fontSize={14} />}
                                                        onChangeValue={(e) => {
                                                            onChangeListSelect(e)
                                                        }}
                                                        isSearch
                                                    />
                                                </FormItem>
                                            </FormGroup>
                                        </FormSearch>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className={classes['content-with']}>
                                                        <div className={classes['title-header']}>{t("Holon_Template.category")}</div>
                                                    </th>
                                                    <th className={classes['content-with']}>
                                                        <div className={classes['title-header']}>{t("Holon_Template.name")}</div>
                                                    </th>
                                                    <th className={classes['content-with']}>
                                                        <div className={classes['title-header']}>{t("Holon_Template.status")}</div>
                                                    </th>
                                                    <th className={classes['content-with-action']}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    convertListSelect(listSelect, keySearchSelect)?.map(x => {
                                                        return <tr key={x?.id}>
                                                            <td className={classes['content-width']}>
                                                                <p title={x?.category}>{x?.category}</p>
                                                            </td>
                                                            <td className={classes['content-width']}>
                                                                <p title={x?.name}>{x?.name}</p>
                                                            </td>
                                                            <td>{
                                                                <div className={classes['Custom-Row-CheckBox']}>
                                                                    {x?.status == Constants.STATUS_CATEGORY.PUBLIC.VALUE ?
                                                                        t("Holon_Template.valid")
                                                                        : t("Holon_Template.invalid")
                                                                    }
                                                                </div>
                                                            }</td>
                                                            <td className={classes['action-remove']}>
                                                                <Button isShadow={false} backgroundTransparent size='small' onClick={() => handleRemove(x)} color='white' startIcon={<IconMinusAccordion color="currentColor" />} />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={classes['custom-pagination']}>
                                        <CustomPagination page={pageListSelect} limit={10} total={listSelectTemplateAll?.length ?? 0} onChange={onChangePageListSelect} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapContentBody>
                </CustomFormProvider>
            </div>

        </>
    )
}
