import Actions from './action';

const initialState = {
    continents: [],
    countries: [],
    ports: [],
    loading: {
        loadingContinent: false,
        loadingCountry: false,
        loadingPort: false,
    },
}

export default function addressReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CONTINENTS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingContinent: true,
                }
            }

        case Actions.GET_CONTINENTS_SUCCESS:
            return {
                ...state,
                continents: action?.payload,
                loading: {
                    ...state.loading,
                    loadingContinent: false,
                }
            }

        case Actions.GET_CONTINENTS_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingContinent: false,
                }
            }

        case Actions.GET_COUNTRIES_BY_CONTINENT_ID:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCountry: true,
                }
            }

        case Actions.GET_COUNTRIES_BY_CONTINENT_ID_SUCCESS:
            return {
                ...state,
                countries: action?.payload,
                loading: {
                    ...state.loading,
                    loadingCountry: false,
                }
            }

        case Actions.GET_COUNTRIES_BY_CONTINENT_ID_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCountry: false,
                }
            }

        case Actions.GET_PORTS_BY_PORT_TYPE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingPort: true,
                }
            }

        case Actions.GET_PORTS_BY_PORT_TYPE_SUCCESS:
            return {
                ...state,
                ports: action?.payload,
                loading: {
                    ...state.loading,
                    loadingPort: false,
                }
            }

        case Actions.GET_PORTS_BY_PORT_TYPE_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingPort: false,
                }
            }

        default: 
            return state;
    }
}
