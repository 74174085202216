import React, { useEffect, useState } from 'react';
import classes from './CustomPagination.module.scss';
import Constants from 'utils/Constants';
import { DOTS, usePagination } from 'hooks/use-pagination';
import IconChevronLeft from 'assets/icon/IconChevronLeft';
import IconChevronRight from 'assets/icon/IconChevronRight';

const CustomPagination = (props) => {
    const { total = 0, itemCount = 3, siblingCount = 1, limit = Constants.PAGINATION.LIMIT, className, onChange = () => { }, page = 1, onChangeRowPerpage = {}, isRowPerpage = false } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPage, setLimitPage] = useState(Constants.PAGINATION.LIMIT);

    const onPageChange = (e) => {
        setCurrentPage(
            parseInt(e, 10),
        );
        onChange(parseInt(e, 10))
    };

    const handleChangeLimit = (e) => {
        onChangeRowPerpage(e);
    }

    useEffect(() => {
        if (limit) {
            setLimitPage(parseInt(limit, 10));
        } else {
            setLimitPage(Constants.PAGINATION.LIMIT)
        }
    }, [limit])


    useEffect(() => {
        if (page) {
            setCurrentPage(
                parseInt(page, 10),
            );
        } else {
            setCurrentPage(1);
        }
    }, [page]);

    const paginationRange = usePagination({
        total,
        limit: limitPage,
        siblingCount,
        currentPage,
        itemCount
    });

    let lastPage = paginationRange?.[paginationRange?.length - 1];

    const onNext = () => {
        if (currentPage < lastPage) {
            onPageChange(parseInt(currentPage, 10) + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage > 1) {
            onPageChange(parseInt(currentPage, 10) - 1);
        }
    };

    const onLastPage = () => {
        onPageChange(parseInt(lastPage, 10))
    }
    
    const onFirstPage = () => {
        onPageChange(1)
    }

    return (
        <div className={classes['custom-pagiantion']}>
            <div />
            {
                currentPage === 0 || paginationRange?.length < 2 ? <></> : <ul className={`${classes['flex-bottom-pagination']} ${className}`}>
                    {
                        <button
                            className={`${classes['btn-next']} ${currentPage == 1 ? classes['btn-disabled'] : ''}`}
                            onClick={onFirstPage}
                        >
                            <IconChevronLeft fontSize={10} />
                            <IconChevronLeft fontSize={10} />
                        </button>
                    }
                    <button
                        className={`${classes['btn-pre']} ${1 == currentPage ? classes['btn-disabled'] : ''}`}
                        onClick={onPrevious}
                    >
                        <IconChevronLeft />
                    </button>
                    <div className={classes['pagination']}>
                        {paginationRange?.map((pageNumber, key) => {
                            if (pageNumber === DOTS) {
                                return (
                                    <button
                                        key={key}
                                        className={classes['pagination-item-dots']}
                                    >
                                        &#8230;
                                    </button>
                                );
                            }
                            return (
                                <button
                                    className={`${classes['pagination-item']} ${currentPage == pageNumber ? classes['active'] : ''}`}
                                    key={key}
                                    onClick={() => onPageChange(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            );
                        })}
                    </div>
                    <button
                        className={`${classes['btn-next']} ${lastPage == currentPage ? classes['btn-disabled'] : ''}`}
                        onClick={onNext}
                    >
                        <IconChevronRight />
                    </button>
                    {
                        <button
                            className={`${classes['btn-next']} ${lastPage == currentPage ? classes['btn-disabled'] : ''}`}
                            onClick={onLastPage}
                        >
                            <IconChevronRight fontSize={10} />
                            <IconChevronRight fontSize={10} />
                        </button>
                    }
                </ul>
            }
            {
                isRowPerpage ? <select value={limitPage} onChange={(e) => {
                    handleChangeLimit(e.target.value);
                }}>
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select> : <div />
            }
        </div>
    );
};

export default CustomPagination;
