import React from 'react'
import classes from './ContentLayout.module.scss';
import PropsType from 'prop-types';
function ContentLayout(props) {
    const {
        isUseBackground = true,
        padding,
        width,
        margin
    } = props;
    return (
        <div style={{
            backgroundColor: isUseBackground ? '#F2F2F2' : 'transparent',
            padding: padding,
            width: width,
            margin: margin
        }} className={`${classes['content-layout']} content-layout`}>
            {props?.children}
        </div>
    )
}

ContentLayout.propTypes = {
    width: PropsType.any,
}
export default ContentLayout