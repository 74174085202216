import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrivatePolicyAction from 'redux/private-policy/action';
import Constants from 'utils/Constants';
import classes from './PrivatePolicy.module.scss';
import useTrans from 'hooks/use-trans';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';

function PrivatePolicyContainer() {
    const { data } = useSelector((state) => state.privatePolicy);
    const dispatch = useDispatch();

    const { t } = useTrans()
    useEffect(() => {
        dispatch({
            type: PrivatePolicyAction.GET_PRIVATE_POLICY,
            payload: {
                key: Constants.T002.PRIVATE_POLICY.CODE,
                lang: Constants.T001.JP.CODE,
            },
        });
    }, []);

    return (
        <ContentLayout>
            <div className={classes['PrivatePolicyContainer']}>
                <div className={classes['card-content']}>
                    <div className={classes['card-heading']}>
                        <p>{t("faq_management.Hex_personal_info")}</p>
                    </div>
                </div>
                <div className={classes['card-title']}>
                    <p className={classes['title']}>{data?.title}</p>
                </div>
                <div className={classes['card-content']}>
                    <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
            </div>
        </ContentLayout>
    );
}

export default PrivatePolicyContainer;
