const Actions = {
    GET_CONTINENTS: "GET_CONTINENTS",
    GET_CONTINENTS_SUCCESS: "GET_CONTINENTS_SUCCESS",
    GET_CONTINENTS_FAILURE: "GET_CONTINENTS_FAILURE",

    GET_COUNTRIES_BY_CONTINENT_ID: "GET_COUNTRIES_BY_CONTINENT_ID",
    GET_COUNTRIES_BY_CONTINENT_ID_SUCCESS: "GET_COUNTRIES_BY_CONTINENT_ID_SUCCESS",
    GET_COUNTRIES_BY_CONTINENT_ID_FAILURE: "GET_COUNTRIES_BY_CONTINENT_ID_FAILURE",   

    GET_PORTS_BY_PORT_TYPE: "GET_PORTS_BY_PORT_TYPE",
    GET_PORTS_BY_PORT_TYPE_SUCCESS: "GET_PORTS_BY_PORT_TYPE_SUCCESS",
    GET_PORTS_BY_PORT_TYPE_FAILURE: "GET_PORTS_BY_PORT_TYPE_FAILURE",
    
    GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID: 'GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID', 
    GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID_SUCCESS: 'GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID_SUCCESS', 
    GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID_FAILURE: 'GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID_FAILURE', 
}

export default Actions;