import React, { useEffect, useState } from 'react'
import useRouter from 'hooks/use-router';
import { useDispatch, useSelector } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import useTrans from 'hooks/use-trans';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE } from 'utils/EventRegister';
import Button from 'shared/components/form-input/button/Button';
import styles from './TemplateSelectionPopup.module.scss';
import actionHolon from 'redux/holon/action';
import { Close } from '@mui/icons-material';
import Loading from 'shared/components/form-input/button/loading/Loading';
import Constants from 'utils/Constants';
import CustomHeader from '../custom-header/CustomHeader';

function TemplateSelectionPopup(props) {
    const { showVisible, payload } = props;
    const { holonMasterCode } = payload;
    const router = useRouter();
    const { t } = useTrans();
    const { templates } = useSelector(state => state?.holon);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const {
        hoLonTemplateId 
    } = useSelector(state =>state?.holonInstance)

    useEffect(() => {
        setIsLoading(true);
        dispatch({
            type: actionHolon.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: holonMasterCode,
            callback: () => {
                setIsLoading(false);
            },
            fallback: () => {
                setIsLoading(false);
            }
        })
    }, []);

    const handleAddInstance = (templateId) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            payload: {
                title: payload?.titleCreatePopup,
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                templateId: templateId,
                callback: () => {
                    if(router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)){
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)
                            }
                        });
                    }else if(hoLonTemplateId == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: payload?.listTemplate
                            }
                        });
                    }
                }
            }
        })
    }

    return (
        <div>
            <CustomHeader
                title={t(payload.title)}
                showVisible={showVisible}
                payload={payload}
            />
            <ul className={styles['two-column-list']}>
                {isLoading && <li><Loading color="black"/></li>}
                {!isLoading && templates?.map((item, index) => (
                    <li key={index}>
                        <Button size='large' color="green" onClick={() => handleAddInstance(item?.id)}>{item?.name}</Button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TemplateSelectionPopup