import React from 'react'
import classes from './InputMessage.module.scss';
import useTrans from 'hooks/use-trans';

function InputMessage(props) {
    const { t } = useTrans();
    const { refInput } = props;
    return (
        <div className={classes['InputMessage']}>
            <div className={classes['Custom_Input']}>
                <textarea
                    ref={refInput}
                    onFocus={props?.onFocus}
                    disabled={props?.disabled}
                    value={props?.value}
                    onChange={(e) => props?.onChange(e.target.value)}
                    placeholder={t("message.enter_message")}
                    onKeyDown={(evt) => {
                        if (evt.keyCode === 13 && !evt.shiftKey) {
                            evt.preventDefault();
                        }
                    }}
                    style={{
                        maxHeight: 50
                    }}
                    maxLength={500}
                />
            </div>
        </div>
    )
}

export default InputMessage