import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TermOfUseAction from 'redux/term-of-use/action';
import Constants from 'utils/Constants';
import classes from './TermOfUseContainer.module.scss';
import useTrans from 'hooks/use-trans';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';

function TermOfUseContainer() {
    const { data } = useSelector((state) => state.termOfUse);

    const { t } = useTrans()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: TermOfUseAction.GET_TERM_OF_USE,
            payload: {
                key: Constants.T002.TERM_OF_SERVICE.CODE,
                lang: Constants.T001.JP.CODE,
            },
        });
    }, []);

    return (
        <ContentLayout>
            <div className={classes['TermOfUseContainer']}>
                <div className={classes['card-content']}>
                    <div className={classes['card-heading']}>
                        <p>{t("faq_management.term_of_use")}</p>
                    </div>
                </div>
                <div className={classes['card-title']}>
                    <p className={classes['title']}>{data?.title}</p>
                </div>
                <div className={classes['card-content']}>
                    <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
            </div>
        </ContentLayout>
    );
}

export default TermOfUseContainer;
