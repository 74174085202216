
import React from "react";
import MainRouter from './router/MainRouter';
import { Provider } from "react-redux";
import initStore from './redux/store';
import "./Global.scss";
import "./Variable.scss";
import 'bootstrap/dist/css/bootstrap.min.css'

const store = initStore();

function App() {
    return (
        <Provider store={store}>
            <MainRouter />
        </Provider>
    );
}

export default App;
