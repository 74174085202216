import React, { useEffect, useState } from "react";
//import '../assets/css/Messages.css';
import $ from 'jquery';
import addChat from '../assets/img/chat-add-contact.png';
import {sendHolon,getLabel,getCoreAPI,getHolonAttributes,createLabel,formatDate,truncateStr,createConnection} from 'shared/components/common/Util';
import { AttachFileRounded, Cancel, CheckCircle, Close, Search, SendRounded } from "@mui/icons-material";
import FilterBar from "shared/components/common/FilterBar";

function Messages(){
    const [contacts, setContacts] = useState([]);
    const [chats, setChats] = useState([]);
    const fetchContacts = async() => {
        const response = await fetch(getCoreAPI()+"/holon/Person/Contact")
        const data = await response.json()
        setContacts(data)
    }    
    const fetchChats = async() => {
        const response = await fetch(getCoreAPI()+"/connection/holon/"+$('#profile-session').attr('user-data-id')+'/Communication')
        const data = await response.json()
        setChats(data)
    }
    const fetchData = async() => {
        fetchContacts()
        fetchChats()
    }

    // useEffect(() => {
    //     fetchData()
    // }, [])
    
    return (        
        <div className="container-fluid">
            <div className="modal fade" id="resultModal" tabIndex="-1" aria-labelledby="resultModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content alternative-header">
                        <div className="modal-header">
                            <h1 className="modal-title fs-6" id="resultModalLabel"></h1>
                            <span><Close style={{cursor:'pointer'}} data-bs-dismiss="modal" aria-label="Close"/></span>
                        </div>
                        <div className="modal-body">
                            <div className="card border-success mb-3">
                                <div className="card-header bg-transparent border-success"  id="resulDescModalLabel">Loading</div>
                                <div className="card-body text-success">
                                    <center>
                                        <div id="result-loading" className="spinner-border text-info d-none" role="status">
                                            <h2><span className="visually-hidden">Loading...</span></h2>
                                        </div>
                                        <div id="result-ok" className="success-request d-none"><CheckCircle/></div>
                                        <div id="result-nope" className="success-request d-none"><Cancel/></div>
                                        <div id="result-message" className=""></div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="contactModal" tabIndex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content alternative-header">
                        <div className="modal-header">
                            <h1 className="modal-title fs-6" id="contactModalLabel">{getLabel('ユーザー')} {getLabel('作成')}</h1>
                            <Close style={{cursor:'pointer'}} data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <form id="userForm">
                                <div className="card border-success mb-3">
                                    <div className="card-header bg-transparent table-headers border-success">
                                        <span>{getLabel('ユーザー')}</span>
                                        <span id="access-mode"></span>
                                    </div>
                                    <div className="card-body text-success">
                                                                    
                                        <div className="mb-3">
                                            <label htmlFor="avatar" className="form-label">{getLabel('アバター')}</label><br/>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="avatar" id="avatar" value="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairDreads01&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=ShirtVNeck&clotheColor=Gray01&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Brown" />
                                                <img src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairDreads01&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=ShirtVNeck&clotheColor=Gray01&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Brown" alt="avatar" width="30" height="30" className="rounded-circle"/>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="avatar" id="avatar" value="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortRound&accessoriesType=Prescription02&hairColor=Blonde&facialHairType=Blank&clotheType=ShirtCrewNeck&clotheColor=Pink&eyeType=Default&eyebrowType=UpDown&mouthType=Default&skinColor=Light" />
                                                <img src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortRound&accessoriesType=Prescription02&hairColor=Blonde&facialHairType=Blank&clotheType=ShirtCrewNeck&clotheColor=Pink&eyeType=Default&eyebrowType=UpDown&mouthType=Default&skinColor=Light" alt="avatar" width="30" height="30" className="rounded-circle"/>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="avatar" id="avatar" value="https://avataaars.io/?avatarStyle=Circle&topType=WinterHat3&accessoriesType=Round&hatColor=Blue01&facialHairType=BeardMajestic&facialHairColor=BrownDark&clotheType=BlazerSweater&eyeType=Side&eyebrowType=RaisedExcited&mouthType=Serious&skinColor=DarkBrown" />
                                                <img src="https://avataaars.io/?avatarStyle=Circle&topType=WinterHat3&accessoriesType=Round&hatColor=Blue01&facialHairType=BeardMajestic&facialHairColor=BrownDark&clotheType=BlazerSweater&eyeType=Side&eyebrowType=RaisedExcited&mouthType=Serious&skinColor=DarkBrown" alt="hugenerd" width="30" height="30" className="rounded-circle"/>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="avatar" id="avatar" value="https://avataaars.io/?avatarStyle=Circle&topType=LongHairCurvy&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Side&eyebrowType=DefaultNatural&mouthType=Smile&skinColor=Light" />
                                                <img src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairCurvy&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Side&eyebrowType=DefaultNatural&mouthType=Smile&skinColor=Light" alt="hugenerd" width="30" height="30" className="rounded-circle"/>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">{getLabel('名前')}</label>
                                            <input type="text" className="form-control" id="name"  />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="surname" className="form-label">{getLabel('姓氏')}</label>
                                            <input type="text" className="form-control" id="surname" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="birthday" className="form-label">{getLabel('生年月日')}</label>
                                            <input type="date" className="form-control" id="birthday" data-provide="datepicker" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="gender" className="form-label">{getLabel('性別')}</label>
                                            <select id="gender" className="form-select form-control" aria-label="select gender">
                                                <option selected>Select the gender</option>
                                                <option value="M">{getLabel('男')}</option>
                                                <option value="F">{getLabel('女')}</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">{getLabel('メールアドレス')}</label>
                                            <input type="email" className="form-control" id="email" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="phone" className="form-label">{getLabel('電話番号')}</label>
                                            <input type="phone" className="form-control" id="phone" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card border-success mb-3">
                                    <div className="card-header bg-transparent table-headers border-success">
                                        <span>{getLabel('アクション')}</span>
                                        <span id="access-mode"></span>
                                    </div>
                                    <div className="card-body text-success">
                                        <button type="button" className="btn btn-outline-dark" disabled>{getLabel('連絡先')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer" data-bs-dismiss="modal" aria-label="Close">
                            <button type="button" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#contactsModal" >{getLabel('戻る')}</button>
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#resultModal" onClick={() =>{sendHolon('userForm','POST','/holon',"Person","Contact",["連絡先"],fetchContacts);}}  id="submitContact">{getLabel('保存')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal modal-xl fade" id="contactsModal" tabIndex="-1" aria-labelledby="contactsModalLabel" aria-hidden="true">
                <div className="modal-dialog h-100">
                    <div className="modal-content alternative-header h-100">
                        <div className="modal-header">
                            <h1 className="modal-title fs-6" id="contactsModalLabel"></h1>
                            <span><Close style={{cursor:'pointer'}} data-bs-dismiss="modal" aria-label="Close"/></span>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid h-100  my-2 generic-sec">
                                <h3>ユーザー選択</h3>
                                <FilterBar tags="true" dateRange="true" createBtn="true" modal="#contactModal" />
                                <div className="container-fluid rounded h-75 p-0 bg-white">
                                    <table className="table table-borderless  table-list">
                                        <thead>
                                            <tr className="table-headers">
                                                <th>ユーザID</th>
                                                <th>名前</th>
                                                <th>性別</th>
                                                <th>生年月日</th>
                                                <th>メールアドレス</th>
                                                <th>電話番号</th>
                                                <th>作成日</th>
                                                <th>更新日</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contacts.map( contact => (
                                                <tr>
                                                    <td><img src={getHolonAttributes(contact,"avatar")} height="30"/></td>
                                                    <td>{contact.name}</td>
                                                    <td>{getHolonAttributes(contact,"gender")}</td>
                                                    <td>{getHolonAttributes(contact,"birthday")}</td>
                                                    <td>{getHolonAttributes(contact,"email")}</td>
                                                    <td>{getHolonAttributes(contact,"phone")}</td>
                                                    <td>2022-11-25</td>
                                                    <td>2022-11-25</td>
                                                    <td><input className="form-check-input rounded-circle" type="checkbox" value="" id="flexCheckDefault"
                                                        data-id={contact.id}
                                                        data-name={contact.name}
                                                        onClick={()=>toggleLabel(contact.id,contact.name)}
                                                    /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div id="labels-section" className="labels-section w-100 p-2 bg-white">
                                    
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" data-bs-dismiss="modal" aria-label="Close">
                            <button type="button" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#templatesModal">{getLabel('戻る')}</button>
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#resultModal" onClick={() =>requestConnections(fetchChats)}  id="submitAction">転送</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-0 h-100 chat-sections">
                <div className="col-auto col-md-3 col-xl-2 px-sm-0 px-0 ml-5 chat-style">
                    <div className="d-flex flex-column align-items-center pt-2 text-white">
                        <div className="search-chat pb-4">
                            <form className="d-flex mt-3 ps-2 has-search" role="search">
                                <span className="form-control-feedback"><Search/></span>
                                <input className="form-control me-2 bg-white" type="search" placeholder={getLabel('フリーワードで検索')} aria-label={getLabel('フリーワードで検索')} />
                            </form>
                        </div>
                        <div className="chats-container px-3">
                            {chats.map(chat=>
                                <div className="row chat-summary" onClick={e => selectChat(e.target)} chat-data-id={chat.id}  chat-data-name={chat.name} chat-data-avatar={getHolonAttributes(chat.holons[0],'avatar')}>
                                    <div className="col-3 p-1"><img src={getHolonAttributes(chat.holons[0],'avatar')} width="50" /></div>
                                    <div className="col-6 px-1">
                                        <div className="row">
                                            <div className="col px-1"><span className="chat-title">{chat.holons[0].name}</span></div>
                                        </div>
                                        <div className="row">
                                            <div className="col px-1">
                                                {chat.messages.map(message=>
                                                    <p className="chat-description">{truncateStr(message.details,25)}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="row">
                                            <div className="col">
                                                {chat.messages.map(message=>
                                                    <span className="chat-last-time">{formatDate(message.created,'time')}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><span className="chat-pending badge rounded-pill text-bg-secondary">8</span></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="chat-add-icon" data-bs-toggle="modal" data-bs-target="#contactsModal"><a href='#' className="align-right"><img src={addChat} width='50'/></a></div>
                    </div>
                </div>
                <div className="col container-fluid px-0">
                    <div className="chat-header">
                        <h3 id="chat-header" chat-data-id="0"></h3>
                    </div>
                    <div className="container-fluid px-0 chat-container overflow-auto">
                        <div id="chat" className="chat_box_wrapper chat_box_small chat_box_active h-75">
                            <div id="message-template" className="chat_message_wrapper chat_message_right d-none">
                                <div className="chat_user_avatar"></div>
                                <ul className="chat_message">
                                    <li>
                                        <p className="msg-content"></p>
                                        <span className="chat_message_time"></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="chat_box touchscroll chat_box_colors_a">                                        
                            </div>                                
                        </div>    
                    </div>
                    <div className="w-100 reply-main row">
                        <div className="col-sm-11 col-xs-11 ">
                            <textarea className="form-control w-100 h-100" rows="1" id="comment"></textarea>
                        </div>
                        <div className="col-sm-1 col-xs-1">
                            <div className="row">
                                <div className="col-sm-1 col-xs-1 reply-attach">
                                    <AttachFileRounded/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-1 col-xs-1 reply-send" onClick={()=>sendMessage(fetchChats)}>
                                    <SendRounded/>
                                </div>
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>
        </div>
    );
}

function toggleLabel(id,name){
    var labels = $('#labels-section').find('.btn-outline-dark')
    console.info(id+" - "+name)
    var found = false;
    labels.map((label)=> {
        if($(labels[label]).attr('data-name')==name){
            found = true
            $(labels[label]).remove()
        }
    })
    if(!found){
        $('#labels-section').append(createLabel(id,name))
    }    
}

function sendMessage(fetchChats){
    var endpoint='/connection/message';
    var methodVal='POST';
    var jsonMessage = [];
    var jsonConnection = [];
    jsonConnection['id']=$('#chat-header').attr('chat-data-id'); 
    jsonMessage['externalID']='';
    jsonMessage['name']=$('#chat-header').attr('chat-data-name'); 
    jsonMessage['type']='CHAT';
    jsonMessage['details']=$('#comment').val().replace('\n','<br>');
    jsonMessage['author']=$('#profile-session').attr('user-data-dname');
    jsonMessage['contentType']="text/html";
    jsonMessage['connection']=Object.assign({}, jsonConnection);
    console.info(JSON.stringify(Object.assign({}, jsonMessage)));
    postMessage(jsonMessage,'LAST');
    $('#comment').val('')

    $.ajax({
        url: getCoreAPI()+endpoint,
        method: methodVal,
        data: JSON.stringify(Object.assign({}, jsonMessage)),
        dataType : 'json',
        contentType: 'application/json',
        success:function(data){
            $('.pending-message').removeClass('pending-message').find('.chat_message_time').html(formatDate(data.created))
            fetchChats();
            //setTimeout(window.location.reload(),3000)
        },
        error:function(error){
            console.error('Something went wrong, the chat request was not applied.')
            //setTimeout(window.location.reload(),3000)
        }
    });
}

function postMessage(message,pos){
    var messageTemplate = $('#message-template').clone()
    if(message.author != $('#profile-session').attr('user-data-dname'))
    {$(messageTemplate).removeClass('chat_message_right')}
    $(messageTemplate).addClass('pending-message')
    $(messageTemplate).find('.chat_user_avatar').html($('#profile-session').find('img').html())
    $(messageTemplate).find('.msg-content').append(message.details)
    if(message.created != undefined && message.created != null){
        $(messageTemplate).find('.chat_message_time').append(formatDate(message.created,''))
    }else{
        $(messageTemplate).find('.chat_message_time').append('<div class="spinner-border spinner-border-sm text-light" role="status"><span class="visually-hidden">Loading...</span></div>')
    }
    if(pos==='LAST'){
        $('#chat .chat_box').append($(messageTemplate).removeClass('d-none').attr('id',''))
        $('#chat').animate({ scrollTop: $(document).height() }, 1000);
    }else{
        $('#chat .chat_box').prepend($(messageTemplate).removeClass('d-none').attr('id',''))
    }
    
}

function selectChat(chatSummary){
    var tryNum = 5;
    while(!$(chatSummary).hasClass('chat-summary') && tryNum > 0){
        chatSummary = $(chatSummary).parent();
        tryNum--;
    }   
    $('.chat_box').html('')
    $('.chat-summary').removeClass('selected');
    $(chatSummary).addClass('selected')
    $('#chat-header').html($(chatSummary).attr('chat-data-name'))
    $('#chat-header').attr('chat-data-id',$(chatSummary).attr('chat-data-id'))
    $('#chat-header').attr('chat-data-name',$(chatSummary).attr('chat-data-name'))
    $('#chat-header').attr('chat-data-avatar',$(chatSummary).attr('chat-data-avatar'))
    getMessages();
    $('#chat').animate({ scrollTop: $(document).height() }, 1000);
}

function getMessages(){
    var endpoint= "/connection/"+$('#chat-header').attr('chat-data-id')+'/messages'
    var methodVal = "GET"
    $.ajax({
        url: getCoreAPI()+endpoint,
        method: methodVal,
        dataType : 'json',
        contentType: 'application/json',
        success:function(data){
            data.map((message)=>{
                postMessage(message,'PREVIOUS')
            })
            
            //setTimeout(window.location.reload(),3000)
        },
        error:function(error){
            console.error('Something went wrong, the chat request was not applied.')
            //setTimeout(window.location.reload(),3000)
        }
    });
}

function requestConnections(callback){
    var labels = $('#labels-section').find('.btn-outline-dark')
    var holonA = {
        'id':$('#profile-session').attr('user-data-id'),
        'name':$('#profile-session').attr('user-data-dname')
    }
    labels.map((label)=> {
        var holonB ={
            'id':$(labels[label]).attr('data-id'),
            'name':$(labels[label]).attr('data-name')
        }
        var name ="";
        if(holonA.name.localeCompare(holonB.name)){
            name='Communication'+' '+holonA.name+'/'+holonB.name;
        }else{
            name='Communication'+' '+holonB.name+'/'+holonA.name;
        }
        createConnection(name,holonA,holonB,'Communication',callback)
        $(".form-check-input[data-id='"+$(labels[label]).attr('data-id')+"']").prop('checked',false);
        $(labels[label]).remove()        
    })
    
}

export default Messages;