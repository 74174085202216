import { request } from "adapter/ApiProvider";
import axios from "axios";
import { getCoreAPI } from "shared/components/common/Util";
import ApiOperation from "adapter/ApiOperation";

const Factories = {
    getAddress: async (code) => {
        var url = `${getCoreAPI()}/address/${code}`;

        return await axios.get(url).then(res => res.data);
    },
    getMenuByRole: async () => {
        var url = `${getCoreAPI()}/management/menu-master/roles`;
        return await request({url});
    },

    getFunctionByRole: () => {
        var url = `${getCoreAPI()}/management/menu-master/functions`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
};

export default Factories;