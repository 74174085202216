import { Redirect } from 'react-router-dom';
import EstimatedFarePage from "pages/estimated-fare/EstimatedFarePage";
// import Configuration from "pages/settings/Configuration";
// import Accounts from "pages/settings/AccountSettingsPage";
import RouterPath from "./RouterPath";
import HomeLayout from 'shared/components/layout/HomeLayout';
import Home from "pages/home/Home";
// import CommonInput from 'pages/CommonInput';
// import ServiceIntroduction from 'pages/System/ServiceIntroduction';
import { AdminGuard } from './guards/AdminGuard';
import ResetPasswordPage from 'pages/user/id-password-reset/ResetPasswordPage';
// import AccountSettings from 'pages/settings/AccountSettings';
import { GuestGuard } from './guards/GuestGuard';
import WarehouseInPage from 'pages/warehouse/mobile/WarehouseInPage';
import MobileLayout from 'shared/components/layout/mobile/MobileLayout';
import WarehouseOutPage from 'pages/warehouse/mobile/WarehouseOutPage';
import DashboardPage from "pages/dashboard/DashboardPage";
import React from 'react';

const MobileRoutes = [
    {
        layout: MobileLayout,
        routes: [
            {
                id: "PORTAL_WAREHOUSE_IN_PARAMS",
                component: <WarehouseInPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WAREHOUSE_IN",
                component: <WarehouseInPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WAREHOUSE_OUT_PARAMS",
                component: <WarehouseOutPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WAREHOUSE_OUT",
                component: <WarehouseOutPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_DASHBOARD",
                component: <DashboardPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
        ]
    },
    {
        layout: HomeLayout,
        routes: [
            {
                id: "RESET_PASSWORD",
                component: <ResetPasswordPage />,
                guards: [GuestGuard],
                fallback: () => <Redirect to={RouterPath.PORTAL_DASHBOARD} />
            },
            {
                id: "ESTIMATED_FARE",
                component: <EstimatedFarePage />,
                guards: [GuestGuard],
                fallback: () => <Redirect to={RouterPath.PORTAL_DASHBOARD} />
            },
            {
                id: "HOME",
                component: <Home />,
                guards: [GuestGuard],
                fallback: () => <Redirect to={RouterPath.PORTAL_DASHBOARD} />
            },
        ]
    },
];

export default MobileRoutes