import ApiOperation from 'adapter/ApiOperation';
import axios from 'axios';

const factory = {
    getTermOfUse: (payload) => {
        var url = `/rest/api/v1/master/policy/${payload?.key}`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        });
    },
};

export default factory;
