import actionCargo from "./action";

const initialState = {
}

const myReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return {
                ...state
            }
    }
}

export default myReducer;