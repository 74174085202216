import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import Actions from "./action";
import Factories from "./factory";
import { getToastError, getToast } from "utils/Utils";

function* getHolonTemplate() {
    yield takeLatest(Actions.GET_HOLON_TEMPLATE, function* (payload) {
        const { holonType } = payload;
        try {
            const data = yield call(() => Factories.getHolonTemplate(holonType));
            if (data?.data) {
                yield put({
                    type: Actions.GET_HOLON_TEMPLATE_RESPONSE,
                    payload: data?.data
                })
                payload?.callback && payload?.callback(data?.data)
            }
        } catch (error) {
            console.log("xxx Get holon saga error: ", error);
        }
    });
}

function* getHolonTemplatesByListTemplateId() {
    yield takeEvery(Actions.GET_HOLON_TEMPLATES_BY_TEMPLATE_IDS, function* (payload) {
        try {
            const response = yield call(() => Factories.getHolonTemplatesByListTemplateId(payload?.payload));

            if (response?.data) {
                yield put({
                    type: Actions.GET_HOLON_TEMPLATES_BY_TEMPLATE_IDS_SUCCESS,
                    payload: response?.data
                })
                payload?.callback && payload?.callback(response?.data)
            }
        } catch (error) {
            console.log("xxx Get holon saga error: ", error);
        }
    });
}

function* getHolonTemplateByCode() {
    yield takeEvery(Actions.GET_HOLON_TEMPLATE_BY_CODE, function* (payload) {
        const { code, callback } = payload;
        try {
            const { data } = yield call(() => Factories.getHolonTemplateByCode(code));
            if (callback) {
                callback(data)
            }
        } catch (res) {
            const { data: { error } } = res?.response;

            getToastError(error?.detail)
        }
    });
}

function* getCompanyById() {
    yield takeEvery(Actions.GET_COMPANY, function* (payload) {
        const { companyId, callback, fallback } = payload;
        try {
            const { data } = yield call(() => Factories.getCompanyById(companyId));
            if (callback) {
                callback(data)
            }
        } catch (res) {
            fallback && fallback();
            const { data: { error } } = res?.response;
            getToastError(error?.detail)
        }
    });
}

function* updateCompany() {
    yield takeEvery(Actions.UPDATE_COMPANY, function* (payload) {
        const { formData, callback, fallback } = payload;
        try {
            const response = yield call(() => Factories.updateCompany(formData));
            if (response?.data) {
                callback && callback(response);
            } else {
                fallback && fallback(response?.error?.detail)
                yield put({
                    type: Actions.UPDATE_COMPANY_FAILURE,
                    payload: null,
                })
            }
        } catch (res) {
            const { data: { error } } = res?.response;
            fallback && fallback(error?.detail)
            yield put({
                type: Actions.UPDATE_COMPANY_FAILURE,
                payload: null,
            })
        }
    });
}

function* connectEmployee() {
    yield takeEvery(Actions.CONNECT_EMPLOYEE, function* (payload) {
        const { formData, companyId, callback, fallback } = payload;
        try {
            const res = yield call(() => Factories.updateConnectEmployee(formData, companyId));
            if (res.data) {
                callback && callback()
            }
            else {
                fallback && fallback(res.error?.detail)
            }
        } catch (res) {
            const { data: { error } } = res?.response;
            fallback && fallback(error?.detail)
            yield put({
                type: Actions.CONNECT_EMPLOYEE_FAILURE,
                payload: error?.detail,
            })
        }
    });
}

function* removeConnection() {
    yield takeEvery(Actions.REMOVE_CONNECTION, function* (payload) {
        const { formData, callback, fallback } = payload;
        try {
            const res = yield call(() => Factories.removeConnection(formData));
            if (res.data) {
                callback && callback()
            }
            else {
                fallback && fallback(res.error?.detail)
            }
        } catch (res) {
            const { data: { error } } = res?.response;
            fallback && fallback()
            yield put({
                type: Actions.REMOVE_CONNECTION_FAILURE,
                payload: error?.detail,
            })
        }
    });
}

function* deleteInstanceAndRemoveConnection() {
    yield takeEvery(Actions.DELETE_INSTANCE_REMOVE_CONNECTION, function* (payload) {
        const { formData, callback, fallback } = payload;
        try {
            const res = yield call(() => Factories.deleteInstanceAndRemoveConnection(formData));
            if (res.data) {
                callback && callback()
            }
            else {
                fallback && fallback(res.error?.detail)
            }
        } catch (res) {
            const { data: { error } } = res?.response;
            fallback && fallback()
            yield put({
                type: Actions.DELETE_INSTANCE_REMOVE_CONNECTION_FAILURE,
                payload: error?.detail,
            })
        }
    });
}

function* getTemplatesInUserByMasterCodes() {
    yield takeEvery(Actions.GET_HOLON_TEMPLATES_IN_USER_BY_MASTER_CODES, function* (payload) {
        try {
            const templateIdOfMasterCode = yield call(() => Factories.getTemplateIdsByCodes(payload?.payload?.masterCode));
            const templates = payload?.payload?.user?.connections?.map(connection => connection?.holons?.[0]?.templateId);
            templates.push(payload?.payload?.user?.templateId);

            const templateIds = [];
            if (templateIdOfMasterCode?.data) {
                templates.forEach((template) => {
                    if (templateIdOfMasterCode?.data?.indexOf(template) > -1) {
                        templateIds.push(template);
                    }
                })
            }
            if (templateIds.length > 0) {
                yield put({
                    type: Actions.GET_HOLON_TEMPLATES_BY_TEMPLATE_IDS,
                    payload: templateIds.join(','),
                    callback: payload?.callback,
                })
            }
        } catch (res) {
            const { data: { error } } = res?.response;
        }
    });
}

function* getHolonTemplatesByMasterCodes() {
    yield takeEvery(Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES, function* (payload) {
        try {
            const response = yield call(() => Factories.getHolonTemplatesByMasterCodes(payload?.payload));

            if (response?.data) {
                yield put({
                    type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES_SUCCESS,
                    payload: response?.data
                })
                payload?.callback && payload?.callback(response?.data)
            }
        } catch (res) {
            const { data: { error } } = res?.response;
            payload?.fallback && payload?.fallback(error)
        }
    });
}

function* getTemplateIdsByMasterCode() {
    yield takeEvery(Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES, function* (payload) {
        try {
            const response = yield call(() => Factories.getTemplateIdsByCodes(payload?.payload));

            if (response?.data) {
                yield put({
                    type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES_SUCCESS,
                    payload: response?.data
                })
                payload?.callback && payload?.callback(response?.data)
            }
        } catch (res) {
            const { data: { error } } = res?.response;

            getToastError(error?.detail)
        }
    });
}

function* getHolonTemplatesNoAttributesByMasterCodes() {
    yield takeEvery(Actions.GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES, function* (payload) {
        try {
            const response = yield call(() => Factories.getHolonTemplatesNoAttributesByMasterCodes(payload?.payload));

            if (response?.data) {
                yield put({
                    type: Actions.GET_HOLON_TEMPLATES_NO_ATTRIBUTES_BY_MASTER_CODES_SUCCESS,
                    payload: response?.data
                })
                payload?.callback && payload?.callback(response?.data)
            }
        } catch (res) {
            const { data: { error } } = res?.response;
        }
    });
}


function* getHolonInstanceByAttributeValue() {
    yield takeLatest(Actions.GET_HOLON_INSTANCE_BY_ATTRIBUTE_VALUE, function* (payload) {
        const {value, templateId, callback, fallback} = payload;
        try {
            const response = yield call(() => Factories.getHolonInstanceByAttributeValue(value, templateId));

            if ('data' in response) {
                callback(response?.data)
            }
            if (response?.error) {
                fallback()
            }
            
        } catch (error) {
            fallback(null);
        }
    });
}

function* getHolonMasterByCode() {
    yield takeLatest(Actions.GET_HOLON_MASTER_BY_CODE, function* (payload) {
        const {code, callback, fallback} = payload;
        try {
            const response = yield call(() => Factories.getHolonMasterByCode(code));

            if (response?.data) {
                yield put({
                    type: Actions.GET_HOLON_MASTER_BY_CODE_SUCCESS,
                    payload: response?.data
                })
            }

            if ('data' in response) {
                callback && callback(response?.data)
            }
            if (response?.error) {
                fallback && fallback()
            }
            
        } catch (error) {
            fallback && fallback(null);
        }
    });
}

function* getOutsourceCompanyClientConnection() {
    yield takeLatest(Actions.GET_OUTSOURCE_COMPANY_CLIENT_CONNECTION, function* (payload) {
        const {callback, fallback} = payload;
        try {
            const response = yield call(() => Factories.getOutsourceCompanyClientConnection());
            if ('data' in response) {
                callback(response?.data)
            }
            if (response?.error) {
                fallback()
            }
            
        } catch (error) {
            fallback(null);
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getHolonTemplate),
        fork(getHolonTemplatesByListTemplateId),
        fork(getHolonTemplateByCode),
        fork(getCompanyById),
        fork(updateCompany),
        fork(getTemplatesInUserByMasterCodes),
        fork(connectEmployee),
        fork(getHolonTemplatesByMasterCodes),
        fork(getHolonTemplatesNoAttributesByMasterCodes),
        fork(getTemplateIdsByMasterCode),
        fork(getHolonInstanceByAttributeValue),
        fork(getHolonMasterByCode),
        fork(getOutsourceCompanyClientConnection),
        fork(removeConnection),
        fork(deleteInstanceAndRemoveConnection),
    ]);
}