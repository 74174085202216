import React from 'react';
import styles from "./Box.module.scss";
import IconChevronDown from 'assets/icon/IconChevronDown';
import { useState } from 'react';
import IconChevronUp from 'assets/icon/IconChevronUp';
import PropsType from 'prop-types';
function BoxHeader(props) {
    const [open, setOpen] = useState(true);
    const handleOpenSection = () => {
        setOpen(!open);
        let _element = document.querySelectorAll(`#${props?.id}`);
        if (_element?.length > 0) {
            if (open) {
                _element[0].style.display = "none";
            } else {
                _element[0].style.display = "flex";
            }
        }
    }
    return (
        <div className={styles["BoxHeader"]} style={{borderRadius: open ? '10px 10px 0px 0px' : '10px'}}>
            {props?.children}
            {
                props?.id && <span onClick={handleOpenSection}>
                    {
                        !open ? <IconChevronDown /> : <IconChevronUp />
                    }
                </span>
            }
        </div>
    )
}

BoxHeader.propTypes = {
    id: PropsType.string,
}
export default BoxHeader;