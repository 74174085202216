import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import RouterPath from 'router/RouterPath';

function CustomViewport(props) {
    const MOBILE_VIEW = [RouterPath.PORTAL_WAREHOUSE_IN, RouterPath.PORTAL_WAREHOUSE_OUT, RouterPath.PORTAL_DASHBOARD, RouterPath.HOME]

    let location = useLocation();
    useEffect(() => {
        let route = location.pathname;
        let element = document.querySelector(`meta[name=viewport]`);
        var matching = MOBILE_VIEW.filter(x => route.includes(x));
        if (element) {
            if (matching.length > 0 && isMobile) {
                element.setAttribute("content", 'initial-scale=1');
            } else {
                element.setAttribute("content", 'initial-scale=1, width=1440px');
            }
        }

        return () => {

        }
    }, [isMobile, location?.pathname])

    return <></>
}
export default CustomViewport;