import React from 'react';

function IconLeftArrow({ color = 'white' }) {
    return (
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 13L2 7L9 1" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>

    );
}

export default IconLeftArrow;
