import React, { useEffect, useState } from 'react'
import classes from './HolonTemplateContainer.module.scss';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination';
import IconSearch from 'assets/icon/IconSearch';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE, EVENT_SHOW_POPUP_DETAIL_HOLON_TEMPLATE, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE } from 'utils/EventRegister';
import { useDispatch, useSelector } from 'react-redux';
import actionHolonTemplate from 'redux/holon-template/action';
import Constants from 'utils/Constants';
import useRouter from 'hooks/use-router';
import moment from 'moment';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import IconMinusAccordionFilter from 'assets/icon/IconMinusAccordionFilter';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import { convertDate } from 'utils/Utils';

function HolonTemplateContainer() {
    const { t } = useTrans();
    const methods = useForm();
    const dispatch = useDispatch();
    const router = useRouter()
    const { holonTemplate, loading: {
        loadingGetTemplate
    } } = useSelector((state) => state.holonTemplate);
    const [category, setCategory] = useState([])
    const { categories } = useSelector(state => state?.holonTemplate);

    const OptionStatus = [
        {
            value: "1",
            key: "1",
            label: t("Holon_Template.valid")
        },
        {
            value: "0",
            key: "0",
            label: t("Holon_Template.invalid")
        },
    ]

    useEffect(() => {
        if (categories) {
            setCategory(Object.keys(categories)?.map(key => {
                return {
                    value: key,
                    key: key,
                    label: categories[key]
                }
            }));
        }
    }, [categories])

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = [
            {
                elementType: Constants.ELEMENT_TYPE.SELECT.VALUE,
                key: 'category',
                label: t("Holon_Template.category"),
                category: router.get(Constants.QueryParams.CATEGORY.VALUE) || "",
                options: category
            },
            {
                elementType: Constants.ELEMENT_TYPE.SELECT.VALUE,
                key: 'status',
                label: t("Holon_Template.status"),
                status: router.get(Constants.QueryParams.STATUS.VALUE) || "",
                options: OptionStatus
            },
            {
                elementType: Constants.VIEW_SEARCH.ROW.VALUE,
                key: 'date',
                label: t("Holon_Template.date"),
                component: [
                    {
                        elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                        key: 'from',
                        label: t("Holon_Template.from"),
                        from: router.get(Constants.QueryParams.CREATED_DATE_FROM.VALUE) || null,
                    },
                    {
                        elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                        key: 'to',
                        label: t("Holon_Template.to"),
                        to: router.get(Constants.QueryParams.CREATED_DATE_TO.VALUE) || null,
                    }
                ]
            }
        ]
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                formArray: false,
                callback: (_props) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.CATEGORY.VALUE]: _props?.category,
                            [Constants.QueryParams.STATUS.VALUE]: _props?.status,
                            [Constants.QueryParams.CREATED_DATE_FROM.VALUE]: _props?.from,
                            [Constants.QueryParams.CREATED_DATE_TO.VALUE]: _props?.to,
                        }
                    })
                }
            }
        })
    }

    const handleOpenEdit = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_DETAIL_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                title: t("Holon_Template.holon_template_detail"),
                holonTemplateId: id,
                callback: () => {

                }
            }
        })
    }
    let dataHeadCell = [
        {
            label: t('faq_management.detail'),
            field: "detail",
            maxWidth: 80,
            width: 80,
            minWidth: 80,
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            alignHeader: 'left',
            align: 'left',
            component: (_props) => {
                return <ButtonLink onClick={(e) => {
                    e.stopPropagation();
                    handleOpenEdit(_props?.data?.id)
                }} className={classes['link-detail']}>
                    {t("faq_management.detail")}
                </ButtonLink>
            }
        },
        {
            label: t('Holon_Template.category'),
            field: "category",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (props) => {
                return category?.find(x => x?.key == props?.data?.category)?.label ?? props?.data?.category
            }
        },
        {
            label: t('Holon_Template.sample_name'),
            field: "name",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            alignHeader: 'left'
        },
        {
            label: t('Holon_Template.status'),
            field: "status",
            minWidth: '120px',
            width: '120px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (props) => {
                return <div className={classes['Custom-Row-CheckBox']}>
                    {props?.data?.status == Constants.STATUS_CATEGORY.PUBLIC.VALUE ?
                        t("Holon_Template.valid")
                        : t("Holon_Template.invalid")
                    }
                </div>
            }

        },
        {
            label: t('Holon_Template.created'),
            field: "createdAt",
            minWidth: '150px',
            width: '150px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            sort: true,
            component: (props) => {
                return props?.children ? moment(props?.children).format(Constants.FORMAT_DATE_TIME.DATE) : ""
            }
        },
        {
            label: t('Holon_Template.updateDayMonth'),
            field: "updatedAt",
            minWidth: '150px',
            width: '150px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            sort: true,
            component: (props) => {
                return props?.children ? moment(props?.children).format(Constants.FORMAT_DATE_TIME.DATE) : ""
            }
        },
        {
            label: t('Holon_Template.explanation'),
            field: "note",
            minWidth: '170px',
            width: '170px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
        }
    ];

    const handleCreate = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE,
            payload: {
                title: t("Holon_Template.create_holon_template"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT,
                        payload: {
                            ...router.getAll()
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT,
            payload: {
                ...router.getAll()
            }
        })
    }, [
        router.get(Constants.QueryParams.LIMIT.VALUE),
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.SEARCH.VALUE),
        router.get(Constants.QueryParams.CATEGORY.VALUE),
        router.get(Constants.QueryParams.STATUS.VALUE),
        router.get(Constants.QueryParams.CREATED_DATE_FROM.VALUE),
        router.get(Constants.QueryParams.CREATED_DATE_TO.VALUE),
        router.get(Constants.QueryParams.SORT.VALUE),
        router.get(Constants.QueryParams.TYPE_SORT.VALUE),
    ])

    const handleSubmit = (data) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.FILTER.VALUE]: router.get(Constants.QueryParams.FILTER.VALUE),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.SEARCH.VALUE]: data?.search?.trim(),
            }
        })
    }


    useEffect(() => {
        if (router.get(Constants.QueryParams.SEARCH.VALUE)) {
            methods.setValue("search", router.get(Constants.QueryParams.SEARCH.VALUE))
        } else {
            methods.setValue("search", "")
        }
    }, [
        router.get(Constants.QueryParams.SEARCH.VALUE),
    ])

    const handleRemoveRouter = (value) => {
        let params = { ...router.getAll() };
        if (value == 'date') {
            delete params[Constants.QueryParams.CREATED_DATE_FROM.VALUE]
            delete params[Constants.QueryParams.CREATED_DATE_TO.VALUE]
        }

        if (value == 'status') {
            delete params[Constants.QueryParams.STATUS.VALUE]
        }

        if (value == 'category') {
            delete params[Constants.QueryParams.CATEGORY.VALUE]
        }

        router.replace({
            params: {
                ...params
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_MASTER_CATEGORY_HOLON_TEMPLATE,
            payload: {}
        })

        dispatch({
            type: actionHolonTemplate.GET_MASTER_HOLON_TEMPLATE,
            payload: {}
        })

        dispatch({
            type: actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT,
        })
    }, [])

    const onChangePage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e,
            },
        });
    };

    const onChangeSortColumn = (data) => {
        let params = { ...router.getAll() }
        if (data?.type) {
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1,
                    [Constants.QueryParams.SORT.VALUE]: data?.field,
                    [Constants.QueryParams.TYPE_SORT.VALUE]: data?.type,
                }
            })
        }

        if (!data?.type) {
            delete params[Constants.QueryParams.SORT.VALUE];
            delete params[Constants.QueryParams.TYPE_SORT.VALUE];
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1
                }
            })
        }
    }

    const dataSort = (router) => {
        if (router[Constants.QueryParams.SORT.VALUE]) {
            return {
                field: router[Constants.QueryParams.SORT.VALUE],
                type: router[Constants.QueryParams.TYPE_SORT.VALUE]
            }
        } else {
            return {
                field: null,
                type: null
            }
        }
    }

    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            },
        });
    }

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_ALL_HOLON_TEMPLATE,
            payload: {
                isAll: true
            }
        })
    }, [])
    return (
        <ContentLayout>
            <Breadcrumb>
                {t("Holon_Template.holon_template")}
            </Breadcrumb>
            <div className={classes['HolonTemplate']}>
                <div className={classes['Custom-Search']}>
                    <CustomFormProvider {...methods}>
                        <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                            <FormSearch className={classes['custom-form-search']}>
                                <FormGroup>
                                    <FormItem >
                                        <FormInput
                                            fieldName='search'
                                            background='white'
                                            placeholder={t("search_by_word")}
                                            iconSearch={<IconSearch fontSize={14} />}
                                            isSearch
                                        />
                                    </FormItem>
                                    {
                                        (
                                            router.get(Constants.QueryParams.CREATED_DATE_FROM.VALUE) || router.get(Constants.QueryParams.CREATED_DATE_TO.VALUE)
                                        ) &&
                                        <FormItem>
                                            <ButtonFilter
                                                onClick={() => handleRemoveRouter('date')}
                                                color='white' size='large'
                                                title={`${t("Holon_Template.created")}：${convertDate(router.get(Constants.QueryParams.CREATED_DATE_FROM.VALUE))} ${router.get(Constants.QueryParams.CREATED_DATE_FROM.VALUE) && router.get(Constants.QueryParams.CREATED_DATE_TO.VALUE) ? '〜' : ''} ${convertDate(router.get(Constants.QueryParams.CREATED_DATE_TO.VALUE))} `}
                                                endIcon={<IconMinusAccordionFilter />} />
                                        </FormItem>
                                    }
                                    {
                                        router.get(Constants.QueryParams.STATUS.VALUE) && <FormItem>
                                            <ButtonFilter onClick={() => handleRemoveRouter('status')} color='white' size='large' title={`${t("Holon_Template.status")}：${router.get(Constants.QueryParams.STATUS.VALUE) == 1 ? t("Holon_Template.valid") : t("Holon_Template.invalid")} `} endIcon={<IconMinusAccordionFilter />} />
                                        </FormItem>
                                    }
                                    {
                                        router.get(Constants.QueryParams.CATEGORY.VALUE) && <FormItem>
                                            <ButtonFilter onClick={() => handleRemoveRouter('category')} color='white' size='large' title={`${t("Holon_Template.category")}：${category?.find(x => x?.key == router.get(Constants.QueryParams.CATEGORY.VALUE))?.label ?? router.get(Constants.QueryParams.CATEGORY.VALUE)} `} endIcon={<IconMinusAccordionFilter />} />
                                        </FormItem>
                                    }
                                    <FormItem>
                                        <ButtonFilter onClick={handleOpenAddSearchCriteria} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                    </FormItem>
                                </FormGroup>
                                <ButtonAdd onClick={handleCreate} size='large' color='yellow' endIcon={<IconPlusWhite />} />
                            </FormSearch>
                            <button hidden type='submit' />
                        </form>
                    </CustomFormProvider>
                </div>
                <div className={classes['Custom-body']}>
                    <CustomTable dataSort={dataSort({ ...router.getAll() })} onChangeSortColumn={onChangeSortColumn} loading={loadingGetTemplate} data={holonTemplate?.records ?? []} headCell={dataHeadCell} />
                    <div className={classes['custom-pagination']}>
                        <CustomPagination
                            isRowPerpage
                            onChangeRowPerpage={onChangeRowPerpage}
                            limit={router.get(Constants.QueryParams.LIMIT.VALUE)}
                            onChange={onChangePage} total={holonTemplate?.total} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default HolonTemplateContainer