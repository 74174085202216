import useRouter from 'hooks/use-router';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import RouterPath from './RouterPath';
import { matchPath } from 'react-router';

function DefaultRoute (props) {
    const { routes } = props
    let location = useLocation();
    let router = useRouter();
    useEffect(() => {
        let valid = false;
        routes.forEach(x => {
            x.routes.forEach(r=>{
                var check = matchPath(location.pathname, { path: r.path, strict: true })
                if(check != null && check?.isExact){
                    valid = true;
                    return;
                }
            });
            if(valid){
                return
            }
        });
        if(!valid){
            router.push({
                pathname: RouterPath.HOME
            })
        }
    }, [location.pathname]);

    return (
        <></>
    )
}
export default DefaultRoute;