import React from 'react'
import classes from './WrapContentBody.module.scss';
import PropTypes from 'prop-types';
import Constants from 'utils/Constants';

function BoxContentPopup(props) {
    return (
        <div style={{
            borderRadius: Constants.COMMON_POPUP.BORDER_RADIUS,
            width: props.width ?? Constants.COMMON_POPUP.CONTENT_WIDTH,
            maxWidth: props.maxWidth,
            minWidth: props.minWidth ?? Constants.COMMON_POPUP.CONTENT_WIDTH,
        }} className={classes['BoxContent']}>
            {props?.children}
        </div>
    )
}

BoxContentPopup.propTypes = {
    borderRadius: PropTypes.string,
    width: PropTypes.string,
}
export default BoxContentPopup