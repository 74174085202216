import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionWareHouseIn from "./action";
import factory from "./factory";

function* getListWarehouseIn() {
    yield takeEvery(actionWareHouseIn.GET_WAREHOUSE_IN_LIST, function* (payload) {
        try {
            const response = yield call(() => factory.getListWarehouseIn(payload?.payload));
            const data = response?.records?.map(x => {
                x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                return x;
            });
            yield put({
                type: actionWareHouseIn.GET_WAREHOUSE_IN_LIST_SUCCESS,
                payload: {
                    ...response,
                    records: data
                }
            })
        } catch (error) {

        }
    })
}

function* getHolonTemplateWarehouse() {
    yield takeEvery(actionWareHouseIn.GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN, function* () {
        try {
            const response = yield call(() => factory.getHolonTemplateDetail());
            yield put({
                type: actionWareHouseIn.GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}

function* getDataInBarcodeWarehouseOut() {
    yield takeEvery(actionWareHouseIn.GET_WAREHOUSE_OUT_BARCODE, function* (payload) {
        try {
            const response = yield call(() => factory.getDataInBarcodeWarehouseOut(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data);
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
            }
        } catch (error) {

        }
    })
}

function* creationWarehouseIn() {
    yield takeEvery(actionWareHouseIn.CREATE_WAREHOUSE_IN, function* (payload) {
        try {
            const response = yield call(() => factory.creationWarehouseIn(payload?.payload));
            yield payload?.callback?.success && payload?.callback?.success(response, null);
        } catch (error) {
            yield payload?.callback?.success && payload?.callback?.success(null, error);
        }
    })
}

function* creationWarehouseOut() {
    yield takeEvery(actionWareHouseIn.CREATE_WAREHOUSE_OUT, function* (payload) {
        try {
            const response = yield call(() => factory.creationWarehouseOut(payload?.payload));
            yield payload?.callback?.success && payload?.callback?.success(response, null);
        } catch (error) {
            yield payload?.callback?.success && payload?.callback?.success(null, error);
        }
    })
}

function* getDataInBarcode() {
    yield takeEvery(actionWareHouseIn.GET_WAREHOUSE_IN_BARCODE, function* (payload) {
        try {
            const response = yield call(() => factory.getDataInBarcode(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data);
                yield put({
                    type: actionWareHouseIn.GET_WAREHOUSE_IN_BARCODE_SUCCESS,
                    payload: response?.data
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListWarehouseIn),
        fork(getHolonTemplateWarehouse),
        fork(creationWarehouseIn),
        fork(getDataInBarcode),
        fork(getDataInBarcodeWarehouseOut),
        fork(creationWarehouseOut)
    ])
}