import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import Validator from 'utils/Validator';
import CustomDatePicker from '../form-input/date-picker/DatePicker';
import MonthYearPicker from '../form-input/date-picker/MonthYearPicker';

const FormMonthYearPicker = (props) => {
    const {
        fieldName,
        validate = [],
        withPortal = false,
        isSearch = false,
        validationFE,
        isRegexLabel
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className="Input w-100">
            <Controller
                control={control}
                name={fieldName}
                rules={{ validate: Validator.genValidate(validate, fieldName) }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <>
                            <MonthYearPicker
                                value={value}
                                onChange={onChange}
                                errors={errors}
                                onBlur={onBlur}
                                inputRef={ref}
                                withPortal = {withPortal}
                                isSearch={isSearch}
                                name={fieldName}
                                validationFE={validationFE}
                                isRegexLabel={isRegexLabel}
                            />
                        </>
                    );
                }}
            />
        </div>
    );
};

FormMonthYearPicker.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isSearch: PropTypes.bool,
};
export default FormMonthYearPicker;
