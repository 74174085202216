import IconClose from 'assets/icon/IconClose';
import Constants from 'utils/Constants';
import React from 'react';

function CustomDialog(props) {
    const { payload, hiddenPopup, showHeader, footer, getType , type, ListTypeClickBackdrop} = props;
    let customStyle = {};

    const {
        headerSize = Constants.SIZE.DEFAULT,
    } = payload;

    const getStylesHeaderHeight = () => {
        switch (headerSize) {
            case Constants.SIZE.SMALL:
                return getStylesHeader('60px');
            case Constants.SIZE.MEDIUM:
                return getStylesHeader('90px');
            case Constants.SIZE.LARGE:
                return getStylesHeader('120px');
            default:
                return {};
        }
    };

    const getStylesHeader = (height) => {
        return {
            height: 'unset',
            minHeight: height
        }
    };

    if (showHeader) {
        customStyle['borderTopLeftRadius'] = 0;
        customStyle['borderTopRightRadius'] = 0;
    }

    let CAN_CLICK_BACKDROP = true;
    CAN_CLICK_BACKDROP = ListTypeClickBackdrop?.indexOf(type) != -1 ? false : true;

    return (
        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1050, display: 'flex', alignItems: 'center', justifyContent: 'center', }}
        >
            <div style={{ width: 'fit-content', margin: 'auto' }}>
                {
                    showHeader &&
                    <div className='modal-header' onClose={hiddenPopup} style={{ ...getStylesHeaderHeight(), padding: '0 65px', justifyContent: 'center', textAlign: 'center', borderTopLeftRadius: 10, borderTopRightRadius: 10, ...payload?.customStylesHeader }}>
                        <h1 style={{ margin: 'unset' }}>{payload?.title ?? payload?.data?.title}</h1>
                        <button onClick={hiddenPopup} style={{ cursor: 'pointer' }} aria-label="Close">
                            <IconClose fontSize={26} color='#fff' />
                        </button>
                    </div>
                }
                <div style={{ maxHeight: 'calc(100vh - 50px)', overflow: 'auto', ...payload?.customStyle, borderRadius: 10, ...customStyle }}>
                    {getType ?? <></>}
                </div>
                {
                    footer && <div>
                        {footer}
                    </div>
                }
            </div>
            <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#0000005e', zIndex: -1 }}
                onClick={() => {
                    CAN_CLICK_BACKDROP && hiddenPopup();
                    payload?.backdropCallback && payload.backdropCallback()
                }}
            ></div>
        </div>
    )
}
export default CustomDialog;