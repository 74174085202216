import React from "react";
import useTrans from "hooks/use-trans";
import Box from "shared/components/form-input/box/Box";
import classes from "./TrackingInfoRegistrationPopup.module.scss";
import FormInput from 'shared/components/custom-form/FormInput';
import FormDateTimePicker from 'shared/components/custom-form/FormDateTimePicker';
import BoxBody from 'shared/components/common/box/BoxBody';
import CustomBox from 'shared/components/common/box/Box';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import Validator from 'utils/Validator';
import ButtonAdd from "shared/components/form-input/button/ButtonAdd";
import Button from "shared/components/form-input/button/Button";

const TrackingInfoRegistrationForm = (props) => {
    const { appendHistory, fieldsHistory, removeHistory } = props
    const { t } = useTrans()
    
    const addNewRow = () => {
        appendHistory({
            timestamp: "",
            packagePlace: "",
            statusManual: "",
            idCreated: fieldsHistory?.length + 1
        })
    }

    return (
        <Box className={classes["freight-calculation-management"]}>
            <div className={classes["title-input-item"]}>
                <p>
                    {t('tracking_info_registration.title')}
                </p>
            </div>
            <CustomBox>
                <BoxBody padding={0}>
                    <div className={classes['custom-table-edit-estimate']}>
                        <table>
                            <thead>
                                <tr className={classes['form-header']} >
                                    <th className={''}>{t("tracking_info_registration.time_stamp")}</th>
                                    <th className={''}>{t("tracking_info_registration.actor_or_dealer")}</th>
                                    <th className={''}>{t("tracking_info_registration.content")}</th>
                                    <th style={{ width: ' 50px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fieldsHistory.map((item, key) => {
                                        return <tr key={item?.id} className={classes['form-body']} index={key}>
                                            <td>
                                                {/* <FormInput 
                                                    fieldName={`histories.${key}.timestamp`} 
                                                    validate={[Validator.required]} 
                                                    validationFE 
                                                    isRegexLabel={t("tracking_info_registration.time_stamp")} 
                                                /> */}
                                                <FormDateTimePicker
                                                    fieldName={`histories.${key}.timestamp`} 
                                                    validate={[Validator.required]} 
                                                    validationFE 
                                                    isRegexLabel={t("tracking_info_registration.time_stamp")}
                                                />
                                            </td>
                                            <td>
                                                <FormInput 
                                                    fieldName={`histories.${key}.packagePlace`} 
                                                    validate={[Validator.required]} 
                                                    validationFE 
                                                    isRegexLabel={t("tracking_info_registration.actor_or_dealer")} 
                                                />
                                            </td>
                                            <td>
                                                <FormInput 
                                                    fieldName={`histories.${key}.statusManual`} 
                                                    validate={[Validator.required]} 
                                                    validationFE 
                                                    isRegexLabel={t("tracking_info_registration.content")} 
                                                />
                                            </td>
                                            <td style={{ width: ' 50px' }} className={classes['button-action-remove']}>
                                                <Button type="button" color="transparent" className={classes['btn-cancel']} startIcon={<IconMinusAccordion />} onClick={() => removeHistory(key)}/>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <ButtonAdd size='large' onClick={addNewRow} color='yellow' startIcon={<IconPlusWhite />} />
                    </div>
                </BoxBody>
            </CustomBox>
        </Box>
    );
}

export default TrackingInfoRegistrationForm;
