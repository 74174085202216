import React, { useCallback, useEffect, useRef, useState } from 'react'
import classes from './ListHolonInstancePopup.module.scss';
import Button from 'shared/components/form-input/button/Button';
import IconPlus from 'assets/icon/IconPlus';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm, useFormContext } from 'react-hook-form';
import CustomPagination from '../../custom-pagination/CustomPagination';
import FormSearch from '../../form-search/FormSearch';
import FormItem from '../../form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE, EVENT_SHOW_POPUP_HOLON_TEMPLATE_SEARCH } from 'utils/EventRegister';
import moment from 'moment';
import FormGroup from '../../form-search/FormGroup';
import { useDispatch } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import Loading from 'shared/components/form-input/button/loading/Loading';
import { debounce } from 'lodash';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import WrapContentBody from '../custom-body/WrapContentBody';
import IconClose from 'assets/icon/IconClose';
import IconCheck from 'assets/icon/IconCheck';
import IconChecked from 'assets/icon/IconChecked';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import { convertRegexToOptions } from 'utils/Utils';
import { convertNumberToString } from 'utils/String';
import CommonText from '../../text/CommonText';
import { CommonRowTableHolonInstance } from 'shared/components/holon-instance/CommonDataFilter';

const HolonTemplateCommon = (props) => {
    const isMulti = props?.isMulti == false ? props?.isMulti : true;
    const { holonTemplateDetailWithHolonTemplate,
        holonInstanceWithHolonTemplate,
        onChangeSaveHolonInstance,
        saveHolonInstance,
        listDataHolon,
        loading,
        handleChangePageInstance, onSearchListTemplate, holonActive,
        handleAddHolonInstance,
        paramSearch,
        handleSearchAttributes,
        handleChangeRowPerPage,
        limit,
        sectionTab,
        listSelectAll
    } = props;

    const methods = useFormContext();
    const [listTemplateAll, setListTemplateAll] = useState([]);
    const [listTemplate, setListTemplate] = useState([]);
    const { t } = useTrans();
    const currentList = useRef(false);
    const [listChoiceTemplateAll, setListChoiceTemplateAll] = useState([]);

    let dataSelected = [];
    listSelectAll?.map(x => {
        dataSelected = dataSelected.concat(x?.listHolon);
    });

    useEffect(() => {
        if (!holonTemplateDetailWithHolonTemplate?.id) {
            setListChoiceTemplateAll([]);
            setListTemplate([])
        }

        return () => {
        }
    }, [holonTemplateDetailWithHolonTemplate])

    useEffect(() => {
        if (holonInstanceWithHolonTemplate?.records?.length > 0) {
            if (listDataHolon?.length > 0) {
                let newList = [...holonInstanceWithHolonTemplate?.records];
                setListTemplateAll(holonInstanceWithHolonTemplate?.records)
                let temp = [...newList];
                // [...newList]?.map(x => {
                //     if (listDataHolon?.findIndex(v => v?.id == x?.id) == -1) {
                //         temp.push(x);
                //     }
                // })
                setListTemplate(temp);
            } else {
                setListTemplate(holonInstanceWithHolonTemplate?.records);
                setListTemplateAll(holonInstanceWithHolonTemplate?.records);
            }
        } else {
            setListTemplate(holonInstanceWithHolonTemplate?.records);
            setListTemplateAll(holonInstanceWithHolonTemplate?.records);
        }
    }, [holonInstanceWithHolonTemplate?.records, listDataHolon]);

    useEffect(() => {
        if (listDataHolon?.length > 0) {
            setListChoiceTemplateAll(listDataHolon);
        } else {
            setListChoiceTemplateAll([])
        }
    }, [listDataHolon])

    const handleAdd = (item) => {
        let newList = [...listTemplate];
        // CHECK INDEX ITEM
        let indexRemove = newList?.findIndex(x => x?.id == item?.id);
        if (indexRemove != -1) {
            let newListChoiceTemplateAll = [...listChoiceTemplateAll]
            newListChoiceTemplateAll.push(item)
            setListChoiceTemplateAll(newListChoiceTemplateAll);
            onChangeSaveHolonInstance({
                id: holonTemplateDetailWithHolonTemplate?.id,
                listHolon: newListChoiceTemplateAll
            });
        }
    }

    const handleSingleAdd = (item) => {
        // let newList = [...listTemplate];
        // // CHECK INDEX ITEM
        // let indexReplace = newList?.findIndex(x => x?.id == item?.id);
        // if (indexReplace != -1) {

        // }
        let newListChoiceTemplateAll = [item]
        setListChoiceTemplateAll(newListChoiceTemplateAll);
        saveHolonInstance({
            id: holonTemplateDetailWithHolonTemplate?.id,
            listHolon: newListChoiceTemplateAll
        });
    }

    const handleRemove = (item) => {
        let newListChoiceTemplateAll = [...listChoiceTemplateAll];
        let indexRemove = newListChoiceTemplateAll?.findIndex(x => x?.id == item?.id);
        if (indexRemove != -1) {

            newListChoiceTemplateAll.splice(indexRemove, 1);
            setListChoiceTemplateAll(newListChoiceTemplateAll);
            onChangeSaveHolonInstance({
                id: holonTemplateDetailWithHolonTemplate?.id,
                listHolon: newListChoiceTemplateAll
            });
        }
    }

    const handleChangePaginationList = (e) => {
        handleChangePageInstance(e);
    }

    const searchHolonTemplate = useCallback(
        debounce(async (e) => {
            onSearchListTemplate(e, holonActive);
        }, 500),
        [holonActive],
    );

    const onChangeList = (e) => {
        currentList.current = true;
        searchHolonTemplate(e);
    }



    const checked = (data, id) => data?.findIndex(x => x?.id == id) != -1 ? true : false;

    const renderDataFilter = (string) => {
        let data = JSON.parse(string);
        data = Object.keys(data)?.map(key => {
            return {
                label: key,
                key: key,
                value: data[key],
            }
        });

        const handleRemoveRouter = (_key) => {
            let index = data?.findIndex(x => x?.key == _key);
            if (index != -1) {
                data?.splice(index, 1);
            }

            let object = {};
            data.forEach((element) => {
                object[`${element?.key}`] = element?.value;
            });

            let params = {
                ...paramSearch
            };
            if (Object.keys(object)?.length > 0) {
                params[Constants.QueryParams.ATTRIBUTES.VALUE] = JSON.stringify(object)
            } else {
                delete params[Constants.QueryParams.ATTRIBUTES.VALUE]
            }

            handleSearchAttributes(params)
        }

        const mapLabel = (key) => {
            return [...holonTemplateDetailWithHolonTemplate?.attributes]?.find(x => x?.key == key)?.label;
        }

        return data?.map((x, key) => {
            return <ButtonFilter key={key} onClick={() => handleRemoveRouter(x?.key)} color='white' size='large' title={`${mapLabel(x?.key)}：${x?.value}`} endIcon={<IconMinusAccordion />} />
        })
    }

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = holonTemplateDetailWithHolonTemplate?.attributes?.filter(x => x?.elementType != Constants.ELEMENT_TYPE.FILE.VALUE && x?.elementType != Constants.ELEMENT_TYPE.IMAGE.VALUE)?.map(x => {
            return {
                elementType: x?.elementType,
                options: convertRegexToOptions(x?.regex),
                label: x?.label,
                key: x?.key,
                id: x?.id,
                [x?.key]: paramSearch?.[Constants.QueryParams.ATTRIBUTES.VALUE] && JSON.parse(paramSearch?.[Constants.QueryParams.ATTRIBUTES.VALUE])[x?.key] ?
                    JSON.parse(paramSearch?.[Constants.QueryParams.ATTRIBUTES.VALUE])[x?.key] : null
            }
        });

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...paramSearch
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                callback: (_props) => {
                    let dataMain = [];
                    let dataSub = [];

                    _props?.map(x => {
                        if (x[x?.key]) {
                            let index = holonTemplateDetailWithHolonTemplate?.attributes?.findIndex(at => at?.id == x.id);
                            if (index != -1) {
                                dataMain.push(x);
                            } else {
                                dataSub.push(x);
                            }
                        }
                    })

                    let params = {
                        ...paramSearch
                    };
                    if (dataMain?.length > 0) {
                        params[Constants.QueryParams.ATTRIBUTES.VALUE] = JSON.stringify(dataMain?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur?.[cur?.key] }), {}))
                    }

                    handleSearchAttributes(params);
                },
                formArray: true
            }
        })
    }

    const onSubmit = (data) => {
        onSearchListTemplate(data?.Search, holonActive);
    }

    const onChangeRowPerPage = (e) => {
        handleChangeRowPerPage(e)
    }

    const renderValue = (attributes, attributeHeader) => {
        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
            return "*******";
        }
        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
            let options = convertRegexToOptions(attributeHeader?.regex);
            let valueOptions = attributes[attributeHeader?.key]?.value?.split(",");
            return valueOptions?.map((_opValue, _index) => {
                let index = options?.findIndex(item => item?.value == _opValue);
                if (index != -1) {
                    return `${options[index]?.label}${valueOptions[_index + 1] ? ', ' : ''}`
                }
            })
        }

        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
            return attributes[attributeHeader?.key]?.value ? moment(attributes[attributeHeader?.key]?.value).format(Constants.FORMAT_DATE_TIME.DATE) : ''
        }
        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
            return attributes[attributeHeader?.key]?.value ? moment(attributes[attributeHeader?.key]?.value).format(Constants.FORMAT_DATE_TIME.DATE_TIME) : ''
        }
        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.MONEY.VALUE) {
            return attributes[attributeHeader?.key]?.value ? convertNumberToString(attributes[attributeHeader?.key]?.value, ",") : ''
        }
        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.NUMBER.VALUE) {
            return attributes[attributeHeader?.key]?.value ? convertNumberToString(attributes[attributeHeader?.key]?.value, ",") : ''
        }
        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.PERCENT.VALUE) {
            return attributes[attributeHeader?.key]?.value ? `${attributes[attributeHeader?.key]?.value}%` : ''
        }
        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
            let _find = convertRegexToOptions(attributeHeader?.regex)?.find(_option => _option?.value == attributes[attributeHeader?.key]?.value);
            return _find?.label;
        }
        if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.RADIO.VALUE) {
            let _find = convertRegexToOptions(attributeHeader?.regex)?.find(_option => _option?.value == attributes[attributeHeader?.key]?.value);
            return _find?.label;
        }

        return attributes[attributeHeader?.key]?.value;
    }

    return <>
        <div className={classes['wrap-content']}>
            <div className={classes['selected-container']}>
                <div className={classes['list']}>
                    <div className={classes['content-list']}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <FormSearch className={classes['custom-form-search']}>
                                <FormGroup flexWrap="wrap">
                                    <FormItem minWidth={183} maxWidth="183px">
                                        <FormInput
                                            fieldName='Search'
                                            background='white'
                                            className="custom-input"
                                            placeholder={t("search_by_word")}
                                            iconSearch={<IconSearch fontSize={14} />}
                                            onChangeValue={(e) => {
                                                // onChangeList(e)
                                            }}
                                            isSearch
                                        />
                                    </FormItem>
                                    {
                                        paramSearch?.[Constants.QueryParams.ATTRIBUTES.VALUE] && renderDataFilter(paramSearch?.[Constants.QueryParams.ATTRIBUTES.VALUE])
                                    }
                                    <FormItem>
                                        <ButtonFilter disabled={holonTemplateDetailWithHolonTemplate?.attributes?.length > 0 ? false : true} onClick={handleOpenAddSearchCriteria} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                    </FormItem>
                                </FormGroup>
                                <>
                                    <Button color='yellow' type='submit' size='large' title={t('search')} />
                                    <Button color='yellow' onClick={() => {
                                        handleAddHolonInstance(holonActive);
                                    }} size='large' startIcon={<IconPlusWhite />} /></>
                            </FormSearch>
                        </form>
                        {sectionTab}
                        <div style={{
                            borderRadius: '10px 10px 0px 0px',
                            position: 'relative',
                        }}>
                            {
                                holonTemplateDetailWithHolonTemplate?.attributes?.length > 0 && loading ? <div className={classes['custom-loading']}>
                                    <Loading />
                                </div> : <></>
                            }
                            {
                                holonTemplateDetailWithHolonTemplate?.attributes?.length > 0 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className={classes['content-with-action']}></th>
                                                {
                                                    holonTemplateDetailWithHolonTemplate?.attributes?.filter(x => x?.elementType != Constants.ELEMENT_TYPE.IMAGE.VALUE
                                                        && x?.elementType != Constants.ELEMENT_TYPE.FILE.VALUE &&
                                                        x?.elementType != Constants.ELEMENT_TYPE.PASSWORD.VALUE)?.slice(0, 5)
                                                        ?.map((x, index) => {
                                                            return <th key={index} className={classes['content_with']}>
                                                                <div title={x?.label} className={classes['title-header']}>
                                                                    {x?.label}
                                                                </div>
                                                            </th>
                                                        })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !loading && listTemplate?.map((menu, index) => {
                                                    return <tr key={index}>
                                                        <td onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (checked(listChoiceTemplateAll, menu?.id)) {
                                                                handleRemove(menu);
                                                            } else {
                                                                if (isMulti) {
                                                                    handleAdd(menu)
                                                                } else {
                                                                    handleSingleAdd(menu)
                                                                }
                                                            }
                                                        }} className={classes['content-with-action']}>
                                                            {
                                                                checked(listChoiceTemplateAll, menu?.id) ? <IconChecked /> : <IconCheck />
                                                            }
                                                        </td>
                                                        {
                                                            holonTemplateDetailWithHolonTemplate?.attributes?.filter(x => x?.elementType != Constants.ELEMENT_TYPE.IMAGE.VALUE
                                                                && x?.elementType != Constants.ELEMENT_TYPE.FILE.VALUE
                                                                && x?.elementType != Constants.ELEMENT_TYPE.PASSWORD.VALUE)?.slice(0, 5)
                                                                ?.map((template, _index) => {
                                                                    return <td key={_index} className={classes['content_with']} >
                                                                        <div title={template?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE ? Constants.PASSWORD.VALUE : menu?.attributes[template?.key]?.value} className={classes['custom-content-table']}>
                                                                            <CommonText>
                                                                                {CommonRowTableHolonInstance({
                                                                                    attributeHeader: template,
                                                                                    attributes: menu?.attributes
                                                                                })}
                                                                            </CommonText>
                                                                        </div>
                                                                    </td>
                                                                })}
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    : <></>
                            }
                        </div>

                    </div>
                    <div className={classes['custom-pagination-popup']}>
                        <CustomPagination onChangeRowPerpage={onChangeRowPerPage} isRowPerpage={true} page={holonInstanceWithHolonTemplate?.page ?? 1} limit={limit} total={holonInstanceWithHolonTemplate?.total ?? 0} onChange={handleChangePaginationList} />
                    </div>
                </div>
                {/* <div className={classes['list']}>
                    <div className={classes['content-list']}>
                        <FormSearch className="custom-form-search">
                            <FormGroup>
                                <FormItem width="183px">
                                    <FormInput
                                        fieldName='Search_choice'
                                        background='white'
                                        className="custom-input"
                                        placeholder={t("search_by_word")}
                                        iconSearch={<IconSearch fontSize={14} />}
                                        onChangeValue={(e) => {
                                            onChangeListChoice(e)
                                        }}
                                        isSearch
                                    />
                                </FormItem>
                            </FormGroup>
                        </FormSearch>
                        <div style={{
                            borderRadius: '10px 10px 0px 0px',
                            position: 'relative',
                        }} className={classes['list-selected']}>
                            {
                                holonTemplateDetailWithHolonTemplate?.attributes?.length == 0 && loading ? <div className={classes['custom-loading']}>
                                    <Loading />
                                </div> : <></>
                            }
                            {
                                holonTemplateDetailWithHolonTemplate?.attributes?.length > 0 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                {
                                                    holonTemplateDetailWithHolonTemplate?.attributes?.slice(0, 3)?.map((x, index) => {
                                                        return <th key={index} className={classes['content-with']}>
                                                            <div title={x?.label} className={classes['title-header']}>
                                                                {x?.label}
                                                            </div>
                                                        </th>
                                                    })
                                                }
                                                <th className={classes['content-with-action']}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listChoice?.map((menu, key) => {
                                                    return <tr key={key}>
                                                        {
                                                            holonTemplateDetailWithHolonTemplate?.attributes?.slice(0, 3)?.map((template, _index) => {
                                                                return <td key={_index} className={classes['content-with']} >
                                                                    <div title={template?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE ? Constants.PASSWORD.VALUE : menu?.attributes[template?.key]?.value} className={classes['custom-content-table']}>{template?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE ? Constants.PASSWORD.VALUE : menu?.attributes[template?.key]?.value}</div>
                                                                </td>
                                                            })}
                                                        <td className={classes['action-remove']}>
                                                            <Button backgroundTransparent size='small' onClick={() => handleRemove(menu)} color='white' startIcon={<IconMinusAccordion color='currentColor' />} />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    : <></>
                            }
                        </div>
                    </div>
                    <div className={classes['custom-pagination-popup']}>
                        <CustomPagination page={paginationTemplateChoice} limit={10} total={listChoiceTemplateAll?.length ?? 0} onChange={handleChangePaginationListChoice} />
                    </div>
                </div> */}
            </div>
        </div>
    </>
}

function ListHolonInstancePopup(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const [listChoiceTemplateAll, setListChoiceTemplateAll] = useState([]);
    const [listHolonTemplate, setListHolonTemplate] = useState([]);
    const [holonTemplateDetailWithHolonTemplate, setHolonTemplateDetailWithHolonTemplate] = useState({});
    const [holonInstanceWithHolonTemplate, setHolonInstanceWithHolonTemplate] = useState({});
    const [loadingGetInstanceByHolonTemplate, setLoadingGetInstanceByHolonTemplate] = useState(false);
    const [holonActive, setHolonActive] = useState(null);
    const [paramSearch, setParamSearch] = useState(null);
    const [keyword, setKeyword] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Constants.PAGINATION.LIMIT);
    const { t } = useTrans();
    const dispatch = useDispatch();

    const handleOke = () => {
        let data = [];
        listChoiceTemplateAll?.map(x => {
            x?.listHolon?.map(_l => {
                data.push({
                    id: _l?.id,
                    name: _l?.name,
                    attributes: Object.keys(_l.attributes).map(x => {
                        return _l.attributes[x]
                    })
                })
            })
        })
        payload?.callback(data);
        showVisible()
    }

    const handleGetHolonInstance = ({ paramRqs }) => {

        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE,
            payload: {
                ...paramRqs
            },
            callback: (data) => {
                setHolonInstanceWithHolonTemplate(data);
                setLoadingGetInstanceByHolonTemplate(false);
            }
        })
    }

    useEffect(() => {
        if (payload?.ListHolonTemplate) {
            setHolonActive(payload?.ListHolonTemplate[0]);
            setLoadingGetInstanceByHolonTemplate(true);
            let paramRqs = {};
            if (payload[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]) {
                paramRqs[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE] = payload?.[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE];
            }
            if (payload[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]) {
                paramRqs[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE] = payload?.[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE];
            }
            if (payload[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]) {
                paramRqs[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE] = payload?.[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE];
            }
            if (payload?.ListHolonTemplate?.[0]) {
                paramRqs[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = payload?.ListHolonTemplate?.[0];
            }
            handleGetHolonInstance({ paramRqs })
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
                payload: payload?.ListHolonTemplate?.[0],
                callback: (data) => {
                    setHolonTemplateDetailWithHolonTemplate(data)
                }
            })
        }
    }, [
        payload?.ListHolonTemplate,
        payload[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE],
        payload[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE],
        payload[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]])

    const handleChangeTab = (x) => {
        methods.setValue("Search", "")
        setParamSearch(null)
        setKeyword(null);
        setHolonActive(x);
        setLoadingGetInstanceByHolonTemplate(true);

        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE,
            payload: {
                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: x,
                [Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]: payload?.[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE],
                [Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]: payload?.[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE],
                [Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]: payload?.[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE],
            },
            callback: (data) => {
                setHolonInstanceWithHolonTemplate(data);
                setLoadingGetInstanceByHolonTemplate(false)
            }
        })
        dispatch({
            type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
            payload: x,
            callback: (data) => {
                setHolonTemplateDetailWithHolonTemplate(data)
            }
        })
    }


    const handleSaveHolonInstance = (dataHolon) => {
        let data = [...listChoiceTemplateAll];
        let index = data?.findIndex(x => x?.id == dataHolon?.id);
        if (index != -1) {
            data[index].listHolon = dataHolon?.listHolon;
        } else {
            data.push(dataHolon);
        }

        setListChoiceTemplateAll(data);
    }

    const saveHolonInstance = (dataHolon) => {
        let data = [];
        data.push(dataHolon);
        setListChoiceTemplateAll(data);
    }


    useEffect(() => {
        if (payload?.value?.length > 0) {
            dispatch({
                type: actionsHolonInstance.GET_LIST_TEMPLATE_ID_WITH_INSTANCE,
                payload: payload?.value?.join(","),
                callback: (_data) => {
                    let dataGroup = _data?.reduce((rv, x) => {
                        const groupIndex = rv?.findIndex((a) => a.id == x?.templateId);
                        if (groupIndex != -1) {
                            rv[groupIndex].listHolon?.push({
                                id: x?.id,
                                name: x?.name,
                                attributes: x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {})
                            });
                        } else {
                            let data = {
                                id: x?.templateId,
                                listHolon: []
                            };

                            data.listHolon.push({
                                id: x?.id,
                                name: x?.name,
                                attributes: x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {})
                            });

                            rv?.push(data);
                        }
                        return rv;
                    }, []);
                    setListChoiceTemplateAll(dataGroup);
                }
            })
        }
    }, [payload?.value])

    useEffect(() => {
        if (payload?.ListHolonTemplate) {
            dispatch({
                type: actionsHolonInstance.GET_HOLON_TEMPLATE_WITH_IDS,
                payload: payload?.ListHolonTemplate?.join(","),
                callback: (_data) => {
                    setListHolonTemplate(_data);
                }
            })
        }
    }, [payload?.ListHolonTemplate])

    const handleChangePageInstance = (e) => {
        let params = {};
        setPage(e);
        if (keyword) {
            params[Constants.QueryParams.KEYWORD.VALUE] = keyword;
        }
        if (limit) {
            params[Constants.QueryParams.LIMIT.VALUE] = limit;
        }

        setLoadingGetInstanceByHolonTemplate(true);
        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE,
            payload: {
                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonActive,
                [Constants.QueryParams.OFFSET.VALUE]: e,
                [Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]: payload?.[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE],
                [Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]: payload?.[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE],
                [Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]: payload?.[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE],
                ...params
            },
            callback: (data) => {
                setHolonInstanceWithHolonTemplate(data);
                setLoadingGetInstanceByHolonTemplate(false);
            }
        })
    }
    const onSearchListTemplate = (e, holonActive) => {

        let params = {};

        if (limit) {
            params[Constants.QueryParams.LIMIT.VALUE] = limit;
        }

        setKeyword(e);
        setLoadingGetInstanceByHolonTemplate(true);
        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE,
            payload: {
                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonActive,
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.KEYWORD.VALUE]: e,
                [Constants.QueryParams.ATTRIBUTES.VALUE]: paramSearch?.[Constants.QueryParams.ATTRIBUTES.VALUE],
                [Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]: payload?.[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE],
                [Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]: payload?.[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE],
                [Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]: payload?.[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE],
                ...params
            },
            callback: (data) => {
                setLoadingGetInstanceByHolonTemplate(false);
                setHolonInstanceWithHolonTemplate(data)
            }
        })
    }

    const handleAddHolonInstance = (templateActive) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            newWindow: true,
            payload: {
                title: t("holon.create_holon_instance"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    let params = {};

                    if (keyword) {
                        params[Constants.QueryParams.KEYWORD.VALUE] = keyword;
                    }

                    if (limit) {
                        params[Constants.QueryParams.LIMIT.VALUE] = limit;
                    }

                    setLoadingGetInstanceByHolonTemplate(true);
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE,
                        payload: {
                            [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonActive,
                            [Constants.QueryParams.OFFSET.VALUE]: page,
                            [Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]: payload?.[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE],
                            [Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]: payload?.[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE],
                            [Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]: payload?.[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE],
                            ...params
                        },
                        callback: (data) => {
                            setLoadingGetInstanceByHolonTemplate(false);
                            setHolonInstanceWithHolonTemplate(data)
                        }
                    })
                },
                templateId: templateActive,
                isHolonTemplateName: true
            }
        })
    }

    useEffect(() => {
        return () => {

        }
    }, [])

    const handleSearchAttributes = (data) => {
        setParamSearch(data);
        setLoadingGetInstanceByHolonTemplate(true);

        let params = {};
        if (limit) {
            params[Constants.QueryParams.LIMIT.VALUE] = limit;
        }
        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE,
            payload: {
                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonActive,
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.KEYWORD.VALUE]: keyword,
                [Constants.QueryParams.ATTRIBUTES.VALUE]: data?.[Constants.QueryParams.ATTRIBUTES.VALUE],
                [Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]: payload?.[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE],
                [Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]: payload?.[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE],
                [Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]: payload?.[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE],
                ...params
            },
            callback: (data) => {
                setLoadingGetInstanceByHolonTemplate(false);
                setHolonInstanceWithHolonTemplate(data)
            }
        })
    }

    const handleChangeRowPerPage = (e) => {
        let params = {};
        setLimit(e);
        setPage(1);
        if (keyword) {
            params[Constants.QueryParams.KEYWORD.VALUE] = keyword;
        }
        if (e) {
            params[Constants.QueryParams.LIMIT.VALUE] = e;
        }
        setLoadingGetInstanceByHolonTemplate(true);
        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE,
            payload: {
                [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonActive,
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]: payload?.[Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE],
                [Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]: payload?.[Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE],
                [Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]: payload?.[Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE],
                ...params
            },
            callback: (data) => {
                setHolonInstanceWithHolonTemplate(data);
                setLoadingGetInstanceByHolonTemplate(false);
            }
        })
    }

    return (
        <div className={classes['container']}>
            <CustomFormProvider {...methods}>
                <WrapContentBody
                    showVisible={showVisible} title={payload?.title} isUseBtnBack={false} isUseBackground={false}
                    actionSectionBottom={<Button onClick={handleOke} title={t("save")} />}
                    useForm={false}
                    actionSectionTop={<div onClick={() => showVisible()} className={classes['icon']}>
                        <IconClose color="#929292" fontSize={20} />
                    </div>}
                >
                    <div className={classes['selected-holon-template']}>
                        <HolonTemplateCommon
                            holonTemplateDetailWithHolonTemplate={holonTemplateDetailWithHolonTemplate}
                            holonInstanceWithHolonTemplate={holonInstanceWithHolonTemplate}
                            value={payload?.value}
                            isMulti={payload?.isMulti}
                            onChangeSaveHolonInstance={handleSaveHolonInstance}
                            saveHolonInstance={saveHolonInstance}
                            listDataHolon={listChoiceTemplateAll?.find(x => x?.id == holonActive)?.listHolon}
                            loading={loadingGetInstanceByHolonTemplate}
                            handleChangePageInstance={handleChangePageInstance}
                            onSearchListTemplate={onSearchListTemplate}
                            holonActive={holonActive}
                            handleAddHolonInstance={handleAddHolonInstance}
                            paramSearch={paramSearch}
                            handleSearchAttributes={handleSearchAttributes}
                            handleChangeRowPerPage={handleChangeRowPerPage}
                            limit={limit}
                            listSelectAll={listChoiceTemplateAll}
                            sectionTab={<div className={classes['custom-tab-holon-template']}>
                                {listHolonTemplate?.map((x, key) => {
                                    return <div key={key} style={{
                                        pointerEvents: loadingGetInstanceByHolonTemplate ? 'none' : 'fill'
                                    }} onClick={() => {
                                        !loadingGetInstanceByHolonTemplate && handleChangeTab(x?.id)
                                    }} className={`${classes['custom-tab']} ${holonActive == x?.id ? classes['custom-tab-active'] : ''}`}>
                                        <span>{x?.name}</span>
                                        {
                                            listChoiceTemplateAll?.find(v => v?.id == x?.id)?.listHolon?.length > 0 ? <div className={classes['custom-tab-number']}>
                                                <p>{listChoiceTemplateAll?.find(v => v?.id == x?.id)?.listHolon?.length}</p>
                                            </div> : <></>
                                        }
                                    </div>
                                })}
                            </div>}
                        />
                    </div>
                </WrapContentBody>
            </CustomFormProvider>
        </div>
    )
}

export default ListHolonInstancePopup;