import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import factory from './factory';
import { actionMessage } from './action';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import { getToast } from 'utils/Utils';
import i18n from "i18nextInit"
import Constants from 'utils/Constants';

function* getListChannel() {
    yield takeEvery(
        actionMessage.REQUEST_SYNC_CHANNEL,
        function* (payload) {
            try {
                const response = yield call(() =>
                    factory.getListUser(payload?.payload),
                );
                if (!response?.error) {
                    yield put({
                        type: actionMessage.REQUEST_SYNC_CHANNEL_SUCCESS,
                        payload: response
                    })
                } else {
                    yield put({
                        type: actionMessage.REQUEST_SYNC_CHANNEL_FAILED,
                        payload: []
                    })
                }
                yield payload?.callback?.success && payload?.callback?.success(response)
            } catch (error) {
                yield put({
                    type: actionMessage.REQUEST_SYNC_CHANNEL_FAILED,
                    payload: []
                })
            }
        },
    );
}

function* loadMoreChannel() {
    yield takeEvery(
        actionMessage.LOAD_MORE_CHANNEL,
        function* (payload) {
            try {
                const response = yield call(() =>
                    factory.getListUser(payload?.payload),
                );

                yield put({
                    type: actionMessage.LOAD_MORE_CHANNEL_SUCCESS,
                    payload: response
                })
                yield payload?.callback && payload?.callback()
            } catch (error) {
                yield put({
                    type: actionMessage.LOAD_MORE_CHANNEL_FAILED,
                    payload: []
                })
            }
        },
    );
}

function* getMessage() {
    yield takeEvery(actionMessage.REQUEST_GET_MESSAGE, function* (payload) {
        try {
            const response = yield call(() => factory.getMessage(payload?.payload));
            if (response?.data) {
                yield put({
                    type: actionMessage.REQUEST_GET_MESSAGE_SUCCESS,
                    payload: response?.data,
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed()
                yield put({
                    type: actionMessage.REQUEST_GET_MESSAGE_FAILED,
                    payload: [],
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed()
            yield put({
                type: actionMessage.REQUEST_GET_MESSAGE_FAILED,
                payload: [],
            })
        }
    })
}

function* getFirstMessage() {
    yield takeEvery(actionMessage.REQUEST_GET_FIRST_MESSAGE, function* (payload) {
        try {
            const response = yield call(() => factory.getMessage(payload?.payload));
            if (response?.data) {
                yield put({
                    type: actionMessage.REQUEST_GET_FIRST_MESSAGE_SUCCESS,
                    payload: response?.data,
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                yield put({
                    type: actionMessage.REQUEST_GET_FIRST_MESSAGE_FAILED,
                    payload: [],
                })
            }
        } catch (error) {
            yield put({
                type: actionMessage.REQUEST_GET_FIRST_MESSAGE_FAILED,
                payload: [],
            })
        }
    })
}

function* getScrollMessage() {
    yield takeEvery(actionMessage.REQUEST_GET_SCROLL_MESSAGE, function* (payload) {
        try {
            const response = yield call(() => factory.getMessage(payload?.payload));
            if (response?.data) {
                yield put({
                    type: actionMessage.REQUEST_GET_SCROLL_MESSAGE_SUCCESS,
                    payload: response?.data,
                })
                yield payload?.callback?.success && payload?.callback?.success(response?.data)
            } else {
                yield put({
                    type: actionMessage.REQUEST_GET_SCROLL_MESSAGE_FAILED,
                    payload: [],
                })
            }
        } catch (error) {
            yield put({
                type: actionMessage.REQUEST_GET_SCROLL_MESSAGE_FAILED,
                payload: [],
            })
        }
    })
}

function* syncMessage() {
    yield takeEvery(actionMessage.REQUEST_SYNC_MESSAGE, function* (payload) {
        try {
            const response = yield call(() => factory.getMessage(payload?.payload));
            if (response?.data) {
                yield put({
                    type: actionMessage.REQUEST_SYNC_MESSAGE_SUCCESS,
                    payload: response?.data,
                })
                yield payload?.callback?.success && payload?.callback?.success();
            }
        } catch (error) {

        }
    })
}

function* sendMessage() {
    yield takeEvery(actionMessage.SEND_MESSAGE, function* (payload) {
        try {
            const response = yield call(() => factory.sendMessage(payload?.payload));
            if (response?.channelId) {
                if(response?.isSendByMail == Constants.STATUS_CHECKBOX.YES.VALUE){
                    getToast(i18n.t("I0003") )
                }
                yield payload?.callback?.success && payload?.callback?.success()
                yield put({
                    type: actionMessage.SEND_MESSAGE_SUCCESS,
                    payload: response,
                })
            }
        } catch (error) {

        }
    })
}
function* getUserList() {
    yield takeEvery(actionMessage.GET_SYNC_CHANNEL_LIST, function* (payload) {
        try {
            const response = yield call(() => factory.getUserList(payload?.payload));
            yield put({
                type: actionMessage.GET_SYNC_CHANNEL_LIST_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* getChannelListPopup() {
    yield takeEvery(actionMessage.GET_LIST_CHANNEL_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getListUser(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success(response)
            }
        } catch (error) {

        }
    })
}

function* getUserListCompany() {
    yield takeEvery(actionMessage.GET_LIST_USER_COMPANY, function* (payload) {
        try {
            const response = yield call(() => factory.getUserListCompany(payload?.payload));
            yield payload?.callback?.success && payload?.callback?.success(response);
        } catch (error) {

        }
    })
}
function* getListUserInstance() {
    yield takeEvery(actionMessage.GET_LIST_USER_INSTANCE, function* (payload) {
        try {
            const response = yield call(() => factory.getListUserInstance(payload?.payload));
            yield put({
                type: actionMessage.GET_LIST_USER_INSTANCE_SUCCESS,
                payload: response
            })
            yield payload?.callback?.success && payload?.callback?.success(response)
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed()
        }
    })
}
function* CreatedChannel() {
    yield takeEvery(actionMessage.CREATE_CHANNEL, function* (payload) {
        try {
            const response = yield call(() => factory.createdChannel(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionMessage.CREATE_CHANNEL_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success(response?.data?.id);
            }
        } catch (error) {

        }
    })
}

function* ForwardMessage() {
    yield takeEvery(actionMessage.FORWARD_MESSAGE, function* (payload) {
        try {
            const response = yield call(() => factory.forwardMessage(payload?.payload));
            if (response?.data?.channelId) {
                yield put({
                    type: actionMessage.FORWARD_MESSAGE_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success(response?.data?.channelId);
            }
        } catch (error) {

        }
    })
}

function* addToChannel() {
    yield takeEvery(actionMessage.ADD_USER_CHANNEL, function* (payload) {
        try {
            const response = yield call(() => factory.addToChannel(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionMessage.ADD_USER_CHANNEL_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed();
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            }
        } catch (error) {
            yield put({
                type: actionMessage.ADD_USER_CHANNEL_FAILED
            })
        }
    })
}

function* syncUser() {
    yield takeEvery(actionMessage.SYNC_USER, function* (payload) {
        try {
            const response = yield call(() => factory.syncUser());
            yield payload?.callback && payload?.callback()
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListChannel),
        fork(loadMoreChannel),
        fork(getMessage),
        fork(syncMessage),
        fork(sendMessage),
        fork(getUserList),
        fork(CreatedChannel),
        fork(syncUser),
        fork(getListUserInstance),
        fork(addToChannel),
        fork(getUserListCompany),
        fork(getChannelListPopup),
        fork(getScrollMessage),
        fork(getFirstMessage),
        fork(ForwardMessage)
    ]);
}
