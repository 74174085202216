import React, { useEffect, useState } from 'react'
import Button from 'shared/components/form-input/button/Button';
import classes from './HexServiceContainer.module.scss';
import Constants from 'utils/Constants';
import { useForm } from 'react-hook-form';
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import { useDispatch, useSelector } from 'react-redux';
import useCustomState from 'hooks/use-custom-state';
import actionHexService from 'redux/hex-service/action';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormSelect from 'shared/components/custom-form/FormSelect';
import TextLabel from 'shared/components/common/form-search/TextLabel';
import FormImage from 'shared/components/custom-form/FormImage';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import FormColorPicker from 'shared/components/custom-form/FormColorPicker';
import Validator from 'utils/Validator';
import FormEditor from 'shared/components/custom-form/FormEditor';
import FormInput from 'shared/components/custom-form/FormInput';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import FormRadio from 'shared/components/custom-form/FormRadio';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import actionHolonTemplate from 'redux/holon-template/action';
import { showPopupNotification } from 'utils/Utils';

function HexServiceContainer() {
    const defaultValues = {
        Lang: Constants.T001.JP.CODE,
        Type: Constants.T002.TERM_OF_SERVICE.CODE,
    };
    const methods = useForm({
        defaultValues: defaultValues,
    });
    const methodsForm = useForm({
        defaultValues: {
            status: 1,
            textColor: "#FFFFFF",
            backgroundColorCode: "#000000",
            sort: 1
        },
    });

    const router = useRouter();
    const { t } = useTrans()
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const dispatch = useDispatch();
    const [optionsLang, setOptionsLang] = useCustomState([]);
    const { hexService, loading: {
        loadingGetHexService,
        loadingUpdateHexService
    } } = useSelector((state) => state.hexService);

    useEffect(() => {
        let data = Object.keys(Constants.T001).map((key) => {
            return {
                label: t(`faq_management.lang.${Constants.T001[key]?.NAME}`),
                value: Constants.T001[key]?.CODE,
                key: Constants.T001[key]?.CODE,
            };
        });
        setOptionsLang(data);
    }, []);

    let optionsHexService = Object.keys(Constants.HEX_SERVICE).map(key => {
        return {
            key: key,
            value: key,
            label: t(`faq_management.${Constants.HEX_SERVICE[key]}`),
        }
    })

    useEffect(() => {
        if (router.get([Constants.QueryParams.LANGUAGE.VALUE])) {
            methods.setValue(
                'Lang',
                router.get([Constants.QueryParams.LANGUAGE.VALUE]),
            );
        } else {
            methods.setValue('Lang', Constants.T001.JP.CODE);
        }
        if (router.get([Constants.QueryParams.TYPE_SEARCH.VALUE])) {
            methods.setValue(
                'Type',
                router.get([Constants.QueryParams.TYPE_SEARCH.VALUE]),
            );
        } else {
            methods.setValue('Type', 1);
        }
    }, [
        router.get([Constants.QueryParams.LANGUAGE.VALUE]),
        router.get([Constants.QueryParams.TYPE_SEARCH.VALUE]),
    ]);

    const onSubmit = (data) => {
        router.replace({
            params: {
                [Constants.QueryParams.LANGUAGE.VALUE]: data?.Lang,
                [Constants.QueryParams.TYPE_SEARCH.VALUE]: data?.Type,
            },
        });

        methodsForm.clearErrors()
    };

    const onSubmitForm = (_data) => {
        showPopupNotification({
            typePopup: 'confirm',
            message: t("E0042"),
            callback: () => {
                dispatch({
                    type: actionHexService.UPDATE_HEX_SERVICE,
                    payload: {
                        lang: router.get([Constants.QueryParams.LANGUAGE.VALUE]) ?? Constants.T001.JP.CODE,
                        id: router.get([Constants.QueryParams.TYPE_SEARCH.VALUE]) ?? 1,
                        content: _data?.content,
                        title: _data?.title,
                        description: _data?.description,
                        logo: _data?.logo,
                        backgroundColorCode: _data?.backgroundColorCode,
                        textColor: _data?.textColor,
                        orderTemplate: _data?.orderTemplate[0] ?? "",
                        estimationTemplate: _data?.estimationTemplate[0] ?? "",
                        status: _data?.status?.toString(),
                        sort: _data?.sort
                    },
                    callback: {
                        success: () => {
                            setLoadingUpdate(false);
                            showPopupNotification({
                                typePopup: 'message',
                                message: t("E0043"),
                                callback: () => {

                                }
                            })
                        },
                        failed: () => {
                            setLoadingUpdate(false);
                        }
                    },
                });
            }
        })
    }

    useEffect(() => {
        const params = {};
        if (router.get([Constants.QueryParams.LANGUAGE.VALUE])) {
            params['lang'] = router.get([Constants.QueryParams.LANGUAGE.VALUE]);
        } else {
            params['lang'] = Constants.T001.JP.CODE;
        }
        if (router.get([Constants.QueryParams.TYPE_SEARCH.VALUE])) {
            params['id'] = router.get([
                Constants.QueryParams.TYPE_SEARCH.VALUE,
            ]);
        } else {
            params['id'] = 1;
        }
        dispatch({
            type: actionHexService.GET_HEX_SERVICE,
            payload: {
                ...params,
            },
        });
    }, [
        router.get([Constants.QueryParams.LANGUAGE.VALUE]),
        router.get([Constants.QueryParams.TYPE_SEARCH.VALUE]),
    ]);

    // const handleUpdate = () => {
    //     router.push({
    //         pathname: RouterPath.getRouteWithArrayKey(
    //             RouterPath.PORTAL_UPDATE_HEX_SERVICE,
    //             {
    //                 lang: router.get(Constants.QueryParams.LANGUAGE.VALUE) ?? Constants.T001.JP.CODE,
    //                 key: router.get(Constants.QueryParams.TYPE_SEARCH.VALUE) ?? Constants.T002.TERM_OF_SERVICE.CODE,
    //             },
    //         ),
    //     });
    // };

    useEffect(() => {
        if (hexService?.id) {
            methodsForm.setValue('title', hexService?.title);
            methodsForm.setValue('content', hexService?.content);
            methodsForm.setValue('description', hexService?.description);
            methodsForm.setValue('backgroundColorCode', hexService?.backgroundColorCode ?? "#000000");
            methodsForm.setValue('logo', hexService?.logo);
            methodsForm.setValue('status', hexService?.status ?? "1");
            methodsForm.setValue('textColor', hexService?.textColor ?? "#FFFFFF");
            methodsForm.setValue('sort', hexService?.sort);
            methodsForm.setValue('orderTemplate', hexService?.orderTemplate ? hexService?.orderTemplate?.split(",") : "");
            methodsForm.setValue('estimationTemplate', hexService?.estimationTemplate ? hexService?.estimationTemplate?.split(",") : "");
        } else {
            methodsForm.setValue('title', "");
            methodsForm.setValue('content', "");
            methodsForm.setValue('description', "");
            methodsForm.setValue('backgroundColorCode', "#000000");
            methodsForm.setValue('logo', null);
            methodsForm.setValue('textColor', "#FFFFFF");
            methodsForm.setValue('orderTemplate', "");
            methodsForm.setValue('status', "1");
            methodsForm.setValue('sort', "1");
            methodsForm.setValue('estimationTemplate', "");
        }
    }, [hexService]);

    const OptionRelease = [
        {
            value: "1",
            key: "1",
            label: t("Holon_Template.valid")
        },
        {
            value: "0",
            key: "0",
            label: t("Holon_Template.invalid")
        },
    ]

    const optionsSort = [
        {
            value: "1",
            key: "1",
            label: "1"
        },
        {
            value: "2",
            key: "2",
            label: "2"
        },
        {
            value: "3",
            key: "3",
            label: "3"
        },
        {
            value: "4",
            key: "4",
            label: "4"
        },
        {
            value: "5",
            key: "5",
            label: "5"
        },
    ]

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_ALL_HOLON_TEMPLATE,
            payload: {
                isAll: true
            }
        })
    }, [])

    return (
        <div className={classes['policy-setting']}>
            <ContentLayout>
                <Breadcrumb t={t("faq_management.hex_services.hex_service_setting")} />
                <div className={classes['policy-setting-form-search']}>
                    <CustomFormProvider {...methods}>
                        <form
                            className="quick-submit-form"
                            onSubmit={methods.handleSubmit(onSubmit)}
                        >
                            <FormSearch>
                                <FormGroup>
                                    <FormItem>
                                        <FormSelect
                                            options={optionsHexService}
                                            fieldName="Type"
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <FormSelect
                                            options={optionsLang}
                                            fieldName="Lang"
                                        />
                                    </FormItem>
                                    <button hidden type='submit' />
                                </FormGroup>
                            </FormSearch>
                        </form>
                    </CustomFormProvider>
                </div>
            </ContentLayout>
            <ContentLayout>
                <CustomFormProvider {...methodsForm}>
                    <form onSubmit={methodsForm.handleSubmit(onSubmitForm)}>
                        <div className={classes['wrap_layout_form']}>
                            {/* <div className={classes['hex-service-content']}>
                        <div className={classes['hex-service-item']}>
                            <p className={classes['label']}>{t('faq_management.title')}</p>
                            <p className={classes['content']}>{hexService?.title}</p>
                        </div>
                        <div className={classes['hex-service-item']}>
                            <p className={classes['label']}>{t('faq_management.background_color_code')}</p>
                            <div className={classes['content-row']}>
                                <p className={classes['content']}>{hexService?.backgroundColorCode}</p>
                                <div style={{ height: 20, width: 20, background: hexService?.backgroundColorCode, borderRadius: 5 }} />
                            </div>
                        </div>
                        <div className={classes['hex-service-item']}>
                            <p className={classes['label']}>{t('faq_management.text_color')}</p>
                            <div className={classes['content-row']}>
                                <p className={classes['content']}>{hexService?.textColor}</p>
                                <div style={{ height: 20, width: 20, background: hexService?.textColor, borderRadius: 5 }} />
                            </div>
                        </div>
                        <div className={classes['hex-service-item']}>
                            <p className={classes['label']}>{t('faq_management.logo')}</p>
                            <div className={classes['content-row']}>
                                <div className={classes['content-image']}>
                                    {hexService?.logo ? <img src={hexService?.logo} /> : <></>}
                                </div>
                            </div>
                        </div>
                        <div className={classes['hex-service-item']}>
                            <p className={classes['label']}>{t('faq_management.description')}</p>
                            <div
                                className={classes['content']}
                                dangerouslySetInnerHTML={{ __html: hexService?.description }}
                            />
                        </div>
                        <div className={classes['hex-service-item']}>
                            <p className={classes['label']}>{t('faq_management.content')}</p>
                            <div
                                className={classes['content']}
                                dangerouslySetInnerHTML={{ __html: hexService?.content }}
                            />
                        </div>
                    </div> */}
                            <FormSearch>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.hex_services.display_order')} />
                                        <FormSelect
                                            options={optionsSort}
                                            fieldName="sort"
                                        />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.hex_services.service_name')} />
                                        <FormInput
                                            validate={[Validator.required]}
                                            fieldName="title"
                                            validationFE
                                            textTrans='faq_management.hex_services.field_name'
                                        />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t("faq_management.hex_services.service_overview")} />
                                        <FormTextArea
                                            validate={[Validator.required]}
                                            fieldName="description"
                                            validationFE
                                            textTrans='faq_management.hex_services.field_name'
                                        />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t("faq_management.hex_services.service_introduction_content")} />
                                        <FormEditor
                                            height='300px'
                                            validate={[Validator.required]}
                                            fieldName="content"
                                            validationFE
                                            textTrans='faq_management.hex_services.field_name'
                                        />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.hex_services.status')} />
                                        <FormRadio options={OptionRelease} fieldName="status" />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.hex_services.order_template')} />
                                        <FormSelectTemplate masterCode="ORDER"  style={{ width: '100%' }} isShowButtonEdit isMulti={false} validate={[]} fieldName='orderTemplate' />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.hex_services.request_for_quotation_template')} />
                                        <FormSelectTemplate masterCode="ESTIMATION_REQUEST"  style={{ width: '100%' }} isShowButtonEdit isMulti={false} validate={[]} fieldName='estimationTemplate' />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.hex_services.background_color')} />
                                        <FormColorPicker validate={[]} fieldName="backgroundColorCode" />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.hex_services.text_color')} />
                                        <FormColorPicker validate={[]} fieldName="textColor" />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.hex_services.image')} />
                                        <FormImage message={t("faq_management.hex_services.require_accept_image")} accept="image/png, image/jpeg, image/gif, image/bmp" width='350px' height='130px' validate={[Validator.requiredImage]} fieldName="logo" />
                                    </FormItem>
                                </FormGroup>
                            </FormSearch>
                            <div className={classes['action-update']}>
                                <Button disabled={loadingGetHexService} loading={loadingUpdateHexService || loadingUpdate} type="submit" title={t("save")} />
                            </div>
                        </div>
                    </form>
                </CustomFormProvider>
            </ContentLayout>
        </div>
    );
}

export default HexServiceContainer;