import React from 'react'
import classes from './ServiceDetailPopup.module.scss';

function ServiceDetailPopup(props) {
    const { payload } = props;
    return (
        <div className={classes['service-detail-popup']}>
            <div className={classes['service-detail-content']}>
                {
                    payload?.titleContent ? <p className={classes['service-title-content']}>{payload?.titleContent}</p> : <></>
                }
                <div className={classes['content']} dangerouslySetInnerHTML={{
                    __html: payload?.content
                }} />
            </div>
        </div>
    )
}

export default ServiceDetailPopup