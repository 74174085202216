import FareCalculationAction from "./action";

const initialState = {
    fareCalculationServiceCrossBorder: {},
    fareCalculationService: [],
    fareCalculationGlobalVariable: [],
    fareCalculationUserInput: {},
    fareCalcManagementCalculate: [],
    fareServiceDetail: {},
    fareValueEstimated:{},
    loading: {
        loadingGetFareCalcuServiceCrossBorder: false,
        loadingGetFareCalcuService: false,
        loadingGetFareCalcuGlobalVariable: false,
        loadingCreateFareCalculation: false,
        loadingUpdateFareDetailManagementService: false,
        loadingFareCalcManagementCalculate: false,
        loadingFareCalculationUserInput: false,
        loadingFareServiceDetail: false,
        loadingCreateService: false,
        loadingDeleteService: false
    }
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case FareCalculationAction.GET_CROSS_BORDER_COURIER_SERVICE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuServiceCrossBorder: true
                },
                fareCalculationServiceCrossBorder: {}
            }
        case FareCalculationAction.GET_CROSS_BORDER_COURIER_SERVICE_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuServiceCrossBorder: false,
                },
                fareCalculationServiceCrossBorder: action?.payload
            }
        case FareCalculationAction.GET_CROSS_BORDER_COURIER_SERVICE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuServiceCrossBorder: false,
                },
                fareCalculationServiceCrossBorder: {}
            }
        case FareCalculationAction.REMOVE_CROSS_BORDER_COURIER_SERVICE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuServiceCrossBorder: false,
                },
                fareCalculationServiceCrossBorder: {}
            }
        case FareCalculationAction.GET_CALCULATION_SERVICE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuService: true
                }
            }
        case FareCalculationAction.GET_CALCULATION_SERVICE_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuService: false,
                },
                fareCalculationService: action?.payload
            }
        case FareCalculationAction.GET_CALCULATION_SERVICE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuService: false,
                },
                fareCalculationService: []
            }
        case FareCalculationAction.GET_FARE_CALCULATION_GLOBAL_VARIABLE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuGlobalVariable: true
                }
            }
        case FareCalculationAction.GET_FARE_CALCULATION_GLOBAL_VARIABLE_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuGlobalVariable: false,
                },
                fareCalculationGlobalVariable: action?.payload
            }
        case FareCalculationAction.GET_FARE_CALCULATION_GLOBAL_VARIABLE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetFareCalcuGlobalVariable: false,
                },
                fareCalculationGlobalVariable: action?.payload
            }
        case FareCalculationAction.GET_FARE_CALCULATION_USER_INPUT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalculationUserInput: true
                }
            }
        case FareCalculationAction.GET_FARE_CALCULATION_USER_INPUT_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalculationUserInput: false,
                },
                fareCalculationUserInput: action?.payload
            }
        case FareCalculationAction.GET_FARE_CALCULATION_USER_INPUT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalculationUserInput: false,
                },
                fareCalculationUserInput: action?.payload
            }
        case FareCalculationAction.GET_FARE_CALC_USER_INPUT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalculationUserInput: true
                }
            }
        case FareCalculationAction.GET_FARE_CALC_USER_INPUT_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalculationUserInput: false,
                },
                fareCalculationUserInput: action?.payload
            }
        case FareCalculationAction.GET_FARE_CALC_USER_INPUT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalculationUserInput: false,
                },
                fareCalculationUserInput: action?.payload
            }
        case FareCalculationAction.CREATE_FARE_CALCULATION:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateFareCalculation: true
                }
            }
        case FareCalculationAction.CREATE_FARE_CALCULATION_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateFareCalculation: false,
                },
            }
        case FareCalculationAction.CREATE_FARE_CALCULATION_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateFareCalculation: false,
                },
            }
        case FareCalculationAction.UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateFareDetailManagementService: true
                }
            }
        case FareCalculationAction.UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateFareDetailManagementService: false,
                },
            }
        case FareCalculationAction.UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateFareDetailManagementService: false,
                },
            }
        case FareCalculationAction.CREATE_FARE_DETAIL_MANAGEMENT_SERVICE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateService: true
                }
            }
        case FareCalculationAction.CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateService: false,
                },
            }
        case FareCalculationAction.CREATE_FARE_DETAIL_MANAGEMENT_SERVICE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateService: false,
                },
            }
        case FareCalculationAction.GET_FARE_CALC_MANAGEMENT_CALCULATE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalcManagementCalculate: true
                },
            }
        case FareCalculationAction.GET_FARE_CALC_MANAGEMENT_CALCULATE_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalcManagementCalculate: false,
                },
                fareCalcManagementCalculate: action.payload
            }
        case FareCalculationAction.GET_FARE_CALC_MANAGEMENT_CALCULATE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareCalcManagementCalculate: false,
                },
                fareCalcManagementCalculate: []
            }
        case FareCalculationAction.REMOVE_FARE_CALC_MANAGEMENT_CALCULATE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                },
                fareCalcManagementCalculate: [],
                fareValueEstimated: {}
            }
        case FareCalculationAction.SAVE_VALUE_ESTIMATED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                },
                fareValueEstimated: action.payload
            }
        case FareCalculationAction.GET_DETAIL_FARE_CAL_SERVICE_CONTENT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareServiceDetail: true
                },
                fareServiceDetail: {}

            }
        case FareCalculationAction.GET_DETAIL_FARE_CAL_SERVICE_CONTENT_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareServiceDetail: false,
                },
                fareServiceDetail: action.payload
            }
        case FareCalculationAction.GET_DETAIL_FARE_CAL_SERVICE_CONTENT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingFareServiceDetail: false,
                },
                fareServiceDetail: {}
            }
        case FareCalculationAction.DELETE_FARE_CALC_SERVICE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteService: true
                },
            }
        case FareCalculationAction.DELETE_FARE_CALC_SERVICE_SUCCESSFUL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteService: false,
                },
            }
        case FareCalculationAction.DELETE_FARE_CALC_SERVICE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteService: false,
                },
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;