import React, { useEffect, useState } from 'react';
import ErrorMessage from '../error-message/ErrorMessage';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';
import classes from './Styles.module.scss';
import _ from 'lodash';
import IconDelete from 'assets/icon/IconDelete';
import PropTypes from 'prop-types';
import IconEye from 'assets/icon/IconEye';
import IconEyeOff from 'assets/icon/IconEyeOff';
import Constants from 'utils/Constants';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';

const Input = (props) => {
    const {
        type = 'text',
        name,
        disabled = false,
        value,
        iconSearch,
        isTooltip = false,
        refInput,
        onChange,
        errors,
        placeholder = '',
        onFocus,
        style,
        maxLength,
        onBlur,
        isBorder = false,
        iconDelete = false,
        handleDelete,
        showErrorProps = true,
        width = '100%',
        height = Constants.STYLE.input.height,
        background,
        className,
        showIconPassword = true,
        callback = () => {},
        isSearch = false,
        max,
        validationFE = false,
        textTrans,
        isRegexLabel
    } = props;

    const onChangeInput = (e) => {
        onChange(e);
        if (callback) {
            callback(e.target.value);
        }
    };
    const [inputType, setInputType] = useState("text");

    const handleShowValue = (e) => {
        setInputType(e);
    };

    useEffect(() => {
        if (type) {
            setInputType(type);
        }
    }, [type]);

    let showError = false;
    let error = {
        message: '',
    };

    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    return (
        <>
            <div
                className={`${classes['Custom_Input']} ${className} ${disabled ? classes['disabled'] : ''} ${isSearch ? classes['Custom_Form_Search'] : ''} Custom_Input
                ${showError ? classes['Error'] : ""}`}
                style={{ width: width, height: height, background: background }}
            >
                {showIconPassword && type?.toLowerCase() == "password" ? (
                    <div
                        onClick={() =>
                            handleShowValue(inputType == "password" ? "text" : "password")
                        }
                        className={classes["Icon_Delete"]}
                    >
                        {inputType == "password" ? <IconEyeOff /> : <IconEye /> }
                    </div>
                ) : (
                    <></>
                )}
                {iconSearch ? (
                    <div className={classes['Icon_Search']}>{iconSearch}</div>
                ) : (
                    <></>
                )}
                <input
                    name={name}
                    disabled={disabled}
                    value={value}
                    ref={refInput}
                    type={inputType ?? type}
                    onChange={onChangeInput}
                    className={
                        `${showError ? "" : classes['Success']
                        } ${iconSearch ? classes['Padding_Icon_Left'] : ''} ${type?.toLowerCase() == "password" ? classes['Padding_Icon_Right'] : ''} ${isBorder && value ? classes['CustomBorder'] : ''} ${iconDelete ? classes['CustomPaddingRight'] : ''
                        } custom-input`}
                    style={style}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete="off"
                    max={max}
                />
                {iconDelete ? (
                    <div
                        onClick={handleDelete}
                        className={classes['Icon_Delete']}
                    >
                        <IconDelete fontSize="24px" />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} textTrans={textTrans} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </>
    );
};

Input.propsType = {
    name: PropTypes.string,
};
export default Input;
