import React from 'react';

function IconChevronUp({ fontSize= 12, strokeWidth = 2}) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fontSize={fontSize}
        >
            <path
                d="M1.00024 7L6.00024 2L11.0002 7"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconChevronUp;
