import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionTransaction from "./action";
import factory from "./factory";

function* getList() {
    yield takeEvery(actionTransaction.GET_LIST_TRANSACTION, function* (payload) {
        try {
            const response = yield call(() => factory.getListTransaction(payload?.payload));
            yield put({
                type: actionTransaction.GET_LIST_TRANSACTION_SUCCESS,
                payload: response
            })
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
        }
    })
}

function* getHolonInstanceWalletByCode() {
    yield takeEvery(actionTransaction.GET_HOLON_INSTANCE_WALLET_BY_CODE, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonInstanceByCode(payload?.payload));
            if (response?.data) {
                yield payload?.callback && payload?.callback(response?.data)
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
        }
    })
}

function* getHolonInstanceOrderByCode() {
    yield takeEvery(actionTransaction.GET_HOLON_INSTANCE_ORDER_BY_CODE, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonInstanceByCode(payload?.payload));
            if (response?.data) {
                yield payload?.callback && payload?.callback(response?.data)
            }
        } catch (error) {

        }
    })
}

function* getTransactionDetail() {
    yield takeEvery(actionTransaction.GET_TRANSACTION_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.getTransactionDetail(payload?.payload));
            if (response?.data) {
                yield payload?.callback && payload?.callback(response?.data);
            }
            yield put({
                type: actionTransaction.GET_TRANSACTION_DETAIL_SUCCESS,
                payload: response
            })
        } catch (error) {
        }
    })
}

function* approveTransaction() {
    yield takeEvery(actionTransaction.APPROVE_TRANSACTION, function* (payload) {
        try {
            const response = yield call(() => factory.approveTransaction(payload?.payload));
            yield payload?.callback?.success(response, null)
            yield put({
                type: actionTransaction.APPROVE_TRANSACTION_SUCCESS,
                payload: response
            })
        } catch (error) {
            yield payload?.callback?.success(null, error)
        }
    })
}

function* rejectTransaction() {
    yield takeEvery(actionTransaction.REJECT_TRANSACTION, function* (payload) {
        try {
            const response = yield call(() => factory.rejectTransaction(payload?.payload));
            yield payload?.callback?.success(response, null)
            yield put({
                type: actionTransaction.REJECT_TRANSACTION_SUCCESS,
                payload: response
            })
        } catch (error) {
            yield payload?.callback?.success(null, error)
        }
    })
}
function* createTransaction() {
    yield takeEvery(actionTransaction.CREATE_TRANSACTION_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.createTransaction(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionTransaction.CREATE_TRANSACTION_DETAIL_SUCCESS,
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0]);
                yield put({
                    type: actionTransaction.CREATE_TRANSACTION_DETAIL_FAILED,
                    payload: response?.error?.detail?.exception[0]
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
            yield put({
                type: actionTransaction.CREATE_TRANSACTION_DETAIL_FAILED,
            })
        }
    })
}

function* updateTransaction() {
    yield takeEvery(actionTransaction.UPDATE_TRANSACTION_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.updateTransaction(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionTransaction.UPDATE_TRANSACTION_DETAIL_SUCCESS,
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0]);
                yield put({
                    type: actionTransaction.UPDATE_TRANSACTION_DETAIL_FAILED,
                    payload: response?.error?.detail?.exception[0]
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
            yield put({
                type: actionTransaction.UPDATE_TRANSACTION_DETAIL_FAILED,
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getList),
        fork(getTransactionDetail),
        fork(approveTransaction),
        fork(rejectTransaction),
        fork(createTransaction),
        fork(updateTransaction),
        fork(getHolonInstanceWalletByCode),
        fork(getHolonInstanceOrderByCode),
    ])
}