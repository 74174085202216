import React, { useEffect, useState } from 'react';
import classes from './Select.module.scss';
import _ from 'lodash';
import ErrorMessage from '../error-message/ErrorMessage';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux';

const Select = (props) => {
    const {
        size,
        inputRef,
        value,
        disabled,
        isSearch,
        className,
        isShowKey = false,
        errors,
        name,
        validationFE = false,
        textTrans,
        isRegexLabel,
        onBlur,
        attributeKey
    } = props;

    let showError = false;
    let error = {
        message: '',
    };

    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    let id = `select-${uuidv4()}`;

    const [data, setData] = useState([])
    const fieldData = useSelector(state => state.app[name]);
    useEffect(() => {
        if (fieldData) {
            setData(fieldData)
        } else if (props?.options) {
            setData(props?.options)
        }
    }, [props?.options, fieldData])

    useEffect(() => {
        let label = data?.find(x => x.value == value)?.label;
        if (label) {
            localStorage.setItem(attributeKey, label);
        }
        return () => {
            localStorage.removeItem(attributeKey);
        }
    }, [value, data]);

    return (
        <>
            <div
                className={`${classes['CustomSelect']} 
                ${showError && classes['CustomSelect_Error']} ${size ? classes[size] : ''} custom-select ${disabled ? classes['disabled'] : ''} ${isSearch ? classes['Custom_Form_Search'] : ''}
                ${className}
                `}
            >
                {/* <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }} className={`${classes['CustomIcon']} CustomIcon`}>
                    <IconChevronDownSelect />
                </div> */}
                {
                    isShowKey && <div className={classes['CustomNameSelect']}>
                        <p>{props.options?.find(x => x?.key == value)?.key_option}</p>
                    </div>
                }
                <select
                    name={name}
                    className={`${props.textAlign == 'left' ?
                        classes['option-left'] : ''} ${isShowKey ? classes['Custom_Select'] : ''}`}
                    ref={inputRef}
                    onChange={(e) => props.onChange(e.currentTarget.value?.toString())}
                    onBlur={(e) => onBlur(e.currentTarget.value?.toString())}
                    value={value ?? ""}
                    style={{
                        color: value ? "#000000" : '#929292'
                    }}
                    id={id}
                >
                    <option value="" disabled>{props.placeholder}</option>
                    {data?.map((value, key) => {
                        return (
                            <option key={key} value={value.value}>
                                {value.label}
                            </option>
                        );
                    })}
                </select>
            </div>
            {/* {showError ? (
                <div className={classes['Custom_Select_Error_Message']}>
                    {!validationFE ? (<ErrorMessage message={error?.message} />) : (<ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={name} />)}
                </div>
            ) : (
                <></>
            )} */}
            {showError ? (
                <div className={classes['Custom_Select_Error_Message']}>
                    {
                        !validationFE ? (<ErrorMessage message={error?.message} />) : (
                            isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} textTrans={textTrans} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={name} />
                        )
                    }
                </div>) : (
                <></>
            )}
        </>
    );
};

export default Select;
