import React from 'react'
import BoxContentPopup from '../custom-body/BoxContentPopup';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';

import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import actionProduct from 'redux/product/action';
import FormData from './FormData';

function CreateProductPopup(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();


    const dispatch = useDispatch();
    const { loadingCreate } = useSelector(state => state.product);
    const methods = useForm({
        defaultValues: {
            item_name: "",
            price: 0,
            manual_update_flag: "",
            gs1_code: "",
            currency: '￥'
        }
    });

    const onSubmit = (data) => {
        const params = {
            id: 0,
            item_name: data?.item_name,
            price: parseInt(data?.price, 10),
            genre_id: data?.genre_id?.key,
            genre_name: data?.genre_id?.label,
            product_url: data?.product_url,
            origin: data?.origin,
            note: data?.note,
            currency: data?.currency,
            jan_code: data?.jan_code,
            brand_name: data?.brand_name,
            maker_name: data?.maker_name,
        }
        if (payload?.callbackResult) {
            payload?.callbackResult(params);
            showVisible();
        } else {
            dispatch({
                type: actionProduct.CREATE_PRODUCT,
                payload: {
                    ...params
                },
                callback: {
                    success: () => {
                        payload?.callback();
                        showVisible()
                    },
                    failed: () => {

                    }
                }
            })
        }
    }



    return (
        <BoxContentPopup minWidth="1000px">
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody title={payload?.title} showVisible={showVisible} payload={payload}
                        actionSectionBottom={<Button loading={loadingCreate} type='submit' title={t("product.submit")} />}
                    >
                        <FormData isCreate />
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default CreateProductPopup