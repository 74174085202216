import React, { useEffect } from "react";
import useTrans from "hooks/use-trans";
import Box from "shared/components/form-input/box/Box";
import EditFreightEstimate from "./EditFreightEstimate"
import ListStationConstants from "./ListStationConstants"
import TrialCalculationList from "./TrialCalculationList"
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import { useForm } from "react-hook-form";
import classes from "./FreightCalculationManagementContainer.module.scss"
import IconSave from 'assets/icon/IconSave';
import { useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import actionFareCalculation from 'redux/freight-calculation/action';
import Button from 'shared/components/form-input/button/Button';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_UPDATE } from 'utils/EventRegister';
import { showPopupNotification } from 'utils/Utils';
import Constants from 'utils/Constants';

const FreightCalculationManagement = () => {
    const { t } = useTrans()
    const methods = useForm();
    const control = methods.control;
    const dispatch = useDispatch();
    const {
        fareCalculationService,
        fareCalculationGlobalVariable,
        fareCalculationUserInput,
        loading: { loadingCreateFareCalculation },
    } = useSelector((state) => state.fareCalculation);

    const {
        append: appendUserInput,
        fields: fieldsUserInput,
        remove: removeUserInput,
    } = useFieldArray({
        control, name: "fareCalcUserInputs"
    })

    const {
        append: appendGlobalVariable,
        fields: fieldsGlobalVariable,
        remove: removeGlobalVariable,
    } = useFieldArray({
        control, name: "fareCalcConstants"
    })

    useEffect(() => {
        getFareCalculation()
    }, []);

    const getFareCalculation = () => {
        dispatch({
            type: actionFareCalculation.GET_CALCULATION_SERVICE,
        })
        dispatch({
            type: actionFareCalculation.GET_FARE_CALCULATION_GLOBAL_VARIABLE,
        })
        dispatch({
            type: actionFareCalculation.GET_FARE_CALCULATION_USER_INPUT,
        })
    }

    useEffect(() => {
        if (fareCalculationGlobalVariable) {
            methods.setValue("fareCalcConstants", fareCalculationGlobalVariable?.data?.map(x => {
                return {
                    ...x,
                }
            }))
        }
        if (fareCalculationUserInput) {
            methods.setValue("fareCalcUserInputs", fareCalculationUserInput?.data?.map(x => {
                return {
                    ...x,
                    isRequired: x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isEditabled: x?.isEditabled ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                    elementType: x?.elementType ?? Constants.ELEMENT_TYPE.TEXT.VALUE
                }
            }))
        }
    }, [fareCalculationGlobalVariable, fareCalculationUserInput, fareCalculationService])

    const valueDragDrop = (items) => {
        methods.setValue("fareCalcUserInputs", items)
    }

    const popupConfirm = (data) => {
        let fareCalcUserInputs = data?.fareCalcUserInputs?.map(x => {
            return {
                ...x,
                isRequired: x?.isRequired ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isMultiSelected: x?.isMultiSelected ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isEditabled: x?.isEditabled ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                elementType: x?.elementType ?? Constants.ELEMENT_TYPE.TEXT.VALUE
            }
        })
        let params = {
            fareCalcUserInputs: fareCalcUserInputs,
            fareCalcConstants: data?.fareCalcConstants
        }
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                title: t("update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionFareCalculation.CREATE_FARE_CALCULATION,
                        payload: params,
                        onSuccess: onCreateSuccess,
                        onFailure: onCreateFailure,
                    })
                }
            }
        })
    }

    const onSubmit = (data) => {
        popupConfirm(data)
    }
    const onCreateSuccess = () => {
        showPopupNotification({
            typePopup: 'message',
            newWindow: true,
            message: t("E0046")
        })
        getFareCalculation()

    }

    const onCreateFailure = (detail = {}) => {
        Object.keys(detail).forEach(key => {
            detail[key]?.map(msg => {
                showPopupNotification({
                    typePopup: 'message',
                    newWindow: true,
                    message: msg
                })
            })
        })
        getFareCalculation()

    }

    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Box className={classes["freight-calculation-management"]}>
                    <div className={classes["HeaderSection"]}>
                        {t("fare_calculation.freight_calculation_management")}
                    </div>
                    <div className={classes["line-estimate"]}></div>
                    <EditFreightEstimate removeUserInput={removeUserInput} appendUserInput={appendUserInput} fieldsUserInput={fieldsUserInput} callback={valueDragDrop} method={methods}/>
                    <div className={classes["line-estimate"]}></div>
                    <ListStationConstants appendGlobalVariable={appendGlobalVariable} fieldsGlobalVariable={fieldsGlobalVariable} removeGlobalVariable={removeGlobalVariable} />
                    <div className={classes["line-estimate"]}></div>
                    <TrialCalculationList fareCalculationService={fareCalculationService} />
                    <div className={classes["btn-form"]}>
                        <Button
                            type="submit"
                            startIcon={<IconSave color="#106574" />}
                            loading={loadingCreateFareCalculation}
                        >
                            {t('Holon_Template.save')}
                        </Button>
                    </div>
                </Box>
            </form>
        </CustomFormProvider>
    );
};

export default FreightCalculationManagement;