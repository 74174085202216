import React, { useEffect, useState, useCallback } from "react";
//import '../assets/css/Configuration.css';
//import profile from '../assets/img/profile.png';
import $ from 'jquery';
import Menu from "shared/components/menu/Menu";
import { Close,AddCircleOutlined, CheckCircle, Cancel } from "@mui/icons-material";
import {getLabel, sendAction,getCoreAPI} from 'shared/components/common/Util';
import FilterBar from 'shared/components/common/FilterBar.js';

function SystemTemplate(){
    const [, updateState] = useState();
    const handleForceupdateMethod = useCallback(() => updateState({}), []);
    const [actions, setActions] = useState([])
    const fetchActions = async() => {
        const response = await fetch(getCoreAPI()+"/system/actions")
        const data = await response.json()
        setActions(data)
    }
    useEffect(() => {
        fetchActions()
    }, [])
    return (
        <div className="container-fluid">
            <div className="modal fade" id="resultModal" tabIndex="-1" aria-labelledby="resultModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content alternative-header">
                        <div className="modal-header">
                            <h1 className="modal-title fs-6" id="resultModalLabel"></h1>
                            <span onClick={handleForceupdateMethod}><Close style={{cursor:'pointer'}} data-bs-dismiss="modal" aria-label="Close"/></span>
                        </div>
                        <div className="modal-body">
                            <div className="card border-success mb-3">
                                <div className="card-header bg-transparent border-success"  id="resulDescModalLabel">Loading</div>
                                <div className="card-body text-success">
                                    <center>
                                        <div id="result-loading" className="spinner-border text-info d-none" role="status">
                                            <h2><span className="visually-hidden">Loading...</span></h2>
                                        </div>
                                        <div id="result-ok" className="success-request d-none"><CheckCircle/></div>
                                        <div id="result-nope" className="success-request d-none"><Cancel/></div>
                                        <div id="result-message" className=""></div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="templateModal" tabIndex="-1" aria-labelledby="templateModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content alternative-header">
                        <div className="modal-header">
                            <h1 className="modal-title fs-6" id="templateModalLabel">{getLabel('[LABEL_TEMPLATE]')}</h1>
                            <Close style={{cursor:'pointer'}} data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="card border-success mb-3">
                                <div className="card-header bg-transparent border-success">
                                    <span>{getLabel('[LABEL_TEMPLATE]')}</span>
                                    <span id="access-mode"></span>
                                </div>
                                <div className="card-body text-success">
                                    <form id="templateForm">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">{getLabel('[LABEL_TEMPLATE_NAME]')}</label>
                                            <input type="text" className="form-control" id="name" aria-describedby="nameHelp" />
                                            <div id="nameHelp" className="form-text">Define the best name to describe the action.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">{getLabel('[LABEL_DESCRIPTION]')}</label>
                                            <input type="text" className="form-control" id="description" />
                                            <div id="descriptionHelp" className="form-text">Describe in detail what is the objective of this action.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="timeout" className="form-label">{getLabel('[LABEL_UNIT]')}</label>
                                            <select id="measure" className="form-select form-control" aria-label="select measure">
                                                <option selected>Select the measure</option>
                                                <option value="s">秒</option>
                                                <option value="i">分</option>
                                                <option value="h">時</option>
                                                <option value="d">日</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="library" className="form-label">{getLabel('[LABEL_LIBRARY]')} / API</label>
                                            <input type="text" className="form-control" id="library" />
                                            <div id="libraryHelp" className="form-text">Provide the API enpoint if required.</div>
                                        </div>
                                        <div className="mb-3 form-check">
                                            <input type="checkbox" className="form-check-input" id="read" />
                                            <label className="form-check-label" htmlFor="read">{getLabel('[LABEL_READ]')}</label>
                                        </div>
                                        <div className="mb-3 form-check">
                                            <input type="checkbox" className="form-check-input" id="write" />
                                            <label className="form-check-label" htmlFor="write">{getLabel('[LABEL_WRITE]')}</label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal" aria-label="Close">{getLabel('[LABEL_BACK]')}</button>
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#resultModal" onClick={() =>sendAction('templateForm','POST','http://localhost:8081/rest/api/v1/system/template')}  id="submitAction">{getLabel('[LABEL_SAVE]')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="actionsModal" tabIndex="-1" aria-labelledby="actionsModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content alternative-header">
                        <div className="modal-header">
                            <h1 className="modal-title fs-6" id="actionsModalLabel">{getLabel('[LABEL_ACTION]')}</h1>
                            <Close style={{cursor:'pointer'}} data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="card border-success mb-3">
                                <div className="card-header bg-transparent border-success">
                                    <span>{getLabel('[LABEL_ACTION]')}</span>
                                    <span id="access-mode"></span>
                                </div>
                                <div className="card-body text-success">
                                    <form id="actionForm">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">{getLabel('[LABEL_ACTION_NAME]')}</label>
                                            <input type="text" className="form-control" id="name" aria-describedby="nameHelp" />
                                            <div id="nameHelp" className="form-text">Define the best name to describe the action.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">{getLabel('[LABEL_DESCRIPTION]')}</label>
                                            <input type="text" className="form-control" id="description" />
                                            <div id="descriptionHelp" className="form-text">Describe in detail what is the objective of this action.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="inputAction" className="form-label">{getLabel('[LABEL_INPUT]')}</label>
                                            <input type="text" className="form-control" id="inputAction" />
                                            <div id="inputActionHelp" className="form-text">Provide the input to execute this action.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="outputAction" className="form-label">{getLabel('[LABEL_OUTPUT]')}</label>
                                            <input type="text" className="form-control" id="outputAction" />
                                            <div id="outputActionHelp" className="form-text">Provide the expected output if any.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="timeout" className="form-label">{getLabel('[LABEL_TIMEOUT]')}</label>
                                            <input type="short" className="form-control" id="timeout" />
                                            <div id="timeoutHelp" className="form-text">Provide the time limit for the execution of this action.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="timeout" className="form-label">{getLabel('[LABEL_UNIT]')}</label>
                                            <select id="measure" className="form-select form-control" aria-label="select measure">
                                                <option selected>Select the measure</option>
                                                <option value="s">秒</option>
                                                <option value="i">分</option>
                                                <option value="h">時</option>
                                                <option value="d">日</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="library" className="form-label">{getLabel('[LABEL_LIBRARY]')} / API</label>
                                            <input type="text" className="form-control" id="library" />
                                            <div id="libraryHelp" className="form-text">Provide the API enpoint if required.</div>
                                        </div>
                                        <div className="mb-3 form-check">
                                            <input type="checkbox" className="form-check-input" id="read" />
                                            <label className="form-check-label" htmlFor="read">{getLabel('[LABEL_READ]')}</label>
                                        </div>
                                        <div className="mb-3 form-check">
                                            <input type="checkbox" className="form-check-input" id="write" />
                                            <label className="form-check-label" htmlFor="write">{getLabel('[LABEL_WRITE]')}</label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" data-bs-dismiss="modal" aria-label="Close">
                            <button type="button" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#templatesModal">{getLabel('[LABEL_PREVIOUS]')}</button>
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#resultModal" onClick={() =>sendAction('actionForm','POST','/system/action')}  id="submitAction">{getLabel('[LABEL_SAVE]')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal modal-xl fade" id="templatesModal" tabIndex="-1" aria-labelledby="templatesModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content alternative-header">
                        <div className="modal-header alternative-header">
                            <h1 className="modal-title fs-6" id="templatesModalLabel">{getLabel('[LABEL_TEMPLATE]')} {getLabel('[LABEL_VIEW]')}</h1>
                            <span >
                                <Close style={{cursor:'pointer'}} data-bs-dismiss="modal" aria-label="Close"/>
                            </span>
                        </div>
                        <div className="modal-body">                            
                            <div className="card border-success mb-3">
                                <div className="card-header table-headers ">{getLabel('[LABEL_METADATA]')}</div>
                                <div className="card-body text-success">
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr><td>{getLabel('[LABEL_CATEGORY]')}</td><td>人</td></tr>
                                            <tr><td>{getLabel('[LABEL_TEMPLATE_NAME]')}</td><td>本人</td></tr>
                                            <tr><td>{getLabel('[LABEL_STATUS]')}</td><td>有効</td></tr>
                                            <tr><td>{getLabel('[LABEL_DESCRIPTION]')}</td><td>-</td></tr>
                                            <tr><td>{getLabel('[LABEL_LIBRARY]')}</td><td>-</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card border-success mb-3">
                                <div className="card-header table-headers ">{getLabel('[LABEL_METADATA]')}</div>
                                <div className="card-body text-success p-0">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr className="card-thead">
                                                <th>{getLabel('[LABEL_KEY]')}</th>
                                                <th>{getLabel('[LABEL_DESCRIPTION]')}</th>
                                                <th>{getLabel('[LABEL_REGEX_VALIDATION]')}</th>
                                                <th>{getLabel('[LABEL_DEFAULT]')}</th>
                                                <th>{getLabel('[LABEL_REQUIRED]')}</th>
                                                <th>{getLabel('[LABEL_MULTIPLE]')}</th>
                                                <th>{getLabel('[LABEL_EDITABLE]')}</th>
                                                <th>{getLabel('[LABEL_READ]')}</th>
                                                <th>{getLabel('[LABEL_WRITE]')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr><td>注文番号</td><td>注文番号</td><td>[A-F0-9]{10}</td><td></td><td><input className="form-check-input rounded-circle " type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><span className="badge">T1</span></td><td>...</td></tr>
                                            <tr><td>JP追跡番号</td><td></td><td>[A-F0-9]{10}</td><td></td><td><input className="form-check-input rounded-circle " type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><span className="badge">T1</span></td><td>...</td></tr>
                                            <tr><td>海外追跡番号</td><td></td><td>[A-F0-9]{10}</td><td></td><td><input className="form-check-input rounded-circle " type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><span className="badge">T1</span></td><td>...</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card border-success mb-3">
                                <div className="card-header table-headers ">
                                    <div className="row">
                                        <div className="col-auto  me-auto">{getLabel('[LABEL_ACTION]')}</div>
                                        <div className="col-auto" data-bs-dismiss="modal">
                                            <a href="#actionsModal" data-bs-target="#actionsModal" data-bs-toggle="modal" className="positive-icon"><AddCircleOutlined/></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body text-success p-0">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr className="card-thead">
                                                <th>{getLabel('[LABEL_ACTION_NAME]')}</th>
                                                <th>{getLabel('[LABEL_DESCRIPTION]')}</th>
                                                <th>{getLabel('[LABEL_INPUT]')}</th>
                                                <th>{getLabel('[LABEL_OUTPUT]')}</th>
                                                <th>{getLabel('[LABEL_TIMEOUT]')}</th>
                                                <th>{getLabel('[LABEL_UNIT]')}</th>
                                                <th>API</th>
                                                <th>{getLabel('[LABEL_READ]')}</th>
                                                <th>{getLabel('[LABEL_WRITE]')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {actions.map( action => (
                                                <tr>
                                                    <td>{action.name}</td>
                                                    <td>{action.description}</td>
                                                    <td>{action.inputAction}</td>
                                                    <td>{action.outputAction}</td>
                                                    <td>{action.timeout}</td>
                                                    <td>{action.measure}</td>
                                                    <td>{action.library}</td>
                                                    {action.read==true ?(
                                                        <td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" disabled checked/></td>
                                                    ):( 
                                                        <td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" disabled/></td>
                                                    )}                                                
                                                    {action.write==true ?(
                                                        <td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" disabled checked/></td>
                                                    ):( 
                                                        <td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" disabled/></td>
                                                    )} 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card border-success mb-3">
                                <div className="card-header table-headers ">関連情報</div>
                                <div className="card-body text-success p-0">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr className="card-thead">
                                                <th>{getLabel('[LABEL_KEY]')}</th>
                                                <th>{getLabel('[LABEL_DESCRIPTION]')}</th>
                                                <th>{getLabel('[LABEL_TEMPLATE]')}</th>
                                                <th>{getLabel('[LABEL_REQUIRED]')}</th>
                                                <th>{getLabel('[LABEL_MULTIPLE]')}</th>
                                                <th>{getLabel('[LABEL_EDITABLE]')}</th>
                                                <th>{getLabel('[LABEL_READ]')}</th>
                                                <th>{getLabel('[LABEL_WRITE]')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr><td>作成者</td><td></td><td><span className="badge">T1</span><span className="badge">T2</span></td><td><input className="form-check-input rounded-circle" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td></td><td>...</td></tr>
                                            <tr><td>発送人</td><td></td><td><span className="badge">T1</span></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td><input className="form-check-input rounded-circle disabled" type="checkbox" value="" id="flexCheckDefault" checked disabled/></td><td></td><td>...</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">{getLabel('[LABEL_BACK]')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container-fluid  my-2 generic-sec">
                    <h3>{getLabel('[LABEL_TEMPLATE]')}</h3>
                    <FilterBar tags="true" dateRange="true" createBtn="true" modal="#templateModal"  />
                    <div className="container-fluid rounded h-75 p-0 bg-white">
                        <table className="table table-borderless  table-list">
                            <thead>
                                <tr className="table-headers">
                                    <td>{getLabel('[LABEL_CATEGORY]')}</td>
                                    <td>{getLabel('[LABEL_NAME]')}</td>
                                    <td>{getLabel('[LABEL_STATUS]')}</td>
                                    <td>作成日</td>
                                    <td>更新日</td>
                                    <td>作成者</td>
                                    <td>説明</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr data-bs-toggle="modal" data-bs-target="#templatesModal"  onClick={handleForceupdateMethod}><td>人</td><td>本人</td><td>有効</td><td>2022-11-25</td><td>2022-11-26</td><td>Admin</td><td>１人間のHOLON世界でのDigitalTwin、その他社会...</td></tr>
                                <tr data-bs-toggle="modal" data-bs-target="#templatesModal"><td>人</td><td>ポジション・役職：会社代表者</td><td>有効</td><td>2022-11-25</td><td>2022-11-26</td><td>Admin</td><td></td></tr>
                                <tr data-bs-toggle="modal" data-bs-target="#templatesModal"><td>人</td><td>ポジション・役職：BetterLife株式会社：業務担当職</td><td>有効</td><td>2022-11-25</td><td>2022-11-26</td><td>Admin</td><td></td></tr>
                                <tr data-bs-toggle="modal" data-bs-target="#templatesModal"><td>人</td><td>ポジション・役職：BetterLife株式会社：営業職</td><td>有効</td><td>2022-11-25</td><td>2022-11-26</td><td>Admin</td><td></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
function forceUpdateHandler(){
    this.forceUpdate();
}
export default SystemTemplate;