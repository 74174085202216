import actionWallet from "./action";

const initialState = {
    loadingListWallet: false,
    loadingCreateWallet: false,
    loadingUpdateWallet: false,
    loadingWalletDetail: false,
    listWallet: {},
    holonTemplateDetail: {},
    permission: 0
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionWallet.GET_WALLET_LIST:
            return {
                ...state,
                loadingListWallet: true
            }
        case actionWallet.GET_PERMISSION_WALLET_SUCCESS:
            return {
                ...state,
                permission: action.payload
            }
        case actionWallet.GET_WALLET_LIST_SUCCESS:
            return {
                ...state,
                loadingListWallet: false,
                listWallet: action.payload
            }
        case actionWallet.GET_WALLET_LIST_FAILED:
            return {
                ...state,
                loadingListWallet: false
            }
        case actionWallet.GET_HOLON_TEMPLATE_DETAIL_WITH_WALLET_SUCCESS:
            return {
                ...state,
                holonTemplateDetail: action.payload
            }
        case actionWallet.CREATE_WALLET:
            return {
                ...state,
                loadingCreateWallet: true
            }
        case actionWallet.CREATE_WALLET_FAILED:
            return {
                ...state,
                loadingCreateWallet: false
            }
        case actionWallet.CREATE_WALLET_SUCCESS:
            return {
                ...state,
                loadingCreateWallet: false
            }
        case actionWallet.UPDATE_WALLET:
            return {
                ...state,
                loadingUpdateWallet: true
            }
        case actionWallet.UPDATE_WALLET_FAILED:
            return {
                ...state,
                loadingUpdateWallet: false
            }
        case actionWallet.UPDATE_WALLET_SUCCESS:
            return {
                ...state,
                loadingUpdateWallet: false
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;