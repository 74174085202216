import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import CompanyTemplateContainer from 'shared/container/company-template/CompanyTemplateContainer';

function CompanyTemplate() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t('company_template.title');
    }, []);
    return (
        <CompanyTemplateContainer />
    )
}

export default CompanyTemplate