import React from "react";
// import IconCheckBoxTable from "../common/icons/IconCheckBoxTable";
// import IconChecked from "../common/icons/IconChecked";
import classes from "./CustomTable.module.scss";
import IconChecked from "assets/icon/IconChecked";
import IconCheck from "assets/icon/IconCheck";

function CustomTableRow(props) {
    const {
        dataHead = [],
        row: item,
        index,
        isBackgroundRow,
        handleSelect,
        dataSelected = [],
        fieldId = "Id",
        isCheckBox,
    } = props;

    const handleSelectRow = (e) => {
        handleSelect(e);
    };

    let isChecked = (check) =>
        dataSelected.findIndex((x) => x[fieldId] == check[fieldId]) != -1
            ? true
            : false;

    return (
        <tr
            className={`${classes.table_row} ${isBackgroundRow ? classes.background_even : ""}`}
        >
            {isCheckBox && (
                <td
                    className={`${classes["body_checkbox"]} ${classes['custom_column_checkbox']}`}
                >
                    <div
                        onClick={() => {
                            handleSelectRow && handleSelectRow(item);
                        }}
                    >
                        {!isChecked(item) ? (
                            <IconCheck fontSize={15} />
                        ) : (
                            <IconChecked color="#5CBBFF" fontSize={15} />
                        )}
                    </div>
                </td>
            )}

            {dataHead?.map((c, key) => {
                return c?.component ? (
                    <td
                        key={key}
                        style={{
                            minWidth: c?.minWidth,
                            maxWidth: c?.maxWidth,
                            width: c?.width,
                            textAlign: c?.align ?? "center",
                            fontWeight: c?.fontWeight ?? 400,
                            fontSize: c?.fontSize ?? '12px !important',
                            color: "#000",
                            ...c?.styleBody,
                            verticalAlign: "middle",
                            padding: '4px 20px'
                        }}
                    >
                        <c.component
                            data={item}
                            dataId={item[c.field]}
                            fieldId="fieldId"
                            stt={index}
                        >
                            {item[c.field]}
                        </c.component>
                    </td>
                ) : (
                    <td
                        key={key}
                        style={{
                            minWidth: c?.minWidth,
                            maxWidth: c?.maxWidth,
                            width: c?.width,
                            textAlign: c?.align ?? "center",
                            fontWeight: c?.fontWeight ?? 400,
                            fontSize: c?.fontSize ?? 13,
                            color: "#000",
                            ...c?.styleBody,
                            padding: '4px 20px',
                            verticalAlign: "middle",
                        }}
                    >
                        <div title={item[c.field]} className={classes['text_two_line']}>
                            {item[c.field]}
                        </div>
                    </td>
                );
            })}
        </tr>
    );
}

export default CustomTableRow;
