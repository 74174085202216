import useTrans from 'hooks/use-trans'
import React from 'react'
import CargoContainer from 'shared/container/cargo/CargoContainer';

function CargoPage() {
    const { t } = useTrans();
    document.title = t("cargo.cargo_management")
    return (
        <CargoContainer />
    )
}

export default CargoPage