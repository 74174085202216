import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import styles from './Form.module.scss';
import useTrans from 'hooks/use-trans';

function FormTextarea (props) {
    const {trans} = useTrans();
    const {
        defaultValue = '',
        fieldName,
        validate = [],
        placeholder = '',
        disabled = false,
        register,
        type = "text",
        callback = null,
        maxLength = ''
    } = props;
    const { formState: { errors }, control } = useFormContext();
    
    return ( 
        <Controller
            control={control}
            name={fieldName}
            rules={{ validate: Validator.genValidate(validate, fieldName) }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                const onChangeValue = (e) => {
                    onChange(e.target.value);
                    callback && callback()
                }
                return <div className='h-100'>
                    <div className={`h-100 ${errors[fieldName] ? 'error' : ''}`}>
                        <textarea
                            _props={{ inputRef: ref }}
                            {...register(fieldName, {value: value ? value : defaultValue})}
                            type={type}
                            name={fieldName}
                            onChange={onChangeValue}
                            onBlur={onBlur}
                            defaultValue={defaultValue}
                            placeholder={placeholder}
                            required={false}
                            readOnly={disabled}
                            className={`w-100 ${styles['form-textarea']}`}
                        />
                    </div>
                    { errors[fieldName] && <span className={styles['error-msg']}>{Validator.renderMessage(trans(errors[fieldName]?.message), {0: trans(fieldName), 1: trans(maxLength)})}</span> }
                </div>
            }}
        />
    )
}

export default FormTextarea;