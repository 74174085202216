import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import actionTransaction from 'redux/transaction/action';
import classes from './Transaction.module.scss';
import useTrans from 'hooks/use-trans';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import IconEdit from 'assets/icon/IconEdit';
import EventRegister, { EVENT_SHOW_COPY_DATA_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_PREVIEW_FILE_POPUP, EVENT_SHOW_TRANSACTION_CREATE_POPUP, EVENT_SHOW_TRANSACTION_UPDATE_POPUP } from 'utils/EventRegister';
import { convertNumberToString, convertEnumValueToDisplay } from 'utils/String';
import { getMessageCommon, showPopupNotification } from 'utils/Utils';
import Constants from 'utils/Constants';
import Validator from 'utils/Validator';
import moment from 'moment';
import IconCopy from 'assets/icon/IconCopy';
import useRouter from 'hooks/use-router';

function TransactionDetailPopup(props) {
    const { payload, showVisible } = props;
    const dispatch = useDispatch();
    const { t } = useTrans();
    const router = useRouter();
    const [transactionDetail, setTransactionDetail] = useState(null)
    const [loadingGetDetail, setLoadingGetDetail] = useState(false)
    const [loadingReject, setLoadingReject] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [loadingCloneHolonInstance, setLoadingCloneHolonInstance] = useState(false)

    useEffect(() => {
        getDetail()
    }, [payload?.id])

    const getDetail = () => {
        if (payload?.id) {
            setLoadingGetDetail(true)
            dispatch({
                type: actionTransaction.GET_TRANSACTION_DETAIL,
                payload: payload?.id,
                callback: (data) => {
                    setTransactionDetail(data);
                    setLoadingGetDetail(false)
                }
            })
        }
    }

    const handleEdit = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_TRANSACTION_UPDATE_POPUP,
            payload: {
                title: t("transaction.transaction_details"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                id: payload?.id,
                callback: () => {
                },
            }
        })
    }

    const dataHeadCell = [
        {
            label: t('transaction.transaction_id'),
            data: transactionDetail?.transactionId
        },
        {
            label: t('transaction.transaction_type'),
            data: convertEnumValueToDisplay(transactionDetail?.transactionType, Constants.TRANSACTION_CODE.TRANSACTION_TYPE)
        },
        {
            label: t('transaction.status'),
            data: convertEnumValueToDisplay(transactionDetail?.status, Constants.TRANSACTION_CODE.STATUS)
        },
        {
            label: t('transaction.amount_of_money'),
            data: convertNumberToString(transactionDetail?.amount, ",", false)
        },
        {
            label: t('transaction.transaction_date_and_time'),
            data: transactionDetail?.transactionTime ? moment(transactionDetail?.transactionTime).format(Constants.FORMAT_DATE_TIME.DATE) : ''
        },
        {
            label: t('transaction.transaction_system'),
            data: convertEnumValueToDisplay(transactionDetail?.transactionBy, Constants.TRANSACTION_CODE.TRANSACTION_BY)
        },
        {
            label: t('transaction.account_info'),
            child: [
                {
                    label: t('transaction.bank_name'),
                    data: transactionDetail?.bankName
                },
                {
                    label: t('transaction.branch_name'),
                    data: transactionDetail?.branchName
                },
                {
                    label: t('transaction.account_type'),
                    data: convertEnumValueToDisplay(transactionDetail?.accountType, Constants.TRANSACTION_CODE.ACCOUNT_TYPE)
                },
                {
                    label: t('transaction.account_no'),
                    data: transactionDetail?.accountNo
                },
                {
                    label: t('transaction.account_owner'),
                    data: transactionDetail?.accountOwner
                }
            ]
        },
        {
            label: t('transaction.account_owner_kana'),
            data: transactionDetail?.accountOwnerKana
        },
        {
            label: t('transaction.wallet'),
            data: transactionDetail?.wallet
        },
        {
            label: t('transaction.order'),
            data: transactionDetail?.order
        },
        {
            label: t('transaction.authorizer_name'),
            data: transactionDetail?.authorizerName
        },
        {
            label: t('transaction.remark'),
            data: transactionDetail?.remark
        },
        {
            label: t('transaction.evidence'),
            data: transactionDetail?.evidence?.split(','),
            isAttachment: true
        },
    ];

    const handleApproveTransaction = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_POPUP_CONFIRM,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                message: Validator.renderMessage(t("I0006"), {
                    0: t("transaction.approve")
                }),
                callback: () => {
                    setLoadingApprove(true);
                    dispatch({
                        type: actionTransaction.APPROVE_TRANSACTION,
                        payload: payload?.id,
                        callback: {
                            success: (response, error) => {
                                setLoadingApprove(false);
                                response?.data?.reload == Constants.RESPONSE_ACTION.RELOAD.VALUE && getDetail()
                                showPopupNotification({
                                    typePopup: 'message',
                                    message: getMessageCommon({
                                        response: response,
                                        error: error,
                                        actionName: t("transaction.approve")
                                    }),
                                    newWindow: true,
                                })
                            },
                        },
                    })
                }
            },
        });
    }

    const handleRejectTransaction = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_POPUP_CONFIRM,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                message: Validator.renderMessage(t("I0006"), {
                    0: t("transaction.reject")
                }),
                callback: () => {
                    setLoadingReject(true);
                    dispatch({
                        type: actionTransaction.REJECT_TRANSACTION,
                        payload: payload?.id,
                        callback: {
                            success: (response, error) => {
                                setLoadingReject(false);
                                response?.data?.reload == Constants.RESPONSE_ACTION.RELOAD.VALUE && getDetail()
                                showPopupNotification({
                                    typePopup: 'message',
                                    message: getMessageCommon({
                                        response: response,
                                        error: error,
                                        actionName: t("transaction.approve")
                                    }),
                                    newWindow: true,
                                })
                            },
                        },
                    })
                }
            },
        });
    }

    const bottomButtons = () => {
        return (
            <div className={classes['bottom-buttons']}>
                <Button onClick={handleApproveTransaction} disabled={loadingGetDetail || loadingReject} loading={loadingApprove} type='button' title={t("transaction.approve")} />
                <Button onClick={handleRejectTransaction} disabled={loadingGetDetail || loadingApprove} loading={loadingReject} type='button' title={t("transaction.reject")} />
            </div>
        );
    };

    const handleOpenPreview = (attachment) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_PREVIEW_FILE_POPUP,
            newWindow: true,
            payload: {
                linkFile: attachment,
                linkFileName: condenseFilename(attachment),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
            }
        })
    }

    function condenseFilename(initial) {
        initial = initial?.split("/")?.[initial?.split("/")?.length - 1]
        var split = initial?.split('.');
        if (split?.length > 0) {
            let filename = split?.[0];
            var extension = initial?.split(".")[1];
            if (filename.length > 105) {
                return filename.substring(0, 100) + '...' + extension;
            }
        }
        return initial;
    }

    const renderContent = () => {
        return (
            <div className={classes['created-holon-instance']}>
                <Box>
                    <BoxBody style={{ borderRadius: 10 }} className={classes['data-attributes-body']}>
                        {dataHeadCell.map(cell => (
                            <>
                                <BoxRow>
                                    <BoxLabel minWidth="250px">{cell?.label}</BoxLabel>
                                    {cell.isAttachment ?
                                        (cell.data?.length > 0 ?
                                            <Box>
                                                {cell.data?.map((attachment, index) => {
                                                    return <BoxRow key={index}>
                                                        <BoxContent>
                                                            <Button
                                                                className={classes['btn-attachment']}
                                                                onClick={() => handleOpenPreview(attachment)}
                                                                key={index}
                                                                variant='outlined'
                                                                disabled={attachment ? false : true}
                                                                title={condenseFilename(attachment)} />
                                                        </BoxContent>
                                                    </BoxRow>
                                                })}
                                            </Box> : <></>
                                        )
                                        :
                                        <BoxContent>
                                            {cell?.data}
                                        </BoxContent>
                                    }
                                </BoxRow>
                                {cell?.child &&
                                    cell?.child.map(child => (
                                        <BoxRow>
                                            <div className={classes['child-attribute']}>
                                                <BoxLabel minWidth="250px">{child?.label}</BoxLabel>
                                            </div>
                                            <BoxContent>
                                                {child?.data}
                                            </BoxContent>
                                        </BoxRow>
                                    ))
                                }
                            </>
                        ))}
                    </BoxBody>
                </Box>
            </div>
        );
    }

    const handleCloneInstance = (data) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_COPY_DATA_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoadingCloneHolonInstance(false);
                    setTimeout(() => {
                        EventRegister.emit(EVENT_SHOW_POPUP, {
                            open: true,
                            type: EVENT_SHOW_TRANSACTION_CREATE_POPUP,
                            payload: {
                                title: t("transaction.transaction_create"),
                                showHeader: false,
                                customStyle: {
                                    padding: 0,
                                    borderTop: 0
                                },
                                dataClone: data,
                                callback: () => {
                                    dispatch({
                                        type: actionTransaction.GET_LIST_TRANSACTION,
                                        payload: {
                                            ...router.getAll(),
                                        }
                                    })
                                },
                            }
                        })
                    }, 1);
                }
            }
        })
    }

    return (
        <BoxContentPopup minWidth="1000px">
            <WrapContentBody
                title={payload?.title} showVisible={showVisible} payload={payload}
                actionSectionTop={<>
                    <Button onClick={handleEdit} color='gray' startIcon={<IconEdit color="currentColor" />} />
                    <Button loading={loadingCloneHolonInstance} onClick={() => handleCloneInstance(transactionDetail)} color='gray' startIcon={<IconCopy color="currentColor" />} />
                </>}
                actionSectionBottom={bottomButtons()}
            >
                {renderContent()}
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default TransactionDetailPopup