const { default: actionProduct } = require("./action");

const initialState = {
    listProduct: {},
    loadingGetDetail: false,
    loadingCreate: false,
    loadingUpdate: false,
    loadingDelete: false,
    loadingGetList: false,
    product: {}
}

const myReducer = (state = initialState, action) => {
    switch (action?.type) {
        case actionProduct.CREATE_PRODUCT:
            return {
                ...state,
                loadingCreate: true
            }
        case actionProduct.CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingCreate: false
            }
        case actionProduct.CREATE_PRODUCT_FAILED:
            return {
                ...state,
                loadingCreate: false
            }
        case actionProduct.UPDATE_PRODUCT:
            return {
                ...state,
                loadingUpdate: true
            }
        case actionProduct.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingUpdate: false
            }
        case actionProduct.UPDATE_PRODUCT_FAILED:
            return {
                ...state,
                loadingUpdate: false
            }
        case actionProduct.DELETE_PRODUCT:
            return {
                ...state,
                loadingDelete: true
            }
        case actionProduct.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingDelete: false
            }
        case actionProduct.DELETE_PRODUCT_FAILED:
            return {
                ...state,
                loadingDelete: false
            }
        case actionProduct.GET_LIST_PRODUCT:
            return {
                ...state,
                loadingGetList: true
            }
        case actionProduct.GET_LIST_PRODUCT_SUCCESS:
            return {
                ...state,
                listProduct: action.payload,
                loadingGetList: false
            }
        case actionProduct.GET_DETAIL_PRODUCT:
            return {
                ...state
            }
        case actionProduct.GET_DETAIL_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;