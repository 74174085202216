/* eslint-disable react/react-in-jsx-scope */
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import FormFile from 'shared/components/custom-form/FormFile';
import FormImage from 'shared/components/custom-form/FormImage';
import FormInput from 'shared/components/custom-form/FormInput';
import FormRadio from 'shared/components/custom-form/FormRadio';
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE } from 'utils/EventRegister';
import Validator from 'utils/Validator';
import WrapContentBody from '../custom-body/WrapContentBody';
import classes from './CreatedHolonInstance.module.scss';
import { convertRegexToOptions, getMessageCommon, showPopupNotification } from 'utils/Utils';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import FormConnections from './FormConnections';
import FormAttributes from './FormAttributes';
import FormAttachment from './FormAttachment';
import FormCargoProduct from './FormCargoProduct';
import actionCargo from 'redux/cargo/action';
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash';
import EventPopup from 'utils/EventPopup';
import { useSelector } from 'react-redux';

function UpdateHolonInstancePopup(props) {
    const { showVisible, payload } = props;
    let defaultValues = {
        attributes: [],
        attachments: [],
        connections: [],
        actions: [],
        CargoProduct: [],
    }
    const methods = useForm({
        defaultValues: defaultValues
    });
    const router = useRouter();
    const control = methods.control;
    const refPass = useRef(false);
    const { t } = useTrans()
    const dispatch = useDispatch();
    const [holonInStanceDetail, setHolonInStanceDetail] = useState({})
    const [holonTemplateDetail, setHolonTemplateDetail] = useState({})
    const [loadingUpdateInstance, setLoadingUpdateInstance] = useState(false);
    const [dataDefault, setDataDefault] = useState({
        attributes: [],
        connections: [],
        cargoProduct: [],
        attachments: []
    });

    const {
        hoLonTemplateId 
    } = useSelector(state =>state?.holonInstance)

    useEffect(() => {
        if (payload?.holonInstanceId) {
            dispatch({
                type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                payload: payload?.holonInstanceId,
                callback: (data) => {
                    setHolonInStanceDetail(data);

                }
            })
        }
        return () => {
            setHolonInStanceDetail({})
        }
    }, [payload?.holonInstanceId])

    useEffect(() => {
        if (payload?.holonTemplateId) {
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
                payload: payload?.holonTemplateId,
                callback: (data) => {
                    setHolonTemplateDetail(data);
                }
            })
        }

        return () => {
            setHolonTemplateDetail({})
        }
    }, [payload?.holonTemplateId])

    const renderInput = (value, index) => {
        switch (value?.elementType) {
            case Constants.ELEMENT_TYPE.TEXT.VALUE:
                let validate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validate.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.ADDRESS.VALUE:
                let validateAddreess = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAddreess.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateAddreess]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.EMAIL.VALUE:
                let validateEmail = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateEmail.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validateEmail]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.FILE.VALUE:
                let validateFile = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateFile.push(Validator.requiredInput)
                }
                return <FormFile disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateFile]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.IMAGE.VALUE:
                let validateImage = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateImage.push(Validator.requiredInput)
                }
                return <FormImage disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateImage]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.NUMBER.VALUE:
                let validateNumber = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateNumber.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} format={Constants.FormInputFormat.NUMBER.VALUE} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateNumber]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.URL.VALUE:
                let validateUrl = [Validator.URL];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUrl.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateUrl]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATE.VALUE:
                let validateDate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDate.push(Validator.requiredInput)
                }
                return <FormDatePicker disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATETIME.VALUE:
                let validateDateTime = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDateTime.push(Validator.requiredInput)
                }
                return <FormDatePicker disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDateTime]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.AUTOGEN.VALUE:
                let validateAutogen = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAutogen.push(Validator.requiredInput)
                }
                return <FormInput disabled validate={[Validator.customValidator(value?.regex, value?.msg), ...validateAutogen]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.UNIQUE.VALUE:
                let validateUnique = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUnique.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateUnique]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE:
                let validatSelectTemplate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validatSelectTemplate.push(Validator.requiredInput)
                }
                return <FormSelectTemplate isMulti={value?.isMultiSelected == "1" ? true : false} disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validatSelectTemplate]} fieldName={`attributes.${index}.${value?.key}`} />

            case Constants.ELEMENT_TYPE.SELECT.VALUE:
                let optionSelect = convertRegexToOptions(value?.regex);
                if (value?.isMultiSelected == "1") {
                    return <FormSelect disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} isMultiSelected options={optionSelect} validate={[]} fieldName={`attributes.${index}.${value?.key}`} />
                } else {
                    return <FormSelect disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} options={optionSelect} validate={[]} fieldName={`attributes.${index}.${value?.key}`} />
                }
            case Constants.ELEMENT_TYPE.RADIO.VALUE:
                let validateRadio = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateRadio.push(Validator.requiredInput)
                }
                let optionRadio = convertRegexToOptions(value?.regex);
                return <FormRadio disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} options={optionRadio} validate={[...validateRadio]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.TEL.VALUE:
                let validateTel = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateTel.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateTel]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.PASSWORD.VALUE:
                let validatePass = [];
                let validatePassConfirm = [
                    Validator.confirmPassword(methods.watch(`attributes.${index}.${value?.key}`))
                ];

                if (!refPass.current) {
                    validatePass = [];
                    validatePassConfirm = [];
                } else {
                    validatePass.push(Validator.customValidator(value?.regex, value?.msg))
                    validatePassConfirm.push(Validator.confirmPassword(methods.watch(`attributes.${index}.${value?.key}`)))
                }

                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validatePass.push(Validator.requiredInput)
                }

                const onFocus = () => {
                    refPass.current = true;
                }
                return <div className={classes['attributes-password']}>
                    <FormInput onFocus={onFocus} showIconPassword={refPass.current} valueCurrent={value[value?.key]} disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} type="password" validate={[...validatePass]} fieldName={`attributes.${index}.${value?.key}`} />
                    <FormInput showIconPassword={refPass.current} disabled={
                        (value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true) || refPass.current ? false : true} type="password" validate={validatePassConfirm} fieldName={`attributes.${index}.confirmPassword`} />
                </div>
            case Constants.ELEMENT_TYPE.CHECKBOX.VALUE:
                let validateCheckbox = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateCheckbox.push(Validator.requiredCheckbox)
                }
                return <FormCheckbox disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[...validateCheckbox]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.TEXT_AREA.VALUE:
                let validateTxtArea = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateTxtArea.push(Validator.requiredCheckbox)
                }
                return <FormTextArea validate={[...validateTxtArea]} fieldName={`attributes.${index}.${value?.key}`} />

            default:
                let validateDefault = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDefault.push(Validator.requiredInput)
                }
                return <FormInput disabled={value?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDefault]} fieldName={`attributes.${index}.${value?.key}`} />
        }
    }

    const {
        fields: fieldsHas,
    } = useFieldArray({
        control, name: "attributes"
    });

    const {
        fields: fieldsConnection,
    } = useFieldArray({
        control, name: "connections"
    });

    const {
        fields: fieldsAttachment,
        append: appendAttachment,
        remove: removeAttachments,
    } = useFieldArray({
        control, name: "attachments"
    });

    useEffect(() => {

        if (!payload?.dataPreset?.templateId) {
            let attachments = [];
            if (holonInStanceDetail?.id) {
                methods.setValue("attachments", holonInStanceDetail?.attachments);
                attachments = holonInStanceDetail?.attachments;
            }
            if (holonTemplateDetail?.validAttributes?.length > 0 && holonInStanceDetail?.id) {
                let dataAttributes = holonTemplateDetail?.validAttributes?.map(x => {
                    let indexDetail = holonInStanceDetail?.attributes?.findIndex(attribute => attribute.name == x?.key);
                    // if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                    //     return {
                    //         ...x,
                    //         [x?.key]: holonInStanceDetail.attributes[indexDetail]?.value == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false
                    //     }
                    // }
                    if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                        return {
                            ...x,
                            [x?.key]: Constants.PASSWORD.VALUE,
                            currentPassword: holonInStanceDetail.attributes[indexDetail]?.value,
                            confirmPassword: undefined
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                        return {
                            ...x,
                            [x?.key]: holonInStanceDetail?.attributes[indexDetail]?.value?.split(",")
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                        return {
                            ...x,
                            [x?.key]: holonInStanceDetail?.attributes[indexDetail]?.value?.split(",")
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_AIR.VALUE) {
                        return {
                            ...x,
                            [x?.key]: holonInStanceDetail?.attributes[indexDetail]?.value?.split("|")
                        }
                    }
                    if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_SEA.VALUE) {
                        return {
                            ...x,
                            [x?.key]: holonInStanceDetail?.attributes[indexDetail]?.value?.split("|")
                        }
                    }
                    return {
                        ...x,
                        [x?.key]: holonInStanceDetail.attributes[indexDetail]?.value
                    }
                });
                methods.setValue("attributes", dataAttributes)

                let connections = holonInStanceDetail?.connections?.map(x => {
                    return {
                        ...x,
                        holonsId: x?.holons?.length > 0 ? x?.holons[0]?.id : null,
                    }
                });

                const grouped = connections.reduce((rv, x) => {
                    const groupIndex = rv?.findIndex((a) => a.key === x['type']);
                    if (groupIndex !== -1) {
                        if (x?.holons?.length > 0) {
                            x?.holons?.map(_data => {
                                rv[groupIndex]?.holons?.push(_data);
                            });
                        }
                    } else {
                        const newGroup = {
                            key: x['type'],
                            ...x,
                            holons: [],
                        };
                        if (x?.holons?.length > 0) {
                            newGroup.holons = x?.holons?.map(hol => {
                                return {
                                    attributes: hol?.attributes,
                                    id: hol?.id,
                                    name: hol?.name,
                                }
                            });
                        }
                        rv.push(newGroup);
                    }
                    return rv;
                }, []);

                let dataConnections = holonTemplateDetail?.validConnections?.map(x => {
                    let indexDetail = grouped?.findIndex(connection => connection.type == x?.key);
                    return {
                        ...x,
                        instance: grouped[indexDetail]?.holons ?? []
                    }
                });
                methods.setValue("connections", dataConnections);

                setDataDefault({
                    ...dataDefault,
                    attributes: dataAttributes,
                    connections: dataConnections,
                    attachments: attachments
                })
            }
        } else {
            let dataPreset = payload?.dataPreset?.data;
            let objectPresetAttributes = Object.keys(dataPreset?.attributes)?.map(key => {
                return {
                    name: key,
                    value: dataPreset?.attributes[key]
                }
            });
            methods.setValue("attributes", holonTemplateDetail?.validAttributes?.map(x => {
                let indexDetail = objectPresetAttributes?.findIndex(attribute => attribute.name == x?.key);
                // if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                //     return {
                //         ...x,
                //         [x?.key]: objectPresetAttributes[indexDetail]?.value == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false
                //     }
                // }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectPresetAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: objectPresetAttributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                    return {
                        ...x,
                        [x?.key]: Constants.PASSWORD.VALUE,
                        currentPassword: objectPresetAttributes[indexDetail]?.value ?? "",
                    }
                }
                if (holonTemplateDetail?.validAttributes[indexDetail]?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                    return {
                        ...holonTemplateDetail?.validAttributes[indexDetail],
                        [x?.name]: Constants.PASSWORD.VALUE,
                        currentPassword: x?.value,
                    }
                }
                return {
                    ...x,
                    [x?.key]: objectPresetAttributes[indexDetail]?.value ?? ""
                }
            }))

            let holonInstance = Object.keys(dataPreset?.connections)?.map(key => {
                return {
                    instanceList: dataPreset?.connections[key]?.map(x => {
                        return {
                            id: x
                        }
                    }),
                    key: key
                };
            });

            let presetConnection = []
            holonTemplateDetail?.validConnections?.forEach(x => {
                let index = holonInstance?.findIndex(v => v?.key == x?.key);
                if (index != -1) {
                    return presetConnection.push({
                        ...x,
                        instance: holonInstance[index]?.instanceList
                    })
                }
            })
            methods.setValue("connections", presetConnection);
        }
    }, [holonTemplateDetail, holonInStanceDetail, payload?.dataPreset])

    const onSubmit = (data) => {
        let attributes = data?.attributes?.map(x => {
            // if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
            //     return {
            //         value: x?.[x?.key] ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
            //         name: x?.key,
            //         elementType: x?.elementType
            //     }
            // }
            if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                if (x?.[x?.key] == Constants.PASSWORD.VALUE) {
                    return {
                        value: x?.currentPassword,
                        name: x?.key,
                        elementType: x?.elementType
                    }
                }
            }

            if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                return {
                    value: x?.[x?.key]?.length > 0 ? x?.[x?.key]?.join(",") : "",
                    name: x?.key,
                    elementType: x?.elementType
                }
            }
            if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                return {
                    value: x?.[x?.key]?.length > 0 ? x?.[x?.key]?.join(",") : "",
                    name: x?.key,
                    elementType: x?.elementType
                }
            }

            if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_AIR.VALUE) {
                return {
                    value: x?.[x?.key].join("|"),
                    name: x?.key,
                    elementType: x?.elementType
                }
            }
            if (x?.elementType == Constants.ELEMENT_TYPE.ADDRESS_SEA.VALUE) {
                return {
                    value: x?.[x?.key].join("|"),
                    name: x?.key,
                    elementType: x?.elementType
                }
            }

            let description = "";
            if (localStorage.getItem(x?.key) && localStorage.getItem(x?.key) != "undefined") {
                description = localStorage.getItem(x?.key);
                localStorage.removeItem(x?.key);
            }
            return {
                value: x?.[x?.key],
                name: x?.key,
                elementType: x?.elementType,
                description: description
            }
        });
        let connections = [];
        data?.connections?.map(x => {
            let object = {
                name: x?.key,
                type: x?.key,
                fromName: x?.fromName,
                toName: x?.toName,
                role: x?.role
            }
            if (x?.instance?.length == 0) {
                return connections.push({
                    ...object,
                    holons: [
                    ]
                })
            } else {
                x?.instance?.map(holon => {
                    return connections.push({
                        ...object,
                        holons: [
                            {
                                id: holon?.id
                            }
                        ]
                    })
                })
            }
        });
        let params = {
            name: holonTemplateDetail?.name,
            description: holonTemplateDetail?.description,
            templateId: holonTemplateDetail?.id,
            type: holonTemplateDetail?.type,
            attributes: attributes,
            connections: connections,
            id: holonInStanceDetail?.id,
            attachments: data?.attachments
        }

        if (payload?.dataPreset?.templateId) {
            dispatch({
                type: actionsHolonInstance.CREATE_HOLON_INSTANCE,
                payload: params,
                callback: {
                    success: () => {
                        showVisible(false)
                        payload?.callback();
                        showPopupNotification({
                            callback: () => { },
                            typePopup: 'message',
                            newWindow: false,
                            message: t("E0041")
                        })
                    },
                    failed: (msg) => {
                        showPopupNotification({
                            callback: () => { },
                            typePopup: 'message',
                            newWindow: true,
                            message: msg
                        })
                    }
                }
            })
        } else if (payload?.dataBox?.boxEdit?.actionType) {
            showPopupNotification({
                typePopup: 'update',
                newWindow: true,
                callback: () => {
                    setLoadingUpdateInstance(true);
                    dispatch({
                        type: payload?.dataBox?.boxEdit?.actionType,
                        payload: {
                            ...params,
                            mode: payload?.dataBox?.boxEdit?.actionPayload?.mode
                        },
                        callback: {
                            success: () => {
                                setLoadingUpdateInstance(false);
                                showVisible(false)
                                payload?.callback && payload?.callback();

                                if (payload?.dataBox) {
                                    payload?.dataBox?.callback?.callbackSuccess();
                                }

                                showPopupNotification({
                                    callback: () => { },
                                    typePopup: 'message',
                                    newWindow: false,
                                    message: t("E0043")
                                })
                            },
                            failed: (msg) => {
                                setLoadingUpdateInstance(false)
                                showPopupNotification({
                                    callback: () => { },
                                    typePopup: 'message',
                                    newWindow: true,
                                    message: msg
                                })
                            }
                        }
                    })
                }
            })
        }
        else {
            showPopupNotification({
                typePopup: 'update',
                callback: () => {
                    setLoadingUpdateInstance(true);
                    dispatch({
                        type: actionsHolonInstance.UPDATE_HOLON_INSTANCE,
                        payload: params,
                        callback: {
                            success: (res, error) => {
                                if (res?.data?.id) {
                                    if (false) {
                                        let CargoProduct = data?.CargoProduct?.map(mer => {
                                            return {
                                                ...mer,
                                                warehouseManagementCargoId: payload?.holonInstanceId,
                                            }
                                        });
                                        dispatch({
                                            type: actionCargo.CREATE_PRODUCT_DETAIL_CARGO,
                                            payload: {
                                                data: CargoProduct ?? [],
                                                cargoId: payload?.holonInstanceId,
                                                isEdit: true,
                                                warehouseId: params?.connections?.find(cn => cn?.type == "belong_to_warehouse")?.holons?.[0]?.id ?? null,
                                            },
                                            callback: {
                                                success: () => {
                                                    setLoadingUpdateInstance(false);
                                                    let params = {};
                                                    if (payload?.holonTemplateId) {
                                                        params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = payload?.holonTemplateId
                                                    }
                                                    if (payload?.subHolonTemplateId) {
                                                        params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = payload?.subHolonTemplateId
                                                    }
                                                    if (payload?.cId) {
                                                        params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = payload?.cId
                                                    }
                                                    if (payload?.cName) {
                                                        params[Constants.QueryParams.CONNECTION_NAME.VALUE] = payload?.cName
                                                    }
                                                    if (payload?.userCompany) {
                                                        params[Constants.QueryParams.USER_IN_COMPANY.VALUE] = payload?.userCompany
                                                    }
                                                    if (Object.keys(EventPopup.popupList.refs)?.length == 1) {
                                                        showVisible(false)
                                                        dispatch({
                                                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                                                            payload: {
                                                                ...router.getAll(),
                                                                ...params
                                                            }
                                                        });
                                                    } else {

                                                    }

                                                    if (payload?.callback) {
                                                        payload?.callback();
                                                    }
                                                    if (payload?.dataBox?.callback) {
                                                        payload?.dataBox?.callback()
                                                    }

                                                    showPopupNotification({
                                                        callback: () => { },
                                                        typePopup: 'message',
                                                        newWindow: false,
                                                        message: t("E0043")
                                                    });
                                                },
                                                failed: (msg) => {
                                                    setLoadingUpdateInstance(false)
                                                    showPopupNotification({
                                                        callback: () => { },
                                                        typePopup: 'message',
                                                        newWindow: true,
                                                        message: msg
                                                    })
                                                }
                                            }
                                        })
                                    } else {
                                        setLoadingUpdateInstance(false);
                                        if (payload?.dataBox?.callback?.callbackSuccess) {
                                            payload?.dataBox?.callback?.callbackSuccess();
                                        } else {
                                            let params = {};
                                            if (payload?.holonTemplateId && hoLonTemplateId != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                                                params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = payload?.holonTemplateId
                                            }else if(payload?.listTemplateAll){
                                                params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = 
                                                    payload?.listTemplateAll?.map(x => x?.id)?.join(",");
                                            }
                                            if (payload?.subHolonTemplateId) {
                                                params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = payload?.subHolonTemplateId
                                            }
                                            if (payload?.cId) {
                                                params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = payload?.cId
                                            }
                                            if (payload?.cName) {
                                                params[Constants.QueryParams.CONNECTION_NAME.VALUE] = payload?.cName
                                            }
                                            if (payload?.userCompany) {
                                                params[Constants.QueryParams.USER_IN_COMPANY.VALUE] = payload?.userCompany
                                            }
                                            if (payload?.requireConnectionKey) {
                                                params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = payload?.requireConnectionKey
                                            }
                                            if (payload?.requireConnectionHolonId) {
                                                params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = payload?.requireConnectionHolonId
                                            }
                                            if (Object.keys(EventPopup.popupList.refs)?.length == 1) {
                                                dispatch({
                                                    type: actionsHolonInstance.GET_HOLON_INSTANCE,
                                                    payload: {
                                                        ...router.getAll(),
                                                        ...params
                                                    }
                                                });
                                            }
                                            if (payload?.callback) {
                                                payload?.callback();
                                            }
                                        }
                                        showVisible(false);
                                        showPopupNotification({
                                            callback: () => { },
                                            typePopup: 'message',
                                            newWindow: false,
                                            message: getMessageCommon({
                                                response: res,
                                                error: error,
                                                isAction: false,
                                                messageSuccess: t("E0043")
                                            }),
                                        })
                                    }
                                } else {
                                    showPopupNotification({
                                        callback: () => { },
                                        typePopup: 'message',
                                        newWindow: false,
                                        message: getMessageCommon({
                                            response: res,
                                            error: error,
                                            isAction: false,
                                            messageSuccess: t("E0043")
                                        }),
                                    })
                                }
                            },
                            failed: (msg) => {
                                setLoadingUpdateInstance(false);
                                showPopupNotification({
                                    callback: () => { },
                                    typePopup: 'message',
                                    newWindow: true,
                                    message: msg
                                })
                            }
                        }
                    })
                },
                newWindow: true
            })

        }
    }

    const handleOpenDetail = (holonInstanceId, holonTemplateId, subHolonTemplateId) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                title: payload?.titleDetailPopup ? payload?.titleDetailPopup : t("holon.detail_holon_instance"),
                titleUpdatePopup: payload?.title ? payload?.title : null,
                holonInstanceId: holonInstanceId,
                holonTemplateId: holonTemplateId,
                subHolonTemplateId: subHolonTemplateId,
                dataBox: {
                    ...payload?.dataBox
                },
                isCargo: payload?.isCargo,
                isHolonTemplateName: payload?.isHolonTemplateName,
                permissionUpdate: payload?.permissionUpdate,
                permissionClone: payload?.permissionClone,
                userCompany: payload?.userCompany,
                requireConnectionKey: payload?.requireConnectionKey,
                requireConnectionHolonId: payload?.requireConnectionHolonId,
                ExcludeIds: payload?.ExcludeIds,
                listTemplateAll: payload?.listTemplateAll,
                cId: payload?.cId,
                cName: payload?.cName
            }
        })
    }

    const renderTitleBox = (payload) => {
        if (payload?.isHolonTemplateName) {
            if (!holonTemplateDetail?.name) {
                return;
            }
            return Validator.renderMessage(t("holon.create_holon_instance_name"), {
                0: holonTemplateDetail?.name
            });
        }
        if (payload?.dataBox?.boxEdit?.titleBoxUpdate) {
            return payload?.dataBox?.boxEdit?.titleBoxUpdate;
        }
        return payload?.title;
    }

    let attribute_key = `attribute_${uuidv4()}`;
    let connections_key = `connections_${uuidv4()}`;
    let attachment_key = `attachment_${uuidv4()}`;

    let wacthValue = methods.watch(["attributes", "connections", "CargoProduct", "attachments"])

    let isEditAttributes = _.isEqual(dataDefault?.attributes, wacthValue[0]);
    let isEditConnections = _.isEqual(dataDefault?.connections, wacthValue[1]);
    let isEditCargoProduct = _.isEqual(dataDefault?.cargoProduct, wacthValue[2]);
    let isEditAttachments = _.isEqual(dataDefault?.attachments, wacthValue[3]);

    return (
        <BoxContentPopup borderRadius='20px 0px 0px 20px' width='1000px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        title={renderTitleBox(payload)}
                        handleBtnBack={() => {
                            handleOpenDetail(payload?.holonInstanceId, payload?.holonTemplateId, payload?.subHolonTemplateId)
                        }}
                        actionSectionBottom={<Button disabled={!isEditAttributes || !isEditConnections || !isEditCargoProduct || !isEditAttachments ? false : true} color='gray' loading={loadingUpdateInstance} type='submit' title={t('save')} />}>

                        <div className={classes['wrap-body']}>
                            <div className={classes['wrap-form']}>
                                <FormAttributes attribute_key={attribute_key} data={fieldsHas} />
                                {
                                    fieldsConnection?.length > 0 ? <FormConnections connections_key={connections_key} id={holonInStanceDetail?.id} data={fieldsConnection} /> : <></>
                                }
                                {/* {
                                    (holonTemplateDetail?.id == Constants.CARGO_MASTER_CODE.CARD_BOARD.TEMPLATE_ID || payload?.isCargo) && <FormCargoProduct holonTemplateId={holonTemplateDetail?.id} onChangeCargoProductDefault={onChangeCargoProductDefault} cargo_key={cargo_key} attributes={methods.watch("attributes")} connections={methods.watch("connections")} holonInstanceId={payload?.holonInstanceId} isUpdate />
                                } */}
                                <FormAttachment attachment_key={attachment_key} removeActions={removeAttachments} appendAttachment={appendAttachment} data={fieldsAttachment} />
                            </div>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default UpdateHolonInstancePopup