import ApiOperation from "adapter/ApiOperation"
import Constants from "utils/Constants";

export const factory = {
    getFaqCategory: (payload) => {
        let params = {};
        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['search'] = payload[Constants.QueryParams.SEARCH.VALUE]
        }
        if (payload[Constants.QueryParams.STATUS.VALUE] || payload[Constants.QueryParams.STATUS.VALUE] == "0") {
            params['status'] = payload[Constants.QueryParams.STATUS.VALUE]
        }
        if (payload[Constants.QueryParams.LANGUAGE.VALUE]) {
            params['lang'] = payload[Constants.QueryParams.LANGUAGE.VALUE]
        }
        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE]
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }
        if (payload?.isAll) {
            params['isAll'] = true;
        } else {
            if (payload[Constants.QueryParams.OFFSET.VALUE]) {
                params['page'] = payload[Constants.QueryParams.OFFSET.VALUE]
            } else {
                params['page'] = Constants.PAGINATION.OFFSET;
            }
        }

        return ApiOperation.request({
            url: '/rest/api/v1/management/faq/categories/search',
            params: params
        })
    },
    getDetailFaqCategory: (payload) => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/faq/categories/${payload?.id}`,
        })
    },
    getFaqCategoryMaster: (payload) => {
        let params = {};
        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['categoryName'] = payload[Constants.QueryParams.SEARCH.VALUE]
        }
        params['isAll'] = true;
        return ApiOperation.request({
            url: `/rest/api/v1/master/faq/category`,
            params: params
        })
    },
    addFaqCategory: (payload) => {
        return ApiOperation.request({
            url: '/rest/api/v1/management/faq/categories',
            data: payload,
            method: 'POST'
        })
    },
    updateFaqCategory: (payload) => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/faq/categories/${payload?.id}`,
            data: payload,
            method: 'PUT'
        })
    },
    deleteFaqCategory: (payload) => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/faq/categories/${payload?.id}`,
            data: payload,
            method: 'DELETE'
        })
    },
    // FaqWithCategory : TODO
    getFaqByCategory: (payload) => {
        let params = {};
        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE]
        }
        if (payload[Constants.QueryParams.RELEASE.VALUE]) {
            params['release'] = payload[Constants.QueryParams.RELEASE.VALUE]
        }
        if (payload[Constants.QueryParams.LANGUAGE.VALUE]) {
            params['lang'] = payload[Constants.QueryParams.LANGUAGE.VALUE]
        }
        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE]
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE]
        } else {
            params['page'] = Constants.PAGINATION.OFFSET;
        }
        if (payload[Constants.QueryParams.TAB.VALUE]) {
            params['categoryId'] = payload[Constants.QueryParams.TAB.VALUE]
        } else {
            if (payload[Constants.QueryParams.CATEGORY.VALUE]) {
                params['categoryId'] = payload[Constants.QueryParams.CATEGORY.VALUE]
            } else {
                params['categoryId'] = payload?.categoryId;
            }
        }
        return ApiOperation.request({
            url: '/rest/api/v1/management/faq/search',
            params: params
        })
    },

    addFaqWithCategory: (payload) => {
        return ApiOperation.request({
            url: '/rest/api/v1/management/faq',
            data: payload,
            method: 'POST'
        })
    },
    updateFaqWithCategory: (payload) => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/faq/${payload?.id}`,
            data: payload,
            method: 'PUT'
        })
    },
    deleteFaqWithCategory: (payload) => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/faq/${payload?.id}`,
            data: payload,
            method: 'DELETE'
        })
    },
    getDetailFaq: (payload) => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/faq/${payload?.id}`,
        })
    },
}