
import Cookies from 'js-cookie';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom'
// import Routes from 'router/Routes';

function flattenMenu(menu) {
    const result = [];

    function recursiveFlatten(items) {
        for (const item of items) {
            result.push(item);
            if (item.children && item.children.length > 0) {
                recursiveFlatten(item.children);
            }
        }
    }
    recursiveFlatten(menu);
    return result;
}

export const AdminGuard = async (guardArgs, route) => {
    // const location = useLocation();
    const token_header =  Cookies.get('accessToken') || '';
    const have_token = !!token_header;
    if (!have_token) {
        return false;
    }
    try {
        let menus = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : [];
        if (!menus) {
            return false;
        }
        const flatArray = flattenMenu(menus);

        var isExist = false;
        var isEnable = false;
        for (let index = 0; index < flatArray.length; index++) {
            const item = flatArray[index];
            if (item?.href == guardArgs.path) {
                isExist = true;
                if (item?.isEnable) {
                    isEnable = true;
                }
                break;
            }
        }
        if(guardArgs.path== "/portal" && !isEnable){
            // get first enable
            let firstEnable = flatArray.find(x=>(x.isEnable && x.href != ""));
            if(firstEnable){
                window.location = firstEnable.href
                return true;
            }else{
                Cookies.remove("accessToken");
                Cookies.remove("refreshToken");
                Cookies.remove("expiredAt");
                return false;
            }
        }
        return isExist && isEnable || !isExist;
    } catch (error) {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        Cookies.remove("expiredAt");
        return false;
    }
};