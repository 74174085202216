import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import UploadMultipartFile from '../form-input/upload-upload-multipart-file/UploadMultipartFile';

function FormUploadMultipartFile(props) {
    const {
        fieldName,
        validate = [],
        placeholder,
        disabled = false,
        isRegexLabel,
        validationFE = false,
        buttonComponent,
        accept="*"
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className="Input w-100">
            <div className="w-100">
                <Controller
                    control={control}
                    name={fieldName}
                    rules={{
                        validate: Validator.genValidate(validate, fieldName),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                            <UploadMultipartFile
                                refInput={ref}
                                name={fieldName}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                onBlur={onBlur}
                                value={value}
                                placeholder={placeholder}
                                disabled={disabled}
                                errors={errors}
                                maximumUpload={100}
                                maximumMb={100}
                                isRegexLabel={isRegexLabel}
                                validationFE={validationFE}
                                buttonComponent={buttonComponent}
                                accept={accept}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
}
FormUploadMultipartFile.defaultProps = {
    placeholder: '',
    fieldName: 'fieldName',
};
FormUploadMultipartFile.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    iconSearch: PropTypes.element,
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    isRegexLabel: PropTypes.string,
    isSearch: PropTypes.bool,
    buttonComponent: PropTypes.element,
};
export default FormUploadMultipartFile;
