import React from 'react';
import styles from "./Box.module.scss";

function Box(props) {
    return (
        <div className={styles["Box"]} style={props?.style}>
            {props?.children}
        </div>
    )
}
export default Box;