import MenuManagementAction from "./action";

const initialState = {
    listMenu: {},
    holonTemplate: {},
    menuMaster: {},
    loading: false,
    loadingUpdate: false,
}

const myReducer = (state = initialState, action) => {
    switch (action?.type) {
        case MenuManagementAction.GET_MENU_MANAGEMENT:
            return {
                ...state,
                loading: true
            }
        case MenuManagementAction.GET_MENU_MANAGEMENT_SUCCESS:
            return {
                ...state,
                listMenu: action?.payload?.menu,
                holonTemplate: action?.payload?.templateHolon,
                loading: false,
                loadingUpdate: false,
            }
        case MenuManagementAction.GET_MENU_MANAGEMENT_FAILED:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
            }
        case MenuManagementAction.GET_HOLON_TEMPLATE_MENU_MANAGEMENT_SUCCESS:
            return {
                ...state,
                holonTemplate: action?.payload
            }
        case MenuManagementAction.UPDATE_PERMISTION_MENU_MANAGEMENT:
            return {
                ...state,
                loadingUpdate: true
            }
        case MenuManagementAction.UPDATE_PERMISTION_MENU_MANAGEMENT_FAILED:
            return {
                ...state,
                loadingUpdate: false
            }
        // case MenuManagementAction.UPDATE_PERMISTION_MENU_MANAGEMENT_SUCCESS:
        //     return {
        //         ...state,
        //         loadingUpdate: false
        //     }
        case MenuManagementAction.GET_MENU_MANAGEMENT_BY_CODE:
            return {
                ...state,
                loading: true,
            }
        case MenuManagementAction.GET_MENU_MANAGEMENT_BY_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                menuMaster: action?.payload,
            }
        case MenuManagementAction.GET_MENU_MANAGEMENT_BY_CODE_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case MenuManagementAction.CREATE_MENU_MANAGEMENT:
            return {
                ...state,
                loadingUpdate: true
            }
        case MenuManagementAction.CREATE_MENU_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loadingUpdate: false,
            }
        case MenuManagementAction.CREATE_MENU_MANAGEMENT_FAILURE:
            return {
                ...state,
                loadingUpdate: false,
            }
        case MenuManagementAction.UPDATE_MENU_MANAGEMENT:
            return {
                ...state,
                loadingUpdate: true
            }
        case MenuManagementAction.UPDATE_MENU_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loadingUpdate: false,
            }
        case MenuManagementAction.UPDATE_MENU_MANAGEMENT_FAILURE:
            return {
                ...state,
                loadingUpdate: false,
            }
        case MenuManagementAction.UPDATE_SORT_MENU_MANAGEMENT:
            return {
                ...state,
                loadingUpdate: true
            }
        case MenuManagementAction.UPDATE_SORT_MENU_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loadingUpdate: false,
            }
        case MenuManagementAction.UPDATE_SORT_MENU_MANAGEMENT_FAILURE:
            return {
                ...state,
                loadingUpdate: false,
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;