import React from 'react'
import Box from 'shared/components/form-input/box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import Button from 'shared/components/form-input/button/Button';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import classes from './UploadOrder.module.scss';
import useTrans from 'hooks/use-trans';

const errorPopupAttributes = {
    "ORDER": {
        title: "orders.csv_file_contains_error_records",
        record: "orders.order_no",
        key: "orderNo"
    },
    "PRODUCT": {
        title: "orders.csv_file_contains_error_records",
        record: "product.product_no",
        key: "productNo"
    },
}

function ListErrorPopup(props) {
    const { payload, showVisible } = props;
    const type = payload?.errorType || "ORDER";
    const handleBtnBack = () => {
        showVisible(false);
    }

    const { t } = useTrans();

    return (
        <>
            <BoxContentPopup >
                <WrapContentBody
                    isUseBackground={false}
                    showVisible={showVisible}
                    title={t(errorPopupAttributes[type].title)}
                    actionSectionBottom={
                        <div className={classes['row-action']}>
                            <Button onClick={handleBtnBack} color='gray' type='submit' title={t("Holon_Template.close")} />
                        </div>
                    }
                    useForm={false}
                    handleBtnBack={handleBtnBack}
                >
                    <Box>
                        <BoxBody style={{
                            borderRadius: 10,
                            padding: 0
                        }}>
                            <BoxRow>
                                <BoxLabel minWidth={300}>{t("orders.abnormality_location")}</BoxLabel>
                            </BoxRow>
                            <div >
                                {payload?.data?.map((x, index) => {
                                    return <BoxRow key={index}>
                                        <BoxLabel style={{
                                            alignItems: 'flex-start'
                                        }} minWidth={100}>{t(errorPopupAttributes[type].record)}{x[errorPopupAttributes[type].key]}</BoxLabel>
                                        <BoxContent>{x?.messageError}</BoxContent>
                                    </BoxRow>
                                })}
                            </div>
                        </BoxBody>
                    </Box>
                </WrapContentBody>
            </BoxContentPopup>
        </>
    )
}

export default ListErrorPopup