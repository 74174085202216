import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import useRouter from 'hooks/use-router';
import FormInput from 'shared/components/common/form/home/FormInput'
import FormCheckbox from 'shared/components/common/form/checkbox/FormCheckbox'
import Validator from 'utils/Validator'
import Action from 'redux/user/action';
import Actions from 'redux/app/action';
import { useLocation } from 'react-router-dom';
import useTrans from 'hooks/use-trans';
import styles from './ResetPassword.module.scss'
import Loading from 'shared/components/form-input/button/loading/Loading';

export default function ResetPasswordContainer() {
    const { trans } = useTrans();
    const router = useRouter()
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isValidToken, setIsValidToken] = useState(false);
    const dispatch = useDispatch();
    const methods = useForm();
    const { register, handleSubmit } = methods;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch({
            type: Action.CHECK_TOKEN_EXPIRATION,
            formData: {
                token: token
            },
            callback: () => {
                setIsValidToken(true)
            },
            fallback: () => {
                router.push({
                    pathname: "/"
                })
            }
        });
    }, []);

    const handleSubmitResetPasswordForm = (formData) => {
        const data = {
            password: formData.password,
            passwordConfirm: formData.password_confirm,
            token
        }
        setIsLoading(true);
        dispatch({
            type: Action.RESET_PASSWORD,
            formData: data,
            callback: () => {
                setIsLoading(false);
                router.push({
                    pathname: "/"
                })
            },
            fallback: () => {
                setIsLoading(false);
            }
        });
    }
    const onSubmit = (data) => {
        handleSubmitResetPasswordForm(data)
    }
    const handleCheckboxShowPassword = () => {
        setIsShowPassword(!isShowPassword)
    };

    return <> {isValidToken &&
        <div className={styles['reset-password-container']}>
            <div className={`modal-body ${styles['model-body']}`}>
                <div className={`modal-header ${styles['model-header']}`} style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <h1 className='px-5'>{trans("ResetPassword.title")}</h1>
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles['reset-password-form']}>
                        <div className='mb-5'>
                            <label>{trans("ResetPassword.password_change_instructions")}</label>
                        </div>
                        <div>
                            <label className={styles['form-label']}>{trans("ResetPassword.new_password")}</label>
                            <div className={`${styles['input-container']}`}>
                                <FormInput
                                    type={isShowPassword ? 'text' : 'password'}
                                    fieldName="password"
                                    register={register}
                                    placeholder={trans("ResetPassword.new_password")}
                                    validate={[Validator.required, Validator.password]}
                                />
                            </div>
                        </div>
                        <div className="mb-4 mt-4">
                            <label className={styles['form-label']}>{trans("ResetPassword.password_confirm")}</label>
                            <div className={`${styles['input-container']}`}>
                                <FormInput
                                    type={isShowPassword ? 'text' : 'password'}
                                    fieldName="password_confirm"
                                    register={register}
                                    placeholder={trans("ResetPassword.password_confirm")}
                                    validate={[Validator.required, Validator.confirmPassword(methods.watch('password')), Validator.password]}
                                />
                            </div>
                        </div>
                        <div className={styles['checkbox-container']}>
                            <FormCheckbox
                                label={trans("ResetPassword.show_password")}
                                register={register}
                                fieldName='showPassword'
                                callback={handleCheckboxShowPassword}
                            />
                        </div>
                        <center>
                            <button disabled={isLoading} type="submit" className={`${styles['btn-reset']}`} >{isLoading ? <Loading /> : trans("ResetPassword.change_password")}</button>
                        </center>
                    </form>
                </FormProvider>
            </div>
        </div>}
    </>
}