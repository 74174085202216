import React, { useEffect } from 'react'
import { ArrowOutward, FaxOutlined, Phone } from '@mui/icons-material';
import IconEmail from 'assets/icon/IconEmail';
import headerWorld1 from 'assets/img/header-world-1.png';
import headerWorld2 from 'assets/img/header-world-2.png';
import useTrans from 'hooks/use-trans';
import EventRegister, { EVENT_SHOW_FAQ_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_USER_INQUIRY, EVENT_SHOW_POPUP_USER_LOGIN, EVENT_SHOW_POPUP_USER_REGISTER, EVENT_SHOW_SERVICE_DETAIL_POPUP, EVENT_SHOW_POPUP_ESTIMATE_FARE, G0100 } from 'utils/EventRegister';
import styles from "./Home.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import actionHome from 'redux/home/action';
import { convertNumberToString } from 'utils/String';
import Constants from 'utils/Constants';
import useCustomState from 'hooks/use-custom-state';
import i18n from "i18nextInit";
import IconBook from 'assets/icon/IconBook';
import useRouter from 'hooks/use-router';
import actionFareCalculation from 'redux/freight-calculation/action';

const HomeContainer = () => {
    const { trans, t } = useTrans();
    const dispatch = useDispatch();
    const [lang, setLang] = useCustomState(localStorage.getItem("i18nextLng") ?? Constants.T001.JP.CODE)
    const [optionLanguages, setOptionLanguages] = useCustomState([]);
    const { services, dataOrder, termOfUse, policy } = useSelector(state => state.home);

    const handlePopupLogin = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_LOGIN,
            open: true,
            payload: {
                title: trans("Login.title"),
                customStyle: {
                    maxHeight: 'calc(100vh - 90px - 50px)'
                }
            }
        })
    }

    const handleOpenPackageTracking = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: G0100,
            open: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                option: {
                    typePopup: G0100
                }
            }
        })
    }

    const handlePopupInquiry = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_INQUIRY,
            open: true,
            payload: {
                title: trans("Inquiry.inquiry_user"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
            }
        })
    }

    const handleEstimateFare = () => {
        dispatch({
            type: actionFareCalculation.REMOVE_FARE_CALC_MANAGEMENT_CALCULATE
        })
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_ESTIMATE_FARE,
            open: true,
            payload: {
                title: trans("home.estimated_fare"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)',
                },
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: actionHome.GET_LIST_HEX_SERVICE
        })
        dispatch({
            type: actionHome.GET_TERM_OF_USE_HOME
        })
        dispatch({
            type: actionHome.GET_PRIVATE_POLICY_HOME
        })
    }, [lang])

    const handleShowServiceDetail = (_service) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_SERVICE_DETAIL_POPUP,
            open: true,
            payload: {
                title: _service?.title,
                content: _service?.content
            }
        })
    }

    const handleOpenTermOfUse = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_SERVICE_DETAIL_POPUP,
            open: true,
            payload: {
                title: trans("faq_management.term_of_service"),
                content: termOfUse?.content
            }
        })
    }
    const handleOpenPolicy = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_SERVICE_DETAIL_POPUP,
            open: true,
            payload: {
                title: trans("faq_management.private_policy"),
                content: policy?.content
            }
        })
    }

    const handleOpenFaq = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_FAQ_POPUP,
            open: true,
            payload: {
                title: "FAQ",
            }
        })
    }


    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: Constants.T001[key].NAME,
            })
        });
        setOptionLanguages(data)
        i18n.on("languageChanged", (val) => {
            setLang(val)
        })
        return () => {
            i18n.off("languageChanged")
        }
    }, [])

    const handlePopupRegister = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_REGISTER,
            open: true,
            payload: {
                title: trans("Inquiry.sign_up"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
            }
        })
    }

    return (
        <div className="container-xl" style={{ padding: 0 }} >
            <div className={`container row ${styles['home-header']}`}>
                <div className={`container ${styles['header-top']} row justify-content-between`}>
                    <div className={`col-6 ${styles['top-side']} ${styles['top-side-logo']}`}>{trans("home.better_life_holon")}</div>
                    <div className={`col-6 ${styles['top-side-login']} ${styles['top-side']}`}>
                        <div className={`${styles['button-lang']}`}>
                            <select value={lang} className='w-100' onChange={(val) => {
                                i18n.changeLanguage(val.target.value);
                                setLang(val?.target.value)
                            }}>
                                <option value="jp">{t("faq_management.lang.Jp")}</option>
                                <option value="en">{t("faq_management.lang.En")}</option>
                                <option value="cn">{t("faq_management.lang.Cn")}</option>
                            </select>
                        </div>
                        <button type="button" className="btn btn-sm btn-dark" onClick={handlePopupLogin}>{trans("home.login")}</button>
                    </div>
                </div>
                <div className={`container row justify-content-between ${styles['header-concept']}`}>
                    <div className={styles['header-concept-button']}>
                        <div className={`${styles['side-btn']} ${lang == Constants.T001.EN.CODE.toLowerCase() ? styles['side-btn-english'] : ''} ${styles['side-btn-center']}`}>
                            <button className={styles['inquiry-btn']} onClick={handlePopupInquiry} type='submit' style={{ zIndex: 1 }}>
                                <div className={styles['inquiry-btn-content']}>
                                    <IconEmail />
                                    <span className={styles['inquiry-btn-label']}>{trans("Inquiry.header_title")}</span>
                                </div>
                            </button>
                        </div>
                        <div className={`${styles['side-btn']} ${lang == Constants.T001.EN.CODE.toLowerCase() ? styles['side-btn-english'] : ''} ${styles['side-btn-bottom']}`}>
                            <button className={styles['inquiry-btn']} onClick={() => { handleEstimateFare() }} style={{ zIndex: 1 }}>
                                <div className={styles['inquiry-btn-content']}>
                                    <IconBook />
                                    <span className={styles['inquiry-btn-label']}>{trans("home.estimated_fare")}</span>
                                </div>
                            </button>
                        </div>
                        <div className={`${styles['side-btn']} ${lang == Constants.T001.EN.CODE.toLowerCase() ? styles['side-btn-english'] : ''} ${styles['side-btn-top']}`}>
                            <button className={styles['inquiry-btn']} onClick={handleOpenPackageTracking} style={{ zIndex: 1 }}>
                                <div className={styles['inquiry-btn-content']}>
                                    <IconBook />
                                    <span className={styles['inquiry-btn-label']}>{trans("home.package_tracking")}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`col-6 ${styles['header-side']} ${styles['header-side-left']}`}>
                    <div className={`${styles['header-title']}`}>
                        <h1 dangerouslySetInnerHTML={{
                            __html: trans("home.welcome")
                        }} />
                        <p dangerouslySetInnerHTML={{
                            __html: trans("home.by_using_hex_to_manage_all_of_your_deliveries")
                        }} />
                        <button onClick={handlePopupRegister} type="button" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#signUpModal" className={`btn btn-sm btn-warning ${styles["btnLogin"]}`}>{trans("home.start")}</button>
                    </div>
                    <img src={headerWorld1} className={styles['header-world']} alt='Inquiry' />
                </div>
                <div className={`col-6 ${styles['header-side']} ${styles['header-side-right']}`}>
                    <div className={''}>
                        <img src={headerWorld2} className={styles['header-world']} alt='Delivery' />
                    </div>
                </div>
            </div>
            <div className={`container ${styles['service-thm-sec']}`}>
                <h2>{trans("home.service")}</h2>
                <div className={`${styles['inner-service-thm-sec']} row`}>
                    {
                        services?.length > 0 ? [...services]?.slice(0, 5)?.map((x, index) => {
                            return <div key={index} style={{
                                background: x?.backgroundColorCode
                            }} className={`col ${styles['service-thm']} ${styles['dark-sec']}`}>
                                <div>
                                    <h3 style={{
                                        color: x?.textColor
                                    }}>{x?.title}</h3>
                                    <p style={{
                                        color: x?.textColor
                                    }}>{x?.description}</p>
                                </div>
                                <div className={styles['container-service-access']}>
                                    <span className={styles['service-access-icon']} style={{
                                        color: x?.textColor
                                    }} onClick={(e) => {
                                        e.stopPropagation();
                                        handleShowServiceDetail(x)
                                    }}><ArrowOutward /></span>
                                    <span className={styles['service-access-icon-transport']}>
                                        <img src={x?.logo} className={styles['service-icon']} alt="service icon" />
                                    </span>
                                </div>
                            </div>
                        }) : <></>
                    }
                    {/* <div className={`col ${styles['service-thm']} ${styles['dark-sec']}`}>
                        <h3>グローバル船便</h3>
                        <p>様々な輸送設備や混載サービスにより、お客様の貨物を適切な時間に、適切な場所へ、費用対効果の高い方法でお届けします。</p>
                        <div className={styles['service-access']}>
                            <ArrowOutward />
                            <span>
                                <img src={serviceSea} className={styles['service-icon']} alt="service icon" />
                            </span>
                        </div>
                    </div> */}
                    {/* <div className={`col ${styles['service-thm']} ${styles['gray-sec']}`}>
                        <h3>グローバルエアー便</h3>
                        <p>様々な輸送設備や混載サービスにより、お客様の貨物を適切な時間に、適切な場所へ、費用対効果の高い方法でお届けします。界中の主要港で輸送スペースを確保しています。</p>
                        <div className={styles['service-access']}>
                            <ArrowOutward />
                            <span>
                                <img src={serviceAir} className={styles['service-icon']} alt="service icon" />
                            </span>
                        </div>
                    </div>
                    <div className={`col ${styles['service-thm']} ${styles['yellow-sec']}`}>
                        <h3>越境宅配便</h3>
                        <p>様々な輸送設備や混載サービスにより、お客様の貨物を適切な時間に、適切な場所へ、費用対効果の高い方法でお届けします。</p>
                        <div className={styles['service-access']}>
                            <ArrowOutward />
                            <span>
                                <img src={serviceSea} className={styles['service-icon']} alt="service icon" />
                            </span>
                        </div>
                    </div>
                    <div className={`col ${styles['service-thm']} ${styles['red-sec']}`}>
                        <h3>日本郵政代行</h3>
                        <p>様々な輸送設備や混載サービスにより、お客様の貨物を適切な時間に、適切な場所へ、費用対効果の高い方法でお届けします。界中の主要港で輸送スペースを確保しています。</p>
                        <div className={styles['service-access']}>
                            <ArrowOutward />
                            <span>
                                <img src={serviceAir} className={styles['service-icon']} alt="service icon" />
                            </span>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className={`container ${styles['convincing-data-sec']}`}>
                <div className='row gy-5 justify-content-center'>
                    <div className={`col-4 ${styles['gray-sec']} ${styles['tag-convincing']}`}>
                        <h3>{trans("home.number_of_user")}</h3>
                        <p>{trans("home.real_time_index")}</p>
                    </div>
                    <div className={`${styles['gray-sec']} col-1 ${styles['dialog-pointer']}`}></div>
                    <div className={`${styles['convincing-data-number']} col-6`}>{dataOrder?.numberOfUser ?? 0} {trans("home.man")}</div>
                    <div className={`${styles["red-sec"]} col-4 ${styles["tag-convincing"]}`}>
                        <h3>{trans("home.number_completed_orders")}</h3>
                        <p>{trans("home.real_time_index")}</p>
                    </div>
                    <div className={`${styles['red-sec']} col-1 ${styles['dialog-pointer']}`}></div>
                    <div className={`${styles['convincing-data-number']} col-6`}>{convertNumberToString(dataOrder?.numberOfOrder, ",") ?? 0} {trans("home.subject")}</div>
                </div>
            </div>
            <div className={`container ${styles['home-footer']}`}>
                <h3>{trans("home.better_life")}</h3>
                <p dangerouslySetInnerHTML={{
                    __html: trans("home.address")
                }} />
                <Phone /> {Constants.PHONE_NUMBER_iHOLON.TEL.VALUE}<br />
                <FaxOutlined /> {Constants.PHONE_NUMBER_iHOLON.FAX.VALUE}
            </div>
            <div className={`container ${styles['footer']}`}>
                <div className={styles['footer-content']}>
                    <div className={`col-6 rights-reserver ${styles['footer-left-side']}`}>
                        <p className={styles["copyright"]}>Copyright © 2022 HEX All Rights Reserved.</p>
                    </div>
                    <div className={`col-6 ${styles['footer-right-side']}`}>
                        <div className={`row justify-content-end ${styles['footer-text-container']}`}>
                            <div onClick={handleOpenTermOfUse} className={`col-3 cursor-pointer ${styles["footer-text"]}`}><p>{trans("faq_management.term_of_service")}</p></div>
                            <div onClick={handleOpenPolicy} className={`col-3 cursor-pointer ${styles["footer-text"]} ${styles["footer-text-middle"]}`}><p>{trans("faq_management.private_policy")}</p></div>
                            <div onClick={handleOpenFaq} className={`col-3 cursor-pointer ${styles["footer-text-faq"]}`}><p>
                                FAQ
                            </p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HomeContainer;