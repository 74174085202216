import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from 'shared/components/common/form/home/FormInput';
import FormTextArea from 'shared/components/common/form/home/FormTextarea';
import FormCheckbox from 'shared/components/common/form/checkbox/FormCheckbox'
import FormRadio from 'shared/components/common/form/FormRadio'
import Constants from 'utils/Constants';
import Validator from 'utils/Validator';
import RequiredLabel from '../../common/RequiredLabel';
import StepperComponent from '../../common/Stepper';
import useTrans from 'hooks/use-trans'
import IconOpenInNewWindow from 'assets/icon/IconOpenInNewWindow';
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POPUP_PRIVACY_POLICY,
    EVENT_SHOW_POPUP_USER_INQUIRY_CONFIRMATION
}
    from 'utils/EventRegister';
import IconRightArrow from 'assets/icon/IconRightArrow';
import styles from "./UserInquiryPopup.module.scss";

const UserInquiryPopup = (props) => {
    const { payload } = props;
    const { trans } = useTrans();
    const [inquiryForm, setInquiryForm] = useState(Constants.INQUIRY)
    const [activeRadio, setActiveRadio] = useState(payload?.data?.type ?? 1)
    const methods = useForm({
        defaultValues: activeRadio
    });
    const { register, handleSubmit } = methods;
    const onSubmit = (data) => {
        handleOpenUserInquiryConfirmationPopup(data);
    }

    const handleOpenPopupPolicy = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_PRIVACY_POLICY,
            open: true,
            newWindow: true,
            payload: {
                title: trans("privacy_policy.title"),
                customStyle: {
                    customStyle: 'calc(100vh - 150px)'
                },
            }
        })
    }

    const handleOpenUserInquiryConfirmationPopup = (data) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_INQUIRY_CONFIRMATION,
            open: true,
            payload: {
                title: trans("Inquiry.inquiry_user"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
                data: data
            }
        })
    }

    const handleRadiobox = (id) => {
        const updatedInquiryForm = inquiryForm.map(inquiry => {
            if (id === 1) {
                if (inquiry.id === 2 || inquiry.id === 3) {
                    return { ...inquiry, isShow: true };
                }
                if (inquiry.id === 4 || inquiry.id === 5) {
                    return { ...inquiry, isShow: true };
                }
            }
            if (id === 2) {
                if (inquiry.id === 2 || inquiry.id === 3) {
                    return { ...inquiry, isShow: false };
                }
                if (inquiry.id === 4 || inquiry.id === 5) {
                    return { ...inquiry, isShow: true };
                }
            }
            return inquiry;
        });
        setInquiryForm(updatedInquiryForm);
    }

    useEffect(() => {
        activeRadio && handleRadiobox(activeRadio);
    }, [activeRadio]);

    return <div className={styles['inquiry-container']}>
        <div className={styles['stepper-container']}>
            <div className={styles['stepper-content']}>
                <StepperComponent
                    activeStep={0}
                    steps={Constants.INQUIRY_STEP}
                />
            </div>
        </div>
        <div className={styles['open-time']}>
            <div className={styles['open-time-content']}>
                <span>{trans("Inquiry.openingTime")}</span>
            </div>
        </div>
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    inquiryForm && inquiryForm?.map((field, index) => {
                        return field.isShow && <div key={index}>
                            <div className={styles['input-wrap']}>
                                <div className={styles['input-label-required']}>
                                    <span className={styles['input-label']}>
                                        {trans("Inquiry." + field.key.toString())}
                                    </span>
                                    {
                                        field.isRequired == Constants.IS_REQUIRED ? <RequiredLabel /> : null
                                    }
                                </div>
                                <div>
                                    <div className={styles['form-input']}>
                                        {field.type === 'rdo' &&
                                            <FormRadio
                                                master={field.questions}
                                                name={field.key}
                                                register={register}
                                                callback={handleRadiobox}
                                                checkedValue={activeRadio}
                                                defaultValue={activeRadio}
                                            />
                                        }
                                        {field.type === 'txt' &&
                                            <FormInput
                                                fieldName={field.key}
                                                placeholder={''}
                                                register={register}
                                                validate={[
                                                    Validator.regex(field?.regex),
                                                    field.isRequired == Constants.IS_REQUIRED ? Validator.required : () => { },
                                                    field.key === 'email' ? Validator.email : () => { },
                                                    field.key === 'email' ? Validator.emailFormat : () => { },
                                                    field.key === 'fullNameFuri' ? Validator.fullNameFuri : () => { },
                                                    field.maxLength ? Validator.maxLength(field.maxLength) : () => { },
                                                ]}
                                                defaultValue={payload?.data ? payload?.data[field.key] : null}
                                            />
                                        }
                                        {field.type === 'textarea' &&
                                            <div className={styles['textarea-place-holder']}>
                                                <div><span>{trans(trans("Inquiry.Placeholder." + field?.placeholder))}</span></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {field.type === 'textarea' && <div className={styles['form-textarea']}>
                                <div className={styles['inner-form-textarea']}>
                                    <FormTextArea
                                        fieldName={field.key}
                                        register={register}
                                        maxLength={1000}
                                        validate={[
                                            Validator.regex(field?.regex),
                                            field.isRequired == Constants.IS_REQUIRED ? Validator.required : () => { },
                                            field.maxLength ? Validator.maxLengthArea(field.maxLength) : () => { },
                                        ]}
                                        defaultValue={payload?.data ? payload?.data[field.key] : null}
                                    ></FormTextArea></div>
                            </div>}
                        </div>
                    })
                }
                <div className={styles['policy-container']}>
                    <div className={styles['policy-content']}>
                        <div className={styles['first-policy']}>
                            {trans("Inquiry.entrustedPersonalTitle")}
                        </div>
                        <div className={styles['second-policy']}>
                            {trans("Inquiry.entrustedPersonalContent")}
                        </div>
                        <button className={styles['third-policy']} type="button" onClick={handleOpenPopupPolicy}>
                            {trans("Inquiry.privacyPolicyLink")}
                            <div className={styles['icon-open-in-new-window']}>
                                <IconOpenInNewWindow />
                            </div>
                        </button>
                    </div>
                    <div className={styles['form-checkbox-agree-policy']}>
                        <div className={styles['inner-form-checkbox']}>
                            <FormCheckbox
                                label={trans("Inquiry.btnAgreePolicy")}
                                register={register}
                                fieldName='agree'
                                validate={[Validator.requiredCheckbox]}
                                checked={payload?.data?.agree ? true : false}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles['description']}>
                    <ul>
                        <li>{trans("Inquiry.firstDescription")}</li>
                        <li>{trans("Inquiry.secondDescription")}</li>
                        <li>{trans("Inquiry.thirdDescription")}</li>
                        {/* <li>{trans("Inquiry.fourthDescription")}</li> */}
                    </ul>
                </div>
                <div className={styles['submit-btn-container']}>
                    <button type="submit" className={`${styles['login-btn']}`}>
                        <span className={styles['btn-label']}>{trans("Inquiry.btnAgreePolicy")}</span>
                        <span className={styles['right-arrow-icon']}>
                            <IconRightArrow />
                        </span>
                    </button>
                </div>
            </form>
        </FormProvider>
    </div>
}

export default UserInquiryPopup