import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { actionsHolonInstance } from "./action";
import factory from "./factory";
import Validator from "utils/Validator";
import i18n from "i18nextInit"
import Constants from "utils/Constants";
import { getMessageCommon, showPopupNotification } from "utils/Utils";

function* getAllHolonTemplate() {
    yield takeEvery(
        actionsHolonInstance.HOLON_TEMPLATE_MASTER, function* (payload) {
            try {
                const response = yield call(() => factory.getMasterHolonTemplate(payload?.payload));

                yield put({
                    type: actionsHolonInstance.HOLON_TEMPLATE_MASTER_SUCCESS,
                    payload: response?.records
                })
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}

function* getHolonTemplateDetail() {
    yield takeEvery(
        actionsHolonInstance.HOLON_TEMPLATE_DETAIL, function* (payload) {
            try {
                const response = yield call(() => factory.getHolonTemplateDetail(payload?.payload));
                yield put({
                    type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_SUCCESS,
                    payload: {
                        holonTemplateDetail: response?.data,
                    }
                })
                yield payload?.callback && payload?.callback(response?.data)
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}

function* getHolonTemplateDetailPopup() {
    yield takeEvery(
        actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP, function* (payload) {
            try {
                const response = yield call(() => factory.getHolonTemplateDetail(payload?.payload));
                yield payload?.callback && payload?.callback(response?.data)
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}

function* getCommonFiled() {
    yield takeEvery(
        actionsHolonInstance.GET_COMMON_FILED, function* (payload) {
            try {
                const response = yield call(() => factory.getCommonField(payload?.payload));
                yield payload?.callback?.success && payload?.callback?.success(response?.data)
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}

function* bpToPayment() {
    yield takeEvery(
        actionsHolonInstance.BP_TO_PAYMENT, function* (payload) {
            try {
                const response = yield call(() => factory.bpToPayment(payload?.payload));
                yield payload?.callback?.success && payload?.callback?.success(response, null)
            } catch (error) {
                yield payload?.callback?.success && payload?.callback?.success(null, error)
            }
        }
    )
}

function* getCommonAction() {
    yield takeEvery(
        actionsHolonInstance.GET_ACTION_COMMON, function* (payload) {
            try {
                const response = yield call(() => factory.getCommonAction(payload?.payload));
                yield payload?.callback?.success && payload?.callback?.success(response?.data)
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}

function* getHolonInstance() {
    yield takeLatest(
        actionsHolonInstance.GET_HOLON_INSTANCE, function* (payload) {
            try {
                if (payload?.payload?.[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]) {
                    const response = yield call(() => factory.getHolonInstance(payload?.payload));
                    let data = response?.records?.map(x => {
                        x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                        return x;
                    });
                    data = data?.map(x => {
                        let created_date = {
                            name: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.CREATED_DATE.VALUE,
                            value: x['createdDate'],
                            templateId: x?.templateId
                        }
                        let updated_date = {
                            name: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.UPDATED_DATE.VALUE,
                            value: x['updatedDate'],
                            templateId: x?.templateId
                        }
                        return {
                            ...x,
                            attributes: {
                                ...x?.attributes,
                                created_date,
                                updated_date,
                            }
                        }
                    })
                    yield put({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE_SUCCESS,
                        payload: {
                            ...response,
                            records: data
                        }
                    })
                } else if (payload?.payload?.[Constants.QueryParams.IS_SEARCH_ALL_TEMPLATE.VALUE] == "1") {
                    const response = yield call(() => factory.getHolonInstance(payload?.payload));
                    let data = response?.records?.map(x => {
                        x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                        return x;
                    });
                    data = data?.map(x => {
                        let created_date = {
                            name: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.CREATED_DATE.VALUE,
                            value: x['createdDate'],
                            templateId: x?.templateId
                        }
                        let updated_date = {
                            name: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.UPDATED_DATE.VALUE,
                            value: x['updatedDate'],
                            templateId: x?.templateId
                        }
                        return {
                            ...x,
                            attributes: {
                                ...x?.attributes,
                                created_date,
                                updated_date,
                            }
                        }
                    })
                    yield put({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE_SUCCESS,
                        payload: {
                            ...response,
                            records: data
                        }
                    })
                } else {
                    yield put({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE_SUCCESS,
                        payload: {
                            total: 0,
                            limit: 10,
                            totalPage: 0,
                            page: 1,
                            records: []
                        }
                    })
                }
                yield payload?.callback && payload?.callback()
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}
function* getHolonInstanceDetail() {
    yield takeEvery(
        actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL, function* (payload) {
            try {
                const response = yield call(() => factory.getDetailHolonInstance(payload?.payload));
                if (payload?.callback) {
                    yield payload?.callback && payload?.callback(response?.data)
                } else {
                    yield put({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL_SUCCESS,
                        payload: response?.data
                    })
                }
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}
function* getHolonTemplateConnection() {
    yield takeEvery(
        actionsHolonInstance.GET_HOLON_TEMPLATE_CONNECTION, function* (payload) {
            try {
                const response = yield call(() => factory.getHolonTemplateWithIds(payload?.payload));
                yield put({
                    type: actionsHolonInstance.GET_HOLON_TEMPLATE_CONNECTION_SUCCESS,
                    payload: response
                })
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}
function* createHolonInstance() {
    yield takeEvery(
        actionsHolonInstance.CREATE_HOLON_INSTANCE, function* (payload) {
            try {
                const response = yield call(() => factory.createHolonInstance(payload?.payload));
                if (response?.data?.id) {
                    yield payload?.callback?.success && payload?.callback?.success(response?.data)
                    yield put({
                        type: actionsHolonInstance.CREATE_HOLON_INSTANCE_SUCCESS,
                        payload: response
                    })
                } else {
                    try {
                        let dataError = JSON.parse(response?.error?.detail?.exception[0]);
                        if (dataError.type == 'unique') {
                            dataError = i18n.t("email_use");
                        }
                        yield payload?.callback?.failed && payload?.callback?.failed(dataError)

                    } catch (error) {
                        yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])

                    }
                    yield put({
                        type: actionsHolonInstance.CREATE_HOLON_INSTANCE_FAILED,
                    })
                }
            } catch (error) {
                try {
                    let dataError = JSON.parse(error?.error?.detail?.exception[0]);
                    if (dataError.type == 'unique') {
                        dataError = Validator.renderMessage(i18n.t("E0021"), { 0: `${dataError?.field_name} ${dataError?.field_value}` })
                    }
                    yield payload?.callback?.failed && payload?.callback?.failed(dataError)

                } catch (err) {
                    yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0])

                }
                yield put({
                    type: actionsHolonInstance.CREATE_HOLON_INSTANCE_FAILED,
                })
            }
        }
    )
}

function* updateHolonInstance() {
    yield takeEvery(
        actionsHolonInstance.UPDATE_HOLON_INSTANCE, function* (payload) {
            try {
                const response = yield call(() => factory.updateHolonInstance(payload?.payload));
                if (response?.data?.id) {
                    yield put({
                        type: actionsHolonInstance.UPDATE_HOLON_INSTANCE_SUCCESS,
                    })
                } else {
                    yield put({
                        type: actionsHolonInstance.UPDATE_HOLON_INSTANCE_FAILED,
                    })
                }
                yield payload?.callback?.success && payload?.callback?.success(response, null)
            } catch (error) {
                yield payload?.callback?.success && payload?.callback?.success(null, error)
                yield put({
                    type: actionsHolonInstance.UPDATE_HOLON_INSTANCE_FAILED,
                })
            }
        }
    )
}

function* getHolonInstanceWithHolonTemplate() {
    yield takeLatest(
        actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE, function* (payload) {
            try {
                yield put({
                    type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_LOADING
                })
                const response = yield call(() => factory.getHolonInstanceWithHolonTemplate(payload?.payload));
                yield payload?.callback?.success && payload?.callback?.success();
                const data = response?.records?.map(x => {
                    x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                    return x;
                });
                if (payload?.callback) {
                    yield payload?.callback && payload?.callback({
                        ...response,
                        records: data
                    })
                } else {
                    yield put({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_SUCCESS,
                        payload: {
                            ...response,
                            records: data
                        }
                    })
                }
            } catch (error) {
                showPopupNotification({
                    callback: () => { },
                    typePopup: 'message',
                    newWindow: true,
                    message: getMessageCommon({
                        response: null,
                        error: error,
                        isAction: false
                    })
                })
            }
        }
    )
}

function* getSubHolonTemplateDetail() {
    yield takeEvery(actionsHolonInstance.GET_SUB_HOLON_TEMPLATE, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplateDetail(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionsHolonInstance.GET_SUB_HOLON_TEMPLATE_SUCCESS,
                    payload: response?.data
                })
                yield payload?.callback()
            } else {
                yield payload?.callback()
            }
        } catch (error) {
            showPopupNotification({
                callback: () => { },
                typePopup: 'message',
                newWindow: true,
                message: getMessageCommon({
                    response: null,
                    error: error,
                    isAction: false
                })
            })
        }
    })
}

function* getHolonTemplateWithIds() {
    yield takeEvery(actionsHolonInstance.GET_HOLON_TEMPLATE_WITH_IDS, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplateWithIds(payload?.payload));
            yield payload?.callback && payload?.callback(response?.records);
        } catch (error) {
            showPopupNotification({
                callback: () => { },
                typePopup: 'message',
                newWindow: true,
                message: getMessageCommon({
                    response: null,
                    error: error,
                    isAction: false
                })
            })
        }
    })
}

function* getListTemplateIdWithIdInstance() {
    yield takeEvery(actionsHolonInstance.GET_LIST_TEMPLATE_ID_WITH_INSTANCE, function* (payload) {
        try {
            const response = yield call(() => factory.getListTemplateIsWithIdStance(payload?.payload));
            yield payload?.callback && payload?.callback(response?.data);
        } catch (error) {
            showPopupNotification({
                callback: () => { },
                typePopup: 'message',
                newWindow: true,
                message: getMessageCommon({
                    response: null,
                    error: error,
                    isAction: false
                })
            })
        }
    })
}

function* cloneHolonInstance() {
    yield takeEvery(actionsHolonInstance.HANDLE_CLONE_HOLON_INSTANCE, function* (payload) {
        try {
            const response = yield call(() => factory.cloneHolonInstance(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data);
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0]);
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0])
        }
    })
}

function* deleteHolonInstance() {
    yield takeEvery(actionsHolonInstance.DELETE_HOLON_INSTANCE, function* (payload) {
        try {
            const response = yield call(() => factory.deleteHolonInstance(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0])
        }
    })
}

function* requestPackageTracking() {
    yield takeEvery(actionsHolonInstance.GET_PACKAGE_TRACKING, function* (payload) {
        try {
            const response = yield call(() => factory.getPackageTracking(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data)
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0])
        }
    })
}

function* requestApiWithServer() {
    yield takeEvery(actionsHolonInstance.REQUEST_API_WITH_URL, function* (payload) {
        try {
            const response = yield call(() => factory.requestApiWithUrl(payload?.payload));
            if (response) {
                yield payload?.callback?.success(response, null)
            }
        } catch (error) {
            yield payload?.callback?.success(null, error)
        }
    })
}

function* getUserCompany() {
    yield takeEvery(actionsHolonInstance.GET_USER_IN_COMPANY, function* (payload) {
        try {
            const response = yield call(() => factory.userCompany(payload?.payload));

            if (response) {
                yield payload?.callback?.success(response?.data, null);

                if (response?.data) {
                    localStorage.setItem("user-in-company", JSON.stringify(response?.data?.join(",")))
                } else {
                    localStorage.removeItem("user-in-company");
                }

            }
        } catch (error) {
            yield payload?.callback?.success(null, error)
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getAllHolonTemplate),
        fork(getHolonTemplateDetail),
        fork(getSubHolonTemplateDetail),
        fork(getHolonTemplateConnection),
        fork(getHolonTemplateWithIds),
        fork(getHolonInstance),
        fork(getHolonInstanceDetail),
        fork(createHolonInstance),
        fork(updateHolonInstance),
        fork(getHolonInstanceWithHolonTemplate),
        // fork(getHolonTemplateDetailWithHolonTemplate),
        fork(getListTemplateIdWithIdInstance),
        // fork(getHolonTemplateDetailCreateInstance),
        fork(cloneHolonInstance),
        fork(getHolonTemplateDetailPopup),
        fork(deleteHolonInstance),
        fork(requestApiWithServer),
        fork(requestPackageTracking),
        fork(getUserCompany),
        fork(getCommonFiled),
        fork(getCommonAction),
        fork(bpToPayment),
    ])
}