import React from 'react';
import styles from "./Box.module.scss";
import PropTypes from 'prop-types';
function BoxLabel(props) {
    const { minWidth = 200, width, onClick , isRequired = false} = props
    return (
        <div onClick={onClick} className={styles["BoxLabel"]} style={{ minWidth: minWidth, width: width, maxWidth: width, ...props?.style }}>
            {props?.children}
            { isRequired && <span className={styles['required']}>*</span>}
        </div>
    )
}

BoxLabel.propTypes = {
    isRequired: PropTypes.bool,
    minWidth: PropTypes.any,
    onClick: PropTypes.func,
}
export default BoxLabel;