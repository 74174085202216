import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POPUP_ADD_ALARM_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_ALARM_DETAIL_HOLON_TEMPLATE,
    EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE
} from 'utils/EventRegister';
import classes from './AlarmHolonTemplate.module.scss';
import Constants from 'utils/Constants';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import IconSearch from 'assets/icon/IconSearch';
import FormInput from 'shared/components/custom-form/FormInput';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import actionAlarm from 'redux/alarm-holon-setting/action';
import actionHolonTemplate from 'redux/holon-template/action';
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import CustomTab from 'shared/components/common/custom-tab/CustomTab';
import actionAlarms from 'redux/alarm/action';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';
import { convertDate } from 'utils/Utils';
import IconMinusAccordionFilter from 'assets/icon/IconMinusAccordionFilter';
import HolonTemplateName from 'shared/components/holon-instance/holon-template-name/HolonTemplateName';

function AlarmHolonSettingContainer() {
    const { t } = useTrans();
    const methods = useForm();
    const dispatch = useDispatch();
    const [dataTab, setDataTab] = useState([]);
    const [valueTab, setValueTab] = useState(null)
    const router = useRouter()
    const { listAlarmSettings, master, loading: {
        loadingGetAlarm
    } } = useSelector((state) => state.alarmHolonTemplate);

    const { alarmMaster } = useSelector(state => state.alarm);

    const handleOpenSearch = () => {
        let listFormItem = [
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'search',
                label: t("Holon_Template.holon_template"),
                search: router.get(Constants.QueryParams.SEARCH.VALUE) || ""
            },
            {
                elementType: Constants.ELEMENT_TYPE.TEXT.VALUE,
                key: 'message',
                label: t("alarm.content"),
                message: router.get(Constants.QueryParams.MESSAGE.VALUE) || ""
            },
            {
                elementType: Constants.VIEW_SEARCH.ROW.VALUE,
                key: 'date',
                label: t("Holon_Template.date"),
                component: [
                    {
                        elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                        key: 'from',
                        label: t("Holon_Template.from"),
                        from: router.get(Constants.QueryParams.FROM.VALUE) || null
                    },
                    {
                        elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                        key: 'to',
                        label: t("Holon_Template.to"),
                        to: router.get(Constants.QueryParams.TO.VALUE) || null
                    }
                ]
            }
        ]
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                formArray: false,
                callback: (_props) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.SEARCH.VALUE]: _props?.search,
                            [Constants.QueryParams.MESSAGE.VALUE]: _props?.message,
                            [Constants.QueryParams.FROM.VALUE]: _props?.from ? _props?.from : null,
                            [Constants.QueryParams.TO.VALUE]: _props?.to ? _props?.to : null,
                        }
                    })
                }
            }
        })
    }

    let dataHeadCell = [
        {
            label: t('Holon_Template.detail'),
            field: "detail",
            maxWidth: 100,
            width: 100,
            minWidth: 100,
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            alignHeader: 'center',
            align: 'center',
            component: (props) => {
                return <ButtonLink
                    onClick={() => showDetail(props?.data?.id)}>{t("faq_management.detail")}</ButtonLink>
            }
        },
        {
            label: t('Holon_Template.holon_template'),
            field: "holonTemplateName",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
        },
        {
            label: t('Holon_Template.key'),
            field: "holonKeyLabel",
            minWidth: '200px',
            maxWidth: '200px',
            width: '200px',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            alignHeader: 'left'
        },
        {
            label: t('Holon_Template.conditional_expression'),
            field: "conditionsText",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
        },
        {
            label: t('Holon_Template.msg'),
            field: "message",
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
        },
        {
            label: t('Holon_Template.alarm_lv'),
            field: "alarmLevel",
            minWidth: '130px',
            maxWidth: '130px',
            width: '130px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'center',
            component: (_props) => {
                const renderColor = () => {
                    if (_props?.data?.alarmLevel == "1") {
                        return "#FF9E9E"
                    }
                    if (_props?.data?.alarmLevel == "3") {
                        return "#FEFFD4"
                    }
                    if (_props?.data?.alarmLevel == "5") {
                        return "#FE461E"
                    }
                    if (_props?.data?.alarmLevel == "0") {
                        return "#D9D9D9"
                    }
                }
                return <div style={{
                    backgroundColor: renderColor(),
                    borderRadius: '3px',
                    padding: '2px 10px'
                }}>
                    {master?.alarmLevel?.[_props?.data?.alarmLevel]}
                </div>
            }
        },
        {
            label: t('Holon_Template.user_screen_notification'),
            field: "alarmOnScreen",
            minWidth: '220px',
            width: '220px',
            maxWidth: '220px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (_props) => {
                return <div className={classes['alarm-holon-template']}>
                    {
                        [..._props?.data?.alarmOnScreen]?.splice(0, 1)?.map((x, index) => {
                            return <div className={classes['alarm-holon-template-item']} key={index}>
                                <HolonTemplateName id={x} />
                            </div>
                        })
                    }
                    <div className={classes['button_readmore']}>&#8230;</div>
                </div>
            }
        },
        {
            label: t('Holon_Template.email_notification'),
            field: "alarmOnMail",
            minWidth: '220px',
            width: '220px',
            maxWidth: '220px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'center',
            component: (_props) => {
                return <div className={classes['alarm-holon-template']}>
                    {
                        _props?.data?.alarmOnMail?.length > 0 ? [..._props?.data?.alarmOnMail]?.slice(0, 3)?.map((x, index) => {
                            return <div className={classes['alarm-holon-template-item']} key={index}>
                                <HolonTemplateName id={x} />
                            </div>
                        }) : <></>
                    }<div className={classes['button_readmore']}>&#8230;</div>
                </div>
            }
        },
    ];

    const showDetail = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ALARM_DETAIL_HOLON_TEMPLATE,
            payload: {
                title: t("Holon_Template.alarm_setting_detail"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                alarmId: id,
                callback: () => {
                }
            }
        })
    }

    const handleSubmit = (data) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.KEYWORD.VALUE]: data?.keyword,
                [Constants.QueryParams.OFFSET.VALUE]: 1
            }
        })
    }

    const handleCreate = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_ALARM_HOLON_TEMPLATE,
            payload: {
                title: t("alarm.create_new_alarm_settings"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionAlarm.GET_ALARM_HOLON_SETTING,
                        payload: {
                            ...router.getAll()
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (router.get(Constants.QueryParams.TAB.VALUE)) {
            dispatch({
                type: actionAlarm.GET_ALARM_HOLON_SETTING,
                payload: {
                    ...router.getAll()
                }
            })
        }
    }, [
        router.get([Constants.QueryParams.OFFSET.VALUE]),
        router.get([Constants.QueryParams.LIMIT.VALUE]),
        router.get([Constants.QueryParams.SEARCH.VALUE]),
        router.get([Constants.QueryParams.TAB.VALUE]),
        router.get([Constants.QueryParams.FROM.VALUE]),
        router.get([Constants.QueryParams.TO.VALUE]),
        router.get([Constants.QueryParams.MESSAGE.VALUE]),
        router.get([Constants.QueryParams.KEYWORD.VALUE]),
    ])


    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT,
        })
        dispatch({
            type: actionAlarm.GET_MASTER_ALARM,
        })
        dispatch({
            type: actionAlarm.GET_HOLON_TEMPLATE_WITH_CODE_MASTER,
        })

        dispatch({
            type: actionAlarms.GET_ALARM_MASTER
        })
    }, [])

    const onPageChange = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e
            }
        })
    }

    const onChangeRowPerpage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            }
        })
    }

    const renderDataFilter = () => {
        const handleRemoveRouter = (_key) => {
            let params = { ...router.getAll() };
            delete params[_key]
            if (_key == 'date') {
                delete params[Constants.QueryParams.FROM.VALUE]
                delete params[Constants.QueryParams.TO.VALUE]
            }
            router.replace({
                params: {
                    ...params,
                    [Constants.QueryParams.OFFSET.VALUE]: 1
                }
            })
        }

        return <>
            {
                (
                    router.get(Constants.QueryParams.FROM.VALUE) || router.get(Constants.QueryParams.TO.VALUE)
                ) &&
                <FormItem>
                    <ButtonFilter
                        onClick={() => handleRemoveRouter('date')}
                        color='white'
                        size='large'
                        title={`${t("Holon_Template.created")}：${convertDate(router.get(Constants.QueryParams.FROM.VALUE))} ${router.get(Constants.QueryParams.FROM.VALUE) && router.get(Constants.QueryParams.TO.VALUE) ? '〜' : ''} ${convertDate(router.get(Constants.QueryParams.TO.VALUE))} `}
                        endIcon={<IconMinusAccordionFilter />} />
                </FormItem>
            }
            {
                router.get(Constants.QueryParams.SEARCH.VALUE) && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.SEARCH.VALUE)} color='white' size='large' title={`${t("Holon_Template.holon_template")}: ${router.get(Constants.QueryParams.SEARCH.VALUE)}`} endIcon={<IconMinusAccordionFilter />} />
            }
            {
                router.get(Constants.QueryParams.MESSAGE.VALUE) && <ButtonFilter onClick={() => handleRemoveRouter(Constants.QueryParams.MESSAGE.VALUE)} color='white' size='large' title={`${t("alarm.content")}: ${router.get(Constants.QueryParams.MESSAGE.VALUE)}`} endIcon={<IconMinusAccordionFilter />} />
            }
        </>
    }

    useEffect(() => {
        if (alarmMaster?.alarmTypes) {
            let masterType = Object.keys(alarmMaster?.alarmTypes).map(key => {
                return {
                    key: key,
                    id: key,
                    value: key,
                    label: alarmMaster?.alarmTypes[key],
                }
            });
            masterType = masterType?.sort((a, b) => a.key.localeCompare(b.key));
            setDataTab(masterType);
            router.replace({
                params: {
                    ...router.getAll(),
                    [Constants.QueryParams.TAB.VALUE]: masterType[0]?.id
                }
            })
        }
    }, [alarmMaster?.alarmTypes])

    useEffect(() => {
        if (router.get(Constants.QueryParams.TAB.VALUE)) {
            setValueTab(router.get(Constants.QueryParams.TAB.VALUE));
        } else {
            if (dataTab?.length > 0) {
                setValueTab(dataTab[0].id);
            }
        }
    }, [router.get(Constants.QueryParams.TAB.VALUE), dataTab])


    useEffect(() => {
        if (router.get(Constants.QueryParams.KEYWORD.VALUE)) {
            methods.setValue("keyword", router.get(Constants.QueryParams.KEYWORD.VALUE))
        } else {
            methods.setValue("keyword", "")
        }
    }, [
        router.get(Constants.QueryParams.KEYWORD.VALUE)
    ])

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_ALL_HOLON_TEMPLATE,
            payload: {
                isAll: true
            }
        })
    }, [])

    return (
        <ContentLayout padding={0}>
            <div>
                <CustomTab data={dataTab} value={valueTab} onChange={(e) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.TAB.VALUE]: e
                        }
                    })
                }} />
                <div className={classes['alarm-container-content']}>
                    <div className={classes['custom-search']}>
                        <CustomFormProvider {...methods}>
                            <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                                <FormSearch className={classes['custom-form-search']}>
                                    <FormGroup>
                                        <FormItem>
                                            <FormInput iconSearch={<IconSearch fontSize={16} />} isSearch fieldName='keyword' placeholder={t("Holon_Template.search_keyword")} />
                                        </FormItem>
                                        {
                                            renderDataFilter(router.get(Constants.QueryParams.ATTRIBUTES.VALUE))
                                        }
                                        <ButtonFilter onClick={handleOpenSearch} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                    </FormGroup>
                                    <ButtonAdd onClick={handleCreate} size='large' color='yellow' endIcon={<IconPlusWhite />} />
                                </FormSearch>
                                <button hidden type='submit' />
                            </form>
                        </CustomFormProvider>
                    </div>
                    <div className={classes['custom-body']}>
                        <CustomTable loading={loadingGetAlarm} data={listAlarmSettings?.records ?? []} headCell={dataHeadCell} />
                        <div className={classes['custom-pagination']}>
                            <CustomPagination
                                isRowPerpage
                                onChangeRowPerpage={onChangeRowPerpage} onChange={onPageChange} total={listAlarmSettings?.total} limit={router.get(Constants.QueryParams.LIMIT.VALUE)} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
                        </div>
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default AlarmHolonSettingContainer