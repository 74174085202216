import React from 'react';

function IconInput({ color = 'white' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="41"
            viewBox="0 0 31 37"
            fill="none"
        >
            <path d="M23.8984 0H6.20697C2.7841 0 0 2.78411 0 6.20697V30.793C0 34.2153 2.78411 37 6.20704 37H18.5009H19.4522L20.1256 36.3271L29.4326 27.0201L30.1054 26.3467V25.3943V6.20697C30.1054 2.78411 27.3213 0 23.8984 0ZM27.8072 25.3943H21.7579C19.9586 25.3943 18.5007 26.8533 18.5007 28.6519V34.7018H6.20689C4.04846 34.7018 2.29805 32.9519 2.29805 30.793V6.20697C2.29805 4.04803 4.04854 2.29812 6.20689 2.29812H23.8983C26.0578 2.29812 27.8072 4.04803 27.8072 6.20697V25.3943H27.8072Z" fill="white" />
            <path d="M8.43035 9.17159H6.21295V11.2877H8.43035V9.17159Z" fill="white" />
            <path d="M8.43035 14.3227H6.21295V16.4383H8.43035V14.3227Z" fill="white" />
            <path d="M8.43035 19.4738H6.21295V21.5895H8.43035V19.4738Z" fill="white" />
            <path d="M8.43035 24.6249H6.21295V26.7405H8.43035V24.6249Z" fill="white" />
            <path d="M23.893 9.17159H10.8223V11.2877H23.893V9.17159Z" fill="white" />
            <path d="M23.893 14.3227H10.8223V16.4383H23.893V14.3227Z" fill="white" />
            <path d="M23.893 19.4738H10.8223V21.5895H23.893V19.4738Z" fill="white" />
            <path d="M16.1945 24.6249H10.8223V26.7405H16.1945V24.6249Z" fill="white" />
        </svg>
    );
}

export default IconInput;
