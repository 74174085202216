import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import Validator from 'utils/Validator';
import CustomDatePicker from '../form-input/date-picker/DatePicker';
import moment from 'moment';

const FormDatePicker = (props) => {
    const {
        fieldName,
        validate = [],
        withPortal = false,
        isSearch = false,
        disabled,
        isRegexLabel,
        validationFE = false
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className="Input w-100">
            <Controller
                control={control}
                name={fieldName}
                rules={{ validate: Validator.genValidate(validate, fieldName) }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <>
                            <CustomDatePicker
                                value={value}
                                onChange={(e) => {
                                    if (e) {
                                        onChange(moment(e).format("YYYY-MM-DD"))
                                    } else {
                                        onChange("")
                                    }
                                }}
                                errors={errors}
                                onBlur={onBlur}
                                inputRef={ref}
                                withPortal={withPortal}
                                isSearch={isSearch}
                                name={fieldName}
                                disabled={disabled}
                                isRegexLabel={isRegexLabel}
                                validationFE={validationFE}
                                showTimeInput
                            />
                        </>
                    );
                }}
            />
        </div>
    );
};

FormDatePicker.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isSearch: PropTypes.bool,
};
export default FormDatePicker;
