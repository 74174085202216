import React from 'react'
import classes from './ContentMessage.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import Constants from 'utils/Constants';
import Content from './Content';

function InfiniteScrollContent(props) {
    const {
        listMessage,
        handleForwardMsg,
        handleRepMsg,
        fetchMoreData,
    } = props;

    function checkDate(date1, date2) {
        if (date1 && date2) {
            const momentDate1 = moment(moment(date1).format("YYYY-MM-DD"));
            const momentDate2 = moment(moment(date2).format("YYYY-MM-DD"));

            if (momentDate1.isSame(momentDate2)) {
                return true;
            } else {
                return false;
            }
        } else if (date1 && !date2) {
            return false;
        }

        return false;
    }

    const renderDate = (x) => {
        return <div>{moment(x?.updatedAt).format(Constants.FORMAT_DATE_TIME.DATE)}</div>
    }

    return (
        <>
            <div
                style={{
                    height: 'calc(100vh - 60px - 100px)',
                    maxHeight: 'calc(100vh - 60px - 100px)',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
                id='messageContainerChat'
                className={`${classes['Message']}`}
            >
                <InfiniteScroll
                    dataLength={listMessage.length}
                    next={fetchMoreData}
                    style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'hidden' }}
                    inverse={true}
                    hasMore={true}
                    // loader={loadingChat?.current ? <div style={{ paddingTop: 10 }} className={classes['ContentLoading-InfiniteScroll']}>
                    //     <Loading color="hippie_blue" />
                    // </div> : null}
                    loader={<></>}
                    scrollableTarget="messageContainerChat"
                    className={classes['Message_List']}
                >
                    {
                        listMessage?.map((x, key) => {
                            return <React.Fragment key={key}>
                                <Content listMessage={listMessage} index={key} handleForwardMsg={handleForwardMsg} key={key} x={x} handleRepMsg={handleRepMsg} />
                                {
                                    checkDate(x?.updatedAt, listMessage[key + 1]?.updatedAt) ? null : <div className={classes['Message_Date']}>
                                        {renderDate(x)}
                                    </div>
                                }
                            </React.Fragment>
                        })
                    }
                </InfiniteScroll>
            </div>
        </>
    )
}

export default InfiniteScrollContent