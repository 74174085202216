import React, { useEffect, useRef } from 'react'
import classes from './ContentMessage.module.scss';
import ContentHeader from '../content-header/ContentHeader';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormInputMessage from 'shared/components/custom-form/FormInputMessage';
import { useForm } from 'react-hook-form';
import FormInputUploadMessage from 'shared/components/custom-form/FormInputUploadMessage';
import { useDispatch, useSelector } from 'react-redux';
import { actionMessage } from 'redux/message/action';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import moment from 'moment';
import IconClose from 'assets/icon/IconClose';
import ImageMessage from 'shared/components/common/image/ImageMessage';
import RenderFileAttachmentLink from './AttachmentLink';
import Button from 'shared/components/form-input/button/Button';
import IconSendMsg from 'assets/icon/IconSendMsg';
import Loading from 'shared/components/form-input/button/loading/Loading';
import useCustomState from 'hooks/use-custom-state';
import InfiniteScrollContent from './InfiniteScrollContent';


function ContentMessage(props) {
    const { listChannel,
        handleSyncChannel,
        methodsFilterMessage,
        refMessageInterval,
        addUserToChannel,
        channelInfo,
        handleForwardMsg
    } = props;
    const { listMessage, loadingGetMsg } = useSelector(state => state?.message);
    const methods = useForm();
    const refScrollBottom = useRef(true);
    const loadingChat = useRef(false);
    const [replyMessage, setReplyMessage] = useCustomState(null);
    const [loadingInput, setLoadingInput] = useCustomState(false);
    const [channelId, setChannelId] = useCustomState(null);
    const dispatch = useDispatch();
    const router = useRouter();
    const refButtonSubmit = useRef();
    const messageFilter = useRef(null);

    const handleSendMsgSuccess = (_messageFilter) => {
        dispatch({
            type: actionMessage.REQUEST_SYNC_MESSAGE,
            payload: {
                channelId: router.get(Constants.QueryParams.CHANNEL.VALUE),
                date: null,
                messageFilter: _messageFilter,
                lastMessageId: listMessage?.[0]?.messageId,
            },
            callback: {
                success: () => {
                    refMessageInterval.current = true;
                    setTimeout(() => {
                        methods.setFocus("message", {})
                    }, 0);
                }
            }
        });
    }
    // Handle submit send message
    const onSubmit = (e) => {
        let arr = [];
        if (e?.file?.length > 1) {
            arr = e?.file;
        }
        if (e?.message?.trim() || e?.file?.length > 0) {
            setLoadingInput(true);
            refMessageInterval.current = false;
            let params = {};
            if (e?.file?.length > 0) {
                params['attachmentLink'] = e?.file[0]?.content
            }

            if (replyMessage?.messageId) {
                params['replyMessageId'] = replyMessage?.messageId
            }
            dispatch({
                type: actionMessage.SEND_MESSAGE,
                payload: {
                    channelId: router.get(Constants.QueryParams.CHANNEL.VALUE),
                    messageContent: e?.message?.trim() || "",
                    ...params
                },
                callback: {
                    success: () => {
                        setLoadingInput(false);
                        setReplyMessage(null);
                        methods.setValue("message", null)
                        methods.setValue("file", []);

                        // Handle append new message to list
                        handleSendMsgSuccess(messageFilter.current);

                        arr?.forEach((x, index) => {
                            if (index != 0) {
                                setLoadingInput(true);
                                refMessageInterval.current = false;
                                let _params = {};
                                _params['attachmentLink'] = x?.content
                                dispatch({
                                    type: actionMessage.SEND_MESSAGE,
                                    payload: {
                                        channelId: router.get(Constants.QueryParams.CHANNEL.VALUE),
                                        messageContent: "",
                                        ..._params
                                    },
                                    callback: {
                                        success: () => {
                                            setLoadingInput(false);
                                            setReplyMessage(null);
                                            methods.setValue("message", null)
                                            methods.setValue("file", []);
                                            handleSendMsgSuccess(messageFilter.current);
                                            handleSyncChannel()
                                        },
                                        failed: () => {
                                            setLoadingInput(false);
                                            methods.setFocus("message", {})
                                        }
                                    }
                                })
                            }
                        })
                        handleSyncChannel()
                    },
                    failed: () => {
                        setLoadingInput(false);
                        methods.setFocus("message", {})
                    }
                }
            })
        }
    }

    // handle reload message after 5000s
    useEffect(() => {
        const interval = setInterval(() => {
            if (router.get(Constants.QueryParams.CHANNEL.VALUE) && refMessageInterval.current) {
                dispatch({
                    type: actionMessage.REQUEST_SYNC_MESSAGE,
                    payload: {
                        channelId: router.get(Constants.QueryParams.CHANNEL.VALUE),
                        date: null,
                        messageFilter: messageFilter.current,
                        lastMessageId: listMessage?.[0]?.messageId
                    },
                    callback: {
                        success: () => {
                        },
                        failed: () => {
                            refMessageInterval.current = false;
                            clearInterval(interval)
                        }
                    }
                })
            }
        }, 5000, [messageFilter.current]);
        if (!refMessageInterval.current) {
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [router.get(Constants.QueryParams.CHANNEL.VALUE), refMessageInterval, listMessage, messageFilter])

    // handle scroll bottom 
    useEffect(() => {
        if (listMessage?.length > 0 && router.get(Constants.QueryParams.CHANNEL.VALUE) != channelId) {
            setChannelId(router.get(Constants.QueryParams.CHANNEL.VALUE));
        }
    }, [listMessage, router.get(Constants.QueryParams.CHANNEL.VALUE), channelId])

    useEffect(() => {
        return () => {
            setLoadingInput(false);
            refScrollBottom.current = true;
            dispatch({
                type: actionMessage.RESET_GET_MESSAGE_SUCCESS,
                payload: {
                    listMessage: [],
                    listUser: [],
                    listChannel: {},
                    messageChannel: [],
                }
            })
        }
    }, [])

    const handleCloseReply = () => {
        setReplyMessage(null);
    }

    const handleRepMsg = (message) => {
        setReplyMessage(message);
        methods.setFocus("message")
    }

    const handleScroll = (e, _messageFilter) => {
        loadingChat.current = true;
        refMessageInterval.current = false;
        dispatch({
            type: actionMessage.REQUEST_GET_SCROLL_MESSAGE,
            payload: {
                channelId: router.get(Constants.QueryParams.CHANNEL.VALUE),
                date: null,
                messageFilter: _messageFilter,
                firstMessageId: listMessage?.[listMessage?.length - 1]?.messageId
            },
            callback: {
                success: () => {
                    loadingChat.current = false;
                    refMessageInterval.current = true;
                }
            }
        });
    }

    const getTypeAttachmentLink = (filePath) => {
        let fileExtension = "";
        if (filePath) {
            fileExtension = filePath.substring(filePath.lastIndexOf('.') + 1)
        }
        return fileExtension;
    }

    const onFilterMessage = (e, channel) => {
        if (e) {
            messageFilter.current = e;
            refMessageInterval.current = false;
            dispatch({
                type: actionMessage.REQUEST_GET_MESSAGE,
                payload: {
                    channelId: channel,
                    date: null,
                    messageFilter: e,
                },
                callback: {
                    success: () => {
                        refMessageInterval.current = true;
                    },
                    failed: () => {
                        refMessageInterval.current = false;
                    }
                }
            })
        } else {
            messageFilter.current = null;
            methodsFilterMessage.setValue("search_message", "");
            onCloseFilterMessage(channel);
        }
    }
    const onCloseFilterMessage = (channelId) => {
        messageFilter.current = null;
        refMessageInterval.current = false;
        dispatch({
            type: actionMessage.REQUEST_GET_MESSAGE,
            payload: {
                channelId: channelId,
                date: moment(new Date()).format("YYYY-DD-MM"),
                messageFilter: "",
            },
            callback: {
                success: () => {
                    refMessageInterval.current = true;
                },
                failed: () => {
                    refMessageInterval.current = false;
                }
            }
        })
    }
    return (
        <>
            {
                loadingGetMsg
                    ? <div className={classes['ContentLoading']}>
                        <Loading color="hippie_blue" />
                    </div> :
                    router.get(Constants.QueryParams.CHANNEL.VALUE) ? <div className={classes['ContentMessage']}>
                        <ContentHeader channelInfo={channelInfo} addUserToChannel={addUserToChannel} onCloseFilterMessage={onCloseFilterMessage} onFilterMessage={onFilterMessage} methodsFilterMessage={methodsFilterMessage} listChannel={listChannel} />
                        <InfiniteScrollContent
                            listMessage={listMessage}
                            handleForwardMsg={handleForwardMsg}
                            handleRepMsg={handleRepMsg}
                            fetchMoreData={(e) => handleScroll(e, messageFilter.current)}
                            loadingChat={loadingChat}
                        />
                        <div className={classes['Form_Input_Message']}>
                            {
                                replyMessage && <div className={classes['Form_Heading']}>
                                    <div className={classes['Info_Message_Reply']}>
                                        <div className={classes['Avatar']}>
                                            <ImageMessage src={replyMessage?.avatar || ""} />
                                        </div>
                                        <div className={classes['Content']}>
                                            <p className={classes['Title']}>{replyMessage?.displayName}</p>

                                            {
                                                replyMessage?.attachmentLink && <div className={classes['File_Reply']}> {
                                                    <RenderFileAttachmentLink type={getTypeAttachmentLink(replyMessage?.attachmentLink)} x={replyMessage} />
                                                } </div>
                                            }

                                            <p className={classes['Msg']}>{replyMessage?.messageContent}</p>
                                        </div>
                                    </div>
                                    <div className={classes['Icon_Close']} onClick={handleCloseReply}>
                                        <IconClose color='#929292' fontSize={14} />
                                    </div>
                                </div>
                            }
                            {
                                <div className={classes['Form_Input']}>
                                    <CustomFormProvider {...methods}>
                                        <form onKeyDown={(e) => {
                                            if (e.keyCode == 13 && !e.shiftKey) {
                                                if (refButtonSubmit.current) {
                                                    refButtonSubmit.current.click();
                                                }
                                            }
                                        }} onSubmit={methods.handleSubmit(onSubmit)}>
                                            <div style={{
                                                flexDirection: methods.watch("file") && methods.watch("file")?.length > 0 ? 'column-reverse' : 'row',
                                                gap: methods.watch("file") && methods.watch("file")?.length > 0 ? '5px' : '0px',
                                                padding: '13px 20px'
                                            }} className={classes['Form_Row']}>
                                                <div style={{
                                                    width: 'calc(100% - 60px)'
                                                }}>
                                                    <FormInputMessage disabled={loadingInput} fieldName='message' />
                                                </div>
                                                {
                                                    replyMessage?.messageId && !replyMessage?.messageContent ? <></> : <div style={{
                                                        width: methods.watch("file") && methods.watch("file")?.length > 0 ? '100%' : '10px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: methods.watch("file") && methods.watch("file")?.length > 0 ? 'flex-start' : 'center',
                                                        pointerEvents: loadingInput ? 'none' : 'unset'
                                                    }}>
                                                        <FormInputUploadMessage onChangeFile={() => {
                                                            setTimeout(() => {
                                                                methods.setFocus("message")
                                                            }, 0);
                                                        }} disabled={loadingInput} loading={loadingInput} fieldName='file' />
                                                    </div>
                                                }
                                                <div style={{
                                                    position: 'absolute',
                                                    right: 10,
                                                    top: 12
                                                }}>
                                                    <Button refButton={refButtonSubmit} disabled={false} type='submit' startIcon={<IconSendMsg />} backgroundTransparent />
                                                </div>
                                            </div>
                                        </form>
                                    </CustomFormProvider>
                                </div>
                            }
                        </div>
                    </div> : <></>
            }
        </>
    )
}

export default ContentMessage;