import React from 'react';
import styles from "./Box.module.scss";
import PropsType from 'prop-types';

function BoxBody(props) {
    const { padding = '14px 19px', style, id = "" } = props
    return (
        <div id={id} className={styles["BoxBody"]} style={{ padding: padding, ...style }}>
            {props?.children}
        </div>
    )
}

BoxBody.propTypes = {
    id: PropsType.string,
    padding: PropsType.any,
    style: PropsType.any,
}
export default BoxBody;