import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import InquiryAction from "./action";
import InquiryFactory from "./factory";
import InquiryMapper from "./mapper";
import { getToastError, getToast } from "utils/Utils";


function* getInquiryList() {
    yield takeEvery(InquiryAction.GET_INQUIRY_LIST, function* (payload) {
        try {
            const response = yield call(() =>
                InquiryFactory.getInquiryList(payload?.payload)
            );
            yield put({
                type: InquiryAction.GET_INQUIRY_LIST_SUCCESS,
                payload: InquiryMapper.mapSearchInquiryResponseToView(response),
            });
        } catch (error) {
            yield put({
                type: InquiryAction.GET_INQUIRY_LIST_FAILED,
            });
        }
    });
}

function* getInquiryDetailById() {
    yield takeEvery(InquiryAction.GET_INQUIRY_DETAIL, function* (payload) {
        try {
            const response = yield call(() =>
                InquiryFactory.getInquiryDetailById(payload?.payload)
            );
            yield put({
                type: InquiryAction.GET_INQUIRY_DETAIL_SUCCESS,
                payload: InquiryMapper.mapDataInquiryDetailApiToView(response),
            });
        } catch (error) {
            yield put({
                type: InquiryAction.GET_INQUIRY_DETAIL_FAILED,
            });
        }
    });
}

function* insertInquiry() {
    yield takeEvery(InquiryAction.INSERT_INQUIRY, function* (formData) {
        try {
            const {
                payload,
                onSuccess = () => {},
                onFailure = () => {},
            } = formData;

            const response = yield call(() =>
                InquiryFactory.insertInquiry(payload)
            );

            const requestError = response.error ?? null;
            if (requestError) {
                const detailError = requestError.detail ?? {};
                typeof onFailure === "function" && onFailure(detailError);
                yield put({
                    type: InquiryAction.INSERT_INQUIRY_FAILED,
                });
            } else {
                typeof onSuccess === "function" && onSuccess();
                yield put({
                    type: InquiryAction.INSERT_INQUIRY_SUCCESS,
                    payload: response,
                });
            }
        } catch (error) {
            yield put({
                type: InquiryAction.INSERT_INQUIRY_FAILED,
            });
        }
    });
}

function* updateInquiry() {
    yield takeEvery(InquiryAction.UPDATE_INQUIRY, function* (formData) {
        try {
            const {
                payload,
                onSuccess = () => {},
                onFailure = () => {},
            } = formData;

            // check exist id
            if (!payload?.id) {
                yield put({
                    type: InquiryAction.UPDATE_INQUIRY_FAILED,
                });
            }
            const response = yield call(() =>
                InquiryFactory.updateInquiry(payload)
            );

            const requestError = response.error ?? null;
            if (requestError) {
                const detailError = requestError.detail ?? {};
                typeof onFailure === "function" && onFailure(detailError);
                yield put({
                    type: InquiryAction.UPDATE_INQUIRY_FAILED,
                });
            } else {
                typeof onSuccess === "function" && onSuccess();
                yield put({
                    type: InquiryAction.UPDATE_INQUIRY_SUCCESS,
                    payload: response,
                });
            }
        } catch (error) {
            yield put({
                type: InquiryAction.UPDATE_INQUIRY_FAILED,
            });
        }
    });
}

function* deleteInquiry() {
    yield takeEvery(InquiryAction.DELETE_INQUIRY, function* (formData) {
        try {
            const { payload, onSuccess = () => {} } = formData;

            // check exist id
            if (!payload) {
                yield put({
                    type: InquiryAction.DELETE_INQUIRY_FAILED,
                });
            }
            const response = yield call(() =>
                InquiryFactory.deleteInquiry(payload)
            );
            if (typeof onSuccess === "function") {
                onSuccess();
            }
            yield put({
                type: InquiryAction.DELETE_INQUIRY_SUCCESS,
                payload: response,
            });
        } catch (error) {
            yield put({
                type: InquiryAction.DELETE_INQUIRY_FAILED,
            });
        }
    });
}

function* createUserInquiry() {
    yield takeEvery(InquiryAction.CREATE_USER, function* (formData) {
        const {
            payload,
            onSuccess = () => {},
            onFailure = () => {},
        } = formData;
        try {
            if (!payload?.id) {
                yield put({
                    type: InquiryAction.CREATE_USER_FAILED,
                });
                getToastError(response?.error?.detail)
            }
            const response = yield call(() =>
                InquiryFactory.createUserAccount(payload)
            );
            if(!response?.error && response){
                yield put({
                    type: InquiryAction.CREATE_USER_SUCCESS,
                    payload: response,
                });
                onSuccess(payload?.id);
                getToast("update success", "success")
            }else{
                yield put({
                    type: InquiryAction.CREATE_USER_FAILED,
                });
                getToastError(response?.error?.detail)
                onFailure(payload?.id)
            }

        } catch (res) {
            const { data: { error } } = res?.response;
            yield put({
                type: InquiryAction.CREATE_USER_FAILED,
            });
            onFailure(payload?.id)
            getToastError(error?.detail)
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getInquiryList),
        fork(getInquiryDetailById),
        fork(insertInquiry),
        fork(updateInquiry),
        fork(deleteInquiry),
        fork(createUserInquiry)
    ]);
}
