import useTrans from 'hooks/use-trans';
import React from 'react'
import HolonTemplateContainer from 'shared/container/holon-template/HolonTemplateContainer'

function HolonTemplatePage() {
    const { t } = useTrans();
    document.title = t("Holon_Template.holon_template");
    return (
        <HolonTemplateContainer />
    )
}

export default HolonTemplatePage