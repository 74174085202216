import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const factory = {
    getListSender: (payload) => {

        let params = {
        };

        let data = {
            subAttribute: {}
        }

        if (payload[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.KEYWORD.VALUE];
        }

        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload[Constants.QueryParams.ATTRIBUTES.VALUE]) {
            data['mainAttribute'] = JSON.parse(payload[Constants.QueryParams.ATTRIBUTES.VALUE]);
        }

        let url = `/rest/api/v1/management/sender/filter/${payload[Constants.QueryParams.TYPE.VALUE]}`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            params: params,
            data: data
        })
    },
    updateSender: (payload) => {
        let url = "/rest/api/v1/management/sender";
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    deleteSender: (payload) => {
        let url = `/rest/api/v1/management/sender/${payload?.id}`;
        return ApiOperation.request({
            url: url,
            method: 'DELETE',
            data: {
                mode: payload?.mode
            }
        })
    },
    createSender: (payload) => {
        let url = `/rest/api/v1/management/sender`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    getDetailSender: (payload) => {
        let url = `/rest/api/v1/management/sender/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getHolonTemplateDetail: (payload) => {
        let url = `/rest/api/v1/management/sender/template`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
}

export default factory;