import React, { useEffect } from 'react'
import ContentLayout from '../common/content-layout/ContentLayout';
import CustomFormProvider from '../custom-form/CustomFormProvider';
import classes from './Faq.module.scss';
import FormInput from '../custom-form/FormInput';
import Validator from 'utils/Validator';
import Button from '../form-input/button/Button';
import IconSearch from 'assets/icon/IconSearch';
import IconChevronDown from 'assets/icon/IconChevronDown';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import { useDispatch } from 'react-redux';
import useTrans from 'hooks/use-trans';
import FaqAction from 'redux/faq/action';

function Faq(props) {
    const dispatch = useDispatch();
    const { t } = useTrans()


    useEffect(() => {
        dispatch({
            type: FaqAction.GET_FAQ_CATEGORIES_MASTER,
            payload: {
                isAll: true,
            }
        })
    }, [])
    return (
        <ContentLayout width={props?.width} margin={props?.margin}>
            <div className={classes['FaqContainer']}>
                <CustomFormProvider {...props?.methods}>
                    <form onSubmit={props?.methods.handleSubmit(props?.onSubmit)}>
                        <div className={classes['heading']}>
                            <p>FAQ</p>
                        </div>
                        <div className={classes['search']}>
                            <div className={classes['search-content']}>
                                <FormInput
                                    fieldName="Search"
                                    placeholder={t("faq_management.faq_search")}
                                    background="#FFFFFF"
                                    isSearch
                                    validate={[Validator.maxLengthInput(255)]}
                                />
                                <div className={classes['button']}>
                                    <Button
                                        size="large"
                                        startIcon={<IconSearch fontSize={15} />}
                                        title={t("faq_management.search")}
                                        color="default"
                                        type="submit"
                                        className="Icon-Search"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes['group-search']}>
                            <div className={classes['heading-group-search']}>
                                <p>{t("faq_management.search_by_category")}</p>
                            </div>
                            <div className={classes['group-form']}>
                                {props?.dataFaqCategory?.map((x, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${classes['form-item']} ${props?.activeCategory == x?.id
                                                ? classes['form-item-active']
                                                : ''}`}
                                            onClick={() => props?.onChangeCategory(x?.id)}
                                        >
                                            <div className={classes['category-name']} dangerouslySetInnerHTML={{
                                                __html: x.categoryName
                                            }} />
                                            <div>
                                                <IconChevronDown />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </form>
                </CustomFormProvider>
                <div className={classes['body']}>
                    <div className={classes['heading-body']}>
                        <p>
                            {
                                props?.dataFaqCategory?.find((x) => x?.id == props?.activeCategory)
                                    ?.categoryName
                            }
                        </p>
                    </div>
                    <div className={classes['content-body']}>
                        {props?.faq?.records?.map((x, index) => {
                            return (
                                <div
                                    key={index}
                                    className={classes['row-item-accordion']}
                                >
                                    <div className={classes['row-item-heading']}>
                                        <p>Q.{x.question}</p>
                                        <div
                                            onClick={() => {
                                                if (props?.activeAccordion == x?.id) {
                                                    props?.setActiveAccordion(null);
                                                    return;
                                                } else {
                                                    props?.setActiveAccordion(x?.id);
                                                }
                                            }}
                                            className={classes['action']}
                                        >
                                            {props?.activeAccordion != x?.id ? (
                                                <IconPlusAccordion />
                                            ) : (
                                                <IconMinusAccordion />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`${classes['content-accordion']} ${props?.activeAccordion == x?.id
                                            ? classes['open']
                                            : ''}`}
                                    >
                                        <div>A.</div>
                                        <div dangerouslySetInnerHTML={{
                                            __html: x?.answer
                                        }} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default Faq