import React from 'react';
import { Redirect } from 'react-router-dom';
import DashboardPage from "pages/dashboard/DashboardPage";
import FreightCalculationManagementPage from "pages/freight-calculation-management/FreightCalculationManagementPage";
import EstimatedFarePage from "pages/estimated-fare/EstimatedFarePage";
import EstimateFareManagementPage from "pages/estimate-fare-management/EstimateFareManagementPage";
import Services from "pages/system/SystemServices";
import SystemTemplate from "pages/system/SystemTemplate";
// import Configuration from "pages/settings/Configuration";
// import Accounts from "pages/settings/AccountSettingsPage";
import RouterPath from "./RouterPath";
import Messages from 'pages/Messages';
import HomeLayout from 'shared/components/layout/HomeLayout';
import Home from "pages/home/Home";
// import CommonInput from 'pages/CommonInput';
import TermsOfUsePage from 'pages/system/TermsOfUse';
import FaqPage from 'pages/system/Faq';
import PolicyPage from 'pages/policy-setting/PolicyPage';
import UpdatePolicyPage from 'pages/policy-setting/UpdatePolicyPage';
import PrivacyPolicyPage from 'pages/system/PrivacyPolicy';
import PrivatePolicyPage from 'pages/system/PrivatePolicy';
import FaqManagementPage from 'pages/faq/FaqManagementPage';
import HolonTemplatePage from 'pages/holon-template/HolonTemplatePage';
import MainLayout from 'shared/components/layout/MainLayout';
import MenuManagement from 'pages/system/MenuManagement';
import HolonInstancePage from 'pages/holon-template/HolonInstancePage';
// import ServiceIntroduction from 'pages/System/ServiceIntroduction';
import MessagePage from 'pages/message/MessagePage';
import AlarmHolonSettingPage from 'pages/alarm-holon-setting/AlarmHolonSettingPage';
import { AdminGuard } from './guards/AdminGuard';
import HexsevicePage from 'pages/hex-service/HexsevicePage';
import UpdateHexServicePage from 'pages/hex-service/UpdateHexServicePage';
import BlInstancePage from 'pages/bl-instance/BlInstancePage';
import UserAccountListPage from 'pages/user/user-account-list/UserAccountListPage';
import AlarmPage from 'pages/alarm-holon-setting/AlarmPage';
import InquiryListPage from 'pages/inquiry/InquiryListPage';
import ResetPasswordPage from 'pages/user/id-password-reset/ResetPasswordPage';
import ProductPage from 'pages/product/ProductPage';
import CreateProductPage from 'pages/product/CreateProductPage';
import UpdateProductPage from 'pages/product/UpdateProductPage';
// import AccountSettings from 'pages/settings/AccountSettings';
import AccountSettingsPage from 'pages/settings/AccountSettingsPage';
import WarehousePage from 'pages/warehouse/WarehousePage';
import Configuration from 'pages/settings/Configuration';
import SenderPage from 'pages/sender/SenderPage';
import ReceiverPage from 'pages/receiver/ReceiverPage';
import OrderManagement from 'pages/system/OrderManagement';
import CompanyTemplate from 'pages/system/CompanyTemplate';
import RequestEstimationPage from 'pages/request-estimation/RequestEstimationPage';
import EstimationPage from 'pages/estimation/EstimationPage';
import { GuestGuard } from './guards/GuestGuard';
import ContractPage from 'pages/contract/ContractPage';
import BPOrderPage from 'pages/bp-order';
import ShipperPage from 'pages/shipper/ShipperPage';
import FreightPage from 'pages/freight/FreightPage';
import WalletPage from 'pages/wallet/WalletPage';
import InventoryCountPage from 'pages/stocks/InventoryCountPage';
import InventoryManagementPage from 'pages/stocks/InventoryManagementPage';
import CargoPage from 'pages/cargo/CargoPage';
import WarehouseInPage from 'pages/warehouse/WarehouseInPage';
import WalletChargePage from 'pages/wallet/WalletChargePage';
import WalletWithdrawPage from 'pages/wallet/WalletWithdrawPage';
import TransactionPage from 'pages/transaction/TransactionPage';
import SystemSetting from 'pages/system-setting/SystemSetting';
import StockManagementPage from 'pages/stocks/StockManagementPage';
import WarehouseOutPage from 'pages/warehouse/WarehouseOutPage';
import RequestPaymentPage from 'pages/request-payment/RequestPaymentPage';
import BpRequestPaymentPage from 'pages/bp-request-payments/BpRequestPaymentPage';
import BpFareTableManagementPage from 'pages/bp-fare-table-management/BpFareTableManagementPage';
import NotifyPage from 'pages/notify/NotifyPage';

const Routes = [
    {
        layout: MainLayout,
        routes: [
            {
                id: "PORTAL_ALARM_HOLONE_SETTING",
                component: <AlarmHolonSettingPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_BL_INSTANCE",
                component: <BlInstancePage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_ALARM",
                component: <AlarmPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_BP_ORDER",
                component: <BPOrderPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "CARGO",
                component: <CargoPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_UPDATE_HEX_SERVICE",
                component: <UpdateHexServicePage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_HEX_SERVICE",
                component: <HexsevicePage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            // {
            //     id: "MESSAGE",
            //     component: <MessagePage />,
            //     guards: [AdminGuard],
            //     fallback: () => <Redirect to={RouterPath.LOGIN} />
            // },
            {
                id: "PORTAL_HOLON_INSTANCE",
                component: <HolonInstancePage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_NOTIFY",
                component: <NotifyPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_ORDER_MANAGEMENT",
                component: <OrderManagement />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_COMPANY_TEMPLATE",
                component: <CompanyTemplate />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_SYSTEM_SETTING",
                component: <SystemSetting />,
                guards: [],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_MENU_MANAGEMENT",
                component: <MenuManagement />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "FREIGHT_CALCULATION_MANAGEMENT",
                component: <FreightCalculationManagementPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "ESTIMATED_FARE_MANAGEMENT",
                component: <EstimateFareManagementPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_POLICY",
                component: <PolicyPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_POLICY_TERM_OF_USE",
                component: <PolicyPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_POLICY_PRIVATE_POLICY",
                component: <PolicyPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_POLICY_PRIVACY_POLICY",
                component: <PolicyPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_PRIVACY_POLICY",
                component: <PrivacyPolicyPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_PRIVATE_POLICY",
                component: <PrivatePolicyPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_TRANSACTION",
                component: <TransactionPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WAREHOUSE",
                component: <WarehousePage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WAREHOUSE_IN_PARAMS",
                component: <WarehouseInPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WAREHOUSE_IN",
                component: <WarehouseInPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WAREHOUSE_OUT_PARAMS",
                component: <WarehouseOutPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WAREHOUSE_OUT",
                component: <WarehouseOutPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WALLET_CHARGE",
                component: <WalletChargePage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WALLET_WITHDRAW",
                component: <WalletWithdrawPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_WALLET",
                component: <WalletPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_CREATE_PRODUCT",
                component: <CreateProductPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_UPDATE_PRODUCT",
                component: <UpdateProductPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_PRODUCT",
                component: <ProductPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_UPDATE_POLICY",
                component: <UpdatePolicyPage />,
                guards: [AdminGuard],
                fallback: () => {
                    return <Redirect to={RouterPath.LOGIN} />;
                }
            },
            {
                id: "PORTAL_TERM_OF_USE",
                component: <TermsOfUsePage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_FAQ",
                component: <FaqPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_FAQ_MANAGEMENT",
                component: <FaqManagementPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_HOLON_TEMPLATE",
                component: <HolonTemplatePage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_SETTING_CONFIGURATION",
                guards: [AdminGuard],
                component: <Configuration />,
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_SETTING",
                path: "/portal/settings",
                component: <AccountSettingsPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_SHIPPER",
                path: "/portal/settings",
                component: <ShipperPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_INVENTORY_MANAGEMENT",
                path: "/portal/inventory-management",
                component: <InventoryManagementPage />,
                guards: [],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_INVENTORY_COUNT",
                path: "/portal/inventory-count",
                component: <InventoryCountPage />,
                guards: [],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_FREIGHT",
                path: "/portal/freight",
                component: <FreightPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_USER_ACCOUNT_LIST",
                component: <UserAccountListPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_SENDER",
                component: <SenderPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_RECEIVER",
                component: <ReceiverPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_REQUEST_PAYMENT",
                component: <RequestPaymentPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_BP_REQUEST_PAYMENT",
                component: <BpRequestPaymentPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_BP_FARE_TABLE_MANAGEMENT",
                component: <BpFareTableManagementPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_STOCK_MANAGEMENT",
                path: "/portal/stock-management",
                component: <StockManagementPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_SYSTEM_TEMPLATE",
                path: "/portal/system/template",
                component: <SystemTemplate />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_SYSTEM_SERVICE",
                path: "/portal/system/services",
                guards: [AdminGuard],
                component: <Services />,
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_INBOX",
                component: <MessagePage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_REQUEST_ESTIMATION",
                component: <RequestEstimationPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_ESTIMATION",
                component: <EstimationPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_CONTRACT",
                component: <ContractPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_INQUIRY_LIST",
                component: <InquiryListPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
            {
                id: "PORTAL_DASHBOARD",
                component: <DashboardPage />,
                guards: [AdminGuard],
                fallback: () => <Redirect to={RouterPath.LOGIN} />
            },
        ]
    },
    {
        layout: HomeLayout,
        routes: [
            {
                id: "RESET_PASSWORD",
                component: <ResetPasswordPage />,
                guards: [GuestGuard],
                fallback: () => <Redirect to={RouterPath.PORTAL_DASHBOARD} />
            },
            {
                id: "ESTIMATED_FARE",
                component: <EstimatedFarePage />,
                guards: [GuestGuard],
                fallback: () => <Redirect to={RouterPath.PORTAL_DASHBOARD} />
            },
            {
                id: "HOME",
                component: <Home />,
                guards: [GuestGuard],
                fallback: () => <Redirect to={RouterPath.PORTAL_DASHBOARD} />
            },
        ]
    },
];

export default Routes