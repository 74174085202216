import useTrans from 'hooks/use-trans'
import React from 'react'
// import StockManagementContainer from 'shared/container/stock-management/StockManagementContainer'
import InventoryManagementContainer from 'shared/container/stocks/InventoryManagementContainer';

function StockManagementPage() {
    const { t } = useTrans();

    document.title = t("stocks.stocks_management");
    return (
        <InventoryManagementContainer />
    )
}

export default StockManagementPage