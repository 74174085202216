import useTrans from 'hooks/use-trans';
import React from 'react'
import SenderContainer from 'shared/container/sendor/SenderContainer'

function SenderPage() {
    const { trans } = useTrans();
    document.title=trans("sender.sender_management")
    return (
        <SenderContainer />
    )
}

export default SenderPage