import React, { useCallback, useEffect, useRef, useState } from 'react'
import classes from './HolonInstance.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import Constants from 'utils/Constants';
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import actionHolonTemplate from 'redux/holon-template/action';
import ContentLayout from '../common/content-layout/ContentLayout';
import PropsType from 'prop-types';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import TableAttributes from './table-attributes/TableAttributesV2';
import jsonpath from 'jsonpath';
import SearchConditions from './search-condition/SearchConditions';
import CommonAction from './common-action/CommonAction';
import TableHolonInstance from './table-holon-instance/TableHolonInstance';
import actionCargo from 'redux/cargo/action';
import Button from '../form-input/button/Button';
function HolonInstance(props) {
    const {
        holonTemplateId,
        subHolonTemplateId,
        template_id = "",
        isUseAddFunction = true,
        ActionSection,
        callbackDetailFunction,
        title,
        isShowTitle = true,
        titleCreatePopup = "",
        titleUpdatePopup = "",
        titleDetailPopup = "",
        isCargo = false,
        width,
        isReverse = false,
        isShowCategory = false,
        cname,
        cid,
        typeCategory = "",
        dataPreset,
        isHolonTemplateName = false,
        permissionClone = true,
        permissionUpdate = true,
        permissionDelete = true,
        isShowCheckBox = true,
        requireCKey,
        requireCId,
        listTemplateCreation,
        excludeIds
    } = props;
    const methods = useForm();
    const { holonTemplateMaster, holonTemplateDetail, subHolonTemplateDetail } = useSelector(state => state?.holonInstance);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state?.user);

    const [holonTemplate, setHolonTemplate] = useState([]);
    const [subHolonTemplate, setSubHolonTemplate] = useState([]);

    const [holonTemplateID, setHolonTemplateID] = useState(null)
    const [subHolonTemplateID, setSubHolonTemplateID] = useState(null)

    const [requireConnectionKey, setRequireConnectionKey] = useState(null)
    const [requireConnectionHolonId, setRequireConnectionHolonId] = useState(null)

    const [cID, setCID] = useState(null)
    const [cNAME, setCNAME] = useState(null)
    const [ExcludeIds, setExcludeIds] = useState(null)

    const [headHolonInstance, setHeadHolonInstance] = useState([]);
    const [headHolonInstanceActive, setHeadHolonInstanceActive] = useState([]);
    const [holonTemplateIDActive, setHolonTemplateIDActive] = useState(null);
    const [subHolonTemplateIDActive, setSubHolonTemplateIDActive] = useState(null);

    const [userCompany, setUserCompany] = useState(null);

    const [loadingInstance, setLoadingInstance] = useState(false);
    const [loadingHolonTemplate, setLoadingHolonTemplate] = useState(false);
    const [loadingSubHolonTemplate, setLoadingSubHolonTemplate] = useState(false);

    const [dataSearchWithAttributeTemp, setDataSearchWithAttributeTemp] = useState(null);

    const [listCheckbox, setListCheckbox] = useState([]);
    const [commonFiled, setCommonFiled] = useState([]);
    const [actionCommon, setActionCommon] = useState([]);

    const [warehouse, setWarehouse] = useState(null)

    const router = useRouter();
    const { t } = useTrans();
    const isRefList = useRef(false);
    const refAll = useRef(false);
    const refButtonAttribute = useRef(null);
    const refSubmitData = useRef(false);
    const refLoadData = useRef(true);
    const refLoadHeader = useRef(true);
    const refLoadHolonTemplateId = useRef(true);
    const refLoadSubHolonTemplateId = useRef(true);

    const refUseFirstRender = useRef(true);
    const refUseSecondRender = useRef(false);
    const disabledSubHolonTemplate = useRef(false);

    const isHiddenBtnAdd = useCallback(
        () => {
            if (user?.id && holonTemplateDetail) {
                let holonUser = jsonpath.query(user?.connections, "$[?(@.type=='account')].holons[0]");
                let templateId = holonUser?.[0]?.templateId;
                if (holonTemplateDetail?.validAttributes?.length > 0) {
                    let attributes = holonTemplateDetail?.validAttributes;
                    const someObj = (element) => {
                        return element?.writer?.indexOf(templateId) != -1;
                    };
                    return attributes?.some(someObj);
                } else {
                    return false;
                }
            }
        },
        [holonTemplateDetail, user],
    )

    useEffect(() => {
        if (cid) {
            setCID(cid);
        }
        if (router.get(Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE)) {
            setCID(router.get(Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE));
        }
        if (cid && router.get(Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE)) {
            if (router.get(Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE) != cid) {
                setCID(null);
            } else {
                setCID(cid);
            }
        }
    }, [cid, router.get(Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE)])

    useEffect(() => {
        if (cname) {
            setCNAME(cname);
        }
        if (router.get(Constants.QueryParams.CONNECTION_NAME.VALUE)) {
            setCNAME(router.get(Constants.QueryParams.CONNECTION_NAME.VALUE));
        }
        if (cname && router.get(Constants.QueryParams.CONNECTION_NAME.VALUE)) {
            if (router.get(Constants.QueryParams.CONNECTION_NAME.VALUE) != cname) {
                setCNAME(null);
            } else {
                setCNAME(cname);
            }
        }
    }, [cname, router.get(Constants.QueryParams.CONNECTION_NAME.VALUE)])

    useEffect(() => {
        if (excludeIds) {
            setExcludeIds(excludeIds);
        } else {
            setExcludeIds(null)
        }
    }, [excludeIds])


    useEffect(() => {
        if (requireCKey) {
            setRequireConnectionKey(requireCKey);
        } else {
            setRequireConnectionKey(null)
        }
    }, [requireCKey])

    useEffect(() => {
        if (requireCId) {
            setRequireConnectionHolonId(requireCId);
        } else {
            setRequireConnectionHolonId(null);
        }
    }, [requireCId])

    useEffect(() => {
        if (refAll.current == true) {

        } else {
            isRefList.current = false;
            setHolonTemplateID(null);
            let data = [];
            if (template_id) {
                data = template_id?.split(",");
            }

            if (router.get(Constants.QueryParams.TEMPLATE_ID.VALUE)) {
                data = router.get(Constants.QueryParams.TEMPLATE_ID.VALUE)?.split(",");
            }

            if (router.get(Constants.QueryParams.TEMPLATE_ID.VALUE) &&
                template_id &&
                router.get(Constants.QueryParams.TEMPLATE_ID.VALUE) != template_id) {
                data = []
            }
            if (data?.length > 0) {
                let templateIdRouter = [];
                if (router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE])) {
                    templateIdRouter = router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE])?.split("-");
                }
                let bool = 0;
                templateIdRouter?.findIndex(x => {
                    let index = data?.findIndex(tRouter => tRouter == x);
                    if (index != -1) {
                        bool = bool + 1;
                    }
                });

                if (bool > 0 && bool == templateIdRouter?.length) {
                    setHolonTemplateID(router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]));
                    isRefList.current = true
                } else {
                    setHolonTemplateID(null);
                }

            } else {
                if (router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]) && holonTemplateId) {
                    if (router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]) != holonTemplateId) {
                        setHolonTemplateID(null);
                        return;
                    } else if (router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]) == holonTemplateId) {
                        setHolonTemplateID(holonTemplateId);
                        isRefList.current = true
                        return;
                    }
                } else if (router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE])) {
                    setHolonTemplateID(router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]));
                    isRefList.current = true
                    return;
                }
                else if (holonTemplateId) {
                    setHolonTemplateID(holonTemplateId);
                    setHolonTemplateIDActive(holonTemplateId);
                    setDataSearchWithAttributeTemp(null)
                    isRefList.current = true;
                    refLoadData.current = true;
                    refLoadHolonTemplateId.current = false;
                    return;
                } else {
                    setHolonTemplateID(null);
                    setHolonTemplateIDActive(null);
                }
            }
        }
    }, [
        holonTemplateId,
        router.get([Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]),
        template_id,
        router.get(Constants.QueryParams.TEMPLATE_ID.VALUE),
        refAll.current
    ])

    useEffect(() => {
        if (refUseFirstRender.current && userCompany && holonTemplate?.length > 1) {
            if (template_id && !router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)) {
                refLoadData.current = false;
                disabledSubHolonTemplate.current = true;

                methods.setValue("holonTemplate", [Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE]);

                refUseFirstRender.current = false;
                onChangeSelectTemplate(Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE);

                // refUseSecondRender.current = true;
                // setHolonTemplateIDActive(Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE);
                // dispatch({
                //     type: actionsHolonInstance.LOAD_HOLON_TEMPLATE_ID,
                //     payload: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE
                // })

            }
        }
    }, [
        template_id,
        refUseFirstRender.current,
        router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE),
        headHolonInstance,
        holonTemplate,
        userCompany
    ])

    useEffect(() => {
        if (!refUseFirstRender.current && userCompany && holonTemplate?.length > 1 && refUseSecondRender.current && headHolonInstance?.length > 0) {
            let isCheckNumberHead = headHolonInstance?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.CREATED_DATE.VALUE
                && x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.UPDATED_DATE.VALUE)

            if (isCheckNumberHead?.length > 0) {
                refUseSecondRender.current = false;
                setHeadHolonInstanceActive(headHolonInstance);
                //START : HANDLE GET COMMON FILED
                setLoadingInstance(true);
                let params = {};
                if (cID) {
                    params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = cID;
                }
                if (cNAME) {
                    params[Constants.QueryParams.CONNECTION_NAME.VALUE] = cNAME;
                }
                if (ExcludeIds) {
                    params[Constants.QueryParams.EXCLUDE_IDS.VALUE] = ExcludeIds;
                }

                if (subHolonTemplateIDActive) {
                    params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = subHolonTemplateIDActive;
                }

                if (userCompany) {
                    params[Constants.QueryParams.USER_IN_COMPANY.VALUE] = userCompany;
                }
                if (requireConnectionKey) {
                    params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = requireConnectionKey;
                }
                if (requireConnectionHolonId) {
                    params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = requireConnectionHolonId;
                }

                if (template_id) {
                    params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
                } else if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
                    params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
                } else {
                    params[Constants.QueryParams.IS_SEARCH_ALL_TEMPLATE.VALUE] = 1;
                }
                dispatch({
                    type: actionsHolonInstance.GET_HOLON_INSTANCE,
                    payload: {
                        ...router.getAll(),

                        ...params
                    },
                    callback: () => {
                        setLoadingInstance(false)
                    }
                });
            }

        }
    }, [
        cNAME,
        cID,
        subHolonTemplateIDActive,
        isRefList.current,
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.LIMIT.VALUE),
        router.get(Constants.QueryParams.KEYWORD.VALUE),
        router.get(Constants.QueryParams.CATEGORY.VALUE),
        userCompany,
        template_id,
        refLoadData.current,
        requireConnectionKey,
        requireConnectionHolonId,
        ExcludeIds,
        template_id,
        refUseFirstRender.current,
        router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE),
        refUseSecondRender.current,
        headHolonInstance
    ])


    useEffect(() => {
        if (router.get([Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE]) && subHolonTemplateId) {
            if (router.get([Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE]) != subHolonTemplateId) {
                setSubHolonTemplateID(null);
                return;
            } else if (router.get([Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE]) == subHolonTemplateId) {
                isRefList.current = true
                setSubHolonTemplateID(subHolonTemplateId);
                return;
            }
        } else if (router.get([Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE])) {
            isRefList.current = true
            setSubHolonTemplateID(router.get([Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE]));
            return;
        }
        else if (subHolonTemplateId) {
            isRefList.current = true
            setSubHolonTemplateID(subHolonTemplateId);
            setSubHolonTemplateIDActive(subHolonTemplateId);
            return;
        } else {
            setSubHolonTemplateID(null);
            setSubHolonTemplateIDActive(null);
        }
    }, [
        subHolonTemplateId,
        router.get([Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE])
    ])

    useEffect(() => {
        if (holonTemplateMaster && router.get(Constants.QueryParams.TEMPLATE_ID.VALUE)) {
            let templateList = router.get(Constants.QueryParams.TEMPLATE_ID.VALUE)?.split(",");
            let list = [];
            holonTemplateMaster?.forEach(x => {
                let index = templateList?.findIndex(id => id == x?.id);
                if (index != -1) {
                    list.push({
                        label: x?.name,
                        value: x?.id,
                        key: x?.id,
                    })
                }
            });
            list.unshift({
                label: t("holon.all"),
                value: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE,
                key: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE,
            })
            setHolonTemplate(list)
        }
    }, [holonTemplateMaster, router.get(Constants.QueryParams.TEMPLATE_ID.VALUE)]);

    useEffect(() => {
        if (holonTemplateMaster && template_id) {
            let templateList = template_id?.split(",");
            let list = [];
            holonTemplateMaster?.forEach(x => {
                let index = templateList?.findIndex(id => id == x?.id);
                if (index != -1) {
                    list.push({
                        label: x?.name,
                        value: x?.id,
                        key: x?.id,
                    });
                }
            });
            list.unshift({
                label: t("holon.all"),
                value: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE,
                key: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE,
            })
            setHolonTemplate(list)
        }
    }, [holonTemplateMaster, template_id]);

    useEffect(() => {
        if (!holonTemplateMaster) {
            setHolonTemplate([]);
        }
    }, [holonTemplateMaster])


    useEffect(() => {
        if (holonTemplateMaster && !template_id && !router.get(Constants.QueryParams.TEMPLATE_ID.VALUE)) {
            let list = holonTemplateMaster?.map(x => ({
                label: x?.name,
                value: x?.id,
                key: x?.id,
            }));

            list.unshift({
                label: t("holon.all"),
                value: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE,
                key: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE,
            })

            setHolonTemplate(list)
        }
    }, [router.get(Constants.QueryParams.TEMPLATE_ID.VALUE), holonTemplateMaster, template_id])


    useEffect(() => {
        dispatch({
            type: actionsHolonInstance.GET_USER_IN_COMPANY,
            callback: {
                success: (data) => {
                    setUserCompany(data?.join(","))
                }
            }
        })
        dispatch({
            type: actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT,
        });
        dispatch({
            type: actionCargo.GET_WARE_HOUSE_DEFAULT,
            callback: {
                success: (data) => {
                    if (data?.id) {
                        setWarehouse(data)
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        let params = {};
        if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
            params[Constants.QueryParams.CATEGORY.VALUE] = router.get(Constants.QueryParams.CATEGORY.VALUE);
        }
        dispatch({
            type: actionsHolonInstance.HOLON_TEMPLATE_MASTER,
            payload: params
        });
    }, [
        router.get(Constants.QueryParams.CATEGORY.VALUE)
    ])


    useEffect(() => {
        if (holonTemplateDetail?.id) {
            let data = [];
            holonTemplateDetail?.connections?.map(connection => {
                connection?.holonTemplate?.map(holon => {
                    let index = data?.findIndex(x => x == holon);
                    if (index == -1) {
                        data.push(holon);
                    }
                })
            })

            dispatch({
                type: actionsHolonInstance.GET_HOLON_TEMPLATE_WITH_IDS,
                payload: data?.join(","),
                callback: (dataHolon) => {
                    setSubHolonTemplate(dataHolon?.map(x => {
                        return {
                            label: x?.name,
                            key: x?.id,
                            value: x?.id,
                        }
                    }));
                }
            })

        } else {
            setHeadHolonInstance([]);
            setSubHolonTemplate([])
        }
    }, [holonTemplateDetail])

    useEffect(() => {
        let dataCreateBy = [
            {
                defaultValue: "",
                deletedFlag: "0",
                elementType: Constants.ELEMENT_TYPE.DATETIME.VALUE,
                key: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.CREATED_DATE.VALUE,
                label: t("Holon_Template.created"),
                templateId: holonTemplateDetail?.id,
                id: -1
            },
            {
                defaultValue: "",
                deletedFlag: "0",
                elementType: Constants.ELEMENT_TYPE.DATETIME.VALUE,
                key: Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.UPDATED_DATE.VALUE,
                label: t("Holon_Template.updateDayMonth"),
                templateId: holonTemplateDetail?.id,
                id: -2
            }
        ];

        if (holonTemplateDetail?.id) {
            if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                let data = [];
                commonFiled?.map(x => {
                    let index = holonTemplateDetail?.validAttributes?.findIndex(at => at?.key == x);
                    if (index != -1) {
                        data.push({
                            ...holonTemplateDetail?.validAttributes[index],
                            isHidden: false
                        })
                    }
                });
                data = [...data, ...dataCreateBy]
                setHeadHolonInstance(data)
            } else
                if (holonTemplateID?.split("-")?.length > 1 && holonTemplateDetail?.id) {
                    let data = [];
                    commonFiled?.map(x => {
                        let index = holonTemplateDetail?.validAttributes?.findIndex(at => at?.key == x);
                        if (index != -1) {
                            data.push({
                                ...holonTemplateDetail?.validAttributes[index],
                                isHidden: false
                            })
                        }
                    });
                    data = [...data, ...dataCreateBy]
                    setHeadHolonInstance(data);

                    if (holonTemplateId) {
                        setHeadHolonInstanceActive(data);
                    }
                } else
                    if (subHolonTemplateDetail?.id && holonTemplateDetail?.id) {
                        if (isReverse) {
                            let data = [...subHolonTemplateDetail?.validAttributes?.map(x => {
                                return {
                                    ...x,
                                    templateId: subHolonTemplateDetail?.id
                                }
                            }), ...holonTemplateDetail?.validAttributes?.map(x => {
                                return {
                                    ...x,
                                    templateId: holonTemplateDetail?.id
                                }
                            })]?.map(x => {
                                return {
                                    ...x,
                                    isHidden: false
                                }
                            });
                            data = [...data, ...dataCreateBy]
                            setHeadHolonInstance(data);
                            if (holonTemplateId) {
                                setHeadHolonInstanceActive(data);
                            }
                        } else {
                            let data = [...holonTemplateDetail?.validAttributes?.map(x => {
                                return {
                                    ...x,
                                    templateId: holonTemplateDetail?.id
                                }
                            }), ...subHolonTemplateDetail?.validAttributes?.map(x => {
                                return {
                                    ...x,
                                    templateId: subHolonTemplateDetail?.id
                                }
                            })]?.map(x => {
                                return {
                                    ...x,
                                    isHidden: false
                                }
                            });
                            data = [...data, ...dataCreateBy]
                            setHeadHolonInstance(data);
                            if (holonTemplateId) {
                                setHeadHolonInstanceActive(data);
                            }
                        }
                    } else {
                        if (holonTemplateDetail?.id) {
                            let data = [...holonTemplateDetail?.validAttributes]?.map(x => {
                                return {
                                    ...x,
                                    isHidden: false,
                                    templateId: holonTemplateDetail?.id
                                }
                            })
                            data = [...data, ...dataCreateBy]
                            setHeadHolonInstance(data);
                            if (holonTemplateId) {
                                setHeadHolonInstanceActive(data);
                            }
                        } else {
                            setHeadHolonInstance([]);
                            if (holonTemplateId) {
                                setHeadHolonInstanceActive([]);
                            }
                        }
                    }
        }

    }, [
        subHolonTemplateDetail,
        holonTemplateDetail,
        isReverse,
        commonFiled,
        holonTemplateID,
        holonTemplateId
    ])

    const onSubmit = (_data) => {
        let params = { ...router.getAll() };
        if (_data?.keyword) {
            params[Constants.QueryParams.KEYWORD.VALUE] = _data?.keyword;
        } else {
            delete params[Constants.QueryParams.KEYWORD.VALUE];
        }

        params[Constants.QueryParams.OFFSET.VALUE] = 1;

        if (subHolonTemplateID) {
            if (!subHolonTemplateId) {
                params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = subHolonTemplateID;
            }
        } else {
            delete params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE];
        }

        if (holonTemplateDetail?.id) {
            if (!holonTemplateId) {
                params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = holonTemplateID;
            }

            if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                delete params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE];
                delete params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE];
            }
        } else {
            delete params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE];
            delete params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE];
            setCommonFiled([])
            setActionCommon([])
        }
        if (holonTemplateID) {
            setHeadHolonInstanceActive(headHolonInstance);
        } else {
            setHeadHolonInstanceActive([]);
        }

        delete params[Constants.QueryParams.ATTRIBUTES.VALUE];
        delete params[Constants.QueryParams.ATTRIBUTES_SUB.VALUE];

        refSubmitData.current = true;
        refLoadData.current = true;
        refLoadHeader.current = true;
        isRefList.current = true;

        setHolonTemplateID(holonTemplateID);
        setHolonTemplateIDActive(holonTemplateID);
        setSubHolonTemplateIDActive(subHolonTemplateID);

        dispatch({
            type: actionsHolonInstance.LOAD_HOLON_TEMPLATE_ID,
            payload: holonTemplateID
        })

        router.replace({
            params: {
                ...params,
                ...dataSearchWithAttributeTemp
            }
        })
    }

    const onChangeListTemplate = (data) => {
        setHolonTemplate(data)
    }

    const onClearDataSubHolon = () => {
        setSubHolonTemplate([])
    }

    const onChangeSelectTemplate = (e) => {
        setHolonTemplateID(e);
        setDataSearchWithAttributeTemp(null)
        if (e == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
            refAll.current = true;
        }
        if (!e) {
            setHeadHolonInstance([])
        }
        refLoadHeader.current = false;
        refLoadData.current = false;
        refLoadHolonTemplateId.current = false;
        refLoadSubHolonTemplateId.current = false;

        // TRIGGER HANDLE DISABLED SUB TEMPLATE
        disabledSubHolonTemplate.current = false;
    }

    const onChangeSelectSubTemplate = (e) => {
        setSubHolonTemplateID(e);
    }

    const onResetDataHolonInstance = () => {
        setActionCommon([]);
        setCommonFiled([]);
        setHeadHolonInstance([]);
        setHeadHolonInstanceActive([]);
        setHolonTemplateID(null);
        setHolonTemplateIDActive(null);
        setSubHolonTemplateID(null);
        setSubHolonTemplateIDActive(null);
        dispatch({
            type: actionsHolonInstance.RESET_HOLON_INSTANCE
        })
    }

    useEffect(() => {
        if (subHolonTemplateID) {
            setLoadingSubHolonTemplate(true)
            dispatch({
                type: actionsHolonInstance.GET_SUB_HOLON_TEMPLATE,
                payload: subHolonTemplateID,
                callback: () => {
                    setLoadingSubHolonTemplate(false);
                }
            })
        } else {
            dispatch({
                type: actionsHolonInstance.GET_SUB_HOLON_TEMPLATE_SUCCESS,
                payload: {
                    attributes: [],
                    validAttributes: []
                }
            })
        }
    }, [
        subHolonTemplateID
    ])

    useEffect(() => {
        if (!holonTemplateID) {
            dispatch({
                type: actionsHolonInstance.RESET_HOLON_TEMPLATE_DETAIL,
            });
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_SUCCESS,
                payload: {
                    attributes: []
                }
            });
        }

        if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE && userCompany != null) {
            setLoadingHolonTemplate(true);

            let paramCommonFiled = null;

            paramCommonFiled = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",");
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL,
                payload: holonTemplate[1]?.key,
                callback: () => {
                    setLoadingHolonTemplate(false)
                }
            })

            if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
                paramCommonFiled = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
            }

            dispatch({
                type: actionsHolonInstance.GET_COMMON_FILED,
                payload: paramCommonFiled,
                callback: {
                    success: (data) => {
                        setCommonFiled(data)
                    }
                }
            })
        } else if (holonTemplateID && userCompany != null && holonTemplateID != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
            setLoadingHolonTemplate(true)
            if (holonTemplateID?.split("-")?.length > 1) {
                dispatch({
                    type: actionsHolonInstance.GET_COMMON_FILED,
                    payload: holonTemplateID?.replace(/-/g, ','),
                    callback: {
                        success: (data) => {
                            setCommonFiled(data)
                        }
                    }
                })
            } else {
                setCommonFiled([]);
            }
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL,
                payload: holonTemplateID?.split("-")[0],
                callback: () => {
                    setLoadingHolonTemplate(false)
                }
            })
        }
    }, [
        holonTemplateID,
        router.get(Constants.QueryParams.CATEGORY.VALUE),
        template_id,
        userCompany
    ])

    useEffect(() => {
        if (!holonTemplateIDActive) {
            dispatch({
                type: actionsHolonInstance.RESET_HOLON_TEMPLATE_DETAIL,
            });
            dispatch({
                type: actionsHolonInstance.RESET_HOLON_INSTANCE,
            });
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_SUCCESS,
                payload: {
                    attributes: []
                }
            });

            setHeadHolonInstanceActive([]);
        }

        if (holonTemplateIDActive == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
            let paramCommonFiled = null;
            paramCommonFiled = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")

            dispatch({
                type: actionsHolonInstance.GET_ACTION_COMMON,
                payload: paramCommonFiled,
                callback: {
                    success: (data) => {
                        setActionCommon(data)
                    }
                }
            })
        } else if (holonTemplateIDActive) {
            dispatch({
                type: actionsHolonInstance.GET_ACTION_COMMON,
                payload: holonTemplateIDActive?.replace(/-/g, ','),
                callback: {
                    success: (data) => {
                        setActionCommon(data)
                    }
                }
            })
        }
    }, [
        holonTemplateIDActive,
        template_id,
        holonTemplate
    ])

    useEffect(() => {
        if (refLoadHeader?.current == true && headHolonInstance?.length > 0) {
            setHeadHolonInstanceActive(headHolonInstance);
            refLoadHeader.current = false;
        }
    }, [
        refLoadHeader?.current,
        headHolonInstance
    ])

    useEffect(() => {
        if (refLoadHolonTemplateId?.current == true && holonTemplateID) {
            setHolonTemplateIDActive(holonTemplateID);
            refLoadHolonTemplateId.current = false;
        }
    }, [
        refLoadHolonTemplateId?.current,
        holonTemplateID
    ])

    useEffect(() => {
        if (refLoadSubHolonTemplateId?.current == true && subHolonTemplateID) {
            setSubHolonTemplateIDActive(subHolonTemplateID);
            refLoadSubHolonTemplateId.current = false;
        }
    }, [
        refLoadSubHolonTemplateId?.current,
        subHolonTemplateID
    ])

    // RELOAD DATA TO TABLE
    useEffect(() => {
        if (refLoadData?.current == true) {
            if (holonTemplateIDActive == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE && userCompany != null) {
                setLoadingInstance(true);
                let params = {};
                if (cID) {
                    params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = cID;
                }
                if (cNAME) {
                    params[Constants.QueryParams.CONNECTION_NAME.VALUE] = cNAME;
                }
                if (ExcludeIds) {
                    params[Constants.QueryParams.EXCLUDE_IDS.VALUE] = ExcludeIds;
                }

                if (subHolonTemplateIDActive) {
                    params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = subHolonTemplateIDActive;
                }

                if (userCompany) {
                    params[Constants.QueryParams.USER_IN_COMPANY.VALUE] = userCompany;
                }
                if (requireConnectionKey) {
                    params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = requireConnectionKey;
                }
                if (requireConnectionHolonId) {
                    params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = requireConnectionHolonId;
                }

                if (template_id) {
                    params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
                } else if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
                    params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
                } else {
                    params[Constants.QueryParams.IS_SEARCH_ALL_TEMPLATE.VALUE] = 1;
                }
                dispatch({
                    type: actionsHolonInstance.GET_HOLON_INSTANCE,
                    payload: {
                        ...router.getAll(),

                        ...params
                    },
                    callback: () => {
                        setLoadingInstance(false)
                    }
                });
                refLoadData.current = false;
            } else
                if (holonTemplateIDActive && isRefList.current == true && userCompany != null) {

                    setLoadingInstance(true);
                    let params = {};
                    if (cID) {
                        params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = cID;
                    }
                    if (cNAME) {
                        params[Constants.QueryParams.CONNECTION_NAME.VALUE] = cNAME;
                    }
                    if (ExcludeIds) {
                        params[Constants.QueryParams.EXCLUDE_IDS.VALUE] = ExcludeIds;
                    }

                    if (subHolonTemplateIDActive) {
                        params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = subHolonTemplateIDActive;
                    }

                    if (userCompany) {
                        params[Constants.QueryParams.USER_IN_COMPANY.VALUE] = userCompany;
                    }

                    if (requireConnectionKey) {
                        params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = requireConnectionKey;
                    }
                    if (requireConnectionHolonId) {
                        params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = requireConnectionHolonId;
                    }

                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE,
                        payload: {
                            ...router.getAll(),
                            [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonTemplateIDActive?.replace(/-/g, ','),
                            ...params
                        },
                        callback: () => {
                            setLoadingInstance(false)
                        }
                    });
                    refLoadData.current = false;
                }
        }
    }, [
        holonTemplateIDActive,
        cNAME,
        cID,
        subHolonTemplateIDActive,
        isRefList.current,
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.LIMIT.VALUE),
        router.get(Constants.QueryParams.KEYWORD.VALUE),
        router.get(Constants.QueryParams.CATEGORY.VALUE),
        userCompany,
        template_id,
        refLoadData.current,
        requireConnectionKey,
        requireConnectionHolonId,
        ExcludeIds
    ]);

    // Handle reload data from url to form
    useEffect(() => {
        if (subHolonTemplateID) {
            methods.setValue("subHolonTemplate", subHolonTemplateID)
        } else {
            methods.setValue("subHolonTemplate", null);
        }
        if (holonTemplateID) {
            if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                methods.setValue("holonTemplate", [Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE])
            } else {
                methods.setValue("holonTemplate", holonTemplateID?.split("-"))
            }
        } else {
            methods.setValue("holonTemplate", null);
        }
        if (router.get(Constants.QueryParams.KEYWORD.VALUE)) {
            methods.setValue("keyword", router.get(Constants.QueryParams.KEYWORD.VALUE))
        } else {
            methods.setValue("keyword", "")
        }
        if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
            methods.setValue("category", router.get(Constants.QueryParams.CATEGORY.VALUE))
        } else {
            methods.setValue("category", "")
        }
    }, [
        subHolonTemplateID,
        holonTemplateID,
        router.get(Constants.QueryParams.KEYWORD.VALUE)
    ])

    // Clear data 

    const onClearData = () => {
        setHolonTemplate([])
        setSubHolonTemplate([]);
        setHeadHolonInstance([])
        dispatch({
            type: actionsHolonInstance.RESET_HOLON_TEMPLATE_DETAIL
        });
        dispatch({
            type: actionsHolonInstance.GET_HOLON_INSTANCE_SUCCESS,
            payload: {
                total: 0,
                limit: 10,
                totalPage: 0,
                page: 1,
                records: []
            }
        });

        dispatch({
            type: actionsHolonInstance.LOAD_HOLON_TEMPLATE_ID,
            payload: null
        })
    }
    useEffect(() => {
        return () => {
            onClearData()
        }
    }, [])


    useEffect(() => {
        if (refSubmitData.current == false) {
            let params = {};
            if (router.get(Constants.QueryParams.ATTRIBUTES.VALUE)) {
                params[Constants.QueryParams.ATTRIBUTES.VALUE] = router.get(Constants.QueryParams.ATTRIBUTES.VALUE);
            }
            if (router.get(Constants.QueryParams.ATTRIBUTES_SUB.VALUE)) {
                params[Constants.QueryParams.ATTRIBUTES_SUB.VALUE] = router.get(Constants.QueryParams.ATTRIBUTES_SUB.VALUE);
            }

            setDataSearchWithAttributeTemp(params)
        }
    }, [
        router.get(Constants.QueryParams.ATTRIBUTES.VALUE),
        router.get(Constants.QueryParams.ATTRIBUTES_SUB.VALUE),
        refSubmitData.current
    ])

    const onChangePage = () => {
        refLoadData.current = true;
    }
    return (
        <ContentLayout width={width}>
            {isShowTitle && <Breadcrumb>
                {title ? title : t("holon.holon_instance")}
            </Breadcrumb>}
            <div className={classes['HolonInstanceContainer']}>
                <CustomFormProvider {...methods} >
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <SearchConditions
                            holonTemplateId={holonTemplateId}
                            holonTemplate={holonTemplate}
                            subHolonTemplate={subHolonTemplate}
                            onChangeListTemplate={onChangeListTemplate}
                            holonTemplateMaster={holonTemplateMaster}
                            holonTemplateDetail={holonTemplateDetail}
                            subHolonTemplateDetail={subHolonTemplateDetail}
                            holonTemplateID={holonTemplateID}
                            template_id={template_id}
                            onClearDataSubHolon={onClearDataSubHolon}
                            onChangeSelectTemplate={onChangeSelectTemplate}
                            commonFiled={commonFiled}
                            setListCheckbox={setListCheckbox}
                            dataSearchWithAttributeTemp={dataSearchWithAttributeTemp}
                            setDataSearchWithAttributeTemp={setDataSearchWithAttributeTemp}
                            refSubmitData={refSubmitData}
                            onChangeSelectSubTemplate={onChangeSelectSubTemplate}
                            onResetDataHolonInstance={onResetDataHolonInstance}
                            requireConnectionKey={requireConnectionKey}
                            requireConnectionHolonId={requireConnectionHolonId}
                            disabledSubHolonTemplate={disabledSubHolonTemplate.current}
                        />
                        <TableAttributes
                            onChangeHead={(e) => setHeadHolonInstance(e)}
                            headHolonInstance={headHolonInstance}
                            refButtonAttribute={refButtonAttribute}
                        />
                        <div className={classes['action-button']}>
                            <Button type='submit' className="button" onClick={() => {
                                if (refButtonAttribute && refButtonAttribute.current) {
                                    refButtonAttribute.current.click()
                                }
                            }} color='yellow' title={t("Holon_Template.search")} />
                        </div>
                        <CommonAction
                            isUseAddFunction={isUseAddFunction}
                            holonTemplateID={holonTemplateIDActive}
                            isHiddenBtnAdd={isHiddenBtnAdd}
                            ActionSection={ActionSection}
                            titleCreatePopup={titleCreatePopup}
                            subHolonTemplateID={subHolonTemplateIDActive}
                            cNAME={cNAME}
                            cID={cID}
                            ExcludeIds={ExcludeIds}
                            userCompany={userCompany}
                            isCargo={isCargo}
                            isShowCategory={isShowCategory}
                            typeCategory={typeCategory}
                            dataPreset={dataPreset}
                            isHolonTemplateName={isHolonTemplateName}
                            actionCommon={actionCommon}
                            holonTemplateDetail={holonTemplateDetail}
                            templateDetail={holonTemplateDetail}
                            headHolonInstance={headHolonInstance}
                            template_id={template_id}
                            holonTemplate={holonTemplate}
                            listCheckbox={listCheckbox}
                            warehouse={warehouse}
                            requireConnectionKey={requireConnectionKey}
                            requireConnectionHolonId={requireConnectionHolonId}
                            listTemplateCreation={listTemplateCreation}
                            holonTemplateIDActive={holonTemplateIDActive}
                        />
                        <button hidden type='submit' />
                    </form>
                </CustomFormProvider>
                <TableHolonInstance
                    isShowCheckBox={isShowCheckBox}
                    listCheckbox={listCheckbox}
                    headHolonInstance={headHolonInstanceActive}
                    loadingInstance={loadingInstance}
                    loadingHolonTemplate={loadingHolonTemplate}
                    loadingSubHolonTemplate={loadingSubHolonTemplate}
                    callbackDetailFunction={callbackDetailFunction}
                    setListCheckbox={setListCheckbox}
                    titleDetailPopup={titleDetailPopup}
                    titleUpdatePopup={titleUpdatePopup}
                    subHolonTemplateID={subHolonTemplateIDActive}
                    titleCreatePopup={titleCreatePopup}
                    isCargo={isCargo}
                    isHolonTemplateName={isHolonTemplateName}
                    permissionClone={permissionClone}
                    isHiddenBtnAdd={isHiddenBtnAdd}
                    permissionUpdate={permissionUpdate}
                    cID={cID}
                    cNAME={cNAME}
                    ExcludeIds={ExcludeIds}
                    requireConnectionKey={requireConnectionKey}
                    requireConnectionHolonId={requireConnectionHolonId}
                    userCompany={userCompany}
                    holonTemplateID={holonTemplateIDActive}
                    commonFiled={commonFiled}
                    refLoadData={refLoadData}
                    onChangePage={onChangePage}
                    permissionDelete={permissionDelete}
                    listTemplateCreation={listTemplateCreation}
                />
            </div>
        </ContentLayout>
    )
}

HolonInstance.propTypes = {
    holonTemplateId: PropsType.any,
    subHolonTemplateId: PropsType.any,
    template_id: PropsType.any,
    isUseAddFunction: PropsType.any,
    ActionSection: PropsType.any,
    callbackDetailFunction: PropsType.any,
    titleCreatePopup: PropsType.any,
    titleUpdatePopup: PropsType.any,
    titleDetailPopup: PropsType.any,
    width: PropsType.string,
    isReverse: PropsType.bool,
    isCargo: PropsType.bool,
    isShowCategory: PropsType.bool,
    isHolonTemplateName: PropsType.bool,
    view: PropsType.string,
    cname: PropsType.string,
    cid: PropsType.string,
    typeCategory: PropsType.string,
    dataPreset: PropsType.any,
    permissionClone: PropsType.bool,
    permissionUpdate: PropsType.bool,
    isShowCheckBox: PropsType.bool,
    listTemplateCreation: PropsType.array,
}
export default HolonInstance