import React from "react";
import SystemSettingContainer from 'shared/container/system-setting/SystemSettingContainer';
import useTrans from 'hooks/use-trans';

function SystemSetting(){
    const { t } = useTrans();
    document.title = t("holon_system_setting.holon_master_definition");
    return (
        <div>
            <SystemSettingContainer />
        </div>
    );
}


export default SystemSetting;