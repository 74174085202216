import React, { useEffect, useState } from "react";
import useTrans from "hooks/use-trans";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import Box from "shared/components/form-input/box/Box";
import classes from "./EstimatedFareContainer.module.scss";
import { useForm } from "react-hook-form";
import _ from 'lodash';
import Cookies from 'js-cookie';
import useRouter from 'hooks/use-router';
import actionFareCalculation from 'redux/freight-calculation/action';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import Constants from "utils/Constants";
import IconMultiply from "assets/icon/IconMultiply";
import IconCircular from "assets/icon/IconCircular";
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POST_DETAIL,
    EVENT_SHOW_POPUP_USER_INQUIRY,
    EVENT_SHOW_CREATE_INQUIRY_POPUP
} from "utils/EventRegister";
import { isRouteRoleUser } from 'utils/Utils';


const FreightCalculationResult = () => {
    const { t } = useTrans()
    const methods = useForm();
    const {
        fareCalcManagementCalculate,
    } = useSelector((state) => state.fareCalculation);
    const dispatch = useDispatch();
    const token = Cookies.get("accessToken");
    const router = useRouter()
    const [bodyTable, setBodyTable] = useState()
    const [headerTable, setHeaderTable] = useState();
    const [renderBody, setRenderBody] = useState();
    const [isShowInquiryPage, setShowInquiryPage] = useState(true);
    const bottomEl = useRef(null);

    const scrollToTable = () => {
        bottomEl?.current?.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest", top: document.body.scrollHeigh });
    };

    useEffect(() => {
        if (fareCalcManagementCalculate?.length !== 0) {
            setTimeout(() => {
                scrollToTable()
            }, 500)
        }
    }, [fareCalcManagementCalculate])

    const showDetail = (id) => {
        dispatch({
            type: actionFareCalculation.GET_DETAIL_FARE_CAL_SERVICE_CONTENT,
            payload: id
        })
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POST_DETAIL,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                callback: () => { },
            },
        });
    }

    useEffect(() => {
        if(token){
            setShowInquiryPage(isRouteRoleUser('/portal/inquiry'))
        }
    }, [isShowInquiryPage])

    const handleRegister = () => {
        if (token) {
            router.push({
                pathname: "/portal/inquiry"
            })
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_CREATE_INQUIRY_POPUP,
                payload: {
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0,
                    },
                    callback: () => { },
                },
            });
        } else {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                type: EVENT_SHOW_POPUP_USER_INQUIRY,
                open: true,
                payload: {
                    title: t("Inquiry.inquiry_user"),
                    showHeader: true,
                    customStyle: {
                        maxHeight: 'calc(100vh - 150px)',
                    },
                    callback: () => { },
                }
            })
        }
    }

    const handleBodyTable = () => {
        const headerLogic = [{ name: `${t('home.service')}` }]
        fareCalcManagementCalculate?.data?.head.forEach((obj) => {
            headerLogic.push(obj)
        });
        setHeaderTable(headerLogic)
        const bodyLogic = []
        Object.values(fareCalcManagementCalculate?.data?.body).forEach((serviceName) => {
            bodyLogic.push({
                services: serviceName?.serviceName
            });
        })
        bodyLogic.forEach((rows, index) => {
            const rowData = fareCalcManagementCalculate?.data?.body[index];
            headerLogic.forEach((obj) => {
                const objectData = _.find(rowData?.result, { key: obj?.key });
                if (rowData?.isCalculationObject == Constants.CROSS_BORDER_COURIER.YES.VALUE) {
                    if (objectData?.type == Constants.TYPE_CHARACTER.CHARACTER.VALUE) {
                        rows[obj.key] = objectData?.value == Constants.TYPE_CHARACTER.YES.VALUE ? Constants.TYPE_CHARACTER.YES.TYPE : Constants.TYPE_CHARACTER.NO.TYPE;
                    } else {
                        rows[obj.key] = objectData?.value ?? Constants.TYPE_CHARACTER.EMPTY.TYPE;
                    }
                }
                else {
                    rows[obj.key] = Constants.TYPE_CHARACTER.EMPTY.TYPE;
                }
            });
        });
        for (let i = 0; i < bodyLogic?.length; i++) {
            delete bodyLogic[i]?.undefined;
        }
        const serviceIdMap = {};
        for (const item of fareCalcManagementCalculate?.data?.body) {
            serviceIdMap[item.serviceName] = item.serviceId;
        }

        for (const item of bodyLogic) {
            if (serviceIdMap[item.services] !== undefined) {
                item.serviceId = serviceIdMap[item.services];
            }
        }
        setBodyTable(bodyLogic)
        handleRenderBody(bodyLogic)
    }

    const handleRenderBody = (data) => {
        if (data?.length > 0) {
            const idBody = Object.keys(data[0]);
            const renderBody = idBody.filter(key => key !== 'serviceId');
            setRenderBody(renderBody)
        }
    }

    useEffect(() => { 
        if (fareCalcManagementCalculate?.length !== 0) {
            handleBodyTable()
        }
    }, [fareCalcManagementCalculate])

    return (
        <Box className={classes["estimated-fare-container"]}>
            {
                fareCalcManagementCalculate?.length !== 0 && <div>
                    <CustomFormProvider {...methods}>
                        <div className={classes["HeaderSection"]}>
                            <p className={classes['title-section']}>
                                {t("fare_calculation.freight_calculation_result")}
                            </p>
                            <div className={classes['icon-note']}>
                            </div>
                        </div>
                        <div className={classes['reference-information']}>
                            <p>
                                {t('fare_calculation.title_information_price_simulation')}
                            </p>
                            <div className={classes['reference-description']}>
                                <p>
                                    {t('fare_calculation.warning_description')}
                                </p>
                                <p>
                                    {t('fare_calculation.warning_actual_weight')}
                                </p>
                            </div>
                        </div>
                        <div className={classes["table-result"]}>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            headerTable && headerTable?.map((x, index) => {
                                                return (
                                                    <th style={{padding: '10px'}} key={index}>{x?.name}</th>
                                                )
                                            })
                                        }
                                        <th>{t("fare_calculation.detail")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bodyTable && bodyTable?.map((x, index) => {
                                            return (
                                                <tr key={index}>
                                                    {
                                                        renderBody.map((field, indexTd) => {
                                                            return (
                                                                <td key={indexTd}>
                                                                    {
                                                                        [Constants.TYPE_CHARACTER.YES.TYPE, Constants.TYPE_CHARACTER.NO.TYPE].includes(x[field])
                                                                            ? (x[field] == Constants.TYPE_CHARACTER.YES.TYPE ? <IconMultiply/> : <IconCircular/>)
                                                                            : x[field]?.toLocaleString()
                                                                    }
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    <td>
                                                        <button className={classes['button-detail']} onClick={() => showDetail(x?.serviceId)
                                                        }>
                                                            {t('fare_calculation.in_detail')}
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className={classes['reference-information']}>
                            <p>
                                {t('fare_calculation.reference_delivery_time')}
                            </p>
                            <p>
                                {t('fare_calculation.delivery_days_may_change')}
                            </p>
                        </div>
                        {
                            isShowInquiryPage && <div className={classes['reference-contact']}>
                                <p onClick={() => {
                                    handleRegister()
                                }} ref={bottomEl} >
                                    {t('fare_calculation.please_contact_us_for_pickup_areas_in_japan')}
                                </p>
                            </div>
                        }
                    </CustomFormProvider>
                </div>
            }
        </Box>
    );
};

export default FreightCalculationResult;
