import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import FaqAction from "./action";
import { factory } from "./factory";
import Constants from "utils/Constants";

function* getFaqCategory() {
    yield takeEvery(FaqAction.GET_FAQ_CATEGORIES, function* (payload) {
        try {
            const response = yield call(() => factory.getFaqCategory(payload?.payload));
            if (response) {
                yield put({
                    type: FaqAction.GET_FAQ_CATEGORIES_SUCCESS,
                    payload: response
                })
            } else {
                yield put({
                    type: FaqAction.GET_FAQ_CATEGORIES_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            yield put({
                type: FaqAction.GET_FAQ_CATEGORIES_FAILED,
            })
        }
    })
}

function* getFaqCategoryPopup() {
    yield takeEvery(FaqAction.GET_FAQ_CATEGORIES_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getFaqCategory(payload?.payload));
            if (response) {
                yield put({
                    type: FaqAction.GET_FAQ_CATEGORIES_POPUP_SUCCESS,
                    payload: response
                })
            } else {
                yield put({
                    type: FaqAction.GET_FAQ_CATEGORIES_POPUP_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            yield put({
                type: FaqAction.GET_FAQ_CATEGORIES_POPUP_FAILED,
            })
        }
    })
}
function* getDetailFaqCategory() {
    yield takeEvery(FaqAction.GET_DETAIL_FAQ_CATEGORIES, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailFaqCategory(payload?.payload));
            if (response?.data) {
                yield put({
                    type: FaqAction.GET_DETAIL_FAQ_CATEGORIES_SUCCESS,
                    payload: response?.data
                })
            } else {
                yield put({
                    type: FaqAction.GET_DETAIL_FAQ_CATEGORIES_FAILED,
                    payload: {}
                })
            }
        } catch (error) {
            yield put({
                type: FaqAction.GET_DETAIL_FAQ_CATEGORIES_FAILED,
                payload: {}
            })
        }
    })
}

function* deleteFaqCategory() {
    yield takeEvery(FaqAction.DELETE_FAQ_CATEGORY, function* (payload) {
        try {
            const response = yield call(() => factory.deleteFaqCategory(payload?.payload));
            if (response) {
                yield put({
                    type: FaqAction.DELETE_FAQ_CATEGORY_SUCCESS,
                    payload: response
                })
                yield put({
                    type: FaqAction.GET_FAQ_CATEGORIES_POPUP,
                    payload: {
                        [Constants.QueryParams.LANGUAGE.VALUE]: payload?.payload?.lang
                    }
                })
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed();
                yield put({
                    type: FaqAction.DELETE_FAQ_CATEGORY_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
            yield put({
                type: FaqAction.DELETE_FAQ_CATEGORY_FAILED,
            })
        }
    })
}

function* updateFaqCategory() {
    yield takeEvery(FaqAction.UPDATE_FAQ_CATEGORY, function* (payload) {
        try {
            const response = yield call(() => factory.updateFaqCategory(payload?.payload));
            if (response?.id) {
                yield put({
                    type: FaqAction.UPDATE_FAQ_CATEGORY_SUCCESS,
                    payload: response
                });
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield put({
                    type: FaqAction.UPDATE_FAQ_CATEGORY_FAILED,
                    payload: response
                })
                yield payload?.callback?.failed && payload?.callback?.failed();
            }
        } catch (error) {
            yield put({
                type: FaqAction.UPDATE_FAQ_CATEGORY_FAILED,
            })
        }
    })
}
function* addFaqCategory() {
    yield takeEvery(FaqAction.ADD_FAQ_CATEGORY, function* (payload) {
        try {
            const response = yield call(() => factory.addFaqCategory(payload?.payload));
            if (response?.id) {
                yield put({
                    type: FaqAction.ADD_FAQ_CATEGORY_SUCCESS,
                    payload: response
                });
                yield put({
                    type: FaqAction.GET_FAQ_CATEGORIES_POPUP,
                    payload: {
                        [Constants.QueryParams.LANGUAGE.VALUE]: payload?.payload?.lang
                    }
                })
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield put({
                    type: FaqAction.ADD_FAQ_CATEGORY_FAILED,
                    payload: response
                })
                yield payload?.callback?.failed && payload?.callback?.failed();
            }
        } catch (error) {
            yield put({
                type: FaqAction.ADD_FAQ_CATEGORY_FAILED,
            })
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0]);
        }
    })
}

// FaqByCategory : TODO

function* getFaqByCategory() {
    yield takeLatest(FaqAction.GET_FAQ_WITH_CATEGORY, function* (payload) {
        try {
            const response = yield call(() => factory.getFaqByCategory(payload?.payload));
            if (response) {
                yield put({
                    type: FaqAction.GET_FAQ_WITH_CATEGORY_SUCCESS,
                    payload: response
                })
            } else {
                yield put({
                    type: FaqAction.GET_FAQ_WITH_CATEGORY_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            yield put({
                type: FaqAction.GET_FAQ_WITH_CATEGORY_FAILED,
            })
        }
    })
}

function* addFaqWithCategory() {
    yield takeEvery(FaqAction.CREATE_FAQ_WITH_CATEGORY, function* (payload) {
        try {
            const response = yield call(() => factory.addFaqWithCategory(payload?.payload));
            if (response?.id) {
                yield put({
                    type: FaqAction.CREATE_FAQ_WITH_CATEGORY_SUCCESS,
                    payload: response
                });
                yield put({
                    type: FaqAction.GET_FAQ_WITH_CATEGORY,
                    payload: {
                        ...payload?.paramsUrl
                    }
                })
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield put({
                    type: FaqAction.CREATE_FAQ_WITH_CATEGORY_FAILED,
                    payload: response
                })
                yield payload?.callback?.failed && payload?.callback?.failed();
            }
        } catch (error) {
            yield put({
                type: FaqAction.CREATE_FAQ_WITH_CATEGORY_FAILED,
            })
        }
    })
}

function* deleteFaqWithCategory() {
    yield takeEvery(FaqAction.DELETE_FAQ_WITH_CATEGORY, function* (payload) {
        try {
            const response = yield call(() => factory.deleteFaqWithCategory(payload?.payload));
            if (response) {
                yield put({
                    type: FaqAction.DELETE_FAQ_WITH_CATEGORY_SUCCESS,
                    payload: response
                })
                yield put({
                    type: FaqAction.GET_FAQ_WITH_CATEGORY,
                    payload: {
                        ...payload?.paramsUrl
                    }
                })
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed();
                yield put({
                    type: FaqAction.DELETE_FAQ_WITH_CATEGORY_FAILED,
                    payload: response
                })
            }
        } catch (error) {
            yield put({
                type: FaqAction.DELETE_FAQ_WITH_CATEGORY_FAILED,
            })
        }
    })
}

function* updateFaqWithCategory() {
    yield takeEvery(FaqAction.UPDATE_FAQ_WITH_CATEGORY, function* (payload) {
        try {
            const response = yield call(() => factory.updateFaqWithCategory(payload?.payload));
            if (response?.id) {
                yield put({
                    type: FaqAction.UPDATE_FAQ_WITH_CATEGORY_SUCCESS,
                    payload: response
                });
                yield put({
                    type: FaqAction.GET_FAQ_WITH_CATEGORY,
                    payload: {
                        ...payload?.paramsUrl
                    }
                })
                yield payload?.callback?.success && payload?.callback?.success();
            } else {
                yield put({
                    type: FaqAction.ADD_FAQ_CATEGORY_FAILED,
                    payload: response
                })
                yield payload?.callback?.failed && payload?.callback?.failed();
            }
        } catch (error) {
            yield put({
                type: FaqAction.UPDATE_FAQ_WITH_CATEGORY_FAILED,
            })
        }
    })
}

function* getDetailFaqWithCategory() {
    yield takeEvery(FaqAction.GET_DETAIL_FAQ_WITH_CATEGORIES, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailFaq(payload?.payload));
            if (response?.data) {
                yield put({
                    type: FaqAction.GET_DETAIL_FAQ_WITH_CATEGORIES_SUCCESS,
                    payload: response?.data
                })
            } else {
                yield put({
                    type: FaqAction.GET_DETAIL_FAQ_WITH_CATEGORIES_FAILED,
                    payload: {}
                })
            }
        } catch (error) {
            yield put({
                type: FaqAction.GET_DETAIL_FAQ_WITH_CATEGORIES_FAILED,
                payload: {}
            })
        }
    })
}

function* getDetailFaqWithCategoryPopup() {
    yield takeEvery(FaqAction.GET_DETAIL_FAQ_WITH_CATEGORIES_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailFaq(payload?.payload));
            if (response?.data) {
                yield payload?.callback(response?.data)
            }
        } catch (error) {
        }
    })
}
function* getFaqCategoryMaster() {
    yield takeEvery(FaqAction.GET_FAQ_CATEGORIES_MASTER, function* (payload) {
        try {
            const response = yield call(() => factory.getFaqCategoryMaster(payload?.payload));
            if (response) {
                yield put({
                    type: FaqAction.GET_FAQ_CATEGORIES_MASTER_SUCCESS,
                    payload: response
                })
            } else {
                yield put({
                    type: FaqAction.GET_FAQ_CATEGORIES_MASTER_FAILED,
                    payload: {}
                })
            }
        } catch (error) {
            yield put({
                type: FaqAction.GET_FAQ_CATEGORIES_MASTER_FAILED,
                payload: {}
            })
        }
    })
}

function* getCategoryWithLanguage() {
    yield takeEvery(FaqAction.GET_FAQ_CATEGORIES_WITH_LANGUAGE, function* (payload) {
        try {
            const response = yield call(() => factory.getFaqCategory(payload?.payload));
            if (!response?.error) {
                yield payload?.callback(response)
            }
        } catch (error) {

        }
    })
}

function* getCategoryWithLanguagePopup() {
    yield takeEvery(FaqAction.GET_FAQ_CATEGORIES_WITH_LANGUAGE_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getFaqCategory(payload?.payload));
            if (!response?.error) {
                yield payload?.callback(response)
            }
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getFaqCategory),
        fork(getFaqByCategory),
        fork(addFaqCategory),
        fork(updateFaqCategory),
        fork(getDetailFaqCategory),
        fork(deleteFaqCategory),
        fork(addFaqWithCategory),
        fork(deleteFaqWithCategory),
        fork(updateFaqWithCategory),
        fork(getDetailFaqWithCategory),
        fork(getFaqCategoryMaster),
        fork(getFaqCategoryPopup),
        fork(getCategoryWithLanguage),
        fork(getCategoryWithLanguagePopup),
        fork(getDetailFaqWithCategoryPopup)
    ])
}