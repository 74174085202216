import React, { useEffect, useState } from 'react';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import Box from 'shared/components/common/box/Box';
import FormInput from 'shared/components/custom-form/FormInput';
import BoxRow from 'shared/components/common/box/BoxRow';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import BoxContent from 'shared/components/common/box/BoxContent';
import Validator from 'utils/Validator';
import Button from 'shared/components/form-input/button/Button';
import BoxBody from 'shared/components/common/box/BoxBody';
import { useDispatch } from 'react-redux';
import FormFileWarehouse from 'shared/components/custom-form/FormFileWarehouse';
import classes from './WalletChargeContainer.module.scss'
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormRadioWalletCharge from 'shared/components/custom-form/FormRadioWalletCharge';
import Constants from 'utils/Constants';
import actionWallet from 'redux/wallet/action';
import { useParams } from 'react-router-dom';
import { showPopupNotification } from 'utils/Utils';

function WalletChargeContainer() {
    const { t } = useTrans();
    const { id } = useParams();
    const methods = useForm({
        defaultValues: {
            currency: 'JPY',
            transactionBy: 1
        }
    });
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [bankInfo, setBankInfo] = useState({})
    const watchData = methods.watch(['transactionBy'])

    useEffect(() => {
        dispatch({
            type: actionWallet.PAYMENT_INFO,
            callback: {
                success: (data) => {
                    setBankInfo(data)
                }
            }
        })
    }, [])


    const handleSubmit = (data) => {
        setLoading(true);
        let bank = {};
        if(data?.transactionBy == 1){
            bank['bankName'] = bankInfo?.bankName;
            bank['branchName'] = bankInfo?.branchName;
            bank['accountType'] = bankInfo?.accountType;
            bank['accountNo'] = bankInfo?.accountNo;
            bank['accountOwner'] = bankInfo?.accountOwner;
            bank['accountOwnerKana'] = bankInfo?.accountOwnerKana;
            bank['accountType'] = Constants.ACTION_TYPE_CHARGE.ORDINARY_DEPOSIT.VALUE;
        }
        dispatch({
            type: actionWallet.CREATION_WALLET_CHARGE,
            payload: {
                ...data,
                walletId: id,
                amount: parseInt(data.amount),
                transactionBy: data?.transactionBy?.toString(),
                attachments: data?.attachments ?? [],
                // status: "1",
                ...bank
            },
            callback: {
                success: (msg) => {
                    setLoading(false);
                    showPopupNotification({
                        typePopup: 'message',
                        message: t(msg)
                    });
                    methods.reset({})
                },
                failed: (msg) => {
                    setLoading(false);
                    showPopupNotification({
                        typePopup: 'message',
                        message: msg
                    })
                }
            }
        })
    }

    let currency = [
        {
            label: 'JPY',
            value: 'JPY',
            key: 'JPY'
        }
    ]

    useEffect(() => {
        if (id) {
            methods.setValue("walletId", id)
        }
    }, [id])

    useEffect(() => {
        if (watchData[0]) {
            if (watchData[0] == "1") {
                methods.setValue("transactionName", t("wallet_charge.bank_transfer"))
            }
            if (watchData[0] == "2") {
                methods.setValue("transactionName", "Alipay")
            }
            if (watchData[0] == "3") {
                methods.setValue("transactionName", "WeChatPay")
            }
        } else {
            methods.clearErrors("attachments")
            methods.setError("attachments", {
                message: undefined
            })
        }
    }, [watchData, methods])

    let validateFile = [];
    if (watchData[0] == 2 || watchData[0] == 3) {
        validateFile.push(Validator.required)
    }
    if (watchData[0] == 1) {
        validateFile = [];
    }

    const handleCancel = () => {
        methods.reset({

        });
    }

    return (
        <ContentLayout>
            <Breadcrumb>{t("wallet_charge.wallet_charge")}</Breadcrumb>
            <CustomFormProvider {...methods}>
                <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                    <Box>
                        <BoxBody style={{
                            padding: '0px'
                        }}>
                            <BoxRow>
                                <BoxLabel>{t("wallet_charge.wallet_id")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("wallet_charge.wallet_id")}
                                        fieldName='walletId'
                                        placeholder={t("wallet_charge.wallet_id")}
                                        disabled
                                    />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel isRequired>{t("wallet_charge.charge_amount")}</BoxLabel>
                                <BoxContent style={{
                                    flexDirection: 'row',
                                    display: 'flex'
                                }}>
                                    <FormInput
                                        validationFE
                                        validate={[Validator.required]}
                                        isRegexLabel={t("wallet_charge.charge_amount")}
                                        fieldName='amount'
                                        format={Constants.FormInputFormat.NUMBER.VALUE}
                                        placeholder={t("wallet_charge.charge_amount")}
                                    />
                                    <div style={{ width: 100 }}>
                                        <FormSelect
                                            validationFE
                                            validate={[]}
                                            isRegexLabel={t("wallet_charge.charge_amount")}
                                            fieldName='currency'
                                            placeholder={t("wallet_charge.charge_amount")}
                                            options={currency}
                                        />
                                    </div>
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel isRequired>{t("wallet_charge.how_to_Change")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[Validator.required]}
                                        isRegexLabel={t("wallet_charge.how_to_Change")}
                                        fieldName='transactionName'
                                        placeholder={t("wallet_charge.how_to_Change")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel></BoxLabel>
                                <BoxContent>
                                    <FormRadioWalletCharge
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("wallet_charge.how_to_Change")}
                                        fieldName='transactionBy'
                                        placeholder={t("wallet_charge.how_to_Change")}
                                        bankInfo={bankInfo}
                                        onChangeValue={(e) => {
                                            if (e == "1") {
                                                methods.setValue("transactionName", t("wallet_charge.bank_transfer"))
                                            }
                                            if (e == "2") {
                                                methods.setValue("transactionName", "Alipay")
                                            }
                                            if (e == "3") {
                                                methods.setValue("transactionName", "WeChatPay")
                                            }
                                        }}
                                    />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel>{t("wallet_charge.charge_reserve")}</BoxLabel>
                                <BoxContent>
                                    <FormFileWarehouse
                                        buttonTitle={<div className={classes['btn-upload']}>{t("wallet_charge.upload_new_photo")}</div>}
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("wallet_charge.charge_reserve")}
                                        fieldName='attachments'
                                        placeholder={t("wallet_charge.charge_reserve")} />
                                </BoxContent>
                            </BoxRow>
                        </BoxBody>
                    </Box>
                    <div className={classes['action-button']}>
                        <Button loading={loading} title={t("keep")} type='submit' />
                        <Button onClick={handleCancel} variant="outlined" title={t("wallet_charge.cancel")} type='button' />
                    </div>
                </form>
            </CustomFormProvider>
        </ContentLayout>
    )
}

export default WalletChargeContainer