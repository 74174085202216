import _i18n from "i18nextInit";
import { useTranslation } from "react-i18next";

export default function useTrans () {
    const { t, i18n } = useTranslation()
    const trans = (text) => {
        return t(text)
    }
    return {
        trans: trans,
        t: trans,
        i18n: i18n
    }
}
