import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import Utils from 'utils/Utils';
import SelectTemplate from '../form-input/select-template/SelectTemplate';

const FormSelectTemplate = (props) => {
    const {
        fieldName,
        validate = [],
        placeholder = '',
        disabled = false,
        options = [],
        textAlign = 'left',
        size = '',
        onChangeSelect,
        isMulti,
        isShowButtonEdit,
        listHolon,
        isBorder = true,
        style,
        validationFE = false,
        isRegexLabel ,
        masterCode
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const wrapRef = useRef()
    return (
        <Controller
            control={control}
            name={fieldName}
            rules={{ validate: Validator.genValidate(validate, fieldName) }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                    <SelectTemplate
                        inputRef={ref}
                        onChange={(e) => {
                            onChange(e);
                            onChangeSelect && onChangeSelect(e);
                            Utils.triggerSubmit(wrapRef);
                        }}
                        value={value}
                        errors={errors}
                        disabled={disabled}
                        options={options}
                        placeholder={placeholder}
                        textAlign={textAlign}
                        name={fieldName}
                        onBlur={onBlur}
                        size={size}
                        isMulti={isMulti}
                        isShowButtonEdit={isShowButtonEdit}
                        listHolon={listHolon}
                        isBorder={isBorder}
                        style={style}
                        isRegexLabel={isRegexLabel}
                        validationFE={validationFE}
                        masterCode={masterCode}
                    />
                );
            }}
        />
    );
};

FormSelectTemplate.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isShowButtonEdit: PropTypes.bool,
    isBorder: PropTypes.bool,
    options: PropTypes.array,
    textAlign: PropTypes.string,
    size: PropTypes.string,
    listHolon: PropTypes.any,
};
export default FormSelectTemplate;
