import { all, call, fork, takeLatest, put } from "redux-saga/effects";
import Actions from "./action";
import Factories from "./factory";

function* getContinents() {
    yield takeLatest(Actions.GET_CONTINENTS, function* (payload) {
        try {
            const response = yield call(() => Factories.getContinents())

            if (response?.data) {
                yield put({
                    type: Actions.GET_CONTINENTS_SUCCESS,
                    payload: response?.data
                })
            }
            else {
                yield put({
                    type: Actions.GET_CONTINENTS_FAILURE,
                })
            }
        }
        catch (e) {
            yield put({
                type: Actions.GET_CONTINENTS_FAILURE,
            })
        }
    })
}

function* getCountriesByContinentId() {
    yield takeLatest(Actions.GET_COUNTRIES_BY_CONTINENT_ID, function* (payload) {
        try {
            const response = yield call(() => Factories.getCountriesByContinentId(payload?.payload))

            if (response?.data) {
                yield put({
                    type: Actions.GET_COUNTRIES_BY_CONTINENT_ID_SUCCESS,
                    payload: response?.data
                })
            }
            else {
                yield put({
                    type: Actions.GET_COUNTRIES_BY_CONTINENT_ID_FAILURE,
                })
            }
        }
        catch (e) {
            yield put({
                type: Actions.GET_COUNTRIES_BY_CONTINENT_ID_FAILURE,
            })
        }
    })
}

function* getAddressByPostalCodeAndCountryId() {
    yield takeLatest(Actions.GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID, function* (payload) {
        try {
            const response = yield call(() => Factories.getAddressByPostalCodeAndCountryId(payload?.payload));
            
            if (response?.data) {  
                payload?.callback && payload?.callback(response?.data);
                yield put({
                    type: Actions.GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID_SUCCESS,
                    payload: response?.data
                })
            }

            else {
                yield put({
                    type: Actions.GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID_FAILURE,
                })
            }

        } catch (error) {
            yield put({
                type: Actions.GET_ADDRESS_BY_POSTAL_CODE_AND_COUNTRY_ID_FAILURE,
            })
        }
    });
}

function* getPortsByPortType() {
    yield takeLatest(Actions.GET_PORTS_BY_PORT_TYPE, function* (payload) {
        const {portType, countryId, callback} = payload;
        try {
            const response = yield call(() => Factories.getPortsByPortType(portType, countryId));
            
            if (response?.data) {  
                callback && callback(response?.data);

                yield put({
                    type: Actions.GET_PORTS_BY_PORT_TYPE_SUCCESS,
                    payload: response?.data
                })
            }

            else {
                yield put({
                    type: Actions.GET_PORTS_BY_PORT_TYPE_FAILURE,
                })
            }

        } catch (error) {
            yield put({
                type: Actions.GET_PORTS_BY_PORT_TYPE_FAILURE,
            })
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getContinents),
        fork(getCountriesByContinentId),
        fork(getAddressByPostalCodeAndCountryId),
        fork(getPortsByPortType),
    ])
}