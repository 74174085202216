import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Constants from 'utils/Constants';
import classes from './Stocks.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import { useForm } from 'react-hook-form';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import IconPlusAccordion from 'assets/icon/IconPlusAccordion';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE } from 'utils/EventRegister';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import ButtonFilter from 'shared/components/form-input/button/ButtonFilter';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import actionStocks from 'redux/stocks/action';
import moment from 'moment';
import IconMinusAccordionFilter from 'assets/icon/IconMinusAccordionFilter';
import { convertDate } from 'utils/Utils';
import CustomFormTable from 'shared/components/stocks/stock-management/CustomFormTable';
import FormHidden from 'shared/components/custom-form/FormHidden';
import { convertNumberToString } from 'utils/String';
import Validator from 'utils/Validator';
import { useParams } from 'react-router';

function InventoryCountContainer() {
    const dispatch = useDispatch();
    const router = useRouter();
    const { listProduct, loadingGetList = false } = useSelector(state => state.stocks);
    const { t } = useTrans();
    const methods = useForm();
    let { id } = useParams()
    const handleSubmit = (data) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: router.get([Constants.QueryParams.LIMIT.VALUE]),
                [Constants.QueryParams.SEARCH.VALUE]: data?.search,
                [Constants.QueryParams.BRAND.VALUE]: data?.brand,
                [Constants.QueryParams.CONTRIBUTOR.VALUE]: data?.contributors,
            }
        })
    }
    useEffect(() => {
        dispatch({
            type: actionStocks.GET_LIST_STOCKS,
            payload: {
                ...router.getAll(),
                [Constants.QueryParams.WAREHOUSE_ID.VALUE]: id
            }
        })
    }, [
        router.get(Constants.QueryParams.SEARCH.VALUE),
        router.get(Constants.QueryParams.BRAND.VALUE),
        router.get(Constants.QueryParams.CONTRIBUTOR.VALUE),
        router.get(Constants.QueryParams.LIMIT.VALUE),
        router.get(Constants.QueryParams.OFFSET.VALUE),
        router.get(Constants.QueryParams.FROM.VALUE),
        router.get(Constants.QueryParams.TO.VALUE),
        id
    ])

    let dataHeadCell = [
        {
            label: t('STT'),
            field: "stt",
            minWidth: '10px',
            width: '10px',
            maxWidth: '10px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (_props) => {
                return <>{_props?.data?.total ? '' : _props?.stt + 1}</>
            }
        },
        {
            label: t('stocks.holder_name'),
            field: "ownerName",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
        {
            label: t('stocks.cargo_number'),
            field: "productCode",
            minWidth: '150px',
            width: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left'
        },
        {
            label: t('stocks.receipt_date'),
            field: "inWarehouseDate",
            minWidth: '100px',
            maxWidth: '100px',
            width: '100px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => {
                return <>{_props?.children ? moment(_props?.children).format("DD/MM/YYYY") : ''}</>
            }
        },
        {
            label: t('stocks.product_name'),
            field: "productName",
            minWidth: '200px',
            width: '200px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (_props) => {
                return _props?.data?.total ? <div style={{ textAlign: 'right' }}>{t("stocks.total")}</div> : <div className={classes['text-line']}>{_props?.children}</div>
            }
        },
        {
            label: t('stocks.quantity'),
            field: "inventoryQuantity",
            minWidth: '100px',
            width: '100px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'right',
            alignHeader: 'right',
            component: (props) => {
                return convertNumberToString(props?.children, ",")
            }
        },
        {
            label: t('stocks.real_number'),
            field: "real_number",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'right',
            alignHeader: 'right',
            component: (props) => {
                return props?.data?.total ? props?.data?.quantity : <>
                    <FormInput validate={[Validator.requiredInput]} format={Constants.FormInputFormat.NUMBER.VALUE} fieldName={`stocks.${props?.stt}.inventoryQuantity`} />
                    <FormHidden format={Constants.FormInputFormat.NUMBER.VALUE} fieldName={`stocks.${props?.stt}.id`} /></>
            }
        },
        {
            label: t('stocks.remarks'),
            field: "note",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
            component: (props) => {
                return props?.data?.total ? '' : <FormInput fieldName={`stocks.${props?.stt}.note`} />
            }
        },
    ];

    const handleOpenAddSearchCriteria = () => {
        let listFormItem = [
            {
                elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                key: 'from',
                label: t("Holon_Template.from"),
                from: router.get(Constants.QueryParams.FROM.VALUE) || null
            },
            {
                elementType: Constants.ELEMENT_TYPE.DATE.VALUE,
                key: 'to',
                label: t("Holon_Template.to"),
                to: router.get(Constants.QueryParams.TO.VALUE) || null
            }
        ]
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                value: {
                    ...router.getAll()
                },
                title: t("Holon_Template.search"),
                listFormItem: listFormItem,
                formArray: false,
                callback: (_props) => {
                    router.replace({
                        params: {
                            ...router.getAll(),
                            [Constants.QueryParams.OFFSET.VALUE]: 1,
                            [Constants.QueryParams.FROM.VALUE]: _props?.from,
                            [Constants.QueryParams.TO.VALUE]: _props?.to,
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (router.get(Constants.QueryParams.SEARCH.VALUE)) {
            methods.setValue("search", router.get(Constants.QueryParams.SEARCH.VALUE));
        } else {
            methods.setValue("search", null);
        }
    }, [router.get(Constants.QueryParams.SEARCH.VALUE)])

    const handleRemoveRouter = (value) => {
        let params = { ...router.getAll() };
        if (value == 'date') {
            delete params[Constants.QueryParams.FROM.VALUE]
            delete params[Constants.QueryParams.TO.VALUE]
        }

        if (value == 'status') {
            delete params[Constants.QueryParams.STATUS.VALUE]
        }

        if (value == 'category') {
            delete params[Constants.QueryParams.CATEGORY.VALUE]
        }

        router.replace({
            params: {
                ...params
            }
        })
    }


    return (
        <ContentLayout>
            <Breadcrumb>{t("stocks.inventory_stocks")}</Breadcrumb>
            <div className={classes['product-container']}>
                <div className={classes['custom-search']}>
                    <CustomFormProvider {...methods}>
                        <form className='quick-submit' onSubmit={methods.handleSubmit(handleSubmit)}>
                            <FormSearch className={classes['custom-form-search']}>
                                <FormGroup>
                                    <FormItem>
                                        <FormInput iconSearch={<IconSearch fontSize={16} />} isSearch fieldName='search' placeholder={t("search_by_word")} />
                                    </FormItem>
                                    {
                                        (
                                            router.get(Constants.QueryParams.FROM.VALUE) || router.get(Constants.QueryParams.TO.VALUE)
                                        ) &&
                                        <FormItem>
                                            <ButtonFilter
                                                onClick={() => handleRemoveRouter('date')}
                                                color='white' size='large'
                                                title={`${t("Holon_Template.created")}：${convertDate(router.get(Constants.QueryParams.FROM.VALUE))} ${router.get(Constants.QueryParams.FROM.VALUE) && router.get(Constants.QueryParams.TO.VALUE) ? '〜' : ''} ${convertDate(router.get(Constants.QueryParams.TO.VALUE))} `}
                                                endIcon={<IconMinusAccordionFilter />} />
                                        </FormItem>
                                    }
                                    <FormItem>
                                        <ButtonFilter onClick={handleOpenAddSearchCriteria} size='large' title={t("Holon_Template.add_search_conditions")} color='white' endIcon={<IconPlusAccordion />} />
                                    </FormItem>
                                </FormGroup>
                            </FormSearch>
                            <button hidden type='submit' />
                        </form>
                    </CustomFormProvider>
                </div>
                <div className={classes['custom-body']}>
                    <CustomFormTable id={id} loadingGetList={loadingGetList} listProduct={listProduct} dataHeadCell={dataHeadCell} />
                </div>
            </div>
        </ContentLayout>
    )
}

export default InventoryCountContainer