import React, { useEffect } from 'react'
import classes from './FaqManagementPopup.module.scss';
import Faq from 'shared/components/faq/Faq';
import { useForm } from 'react-hook-form';
import Constants from 'utils/Constants';
import useCustomState from 'hooks/use-custom-state';
import { useDispatch, useSelector } from 'react-redux';
import FaqAction from 'redux/faq/action';

function FaqManagementPopup() {
    const methods = useForm();
    const { faqCategoryMaster, faq } = useSelector(state => state.faq);
    const [activeCategory, setActiveCategory] = useCustomState(3);
    const [activeAccordion, setActiveAccordion] = useCustomState(null);
    const [dataFaqCategory, setFaqCategory] = useCustomState([]);
    const [search, setSearch] = useCustomState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (search) {
            methods.setValue("Search", search)
        } else {
            methods.setValue("Search", "")
        }
    }, [search])


    useEffect(() => {
        if (faqCategoryMaster) {
            setFaqCategory(faqCategoryMaster?.records);
        }
    }, [faqCategoryMaster])

    useEffect(() => {
        if (faqCategoryMaster?.records?.length == 0) {
            dispatch({
                type: FaqAction.GET_FAQ_WITH_CATEGORY_SUCCESS,
                payload: {
                    records: [],
                    limit: 0,
                    page: 1,
                    total: 0,
                    totalPage: 1
                }
            })

            return;
        }
        if (activeCategory) {
            setActiveCategory(activeCategory);
            dispatch({
                type: FaqAction.GET_FAQ_WITH_CATEGORY,
                payload: {
                    categoryId: activeCategory,
                    [Constants.QueryParams.SEARCH.VALUE]: search
                }
            })
        } else {
            setActiveCategory(faqCategoryMaster?.records?.[0]?.id)
            dispatch({
                type: FaqAction.GET_FAQ_WITH_CATEGORY,
                payload: {
                    categoryId: faqCategoryMaster?.records?.[0]?.id,
                    [Constants.QueryParams.SEARCH.VALUE]: search
                }
            })
        }
    }, [faqCategoryMaster?.records?.[0]?.id], activeCategory, search);

    const onSubmit = (data) => {
        setSearch(data?.Search)
        dispatch({
            type: FaqAction.GET_FAQ_WITH_CATEGORY,
            payload: {
                categoryId: activeCategory,
                [Constants.QueryParams.SEARCH.VALUE]: data?.Search
            }
        })
    };

    const onChangeCategory = (e) => {
        setActiveCategory(e);
        setSearch("");
        dispatch({
            type: FaqAction.GET_FAQ_WITH_CATEGORY,
            payload: {
                categoryId: e,
            }
        })
    };
    return (
        <div className={classes['service-detail-popup']}>
            <div className={classes['service-detail-content']}>
                <Faq
                    onSubmit={onSubmit} onChangeCategory={onChangeCategory}
                    activeCategory={activeCategory}
                    activeAccordion={activeAccordion}
                    setActiveAccordion={setActiveAccordion}
                    dataFaqCategory={dataFaqCategory}
                    faq={faq}
                    methods={methods}
                    margin="0px"
                    width="100%"
                />
            </div>
        </div>
    )
}

export default FaqManagementPopup