// import IcOverview from 'assets/images/icons/ic-overview';
// import {
//     IcCategory,
//     IcColor,
//     IcProduct,
//     IcSize,
//     IcTag
// } from 'assets/images/icons/icon-list';
import _ from "lodash";
import RouterPath from "../router/RouterPath";

export default class Permission {
    static FUNCTIONS = {
        CREATE_INQUIRY: 'F001',
        UPDATE_INQUIRY: 'F002',
        UPDATE_MANAGEMENT_INFO_INQUIRY: 'F003',
    }
    static IsEnabledFunction = (Id) => {
        let decentralization = [];
        try {
            decentralization = localStorage.getItem('functions') ? JSON.parse(localStorage.getItem('functions')) : [];
        } catch (error) {
            decentralization= [];
        }
        
        let fnc = {};
        
        fnc = decentralization?.find(item => item?.functionCode == Id)

        return fnc ? fnc.permission > 0 : false;
    }
    // static IsEnabledFunction = (Id) => {
    //     let decentralization = {};
    //     try {
    //         decentralization = localStorage.getItem('decentralization') ? JSON.parse(localStorage.getItem('decentralization')) : {};
    //     } catch (error) {
    //         decentralization= {};
    //     }
        
    //     if (!decentralization || !decentralization.Module) {
    //         return true;
    //     }
    //     const module = _.find(decentralization.Module, (item) => (item.Id == this.MODULE_ID));
    //     if (!module || !module.Screens) {
    //         return true;
    //     }
    //     const length = module.Screens.length;
    //     let fnc = null;
    //     for (let i = 0; i < length; i++) {
    //         if (module.Screens[i].Functions) {
    //             fnc = _.find(module.Screens[i].Functions, (item) => (item.Id == Id));
    //             if (fnc) {
    //                 break;
    //             }
    //         }
    //     }
    //     return fnc ? fnc.IsEnabled : true;
    // }
    // static icons = {
    //     'IcOverview': <IcOverview />,
    //     'IcCategory': <IcCategory />,
    //     'IcColor': <IcColor />,
    //     'IcProduct': <IcProduct />,
    //     'IcSize': <IcSize />,
    //     'IcTag': <IcTag />
    // };
    static getSidebarData = (decentralization) => {
        const result = [];
        const module = _.find(decentralization.Module, (item) => (item.Id == Permission.MODULE_ID));
        if (module && module.Menus) {
            const length = module.Menus.length;
            for (let i = 0; i < length; i++) {
                const menu = module.Menus[i];
                const m = {};
                m.id = menu.Id;
                m.title = menu.Name;
                m.icon = this.icons[menu.Icon] ?? this.icons['IcOverview'];
                if (menu.Menus && menu.Menus.length > 0) {
                    const length2 = menu.Menus.length;
                    m.subNav = [];
                    for (let j = 0; j < length2; j++) {
                        const childMmenu = menu.Menus[j];
                        const cm = {};
                        cm.id = childMmenu.Id;
                        cm.title = childMmenu.Name;
                        cm.path = childMmenu.RouterName ? RouterPath[childMmenu.RouterName] ?? '' : childMmenu.ScreenUrl;
                        cm.parentId = m.id
                        m.subNav.push(cm);
                    }
                }
                else {
                    m.path = menu.RouterName ? RouterPath[menu.RouterName] ?? '' : menu.ScreenUrl;
                }
                result.push(m);
            }
        }
        //console.log(result);
        return result;
        //return SidebarData;
    }
}