import React, { useState } from 'react'
import classes from './ContentMessage.module.scss';
import ImageDefault from "assets/img/image_default.png"

function getImageDimensions(imageUrl, callback, maxWidth, maxHeight) {
    var img = new Image();

    img.onerror = (e) => {
        e.target.onerror = null;
        setTimeout(() => {
            getImageDimensions(ImageDefault, callback, maxWidth, maxHeight)
        }, 1);
    }

    img.onload = function () {

        let width = img.width;
        let height = img.height
        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
        }

        callback({ width, height , image: img.src});
    };

    img.src = imageUrl;
}

function ResizeImage({ url, maxWidth = 300, maxHeight = 200}) {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [image, setImage] = useState(null)
    getImageDimensions(url, (data) => {
        setWidth(data?.width);
        setHeight(data?.height);
        setImage(data?.image);
    }, maxWidth, maxHeight)
    return (
        <div className={classes['Resize_Image']} style={{
            backgroundImage: `url('${image}')` ,
            width: width,
            height: height
        }} />
    )
}

export default ResizeImage