import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Actions from 'redux/holon/action';
import styles from './../order-management/OrdersManagementContainer.module.scss'
import HolonInstance from 'shared/components/holon-instance/HolonInstance';
import { actionsHolonInstance } from 'redux/holon-instance/action';

const BPOrderContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTrans()
    const [template, setTemplate] = useState();
    const [listTemplateCreation, setListTemplateCreation] = useState([]);

    useEffect(() => {
        dispatch({
            type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.BP_ORDER,
            callback: (data) => {
                setTemplate(data.toString());
                dispatch({
                    type: actionsHolonInstance.GET_HOLON_TEMPLATE_WITH_IDS,
                    payload: data?.join(","),
                    callback: (_data) => {
                        setListTemplateCreation(_data?.map(x => ({ id: x?.id, name: x?.name})));
                    }
                })
            }
        })

    }, []);
    
    return <div className={`${styles['order-management-container']}`}>
        <div className={styles['holon-instance']} >
            {template &&
                <HolonInstance
                    title={t("order_management.bp_title")}
                    template_id={template}
                    isUseAddFunction={true}
                    titleDetailPopup={t("order_management.popup_detail_title")}
                    titleCreatePopup={t("order_management.popup_create_title")}
                    titleUpdatePopup={t("order_management.popup_update_title")}
                    isShowCategory
                    typeCategory={Constants.MASTER_HOLON_CATEGORY.object_digital}
                    listTemplateCreation={listTemplateCreation}
                />
            }
        </div>
    </div>
}

export default BPOrderContainer;