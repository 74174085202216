import React from 'react'
import PackageTracking from 'shared/container/package-tracking/PackageTracking'

function RenderBody(props) {
    switch (props?.data?.typePopup) {
        case "G0100":
            return <PackageTracking {...props} />
        default:
            return <></>
    }
}

export default RenderBody