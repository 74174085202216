import React from 'react'

function IconStatsPencil() {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={40}
                height={40}
                fill="none"
            >
                <rect width={40} height={40} fill="#106574" rx={10} />
                <path
                    fill="#fff"
                    d="m29.423 13.453-2.877-2.875a1.972 1.972 0 0 0-2.788 0l-1.865 1.864-9.112 9.113a3.624 3.624 0 0 0-.847 1.336l-1.847 5.14a1.471 1.471 0 0 0 1.883 1.882l5.14-1.847c.502-.18.958-.47 1.335-.848l8.626-8.625.488-.488 1.864-1.864c.768-.77.77-2.017 0-2.788ZM13.756 22.53l9.044-9.045 1.614 1.614-9.184 9.182-1.605-1.604c.042-.05.085-.1.131-.147Zm2.887 4.238-3.275 1.177-1.313-1.313 1.177-3.274c.008-.024.02-.047.03-.07l3.45 3.45c-.023.01-.046.021-.07.03Zm.827-.524a2.392 2.392 0 0 1-.146.13l-1.606-1.605 9.184-9.183 1.613 1.613-9.045 9.044Zm10.978-10.978-1.377 1.376-.069.07-3.714-3.714 1.445-1.446a.594.594 0 0 1 .838.001l2.877 2.875a.592.592 0 0 1-.002.839h.002Z"
                />
            </svg>
        </>
    )
}

export default IconStatsPencil