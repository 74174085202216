export const actionsHolonInstance = {
    GET_HOLON_INSTANCE : "GET_HOLON_INSTANCE",
    GET_HOLON_INSTANCE_SUCCESS : "GET_HOLON_INSTANCE_SUCCESS",
    GET_HOLON_INSTANCE_FAILED : "GET_HOLON_INSTANCE_FAILED",
    
    GET_HOLON_INSTANCE_DETAIL : "GET_HOLON_INSTANCE_DETAIL",
    GET_HOLON_INSTANCE_DETAIL_SUCCESS : "GET_HOLON_INSTANCE_DETAIL_SUCCESS",
    GET_HOLON_INSTANCE_DETAIL_FAILED : "GET_HOLON_INSTANCE_DETAIL_FAILED",

    HOLON_TEMPLATE_MASTER : "HOLON_TEMPLATE_MASTER",
    HOLON_TEMPLATE_MASTER_SUCCESS : "HOLON_TEMPLATE_MASTER_SUCCESS",
    HOLON_TEMPLATE_MASTER_FAILED : "HOLON_TEMPLATE_MASTER_FAILED",

    HOLON_TEMPLATE_DETAIL : "HOLON_TEMPLATE_DETAIL",
    HOLON_TEMPLATE_DETAIL_SUCCESS : "HOLON_TEMPLATE_DETAIL_SUCCESS",
    HOLON_TEMPLATE_DETAIL_FAILED : "HOLON_TEMPLATE_DETAIL_FAILED",
    
    HOLON_TEMPLATE_DETAIL_POPUP : "HOLON_TEMPLATE_DETAIL_POPUP",
    
    // GET_TEMPLATE_DETAIL_CREATE_INSTANCE : "GET_TEMPLATE_DETAIL_CREATE_INSTANCE",
    // GET_TEMPLATE_DETAIL_CREATE_INSTANCE_SUCCESS : "GET_TEMPLATE_DETAIL_CREATE_INSTANCE_SUCCESS",
    // GET_TEMPLATE_DETAIL_CREATE_INSTANCE_FAILED : "GET_TEMPLATE_DETAIL_CREATE_INSTANCE_FAILED",

    GET_HOLON_TEMPLATE_CONNECTION : "GET_HOLON_TEMPLATE_CONNECTION",
    GET_HOLON_TEMPLATE_CONNECTION_SUCCESS : "GET_HOLON_TEMPLATE_CONNECTION_SUCCESS",

    CREATE_HOLON_INSTANCE : "CREATE_HOLON_INSTANCE",
    CREATE_HOLON_INSTANCE_SUCCESS : "CREATE_HOLON_INSTANCE_SUCCESS",
    CREATE_HOLON_INSTANCE_FAILED : "CREATE_HOLON_INSTANCE_FAILED",
    
    DELETE_HOLON_INSTANCE : "DELETE_HOLON_INSTANCE",
    DELETE_HOLON_INSTANCE_SUCCESS : "DELETE_HOLON_INSTANCE_SUCCESS",

    UPDATE_HOLON_INSTANCE : "UPDATE_HOLON_INSTANCE",
    UPDATE_HOLON_INSTANCE_SUCCESS : "UPDATE_HOLON_INSTANCE_SUCCESS",
    UPDATE_HOLON_INSTANCE_FAILED : "UPDATE_HOLON_INSTANCE_FAILED",

    GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE : "GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE",
    GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_SUCCESS : "GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_SUCCESS",
    GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_FAILED : "GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_FAILED",
    GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_LOADING : "GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_LOADING",

    GET_SUB_HOLON_TEMPLATE : "GET_SUB_HOLON_TEMPLATE",
    GET_SUB_HOLON_TEMPLATE_SUCCESS : "GET_SUB_HOLON_TEMPLATE_SUCCESS",
    GET_SUB_HOLON_TEMPLATE_FAILED : "GET_SUB_HOLON_TEMPLATE_FAILED",

    HANDLE_CLONE_HOLON_INSTANCE : "HANDLE_CLONE_HOLON_INSTANCE",
    HANDLE_CLONE_HOLON_INSTANCE_SUCCESS : "HANDLE_CLONE_HOLON_INSTANCE_SUCCESS",
    HANDLE_CLONE_HOLON_INSTANCE_FAILED : "HANDLE_CLONE_HOLON_INSTANCE_FAILED",


    GET_HOLON_TEMPLATE_WITH_IDS : "GET_HOLON_TEMPLATE_WITH_IDS",
    
    GET_LIST_TEMPLATE_ID_WITH_INSTANCE: "GET_LIST_TEMPLATE_ID_WITH_INSTANCE",

    REQUEST_API_WITH_URL : "REQUEST_API_WITH_URL",
    RESET_HOLON_TEMPLATE_DETAIL : "RESET_HOLON_TEMPLATE_DETAIL",

    GET_PACKAGE_TRACKING : "GET_PACKAGE_TRACKING",

    GET_USER_IN_COMPANY : "GET_USER_IN_COMPANY",

    GET_COMMON_FILED : "GET_COMMON_FILED",

    GET_ACTION_COMMON : "GET_ACTION_COMMON",

    RESET_HOLON_INSTANCE: "RESET_HOLON_INSTANCE",

    BP_TO_PAYMENT: "BP_TO_PAYMENT",

    LOAD_HOLON_TEMPLATE_ID: "LOAD_HOLON_TEMPLATE_ID"
}