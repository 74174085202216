import Constants from "utils/Constants";
import Utils from "utils/Utils";
import { getCoreAPI } from "shared/components/common/Util";
import ApiOperation from "adapter/ApiOperation";

const Factory = {

    getTrackingInformationHistory: (payload) => {
        const url = `${getCoreAPI()}/management/tracking-information/history/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    updateTrackingInformationHistory: (payload) => {
        const url = `${getCoreAPI()}/management/tracking-information/history`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload,
        })
    },

    checkOrderStatus: (payload) => {
        const url = `${getCoreAPI()}/management/order/cancel/check-status/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    cancelOrder: (payload) => {
        const url = `${getCoreAPI()}/management/order/cancel/${payload.orderId}`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload.data
        })
    },
    importOrder: (payload) => {
        const url = `${getCoreAPI()}/management/order/import`;
        const formData = new FormData();
        formData.append('file', payload?.file);
        formData.append('templateId', payload?.templateId);
        formData.append('isIgnoreError', payload?.isIgnoreError ?? false);
        if(payload?.warehouseId){
            formData.append('warehouseId', payload?.warehouseId);
        }
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: formData
        })
    },
}
export default Factory;