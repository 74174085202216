import { useStateMachine } from 'little-state-machine';
import React, { useState } from 'react'
import Constants from 'utils/Constants';
import { clearAction, persistAction } from 'utils/Helper';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_POPUP_USER_REGISTER, EVENT_SHOW_POPUP_USER_REGISTER_RESULT, EVENT_SHOW_SERVICE_DETAIL_POPUP } from 'utils/EventRegister';
import { useDispatch, useSelector } from 'react-redux';
import Action from 'redux/user/action';
import StepperComponent from '../../common/Stepper';
import Actions from 'redux/app/action';

import styles from "./Register.module.scss";
import useTrans from 'hooks/use-trans';
import { useEffect } from 'react';
import ElementType from 'utils/ElementType';
import Loading from 'shared/components/form-input/button/loading/Loading';

const UserRegisterStep2Popup = (props) => {
    const [disabled, setDisabled] = useState(false);
    const { state } = useStateMachine(persistAction);
    const { actions } = useStateMachine({ clearAction, persistAction });

    const { termOfUse, policy } = useSelector(state => state.home);
    const { trans } = useTrans();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            actions.clearAction();
            actions.persistAction({
                email: state?.data?.email
            })
        };
    }, []);
    const onBack = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_REGISTER,
            open: true,
            payload: {
                title: trans("Inquiry.sign_up"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
            }
        })
    }

    const handleOpenTermOfUse = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_SERVICE_DETAIL_POPUP,
            open: true,
            newWindow: true,
            payload: {
                title: trans("faq_management.term_of_service"),
                content: termOfUse?.content
            }
        })
    }
    const handleOpenPolicy = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_SERVICE_DETAIL_POPUP,
            open: true,
            newWindow: true,
            payload: {
                title: trans("privacy_policy.title"),
                content: policy?.content
            }
        })
    }

    const onSubmit = () => {
        setDisabled(true);
        let data = [{
            name: "holon_code",
            value: `${Constants.HOLON_MASTER_CODE.USER_TEMPLATE},${Constants.HOLON_MASTER_CODE.HONNIN_TEMPLATE}`,
        }];

        Object.keys(state?.data).forEach((key, index) => {
            data.push({
                name: key,
                value: state?.data[key],
                description: localStorage.getItem(key) ? localStorage.getItem(key) :  ""
            });
            localStorage.removeItem(key)
        });
        
        dispatch({
            type: Action.REGISTER,
            formData: data,
            callback: () => {
                setDisabled(false);
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    type: EVENT_SHOW_POPUP_USER_REGISTER_RESULT,
                    open: true,
                    payload: {
                        title: trans("Inquiry.sign_up"),
                    }
                })
            },
            fallback: (detail) => {
                setDisabled(false);
                let msg = trans("E0036");
                Object.keys(detail)?.map(key => {
                    if (key == 'key') {
                        detail[key]?.map(message => {
                            if (message) {
                                msg = message;
                            }
                        })
                    }
                });
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    open: true,
                    newWindow: true,
                    payload: {
                        title: msg,
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            }
        });
    }

    const getValue = (field) => {
        if (field?.elementType == ElementType.SELECT
            || field?.elementType == ElementType.RADIO
            || field?.elementType == ElementType.CHECKBOX
        ) {
            const selected = state?.data[field?.key];
            return field?.regex?.split("｜")?.map(item => {
                const [value, label] = item?.split("：");
                if (value == selected) {
                    return label;
                }
            })
        }
        return state?.data[field?.key];
    }

    return <div className={styles['register-container']}>
        <div className='mb-3 d-center'>
            <StepperComponent
                steps={Constants.REGISTER_STEP}
                activeStep={1}
                diameter={120}
            />
        </div>
        <div className={styles['notify']}>
            <div className={styles['notify-header']}>
                {trans("register_form.step2.title1")}
            </div>
            <div className={styles['notify-content']}>
                <div dangerouslySetInnerHTML={{ __html: trans("register_form.step2.content2") }} />
            </div>
        </div>
        <div className={styles['notify']}>
            <div className={styles['notify-header']}>
                {trans("register_form.step2.title2")}
            </div>
            <div className={styles['notify-content']}>
                {
                    state?.user?.map((template, index) => {
                        return template?.attributes?.map((field, index) => {
                            return (field?.elementType !== ElementType.AUTO && field?.elementType !== ElementType.PASSWORD) && !Constants.BLOCKED_FIELD_REGISTER.includes(field?.key) && !field?.key?.startsWith("setting") && <div className='d-flex' key={index}>
                                <div className='col-5 label'>{field?.label}</div>
                                <div className='col-7'>
                                    {getValue(field)}
                                </div>
                            </div>
                        })
                    })
                }
                {
                    state?.honnin?.map((template, index) => {
                        return template?.attributes?.map((field, index) => {
                            return (field?.elementType !== ElementType.AUTO && field?.elementType !== ElementType.PASSWORD) && !Constants.BLOCKED_FIELD_REGISTER.includes(field?.key) && !field?.key?.startsWith("setting") && <div className='d-flex' key={index}>
                                <div className='col-5 label'>{field?.label}</div>
                                <div className='col-7'>
                                    {getValue(field)}
                                </div>
                            </div>
                        })
                    })
                }
            </div>
        </div>
        <div className={styles['notify']}>
            <div className={styles['notify-header']}>
                {trans("register_form.step2.title3")}
            </div>
            <div className={styles['notify-content']}>
                <ul>
                    <li>
                        {trans("register_form.step2.accept_term_of_use.item1_start")}
                        <button onClick={handleOpenPolicy} style={{ background: 'transparent', border: 'none', color: '#0031f7', fontWeight: '500' }}>{trans("register_form.step2.accept_term_of_use.privacy_btn")}</button>
                        {trans("register_form.step2.accept_term_of_use.linking_word")}
                        <button onClick={handleOpenTermOfUse} style={{ background: 'transparent', border: 'none', color: '#0031f7', fontWeight: '500' }}>{trans("register_form.step2.accept_term_of_use.term_of_service")}</button>
                        {trans("register_form.step2.accept_term_of_use.item1_end")}
                    </li>
                    <li>
                        {trans("register_form.step2.content3")}
                    </li>
                    <span>
                        {trans("register_form.step2.content3_1")}
                    </span>
                </ul>
            </div>
        </div>
        <div className='text-center'>
            <button disabled={disabled} onClick={onBack} className='btn btn-outline-dark me-3'>{trans("common.back_button")}</button>
            <button disabled={disabled} onClick={onSubmit} className={`${styles['btn-submit']} btn btn-dark mx-auto`}>
                <span className={disabled ? styles['disable-btn'] : ''} >{trans("register_form.button.agreeSubmit")}</span>
                {disabled ? <div className={styles['centered-content']}><Loading /> </div> : ''}
            </button>
        </div>
    </div>
}

export default UserRegisterStep2Popup;