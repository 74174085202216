import React, { useEffect, useMemo, useState } from 'react'
import classes from './UpdateAlarm.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import actionAlarm from 'redux/alarm-holon-setting/action';
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormInput from 'shared/components/custom-form/FormInput';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ALARM_DETAIL_HOLON_TEMPLATE, EVENT_SHOW_POPUP_CONFIRM_UPDATE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import Validator from 'utils/Validator';
import FormHidden from 'shared/components/custom-form/FormHidden';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxRow from '../../box/BoxRow';
import BoxBody from '../../box/BoxBody';
import CustomBox from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxLabel from '../../box/BoxLabel';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import actionHolonTemplate from 'redux/holon-template/action';
import { showPopupNotification } from 'utils/Utils';

function UpdateAlarmPopup(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const { t } = useTrans();
    const [listAttributes, setListAttributes] = useState([])
    const [listType, setListType] = useState([])
    const [listAlarmMaster, setListAlarmMaster] = useState([])
    const [listConnections, setListConnection] = useState([]);
    const [holonTemplateDetail, setHolonTemplateDetail] = useState([]);
    const [alarmHolonTemplateDetail, setAlarmHolonTemplateDetail] = useState([]);
    const dispatch = useDispatch();
    const {
        master, alarmCodeMaster
    } = useSelector(state => state.alarmHolonTemplate);

    useEffect(() => {
        if (holonTemplateDetail?.id) {
            dispatch({
                type: actionAlarm.GET_OWNER_COLLECTION_HOLON_TEMPLATE,
                payload: holonTemplateDetail?.id,
                callback: (_data) => {
                    setListConnection(_data?.map(x => {
                        return {
                            label: x?.note,
                            key: x?.key,
                            value: x?.key,
                            key_option: x?.key,
                        }
                    }))
                }
            })
        }
    }, [holonTemplateDetail])


    useEffect(() => {
        if (alarmHolonTemplateDetail?.id) {
            methods.setValue("type", alarmHolonTemplateDetail?.type);
            methods.setValue("id", alarmHolonTemplateDetail?.id);
            methods.setValue("holonTemplateId", [alarmHolonTemplateDetail?.holonTemplateId]);
            methods.setValue("holonKey", alarmHolonTemplateDetail?.holonKey);
            methods.setValue("conditionsText", alarmHolonTemplateDetail?.conditionsText);
            methods.setValue("conditionsLogic", alarmHolonTemplateDetail?.conditionsLogic);
            methods.setValue("messageText", alarmHolonTemplateDetail?.message);
            methods.setValue("alarmLevel", alarmHolonTemplateDetail?.alarmLevel?.toString());
            methods.setValue("alarmOnScreen", alarmHolonTemplateDetail?.alarmOnScreen);
            methods.setValue("alarmOnMail", alarmHolonTemplateDetail?.alarmOnMail);
            methods.setValue("status", alarmHolonTemplateDetail?.status);
            methods.setValue("target_key", alarmHolonTemplateDetail?.subjectKey);

            if (alarmHolonTemplateDetail?.type == 'T') {
                methods.setValue("alarmLevel", '0');
                setListAlarmMaster([
                    {
                        label: master?.alarmLevel['0'],
                        value: "0",
                        key: "0",
                    }
                ])
            } else {
                let data = [];
                Object.keys(master?.alarmLevel).map(key => {
                    if (key != "0") {
                        data.push({
                            label: master?.alarmLevel[key],
                            value: key,
                            key: key
                        })
                    }
                })
                setListAlarmMaster(data)
                methods.setValue("alarmLevel", alarmHolonTemplateDetail?.alarmLevel?.toString());
            }

            dispatch({
                type: actionAlarm.GET_HOLON_TEMPLATE_DETAIL,
                payload: alarmHolonTemplateDetail?.holonTemplateId
            })
        }
    }, [alarmHolonTemplateDetail])

    useEffect(() => {
        if (holonTemplateDetail?.id && alarmHolonTemplateDetail?.id) {
            let index = holonTemplateDetail?.attributes?.findIndex(x => x?.key == alarmHolonTemplateDetail?.holonKeyValue);
            setListAttributes(holonTemplateDetail?.attributes?.map(x => {
                return {
                    value: x?.id,
                    label: x?.label,
                    key: x?.id,
                    key_option: x?.key
                }
            }))

            if (index != -1) {
                methods.setValue("holonKey", holonTemplateDetail?.attributes[index]?.id?.toString());
            }
        }
    }, [holonTemplateDetail, alarmHolonTemplateDetail])

    useEffect(() => {
        if (alarmHolonTemplateDetail?.id) {
            dispatch({
                type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP,
                payload: alarmHolonTemplateDetail?.holonTemplateId,
                callback: {
                    success: (data) => {
                        setHolonTemplateDetail(data)
                        setListAttributes(data?.attributes?.map(x => {
                            return {
                                ...x,
                                key_option: x?.key
                            }
                        }))
                    }
                }
            })
        }
    }, [alarmHolonTemplateDetail?.id])

    useEffect(() => {
        if (payload?.alarmId) {
            dispatch({
                type: actionAlarm.GET_ALARM_HOLON_SETTING_DETAIL_POPUP,
                payload: payload?.alarmId,
                callback: (data) => {
                    setAlarmHolonTemplateDetail(data);
                }
            })
        }
    }, [payload?.alarmId])

    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            if (name == "holonTemplateId" && value['holonTemplateId']?.length > 0) {
                dispatch({
                    type: actionAlarm.GET_HOLON_TEMPLATE_DETAIL,
                    payload: value['holonTemplateId'][0]
                })
            }
        }
        )
        return () => subscription.unsubscribe()
    }, [methods.watch])

    useEffect(() => {
        if (master?.alarmTypes) {
            setListType(Object.keys(master?.alarmTypes).map(key => {
                return {
                    value: key,
                    key: key,
                    label: master?.alarmTypes[key]
                }
            }))
        }
    }, [master])

    const onSubmit = (data) => {
        let params = {
            type: data?.type,
            holonTemplateId: holonTemplateDetail?.id,
            holonTemplateName: holonTemplateDetail?.name,
            holonKeyLabel: holonTemplateDetail?.attributes?.find(x => x?.id == data?.holonKey)?.label,
            holonKeyValue: holonTemplateDetail?.attributes?.find(x => x?.id == data?.holonKey)?.key,
            conditionsText: data?.conditionsText,
            conditionsLogic: data?.conditionsLogic,
            messageText: data?.messageText,
            alarmLevel: parseInt(data?.alarmLevel, 10),
            alarmOnScreen: data?.alarmOnScreen || [],
            alarmOnMail: data?.alarmOnMail || [],
            subjectKey: data?.target_key,
            status: data?.status,
            id: data?.id
        }

        showPopupNotification({
            typePopup: 'confirm',
            message: t("E0042"),
            newWindow: true,
            callback: () => {
                showVisible();
                dispatch({
                    type: actionAlarm.UPDATE_ALARM_HOLON_SETTING,
                    payload: params,
                    callback: {
                        success: () => {
                            showVisible();
                            payload?.callback();
                            showPopupNotification({
                                typePopup: 'message',
                                message: t("E0043"),
                            })
                        },
                        failed: (msg) => {
                            showPopupNotification({
                                typePopup: 'message',
                                message: msg,
                                newWindow: true,
                            })
                        }
                    }
                })
            }
        })
    }

    let statusTemplate = [
        {
            value: Constants.STATUS_ALARM.ACTIVE.VALUE,
            key: Constants.STATUS_ALARM.ACTIVE.VALUE,
            label: t("Holon_Template.valid"),
        },
        {
            value: Constants.STATUS_ALARM.UNACTIVE.VALUE,
            key: Constants.STATUS_ALARM.UNACTIVE.VALUE,
            label: t("Holon_Template.invalid"),
        }
    ]

    useEffect(() => {
        return () => {
            dispatch({
                type: actionAlarm.GET_HOLON_TEMPLATE_DETAIL_SUCCESS,
                payload: {
                    holonTemplateCodeMaster: {}
                }
            })

            setListAlarmMaster(null);
            setListAttributes(null);
            setListConnection(null);
            setListType(null);
        }
    }, [])

    const gotoDetail = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ALARM_DETAIL_HOLON_TEMPLATE,
            payload: {
                title: t("Holon_Template.alarm_setting_detail"),
                showHeader: false,
                alarmId: payload?.alarmId,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                callback: () => {
                }
            }
        })
    }

    return (
        <BoxContentPopup width='1000px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        actionSectionBottom={<Button type='submit' title={t("save")} />}
                        showVisible={showVisible} title={payload?.title}
                        handleBtnBack={() => {
                            showVisible();
                            gotoDetail()
                        }}
                    >
                        <CustomBox>
                            <BoxHeader>
                                <BoxTitle>{t("Holon_Template.setting")}</BoxTitle>
                            </BoxHeader>
                            <BoxBody>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.Warning/notification")}</BoxLabel>
                                    <FormSelect
                                        validationFE
                                        isRegexLabel={t("Holon_Template.Warning/notification")}
                                        disabled validate={[]} onChangeSelect={(e) => {
                                            if (e == 'T') {
                                                methods.setValue("alarmLevel", '0');
                                                setListAlarmMaster([
                                                    {
                                                        label: master?.alarmLevel['0'],
                                                        value: "0",
                                                        key: "0",
                                                    }
                                                ])
                                            } else {
                                                let data = [];
                                                Object.keys(master?.alarmLevel).map(key => {
                                                    if (key != "0") {
                                                        data.push({
                                                            label: master?.alarmLevel[key],
                                                            value: key,
                                                            key: key
                                                        })
                                                    }
                                                })
                                                setListAlarmMaster(data)
                                                methods.setValue("alarmLevel", data[0].key);
                                            }
                                        }} options={listType ?? []} fieldName='type' />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.holon_template")}</BoxLabel>
                                    <FormSelectTemplate
                                        placeholder={t("alarm.please_select_the_target_of_notification_alarm_conditions")}
                                        masterCode={null} style={{
                                            width: '100%'
                                        }} isBorder={false} disabled isShowButtonEdit isMulti={false} fieldName='holonTemplateId' />
                                    <FormHidden fieldName='id' />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.key")}</BoxLabel>
                                    <FormSelect
                                        placeholder={t("alarm.please_specify_notification_alarm_conditions_Example_Balance")}
                                        isRegexLabel={t("Holon_Template.key")}
                                        validationFE
                                        isShowKey disabled validate={[]} fieldName='holonKey' options={listAttributes ?? []} />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.condition_explanation")}</BoxLabel>
                                    <FormInput
                                        placeholder={t("alarm.description_text_Example_Wallet_balance_is_less_than")}
                                        isRegexLabel={t("Holon_Template.condition_explanation")}
                                        validationFE
                                        validate={[]} fieldName='conditionsText' />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.conditional_expression")}</BoxLabel>
                                    <FormInput
                                        placeholder={t("alarm.example")}
                                        isRegexLabel={t("Holon_Template.conditional_expression")}
                                        validationFE
                                        validate={[]} fieldName='conditionsLogic' />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.target_key")}</BoxLabel>
                                    <FormSelect
                                        placeholder={t("alarm.please_specify_the_recipient_of_notifications_alarms")}
                                        isRegexLabel={t("Holon_Template.target_key")}
                                        validationFE
                                        isShowKey validate={[]} options={listConnections ?? []} fieldName='target_key' />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.msg")}</BoxLabel>
                                    <FormInput
                                        placeholder={t("alarm.please_enter_the_notification_alarm_message")}
                                        isRegexLabel={t("Holon_Template.msg")}
                                        validationFE
                                        validate={[]} fieldName='messageText' />
                                </BoxRow>

                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.alarm_lv")}</BoxLabel>
                                    <FormSelect
                                        isRegexLabel={t("Holon_Template.alarm_lv")}
                                        validationFE
                                        validate={[]} options={listAlarmMaster ?? []} fieldName='alarmLevel' />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.user_screen_notification")}</BoxLabel>
                                    <FormSelectTemplate
                                        placeholder={t("alarm.please_select_the_desired_notification_alarm_target_on_the_screen")}
                                        masterCode="READER_WRITER" style={{
                                            width: '100%'
                                        }} isBorder={false} listHolon={alarmCodeMaster?.records} isShowButtonEdit fieldName='alarmOnScreen' />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.email_notification")}</BoxLabel>
                                    <FormSelectTemplate
                                        placeholder={t("alarm.please_select_the_target_for_which_you_would_like_to_receive_notifications_alerts_via_email")}
                                        masterCode="READER_WRITER" style={{
                                            width: '100%'
                                        }} isBorder={false} listHolon={alarmCodeMaster?.records} isShowButtonEdit fieldName='alarmOnMail' />
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth="140px">{t("Holon_Template.status")}</BoxLabel>
                                    <FormSelect
                                        isRegexLabel={t("Holon_Template.status")}
                                        validationFE
                                        validate={[]} options={statusTemplate ?? []} fieldName='status' />
                                </BoxRow>
                            </BoxBody>
                        </CustomBox>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default UpdateAlarmPopup