const actionWareHouse  = {
    GET_WAREHOUSE_LIST : "GET_WAREHOUSE_LIST",
    GET_WAREHOUSE_LIST_SUCCESS : "GET_WAREHOUSE_LIST_SUCCESS",
    GET_WAREHOUSE_LIST_FAILED : "GET_WAREHOUSE_LIST_FAILED",
    
    GET_WAREHOUSE_DETAIL : "GET_WAREHOUSE_DETAIL",
    GET_WAREHOUSE_DETAIL_SUCCESS : "GET_WAREHOUSE_DETAIL_SUCCESS",
    GET_WAREHOUSE_DETAIL_FAILED : "GET_WAREHOUSE_DETAIL_FAILED",

    GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE : "GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE",
    GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_SUCCESS : "GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_SUCCESS",
    GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_FAILED : "GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_FAILED",

    UPDATE_WAREHOUSE : "UPDATE_WAREHOUSE",
    UPDATE_WAREHOUSE_SUCCESS : "UPDATE_WAREHOUSE_SUCCESS",
    UPDATE_WAREHOUSE_FAILED : "UPDATE_WAREHOUSE_FAILED",

    DELETE_WAREHOUSE : "DELETE_WAREHOUSE",
    DELETE_WAREHOUSE_SUCCESS : "DELETE_WAREHOUSE_SUCCESS",
    DELETE_WAREHOUSE_FAILED : "DELETE_WAREHOUSE_FAILED",

    CREATE_WAREHOUSE : "CREATE_WAREHOUSE",
    CREATE_WAREHOUSE_SUCCESS : "CREATE_WAREHOUSE_SUCCESS",
    CREATE_WAREHOUSE_FAILED : "CREATE_WAREHOUSE_FAILED",
}
export default actionWareHouse;