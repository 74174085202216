import React, { useEffect, useState } from 'react'
import classes from './CreateHolonTemplate.module.scss';
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormInput from 'shared/components/custom-form/FormInput';
import { useFieldArray, useForm } from 'react-hook-form';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import Button from 'shared/components/form-input/button/Button';
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import Constants from 'utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import actionHolonTemplate from 'redux/holon-template/action';
import { EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import useTrans from 'hooks/use-trans';
import { Close } from '@mui/icons-material';
import Utils, { showPopupNotification } from 'utils/Utils';
import Validator from 'utils/Validator';
import WrapContentBody from '../custom-body/WrapContentBody';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid'
import IconDropDrap from 'assets/icon/IconDropdrap';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import CustomBox from '../../box/Box';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import IconPlusYellow from 'assets/icon/IconPlusYellow';
import BoxContentPopup from '../custom-body/BoxContentPopup';

const getListStyle = isDraggingOver => ({
    zIndex: 1,
    padding: '8px 19px 12px 19px'
});

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "",
    ...draggableStyle,
    zIndex: 1
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function CreateHolonTemplate(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const control = methods.control;
    const { loading: {
        loadingCreateTemplate
    }, categories , actionType} = useSelector(state => state.holonTemplate);
    const { t } = useTrans();
    // const [elementType, setElementType] = useState([])
    const dispatch = useDispatch()
    const {
        append: appendHas,
        fields: fieldsHas,
        remove: removeHas,
    } = useFieldArray({
        control, name: "attributes"
    });

    const {
        append: appendActions,
        fields: fieldsActions,
        remove: removeActions,
    } = useFieldArray({
        control, name: "actions"
    })

    const {
        append: appendConnects,
        fields: fieldsConnects,
        remove: removeConnects,
    } = useFieldArray({
        control, name: "connections"
    })

    const onSubmit = (data) => {

        let attributes = data?.attributes?.map(x => {
            return {
                ...x,
                isRequired: x?.isRequired ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isMultiSelected: x?.isMultiSelected ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isEditabled: x?.isEditabled ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                elementType: x?.elementType ?? Constants.ELEMENT_TYPE.TEXT.VALUE
            }
        })
        let _connections = data?.connections?.map(x => {
            return {
                ...x,
                isRequired: x?.isRequired ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isMultiSelected: x?.isMultiSelected ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                isEditabled: x?.isEditabled ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                holonTemplate: x?.holonTemplate || []
            }
        })
        let params = {
            // id: "TL08",
            category: data?.category || "",
            name: data?.name || "",
            status: data?.status || Constants.STATUS_CATEGORY.PUBLIC.VALUE,
            note: data?.note || "",
            library: data?.library || "",
            attributes: attributes,
            actions: data?.actions,
            connections: _connections,
        }

        dispatch({
            type: actionHolonTemplate.CREATE_HOLON_TEMPLATE_MANAGEMENT,
            payload: params,
            success: () => {
                showVisible(false)
                payload?.callback();
                showPopupNotification({
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    typePopup: 'message',
                    message: t("E0041"),
                })
            },
            failed: (msg) => {
                showPopupNotification({
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    typePopup: 'message',
                    message: msg,
                    newWindow: true,
                })
            }
        })

    }

    let unitRotation = [
        {
            value: "s",
            key: "s",
            label: "S",
        },
        // {
        //     value: "day",
        //     key: "day",
        //     label: "Day",
        // },
        {
            value: "m",
            key: "minute",
            label: "M",
        },
        {
            value: "h",
            key: "hour",
            label: "H",
        }
    ]

    let statusTemplate = [
        {
            value: Constants.STATUS_CATEGORY.PUBLIC.VALUE,
            key: Constants.STATUS_CATEGORY.PUBLIC.VALUE,
            label: t("Holon_Template.valid"),
        },
        {
            value: Constants.STATUS_CATEGORY.PRIVATE.VALUE,
            key: Constants.STATUS_CATEGORY.PRIVATE.VALUE,
            label: t("Holon_Template.invalid"),
        }
    ]

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let items = reorder(
            methods.watch("attributes"),
            result.source.index,
            result.destination.index
        );

        items = items?.map((x, index) => {
            return {
                ...x,
                sortNo: index
            }
        })
        methods.setValue("attributes", items)
    }

    useEffect(() => {
        if (payload?.dataClone) {
            let holonTemplateDetail = payload?.dataClone;
            let attributes = holonTemplateDetail.attributes?.map(x => {
                return {
                    ...x,
                    isRequired: x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isMultiSelected: x?.isMultiSelected == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isEditabled: x?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                }
            })
            let connections = holonTemplateDetail.connections?.map(x => {
                return {
                    ...x,
                    isRequired: x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isMultiSelected: x?.isMultiSelected == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                    isEditabled: x?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? true : false,
                }
            })
            holonTemplateDetail.attributes = attributes;
            holonTemplateDetail.connections = connections;
            methods.reset(holonTemplateDetail)
        }
    }, [payload?.dataClone])

    return (
        <BoxContentPopup width='1000px' borderRadius="20px 0px 0px 20px">
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        title={payload?.title}
                        actionSectionBottom={<Button loading={loadingCreateTemplate} type='submit' color='gray' title={t("Holon_Template.save")} />
                        }
                        showVisible={showVisible}
                    >
                        <div className={classes['create-holon-template']}>
                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.basic_information")}</BoxTitle>
                                </BoxHeader>
                                <BoxBody>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.category")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormSelect validationFE textTrans='Holon_Template.field_name' placeholder={t("Holon_Template.category")} fieldName='category' options={Object.keys(categories)?.map(key => {
                                                return {
                                                    value: key,
                                                    key: key,
                                                    label: categories[key]
                                                }
                                            })} validate={[Validator.required]} />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.template_name")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormInput validationFE textTrans='Holon_Template.field_name' validate={[Validator.required, Validator.maxLength(200)]} placeholder={t("Holon_Template.template_name")} fieldName="name" />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.status")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormSelect validationFE textTrans='Holon_Template.field_name' validate={[Validator.required]} options={statusTemplate} placeholder={t("Holon_Template.status")} fieldName='status' />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.explanation")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormInput validationFE textTrans='Holon_Template.field_name' validate={[Validator.maxLength(500)]} placeholder={t("Holon_Template.please_enter")} fieldName='note' />
                                        </BoxContent>
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth={140}>
                                            {t("Holon_Template.library")}
                                        </BoxLabel>
                                        <BoxContent width={300}>
                                            <FormSelect placeholder={t("Holon_Template.please_select")} fieldName='library' />
                                        </BoxContent>
                                    </BoxRow>
                                </BoxBody>
                            </CustomBox>
                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.attribute")}</BoxTitle>
                                    <div style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }} onClick={() => {
                                        appendHas({
                                            key: "",
                                            explain: "",
                                            label: "",
                                            regex: "",
                                            msg: "",
                                            defaultValue: "",
                                            isRequired: false,
                                            isMultiSelected: false,
                                            isEditabled: false,
                                            reader: [],
                                            writer: [],
                                            elementType: null,
                                            idCreated: uuidv4(),
                                            sortNo: fieldsHas?.length + 1
                                        })
                                    }}>
                                        <IconPlusYellow />
                                    </div>
                                </BoxHeader>
                                <BoxBody padding={0}>
                                    <div className={classes['custom-table']}>
                                        <table>
                                            <thead>
                                                <tr className={classes['form-header']}>
                                                    <th className={`${classes['head-title']} ${classes['input-key']}`}>{t("Holon_Template.key")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.key_name")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explanation")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.regex")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.default_value")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.message_key")}</th>
                                                    <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.require")}</th>
                                                    <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.multiple_allowed")}</th>
                                                    {/* <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.editable")}</th> */}
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.element_type")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-action']}`}>{t("Holon_Template.delete")} </th>
                                                    <th className={`${classes['head-title']} ${classes['button-sort']}`}></th>
                                                </tr>
                                            </thead>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable direction='vertical' droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <tbody
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                            className='overflow-y-auto'
                                                        >
                                                            {
                                                                fieldsHas?.map((x, key) => {
                                                                    return <Draggable key={x?.id} draggableId={x?.id} index={key}>
                                                                        {(provided, snapshot) => (
                                                                            <tr
                                                                                ref={provided.innerRef}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                key={x?.idCreated}
                                                                                className={classes['form-body']}>

                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.key")} validate={[Validator.required, Validator.stringKey, Validator.maxLength(50)]} fieldName={`attributes.${key}.key`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.key_name")} validate={[Validator.required, Validator.maxLength(1000)]} fieldName={`attributes.${key}.label`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.explanation")} validate={[Validator.maxLength(1000)]} fieldName={`attributes.${key}.explain`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.regex")} validate={[Validator.maxLength(1000)]} fieldName={`attributes.${key}.regex`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.default_value")} validate={[Validator.maxLength(1000)]} fieldName={`attributes.${key}.defaultValue`} />
                                                                                </td>
                                                                                <td>
                                                                                    <FormInput validationFE isRegexLabel={t("Holon_Template.message_key")} validate={[Validator.maxLength(1000)]} fieldName={`attributes.${key}.msg`} />
                                                                                </td>
                                                                                <td className={classes['checkbox-width']}>
                                                                                    <FormCheckbox fieldName={`attributes.${key}.isRequired`} />
                                                                                </td>
                                                                                <td className={classes['checkbox-width']}>
                                                                                    <FormCheckbox fieldName={`attributes.${key}.isMultiSelected`} />
                                                                                </td>
                                                                                {/* <td className={classes['checkbox-width']}>
                                                                                    <FormCheckbox fieldName={`attributes.${key}.isEditabled`} />
                                                                                </td> */}
                                                                                <td className={classes['input-width']}>
                                                                                    <FormSelect options={Utils.convertObjectKeyToArr(Constants.ELEMENT_TYPE, "NAME", "VALUE", "Holon_Template.list_element_type.")} fieldName={`attributes.${key}.elementType`} />
                                                                                </td>
                                                                                <td className={classes['button-width']}>
                                                                                    <FormSelectTemplate masterCode="READER_WRITER" fieldName={`attributes.${key}.reader`} />
                                                                                </td>
                                                                                <td className={classes['button-width']}>
                                                                                    <FormSelectTemplate masterCode="READER_WRITER" fieldName={`attributes.${key}.writer`} />
                                                                                </td>
                                                                                <td onClick={() => removeHas(key)} className={classes['button-action-remove']}>
                                                                                    <IconMinusAccordion />
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: '100%', cursor: 'grab' }}>
                                                                                        <IconDropDrap />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </Draggable>
                                                                })
                                                            }
                                                            {provided.placeholder}
                                                        </tbody>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </table>
                                    </div>
                                </BoxBody>
                            </CustomBox>

                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.action")}</BoxTitle>
                                    <div style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }} onClick={() => {
                                        appendActions({
                                            key: "",
                                            note: "",
                                            inputValue: "",
                                            outputValue: "",
                                            timeout: "0",
                                            unit: "s",
                                            api: "",
                                            reader: [],
                                            writer: [],
                                            idCreated: fieldsActions?.length + 1,
                                            type: "",
                                            actionType: '0'
                                        })
                                    }}>
                                        <IconPlusYellow />
                                    </div>
                                </BoxHeader>
                                <BoxBody padding={0}>
                                    <div className={classes['custom-table']}>
                                        <table>
                                            <thead>
                                                <tr className={classes['form-header']}>
                                                    <th className={`${classes['head-title']} ${classes['input-key']}`}>{t("Holon_Template.action_name")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explanation")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.type")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.input")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.output")}</th>
                                                    <th style={{ minWidth: 90 }} className={`${classes['head-title']}`}>{t("Holon_Template.timeout")}</th>
                                                    <th style={{ minWidth: 50 }} className={`${classes['head-title']}`}>{t("Holon_Template.unit")}</th>
                                                    <th style={{ minWidth: 70 }} className={`${classes['head-title']}`}>{t("Holon_Template.api")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.action_type")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-action']}`}>{t("Holon_Template.delete")} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fieldsActions?.map((x, key) => {
                                                        return <tr key={x?.idCreated} className={classes['form-body']}>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.action_name")} validate={[Validator.required, Validator.maxLength(50), Validator.stringKey]} fieldName={`actions.${key}.key`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.explanation")} validate={[Validator.maxLength(200)]} fieldName={`actions.${key}.note`} />
                                                            </td>
                                                            <td>
                                                                <FormSelect options={Utils.convertObjectKeyToArr(Constants.TYPE_ACTIONS, "NAME", "VALUE", "Holon_Template.actions.")} fieldName={`actions.${key}.type`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.input")} validate={[Validator.required, Validator.maxLength(100)]} fieldName={`actions.${key}.inputValue`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.output")} validate={[Validator.required, Validator.maxLength(100)]} fieldName={`actions.${key}.outputValue`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.timeout")} validate={[Validator.maxLength(10)]} fieldName={`actions.${key}.timeout`} />
                                                            </td>
                                                            <td>
                                                                <FormSelect options={unitRotation} fieldName={`actions.${key}.unit`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.api")} validate={[Validator.required, Validator.maxLength(200)]} fieldName={`actions.${key}.api`} />
                                                            </td>
                                                            <td>
                                                                <FormSelect options={Object.keys(actionType).map(key => ({ value: key?.toString(), label: actionType[key]}))} fieldName={`actions.${key}.actionType`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate masterCode="READER_WRITER" fieldName={`actions.${key}.reader`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate masterCode="READER_WRITER" fieldName={`actions.${key}.writer`} />
                                                            </td>
                                                            <td className={classes['button-action-remove']} onClick={() => removeActions(key)}>
                                                                <IconMinusAccordion />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </BoxBody>
                            </CustomBox>

                            <CustomBox>
                                <BoxHeader>
                                    <BoxTitle>{t("Holon_Template.connect")}</BoxTitle>
                                    <div style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }} onClick={() => {
                                        appendConnects({
                                            key: "",
                                            note: "",
                                            isRequired: false,
                                            isMultiSelected: false,
                                            isEditabled: false,
                                            reader: [],
                                            writer: [],
                                            holonTemplate: [],
                                            idCreated: fieldsConnects?.length + 1
                                        })
                                    }}>
                                        <IconPlusYellow />
                                    </div>
                                </BoxHeader>
                                <BoxBody padding={0}>
                                    <div className={classes['custom-table']}>
                                        <table>
                                            <thead>
                                                <tr className={classes['form-header']}>
                                                    <th className={`${classes['head-title']} ${classes['input-key']}`}>{t("Holon_Template.key")}</th>
                                                    <th className={`${classes['head-title']} ${classes['input-width']}`}>{t("Holon_Template.explanation")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.holon_template")}</th>
                                                    <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.multiple_allowed")}</th>
                                                    <th className={`${classes['head-title']} ${classes['checkbox-width']}`}>{t("Holon_Template.require")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.reader")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-width']}`}>{t("Holon_Template.writer")}</th>
                                                    <th className={`${classes['head-title']} ${classes['button-action']}`}>{t("Holon_Template.delete")} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fieldsConnects.map((x, key) => {
                                                        return <tr key={x?.idCreated} className={classes['form-body']}>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.key")} validate={[Validator.required, Validator.maxLength(1000), Validator.stringKey]} fieldName={`connections.${key}.key`} />
                                                            </td>
                                                            <td>
                                                                <FormInput validationFE isRegexLabel={t("Holon_Template.explanation")} validate={[Validator.maxLength(1000)]} fieldName={`connections.${key}.note`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate fieldName={`connections.${key}.holonTemplate`} />
                                                            </td>
                                                            <td className={classes['checkbox-width']}>
                                                                <FormCheckbox fieldName={`connections.${key}.isMultiSelected`} />
                                                            </td>
                                                            <td className={classes['checkbox-width']}>
                                                                <FormCheckbox fieldName={`connections.${key}.isRequired`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate masterCode="READER_WRITER" fieldName={`connections.${key}.reader`} />
                                                            </td>
                                                            <td>
                                                                <FormSelectTemplate masterCode="READER_WRITER" fieldName={`connections.${key}.writer`} />
                                                            </td>
                                                            <td onClick={() => removeConnects(key)} className={classes['button-action-remove']}>
                                                                <IconMinusAccordion />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </BoxBody>
                            </CustomBox>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default CreateHolonTemplate