import React from 'react'
import classes from './WrapContentBody.module.scss';
import CustomHeaderPopup from '../custom-header/CustomHeaderPopup';
import PropTypes from 'prop-types';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import AutoScroll from '../../box/AutoScroll';

function WrapContentBody(props) {
    const { handleBtnBack,
        loadingBtnBottom = false,
        disabledBtnBottom = false,
        handleBtnBottom,
        showVisible,
        payload,
        children,
        actionSectionBottom,
        actionSectionTop,
        title,
        isUseBackground = true,
        isUseBtnBack,
        isUseBtnBottom = true,
        isUseActionSectionTop = true,
        useForm = true,
        maxHeight = '65vh',
        style
    } = props;
    const { t } = useTrans();
    return (
        <div style={{
            backgroundColor: isUseBackground ? '' : 'transparent',
            paddingBottom: !isUseBtnBottom ? 28 : 20,
        }} className={`${classes['wrap-content-body']} wrap-content-body`}>
            <CustomHeaderPopup
                onClickBtn={handleBtnBack}
                showVisible={showVisible}
                payload={payload}
                ActionSection={actionSectionTop}
                title={title}
                isUseBtnBack={isUseBtnBack}
                isUseActionSectionTop={isUseActionSectionTop}
                useForm={useForm} />
            <div style={{
                ...style
            }} className={classes['wrap-content']}>
                <AutoScroll className={classes['wrap-form']} style={{
                    maxHeight: maxHeight
                }}>
                    {children}
                </AutoScroll>
                {
                    isUseBtnBottom && <div className={classes['wrap-footer']}>
                        {actionSectionBottom ? actionSectionBottom : <Button color='gray' disabled={disabledBtnBottom} loading={loadingBtnBottom} onClick={handleBtnBottom} title={t("Holon_Template.delete")} />}
                    </div>
                }
            </div>
        </div>
    )
}

WrapContentBody.propTypes = {
    handleBtnBack: PropTypes.func,
    loadingBtnBottom: PropTypes.bool,
    disabledBtnBottom: PropTypes.bool,
    handleBtnBottom: PropTypes.func,
    showVisible: PropTypes.any,
    payload: PropTypes.any,
    children: PropTypes.any,
    actionSectionBottom: PropTypes.any,
    actionSectionTop: PropTypes.any,
    title: PropTypes.any,
    isUseBackground: PropTypes.bool,
    isUseBtnBack: PropTypes.bool,
    isUseBtnBottom: PropTypes.bool,
    useForm: PropTypes.bool,
    isUseActionSectionTop: PropTypes.bool,
}
export default WrapContentBody