import React, { useEffect, useState } from 'react'
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_SELECT_HOLON_TEMPLATE } from 'utils/EventRegister'
import classes from './SelectTemplate.module.scss';
import Button from '../button/Button';
import IconEdit from 'assets/icon/IconEdit';
import { useSelector } from 'react-redux';
import useTrans from 'hooks/use-trans';
import _ from 'lodash';
import ErrorMessage from '../error-message/ErrorMessage';
import IconClose from 'assets/icon/IconClose';
import HolonTemplateName from 'shared/components/holon-instance/holon-template-name/HolonTemplateName';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';

export default function SelectTemplate(props) {
    const { value, onChange, isMulti = true, isShowButtonEdit = false, listHolon, disabled, errors, name, isBorder = true
        , inputRef, style, validationFE, isRegexLabel, masterCode } = props;
    const [valueTemplate, setValueTemplate] = useState([]);
    const { holonTemplateSearch, loading: {
        loadingGetTemplateSearch = false
    } } = useSelector((state) => state.holonTemplate);
    const { t } = useTrans();

    const handleOpenTemplate = () => {
        if (!disabled) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                newWindow: true,
                type: EVENT_SHOW_POPUP_SELECT_HOLON_TEMPLATE,
                payload: {
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                    callback: (data) => {
                        onChange && onChange(data ?? []);
                        setValueTemplate(data ?? [])
                    },
                    value,
                    data: listHolon ? { records: listHolon } : holonTemplateSearch,
                    title: t("Holon_Template.holon_template_select"),
                    isMulti: isMulti,
                    masterCode: masterCode
                }
            })
        }
    }

    useEffect(() => {
        if (value) {
            setValueTemplate(value)
        } else {
            setValueTemplate([])
        }
    }, [value])

    let showError = false;
    let error = {
        message: '',
    };

    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    const handleRemove = (x) => {
        let data = [...valueTemplate];
        let index = valueTemplate?.findIndex(v => v == x);
        if (index != -1) {
            data.splice(index, 1);
        }

        setValueTemplate(data);
        onChange(data)
    }

    return (
        <>
            <div style={{
                ...style
            }} ref={inputRef} className={`${classes['select-template']} ${!isBorder ? classes['border-none'] : ''}
            ${valueTemplate?.length == 0 ? classes['select-template-empty'] : ''}`}>
                {
                    valueTemplate?.length > 0 ? <div className={classes['wrap-content-template']}>
                        <div className={classes['template-row']}>
                            {
                                valueTemplate?.length > 0 ? [...valueTemplate]?.slice(0, 3)?.map(x => {
                                    return <div key={x} className={classes['template-item']}>
                                        <HolonTemplateName id={x} />
                                        {
                                            isBorder ? <span onClick={() => handleRemove(x)}>
                                                <IconClose color='currentColor' fontSize={13} />
                                            </span> : <></>
                                        }
                                    </div>
                                }) : <></>
                            }
                            {
                                ((isBorder) || valueTemplate?.length > 3) ? <div onClick={handleOpenTemplate} className={classes['button-read-more']}>&#8230;</div>
                                    : <></>
                            }
                        </div>
                        {
                            isShowButtonEdit && <Button size='small' loading={loadingGetTemplateSearch} disabled={disabled} onClick={handleOpenTemplate} startIcon={<IconEdit />} />
                        }
                    </div> : <Button size='small' loading={loadingGetTemplateSearch} disabled={disabled} onClick={handleOpenTemplate} startIcon={<IconEdit />} />
                }
            </div>
            {showError ? (
                <div className={classes['Custom_Select_Error_Message']}>
                    {
                        !validationFE ? (<ErrorMessage message={error?.message} />) : (
                            isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                        )
                    }
                </div>) : (
                <></>
            )}
        </>
    )
}
