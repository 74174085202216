import React, { useRef } from 'react'
import useOnClickOutside from 'hooks/use-onclick-outside';
import classes from './ProductActions.module.scss'
import useTrans from 'hooks/use-trans';
import IconChevronDown from 'assets/icon/IconChevronDown';
import { useState } from 'react';
import Constants from 'utils/Constants';
import { downloadFileWithFileName, getMessageCommon, showPopupNotification } from 'utils/Utils';
import factory from 'redux/holon-instance/factory';
import useRouter from 'hooks/use-router';
import { useDispatch } from 'react-redux';
import EventRegister, { EVENT_SHOW_POPUP } from 'utils/EventRegister';
import actionProduct from 'redux/product/action';
import Loading from 'shared/components/form-input/button/loading/Loading';
import Validator from 'utils/Validator';
import IconChevronUp from 'assets/icon/IconChevronUp';

const ItemDropdown = ({ disabled, loading, onClick, title, target, href, style, rel, children }) => {
    if (href) {
        return (
            <a target={target} style={style} rel={rel} href={href}
                className={`${classes['item-dropdown']} ${disabled ? classes['disable'] : ''} `}>{children ?? title}</a>
        )
    }

    return (
        <div
            className={`${classes['item-dropdown']} ${disabled ? classes['disable'] : ''} `}
            onClick={() => onClick && onClick()}
        >
            {children ?? title}
            {loading ? (
                <div className={`${classes['icon-button']} ${classes['icon-button-loading']}`}><Loading /></div>
            ) : null}
        </div>
    )
}

function DropdownOptions() {
    const router = useRouter();
    const dispatch = useDispatch();
    const { t } = useTrans();
    const [loadingInstance, setLoadingInstance] = useState(null);

    const handleAsyncPost = (x, index) => {
        let data = {};
        if (x?.data) {
            data = {
                ...x.data
            }
        }

        showPopupNotification({
            typePopup: 'confirm',
            newWindow: true,
            message: Validator.renderMessage(t("confirm_action"), {
                0: x?.note
            }),
            payload: {
                isLoading: true
            },
            callback: async (propsCallbackPopup) => {
                setLoadingInstance(index);
                try {
                    const response = await factory.requestApiWithAction({
                        method: 'POST',
                        url: x?.api,
                        data
                    })

                    if (typeof propsCallbackPopup?.hiddenPopup == 'function') {
                        propsCallbackPopup?.hiddenPopup()
                    }
                    if (!response?.error) {
                        setLoadingInstance(null);
                        if (response?.type == 'blob') {
                            downloadFileWithFileName(response?.data, response?.filename, response?.contentType)
                        } else {
                            showPopupNotification({
                                typePopup: 'message',
                                message: getMessageCommon({
                                    response: response,
                                    error: null,
                                    actionName: x?.note
                                }),
                                newWindow: true
                            });
                        }
                    } else {
                        setLoadingInstance(null);
                        showPopupNotification({
                            typePopup: 'message',
                            message: getMessageCommon({
                                response: null,
                                error: response,
                                actionName: x?.note
                            }),
                            newWindow: true
                        });
                    }
                } catch (error) {
                    setLoadingInstance(null);
                    showPopupNotification({
                        typePopup: 'message',
                        message: getMessageCommon({
                            response: null,
                            error: error,
                            actionName: x?.note
                        }),
                        newWindow: true
                    });
                }
            }
        });
    }

    const list = [
        {
            type: Constants.TYPE_ACTIONS.ASYNCPOST.VALUE,
            api: '/rest/api/v1/management/product/import/template',
            note: t('product.download_template_import'),
            data: {"responseType": "blob"}
        },
        {
            type: Constants.TYPE_ACTIONS.POPUP.VALUE,
            api: 'G1600U',
            note: t('product.import_product')
        },
    ]

    return (
        <>
            {list.map((x, index) => {
                if (Constants.TYPE_ACTIONS.ASYNCPOST.VALUE === x.type) {
                    return (
                        <ItemDropdown
                            key={index}
                            disabled={(loadingInstance != index && loadingInstance != null) ? true : false || loadingInstance != null ? true : false}
                            loading={loadingInstance == index ? true : false}
                            color='yellow'
                            style={{ color: 'white' }}
                            onClick={() => handleAsyncPost(x, index)}
                            title={x.note}
                        />
                    )
                }

                if (Constants.TYPE_ACTIONS.POPUP.VALUE === x.type) {
                    return (
                        <ItemDropdown
                            key={index}
                            disabled={(loadingInstance != index && loadingInstance != null) ? true : false || loadingInstance != null ? true : false}
                            loading={loadingInstance == index ? true : false}
                            color='yellow'
                            style={{ color: 'white' }}
                            onClick={() => {
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: x?.api,
                                    payload: {
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                        api: x?.api,
                                        actionName: x?.note,
                                        callback: ({ isFileEmpty }) => {
                                            if (isFileEmpty) {
                                                return;
                                            }
                                            dispatch({
                                                type: actionProduct.GET_LIST_PRODUCT,
                                                payload: {
                                                    ...router.getAll(),
                                                },
                                                callback: () => {
                                                    setLoadingInstance(null)
                                                }
                                            });
                                        }
                                    }
                                })
                            }}
                            title={x.note}
                        />
                    )
                }

                return null
            })}
        </>
    )
}

function ProductActions() {
    const [anchorEl, setAnchorEl] = useState(null);
    const dropDownMenuRef = useRef();
    const { t } = useTrans();

    useOnClickOutside(dropDownMenuRef, () => {
        setAnchorEl(null);
    });

    return (
        <div ref={dropDownMenuRef} className={`${classes["dropdown-container"]} custom-content`}>
            <div
                onClick={(e) => setAnchorEl(e)}
                className={classes["custom-content"]}
            >
                {t("holon.batch_processing")}
                {anchorEl ? (
                    <IconChevronUp strokeWidth={1} />
                ) : (
                    <IconChevronDown strokeWidth={1} />
                )}
            </div>
            {anchorEl && (
                <div style={{
                    minWidth: dropDownMenuRef.current?.clientWidth
                }} className={classes['dropdown-option-list']}>
                    <DropdownOptions />
                </div>
            )}
        </div>
    );
}

export default ProductActions;
