import React from 'react'

function IconTag() {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.14206 11.6504L11.0173 5.77479L9.96265 4.72041L4.08764 10.5954C3.72975 10.953 3.45952 11.3692 3.27968 11.8127C3.01003 12.4777 2.94315 13.2027 3.07753 13.8978C3.2119 14.5925 3.55081 15.2601 4.08764 15.7968C4.44499 16.1547 4.86143 16.4252 5.30468 16.6047C5.96995 16.8741 6.69472 16.9412 7.38972 16.8066C8.08476 16.6725 8.75214 16.3336 9.2887 15.7967L16.4939 8.59147C16.9842 8.10155 17.3539 7.5326 17.5995 6.92615C17.9684 6.0165 18.0603 5.02303 17.8759 4.07205C17.6925 3.12107 17.2294 2.20908 16.4943 1.47388C16.0043 0.983686 15.4351 0.614467 14.8289 0.368334C13.919 -0.000260398 12.9264 -0.091823 11.9752 0.0918881C11.0239 0.275599 10.1119 0.738764 9.37699 1.47419L1.89495 8.95588C1.26471 9.58612 0.789711 10.3174 0.474008 11.0965C-0.000172183 12.2657 -0.11818 13.5422 0.118148 14.7658C0.354789 15.9881 0.949009 17.1597 1.89495 18.105C2.52487 18.7352 3.25616 19.2103 4.03557 19.5262C5.20444 20.0001 6.48124 20.1184 7.70421 19.8815C8.92687 19.6452 10.0984 19.0509 11.0441 18.105L17.1958 11.953L16.1414 10.8985L9.98933 17.0506C9.50183 17.5381 8.94175 17.9014 8.34277 18.1439C7.4453 18.5084 6.45983 18.6 5.51812 18.4175C4.57604 18.2343 3.68065 17.7813 2.94967 17.0506C2.46182 16.5631 2.09885 16.003 1.85569 15.4037C1.49151 14.5065 1.40026 13.5208 1.58252 12.579C1.76534 11.6373 2.21866 10.7416 2.94967 10.0106L10.4314 2.52888C10.7786 2.18166 11.1766 1.92334 11.6027 1.7506C12.2409 1.49166 12.9425 1.42658 13.6125 1.55619C14.2828 1.68642 14.9187 2.00834 15.4395 2.52826C15.7868 2.87576 16.0445 3.27381 16.2172 3.69951C16.4764 4.33771 16.5415 5.03959 16.4119 5.70967C16.2817 6.37971 15.9597 7.01588 15.4395 7.5367L8.23398 14.742C8.01906 14.9566 7.77382 15.1157 7.51132 15.2224C7.11745 15.3823 6.68374 15.4227 6.26991 15.3425C5.85581 15.2616 5.4646 15.0636 5.14206 14.742C4.92714 14.5271 4.76839 14.2818 4.66198 14.019C4.50206 13.6254 4.46132 13.1914 4.54186 12.7776C4.62272 12.3635 4.82011 11.9723 5.14206 11.6504Z" fill="#0E525E" />
        </svg>

    )
}

export default IconTag