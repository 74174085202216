import React from "react";
import classes from "./Section.module.scss"

const Section = (props) => {

    const { sectionTitle } = props

    return (
        <div className={classes["Section"]}>
            <div className={classes["SectionHeader"]}>
                <p>{sectionTitle}</p>
            </div>
            <div className={classes["SectionBody"]}>
                {props.children}
            </div>
        </div>
    );
};

export default Section;
