import React, { useEffect } from 'react'
import classes from './FAQ.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FaqAction from 'redux/faq/action';
import useTrans from 'hooks/use-trans';
import Validator from 'utils/Validator';
import FormSelect from 'shared/components/custom-form/FormSelect';
import useCustomState from 'hooks/use-custom-state';
import Constants from 'utils/Constants';
import Button from 'shared/components/form-input/button/Button';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import useRouter from 'hooks/use-router';
import FormRadio from 'shared/components/custom-form/FormRadio';
import FormEditor from 'shared/components/custom-form/FormEditor';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxLabel from '../../box/BoxLabel';
import BoxRow from '../../box/BoxRow';
import FormGroup from '../../form-search/FormGroup';
import FormItem from '../../form-search/FormItem';

function CreateFAQPopup(props) {
    const { showVisible, payload } = props;
    const methods = useForm({
        defaultValues: {
            status: Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE
        }
    });
    const { trans, t } = useTrans();
    const dispatch = useDispatch();
    const router = useRouter()
    const [optionLanguages, setOptionLanguages] = useCustomState([]);
    const [optionCategory, setOptionCategory] = useCustomState([]);
    const { faqCategory, loadingAddFaq } = useSelector(state => state.faq);

    const onSubmit = (_data) => {
        dispatch({
            type: FaqAction.CREATE_FAQ_WITH_CATEGORY,
            payload: {
                ..._data,
                status: _data?.status,
                sort: 1 // DEFINE
            },
            callback: {
                success: () => {
                    showVisible(false);
                    methods.reset({});
                },
                failed: () => {
                    // SHOW MESSAGE ERROR
                }
            },
            paramsUrl: { ...router.getAll() }
        })
    }

    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: t(`faq_management.lang.${Constants.T001[key].NAME}`),
            })
        });
        setOptionLanguages(data)
    }, [])

    useEffect(() => {
        // let data = [];
        // if (faqCategory) {
        //     [...faqCategory?.records].forEach(value => {
        //         data.push({
        //             key: value?.id,
        //             value: value?.id,
        //             label: value?.categoryName,
        //         })
        //     });
        // }
        // setOptionCategory(data)
    }, [faqCategory])

    const OptionRelease = [
        {
            value: Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE,
            key: Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE,
            label: t("Holon_Template.valid")
        },
        {
            value: Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE,
            key: Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE,
            label: t("Holon_Template.invalid")
        },
    ]

    useEffect(() => {
        return () => {
            setOptionCategory([])
            setOptionLanguages([])
        }
    }, [])
    

    return (
        <BoxContentPopup width='760px' borderRadius="20px 0px 0px 20px" >
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        showVisible={showVisible}
                        title={payload?.title}
                        actionSectionBottom={<Button loading={loadingAddFaq} type='submit' title={trans("faq_management.add")} />}
                    >
                        <div className={classes['Wrap_ContentPopup']}>
                            <Box>
                                <BoxBody style={{
                                    borderRadius: 10
                                }}>
                                    <FormGroup>
                                        <BoxRow style={{ flex: 1 }}>
                                            <BoxLabel minWidth="70px" width="70px" require>
                                                {trans("faq_management.lang_choice")}
                                            </BoxLabel>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName='language'
                                                validationFE
                                                isRegexLabel={trans("faq_management.lang_choice")}
                                                placeholder={trans("faq_management.lang_choice")}
                                                options={optionLanguages}
                                                onChangeSelect={e => {
                                                    methods.setValue("categoryId", null);
                                                    setOptionCategory([])
                                                    dispatch({
                                                        type: FaqAction.GET_FAQ_CATEGORIES_WITH_LANGUAGE_POPUP,
                                                        payload: {
                                                            isAll: true,
                                                            [Constants.QueryParams.LANGUAGE.VALUE]: e
                                                        },
                                                        callback: (data) => {
                                                            setOptionCategory(data?.records?.map(x => {
                                                                return {
                                                                    label: x?.categoryName,
                                                                    value: x?.id,
                                                                    key: x?.id
                                                                }
                                                            }));
                                                        }
                                                    })
                                                }}
                                            />
                                        </BoxRow>
                                        <BoxRow style={{ flex: 1 }}>
                                            <BoxLabel minWidth="70px" width="70px" require>
                                                {trans("faq_management.category")}
                                            </BoxLabel>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName='categoryId'
                                                validationFE
                                                textTrans="faq_management.field_name"
                                                placeholder={trans("category_name")}
                                                options={optionCategory}
                                            />
                                        </BoxRow>
                                    </FormGroup>
                                    <BoxRow>
                                        <BoxLabel minWidth="70px" width="70px" require>
                                            {trans("faq_management.publish_setting")}
                                        </BoxLabel>
                                        <FormRadio
                                            fieldName="status"
                                            validationFE
                                            options={OptionRelease}
                                            textTrans="faq_management.field_name"
                                        />
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="70px" width="70px" require>
                                            {trans("question")}
                                        </BoxLabel>
                                        <FormTextArea
                                            validate={[Validator.required,Validator.maxLength(1000)]}
                                            fieldName='question'
                                            validationFE
                                            placeholder={trans("question")}
                                            textTrans="faq_management.field_name"
                                        />
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="70px" width="70px" require>
                                            {trans("answer")}
                                        </BoxLabel>
                                        <FormEditor
                                            validate={[Validator.required,Validator.maxLength(1000)]}
                                            fieldName='answer'
                                            validationFE
                                            placeholder={trans("answer")}
                                            height='150px'
                                            textTrans="faq_management.field_name"
                                        />
                                    </BoxRow>
                                </BoxBody>
                            </Box>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default CreateFAQPopup