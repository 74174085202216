import useRouter from 'hooks/use-router';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PolicySettingAction from 'redux/policy-setting/action';
import RouterPath from 'router/RouterPath';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import TextLabel from 'shared/components/common/form-search/TextLabel';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormInput from 'shared/components/custom-form/FormInput';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import Validator from 'utils/Validator';
import classes from './PolicySetting.module.scss';
import FormEditor from 'shared/components/custom-form/FormEditor';
import useTrans from 'hooks/use-trans';
import { showPopupNotification } from 'utils/Utils';

function UpdatePolicySettingContainer(props) {
    const { lang, type } = props;
    const defaultValues = {};
    const methods = useForm({
        defaultValues: defaultValues,
    });

    const router = useRouter();
    const { t } = useTrans()
    const dispatch = useDispatch();
    const { data, loadingUpdatePolicy, loadingGetPolicy } = useSelector((state) => state.policySetting);

    useEffect(() => {
        if (lang && type) {
            dispatch({
                type: PolicySettingAction.GET_POLICY_SETTING,
                payload: {
                    lang,
                    key: type,
                },
            });
        }
    }, [lang, type]);

    useEffect(() => {
        if (data) {
            methods.setValue('Title', data?.title);
            methods.setValue('Content', data?.content);
            methods.setValue('Key', type);
            methods.setValue('Lang', type);
        }
    }, [data, type, type]);

    const onSubmit = (_data) => {
        showPopupNotification({
            typePopup: 'confirm',
            title: t("E0042"),
            callback: () => {
                dispatch({
                    type: PolicySettingAction.UPDATE_POLICY_SETTING,
                    payload: {
                        lang: lang,
                        key: type,
                        content: _data?.Content,
                        title: _data?.Title,
                    },
                    callback: {
                        success: () => {
                            router.push({
                                pathname: RouterPath.PORTAL_POLICY,
                                params: {
                                    [Constants.QueryParams.LANGUAGE.VALUE]: data?.lang,
                                    [Constants.QueryParams.TYPE_SEARCH.VALUE]:
                                        data?.key,
                                },
                            });
                        },
                    },
                });
            }
        })
    };
    return (
        <div className={classes['PolicySetting']}>
            <div className={classes['Update-PolicySetting-FormSearch']}>
                <CustomFormProvider {...methods}>
                    <form
                        className="quick-submit"
                        onSubmit={methods.handleSubmit(onSubmit)}
                    >
                        <FormSearch>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel title={t('faq_management.title')} />
                                    <FormInput validate={[Validator.required]} fieldName="Title" />
                                </FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel title={t("faq_management.content")} />
                                    <FormEditor validate={[Validator.required]} fieldName="Content" />
                                </FormItem>
                            </FormGroup>
                        </FormSearch>
                        <div className={classes['action-submit']}>
                            <Button disabled={loadingGetPolicy} loading={loadingUpdatePolicy} type="submit" title={t("save")} />
                        </div>
                    </form>
                </CustomFormProvider>
            </div>
        </div>
    );
}

export default UpdatePolicySettingContainer;
