import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import HexServiceContainer from 'shared/container/hex-service/HexServiceContainer'

function HexsevicePage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t('faq_management.hex_services.hex_service_setting');
    }, []);
    return (
        <HexServiceContainer />
    )
}

export default HexsevicePage