const actionHolonTemplate = {
    GET_HOLON_TEMPLATE_MANAGEMENT: "GET_HOLON_TEMPLATE_MANAGEMENT",
    GET_HOLON_TEMPLATE_MANAGEMENT_SUCCESS: "GET_HOLON_TEMPLATE_MANAGEMENT_SUCCESS",
    GET_HOLON_TEMPLATE_MANAGEMENT_FAILED: "GET_HOLON_TEMPLATE_MANAGEMENT_FAILED",

    GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT: "GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT",
    GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT_SUCCESS: "GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT_SUCCESS",
    GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT_FAILED: "GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT_FAILED",

    CREATE_HOLON_TEMPLATE_MANAGEMENT: "CREATE_HOLON_TEMPLATE_MANAGEMENT",
    CREATE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS: "CREATE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS",
    CREATE_HOLON_TEMPLATE_MANAGEMENT_FAILED: "CREATE_HOLON_TEMPLATE_MANAGEMENT_FAILED",

    UPDATE_HOLON_TEMPLATE_MANAGEMENT: "UPDATE_HOLON_TEMPLATE_MANAGEMENT",
    UPDATE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS: "UPDATE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS",
    UPDATE_HOLON_TEMPLATE_MANAGEMENT_FAILED: "UPDATE_HOLON_TEMPLATE_MANAGEMENT_FAILED",

    GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT: "GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT",
    GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_SUCCESS: "GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_SUCCESS",
    GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_FAILED: "GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_FAILED",
    
    GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP: "GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP",
    GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP_SUCCESS: "GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP_SUCCESS",
    GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP_FAILED: "GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP_FAILED",

    DELETE_HOLON_TEMPLATE_MANAGEMENT: "DELETE_HOLON_TEMPLATE_MANAGEMENT",
    DELETE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS: "DELETE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS",
    DELETE_HOLON_TEMPLATE_MANAGEMENT_FAILED: "DELETE_HOLON_TEMPLATE_MANAGEMENT_FAILED",

    GET_MASTER_CATEGORY_HOLON_TEMPLATE: "GET_MASTER_CATEGORY_HOLON_TEMPLATE",
    GET_MASTER_CATEGORY_HOLON_TEMPLATE_SUCCESS: "GET_MASTER_CATEGORY_HOLON_TEMPLATE_SUCCESS",
    GET_MASTER_CATEGORY_HOLON_TEMPLATE_FAILED: "GET_MASTER_CATEGORY_HOLON_TEMPLATE_FAILED",

    GET_MASTER_HOLON_TEMPLATE: "GET_MASTER_HOLON_TEMPLATE",
    GET_MASTER_HOLON_TEMPLATE_SUCCESS: "GET_MASTER_HOLON_TEMPLATE_SUCCESS",
    GET_MASTER_HOLON_TEMPLATE_FAILED: "GET_MASTER_HOLON_TEMPLATE_FAILED",

    CLONE_HOLON_TEMPLATE: "CLONE_HOLON_TEMPLATE",

    GET_ALL_HOLON_TEMPLATE: "GET_ALL_HOLON_TEMPLATE",
    GET_ALL_HOLON_TEMPLATE_SUCCESS: "GET_ALL_HOLON_TEMPLATE_SUCCESS",
    GET_ALL_HOLON_TEMPLATE_FAILED: "GET_ALL_HOLON_TEMPLATE_FAILED",

    GET_HOLON_TEMPLATE_MANAGEMENT_POPUP: "GET_HOLON_TEMPLATE_MANAGEMENT_POPUP",
}

export default actionHolonTemplate;