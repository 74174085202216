import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import RequestEstimationContainer from 'shared/container/request-estimation/RequestEstimationContainer';

function RequestEstimationPage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t('request_estimation.title');
    }, []);
    return (
        <RequestEstimationContainer />
    )
}

export default RequestEstimationPage