import { all, call, fork, takeLatest, put } from "redux-saga/effects";
import Actions from "./action";
import Factories from "./factory";
import Cookies from 'js-cookie';
import { getToastError } from "utils/Utils";
import actionCargo from "redux/cargo/action";
import { actionsHolonInstance } from "redux/holon-instance/action";

function* resetPassword() {
    yield takeLatest(Actions.RESET_PASSWORD, function* (payload) {
        const { formData, callback, fallback } = payload;
        try {
            yield call(() => Factories.resetPassword(formData));
            callback && callback()
        } catch (res) {
            fallback && fallback()
            const { error } = res?.response;
            getToastError(error?.detail)
        }
    });
}

function* forgotIDPassword() {
    yield takeLatest(Actions.FORGOT_ID_PASSWORD, function* (payload) {
        const { formData, callback, fallback } = payload;
        try {
            const res = yield call(() => Factories.forgotIDPassword(formData));
            if (res?.data) {
                callback && callback()
            }
            else {
                fallback && fallback();
                yield put({
                    type: Actions.FORGOT_ID_PASSWORD_FAILURE,
                    payload: res?.error?.detail
                })
            }
        } catch (res) {
            const { data: { error } } = res?.response;
            fallback && fallback()
            yield put({
                type: Actions.FORGOT_ID_PASSWORD_FAILURE,
                payload: error?.detail,
            })
        }
    });
}

function* login() {
    yield takeLatest(Actions.LOGIN_REQUEST, function* (payload) {
        const { formData, callback, fallback } = payload;
        try {
            const { data } = yield call(() => Factories.login(formData));
            const expiredAt = new Date(data?.expiredAt);

            Cookies.set('accessToken', data.accessToken, { expires: expiredAt });
            Cookies.set('refreshToken', data.refreshToken, { expires: 30 });
            Cookies.set('expiredAt', expiredAt);

            callback && callback();

            yield put({
                type: actionCargo.GET_WARE_HOUSE_DEFAULT
            })

            yield put({
                type: actionsHolonInstance.GET_USER_IN_COMPANY
            })
            
        } catch (res) {
            const { data: { error } } = res?.response;
            fallback && fallback(error?.detail)
            yield put({
                type: Actions.LOGIN_FAILURE,
                payload: error?.detail,
            })
        }
    });
}

function* logout() {
    yield takeLatest(Actions.LOGOUT, function* (payload) {
        try {
            Cookies.remove('accessToken');
            Cookies.remove('refreshToken');
            Cookies.remove('expiredAt');
            if (payload?.callback?.success) {
                payload?.callback?.success()
            }
            yield put({
                type: Actions.LOGOUT_SUCCESS,
            })
        } catch (error) {
            getToastError(error?.detail)
        }
    });
}


function* register() {
    yield takeLatest(Actions.REGISTER, function* (payload) {
        console.log(payload);
        const { formData, callback, fallback } = payload;
        try {
            const response = yield call(() => Factories.register(formData));
            if (response?.data) {
                callback && callback();
            }
            if (response?.error) {
                if (fallback) {
                    fallback(response?.error?.detail)
                }
            }
        } catch (res) {
            console.log("Error on register");
        }
    });
}

function* inquiry() {
    yield takeLatest(Actions.INQUIRY, function* (payload) {
        const { formData, callback, fallback } = payload;
        try {
            yield call(() => Factories.inquiry(formData));
            callback && callback()
        } catch (res) {
            fallback && fallback()
            const { data: { error } } = res?.response;
            getToastError(error?.detail)
        }
    });
}

function* checkTokenExpiration() {
    yield takeLatest(Actions.CHECK_TOKEN_EXPIRATION, function* (payload) {
        const { formData, callback, fallback } = payload;
        try {
            yield call(() => Factories.validateToken(formData));
            callback && callback();
        } catch (res) {
            fallback && fallback();
            const { data: { error } } = res?.response;
            getToastError(error?.detail)
        }
    });
}

function* getUserInfo() {
    yield takeLatest(Actions.GET_ACCOUNT, function* () {
        try {
            const response = yield call(() => Factories.getUserInfo());
            if (response?.data) {
                const user_id = response.data?.id;
                const hex_id = response?.data?.attributes?.find(attribute => attribute.name == 'hex_id')?.value;
                const status_user = response?.data?.attributes?.find(attribute => attribute.name == 'status')?.value;
                const account = response?.data?.connections?.find(connection => connection?.type == 'account')?.holons?.[0];
                const avatar = account?.attributes?.find(attribute => attribute.name == 'avatar')?.value;
                let first_name = account?.attributes?.find(attribute => attribute.name == 'first_name')?.value;
                let last_name = account?.attributes?.find(attribute => attribute.name == 'last_name')?.value;

                if (first_name == undefined || first_name == null) {
                    first_name = ''
                }

                if (last_name == undefined || last_name == null) {
                    last_name = ''
                }

                yield put({
                    type: Actions.GET_ACCOUNT_SUCCESS,
                    payload: {
                        user: response?.data,
                        user_id: user_id,
                        hex_id: hex_id,
                        status_user: status_user,
                        avatar_user: avatar,
                        full_name_user: `${last_name} ${first_name}`,
                    }
                })
            }
            else {
                yield put({
                    type: Actions.GET_ACCOUNT_FAILURE,
                })
            }
        }
        catch (e) {
            yield put({
                type: Actions.GET_ACCOUNT_FAILURE,
            })
        }
    });
}

function* uploadImage() {
    yield takeLatest(Actions.UPLOAD_IMAGE, function* (payload) {
        try {
            const response = yield call(() => Factories.uploadImage(payload?.payload));

            if (response?.data) {
                if (payload?.callback) {
                    payload?.callback(response?.data);
                }
            } else {
                payload?.failed && payload?.failed()
            }
        }
        catch (e) {
            payload?.failed && payload?.failed()
        }
    });
}

function* uploadImageCommon() {
    yield takeLatest(Actions.UPLOAD_IMAGE_COMMON, function* (payload) {
        try {
            const response = yield call(() => Factories.uploadImageCommon(payload?.payload));

            if (response?.data) {
                if (payload?.callback) {
                    payload?.callback(response?.data);
                }
            } else {
                payload?.failed && payload?.failed()
            }
        }
        catch (e) {
            payload?.failed && payload?.failed()
        }
    });
}

function* updateUserInfo() {
    yield takeLatest(Actions.UPDATE_ACCOUNT, function* (payload) {
        try {
            const response = yield call(() => Factories.updateUserInfo(payload?.payload));

            if (response?.data) {
                if (payload?.callback?.success) {
                    payload?.callback?.success()
                }
                yield put({
                    type: Actions.UPDATE_ACCOUNT_SUCCESS,
                    payload: response?.data
                })
            }
            else {
                if (payload?.callback?.failed) {
                    payload?.callback?.failed()
                }
                yield put({
                    type: Actions.UPDATE_ACCOUNT_FAILURE,
                    payload: response?.error?.detail,
                })
            }
        }
        catch (e) {
            yield put({
                type: Actions.UPDATE_ACCOUNT_FAILURE,
            })
        }
    })
}

function* getUserStatusByhexId() {
    yield takeLatest(Actions.GET_USER_STATUS_BY_HEX_ID, function* (payload) {
        try {
            const response = yield call(() => Factories.getUserStatusByHexId(payload?.payload));

            yield payload?.callback && payload?.callback(response?.data);
        }
        catch (e) {
        }
    })
}

function* updateAlarmUser() {
    yield takeLatest(Actions.UPDATE_ALARM_ACCOUNT, function* (payload) {
        try {
            const response = yield call(() => Factories.updateAlarmUser(payload?.payload));

            if (response?.data) {
                if (payload?.callback?.success) {
                    payload?.callback?.success()
                }
                yield put({
                    type: Actions.UPDATE_ALARM_ACCOUNT_SUCCESS,
                    payload: response?.data
                })
            }
            else {
                if (payload?.callback?.failed) {
                    payload?.callback?.failed()
                }
                yield put({
                    type: Actions.UPDATE_ALARM_ACCOUNT_FAILURE,
                    payload: response?.error?.detail,
                })
            }
        }
        catch (e) {
            yield put({
                type: Actions.UPDATE_ALARM_ACCOUNT_FAILURE,
            })
        }
    })
}

function* refreshToken() {
    yield takeLatest(Actions.REQUEST_REFRESH_TOKEN, function* () {
        try {
            const { data } = yield call(() => Factories.refreshToken());
            const expiredAt = new Date(data?.expiredAt);

            Cookies.set('accessToken', data.accessToken, { expires: expiredAt });
            Cookies.set('refreshToken', data.refreshToken, { expires: 30 });
            Cookies.set('expiredToken', expiredAt);
        } catch (res) {
            const { data: { error } } = res?.response;
            getToastError(error?.detail)
        }
    });
}

function* getSaleList() {
    yield takeLatest(Actions.GET_SALE_LIST, function* (payload) {
        try {
            const response = yield call(() =>
                Factories.getSaleList(payload)
            );

            yield put({
                type: Actions.GET_SALE_LIST_SUCCESS,
                payload: {
                    records: response,
                    limit: 0,
                    page: 1,
                    total: 0,
                    totalPage: 1,
                },
            });
        } catch (res) {
            yield put({
                type: Actions.GET_SALE_LIST_SUCCESS,
                payload: [],
            });
        }
    });
}

function* getManagerList() {
    yield takeLatest(Actions.GET_MANAGER_LIST, function* (payload) {
        try {
            const response = yield call(() =>
                Factories.getManagerList(payload)
            );

            yield put({
                type: Actions.GET_MANAGER_LIST_SUCCESS,
                payload: {
                    records: response,
                    limit: 0,
                    page: 1,
                    total: 0,
                    totalPage: 1,
                },
            });
        } catch (res) {
            yield put({
                type: Actions.GET_ACCOUNT_FAILURE,
                payload: [],
            });
        }
    });
}

function* getPositionsOfBusinessCustomer() {
    yield takeLatest(Actions.GET_POSITIONS_OF_BUSINESS_CUSTOMER, function* (payload) {
        try {
            const response = yield call(() => Factories.getPositionsOfBusinessCustomer(payload?.payload));
            if (response?.data) {
                const data = response?.data?.map(x => {
                    x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                    return x;
                });
                yield put({
                    type: Actions.GET_POSITIONS_OF_BUSINESS_CUSTOMER_SUCCESS,
                    payload: data
                })
            }
            else {
                yield put({
                    type: Actions.GET_POSITIONS_OF_BUSINESS_CUSTOMER_FAILURE,
                })
            }
        }
        catch (e) {
            yield put({
                type: Actions.GET_POSITIONS_OF_BUSINESS_CUSTOMER_FAILURE,
            })
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(register),
        fork(login),
        fork(logout),
        fork(forgotIDPassword),
        fork(resetPassword),
        fork(getUserInfo),
        fork(updateUserInfo),
        fork(updateAlarmUser),
        fork(uploadImage),
        fork(getUserStatusByhexId),
        fork(inquiry),
        fork(refreshToken),
        fork(checkTokenExpiration),
        fork(getSaleList),
        fork(getManagerList),
        fork(getPositionsOfBusinessCustomer),
        fork(uploadImageCommon)
    ]);
}
