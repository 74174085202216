import React, { useEffect } from 'react';
import PolicySettingContainer from 'shared/container/policy-setting/PolicySettingContainer';

function PolicyPage() {
    useEffect(() => {
        document.title = 'ポリシー設定';
    }, [])
    
    return <PolicySettingContainer />
}

export default PolicyPage;
