import React from 'react'
import moment from 'moment';
import useTrans from 'hooks/use-trans';
import classes from './TableAlarm.module.scss';
import Constants from 'utils/Constants';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE } from 'utils/EventRegister';
import { useSelector } from 'react-redux';
import ButtonLink from '../form-input/button/ButtonLink';

export default function TableAlarm(props) {
    const { listAlarm, maxHeight = 270 } = props;
    const { holonTemplateMaster } = useSelector(state => state?.holonTemplate)
    const { t } = useTrans();
    let dataHeadCell = [
        {
            id: '日時',
            label: t("alarm.day"),
            field: 'alarmTime',
            component: (_props) => {
                return <div title={_props?.children} className={classes['content-table']} style={{
                    padding: '0px 10px 0px 0px',
                }}>{_props?.data?.alarmTime ? moment(_props?.data?.alarmTime).format(Constants.FORMAT_DATE_TIME.DATE) : ''}</div>
            },
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleHeader: {
                padding: '5px 10px'
            },
            styleBody: {
                padding: '0px 0px'
            },
        },
        {
            id: '対象',
            label: t("alarm.subject"),
            field: 'subject',
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleHeader: {
                padding: '5px 10px'
            },
            align: 'left',
            styleBody: {
                padding: '0px 0px'
            },
            component: (_props) => {
                return <div title={_props?.children} className={classes['content-table']} style={{
                    padding: '0px 10px 0px 0px',
                }}>{_props?.children}</div>
            },
        },
        {
            id: '内容',
            label: t("alarm.content"),
            field: 'messageText',
            minWidth: '200px',
            width: '200px',
            maxWidth: '200px',
            alignHeader: 'left',
            styleHeader: {
                padding: '5px 10px'
            },
            align: 'left',
            styleBody: {
                padding: '0px 0px'
            },
            component: (_props) => {
                return <div title={_props?.children} className={classes['content-table']} style={{
                    padding: '0px 10px 0px 0px',
                }}>{_props?.children}</div>
            },
        },
    ];

    const renderColor = (_props) => {
        if (_props?.alarmLevel == "1") {
            return "#FF9E9E"
        }
        if (_props?.alarmLevel == "3") {
            return "#FEFFD4"
        }
        if (_props?.alarmLevel == "5") {
            return "#FE461E"
        }
        if (_props?.alarmLevel == "0") {
            return "#D9D9D9"
        }

        return "#D9D9D9"
    }

    const handleOpenHolonInstance = (data) => {
        if (data?.alarmForHolonInstanceId && data?.alarmForHolonTemplateId) {
            const holonTemplate = holonTemplateMaster?.find(x => x?.id == data?.alarmForHolonTemplateId);
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE,
                newWindow: true,
                payload: {
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0,
                    },
                    title: holonTemplate?.name,
                    titleUpdatePopup: holonTemplate?.name,
                    holonInstanceId: data?.alarmForHolonInstanceId,
                    holonTemplateId: data?.alarmForHolonTemplateId,
                    permissionClone: true,
                    permissionUpdate: true,
                }
            })
        }
    }

    return (
        // <CustomTable maxHeight={height} loading={loadingGetAlarm} data={listAlarm} headCell={dataHeadCell} />
        <div className={classes['custom-table']}>
            <div className={classes['custom-table-header']}>
                <div className={classes[`item-row-detail`]}>
                    {t("Holon_Template.detail")}
                </div>
                {
                    dataHeadCell?.map((x, key) => {
                        return <div key={key} className={classes[`item-row-${key}`]}>
                            {x?.label}
                        </div>
                    })
                }
            </div>
            <div style={{ maxHeight: maxHeight }} className={classes['custom-table-body']}>
                {
                    listAlarm?.records?.map((body, key) => {
                        return <div style={{
                            backgroundColor: renderColor(body),
                        }} className={classes['item-row']} key={key}>
                            <div className={classes[`item-row-detail`]}>
                                {
                                    body?.alarmForHolonInstanceId && body?.alarmForHolonTemplateId && <ButtonLink onClick={() => handleOpenHolonInstance(body)} >{t("Holon_Template.detail")}</ButtonLink>
                                }
                            </div>
                            {
                                dataHeadCell?.map((head, _key) => {
                                    let Component = head?.component;
                                    return <div className={classes[`item-row-${_key}`]} key={_key}>
                                        {head?.component ? <Component data={body}
                                            dataId={body?.id}
                                            fieldId="fieldId"
                                            stt={_key} >
                                            {body[head?.field]}
                                        </Component> : body[head?.field]}
                                    </div>
                                })
                            }
                        </div>
                    })
                }
            </div>
        </div>
    )
}
