import TermOfUseAction from "./action";

const initialState = {
    loading: false,
    data: {}
}

const myReducer = (state = initialState, action) => {
    switch (action?.type) {
        case TermOfUseAction.GET_TERM_OF_USE_SUCCESS:
            return {
                ...state,
                data: action?.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default myReducer;