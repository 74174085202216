import useTrans from 'hooks/use-trans'
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Constants from 'utils/Constants';
import ProfileTab from "./tabs/ProfileTab";
import AccountTab from "./tabs/AccountTab";
import AlarmTab from "./tabs/AlarmTab";
import styles from "./AccountSettingsContainer.module.scss";
import OthersTab from './tabs/OthersTab';
import useRouter from 'hooks/use-router';

function AccountSettingsContainer() {
    const { t } = useTrans()
    const [valueTab, setValueTab] = useState();
    const [activeTab, setActiveTab] = useState();
    const { user } = useSelector(state => state.user);
    const router = useRouter();
    useEffect(() => {
        const value = router.get(Constants.QueryParams.TAB.VALUE)
        if (value) {
            const tab = tabs.find(tab => tab.value == value);
            setActiveTab(tab.id)
            setValueTab(value);
        } else {
            setValueTab('profile')
            setActiveTab(0)
        }
    }, [router.get(Constants.QueryParams.TAB.VALUE)])

    const tabs = [
        {
            id: 0,
            value: 'profile',
            title: t("account_settings.profile_tab"),
            content: <ProfileTab name="Profile" />,
            isActive: true
        },
        {
            id: 1,
            value: 'notification',
            title: t("account_settings.notification_tab"),
            content: <AlarmTab name="Alarm" />,
            isActive: true

        },
        {
            id: 2,
            value: 'account',
            title: t("account_settings.account_tab"),
            content: <AccountTab name="Account" />,
            isActive: true
        },
        {
            id: 3,
            value:'others',
            title: t("account_settings.others_tab"),
            content: <OthersTab name="Others" />,
            isActive: true
        },
    ];

    const handleTabClick = (index, value) => {
        // setActiveTab(index);
        if (value === valueTab) {
            return;
        }
        setValueTab(value);
        setActiveTab(index);
        router.replace({
            params: {
                [Constants.QueryParams.TAB.VALUE]: value
            }
        })
    };

    return (
        <div className={`generic-sec ${styles["account-setting-container"]}`}>
            <div className="">
                <div className={styles["account-setting-header"]}>
                    <div className={styles.header}>
                        <span>{t("account_settings.title")}</span>
                    </div>
                    <div className={styles["tab-list"]}>
                        {tabs.map((tab, index) => (
                            <div className={styles.tab} key={index}>
                                <button
                                    className={`${styles["tab-name"]} ${!tab.isActive && styles["inactive-tab"]}`}
                                    onClick={() => handleTabClick(index, tab.value)}
                                    disabled={!tab.isActive}
                                >
                                    <span>{tab.title}</span>
                                </button>
                                <div
                                    className={`${styles["underline-bar"]} ${index === activeTab && styles.active}`}
                                ></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles["account-setting-content"]}>
                {tabs[activeTab]?.content}
            </div>
        </div>
    );
}

export default AccountSettingsContainer;
