import React, { useEffect, useState } from 'react'
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import FormAsyncSelect from 'shared/components/custom-form/FormAsyncSelect';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import FormInput from 'shared/components/custom-form/FormInput';
import Validator from 'utils/Validator';
import classes from './Product.module.scss';
import { useDispatch } from 'react-redux';
import actionProduct from 'redux/product/action';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';

function FormData({ labelValue = '' , isCreate = false}) {
    const limitGenre = 100;
    const [genres, setGenres] = useState([])
    const [genresDefault, setGenresDefault] = useState([])
    const [genresFirst, setGenresFirst] = useState(null)
    const [loadingGenre, setLoadingGenre] = useState(false)
    const [valueSearch, setValueSearch] = useState(null)
    const { t } = useTrans();
    let currency = [
        {
            label: '￥',
            value: '￥',
            key: '￥'
        }
    ]

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: actionProduct.GET_CATEGORY,
            payload: {
                [Constants.QueryParams.LIMIT.VALUE]: limitGenre
            },
            callback: {
                success: (data) => {
                    setGenres(data?.records?.map(x => {
                        return {
                            key: x?.id,
                            value: x?.id,
                            label: x?.name,
                        }
                    }))
                    setGenresDefault(data?.records?.map(x => {
                        return {
                            key: x?.id,
                            value: x?.id,
                            label: x?.name,
                        }
                    }))
                    setGenresFirst(data)
                }
            }
        })
    }, [])

    const onMenuScrollToBottom = () => {
        if ((genresFirst?.total > genres?.length)) {
            setLoadingGenre(true);
            dispatch({
                type: actionProduct.GET_CATEGORY,
                payload: {
                    [Constants.QueryParams.LIMIT.VALUE]: limitGenre,
                    [Constants.QueryParams.OFFSET.VALUE]: genresFirst?.page + 1,
                    [Constants.QueryParams.KEYWORD.VALUE]: valueSearch,
                },
                callback: {
                    success: (data) => {
                        setGenresFirst(data)
                        setLoadingGenre(false);
                        setGenres([...genres, ...data?.records?.map(x => {
                            return {
                                key: x?.id,
                                value: x?.id,
                                label: x?.name,
                            }
                        })])
                    }
                }
            })
        }
    }

    const getTypesOptions = (keySearch, callback) => {
        // filterOption(keySearch, callback);
    }
    // const filterOption = useCallback(
    //     debounce(async (keySearch, callback) => {
    //         setValueSearch(keySearch);
    //         dispatch({
    //             type: actionProduct.GET_CATEGORY,
    //             payload: {
    //                 [Constants.QueryParams.LIMIT.VALUE]: limitGenre,
    //                 [Constants.QueryParams.OFFSET.VALUE]: 1,
    //                 [Constants.QueryParams.KEYWORD.VALUE]: keySearch,
    //             },
    //             callback: {
    //                 success: (data) => {
    //                     setGenresFirst(data)
    //                     callback(data?.records?.map(v => {
    //                         return {
    //                             key: v?.id,
    //                             value: v?.id,
    //                             label: v?.name
    //                         }
    //                     }))
    //                 }
    //             }
    //         })
    //     }, 500),
    //     [],);

    const onInputChange = (e) => {
        setValueSearch(e);
        setLoadingGenre(true)
        dispatch({
            type: actionProduct.GET_CATEGORY,
            payload: {
                [Constants.QueryParams.LIMIT.VALUE]: limitGenre,
                [Constants.QueryParams.KEYWORD.VALUE]: e,
            },
            callback: {
                success: (data) => {
                    setLoadingGenre(false)
                    setGenres(data?.records?.map(x => {
                        return {
                            key: x?.id,
                            value: x?.id,
                            label: x?.name,
                        }
                    }))
                    setGenresDefault(data?.records?.map(x => {
                        return {
                            key: x?.id,
                            value: x?.id,
                            label: x?.name,
                        }
                    }))
                    setGenresFirst(data)
                }
            }
        })
    }

    return (
        <div className={classes['created-holon-instance']}>
            <Box>
                <BoxBody style={{
                    borderRadius: 10
                }} className={classes['data-attributes-body']}>
                    <BoxRow>
                        <BoxLabel isRequired minWidth="140px">{t("product.gs1_code")}</BoxLabel>
                        <BoxContent>
                            <FormInput validationFE isRegexLabel={t("product.gs1_code")} placeholder={t("product.gs1_code")} validate={[Validator.required]} fieldName='jan_code' />
                        </BoxContent>
                    </BoxRow>
                    <BoxRow>
                        <BoxLabel isRequired minWidth="140px">{t("product.category")}</BoxLabel>
                        <BoxContent>
                            <FormAsyncSelect
                                onMenuScrollToBottom={onMenuScrollToBottom}
                                getTypesOptions={getTypesOptions}
                                // defaultOptions={genres ?? []}
                                isLoading={loadingGenre}
                                isMenuPortal placeholder={t("product.category")}
                                options={genres}
                                labelValue={labelValue}
                                validate={[Validator.required]}
                                fieldName='genre_id'

                                onInputChange={onInputChange}
                                validationFE
                                isRegexLabel={t("product.category")}
                            />
                        </BoxContent>
                    </BoxRow>
                    <BoxRow>
                        <BoxLabel isRequired minWidth="140px">{t("product.product_name")}</BoxLabel>
                        <BoxContent>
                            <FormInput validationFE isRegexLabel={t("product.product_name")} placeholder={t("product.product_name")} validate={[Validator.required]} fieldName='item_name' />
                        </BoxContent>
                    </BoxRow>
                    <BoxRow>
                        <BoxLabel isRequired minWidth="140px">{t("product.unit_price")}</BoxLabel>
                        <BoxContent>
                            <FormInput validationFE isRegexLabel={t("product.unit_price")} format={Constants.FormInputFormat.NUMBER.VALUE} placeholder={t("product.unit_price")} validate={[Validator.required]} fieldName='price' />
                        </BoxContent>
                    </BoxRow>
                    <BoxRow>
                        <BoxLabel isRequired minWidth="140px">{t("product.currency")}</BoxLabel>
                        <BoxContent>
                            <FormSelect options={currency} validationFE isRegexLabel={t("product.currency")} maxLength={5} placeholder={t("product.currency")} validate={[Validator.required]} fieldName='currency' />
                        </BoxContent>
                    </BoxRow>
                    <BoxRow>
                        <BoxLabel minWidth="140px">{t("product.origin")}</BoxLabel>
                        <BoxContent>
                            <FormInput isRegexLabel={t("product.origin")} placeholder={t("product.origin")} fieldName='origin' />
                        </BoxContent>
                    </BoxRow>
                    <BoxRow>
                        <BoxLabel minWidth="140px">{t("product.url")}</BoxLabel>
                        <BoxContent>
                            <FormTextArea isRegexLabel={t("product.url")} placeholder={t("product.url")} fieldName='product_url' />
                        </BoxContent>
                    </BoxRow>
                    <BoxRow>
                        <BoxLabel minWidth="140px">{t("product.brand_name")}</BoxLabel>
                        <BoxContent>
                            <FormInput isRegexLabel={t("product.brand_name")} placeholder={t("product.brand_name")} fieldName='brand_name' />
                        </BoxContent>
                    </BoxRow>
                    <BoxRow>
                        <BoxLabel minWidth="140px">{t("product.maker_name")}</BoxLabel>
                        <BoxContent>
                            <FormInput isRegexLabel={t("product.maker_name")} placeholder={t("product.maker_name")} fieldName='maker_name' />
                        </BoxContent>
                    </BoxRow>
                </BoxBody>
            </Box>
        </div>
    )
}

FormData.propTypes = {}

export default FormData
