import React, { useEffect, useState } from 'react'
import styles from './EstimationContainer.module.scss'
import Actions from 'redux/holon/action';
import Constants from 'utils/Constants';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import useTrans from 'hooks/use-trans';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_TEMPLATE_SELECTION_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE } from 'utils/EventRegister';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import { useDispatch } from 'react-redux';
import useRouter from 'hooks/use-router';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';

const EstimationContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTrans()
    const [estimationTemplate, setEstimationTemplate] = useState(null);
    const router = useRouter();
    const [templateId, setTemplateId] = useState(router.get(Constants.QueryParams.CREATE_BY_TEMPLATE_ID.VALUE));

    useEffect(() => {
        const templateId = router.get(Constants.QueryParams.CREATE_BY_TEMPLATE_ID.VALUE);
        dispatch({
            type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.ESTIMATION,
            callback: (data) => {
                setEstimationTemplate(data?.toString())
                data?.includes(templateId) && handleAddInstance(templateId)
            }
        })
    }, []);

    useEffect(() => {
        setTemplateId(router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE))
    }, [router.get(Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE)]);

    const handleOpenEstimationSelectionPopup = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_TEMPLATE_SELECTION_POPUP,
            payload: {
                showHeader: false,
                title: t("estimation.popup_select_title"),
                holonMasterCode: Constants.HOLON_MASTER_CODE.ESTIMATION,
                titleCreatePopup: t("estimation.popup_create_title"),
                listTemplate: estimationTemplate
            }
        })
    }

    const handleClickAddButton = () => {
        return <ButtonAdd size='large' onClick={handleOpenEstimationSelectionPopup} color='yellow' startIcon={<IconPlusWhite />} />
    }

    const handleAddInstance = (templateId) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            payload: {
                title: t("estimation.popup_create_title"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                templateId: templateId,
                callback: () => {
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE,
                        payload: {
                            ...router.getAll(),
                            [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: templateId
                        }
                    });
                }
            }
        })
    }

    return (
        <div className={`${styles['main-container']}`}>
            <div className={styles['holon-instance']} >
                {estimationTemplate &&
                    <HolonInstance
                        isUseAddFunction={false}
                        ActionSection={handleClickAddButton()}
                        title={t("estimation.title")}
                        template_id={estimationTemplate || ''}
                        titleDetailPopup={t("estimation.popup_detail_title")}
                        titleCreatePopup={t("estimation.popup_create_title")}
                        titleUpdatePopup={t("estimation.popup_update_title")}
                        listTemplateCreation={estimationTemplate?.split(",")?.map(x => ({id: x}))}
                    />}
            </div>
        </div>
    )
}

export default EstimationContainer