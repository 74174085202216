import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionBPFare from "./action";
import factory from "./factory";

function* getBPFareUnitPriceByCodes() {
    yield takeEvery(actionBPFare.GET_BP_FARE_BY_UNIT_PRICE_CODES, function* (payload) {
        try {
            const response = yield call(() => factory.getBPFareUnitPriceByCodes(payload.payload));
            yield payload?.callback?.success && payload?.callback?.success(response);
        } catch (error) {

        }
    })
}

function* updateBPFareUnitPriceByCodes() {
    yield takeEvery(actionBPFare.UPDATE_BP_FARE_BY_UNIT_PRICE_CODES, function* (payload) {
        try {
            const response = yield call(() => factory.updateBPFareUnitPriceByCodes(payload.payload));
            yield payload?.callback?.success && payload?.callback?.success(response);
        } catch (error) {

        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getBPFareUnitPriceByCodes),
        fork(updateBPFareUnitPriceByCodes)
    ]);
}