import React, { useEffect, useState } from 'react'
import styles from './ContractContainer.module.scss'
import Actions from 'redux/holon/action';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import actionHolon from 'redux/holon/action';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import actionsBlInstance from 'redux/bl-instance/action';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE } from 'utils/EventRegister';
import { useDispatch } from 'react-redux';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';
import { useSelector } from 'react-redux';
import useRouter from 'hooks/use-router';

const ContractContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTrans()
    const { blCompany } = useSelector(state => state.blInstance);
    const [templates, setTemplates] = useState([]);
    const router = useRouter();

    useEffect(() => {
        dispatch({
            type: Actions.GET_TEMPLATE_IDS_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.CONTRACT,
            callback: (data) => {
                setTemplates(data)
            }
        })

        dispatch({
            type: actionHolon.GET_HOLON_MASTER_BY_CODE,
            code: Constants.HOLON_MASTER_CODE.BL_INSTANCE,
            callback: (holonMaster) => {
                dispatch({
                    type: actionsBlInstance.GET_BL_COMPANY,
                    payload: holonMaster?.data[0],
                })
            }
        })
    }, []);

    const handleShowPopupAddNewInstance = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                dataPreset: {
                    templateId: templates,
                    data: {
                        attributes: {},
                        connections: {
                            [Constants.QueryParams.CONNECTION_CONTRACT_MASTER.VALUE]: [{ value: blCompany.id, name: blCompany.name }]
                        }
                    }
                },
                callback: () => {
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE,
                        payload: {
                            ...router.getAll(),
                            [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: templates?.[0]
                        }
                    });
                },
                title: t("contract.popup_create_title")
            }
        })
    }

    const handleClickAddButton = () => {
        return <ButtonAdd size='large' onClick={handleShowPopupAddNewInstance} color='yellow' startIcon={<IconPlusWhite />} />
    }

    return (
        <div className={`${styles['main-container']}`}>
            <div className={styles['holon-instance']} >
                {templates?.[0] &&
                    <HolonInstance
                        title={t("contract.title")}
                        isUseAddFunction={false}
                        ActionSection={handleClickAddButton()}
                        holonTemplateId={templates?.toString() || ''}
                        titleDetailPopup={t("contract.popup_detail_title")}
                        titleCreatePopup={t("contract.popup_create_title")}
                        titleUpdatePopup={t("contract.popup_update_title")}
                    />}
            </div>
        </div>
    )
}

export default ContractContainer