import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import classes from "./UploadAvatar.module.scss";
import _ from "lodash";
import IconClose from "assets/icon/IconClose";
import IconUpload from "assets/icon/IconUpload";
import IconCircleError from "assets/icon/IconCircleError";
import { useDispatch } from "react-redux";
import Action from 'redux/user/action';
import Loader from "shared/components/common/loader/Loader";
import Loading from "../button/loading/Loading";
import ErrorMessage from "../error-message/ErrorMessage";
import profile from 'assets/img/profile.png';
import EventRegister, { 
    EVENT_SHOW_POPUP, 
    EVENT_SHOW_POPUP_CONFIRM_DELETE,
} from 'utils/EventRegister';
import useTrans from "hooks/use-trans";
import Image from "shared/components/common/image/Image";


const UploadAvatar = (props) => {
    const {
        name,
        disabled = false,
        value,
        refInput,
        onChange,
        placeholder,
        onFocus,
        style,
        maxLength,
        onBlur,
        errors,
        maximumUpload = 100,
    } = props;
    const [imageUrl, setImageUrl] = useState();
    const [loadingUpload, setLoadingUpload] = React.useState(false);
    const refImport = useRef();
    const { trans } = useTrans();
    const dispatch = useDispatch();

    const onChangeUpload = (data) => {
        setLoadingUpload(true)
        setImageUrl(URL.createObjectURL(data));
        dispatch({
            type: Action.UPLOAD_IMAGE,
            payload: data,
            callback: (url) => {
                setLoadingUpload(false)
                onChange(url);
                setImageUrl(url);
            }
        })
    };
    const onChangeImport = (e) => {
        if (e.target.files?.length == 0) {
            return;
        }
        if (e.target.files) {
            onChangeUpload(e.target.files[0]);
        }
    };

    useEffect(() => {
        if (!value) {
            refInput ? refInput : refImport.current.value = null;
            setImageUrl(profile);
        }else{
            setImageUrl(null);
        }
    }, [value]);

    let showError = false;
    let error = {
        message: "",
    };

    let arr = name.split(".");
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    const handleRemoveAvatar = () => {
        // setImageUrl(profile)
        // onChange(profile)
        if (value != profile && imageUrl != profile) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                newWindow: true,
                type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
                payload: {
                    title: trans("Holon_Template.confirm_delete"),
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                    callback: () => {
                        setImageUrl(profile)
                        onChange(profile)
                    }
                }
            })
        }
    }

    return (
        <>
            <div className={classes.profile}>
                <div className={classes['profile-name']}>
                    <span>{trans('my_own_photo')}</span>
                </div>
                <div className="row">
                    <div className={`${classes['avatar-container']}`}>
                        <div className={classes['profile-img']}>
                            {/* <img src={imageUrl ? imageUrl : value ? value : null} alt="hugenerd" width="50" height="50" className="rounded-circle" style={{opacity: loadingUpload ? 0.3 : 1}} /> */}
                            <Image defaultImage={profile} src={imageUrl ? imageUrl : value ? value : null} alt="hugenerd" width="50" height="50" className="rounded-circle" style={{opacity: loadingUpload ? 0.3 : 1}} />
                            {
                                loadingUpload ? <div className={classes['Loading_Upload']}><Loading /></div> : <></>
                            }
                        </div>
                        <div className={classes['edit-img']}>
                            <div className={classes['upload-new-img']} >
                                <label style={{cursor: 'pointer'}} htmlFor="update-avatar">{trans('upload_new_photo')}</label>
                                <input
                                    name={name}
                                    disabled={disabled}
                                    ref={refInput ? refInput : refImport }
                                    type={"file"}
                                    onChange={(e) =>
                                        onChangeImport(e)
                                    }
                                    style={style}
                                    maxLength={maxLength}
                                    placeholder={placeholder}
                                    onBlur={onBlur}
                                    onFocus={onFocus}
                                    autoComplete="off"
                                    hidden
                                    id="update-avatar"
                                    multiple={false}
                                    accept="image/png, image/jpg, image/jpeg, image/bmp"
                                />
                            </div>
                            <div 
                                className={classes['delete-img']} 
                                onClick={handleRemoveAvatar}
                            >
                                <span style={{cursor: 'pointer'}}>{trans('delete_photo')}</span>
                            </div>
                        </div>
                    </div>
                    {showError ? <ErrorMessage message={error?.message} /> : <></>}
                </div>
            </div>
        </>
    );
};

export default UploadAvatar;
