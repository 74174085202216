import React from 'react'

function IconMinimize() {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.62627 1.52136C6.51568 1.52136 6.40655 1.53795 6.30205 1.57042C6.2343 1.59155 6.16837 1.61936 6.10599 1.6532C6.01371 1.70352 5.92924 1.76661 5.85468 1.84095C5.78034 1.91539 5.71712 2.00002 5.66693 2.09214C5.58062 2.25095 5.53499 2.43089 5.53499 2.61255V3.42564L5.5328 3.75745L5.5279 3.98461L1.86234 0.318984C1.4368 -0.106328 0.744555 -0.106328 0.319148 0.318984C-0.106383 0.744516 -0.106383 1.43689 0.319148 1.86217L2.47162 4.01464L3.35955 4.90586L3.98137 5.53489H2.61271C2.50212 5.53489 2.39312 5.55148 2.28837 5.58395C2.22074 5.60508 2.15484 5.63289 2.09246 5.6667C2.00015 5.71702 1.91546 5.78014 1.84112 5.85458C1.76677 5.92905 1.70355 6.01352 1.65337 6.10592C1.56705 6.26448 1.52143 6.44442 1.52155 6.62605C1.52155 6.77339 1.55037 6.91623 1.60737 7.05086C1.63471 7.11555 1.66865 7.17792 1.70809 7.23639C1.7868 7.35286 1.8858 7.45195 2.0024 7.53083C2.18309 7.65277 2.39399 7.71736 2.61274 7.71736H6.63655C6.92524 7.71736 7.19674 7.6048 7.40084 7.4007C7.60505 7.19661 7.71749 6.92511 7.71749 6.63639V2.61255C7.71743 2.01098 7.22806 1.52148 6.62627 1.52136Z" fill="#EBEBEB" />
                <path d="M15.6805 13.9719L13.5179 11.8093L12.6332 10.9213L12.0182 10.2992H13.3871C13.4975 10.2992 13.6067 10.2826 13.7113 10.2501C13.7789 10.229 13.8449 10.2012 13.9072 10.1673C13.9996 10.1173 14.0841 10.054 14.1586 9.9796C14.233 9.90513 14.2961 9.82066 14.3463 9.72838C14.4326 9.56957 14.4781 9.38963 14.4781 9.20801C14.4781 9.06066 14.4492 8.91794 14.3922 8.78319C14.365 8.71851 14.3312 8.65613 14.2918 8.59766C14.2129 8.48119 14.1138 8.3821 13.9973 8.30335C13.8166 8.18141 13.6056 8.11694 13.3871 8.11694H9.36316C9.07448 8.11694 8.80298 8.22938 8.59888 8.43347C8.39466 8.63757 8.28223 8.90894 8.28223 9.19776V13.2216C8.28223 13.8234 8.77173 14.3128 9.37341 14.3128C9.48401 14.3128 9.59313 14.2964 9.69763 14.2637C9.76526 14.2426 9.83129 14.2149 9.89366 14.1809C9.98598 14.1308 10.0706 14.0676 10.145 13.9932C10.2194 13.9188 10.2826 13.8341 10.3328 13.742C10.4191 13.5833 10.4647 13.4033 10.4646 13.2216L10.4647 12.4125L10.4669 12.0757L10.4718 11.8495L14.1374 15.5151C14.5627 15.9404 15.2551 15.9404 15.6805 15.5151C16.1059 15.0895 16.1059 14.3974 15.6805 13.9719Z" fill="#EBEBEB" />
            </svg>
        </>
    )
}

export default IconMinimize