import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import nextIcon from 'assets/img/next-icon.svg';
import FormInput from 'shared/components/common/form/home/FormInput'
import FormCheckbox from 'shared/components/common/form/checkbox/FormCheckbox'
import Validator from 'utils/Validator'
import useRouter from 'hooks/use-router';
import Action from 'redux/user/action';
import Actions from 'redux/app/action';
import useTrans from 'hooks/use-trans';
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POPUP_USER_REGISTER,
    EVENT_SHOW_POPUP_USER_FORGOT_ID_PASSWORD,
}
    from 'utils/EventRegister';
import RouterPath from 'router/RouterPath';
import styles from './UserLoginPopup.module.scss'
import Loading from 'shared/components/form-input/button/loading/Loading';
import Constants from 'utils/Constants';

const UserLoginPopup = (props) => {
    const { trans } = useTrans();
    const router = useRouter()
    const [isInputVisible, setInputVisible] = useState(false);
    const dispatch = useDispatch();
    const methods = useForm();
    const { register, handleSubmit, setError } = methods;
    const [isLoading, setIsLoading] = useState(false)
    const savedHexId = localStorage.getItem('savedHexId');
    const savedPassword = localStorage.getItem('savedPassword');

    const handleSubmitLoginForm = (data) => {
        data.save ? localStorage.setItem('savedHexId', data.hexId) : localStorage.removeItem('savedHexId');
        data.save ? localStorage.setItem('savedPassword', data.password) : localStorage.removeItem('savedPassword');
        setIsLoading(true);
        dispatch({
            type: Action.LOGIN_REQUEST,
            formData: data,
            callback: () => {
                dispatch({
                    type: Actions.GET_MENU,
                    callback: () => {
                        dispatch({
                            type: Action.GET_USER_STATUS_BY_HEX_ID,
                            payload: data['hexId'],
                            callback: (data) => {
                                setIsLoading(false);
                                props?.showVisible();
                                if (data && data != Constants.USER_STATUS.TEMPORARY.VALUE) {
                                    router.push({
                                        pathname: RouterPath.PORTAL_DASHBOARD
                                    })
                                } else {
                                    router.push({
                                        pathname: RouterPath.PORTAL_SETTING
                                    })
                                }
                            }
                        });
                    }
                });
                // router.push({
                //     pathname: "/portal"
                // })
            },
            fallback: (error) => {
                Object.keys(error).forEach((key) => {
                    setError(key, {
                        type: 'custom',
                        message: error[key]?.[0]
                    })
                })
                setIsLoading(false);
            }
        });
    }

    const onSubmit = (data) => {
        data.hexId ? setInputVisible(true) : setInputVisible(false);
        isInputVisible && handleSubmitLoginForm(data)
    }

    const handleInputChange = () => {
        isInputVisible && setInputVisible(false)
    };

    const handlePopupRegister = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_REGISTER,
            open: true,
            payload: {
                title: trans("Inquiry.sign_up"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
            }
        })
    }

    const handlePopupUserForgotIDPassword = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_FORGOT_ID_PASSWORD,
            open: true,
            payload: {
                title: trans("Forget_ID_Password.header_title")
            }
        })
    }

    return <>
        <div className={`modal-body ${styles['login-model-body']}`}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '500px', margin: 'auto' }}>
                    <div className={``}>
                        <label className={styles['login-form-label']}>{trans("hexId")}</label>
                        <div className={`${styles['input-container']} ${!isInputVisible && styles['form-input-icon']}`}>
                            <FormInput
                                defaultValue={savedHexId}
                                fieldName="hexId"
                                register={register}
                                placeholder={trans("hexId")}
                                callback={isInputVisible ? handleInputChange : null}
                                validate={[Validator.requiredLogin]}
                                height={"56px"}
                            />
                            {!isInputVisible &&
                                <div className={styles["svg-overlay"]}>
                                    <input type="image" src={nextIcon} alt="Submit" className={styles["svg-icon"]} />
                                </div>}
                        </div>
                    </div>
                    {isInputVisible &&
                        <div className="mb-3 mt-3">
                            <label className={styles['login-form-label']}>{trans("password")}</label>
                            <div className={`${styles['input-container']} ${isInputVisible && styles['form-input-icon']}`}>
                                <FormInput
                                    type="text"
                                    fieldName="password"
                                    register={register}
                                    placeholder={trans("password")}
                                    validate={[Validator.requiredLogin]}
                                    height={"56px"}
                                    isShowRevealPasswordButton={true}
                                    defaultValue={savedPassword}
                                />
                                {/* <div className={styles["svg-overlay"]}>
                                    <input type="image" src={nextIcon} alt="Submit" className={styles["svg-icon"]} />
                                </div> */}
                            </div>
                        </div>
                    }
                    <div className={styles['save-checkbox-container']}>
                        <FormCheckbox
                            label={trans("Login.checkbox_label_is_save_pass")}
                            register={register}
                            fieldName='save'
                            checked={savedHexId ? true : false}
                        />
                    </div>
                    {/* <div className={styles['save-password-checkbox-container']}>
                        <FormCheckbox
                            label={trans("Login.checkbox_label_is_save_pass")}
                            register={register}
                            fieldName='save_password'
                            checked={savedPassword ? true : false}
                        />
                    </div> */}
                    <center>
                        <button disabled={isLoading} type="submit" className={`${styles['login-btn']}`}>{isLoading ? <Loading /> : trans("Login.login_btn")}</button>
                    </center>
                </form>
            </FormProvider>
            <center>
                <div className={styles['forget-id-pw']}>
                    <span type="submit" onClick={handlePopupUserForgotIDPassword} className={styles['forgot-id-password']}>{trans("Login.forget_id_pw")}</span>
                </div>
            </center>
            <center>
                <div className={styles['login-description-link']}>
                    <span className={styles['login-description-link-label']}>{trans("Login.register_title")}</span>
                </div>
                <div className={styles['login-small-description']}>
                    <span className={styles['login-small-description-label']}>{trans("Login.register_description")}</span>
                </div>
                <button onClick={handlePopupRegister} className={`${styles['register-btn']}`}>{trans("Login.register_btn")}</button>
            </center>
        </div>
    </>
};

export default UserLoginPopup;