import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionAlarm from "./action";
import factory from "./factory";

function* getListAlarm() {
    yield takeEvery(actionAlarm.GET_ALARM_HOLON_SETTING, function* (payload) {
        try {
            const response = yield call(() => factory.getAlarmHolonTemplate(payload?.payload));
            yield put({
                type: actionAlarm.GET_ALARM_HOLON_SETTING_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* getListHolonTemplateWithCodeMaster() {
    yield takeEvery(actionAlarm.GET_HOLON_TEMPLATE_WITH_CODE_MASTER, function* (payload) {
        try {
            const response = yield call(() => factory.getMasterHolonTemplate());
            yield put({
                type: actionAlarm.GET_HOLON_TEMPLATE_WITH_CODE_MASTER_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* getOwnerCollectionHolonTemplate() {
    yield takeEvery(actionAlarm.GET_OWNER_COLLECTION_HOLON_TEMPLATE, function* (payload) {
        try {
            const response = yield call(() => factory.getOwnerCollectionHolonTemplate(payload?.payload));
            yield payload?.callback && payload?.callback(response);
        } catch (error) {

        }
    })
}
function* updateAlarm() {
    yield takeEvery(actionAlarm.UPDATE_ALARM_HOLON_SETTING, function* (payload) {
        try {
            const response = yield call(() => factory.updateAlarmHolonTemplate(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success()
                yield put({
                    type: actionAlarm.UPDATE_ALARM_HOLON_SETTING_SUCCESS
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
                yield put({
                    type: actionAlarm.UPDATE_ALARM_HOLON_SETTING_FAILED
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0])
            yield put({
                type: actionAlarm.UPDATE_ALARM_HOLON_SETTING_FAILED
            })
        }
    })
}
function* deletetAlarm() {
    yield takeEvery(actionAlarm.DELETE_ALARM_HOLON_SETTING, function* (payload) {
        try {
            const response = yield call(() => factory.deleteAlarmHolonTemplate(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success()
                yield put({
                    type: actionAlarm.DELETE_ALARM_HOLON_SETTING_SUCCESS
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
                yield put({
                    type: actionAlarm.DELETE_ALARM_HOLON_SETTING_FAILED
                })
            }

        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0])
            yield put({
                type: actionAlarm.DELETE_ALARM_HOLON_SETTING_FAILED
            })
        }
    })
}
function* createAlarm() {
    yield takeEvery(actionAlarm.CREATE_ALARM_HOLON_SETTING, function* (payload) {
        try {
            const response = yield call(() => factory.createAlarmHolonTemplate(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success()
                yield put({
                    type: actionAlarm.CREATE_ALARM_HOLON_SETTING_SUCCESS
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
                yield put({
                    type: actionAlarm.CREATE_ALARM_HOLON_SETTING_FAILED
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed(error?.error?.detail?.exception[0])
            yield put({
                type: actionAlarm.CREATE_ALARM_HOLON_SETTING_FAILED
            })
        }
    })
}

function* getAlarmDetail() {
    yield takeEvery(actionAlarm.GET_ALARM_HOLON_SETTING_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.getAlarmHolonTemplateDetail(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionAlarm.GET_ALARM_HOLON_SETTING_DETAIL_SUCCESS,
                    payload: response?.data
                })
            }
        } catch (error) {

        }
    })
}

function* getAlarmDetailPopup() {
    yield takeEvery(actionAlarm.GET_ALARM_HOLON_SETTING_DETAIL_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getAlarmHolonTemplateDetail(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback(response?.data)
            }
        } catch (error) {

        }
    })
}

function* getHolonTemplate() {
    yield takeEvery(actionAlarm.GET_HOLON_TEMPLATE_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplateDetail(payload?.payload));
            yield put({
                type: actionAlarm.GET_HOLON_TEMPLATE_DETAIL_SUCCESS,
                payload: {
                    holonTemplateDetail: response?.data,
                }
            })
            yield payload?.callback && payload?.callback()
        } catch (error) {

        }
    })
}

function* getMasterAlarm() {
    yield takeEvery(actionAlarm.GET_MASTER_ALARM, function* (payload) {
        try {
            const response = yield call(() => factory.getMasterAlarmHolonTemplate());
            yield put({
                type: actionAlarm.GET_MASTER_ALARM_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListAlarm),
        fork(createAlarm),
        fork(updateAlarm),
        fork(deletetAlarm),
        fork(getAlarmDetail),
        fork(getHolonTemplate),
        fork(getMasterAlarm),
        fork(getListHolonTemplateWithCodeMaster),
        fork(getOwnerCollectionHolonTemplate),
        fork(getAlarmDetailPopup)
    ])
}