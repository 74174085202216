import React from 'react';
import _ from 'lodash';
import classes from './CustomCheckBox.module.scss';
import ErrorMessage from '../error-message/ErrorMessage';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';

const CustomCheckBox = (props) => {
    const {
        onChange,
        ref,
        value,
        errors,
        disabled = false,
        name,
        isTooltip = false,
        label,
        inputRef,
        className,
        validationFE,
        isRegexLabel
    } = props;
    const onChangeCheckBox = (e, value) => {
        e.stopPropagation();
        onChange(value);
    };

    let showError = false;
    let error = {
        message: '',
    };
    
    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }
    return (
        <>
            <div
                className={`${classes['CustomCheckBox']} ${disabled ? classes['disabled'] : ''} CustomCheckBox ${className}`}
                ref={ref}
                onClick={(e) => !disabled && onChangeCheckBox(e, value ? false : true)}
            >
                <button
                    type="button"
                    ref={inputRef}
                    disabled={disabled}
                >
                    {value == true ? (
                        <IconChecked color="#0E525E" fontSize={16} />
                    ) : (
                        <IconCheck color="#0E525E" />
                    )}
                </button>
                <input type="checkbox" hidden />
                <p>{label}</p>
            </div>
            {/* {showError && !isTooltip ? (
                <div className={classes['CustomCheckBox_Error']}>
                    <ErrorMessage message={error?.message} />
                </div>
            ) : (
                <></>
            )} */}
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default CustomCheckBox;
