import React from 'react';
import PropTypes from 'prop-types';
import classes from './FormSearch.module.scss';

function TextLabel(props) {
    const { require = false } = props;
    return (
        <div className={`m-0 ${classes['TextLabel']} ${props.className}`}>
            {props.title ?? props.children}{' '}
            {require ? (
                <span
                    style={{
                        paddingLeft: 5,
                        color: '#FF2C00',
                    }}
                >
                    *
                </span>
            ) : (
                <></>
            )}
        </div>
    );
}

TextLabel.propTypes = {
    title: PropTypes.string,
    require: PropTypes.bool,
};
export default TextLabel;
