import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const factory = {
    getHolonTemplate: (payload) => {
        var url = '/rest/api/v1/management/holon-template';
        let params = {};
        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE]
        }
        if (payload[Constants.QueryParams.STATUS.VALUE] || payload[Constants.QueryParams.STATUS.VALUE] == "0") {
            params['status'] = payload[Constants.QueryParams.STATUS.VALUE]
        }
        if (payload[Constants.QueryParams.CATEGORY.VALUE]) {
            params['category'] = payload[Constants.QueryParams.CATEGORY.VALUE]
        }
        if (payload[Constants.QueryParams.CREATED_DATE_FROM.VALUE]) {
            params['fromDate'] = payload[Constants.QueryParams.CREATED_DATE_FROM.VALUE]
        }
        if (payload[Constants.QueryParams.CREATED_DATE_TO.VALUE]) {
            params['toDate'] = payload[Constants.QueryParams.CREATED_DATE_TO.VALUE]
        }
        if (payload?.isAll) {
            params['isAll'] = true;
        }
        if (payload[Constants.QueryParams.SORT.VALUE]) {
            params['sortColumn'] = payload[Constants.QueryParams.SORT.VALUE];
            params['sortType'] = payload[Constants.QueryParams.TYPE_SORT.VALUE];
        } else {
            params['sortColumn'] = "createdAt";
            params['sortType'] = "desc"
        }
        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE]
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }
        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE]
        } else {
            params['page'] = Constants.PAGINATION.OFFSET;
        }

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    getMasterHolonTemplate: (payload) => {
        var url = '/rest/api/v1/management/holon-template';
        let params = {
            isAll: true,
        };

        if(payload?.masterCode){
            params['masterCodes'] = payload?.masterCode
        }

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    createHolonTemplate: (payload) => {
        var url = '/rest/api/v1/management/holon-template';

        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    getDetailHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/holon-template/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    updateHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/holon-template`;

        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    cloneHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/holon-template/clone/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
        })
    },
    deleteHolonTemplate: (payload) => {
        var url = `/rest/api/v1/management/holon-template/${payload}`;

        return ApiOperation.request({
            url: url,
            method: 'DELETE',
        })
    },
    getMasterCategoryHolonTemplate: () => {
        let url = `/rest/api/v1/master/holon/category`
        return ApiOperation.request({
            url: url,
            method: "GET"
        })
    },
    geHolonTemplateMaster: () => {
        let url = `/rest/api/v1/management/holon-template/master`
        return ApiOperation.request({
            url: url,
            method: "GET"
        })
    }
}

export default factory;