import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import styles from './Form.module.scss';
import useTrans from 'hooks/use-trans';
import { useEffect, useState } from 'react';
import IconEye from 'assets/icon/IconEye';
import IconEyeOff from 'assets/icon/IconEyeOff';
import { useDispatch } from 'react-redux';
import { getFunction } from '../../Util';

function FormInput(props) {
    const { trans } = useTrans();
    const {
        defaultValue = '',
        fieldName,
        validate = [],
        placeholder = '',
        disabled = false,
        register,
        type = "text",
        callback = undefined,
        height = "43px",
        isShowRevealPasswordButton = false,
        field = ''
    } = props;

    const { formState: { errors }, control, setValue, getValues } = useFormContext();

    const [isShowPassword, setIsShowPassWord] = useState(false);
    const [initValue, setInitValue] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (defaultValue) {
            try {
                let json = JSON.parse(field?.defaultValue);
                if (!getValues(fieldName) || getValues(fieldName)?.trim().length === 0) {
                    setValue(fieldName, json["default"]);
                    setInitValue(json["default"]);
                }
                if (json["default"]) {
                    getFunction(json["trigger"], defaultValue, dispatch, setValue, getValues);
                }
            } catch (error) {
                setValue(fieldName, defaultValue);
                setInitValue(defaultValue);
            }
        }
    }, []);

    const showPassword = () => {
        setIsShowPassWord(!isShowPassword)
    } 

    return (
        <Controller
            control={control}
            name={fieldName}
            rules={{ validate: Validator.genValidate(validate, fieldName) }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                const onChangeValue = (e) => {
                    onChange(e.target.value);
                    callback && callback(e.target.value);
                    getFunction("onChange", defaultValue, dispatch, setValue, getValues)
                }
                const onBlurValue = (e) => {
                    onBlur(e.target.value);
                    getFunction("onBlur", defaultValue, dispatch, setValue, getValues);
                }
                return <div className={`w-100 ${styles['form-home-input-container']}`}>
                    <div className={`w-100 ${styles['inner-form-home-input-container']} ${errors[fieldName] ? 'error' : ''}`}>
                        <input
                            _props={{ inputRef: ref }}
                            {...register(fieldName, { value: value ?? "" })}
                            type={isShowRevealPasswordButton ? (isShowPassword ? 'text' : 'password') : type}
                            name={fieldName}
                            onChange={onChangeValue}
                            onBlur={onBlurValue}
                            defaultValue={initValue ?? ""}
                            placeholder={placeholder}
                            required={false}
                            readOnly={disabled}
                            className={` w-100 ${styles['form-home-input']} ${isShowRevealPasswordButton && value && styles['input-password']}`}
                            autoComplete={''}
                            style={{ height: height }}
                        />
                        {isShowRevealPasswordButton && value && (
                            <div className={styles[`icon-password`]} onClick={() => { showPassword() }}>
                                {
                                    isShowPassword && value ? <IconEye /> : <IconEyeOff />
                                }
                            </div>
                        )}
                    </div>
                    { 
                        errors[fieldName] && <div className={styles['error-msg']}>{Validator.renderMessage(trans(errors[fieldName]?.message), { 0: field?.messageKey ? trans(field?.labelKey) : trans(fieldName) })}</div>
                    }
                </div>
            }}
        />
    )
}

export default FormInput;