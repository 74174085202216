import ApiOperation from "adapter/ApiOperation";
import { getCoreAPIMessenger } from "shared/components/common/Util";
import Constants from "utils/Constants";

const factory = {
    getListUser: (payload) => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/channel`;

        let params = {

        };
        if (payload?.keyword) {
            params['keyword'] = payload?.keyword
        }
        if (payload?.isAll) {
            params['isAll'] = true
        }
        if (payload?.limit) {
            params['limit'] = payload?.limit
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT_MESSAGE
        }
        if (payload?.[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE]
        } else {
            params['page'] = Constants.PAGINATION.OFFSET
        }

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    getMessage: (payload) => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/message`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: payload
        })
    },
    sendMessage: (payload) => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/message`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    forwardMessage: (payload) => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/message/forward-for-channel`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    getUserList: (payload) => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/message`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
        })
    },
    getUserListCompany: (payload) => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/user/user-in-company`;

        let params = {};
        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }
        if (payload[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.KEYWORD.VALUE];
        }

        if (payload[Constants.QueryParams.CHANNEL.VALUE]) {
            params['channelId'] = payload[Constants.QueryParams.CHANNEL.VALUE];
        }
        
        if (payload[Constants.QueryParams.USER_IN_COMPANY.VALUE]) {
            params['userInCompanyIds'] = payload[Constants.QueryParams.USER_IN_COMPANY.VALUE];
        }
        
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                ...params
            }
        })
    },
    createdChannel: (payload) => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/channel/create`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    addToChannel: (payload) => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/channel/add-to-channel`;

        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    syncUser: () => {
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/channel/sync-user`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
        })
    },
    getListUserInstance: (payload) => {
        let params = {};
        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }
        if (payload[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.KEYWORD.VALUE];
        }
        if (payload[Constants.QueryParams.CHANNEL.VALUE]) {
            params['channelId'] = payload[Constants.QueryParams.CHANNEL.VALUE];
        }
        var url = `${getCoreAPIMessenger()}/rest/api/v1/convention/user`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                ...params
            }
        })
    }
}

export default factory;