import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './Button.module.scss';
import Loading from './loading/Loading';

function Button(props) {
    const {
        title,
        variant = 'contained',
        onClick = () => { },
        children,
        color = 'blue',
        startIcon = null,
        endIcon = null,
        disabled = false,
        type = 'button',
        backgroundTransparent = false,
        size = 'nomarl',
        className = '',
        loading = false,
        refButton,
        style,
        isShadow = true
    } = props;
    // variant : outlined | contained
    // color : gray | default | green | red | violet |
    // size : small | normal | large
    const getColor = (_color) => {
        if (_color == 'default') {
            return 'button_color_default';
        }
        if (_color == 'red') {
            return 'button_color_red';
        }
        if (_color == 'gray') {
            return 'button_color_gray';
        }
        if (_color == 'dark_gray') {
            return 'button_color_dark_gray';
        }
        if (_color == 'yellow') {
            return 'button_color_yellow';
        }
        if (_color == 'green') {
            return 'button_color_green';
        }
        if (_color == 'white') {
            return 'button_color_white';
        }
        if (_color == 'hippie_blue') {
            return 'button_color_hippie_blue';
        }
        if (_color == 'transparent') {
            return 'button_color_transparent';
        }
        if (_color == 'lightblue') {
            return 'button_color_lightblue';
        }
        if (_color == 'gray-yellow') {
            return 'button_color_gray-yellow';
        }
        return '';
    };

    return (
        <div
            className={`${classes['custom-button']} 
            ${classes[getColor(color)]} 
            ${backgroundTransparent ? classes['background-transparent'] : ''} 
                ${disabled ? classes['disabled'] : ''} ${loading ? classes['disabled'] : ''} ${classes[size]} ${!children && !title ? classes['custom-button-icon'] : ''} ${className}
                ${isShadow ? className['custom-button-shadow'] : ''}`}
        >
            <button
                className={`${classes[variant]}`}
                type={type}
                onClick={onClick}
                disabled={disabled ? disabled : (loading ? true : false)}
                ref={refButton}
                style={{
                    ...style
                }}
            >
                {loading ? (
                    <div className={`${classes['icon-button']} ${true ? classes['icon-button-loading'] : ['']}`}><Loading /></div>
                ) : null}
                {!loading && startIcon && (
                    <div className={classes['icon-button']}>{startIcon}</div>
                )}{' '}
                {title ?? children}{' '}
                {endIcon && (
                    <div className={classes['icon-button']}>{endIcon}</div>
                )}
            </button>
        </div>
    );
}
Button.propTypes = {
    title: PropTypes.string,
    variant: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    type: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    backgroundTransparent: PropTypes.bool,
    loading: PropTypes.bool,
    isShadow: PropTypes.bool,
    style: PropTypes.any,
};
export default Button;
