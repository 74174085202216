import { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import {
    matchPath
} from 'react-router-dom';
import Utils from 'utils/Utils';
import CustomRoute from './CustomRoute';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_USER_LOGIN } from 'utils/EventRegister';
import useTrans from 'hooks/use-trans';
import Cookies from 'js-cookie';

export const ProtectedRoute = ({
    fallback,
    route,
    guards,
    ...rest
}) => {
    const [canAccess, setCanAccess] = useState(null)
    // const [canAccess, setCanAccess] = useState(true)
    const { t } = useTrans();
    useLayoutEffect(() => {
        checkAsync()
    }, [])

    const checkAsync = async () => {
        const {
            pathname,
        } = window.location;
        const matchResult = matchPath(pathname, rest);
        const hasMatchedRoute = !!matchResult;
        if (hasMatchedRoute) {
            const guardArgs = rest;
            // const canBeRendered = await route?.guards?.every(async (guard) => await guard(guardArgs));
            const canBeRendered = await Utils.asyncEvery(route?.guards, async (guard) => await guard(guardArgs));
            if (route?.guards?.length && !canBeRendered) {
                setCanAccess(false)
            } else {
                setCanAccess(true)
            }
        }
    }

    if (canAccess === null) {
        return (
            <>
            </>
        )
    }

    if (!canAccess) {
        const fallbackArgs = rest;
        const have_token =  Cookies.get('accessToken') ? true : false;
        if (!have_token) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                type: EVENT_SHOW_POPUP_USER_LOGIN,
                open: true,
                payload: {
                    title: t("Login.title"),
                    customStyle: {
                        maxHeight: 'calc(100vh - 90px - 50px)'
                    }
                }
            })
        }
        return route?.fallback(fallbackArgs);
    }

    return (
        <CustomRoute path={route.path} exact Layout={route.layout} route={route}>
            {route.component}
        </CustomRoute>
    );
};