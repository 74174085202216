import React from 'react'
import useTrans from 'hooks/use-trans';
import RenderBody from './RenderBody';

function ActionPopup(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const handleOke = () => {
        showVisible()
    }

    const title = () => {
        switch (payload?.option?.typePopup) {
            case 'G0100':
                return <>{t("package_tracking.package_tracking")}</>
            default:
                return <>{t("package_tracking.package_tracking")}</>
        }
    }
    return (
        <RenderBody showVisible={showVisible} handleOke={handleOke} title={title()} data={payload?.option} />
    )
}

export default ActionPopup