import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const factory = {
    getMasterHolonTemplate: (payload) => {
        var url = '/rest/api/v1/management/holon-template';
        let params = {
            isAll: true,
        };

        if (payload?.masterCodes) {
            params['masterCodes'] = payload?.masterCodes
        }

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    createProductDetailCargo: (payload) => {
        var url = '/rest/api/v1/management/cargo-product/select';
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    returnRequest: (payload) => {
        var url = `/rest/api/v1/management/cargo/request-return/${payload?.name}/check`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    returnRequestReason: (payload) => {
        var url = `/rest/api/v1/management/cargo/request-return/${payload?.name}`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: {
                returnReason: payload?.returnReason
            }
        })
    },
    getProductDetailCargo: (payload) => {
        var url = `/rest/api/v1/management/cargo-product/detail/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getWarehouseDefault: () => {
        var url = `/rest/api/v1/management/warehouse/hex-warehouse-default`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    getListProductCargo: (payload) => {
        var url = `/rest/api/v1/management/cargo-product/select`;
        let params = {

        }
        if (payload?.holon_id) {
            params['holon_id'] = payload?.holon_id;
        }
        if (payload?.[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload?.[Constants.QueryParams.KEYWORD.VALUE];
        }
        if (payload?.[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload?.[Constants.QueryParams.OFFSET.VALUE];
        }
        if (payload?.[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload?.[Constants.QueryParams.LIMIT.VALUE];
        }
        if (payload?.id) {
            params['warehouseId'] = payload?.id;
        }

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                ...params
            }
        })
    }
}

export default factory;