import React from "react";
import Box from "shared/components/form-input/box/Box";
import classes from "./EstimatedFareContainer.module.scss";
import EstimateFare from './EstimateFare'
import ReferenceDeliveryTime from './ReferenceDeliveryTime'
import FreightCalculationResult from './FreightCalculationResult'
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

const EstimatedFareContainer = () => {
    const token = Cookies.get("accessToken");
    const {
        fareCalcManagementCalculate,
    } = useSelector((state) => state.fareCalculation);
    return (
        <Box className={classes[`${token ? "estimated-fare-container" : "estimated-fare-container-popup"}`]}>
            <EstimateFare />
            {
                fareCalcManagementCalculate?.length === 0 && <>
                    <div className={classes["line-estimate"]}></div>
                    <ReferenceDeliveryTime />
                </>
            }

            <div className={classes["line-estimate"]}></div>
            <FreightCalculationResult />
        </Box>
    );
};

export default EstimatedFareContainer;
