const PolicySettingAction = {
    GET_POLICY_SETTING: "GET_POLICY_SETTING",
    GET_POLICY_SETTING_LOADING: "GET_POLICY_SETTING_LOADING",
    GET_POLICY_SETTING_FAILED: "GET_POLICY_SETTING_FAILED",
    GET_POLICY_SETTING_SUCCESS: "GET_POLICY_SETTING_SUCCESS",

    UPDATE_POLICY_SETTING: "UPDATE_POLICY_SETTING",
    UPDATE_POLICY_SETTING_LOADING: "UPDATE_POLICY_SETTING_LOADING",
    UPDATE_POLICY_SETTING_FAILED: "UPDATE_POLICY_SETTING_FAILED",
    UPDATE_POLICY_SETTING_SUCCESS: "UPDATE_POLICY_SETTING_SUCCESS",
}

export default PolicySettingAction;