export const POPUP_TEXT_TYPE="POPUP_TEXT_TYPE";
export const EVENT_SHOW_POPUP="EVENT_SHOW_POPUP";
export const EVENT_SHOW_POPUP2="EVENT_SHOW_POPUP2";
export const EVENT_SHOW_POPUP3="EVENT_SHOW_POPUP3";
export const EVENT_SHOW_POPUP_CANCEL_POST="EVENT_SHOW_POPUP_CANCEL_POST";
export const EVENT_SHOW_POPUP_DELETE="EVENT_SHOW_POPUP_DELETE";
export const EVENT_SHOW_POPUP_ACCEPT="EVENT_SHOW_POPUP_ACCEPT";
export const EVENT_SHOW_POPUP_COMMENT="EVENT_SHOW_POPUP_COMMENT";
export const EVENT_SHOW_POPUP_EDIT="EVENT_SHOW_POPUP_EDIT";
export const EVENT_SHOW_POPUP_CREATE_POST="EVENT_SHOW_POPUP_CREATE_POST";
export const EVENT_SHOW_POPUP_CREATE_GROUP="EVENT_SHOW_POPUP_CREATE_GROUP";
export const EVENT_SHOW_ADD_MEMBER="EVENT_SHOW_ADD_MEMBER";
export const EVENT_SHOW_POPUP_ACCEPT_COMMON="EVENT_SHOW_POPUP_ACCEPT_COMMON";
export const EVENT_SHOW_UPDATE_AVATAR="EVENT_SHOW_UPDATE_AVATAR";
export const EVENT_SHOW_UPDATE_SHORTCUTS="EVENT_SHOW_UPDATE_SHORTCUTS";
export const EVENT_SHOW_POPUP_USER_LOGIN="EVENT_SHOW_POPUP_USER_LOGIN";
export const EVENT_SHOW_POPUP_USER_REGISTER="EVENT_SHOW_POPUP_USER_REGISTER";
export const EVENT_SHOW_POPUP_USER_REGISTER_2="EVENT_SHOW_POPUP_USER_REGISTER_2";
export const EVENT_SHOW_POPUP_USER_REGISTER_RESULT="EVENT_SHOW_POPUP_USER_REGISTER_RESULT";
export const EVENT_SHOW_POPUP_ADD_FAQ_CATEGORY="EVENT_SHOW_POPUP_ADD_FAQ_CATEGORY";
export const EVENT_SHOW_POPUP_UPDATE_FAQ_CATEGORY="EVENT_SHOW_POPUP_UPDATE_FAQ_CATEGORY";
export const EVENT_SHOW_POPUP_ADD_FAQ="EVENT_SHOW_POPUP_ADD_FAQ";
export const EVENT_SHOW_POPUP_UPDATE_FAQ="EVENT_SHOW_POPUP_UPDATE_FAQ";
export const EVENT_SHOW_POPUP_USER_FORGOT_ID_PASSWORD="EVENT_SHOW_POPUP_USER_FORGOT_ID_PASSWORD";
export const EVENT_SHOW_POPUP_USER_INQUIRY="EVENT_SHOW_POPUP_USER_INQUIRY";
export const EVENT_SHOW_POPUP_USER_INQUIRY_CONFIRMATION="EVENT_SHOW_POPUP_USER_INQUIRY_CONFIRMATION";
export const EVENT_SHOW_POPUP_USER_INQUIRY_COMPLETION="EVENT_SHOW_POPUP_USER_INQUIRY_COMPLETION";
export const EVENT_SHOW_POPUP_FAQ_CATEGORY="EVENT_SHOW_POPUP_FAQ_CATEGORY";
export const EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE="EVENT_SHOW_POPUP_ADD_HOLON_TEMPLATE";
export const EVENT_SHOW_POPUP_SELECT_HOLON_TEMPLATE="EVENT_SHOW_POPUP_SELECT_HOLON_TEMPLATE";
export const EVENT_SHOW_POPUP_CONFIRM_DELETE="EVENT_SHOW_POPUP_CONFIRM_DELETE";
export const EVENT_SHOW_POPUP_CONFIRM_UPDATE="EVENT_SHOW_POPUP_CONFIRM_UPDATE";
export const EVENT_SHOW_POPUP_CONFIRM="EVENT_SHOW_POPUP_CONFIRM";
export const EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE="EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE";
export const EVENT_SHOW_POPUP_PRIVACY_POLICY="EVENT_SHOW_POPUP_PRIVACY_POLICY";
export const EVENT_SHOW_POPUP_UPDATE_HOLON_TEMPLATE="EVENT_SHOW_POPUP_UPDATE_HOLON_TEMPLATE";
export const EVENT_SHOW_POPUP_DETAIL_HOLON_TEMPLATE="EVENT_SHOW_POPUP_DETAIL_HOLON_TEMPLATE";
export const EVENT_SHOW_POPUP_HOLON_TEMPLATE_SEARCH="EVENT_SHOW_POPUP_HOLON_TEMPLATE_SEARCH";
export const EVENT_SHOW_POPUP_ADD_INSTANCE="EVENT_SHOW_POPUP_ADD_INSTANCE";
export const EVENT_SHOW_POPUP_LIST_HOLON_INSTANCE="EVENT_SHOW_POPUP_LIST_HOLON_INSTANCE";
export const EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE="EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE";
export const EVENT_SHOW_POPUP_UPDATE_HOLON_INSTANCE="EVENT_SHOW_POPUP_UPDATE_HOLON_INSTANCE";
export const EVENT_SHOW_POPUP_ADD_ALARM_HOLON_TEMPLATE="EVENT_SHOW_POPUP_ADD_ALARM_HOLON_TEMPLATE";
export const EVENT_SHOW_POPUP_ALARM_DETAIL_HOLON_TEMPLATE="EVENT_SHOW_POPUP_ALARM_DETAIL_HOLON_TEMPLATE";
export const EVENT_SHOW_POPUP_UPDATE_ALARM_HOLON_TEMPLATE="EVENT_SHOW_POPUP_UPDATE_ALARM_HOLON_TEMPLATE";
export const EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE="EVENT_SHOW_POPUP_FORM_SEARCH_ATTRIBUTE_HOLON_TEMPLATE";
export const EVENT_SHOW_POPUP_COMPANY_INFORMATION_SETTINGS="EVENT_SHOW_POPUP_COMPANY_INFORMATION_SETTINGS";
export const EVENT_SHOW_POPUP_ORGANIZATIONAL_COLLABORATION="EVENT_SHOW_POPUP_ORGANIZATIONAL_COLLABORATION";
export const EVENT_SHOW_POPUP_HEX_SERVICE_DETAIL="EVENT_SHOW_POPUP_HEX_SERVICE_DETAIL";
export const EVENT_SHOW_ALARM_LIST_POPUP="EVENT_SHOW_ALARM_LIST_POPUP";
export const EVENT_SHOW_USER_LIST_POPUP="EVENT_SHOW_USER_LIST_POPUP";
export const EVENT_SHOW_INQUIRY_CONDITION_SEARCH_POPUP="EVENT_SHOW_INQUIRY_CONDITION_SEARCH_POPUP";
export const EVENT_SHOW_INQUIRY_DETAIL_POPUP="EVENT_SHOW_INQUIRY_DETAIL_POPUP";
export const EVENT_SHOW_CREATE_INQUIRY_POPUP="EVENT_SHOW_CREATE_INQUIRY_POPUP";
export const EVENT_SHOW_UPDATE_INQUIRY_POPUP="EVENT_SHOW_UPDATE_INQUIRY_POPUP";
export const EVENT_SHOW_CREATE_WAREHOUSE_POPUP="EVENT_SHOW_CREATE_WAREHOUSE_POPUP";
export const EVENT_SHOW_UPDATE_WAREHOUSE_POPUP="EVENT_SHOW_UPDATE_WAREHOUSE_POPUP";
export const EVENT_SHOW_WAREHOUSE_DETAIL_POPUP="EVENT_SHOW_WAREHOUSE_DETAIL_POPUP";
export const EVENT_SHOW_SERVICE_DETAIL_POPUP="EVENT_SHOW_SERVICE_DETAIL_POPUP";
export const EVENT_SHOW_FAQ_POPUP="EVENT_SHOW_FAQ_POPUP";
export const EVENT_SHOW_ORGANIZATIONAL_COLLABORATION_BL_POPUP="EVENT_SHOW_ORGANIZATIONAL_COLLABORATION_BL_POPUP";
export const EVENT_SHOW_ADD_BL_ACCOUNT_POPUP="EVENT_SHOW_ADD_BL_ACCOUNT_POPUP";
export const EVENT_SHOW_ADD_BL_ACCOUNT_SUCCESS_POPUP="EVENT_SHOW_ADD_BL_ACCOUNT_SUCCESS_POPUP";
export const EVENT_SHOW_PREVIEW_FILE_POPUP="EVENT_SHOW_PREVIEW_FILE_POPUP";
export const EVENT_SHOW_FAQ_DETAIL_POPUP="EVENT_SHOW_FAQ_DETAIL_POPUP";
export const EVENT_SHOW_COMPANY_DETAIL_POPUP="EVENT_SHOW_COMPANY_DETAIL_POPUP";
export const EVENT_SHOW_BP_SERVICE_SELECTION_POPUP="EVENT_SHOW_BP_SERVICE_SELECTION_POPUP";

export const EVENT_SHOW_LIST_CHANNEL_POPUP="EVENT_SHOW_LIST_CHANNEL_POPUP";
export const EVENT_SHOW_LIST_TEMPLATE_OCR_POPUP="EVENT_SHOW_LIST_TEMPLATE_OCR_POPUP";
export const EVENT_SHOW_LIST_PRODUCT_POPUP="EVENT_SHOW_LIST_PRODUCT_POPUP";
export const EVENT_SHOW_LIST_PRODUCT_DETAIL_CARGO_POPUP="EVENT_SHOW_LIST_PRODUCT_DETAIL_CARGO_POPUP";
export const EVENT_SHOW_COPY_DATA_POPUP="EVENT_SHOW_COPY_DATA_POPUP";

export const EVENT_SHOW_CREATE_SENDER_POPUP="EVENT_SHOW_CREATE_SENDER_POPUP";
export const EVENT_SHOW_UPDATE_SENDER_POPUP="EVENT_SHOW_UPDATE_SENDER_POPUP";
export const EVENT_SHOW_SENDER_DETAIL_POPUP="EVENT_SHOW_SENDER_DETAIL_POPUP";

export const EVENT_SHOW_CREATE_PRODUCT_POPUP="EVENT_SHOW_CREATE_PRODUCT_POPUP";
export const EVENT_SHOW_UPDATE_PRODUCT_POPUP="EVENT_SHOW_UPDATE_PRODUCT_POPUP";
export const EVENT_SHOW_PRODUCT_DETAIL_POPUP="EVENT_SHOW_PRODUCT_DETAIL_POPUP";


export const EVENT_SHOW_CREATE_STOCKS_POPUP="EVENT_SHOW_CREATE_STOCKS_POPUP";
export const EVENT_SHOW_UPDATE_STOCKS_POPUP="EVENT_SHOW_UPDATE_STOCKS_POPUP";
export const EVENT_SHOW_STOCK_DETAIL_POPUP="EVENT_SHOW_STOCK_DETAIL_POPUP";

export const EVENT_SHOW_HISTORY_DETAIL_POPUP="EVENT_SHOW_HISTORY_DETAIL_POPUP";
export const EVENT_SHOW_STOCK_INVENTORY_POPUP="EVENT_SHOW_STOCK_INVENTORY_POPUP";

export const EVENT_SHOW_CREATE_RECEIVER_POPUP="EVENT_SHOW_CREATE_RECEIVER_POPUP";
export const EVENT_SHOW_UPDATE_RECEIVER_POPUP="EVENT_SHOW_UPDATE_RECEIVER_POPUP";
export const EVENT_SHOW_RECEIVER_DETAIL_POPUP="EVENT_SHOW_RECEIVER_DETAIL_POPUP";
export const EVENT_SHOW_SERVICE_SELECTION_POPUP="EVENT_SHOW_SERVICE_SELECTION_POPUP";
export const EVENT_SHOW_TEMPLATE_SELECTION_POPUP="EVENT_SHOW_TEMPLATE_SELECTION_POPUP";

export const EVENT_SHOW_CREATE_HEX_MENU_POPUP="EVENT_SHOW_CREATE_HEX_MENU_POPUP";
export const EVENT_SHOW_UPDATE_HEX_MENU_POPUP="EVENT_SHOW_UPDATE_HEX_MENU_POPUP";
export const EVENT_SHOW_CROSS_BORDER_COURIER_SERVICE="EVENT_SHOW_CROSS_BORDER_COURIER_SERVICE";
export const EVENT_SHOW_POST_DETAIL="EVENT_SHOW_POST_DETAIL";
export const EVENT_SHOW_POPUP_ESTIMATE_FARE="EVENT_SHOW_POPUP_ESTIMATE_FARE";
export const LIST_INSTANCE_UPDATE="LIST_INSTANCE_UPDATE";

export const EVENT_SHOW_TRANSACTION_DETAIL_POPUP="EVENT_SHOW_TRANSACTION_DETAIL_POPUP";
export const EVENT_SHOW_TRANSACTION_CREATE_POPUP="EVENT_SHOW_TRANSACTION_CREATE_POPUP";
export const EVENT_SHOW_TRANSACTION_UPDATE_POPUP="EVENT_SHOW_TRANSACTION_UPDATE_POPUP";


export const G0100="G0100"
export const G1381="G1381"
export const G1382="G1382"
export const G1381_U="G1381U"
export const OVERVIEW_IMPORT_ORDER="OVERVIEW_IMPORT_ORDER"
export const G1600_U="G1600U"
export const OVERVIEW_IMPORT_PRODUCT="OVERVIEW_IMPORT_PRODUCT"
export const G1511_ACTION2="G1511_ACTION2"
export const G1511_ADD_PRODUCT="G1511_ADD_PRODUCT"
export const G1811_PAYMENT="G1811_PAYMENT"

export const EVENT_SHOW_POPUP_AUTO_BILL_PAY="EVENT_SHOW_POPUP_AUTO_BILL_PAY"

export const FIRST_POPUP = 1;
export const SECOND_POPUP = 2;
export const THREE_POPUP = 3;
export const EVENT_SHOW_POPUP_ALARM = "EVENT_SHOW_POPUP_ALARM";
export const EVENT_RENDER_POPUP = "EVENT_RENDER_POPUP";
export const EVENT_SHOW_POPUP_ERROR_UPLOAD_ORDER = "EVENT_SHOW_POPUP_ERROR_UPLOAD_ORDER";

export default class EventRegister {
    static listeners = {
        count: 0,
        refs: {},
    };

    static addEventListener(eventName, callback) {
        if (typeof eventName === 'string' && typeof callback === 'function') {
            EventRegister.listeners.count += 1;
            const eventId = `l${EventRegister.listeners.count}`;
            EventRegister.listeners.refs[eventId] = {
                name: eventName,
                callback,
            };
            return eventId;
        }
        return false;
    }

    static removeEventListener(id) {
        if (typeof id === 'string') {return delete EventRegister.listeners.refs[id];}
        return false;
    }

    static removeAllListeners() {
        let removeError = false;
        Object.keys(EventRegister.listeners.refs).forEach((id) => {
            const removed = delete EventRegister.listeners.refs[id];
            removeError = !removeError ? !removed : removeError;
        });
        return !removeError;
    }

    static emitEvent(eventName, data) {
        Object.keys(EventRegister.listeners.refs).forEach((id) => {
            if (EventRegister.listeners.refs[id] && eventName === EventRegister.listeners.refs[id].name) {
                EventRegister.listeners.refs[id].callback(data);
            }
        });
    }

    static on(eventName, callback) {
        return EventRegister.addEventListener(eventName, callback);
    }

    static off(eventName) {
        return EventRegister.removeEventListener(eventName);
    }

    static offAll() {
        return EventRegister.removeAllListeners();
    }

    static emit(eventName, data) {
        EventRegister.emitEvent(eventName, data);
    }
}
