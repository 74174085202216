const { default: actionAlarm } = require("./action");

const initialState = {
    listAlarmSettings: {
        limit: 0,
        page: 1,
        records: [],
        total: 0,
        totalPage: 1
    },
    loading: {
        loadingGetAlarm: false,
        loadingAddAlarm: false,
        loadingAlarmDetail: false,
        loadingDeleteAlarm: false,
        loadingUpdateAlarm: false,
    },
    holonTemplateDetail: {},
    alarmHolonTemplateDetail: {},
    master: {},
    alarmCodeMaster: null,
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionAlarm.GET_ALARM_HOLON_SETTING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetAlarm: true
                }
            }
        case actionAlarm.CREATE_ALARM_HOLON_SETTING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingAddAlarm: true
                }
            }
        case actionAlarm.CREATE_ALARM_HOLON_SETTING_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingAddAlarm: false
                }
            }
        case actionAlarm.CREATE_ALARM_HOLON_SETTING_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingAddAlarm: false
                }
            }
        case actionAlarm.GET_ALARM_HOLON_SETTING_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetAlarm: false
                },
                listAlarmSettings: action.payload
            }
        case actionAlarm.GET_ALARM_HOLON_SETTING_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetAlarm: false
                }
            }
        case actionAlarm.GET_HOLON_TEMPLATE_DETAIL_SUCCESS:
            return {
                ...state,
                holonTemplateDetail: action.payload?.holonTemplateDetail
            }
        case actionAlarm.GET_MASTER_ALARM_SUCCESS:
            return {
                ...state,
                master: action.payload
            }
        case actionAlarm.GET_HOLON_TEMPLATE_WITH_CODE_MASTER_SUCCESS:
            return {
                ...state,
                alarmCodeMaster: action.payload
            }
        case actionAlarm.GET_ALARM_HOLON_SETTING_DETAIL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingAlarmDetail: true
                }
            }
        case actionAlarm.GET_ALARM_HOLON_SETTING_DETAIL_SUCCESS:
            return {
                ...state,
                alarmHolonTemplateDetail: action.payload,
                loading: {
                    ...state.loading,
                    loadingAlarmDetail: false
                }
            }
        case actionAlarm.RESET_GET_ALARM_HOLON_SETTING_DETAIL:
            return {
                ...state,
                alarmHolonTemplateDetail: {},
            }
        case actionAlarm.DELETE_ALARM_HOLON_SETTING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteAlarm: true
                }
            }
        case actionAlarm.DELETE_ALARM_HOLON_SETTING_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteAlarm: false
                }
            }
        case actionAlarm.DELETE_ALARM_HOLON_SETTING_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteAlarm: false
                }
            }
        case actionAlarm.UPDATE_ALARM_HOLON_SETTING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateAlarm: true
                }
            }
        case actionAlarm.UPDATE_ALARM_HOLON_SETTING_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateAlarm: false
                }
            }
        case actionAlarm.UPDATE_ALARM_HOLON_SETTING_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateAlarm: false
                }
            }
        default:
            return { ...state }
    }
}

export default myReducer;