import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import EstimatedFareContainer from 'shared/container/estimated-fare-container/EstimatedFareContainer'
import classes from "./EstimatedFarePage.module.scss";

function EstimatedFarePage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t("home.estimated_fare");
    }, [])
    return (
        <div className={classes["EstimatedFare"]}>
            <EstimatedFareContainer />
        </div>
    )
}

export default EstimatedFarePage