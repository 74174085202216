const actionWareHouse  = {
    GET_WAREHOUSE_IN_LIST : "GET_WAREHOUSE_IN_LIST",
    GET_WAREHOUSE_IN_LIST_SUCCESS : "GET_WAREHOUSE_IN_LIST_SUCCESS",
    GET_WAREHOUSE_IN_LIST_FAILED : "GET_WAREHOUSE_IN_LIST_FAILED",
    
    GET_WAREHOUSE_IN_DETAIL : "GET_WAREHOUSE_IN_DETAIL",
    GET_WAREHOUSE_IN_DETAIL_SUCCESS : "GET_WAREHOUSE_IN_DETAIL_SUCCESS",
    GET_WAREHOUSE_IN_DETAIL_FAILED : "GET_WAREHOUSE_IN_DETAIL_FAILED",

    GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN : "GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN",
    GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN_SUCCESS : "GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN_SUCCESS",
    GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN_FAILED : "GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_IN_FAILED",

    UPDATE_WAREHOUSE_IN : "UPDATE_WAREHOUSE_IN",
    UPDATE_WAREHOUSE_IN_SUCCESS : "UPDATE_WAREHOUSE_IN_SUCCESS",
    UPDATE_WAREHOUSE_IN_FAILED : "UPDATE_WAREHOUSE_IN_FAILED",

    DELETE_WAREHOUSE_IN : "DELETE_WAREHOUSE_IN",
    DELETE_WAREHOUSE_IN_SUCCESS : "DELETE_WAREHOUSE_IN_SUCCESS",
    DELETE_WAREHOUSE_IN_FAILED : "DELETE_WAREHOUSE_IN_FAILED",

    CREATE_WAREHOUSE_IN : "CREATE_WAREHOUSE_IN",
    CREATE_WAREHOUSE_IN_SUCCESS : "CREATE_WAREHOUSE_IN_SUCCESS",
    CREATE_WAREHOUSE_IN_FAILED : "CREATE_WAREHOUSE_IN_FAILED",

    CREATE_WAREHOUSE_OUT : "CREATE_WAREHOUSE_OUT",
    CREATE_WAREHOUSE_OUT_SUCCESS : "CREATE_WAREHOUSE_OUT_SUCCESS",
    CREATE_WAREHOUSE_OUT_FAILED : "CREATE_WAREHOUSE_OUT_FAILED",

    GET_WAREHOUSE_IN_BARCODE : "GET_WAREHOUSE_IN_BARCODE",
    GET_WAREHOUSE_IN_BARCODE_SUCCESS : "GET_WAREHOUSE_IN_BARCODE_SUCCESS",
    GET_WAREHOUSE_IN_BARCODE_FAILED : "GET_WAREHOUSE_IN_BARCODE_FAILED",


    GET_WAREHOUSE_OUT_BARCODE : "GET_WAREHOUSE_OUT_BARCODE",
    GET_WAREHOUSE_OUT_BARCODE_SUCCESS : "GET_WAREHOUSE_OUT_BARCODE_SUCCESS",
    GET_WAREHOUSE_OUT_BARCODE_FAILED : "GET_WAREHOUSE_OUT_BARCODE_FAILED",
}
export default actionWareHouse;