import useTrans from 'hooks/use-trans'
import React from 'react'
import ProductContainer from 'shared/container/product/ProductContainer'

function ProductPage() {
    const { t } = useTrans();
    document.title = t("product.product_management")
    return (
        <ProductContainer />
    )
}

export default ProductPage