import React from 'react';

function IconChevronDown({ fontSize = 12,strokeWidth = 1.5}) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fontSize={fontSize}
        >
            <path
                d="M9 1L5 5L0.999999 1"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconChevronDown;
