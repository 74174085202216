import ApiOperation from 'adapter/ApiOperation';
import axios from 'axios';

const systemSetting = {
    getHolonMasterSystem: () => {
        var url = `/rest/api/v1/holon-master`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        });
    },

    putHolonMasterSystem: (payload) => {
        var url = `/rest/api/v1/holon-master/update-all`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        });
    },
};

export default systemSetting;
