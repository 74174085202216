import React, { useState } from 'react'
import Box from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import classes from './HolonInstanDetail.module.scss';
import useTrans from 'hooks/use-trans';
import { v4 as uuidv4 } from 'uuid'
import jsonPath from 'jsonpath';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import { G0100, G1511_ACTION2 } from 'utils/EventRegister';
import { ReloadActionWithScreen, getMessageCommon, showPopupNotification } from 'utils/Utils';
import { useDispatch } from 'react-redux';
import actionCargo from 'redux/cargo/action';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import useRouter from 'hooks/use-router';
import Validator from 'utils/Validator';
import EventPopup from 'utils/EventPopup';

function ActionHolonInstance(props) {
    const {
        dataHolonInstanceActions,
        holonInStanceDetail,
        payload,
        setHolonInStanceDetail,
        setLoadingHolonInstanceDetail } = props;
    const [loadingAsyncPost, setLoadingAsyncPost] = useState(null);
    const { t } = useTrans();
    let actions_key = `actions_${uuidv4()}`;
    const dispatch = useDispatch();
    const router = useRouter();

    const mappingConnection = (input) => {
        let empty = 0;
        input = input?.map(x => {
            var authors = jsonPath.query(holonInStanceDetail, x?.value);
            if (authors?.length == 0) {
                empty + 1;
                return null;
            }
            return {
                ...x,
                value: authors
            }
        })
        if (empty > 0) {
            return [];
        }
        return input;
    }

    const requestApiActionPopup = (x, stringPattern) => {
        dispatch({
            type: actionCargo.RETURN_REQUEST,
            payload: {
                name: JSON.parse(stringPattern)?.['0']
            },
            callback: {
                success: (res, error) => {
                    setLoadingAsyncPost(null);
                    if (res?.data?.isValid == Constants.STATUS_CHECKBOX.NO.VALUE) {
                        showPopupNotification({
                            typePopup: 'message',
                            message: getMessageCommon({
                                response: res,
                                error: error,
                                actionName: x?.note
                            }),
                            newWindow: true
                        });
                    } else {
                        handleShowPopup(x, stringPattern)
                    }
                }
            }
        });
    }

    const requestPackageTracking = (x, stringPattern) => {
        dispatch({
            type: actionsHolonInstance.GET_PACKAGE_TRACKING,
            payload: JSON.parse(stringPattern)?.id,
            callback: {
                success: (res) => {
                    setLoadingAsyncPost(null);
                    showPopupNotification({
                        type: x?.api,
                        typePopup: 'action_popup',
                        callback: () => { },
                        option: {
                            typePopup: x?.api,
                            data: x?.inputValue,
                            dataBody: JSON.parse(stringPattern),
                            actionName: x?.note,
                            step: G0100,
                            res: res,
                            list: [{
                                item: `${JSON.parse(stringPattern)?.id}`
                            }]
                        },
                        newWindow: true,
                        payload: JSON.parse(stringPattern)
                    })
                },
                failed: (msg) => {
                    setLoadingAsyncPost(null);
                    showPopupNotification({
                        message: msg,
                        typePopup: 'message',
                        newWindow: true
                    });
                }
            }
        });
    }

    const handleShowPopup = (x, stringPattern) => {
        showPopupNotification({
            type: x?.api,
            typePopup: 'action_popup',
            callback: () => {
                dispatch({
                    type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                    payload: payload?.holonInstanceId,
                    callback: (data) => {
                        setHolonInStanceDetail(data);
                        setLoadingHolonInstanceDetail(false);
                    }
                });
                if (Object.keys(EventPopup.popupList.refs)?.length == 1) {
                    let params = {};
                    if (payload?.holonTemplateId) {
                        params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = payload?.holonTemplateId
                    }
                    if (payload?.subHolonTemplateId) {
                        params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = payload?.subHolonTemplateId
                    }
                    if (payload?.cId) {
                        params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = payload?.cId
                    }
                    if (payload?.cName) {
                        params[Constants.QueryParams.CONNECTION_NAME.VALUE] = payload?.cName
                    }
                    if (payload?.requireConnectionKey) {
                        params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = payload?.requireConnectionKey
                    }
                    if (payload?.requireConnectionHolonId) {
                        params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = payload?.requireConnectionHolonId
                    }
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE,
                        payload: {
                            ...router.getAll(),
                            ...params
                        }
                    });
                }

            },
            option: {
                typePopup: x?.api,
                data: x?.inputValue,
                dataBody: JSON.parse(stringPattern),
                actionName: x?.note
            },
            newWindow: true,
            payload: JSON.parse(stringPattern)
        })
    }

    return (
        <>
            {
                dataHolonInstanceActions?.length ? <Box>
                    <BoxHeader id={actions_key}>
                        <BoxTitle>{t("Holon_Template.action")}</BoxTitle>
                    </BoxHeader>
                    <BoxBody id={actions_key}>
                        {/* check $ jsonPath <> data POST */}
                        <div className={classes['viewer-actions']}>
                            {dataHolonInstanceActions?.map((x, index) => {
                                let data = x?.inputValue?.trim();
                                x.inputValue = x?.inputValue?.trim();
                                x.note = x?.note?.trim();
                                let stringApi = x?.api?.trim();
                                if (stringApi) {
                                    if (x?.inputValue) {
                                        try {
                                            let stringPattern = x?.inputValue;
                                            let dataBody = [];
                                            let arrInput = [];
                                            let arrInputJsonPath = [];
                                            let arrInputParams = [];

                                            const pattern = new RegExp(/"(\$)([^"\s]*)"/gm);
                                            let listJson = stringPattern.match(pattern);
                                            listJson?.forEach(x => {
                                                let data = mappingConnection([
                                                    {
                                                        value: x?.replace(/"/g, '')
                                                    }
                                                ]);
                                                stringPattern = stringPattern.replace(x, `"${data[0]?.value[0]}"`);
                                            });
                                            if (stringApi?.indexOf("/") != -1) {
                                                data = JSON.parse(data);
                                                arrInput = Object.keys(data).map(key => {
                                                    return {
                                                        key: key,
                                                        value: data[key]
                                                    }
                                                });

                                            }

                                            [...arrInput]?.forEach(xx => {
                                                if (xx?.value?.indexOf("$.") != -1) {
                                                    arrInputJsonPath.push(xx);
                                                }
                                            });

                                            [...arrInput]?.forEach(xx => {
                                                if (!xx?.value?.indexOf("$.") != -1) {
                                                    arrInputParams.push(xx)
                                                }
                                            });

                                            [...arrInput]?.forEach(xx => {
                                                if (xx?.value?.indexOf("$.") == -1) {
                                                    dataBody.push(xx)
                                                }
                                            })

                                            if (arrInputJsonPath?.length > 0) {
                                                if (mappingConnection(arrInputJsonPath)?.length > 0) {
                                                    mappingConnection(arrInputJsonPath)?.map(xx => {
                                                        if (stringApi?.indexOf(xx?.key) != -1) {
                                                            stringApi = stringApi?.replace(`{${xx?.key}}`, xx?.value)
                                                        } else {
                                                            dataBody.push({
                                                                ...xx,
                                                                value: xx?.value?.[0]
                                                            })
                                                        }
                                                    });

                                                } else {
                                                    return <Button key={index} variant='outlined' disabled title={x?.note} />
                                                }
                                            } else {
                                                arrInputParams?.map(xx => {
                                                    if (stringApi?.indexOf(xx?.key) != -1) {
                                                        stringApi = stringApi?.replace(`{${xx?.key}}`, xx?.value)
                                                    } else {
                                                        dataBody.push({
                                                            ...xx,
                                                            value: xx?.value
                                                        })
                                                    }
                                                });
                                            }
                                            if (x?.type == Constants.TYPE_ACTIONS.REDIRECT.VALUE) {
                                                return <a style={{
                                                    pointerEvents: x?.IsEditabled == "0" ? 'none' : 'unset',
                                                    backgroundColor: 'white',
                                                    opacity: loadingAsyncPost && loadingAsyncPost != index ? 0.7 : 1
                                                }} key={index} className={classes['custom-button']} target="_blank" href={stringApi} rel="noreferrer">{x?.note}</a>
                                            }

                                            if (x?.type == Constants.TYPE_ACTIONS.POPUP.VALUE) {
                                                return <Button
                                                    disabled={x?.IsEditabled == "0" ||
                                                        (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false}
                                                    loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                        if (x?.api == G1511_ACTION2) {
                                                            setLoadingAsyncPost(index);
                                                            requestApiActionPopup(x, stringPattern)
                                                        }
                                                        else if (x?.api == G0100) {
                                                            setLoadingAsyncPost(index);
                                                            requestPackageTracking(x, stringPattern)
                                                        } else {
                                                            handleShowPopup(x, stringPattern);
                                                        }
                                                    }}
                                                    title={x?.note}
                                                />;
                                            }

                                            if (x?.type == Constants.TYPE_ACTIONS.ASYNCPOST.VALUE) {
                                                return <Button loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                    showPopupNotification({
                                                        typePopup: 'confirm',
                                                        newWindow: true,
                                                        message: Validator.renderMessage(t("confirm_action"), {
                                                            0: x?.note
                                                        }),
                                                        callback: () => {
                                                            setLoadingAsyncPost(index)
                                                            dispatch({
                                                                type: actionsHolonInstance.REQUEST_API_WITH_URL,
                                                                payload: {
                                                                    url: stringApi,
                                                                    method: 'POST',
                                                                    data: JSON.parse(stringPattern)
                                                                },
                                                                callback: {
                                                                    success: (res) => {
                                                                        setLoadingAsyncPost(null);

                                                                        if (res?.data?.reload == "1") {
                                                                            dispatch({
                                                                                type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                                                                                payload: payload?.holonInstanceId,
                                                                                callback: (data) => {
                                                                                    setHolonInStanceDetail(data);
                                                                                    setLoadingHolonInstanceDetail(false);
                                                                                }
                                                                            })
                                                                        }
                                                                        if (res?.data?.screenId) {
                                                                            ReloadActionWithScreen({
                                                                                screenId: res?.data?.screenId,
                                                                                id: holonInStanceDetail?.name,
                                                                                router: router
                                                                            })
                                                                        }
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: getMessageCommon({
                                                                                response: res,
                                                                                error: null,
                                                                                actionName: x?.note
                                                                            }),
                                                                            newWindow: true
                                                                        });
                                                                    },
                                                                    failed: (msg) => {
                                                                        setLoadingAsyncPost(null);
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: msg,
                                                                            newWindow: true
                                                                        })
                                                                    },
                                                                    error: () => {
                                                                        setLoadingAsyncPost(null);
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: Validator.renderMessage(t("I0005"), {
                                                                                0: x?.note
                                                                            }),
                                                                            newWindow: true
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    });
                                                }} title={x?.note} disabled={x?.IsEditabled == "0" ||
                                                    (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false} />
                                            }
                                            if (x?.type == Constants.TYPE_ACTIONS.POST.VALUE) {
                                                return <Button loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                    showPopupNotification({
                                                        typePopup: 'confirm',
                                                        message: Validator.renderMessage(t("confirm_action"), {
                                                            0: x?.note
                                                        }),
                                                        newWindow: true,
                                                        callback: () => {
                                                            setLoadingAsyncPost(index)
                                                            dispatch({
                                                                type: actionsHolonInstance.REQUEST_API_WITH_URL,
                                                                payload: {
                                                                    url: stringApi,
                                                                    method: 'POST',
                                                                    data: JSON.parse(stringPattern)
                                                                },
                                                                callback: {
                                                                    success: (res) => {
                                                                        setLoadingAsyncPost(null)
                                                                        if (res?.data?.reload == "1") {
                                                                            dispatch({
                                                                                type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                                                                                payload: payload?.holonInstanceId,
                                                                                callback: (data) => {
                                                                                    setHolonInStanceDetail(data);
                                                                                    setLoadingHolonInstanceDetail(false);
                                                                                }
                                                                            })
                                                                        }
                                                                        if (res?.data?.screenId) {
                                                                            ReloadActionWithScreen({
                                                                                screenId: res?.data?.screenId,
                                                                                id: holonInStanceDetail?.name,
                                                                                router: router
                                                                            })
                                                                        }
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: getMessageCommon({
                                                                                response: res,
                                                                                error: null,
                                                                                actionName: x?.note
                                                                            }),
                                                                            newWindow: true
                                                                        });
                                                                    },
                                                                    failed: (msg) => {
                                                                        setLoadingAsyncPost(null);
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: msg,
                                                                            newWindow: true
                                                                        })
                                                                    },
                                                                    error: () => {
                                                                        setLoadingAsyncPost(null);
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: Validator.renderMessage(t("I0005"), {
                                                                                0: x?.note
                                                                            }),
                                                                            newWindow: true
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    });
                                                }} title={x?.note} disabled={x?.IsEditabled == "0" ||
                                                    (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false} />
                                            }
                                            return <a style={{
                                                pointerEvents: x?.IsEditabled == "0" ? 'none' : 'unset',
                                                backgroundColor: 'white'
                                            }} key={index} onClick={() => EventPopup.removeLastPopup()} className={classes['custom-button']} href={stringApi} rel="noreferrer">{x?.note}</a>
                                        } catch (error) {
                                            return <Button key={index} variant='outlined' disabled title={x?.note} />
                                        }
                                    } else {
                                        if (x?.type == Constants.TYPE_ACTIONS.ASYNCPOST.VALUE) {
                                            return <Button loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                showPopupNotification({
                                                    typePopup: 'confirm',
                                                    message: Validator.renderMessage(t("confirm_action"), {
                                                        0: x?.note
                                                    }),
                                                    newWindow: true,
                                                    callback: () => {
                                                        setLoadingAsyncPost(index)
                                                        dispatch({
                                                            type: actionsHolonInstance.REQUEST_API_WITH_URL,
                                                            payload: {
                                                                url: stringApi,
                                                                method: 'POST',
                                                            },
                                                            callback: {
                                                                success: (res) => {
                                                                    setLoadingAsyncPost(null)

                                                                    if (res?.data?.reload == "1") {
                                                                        dispatch({
                                                                            type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                                                                            payload: payload?.holonInstanceId,
                                                                            callback: (data) => {
                                                                                setHolonInStanceDetail(data);
                                                                                setLoadingHolonInstanceDetail(false);
                                                                            }
                                                                        })
                                                                    }

                                                                    if (res?.data?.screenId) {
                                                                        ReloadActionWithScreen({
                                                                            screenId: res?.data?.screenId,
                                                                            id: holonInStanceDetail?.name,
                                                                            router: router
                                                                        })
                                                                    }

                                                                    showPopupNotification({
                                                                        typePopup: 'message',
                                                                        message: getMessageCommon({
                                                                            response: res,
                                                                            error: null,
                                                                            actionName: x?.note
                                                                        }),
                                                                        newWindow: true
                                                                    });
                                                                },
                                                                failed: (msg) => {
                                                                    setLoadingAsyncPost(null);
                                                                    showPopupNotification({
                                                                        typePopup: 'message',
                                                                        message: msg,
                                                                        newWindow: true
                                                                    })
                                                                },
                                                                error: () => {
                                                                    setLoadingAsyncPost(null);
                                                                    showPopupNotification({
                                                                        typePopup: 'message',
                                                                        message: Validator.renderMessage(t("I0005"), {
                                                                            0: x?.note
                                                                        }),
                                                                        newWindow: true
                                                                    })
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                            }} title={x?.note} disabled={x?.IsEditabled == "0" ||
                                                (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false} />
                                        } else if (x?.type == Constants.TYPE_ACTIONS.POST.VALUE) {
                                            return <Button loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                showPopupNotification({
                                                    typePopup: 'confirm',
                                                    message: Validator.renderMessage(t("confirm_action"), {
                                                        0: x?.note
                                                    }),
                                                    newWindow: true,
                                                    callback: () => {
                                                        setLoadingAsyncPost(index)
                                                        dispatch({
                                                            type: actionsHolonInstance.REQUEST_API_WITH_URL,
                                                            payload: {
                                                                url: stringApi,
                                                                method: 'POST',
                                                            },
                                                            callback: {
                                                                success: (res) => {
                                                                    setLoadingAsyncPost(null)
                                                                    if (res?.data?.reload == "1") {
                                                                        dispatch({
                                                                            type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                                                                            payload: payload?.holonInstanceId,
                                                                            callback: (data) => {
                                                                                setHolonInStanceDetail(data);
                                                                                setLoadingHolonInstanceDetail(false);
                                                                            }
                                                                        })
                                                                    }
                                                                    if (res?.data?.screenId) {
                                                                        ReloadActionWithScreen({
                                                                            screenId: res?.data?.screenId,
                                                                            id: holonInStanceDetail?.name,
                                                                            router: router
                                                                        })
                                                                    }
                                                                    showPopupNotification({
                                                                        typePopup: 'message',
                                                                        message: getMessageCommon({
                                                                            response: res,
                                                                            error: null,
                                                                            actionName: x?.note
                                                                        }),
                                                                        newWindow: true
                                                                    })
                                                                },
                                                                failed: (msg) => {
                                                                    setLoadingAsyncPost(null);
                                                                    showPopupNotification({
                                                                        typePopup: 'message',
                                                                        message: msg,
                                                                        newWindow: true
                                                                    })
                                                                },
                                                                error: () => {
                                                                    setLoadingAsyncPost(null);
                                                                    showPopupNotification({
                                                                        typePopup: 'message',
                                                                        message: Validator.renderMessage(t("I0005"), {
                                                                            0: x?.note
                                                                        }),
                                                                        newWindow: true
                                                                    })
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                            }} title={x?.note} disabled={x?.IsEditabled == "0" ||
                                                (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false} />
                                        } else {
                                            return <a style={{
                                                pointerEvents: x?.IsEditabled == "0" ? 'none' : 'unset',
                                                backgroundColor: 'white',
                                                opacity: loadingAsyncPost && loadingAsyncPost != index ? 0.7 : 1
                                            }} key={index} onClick={() => EventPopup.removeLastPopup()} className={classes['custom-button']} href={x?.api} rel="noreferrer">{x?.note}</a>
                                        }
                                    }
                                } else {
                                    return <Button key={index} variant='outlined' disabled title={x?.note} />
                                }
                            })}
                        </div>
                    </BoxBody>
                </Box> : <></>
            }
        </>
    )
}

export default ActionHolonInstance