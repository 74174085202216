import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionSender from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";

function* getListSender() {
    yield takeEvery(actionSender.GET_SENDER_LIST, function* (payload) {
        try {
            const response = yield call(() => factory.getListSender(payload?.payload));
            const data = response?.records?.map(x => {
                x.attributes = x?.attributes?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                x.connections = x?.connections?.reduce((obj, cur) => ({ ...obj, [cur?.type]: cur }), {});
                return x;
            });
            yield put({
                type: actionSender.GET_SENDER_LIST_SUCCESS,
                payload: {
                    ...response,
                    records: data
                }
            })
        } catch (error) {

        }
    })
}

function* updateListSender() {
    yield takeEvery(actionSender.UPDATE_SENDER, function* (payload) {
        try {
            const response = yield call(() => factory.updateSender(payload?.payload));
            if (response?.data?.id) {
                yield put({
                    type: actionSender.UPDATE_SENDER_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield put({
                    type: actionSender.UPDATE_SENDER_FAILED,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

function* createSender() {
    yield takeEvery(actionSender.CREATE_SENDER, function* (payload) {
        try {
            const response = yield call(() => factory.createSender(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success && payload?.callback?.success()
                yield put({
                    type: actionSender.CREATE_SENDER_SUCCESS,
                    payload: response
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
                yield put({
                    type: actionSender.CREATE_SENDER_FAILED,
                })
            }
        } catch (error) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

function* getSenderDetail() {
    yield takeEvery(actionSender.GET_SENDER_DETAIL, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailSender(payload?.payload));
            if(response?.data?.id){
                yield payload?.callback?.success && payload?.callback?.success(response?.data);
            }
            yield put({
                type: actionSender.GET_SENDER_DETAIL_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

function* getHolonTemplateSender() {
    yield takeEvery(actionSender.GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplateDetail(payload?.payload));
            yield put({
                type: actionSender.GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}

function* deleteSender() {
    yield takeEvery(actionSender.DELETE_SENDER, function* (payload) {
        try {
            const response = yield call(() => factory.deleteSender(payload?.payload));
            if (!response?.error) {
                yield put({
                    type: actionSender.DELETE_SENDER_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            } else {
                yield put({
                    type: actionSender.DELETE_SENDER_FAILED,
                })
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed()
            yield put({
                type: actionSender.DELETE_SENDER_FAILED,
            })

            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getListSender),
        fork(updateListSender),
        fork(createSender),
        fork(getSenderDetail),
        fork(getHolonTemplateSender),
        fork(deleteSender)
    ])
}