import useTrans from 'hooks/use-trans'
import React from 'react'
import WarehouseInContainer from 'shared/container/warehousein/WarehouseInContainer';

function WarehouseInPage() {
    const { t } = useTrans();

    document.title = t("warehouse.incoming_inventory_management")
    return (
        <WarehouseInContainer />
    )
}

export default WarehouseInPage