import React from 'react'

function IconXlsx() {
    return (
        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5519 0H7.55198H6.93204L6.49403 0.438002L0.438002 6.49441L0 6.93241V7.55203V20.0381C0 22.2653 1.81178 24.0775 4.03931 24.0775H15.5519C17.7787 24.0775 19.5905 22.2653 19.5905 20.0381V4.03936C19.5905 1.81183 17.7787 0 15.5519 0ZM18.0953 20.0381C18.0953 21.4431 16.9566 22.5818 15.5519 22.5818H4.03931C2.63398 22.5818 1.49529 21.4431 1.49529 20.0381V7.55198H5.43218C6.60243 7.55198 7.55198 6.60309 7.55198 5.43251V1.49562H15.5519C16.9566 1.49562 18.0953 2.63431 18.0953 4.03936V20.0381Z" fill="#0E525E" />
            <path d="M5.80691 14.2306L6.45836 13.3808C6.52862 13.2853 6.56737 13.1828 6.56737 13.0873C6.56737 12.8765 6.40122 12.6975 6.17136 12.6975C6.03672 12.6975 5.90275 12.7612 5.80032 12.8955L5.29597 13.5598H5.28943L4.78507 12.8955C4.68265 12.7612 4.54867 12.6975 4.4147 12.6975C4.18483 12.6975 4.01874 12.8765 4.01874 13.0873C4.01874 13.1828 4.05683 13.2853 4.12708 13.3808L4.77854 14.2306L4.05024 15.1822C3.97999 15.278 3.94189 15.3802 3.94189 15.4761C3.94189 15.6869 4.10804 15.8658 4.33785 15.8658C4.47179 15.8658 4.60642 15.8018 4.70823 15.6678L5.28938 14.9011H5.29592L5.87707 15.6678C5.97954 15.8018 6.11347 15.8658 6.24745 15.8658C6.47731 15.8658 6.64341 15.6869 6.64341 15.4761C6.64341 15.3802 6.60532 15.2781 6.53506 15.1822L5.80691 14.2306Z" fill="#0E525E" />
            <path d="M8.42631 15.0863C8.28575 15.061 8.22203 14.9841 8.22203 14.8055V11.9184C8.22203 11.6245 8.03025 11.4456 7.77486 11.4456C7.51283 11.4456 7.32764 11.6245 7.32764 11.9184V14.882C7.32764 15.5145 7.57057 15.8658 8.27921 15.8658H8.31796C8.64366 15.8658 8.78418 15.6931 8.78418 15.476C8.78418 15.278 8.68176 15.1312 8.42631 15.0863Z" fill="#0E525E" />
            <path d="M10.829 13.8984L10.4901 13.86C10.1388 13.8219 10.0626 13.745 10.0626 13.6045C10.0626 13.4577 10.1776 13.3619 10.4521 13.3619C10.6753 13.3619 10.8861 13.4065 11.0779 13.4962C11.1993 13.5536 11.2697 13.5727 11.3333 13.5727C11.5441 13.5727 11.6847 13.4193 11.6847 13.2213C11.6847 13.068 11.5954 12.9596 11.4226 12.87C11.1613 12.736 10.829 12.6658 10.4835 12.6658C9.6725 12.6658 9.20624 13.0552 9.20624 13.662C9.20624 14.1985 9.54445 14.5308 10.2156 14.6073L10.5538 14.6457C10.9182 14.6841 11.001 14.7606 11.001 14.9077C11.001 15.0801 10.848 15.1951 10.5223 15.1951C10.2221 15.1951 9.98574 15.0992 9.7618 14.9714C9.65999 14.914 9.58315 14.8884 9.49977 14.8884C9.29549 14.8884 9.12939 15.0483 9.12939 15.2462C9.12939 15.393 9.19312 15.5079 9.34675 15.6038C9.6022 15.7634 9.99228 15.8977 10.4776 15.8977C11.3523 15.8977 11.8573 15.4698 11.8573 14.8374C11.8574 14.32 11.5573 13.9814 10.829 13.8984Z" fill="#0E525E" />
            <path d="M14.2654 14.2306L14.9169 13.3808C14.9872 13.2853 15.0259 13.1828 15.0259 13.0873C15.0259 12.8765 14.8598 12.6975 14.6299 12.6975C14.4954 12.6975 14.3613 12.7612 14.2589 12.8955L13.7546 13.5598H13.748L13.2436 12.8955C13.1412 12.7612 13.0072 12.6975 12.8732 12.6975C12.6434 12.6975 12.4772 12.8765 12.4772 13.0873C12.4772 13.1828 12.5153 13.2853 12.5856 13.3808L13.237 14.2306L12.5087 15.1822C12.4385 15.278 12.4004 15.3802 12.4004 15.4761C12.4004 15.6869 12.5666 15.8658 12.7964 15.8658C12.9303 15.8658 13.065 15.8018 13.1667 15.6678L13.7479 14.9011H13.7545L14.3356 15.6678C14.4381 15.8018 14.572 15.8658 14.706 15.8658C14.9359 15.8658 15.102 15.6869 15.102 15.4761C15.102 15.3802 15.0639 15.2781 14.9936 15.1822L14.2654 14.2306Z" fill="#0E525E" />
        </svg>

    )
}

export default IconXlsx