import React from 'react'
import classes from './Box.module.scss';
import HolonTemplateName from 'shared/components/holon-instance/holon-template-name/HolonTemplateName';

function BoxTemplate({ data = []}) {
    return (
        <div className={classes['BoxTemplate']}>
            {data?.length > 0 ? [...data]?.slice(0, 3)?.map(v => <div key={v} className={classes['item-select-template']}>
                <HolonTemplateName id={v} />
            </div>) : <></>}
            {data?.length > 3 && <>&#8230;</>}
        </div>
    )
}

export default BoxTemplate