import React, { useEffect } from 'react'
import classes from './FaqContainer.module.scss';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import FaqAction from 'redux/faq/action';
import IconDelete from 'assets/icon/IconDelete';
import EventRegister, { EVENT_SHOW_FAQ_DETAIL_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_FAQ, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_POPUP_FAQ_CATEGORY, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_POPUP_UPDATE_FAQ } from 'utils/EventRegister';
import useRouter from 'hooks/use-router';
import Constants from 'utils/Constants';
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import FormRadio from 'shared/components/custom-form/FormRadio';
import FormSelect from 'shared/components/custom-form/FormSelect';
import useCustomState from 'hooks/use-custom-state';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';

function FaqManagementContainer() {
    const { t } = useTrans();
    const methods = useForm({
        defaultValues: {
            lang: Constants.T001.JP.CODE
        }
    });
    const dispatch = useDispatch();
    const { faq, faqCategory, loadingDeleteFaq, loadingGetList } = useSelector(state => state.faq);
    const router = useRouter()
    const [optionLanguages, setOptionLanguages] = useCustomState([]);
    const [optionCategory, setOptionCategory] = useCustomState([]);
    const [categoryWithLanguage, setCategoryWithLanguage] = useCustomState([]);

    const OptionRelease = [
        {
            value: 1,
            key: 1,
            label: t("Holon_Template.valid")
        },
        {
            value: 2,
            key: 2,
            label: t("Holon_Template.invalid")
        },
    ]

    useEffect(() => {
        if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
            dispatch({
                type: FaqAction.GET_FAQ_CATEGORIES_WITH_LANGUAGE,
                payload: {
                    isAll: true,
                    [Constants.QueryParams.LANGUAGE.VALUE]: router.get(Constants.QueryParams.LANGUAGE.VALUE)
                },
                callback: (data) => {
                    setCategoryWithLanguage(data?.records);
                }
            })
        }
    }, [
        router.get(Constants.QueryParams.LANGUAGE.VALUE)
    ])


    useEffect(() => {
        let data = [];
        if (faqCategory?.records?.length > 0) {
            [...faqCategory?.records].forEach(value => {
                data.push({
                    key: value?.id,
                    value: value?.id,
                    label: value?.categoryName,
                })
            });

            let params = { ...router.getAll() };

            if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
                let index = data?.findIndex(x => x?.key == router.get(Constants.QueryParams.CATEGORY.VALUE));
                if (index != -1) {
                    methods.setValue("categoryId", router.get(Constants.QueryParams.CATEGORY.VALUE));
                } else {
                    delete params[Constants.QueryParams.CATEGORY.VALUE];
                }
            }
            router.replace({
                params: {
                    ...params
                }
            })
        }
        setOptionCategory(data)
    }, [faqCategory?.records, router.get(Constants.QueryParams.CATEGORY.VALUE)])

    useEffect(() => {
        router.replace({
            params: {
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LANGUAGE.VALUE]: Constants.T001.JP.CODE,
            }
        })
    }, [])

    useEffect(() => {
        dispatch({
            type: FaqAction.GET_FAQ_WITH_CATEGORY,
            payload: {
                ...router.getAll(),
            }
        })
    }, [
        router.get([Constants.QueryParams.OFFSET.VALUE]),
        router.get([Constants.QueryParams.LIMIT.VALUE]),
        router.get([Constants.QueryParams.CATEGORY.VALUE]),
        router.get([Constants.QueryParams.LANGUAGE.VALUE]),
    ])

    useEffect(() => {
        let params = {};
        if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
            params['lang'] = router.get(Constants.QueryParams.LANGUAGE.VALUE);
        } else {
            params['lang'] = Constants.T001.JP.CODE;
        }
        if (router.get(Constants.QueryParams.RELEASE.VALUE)) {
            params['status'] = router.get(Constants.QueryParams.RELEASE.VALUE) == 1 ? Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE : Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE;
        }
        if (router.get(Constants.QueryParams.LANGUAGE.VALUE)) {
            dispatch({
                type: FaqAction.GET_FAQ_CATEGORIES,
                payload: {
                    isAll: true,
                    ...params
                }
            })
        }
    }, [router.get(Constants.QueryParams.LANGUAGE.VALUE), router.get(Constants.QueryParams.RELEASE.VALUE)])

    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: t(`faq_management.lang.${Constants.T001[key].NAME}`),
            })
        });
        setOptionLanguages(data)
    }, [])

    let dataHeadCell = [
        {
            label: t('faq_management.detail'),
            field: "detail",
            maxWidth: 70,
            width: 70,
            minWidth: 70,
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            alignHeader: 'left',
            align: 'left',
            component: (_props) => {
                return <div style={{ justifyContent: 'flex-start' }} className={classes['Custom-Row-Table']}>
                    <ButtonLink
                        size="nomarl"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditFaq(_props?.data?.id)
                        }}
                        title={t("faq_management.detail")}
                    />
                    {/* <div
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteFaq(_props?.data?.id)
                        }}
                        loading={loadingDeleteFaq}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <IconDelete fontSize={20} color="#FFFFFF" />
                    </div> */}
                </div>
            }
        },
        {
            label: t('No'),
            field: "No",
            minWidth: '40px',
            width: '40px',
            maxWidth: '40px',
            alignHeader: 'center',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'center',
            component: (props) => {
                return props?.stt + 1
            }
        },
        {
            label: t('category'),
            field: "category",
            minWidth: '200px',
            width: '200px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (props) => {
                return categoryWithLanguage?.find(x => x?.id == props?.data?.categoryId)?.categoryName
            }
        },
        {
            label: t('question'),
            field: "question",
            minWidth: '300px',
            width: '300px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (props) => {
                return <div className={classes['Custom-Text-Table']} dangerouslySetInnerHTML={{
                    __html: props?.children
                }} />
            }
        },
        {
            label: t('answer'),
            field: "answer",
            minWidth: '300px',
            width: '300px',
            alignHeader: 'left',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'left',
            component: (props) => {
                return <div className={classes['Custom-Text-Table']} dangerouslySetInnerHTML={{
                    __html: props?.children
                }} />
            }
        },
        {
            label: t('faq_management.release'),
            field: "release",
            minWidth: '80px',
            width: '80px',
            alignHeader: 'center',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            align: 'center',
            component: (props) => {
                return <div className={classes['Custom-Row-CheckBox']}>
                    {props?.data?.status == Constants.STATUS_CATEGORY.PUBLIC.VALUE ?
                        <Button onClick={() => handleUpdateStatus(props?.data)} color='transparent' startIcon={<IconChecked />} />
                        : <Button onClick={() => handleUpdateStatus(props?.data)} color='transparent' startIcon={<IconCheck />} />
                    }
                </div>
            }
        },

    ];

    const handleUpdateStatus = (data) => {
        dispatch({
            type: FaqAction.UPDATE_FAQ_WITH_CATEGORY,
            payload: {
                ...data,
                status: data?.status == Constants.STATUS_CATEGORY.PUBLIC.VALUE ? Constants.STATUS_CATEGORY.PRIVATE.VALUE : Constants.STATUS_CATEGORY.PUBLIC.VALUE
            },
            callback: {
                success: () => {
                },
                failed: () => {
                }
            },
            paramsUrl: { ...router.getAll() }
        })
    }

    const handleOpenEditFaq = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_FAQ_DETAIL_POPUP,
            payload: {
                id,
                title: t("faq_management.FAQDetails"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                }
            }
        })
    }

    const handleOpenAddFaq = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_FAQ,
            payload: {
                title: t("faq_management.add_new_faq"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                }
            }
        })
    }

    const handleOpenCategory = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_FAQ_CATEGORY,
            payload: {
                title: t("faq_management.add_new_category"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: FaqAction.GET_FAQ_CATEGORIES_WITH_LANGUAGE,
                        payload: {
                            isAll: true,
                            [Constants.QueryParams.LANGUAGE.VALUE]: router.get(Constants.QueryParams.LANGUAGE.VALUE)
                        },
                        callback: (data) => {
                            setCategoryWithLanguage(data?.records);
                        }
                    })
                }
            }
        })
    }

    const onSubmit = (_data) => {
        router.replace({
            params: {
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.SEARCH.VALUE]: _data?.search,
                [Constants.QueryParams.RELEASE.VALUE]: _data?.release,
                [Constants.QueryParams.CATEGORY.VALUE]: _data?.categoryId,
                [Constants.QueryParams.LANGUAGE.VALUE]: _data?.lang,
            }
        })
    }


    useEffect(() => {
        if (router.get([Constants.QueryParams.CATEGORY.VALUE])) {
            methods.setValue("categoryId", router.get([Constants.QueryParams.CATEGORY.VALUE]))
        } else {
            methods.setValue("categoryId", null)
        }
        if (router.get([Constants.QueryParams.RELEASE.VALUE])) {
            methods.setValue("release", router.get([Constants.QueryParams.RELEASE.VALUE]))
        } else {
            methods.setValue("release", null)
        }
        if (router.get([Constants.QueryParams.LANGUAGE.VALUE])) {
            methods.setValue("lang", router.get([Constants.QueryParams.LANGUAGE.VALUE]))
        } else {
            methods.setValue("lang", Constants.T001.JP.CODE)
        }
    }, [
        router.get([Constants.QueryParams.CATEGORY.VALUE]),
        router.get([Constants.QueryParams.RELEASE.VALUE]),
        router.get([Constants.QueryParams.LANGUAGE.VALUE])])

    const onChangePage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e,
            },
        });
    }

    const onChangeRowPerPage = (e) => {
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            },
        });
    }

    return (
        <ContentLayout>
            <Breadcrumb>
                {t("faq_management.faq_management")}
            </Breadcrumb>
            <div className={classes['FaqManagementContainer']}>
                <CustomFormProvider {...methods}>
                    <form className='quick-submit-form' onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className={classes['FaqManagement-FormSearch']}>
                            <div className={classes['Form-Group']}>
                                <div className={classes['Form-Item_Left']}>
                                    <p>
                                        {t("faq_management.lang_choice")}
                                    </p>
                                </div>
                                <div className={classes['Form-Item-Center']}>
                                    <FormSelect
                                        fieldName='lang'
                                        options={optionLanguages}
                                        onChangeSelect={() => {
                                            methods.setValue("categoryId", null);
                                            let params = { ...router.getAll() };
                                            if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
                                                delete params[Constants.QueryParams.CATEGORY.VALUE];
                                            }
                                            router.replace({
                                                params: {
                                                    ...params
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={classes['Form-Group']}>
                                <div className={classes['Form-Item_Left']}>
                                    <p>
                                        {t("faq_management.category_selection")}
                                    </p>
                                </div>
                                <div className={classes['Form-Item-Center']}>
                                    <FormSelect
                                        validate={[]}
                                        fieldName='categoryId'
                                        placeholder=''
                                        options={optionCategory}
                                    />
                                </div>
                                <Button onClick={handleOpenCategory} title={t("faq_management.category_setting")} />
                            </div>
                            <div className={classes['Form-Group-Row']}>
                                <div className={classes['Form-Group_radio-button']}>
                                    <p>{t("faq_management.category_visibility_settings")}</p>
                                    <div style={{ width: 150 }}>
                                        <FormRadio
                                            fieldName="release"
                                            options={OptionRelease}
                                            onChangeValue={() => {
                                                methods.setValue("categoryId", null);
                                                let params = { ...router.getAll() };
                                                if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
                                                    delete params[Constants.QueryParams.CATEGORY.VALUE];
                                                }
                                                router.replace({
                                                    params: {
                                                        ...params
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes['Form-Group_button']}>
                                    <Button
                                        size="large"
                                        type="button"
                                        onClick={handleOpenAddFaq}
                                        title={t("faq_management.add_new_faq")}
                                    />
                                </div>
                            </div>
                        </div>
                        <button type='submit' hidden />
                    </form>
                </CustomFormProvider>
                <div className={classes['body']}>
                    <CustomTable loading={loadingGetList} data={faq?.records ?? []} headCell={dataHeadCell} />
                    <div className={classes['custom-pagination']}>
                        <CustomPagination page={router.get(Constants.QueryParams.OFFSET.VALUE)} limit={router.get(Constants.QueryParams.LIMIT.VALUE)} isRowPerpage={true} onChangeRowPerpage={onChangeRowPerPage} onChange={onChangePage} total={faq?.total ?? 0} />
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default FaqManagementContainer