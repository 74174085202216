import React from 'react'

function IconPlusWhite({ fontSize = 18}) {
    return (
        <svg fontSize={fontSize} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="white" />
            <path d="M5 9H13" stroke="#929292" strokeWidth="2" strokeLinecap="round" />
            <path d="M9 5L9 13" stroke="#929292" strokeWidth="2" strokeLinecap="round" />
        </svg>

    )
}

export default IconPlusWhite