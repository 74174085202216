const { default: actionAlarm } = require("./action");

const initalState = {
    listAlarm: {
        records: [],
        limit: 0,
        page: 0,
        total: 0,
        totalPage: 0
    },
    loading: {
        loadingGetAlarm: false
    },
    alarmMaster: {}
}

const myReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionAlarm.GET_LIST_ALARM:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetAlarm: true
                }
            }
        case actionAlarm.GET_LIST_ALARM_SUCCESS:
            return {
                ...state,
                listAlarm: action.payload,
                loading: {
                    ...state.loading,
                    loadingGetAlarm: false
                }
            }
        case actionAlarm.GET_ALARM_MASTER_SUCCESS:
            return {
                ...state,
                alarmMaster: action.payload,
            }

        default:
            return {
                ...state
            }
    }
}

export default myReducer;