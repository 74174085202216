const actionSender  = {
    GET_SENDER_LIST : "GET_SENDER_LIST",
    GET_SENDER_LIST_SUCCESS : "GET_SENDER_LIST_SUCCESS",
    GET_SENDER_LIST_FAILED : "GET_SENDER_LIST_FAILED",
    
    GET_SENDER_DETAIL : "GET_SENDER_DETAIL",
    GET_SENDER_DETAIL_SUCCESS : "GET_SENDER_DETAIL_SUCCESS",
    GET_SENDER_DETAIL_FAILED : "GET_SENDER_DETAIL_FAILED",

    GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER : "GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER",
    GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER_SUCCESS : "GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER_SUCCESS",
    GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER_FAILED : "GET_HOLON_TEMPLATE_DETAIL_WITH_SENDER_FAILED",

    UPDATE_SENDER : "UPDATE_SENDER",
    UPDATE_SENDER_SUCCESS : "UPDATE_SENDER_SUCCESS",
    UPDATE_SENDER_FAILED : "UPDATE_SENDER_FAILED",

    DELETE_SENDER : "DELETE_SENDER",
    DELETE_SENDER_SUCCESS : "DELETE_SENDER_SUCCESS",
    DELETE_SENDER_FAILED : "DELETE_SENDER_FAILED",

    CREATE_SENDER : "CREATE_SENDER",
    CREATE_SENDER_SUCCESS : "CREATE_SENDER_SUCCESS",
    CREATE_SENDER_FAILED : "CREATE_SENDER_FAILED",
}
export default actionSender;