import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import OrdersManagementContainer from 'shared/container/order-management/OrderManagementContainer';

function OrderManagement() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t('order_management.title');
    }, []);
    return (
        <OrdersManagementContainer />
    )
}

export default OrderManagement