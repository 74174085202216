import useTrans from 'hooks/use-trans';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import { getFunction } from '../Util';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid'

function FormSelect (props) {
    const {
        master,
        fieldName = 'label',
        fieldValue = 'value',
        name,
        readonly = false,
        disabled = false,
        placeholder,
        defaultValue = "",
        register,
        validate=[],
        field = ''
    } = props;
    const { trans } = useTrans();
    const dispatch = useDispatch();
    const fieldData = useSelector(state => state.app[name]);
    const [masterData, setMasterData] = useState(undefined);
    const [initValue, setInitValue] = useState("");
    const { formState: { errors }, control, setValue, getValues } = useFormContext();

    useEffect(() => {
        if (defaultValue) {
            try {
                const json = JSON.parse(defaultValue);
                if (json["default"]) {
                    setInitValue(json["default"]);
                    setValue(name, json["default"]);
                    getFunction(json["trigger"], field?.defaultValue, dispatch, setValue, getValues);
                }
            } catch (error) {
                setInitValue(defaultValue);
            }
        }
    }), [];

    useEffect(() => {
        if (master?.length > 0) {
            setMasterData(master)
        }
    }, [master]);

    useEffect(() => {
        if (fieldData) {
            setMasterData(fieldData);
        }
    }, [fieldData])

    const onChangeSelect = (e) => {
        getFunction("onChange", field?.defaultValue, dispatch, setValue, getValues);
    }

    const onBlurSelect = (e) => {
        getFunction("onBlur", field?.defaultValue, dispatch, setValue, getValues);
    }
    return (
        <Controller
            control={control}
            name={name}
            rules={{ validate: Validator.genValidate(validate, name) }}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => {
                useEffect(() => {
                    let label = masterData?.find(x => x.value == value)?.label;
                    if (label) {
                        localStorage.setItem(name, label);
                    }
                    return () => {
                        localStorage.removeItem(name);
                    }
                }, [value, masterData]);
                return (<div className='form-wrap'>
                    <div className={`form-select form-input ${errors[name] ? 'error' : ''}`}>
                        <select
                            id={`select-${uuidv4()}`}
                            ref={ref}
                            style={{ appearance: 'none'}}
                            onChange={(e) => {
                                onChange(e);
                                onChangeSelect(e);
                            }}
                            onBlur={(e) => {
                                onBlur(e);
                                onBlurSelect(e);
                            }}
                            name={name}
                            readOnly={readonly}
                            disabled={disabled}
                            className="w-100"
                            value={value ?? initValue}
                        >
                            <option value="">{placeholder}</option>
                            {masterData?.map((item, index) => (
                                <option key={index} value={item[fieldValue]}>
                                    {item[fieldName]}
                                </option>
                            ))}
                        </select>
                    </div>
                    { errors[name] && 
                    <span className='error-msg'>
                        {
                            Validator.renderMessage(trans(errors[name]?.message), {0: field?.messageKey ? trans(field?.labelKey) : trans(name)})
                        }
                    </span> 
                    }
                </div>)
            }}
        />

    )
}
FormSelect.defaultProps = {
    placeholder: ""
}
export default FormSelect;