import IconPlusWhite from 'assets/icon/IconPlusWhite';
import React from 'react'
import FormSearch from 'shared/components/common/form-search/FormSearch';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';
import classes from './CommonAction.module.scss';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE } from 'utils/EventRegister';
import { useDispatch } from 'react-redux';
import Constants from 'utils/Constants';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import DropdownActions from '../dropdown-actions/DropdownActions';

function CommonAction(props) {
    const {
        isUseAddFunction,
        holonTemplateID,
        isHiddenBtnAdd,
        ActionSection,
        titleCreatePopup,
        subHolonTemplateID,
        cNAME,
        cID,
        userCompany,
        isCargo,
        isShowCategory,
        typeCategory,
        dataPreset,
        isHolonTemplateName,
        actionCommon,
        template_id,
        holonTemplate,
        headHolonInstance,
        listCheckbox,
        warehouse,
        requireConnectionKey,
        requireConnectionHolonId,
        listTemplateCreation,
        ExcludeIds,
        holonTemplateIDActive
    } = props;

    const dispatch = useDispatch();
    const router = useRouter();
    const { t } = useTrans();

    const handleAddInstance = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            payload: {
                title: titleCreatePopup ? titleCreatePopup : t("holon.create_holon_instance"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    if (holonTemplateID) {
                        let params = {
                            [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonTemplateID
                        };

                        if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                            params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
                        } else if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
                            params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
                        }
                        dispatch({
                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                            payload: {
                                ...router.getAll(),
                                [Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE]: subHolonTemplateID,
                                [Constants.QueryParams.CONNECTION_NAME.VALUE]: cNAME,
                                [Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE]: cID,
                                [Constants.QueryParams.USER_IN_COMPANY.VALUE]: userCompany,
                                [Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE]: requireConnectionKey,
                                [Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE]: requireConnectionHolonId,
                                [Constants.QueryParams.EXCLUDE_IDS.VALUE]: ExcludeIds,
                                ...params
                            }
                        });
                    }
                },
                templateId: holonTemplateID,
                subHolonTemplateId: subHolonTemplateID,
                isCargo: isCargo,
                isShowCategory: isShowCategory,
                typeCategory: typeCategory,
                dataPreset: dataPreset,
                isHolonTemplateName: isHolonTemplateName,
                userCompany: userCompany,
                requireConnectionKey: requireConnectionKey,
                requireConnectionHolonId: requireConnectionHolonId,
                dataTemplate: listTemplateCreation,
                cNAME: cNAME,
                cID: cID
            }
        })
    }

    return (
        <>
            <FormSearch className={classes['wrap-form-search']}>
                <DropdownActions
                    list={actionCommon}
                    cNAME={cNAME}
                    cID={cID}
                    userCompany={userCompany}
                    subHolonTemplateID={subHolonTemplateID}
                    headHolonInstance={headHolonInstance}
                    holonTemplateID={holonTemplateID}
                    template_id={template_id}
                    holonTemplate={holonTemplate}
                    listCheckbox={listCheckbox}
                    warehouse={warehouse}
                    requireConnectionKey={requireConnectionKey}
                    requireConnectionHolonId={requireConnectionHolonId}
                    ExcludeIds={ExcludeIds}

                />
                {
                    isUseAddFunction ?
                        (!holonTemplateID || holonTemplateIDActive || isHiddenBtnAdd())
                            ? <ButtonAdd
                                onClick={handleAddInstance}
                                color='yellow'
                                size='large'
                                startIcon={<IconPlusWhite />}
                                disabled={holonTemplateID && holonTemplateID?.split("-")?.length > 1 ? true : false}
                            /> : <></>
                        :
                        <>{ActionSection}</>
                }
            </FormSearch>
        </>
    )
}

export default CommonAction