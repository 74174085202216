import React, { useEffect, Fragment } from "react";
import useTrans from "hooks/use-trans";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import Box from "shared/components/form-input/box/Box";
import classes from "./EstimatedFareContainer.module.scss";
import { useForm } from "react-hook-form";
import questionMarkCycle from 'assets/img/question-mark-cycle.png';
import exclamationMarkCycle from 'assets/img/exclamation-mark-cycle.png';

const ReferenceDeliveryTime = () => {
    const { t } = useTrans()
    const methods = useForm();

    return (
        <Box className={classes["estimated-fare-container"]}>
            <div>
                <CustomFormProvider {...methods}>
                    <div className={classes['reference-information']}>
                        <p>
                            {t('fare_calculation.reference_delivery_time')}
                        </p>
                        <p>
                            {t('fare_calculation.delivery_days_may_change')}
                        </p>
                    </div>
                    <div className={classes['reference-note']}>
                        <div className={`${classes['reference-note-confirm']} `}>
                            <p className={classes['header-note-confirm']}>
                                {t('fare_calculation.note')}
                            </p>
                            <div className={classes['content-note-confirm']}>
                                <div className={classes['title-infor']} >
                                    <p>{t('fare_calculation.items_cannot_be_handled')}</p>
                                    <p>{t('fare_calculation.calculate_volumetric_weight')}</p>
                                    <p>{t('fare_calculation.product_features')}</p>
                                    <p>{t('fare_calculation.notes_on_entering_recipient_info')}</p>
                                    <p>{t('fare_calculation.tracking_information_register')}</p>
                                    <p>{t('fare_calculation.international_mail_shipping')}</p>
                                </div>
                                <div className={classes['img-note-confirm']}>
                                    <img src={questionMarkCycle} />
                                </div>
                            </div>
                        </div>
                        <div className={classes["note-confirm-line"]}></div>
                        <div className={classes['reference-note-confirm']}>
                            <p className={classes['header-note-confirm']}>
                                {t('fare_calculation.please_confirm')}
                            </p>
                            <div className={classes['content-note-confirm']}>
                                <div className={classes['title-infor']} >
                                    <p>{t('fare_calculation.volumetric_weight')}</p>
                                    <p>{t('fare_calculation.cross_border_logistics_service_information')}</p>
                                    <p>{t('fare_calculation.packing_tips_and_precautions')}</p>
                                    <p>{t('fare_calculation.cost_effective_shipping')}</p>
                                    <p>{t('fare_calculation.the_appeal_of_combined')}</p>
                                    <p>{t('fare_calculation.daiso_good')}</p>

                                </div>
                                <div className={classes['img-note-confirm']}>
                                    <img src={exclamationMarkCycle} />
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomFormProvider>
            </div>
        </Box>
    );
};

export default ReferenceDeliveryTime;
