import React from "react";
import Menu from "shared/components/menu/Menu";

function Services(){
    return (
        <div className="container-fluid">
            <h1>Services</h1>
        </div>
    );
}


export default Services;