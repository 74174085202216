import useTrans from 'hooks/use-trans'
import React from 'react'
import UserAccountListContainer from 'shared/container/user/user-account-list/UserAccountListContainer';

function UserAccountListPage() {
    const { t } = useTrans();
    document.title = t("User_Account_List.title");
    return (
        <UserAccountListContainer />
    )
}

export default UserAccountListPage