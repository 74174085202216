import React, { useRef, useState } from 'react';
import classes from './DatePicker.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash';
import ErrorMessage from '../error-message/ErrorMessage';
import { IconButton } from '@mui/material';
import vector519 from 'assets/img/vector-519.png';
import vector520 from 'assets/img/vector-520.png';
import IconCalendar from 'assets/icon/IconCalendar';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import moment from 'moment';

const autoCorrectedDatePipe = isMask => isMask ? createAutoCorrectedDatePipe("dd/mm/yyyy") : createAutoCorrectedDatePipe("dd/mm/yyyy HH:MM")

const CustomDatePicker = (props) => {
    const {
        value = "",
        onChange,
        isClearable = false,
        disabled = false,
        endDate = "",
        inputRef,
        errors,
        isSearch,
        dateTime = false,
        validationFE,
        isRegexLabel,
        name,
        showTimeInput = false
    } = props;
    const [open, setOpen] = useState(false);
    const ref = useRef();
    const { t } = useTrans();

    let showError = false;
    let error = {
        message: '',
    };

    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    let id = `${uuidv4()}-root-portal`;

    let mask = !dateTime ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/];

    let date = value;
    if (!dateTime) {
        if (value && !moment(value)?._isValid) {
            date = null;
        }
    } else {
        if (value && !moment(value)?._isValid) {
            date = null;
        }
    }


    let propsAttributes = {};

    if (showTimeInput) {
        propsAttributes['showTimeInput'] = dateTime
    }
    return (
        <>
            <div
                ref={inputRef}
                id={id}
                className={`${classes['CustomDatePicker']} custom-date-picker 
                ${showError ? classes['DatePicker_Error'] : ''} ${isSearch ? classes['Custom_Form_Search'] : ''}`}
                style={{
                    pointerEvents: disabled ? 'none' : 'unset',
                    background: disabled ? '#D9D9D9' : '',
                    marginBottom: showError ? '5px' : '0px'
                }}
            >
                <DatePicker
                    showTimeSelect={!showTimeInput}
                    closeOnScroll={(e) => e.target === document}
                    ref={inputRef ? inputRef : ref}
                    locale={localStorage.getItem("i18nextLng") == Constants.T001.EN.CODE.toLowerCase() ? 'en' :
                        localStorage.getItem("i18nextLng") == Constants.T001.CN.CODE.toLowerCase() ? 'zh-CN' : 'ja'}
                    selected={date ? new Date(value) : null}
                    onChange={(date) => {
                        onChange && onChange(date);
                        showTimeInput && setOpen(false);
                    }}
                    timeCaption={t("date_picker.time")}
                    shouldCloseOnSelect={!showTimeInput}
                    timeIntervals={1}
                    placeholderText={!dateTime ? "dd/MM/yyyy" : "dd/MM/yyyy hh:mm:ss"}
                    isClearable={isClearable}
                    popperClassName="some-custom-class"
                    popperPlacement="bottom"
                    dateFormat={!dateTime ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm:ss"}
                    endDate={endDate}
                    disabled={disabled}
                    open={open}
                    timeInputLabel={`${t("date_picker.time")}:`}
                    onClickOutside={() => {
                        setOpen(false)
                    }}
                    {...propsAttributes}
                    name={name}
                    portalId={id}
                    customInput={<MaskedInput
                        pipe={(autoCorrectedDatePipe(!dateTime))}
                        mask={mask}
                        keepCharPositions={false}
                        guide={false}
                    />}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div className="custom-header">
                            <IconButton
                                className="btn-arrow-rounded-icon"
                                children={
                                    <img
                                        src={vector519}
                                        className="vector-icon"
                                        alt="vector icon"
                                    />
                                }
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                disableFocusRipple={true}
                                disableRipple={true}
                            />
                            <div>{`${date.getFullYear()}${t("date_picker.year")} ${date.getMonth() + 1}${t("date_picker.month")}`}
                            </div>
                            <IconButton
                                className="btn-arrow-rounded-icon"
                                children={
                                    <img
                                        src={vector520}
                                        className="vector-icon"
                                        alt="vector icon"
                                    />
                                }
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                disableFocusRipple={true}
                                disableRipple={true}
                            />
                        </div>
                    )}
                />
                {open ? (
                    <div onClick={() => setOpen(!open)} className={classes['Icon']}>
                        <IconCalendar />
                    </div>
                ) : (
                    <div onClick={() => setOpen(!open)} className={classes['Icon']}>
                        <IconCalendar />
                    </div>
                )}
            </div>
            {/* {showError ? <ErrorMessage message={error?.message} /> : <></>} */}
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default CustomDatePicker;
