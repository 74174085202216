import ApiOperation from "adapter/ApiOperation"

const factory = {
    getFareCalculationServiceCrossBorder: (id) => {
        let url = `/rest/api/v1/fare-calc/management/service/${id}`
        return ApiOperation.request({
            url: url,
            method: "GET"
        })
    },

    getFareCalculationService: () => {
        let url = `/rest/api/v1/fare-calc/management/service`
        return ApiOperation.request({
            url: url,
            method: "GET"
        })
    },

    getFareCalculationGlobalVariable: () => {
        let url = `/rest/api/v1/fare-calc/management/global-variable`
        return ApiOperation.request({
            url: url,
            method: "GET"
        })
    },

    getFareCalculationUserInput: () => {
        let url = `/rest/api/v1/fare-calc/management/user-input`
        return ApiOperation.request({
            url: url,
            method: "GET"
        })
    },
    getFareUserInput: () => {
        let url = `/rest/api/v1/fare-calc/user-input`
        return ApiOperation.request({
            url: url,
            method: "GET"
        })
    },

    getFareCalServiceContent: (id) => {
        let url = `/rest/api/v1/fare-calc/service-content/${id}`
        return ApiOperation.request({
            url: url,
            method: "GET"
        })
    },

    createFareCalculation: (payload) => {
        var url = `/rest/api/v1/fare-calc/management`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },

    updateFareDetailManagementService: (payload) => {
        var url = `/rest/api/v1/fare-calc/management/service/update-detail`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },

    createFareDetailManagementService: (payload) => {
        var url = `/rest/api/v1/fare-calc/management/service/create-detail`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },

    createFareCalcManagementCalculate: (payload) => {
        let url = `/rest/api/v1/fare-calc/management/calculate`
        return ApiOperation.request({
            url: url,
            method: "POST",
            data: payload
        })
    },
    deleteFareCalcManagementCalculate: (payload) => {
        let url = `/rest/api/v1/fare-calc/management/service/${payload}`
        return ApiOperation.request({
            url: url,
            method: "DELETE",
        })
    },
}
export default factory;