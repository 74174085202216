import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import classes from "./UploadImage.module.scss";
import _ from "lodash";
import IconClose from "assets/icon/IconClose";
import IconUpload from "assets/icon/IconUpload";
import IconCircleError from "assets/icon/IconCircleError";
import { useDispatch } from "react-redux";
import Action from 'redux/user/action';
import Loading from "../button/loading/Loading";
import ErrorMessage from "../error-message/ErrorMessage";
import Image from "shared/components/common/image/Image";
import ErrorMessageHandleRegex from "../error-message/ErrorMessageHandleRegex";
import ErrorMessageHandleFE from "../error-message/ErrorMessageHandleFE";
import { v4 as uuidv4 } from 'uuid'
import { useFormContext } from "react-hook-form";
import useTrans from "hooks/use-trans";

const UploadImage = (props) => {
    const { t } = useTrans();
    const {
        name,
        disabled = false,
        value,
        refInput,
        onChange,
        placeholder,
        onFocus,
        style,
        maxLength,
        onBlur,
        errors,
        maximumUpload = 100,
        isRegexLabel,
        validationFE,
        accept = "image/png, image/jpg, image/gif, image/jpeg, image/bmp",
        message = t("faq_management.hex_services.require_accept_image")
    } = props;
    const [imageUrl, setImageUrl] = useState();
    const [dragActive, setDragActive] = React.useState(false);
    const [warningDrag, setWarningDrag] = React.useState(false);
    const [loadingUpload, setLoadingUpload] = React.useState(false);
    const [messageErrorUpload, setMessageErrorUpload] = useState();
    const refImport = useRef();
    const dispatch = useDispatch();
    const methods = useFormContext();

    const onChangeUpload = (data) => {
        let acceptArr = accept?.split(",");
        if (acceptArr?.length > 0){
            let index = acceptArr?.findIndex(x => x?.trim() == data?.type);
            if (index == -1) {
                methods.setError(name, {
                    message: message
                });
                return;
            }
        }

        methods.setError(name, {
            message: undefined
        });
        methods.clearErrors(name)
        setLoadingUpload(true);
        let buttonAll = document.querySelectorAll('button');
        let arrButton = [];
        buttonAll.forEach(element => {
            if (element.disabled == false) {
                element.disabled = true;
                arrButton.push(element);
            }
        });
        setImageUrl(URL.createObjectURL(data));
        dispatch({
            type: Action.UPLOAD_IMAGE,
            payload: data,
            callback: (url) => {
                setLoadingUpload(false)
                onChange(url);
                setImageUrl(url);
                arrButton.forEach(element => {
                    element.disabled = false;
                });
            }
        })
    };
    const onChangeImport = (e) => {
        if (e.target.files?.length == 0) {
            return;
        }
        if (e.target.files) {
            onChangeUpload(e.target.files[0]);
        }
    };

    useEffect(() => {
        if (!value) {
            refInput ? refInput : refImport.current.value = null;
            setImageUrl(null);
        } else {
            setImageUrl(null);
        }
    }, [value]);

    let showError = false;
    let error = {
        message: "",
    };

    let arr = name.split(".");
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    const handleDrag = function (
        e
    ) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    useEffect(() => {
        var dropReceiver = document.getElementById("dropReceiverImage");

        if (dropReceiver) {
            dropReceiver.addEventListener("dragover", function (e) {
                e.stopPropagation();
                e.preventDefault();
                if (e.dataTransfer) {
                    e.dataTransfer.dropEffect = "copy";

                    for (var i = 0; i < e.dataTransfer.items.length; i++) {
                        if (
                            accept?.split(",")?.map(x => x?.trim())?.findIndex((x) => x == e?.dataTransfer?.items[i].type) ==
                            -1
                        ) {
                            setWarningDrag(true);
                        } else {
                            setWarningDrag(false);
                        }
                    }
                }
            });
        }
    }, []);

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files) {
            if (e.dataTransfer.files.length == 1) {
                if (e.dataTransfer.files[0]) {
                    var ext = e.dataTransfer.files[0].name.split(".").pop();
                    let acceptArr = accept?.split(",")?.map(x => x?.trim()?.split("/")?.[1]);
                    let index = acceptArr?.findIndex(x => x == ext);
                    if (index != -1) {
                        onChangeUpload(e.dataTransfer.files[0]);
                        setMessageErrorUpload(null);
                    } else {
                        setWarningDrag(false);
                    }
                }
            }
        }
    };

    let htmlFor = uuidv4();
    return (
        <div style={{
            display: 'flex',
            gap: 7,
            flexDirection: 'column',
            height: '100%'
        }}>
            <div
                style={{
                    pointerEvents: loadingUpload || disabled ? 'none' : 'fill'
                }}
                ref={refInput !== null ? refInput : null}
                className={`${classes["Custom_Input"]} ${showError && classes["Custom_Input_Error"]} ${(value?.length ?? 0) == maximumUpload && classes["Custom_Input_Max"]}`}
            >
                <label
                    id={name}
                    onDragLeave={() => setWarningDrag(false)}
                    onDragEnter={(e) =>
                        handleDrag(e)
                    }
                    onDrop={(e) => console.log(e)}
                    htmlFor={htmlFor}
                    style={{
                        opacity: loadingUpload ? 0.3 : disabled ? 0.8 : 1
                    }}
                    className={`${classes["Custom_Input_Import_File"]} ${value ? classes["CustomBorder_Error"] : ""} ${value ? classes["CustomBorder_Success"] : ""} ${dragActive && classes["hover_drag_input"]} ${warningDrag && classes["hover_drag_input_error"]}`}
                >
                    {
                        imageUrl || value ? <div className={`${classes["Custom_Image"]}`}>
                            {/* <img src={imageUrl ? imageUrl : value ? value : null} /> */}
                            <Image src={imageUrl ? imageUrl : value ? value : null} />
                        </div> : (
                            warningDrag ? (
                                <div className={classes["Icon_Drag_Error"]}>
                                    <IconClose fontSize={20} color="#FC2424" />
                                </div>
                            ) : (
                                <IconUpload fontSize={20} />
                            )
                        )
                    }
                    {dragActive && (
                        <div
                            className={classes["drag-file-element"]}
                            onDragEnter={(e) =>
                                handleDrag(e)
                            }
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={(e) => handleDrop(e)}
                        ></div>
                    )}
                </label>
                {
                    loadingUpload ? <div className={classes['Loading_Upload']}><Loading /></div> : <></>
                }
                <input
                    name={name}
                    disabled={disabled}
                    ref={refInput ? refInput : refImport}
                    type={"file"}
                    onChange={(e) =>
                        onChangeImport(e)
                    }
                    style={style}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete="off"
                    hidden
                    id={htmlFor}
                    multiple={false}
                    accept={accept}
                />
            </div>
            {messageErrorUpload &&
                <div className={classes["message_error"]}>
                    <div className={classes["message_error_border"]}>
                        <IconCircleError />
                        <p>{messageErrorUpload}</p>
                    </div>
                </div>}
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </div>
    );
};

export default UploadImage;
