import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Action from 'redux/user/action';
import FormInput from 'shared/components/common/form/home/FormInput';
import FormTextArea from 'shared/components/common/form/home/FormTextarea';
import FormRadio from 'shared/components/common/form/FormRadio'
import FormCaptcha from 'shared/components/custom-form/FormCaptcha';
import Constants from 'utils/Constants';
import Validator from 'utils/Validator';
import { useStateMachine } from "little-state-machine";
import RequiredLabel from '../../common/RequiredLabel';
import { persistAction } from 'utils/Helper';
import StepperComponent from '../../common/Stepper';
import useTrans from 'hooks/use-trans'
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POPUP_USER_INQUIRY,
    EVENT_SHOW_POPUP_USER_INQUIRY_COMPLETION
}
    from 'utils/EventRegister';
import IconLeftArrow from 'assets/icon/IconLeftArrow';
import IconRightArrow from 'assets/icon/IconRightArrow';
import styles from "./UserInquiryPopup.module.scss";
import Loading from 'shared/components/form-input/button/loading/Loading';

const UserInquiryConfirmationPopup = (props) => {
    const { payload } = props;
    const { trans } = useTrans();
    const dispatch = useDispatch();
    const [inquiryForm, setInquiryForm] = useState()
    const { actions, state } = useStateMachine({ persistAction });
    const [activeRadio, setActiveRadio] = useState(payload?.data?.type ?? 1)
    const [recaptcha, setRecaptcha] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const methods = useForm({
        defaultValues: state
    });

    const { register, handleSubmit } = methods;

    const onSubmit = (data) => {
        setIsLoading(true)
        delete data["captcha"];
        dispatch({
            type: Action.INQUIRY,
            formData: data,
            callback: () => {
                handlePopupCompletion();
                setIsLoading(false)
            },
            fallback: () => {
                setIsLoading(false)
            }
        });
    }

    const handlePopupCompletion = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_INQUIRY_COMPLETION,
            open: true,
            payload: {
                title: trans("Inquiry.inquiry_user"),
                backdropCallback: () => {
                    dispatch({
                        type: Action.REMOVE_INQUIRY_DATA,
                    });
                }
            }
        })
    }

    const handlePopupInquiry = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_INQUIRY,
            open: true,
            payload: {
                title: trans("Inquiry.header_title"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
                data: payload?.data
            }
        })
    }

    const handleRadiobox = (id) => {
        const updatedInquiryForm = Constants.INQUIRY.map(inquiry => {
            if (id === 1) {
                if (inquiry.id === 2 || inquiry.id === 3) {
                    return { ...inquiry, isShow: true };
                }
                if (inquiry.id === 4 || inquiry.id === 5) {
                    return { ...inquiry, isShow: true };
                }
            }
            if (id === 2) {
                if (inquiry.id === 2 || inquiry.id === 3) {
                    return { ...inquiry, isShow: false };
                }
                if (inquiry.id === 4 || inquiry.id === 5) {
                    return { ...inquiry, isShow: true };
                }
            }
            return inquiry;
        });
        setInquiryForm(updatedInquiryForm);
    }

    const handleCapCha = (value) => {
        setRecaptcha(value)
    }

    useEffect(() => {
        activeRadio && handleRadiobox(activeRadio);
    }, [activeRadio]);

    return <div className={styles['inquiry-container']}>
        <div className={styles['stepper-container']}>
            <div className={styles['stepper-content']}>
                <StepperComponent
                    activeStep={1}
                    steps={Constants.INQUIRY_STEP}
                />
            </div>
        </div>
        <div className={styles['open-time']}>
        </div>
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    inquiryForm && inquiryForm?.map((field, index) => {
                        return field.isShow && <div key={index}>
                            <div className={styles['input-wrap']}>
                                <div className={styles['input-label-required']}>
                                    <span className={styles['input-label']}>
                                        {trans("Inquiry." + field.key.toString())}
                                    </span>
                                    {
                                        field.isRequired == Constants.IS_REQUIRED ? <RequiredLabel /> : null
                                    }
                                </div>
                                <div>
                                    <div className={styles['form-input']}>
                                        {field.type === 'rdo' &&
                                            <FormRadio
                                                master={field.questions}
                                                name={field.key}
                                                register={register}
                                                checkedValue={activeRadio}
                                                defaultValue={activeRadio}
                                                disabled={true}
                                            />
                                        }
                                        {field.type === 'txt' &&
                                            <FormInput
                                                fieldName={field.key}
                                                register={register}
                                                validate={[
                                                    Validator.regex(field?.regex),
                                                    field.isRequired == Constants.IS_REQUIRED ? Validator.required : () => { },
                                                ]}
                                                defaultValue={payload?.data ? payload?.data[field.key] : null}
                                                disabled={true}
                                            />
                                        }
                                        {field.type === 'textarea' &&
                                            <div className={styles['textarea-place-holder']}>
                                                <div><span>{field?.note}</span></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {field.type === 'textarea' && <div className={styles['form-textarea']}>
                                <div className={styles['inner-form-textarea']}>
                                    <FormTextArea
                                        fieldName={field.key}
                                        register={register}
                                        validate={[
                                            Validator.regex(field?.regex),
                                            field.isRequired == Constants.IS_REQUIRED ? Validator.required : () => { },
                                        ]}
                                        defaultValue={payload?.data ? payload?.data[field.key] : null}
                                        disabled={true}
                                    ></FormTextArea></div>
                            </div>}
                        </div>
                    })
                }
                <div className={styles['re-cap-cha']}>
                    <FormCaptcha
                        onChange={handleCapCha}
                        fieldName='captcha'
                        validate={[Validator.required]}
                    />
                </div>
                <div className={styles['description']}></div>
                <div className={styles['confirm-btn-container']}>
                    <button disabled={isLoading} type="button" onClick={handlePopupInquiry} className={`${styles['login-btn']}`} >
                        <span className={styles['left-arrow-icon']}><IconLeftArrow /></span>
                        <span className={styles['btn-label']}>{trans("Inquiry.btnReturn")}</span>
                    </button>
                    <button disabled={isLoading} type="submit" className={`${styles['login-btn']}`} >
                        {isLoading ? <Loading /> : <>
                            <span className={styles['btn-label']}>{trans("Inquiry.button_next")}</span>
                            <span className={styles['right-arrow-icon']}><IconRightArrow /></span></>}
                    </button>
                </div>
            </form>
        </FormProvider>
    </div>
}

export default UserInquiryConfirmationPopup