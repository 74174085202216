import React from "react";
import classes from './RowItem.module.scss'

const RowItem = (props) => {

    const { label, value, valueWithCondition = null } = props

    return (
        <div className={classes["RowItem"]}>
            <div className={classes["Label"]}>
                <p >{label}</p>
            </div>
            {valueWithCondition || <div className={classes["Value"]}>
                <p>{value}</p>
            </div>}
        </div>
    );
};

export default RowItem;
