import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import CustomRadioWalletCharge from '../form-input/radio-wallet-charge/CustomRadioWalletCharge';

const FormRadioWalletCharge = (props) => {
    const {
        fieldName,
        validate = [],
        placeholder = '',
        disabled = false,
        size = '',
        options = [],
        onChangeValue = () => {},
        className,
        textTrans,
        validationFE = false,
        isRegexLabel,
        bankInfo
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const wrapRef = useRef();
    return (
        <div ref={wrapRef} className="Input w-100">
            <Controller
                control={control}
                name={fieldName}
                rules={{ validate: Validator.genValidate(validate, fieldName) }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <CustomRadioWalletCharge
                            inputRef={ref}
                            onChange={(e) => {
                                onChange(e);
                                onChangeValue(e);
                            }}
                            value={value}
                            errors={errors}
                            disabled={disabled}
                            placeholder={placeholder}
                            name={fieldName}
                            onBlur={onBlur}
                            size={size}
                            options={options}
                            className={className}
                            textTrans={textTrans}
                            validationFE={validationFE}
                            isRegexLabel={isRegexLabel}
                            bankInfo={bankInfo}
                        />
                    );
                }}
            />
        </div>
    );
};

FormRadioWalletCharge.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    validationFE: PropTypes.bool,
    textAlign: PropTypes.string,
    size: PropTypes.string,
    textTrans: PropTypes.string,
    options: PropTypes.array,
};
export default FormRadioWalletCharge;
