import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionHome from "./action";
import factory from "./factory";

function* getListHexService() {
    yield takeEvery(actionHome.GET_LIST_HEX_SERVICE, function* () {
        try {
            const response = yield call(() => factory.getListHexService());
            if (response?.data?.services) {
                yield put({
                    type: actionHome.GET_LIST_HEX_SERVICE_SUCCESS,
                    payload: {
                        services: response?.data?.services,
                        dataOrder: {
                            numberOfUser: response?.data?.numberOfUser,
                            numberOfOrder: response?.data?.numberOfOrder
                        },
                    }
                })
            }
        } catch (error) {

        }
    })
}

function* getTermOfUse() {
    yield takeEvery(actionHome.GET_TERM_OF_USE_HOME, function* () {
        try {
            const response = yield call(() => factory.getPolicy("TS"));
            yield put({
                type: actionHome.GET_TERM_OF_USE_HOME_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}

function* getPrivatePolicy() {
    yield takeEvery(actionHome.GET_PRIVATE_POLICY_HOME, function* () {
        try {
            const response = yield call(() => factory.getPolicy("PE"));
            yield put({
                type: actionHome.GET_PRIVATE_POLICY_HOME_SUCCESS,
                payload: response?.data
            })
        } catch (error) {

        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getListHexService),
        fork(getTermOfUse),
        fork(getPrivatePolicy),
    ])
}