import React, { useEffect, useState } from 'react'
import EventRegister, { EVENT_SHOW_POPUP_ALARM } from 'utils/EventRegister';
import NotifyAlarmContent from './NotifyAlarmContent';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actionHolonTemplate from 'redux/holon-template/action';

function NotifyAlarm() {
    const [showVisible, setShowVisible] = useState(false);
    const [locationState, setLocationState] = useState(null)
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        setLocationState(location?.pathname);
    }, [location])

    useEffect(() => {
        if (locationState != location?.pathname) {
            setShowVisible(false);
        }
    }, [location])

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_ALL_HOLON_TEMPLATE,
            payload: {}
        });
        EventRegister.on(EVENT_SHOW_POPUP_ALARM, () => {
            setShowVisible(true);
        });
    }, []);

    return (
        <>
            {
                showVisible && <NotifyAlarmContent setShowVisible={() => setShowVisible(false)} />
            }
        </>
    )
}

export default NotifyAlarm