import useTrans from 'hooks/use-trans'
import React from 'react'
import MessageContainer from 'shared/container/message/MessageContainer'

export default function MessagePage() {
    const { t } = useTrans();
    document.title = t("message.message")
    return (
        <MessageContainer />
    )
}
