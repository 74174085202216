const { default: ApiOperation } = require("adapter/ApiOperation");
const { default: Constants } = require("utils/Constants");

const factory = {
    getListAlarm: (payload) => {
        var url = `/rest/api/v1/management/alarm`;
        let params = {}

        if (payload[Constants.QueryParams.SUBJECT.VALUE]) {
            params['subject'] = payload[Constants.QueryParams.SUBJECT.VALUE];
        }
        if (payload[Constants.QueryParams.TAB.VALUE]) {
            params['type'] = payload[Constants.QueryParams.TAB.VALUE];
        }
        if (payload[Constants.QueryParams.MESSAGE.VALUE]) {
            params['content'] = payload[Constants.QueryParams.MESSAGE.VALUE];
        }
        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        }
        if (payload[Constants.QueryParams.KEYWORD.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.KEYWORD.VALUE];
        }
        if (payload[Constants.QueryParams.FROM.VALUE]) {
            params['fromDate'] = payload[Constants.QueryParams.FROM.VALUE];
        }
        if (payload[Constants.QueryParams.TO.VALUE]) {
            params['toDate'] = payload[Constants.QueryParams.TO.VALUE];
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    getMasterAlarmH: () => {
        var url = `/rest/api/v1/management/alarm-setting-master/master`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
}

export default factory;