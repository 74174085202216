import React from 'react';

function IconSave({ color = 'white' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
        >
            <path
                stroke="#fff"
                strokeLinejoin="round"
                d="M12.417 1.302h-10.2a.9.9 0 00-.9.9v10.2a.9.9 0 00.9.9h10.2a.9.9 0 00.9-.9v-10.2a.9.9 0 00-.9-.9z"
            ></path>
            <path
                stroke="#fff"
                strokeLinejoin="round"
                d="M10.817 1.302v6h-7v-6h7z"
            ></path>
        </svg>
    );
}

export default IconSave;
