import React, { useEffect } from 'react'
import classes from './UpdateFaqCategory.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FaqAction from 'redux/faq/action';
import FormGroup from '../../form-search/FormGroup';
import Button from 'shared/components/form-input/button/Button';
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormItem from '../../form-search/FormItem';
import TextLabel from '../../form-search/TextLabel';
import Validator from 'utils/Validator';
import FormInput from 'shared/components/custom-form/FormInput';
import Constants from 'utils/Constants';
import useCustomState from 'hooks/use-custom-state';
import useTrans from 'hooks/use-trans';
import { Close } from '@mui/icons-material';
import useRouter from 'hooks/use-router';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_POPUP_CONFIRM_UPDATE } from 'utils/EventRegister';

function UpdateFaqCategoryPopup(props) {
    const { showVisible, payload } = props;
    const { faqCategoryDetail, loadingUpdateCategoryFaq } = useSelector(state => state.faq);
    const methods = useForm();
    const { trans, t } = useTrans();
    const dispatch = useDispatch();
    const [optionLanguages, setOptionLanguages] = useCustomState([]);
    const router = useRouter();

    const onSubmit = (_data) => {

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: FaqAction.UPDATE_FAQ_CATEGORY,
                        payload: {
                            ..._data,
                            id: faqCategoryDetail?.id,
                            sort: faqCategoryDetail?.sort,
                        },
                        callback: {
                            success: () => {
                                showVisible(false);
                                methods.reset({});
                                payload?.callback && payload?.callback()
                            },
                            failed: () => {
                            }
                        },
                        paramsUrl: { ...router.getAll() }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (faqCategoryDetail?.id) {
            methods.setValue("id", faqCategoryDetail?.id);
            methods.setValue("categoryName", faqCategoryDetail?.categoryName);
            methods.setValue("lang", faqCategoryDetail?.lang);
            methods.setValue("status", faqCategoryDetail?.status);
        } else {
            methods.setValue("id", null);
            methods.setValue("categoryName", null);
            methods.setValue("lang", null);
            methods.setValue("status", faqCategoryDetail?.status);
        }
    }, [faqCategoryDetail])


    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: t(`faq_management.lang.${Constants.T001[key].NAME}`),
            })
        });
        setOptionLanguages(data)
    }, [])
    return (
        <BoxContentPopup>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        actionSectionBottom={<Button loading={loadingUpdateCategoryFaq} type='submit' title={trans("faq_management.save")} />}
                        showVisible={showVisible}
                        title={payload?.title}
                    >
                        <div className={classes['AddFaqCategoryPopup']}>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel require>
                                        {trans("faq_management.add_new_category")}
                                    </TextLabel>
                                    <FormInput
                                        validate={[Validator.required, Validator.maxLength(200)]}
                                        fieldName='categoryName'
                                        placeholder={trans("faq_management.category_selection")}
                                        validationFE
                                        isRegexLabel={trans("faq_management.add_new_category")}
                                    />
                                </FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <TextLabel require>
                                        {trans("faq_management.lang_choice")}
                                    </TextLabel>
                                    <FormSelect
                                        validate={[Validator.required]}
                                        fieldName='lang'
                                        placeholder={trans("faq_management.lang_choice")}
                                        options={optionLanguages}
                                        validationFE
                                        isRegexLabel={trans("faq_management.lang_choice")}
                                    />
                                </FormItem>
                            </FormGroup>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default UpdateFaqCategoryPopup;