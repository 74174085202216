import axios from "axios";
import ApiOperation from "adapter/ApiOperation";
import { getCoreAPI } from "shared/components/common/Util";
import { request } from "adapter/ApiProvider";


const Factories = {
    getHolonMasterByCode: async(code) => {
        var url = `${getCoreAPI()}/holon-master-by-code`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: { code }
        })
    },
    getHolonTemplate: async (type) => {
        var url = `${getCoreAPI()}/holon-template/${type}`;
        return await axios.get(url).then(res => res.data);
    },

    getTemplateIdsByCodes: (data) => {
        var url = `${getCoreAPI()}/holon-master/data`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: { codes: data }
        })
    },

    getHolonTemplatesByListTemplateId: (data) => {
        var url = `${getCoreAPI()}/holon-template/list-template`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: { templates: data }
        })
    },

    getHolonTemplateByCode: async (code) => {
        var url = `${getCoreAPI()}/holon-master/detail/${code}`;
        return await request({
            url: url,
            method: 'GET'
        });
    },

    getHolonTemplatesNoAttributesByMasterCodes: (data) => {
        var url = `${getCoreAPI()}/holon-template/master-code`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: { codes: data }
        })
    },

    getHolonTemplatesByMasterCodes: (data) => {
        var url = `${getCoreAPI()}/holon-template-attribute/master-code`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: { codes: data }
        })
    },

    getCompanyById: async (id) => {
        var url = `${getCoreAPI()}/management/user-account/company/${id}`;
        return await axios.get(url).then(res => res.data);
    },

    updateCompany: async (formData) => {
        var url = `${getCoreAPI()}/management/user-account/company/update`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: formData
        })
    },
    
    updateConnectEmployee: async (formData, companyId) => {
        var url = `${getCoreAPI()}/management/user-account/company/${companyId}/connect-employee-position`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: formData
        })
    },

    removeConnection: async (formData) => {
        var url = `${getCoreAPI()}/management/user-account/company/remove-connection`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: formData
        })
    },

    deleteInstanceAndRemoveConnection: async (formData) => {
        var url = `${getCoreAPI()}/management/user-account/company/delete-instance`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: formData
        })
    },

    getHolonInstanceByAttributeValue: async(value, templateId) => {
        console.log(value, templateId);
        var url = `${getCoreAPI()}/get-holon-by-attribute`;
        return ApiOperation.request({
            url: url,
            method: "GET",
            params: {
                value,
                templateId
            }
        })
    },
    getOutsourceCompanyClientConnection: async () => {
        var url = `${getCoreAPI()}/management/outsource-company/client-connection`;
        return await request({
            url: url,
            method: 'GET'
        });
    },
};

export default Factories