import useTrans from 'hooks/use-trans';
import React from 'react'
import TransactionContainer from 'shared/container/transaction/TransactionContainer';

function TransactionPage() {
    const { t } = useTrans();
    document.title = t("transaction.transaction_management");
    return (
        <TransactionContainer />
    )
}

export default TransactionPage