const Action = {
    GET_BL_COMPANY: 'GET_BL_COMPANY',
    GET_BL_COMPANY_SUCCESS: 'GET_BL_COMPANY_SUCCESS',
    GET_BL_COMPANY_FAILURE: 'GET_BL_COMPANY_FAILURE',

    GET_BL_DEPARTMENT_BY_COMPANY_ID: 'GET_BL_DEPARTMENT_BY_COMPANY_ID',
    GET_BL_DEPARTMENT_BY_COMPANY_ID_SUCCESS: 'GET_BL_DEPARTMENT_BY_COMPANY_ID_SUCCESS',
    GET_BL_DEPARTMENT_BY_COMPANY_ID_FAILURE: 'GET_BL_DEPARTMENT_BY_COMPANY_ID_FAILURE',
    CLEAR_BL_DEPARTMENT: 'CLEAR_BL_DEPARTMENT',
    
    GET_BL_POSITION_BY_DEPARTMENT_ID: 'GET_BL_POSITION_BY_DEPARTMENT_ID',
    GET_BL_POSITION_BY_DEPARTMENT_ID_SUCCESS: 'GET_BL_POSITION_BY_DEPARTMENT_ID_SUCCESS',
    GET_BL_POSITION_BY_DEPARTMENT_ID_FAILURE: 'GET_BL_POSITION_BY_DEPARTMENT_ID_FAILURE',
    CLEAR_BL_POSITION: 'CLEAR_BL_POSITION',

    ADD_POSITION_FOR_USER: 'ADD_POSITION_FOR_USER',
    ADD_POSITION_FOR_USER_SUSSESS: 'ADD_POSITION_FOR_USER_SUSSESS',
    ADD_POSITION_FOR_USER_FAILURE: 'ADD_POSITION_FOR_USER_FAILURE',

    REGISTER_BL_ACCOUNT: 'REGISTER_BL_ACCOUNT',
    REGISTER_BL_ACCOUNT_SUCCESS: 'REGISTER_BL_ACCOUNT_SUCCESS',
    REGISTER_BL_ACCOUNT_FAILURE: 'REGISTER_BL_ACCOUNT_FAILURE',
    CLEAR_REGISTER_BL_ACCOUNT_ERROR: 'CLEAR_REGISTER_BL_ACCOUNT_ERROR',
}

export default Action;