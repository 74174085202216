import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionHolonTemplate from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";

function* getHolonTemplate() {
    yield takeEvery(actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplate(payload?.payload));
            yield put({
                type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT_SUCCESS,
                payload: response
            })
        } catch (error) {
            yield put({
                type: actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT_FAILED,
            })
        }
    })
}

function* getAllHolonTemplate() {
    yield takeEvery(actionHolonTemplate.GET_ALL_HOLON_TEMPLATE, function* (payload) {
        try {
            const response = yield call(() => factory.getMasterHolonTemplate(payload?.payload));
            if(payload?.callback?.success){
                payload?.callback?.success(response)
            }else{
                yield put({
                    type: actionHolonTemplate.GET_ALL_HOLON_TEMPLATE_SUCCESS,
                    payload: response
                })
            }
        } catch (error) {
        }
    })
}

function* getHolonTemplatePopup() {
    yield takeEvery(actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getHolonTemplate(payload?.payload));
            yield payload?.callback?.success(response?.records)
        } catch (error) {
        }
    })
}

function* createHolonTemplate() {
    yield takeEvery(actionHolonTemplate.CREATE_HOLON_TEMPLATE_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.createHolonTemplate(payload?.payload));
            if (response?.data?.id) {
                payload?.success && payload?.success()
                yield put({
                    type: actionHolonTemplate.CREATE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS,
                    payload: response
                })
                yield put({
                    type: actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT,
                    payload: {}
                })
            } else {
                payload?.failed && payload?.failed(response?.error?.detail?.exception[0])
                yield put({
                    type: actionHolonTemplate.CREATE_HOLON_TEMPLATE_MANAGEMENT_FAILED,
                })
            }
        } catch (error) {
            yield payload?.failed && payload?.failed(error?.error?.detail?.row1?.exception[0])
            yield put({
                type: actionHolonTemplate.CREATE_HOLON_TEMPLATE_MANAGEMENT_FAILED,
            })
        }
    })
}
function* getDetailHolonTemplate() {
    yield takeEvery(actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailHolonTemplate(payload?.payload));
            yield put({
                type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_SUCCESS,
                payload: response?.data
            })
        } catch (error) {
            yield put({
                type: actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_FAILED,
            })
        }
    })
}

function* getDetailHolonTemplatePopup() {
    yield takeEvery(actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_POPUP, function* (payload) {
        try {
            const response = yield call(() => factory.getDetailHolonTemplate(payload?.payload));
            if (response?.data) {
                yield payload?.callback?.success(response?.data)
            }
        } catch (error) {
        }
    })
}

function* cloneHolonTemplate() {
    yield takeEvery(actionHolonTemplate.CLONE_HOLON_TEMPLATE, function* (payload) {
        try {
            const response = yield call(() => factory.cloneHolonTemplate(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success(response?.data);
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed();
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            }
        } catch (error) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                newWindow: true,
                payload: {
                    title: error?.error?.detail?.exception[0],
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                }
            })
        }
    })
}
function* updateHolonTemplate() {
    yield takeEvery(actionHolonTemplate.UPDATE_HOLON_TEMPLATE_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.updateHolonTemplate(payload?.payload));
            if (response?.data?.id) {
                yield payload?.callback?.success && payload?.callback?.success()
                yield put({
                    type: actionHolonTemplate.UPDATE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS,
                    payload: response
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed(response?.error?.detail?.exception[0])
                yield put({
                    type: actionHolonTemplate.UPDATE_HOLON_TEMPLATE_MANAGEMENT_FAILED,
                })
            }
        } catch (error) {
            yield payload?.callback?.failed && payload?.callback?.failed()
            yield put({
                type: actionHolonTemplate.UPDATE_HOLON_TEMPLATE_MANAGEMENT_FAILED,
            })
        }
    })
}

function* getSearchHolonTemplate() {
    yield takeEvery(actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.getMasterHolonTemplate(payload?.payload));
            if (response) {
                yield payload?.callback?.success && payload?.callback?.success(response);
                yield put({
                    type: actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT_SUCCESS,
                    payload: response
                })
            } else {
                yield payload?.callback?.failed && payload?.callback?.failed();
                yield put({
                    type: actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT_FAILED,
                    payload: response
                })
            }
        } catch (error) {

        }
    })
}

function* getMasterCategoryHolonTemplate() {
    yield takeEvery(actionHolonTemplate.GET_MASTER_CATEGORY_HOLON_TEMPLATE, function* (payload) {
        try {
            const response = yield call(() => factory.getMasterCategoryHolonTemplate());
            if (response) {
                // yield payload?.callback?.success && payload?.callback?.success();
                yield put({
                    type: actionHolonTemplate.GET_MASTER_CATEGORY_HOLON_TEMPLATE_SUCCESS,
                    payload: response?.data
                })
            } else {
                // yield payload?.callback?.failed && payload?.callback?.failed();
                yield put({
                    type: actionHolonTemplate.GET_MASTER_CATEGORY_HOLON_TEMPLATE_FAILED,
                    payload: response
                })
            }
        } catch (error) {

        }
    })
}

function* deleteHolonTemplate() {
    yield takeEvery(actionHolonTemplate.DELETE_HOLON_TEMPLATE_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.deleteHolonTemplate(payload?.payload));
            if(response){
                yield payload?.callback?.success && payload?.callback?.success(response, null)
            }
        } catch (error) {
            yield payload?.callback?.success && payload?.callback?.success(null, error)
        }
    })
}

function* getMasterHolonTemplate() {
    yield takeEvery(actionHolonTemplate.GET_MASTER_HOLON_TEMPLATE, function* (payload) {
        try {
            const response = yield call(() => factory.geHolonTemplateMaster(payload?.payload));
            yield put({
                type: actionHolonTemplate.GET_MASTER_HOLON_TEMPLATE_SUCCESS,
                payload: response
            })
        } catch (error) {

        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getHolonTemplate),
        fork(getMasterHolonTemplate),
        fork(createHolonTemplate),
        fork(getDetailHolonTemplate),
        fork(updateHolonTemplate),
        fork(getSearchHolonTemplate),
        fork(deleteHolonTemplate),
        fork(getMasterCategoryHolonTemplate),
        fork(getDetailHolonTemplatePopup),
        fork(cloneHolonTemplate),
        fork(getAllHolonTemplate),
        fork(getHolonTemplatePopup)
    ])
}