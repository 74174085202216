import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import TextArea from '../form-input/text-area/TextArea';

function FormTextArea(props) {
    const { fieldName, validate = [], disabled = false, placeholder = "", validationFE = false , textTrans, isRegexLabel, height} = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className="Input w-100">
            <div className="w-100">
                <Controller
                    control={control}
                    name={fieldName}
                    rules={{
                        validate: Validator.genValidate(validate, fieldName),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                            <TextArea
                                validationFE={validationFE}
                                refInput={ref}
                                name={fieldName}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                placeholder={placeholder}
                                errors={errors}
                                disabled={disabled}
                                textTrans={textTrans}
                                required={false} 
                                isRegexLabel={isRegexLabel}
                                height={height}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
}
FormTextArea.defaultProps = {
    placeholder: '',
    fieldName: 'fieldName',
};
FormTextArea.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    validationFE: PropTypes.bool,
    iconSearch: PropTypes.element,
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    textTrans: PropTypes.string,
    readOnly: PropTypes.bool,
};
export default FormTextArea;
