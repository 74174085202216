import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import _ from 'lodash';
import styles from './Tabs.module.scss'
import Constants from 'utils/Constants';
import actionHolon from 'redux/holon/action';
import actionUser from 'redux/user/action';
import useTrans from 'hooks/use-trans';
import Button from 'shared/components/form-input/button/Button';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import FieldAccountSetting from 'shared/components/form/field-account-setting/FieldAccountSetting';
import EventRegister, { 
    EVENT_SHOW_POPUP, 
    EVENT_SHOW_POPUP_CONFIRM_DELETE, 
    EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
    EVENT_SHOW_COMPANY_DETAIL_POPUP,
} from 'utils/EventRegister';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import ElementType from 'utils/ElementType';
import moment from 'moment';

function UserForm(props) {
    const { } = props;
    const dispatch = useDispatch();
    const { trans } = useTrans();
    const methods = useForm();
    const { register, handleSubmit, setValue, reset, watch, getValues, setError, clearErrors } = methods;
    const [userTemplates, setUserTemplates] = useState([]);
    const { user, user_id, hex_id, updateUserError, loading: { loadingUpdateAccount } } = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [dataDefault, setDataDefault] = useState({})
    
    useEffect(() => {
        return () => {
            dispatch({
                type: actionUser.CLEAR_UPDATE_ACCOUNT_ERROR,
            });
            dispatch({
                type: actionHolon.CLEAR_HOLON_TEMPLATES,
            });
        }
    }, []);

    useEffect(() => {
        if (user?.id) {
            dispatch({
                type: actionHolon.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
                payload: Constants.HOLON_MASTER_CODE.USER_TEMPLATE,
                callback: (data) => {
                    setUserTemplates(data)
                }
            })
        }
    }, [user]);

    useEffect(() => {
        if (updateUserError) {
            Object.keys(updateUserError).forEach((key) => {
                setError(key, {
                    type: 'custom',
                    message: updateUserError[key]?.[0]
                })
            })
        }
    }, [updateUserError]);


    useEffect(() => {
        const formData = {};
        const templateIds = [];
        userTemplates.map(template => {
            templateIds.push(template.id);
        })
        user?.attributes?.map((attribute) => {
            for (const template of userTemplates) {
                const att = template?.attributes?.find((att, index) => attribute?.name == att?.key && !att?.writer?.includes("System") && !att?.key?.startsWith("setting")) 
                if (att && attribute?.value != null && attribute?.value != '' && attribute?.value != undefined && !att?.key?.includes('password')) {
                    if (att?.elementType == ElementType.DATE) {
                        try {
                            let value = attribute?.value;
                            if (moment(attribute?.value, 'DD/MM/yyyy').format('DD/MM/yyyy') == attribute?.value) {
                                value = moment(attribute?.value, 'DD/MM/yyyy').format('yyyy-MM-DD');
                                formData[`${attribute?.name}`] = value.toString();
                            } else if (moment(attribute?.value, 'yyyy/MM/DD').format('yyyy/MM/DD') == attribute?.value) {
                                value = moment(attribute?.value, 'yyyy/MM/DD').format('yyyy-MM-DD');
                                formData[`${attribute?.name}`] = value.toString();
                            }
                            // formData[`${attribute?.name}`] = value.toString();
                            
                        } catch (error) {
                            // formData[`${attribute?.name}`] = attribute?.value;
                        }
                        
                    } else if ((att?.elementType == ElementType.CHECKBOX || att?.elementType == ElementType.RADIO || att?.elementType == ElementType.SELECT) && (attribute?.value == 'null' || attribute?.value == 'undefined')) {
                        continue;
                    } else {
                        formData[`${attribute?.name}`] = attribute?.value;
                    }
                    // formData[`${attribute?.name}`] = attribute?.value;
                }
            }
        })
        formData[`template_id`] = templateIds.join(',');

        setDataDefault(formData)

        reset(formData);
    }, [user, userTemplates]);

    const onSubmit = (data) => {
        const formData = [];
        Object?.keys(data).forEach((key, index) => {
            formData.push({
                name: key,
                value: data[key],
                description: localStorage.getItem(key) ?? ""
            });
        })
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            payload: {
                title: trans("Holon_Template.confirm_update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoading(true);
                    dispatch({
                        type: actionUser.UPDATE_ACCOUNT,
                        payload: {
                            user_id: user_id,
                            hex_id: hex_id,
                            data: formData,
                        },
                        callback: {
                            success: () => {
                                // getToast(trans('successful_update'));
                                setLoading(false);
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: trans("successful_update"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                                dispatch({
                                    type: actionUser.GET_ACCOUNT,
                                })
                            },
                            failed: () => {
                                // getToast(trans('failed_update'), 'error');
                                setLoading(false);
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: trans("system_error"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    const watchFormData = methods.watch()
    let isEdited = _.isEqual(dataDefault, watchFormData) ? false : true;
    
    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    { userTemplates.length > 0 &&
                        <>
                            {
                                userTemplates?.map((template, i) =>
                                    template && template?.attributes?.map((field, index) => {
                                        return field && (!field?.key?.startsWith("setting") && (field?.key != "email") && !field?.key?.includes("status")) &&
                                            <FieldAccountSetting
                                                key={index}
                                                template={template?.id}
                                                field={field}
                                                register={register}
                                                setValue={setValue}
                                                defaultValue={getValues(`${field?.key}`)}
                                                watch={watch}
                                                getValues={getValues}
                                                clearErrors={clearErrors}
                                            />
                                    })
                                )
                            }
                            <div className={styles['profile-submit-btn-end']}>
                                <Button color='lightblue' disabled={!isEdited} loading={loading} type='submit' title={trans('keep')} />
                            </div>
                        </>
                    }
                </form>
            </FormProvider>
        </>
    )
}

function AccountTab(props) {
    const dispatch = useDispatch();
    const { trans } = useTrans();
    const { user, user_id, hex_id, positions, loading: { loadingPositions } } = useSelector(state => state.user);

    useEffect(() => {
        return () => {
            dispatch({
                type: actionUser.CLEAR_UPDATE_ACCOUNT_ERROR,
            });
            dispatch({
                type: actionHolon.CLEAR_HOLON_TEMPLATES,
            });
        }
    }, []);

    return (
        <>
            <div className="container-fluid">
                <UserForm 
                    user={user}
                    user_id={user_id}
                    hex_id={hex_id}
                    // onSubmit={onSubmit}
                />
            </div>
        </>
    );
}

export default AccountTab;