const Actions = {
    SHOW_LOADING: "SHOW_LOADING",
    HIDE_LOADING: "HIDE_LOADING",
    SHOW_LOADING_MENU: "SHOW_LOADING_MENU",
    HIDE_LOADING_MENU: "HIDE_LOADING_MENU",
    GET_ADDRESS: "GET_ADDRESS",
    GET_MENU: "GET_MENU",
    GET_MENU_RESPONSE: "GET_MENU_RESPONSE",
    GET_FUNCTION: "GET_FUNCTION",
    GET_FUNCTION_RESPONSE: "GET_FUNCTION_RESPONSE",

    SET_FORM_FIELD_MASTER_DATA: "SET_FORM_FIELD_MASTER_DATA",
}

export default Actions;