import useTrans from 'hooks/use-trans'
import React from 'react'
import NotifyAlarmContainer from 'shared/container/notify-alarm/NotifyAlarmContainer'

function NotifyPage() {
    const { t } = useTrans()
    document.title = t("alarm.notification_display")
    return (
        <NotifyAlarmContainer />
    )
}

export default NotifyPage