import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react';
import PrivatePolicyContainer from 'shared/container/private-policy/PrivatePolicyContainer';

function PrivatePolicyPage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t("faq_management.Hex_personal_info")
    }, []);
    return <PrivatePolicyContainer />
}

export default PrivatePolicyPage
