import React from 'react';

function IconPlus() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="9" cy="9" r="9" fill="#FFC30D" />
            <path
                d="M5 9H13"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M9 5L9 13"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconPlus;
