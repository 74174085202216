import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import Actions from "./action";
import Factories from "./factory";

function* getAddressByPostalCode() {
    yield takeLatest(Actions.GET_ADDRESS, function* (payload) {
        const {code, callback} = payload;
        try {
            const { data } = yield call(() => Factories.getAddress(code));
            
            if (callback) {
                callback(data);
            }

        } catch (error) {
            console.log("xxx Get address error: ", error);
        }
    });
}

function* getMenuByRole() {
    yield takeLatest(Actions.GET_MENU, function* (payload) {
        const {code, callback} = payload;
        try {
            const { data } = yield call(() => Factories.getMenuByRole(code));
            localStorage.setItem("menu", JSON.stringify(data));
            yield put({
                type: Actions.GET_MENU_RESPONSE,
                payload: data
            });
            if (callback) {
                callback();
            }
            if(data){
                yield put({
                    type: Actions.HIDE_LOADING_MENU,
                });
            }
        } catch (error) {
            console.log("xxx Get menu error: ", error);
            yield put({
                type: Actions.GET_MENU_RESPONSE,
                payload: []
            });
            yield put({
                type: Actions.HIDE_LOADING_MENU,
            });
        }
    });
}

function* getFunctionByRole() {
    yield takeLatest(Actions.GET_FUNCTION, function* (payload) {
        
        try {
            const response = yield call(() => Factories.getFunctionByRole());
            if (response?.data) {
                localStorage.setItem("functions", JSON.stringify(response?.data));

                yield put({
                    type: Actions.GET_FUNCTION_RESPONSE,
                    payload: response?.data,
                });
            }
        } catch (error) {
            yield put({
                type: Actions.GET_FUNCTION_RESPONSE,
                payload: []
            });
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getAddressByPostalCode),
        fork(getMenuByRole),
        fork(getFunctionByRole),
    ]);
}