import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import IconButton from '@mui/material/IconButton';
import vector519 from 'assets/img/vector-519.png';
import vector520 from 'assets/img/vector-520.png';
import useTrans from "hooks/use-trans";
import "./DatePicker.scss";

const FormDateRangePicker = (props) => {
    const { trans } = useTrans();
    const {name, validate = [], register, defaultValue = '', field = ''} = props
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { formState: { errors }, control } = useFormContext();
    
    const onDateChange = (dates, callback) => {
        const [start, end] = dates;

        callback(dates);

        setStartDate(start);
        setEndDate(end);
    }
    
    return (
        <Controller
            control={control}
            name={name}
            rules={{ validate: Validator.genValidate(validate, name) }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return <div className="form-wrap">
                    <div className={`${errors[name] ? 'error' : ''}`}>
                        <DatePicker
                            {...register(name, {value: [startDate, endDate]})}
                            _props={{ inputRef: ref }}
                            selectsRange
                            className="w-100"
                            startDate={startDate}
                            endDate={endDate}
                            onChange={value => onDateChange(value, onChange)}
                            shouldCloseOnSelect={false}
                            locale={ja}
                            dayClassName={(date) => `${date.toLocaleDateString('en-US', {
                                weekday: 'short',
                            })}`}
                            renderCustomHeader={({
                                date,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                            }) => (
                                <div className="custom-header">
                                    <IconButton
                                        className="btn-arrow-rounded-icon"
                                        children={<img src={vector519} className="vector-icon" alt="vector icon" />}
                                        onClick={decreaseMonth}
                                        disabled={prevMonthButtonDisabled}
                                        disableFocusRipple={true}
                                        disableRipple={true}
                                    />
                                    <div>{`${date.getFullYear()}年 ${date.getMonth() + 1}月`}</div>
                                    <IconButton className="btn-arrow-rounded-icon"
                                        children={<img src={vector520} className="vector-icon" alt="vector icon" />}
                                        onClick={increaseMonth}
                                        disabled={nextMonthButtonDisabled}
                                        disableFocusRipple={true}
                                        disableRipple={true}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    { errors[name] && <span className='error-msg'>{Validator.renderMessage(trans(errors[name]?.message), {0: field?.messageKey ? trans(field?.labelKey) : trans(name)})}</span> }
                </div>
            }} />
    );
};

export default FormDateRangePicker;
