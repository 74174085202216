import React, { useEffect } from 'react'
import CustomPagination from 'shared/components/common/custom-pagination/CustomPagination'
import classes from './TableHolonInstance.module.scss';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import TableSkeleton from 'shared/components/common/skeleton/TableSkeleton';
import Constants from 'utils/Constants';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE } from 'utils/EventRegister';
import { CommonRowTableHolonInstance } from '../CommonDataFilter';

function TableHolonInstance(props) {
    const {
        isShowCheckBox,
        listCheckbox,
        headHolonInstance,
        loadingInstance,
        callbackDetailFunction,
        setListCheckbox,
        titleDetailPopup,
        titleUpdatePopup,
        subHolonTemplateID,
        titleCreatePopup,
        isCargo,
        isHolonTemplateName,
        permissionClone,
        isHiddenBtnAdd,
        permissionUpdate,
        cID,
        cNAME,
        userCompany,
        holonTemplateID,
        onChangePage,
        requireConnectionKey,
        requireConnectionHolonId,
        permissionDelete,
        ExcludeIds,
        listTemplateCreation
    } = props;

    const { holonInstance } = useSelector(state => state?.holonInstance);

    const router = useRouter();
    const { t } = useTrans();

    useEffect(() => {
        const executeSyncScroll = () => {
            syncScroll(
                '_custom_scroll_footer',
                '_custom_scroll_body',
                '_custom_scroll_header',
            );
            syncScroll(
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_footer'
            );
        };
        executeSyncScroll();
    }, []);

    useEffect(() => {
        $(() => {
            $('._custom_scroll_body').scrollLeft(
                $('._custom_scroll_header').scrollLeft(),
            );
            $('._custom_scroll_footer').scrollLeft(
                $('._custom_scroll_header').scrollLeft(),
            );
        });
    }, [loadingInstance]);

    let ignoreScrollEvents = false;

    const syncScroll = (...selectors) => {
        const wrapper = document.getElementById("Menu_Table");
        if (wrapper && selectors.length > 1) {
            wrapper.addEventListener(
                'scroll',
                ({ target }) => {
                    if (target.classList.contains(selectors[0])) {
                        const ignore = ignoreScrollEvents;
                        ignoreScrollEvents = false;
                        if (ignore) {
                            return;
                        }
                        ignoreScrollEvents = true;
                        const { scrollLeft } = target;
                        selectors.forEach((selector) => {
                            if (wrapper.querySelector(`.${selector}`)) {
                                wrapper.querySelector(`.${selector}`).scrollLeft = scrollLeft;
                            }
                        });
                    }
                },
                true,
            );
        }
    };

    const isSelected = (data, id) => data?.findIndex(x => x?.id == id) != -1 ? true : false;
    const mappingSelected = (data, listSelect) => {
        let bool = [];
        data?.map(x => {
            let index = listSelect?.findIndex(v => v?.id == x?.id);
            if (index != -1) {
                bool.push(x);
            }
        });

        if (bool?.length == data?.length) {
            return true;
        } else {
            return false;
        }
    }
    const isSelectedAll = (data, listSelect) =>
        listSelect?.length > 0 &&
            mappingSelected(data?.records, listSelect) ? true : false;

    const handleCheckAll = (_holonInstance) => {
        let data = [...listCheckbox];
        _holonInstance?.map(x => {
            let index = data?.findIndex(h => h?.id == x?.id);
            if (index == -1) {
                data.push(x);
            }
        });
        setListCheckbox(data);
    }
    const handleRemoveAll = (_holonInstance) => {
        // let data = [...listCheckbox];
        // _holonInstance?.map(x => {
        //     let index = data?.findIndex(h => h?.id == x?.id);
        //     if (index != -1) {
        //         data.splice(index, 1);
        //     }
        // });
        // setListCheckbox(data);
        setListCheckbox([]);
    }


    const onPageChange = (e) => {
        setListCheckbox([])
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: e
            }
        });
        onChangePage()
    }

    // Handle change limit table
    const onChangeRowPerpage = (e) => {
        setListCheckbox([])
        router.replace({
            params: {
                ...router.getAll(),
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                [Constants.QueryParams.LIMIT.VALUE]: e,
            }
        });
        onChangePage()
    }

    // handle open detail
    const handleOpenDetail = (value) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                title: titleDetailPopup ? titleDetailPopup : t("holon.detail_holon_instance"),
                titleUpdatePopup: titleUpdatePopup ? titleUpdatePopup : null,
                holonInstanceId: value?.id,
                holonTemplateId: value?.templateId,
                subHolonTemplateId: subHolonTemplateID,
                titleCreatePopup: titleCreatePopup ? titleCreatePopup : t("holon.create_holon_instance"),
                isCargo: isCargo,
                isHolonTemplateName: isHolonTemplateName,
                permissionClone: permissionClone && isHiddenBtnAdd(),
                permissionUpdate: permissionUpdate && isHiddenBtnAdd(),
                cId: cID,
                cName: cNAME,
                userCompany: userCompany,
                requireConnectionKey: requireConnectionKey,
                requireConnectionHolonId: requireConnectionHolonId,
                permissionDelete: permissionDelete,
                ExcludeIds: ExcludeIds,
                listTemplateAll: listTemplateCreation
            }
        })
    }

    const handleCheckBox = (item) => {
        let data = [...listCheckbox];
        let index = data?.findIndex(x => x?.id == item?.id);
        if (index == -1) {
            data.push(item);
        } else {
            data?.splice(index, 1);
        }

        setListCheckbox(data);
    };
    const RenderValueTableRow = (menu, attribute, isTitle = false) => {
        if ((holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE || holonTemplateID?.split("-")?.length > 1) && menu?.attributes[attribute?.key]) {
            return CommonRowTableHolonInstance({
                attributeHeader: attribute,
                attributes: menu?.attributes,
                isTitle
            })
        } else
            if (menu?.attributes[attribute?.key] && attribute?.templateId == holonTemplateID) {
                return CommonRowTableHolonInstance({
                    attributeHeader: attribute,
                    attributes: menu?.attributes,
                    isTitle
                })
            } else {
                if (subHolonTemplateID) {
                    let attributeSub = [];
                    let loop = false;
                    menu?.connections?.forEach(x => {
                        if (!loop) {
                            let index = x?.holons?.findIndex(v => v?.templateId == subHolonTemplateID);
                            if (index != -1) {
                                attributeSub = x?.holons[index]?.attributes;
                                loop = true;
                                return false;
                            }
                        }
                    });
                    attributeSub = attributeSub?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur }), {});
                    return CommonRowTableHolonInstance({
                        attributeHeader: attribute,
                        attributes: attributeSub
                    })
                }
            }
    }

    return (
        <>
            {
                holonInstance?.length == 0 ? <></> :
                    <div id='Menu_Table' className={classes['custom-table-instance']}>
                        {
                            <div className={`${classes.container_table}`}
                                style={{ position: 'relative' }}>
                                <div className={`_custom_scroll_header ${classes['custom-scroll-header']}`}>
                                    {
                                        isShowCheckBox ?
                                            <div onClick={() => !isSelectedAll(holonInstance, listCheckbox) ? handleCheckAll(holonInstance?.records) : handleRemoveAll(holonInstance?.records)} className={`${classes["title-header"]} ${classes['custom-checkbox-th-header']}`}>
                                                {isSelectedAll(holonInstance, listCheckbox) ?
                                                    <IconChecked fontSize={15} />
                                                    : <IconCheck fontSize={15} />}
                                            </div> :
                                            <></>
                                    }
                                    {
                                        headHolonInstance?.map((attribute, index) => {
                                            return <div hidden={attribute?.isHidden} key={index} className={`${classes["title-header"]} ${classes["custom-title-header"]}`}>
                                                <div className={classes['custom-content-table']}>{attribute?.label?.trim()}</div>
                                            </div>
                                        })}
                                </div>
                                {
                                    (loadingInstance) ? <TableSkeleton /> : <div className={`_custom_scroll_body ${classes['custom-scroll-body']}`}>
                                        {holonInstance?.records?.map((menu, key) => {
                                            return <div className={`${classes['custom-scroll-body-row']}`} key={`row_${menu?.id}_${key}`}>
                                                {
                                                    isShowCheckBox ?
                                                        isSelected(listCheckbox, menu?.id) ? <div
                                                            onClick={() => handleCheckBox(menu)}
                                                            className={classes['custom-checkbox-td-body']}
                                                        >
                                                            <IconChecked fontSize={15} />
                                                        </div> : <div
                                                            onClick={() => handleCheckBox(menu)}
                                                            className={classes['custom-checkbox-td-body']}
                                                        >
                                                            <IconCheck fontSize={15} />
                                                        </div>
                                                        : <></>
                                                }
                                                {
                                                    headHolonInstance?.map((attribute, _index) => {
                                                        return attribute?.key == 'hex_id' && !attribute?.isHidden ? <div
                                                            key={_index}
                                                            onClick={() => typeof callbackDetailFunction == 'function'
                                                                ? callbackDetailFunction(menu) : handleOpenDetail(menu)}
                                                            className={classes['custom-title-body']}
                                                        >
                                                            <ButtonLink>
                                                                {menu?.attributes?.["hex_id"]?.value}
                                                            </ButtonLink>
                                                        </div> :
                                                            <div
                                                                hidden={attribute?.isHidden}
                                                                key={_index}
                                                                className={classes['custom-title-body']}
                                                                style={{
                                                                    justifyContent: attribute?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE ? 'flex-start' : "flex-start"
                                                                }}
                                                            >
                                                                <div
                                                                    className={classes['custom-content-table']}
                                                                    title={RenderValueTableRow(menu, attribute, true)}
                                                                >
                                                                    {
                                                                        RenderValueTableRow(menu, attribute)
                                                                    }
                                                                </div>
                                                            </div>
                                                    })}
                                            </div>
                                        })}
                                    </div>
                                }
                                {
                                    (!loadingInstance) && holonInstance?.records?.length == 0 ? <div className={`_custom_scroll_body ${classes['custom-table-empty']}`}>
                                        {t("custom_table.empty_table")}
                                    </div> : <></>
                                }
                                <div
                                    className={`_custom_scroll_footer ${classes['_custom_scroll_footer']}`}
                                >
                                    <div
                                        className={`${classes['_custom_scroll_footer_row']}`}
                                    >
                                        {
                                            isShowCheckBox && <div className={`${classes["title-header"]} ${classes['custom-checkbox-th-footer']}`}></div>
                                        }
                                        {
                                            headHolonInstance?.map((attribute, index) => {
                                                return <div hidden={attribute?.isHidden} key={index} className={`${classes["title-header"]} ${classes['custom-title-footer']}`}>
                                                    <div>{attribute?.label?.trim()}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            }
            <div className={classes['custom-pagination']}>
                <CustomPagination
                    isRowPerpage
                    onChangeRowPerpage={onChangeRowPerpage}
                    limit={router.get([Constants.QueryParams.LIMIT.VALUE])} onChange={onPageChange} total={holonInstance?.total} page={router.get([Constants.QueryParams.OFFSET.VALUE])} />
            </div>
        </>
    )
}

export default TableHolonInstance