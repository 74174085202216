import ApiOperation from "adapter/ApiOperation"

const factory = {
    getBPFareUnitPriceByCodes: (payload) => {
        let url = `/rest/api/v1/management/bp/fare-unit-prices`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload.unitPriceCodes
        })
    },

    updateBPFareUnitPriceByCodes: (payload) => {
        let url = `/rest/api/v1/management/bp/fare-unit-prices`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload.unitPriceCodes
        })
    },
}

export default factory;