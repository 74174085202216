export default class RouterPath {
    static HOME = '/';
    static ESTIMATED_FARE = '/estimated-fare'
    static RESET_PASSWORD = '/reset-password';
    static LOGIN = '/';

    static PORTAL_DASHBOARD = '/portal';
    static PORTAL_INBOX = '/portal/inbox';
    static PORTAL_SETTING_CONFIGURATION = '/portal/settings/configuration';
    static PORTAL_SYSTEM_TEMPLATE = '/portal/system/template';
    static PORTAL_SYSTEM_SERVICE = '/portal/system/services';
    static PORTAL_SETTING = '/account-settings';

    static COMMON_INPUT = '/portal/common-input';
    static PORTAL_TERM_OF_USE = '/portal/term-of-use';
    static PORTAL_FAQ = '/portal/faq';
    static PORTAL_POLICY = '/portal/system-manage/policy';
    static PORTAL_POLICY_TERM_OF_USE = '/portal/system-manage/term-of-use';
    static PORTAL_POLICY_PRIVATE_POLICY = '/portal/system-manage/private-policy';
    static PORTAL_POLICY_PRIVACY_POLICY = '/portal/system-manage/privacy-policy';
    static PORTAL_UPDATE_POLICY = '/portal/system-manage/update-policy/:lang/:key';
    static PORTAL_PRIVACY_POLICY = '/portal/privacy-policy';
    static PORTAL_PRIVATE_POLICY = '/portal/private-policy';
    static PORTAL_FAQ_CATEGORY = '/portal/system-manage/faq-category';
    static PORTAL_FAQ_MANAGEMENT = '/portal/system-manage/faq';
    static PORTAL_REQUEST_ESTIMATION = '/portal/system-manage/request-estimation';
    static PORTAL_ESTIMATION = '/portal/system-manage/estimation';
    static PORTAL_CONTRACT = '/portal/system-manage/contract';
    static PORTAL_ORDER_MANAGEMENT = '/portal/system-manage/order-management';
    static PORTAL_MENU_MANAGEMENT = '/portal/system-manage/hex-menu';
    static PORTAL_SERVICE_INTRODUCTION_MANAGEMENT = '/portal/system-manage/service-introduction';
    static FREIGHT_CALCULATION_MANAGEMENT = '/portal/system-manage/freight-calculation-management'
    static ESTIMATED_FARE_MANAGEMENT = '/portal/system-manage/fare-estimate-management'
    static PORTAL_HOLON_TEMPLATE = '/portal/system-manage/holon-template';
    static PORTAL_HOLON_INSTANCE = '/portal/system-manage/holon-instance';
    static PORTAL_ALARM_HOLONE_SETTING = '/portal/system-manage/alarm-setting';
    static PORTAL_HEX_SERVICE = '/portal/system-manage/hex-service';
    static PORTAL_UPDATE_HEX_SERVICE = '/portal/system-manage/hex-service/:lang/:key';
    static PORTAL_ALARM = '/portal/system-manage/alarm';
    static PORTAL_BL_INSTANCE = '/portal/system-manage/bl-users';
    static PORTAL_USER_ACCOUNT_LIST = '/portal/system-manage/users';
    static PORTAL_PRODUCT = '/portal/setting/product';
    static PORTAL_CREATE_PRODUCT = '/portal/setting/create-product';
    static PORTAL_UPDATE_PRODUCT = '/portal/setting/update-product/:id';
    static PORTAL_WAREHOUSE = '/portal/warehouse';
    static PORTAL_CREATE_WAREHOUSE = '/portal/system-manage/create-warehouse';
    static PORTAL_UPDATE_WAREHOUSE = '/portal/system-manage/update-warehouse/:id';
    static PORTAL_BP_ORDER = '/portal/system-manage/bp-order-management';
    static PORTAL_SHIPPER = '/portal/shipper';
    static PORTAL_FREIGHT = '/portal/freight';
    static PORTAL_SENDER = '/portal/sender';
    static PORTAL_INVENTORY_COUNT = '/portal/inventory-count/:id';
    static PORTAL_INVENTORY_MANAGEMENT = '/portal/stock-management/:id';
    static PORTAL_RECEIVER = '/portal/receiver';
    static PORTAL_WALLET = '/portal/wallet';
    static MESSAGE = '/portal/inbox';
    static CARGO = '/portal/cargo';
    static PORTAL_WAREHOUSE_IN = '/portal/warehouse-in';
    static PORTAL_WAREHOUSE_IN_PARAMS = '/portal/warehouse-in/:id';
    static PORTAL_WAREHOUSE_OUT = '/portal/warehouse-out';
    static PORTAL_WAREHOUSE_OUT_PARAMS = '/portal/warehouse-out/:id';
    static PORTAL_WALLET_CHARGE = '/portal/wallet/charge/:id';
    static PORTAL_WALLET_WITHDRAW = '/portal/wallet/withdraw/:id';
    static PORTAL_TRANSACTION = '/portal/transaction';
    static PORTAL_COMPANY_TEMPLATE = '/portal/system-manage/outsource-companies';
    static PORTAL_SYSTEM_SETTING = '/portal/setting/system'
    static PORTAL_STOCK_MANAGEMENT = '/portal/stock-management';
    static PORTAL_REQUEST_PAYMENT = '/request-payment';
    static PORTAL_BP_REQUEST_PAYMENT = '/bprequest-payment';
    static PORTAL_BP_FARE_TABLE_MANAGEMENT = '/portal/system-manage/bp-fare-table-management';
    static PORTAL_NOTIFY = '/portal/setting/alarm';

    static PORTAL_INQUIRY_LIST = '/portal/inquiry';

    static getRouteWithId(path, id, keyword = ':id') {
        return path.replace(keyword, id);
    }
    static getRouteWithArrayKey(path, chars) {
        Object.keys(chars).map(key => {
            path = path.replace(`:${key}`, chars[key]);
        })
        return path;
    }
}
