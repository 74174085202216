import Constants from "utils/Constants";
import Utils from "utils/Utils";
import { getCoreAPI } from "shared/components/common/Util";


const { default: ApiOperation } = require("adapter/ApiOperation")

const factory = {
    getMenuManagement: (payload) => {
        let params = {
            isAll: true
        };
        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE]
        } else {
            params['page'] = 1;
        }

        // if (payload[Constants.QueryParams.LIMIT.VALUE]) {
        //     params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE]
        // } else {
        //     params['limit'] = Constants.PAGINATION.LIMIT;
        // }
        let url = "/rest/api/v1/management/menu-master";
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    getMasterHolonTemplate: (payload) => {
        var url = '/rest/api/v1/management/holon-template';
        let params = {
            isAll: true,
            sortColumn: 'id',
            sortType: "asc",
            masterCodes: Utils.convertObjectKeyToArr(Constants.CODE_MASTER)?.map(key => key?.value)?.join(",")
        };

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params
        })
    },
    updatePermissin: (payload) => {
        var url = '/rest/api/v1/management/menu-setting';

        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },

    getMenuMasterByCode: (payload) => {
        const url = `${getCoreAPI()}/management/menu-master/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    createMenuMaster: (payload) => {
        const url = `${getCoreAPI()}/management/menu-master`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },

    updateSortMenuMaster: (payload) => {
        const url = `${getCoreAPI()}/management/menu-master/sort`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },

    deleteMenuMaster: (payload) => {
        const url = `${getCoreAPI()}/management/menu-master/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'DELETE',
        })
    },

}

export default factory;