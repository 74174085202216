import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux/holon/action';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import Constants from 'utils/Constants';
import IconPlusHippieBlue from 'assets/icon/IconPlusHippieBlue';
import Button from 'shared/components/form-input/button/Button';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ORGANIZATIONAL_COLLABORATION, EVENT_SHOW_POPUP_ADD_INSTANCE, EVENT_SHOW_POPUP_CONFIRM_UPDATE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_POPUP_CONFIRM_DELETE } from 'utils/EventRegister';
import styles from "./CompanyInformationSettingsPopup.module.scss";
import useTrans from 'hooks/use-trans';
import FormAttributes from '../holon-instance-popup/FormAttributes';
import { convertRegexToOptions, showPopupNotification } from 'utils/Utils';
import moment from 'moment';
import { convertNumberToString } from 'utils/String';
import Loading from 'shared/components/form-input/button/loading/Loading';
import ActionHolonInstance from '../holon-instance-popup/ActionHolonInstance';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import Box from '../../box/Box';
import Validator from 'utils/Validator';
import IconDelete from "assets/icon/IconDelete";
import IconMinusAccordion from 'assets/icon/IconMinusAccordion';

export const RenderTableHolonInstance = (attributeHeader, attributes) => {
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
        return "*******";
    }

    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
        let options = convertRegexToOptions(attributeHeader?.regex);
        return <div className={styles['list-checkbox']}>
            {
                options?.map((option, index) => {
                    if (attributes.find(item => item.name == attributeHeader.key)?.value?.split(",")?.findIndex(item => item == option?.value) != -1) {
                        return <React.Fragment key={index}>
                            <span>{option?.label}{options[index + 1]?.value ? ',' : ''}</span>
                        </React.Fragment>
                    }
                })
            }
        </div>
    }

    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
        return attributes.find(item => item.name == attributeHeader.key)?.value ? moment(attributes.find(item => item.name == attributeHeader.key)?.value).format(Constants.FORMAT_DATE_TIME.DATE) : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
        return attributes.find(item => item.name == attributeHeader.key)?.value ? moment(attributes.find(item => item.name == attributeHeader.key)?.value).format(Constants.FORMAT_DATE_TIME.DATE_TIME) : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.MONEY.VALUE) {
        return attributes.find(item => item.name == attributeHeader.key)?.value ? convertNumberToString(attributes.find(item => item.name == attributeHeader.key)?.value, ",") : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.NUMBER.VALUE) {
        return attributes.find(item => item.name == attributeHeader.key)?.value ? convertNumberToString(attributes.find(item => item.name == attributeHeader.key)?.value, ",") : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.PERCENT.VALUE) {
        return attributes.find(item => item.name == attributeHeader.key)?.value ? `${attributes.find(item => item.name == attributeHeader.key)?.value}%` : ''
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
        let _find = convertRegexToOptions(attributeHeader?.regex)?.find(_option => _option?.value == attributes.find(item => item.name == attributeHeader.key)?.value);
        return _find?.label;
    }
    if (attributeHeader?.elementType == Constants.ELEMENT_TYPE.RADIO.VALUE) {
        let _find = convertRegexToOptions(attributeHeader?.regex)?.find(_option => _option?.value == attributes.find(item => item.name == attributeHeader.key)?.value);
        return _find?.label;
    }

    return attributes.find(item => item.name == attributeHeader.key)?.value;
}

const CompanyInformationSettingsPopup = (props) => {
    const { showVisible, payload } = props;
    const { holonData } = props.payload;
    const dispatch = useDispatch();
    const { updateCompanyError } = useSelector(state => state.holon);
    const [isUpdating, setIsUpdating] = useState(false);
    const [userTemplate, setUserTemplate] = useState([]);
    const [personalCustomerTemplate, setPersonalCustomerTemplate] = useState([]);
    const [tableTemplate, setTableTemplate] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [departmentTemplate, setDepartmentTemplate] = useState();
    const [positionTemplate, setPositionTemplate] = useState();
    const [holonInStanceDetail, setHolonInStanceDetail] = useState({})
    const [holonTemplateDetail, setHolonTemplateDetail] = useState()
    const [dataHolonInstanceActions, setHolonInstanceActions] = useState([])
    const [isLoadingCompanyData, setIsLoadingCompanyData] = useState(false)
    const [isLoadingCompanyInstance, setIsLoadingCompanyInstance] = useState(false)
    const [isReload, setIsReload] = useState(false)
    const [isReloadCompanyInstance, setIsReloadCompanyInstance] = useState(false)
    const { trans } = useTrans();
    const methods = useForm({
    });

    const { register, handleSubmit, setValue, setError, getValues } = methods;
    useEffect(() => {
        if (payload?.holonData) {
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
                payload: payload?.holonData?.templateId,
                callback: (data) => {
                    data && setHolonTemplateDetail(data);
                }
            })
        }

        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.USER_TEMPLATE,
            callback: (data) => {
                setUserTemplate(data);
            }
        })

        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.PERSONAL_CUSTOMER,
            callback: (data) => {
                setPersonalCustomerTemplate(data);
            }
        })

        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.DEPARTMENT,
            callback: (data) => {
                data?.length > 0 && setDepartmentTemplate(data[0].id);
            }
        })

        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.POSITION,
            callback: (data) => {
                data?.length > 0 && setPositionTemplate(data[0].id);
            }
        })
    }, []);

    useEffect(() => {
        setIsLoadingCompanyData(true)
        dispatch({
            type: Actions.GET_COMPANY,
            companyId: holonData?.id,
            callback: (data) => {
                setCompanyData(data);
                setIsLoadingCompanyData(false)
            },
            fallback: (data) => {
                setIsLoadingCompanyData(false)
                setCompanyData(false);
            }
        });
    }, [isReload]);

    useEffect(() => {
        setIsLoadingCompanyInstance(true)
        if (payload?.holonData) {
            dispatch({
                type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                payload: payload?.holonData.id,
                callback: (data) => {
                    setIsLoadingCompanyInstance(false)
                    setHolonInStanceDetail(data);
                }
            })
        }
    }, [isReloadCompanyInstance]);

    useEffect(() => {
        if (holonTemplateDetail?.validActions?.length > 0 && holonInStanceDetail?.id) {
            let data = [];
            holonTemplateDetail?.validActions?.map(x => {
                return data.push({
                    ...x,
                    IsEditabled: x?.IsEditabled,
                })
            })
            setHolonInstanceActions(data)
        }
    }, [holonTemplateDetail, holonInStanceDetail])

    useEffect(() => {
        const mergedTemplate = [];
        userTemplate?.map(template => template?.attributes?.map((field,) => (
            mergedTemplate.push(field)
        )))
        personalCustomerTemplate?.map(template => template?.attributes?.map((field,) => (
            mergedTemplate.push(field)
        )))
        setTableTemplate(mergedTemplate)
    }, [personalCustomerTemplate, userTemplate]);

    const handleReloadCompanyData = () => {
        setIsReload(!isReload);
    }

    const onSubmit = (data) => {
        const transformedObject = {};
        data.attributes.forEach(obj => {
            const key = obj.key;
            const value = obj[key];
            transformedObject[key] = value ?? '';
        });

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setIsUpdating(true)
                    dispatch({
                        type: Actions.UPDATE_COMPANY,
                        formData: {
                            ...transformedObject,
                            id: holonData?.id,
                            template_id: holonData?.templateId
                        },
                        callback: (data) => {
                            setIsUpdating(false)
                            setIsReloadCompanyInstance(!isReloadCompanyInstance)
                            showPopupNotification({
                                typePopup: 'message',
                                message: trans("E0043"),
                                newWindow: true
                            })
                        },
                        fallback: (detailError) => {
                            setIsUpdating(false)
                            let messageError = "";
                            Object.keys(detailError).forEach((key) => {
                                detailError[key]?.map((msg) => {
                                    messageError += msg;
                                });
                            });
                            EventRegister.emit(EVENT_SHOW_POPUP, {
                                open: true,
                                newWindow: true,
                                type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                payload: {
                                    title: messageError,
                                    newWindow: true,
                                    showHeader: false,
                                    customStyle: {
                                        padding: 0,
                                        borderTop: 0,
                                    },
                                },
                            });
                        }
                    })
                }
            }
        })
    }

    const handleOpenOrganizationalCollaborationPopup = (companyData, departmentHolon, positionHolon) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_ORGANIZATIONAL_COLLABORATION,
            open: true,
            newWindow: true,
            payload: {
                title: trans('Organizational_Collaboration.title'),
                headerSize: Constants.SIZE.SMALL,
                companyData,
                departmentHolon,
                positionHolon,
                handleReloadCompanyData
            }
        })
    }

    const handleClosePopupAddNewInstance = () => {
        setIsReload(!isReload);
        showVisible(false);
    }

    const handleClosePopupAddNewInstanceNotHidePopup = () => {
        setIsReload(!isReload);
    }

    const handleShowPopupAddNewInstance = (connection, templateId, key) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_ADD_INSTANCE,
            newWindow: true,
            payload: {
                handleBtnBack: handleClosePopupAddNewInstance,
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                dataPreset: {
                    templateId: templateId,
                    data: {
                        attributes: {},
                        connections: {
                            [key]: [{ value: connection.id, name: connection.name }]
                        }
                    }
                },
                dataBox: {
                    callback: handleClosePopupAddNewInstanceNotHidePopup
                },
                title: trans("holon.create_holon_instance")
            }
        })
    }

    useEffect(() => {
        if (updateCompanyError) {
            Object.keys(updateCompanyError).forEach((key) => {
                setError(key, {
                    type: 'custom',
                    message: updateCompanyError[key]?.[0]
                })
            })
        }
    }, [updateCompanyError]);

    useEffect(() => {
        return () => {
            dispatch({
                type: Actions.CLEAR_UPDATE_COMPANY_ERROR
            });
        }
    }, []);

    const handleRemoveConnection = (connectionId) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            newWindow: true,
            payload: {
                title: trans("E0044"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                isLoading: true,
                callback: (_props) => {
                    dispatch({
                        type: Actions.REMOVE_CONNECTION,
                        formData: {
                            connectionId: connectionId
                        },
                        callback: () => {
                            _props.hiddenPopup();
                            showPopupNotification({
                                typePopup: 'message',
                                message: trans("E0045"),
                                newWindow: true
                            })
                            handleReloadCompanyData()
                        },
                        fallback: () => {
                            _props.hiddenPopup();
                            showPopupNotification({
                                typePopup: 'message',
                                message: trans("E0031"),
                                newWindow: true
                            })
                        }
                    })
                }
            }
        })
    }

    const handleDeleteInstanceAndRemoveConnection = (holonId, connectionType) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            newWindow: true,
            payload: {
                title: trans("E0044"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                isLoading: true,
                callback: (_props) => {
                    dispatch({
                        type: Actions.DELETE_INSTANCE_REMOVE_CONNECTION,
                        formData: {
                            holonId,
                            connectionType
                        },
                        callback: () => {
                            _props.hiddenPopup();
                            showPopupNotification({
                                typePopup: 'message',
                                message: trans("E0045"),
                                newWindow: true
                            })
                            handleReloadCompanyData()
                        },
                        fallback: () => {
                            _props.hiddenPopup();
                            showPopupNotification({
                                typePopup: 'message',
                                message: trans("E0031"),
                                newWindow: true
                            })
                        }
                    })
                }
            }
        })
    }

    const control = methods.control;
    const {
        fields: fieldsHas,
    } = useFieldArray({
        control, name: "attributes"
    });

    useEffect(() => {
        if (holonTemplateDetail?.validAttributes?.length > 0 && holonInStanceDetail?.id) {
            methods.setValue("attributes", holonTemplateDetail?.validAttributes?.map(x => {
                let indexDetail = holonInStanceDetail?.attributes?.findIndex(attribute => attribute.name == x?.key);
                if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                    return {
                        ...x,
                        [x?.key]: Constants.PASSWORD.VALUE,
                        currentPassword: holonInStanceDetail.attributes[indexDetail]?.value,
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: holonInStanceDetail?.attributes[indexDetail]?.value?.split(",")
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: holonInStanceDetail?.attributes[indexDetail]?.value?.split(",")
                    }
                }
                return {
                    ...x,
                    [x?.key]: holonInStanceDetail.attributes[indexDetail]?.value
                }
            }))
        }
    }, [holonTemplateDetail, holonInStanceDetail])

    const RenderUserTable = (connections) => {
        return <div className={`${styles["table_container"]}`}>
            <div className={`${styles['CustomTable']} container-fluid h-75 p-0 bg-white`}>
                <table className="table table-borderless table-list">
                    <thead className={`${styles["title_header"]}`}>
                        <tr>
                            {userTemplate?.map(template => template?.attributes?.map((field,) => (
                                <th key={field.key}>{trans(field.label)}</th>
                            )))}
                            {personalCustomerTemplate?.map(template => template?.attributes?.map((field,) => (
                                <th key={field.key}>{trans(field.label)}</th>
                            )))}
                            <th>{trans("Holon_Template.action")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {connections?.map((positionConnection,) => (
                            positionConnection.type == "position" && positionConnection?.holons?.map((userHolon, index) => (
                                <tr className={styles['table_row']} key={index}>
                                    {tableTemplate?.map((field,) => (
                                        <td
                                            key={field.key}
                                            style={{
                                                textAlign: field?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE ? 'center' : "left"
                                            }}
                                        >
                                            {RenderTableHolonInstance(field, userHolon?.attributes)}
                                        </td>
                                    ))}
                                    <td>
                                        <div className={styles['action_column']}>
                                            <Button endIcon={<IconMinusAccordion />} color='transparent' onClick={() => handleRemoveConnection(positionConnection?.id, positionConnection?.type)}>
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    }

    const RenderDepartmentList = () => {
        return <div className={styles['data-attributes']}>
            <div className={styles['data-attributes-body']}>
                {companyData?.connections?.map((companyConnection, companyConnectionIndex) => (
                    companyConnection.type == "company" && <div key={companyConnectionIndex}>
                        {companyConnection?.holons?.map((departmentHolon, departmentHolonIndex) => (
                            departmentHolon.templateId == departmentTemplate?.toString() &&
                            <>
                                <div key={departmentHolonIndex} className={styles['Custom-body']}>
                                    {departmentHolon?.attributes?.map((department, departmentIndex) => (
                                        <>
                                            {department.name === 'name' && <div key={departmentIndex} className={styles['department-name-bar']}>
                                                <p>{`${trans("Company_Setting.department")}: ${department.value}`}</p>
                                                <div className={styles['action_btn_container']}>
                                                    <Button onClick={() => handleShowPopupAddNewInstance(departmentHolon, positionTemplate, "department")} size='large' color='transparent' endIcon={<IconPlusHippieBlue />} />
                                                    <div>
                                                        <Button color={'dark_gray'} size={"small"} endIcon={<IconDelete />} onClick={() => handleDeleteInstanceAndRemoveConnection(companyConnection?.holons?.[0]?.id, companyConnection?.type)} />
                                                    </div>
                                                </div>
                                            </div>}
                                        </>
                                    ))}
                                    {departmentHolon?.connections?.map((departmentConnection,) => (
                                        departmentConnection?.type == "department" && departmentConnection?.holons?.map((positionHolon, positionHolonIndex) => (
                                            positionHolon.templateId == positionTemplate?.toString() &&
                                            <>
                                                <div key={positionHolonIndex} className={styles['Custom-position-body']}>
                                                    {positionHolon?.attributes?.map((position, positionIndex) => (
                                                        <>
                                                            {position.name === 'name' && <div key={positionIndex} className={styles['position-name-bar']}>
                                                                <p>{`${trans("Company_Setting.position")}: ${position.value}`}</p>
                                                                <div className={styles['action_btn_container']}>
                                                                    <Button onClick={() => handleOpenOrganizationalCollaborationPopup(companyData, departmentHolon, positionHolon)} size='large' color='transparent' endIcon={<IconPlusHippieBlue />} />
                                                                    <div>
                                                                        <Button color={'dark_gray'} size={"small"} endIcon={<IconDelete />} onClick={() => handleDeleteInstanceAndRemoveConnection(departmentConnection?.holons?.[0]?.id, departmentConnection?.type)} />
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </>
                                                    ))}
                                                    {positionHolon && RenderUserTable(positionHolon.connections)}
                                                </div>
                                            </>
                                        ))
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    }

    return <div>
        <div className={styles['company-info-container']}>
            {isLoadingCompanyInstance ? <div className={styles['custom-loading']}><Loading /></div> :
                <div>
                    <FormProvider {...methods}>
                        <React.Fragment>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={styles['data-attributes']}>
                                    <FormAttributes data={fieldsHas} attribute_key={"company_info_settings_form_att"} />
                                    <div className={styles['save-btn']}>
                                        <Button className='my-4' color='gray' loading={isUpdating} type='submit' title={trans("save")} />
                                    </div>
                                </div>
                            </form>
                        </React.Fragment>
                    </FormProvider>
                    <ActionHolonInstance
                        dataHolonInstanceActions={dataHolonInstanceActions}
                        holonInStanceDetail={holonInStanceDetail}
                        payload={payload}
                        setHolonInStanceDetail={() => { }}
                        setLoadingHolonInstanceDetail={() => { }}
                    />
                </div>}
            {isLoadingCompanyData ? <div className={styles['custom-loading']}><Loading /></div> :
                companyData && <>
                    <Box>
                        <BoxHeader id={"company_info_settings_form_table"}>
                            <div className={styles['data-attributes']}>
                                <div className={styles['data-attributes-header']}>
                                    <p>{trans("Company_Setting.department_container_title")}</p>
                                    <Button onClick={() => handleShowPopupAddNewInstance(companyData, departmentTemplate, "company")} size='large' color='transparent' endIcon={<IconPlusHippieBlue />} />
                                </div>
                            </div>
                        </BoxHeader>
                        <BoxBody id={"company_info_settings_form_table"}>
                            {RenderDepartmentList()}
                        </BoxBody>
                    </Box>
                </>}
        </div>
    </div>
}

export default CompanyInformationSettingsPopup