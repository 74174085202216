import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const factory = {
    getListTransaction: (payload) => {
        let params = {};
        if (payload[Constants.QueryParams.SEARCH.VALUE]) {
            params['keyword'] = payload[Constants.QueryParams.SEARCH.VALUE];
        }
        if (payload[Constants.QueryParams.TRANSACTION_ID.VALUE]) {
            params['transactionId'] = payload[Constants.QueryParams.TRANSACTION_ID.VALUE];
        }
        if (payload[Constants.QueryParams.TRANSACTION_TYPE.VALUE]) {
            params['transactionType'] = payload[Constants.QueryParams.TRANSACTION_TYPE.VALUE];
        }
        if (payload[Constants.QueryParams.STATUS.VALUE]) {
            params['status'] = payload[Constants.QueryParams.STATUS.VALUE];
        }
        if (payload[Constants.QueryParams.TRANSACTION_TIME.VALUE]) {
            params['transactionTime'] = payload[Constants.QueryParams.TRANSACTION_TIME.VALUE];
        }
        if (payload[Constants.QueryParams.TRANSACTION_BY.VALUE]) {
            params['transactionBy'] = payload[Constants.QueryParams.TRANSACTION_BY.VALUE];
        }
        if (payload[Constants.QueryParams.AMOUNT.VALUE]) {
            params['amount'] = payload[Constants.QueryParams.AMOUNT.VALUE];
        }
        if (payload[Constants.QueryParams.WALLET.VALUE]) {
            params['wallet'] = payload[Constants.QueryParams.WALLET.VALUE];
        }
        if (payload[Constants.QueryParams.AUTHORIZE_NAME.VALUE]) {
            params['authorizerName'] = payload[Constants.QueryParams.AUTHORIZE_NAME.VALUE];
        }

        if (payload[Constants.QueryParams.OFFSET.VALUE]) {
            params['page'] = payload[Constants.QueryParams.OFFSET.VALUE];
        } else {
            params['page'] = 1;
        }
        if (payload[Constants.QueryParams.SORT.VALUE]) {
            params['sortColumn'] = payload[Constants.QueryParams.SORT.VALUE];
            params['sortType'] = payload[Constants.QueryParams.TYPE_SORT.VALUE];
        }

        if (payload[Constants.QueryParams.LIMIT.VALUE]) {
            params['limit'] = payload[Constants.QueryParams.LIMIT.VALUE];
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (payload?.isAll) {
            params['isAll'] = true;
        }
        let url = `/rest/api/v1/management/wallet-transaction/filter`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            params: params
        })
    },
    getTransactionDetail: (payload) => {
        let url = `/rest/api/v1/management/wallet-transaction/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },
    approveTransaction: (payload) => {
        let url = `/rest/api/v1/management/wallet-transaction/approve/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'POST'
        })
    },
    rejectTransaction: (payload) => {
        let url = `/rest/api/v1/management/wallet-transaction/reject/${payload}`;
        return ApiOperation.request({
            url: url,
            method: 'POST'
        })
    },
    createTransaction: (payload) => {
        let url = `/rest/api/v1/management/wallet-transaction`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
    updateTransaction: (payload) => {
        let url = `/rest/api/v1/management/wallet-transaction/${payload?.id}`;
        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
    getHolonInstanceByCode: (payload) => {
        let url = `/rest/api/v1/management/holon-instance/find-by-name-and-code`;
        return ApiOperation.request({
            url: url,
            method: 'POST',
            data: payload
        })
    },
}

export default factory;