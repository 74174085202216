import { useSelector } from 'react-redux';
import classes from './HolonTemplateName.module.scss';
import React from 'react'

function HolonTemplateName({ id = '', className = '' }) {
    const { holonTemplateMaster } = useSelector(state => state?.holonTemplate)

    const holonTemplate = holonTemplateMaster?.find(x => x?.id == id);
    if (holonTemplate?.id) {
        return <div title={holonTemplate?.name} className={`${classes['wrap-text']} ${className}`}>
            <p>{holonTemplate?.name}</p>
        </div>;
    }
    return id;
}

export default HolonTemplateName