const actionHexService = {
    GET_HEX_SERVICE: "GET_HEX_SERVICE",
    GET_HEX_SERVICE_SUCCESS: "GET_HEX_SERVICE_SUCCESS",
    GET_HEX_SERVICE_FAILED: "GET_HEX_SERVICE_FAILED",

    UPDATE_HEX_SERVICE: "UPDATE_HEX_SERVICE",
    UPDATE_HEX_SERVICE_SUCCESS: "UPDATE_HEX_SERVICE_SUCCESS",
    UPDATE_HEX_SERVICE_FAILED: "UPDATE_HEX_SERVICE_FAILED",

    UPLOAD_IMAGE: "UPLOAD_IMAGE",
    UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_FAILED: "UPLOAD_IMAGE_FAILED",

    GET_HEX_SERVICE_LIST: "GET_HEX_SERVICE_LIST",
    GET_HEX_SERVICE_LIST_SUCCESS: "GET_HEX_SERVICE_LIST_SUCCESS",
    GET_HEX_SERVICE_LIST_FAILED: "GET_HEX_SERVICE_LIST_FAILED",
}

export default actionHexService;