import React from 'react'
import classes from './Button.module.scss';

function ButtonLink(props) {
    return (
        props?.isHref ? <a title={props?.children ?? props?.title} target='_blank' rel="noreferrer" className={classes['btn-link']} style={props?.style} href={props?.children} >{props?.children ?? props?.title}</a>
            : <a title={props?.children ?? props?.title} target='_blank' rel="noreferrer" className={classes['btn-link']} style={props?.style} onClick={props?.onClick && props?.onClick}>{props?.children ?? props?.title}</a>
    )
}

export default ButtonLink