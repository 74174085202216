const actionAlarm = {
    GET_LIST_ALARM: "GET_LIST_ALARM",
    GET_LIST_ALARM_SUCCESS: "GET_LIST_ALARM_SUCCESS",
    GET_LIST_ALARM_FAILED: "GET_LIST_ALARM_FAILED",

    GET_ALARM_MASTER: "GET_ALARM_MASTER",
    GET_ALARM_MASTER_SUCCESS: "GET_ALARM_MASTER_SUCCESS",
    GET_ALARM_MASTER_FAILED: "GET_ALARM_MASTER_FAILED",
}

export default actionAlarm;