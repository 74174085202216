import React from 'react'
import useTrans from 'hooks/use-trans';

const RequiredLabel = () => {
    const { trans } = useTrans();

    return <span
        style={{
            background: '#ED6041',
            borderRadius: '50px',
            paddingInline: '5px',
            paddingBlock: '2px',
            height: '24px',
            color: '#FFF',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: '2.8px',
            whiteSpace: 'nowrap'
        }}>※{trans('Holon_Template.require')}</span>
}

export default RequiredLabel;