import ApiOperation from "adapter/ApiOperation";

const factory = {
    getListHexService: () => {
        return ApiOperation.request({
            url: `/rest/api/v1/home/data-master`,
        })
    },
    getPolicy: (payload) => {
        var url = `/rest/api/v1/master/policy/${payload}`;
        return ApiOperation.request({
            url: url,
        })
    },
};
export default factory;