import useTrans from 'hooks/use-trans';
import React from 'react'
import ReceiverContainer from 'shared/container/receiver/ReceiverContainer';

function ReceiverPage() {
    const { trans } = useTrans();

    document.title=trans("receiver.receiver_management")
    return (
        <ReceiverContainer />
    )
}

export default ReceiverPage