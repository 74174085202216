import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actionOcr from "./action";
import factory from "./factory";

function* getAllTemplateOcr() {
    yield takeEvery(actionOcr.GET_TEMPLATE_OCR, function* (payload) {
        try {
            const response = yield call(() => factory.getAllTemplateOcr());
            yield payload?.callback?.success && payload?.callback?.success(response);
        } catch (error) {

        }
    })
}

function* getOcrTable() {
    yield takeEvery(actionOcr.GET_TEMPLATE_OCR_TABLE, function* (payload) {
        try {
            const response = yield call(() => factory.getOcrTable());
            yield payload?.callback?.success && payload?.callback?.success(response);
        } catch (error) {

        }
    })
}

function* getDataFromTemplateOcr() {
    yield takeEvery(actionOcr.LOAD_DATA_FROM_TEMPLATE, function* (payload) {
        try {
            const response = yield call(() => factory.getDataFromTemplate(payload?.payload));
            if (!response?.data?.error) {
                yield payload?.callback?.success(response?.data);
            } else {
                yield payload?.callback?.failed(response?.error?.detail?.exception[0]);
            }
        } catch (error) {

        }
    })
}

function* getDataByDocumentIdAndMultipartFile() {
    yield takeEvery(actionOcr.LOAD_DATA_BY_DOCUMENT_ID_AND_MULTIPART_FILE, function* (payload) {
        try {
            const response = yield call(() => factory.getDataByDocumentIdAndMultipartFile(payload?.payload));
            if (!response?.error) {
                yield payload?.callback?.success(response?.data);
            } else {
                yield payload?.callback?.failed(response);
            }
        } catch (error) {
            yield payload?.callback?.failed(error);
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllTemplateOcr),
        fork(getDataFromTemplateOcr),
        fork(getDataByDocumentIdAndMultipartFile),
        fork(getOcrTable)
    ]);
}