import React, { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import useTrans from 'hooks/use-trans';
import Actions from 'redux/app/action';
import Constants from 'utils/Constants';
import ElementType from 'utils/ElementType';
import Validator from 'utils/Validator';
import FormInput from 'shared/components/custom-form/FormInput';
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import FormRadio from 'shared/components/custom-form/FormRadio';
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import styles from './FieldAccountSetting.module.scss'
import actionUser from 'redux/user/action';
import profile from 'assets/img/profile.png';
import FormFile from 'shared/components/custom-form/FormFile';
import FormImage from 'shared/components/custom-form/FormImage';
import Button from 'shared/components/form-input/button/Button';
import { convertRegexToOptions } from 'utils/Utils';
import FormAvatar from 'shared/components/custom-form/FormAvatar';
import FormListCheckbox from 'shared/components/custom-form/FormListCheckbox';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import { getFunction } from 'shared/components/common/Util';

const fields = {
    PCODE: 'address_code',
    MAIN: 'address_main',
    CHOMEBANCHIGO: 'address_chomebanchigo',
    BUILDING: 'address_building'
}

const FieldAccountSetting = (props) => {
    const { template, field, data, register, setValue, defaultValue, watch, getValues, clearErrors, disabled=false } = props;

    const dispatch = useDispatch();
    const { trans } = useTrans();
    // const watchAvatar = watch([`avatar`]); 
    const [isChangedPassword, setIsChangedPassword] = useState(false);
    const methods = useFormContext();
    // if (field.key == 'avatar') {
    //     if (!watchAvatar[0]) {
    //         setValue(`avatar`, profile);
    //     }
    // }

    // const handleUpdateAvatar = (e) => {
    //     const fileImg = e.target.files[0]
    //     dispatch({
    //         type: actionUser.UPLOAD_IMAGE,
    //         payload: fileImg,
    //         callback: (avatar) => {
    //             setValue(`avatar`, avatar);
    //         }}
    //     )
    // }

    // const handleRemoveAvatar = () => {
    //     setValue(`avatar`, profile);
    // }

    const handleChangePassword = () => {
        methods.unregister('current_password')
        methods.unregister('password')
        methods.unregister('confirm_password')
        setIsChangedPassword(!isChangedPassword)
    }

    const renderField = (field) => {
        switch (field?.elementType) {
            case ElementType.TEXT:
                let validate = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validate.push(Validator.required)
                }
                useEffect(() => {
                    if (field?.defaultValue) {
                        try {
                            let json = JSON.parse(field?.defaultValue);
                            if (json["default"]) {
                                setValue(field?.key, json["default"]);
                                getFunction(json["trigger"], field?.defaultValue, dispatch, methods.setValue, methods.getValues);
                            }
                        } catch (error) {
                            setValue(field?.key, defaultValue);
                        }
                    }
                }, []);
                const onChangeValue = () => {
                    getFunction("onChange", field?.defaultValue, dispatch, methods.setValue, methods.getValues);
                }
                const onBlurInput = () => {
                    getFunction("onBlur", field?.defaultValue, dispatch, methods.setValue, methods.getValues);
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput 
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validate]}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        onChangeValue={onChangeValue}
                                        onBlurInput={onBlurInput}
                                        // register={register}
                                        // defaultValue={data}
                                        // defaultValue={defaultValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )
            case ElementType.CHECKBOX:
                let validateCheckbox = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateCheckbox.push(Validator.requiredCheckbox)
                }
                let optionCheckBox = convertRegexToOptions(field?.regex);
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline-checkbox']}>
                                    <label htmlFor={`${field.key}`} style={{marginRight: '10px'}} className={styles['form-label-checkbox']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <div className={styles['ml-20']}>
                                        <FormListCheckbox 
                                            fieldName={`${field.key}`}
                                            validate={[...validateCheckbox]}
                                            options={optionCheckBox}
                                            isRegexLabel={field?.label}
                                            validationFE={true}
                                            disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                            // label={field.label}
                                            // register={register}
                                            // checked={Boolean(defaultValue)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            case ElementType.DATE:
                let validateDate = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDate.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormDatePicker 
                                        fieldName={`${field.key}`}
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateDate]}
                                        placeholder={field?.explain}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}                                        // name={`${field.key}`}
                                        // register={register}
                                        // defaultValue={defaultValue}
                                        // defaultValue={data}
                                    /> 
                                </div>
                            </div>
                        </div>
                    </>
                )
            case ElementType.DATETIME:
                let validateDateTime = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDateTime.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormDatePicker 
                                        fieldName={`${field.key}`}
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateDateTime]}
                                        placeholder={field?.explain}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        // name={`${field.key}`}
                                        // register={register}
                                        // defaultValue={defaultValue}
                                        // defaultValue={data}
                                    />
                                </div>  
                            </div>
                        </div>
                    </>
                )
            case ElementType.PASSWORD:
                let validatePass = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validatePass.push(Validator.required)
                }
                return (
                    <>
                        {/* <div className={styles['profile-submit-btn-start']}>
                            <Button color='lightblue' title={trans('change_password')} onClick={handleChangePassword} />
                        </div>
                        { isChangedPassword &&   
                            <> */}
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{trans('current_password')}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput 
                                        fieldName={`current_password`} 
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validatePass]}
                                        placeholder={field?.explain}
                                        type="password"
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        // register={register}
                                        // defaultValue={data}
                                        // defaultValue={defaultValue}
                                    />
                                </div>  
                            </div>
                        </div>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{trans('new_password')}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput 
                                        fieldName={`password`} 
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validatePass]}
                                        placeholder={field?.explain}
                                        type="password"
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        // register={register}
                                        // defaultValue={data}
                                        // defaultValue={defaultValue}
                                    />
                                </div>  
                            </div>
                        </div>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{trans('new_password_confirm')}</label>
                                    <span style={{ color: 'red' }}>*</span>
                                    <FormInput 
                                        fieldName={`confirm_password`} 
                                        validate={[Validator.confirmPassword(watch(`password`))]}
                                        placeholder={trans('new_password_confirm')}
                                        type="password"
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        // register={register}
                                        // defaultValue={data}
                                        // defaultValue={defaultValue}
                                    />
                                </div>  
                            </div>
                        </div>
                        {/* </>
                        } */}
                    </>
                )
            case ElementType.FILE:
                let validateFile = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateFile.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormFile
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateFile]}
                                        type="file"
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        // register={register}
                                        // defaultValue={data}
                                        // defaultValue={defaultValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )
            case ElementType.IMAGE:
                let validateImage = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateImage.push(Validator.required)
                }
                if (field.key == 'avatar'){
                    return (
                        <>
                            {/* <div className={styles.profile}>
                                <div className={styles['profile-name']}>
                                    <span>自分の写真</span>
                                </div>
                                <div className="row">
                                    <div className={`${styles['avatar-container']}`}>
                                        <div className={styles['profile-img']}>
                                            <img src={watchAvatar[0]} alt="hugenerd" width="50" height="50" className="rounded-circle" />
                                        </div>
                                        <div className={styles['edit-img']}>
                                            <div className={styles['upload-new-img']} >
                                                <label style={{cursor: 'pointer'}} htmlFor="update-avatar">新しい写真をアップロード</label>
                                                <input
                                                    hidden
                                                    {...register(`${field.key}`)}
                                                    type="file"
                                                    id="update-avatar"
                                                    className="update-avatar"
                                                    accept=".png,.jpg,.jpeg"
                                                    disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                                    onChange={handleUpdateAvatar}
                                                />
                                            </div>
                                            <div className={styles['delete-img']} onClick={handleRemoveAvatar}>
                                                <span style={{cursor: 'pointer'}}>写真を削除</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <FormAvatar
                                fieldName={`${field.key}`} 
                                validate={[Validator.customValidator(field?.regex, field?.msg), ...validateImage]}
                                disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                            />
                        </>
                    )
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormImage
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateImage]}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        // type="file"
                                        // register={register}
                                        // defaultValue={data}
                                        // defaultValue={defaultValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )
            case ElementType.RADIO:
                let validateRadio = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateRadio.push(Validator.required)
                }
                let optionRadio = convertRegexToOptions(field?.regex);
                return (
                    <>
                        <div className={`row ${styles['gender-checkbox']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-check-name']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-check-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                </div>
                                <FormRadio 
                                    fieldName={`${field.key}`}
                                    options={optionRadio}
                                    validate={[...validateRadio]}
                                    disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                    isRegexLabel={field?.label}
                                    validationFE={true}
                                    className={styles['radio']}
                                    // name={`${field.key}`}
                                    // master={master}
                                    // value={data}
                                    // value={defaultValue}
                                    // register={register}
                                />
                            </div>
                        </div>
                    </>
                )
            case ElementType.SELECT:
                let optionSelect = convertRegexToOptions(field?.regex);

                let validateSelect = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateSelect.push(Validator.required)
                }
                if (field?.isMultiSelected == "1") {
                    return (
                        <>
                            <div className={`row ${styles['row-form-input']}`}>
                                <div className={styles['fit-content']}>
                                    <div className={styles['form-outline']}>
                                        <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                        {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                        <FormSelect
                                            options={optionSelect}
                                            isMultiSelected
                                            fieldName={`${field.key}`}
                                            validate={[...validateSelect]}
                                            placeholder={field?.explain}
                                            disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                            isRegexLabel={field?.label}
                                            validationFE={true}
                                            attributeKey={field?.key}
                                            // data={master}
                                            // name={`${field.key}`}
                                            // defaultValue={data}
                                            // defaultValue={defaultValue}
                                            // register={register}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className={`row ${styles['row-form-input']}`}>
                                <div className={styles['fit-content']}>
                                    <div className={styles['form-outline']}>
                                        <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                        {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                        <FormSelect
                                            options={optionSelect}
                                            fieldName={`${field.key}`}
                                            validate={[...validateSelect]}
                                            placeholder={field?.explain}
                                            disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                            isRegexLabel={field?.label}
                                            validationFE={true}
                                            // data={master}
                                            // name={`${field.key}`}
                                            // defaultValue={data}
                                            // defaultValue={defaultValue}
                                            // register={register}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            case ElementType.EMAIL:
                let validateEmail = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateEmail.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateEmail]}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        // register={register}
                                        // defaultValue={data}
                                        // defaultValue={defaultValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )
            case ElementType.NUMBER:
                let validateNumber = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateNumber.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput 
                                        format={Constants.FormInputFormat.NUMBER.VALUE} 
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateNumber]} 
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )
            case ElementType.URL:
                let validateUrl = [Validator.URL];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUrl.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateUrl]}
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )
            case ElementType.TEL:
                let validateTel = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateTel.push(Validator.required)
                }
                return (
                    <>
                        <div className={`${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>    
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <div className={styles['form-outline-with-btn']}>
                                        <FormInput
                                            fieldName={`${field.key}`} 
                                            placeholder={field?.explain}
                                            validate={[Validator.customValidator(field?.regex, field?.msg), ...validateTel]}
                                            // register={register}
                                            // defaultValue={data}
                                            // defaultValue={defaultValue}
                                            disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                            isRegexLabel={field?.label}
                                            validationFE={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            case Constants.ELEMENT_TYPE.AUTOGEN.VALUE:
                let validateAutogen = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAutogen.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput 
                                        disabled 
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateAutogen]} 
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) 
            case Constants.ELEMENT_TYPE.UNIQUE.VALUE:
                let validateUnique = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUnique.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput 
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateUnique]} 
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) 
            case Constants.ELEMENT_TYPE.TEXT_AREA.VALUE:
                let validateTxtArea = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateTxtArea.push(Validator.requiredCheckbox)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormTextArea 
                                        validate={[...validateTxtArea]} 
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) 
            default:
                let validateDefault = [];
                if (field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDefault.push(Validator.required)
                }
                return (
                    <>
                        <div className={`row ${styles['row-form-input']}`}>
                            <div className={styles['fit-content']}>
                                <div className={styles['form-outline']}>
                                    <label htmlFor={`${field.key}`} className={styles['form-label']}>{field?.label}</label>
                                    {field?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                    <FormInput 
                                        fieldName={`${field.key}`} 
                                        placeholder={field?.explain}
                                        validate={[Validator.customValidator(field?.regex, field?.msg), ...validateDefault]}
                                        disabled={field?.isEditabled == Constants.STATUS_CHECKBOX.YES.VALUE ? false : true}
                                        isRegexLabel={field?.label}
                                        validationFE={true}
                                        // register={register}
                                        // defaultValue={data}
                                        // defaultValue={defaultValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )
        }
    }

    return <>
        {
            renderField(field)
        }
    </>
}
export default FieldAccountSetting;