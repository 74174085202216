import React from "react";

function Configuration(){
    return (
        <div className="container-fluid">
            <h1>Configuration</h1>
        </div>
    );
}


export default Configuration;