import React from 'react'
import classes from './CustomReactSelect.module.scss';
import _ from 'lodash';
import Select, { components } from 'react-select';
import IconChevronUp from 'assets/icon/IconChevronUp';
import IconChevronDown from 'assets/icon/IconChevronDown';

function CustomReactSelect(props) {
    const {
        escapeClearsValue = true,
        placeholder = "Select",
        isSearchable = true,
        noOptionsMessage = () => { },
        errors = [],
        name = 'custom-select-name',
        disabled = false,
        value,
        inputRef = null,
        options = [],
        isClearable = true,
        isMulti = false,
        _props,
        styles = {},
        menuPortalTarget,
        group,
        hideSelectedOptions = false,
        closeMenuOnSelect = true,
        maxToShowProps = 1,
        customComponents = {},
        backgroundColorActive = '#d8d7d7',
        colorActive = '#333333',
        height = 30,
        colorFocusOption = '#d8d7d7'
    } = props
    const onChange = (val, action) => {
        if (!isMulti) {
            props.onChange(val?.key, action)
        } else {
            let rs = val.map((x) => x?.key)
            props.onChange(rs, action)
        }
    }
    let showError = false;

    let arr = name.split(".");

    let error = null

    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return rs = rs[e]
            }
            return {}

        }, errors)
        error = result
        showError = !_.isEmpty(error);
    }
    const getValue = () => {
        if (!isMulti) {
            if (group) {
                let object = null;
                options?.map((v) => {
                    let indexValue = v?.options?.findIndex((x) => x?.key == value);
                    if (indexValue != -1) {
                        object = v?.options[indexValue];
                    }
                })
                return object;
            } else {
                let indexValue = options?.findIndex((x) => x?.key == value);
                if (indexValue != -1) {
                    return options[indexValue];
                }
                return null;
            }
        } else {
            if (group) {
                let object = [];
                options?.map((v) => {
                    value?.map((x) => {
                        let indexValue = v?.options?.findIndex((_xx) => _xx?.key == x);
                        if (indexValue != -1) {
                            object.push(v?.options[indexValue]);
                        }
                    });
                })
                return object;
            } else {
                return options?.filter((x) => value.includes(x.key));
            }
        }

    }

    const [clearable, setIsClearable] = React.useState(false);

    const ClearIndicatorDemo = (props) => {
        const {
            innerProps: { ref, ...restInnerProps },
        } = props;

        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={{ display: `${clearable ? '' : 'none'}`, }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.46967 6.46967C6.76256 6.17678 7.23744 6.17678 7.53033 6.46967L17.5303 16.4697C17.8232 16.7626 17.8232 17.2374 17.5303 17.5303C17.2374 17.8232 16.7626 17.8232 16.4697 17.5303L6.46967 7.53033C6.17678 7.23744 6.17678 6.76256 6.46967 6.46967Z" fill="#707070" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.5303 6.46967C17.8232 6.76256 17.8232 7.23744 17.5303 7.53033L7.53033 17.5303C7.23744 17.8232 6.76256 17.8232 6.46967 17.5303C6.17678 17.2374 6.17678 16.7626 6.46967 16.4697L16.4697 6.46967C16.7626 6.17678 17.2374 6.17678 17.5303 6.46967Z" fill="#707070" />
                </svg>
            </div>
        );
    };

    const MultiValue = ({ index, getValue, ...props }) => {
        const maxToShow = maxToShowProps;
        const overflow = getValue()
            .slice(maxToShow)
            .map((x) => x.label);

        return index < maxToShow ? (
            <components.MultiValue {...props} />
        ) : index === maxToShow ? (
            <MoreSelectedBadge items={overflow} />
        ) : null;
    };
    const MoreSelectedBadge = ({ items }) => {
        const style = {
            marginLeft: "auto",
            background: "#d4eefa",
            borderRadius: "4px",
            fontSize: "11px",
            padding: "3px",
            order: 99,
            whiteSpace: 'nowrap'
        };

        const title = items.join(", ");
        const length = items.length;
        const label = `+ ${length}`;

        return (
            <div style={style} title={title}>
                {label}
            </div>
        );
    };

    let MultiValueProps = {};
    if (isMulti) {
        MultiValueProps = MultiValue;
    }
    const DropdownIndicator = (prop) => {
        return (
            components.DropdownIndicator
                &&
                prop.selectProps.menuIsOpen ?
                (
                    <components.DropdownIndicator {...prop}>
                        <IconChevronUp strokeWidth="1" fontSize={11} />
                    </components.DropdownIndicator>
                ) : (
                    <components.DropdownIndicator {...prop}>
                        <IconChevronDown strokeWidth="1" fontSize={11} />
                    </components.DropdownIndicator>
                )
        );
    };

    const NoOptionsMessage = () => {
        return <></>
    };

    return (
        <div className={classes["CustomSelect"]}>
            <Select
                name={name}
                ref={inputRef}
                className={classes["select"]}
                options={props.options}
                styles={{
                    menu: base => ({ ...base, zIndex: 100 }),
                    control: base => ({
                        ...base,
                        minHeight: 30,
                        fontSize: 12,
                        borderRadius: 4, border: `0.5px solid ${showError ? "red" : "#929292"}`,
                        boxShadow: 'unset',
                        "&:hover": {
                            borderColor: "#929292"
                        },
                        "&:focus": {
                            borderColor: "#929292",
                            boxShadow: 'unset'
                        },
                        backgroundColor: disabled ? '#E3E3E3' : '#fff',
                        cursor: 'pointer'
                    }),
                    placeholder: base => ({ ...base, fontWeight: 400, color: '#929292', opacity: 1 }),
                    option: (base, { isSelected, isFocused }) => ({
                        ...base,
                        fontWeight: 400,
                        color: isSelected ? colorActive : '#333333',
                        backgroundColor: isSelected ? backgroundColorActive : isFocused ? colorFocusOption : '#fff',
                        fontSize: 12,
                        cursor: 'pointer',
                        padding: '4px 10px',
                        "&:focus": {
                            backgroundColor: 'none'
                        }
                    }),
                    singleValue: base => ({ ...base, fontWeight: 400, color: '#333333', textAlign: 'left' }),
                    indicatorSeparator: base => ({ ...base, width: 0 }),
                    indicatorsContainer: base => ({ ...base, minHeight: 30, height: 30 }),
                    ...styles,
                }}
                components={{ ...customComponents, DropdownIndicator, MultiValue: MultiValueProps, NoOptionsMessage }}
                onMenuOpen={() => {
                    setIsClearable(true);
                }}
                onMenuClose={() => {
                    setIsClearable(false);
                }}
                placeholder={placeholder}
                onChange={(e, action) => {
                    if (typeof e === 'undefined') {
                        onChange(null)
                        return
                    }
                    onChange(e, action)
                }
                }
                escapeClearsValue={escapeClearsValue}
                isSearchable={isSearchable}
                noOptionsMessage={noOptionsMessage}
                isDisabled={disabled}
                value={getValue()}
                isClearable={isClearable}
                isMulti={isMulti}
                menuPortalTarget={menuPortalTarget}
                hideSelectedOptions={false}
                {..._props}
                classNamePrefix={`custom__select__form ${_props?.classNamePrefix ?? 'react-select'}`}
                closeMenuOnSelect={closeMenuOnSelect}
            />
        </div>

    )
}

export default CustomReactSelect