import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './Menu.module.scss';
import { List, ListItem, ListItemButton, Collapse, Typography, ListItemText } from '@mui/material';
import IconChevronDownV2 from 'assets/icon/IconChevronDownV2';
import IconChevronUpV2 from 'assets/icon/IconChevronUpV2';
import EventRegister, { EVENT_SHOW_ALARM_LIST_POPUP, EVENT_SHOW_POPUP } from 'utils/EventRegister';

function MenuItem({ item, currentRoute, onMenuClick, expandedMenus, setExpandedMenus, textStyle, parent }) {
    const isExpanded = expandedMenus[item.code];
    const [isCurrent, setCurrent] = useState(false);

    useEffect(() => {
        const parser = document.createElement('a');
        parser.href = item.href;
        const path = parser.pathname;
        if (path == currentRoute) {
            setCurrent(true);
        } else {
            setCurrent(false);
        }
    }, [currentRoute]);

    const handleItemClick = () => {
        onMenuClick(item.code);

        // Toggle the expanded state of the clicked menu item
        setExpandedMenus(prevExpandedMenus => ({
            ...prevExpandedMenus,
            [item.code]: !isExpanded,
        }));
    };

    const openNotify = () => {
        // EventRegister.emit(EVENT_SHOW_POPUP_ALARM);
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_ALARM_LIST_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    backgroundColor: 'transparent'
                }
            }
        })
    }

    return (
        <React.Fragment>
            {/* {
                item.isEnable && item.code == "M003" && <ListItemButton
                    onClick={openNotify}
                    className={`${styles['item-button']} ${isCurrent ? styles['menu-selected'] : ''}`}
                >
                    <ListItemText
                        primary={
                            <Typography
                                className={styles['item-typography']}
                            >
                                {item?.label}
                            </Typography>
                        }
                        className={styles['item-text']}
                    />
                </ListItemButton>
            } */}
            {
                item.isEnable && <React.Fragment>
                    <ListItemButton 
                        component={item.href != '' ? Link : "div"}
                        to={item.href != '' ? item?.href : 'javascript:;'} 
                        className={`${styles['item-button']} ${isCurrent && styles['menu-selected']}`}  
                        onClick={handleItemClick}>
                        <ListItemText
                            primary={
                                <Typography
                                    className={textStyle}
                                >
                                    {item?.label}
                                </Typography>
                            }
                            className={styles['item-text']}
                        />
                        {item.children && item.children.length > 0 && (
                            isExpanded ? <IconChevronDownV2 /> : <IconChevronUpV2 />
                        )}
                    </ListItemButton>
                    {item.children && item.children.length > 0 && (
                        <Collapse in={isExpanded}>
                            <List className={styles['list-button-content']}>
                                {item.children.map(child => (
                                    <MenuItem
                                        key={child.code}
                                        item={child}
                                        currentRoute={currentRoute}
                                        onMenuClick={onMenuClick}
                                        expandedMenus={expandedMenus}
                                        setExpandedMenus={setExpandedMenus}
                                        textStyle={styles['item-typography-content']}
                                        parent={item}
                                    />
                                ))}
                            </List>
                        </Collapse>
                    )}
                </React.Fragment>
            }
        </React.Fragment>
    );
}

function MenuTree({ items, currentRoute }) {
    const [expandedMenus, setExpandedMenus] = useState({});
    const location = useLocation();

    function flattenMenu(menu) {
        const result = [];

        function recursiveFlatten(items) {
            for (const item of items) {
                result.push(item);
                if (item.children && item.children.length > 0) {
                    recursiveFlatten(item.children);
                }
            }
        }
        recursiveFlatten(menu);
        return result;
    }

    function getMenuParent(flatArray, parentCode = undefined) {
        for (const item of flatArray) {
            const parser = document.createElement('a');
            parser.href = item.href;
            const path = parser.pathname;
            if (location.pathname == path || item.code == parentCode) {
                setExpandedMenus(prev => ({
                    ...prev,
                    [item.code]: !prev[item.code]
                }))
                if (item.parentCode != "    ") {
                    var parentMenu = flatArray?.filter(x => x.level < item.level);
                    getMenuParent(parentMenu, item.parentCode);
                }
            }
        }
    }

    useEffect(() => {
        const flatArray = flattenMenu(items);
        getMenuParent(flatArray);
    }, [items])

    
    const handleMenuClick = (code) => {
        setExpandedMenus(prevExpandedMenus => ({
            ...prevExpandedMenus,
            [code]: !prevExpandedMenus[code],
        }));
    }

    return (
        <List className={styles['list-button-content']}>
            {items.map(menuItem => (
                <MenuItem
                    key={menuItem.code}
                    item={menuItem}
                    currentRoute={location.pathname}
                    onMenuClick={handleMenuClick}
                    expandedMenus={expandedMenus}
                    setExpandedMenus={setExpandedMenus}
                    textStyle={styles['item-typography']}
                    parent={menuItem}
                />
            ))}
        </List>
    );
}

export default MenuTree;