import React from 'react';
import _ from 'lodash';
import classes from './CustomCheckBox.module.scss';
import ErrorMessage from '../error-message/ErrorMessage';
import IconChecked from 'assets/icon/IconChecked';
import IconCheck from 'assets/icon/IconCheck';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';

const ListCheckbox = (props) => {
    const {
        onChange,
        ref,
        value = "",
        errors,
        disabled = false,
        name,
        isTooltip = false,
        label,
        inputRef,
        className,
        options = [],
        isRegexLabel,
        validationFE
    } = props;
    const onChangeCheckBox = (e, value) => {
        e.stopPropagation();
        onChange(value?.join(","));
    };

    let showError = false;
    let error = {
        message: '',
    };

    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    let checkIndex = (_value) => value && value?.split(",")?.findIndex(x => x == _value) != -1 ? true : false;
    return (
        <>
            <div className={classes['list-checkbox']}>
                {
                    options?.map((x, key) => {
                        return <div
                            className={`${classes['CustomCheckBox']} ${classes['CustomCheckBox_Text']} ${disabled ? classes['disabled'] : ''} CustomCheckBox ${className}`}
                            ref={ref}
                            onClick={(e) => {
                                if (disabled) {
                                    return;
                                }
                                let dataChange = []
                                if (value && value != '') {
                                    dataChange = value?.split(",");
                                } else {
                                    dataChange = []
                                }
                                let indexOf = dataChange?.findIndex(v => v == x?.value);
                                if (indexOf != -1) {
                                    dataChange.splice(indexOf, 1);
                                } else {
                                    dataChange.push(x?.value);
                                }
                                onChangeCheckBox(e, dataChange);
                            }}
                            key={key}
                        >
                            <button
                                type="button"
                                ref={inputRef}
                                disabled={disabled}
                            >
                                {checkIndex(x?.value) ? (
                                    <IconChecked color="#0E525E" fontSize={16} />
                                ) : (
                                    <IconCheck color="#0E525E" />
                                )}
                            </button>
                            <p title={x?.label} style={{
                                width: key == options?.length - 1 ? (options?.length - 1) % 2 == 0 ? '100%' : '120px' : '120px'
                            }}>{x?.label}</p>
                        </div>
                    })
                }
            </div >
            {/* {showError && !isTooltip ? (
                <div className={classes['CustomCheckBox_Error']}>
                    <ErrorMessage message={error?.message} />
                </div>
            ) : (
                <></>
            )
            } */}
            {showError ? (
                <div className={classes['CustomCheckBox_Error']}>
                    {
                        !validationFE ? (<ErrorMessage message={error?.message} />) : (
                            isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                        )
                    }
                </div>) : (
                <></>
            )}
        </>
    );
};

export default ListCheckbox;
