import React, { useEffect, useState } from 'react'
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import actionAlarm from 'redux/alarm-holon-setting/action';
import FormSelect from 'shared/components/custom-form/FormSelect';
import FormInput from 'shared/components/custom-form/FormInput';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import Validator from 'utils/Validator';
import Loading from 'shared/components/form-input/button/loading/Loading';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import WrapContentBody from '../custom-body/WrapContentBody';
import CustomBox from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import { showPopupNotification } from 'utils/Utils';


function CreateAlarmPopup(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const { t } = useTrans();
    const [listAttributes, setListAttributes] = useState([])
    const [loading, setLoading] = useState(false)
    const [listType, setListType] = useState([])
    const [listAlarmMaster, setListAlarmMaster] = useState([]);
    const [listConnections, setListConnection] = useState([]);
    const dispatch = useDispatch();
    const {
        holonTemplateDetail, master, alarmCodeMaster, loading: {
            loadingAddAlarm,
        }
    } = useSelector(state => state.alarmHolonTemplate);

    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            if (name == "holonTemplateId") {
                if (value['holonTemplateId']?.length > 0) {
                    setLoading(true);
                    setListAttributes([])
                    dispatch({
                        type: actionAlarm.GET_HOLON_TEMPLATE_DETAIL,
                        payload: value['holonTemplateId'][0],
                        callback: () => {
                            setLoading(false);
                        }
                    })
                    dispatch({
                        type: actionAlarm.GET_OWNER_COLLECTION_HOLON_TEMPLATE,
                        payload: value['holonTemplateId'][0],
                        callback: (_data) => {
                            setListConnection(_data?.map(x => {
                                return {
                                    label: x?.note,
                                    key: x?.key,
                                    value: x?.key,
                                    key_option: x?.key,
                                }
                            }))
                        }
                    })
                } else {
                    setListAttributes([])
                    methods.setValue("holonKey", null)
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [methods.watch])


    useEffect(() => {
        if (holonTemplateDetail?.id) {
            let listConnection = [];
            holonTemplateDetail?.connections?.map(connection => {
                if (connection?.holonTemplate?.length > 0) {
                    return listConnection.push({
                        value: connection?.key,
                        label: connection?.holonTemplate[0],
                        key: connection?.key,
                    })
                }
            })
            setListAttributes(holonTemplateDetail?.attributes?.map(x => {
                return {
                    value: x?.id,
                    label: x?.label,
                    key: x?.id,
                    key_option: x?.key
                }
            }))
        } else {
            setListAttributes([])
        }
    }, [holonTemplateDetail])


    useEffect(() => {
        if (master?.alarmTypes) {
            setListType(Object.keys(master?.alarmTypes).map(key => {
                return {
                    value: key,
                    key: key,
                    label: master?.alarmTypes[key]
                }
            }))
        }
    }, [master])

    const onSubmit = (data) => {
        let params = {
            type: data?.type,
            holonTemplateId: holonTemplateDetail?.id,
            holonTemplateName: holonTemplateDetail?.name,
            holonKeyLabel: holonTemplateDetail?.attributes?.find(x => x?.id == data?.holonKey)?.label,
            holonKeyValue: holonTemplateDetail?.attributes?.find(x => x?.id == data?.holonKey)?.key,
            conditionsText: data?.conditionsText,
            conditionsLogic: data?.conditionsLogic,
            messageText: data?.messageText,
            alarmLevel: parseInt(data?.alarmLevel, 10),
            alarmOnScreen: data?.alarmOnScreen || [],
            alarmOnMail: data?.alarmOnMail || [],
            subjectKey: data?.target_key || "",
            status: data?.status
        }

        dispatch({
            type: actionAlarm.CREATE_ALARM_HOLON_SETTING,
            payload: params,
            callback: {
                success: () => {
                    showVisible();
                    setListAlarmMaster(null);
                    setListAttributes([]);
                    setListConnection(null);
                    setListType(null);
                    payload?.callback();
                    showPopupNotification({
                        typePopup: 'message',
                        message: t("E0041")
                    })
                },
                failed: (msg) => {
                    showPopupNotification({
                        typePopup: 'message',
                        message: msg,
                        newWindow: true
                    })
                }
            }
        })
    }

    let statusTemplate = [
        {
            value: Constants.STATUS_ALARM.ACTIVE.VALUE,
            key: Constants.STATUS_ALARM.ACTIVE.VALUE,
            label: t("Holon_Template.valid"),
        },
        {
            value: Constants.STATUS_ALARM.UNACTIVE.VALUE,
            key: Constants.STATUS_ALARM.UNACTIVE.VALUE,
            label: t("Holon_Template.invalid"),
        }
    ]

    useEffect(() => {
        return () => {
            dispatch({
                type: actionAlarm.GET_HOLON_TEMPLATE_DETAIL_SUCCESS,
                payload: {
                    holonTemplateCodeMaster: {}
                }
            })
            setListAttributes([]);
            setListAlarmMaster(null);
            setListConnection(null);
            setListType(null);
            methods.reset({
                holonTemplateId: null
            })
        }
    }, [])


    return (
        <BoxContentPopup width='1000px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        handleBtnBack={() => showVisible()}
                        actionSectionBottom={<Button loading={loadingAddAlarm} type='submit' title={t("Holon_Template.save")} />}
                        title={payload?.title}
                    >
                        <CustomBox>
                            <BoxHeader>
                                <BoxTitle>{t("Holon_Template.setting")}</BoxTitle>
                            </BoxHeader>
                            <BoxBody>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.Warning/notification")}</BoxLabel>
                                    <BoxContent>
                                        <FormSelect
                                            validationFE
                                            isRegexLabel={t("Holon_Template.Warning/notification")}
                                            validate={[]} onChangeSelect={(e) => {
                                                if (e == 'T') {
                                                    methods.setValue("alarmLevel", '0');
                                                    methods.setError("alarmLevel", {
                                                        message: undefined,
                                                    })

                                                    methods.clearErrors("alarmLevel")
                                                    setListAlarmMaster([
                                                        {
                                                            label: master?.alarmLevel['0'],
                                                            value: "0",
                                                            key: "0",
                                                        }
                                                    ])
                                                } else {
                                                    let data = [];
                                                    Object.keys(master?.alarmLevel).map(key => {
                                                        if (key != "0") {
                                                            data.push({
                                                                label: master?.alarmLevel[key],
                                                                value: key,
                                                                key: key
                                                            })
                                                        }
                                                    })
                                                    setListAlarmMaster(data)
                                                    methods.setValue("alarmLevel", data[0].key);
                                                    methods.setError("alarmLevel", {
                                                        message: undefined
                                                    })
                                                    methods.clearErrors("alarmLevel")
                                                }
                                            }} options={listType ?? []} fieldName='type' />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.holon_template")}</BoxLabel>
                                    <BoxContent>
                                        <FormSelectTemplate
                                            placeholder={t("alarm.please_select_the_target_of_notification_alarm_conditions")}
                                            isRegexLabel={t("Holon_Template.holon_template")}
                                            validationFE
                                            masterCode={null}
                                            style={{
                                                width: '100%'
                                            }} isBorder={false} isShowButtonEdit isMulti={false} fieldName='holonTemplateId' />
                                        {
                                            loading && <div style={{ marginRight: 10 }} className='d-flex align-item-center mr-3'><Loading /></div>
                                        }
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.key")}</BoxLabel>
                                    <BoxContent>
                                        <FormSelect
                                            placeholder={t("alarm.please_specify_notification_alarm_conditions_Example_Balance")}
                                            isRegexLabel={t("Holon_Template.key")}
                                            validationFE
                                            isShowKey validate={[]} fieldName='holonKey' options={listAttributes ?? []} />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.condition_explanation")}</BoxLabel>
                                    <BoxContent>
                                        <FormInput
                                            placeholder={t("alarm.description_text_Example_Wallet_balance_is_less_than")}
                                            isRegexLabel={t("Holon_Template.condition_explanation")}
                                            validationFE
                                            validate={[]} fieldName='conditionsText' />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.conditional_expression")}</BoxLabel>
                                    <BoxContent>
                                        <FormInput
                                            placeholder={t("alarm.example")}
                                            isRegexLabel={t("Holon_Template.conditional_expression")}
                                            validationFE
                                            validate={[]} fieldName='conditionsLogic' />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.target_key")}</BoxLabel>
                                    <BoxContent>
                                        <FormSelect
                                            placeholder={t("alarm.please_specify_the_recipient_of_notifications_alarms")}
                                            isRegexLabel={t("Holon_Template.target_key")}
                                            validationFE
                                            isShowKey validate={[]} options={listConnections ?? []} fieldName='target_key' />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.msg")}</BoxLabel>
                                    <BoxContent>
                                        <FormTextArea
                                            placeholder={t("alarm.please_enter_the_notification_alarm_message")}
                                            isRegexLabel={t("Holon_Template.msg")}
                                            validationFE
                                            validate={[]} fieldName='messageText' />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.alarm_lv")}</BoxLabel>
                                    <BoxContent>
                                        <FormSelect
                                            isRegexLabel={t("Holon_Template.alarm_lv")}
                                            validationFE
                                            validate={[]} 
                                            options={listAlarmMaster ?? []} 
                                            fieldName='alarmLevel' 
                                        />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.user_screen_notification")}</BoxLabel>
                                    <BoxContent>
                                        <FormSelectTemplate
                                            placeholder={t("alarm.please_select_the_desired_notification_alarm_target_on_the_screen")}
                                            masterCode="READER_WRITER" 
                                            style={{
                                                width: '100%'
                                            }}
                                            isBorder={false}
                                            listHolon={alarmCodeMaster?.records}
                                            isShowButtonEdit
                                            fieldName='alarmOnScreen'
                                        />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.email_notification")}</BoxLabel>
                                    <BoxContent>
                                        <FormSelectTemplate
                                            placeholder={t("alarm.please_select_the_target_for_which_you_would_like_to_receive_notifications_alerts_via_email")}
                                            masterCode="READER_WRITER"
                                            style={{
                                                width: '100%'
                                            }}
                                            isBorder={false}
                                            listHolon={alarmCodeMaster?.records}
                                            isShowButtonEdit
                                            fieldName='alarmOnMail'
                                        />
                                    </BoxContent>
                                </BoxRow>
                                <BoxRow>
                                    <BoxLabel minWidth={140}>{t("Holon_Template.status")}</BoxLabel>
                                    <BoxContent>
                                        <FormSelect
                                            isRegexLabel={t("Holon_Template.status")}
                                            validationFE
                                            validate={[]} options={statusTemplate ?? []} fieldName='status' />
                                    </BoxContent>
                                </BoxRow>
                            </BoxBody>
                        </CustomBox>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default CreateAlarmPopup