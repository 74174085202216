import Constants from './Constants'
import _ from "lodash";
import Utils from "utils/Utils";

export const convertNumberToString = (value, delimiter = ',', isReturnZero = true) => {
    if (value || value === 0) {
        return value.toString().replace(Constants.REGEX.formatMoney, delimiter)
    }
    return isReturnZero ? '0' : ''
}

export function formatJapanesePrice(price) {
    // Check if the input is a valid number
    if (isNaN(price) || price === null) {
        return "";
    }

    // Convert the number to a string and split it into integer and decimal parts
    let parts = null;
    if(price?.indexOf(".") == -1){
        parts = price?.toFixed(2)?.toString()?.split('.');
    }else{
        parts = price?.toString()?.split('.')
    }

    // Format the integer part with commas for thousands
    const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the formatted integer and the decimal part with the yen symbol
    const formattedPrice = `${formattedInteger}.${parts[1]}`;

    return formattedPrice;
}

export const convertEnumValueToDisplay = (value, enumData = null) => {
    const enumList = Utils.convertObjectKeyToArr(enumData);
    if (value && enumData) {
        return _.find(enumList, { value: value.toString() })?.label
    }
    return ''
}

// export function formatCash (stringMoney) {
//     const delimiter = '.'
//     if (stringMoney.length !== 0) {
//         return stringMoney
//             .split('')
//             .reverse()
//             .reduce(
//                 (prev, next, index) =>
//                     (index % 3 ? next : `${next}${delimiter}`) + prev,
//             )
//     }
//     return null
// }

export const convertStringMoneyToNumber = (value) => {
    try {
        if (value) {
            return Number.parseInt(value.replace(/,/g, ''), 10)
        }
        return 0
    } catch (error) {
        return 0
    }
}

