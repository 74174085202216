import React, { useEffect, useState } from 'react'
import Button from 'shared/components/form-input/button/Button'
import WrapContentBody from '../custom-body/WrapContentBody'
import BoxContentPopup from '../custom-body/BoxContentPopup';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import useTrans from 'hooks/use-trans';
import FormData from './FormData';
import BoxBody from '../../box/BoxBody';
import { useDispatch } from 'react-redux';
import actionTransaction from 'redux/transaction/action';
import { showPopupNotification } from 'utils/Utils';
import Box from '../../box/Box';
import Constants from 'utils/Constants';

function TransactionCreatePopup(props) {
    const { showVisible, payload } = props;
    const methods = useForm({
        defaultValues: {
            currency: 'JPY'
        }
    });
    const [loadingCreateInstance, setLoadingCreateInstance] = useState(false);
    const { t } = useTrans();
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        let params = {
            ...data,
            evidence: data?.evidence?.map(x => x?.content)?.join(","),
            order: data?.order?.map(x => x?.name)?.join(","),
            wallet: data?.wallet?.map(x => x?.name)?.join(","),
            transactionType: parseInt(data?.transactionType),
            transactionBy: parseInt(data?.transactionBy),
            amount: parseInt(data?.amount),
            status: parseInt(data?.status),
            accountType: parseInt(data?.accountType),
            accountNo: data?.accountNo,
            id: 0
        }
        showPopupNotification({
            message: t("E0040"),
            typePopup: 'confirm',
            newWindow: true,
            callback: () => {
                setLoadingCreateInstance(true)
                dispatch({
                    type: actionTransaction.CREATE_TRANSACTION_DETAIL,
                    payload: params,
                    callback: {
                        success: () => {
                            showPopupNotification({
                                message: t("E0041"),
                                typePopup: 'message',
                            });
                            setLoadingCreateInstance(false);
                            showVisible(false);
                            payload?.callback();
                        },
                        failed: (msg) => {
                            showPopupNotification({
                                message: msg,
                                typePopup: 'message',
                                newWindow: true
                            });
                            setLoadingCreateInstance(false)
                        }
                    }
                })
            }
        })
    }

    function condenseFilename(initial) {
        initial = initial?.split("/")?.[initial?.split("/")?.length - 1]
        var split = initial?.split('.');
        if (split?.length > 0) {
            let filename = split?.[0];
            var extension = initial?.split(".")[1];
            if (filename.length > 105) {
                return filename.substring(0, 100) + '...' + extension;
            }
        }
        return initial;
    }

    useEffect(() => {
        if (payload?.dataClone) {
            methods.reset({
                ...payload?.dataClone,
                wallet: [],
                order: [],
                evidence: payload?.dataClone?.evidence?.split(",")?.map(x => ({ content: x, name: condenseFilename(x) })),
                transactionId: ""
            });
            dispatch({
                type: actionTransaction.GET_HOLON_INSTANCE_ORDER_BY_CODE,
                payload: {
                    code: Constants.HOLON_MASTER_CODE.ORDER,
                    name: payload?.dataClone?.order,
                },
                callback: (_order) => {
                    methods.setValue("order", [
                        {
                            id: _order?.id,
                            name: _order?.name,
                        }
                    ])
                }
            })
            dispatch({
                type: actionTransaction.GET_HOLON_INSTANCE_WALLET_BY_CODE,
                payload: {
                    code: Constants.HOLON_MASTER_CODE.WALLET,
                    name: payload?.dataClone?.wallet,
                },
                callback: (_wallet) => {
                    methods.setValue("wallet", [
                        {
                            id: _wallet?.id,
                            name: _wallet?.name,
                        }
                    ])
                }
            })
        }
    }, [payload?.dataClone])

    return (
        <BoxContentPopup minWidth={payload?.minWidth} maxWidth={payload?.maxWidth} width={payload?.width ? payload?.width : '1000px'} borderRadius='20px 0px 0px 20px'>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        style={payload?.style}
                        title={t("transaction.transaction_new")}
                        showVisible={showVisible}
                        actionSectionBottom={<Button color='gray' loading={loadingCreateInstance} type='submit' title={t('save')} />}
                    >
                        <Box>
                            <BoxBody style={{
                                borderRadius: 10
                            }}>
                                <FormData />
                            </BoxBody>
                        </Box>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default TransactionCreatePopup