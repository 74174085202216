import React from 'react';

function IconSearch({ fontSize = 10 }) {
    return (
        <svg
            width="13"
            height="15.758"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fontSize={fontSize}
        >
            <circle
                cx="4.36364"
                cy="4.36364"
                r="3.61364"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M6.54541 7.63647L8.99996 10.9092"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconSearch;
