import useTrans from 'hooks/use-trans'
import React from "react";
import AccountSettingsContainer from "shared/container/account-setting/AccountSettingsContainer";

function AccountSettingsPage(){
    const { t } = useTrans();
    document.title = t("account_settings.title");
    return (
        <AccountSettingsContainer />
    );
}


export default AccountSettingsPage;