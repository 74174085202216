import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import PrivacyPolicyAction from './action';
import factory from './factory';

function* getPrivacyPolicy() {
    yield takeEvery(
        PrivacyPolicyAction.GET_PRIVACY_POLICY,
        function* (payload) {
            try {
                const response = yield call(() =>
                    factory.getPrivacyPolicy(payload?.payload),
                );
                if (response?.data) {
                    yield put({
                        type: PrivacyPolicyAction.GET_PRIVACY_POLICY_SUCCESS,
                        payload: response?.data,
                    });
                }
            } catch (error) {
                yield put({
                    type: PrivacyPolicyAction.GET_PRIVACY_POLICY_FAILED,
                });
            }
        },
    );
}
export default function* rootSaga() {
    yield all([fork(getPrivacyPolicy)]);
}
