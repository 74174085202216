const actionReceiver  = {
    GET_RECEIVER_LIST : "GET_RECEIVER_LIST",
    GET_RECEIVER_LIST_SUCCESS : "GET_RECEIVER_LIST_SUCCESS",
    GET_RECEIVER_LIST_FAILED : "GET_RECEIVER_LIST_FAILED",
    
    GET_RECEIVER_DETAIL : "GET_RECEIVER_DETAIL",
    GET_RECEIVER_DETAIL_SUCCESS : "GET_RECEIVER_DETAIL_SUCCESS",
    GET_RECEIVER_DETAIL_FAILED : "GET_RECEIVER_DETAIL_FAILED",

    GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER : "GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER",
    GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER_SUCCESS : "GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER_SUCCESS",
    GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER_FAILED : "GET_HOLON_TEMPLATE_DETAIL_WITH_RECEIVER_FAILED",

    UPDATE_RECEIVER : "UPDATE_RECEIVER",
    UPDATE_RECEIVER_SUCCESS : "UPDATE_RECEIVER_SUCCESS",
    UPDATE_RECEIVER_FAILED : "UPDATE_RECEIVER_FAILED",

    DELETE_RECEIVER : "DELETE_RECEIVER",
    DELETE_RECEIVER_SUCCESS : "DELETE_RECEIVER_SUCCESS",
    DELETE_RECEIVER_FAILED : "DELETE_RECEIVER_FAILED",

    CREATE_RECEIVER : "CREATE_RECEIVER",
    CREATE_RECEIVER_SUCCESS : "CREATE_RECEIVER_SUCCESS",
    CREATE_RECEIVER_FAILED : "CREATE_RECEIVER_FAILED",
}
export default actionReceiver;