import ApiOperation from "adapter/ApiOperation";
import InquiryMapper from "./mapper";

const rootUrl = "/rest/api/v1/inquiry"; // TODO

const InquiryFactory = {
    getInquiryList: (params) => {
        var url = rootUrl;
        return ApiOperation.request({
            url: url,
            method: "GET",
            params: InquiryMapper.mapUrlParamToQueryApiParam(params),
        });
    },

    getInquiryDetailById: (id) => {
        var url = `${rootUrl}/${id}`;
        return ApiOperation.request({
            url: url,
            method: "GET",
        });
    },

    getSalePersonList: (payload) => {
        var url = `${rootUrl}/${payload?.key}`;
        return ApiOperation.request({
            url: url,
            method: "GET",
        });
    },

    getManagerList: (payload) => {
        var url = `${rootUrl}/${payload?.key}`;
        return ApiOperation.request({
            url: url,
            method: "GET",
        });
    },

    insertInquiry: (payload) => {
        const url = `${rootUrl}/create`;
        return ApiOperation.request({
            url: url,
            method: "POST",
            data: InquiryMapper.mapDataFormToCreateApiParam(payload),
        });
    },

    updateInquiry: (payload) => {
        var url = `${rootUrl}`;
        return ApiOperation.request({
            url: url,
            method: "PUT",
            data: InquiryMapper.mapDataFormToCreateApiParam(payload),
        });
    },

    deleteInquiry: (id) => {
        var url = `${rootUrl}/${id}`;
        return ApiOperation.request({
            url: url,
            method: "DELETE",
        });
    },


    createUserAccount: (id) => {
        var url = `${rootUrl}/create-account`;
        return ApiOperation.request({
            url: url,
            method: "POST",
            data: id,
        });
    },
};

export default InquiryFactory;
