import React from 'react'
import Box from '../../box/Box'
import BoxHeader from '../../box/BoxHeader'
import BoxTitle from '../../box/BoxTitle'
import BoxBody from '../../box/BoxBody'
import BoxRow from '../../box/BoxRow'
import BoxLabel from '../../box/BoxLabel'
import BoxContent from '../../box/BoxContent'
import useTrans from 'hooks/use-trans'
import Constants from 'utils/Constants'
import FormAttribute from 'shared/components/custom-form/FormAttribute'


function FormAttributes({ data = [], isCreate = false, attribute_key}) {
    const { t } = useTrans();
    return (
        <Box>
            <BoxHeader id={attribute_key}>
                <BoxTitle>{t("holon.basic_info")}</BoxTitle>
            </BoxHeader>
            <BoxBody id={attribute_key}>
                {
                    data?.map((x, index) => {
                        return <BoxRow key={index}>
                            <BoxLabel>
                                {x?.label}
                                {x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                            </BoxLabel>
                            <BoxContent>
                                <FormAttribute dataAttribute={data} isCreate={isCreate} value={x} index={index} />
                            </BoxContent>
                        </BoxRow>
                    })
                }
            </BoxBody>
        </Box>
    )
}

export default FormAttributes