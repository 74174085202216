import Constants from "utils/Constants";
import { formatDate } from "utils/DateTime";

const InquiryMapper = {
    mapUrlParamToQueryApiParam: (urlParams) => {
        const queryApiParam = {};
        if (urlParams[Constants.QueryParams.SEARCH.VALUE]) {
            queryApiParam["keyword"] =
                urlParams[Constants.QueryParams.SEARCH.VALUE];
        }
        if (urlParams[Constants.QueryParams.CREATED_DATE_FROM.VALUE]) {
            queryApiParam["fromDate"] =
                urlParams[Constants.QueryParams.CREATED_DATE_FROM.VALUE];
        }
        if (urlParams[Constants.QueryParams.CREATED_DATE_TO.VALUE]) {
            queryApiParam["toDate"] =
                urlParams[Constants.QueryParams.CREATED_DATE_TO.VALUE];
        }
        if (urlParams[Constants.QueryParams.STATUS.VALUE]) {
            queryApiParam["status"] =
                urlParams[Constants.QueryParams.STATUS.VALUE];
        }
        if (urlParams[Constants.QueryParams.OFFSET.VALUE]) {
            queryApiParam["currentPage"] =
                urlParams[Constants.QueryParams.OFFSET.VALUE];
        }
        if (urlParams[Constants.QueryParams.LIMIT.VALUE]) {
            queryApiParam["pageSize"] =
                urlParams[Constants.QueryParams.LIMIT.VALUE];
        } else {
            queryApiParam["pageSize"] = Constants.PAGINATION.LIMIT;
        }
        return queryApiParam;
    },

    mapSearchInquiryResponseToView: (apiResp) => {
        try {
            const dataView = {};
            dataView["limit"] = apiResp["limit"] ?? 0;
            dataView["page"] = apiResp["page"] ?? 1;
            dataView["total"] = apiResp["total"] ?? 0;
            dataView["totalPage"] = apiResp["totalPage"] ?? 1;

            dataView["records"] = apiResp["records"].map((obj) => {
                return {
                    id: obj.id,
                    inquiryCode: obj.inquiryCode,
                    inquiryDate: formatDate(obj.createdAt),
                    createdBy: obj.fullName,
                    phone: obj.phone,
                    picSale: (obj?.saleLastName || '') + (obj?.saleFirstName ? (' ' + obj?.saleFirstName) : ''),
                    companyName: obj.companyName,
                    inquiryStatus: obj.status,
                };
            });
            return dataView;
        } catch (error) {
            return {
                records: [],
                limit: 0,
                page: 1,
                total: 0,
                totalPage: 1,
            };
        }
    },

    mapDataFormToCreateApiParam: (dataForm) => {
        const insertInfo = {};
        insertInfo["id"] = dataForm?.id;
        insertInfo["type"] = dataForm?.userType;
        insertInfo["companyName"] = dataForm?.companyName;
        insertInfo["departmentName"] = dataForm?.departmentName;
        insertInfo["fullName"] = dataForm?.fullName;
        insertInfo["fullNameFuri"] = dataForm?.fullNameFuri;
        //
        insertInfo["phone"] = dataForm?.phone;
        insertInfo["email"] = dataForm?.email;
        insertInfo["title"] = dataForm?.title;
        insertInfo["content"] = dataForm?.content;
        insertInfo["status"] = dataForm?.status;
        insertInfo["needEstimate"] = dataForm?.needEstimate;
        insertInfo["estimateCode"] = dataForm?.estimateCode ?? '';
        insertInfo["hasAccount"] = dataForm.isCreateAccount ?? 0;
        insertInfo["managerId"] = dataForm?.chosenPICManager;
        insertInfo["saleId"] = dataForm?.chosenPICSale;
        insertInfo["remark"] = dataForm?.remark;
        return insertInfo;
    },

    mapDataInquiryDetailApiToView: (apiResp) => {
        const inquiryDetail = {};

        if (!apiResp.id) {
            return inquiryDetail;
        }
        //
        inquiryDetail["id"] = apiResp?.id;
        inquiryDetail["inquiryCode"] = apiResp?.inquiryCode;
        inquiryDetail["inquiryStatus"] = apiResp?.displayStatus;
        inquiryDetail["status"] = apiResp?.status;
        inquiryDetail["typeName"] = apiResp?.displayType;
        inquiryDetail["type"] = apiResp?.type;
        inquiryDetail["companyName"] = apiResp?.companyName;
        //
        inquiryDetail["companyName"] = apiResp?.companyName;
        inquiryDetail["departmentName"] = apiResp?.departmentName;
        inquiryDetail["fullName"] = apiResp?.fullName;
        inquiryDetail["fullNameFuri"] = apiResp?.fullNameFuri;
        inquiryDetail["phone"] = apiResp?.phone;
        inquiryDetail["email"] = apiResp?.email;
        inquiryDetail["topic"] = apiResp?.title;
        inquiryDetail["content"] = apiResp?.content;
        inquiryDetail["saleName"] = (apiResp?.lastNameSale || '') + (apiResp?.firstNameSale ? (' ' + apiResp?.firstNameSale) : '');
        inquiryDetail["saleId"] = apiResp?.saleId;
        inquiryDetail["managerName"] = (apiResp?.lastNameManager || '') + (apiResp?.firstNameManager ? (' ' + apiResp?.firstNameManager) : '');
        inquiryDetail["managerId"] = apiResp?.managerId;
        inquiryDetail["needEstimate"] = apiResp?.needEstimate ?? "0";
        inquiryDetail["estimateCode"] = apiResp?.estimateCode ?? '';
        inquiryDetail["hasAccount"] = parseInt(apiResp.hasAccount ?? 0);
        inquiryDetail["remark"] = apiResp?.remark ?? "";
        inquiryDetail["createdBy"] = apiResp?.createdBy;
        inquiryDetail["updatedBy"] = apiResp?.updatedBy;
        inquiryDetail["nameCreateBy"] = (apiResp?.lastNameCreatedBy || '') + (apiResp?.firstNameCreatedBy ? (' ' + apiResp?.firstNameCreatedBy) : '');
        inquiryDetail["nameUpdatedBy"] = (apiResp?.lastNameUpdatedBy || '') + (apiResp?.firstNameUpdatedBy ? (' ' + apiResp?.firstNameUpdatedBy) : '');
        //
        return inquiryDetail;
    },

    mapDataUserListApiToOptions: (userList) => {
        if (!userList || !Array.isArray(userList) || userList.length === 0) {
            return [];
        }

        return userList.map(obj => {
            return {
                value: parseInt(obj?.id),
                key: parseInt(obj?.id),
                label: obj?.last_name + " " + obj?.first_name,
            }
        })
    },

    handleOption: (option) => {
        const emptyObject = {
            value: null,
            key: null,
            label: "",
        };
        option = InquiryMapper.mapDataUserListApiToOptions(option)
        option.unshift(emptyObject)
        return option
    }
};

export default InquiryMapper;
