const { default: PolicySettingAction } = require('./action');

const initialState = {
    data: {},
    loadingUpdatePolicy: false,
    loadingGetPolicy: false
};
const myReducer = (state = initialState, action) => {
    switch (action?.type) {
        case PolicySettingAction.GET_POLICY_SETTING:
            return {
                ...state,
                loadingGetPolicy: true
            };
        case PolicySettingAction.GET_POLICY_SETTING_SUCCESS:
            return {
                ...state,
                data: action?.payload,
                loadingGetPolicy: false
            };

        case PolicySettingAction.UPDATE_POLICY_SETTING:
            return {
                ...state,
                loadingUpdatePolicy: true
            };

        case PolicySettingAction.UPDATE_POLICY_SETTING_SUCCESS:
            return {
                ...state,
                loadingUpdatePolicy: false
            };
        case PolicySettingAction.UPDATE_POLICY_SETTING_FAILED:
            return {
                ...state,
                loadingUpdatePolicy: false
            };
        default:
            return {
                ...state,
            };
    }
};

export default myReducer;
