import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import EventRegister, { 
    EVENT_SHOW_POPUP, 
    // EVENT_SHOW_POPUP_CONFIRM_DELETE, 
    EVENT_SHOW_POPUP_CONFIRM_UPDATE,
    EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE 
} from 'utils/EventRegister';
import useTrans from "hooks/use-trans";
import actionOrder from "redux/order/action";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import Box from "shared/components/form-input/box/Box";
import Button from 'shared/components/form-input/button/Button';
import classes from "./TrackingInfoRegistrationPopup.module.scss";
import TrackingInfoRegistrationForm from "./TrackingInfoRegistrationForm";
import { showPopupNotification } from "utils/Utils";
import moment from "moment";

const TrackingInfoRegistrationPopup = (props) => {
    const { payload, showVisible } = props;
    const dispatch = useDispatch();
    const { trackingInfo, loading: { loadingUpdate } } = useSelector(state => state.order)
    const methods = useForm({
        defaultValues: {
            holonId: '',
        }
    });
    const control = methods.control;
    const {
        append: appendHistory,
        fields: fieldsHistory,
        remove: removeHistory,
    } = useFieldArray({
        control, name: "histories"
    })

    useEffect(() => {
        if (payload?.id) {
            methods.setValue('holonId', payload?.id)
            dispatch({
                type: actionOrder.GET_TRACKING_INFORMATION_HISTORY,
                payload: payload?.id
            })
        }
    }, [payload?.id]);

    useEffect(() => {
        if (trackingInfo?.id) {
            methods.reset(trackingInfo)    
        }

        return () => {
            methods.reset({})
            dispatch({
                type: actionOrder.GET_TRACKING_INFORMATION_HISTORY_SUCCESS,
                payload: {},
            })
        }
    }, [trackingInfo?.id])

    const onSubmit = (data) => {
        const histories = data?.histories?.map(history => {
            let timestamp = moment.utc(new Date(history?.timestamp)).format();
            return {
                ...history,
                timestamp: timestamp,
            }
        })
        const params = {
            ...data,
            histories: histories
        }
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                // title: t("Holon_Template.confirm_update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: actionOrder.UPDATE_TRACKING_INFORMATION_HISTORY,
                        payload: params,
                        callback: {
                            success: () => {
                                // payload?.callback();
                                // showVisible(false);
                                showPopupNotification({
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    typePopup: 'message',
                                    message: t("E0043"),
                                    newWindow: true,
                                })
                                dispatch({
                                    type: actionOrder.GET_TRACKING_INFORMATION_HISTORY,
                                    payload: payload?.id
                                })
                            },
                            failed: () => {
                                showPopupNotification({
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    typePopup: 'message',
                                    message: t('E0031'),
                                    newWindow: true,
                                })
                                // dispatch({
                                //     type: actionOrder.GET_TRACKING_INFORMATION_HISTORY,
                                //     payload: payload?.id
                                // })
                            }
                        }
                    })
                }
            }
        })
    }

    const { t } = useTrans()
    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Box className={classes["freight-calculation-management"]}>
                    <TrackingInfoRegistrationForm appendHistory={appendHistory} fieldsHistory={fieldsHistory} removeHistory={removeHistory} />
                    <div className={classes["btn-form"]}>
                        <Button type="submit" loading={loadingUpdate}>{t('Holon_Template.save')}</Button>
                        <Button type="button" onClick={showVisible} className={classes['btn-cancel']}>{t('Holon_Template.cancel')}</Button>
                    </div>
                </Box>
            </form>
        </CustomFormProvider>
    );
}

export default TrackingInfoRegistrationPopup;
