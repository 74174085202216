import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import ContractContainer from 'shared/container/contract/ContractContainer';

function ContractPage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t('contract.title');
    }, []);
    return (
        <ContractContainer />
    )
}

export default ContractPage