import React from 'react';

function CustomHeaderTable(props) {
    const { Component, headerColumn } = props;

    return (
        <table style={{ width: '100%' }}>
            <Component headerColumn={headerColumn} />
        </table>
    );
}

export default CustomHeaderTable;
