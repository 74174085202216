import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import UploadAvatar from '../form-input/upload-avatar/UploadAvatar';

function FormAvatar(props) {
    const {
        fieldName,
        validate = [],
        placeholder,
        disabled = false,
        height = '130px',
        width = '100%'
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div style={{
            width: width ,height: height
        }} className="Input">
            <div className="w-100 h-100">
                <Controller
                    control={control}
                    name={fieldName}
                    rules={{
                        validate: Validator.genValidate(validate, fieldName),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {

                        return (
                            <UploadAvatar
                                refInput={ref}
                                name={fieldName}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                onBlur={onBlur}
                                value={value}
                                placeholder={placeholder}
                                disabled={disabled}
                                errors={errors}
                                maximumUpload={100}
                                maximumMb={100}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
}
FormAvatar.defaultProps = {
    placeholder: '',
    fieldName: 'fieldName',
};
FormAvatar.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    iconSearch: PropTypes.element,
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    isSearch: PropTypes.bool,
};
export default FormAvatar;
