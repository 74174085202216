import React, { useEffect, useState } from 'react'
import classes from './UserAccountList.module.scss'
import Actions from 'redux/holon/action';
import Constants from 'utils/Constants';
import useTrans from 'hooks/use-trans';
import useRouter from 'hooks/use-router';
import HolonInstance from 'shared/components/holon-instance/HolonInstance'
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_COMPANY_INFORMATION_SETTINGS } from 'utils/EventRegister';
import { useSelector, useDispatch } from 'react-redux';

const UserAccountListContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTrans()
    const router = useRouter();
    const { holonMaster } = useSelector(state => state.holon);
    const [isCorporation, setIsCorporation] = useState(false);
    const [companyTemplate, setCompanyTemplate] = useState();
    const [userTemplate, setUserTemplate] = useState();
    const [personalCustomerTemplate, setPersonalCustomerTemplate] = useState();
    const [activeTab, setActiveTab] = useState(Constants.CUSTOMER.PERSONAL.VALUE);

    const handleTabClick = (value) => {
        router.replace({
            params: {
                // ...router.getAll(),
                [Constants.QueryParams.TAB.VALUE]: value
            }
        })
    }

    const handlePopupCompanyAccountSetting = (data) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_COMPANY_INFORMATION_SETTINGS,
            open: true,
            payload: {
                headerSize: Constants.SIZE.SMALL,
                title: t("Company_Setting.title"),
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                holonData: data
            }
        })
    }

    const tabs = [
        {
            id: 0,
            value: Constants.CUSTOMER.PERSONAL.VALUE,
            title: t('User_Account_List.individual_customer'),
            content: userTemplate && personalCustomerTemplate ? <HolonInstance width='100%' isShowTitle={false} isReverse={true} holonTemplateId={personalCustomerTemplate} subHolonTemplateId={userTemplate} /> : <></>
        },
        {
            id: 1,
            value: Constants.CUSTOMER.BUSINESS.VALUE,
            title: t('User_Account_List.corporate_customer'),
            content: companyTemplate && holonMaster?.data?.[0] ? <HolonInstance width='100%' isShowTitle={false} holonTemplateId={companyTemplate} callbackDetailFunction={handlePopupCompanyAccountSetting} excludeIds={holonMaster?.data?.[0]} /> : <></>
        },
    ];

    useEffect(() => {
        const value = router.get(Constants.QueryParams.TAB.VALUE)
        if (value) {
            const tab = tabs.find(tab => tab.value == value)
            setActiveTab(tab.id)
        } else {
            setActiveTab(0)
        }
    }, [router.get(Constants.QueryParams.TAB.VALUE)])

    useEffect(() => {
        dispatch({
            type: Actions.GET_HOLON_MASTER_BY_CODE,
            code: Constants.HOLON_MASTER_CODE.BL_INSTANCE,
        })

        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.COMPANY,
            callback: (data) => {
                data?.length > 0 && setCompanyTemplate(data[0].id);
            }
        })

        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.USER_TEMPLATE,
            callback: (data) => {
                data?.length > 0 && setUserTemplate(data[0].id);
            }
        })

        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.PERSONAL_CUSTOMER,
            callback: (data) => {
                data?.length > 0 && setPersonalCustomerTemplate(data[0].id);
            }
        })
    }, []);

    // const handleTabClick = (value) => {
    //     router.replace({
    //         params: {}
    //     })
    //     setIsCorporation(value);
    // };

    // const handlePopupCompanyAccountSetting = (data) => {
    //     EventRegister.emit(EVENT_SHOW_POPUP, {
    //         type: EVENT_SHOW_POPUP_COMPANY_INFORMATION_SETTINGS,
    //         open: true,
    //         payload: {
    //             headerSize: Constants.SIZE.SMALL,
    //             title: t("Company_Setting.title"),
    //             customStyle: {
    //                 padding: 0,
    //                 borderTop: 0
    //             },
    //             holonData: data
    //         }
    //     })
    // }

    return (
        <div className={`${classes['UserAccountListContainer']}`}>
            <div>
                <div className={classes['card-content']}>
                    <div className={classes['card-heading']}>
                        <p>{t("User_Account_List.header_content")}</p>
                    </div>
                </div>
                <div className={classes['Custom-Header']}>
                    {/* <div onClick={() => handleTabClick(false)} className={`${classes['card-heading']} ${!isCorporation ? classes['active_card'] : classes['normal_card']}`}>
                        <p>{t('User_Account_List.individual_customer')}</p>
                        <div className={`${classes['bottom_line']} ${!isCorporation ? classes['active_bottom_line'] : classes['normal_bottom_line']}`}></div>
                    </div>
                    <div onClick={() => handleTabClick(true)} className={`${classes['card-heading']} ${isCorporation ? classes['active_card'] : classes['normal_card']}`}>
                        <p>{t('User_Account_List.corporate_customer')}</p>
                        <div className={`${classes['bottom_line']} ${isCorporation ? classes['active_bottom_line'] : classes['normal_bottom_line']}`}></div>
                    </div> */}
                    {tabs?.map((tab, index) => (
                        <div key={index} onClick={() => handleTabClick(tab?.value)} className={`${classes['card-heading']} ${activeTab == tab?.id ? classes['active_card'] : classes['normal_card']}`}>
                            <p>{tab?.title}</p>
                            <div className={`${classes['bottom_line']} ${activeTab == tab?.id ? classes['active_bottom_line'] : classes['normal_bottom_line']}`}></div>
                        </div>
                    ))}
                </div>
                <div className={classes['holon-instance']} >
                    {/* {isCorporation ? (companyTemplate && <HolonInstance width='100%' isShowTitle={false} holonTemplateId={companyTemplate} callbackDetailFunction={handlePopupCompanyAccountSetting} />)
                        : (userTemplate && personalCustomerTemplate && <HolonInstance width='100%' isShowTitle={false} isReverse={true} holonTemplateId={personalCustomerTemplate} subHolonTemplateId={userTemplate} />)} */}
                    {tabs[activeTab]?.content}
                </div>
            </div>
        </div>
    )
}

export default UserAccountListContainer