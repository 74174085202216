const actionStocks = {
    GET_LIST_STOCKS : "GET_LIST_STOCKS",
    GET_LIST_STOCKS_SUCCESS : "GET_LIST_STOCKS_SUCCESS",
    GET_LIST_STOCKS_FAILED : "GET_LIST_STOCKS_FAILED",

    DELETE_STOCKS : "DELETE_STOCKS",
    GET_DETAIL_STOCKS_POPUP : "GET_DETAIL_STOCKS_POPUP",
    DELETE_STOCKS_SUCCESS : "DELETE_STOCKS_SUCCESS",
    DELETE_STOCKS_FAILED : "DELETE_STOCKS_FAILED",

    UPDATE_STOCKS : "UPDATE_STOCKS",
    UPDATE_STOCKS_SUCCESS : "UPDATE_STOCKS_SUCCESS",
    UPDATE_STOCKS_FAILED : "UPDATE_STOCKS_FAILED",

    CREATE_STOCKS : "CREATE_STOCKS",
    CREATE_STOCKS_SUCCESS : "CREATE_STOCKS_SUCCESS",
    CREATE_STOCKS_FAILED : "CREATE_STOCKS_FAILED",

    GET_DETAIL_STOCKS : "GET_DETAIL_STOCKS",
    GET_DETAIL_STOCKS_SUCCESS : "GET_DETAIL_STOCKS_SUCCESS",
    GET_DETAIL_STOCKS_FAILED : "GET_DETAIL_STOCKS_FAILED",

    UPDATE_INVENTORY : "UPDATE_INVENTORY",
}

export default actionStocks;