import actionHolonTemplate from "./action";

const initialState = {
    holonTemplate: {
        limit: 0,
        page: 1,
        records: [],
        total: 0,
        totalPage: 1
    },
    holonTemplateSearch: {
        limit: 0,
        page: 1,
        records: [],
        total: 0,
        totalPage: 1
    },
    loading: {
        loadingGetTemplate: false,
        loadingUpdateTemplate: false,
        loadingDeleteTemplate: false,
        loadingCreateTemplate: false,
        loadingDetailGetTemplate: false,
        loadingGetTemplateSearch: false,
    },
    holonTemplateDetail: {},
    categoryHolonTemplate: [],
    categories: {},
    actionType: {},
    holonTemplateMaster: []
}

const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetTemplate: true
                }
            }
        case actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetTemplate: false,
                },
                holonTemplate: {
                    limit: 0,
                    page: 1,
                    records: [],
                    total: 0,
                    totalPage: 1
                }
            }
        case actionHolonTemplate.GET_HOLON_TEMPLATE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetTemplate: false,
                },
                holonTemplate: action?.payload
            }
        case actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetTemplateSearch: true,
                },
            }
        case actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetTemplateSearch: false,
                },
                holonTemplateSearch: action?.payload
            }
        case actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDetailGetTemplate: false,
                },
                holonTemplateDetail: action?.payload
            }
        case actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDetailGetTemplate: true,
                },
                holonTemplateDetail: {}
            }
        case actionHolonTemplate.GET_DETAIL_HOLON_TEMPLATE_MANAGEMENT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDetailGetTemplate: false,
                },
            }
        case actionHolonTemplate.GET_MASTER_CATEGORY_HOLON_TEMPLATE_SUCCESS:
            return {
                ...state,
                categoryHolonTemplate: action.payload
            }
        case actionHolonTemplate.CREATE_HOLON_TEMPLATE_MANAGEMENT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateTemplate: true
                }
            }
        case actionHolonTemplate.CREATE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateTemplate: false
                }
            }
        case actionHolonTemplate.CREATE_HOLON_TEMPLATE_MANAGEMENT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateTemplate: false
                }
            }
        case actionHolonTemplate.UPDATE_HOLON_TEMPLATE_MANAGEMENT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateTemplate: true
                }
            }
        case actionHolonTemplate.UPDATE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateTemplate: false
                }
            }
        case actionHolonTemplate.UPDATE_HOLON_TEMPLATE_MANAGEMENT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateTemplate: false
                }
            }
        case actionHolonTemplate.DELETE_HOLON_TEMPLATE_MANAGEMENT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteTemplate: true
                }
            }
        case actionHolonTemplate.DELETE_HOLON_TEMPLATE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteTemplate: false
                }
            }
        case actionHolonTemplate.DELETE_HOLON_TEMPLATE_MANAGEMENT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteTemplate: false
                }
            }
        case actionHolonTemplate.GET_MASTER_HOLON_TEMPLATE_SUCCESS:
            return {
                ...state,
                categories: action.payload?.categories,
                actionType: action.payload?.actionType,
            }
        case actionHolonTemplate.GET_ALL_HOLON_TEMPLATE_SUCCESS:
            return {
                ...state,
                holonTemplateMaster: action.payload?.records
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;