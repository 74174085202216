import useTrans from 'hooks/use-trans';
import React from 'react'
import RequestPaymentContainer from 'shared/container/request-payment/RequestPaymentContainer';

function RequestPaymentPage() {
    const { trans } = useTrans();
    document.title = trans("request_payment.customer_request_payment")
    return (
        <RequestPaymentContainer />
    )
}

export default RequestPaymentPage