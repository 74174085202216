import { actionMessage } from "./action";

const initialState = {
    listChannel: {},
    messageChannel: [],
    listUser: [],
    listMessage: [],
    loadingGetMsg: false
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionMessage.REQUEST_SYNC_CHANNEL_SUCCESS:
            return {
                ...state,
                listChannel: action.payload
            }
        case actionMessage.LOAD_MORE_CHANNEL_SUCCESS:
            let listChannelLoadMore = {
                ...action.payload,
                limit: action.payload?.records?.length + state.listChannel?.limit,
                page: action.payload?.page,
                records: [
                    ...state.listChannel?.records,
                    ...action.payload?.records
                ]
            }
            return {
                ...state,
                listChannel: listChannelLoadMore
            }
        case actionMessage.REQUEST_GET_MESSAGE:
            return {
                ...state,
                messageChannel: action.payload,
            }
        case actionMessage.REQUEST_GET_FIRST_MESSAGE:
            return {
                ...state,
                messageChannel: action.payload,
                loadingGetMsg: true
            }
        case actionMessage.GET_LIST_USER_INSTANCE_SUCCESS:
            return {
                ...state,
                listUser: action.payload
            }
        case actionMessage.REQUEST_GET_MESSAGE_SUCCESS:
            return {
                ...state,
                listMessage: action.payload
            }
        case actionMessage.REQUEST_GET_FIRST_MESSAGE_SUCCESS:
            return {
                ...state,
                listMessage: action.payload,
                loadingGetMsg: false
            }
        case actionMessage.RESET_GET_MESSAGE_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case actionMessage.REQUEST_GET_MESSAGE_FAILED:
            return {
                ...state,
                listMessage: []
            }
        case actionMessage.REQUEST_GET_FIRST_MESSAGE_FAILED:
            return {
                ...state,
                listMessage: [],
                loadingGetMsg: false
            }
        case actionMessage.REQUEST_SYNC_MESSAGE_SUCCESS:
            let currentMessage = [...state.listMessage];
            if (action?.payload?.length > 0) {
                action.payload?.map(x => {
                    let checkExist = currentMessage.find(o => o.messageId == x.messageId)
                    if (!checkExist) {
                        currentMessage.unshift(x)
                    }
                })
            }
            return {
                ...state,
                listMessage: currentMessage
            }
        case actionMessage.REQUEST_GET_SCROLL_MESSAGE_SUCCESS:
            let currentFirstMessage = [...state.listMessage];
            if (action?.payload?.length > 0) {
                action.payload?.map(x => {
                    let checkExist = currentFirstMessage.find(o => o.messageId == x.messageId)
                    if (!checkExist) {
                        currentFirstMessage.push(x)
                    }
                })
            }
            return {
                ...state,
                listMessage: currentFirstMessage
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;