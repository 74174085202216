import React, { useEffect, useState } from 'react'
import BoxRow from '../../box/BoxRow';
import BoxContent from '../../box/BoxContent';
import CommonText from '../../text/CommonText';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import useTrans from 'hooks/use-trans';
import moment from 'moment';
import CustomPagination from '../../custom-pagination/CustomPagination';

function History(props) {
    const { handleShowHistory, history } = props;
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const { t } = useTrans();

    useEffect(() => {
        if (history?.length > 0) {
            setList(history?.slice(0, 10));
        }
    }, [history])

    const onChange = (page) => {
        setPage(page);
        let indexOfFirstItem = (page - 1) * 10;
        let indexOfLastItem = page * 10;
        setList(history?.slice(indexOfFirstItem, indexOfLastItem))
    }

    return (
        <>
            {
                list?.map((x, index) => {
                    return x?._index > 0 && <BoxRow key={index}>
                        <BoxContent style={{ minWidth: 150, width: 150, alignSelf: "flex-start", color: '#0E525E', fontWeight: 500 }}>{x?.created && moment(x?.created).format("DD/MM/YYYY HH:mm:ss")}</BoxContent>
                        <BoxContent style={{ minWidth: 200, alignSelf: "flex-start" }}>{x?.name}</BoxContent>
                        <BoxContent style={{ minWidth: 200, alignSelf: "flex-start" }}>
                            <CommonText>{x?.userName}</CommonText>
                        </BoxContent>
                        <BoxContent style={{
                            minWidth: "calc(100% - 150px - 200px - 200px)"
                        }}>
                            <ButtonLink onClick={() => handleShowHistory(x?._index, history)}>
                                {t("Holon_Template.detail")}
                            </ButtonLink>
                        </BoxContent>
                    </BoxRow>
                })
            }

            <CustomPagination page={page} onChange={onChange} total={history?.length} />
        </>
    )
}

export default History