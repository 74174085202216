import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import IconButton from '@mui/material/IconButton';
import vector519 from 'assets/img/vector-519.png';
import vector520 from 'assets/img/vector-520.png';
import nextImg from 'assets/img/next_double_arrow.png'
import prevImg from 'assets/img/prev_double_arrow.png'
import useTrans from "hooks/use-trans";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import Constants from "utils/Constants";
import moment from "moment";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/MM/yyyy');
const FormDatePicker = (props) => {
    const { trans } = useTrans();
    const {name, validate = [], register, defaultValue = '', maxDate = null, field = ''} = props

    const [startDate, setStartDate] = useState();
    const { formState: { errors }, control } = useFormContext();
    
    const onDateChange = (dates, callback) => {
        callback(dates);
        setStartDate(dates);
    }

    useEffect(() => {
        if (defaultValue) {
            try {
                let newDate = moment(defaultValue, "DD/MM/yyyy").toDate();
                setStartDate(newDate);
            } catch (error) {
                
            }
        }
    }, [defaultValue])

    return (
        <Controller
            control={control}
            name={name}
            rules={{ validate: Validator.genValidate(validate, name) }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return <div className="form-wrap">
                    <div className={`${errors[name] ? 'error' : ''}`}>
                        <DatePicker
                            {...register(name, {value: startDate})}
                            _props={{ inputRef: ref }}
                            className="w-100"
                            selectsRange={false}
                            selected={startDate}
                            onChange={value => onDateChange(value, onChange)}
                            onBlur={value => onBlur(value)}
                            locale={localStorage.getItem("i18nextLng") == Constants.T001.EN.CODE.toLowerCase() ? 'en' :
                                localStorage.getItem("i18nextLng") == Constants.T001.CN.CODE.toLowerCase() ? 'zh-CN' : 'ja'}
                            shouldCloseOnSelect={true}
                            value={startDate}
                            dayClassName={(date) => `${date.toLocaleDateString('en-US')}`}
                            maxDate={maxDate}
                            showMonthDropdown
                            autoComplete="off"
                            dateFormat={"dd/MM/yyyy"}
                            customInput={<MaskedInput
                                pipe={autoCorrectedDatePipe}
                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                keepCharPositions= {false}
                                guide = {false}
                            />}
                            renderCustomHeader={({
                                date,
                                decreaseMonth,
                                increaseMonth,
                                decreaseYear,
                                increaseYear,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                            }) => (
                                <React.Fragment>
                                    <div className="custom-header">
                                        <IconButton
                                            className="btn-arrow-rounded-icon"
                                            children={<img src={prevImg} className="vector-icon" alt="vector icon" />}
                                            onClick={decreaseYear}
                                            disabled={prevMonthButtonDisabled}
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        />
                                        <IconButton
                                            className="btn-arrow-rounded-icon"
                                            children={<img src={vector519} className="vector-icon" alt="vector icon" />}
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        />
                                        <button type="button">{`${date.getFullYear()}年 ${date.getMonth() + 1}月`}</button>
                                        <IconButton className="btn-arrow-rounded-icon"
                                            children={<img src={vector520} className="vector-icon" alt="vector icon" />}
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        />
                                        <IconButton
                                            className="btn-arrow-rounded-icon"
                                            children={<img src={nextImg} className="vector-icon" alt="vector icon" />}
                                            onClick={increaseYear}
                                            disabled={prevMonthButtonDisabled}
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        />
                                    </div>
                                    
                                </React.Fragment>
                            )}
                        />
                    </div>
                    { errors[name] && <span className='error-msg'>{Validator.renderMessage(trans(errors[name]?.message), {0: field?.messageKey ? trans(field?.labelKey) : trans(name)})}</span> }
                </div>
            }} />
    );
};

export default FormDatePicker;
