import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import classes from './CustomTooltip.module.scss';

function ToBeStyledTooltip({ className, ...props }) {
    return <Tooltip {...props} classes={{ tooltip: className }} />;
}
const StyledTooltip = styled(ToBeStyledTooltip)(() => ({
    lineHeight: '15px',
    textTransform: 'capitalize',
}));

function CustomTooltip(props) {
    const {
        text = '',
        textTooltip = '',
        placement = 'top',
        style,
        styleTooltip
    } = props;
    return (
        <div style={{ ...styleTooltip }} className={classes.tooltip}>
            <StyledTooltip
                placement={placement}
                title={textTooltip?.toLowerCase()}
                arrow
            >
                {props?.children ? props?.children : <p style={style} className={classes.text}>{text}</p>}
            </StyledTooltip>
        </div>
    );
}
CustomTooltip.propTypes = {
    text: PropTypes.string,
    textTooltip: PropTypes.string,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    styleTooltip: PropTypes.any
};
export default CustomTooltip;
