import actionTransaction from "./action";

const initialState = {
    transaction: {
        total: 0,
        records: []
    },
    loadingList: false,
    loadingDetail: false,
    transactionDetail: {}
}

const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTransaction.GET_LIST_TRANSACTION_FAILED:
            return {
                ...state,
                loadingList: false,
                transaction: {
                    total: 0,
                    records: []
                }
            }
        case actionTransaction.GET_LIST_TRANSACTION:
            return {
                ...state,
                loadingList: true
            }
        case actionTransaction.GET_LIST_TRANSACTION_SUCCESS:
            return {
                ...state,
                transaction: action.payload,
                loadingList: false
            }
        case actionTransaction.GET_TRANSACTION_DETAIL:
            return {
                ...state,
                loadingDetail: true
            }
        case actionTransaction.GET_TRANSACTION_DETAIL_FAILED:
            return {
                ...state,
                loadingDetail: false
            }
        case actionTransaction.GET_TRANSACTION_DETAIL_SUCCESS:
            return {
                ...state,
                loadingDetail: false,
                transactionDetail: action.payload
            }
        default:
            return { ...state };
    }
}

export default myReducer;