const { default: actionDashboard } = require("./action");

const initialState = {
    wallets: [],
    orders: [],
    notifications: [],
    loading: false,
    error: null,
    numberOfUser: 0,
    numberOfOrderedUser: 0,
    walletInfo: {},
    inquiryCount: 0
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionDashboard.GET_NOTIFICATIONS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetHexService: true
                }
            }
        case actionDashboard.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetHexService: false
                },
                notifications: action.payload
            }
        case actionDashboard.GET_ORDERS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetHexService: true
                }
            }
        case actionDashboard.GET_ORDERS_SUCCESS:
            var total = {
                serviceName: "TOTAL_ORDER",
                orderCountItems: []
            }

            action?.payload?.map((order, index) => {
                order?.orderCountItems?.map(orderItem => {
                    let idx = total?.orderCountItems?.findIndex(_i => _i?.key == orderItem?.key);
                    if (idx == -1) { // Nếu order item chưa có trong total
                        let count = orderItem?.display ? orderItem?.count : 0;
                        total?.orderCountItems.push({ ...orderItem, count: count });
                    } else {
                        if (orderItem?.display) {
                            let count = orderItem?.count;
                            total.orderCountItems[idx].count += count;
                        }
                    }
                });
            });

            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetHexService: false
                },
                orders: [total, ...action.payload]
            }
        case actionDashboard.GET_WALLETS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetHexService: true
                }
            }
        case actionDashboard.GET_WALLETS_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetHexService: false
                },
                wallets: action.payload
            }
        case actionDashboard.GET_NUMBER_OF_USER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetNumberOfUser: true
                }
            }
        case actionDashboard.GET_NUMBER_OF_USER_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetNumberOfUser: false
                },
                numberOfUser: action.payload
            }
        case actionDashboard.GET_NUMBER_OF_USER_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetNumberOfUser: false
                },
            }
        case actionDashboard.GET_NUMBER_OF_ORDERED_USER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetNumberOfOrderedUser: true
                }
            }
        case actionDashboard.GET_NUMBER_OF_ORDERED_USER_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetNumberOfOrderedUser: false
                },
                numberOfOrderedUser: action.payload
            }
        case actionDashboard.GET_NUMBER_OF_ORDERED_USER_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetNumberOfOrderedUser: false
                },
            }
        case actionDashboard.GET_DASHBOARD_WALLET_INFO:
            return {
                ...state,
                loading: {
                    ...state.loading,
                }
            }
        case actionDashboard.GET_DASHBOARD_WALLET_INFO_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                },
                walletInfo: action.payload
            }
        case actionDashboard.GET_DASHBOARD_WALLET_INFO_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                },
                walletInfo: {}
            }

        case actionDashboard.GET_INQUIRY_COUNT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                }
            }
        case actionDashboard.GET_INQUIRY_COUNT_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                },
                inquiryCount: action.payload
            }
        case actionDashboard.GET_INQUIRY_COUNT_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                }
            }
        
               
        default:
            return {
                ...state
            }
    }
};

export default dashboardReducer;
