import React from 'react'

function IconPlusHippieBlue() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none">
            <circle
                cx="9"
                cy="9"
                r="9"
                fill="#58A5B2"
            />
            <path
                d="M5 9H13"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M9 5L9 13"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default IconPlusHippieBlue