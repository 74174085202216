import React from 'react'
import classes from './ColorPicker.module.scss';

function ColorPicker(props) {
    const {
        refField,
        fieldName,
        onChange,
        onBlur,
        value,
        placeholder,
        disabled
    } = props;
    return (
        <div className={classes['color-picker']}>
            <input
                ref={refField}
                name={fieldName}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={onBlur}
                value={value ?? "#000000"}
                placeholder={placeholder}
                disabled={disabled}
                type='color'
            /></div>
    )
}

export default ColorPicker