import React, { useEffect, useRef } from 'react'
import useOnClickOutside from 'hooks/use-onclick-outside';
import classes from './Dropdown.module.scss'
import useTrans from 'hooks/use-trans';
import IconChevronDown from 'assets/icon/IconChevronDown';
import { useState } from 'react';
import Constants from 'utils/Constants';
import { downloadFileWithFileName, getMessageCommon, showPopupNotification } from 'utils/Utils';
import factory from 'redux/holon-instance/factory';
import useRouter from 'hooks/use-router';
import { useDispatch } from 'react-redux';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_AUTO_BILL_PAY } from 'utils/EventRegister';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import $ from 'jquery';
import Loading from 'shared/components/form-input/button/loading/Loading';
import Validator from 'utils/Validator';
import IconChevronUp from 'assets/icon/IconChevronUp';

const ItemDropdown = (props) => {
    const { disabled, loading, onClick, title, target, href, style, rel } = props;
    return !href ?
        <div
            className={`${classes['item-dropdown']} ${disabled ? classes['disable'] : ''} `}
            onClick={() => onClick && onClick()}
        >
            {props?.children ?? title}
            {loading ? (
                <div className={`${classes['icon-button']} ${true ? classes['icon-button-loading'] : ['']}`}><Loading /></div>
            ) : null}
        </div>
        :
        <a target={target} style={style} rel={rel} href={href}
            className={`${classes['item-dropdown']} ${disabled ? classes['disable'] : ''} `}>{props?.children ?? title}</a>
}
function DropdownOptions(prop) {
    const { list = [],
        subHolonTemplateID,
        headHolonInstance,
        holonTemplateID,
        cNAME,
        cID,
        template_id,
        userCompany,
        holonTemplate,
        listCheckbox,
        loadingInstance,
        setLoadingInstance,
        warehouse,
        requireConnectionKey,
        requireConnectionHolonId,
        ExcludeIds
    } = prop;

    const router = useRouter();
    const dispatch = useDispatch();
    const { t } = useTrans();

    const handleAsyncPost = (x, index) => {
        let params = {};
        delete params[Constants.QueryParams.ATTRIBUTES_SUB.VALUE];
        delete params[Constants.QueryParams.ATTRIBUTES.VALUE];
        let subHeaderFields = "";
        let listHeadFields = headHolonInstance?.filter(x => !x?.isHidden);
        if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE || holonTemplateID?.split("-")?.length > 1) {
        } else {
            subHeaderFields = listHeadFields?.filter(x => x?.templateId == subHolonTemplateID && !x?.isHidden)?.map(x => x?.key)?.join(",");
        }
        let headerFields = "";
        if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE || holonTemplateID?.split("-")?.length > 1) {
            headerFields = listHeadFields?.map(x => x?.key)?.join(",");
        } else {
            headerFields = listHeadFields?.filter(x => x?.templateId == holonTemplateID && !x?.isHidden)?.map(x => x?.key)?.join(",");
        }

        if (subHolonTemplateID) {
            params['subTemplateId'] = subHolonTemplateID;
        }
        if (holonTemplateID) {
            params['templateId'] = holonTemplateID?.replace(/-/g, ",");
        }

        if (router.get([Constants.QueryParams.KEYWORD.VALUE])) {
            params['keyword'] = router.get([Constants.QueryParams.KEYWORD.VALUE]);
        }
        if (router.get([Constants.QueryParams.OFFSET.VALUE])) {
            params['page'] = router.get([Constants.QueryParams.OFFSET.VALUE]);
        }
        if (router.get([Constants.QueryParams.VIEW.VALUE])) {
            params['view'] = router.get([Constants.QueryParams.VIEW.VALUE]);
        }

        if (router.get([Constants.QueryParams.LIMIT.VALUE])) {
            params['limit'] = router.get([Constants.QueryParams.LIMIT.VALUE]);
        } else {
            params['limit'] = Constants.PAGINATION.LIMIT;
        }

        if (router.get([Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE])) {
            params['holonIdForMultiple'] = router.get([Constants.QueryParams.HOLON_ID_FOR_MULTIPLE.VALUE]);
        }
        if (router.get([Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE])) {
            params['connectionKeyForMultiple'] = router.get([Constants.QueryParams.CONNECTION_KEY_FOR_MULTI.VALUE]);
        }
        if (router.get([Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE])) {
            params['isSelectForConnection'] = router.get([Constants.QueryParams.IS_SELECT_FOR_CONNECTION.VALUE]);
        }

        if (userCompany) {
            params['userInCompanyIds'] = userCompany;
        }
        if (holonTemplateID == Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
            if (template_id) {
                params["templateId"] = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
            } else if (router.get(Constants.QueryParams.CATEGORY.VALUE)) {
                params["templateId"] = holonTemplate?.filter(x => x?.key != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE)?.map(x => x?.key)?.join(",")
            } else {
                params[Constants.QueryParams.IS_SEARCH_ALL_TEMPLATE.VALUE] = 1;
            }
        }

        if (subHeaderFields) {
            params['subHeaderFields'] = subHeaderFields;
        }

        if (headerFields) {
            params['headerFields'] = headerFields;
        }

        if (router.get([Constants.QueryParams.SEARCH.VALUE])) {
            params['keyword'] = router.get([Constants.QueryParams.SEARCH.VALUE]);
        }

        if (cNAME) {
            params['connectionName'] = cNAME;
        }
        if (ExcludeIds) {
            params['excludeIds'] = ExcludeIds;
        }
        if (cID) {
            params['connectionWithHolonId'] = cID;
        }
        if (requireConnectionKey) {
            params['requireConnectionKey'] = requireConnectionKey;
        }
        if (requireConnectionHolonId) {
            params['requireConnectionHolonId'] = requireConnectionHolonId;
        }

        let data = {};
        if (router.get([Constants.QueryParams.ATTRIBUTES.VALUE])) {
            data['mainAttribute'] = JSON.parse(router.get([Constants.QueryParams.ATTRIBUTES.VALUE]));
        }
        if (router.get([Constants.QueryParams.ATTRIBUTES_SUB.VALUE])) {
            data['subAttribute'] = JSON.parse(router.get([Constants.QueryParams.ATTRIBUTES_SUB.VALUE]));
        }

        if (listCheckbox?.length > 0) {
            params['selectedIds'] = listCheckbox?.map(x => x?.id)?.join(",");
        }

        if (x?.inputValue) {
            try {
                let inputValue = JSON.parse(x?.inputValue);
                if (inputValue['responseType']) {
                    data['responseType'] = inputValue['responseType'];
                }
            } catch (error) {

            }
        }
        showPopupNotification({
            typePopup: 'confirm',
            newWindow: true,
            message: Validator.renderMessage(t("confirm_action"), {
                0: x?.note
            }),
            payload: {
                isLoading: true
            },
            callback: async (propsCallbackPopup) => {
                setLoadingInstance(index);
                try {
                    let response = await factory.requestApiWithAction({
                        method: 'POST',
                        params: {
                            ...params
                        },
                        data: data,
                        url: x?.api
                    })

                    if (typeof propsCallbackPopup?.hiddenPopup == 'function') {
                        propsCallbackPopup?.hiddenPopup()
                    }
                    if (!response?.error) {
                        setLoadingInstance(null);
                        if (response?.type == 'blob') {
                            downloadFileWithFileName(response?.data, response?.filename, response?.contentType)
                        } else {

                            if (response?.data?.reload == "1") {

                                let paramsReload = {};
                                if (cID) {
                                    paramsReload[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = cID;
                                }
                                if (cNAME) {
                                    paramsReload[Constants.QueryParams.CONNECTION_NAME.VALUE] = cNAME;
                                }
                                if (ExcludeIds) {
                                    paramsReload[Constants.QueryParams.EXCLUDE_IDS.VALUE] = ExcludeIds;
                                }

                                if (subHolonTemplateID) {
                                    paramsReload[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = subHolonTemplateID;
                                }

                                if (userCompany) {
                                    paramsReload[Constants.QueryParams.USER_IN_COMPANY.VALUE] = userCompany;
                                }

                                if (requireConnectionKey) {
                                    paramsReload[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = requireConnectionKey;
                                }
                                if (requireConnectionHolonId) {
                                    paramsReload[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = requireConnectionHolonId;
                                }

                                dispatch({
                                    type: actionsHolonInstance.GET_HOLON_INSTANCE,
                                    payload: {
                                        ...router.getAll(),
                                        [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonTemplateID?.replace(/-/g, ','),
                                        ...paramsReload
                                    },
                                    callback: () => {
                                        setLoadingInstance(null)
                                    }
                                });
                            }
                            if (response?.data?.data?.length > 0) {
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_AUTO_BILL_PAY,
                                    newWindow: true,
                                    payload: {
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                        data: response?.data?.data,
                                        note: x?.note
                                    }
                                })
                            } else {
                                showPopupNotification({
                                    typePopup: 'message',
                                    message: getMessageCommon({
                                        response: response,
                                        error: null,
                                        actionName: x?.note
                                    }),
                                    newWindow: true
                                });
                            }
                        }
                    } else {
                        setLoadingInstance(null);
                        showPopupNotification({
                            typePopup: 'message',
                            message: getMessageCommon({
                                response: null,
                                error: response,
                                actionName: x?.note
                            }),
                            newWindow: true
                        });
                    }
                } catch (error) {
                    setLoadingInstance(null);
                    showPopupNotification({
                        typePopup: 'message',
                        message: getMessageCommon({
                            response: null,
                            error: error,
                            actionName: x?.note
                        }),
                        newWindow: true
                    });
                }
            }
        });
    }

    return (
        <div>
            {
                list?.map((x, index) => {
                    let stringApi = x?.api;
                    if (!x?.api) {
                        return <ItemDropdown
                            disabled={x?.IsEditabled == Constants.STATUS_CHECKBOX.NO.VALUE || loadingInstance != index && loadingInstance != null ? true : false || loadingInstance != null ? true : false}
                            loading={loadingInstance == index ? true : false}
                            style={{
                                color: 'white'
                            }}
                            key={index}
                            color='yellow'>{x?.note}</ItemDropdown>
                    }

                    if (x?.type == Constants.TYPE_ACTIONS.REDIRECT.VALUE) {
                        return <ItemDropdown
                            style={{
                                pointerEvents: x?.IsEditabled == "0" ? 'none' : 'unset',
                                backgroundColor: 'white'
                            }}
                            disabled={x?.IsEditabled == Constants.STATUS_CHECKBOX.NO.VALUE || loadingInstance != index && loadingInstance != null ? true : false || loadingInstance != null ? true : false}
                            key={index}
                            target="_blank"
                            href={stringApi}
                            rel="noreferrer">
                            {x?.note}
                        </ItemDropdown>
                    }
                    if (x?.type == Constants.TYPE_ACTIONS.ASYNCPOST.VALUE) {
                        return <ItemDropdown
                            disabled={x?.IsEditabled == Constants.STATUS_CHECKBOX.NO.VALUE || loadingInstance != index && loadingInstance != null ? true : false || loadingInstance != null ? true : false}
                            loading={loadingInstance == index ? true : false}
                            onClick={() => handleAsyncPost(x, index)}
                            style={{
                                color: 'white'
                            }}
                            key={index}
                            color='yellow'>{x?.note}</ItemDropdown>
                    }
                    if (x?.type == Constants.TYPE_ACTIONS.POST.VALUE) {
                        return <ItemDropdown
                            disabled={x?.IsEditabled == Constants.STATUS_CHECKBOX.NO.VALUE || loadingInstance != index && loadingInstance != null ? true : false || loadingInstance != null ? true : false}
                            loading={loadingInstance == index ? true : false}
                            onClick={() => handleAsyncPost(x, index)}
                            style={{
                                color: 'white'
                            }}
                            key={index}
                            color='yellow'>{x?.note}</ItemDropdown>
                    }

                    if (x?.type == Constants.TYPE_ACTIONS.POPUP.VALUE) {
                        return <ItemDropdown
                            disabled={x?.IsEditabled == Constants.STATUS_CHECKBOX.NO.VALUE ||
                                (loadingInstance != index && loadingInstance != null) ? true : false || loadingInstance != null ? true : false}
                            loading={loadingInstance == index ? true : false}
                            key={index}
                            color='yellow'
                            style={{
                                color: 'white'
                            }}
                            onClick={() => {
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: x?.api,
                                    payload: {
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                        api: x?.api,
                                        templateId: holonTemplateID,
                                        actionName: x?.note,
                                        warehouse: warehouse,
                                        callback: () => {

                                            let params = {};
                                            if (cID) {
                                                params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = cID;
                                            }
                                            if (cNAME) {
                                                params[Constants.QueryParams.CONNECTION_NAME.VALUE] = cNAME;
                                            }
                                            if (ExcludeIds) {
                                                params[Constants.QueryParams.EXCLUDE_IDS.VALUE] = ExcludeIds;
                                            }

                                            if (subHolonTemplateID) {
                                                params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = subHolonTemplateID;
                                            }

                                            if (userCompany) {
                                                params[Constants.QueryParams.USER_IN_COMPANY.VALUE] = userCompany;
                                            }

                                            if (requireConnectionKey) {
                                                params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = requireConnectionKey;
                                            }
                                            if (requireConnectionHolonId) {
                                                params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = requireConnectionHolonId;
                                            }

                                            dispatch({
                                                type: actionsHolonInstance.GET_HOLON_INSTANCE,
                                                payload: {
                                                    ...router.getAll(),
                                                    [Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE]: holonTemplateID,
                                                    ...params
                                                },
                                                callback: () => {
                                                    setLoadingInstance(null)
                                                }
                                            });
                                        }
                                    }
                                })
                            }}
                            title={x?.note}
                        />;
                    }
                    return <ItemDropdown
                        disabled={x?.IsEditabled == Constants.STATUS_CHECKBOX.NO.VALUE || loadingInstance != index && loadingInstance != null ? true : false || loadingInstance != null ? true : false}
                        loading={loadingInstance == index ? true : false}
                        style={{
                            color: 'white'
                        }}
                        key={index}
                        color='yellow'>{x?.note}</ItemDropdown>
                })
            }
        </div>
    )
}

function DropdownActions(props) {
    const { list = [],
        subHolonTemplateID,
        headHolonInstance,
        holonTemplateID,
        cNAME,
        cID,
        template_id,
        userCompany,
        holonTemplate,
        listCheckbox,
        warehouse,
        requireConnectionKey,
        requireConnectionHolonId,
        ExcludeIds
    } = props;
    const [anchorEl, setAnchorEl] = useState(null)
    const [listAction, setListAction] = useState([]);
    const [loadingInstance, setLoadingInstance] = useState(null);
    const dropDownMenuRef = useRef()
    const { t } = useTrans();
    useOnClickOutside(dropDownMenuRef, () => {
        setAnchorEl(null);
    })

    useEffect(() => {
        if (list?.length > 0) {
            setListAction(list)
        } else {
            setListAction([])
        }
    }, [
        list
    ])


    return (
        <div ref={dropDownMenuRef} className={`${classes['dropdown-container']} custom-content`}>
            <div onClick={(e) => setAnchorEl(e)} className={`${classes['custom-content']} ${listAction?.length == 0 ? classes['disabled'] : ''}`}>
                {t('holon.batch_processing')}
                {
                    anchorEl ? <IconChevronUp strokeWidth={1} /> : <IconChevronDown strokeWidth={1} />
                }
            </div>
            {anchorEl && (
                <div style={{
                    minWidth: $(".custom-content").width()
                }} className={classes['dropdown-option-list']}>
                    <DropdownOptions
                        userCompany={userCompany}
                        subHolonTemplateID={subHolonTemplateID}
                        headHolonInstance={headHolonInstance}
                        holonTemplateID={holonTemplateID}
                        cNAME={cNAME}
                        cID={cID}
                        list={listAction}
                        template_id={template_id}
                        holonTemplate={holonTemplate}
                        listCheckbox={listCheckbox}
                        loadingInstance={loadingInstance}
                        setLoadingInstance={setLoadingInstance}
                        warehouse={warehouse}
                        requireConnectionKey={requireConnectionKey}
                        requireConnectionHolonId={requireConnectionHolonId}
                        ExcludeIds={ExcludeIds}
                    />
                </div>
            )}
        </div>
    )
}

export default DropdownActions;
