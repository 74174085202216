import useTrans from 'hooks/use-trans';
import React from 'react'
import UpdateProductContainer from 'shared/container/product/UpdateProductContainer'

function UpdateProductPage() {
    const { t } = useTrans();
    document.title = t("product.product_update")
    return (
        <UpdateProductContainer />
    )
}

export default UpdateProductPage