const actionCargo = {

    GET_TEMPLATE_CARGO: "GET_TEMPLATE_CARGO",
    GET_WARE_HOUSE_DEFAULT: "GET_WARE_HOUSE_DEFAULT",
    CREATE_PRODUCT_DETAIL_CARGO: "CREATE_PRODUCT_DETAIL_CARGO",
    GET_PRODUCT_DETAIL_CARGO: "GET_PRODUCT_DETAIL_CARGO",

    GET_LIST_PRODUCT_CARGO_POPUP: "GET_LIST_PRODUCT_CARGO_POPUP",

    RETURN_REQUEST: "RETURN_REQUEST",
    RETURN_REQUEST_REASON: "RETURN_REQUEST_REASON",
}

export default actionCargo;