import React from 'react'
import classes from './ConfirmUpdatePopup.module.scss';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import CustomHeaderConfirm from '../custom-header/CustomHeaderConfirm';

function ConfirmCopyPopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();

    const handleAccept = () => {
        payload?.callback && payload?.callback();
        showVisible(false);
    }
    return (
        <>
            <div className={classes['ConfirmUpdatePopup']}>
                <CustomHeaderConfirm showVisible={showVisible} payload={payload} />
                <div className={classes['Body']}>
                    <p>{t("holon.copy")}</p>
                    <div className={classes['Footer']}>
                        <Button color='gray' onClick={() => showVisible(false)} title={t("confirm_popup.cancel")} />
                        <Button onClick={handleAccept} title={t("confirm_popup.copy")} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmCopyPopup