import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import classes from "./UploadFile.module.scss";
import _ from "lodash";
import IconClose from "assets/icon/IconClose";
import IconUpload from "assets/icon/IconUpload";
import { useDispatch } from "react-redux";
import Loading from "../button/loading/Loading";
import Action from 'redux/user/action';
import ErrorMessage from "../error-message/ErrorMessage";
import ErrorMessageHandleRegex from "../error-message/ErrorMessageHandleRegex";
import ErrorMessageHandleFE from "../error-message/ErrorMessageHandleFE";
import CommonText from "shared/components/common/text/CommonText";

const UploadFile = (props) => {
    const {
        name,
        disabled = false,
        value,
        refInput,
        onChange,
        placeholder,
        onFocus,
        style,
        maxLength,
        onBlur,
        errors,
        maximumUpload = 100,
        isRegexLabel,
        validationFE
    } = props;
    const [nameFile, setNameFile] = useState()
    const [dragActive, setDragActive] = React.useState(false);
    const [warningDrag, setWarningDrag] = React.useState(false);
    const [loadingUpload, setLoadingUpload] = React.useState(false);
    const refImport = useRef();
    const dispatch = useDispatch();

    const onChangeUpload = (data) => {
        setLoadingUpload(true);
        let buttonAll = document.querySelectorAll('button');
        let arrButton = [];
        buttonAll.forEach(element => {
            if (element.disabled == false) {
                element.disabled = true;
                arrButton.push(element);
            }
        });

        dispatch({
            type: Action.UPLOAD_IMAGE,
            payload: data,
            callback: (url) => {
                setLoadingUpload(false);
                onChange(url);
                setNameFile(url);
                arrButton.forEach(element => {
                    element.disabled = false;
                });
            }
        });
        refImport.current.value = null;
    };
    const onChangeImport = (e) => {
        if (e.target.files?.length == 0) {
            return;
        }
        if (e.target.files) {
            onChangeUpload(e.target.files[0]);
        }
    };

    useEffect(() => {
        if (!value) {
            refImport.current.value = null;
            setNameFile(null);
        } else {
            setNameFile(value);
            refImport.current.value = null;
        }
    }, [value]);

    let showError = false;
    let error = {
        message: "",
    };

    let arr = name.split(".");
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    const handleDrag = function (
        e
    ) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files) {
            if (e.dataTransfer.files.length == 1) {
                if (e.dataTransfer.files[0]) {
                    onChangeUpload(e.dataTransfer.files[0]);
                }
            } else {
                onChangeUpload(e.dataTransfer.files[0]);
            }
        }
    };

    return (
        <>
            <div
                style={{
                    pointerEvents: loadingUpload || disabled ? 'none' : 'fill'
                }}
                ref={refInput !== null ? refInput : null}
                className={`${classes["Custom_Input"]} ${showError && classes["Custom_Input_Error"]} ${(value?.length ?? 0) == maximumUpload && classes["Custom_Input_Max"]}`}
            >
                <label
                    id="dropReceiver"
                    onDragLeave={() => setWarningDrag(false)}
                    onDragEnter={(e) =>
                        handleDrag(e)
                    }
                    onDrop={(e) => console.log(e)}
                    htmlFor="import_file"
                    style={{
                        opacity: loadingUpload ? 0.3 : 1
                    }}
                    className={`${classes["Custom_Input_Import_File"]} ${value ? classes["CustomBorder_Error"] : ""} ${value ? classes["CustomBorder_Success"] : ""} ${dragActive && classes["hover_drag_input"]} ${warningDrag && classes["hover_drag_input_error"]}`}
                >
                    {warningDrag ? (
                        <div className={classes["Icon_Drag_Error"]}>
                            <IconClose fontSize={20} color="#FC2424" />
                        </div>
                    ) : (
                        <>
                            {
                                loadingUpload ? <div className={classes['Loading_Upload']}><Loading /></div> : <><IconUpload fontSize={20} /></>
                            }
                        </>
                    )}
                    {dragActive && (
                        <div
                            className={classes["drag-file-element"]}
                            onDragEnter={(e) =>
                                handleDrag(e)
                            }
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={(e) => handleDrop(e)}
                        ></div>
                    )}
                </label>
                <p className={classes["Placeholder_Value"]}>
                    <CommonText>{nameFile}</CommonText>
                </p>
                <input
                    name={name}
                    disabled={disabled}
                    ref={refImport}
                    type={"file"}
                    onChange={(e) => {
                        onChangeImport(e);
                    }}
                    style={style}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete="off"
                    hidden
                    id="import_file"
                    multiple={false}
                    accept="*"
                />
            </div>
            {/* {showError ? <ErrorMessage message={error?.message} /> : <></>} */}
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default UploadFile;
