import useTrans from 'hooks/use-trans'
import React from 'react'
import InventoryCountContainer from 'shared/container/stocks/InventoryCountContainer';

function InventoryCountPage() {
    const { t } = useTrans();
    document.title=t("stocks.inventory_control")
    return (
        <InventoryCountContainer />
    )
}

export default InventoryCountPage