import useTrans from 'hooks/use-trans';
import React from 'react';
import TermOfUseContainer from 'shared/container/term-of-use/TermOfUseContainer';

function TermsOfUsePage() {
    const { t } = useTrans();
    document.title = t("faq_management.term_of_use")
    return <TermOfUseContainer />
}

export default TermsOfUsePage
