import useTrans from "hooks/use-trans";
import React, { useEffect, useState } from "react";
import classes from './TableAttributes.module.scss';
import Button from "../../form-input/button/Button";
import IconChevronDown from "assets/icon/IconChevronDown";
import IconChevronRight from "assets/icon/IconChevronRight";
import Constants from 'utils/Constants';
import IconSortUp2 from "assets/icon/IconSortUp2";
import IconSortUp from "assets/icon/IconSortUp";
import IconSortDown from "assets/icon/IconSortDown";
import IconSortDown2 from "assets/icon/IconSortDown2";
import IconSortLeft from "assets/icon/IconSortLeft";
import IconSortRight from "assets/icon/IconSortRight";

const TableAttributesV2 = (props) => {
    const { t } = useTrans();
    const [dataLeft, setDataLeft] = useState([]);
    const [dataRight, setDataRight] = useState([]);
    const [open, setOpen] = useState(false);
    const [tempLeft, setTempLeft] = useState([]);
    const [tempRight, setTempRight] = useState([]);

    useEffect(() => {
        if (props?.headHolonInstance) {
            let dataLeft = [];
            let dataRight = [];
            props?.headHolonInstance?.map(x => {
                if (x?.isHidden) {
                    dataLeft.push(x)
                } else {
                    dataRight.push(x)
                }
            })
            setDataLeft(dataLeft);
            setDataRight(dataRight);
        }
    }, [props?.headHolonInstance])

    const handleSave = (dataRight, headHolonInstance) => {
        dataRight = dataRight?.map((x, index) => {
            return {
                ...x,
                sort: index
            }
        })
        let data = headHolonInstance?.map(x => {
            let index = dataRight?.findIndex(left => left?.id == x?.id);
            if (index != -1) {
                return {
                    ...x,
                    isHidden: false,
                    sort: dataRight[index]?.sort
                }
            }
            return {
                ...x,
                isHidden: true,
                sort: -1
            }
        })
        data = data?.sort((a, b) => a?.sort - b?.sort)
        props?.onChangeHead(data);
    }

    const handleSelectionLeft = (_data) => {
        let data = [...tempLeft];
        let index = tempLeft?.findIndex(x => x?.id == _data?.id);
        if (index == -1) {
            data.push(_data);
        } else {
            data.splice(index, 1);
        }
        setTempLeft(data)
    }

    const handleSelectionRight = (_data) => {
        let data = [...tempRight];
        let index = data?.findIndex(x => x?.id == _data?.id);
        if (index == -1) {
            data.push(_data);
        } else {
            data.splice(index, 1);
        }
        setTempRight(data)
    }

    const handleClickToRight = (tempLeft) => {
        if (tempLeft?.length == 0) {
            return;
        }
        let head = [...dataRight];
        head = [...head, ...tempLeft];

        let headLeft = [...dataLeft];
        tempLeft?.map(x => {
            let index = headLeft?.findIndex(v => v?.id == x?.id);
            if (index != -1) {
                headLeft.splice(index, 1);
            }
        })

        setTempLeft([]);
        setTempRight([]);
        setDataLeft(headLeft);
        setDataRight(head);
    }

    const handleClickToLeft = (tempRight) => {
        if (tempRight?.length == 0) {
            return;
        }
        let head = [...dataLeft];
        head = [...head, ...tempRight]

        let headRight = [...dataRight];
        tempRight?.map(x => {
            let index = headRight?.findIndex(v => v?.id == x?.id);
            if (index != -1) {
                headRight.splice(index, 1)
            }
        })

        setTempLeft([]);
        setTempRight([]);
        setDataLeft(head);
        setDataRight(headRight);

    }

    const checked = (data, id) => data?.findIndex(x => x?.id == id) != -1 ? true : false;

    const findIndexesOfSubList = (parentList, subList) => {
        return subList.map(subItem => {
            return parentList.findIndex(parentItem => parentItem.id === subItem.id);
        }).filter(index => index !== -1);
    }

    const handleSort = (dataList, direction) => {
        let newData = [...dataRight];
        const movedIndices = [];
        const indexes = findIndexesOfSubList(dataRight, dataList)
        indexes.sort((a, b) => (direction === Constants.TABLE_ATTRIBUTES.DOWN.VALUE || direction === Constants.TABLE_ATTRIBUTES.BOTTOM.VALUE) ? b - a : a - b)
        let firstIndex = 0;
        let lastIndex = newData.length - 1;
        indexes.forEach((index) => {
            let newPosition;
            switch (direction) {
                case Constants.TABLE_ATTRIBUTES.TOP.VALUE:
                    if (index == firstIndex) {
                        firstIndex++;
                        return;
                    }
                    newPosition = firstIndex++;
                    break;
                case Constants.TABLE_ATTRIBUTES.UP.VALUE:
                    if (index == firstIndex) {
                        firstIndex++;
                        return;
                    }
                    newPosition = index - 1 >= 0 ? index - 1 : 0;
                    break;
                case Constants.TABLE_ATTRIBUTES.DOWN.VALUE:
                    if (index == lastIndex) {
                        lastIndex--;
                        return;
                    }
                    newPosition = index + 1 < newData.length ? index + 1 : newData.length - 1;
                    break;
                case Constants.TABLE_ATTRIBUTES.BOTTOM.VALUE:
                    if (index == lastIndex) {
                        lastIndex--;
                        return;
                    }
                    newPosition = lastIndex--;
                    break;
                default:
                    newPosition = index;
            }
            const [removedElement] = newData.splice(index, 1);
            newData.splice(newPosition, 0, removedElement);
            movedIndices.push(newPosition);
        });
        setDataRight(newData);
    }

    const getSortedIndexes = (dataList) => {
        return findIndexesOfSubList(dataRight, dataList).sort((a, b) => a - b);
    };

    const isConsecutive = (indexes) => {
        return indexes.every((value, index, array) => index === 0 || value === array[index - 1] + 1)
    };

    const isDisableTopUpButton = (dataList) => {
        if (tempRight?.length == 0) {
            return true;
        }
        const sortedIndexList = getSortedIndexes(dataList)
        return isConsecutive(sortedIndexList) && sortedIndexList?.[0] === 0;
    };

    const isDisableBottomDownButton = (dataList) => {
        if (tempRight?.length == 0) {
            return true;
        }
        const sortedIndexList = getSortedIndexes(dataList);
        return isConsecutive(sortedIndexList) && sortedIndexList?.[sortedIndexList.length - 1] === dataRight.length - 1;
    };

    return props?.headHolonInstance?.length > 0 && <div className={classes['custom-table-attributes']}>
        <div className={classes['custom-header']}>
            <div onClick={() => setOpen(!open)} className={classes['button-arrow']}>
                {
                    open ? <IconChevronDown /> : <IconChevronRight color="currentColor" fontSize={11} />
                }
            </div>
            <p>{t("holon.display_option")}</p>
        </div>
        {
            open && <>
                <div className={classes['body-content']}>
                    <div className={classes['left']}>
                        <p className={classes['title-select']}>{t("holon.selectable_items")}</p>
                        <div className={classes['content-left']}>
                            <div className={classes['custom-table']}>
                                {
                                    dataLeft?.length == 0 && <div className={classes['empty']}>{t("holon.empty")}</div>
                                }
                                {
                                    dataLeft?.map((x, key) => {
                                        return <div
                                            style={{
                                                background: checked(tempLeft, x?.id) ? '#106574' : 'white',
                                            }}
                                            className={classes['row']} onClick={() => handleSelectionLeft(x)} key={key}>
                                            <p style={{
                                                color: checked(tempLeft, x?.id) ? 'white' : 'black'
                                            }}>{x?.label}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={classes['content-center']}>
                        <Button className="button" disabled={tempLeft?.length > 0 ? false : true} color="gray-yellow" onClick={() => handleClickToRight(tempLeft)} startIcon={<IconSortRight fontSize={20} color="#333333" />} />
                        <Button className="button" disabled={tempRight?.length > 0 ? false : true} color="gray-yellow" onClick={() => handleClickToLeft(tempRight)} startIcon={<IconSortLeft fontSize={20} color="#333333" />} />
                    </div>
                    <div className={classes['left']}>
                        <p className={classes['title-select']}>{t("holon.selected_item")}</p>
                        <div className={classes['content-left']}>
                            <div className={classes['custom-table']}>
                                {
                                    dataRight?.length == 0 && <div className={classes['empty']}>{t("holon.empty")}</div>
                                }
                                {
                                    dataRight?.map((x, key) => {
                                        return <div
                                            style={{
                                                background: checked(tempRight, x?.id) ? '#106574' : 'white',
                                            }}
                                            className={classes['row']} onClick={() => handleSelectionRight(x)} key={key}>
                                            <p style={{
                                                color: checked(tempRight, x?.id) ? 'white' : 'black'
                                            }}>{x?.label}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={classes['content-action']}>
                        <Button
                            disabled={isDisableTopUpButton(tempRight)}
                            color="gray-yellow"
                            startIcon={<div className={classes['center-button']}>
                                <IconSortUp2 fontSize={20} />
                            </div>}
                            onClick={() => handleSort(tempRight, Constants.TABLE_ATTRIBUTES.TOP.VALUE)}
                            className="button"
                        />
                        <Button
                            disabled={isDisableTopUpButton(tempRight)}
                            color="gray-yellow"
                            startIcon={<>
                                <IconSortUp fontSize={20} />
                            </>}
                            onClick={() => handleSort(tempRight, Constants.TABLE_ATTRIBUTES.UP.VALUE)}
                            className="button"
                        />
                        <Button
                            disabled={isDisableBottomDownButton(tempRight)}
                            color="gray-yellow"
                            startIcon={<>
                                <IconSortDown fontSize={20} />
                            </>}
                            onClick={() => handleSort(tempRight, Constants.TABLE_ATTRIBUTES.DOWN.VALUE)}
                            className="button"
                        />
                        <Button
                            disabled={isDisableBottomDownButton(tempRight)}
                            color="gray-yellow"
                            startIcon={<div className={classes['center-button']}>
                                <IconSortDown2 fontSize={20} />
                            </div>}
                            onClick={() => handleSort(tempRight, Constants.TABLE_ATTRIBUTES.BOTTOM.VALUE)}
                            className="button"
                        />
                    </div>
                </div>
                <button ref={props?.refButtonAttribute} type="submit" hidden onClick={() => handleSave(dataRight, props?.headHolonInstance)} />
            </>
        }
    </div>
}

export default TableAttributesV2;