import { actionsHolonInstance } from "./action";

const initialState = {
    holonTemplateMaster: [],
    holonTemplateDetail: {
        attributes: [],
        validAttributes: [],
    },
    holonInStanceDetail: {},
    holonTemplateDetailWithHolonTemplate: {},
    holonTemplateConnection: {
        total: 0,
        limit: 10,
        totalPage: 0,
        page: 1,
        records: []
    },
    holonInstance: {
        total: 0,
        limit: 10,
        totalPage: 0,
        page: 1,
        records: []
    },
    holonInstanceWithHolonTemplate: {
        total: 0,
        limit: 10,
        totalPage: 0,
        page: 1,
        records: []
    },
    loading: {
        loadingInstance: false,
        loadingCreateInstance: false,
        loadingUpdateInstance: false,
        loadingHolonInstanceDetail: false,
    },
    subHolonTemplateDetail: {
        attributes: [],
        validAttributes: [],
    },
    loadingGetInstanceByHolonTemplate: false,
    loadingGetHolonTemplateByHolonTemplate: false,
    hoLonTemplateId: null
}

const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsHolonInstance.HOLON_TEMPLATE_MASTER_SUCCESS:
            return {
                ...state,
                holonTemplateMaster: action.payload
            }
        case actionsHolonInstance.HOLON_TEMPLATE_DETAIL_SUCCESS:
            return {
                ...state,
                holonTemplateDetail: action.payload?.holonTemplateDetail,
                loading: {
                    ...state.loading,
                    loadingInstance: false
                }
            }
        case actionsHolonInstance.HOLON_TEMPLATE_DETAIL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingInstance: true
                }
            }
        case actionsHolonInstance.GET_HOLON_INSTANCE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingInstance: true
                }
            }
        case actionsHolonInstance.GET_HOLON_INSTANCE_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingInstance: false
                },
                holonInstance: action.payload
            }
        case actionsHolonInstance.GET_HOLON_TEMPLATE_CONNECTION_SUCCESS:
            return {
                ...state,
                holonTemplateConnection: action.payload
            }
        case actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_SUCCESS:
            return {
                ...state,
                holonInstanceWithHolonTemplate: action.payload,
                loadingGetInstanceByHolonTemplate: false
            }
        case actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE:
            return {
                ...state,
                holonInstanceWithHolonTemplate: []
            }
        case actionsHolonInstance.GET_HOLON_INSTANCE_WITH_HOLON_TEMPLATE_LOADING:
            return {
                ...state,
                loadingGetInstanceByHolonTemplate: true
            }
        case actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingHolonInstanceDetail: true
                },
                holonInStanceDetail: {}
            }
        case actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL_SUCCESS:
            return {
                ...state,
                holonInStanceDetail: action.payload,
                loading: {
                    ...state.loading,
                    loadingHolonInstanceDetail: false
                }
            }
        case actionsHolonInstance.CREATE_HOLON_INSTANCE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateInstance: true
                }
            }
        case actionsHolonInstance.CREATE_HOLON_INSTANCE_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateInstance: false
                }
            }
        case actionsHolonInstance.CREATE_HOLON_INSTANCE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateInstance: false
                }
            }
        case actionsHolonInstance.UPDATE_HOLON_INSTANCE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateInstance: true
                }
            }
        case actionsHolonInstance.UPDATE_HOLON_INSTANCE_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateInstance: false
                },
                holonInStanceDetail: {}
            }
        case actionsHolonInstance.UPDATE_HOLON_INSTANCE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateInstance: false
                }
            }
        case actionsHolonInstance.GET_SUB_HOLON_TEMPLATE_SUCCESS:
            return {
                ...state,
                subHolonTemplateDetail: action.payload
            }
        case actionsHolonInstance.RESET_HOLON_TEMPLATE_DETAIL:
            return {
                ...state,
                subHolonTemplateDetail: {
                    attributes: [],
                    validAttributes: [],
                },
                holonTemplateDetail: {
                    attributes: [],
                    validAttributes: []
                },
            }
        case actionsHolonInstance.RESET_HOLON_INSTANCE:
            return {
                ...state,
                holonInstance: {
                    total: 0,
                    limit: 10,
                    totalPage: 0,
                    page: 1,
                    records: []
                }
            }
        case actionsHolonInstance.LOAD_HOLON_TEMPLATE_ID:
            return {
                ...state,
                hoLonTemplateId: action.payload
            }
        default:
            return { ...state }
    }
}

export default myReducer;