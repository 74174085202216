import { AddCircle, PropaneOutlined, RemoveCircle, Search } from "@mui/icons-material";
import React from "react";
import { getLabel, openCreateForm } from "./Util";

function FilterBar(props) {

    return (
        <div className="row g-3 p-2 sm filter-bar" >
            <div className="col-md-3 has-search">
                <span className="form-control-feedback"><Search /></span>
                <input className="form-control me-2 bg-white" type="search" placeholder={getLabel('[LABEL_SEARCH]')} aria-label={getLabel('[LABEL_SEARCH]')} />
            </div>
            <div className="col-md-8">
                {props.tags == "true" &&
                    <div id="filter-tags" className="w-100">
                        <button type="button" id="search-tag-template" className="btn button-tag f-small d-none">
                            <span className="filter-text">ステータス：作成中</span> <span className="filter-tag"><RemoveCircle /></span>
                        </button>
                        <button type="button" className="btn button-tag">
                            {getLabel('[LABEL_SEARCH_LABEL]')} <span className="positive-icon"><AddCircle /></span>
                        </button>
                    </div>
                }
            </div>
            {props.createBtn == "true" &&
                <div className="col-md-1">
                    <button type="button" className="btn filter-tag-positive float-end" data-bs-target={props.modal} data-bs-toggle="modal" onClick={() => (props.onClick ? props.onClick() : openCreateForm(props.modal))} ><span data-bs-dismiss="modal" aria-label="Close"><AddCircle /></span></button>
                </div>
            }
        </div>
    )
}

export default FilterBar;