import React, { useEffect } from 'react';
import BPOrderContainer from 'shared/container/bp-order/BPOrderContainer'
import Constants from 'utils/Constants';

function BPOrderPage() {
    useEffect(() => {
        document.title = Constants.TITLE_PAGE;
    }, [])
    return <BPOrderContainer />
}

export default BPOrderPage