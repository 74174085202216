const { default: actionHexService } = require("./action");

const initialState = {
    hexService: {},
    loading: {
        loadingGetHexService: false,
        loadingUpdateHexService: false,
    },
    hexServiceList: [],
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionHexService.GET_HEX_SERVICE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetHexService: true
                }
            }
        case actionHexService.GET_HEX_SERVICE_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetHexService: false
                },
                hexService: action.payload
            }
        case actionHexService.UPDATE_HEX_SERVICE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateHexService: true
                }
            }
        case actionHexService.UPDATE_HEX_SERVICE_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateHexService: false
                }
            }
        case actionHexService.UPDATE_HEX_SERVICE_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpdateHexService: false
                }
            }
        case actionHexService.GET_HEX_SERVICE_LIST_SUCCESS:
            return {
                ...state,
                hexServiceList: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;