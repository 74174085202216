import React, { useRef, useState } from 'react';
import classes from './DatePicker.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import useOnClickOutside from 'hooks/use-onclick-outside';
registerLocale('ja', ja);
import _ from 'lodash';
import ErrorMessage from '../error-message/ErrorMessage';
import { IconButton } from '@mui/material';
import vector519 from 'assets/img/vector-519.png';
import vector520 from 'assets/img/vector-520.png';
import IconCalendar from 'assets/icon/IconCalendar';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';
import { v4 as uuidv4 } from 'uuid'

const MonthYearPicker = (props) => {
    const {
        value = "",
        onChange,
        isClearable = false,
        disabled = false,
        endDate = "",
        startDate = "",
        inputRef,
        errors,
        withPortal,
        isSearch,
        name,
        validationFE,
        isRegexLabel
    } = props;
    const [open, setOpen] = useState(false);
    const ref = useRef();

    useOnClickOutside(ref, () => setOpen(false));

    let showError = false;
    let error = {
        message: '',
    };

    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    let id = `${uuidv4()}-root-portal`

    return (
        <>
            <div
                ref={inputRef}
                id={id}
                className={`${classes['CustomDatePicker']} custom-date-picker ${showError ? classes['DatePicker_Error'] : ''
                } ${isSearch ? classes['Custom_Form_Search'] : ''}`}
            >
                <DatePicker
                    closeOnScroll={(e) => e.target === document}
                    ref={inputRef ? inputRef : ref}
                    locale="ja"
                    selected={value ? new Date(value) : null}
                    onChange={(date) => {
                        onChange && onChange(date);
                        setOpen(false);
                    }}
                    placeholderText="YYYY/MM"
                    isClearable={isClearable}
                    popperClassName="some-custom-class"
                    popperPlacement="bottom"
                    dateFormat="yyyy/MM"
                    startDate={startDate}
                    endDate={endDate}
                    disabled={disabled}
                    open={open}
                    withPortal={withPortal}
                    portalId={id}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div className="custom-header">
                            <IconButton
                                className="btn-arrow-rounded-icon"
                                children={
                                    <img
                                        src={vector519}
                                        className="vector-icon"
                                        alt="vector icon"
                                    />
                                }
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                disableFocusRipple={true}
                                disableRipple={true}
                            />
                            <div>{`${date.getFullYear()}年`}</div>
                            <IconButton
                                className="btn-arrow-rounded-icon"
                                children={
                                    <img
                                        src={vector520}
                                        className="vector-icon"
                                        alt="vector icon"
                                    />
                                }
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                disableFocusRipple={true}
                                disableRipple={true}
                            />
                        </div>
                    )}
                />
                {open ? (
                    <div onClick={() => setOpen(!open)} className={classes['Icon']}>
                        <IconCalendar />
                    </div>
                ) : (
                    <div onClick={() => setOpen(!open)} className={classes['Icon']}>
                        <IconCalendar />
                    </div>
                )}
            </div>
            {/* {showError ? <ErrorMessage message={error?.message} /> : <></>} */}
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default MonthYearPicker;
