import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import Utils from 'utils/Utils';
import CustomReactSelect from '../form-input/react-select/CustomReactSelect';

const FormReactSelect = (props) => {
    const {
        fieldName,
        validate = [],
        placeholder = '',
        disabled = false,
        options = [],
        textAlign = 'left',
        size = '',
        onChangeSelect,
        isSearch = false,
        className,
        isShowKey = false,
        validationFE = false,
        textTrans,
        isRegexLabel,
        onBlurSelect = () => { },
        attributeKey = "",
        isMenuPortal,
        group,
        isMulti,
        maxToShowProps,
        hideSelectedOptions = true,
        menuPlacement,
        maxMenuHeight,
        customComponents,
        closeMenuOnSelect = true
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const wrapRef = useRef();

    let portalStyles = {}
    let menuPortalTarget = {}
    if (isMenuPortal) {
        portalStyles['zIndex'] = 9999;
        menuPortalTarget['menuPortalTarget'] = document.body;
    }

    return (
        <div ref={wrapRef} className="Input w-100">
            <Controller
                control={control}
                name={fieldName}
                rules={{ validate: Validator.genValidate(validate, fieldName) }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <CustomReactSelect
                            validationFE={validationFE}
                            inputRef={ref}
                            onChange={(e) => {
                                onChange(e);
                                onChangeSelect && onChangeSelect(e);
                                Utils.triggerSubmit(wrapRef);
                            }}
                            value={isMulti ? (value ? [...value] : []) : (value)}
                            errors={errors}
                            disabled={disabled}
                            options={options}
                            placeholder={placeholder}
                            textAlign={textAlign}
                            name={fieldName}
                            onBlur={(e) => {
                                onBlurSelect(e);
                                onBlur(e);
                            }}
                            size={size}
                            isSearch={isSearch}
                            className={className}
                            isShowKey={isShowKey}
                            textTrans={textTrans}
                            isRegexLabel={isRegexLabel}
                            attributeKey={attributeKey}
                            {...menuPortalTarget}
                            styles={{
                                menuPortal: base => ({ ...base, ...portalStyles })
                            }}
                            group={group}
                            isMulti={isMulti}
                            maxToShowProps={maxToShowProps}
                            hideSelectedOptions={hideSelectedOptions}
                            _props={{
                                menuPlacement: menuPlacement,
                                maxMenuHeight: maxMenuHeight,
                                classNamePrefix: "react-select"
                            }}
                            customComponents={customComponents}
                            closeMenuOnSelect={closeMenuOnSelect}
                        />
                    );
                }}
            />
        </div>
    );
};

FormReactSelect.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    textAlign: PropTypes.string,
    size: PropTypes.string,
    textTrans: PropTypes.string,
    isSearch: PropTypes.bool,
    isShowKey: PropTypes.bool,
    validationFE: PropTypes.bool,
};
export default FormReactSelect;
