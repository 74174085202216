export const DetectMimeType = (base64String) => {
    const signatures = {
        JVBERi0: "application/pdf",
        R0lGODdh: "image/gif",
        iVBORw0KGgo: "image/png",
        TU0AK: "image/tiff",
        "/9j/": "image/jpg",
        UEs: "application/doc",
        PK: "application/zip",
    };
    for (var s in signatures) {
        if (base64String.indexOf(s) != -1 && base64String.indexOf(s) != undefined) {
            return signatures[s]?.split("/")?.[1];
        }
    }
    return null;
}