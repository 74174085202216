import useRouter from 'hooks/use-router';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PolicySettingAction from 'redux/policy-setting/action';
import RouterPath from 'router/RouterPath';
import FormGroup from 'shared/components/common/form-search/FormGroup';
import FormItem from 'shared/components/common/form-search/FormItem';
import FormSearch from 'shared/components/common/form-search/FormSearch';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormSelect from 'shared/components/custom-form/FormSelect';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import classes from './PolicySetting.module.scss';
import useCustomState from 'hooks/use-custom-state';
import useTrans from 'hooks/use-trans';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import FormInput from 'shared/components/custom-form/FormInput';
import TextLabel from 'shared/components/common/form-search/TextLabel';
import Validator from 'utils/Validator';
import FormEditor from 'shared/components/custom-form/FormEditor';
import { showPopupNotification } from 'utils/Utils';

function PolicySettingContainer(props) {
    const { type } = props;
    const defaultValues = {
        Lang: Constants.T001.JP.CODE,
        Type: Constants.T002.TERM_OF_SERVICE.CODE,
    };
    const methods = useForm({
        defaultValues: defaultValues,
    });
    const methodsForm = useForm({
        defaultValues: {},
    });

    const router = useRouter();
    const { t } = useTrans()
    const dispatch = useDispatch();
    const [optionsLang, setOptionsLang] = useCustomState([]);
    const { data, loadingUpdatePolicy, loadingGetPolicy } = useSelector((state) => state.policySetting);

    useEffect(() => {
        if (type) {
            methods.setValue("Type", type)
        }
    }, [type])

    useEffect(() => {
        let data = Object.keys(Constants.T001).map((key) => {
            return {
                label: t(`faq_management.lang.${Constants.T001[key]?.NAME}`),
                value: Constants.T001[key]?.CODE,
                key: Constants.T001[key]?.CODE,
            };
        });
        setOptionsLang(data);
    }, []);

    let optionsPolicy = [
        {
            label: t(Constants.T002.PRIVATE_POLICY.KEY),
            value: Constants.T002.PRIVATE_POLICY?.CODE,
            key: Constants.T002.PRIVATE_POLICY?.CODE,
        },
        {
            label: t(Constants.T002.PRIVICY_POLICY?.KEY),
            value: Constants.T002.PRIVICY_POLICY?.CODE,
            key: Constants.T002.PRIVICY_POLICY?.CODE,
        },
        {
            label: t(Constants.T002.TERM_OF_SERVICE?.KEY),
            value: Constants.T002.TERM_OF_SERVICE?.CODE,
            key: Constants.T002.TERM_OF_SERVICE?.CODE,
        },
    ]

    useEffect(() => {
        if (router.get([Constants.QueryParams.LANGUAGE.VALUE])) {
            methods.setValue(
                'Lang',
                router.get([Constants.QueryParams.LANGUAGE.VALUE]),
            );
        } else {
            methods.setValue('Lang', Constants.T001.JP.CODE);
        }
        if (router.get([Constants.QueryParams.TYPE_SEARCH.VALUE])) {
            methods.setValue(
                'Type',
                router.get([Constants.QueryParams.TYPE_SEARCH.VALUE]),
            );
        } else {
            methods.setValue('Type', Constants.T002.TERM_OF_SERVICE.CODE);
        }
    }, [
        router.get([Constants.QueryParams.LANGUAGE.VALUE]),
        router.get([Constants.QueryParams.TYPE_SEARCH.VALUE]),
    ]);

    const onSubmit = (data) => {
        router.replace({
            params: {
                [Constants.QueryParams.LANGUAGE.VALUE]: data?.Lang,
                [Constants.QueryParams.TYPE_SEARCH.VALUE]: data?.Type,
            },
        });
    };

    useEffect(() => {
        const params = {};
        if (router.get([Constants.QueryParams.LANGUAGE.VALUE])) {
            params['lang'] = router.get([Constants.QueryParams.LANGUAGE.VALUE]);
        } else {
            params['lang'] = Constants.T001.JP.CODE;
        }
        if (router.get([Constants.QueryParams.TYPE_SEARCH.VALUE])) {
            params['key'] = router.get([
                Constants.QueryParams.TYPE_SEARCH.VALUE,
            ]);
        } else {
            params['key'] = Constants.T002.TERM_OF_SERVICE.CODE;
        }
        dispatch({
            type: PolicySettingAction.GET_POLICY_SETTING,
            payload: {
                ...params,
            },
        });
    }, [
        router.get([Constants.QueryParams.LANGUAGE.VALUE]),
        router.get([Constants.QueryParams.TYPE_SEARCH.VALUE]),
    ]);

    useEffect(() => {
        if (data) {
            methodsForm.setValue('Title', data?.title);
            methodsForm.setValue('Content', data?.content);
            methodsForm.setValue('Key', data?.key);
            methodsForm.setValue('Lang', data?.lang);
        }
    }, [data]);


    const onSubmitForm = (_data) => {
        showPopupNotification({
            typePopup: 'confirm',
            message: t("E0042"),
            callback: () => {
                dispatch({
                    type: PolicySettingAction.UPDATE_POLICY_SETTING,
                    payload: {
                        lang: router.get([Constants.QueryParams.LANGUAGE.VALUE]) ?? Constants.T001.JP.CODE,
                        key: router.get([Constants.QueryParams.TYPE_SEARCH.VALUE]) ?? Constants.T002.TERM_OF_SERVICE.CODE,
                        content: _data?.Content,
                        title: _data?.Title,
                    },
                    callback: {
                        success: () => {
                            showPopupNotification({
                                typePopup: 'message',
                                message: t("successful_update"),
                            })
                            router.push({
                                pathname: RouterPath.PORTAL_POLICY,
                                params: {
                                    [Constants.QueryParams.LANGUAGE.VALUE]: data?.lang,
                                    [Constants.QueryParams.TYPE_SEARCH.VALUE]:
                                        data?.key,
                                },
                            });
                        },
                        failed: (error) => {
                            showPopupNotification({
                                typePopup: 'message',
                                message: error,
                                newWindow: true
                            })
                        }
                    },
                });
            }
        })
    }
    return (
        <ContentLayout>
            <div className={classes['PolicySetting']}>
                <div className={classes['PolicySetting-FormSearch']}>
                    <CustomFormProvider {...methods}>
                        <form
                            className="quick-submit-form"
                            onSubmit={methods.handleSubmit(onSubmit)}
                        >
                            <FormSearch>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.lang_choice')} />
                                        <FormSelect
                                            options={optionsLang}
                                            fieldName="Lang"
                                        />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.terms_and_conditions_information')} />
                                        <FormSelect
                                            options={optionsPolicy}
                                            fieldName="Type"
                                        />
                                    </FormItem>
                                    <button hidden type='submit' />
                                </FormGroup>
                            </FormSearch>
                        </form>
                    </CustomFormProvider>
                </div>
                <div className={classes['policy-content']}>
                    {/* <p className={classes['title-policy']}>{data?.title}</p>
                    <div
                        className={classes['content']}
                        dangerouslySetInnerHTML={{ __html: data?.content }}
                    /> */}
                    <CustomFormProvider {...methodsForm}>
                        <form
                            className="quick-submit"
                            onSubmit={methodsForm.handleSubmit(onSubmitForm)}
                        >
                            <FormSearch>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t('faq_management.title')} />
                                        <FormInput
                                            validate={[Validator.required]}
                                            validationFE
                                            textTrans='faq_management.field_name'
                                            fieldName="Title" />
                                    </FormItem>
                                </FormGroup>
                                <FormGroup>
                                    <FormItem>
                                        <TextLabel title={t("faq_management.content")} />
                                        <FormEditor
                                            validate={[Validator.required]}
                                            validationFE
                                            textTrans='faq_management.field_name'
                                            fieldName="Content" />
                                    </FormItem>
                                </FormGroup>
                            </FormSearch>
                            <div className={classes['action-update']}>
                                <Button type='submit' loading={loadingUpdatePolicy} disabled={loadingGetPolicy} title={t("save")} />
                            </div>
                        </form>
                    </CustomFormProvider>
                </div>
            </div>
        </ContentLayout>
    );
}

export default PolicySettingContainer;
