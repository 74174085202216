import actionWareHouse from "./action";

const initialState = {
    loadingListWarehouse: false,
    loadingCreateWarehouse: false,
    loadingUpdateWarehouse: false,
    loadingWarehouseDetail: false,
    listWarehouse: {},
    holonTemplateDetail: {}
}
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionWareHouse.GET_WAREHOUSE_LIST:
            return {
                ...state,
                loadingListWarehouse: true
            }
        case actionWareHouse.GET_WAREHOUSE_LIST_SUCCESS:
            return {
                ...state,
                loadingListWarehouse: false,
                listWarehouse: action.payload
            }
        case actionWareHouse.GET_WAREHOUSE_LIST_FAILED:
            return {
                ...state,
                loadingListWarehouse: false
            }
        case actionWareHouse.GET_HOLON_TEMPLATE_DETAIL_WITH_WAREHOUSE_SUCCESS:
            return {
                ...state,
                holonTemplateDetail: action.payload
            }
        case actionWareHouse.CREATE_WAREHOUSE:
            return {
                ...state,
                loadingCreateWarehouse: true
            }
        case actionWareHouse.CREATE_WAREHOUSE_FAILED:
            return {
                ...state,
                loadingCreateWarehouse: false
            }
        case actionWareHouse.CREATE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                loadingCreateWarehouse: false
            }
        case actionWareHouse.UPDATE_WAREHOUSE:
            return {
                ...state,
                loadingUpdateWarehouse: true
            }
        case actionWareHouse.UPDATE_WAREHOUSE_FAILED:
            return {
                ...state,
                loadingUpdateWarehouse: false
            }
        case actionWareHouse.UPDATE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                loadingUpdateWarehouse: false
            }
        default:
            return {
                ...state
            }
    }
}

export default myReducer;