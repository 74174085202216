import useTrans from 'hooks/use-trans';
import React from 'react'
import WalletChargeContainer from 'shared/container/wallet-charge/WalletChargeContainer';

function WalletChargePage() {
    const { t } = useTrans();
    document.title = t("wallet.wallet_charge");
    return (
        <WalletChargeContainer />
    )
}

export default WalletChargePage