import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import CustomRadio from '../form-input/radio-button/CustomRadio';
import Utils from 'utils/Utils';
import ReCaptCha from 'shared/components/common/recaptcha/recaptcha'


const FormCaptcha = (props) => {
    const {
        validate = [],
        className,
        fieldName,
        onChange,
        textTrans,
        isRegexLabel,
        validationFE = false
    } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const wrapRef = useRef();
    return (
        <div ref={wrapRef}>
            <Controller
                control={control}
                name={fieldName}
                rules={{ validate: Validator.genValidate(validate, fieldName) }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <ReCaptCha
                            onChange={onChange}
                            errors={errors}
                            name={fieldName}
                            textTrans={textTrans}
                            isRegexLabel={isRegexLabel}
                            validationFE={validationFE}
                        />
                    );
                }}
            />
        </div>
    );
};

FormCaptcha.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    textAlign: PropTypes.string,
    size: PropTypes.string,
    options: PropTypes.array,
};
export default FormCaptcha;
