import _ from 'lodash';
import React from 'react'
import ErrorMessage from '../error-message/ErrorMessage';
import classes from './TextArea.module.scss';
import ErrorMessageHandleFE from '../error-message/ErrorMessageHandleFE';
import ErrorMessageHandleRegex from '../error-message/ErrorMessageHandleRegex';


function TextArea(props) {
    const {
        name,
        value,
        onChange,
        errors,
        maxLength,
        onBlur,
        onFocus,
        refInput,
        disabled,
        validationFE = false,
        textTrans,
        isRegexLabel,
        placeholder,
        height
    } = props;

    let showError = false;
    let error = {
        message: '',
    };

    let arr = name.split('.');
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 7,
            }}
            className={`${classes['textarea']} ${disabled ? classes['disabled'] : ''}`}
        >
            <textarea
                ref={refInput}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                disabled={disabled}
                value={value ?? ""}
                maxLength={maxLength}
                style={{
                    padding: '10px 13px',
                    border: `0.5px solid ${showError ? 'red' : '#929292'}`,
                    fontSize: 13,
                    color: '#333333',
                    borderRadius: '5px',
                    height: height
                }}
                placeholder={placeholder}

            />
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} textTrans={textTrans} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </div>
    )
}

export default TextArea