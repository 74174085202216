import React, { useEffect } from 'react'
import classes from './FormSearchAttributes.module.scss';
import FormSelect from 'shared/components/custom-form/FormSelect';
import Button from 'shared/components/form-input/button/Button';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useFieldArray, useForm } from 'react-hook-form';
import useTrans from 'hooks/use-trans';
import FormInput from 'shared/components/custom-form/FormInput';
import Constants from 'utils/Constants';
import FormRadio from 'shared/components/custom-form/FormRadio';
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import Box from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import FormListCheckbox from 'shared/components/custom-form/FormListCheckbox';
import FormDateTimePicker from 'shared/components/custom-form/FormDateTimePicker';

function FormSearchAttributesTemplate(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const { t } = useTrans();
    const formArray = payload?.formArray == false ? false : true;

    const onSubmit = (data) => {
        if (formArray) {
            payload?.callback && payload?.callback(data?.data);
        } else {
            delete data['data'];
            payload?.callback && payload?.callback(data);
        }
        showVisible();
    }
    const control = methods.control;
    const { fields } = useFieldArray({
        control: control,
        name: 'data'
    })

    useEffect(() => {
        if (payload?.listFormItem) {
            let data = [];
            payload?.listFormItem?.map(x => {
                if (x?.elementType != Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
                    data.push(x);
                }
                return x;
            })
            if (formArray) {
                methods.reset({
                    data: data
                })
            } else {
                methods.reset({
                    data: data
                })
                data?.map(x => {
                    if(x?.elementType == Constants.VIEW_SEARCH.ROW.VALUE){
                        x?.component?.map(com => {
                            methods.setValue(com?.key, com[com?.key]);
                        })
                    }else{
                        methods.setValue(x?.key, x[x?.key]);
                    }
                })
            }
        }
    }, [payload?.listFormItem, formArray])

    const renderInput = (value) => {
        switch (value?.elementType) {
            case Constants.ELEMENT_TYPE.TEXT.VALUE:
                return <FormInput validate={[]} fieldName={`${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT.VALUE:
                return <FormSelect options={value?.options ?? []} validate={[]} fieldName={`${value?.key}`} />
            case Constants.ELEMENT_TYPE.RADIO.VALUE:
                return <FormRadio options={value?.options} validate={[]} fieldName={`${value?.key}`} />
            case Constants.ELEMENT_TYPE.TEL.VALUE:
                return <FormInput validate={[]} fieldName={`${value?.key}`} />
            case Constants.ELEMENT_TYPE.PASSWORD.VALUE:
                return <FormInput type="password" validate={[]} fieldName={`${value?.key}`} />
            case Constants.ELEMENT_TYPE.CHECKBOX.VALUE:
                return <FormListCheckbox options={value?.option} fieldName={`${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATE.VALUE:
                return <FormDatePicker validate={[]} fieldName={`${value?.key}`} />
            case Constants.ELEMENT_TYPE.NUMBER.VALUE:
                return <FormInput format={Constants.FormInputFormat.NUMBER.VALUE} validate={[]} fieldName={`${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATETIME.VALUE:
                return <FormDateTimePicker validate={[]} fieldName={`${value?.key}`} />
            default:
                return <FormInput validate={[]} fieldName={`${value?.key}`} />
        }
    }

    const renderView = (value) => {
        switch (value?.elementType) {
            case Constants.VIEW_SEARCH.ROW.VALUE:
                return <div className={classes['custom-input-row']}>
                    {
                        value?.component?.map((x, index) => {
                            return <React.Fragment>
                                {renderInput(x)}
                                {index < value?.component?.length - 1 ? <span className={classes['icon-center']}> 〜</span> : null}
                            </React.Fragment>
                        })
                    }
                </div>
            default:
                return renderInput(value);
        }
    }
    const renderInputWithArray = (value, index) => {
        switch (value?.elementType) {
            case Constants.ELEMENT_TYPE.TEXT.VALUE:
                return <FormInput validate={[]} fieldName={`data.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT.VALUE:
                return <FormSelect options={value?.options ?? []} validate={[]} fieldName={`data.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.RADIO.VALUE:
                return <FormRadio options={value?.options} validate={[]} fieldName={`data.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.TEL.VALUE:
                return <FormInput validate={[]} fieldName={`data.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.PASSWORD.VALUE:
                return <FormInput type="password" validate={[]} fieldName={`data.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.NUMBER.VALUE:
                return <FormInput format={Constants.FormInputFormat.NUMBER.VALUE} validate={[]} fieldName={`data.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.CHECKBOX.VALUE:
                return <FormListCheckbox options={value?.options} fieldName={`data.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATE.VALUE:
                return <FormDatePicker validate={[]} fieldName={`data.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATETIME.VALUE:
                return <FormDateTimePicker validate={[]} fieldName={`data.${index}.${value?.key}`} />
            default:
                return <FormInput validate={[]} fieldName={`data.${index}.${value?.key}`} />
        }
    }

    return (
        <BoxContentPopup >
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        showVisible={showVisible}
                        title={t("Holon_Template.add_search_criteria")}
                        actionSectionBottom={<Button color='gray' type='submit' title={t("Holon_Template.search")} />}
                    >
                        <Box>
                            <BoxHeader className={classes['wrap-content-heading']}>
                                <BoxTitle>{t("Holon_Template.setting")}</BoxTitle>
                            </BoxHeader>
                            <BoxBody className={classes['wrap-content-body']}>
                                {
                                    fields?.map((item, key) => {
                                        return <BoxRow key={key} className={classes['wrap-content-form-item']}>
                                            <BoxLabel width='200px'>
                                                {item?.label}
                                            </BoxLabel>
                                            <BoxContent>
                                                {
                                                    formArray ? renderInputWithArray(item, key) : renderView(item)
                                                }
                                            </BoxContent>
                                        </BoxRow>
                                    })
                                }
                            </BoxBody>
                        </Box>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default FormSearchAttributesTemplate