import React from 'react'
import HolonInstance from 'shared/components/holon-instance/HolonInstance'

function HolonInstanceContainer() {
    return (
        <HolonInstance 
            isShowCheckBox 
            isHolonTemplateName 
            isShowCategory={true} 
        />
    )
}

export default HolonInstanceContainer