import React, { useEffect, useState } from "react";
import classes from "./CustomRadioWalletCharge.module.scss";
import ErrorMessage from "../error-message/ErrorMessage";
import _ from "lodash";
import IconCheck from "assets/icon/IconCheck";
import IconChecked from "assets/icon/IconChecked";
import ErrorMessageHandleFE from "../error-message/ErrorMessageHandleFE";
import ErrorMessageHandleRegex from "../error-message/ErrorMessageHandleRegex";
import useTrans from "hooks/use-trans";
import Image from "shared/components/common/image/Image";

const RowBank = ({ Title, selected, onClick, ref, value = 1, bankInfo }) => {
    const { t } = useTrans();
    return (
        <div className={classes['row']}>
            <div onClick={() => onClick(value)} className={classes['header']}>
                <button type="button" ref={ref} >
                    {selected == value ? <IconChecked /> : <IconCheck />}
                </button>
                {Title}
            </div>
            <div className={classes['body']}>
                <div className={classes['body-title']}>
                    <p>{t("wallet_charge.transfer_information")}</p>
                </div>
                <ul className={classes['body-info']}>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.bank_name")}</label>
                            <p>{bankInfo?.bankName}</p>
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.branch_name")}</label>
                            <p>{bankInfo?.branchName}</p>
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.course_type")}</label>
                            <p>{bankInfo?.accountType}</p>
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.account_number")}</label>
                            <p>{bankInfo?.accountNo}</p>
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.account_holder")}</label>
                            <p>{bankInfo?.accountOwner}</p>
                        </div>
                    </li>
                    <li>
                        <div className={classes['bank-info']}>
                            <label>{t("wallet_charge.account_name")}</label>
                            <p>{bankInfo?.accountOwnerKana}</p>
                        </div>
                    </li>
                </ul >
            </div >
        </div >
    )
}
const Row = ({ Title, selected, value, onClick, ref, bankInfo }) => {
    return (
        <div className={classes['row']}>
            <div onClick={() => onClick(value)} className={classes['header']}>
                <button type="button" ref={ref} >
                    {selected == value ? <IconChecked /> : <IconCheck />}
                </button>
                {Title}
            </div>
            <div className={classes['body']}>
                <Image width="200px" src={bankInfo} />
            </div>
        </div>
    )
}
function CustomRadioWalletCharge(props) {
    const {
        options = [],
        onChange,
        value,
        className,
        disabled = false,
        errors,
        inputRef,
        name,
        textTrans,
        validationFE,
        isRegexLabel,
        bankInfo
    } = props;

    const [selected, setSelected] = useState(1)
    const { t } = useTrans();
    let showError = false;
    let error = {
        message: "",
    };

    let arr = name.split(".");
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    const onChangeValue = (_e) => {
        onChange(_e)
    }

    useEffect(() => {
        if (value) {
            setSelected(value)
        }
    }, [value])

    return (
        <div style={{
            pointerEvents: disabled ? 'none' : 'fill'
        }} className={`${classes['custom-radio-wallet-charge']} ${className}`}>
            <RowBank bankInfo={bankInfo} ref={inputRef} onClick={onChangeValue} selected={selected} Title={<p>{t("wallet_charge.bank_transfer")}</p>} />
            <Row bankInfo={bankInfo?.alipayQR} ref={inputRef} onClick={onChangeValue} value={2} selected={selected} Title={<p>Alipay</p>} />
            <Row bankInfo={bankInfo?.weChatPayQR} ref={inputRef} onClick={onChangeValue} value={3} selected={selected} Title={<p>WeChatPay</p>} />
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} textTrans={textTrans} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </div>
    )
}

export default CustomRadioWalletCharge