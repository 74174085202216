export const actionMessage = {
    REQUEST_SYNC_CHANNEL: "REQUEST_SYNC_CHANNEL",
    REQUEST_SYNC_CHANNEL_FAILED: "REQUEST_SYNC_CHANNEL_FAILED",
    REQUEST_SYNC_CHANNEL_SUCCESS: "REQUEST_SYNC_CHANNEL_SUCCESS",
    
    LOAD_MORE_CHANNEL: "LOAD_MORE_CHANNEL",
    LOAD_MORE_CHANNEL_FAILED: "LOAD_MORE_CHANNEL_FAILED",
    LOAD_MORE_CHANNEL_SUCCESS: "LOAD_MORE_CHANNEL_SUCCESS",

    GET_CURRENT_CHANNEL_MSG: "GET_CURRENT_CHANNEL_MSG",
    GET_CURRENT_CHANNEL_MSG_LOADING: "GET_CURRENT_CHANNEL_MSG_LOADING",
    GET_CURRENT_CHANNEL_MSG_SUCCESS: "GET_CURRENT_CHANNEL_MSG_SUCCESS",

    REQUEST_GET_MESSAGE: "REQUEST_GET_MESSAGE",
    REQUEST_GET_MESSAGE_LOADING: "REQUEST_GET_MESSAGE_LOADING",
    REQUEST_GET_MESSAGE_SUCCESS: "REQUEST_GET_MESSAGE_SUCCESS",
    REQUEST_GET_MESSAGE_FAILED: "REQUEST_GET_MESSAGE_FAILED",

    RESET_GET_MESSAGE_SUCCESS: "RESET_GET_MESSAGE_SUCCESS",


    REQUEST_GET_FIRST_MESSAGE: "REQUEST_GET_FIRST_MESSAGE",
    REQUEST_GET_FIRST_MESSAGE_LOADING: "REQUEST_GET_FIRST_MESSAGE_LOADING",
    REQUEST_GET_FIRST_MESSAGE_SUCCESS: "REQUEST_GET_FIRST_MESSAGE_SUCCESS",
    REQUEST_GET_FIRST_MESSAGE_FAILED: "REQUEST_GET_FIRST_MESSAGE_FAILED",

    REQUEST_GET_SCROLL_MESSAGE: "REQUEST_GET_SCROLL_MESSAGE",
    REQUEST_GET_SCROLL_MESSAGE_LOADING: "REQUEST_GET_SCROLL_MESSAGE_LOADING",
    REQUEST_GET_SCROLL_MESSAGE_SUCCESS: "REQUEST_GET_SCROLL_MESSAGE_SUCCESS",
    REQUEST_GET_SCROLL_MESSAGE_FAILED: "REQUEST_GET_SCROLL_MESSAGE_FAILED",
    
    REQUEST_SYNC_MESSAGE: "REQUEST_SYNC_MESSAGE",
    REQUEST_SYNC_MESSAGE_LOADING: "REQUEST_SYNC_MESSAGE_LOADING",
    REQUEST_SYNC_MESSAGE_SUCCESS: "REQUEST_SYNC_MESSAGE_SUCCESS",

    REQUEST_RECEIVE_MESSAGE: "REQUEST_RECEIVE_MESSAGE",
    REQUEST_RECEIVE_MESSAGE_LOADING: "REQUEST_RECEIVE_MESSAGE_LOADING",
    REQUEST_RECEIVE_MESSAGE_SUCCESS: "REQUEST_RECEIVE_MESSAGE_SUCCESS",

    SEND_MESSAGE: "SEND_MESSAGE",
    SEND_MESSAGE_LOADING: "SEND_MESSAGE_LOADING",
    SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",


    ADD_CHANNEL_MSG: "ADD_CHANNEL_MSG",
    ADD_CHANNEL_MSG_LOADING: "ADD_CHANNEL_MSG_LOADING",
    ADD_CHANNEL_MSG_SUCCESS: "ADD_CHANNEL_MSG_SUCCESS",
    
    ADD_USER_CHANNEL: "ADD_USER_CHANNEL",
    ADD_USER_CHANNEL_SUCCESS: "ADD_USER_CHANNEL_SUCCESS",
    ADD_USER_CHANNEL_FAILED: "ADD_USER_CHANNEL_FAILED",

    GET_SYNC_CHANNEL_LIST: "GET_SYNC_CHANNEL_LIST",
    GET_SYNC_CHANNEL_LIST_LOADING: "GET_SYNC_CHANNEL_LIST_LOADING",
    GET_SYNC_CHANNEL_LIST_SUCCESS: "GET_SYNC_CHANNEL_LIST_SUCCESS",

    CREATE_CHANNEL: "CREATE_CHANNEL",
    CREATE_CHANNEL_LOADING: "CREATE_CHANNEL_LOADING",
    CREATE_CHANNEL_SUCCESS: "CREATE_CHANNEL_SUCCESS",

    SYNC_USER: "SYNC_USER",
    SYNC_USER_LOADING: "SYNC_USER_LOADING",
    SYNC_USER_SUCCESS: "SYNC_USER_SUCCESS",

    GET_LIST_USER_INSTANCE: "GET_LIST_USER_INSTANCE",
    GET_LIST_USER_INSTANCE_LOADING: "GET_LIST_USER_INSTANCE_LOADING",
    GET_LIST_USER_INSTANCE_SUCCESS: "GET_LIST_USER_INSTANCE_SUCCESS",
   
    GET_LIST_USER_COMPANY: "GET_LIST_USER_COMPANY",
    GET_LIST_USER_COMPANY_LOADING: "GET_LIST_USER_COMPANY_LOADING",
    GET_LIST_USER_COMPANY_SUCCESS: "GET_LIST_USER_COMPANY_SUCCESS",

    FORWARD_MESSAGE: "FORWARD_MESSAGE",
    FORWARD_MESSAGE_SUCCESS: "FORWARD_MESSAGE_SUCCESS",
    FORWARD_MESSAGE_FAILED: "FORWARD_MESSAGE_FAILED",

    GET_LIST_CHANNEL_POPUP: "GET_LIST_CHANNEL_POPUP",
}