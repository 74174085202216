const actionTransaction = {
    GET_LIST_TRANSACTION: "GET_LIST_TRANSACTION",
    GET_LIST_TRANSACTION_LOADING: "GET_LIST_TRANSACTION_LOADING",
    GET_LIST_TRANSACTION_SUCCESS: "GET_LIST_TRANSACTION_SUCCESS",
    GET_LIST_TRANSACTION_FAILED: "GET_LIST_TRANSACTION_FAILED",

    GET_TRANSACTION_DETAIL: "GET_TRANSACTION_DETAIL",
    GET_TRANSACTION_DETAIL_LOADING: "GET_TRANSACTION_DETAIL_LOADING",
    GET_TRANSACTION_DETAIL_SUCCESS: "GET_TRANSACTION_DETAIL_SUCCESS",
    GET_TRANSACTION_DETAIL_FAILED: "GET_TRANSACTION_DETAIL_FAILED",

    APPROVE_TRANSACTION: "APPROVE_TRANSACTION",
    APPROVE_TRANSACTION_LOADING: "APPROVE_TRANSACTION_LOADING",
    APPROVE_TRANSACTION_SUCCESS: "APPROVE_TRANSACTION_SUCCESS",
    APPROVE_TRANSACTION_FAILED: "APPROVE_TRANSACTION_FAILED",

    REJECT_TRANSACTION: "REJECT_TRANSACTION",
    REJECT_TRANSACTION_LOADING: "REJECT_TRANSACTION_LOADING",
    REJECT_TRANSACTION_SUCCESS: "REJECT_TRANSACTION_SUCCESS",
    REJECT_TRANSACTION_FAILED: "REJECT_TRANSACTION_FAILED",

    UPDATE_TRANSACTION_DETAIL: "UPDATE_TRANSACTION_DETAIL",
    UPDATE_TRANSACTION_DETAIL_LOADING: "UPDATE_TRANSACTION_DETAIL_LOADING",
    UPDATE_TRANSACTION_DETAIL_SUCCESS: "UPDATE_TRANSACTION_DETAIL_SUCCESS",
    UPDATE_TRANSACTION_DETAIL_FAILED: "UPDATE_TRANSACTION_DETAIL_FAILED",

    DELETE_TRANSACTION_DETAIL: "DELETE_TRANSACTION_DETAIL",
    DELETE_TRANSACTION_DETAIL_LOADING: "DELETE_TRANSACTION_DETAIL_LOADING",
    DELETE_TRANSACTION_DETAIL_SUCCESS: "DELETE_TRANSACTION_DETAIL_SUCCESS",
    DELETE_TRANSACTION_DETAIL_FAILED: "DELETE_TRANSACTION_DETAIL_FAILED",

    CREATE_TRANSACTION_DETAIL: "CREATE_TRANSACTION_DETAIL",
    CREATE_TRANSACTION_DETAIL_LOADING: "CREATE_TRANSACTION_DETAIL_LOADING",
    CREATE_TRANSACTION_DETAIL_SUCCESS: "CREATE_TRANSACTION_DETAIL_SUCCESS",
    CREATE_TRANSACTION_DETAIL_FAILED: "CREATE_TRANSACTION_DETAIL_FAILED",

    GET_HOLON_INSTANCE_ORDER_BY_CODE: "GET_HOLON_INSTANCE_ORDER_BY_CODE",
    GET_HOLON_INSTANCE_WALLET_BY_CODE: "GET_HOLON_INSTANCE_WALLET_BY_CODE",
}

export default actionTransaction;