import useTrans from 'hooks/use-trans'
import React from 'react'
import WarehouseOutContainer from 'shared/container/warehousein/WarehouseOutContainer';

function WarehouseOutPage() {
    const { t } = useTrans();

    document.title = t("warehouse_out.confirmation_of_shipping")
    return (
        <WarehouseOutContainer />
    )
}

export default WarehouseOutPage