import IconCheck from 'assets/icon/IconCheck'
import IconChecked from 'assets/icon/IconChecked'
import moment from 'moment'
import React from 'react'
import Constants from 'utils/Constants'
import { convertNumberToString } from 'utils/String'
import { convertRegexToOptions } from 'utils/Utils'
import classes from './CommonValueAttributes.module.scss';
import BoxTemplate from 'shared/components/common/box/BoxTemplate'
import ResizeImage from 'shared/components/message/content-message/ResizeImage'
import ButtonLink from '../button/ButtonLink'

function CommonValueAttributes(props) {
    const { x } = props;
    const renderValueAttributes = (x) => {
        if (x?.elementType == Constants.ELEMENT_TYPE.PASSWORD.VALUE) {
            return "*******"
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
            return x?.value ? moment(x?.value).format(Constants.FORMAT_DATE_TIME.DATE_TIME) : <></>
        }

        if (x?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
            if (x?.value && moment(x?.value)?._isValid) {
                return x?.value ? moment(x?.value).format(Constants.FORMAT_DATE_TIME.DATE) : <></>
            }
            return "";
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
            if (x.description && !x.regex) {
                return x.description;
            }
            let data = convertRegexToOptions(x?.regex);
            return data?.find(item => item?.value == x?.value)?.label
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.RADIO.VALUE) {
            let data = convertRegexToOptions(x?.regex);
            return data?.find(item => item?.value == x?.value)?.label
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.YEAR_MONTH_PICKER.VALUE) {
            return x?.value ? moment(x?.value).format("MM/YYYY") : ''
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.PERCENT.VALUE) {
            return `${convertNumberToString(x?.value)}%`
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_INSTANCE.VALUE) {
            return <BoxTemplate data={x?.value?.split(",")} />
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
            return <BoxTemplate data={x?.value?.split(",")} />
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.NUMBER.VALUE) {
            return `${x?.value ? convertNumberToString(x?.value) : ''}`
        }

        if (x?.elementType == Constants.ELEMENT_TYPE.RICH_TEXT.VALUE) {
            return <span dangerouslySetInnerHTML={{
                __html: x?.value
            }} />
        }

        if (x?.elementType == Constants.ELEMENT_TYPE.MONEY.VALUE) {
            return `${x?.value ? convertNumberToString(x?.value) : ''}`
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.URL.VALUE) {
            return <ButtonLink isHref>{x?.value}</ButtonLink>
        }

        if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
            let options = convertRegexToOptions(x?.regex);
            return <div className={classes['list-checkbox']}>
                {
                    options?.map((option, key) => {
                        if (x?.value?.split(",")?.findIndex(item => item == option?.value) != -1) {
                            return <div className={classes['content-checkbox']} key={key}>
                                <IconChecked />
                                <span>{option?.label}</span>
                            </div>
                        } else {
                            return <div className={classes['content-checkbox']} key={key}>
                                <IconCheck />
                                <span>{option?.label}</span>
                            </div>
                        }
                    })
                }
            </div>
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.IMAGE.VALUE) {
            return x?.value ? <ResizeImage url={x?.value} isUrl={false} maxWidth={200} maxHeight={150} /> : <></>
        }
        if (x?.elementType == Constants.ELEMENT_TYPE.TEXT_AREA.VALUE) {
            return <div style={{
                whiteSpace: 'pre-line'
            }}>{x?.value}</div>
        }

        return x?.value;
    }
    return (
        <>{renderValueAttributes(x)}</>
    )
}

export default CommonValueAttributes