import React, { useEffect, useState } from "react";
import useTrans from "hooks/use-trans";
import classes from "./CancelOrderPopup.module.scss";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import Button from 'shared/components/form-input/button/Button';
import FormTextArea from "shared/components/custom-form/FormTextArea";
import Validator from 'utils/Validator';
import { useDispatch } from "react-redux";
import actionOrder from 'redux/order/action';
import { showPopupNotification } from "utils/Utils";
import Loading from "shared/components/form-input/button/loading/Loading";
import IconLeftArrow from "assets/icon/IconLeftArrow";

const CancelOrderPopup = ({ payload, showVisible }) => {
    const methods = useForm();
    const { t } = useTrans();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [isValidStatus, setIsValidStatus] = useState(false)

    const onSubmit = (data) => {
        setIsLoading(true);
        dispatch({
            type: actionOrder.CANCEL_ORDER,
            payload: {
                orderId: payload['0'],
                data: {
                    cancellationReason: data.order_management.cancel_action.cancellation_reason
                }
            },
            callback: (res) => {
                payload.callback && payload.callback();
                setIsLoading(false);
                showVisible(false)
                if (res?.message) {
                    showPopupNotification({
                        typePopup: 'message',
                        message: t(res?.message),
                        newWindow: true
                    })
                } else {
                    showPopupNotification({
                        typePopup: 'message',
                        message: Validator.renderMessage(t("I0004"), {
                            0: 'order'
                        }),
                        newWindow: true
                    })
                }
            },
            fallback: (error) => {
                setIsLoading(false);
                let msg = "";
                Object.keys(error).forEach((key) => {
                    msg += error[key]?.[0];
                })
                showVisible(false)
                showPopupNotification({
                    typePopup: 'message',
                    message: msg,
                    newWindow: true
                })
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: actionOrder.CHECK_ORDER_STATUS,
            payload: payload['0'],
            callback: (data) => {
                setIsValidStatus(true)
            },
            fallback: (error) => {
                let msg = "";
                Object.keys(error).forEach((key) => {
                    msg += error[key]?.[0];
                })
                showVisible(false)
                showPopupNotification({
                    typePopup: 'message',
                    message: msg,
                    newWindow: true
                })
            }
        })
    }, []);

    const handleClose = () => {
        showVisible(false)
    }

    return (
        <>
            <div className={classes["cancel-order-container"]}>
                {isValidStatus ?
                    <CustomFormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes["form-container"]}>
                            <div className={classes["form-header"]}>
                                <Button className="button" color="gray" onClick={() => handleClose()} startIcon={<IconLeftArrow fontSize={20} color="#333333" />} />
                                <div>{t('order_management.cancel_action.reason_label')}</div>
                                <Button color="transparent" disabled={true} />
                            </div>
                            <div className={classes["form-text-area"]}>
                                <Box >
                                    <FormTextArea validationFE validate={[Validator.required]} fieldName={`order_management.cancel_action.cancellation_reason`} />
                                </Box>
                            </div>
                            <Box >
                                <div className={classes["btn-form"]}>
                                    <Button
                                        type="submit"
                                        loading={isLoading}
                                    >
                                        {t('order_management.cancel_action.btn_cancellation_request')}
                                    </Button>
                                </div>
                            </Box>
                        </form>
                    </CustomFormProvider>
                    :
                    <div className={classes["loading-container"]}>
                        <Loading />
                    </div>
                }
            </div>
        </>
    );
}

export default CancelOrderPopup;