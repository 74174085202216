import i18n from "i18nextInit"

export default class Validator {
    static genValidate = (validate, fieldName) => {
        let _validate = {}
        validate.forEach((e, i) => {
            _validate[`${fieldName}_${i}`] = e
        })
        return _validate
    }

    static renderMessage = (msg, param) => {
        Object.keys(param).forEach(key => {
            msg = msg?.replace(`{${key}}`, param[key]);
        });

        return msg;
    }

    static required(value) {
        return value ? undefined : i18n.t("E0001");
    }
    static duplicate = (data) => (value) => {
        const keyData = data.map((item) => item?.key);
        let count = 0;
        let maxCount = 1
        for (let i = 0; i < keyData.length; i++) {
            if (keyData[i] === value) {
                count++;
                if (count > maxCount) {
                    return  i18n.t("E0069");
                }
            }
        }
        return undefined;
    }
    static requiredImage(value) {
        return value ? undefined : i18n.t("E0011");
    }
    static requiredLogin(value) {
        return value ? undefined : i18n.t("E0001");
    }
    static requiredInput(value) {
        return value ? undefined : i18n.t("holon.E0001");
    }
    static requiredSelectTemplate(value) {
        return value != "" && value && value?.length > 0 ? undefined : i18n.t("E0038");
    }
    static requiredCheckbox(value) {
        return value ? undefined : i18n.t("E0037");
    }

    static maxLength = (max) => (value) =>
        value && value.length > max ? Validator.renderMessage(i18n.t("E0006"), { 1: max }) : undefined;

    static maxLengthInput = (max) => (value) =>
        value && value.length > max ? Validator.renderMessage(i18n.t("holon.E0006"), { 1: max }) : undefined;

    static maxLengthArea = (max) => (value) =>
        value && value.length > max ? i18n.t("E0006") : undefined;

    static regex = (regex, msg = "Error", isRequired = true) => (value) => {
        try {
            if (!regex) {
                return undefined;
            }
            if (!value && !isRequired) {
                return undefined;
            }

            regex = new RegExp(regex);
            return regex.test(value) ? undefined : msg;
        } catch (error) {
            console.log(error);
            return undefined;
        }
    }

    static number = (value) =>
        !/^[0-9]*$/i.test(value) && value != null
            ? i18n.t('E0071')
            : undefined
    static emailFormat = (value) =>
        value && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(value)
            ? i18n.t('E0003')
            : undefined
    static email = (value) =>
        value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(value)
            ? i18n.t('E0003')
            : undefined
    static minValue = (min) => (value) =>
        value && value < min ? `Must be at least ${min}` : undefined

    static minLength = (min) => (value) =>
        value && value.length < min
            ? `Tên đăng nhập tối thiểu 0${min} ký tự`
            : undefined

    static phone = (value) =>
        value && !/^\d{2}(?:\d{4}\d{4}|\d{8}|\d\d{3,4}\d{4})$/.test(value) ? i18n.t('E0014') : undefined

    static password = (value) =>
        !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,32}$/i.test(value) && value != null
            ? i18n.t("E0016")
            : undefined

    static fullNameFuri = (value) =>
        !/^[ァ-ヶー]{1,40}$/i.test(value) && value != null
            ? i18n.t("E0001")
            : undefined

    static confirmPassword = (pass) => (value) => {
        return value != pass ? i18n.t("E0007") : undefined
    }

    static customValidator = (regex, mes) => (value) => {
        if (regex && value) {
            let newRegex = new RegExp(regex);
            return !newRegex.test(value)
                ? mes
                : undefined
        }
        return undefined;
    }

    static URL = (value) => {
        return !/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/i.test(value) ?
            i18n.t("E0018") : undefined
    }

    static stringKey = (value) => {
        // return !/^\S*$/i.test(value) ? i18n.t("holon.E0002") : undefined
        return !/^[a-zA-Z0-9_]+$/i.test(value) ? i18n.t("holon.E0002") : undefined
    }
}
