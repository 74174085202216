import InquiryAction from "./action";

const initialState = {
    inquiryList: {
        records: [],
        limit: 0,
        page: 1,
        total: 0,
        totalPage: 1,
    },
    inquiryDetail: {},
    salePersonList: [],
    managerList: [],
    loading: {
        loadingGetInquiryList: false,
        loadingGetInquiryDetail: false,
        loadingUpsertInquiry: false,
        loadingDeleteInquiry: false,
        loadingCreateUserInquiry: false
    },
};

const InquiryReducer = (state = initialState, action) => {
    switch (action.type) {
        case InquiryAction.GET_INQUIRY_LIST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetInquiryList: true,
                },
            };
        case InquiryAction.GET_INQUIRY_LIST_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetInquiryList: false,
                },
                inquiryList: action.payload,
            };
        case InquiryAction.GET_INQUIRY_LIST_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingGetInquiryList: false,
                },
            };
        //
        case InquiryAction.GET_INQUIRY_DETAIL:
            return {
                ...state,
                inquiryDetail: {},
                loading: {
                    ...state.loading,
                    loadingGetInquiryDetail: true,
                },
            };
        case InquiryAction.GET_INQUIRY_DETAIL_SUCCESS:
            return {
                ...state,
                inquiryDetail: action.payload,
                loading: {
                    ...state.loading,
                    loadingGetInquiryDetail: false,
                },
            };
        case InquiryAction.GET_INQUIRY_DETAIL_FAILED:
            return {
                ...state,
                inquiryDetail: {},
                loading: {
                    ...state.loading,
                    loadingGetInquiryDetail: false,
                },
            };
        //
        case InquiryAction.INSERT_INQUIRY:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpsertInquiry: true,
                },
            };
        case InquiryAction.INSERT_INQUIRY_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpsertInquiry: false,
                },
            };
        case InquiryAction.INSERT_INQUIRY_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpsertInquiry: false,
                },
            };
        //
        case InquiryAction.UPDATE_INQUIRY:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpsertInquiry: true,
                },
            };
        case InquiryAction.UPDATE_INQUIRY_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpsertInquiry: false,
                },
            };
        case InquiryAction.UPDATE_INQUIRY_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingUpsertInquiry: false,
                },
            };
        //
        case InquiryAction.DELETE_INQUIRY:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteInquiry: true,
                },
            };
        case InquiryAction.DELETE_INQUIRY_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteInquiry: false,
                },
            };
        case InquiryAction.DELETE_INQUIRY_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingDeleteInquiry: false,
                },
            };
        case InquiryAction.CREATE_USER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateUserInquiry: true,
                },
            };
        case InquiryAction.CREATE_USER_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateUserInquiry: false,
                },
            };
        case InquiryAction.CREATE_USER_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    loadingCreateUserInquiry: false,
                },
            };
        default:
            return { ...state };
    }
};

export default InquiryReducer;
