import React, { useEffect, useState } from 'react';
import classes from './PreviewFile.module.scss';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import Box from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { getCoreAPI } from '../../Util';
import ResizeImage from 'shared/components/message/content-message/ResizeImage';
import { DetectMimeType } from './DetectMimeType';
import Loading from 'shared/components/form-input/button/loading/Loading';
import EventRegister, { EVENT_SHOW_LIST_TEMPLATE_OCR_POPUP, EVENT_SHOW_POPUP } from 'utils/EventRegister';
import CreatedHolonInstancePopup from '../holon-instance-popup/CreatedHolonInstancePopup';
import { replaceCondenseFilename, showPopupNotification } from 'utils/Utils';

function condenseFilename(initial) {
    var split = initial.split('.');
    var filename = split[0];
    var extension = split[1];
    if (filename.length > 80) {
        return filename.substring(0, 75) + '...' + extension;
    }
    return initial;
}

function nameFile(_fileName) {
    return <div title={_fileName} className={classes['custom-name-file']}>
        {condenseFilename(_fileName)}
    </div>
}

function PreviewFilePopup(props) {
    const {
        payload,
        showVisible
    } = props;

    let linkFile = encodeURIComponent(payload?.linkFile);
    const [typeFile, setTypeFile] = useState(null)
    const [loadingFile, setLoadingFile] = useState(true)
    const [loadingPrint, setLoadingPrint] = useState(false)
    const [openHolonInstance, setOpenHolonInstance] = useState(false)
    const [holonTemplateId, setHolonTemplateId] = useState(null)
    const [dataOcr, setDataOcr] = useState(null)
    const { t } = useTrans();
    const getTypeAttachmentLink = (filePath) => {
        let fileExtension = "";
        if (filePath) {
            fileExtension = filePath.substring(filePath.lastIndexOf('.') + 1)
        }
        return fileExtension;
    }

    let fileName = payload?.linkFileName;
    const getNameFile = (url) => {
        var fileName = url.substring(url.lastIndexOf('/') + 1);
        return fileName;
    }

    async function getExtension() {
        fetch(`${getCoreAPI()}/storage/download?url=${payload?.linkFile}`).then(async x => {
            let file = await x.blob();
            if (file) {
                var reader = new FileReader();
                reader.onloadend = function (e) {
                    setTypeFile(DetectMimeType(e.target.result));
                    setLoadingFile(false)
                }
                reader.readAsDataURL(file);
            }
        });
    }

    useEffect(() => {
        if (payload?.linkFile) {
            getExtension();
        }
    }, [payload?.linkFile])

    const renderFileAttachmentLink = (type) => {
        switch (type?.toUpperCase()) {
            case "ZIP":
                return <div className={classes['file-cannot-viewer']}>
                    <p>{t("message.file_not_preview")}</p>
                </div>
            case "DOCX":
                const docs = [
                    {
                        uri: payload?.linkFile,
                        fileType: 'docx',
                        fileName: getNameFile(payload?.linkFile)
                    }
                ];
                return <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />;
            case "DOC":
                const doc = [
                    {
                        uri: payload?.linkFile,
                        fileType: 'doc',
                        fileName: getNameFile(payload?.linkFile)
                    }
                ];
                return <DocViewer documents={doc} pluginRenderers={DocViewerRenderers} />;
            case "ODT":
                const odt = [
                    {
                        uri: payload?.linkFile,
                        fileType: 'odt',
                        fileName: getNameFile(payload?.linkFile)
                    }
                ];
                return <DocViewer documents={odt} pluginRenderers={DocViewerRenderers} />;
            case "XLSX":
                const xlsx = [
                    {
                        uri: payload?.linkFile,
                        fileType: 'xlsx',
                        fileName: getNameFile(payload?.linkFile)
                    }
                ];
                return <DocViewer documents={xlsx} pluginRenderers={DocViewerRenderers} />;
            case "XLS":
                const xls = [
                    {
                        uri: payload?.linkFile,
                        fileType: 'xls',
                        fileName: getNameFile(payload?.linkFile)
                    }
                ];
                return <DocViewer documents={xls} pluginRenderers={DocViewerRenderers} />;
            case "PPT":
                const ppt = [
                    {
                        uri: payload?.linkFile,
                        fileType: 'ppt',
                        fileName: getNameFile(payload?.linkFile)
                    }
                ];
                return <DocViewer documents={ppt} pluginRenderers={DocViewerRenderers} />;
            case "PPTX":
                const pptx = [
                    {
                        uri: payload?.linkFile,
                        fileType: 'pptx',
                        fileName: getNameFile(payload?.linkFile)
                    }
                ];
                return <DocViewer documents={pptx} pluginRenderers={DocViewerRenderers} />;
            case "PSD":
                return <div className={classes['file-cannot-viewer']}>
                    <p>{t("message.file_not_preview")}</p>
                </div>
            case "DXF":
                return <div className={classes['file-cannot-viewer']}>
                    <p>{t("message.file_not_preview")}</p>
                </div>
            case "CSV":
                return <div className={classes['file-cannot-viewer']}>
                    <p>{t("message.file_not_preview")}</p>
                </div>
            case "TXT":
                return <iframe id="pdfDocument" className={classes['file_PDF']} src={payload?.linkFile} title="">
                </iframe>
            case "RAR":
                return <div className={classes['file-cannot-viewer']}>
                    <p>{t("message.file_not_preview")}</p>
                </div>
            case "TAR":
                return <div className={classes['file-cannot-viewer']}>
                    <p>{t("message.file_not_preview")}</p>
                </div>
            case "GZIP":
                return <div className={classes['file-cannot-viewer']}>
                    <p>{t("message.file_not_preview")}</p>
                </div>
            case "JPEG":
            case "JPG":
            case "PNG":
            case "GIF":
            case "TIFF":
            case "BMP":
            case "WEBP":
            case "SVG":
                return <div className={classes['box-body']}>
                    <ResizeImage maxWidth={720} maxHeight={420} url={payload?.linkFile} />
                </div>
            case "PDF":
                return <>
                    <iframe src={`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(payload?.linkFile)}&embedded=true`} className={classes['file_PDF']} id="pdfFrame" name="pdfFrame">
                    </iframe>
                </>
            default:
                return null;
        }
    }

    const handlePrint = async () => {
        setLoadingPrint(true);
        await fetch(`${getCoreAPI()}/storage/download?url=${encodeURIComponent(payload?.linkFile)}`).then(x => {
            return x.blob();
        }).then(x => {
            setLoadingPrint(false);
            var blob = new Blob([x], { type: 'application/pdf' }); //this make the magic
            var blobURL = URL.createObjectURL(blob);

            let iframe = document.createElement('iframe'); //load content in an iframe to print later
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = blobURL;
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();

                }, 1);
            };
        });
    }

    const handleOcr = () => {
        if (["XLS", "PDF", "XLSX"]?.includes(getTypeAttachmentLink(payload?.linkFile)?.toUpperCase())) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_LIST_TEMPLATE_OCR_POPUP,
                newWindow: true,
                payload: {
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0
                    },
                    callback: {
                        loadDataFromTemplate: (data, dataOcr) => {
                            setOpenHolonInstance(true)
                            setHolonTemplateId(data);
                            let attributes = {};
                            dataOcr?.map(x => {
                                attributes = {
                                    ...attributes,
                                    ...x?.mapData
                                }
                            })
                            setDataOcr(attributes);
                        }
                    },
                    attachment: payload?.linkFile
                }
            })
        } else {
            showPopupNotification({
                typePopup: 'message',
                newWindow: true,
                message: t("message.error_ocr")
            });
        }
    }

    useEffect(() => {
        return () => {
            setOpenHolonInstance(false);
            setHolonTemplateId(null)
        }
    }, [])

    let dataOcrFile = [
        {
            name: replaceCondenseFilename(payload?.linkFile),
            content: payload?.linkFile,
            type: "",
        }
    ]
    return (
        <div className={classes['layout-form']}>
            {
                openHolonInstance && <CreatedHolonInstancePopup showVisible={() => setOpenHolonInstance(false)} payload={{
                    maxWidth: '648px',
                    minWidth: '45vw',
                    width: 'auto',
                    templateId: holonTemplateId,
                    title: t("holon.create_holon_instance"),
                    dataBox: {
                        callback: () => {
                            setOpenHolonInstance(false);
                        }
                    },
                    dataOcr: {
                        attributes: {
                            ...dataOcr
                        }, attachments: [...dataOcrFile]
                    },
                    style: {
                        height: "calc(100vh - 210px)",
                        justifyContent: 'space-between'

                    }
                }} />
            }
            <BoxContentPopup maxWidth={openHolonInstance ? "648px" : '800px'} minWidth={openHolonInstance ? "45vw" : '800px'} width='auto'>
                <WrapContentBody
                    showVisible={showVisible}
                    title={t("message.file_preview")}
                    maxHeight="unset"
                    actionSectionBottom={<div style={{ display: 'flex', gap: 18 }}>
                        <Button disabled={loadingPrint || loadingFile} color='white'>
                            <a style={{
                                textDecoration: 'none',
                                color: '#333333'
                            }} href={`${getCoreAPI()}/storage/download?url=${encodeURIComponent(payload?.linkFile)}`} download={payload?.linkFile} rel="noreferrer">
                                {t("message.download")}
                            </a>
                        </Button>
                        {
                            (getTypeAttachmentLink(payload?.linkFile).toUpperCase() == "PDF" || typeFile == "pdf") && <Button disabled={loadingFile} loading={loadingPrint} color='white' title={t("message.print")} onClick={() => handlePrint(payload?.linkFile)} />
                        }
                        {
                            payload?.isMessage && !openHolonInstance && <Button onClick={handleOcr} color='white' title={t("message.ocr")} />
                        }
                    </div>}
                >
                    <div id='form' className={classes['preview-content']}>
                        <div className={classes['content']}>
                            <Box style={{
                                height: '100%'
                            }}>
                                <BoxHeader>
                                    <BoxTitle>{nameFile(fileName)}</BoxTitle>
                                </BoxHeader>
                                {payload?.linkFile && (
                                    renderFileAttachmentLink(getTypeAttachmentLink(payload?.linkFile)) ?? <>
                                        {loadingFile ?
                                            <div className={classes['loading-lsd']}
                                            ><Loading /></div> : <></>}
                                        {renderFileAttachmentLink(typeFile)}
                                    </>

                                )}
                            </Box>
                        </div>
                    </div>
                </WrapContentBody>
            </BoxContentPopup>
        </div>
    )
}

export default PreviewFilePopup