import React from 'react';
import classes from './Box.module.scss';

function Box(props) {
    return (
        <div className={`${classes['Custom_Box']} Custom_Box ${props?.className}`}>
            {props?.children}
        </div>
    );
}

export default Box;
