import { useEffect } from 'react';
import HomeContainer from 'shared/container/home/HomeContainer';
import Constants from 'utils/Constants';

function Home() {
    useEffect(() => {
        document.title = Constants.TITLE_PAGE;
    }, [])

    return <HomeContainer />;
}

export default Home;