/* eslint-disable react/react-in-jsx-scope */
import IconCopy from 'assets/icon/IconCopy';
import IconEdit from 'assets/icon/IconEdit';
import useRouter from 'hooks/use-router';
import useTrans from 'hooks/use-trans';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import Button from 'shared/components/form-input/button/Button';
import Constants from 'utils/Constants';
import EventRegister, { EVENT_SHOW_COPY_DATA_POPUP, EVENT_SHOW_HISTORY_DETAIL_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ADD_INSTANCE, EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE, EVENT_SHOW_POPUP_UPDATE_HOLON_INSTANCE, EVENT_SHOW_PREVIEW_FILE_POPUP, G0100, G1511_ACTION2 } from 'utils/EventRegister';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxContent from '../../box/BoxContent';
import BoxHeader from '../../box/BoxHeader';
import BoxLabel from '../../box/BoxLabel';
import BoxRow from '../../box/BoxRow';
import BoxTitle from '../../box/BoxTitle';
import WrapContentBody from '../custom-body/WrapContentBody';
import classes from './HolonInstanDetail.module.scss';
import { ReloadActionWithScreen, getMessageCommon, showPopupNotification } from 'utils/Utils';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import jsonPath from 'jsonpath';
import EventPopup from 'utils/EventPopup';
import CommonValueAttributes from 'shared/components/form-input/common-value-attributes/CommonValueAttributes';
import BoxSection from '../../box/BoxSection';
import History from './History';
import Validator from 'utils/Validator';
import { useSelector } from 'react-redux';
import actionHolonTemplate from 'redux/holon-template/action';
import { v4 as uuidv4 } from 'uuid'
import actionCargo from 'redux/cargo/action';

function HolonInstanceDetailPopup(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const router = useRouter();
    const dispatch = useDispatch();
    const [dataHolonInstanceAttribute, setHolonInstanceAttribute] = useState([])
    const [dataHolonInstanceActions, setHolonInstanceActions] = useState([])
    const [holonInStanceDetail, setHolonInStanceDetail] = useState({})
    const [holonTemplateDetail, setHolonTemplateDetail] = useState({})
    const [connections, setConnections] = useState([])
    const [attachment, setAttachment] = useState([])
    const [history, setHistory] = useState([])
    const [loadingHolonInstanceDetail, setLoadingHolonInstanceDetail] = useState(false)
    const [loadingDeleteHolonInstance, setLoadingDeleteHolonInstance] = useState(false)
    const [loadingCloneHolonInstance, setLoadingCloneHolonInstance] = useState(false)
    const [loadingAsyncPost, setLoadingAsyncPost] = useState(null);
    const { holonTemplateMaster } = useSelector(state => state?.holonTemplate);
    const {
        hoLonTemplateId
    } = useSelector(state => state?.holonInstance)

    const handleEdit = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_UPDATE_HOLON_INSTANCE,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                title: payload?.titleUpdatePopup ? payload?.titleUpdatePopup : t("holon.update_holon_instance"),
                titleDetailPopup: payload?.title ? payload?.title : t("holon.update_holon_instance"),
                dataBox: {
                    ...payload?.dataBox
                },
                holonInstanceId: payload?.holonInstanceId,
                holonTemplateId: payload?.holonTemplateId,
                subHolonTemplateId: payload?.subHolonTemplateId,
                isCargo: payload?.isCargo,
                permissionUpdate: payload?.permissionUpdate,
                permissionClone: payload?.permissionClone,
                userCompany: payload?.userCompany,
                requireConnectionKey: payload?.requireConnectionKey,
                requireConnectionHolonId: payload?.requireConnectionHolonId,
                ExcludeIds: payload?.ExcludeIds,
                listTemplateAll: payload?.listTemplateAll,
                cId: payload?.cId,
                cName: payload?.cName,
                isHolonTemplateName: payload?.isHolonTemplateName
            }
        })
    }

    const handleDelete = () => {
        showPopupNotification({
            callback: () => {
                setLoadingDeleteHolonInstance(true)
                if (payload?.dataBox?.boxDetail?.actionType) {
                    dispatch({
                        type: payload?.dataBox?.boxDetail?.actionType,
                        payload: {
                            id: holonInStanceDetail?.id,
                            mode: payload?.dataBox?.boxDetail?.actionPayload?.mode
                        },
                        callback: {
                            success: () => {
                                setLoadingDeleteHolonInstance(false)
                                payload?.dataBox?.callback?.callbackSuccess()
                                showVisible(false);
                                payload?.callbackFromConnection && payload?.callbackFromConnection();
                                showPopupNotification({
                                    callback: () => { },
                                    typePopup: 'message',
                                    newWindow: true,
                                    message: t("E0045")
                                })
                            },
                            failed: (msg) => {
                                setLoadingDeleteHolonInstance(false);
                                showPopupNotification({
                                    callback: () => { },
                                    typePopup: 'message',
                                    newWindow: true,
                                    message: msg
                                })
                            }
                        },
                    })
                } else {
                    dispatch({
                        type: actionsHolonInstance.DELETE_HOLON_INSTANCE,
                        payload: holonInStanceDetail?.id,
                        callback: {
                            success: () => {
                                setLoadingDeleteHolonInstance(false);
                                if (payload?.dataBox?.callback?.callbackSuccess) {
                                    payload?.dataBox?.callback?.callbackSuccess()
                                } else {
                                    let params = {};
                                    if (payload?.holonTemplateId && hoLonTemplateId != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                                        params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = payload?.holonTemplateId
                                    } else if (payload?.listTemplateAll) {
                                        params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] =
                                            payload?.listTemplateAll?.map(x => x?.id)?.join(",");
                                    }

                                    if (payload?.subHolonTemplateId) {
                                        params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = payload?.subHolonTemplateId
                                    }
                                    if (payload?.cId) {
                                        params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = payload?.cId
                                    }
                                    if (payload?.cName) {
                                        params[Constants.QueryParams.CONNECTION_NAME.VALUE] = payload?.cName
                                    }
                                    if (payload?.userCompany) {
                                        params[Constants.QueryParams.USER_IN_COMPANY.VALUE] = payload?.userCompany
                                    }
                                    if (payload?.requireConnectionKey) {
                                        params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = payload?.requireConnectionKey
                                    }
                                    if (payload?.requireConnectionHolonId) {
                                        params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = payload?.requireConnectionHolonId
                                    }
                                    if (payload?.ExcludeIds) {
                                        params[Constants.QueryParams.EXCLUDE_IDS.VALUE] = payload?.ExcludeIds
                                    }
                                    dispatch({
                                        type: actionsHolonInstance.GET_HOLON_INSTANCE,
                                        payload: {
                                            ...router.getAll(),
                                            ...params
                                        }
                                    });
                                }
                                showVisible(false);
                                payload?.callbackFromConnection && payload?.callbackFromConnection();
                                showPopupNotification({
                                    callback: () => { },
                                    typePopup: 'message',
                                    newWindow: false,
                                    message: t("E0045")
                                })
                            },
                            failed: (msg) => {
                                setLoadingDeleteHolonInstance(false);
                                showPopupNotification({
                                    callback: () => { },
                                    typePopup: 'message',
                                    newWindow: true,
                                    message: msg
                                })
                            }
                        }
                    })
                }
            },
            typePopup: 'delete',
            newWindow: true
        })
    }

    useEffect(() => {
        if (holonTemplateDetail?.validActions?.length > 0 && holonInStanceDetail?.id) {
            let data = [];
            holonTemplateDetail?.validActions?.map(x => {
                return x?.ShowInDetail == Constants.STATUS_CHECKBOX.YES.VALUE && data.push({
                    ...x,
                    IsEditabled: x?.IsEditabled,
                })
            })
            setHolonInstanceActions(data)
        }
    }, [holonTemplateDetail, holonInStanceDetail])

    useEffect(() => {
        if (holonTemplateDetail?.validAttributes?.length > 0 && holonInStanceDetail?.id) {
            setHolonInstanceAttribute(holonTemplateDetail?.validAttributes?.map(x => {
                let indexDetail = holonInStanceDetail?.attributes?.findIndex(attribute => attribute.name == x?.key);
                return {
                    ...x,
                    [x?.key]: holonInStanceDetail.attributes[indexDetail]?.value,
                    value: holonInStanceDetail.attributes[indexDetail]?.value,
                    name: holonInStanceDetail.attributes[indexDetail]?.name,
                    description: holonInStanceDetail.attributes[indexDetail]?.description,
                }
            }))
        }
    }, [holonTemplateDetail, holonInStanceDetail])

    useEffect(() => {
        if (payload?.holonInstanceId) {
            setLoadingHolonInstanceDetail(true)
            dispatch({
                type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                payload: payload?.holonInstanceId,
                callback: (data) => {
                    setHolonInStanceDetail(data);
                    setLoadingHolonInstanceDetail(false);
                }
            })
        }
        return () => {
            setHolonInStanceDetail({})
        }
    }, [payload?.holonInstanceId])

    useEffect(() => {
        if (payload?.holonTemplateId) {
            setLoadingHolonInstanceDetail(true)
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
                payload: payload?.holonTemplateId,
                callback: (data) => {
                    setHolonTemplateDetail(data);
                    setLoadingHolonInstanceDetail(false);
                }
            })
        }

        return () => {
            setHolonTemplateDetail({})
        }
    }, [payload?.holonTemplateId])

    useEffect(() => {
        dispatch({
            type: actionHolonTemplate.GET_ALL_HOLON_TEMPLATE
        })
    }, [])

    const onClearHolonInstanceDetail = () => {
        showVisible(false);
        setHolonInStanceDetail(null);
        setHolonTemplateDetail(null)
    }


    const handleCloneInstance = (id) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_COPY_DATA_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoadingCloneHolonInstance(true);
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                        payload: id,
                        callback: (data) => {
                            setLoadingCloneHolonInstance(false);
                            EventRegister.emit(EVENT_SHOW_POPUP, {
                                open: true,
                                type: EVENT_SHOW_POPUP_ADD_INSTANCE,
                                payload: {
                                    title: payload?.titleCreatePopup,
                                    showHeader: false,
                                    customStyle: {
                                        padding: 0,
                                        borderTop: 0
                                    },
                                    callback: () => {
                                        let params = {};

                                        if (payload?.holonTemplateId && hoLonTemplateId != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                                            params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = payload?.holonTemplateId
                                        } else if (payload?.listTemplateAll) {
                                            params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] =
                                                payload?.listTemplateAll?.map(x => x?.id)?.join(",");
                                        }

                                        if (payload?.subHolonTemplateId) {
                                            params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = payload?.subHolonTemplateId
                                        }
                                        if (payload?.cId) {
                                            params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = payload?.cId
                                        }
                                        if (payload?.cName) {
                                            params[Constants.QueryParams.CONNECTION_NAME.VALUE] = payload?.cName
                                        }
                                        if (payload?.userCompany) {
                                            params[Constants.QueryParams.USER_IN_COMPANY.VALUE] = payload?.userCompany
                                        }
                                        if (payload?.requireConnectionKey) {
                                            params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = payload?.requireConnectionKey
                                        }
                                        if (payload?.requireConnectionHolonId) {
                                            params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = payload?.requireConnectionHolonId
                                        }
                                        if (payload?.ExcludeIds) {
                                            params[Constants.QueryParams.EXCLUDE_IDS.VALUE] = payload?.ExcludeIds
                                        }
                                        dispatch({
                                            type: actionsHolonInstance.GET_HOLON_INSTANCE,
                                            payload: {
                                                ...router.getAll(),
                                                ...params
                                            }
                                        });
                                    },
                                    dataClone: data,
                                    templateId: holonTemplateDetail?.id,
                                    isCargo: payload?.isCargo
                                }
                            })
                        }
                    })
                }
            }
        })
    }

    const compareAttachments = (array) => {
        return array.sort(function (a, b) {
            return new Date(b.created) - new Date(a.created);
        });

    }
    useEffect(() => {
        if (holonInStanceDetail?.id && holonTemplateDetail?.id) {
            let dataAttachments = holonInStanceDetail?.attachments;
            setAttachment(compareAttachments(dataAttachments));
            let connections = holonInStanceDetail?.connections?.map(x => {
                return {
                    ...x,
                    holonsId: x?.holons?.length > 0 ? x?.holons[0]?.id : null,
                }
            });

            const grouped = connections.reduce((rv, x) => {
                const groupIndex = rv?.findIndex((a) => a.key === x['type']);
                if (groupIndex !== -1) {
                    if (x?.holons?.length > 0) {
                        x?.holons?.map(_data => {
                            rv[groupIndex]?.holons?.push(_data);
                        });
                    }
                } else {
                    const newGroup = {
                        key: x['type'],
                        ...x,
                        holons: [],
                    };
                    if (x?.holons?.length > 0) {
                        newGroup.holons = x?.holons;
                    }
                    rv.push(newGroup);
                }
                return rv;
            }, []);

            setConnections(holonTemplateDetail?.validConnections?.map(x => {
                let indexDetail = grouped?.findIndex(connection => connection.type == x?.key);
                return {
                    ...x,
                    holons: grouped[indexDetail]?.holons
                }
            }))

            let data = [...holonInStanceDetail?.historics]?.sort((a, b) => new Date(b?.created).getTime() - new Date(a?.created).getTime());

            let dataCurrent = []
            holonTemplateDetail?.validAttributes?.map(x => {
                let index = holonInStanceDetail?.attributes?.findIndex(v => v?.name == x?.key);
                let value = "";
                if (holonInStanceDetail.attributes[index]?.description && !x?.regex && x?.elementType == Constants.ELEMENT_TYPE.SELECT.VALUE) {
                    value = holonInStanceDetail.attributes[index]?.description;
                } else {
                    value = holonInStanceDetail.attributes[index]?.value;
                }

                if (index != -1) {
                    dataCurrent.push({
                        ...x,
                        [x?.key]: value,
                        value: value,
                        name: holonInStanceDetail.attributes[index]?.name,
                        description: holonInStanceDetail.attributes[index]?.description,
                    })
                }
            })

            data.unshift({
                name: '',
                details: JSON.stringify({
                    attributes: dataCurrent?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur?.value }), {}),
                    attachments: holonInStanceDetail?.attachments?.reduce((obj, cur) => ({ ...obj, [cur?.name]: cur?.content }), {}),
                    connections: holonTemplateDetail?.validConnections?.map(x => {
                        let indexDetail = grouped?.findIndex(connection => connection.type == x?.key);
                        return {
                            ...x,
                            holons: grouped[indexDetail]?.holons?.map(hl => {
                                return {
                                    ...hl,
                                    name: getConnectionName(hl)
                                }
                            })
                        }
                    })?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur?.holons }), {})
                })
            });

            data = data?.map(_x => {
                if (_x?.type == 'insert') {
                    return {
                        ..._x,
                        details: JSON.stringify({
                            attributes: holonTemplateDetail?.validAttributes?.map(x => {
                                let index = holonInStanceDetail?.attributes?.findIndex(v => v?.name == x?.key)
                                if (index != -1) {
                                    return {
                                        ...x,
                                        [x?.key]: holonInStanceDetail.attributes[index]?.value,
                                        value: "",
                                        name: holonInStanceDetail.attributes[index]?.name,
                                    }
                                }
                            })?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur?.value }), {}),
                            connections: holonTemplateDetail?.validConnections?.map(x => {
                                return {
                                    ...x,
                                    holons: []
                                }
                            })?.reduce((obj, cur) => ({ ...obj, [cur?.key]: cur?.holons }), {}),
                            attachments: {}
                        })
                    }
                }

                return _x;
            });
            data = data?.map((x, index) => {
                return {
                    ...x,
                    _index: index
                }
            })
            setHistory(data);
        }
    }, [holonTemplateDetail, holonInStanceDetail])

    const mappingConnection = (input) => {
        let empty = 0;
        input = input?.map(x => {
            var authors = jsonPath.query(holonInStanceDetail, x?.value);
            if (authors?.length == 0) {
                empty + 1;
                return null;
            }
            return {
                ...x,
                value: authors
            }
        })
        if (empty > 0) {
            return [];
        }
        return input;
    }

    const renderTitleBox = () => {
        if (payload?.isHolonTemplateName) {
            if (!holonTemplateDetail?.name) {
                return;
            }
            return holonTemplateDetail?.name;
        }
        if (payload?.dataBox?.boxDetail?.titleBoxDetail) {
            return payload?.dataBox?.boxDetail?.titleBoxDetail;
        }

        return payload?.title;
    }

    const handleOpenPreview = (x) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_PREVIEW_FILE_POPUP,
            newWindow: true,
            payload: {
                linkFile: x?.content,
                linkFileName: x?.name,
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
            }
        })
    }

    function condenseFilename(initial) {
        initial = initial?.split("/")?.[initial?.split("/")?.length - 1]
        var split = initial?.split('.');
        if (split?.length > 0) {
            let filename = split?.[0];
            var extension = initial?.split(".")[1];
            if (filename.length > 105) {
                return filename.substring(0, 100) + '...' + extension;
            }
        }
        return initial;
    }

    const handleOpenConnection = (value) => {
        const holonTemplate = holonTemplateMaster?.find(x => x?.id == value?.templateId);
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_DETAIL_HOLON_INSTANCE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                title: holonTemplate?.name,
                titleUpdatePopup: holonTemplate?.name,
                holonInstanceId: value?.id,
                holonTemplateId: value?.templateId,
                callbackFromConnection: () => {
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                        payload: payload?.holonInstanceId,
                        callback: (data) => {
                            setHolonInStanceDetail(data);
                            setLoadingHolonInstanceDetail(false);
                        }
                    })
                },
                permissionUpdate: payload?.permissionUpdate,
                permissionClone: payload?.permissionClone,
            }
        })
    }

    const getConnectionName = (holon) => {
        // var name = "";
        // if (holon?.attributes?.length > 0) {
        //     name = jsonPath.query(holon, "$.attributes[?(@.name == 'name')].value");
        // }
        return holon?.name;
    }

    const handleShowHistory = (index, list) => {

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_HISTORY_DETAIL_POPUP,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                data: list,
                index: index,
                holonTemplate: holonTemplateDetail,
                holonInstanceId: holonInStanceDetail?.id
            }
        })
    }

    let attribute_key = `attribute_${uuidv4()}`;
    let actions_key = `actions_${uuidv4()}`;
    let connections_key = `connections_${uuidv4()}`;
    let attachment_key = `attachment_${uuidv4()}`;

    let cardboard_type = holonInStanceDetail?.attributes?.find(x => x?.name == 'cardboard_type')?.value;

    const requestApiActionPopup = (x, stringPattern) => {
        dispatch({
            type: actionCargo.RETURN_REQUEST,
            payload: {
                name: JSON.parse(stringPattern)?.['0']
            },
            callback: {
                success: (res, error) => {
                    setLoadingAsyncPost(null);
                    if (res?.data?.isValid == Constants.STATUS_CHECKBOX.NO.VALUE) {
                        showPopupNotification({
                            typePopup: 'message',
                            message: getMessageCommon({
                                response: res,
                                error: error,
                                actionName: x?.note
                            }),
                            newWindow: true
                        });
                    } else {
                        handleShowPopup(x, stringPattern)
                    }
                }
            }
        });
    }

    const requestPackageTracking = (x, stringPattern) => {
        dispatch({
            type: actionsHolonInstance.GET_PACKAGE_TRACKING,
            payload: JSON.parse(stringPattern)?.id,
            callback: {
                success: (res) => {
                    setLoadingAsyncPost(null);
                    showPopupNotification({
                        type: x?.api,
                        typePopup: 'action_popup',
                        callback: () => { },
                        option: {
                            typePopup: x?.api,
                            data: x?.inputValue,
                            dataBody: JSON.parse(stringPattern),
                            actionName: x?.note,
                            step: G0100,
                            res: res,
                            list: [{
                                item: `${JSON.parse(stringPattern)?.id}`
                            }]
                        },
                        newWindow: true,
                        payload: JSON.parse(stringPattern)
                    })
                },
                failed: (msg) => {
                    setLoadingAsyncPost(null);
                    showPopupNotification({
                        message: msg,
                        typePopup: 'message',
                        newWindow: true
                    });
                }
            }
        });
    }

    const handleShowPopup = (x, stringPattern) => {
        showPopupNotification({
            type: x?.api,
            typePopup: 'action_popup',
            callback: () => {
                dispatch({
                    type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                    payload: payload?.holonInstanceId,
                    callback: (data) => {
                        setHolonInStanceDetail(data);
                        setLoadingHolonInstanceDetail(false);
                    }
                });

                if (Object.keys(EventPopup.popupList.refs)?.length == 1) {
                    let params = {};
                    if (payload?.holonTemplateId && hoLonTemplateId != Constants.SEARCH_CONFIG.MANAGEMENT_HOLON_TEMPLATE.ALL.VALUE) {
                        params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] = payload?.holonTemplateId
                    } else if (payload?.listTemplateAll) {
                        params[Constants.QueryParams.HOLON_TEMPLATE_ID.VALUE] =
                            payload?.listTemplateAll?.map(x => x?.id)?.join(",");
                    }
                    if (payload?.subHolonTemplateId) {
                        params[Constants.QueryParams.SUB_HOLON_TEMPLATE_ID.VALUE] = payload?.subHolonTemplateId
                    }
                    if (payload?.cId) {
                        params[Constants.QueryParams.CONNECTION_WIDTH_HOLON_ID.VALUE] = payload?.cId
                    }
                    if (payload?.cName) {
                        params[Constants.QueryParams.CONNECTION_NAME.VALUE] = payload?.cName
                    }
                    if (payload?.requireConnectionKey) {
                        params[Constants.QueryParams.REQUIRE_CONNECTION_KEY.VALUE] = payload?.requireConnectionKey
                    }
                    if (payload?.requireConnectionHolonId) {
                        params[Constants.QueryParams.REQUIRE_CONNECTION_HOLON_ID.VALUE] = payload?.requireConnectionHolonId
                    }
                    if (payload?.ExcludeIds) {
                        params[Constants.QueryParams.EXCLUDE_IDS.VALUE] = payload?.ExcludeIds
                    }
                    dispatch({
                        type: actionsHolonInstance.GET_HOLON_INSTANCE,
                        payload: {
                            ...router.getAll(),
                            ...params
                        }
                    });
                }

            },
            option: {
                typePopup: x?.api,
                data: x?.inputValue,
                dataBody: JSON.parse(stringPattern),
                actionName: x?.note
            },
            newWindow: true,
            payload: JSON.parse(stringPattern)
        })
    }
    return (
        <BoxContentPopup borderRadius='20px 0px 0px 20px' width='1000px'>
            <WrapContentBody
                title={renderTitleBox()}
                handleBtnBack={onClearHolonInstanceDetail}
                actionSectionTop={<>

                    {
                        payload?.permissionUpdate ? <Button onClick={handleEdit} color='gray' startIcon={<IconEdit color="currentColor" />} /> : <></>
                    }
                    {
                        payload?.permissionClone ? <Button loading={loadingCloneHolonInstance} onClick={() => handleCloneInstance(payload?.holonInstanceId)} color='gray' startIcon={<IconCopy color="currentColor" />} /> : <></>
                    }
                </>}
                actionSectionBottom={payload?.permissionDelete ? <Button color='gray' loading={loadingDeleteHolonInstance}
                    onClick={handleDelete}
                    title={t("Holon_Template.delete")}
                /> : <></>}
                showVisible={showVisible}
                loadingBtnBottom={loadingDeleteHolonInstance}
                handleBtnBottom={handleDelete}
                isUseBtnBottom={payload?.isShowOption == false ? false : true}
                isUseActionSectionTop={payload?.isShowOption == false ? false : true}
            >
                <Box>
                    <BoxHeader id={attribute_key}>
                        <BoxTitle>{t("holon.basic_info")}</BoxTitle>
                    </BoxHeader>
                    <BoxBody style={{ gap: 0 }} padding="14px 0px" id={attribute_key}>
                        {
                            dataHolonInstanceAttribute?.map((x, index) => {
                                return <BoxRow style={{
                                    padding: '5px 19px',
                                    background: index % 2 == 0 ? 'white' : '#F2F2F2'
                                }} key={index}>
                                    <BoxLabel>
                                        {x?.label}
                                    </BoxLabel>
                                    <BoxContent>
                                        <CommonValueAttributes x={x} />
                                    </BoxContent>
                                </BoxRow>
                            })
                        }
                    </BoxBody>
                </Box>
                {
                    dataHolonInstanceActions?.length ? <Box>
                        <BoxHeader id={actions_key}>
                            <BoxTitle>{t("Holon_Template.action")}</BoxTitle>
                        </BoxHeader>
                        <BoxBody id={actions_key}>
                            {/* check $ jsonPath <> data POST */}
                            <div className={classes['viewer-actions']}>
                                {dataHolonInstanceActions?.map((x, index) => {
                                    let data = x?.inputValue?.trim();
                                    x.inputValue = x?.inputValue?.trim();
                                    x.note = x?.note?.trim();
                                    let stringApi = x?.api?.trim();
                                    if (stringApi) {
                                        if (x?.inputValue) {
                                            try {
                                                let stringPattern = x?.inputValue;
                                                let dataBody = [];
                                                let arrInput = [];
                                                let arrInputJsonPath = [];
                                                let arrInputParams = [];

                                                const pattern = new RegExp(/"(\$)([^"\s]*)"/gm);
                                                let listJson = stringPattern.match(pattern);
                                                listJson?.forEach(x => {
                                                    let data = mappingConnection([
                                                        {
                                                            value: x?.replace(/"/g, '')
                                                        }
                                                    ]);
                                                    stringPattern = stringPattern.replace(x, `"${data[0]?.value[0]}"`);
                                                });
                                                if (stringApi?.indexOf("/") != -1) {
                                                    data = JSON.parse(data);
                                                    arrInput = Object.keys(data).map(key => {
                                                        return {
                                                            key: key,
                                                            value: data[key]
                                                        }
                                                    });

                                                }

                                                [...arrInput]?.forEach(xx => {
                                                    if (xx?.value?.indexOf("$.") != -1) {
                                                        arrInputJsonPath.push(xx);
                                                    }
                                                });

                                                [...arrInput]?.forEach(xx => {
                                                    if (!xx?.value?.indexOf("$.") != -1) {
                                                        arrInputParams.push(xx)
                                                    }
                                                });

                                                [...arrInput]?.forEach(xx => {
                                                    if (xx?.value?.indexOf("$.") == -1) {
                                                        dataBody.push(xx)
                                                    }
                                                })

                                                if (arrInputJsonPath?.length > 0) {
                                                    if (mappingConnection(arrInputJsonPath)?.length > 0) {
                                                        mappingConnection(arrInputJsonPath)?.map(xx => {
                                                            if (stringApi?.indexOf(xx?.key) != -1) {
                                                                stringApi = stringApi?.replace(`{${xx?.key}}`, xx?.value)
                                                            } else {
                                                                dataBody.push({
                                                                    ...xx,
                                                                    value: xx?.value?.[0]
                                                                })
                                                            }
                                                        });

                                                    } else {
                                                        return <Button key={index} variant='outlined' disabled title={x?.note} />
                                                    }
                                                } else {
                                                    arrInputParams?.map(xx => {
                                                        if (stringApi?.indexOf(xx?.key) != -1) {
                                                            stringApi = stringApi?.replace(`{${xx?.key}}`, xx?.value)
                                                        } else {
                                                            dataBody.push({
                                                                ...xx,
                                                                value: xx?.value
                                                            })
                                                        }
                                                    });
                                                }
                                                if (x?.type == Constants.TYPE_ACTIONS.REDIRECT.VALUE) {
                                                    return <a style={{
                                                        pointerEvents: x?.IsEditabled == "0" ? 'none' : 'unset',
                                                        backgroundColor: 'white',
                                                        opacity: loadingAsyncPost && loadingAsyncPost != index ? 0.7 : 1
                                                    }} key={index} className={classes['custom-button']} target="_blank" href={stringApi} rel="noreferrer">{x?.note}</a>
                                                }

                                                if (x?.type == Constants.TYPE_ACTIONS.POPUP.VALUE) {
                                                    return <Button
                                                        disabled={x?.IsEditabled == "0" ||
                                                            (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false}
                                                        loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                            if (x?.api == G1511_ACTION2) {
                                                                setLoadingAsyncPost(index);
                                                                requestApiActionPopup(x, stringPattern)
                                                            }
                                                            else if (x?.api == G0100) {
                                                                setLoadingAsyncPost(index);
                                                                requestPackageTracking(x, stringPattern)
                                                            } else {
                                                                handleShowPopup(x, stringPattern);
                                                            }
                                                        }}
                                                        title={x?.note}
                                                    />;
                                                }

                                                if (x?.type == Constants.TYPE_ACTIONS.ASYNCPOST.VALUE) {
                                                    return <Button loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                        showPopupNotification({
                                                            typePopup: 'confirm',
                                                            newWindow: true,
                                                            message: Validator.renderMessage(t("confirm_action"), {
                                                                0: x?.note
                                                            }),
                                                            callback: () => {
                                                                setLoadingAsyncPost(index)
                                                                dispatch({
                                                                    type: actionsHolonInstance.REQUEST_API_WITH_URL,
                                                                    payload: {
                                                                        url: stringApi,
                                                                        method: 'POST',
                                                                        data: JSON.parse(stringPattern)
                                                                    },
                                                                    callback: {
                                                                        success: (res) => {
                                                                            setLoadingAsyncPost(null);

                                                                            if (res?.data?.reload == "1") {
                                                                                dispatch({
                                                                                    type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                                                                                    payload: payload?.holonInstanceId,
                                                                                    callback: (data) => {
                                                                                        setHolonInStanceDetail(data);
                                                                                        setLoadingHolonInstanceDetail(false);
                                                                                    }
                                                                                })
                                                                            }
                                                                            if (res?.data?.screenId) {
                                                                                ReloadActionWithScreen({
                                                                                    screenId: res?.data?.screenId,
                                                                                    id: holonInStanceDetail?.name,
                                                                                    router: router
                                                                                })
                                                                            }
                                                                            showPopupNotification({
                                                                                typePopup: 'message',
                                                                                message: getMessageCommon({
                                                                                    response: res,
                                                                                    error: null,
                                                                                    actionName: x?.note
                                                                                }),
                                                                                newWindow: true
                                                                            });
                                                                        },
                                                                        failed: (msg) => {
                                                                            setLoadingAsyncPost(null);
                                                                            showPopupNotification({
                                                                                typePopup: 'message',
                                                                                message: msg,
                                                                                newWindow: true
                                                                            })
                                                                        },
                                                                        error: () => {
                                                                            setLoadingAsyncPost(null);
                                                                            showPopupNotification({
                                                                                typePopup: 'message',
                                                                                message: Validator.renderMessage(t("I0005"), {
                                                                                    0: x?.note
                                                                                }),
                                                                                newWindow: true
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        });
                                                    }} title={x?.note} disabled={x?.IsEditabled == "0" ||
                                                        (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false} />
                                                }
                                                if (x?.type == Constants.TYPE_ACTIONS.POST.VALUE) {
                                                    return <Button loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                        showPopupNotification({
                                                            typePopup: 'confirm',
                                                            message: Validator.renderMessage(t("confirm_action"), {
                                                                0: x?.note
                                                            }),
                                                            newWindow: true,
                                                            callback: () => {
                                                                setLoadingAsyncPost(index)
                                                                dispatch({
                                                                    type: actionsHolonInstance.REQUEST_API_WITH_URL,
                                                                    payload: {
                                                                        url: stringApi,
                                                                        method: 'POST',
                                                                        data: JSON.parse(stringPattern)
                                                                    },
                                                                    callback: {
                                                                        success: (res) => {
                                                                            setLoadingAsyncPost(null)
                                                                            if (res?.data?.reload == "1") {
                                                                                dispatch({
                                                                                    type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                                                                                    payload: payload?.holonInstanceId,
                                                                                    callback: (data) => {
                                                                                        setHolonInStanceDetail(data);
                                                                                        setLoadingHolonInstanceDetail(false);
                                                                                    }
                                                                                })
                                                                            }
                                                                            if (res?.data?.screenId) {
                                                                                ReloadActionWithScreen({
                                                                                    screenId: res?.data?.screenId,
                                                                                    id: holonInStanceDetail?.name,
                                                                                    router: router
                                                                                })
                                                                            }
                                                                            showPopupNotification({
                                                                                typePopup: 'message',
                                                                                message: getMessageCommon({
                                                                                    response: res,
                                                                                    error: null,
                                                                                    actionName: x?.note
                                                                                }),
                                                                                newWindow: true
                                                                            });
                                                                        },
                                                                        failed: (msg) => {
                                                                            setLoadingAsyncPost(null);
                                                                            showPopupNotification({
                                                                                typePopup: 'message',
                                                                                message: msg,
                                                                                newWindow: true
                                                                            })
                                                                        },
                                                                        error: () => {
                                                                            setLoadingAsyncPost(null);
                                                                            showPopupNotification({
                                                                                typePopup: 'message',
                                                                                message: Validator.renderMessage(t("I0005"), {
                                                                                    0: x?.note
                                                                                }),
                                                                                newWindow: true
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        });
                                                    }} title={x?.note} disabled={x?.IsEditabled == "0" ||
                                                        (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false} />
                                                }
                                                return <a style={{
                                                    pointerEvents: x?.IsEditabled == "0" ? 'none' : 'unset',
                                                    backgroundColor: 'white'
                                                }} key={index} onClick={() => EventPopup.removeLastPopup()} className={classes['custom-button']} href={stringApi} rel="noreferrer">{x?.note}</a>
                                            } catch (error) {
                                                return <Button key={index} variant='outlined' disabled title={x?.note} />
                                            }
                                        } else {
                                            if (x?.type == Constants.TYPE_ACTIONS.ASYNCPOST.VALUE) {
                                                return <Button loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                    showPopupNotification({
                                                        typePopup: 'confirm',
                                                        message: Validator.renderMessage(t("confirm_action"), {
                                                            0: x?.note
                                                        }),
                                                        newWindow: true,
                                                        callback: () => {
                                                            setLoadingAsyncPost(index)
                                                            dispatch({
                                                                type: actionsHolonInstance.REQUEST_API_WITH_URL,
                                                                payload: {
                                                                    url: stringApi,
                                                                    method: 'POST',
                                                                },
                                                                callback: {
                                                                    success: (res) => {
                                                                        setLoadingAsyncPost(null)

                                                                        if (res?.data?.reload == "1") {
                                                                            dispatch({
                                                                                type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                                                                                payload: payload?.holonInstanceId,
                                                                                callback: (data) => {
                                                                                    setHolonInStanceDetail(data);
                                                                                    setLoadingHolonInstanceDetail(false);
                                                                                }
                                                                            })
                                                                        }

                                                                        if (res?.data?.screenId) {
                                                                            ReloadActionWithScreen({
                                                                                screenId: res?.data?.screenId,
                                                                                id: holonInStanceDetail?.name,
                                                                                router: router
                                                                            })
                                                                        }

                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: getMessageCommon({
                                                                                response: res,
                                                                                error: null,
                                                                                actionName: x?.note
                                                                            }),
                                                                            newWindow: true
                                                                        });
                                                                    },
                                                                    failed: (msg) => {
                                                                        setLoadingAsyncPost(null);
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: msg,
                                                                            newWindow: true
                                                                        })
                                                                    },
                                                                    error: () => {
                                                                        setLoadingAsyncPost(null);
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: Validator.renderMessage(t("I0005"), {
                                                                                0: x?.note
                                                                            }),
                                                                            newWindow: true
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    })
                                                }} title={x?.note} disabled={x?.IsEditabled == "0" ||
                                                    (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false} />
                                            } else if (x?.type == Constants.TYPE_ACTIONS.POST.VALUE) {
                                                return <Button loading={loadingAsyncPost == index ? true : false} key={index} variant='outlined' onClick={() => {
                                                    showPopupNotification({
                                                        typePopup: 'confirm',
                                                        message: Validator.renderMessage(t("confirm_action"), {
                                                            0: x?.note
                                                        }),
                                                        newWindow: true,
                                                        callback: () => {
                                                            setLoadingAsyncPost(index)
                                                            dispatch({
                                                                type: actionsHolonInstance.REQUEST_API_WITH_URL,
                                                                payload: {
                                                                    url: stringApi,
                                                                    method: 'POST',
                                                                },
                                                                callback: {
                                                                    success: (res) => {
                                                                        setLoadingAsyncPost(null)
                                                                        if (res?.data?.reload == "1") {
                                                                            dispatch({
                                                                                type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                                                                                payload: payload?.holonInstanceId,
                                                                                callback: (data) => {
                                                                                    setHolonInStanceDetail(data);
                                                                                    setLoadingHolonInstanceDetail(false);
                                                                                }
                                                                            })
                                                                        }
                                                                        if (res?.data?.screenId) {
                                                                            ReloadActionWithScreen({
                                                                                screenId: res?.data?.screenId,
                                                                                id: holonInStanceDetail?.name,
                                                                                router: router
                                                                            })
                                                                        }
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: getMessageCommon({
                                                                                response: res,
                                                                                error: null,
                                                                                actionName: x?.note
                                                                            }),
                                                                            newWindow: true
                                                                        })
                                                                        // if (res?.message) {
                                                                        //     showPopupNotification({
                                                                        //         typePopup: 'message',
                                                                        //         message: t(res?.message),
                                                                        //         newWindow: true
                                                                        //     })
                                                                        // } else {
                                                                        //     showPopupNotification({
                                                                        //         typePopup: 'message',
                                                                        //         message: Validator.renderMessage(t("I0004"), {
                                                                        //             0: x?.note
                                                                        //         }),
                                                                        //         newWindow: true
                                                                        //     })
                                                                        // }
                                                                    },
                                                                    failed: (msg) => {
                                                                        setLoadingAsyncPost(null);
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: msg,
                                                                            newWindow: true
                                                                        })
                                                                    },
                                                                    error: () => {
                                                                        setLoadingAsyncPost(null);
                                                                        showPopupNotification({
                                                                            typePopup: 'message',
                                                                            message: Validator.renderMessage(t("I0005"), {
                                                                                0: x?.note
                                                                            }),
                                                                            newWindow: true
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    })
                                                }} title={x?.note} disabled={x?.IsEditabled == "0" ||
                                                    (loadingAsyncPost != index && loadingAsyncPost != null) ? true : false} />
                                            } else {
                                                return <a style={{
                                                    pointerEvents: x?.IsEditabled == "0" ? 'none' : 'unset',
                                                    backgroundColor: 'white',
                                                    opacity: loadingAsyncPost && loadingAsyncPost != index ? 0.7 : 1
                                                }} key={index} onClick={() => EventPopup.removeLastPopup()} className={classes['custom-button']} href={x?.api} rel="noreferrer">{x?.note}</a>
                                            }
                                        }
                                    } else {
                                        return <Button key={index} variant='outlined' disabled title={x?.note} />
                                    }
                                })}
                            </div>
                        </BoxBody>
                    </Box> : <></>
                }
                {/* <ActionHolonInstance
                    dataHolonInstanceActions={dataHolonInstanceActions}
                    holonInStanceDetail={holonInStanceDetail}
                    payload={payload}
                    setHolonInStanceDetail={setHolonInStanceDetail}
                    setLoadingHolonInstanceDetail={setLoadingHolonInstanceDetail}
                /> */}
                {
                    <BoxSection
                        id={connections_key}
                        BoxHeader={[
                            {
                                component: <BoxTitle>{t("holon.related_information")}</BoxTitle>,
                                id: 1
                            },
                            {
                                component: <BoxTitle>{t("holon.life_cycle")}</BoxTitle>,
                                id: 2
                            },
                        ]}
                        BoxBody={{
                            1: <BoxBody style={{ gap: 0 }} padding="14px 0px" id={connections_key}>
                                {
                                    connections?.map((x, index) => {
                                        return <BoxRow style={{
                                            padding: '5px 19px',
                                            background: index % 2 == 0 ? 'white' : '#F2F2F2'
                                        }} key={index}>
                                            <BoxLabel>{x?.note}</BoxLabel>
                                            <BoxContent>
                                                <div className={classes['box-content']}>
                                                    {x?.holons?.map((holon, key) => {
                                                        return <div onClick={() => handleOpenConnection(holon)} key={key} title={holon?.name} className={classes['box-name']}>
                                                            <p style={{ marginBottom: 0 }}>{getConnectionName(holon)}</p>
                                                        </div>
                                                    })}
                                                </div>
                                            </BoxContent>
                                        </BoxRow>
                                    })
                                }
                            </BoxBody>,
                            2: <BoxBody id={connections_key}>
                                <History handleShowHistory={handleShowHistory} history={history} />
                            </BoxBody>
                        }}
                    />
                }
                {/* {
                    (holonTemplateDetail?.id == Constants.CARGO_MASTER_CODE.CARD_BOARD.TEMPLATE_ID || payload?.isCargo) && <CargoProductDetail cardboard_type={cardboard_type} holonInstanceId={payload?.holonInstanceId} />
                } */}
                {
                    attachment?.length > 0 ? <Box>
                        <BoxHeader id={attachment_key}>
                            <BoxTitle>{t("holon.attachment")}</BoxTitle>
                        </BoxHeader>
                        <BoxBody id={attachment_key}>
                            {
                                attachment?.map((x, index) => {
                                    return <BoxRow key={index}>
                                        {/* <BoxLabel>
                                            {x?.name}
                                        </BoxLabel> */}
                                        <BoxContent>
                                            <Button className={classes['btn-attachment']} onClick={() => handleOpenPreview(x)} key={index} variant='outlined' disabled={x?.content ? false : true} title={condenseFilename(x?.name)} />
                                        </BoxContent>
                                    </BoxRow>
                                })
                            }
                        </BoxBody>
                    </Box> : <></>
                }
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default HolonInstanceDetailPopup