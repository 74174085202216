import React, { useEffect, useState } from 'react';
import Constants from 'utils/Constants';
import { useSelector } from 'react-redux';
import ElementType from 'utils/ElementType';
import _ from 'lodash';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import actionHolon from 'redux/holon/action';
import actionUser from 'redux/user/action';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import styles from './Tabs.module.scss'
import useTrans from 'hooks/use-trans';
import ButtonLink from 'shared/components/form-input/button/ButtonLink';
import EventRegister, { EVENT_SHOW_COMPANY_DETAIL_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from 'utils/EventRegister';
import Button from 'shared/components/form-input/button/Button';
import FieldAccountSetting from 'shared/components/form/field-account-setting/FieldAccountSetting';

function ProfileTab(props) {
    const roleMasterCodes = [
        Constants.HOLON_MASTER_CODE.PERSONAL_CUSTOMER, 
        Constants.HOLON_MASTER_CODE.BUSINESS_CUSTOMER, 
        Constants.HOLON_MASTER_CODE.BL_PERSON_IN_CHARGE,
        Constants.HOLON_MASTER_CODE.HEX_ADMIN
    ];
    const dispatch = useDispatch();
    const { trans } = useTrans();
    const methods = useForm();
    const { register, handleSubmit, setValue, reset, watch, getValues, setError, clearErrors } = methods;
    const { user, user_id, hex_id, status_user, updateUserError, loading: {loadingUpdateAccount, loadingPositions}, positions } = useSelector(state => state.user);
    const [roleTemplates, setRoleTemplates] = useState([]);
    const [honninTemplate, setHonninTemplate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataDefault, setDataDefault] = useState({})

    const dataHeadCell = [
        {
            label: trans('STT'),
            field: "stt",
            minWidth: '10px',
            width: '10px',
            maxWidth: '10px',
            alignHeader: 'center',
            align: 'center',
            styleBody: {
                padding: '5px 20px'
            },
            styleHeader: {
                padding: '10px 20px'
            },
            component: (_props) => {
                return <>{_props?.stt + 1}</>
            }
        },
        {
            label: trans('company_number'),
            field: "companyNumber",
            minWidth: '100px',
            width: '100px',
            maxWidth: '100px',
            alignHeader: 'center',
            align: 'center',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            component: (_props) => {
                return <>{_props?.children}</>
            }
        },
        {
            label: trans('company_name'),
            field: "companyName",
            minWidth: '200px',
            width: '200px',
            alignHeader: 'center',
            align: 'center',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            component: (_props) => {
                return (
                    <ButtonLink 
                        title={_props?.children} 
                        onClick={() => {handleOpenDetail(_props?.data?.companyId)}} 
                        style={{color: '#00b0f0'}}
                    />
                )
            }
        },
        {
            label: trans('department_name'),
            field: "departmentName",
            minWidth: '200px',
            maxWidth: '200px',
            width: '200px',
            alignHeader: 'center',
            align: 'center',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            component: (_props) => {
                return <>{_props?.children}</>
            }
        },
        {
            label: trans('position_name'),
            field: "positionName",
            minWidth: '200px',
            width: '200px',
            alignHeader: 'center',
            align: 'center',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            component: (_props) => {
                return <>{_props?.children}</>
            }
        },
    ]

    const handleOpenDetail = (value) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_COMPANY_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                title: trans("company_detail"),
                holonInstanceId: value,
                holonTemplateId: 'T201',
            }
        })
    }
    useEffect(() => {
        if (user?.id) {
            const formData = {};

            dispatch({
                type: actionHolon.GET_HOLON_TEMPLATES_IN_USER_BY_MASTER_CODES,
                payload: {
                    masterCode: roleMasterCodes.join(','),
                    user: user
                },
                callback: (data) => {
                    setRoleTemplates(data);
                    dispatch({
                        type: actionHolon.GET_HOLON_TEMPLATES_IN_USER_BY_MASTER_CODES,
                        payload: {
                            masterCode: [Constants.HOLON_MASTER_CODE.HONNIN_TEMPLATE].join(','),
                            user: user
                        },
                        callback: (honinnData) => {
                            setHonninTemplate(honinnData);
                            honinnData?.map(template  => {
                                template?.attributes?.map((attribute, index) => {
                                    if (!attribute?.key?.startsWith("address") && attribute?.elementType !== ElementType.TEL) {
                                        delete template?.attributes[index];
                                    }
                                })
                            });
                            registerForm([...data, ...honinnData]);
                        }
                    })
                }
            });
        }
    }, [user]);

    useEffect(() => {
        if (user?.id) {
            const accountConnection = user?.connections?.find(connection => connection.type == 'account')
            const account = accountConnection?.holons?.[0]
            if (account) {
                dispatch({
                    type: actionUser.GET_POSITIONS_OF_BUSINESS_CUSTOMER,
                    payload: account?.id
                })
            }
        }
    }, [user]);

    const registerForm = (templates) => {
        const formData = {};
        const templateIds = [];
        templates.map(template => {
            templateIds.push(template.id);
        })
        user?.connections?.map((connection) => {
            connection?.holons?.[0]?.attributes?.map((attribute) => {
                for (const template of templates) {
                    const att = template?.attributes?.find((att, index) => attribute?.name == att?.key)
                    if (att && template?.id == connection?.holons?.[0]?.templateId && attribute?.value != null && attribute?.value != '' && attribute?.value != undefined) {
                        if (att?.elementType == ElementType.DATE) {
                            try {
                                let value = attribute?.value;
                                if (moment(attribute?.value, 'DD/MM/yyyy').format('DD/MM/yyyy') == attribute?.value) {
                                    value = moment(attribute?.value, 'DD/MM/yyyy').format('yyyy-MM-DD');
                                    formData[`${attribute?.name}`] = value.toString();
                                } else if (moment(attribute?.value, 'yyyy/MM/DD').format('yyyy/MM/DD') == attribute?.value) {
                                    value = moment(attribute?.value, 'yyyy/MM/DD').format('yyyy-MM-DD');
                                    formData[`${attribute?.name}`] = value.toString();
                                }
                                // formData[`${attribute?.name}`] = value.toString();
                                
                            } catch (error) {
                                // formData[`${attribute?.name}`] = attribute?.value;
                            }
                            
                        } else if ((att?.elementType == ElementType.CHECKBOX || att?.elementType == ElementType.RADIO || att?.elementType == ElementType.SELECT) && (attribute?.value == 'null' || attribute?.value == 'undefined')) {
                            continue;
                        } else {
                            formData[`${attribute?.name}`] = attribute?.value;
                        }
                    }
                }
            })
        })  
        formData["template_id"] = templateIds.join(',');
        setDataDefault(formData)
        reset(formData);
    }
    const onSubmit = (data) => {
        const formData = [];
        Object?.keys(data).forEach((key, index) => {
            formData.push({
                name: key,
                value: data[key],
                description: localStorage.getItem(key) ?? ""
            });
            localStorage.removeItem(key);
        });
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            payload: {
                title: trans("E0042"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    setLoading(true);
                    dispatch({
                        type: actionUser.UPDATE_ACCOUNT,
                        payload: {
                            user_id: user_id,
                            hex_id: hex_id,
                            data: formData,
                        },
                        callback: {
                            success: () => {
                                // getToast(trans('successful_update'));
                                setLoading(false);
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: trans("E0043"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                                dispatch({
                                    type: actionUser.GET_ACCOUNT,
                                })
                            },
                            failed: () => {
                                // getToast(trans('failed_update'), 'error');
                                setLoading(false);
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: trans("system_error"),
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    const watchFormData = methods.watch()
    let isEdited = _.isEqual(dataDefault, watchFormData) ? false : true;

    return (
        <React.Fragment>
            {
                (!status_user || status_user == Constants.USER_STATUS.TEMPORARY.VALUE) &&
                <p className={styles['red']}>{trans("registration_notify")}</p>
            }
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    { roleTemplates.length > 0 &&
                        <React.Fragment>
                            {
                                roleTemplates?.map((template, i) =>
                                    template && template?.attributes?.map((field, index) => {
                                        return field && !field?.key?.startsWith("email") && (!field?.key?.startsWith("setting")) &&
                                            <FieldAccountSetting
                                                key={index}
                                                template={template.id}
                                                field={field}
                                                register={register}
                                                setValue={setValue}
                                                defaultValue={getValues(`${field?.key}`)}
                                                watch={watch}
                                                getValues={getValues}
                                                clearErrors={clearErrors}
                                            />
                                    })
                                )
                            }

                        </React.Fragment>
                    }
                    {
                        honninTemplate.length > 0 &&
                        <React.Fragment>
                            {
                                honninTemplate?.map((template, i) =>
                                    template && template?.attributes?.map((field, index) => {
                                        return field && !field?.key?.startsWith("email") && (!field?.key?.startsWith("setting")) &&
                                        <FieldAccountSetting
                                            key={index}
                                            template={template.id}
                                            field={field}
                                            register={register}
                                            setValue={setValue}
                                            defaultValue={getValues(`${field?.key}`)}
                                            watch={watch}
                                            getValues={getValues}
                                            clearErrors={clearErrors}
                                        />
                                    })
                                )
                            }
                        </React.Fragment>
                    }
                    {
                        (honninTemplate.length > 0 || roleTemplates.length > 0) &&
                        <div className={styles['profile-submit-btn-start']}>
                            <Button color='lightblue' disabled={!isEdited} loading={loading} type='submit' title={trans('keep')} />
                        </div>
                    }
                </form>
            </FormProvider>
            {
                (positions && positions?.length > 0) &&
                    <>
                        <div id='Menu_Table' className={styles['custom-table-instance']}>
                            <p>{trans('about_us')}</p>
                            <CustomTable loading={loadingPositions} data={positions ?? []} headCell={dataHeadCell} />
                        </div>
                    </>
            }
        </React.Fragment>
    );
}

export default ProfileTab;