import React from 'react';
import classes from './ErrorMessage.module.scss';

function ErrorMessage(props) {
    const { message, style , className} = props;
    return (
        <div className={`${classes['ErrorMessage']} ${className}`}>
            <p style={style}>
                <div className={classes['ErrorMessage']}>
                    <p style={style}>{message}</p>
                </div>
            </p>
        </div>
    );
}

export default ErrorMessage;
