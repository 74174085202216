import React, { useEffect, useState } from 'react'
import classes from './FAQ.module.scss';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FaqAction from 'redux/faq/action';
import FormGroup from '../../form-search/FormGroup';
import useTrans from 'hooks/use-trans';
import Validator from 'utils/Validator';
import FormSelect from 'shared/components/custom-form/FormSelect';
import useCustomState from 'hooks/use-custom-state';
import Constants from 'utils/Constants';
import Button from 'shared/components/form-input/button/Button';
import FormTextArea from 'shared/components/custom-form/FormTextArea';
import useRouter from 'hooks/use-router';
import FormRadio from 'shared/components/custom-form/FormRadio';
import FormEditor from 'shared/components/custom-form/FormEditor';
import EventRegister, { EVENT_SHOW_FAQ_DETAIL_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_UPDATE } from 'utils/EventRegister';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';

function UpdateFAQPopup(props) {
    const { showVisible, payload } = props;
    const methods = useForm({
        defaultValues: {
            release: Constants.STATUS_CATEGORY.PUBLIC.VALUE
        }
    });
    const [faqDetail, setFaqDetail] = useState(null)
    const { trans, t } = useTrans();
    const dispatch = useDispatch();
    const [optionLanguages, setOptionLanguages] = useCustomState([]);
    const [optionCategory, setOptionCategory] = useCustomState([]);
    const { faqCategory, loadingUpdateFaq } = useSelector(state => state.faq);
    const router = useRouter();

    const onSubmit = (_data) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_UPDATE,
            newWindow: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callback: () => {
                    dispatch({
                        type: FaqAction.UPDATE_FAQ_WITH_CATEGORY,
                        payload: {
                            ..._data,
                            sort: faqDetail?.sort, // DEFINE
                            id: faqDetail?.id,
                        },
                        callback: {
                            success: () => {
                                showVisible(false);
                                methods.reset({})
                            },
                            failed: () => {
                                // SHOW MESSAGE ERROR
                            }
                        },
                        paramsUrl: { ...router.getAll() }
                    })
                }
            }
        })
    }

    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: t(`faq_management.lang.${Constants.T001[key].NAME}`),
            })
        });
        setOptionLanguages(data)
    }, [])

    useEffect(() => {
        // let data = [];
        // if (faqCategory) {
        //     [...faqCategory?.records].forEach(value => {
        //         data.push({
        //             key: value?.id?.toString(),
        //             value: value?.id?.toString(),
        //             label: value?.categoryName,
        //         })
        //     });
        // }
        // setOptionCategory(data)
    }, [faqCategory])

    useEffect(() => {
        if (faqDetail?.id) {
            methods.setValue("id", faqDetail?.id);
            methods.setValue("categoryId", faqDetail?.categoryId?.toString());
            methods.setValue("question", faqDetail?.question);
            methods.setValue("answer", faqDetail?.answer);
            methods.setValue("status", faqDetail?.status);
            methods.setValue("language", router.get(Constants.QueryParams.LANGUAGE.VALUE) ?? Constants.T001.JP.CODE);

            dispatch({
                type: FaqAction.GET_FAQ_CATEGORIES_WITH_LANGUAGE_POPUP,
                payload: {
                    isAll: true,
                    [Constants.QueryParams.LANGUAGE.VALUE]: faqDetail?.lang
                },
                callback: (data) => {
                    setOptionCategory(data?.records?.map(x => {
                        return {
                            label: x?.categoryName,
                            value: x?.id,
                            key: x?.id
                        }
                    }));
                }
            })
        } else {
            methods.setValue("id", null);
            methods.setValue("categoryId", null);
            methods.setValue("question", null);
            methods.setValue("answer", null);
            methods.setValue("language", null);
            methods.setValue("status", Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE);
        }
    }, [faqDetail?.id])

    const OptionRelease = [
        {
            value: Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE,
            key: Constants.STATUS_CATEGORY_FAQ.PUBLIC.VALUE,
            label: t("Holon_Template.valid")
        },
        {
            value: Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE,
            key: Constants.STATUS_CATEGORY_FAQ.PRIVATE.VALUE,
            label: t("Holon_Template.invalid")
        },
    ]

    const handleBack = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_FAQ_DETAIL_POPUP,
            payload: {
                id: payload?.id,
                title: t("faq_management.FAQDetails"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                }
            }
        })
    }

    useEffect(() => {
        if (payload?.id) {
            dispatch({
                type: FaqAction.GET_DETAIL_FAQ_WITH_CATEGORIES_POPUP,
                payload: {
                    id: payload?.id
                },
                callback: (res) => {
                    console.log(res);
                    setFaqDetail(res)
                }
            })
        }
    }, [payload?.id])

    return (
        <BoxContentPopup width='760px' borderRadius="20px 0px 0px 20px" >
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        title={payload?.title}
                        showVisible={showVisible}
                        handleBtnBack={() => handleBack()}
                        actionSectionBottom={<Button loading={loadingUpdateFaq} type='submit' title={trans("faq_management.save")} />}
                    >
                        <div className={classes['Wrap_ContentPopup']}>
                            <Box>
                                <BoxBody style={{
                                    borderRadius: 10
                                }}>
                                    <FormGroup>
                                        <BoxRow style={{ flex: 1 }}>
                                            <BoxLabel minWidth="70px" width="70px" require>
                                                {trans("faq_management.lang_choice")}
                                            </BoxLabel>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName='language'
                                                validationFE
                                                isRegexLabel={trans("faq_management.lang_choice")}
                                                placeholder={trans("faq_management.lang_choice")}
                                                options={optionLanguages}
                                                onChangeSelect={e => {
                                                    methods.setValue("categoryId", null);
                                                    setOptionCategory([])
                                                    dispatch({
                                                        type: FaqAction.GET_FAQ_CATEGORIES_WITH_LANGUAGE_POPUP,
                                                        payload: {
                                                            isAll: true,
                                                            [Constants.QueryParams.LANGUAGE.VALUE]: e
                                                        },
                                                        callback: (data) => {
                                                            setOptionCategory(data?.records?.map(x => {
                                                                return {
                                                                    label: x?.categoryName,
                                                                    value: x?.id,
                                                                    key: x?.id
                                                                }
                                                            }));
                                                        }
                                                    })
                                                }}
                                            />
                                        </BoxRow>
                                        <BoxRow style={{ flex: 1 }}>
                                            <BoxLabel minWidth="70px" width="70px" require>
                                                {trans("faq_management.category")}
                                            </BoxLabel>
                                            <FormSelect
                                                validate={[Validator.required]}
                                                fieldName='categoryId'
                                                validationFE
                                                textTrans="faq_management.field_name"
                                                placeholder={trans("category_name")}
                                                options={optionCategory}
                                            />
                                        </BoxRow>
                                    </FormGroup>
                                    <BoxRow>
                                        <BoxLabel minWidth="70px" width="70px" require>
                                            {trans("faq_management.publish_setting")}
                                        </BoxLabel>
                                        <FormRadio
                                            fieldName="status"
                                            options={OptionRelease}
                                            validationFE
                                            textTrans="faq_management.field_name"
                                        />
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="70px" width="70px" require>
                                            {trans("question")}
                                        </BoxLabel>
                                        <FormTextArea
                                            validate={[Validator.required, Validator.maxLength(1000)]}
                                            fieldName='question'
                                            placeholder={trans("question")}
                                            validationFE
                                            textTrans="faq_management.field_name"
                                        />
                                    </BoxRow>
                                    <BoxRow>
                                        <BoxLabel minWidth="70px" width="70px" require>
                                            {trans("answer")}
                                        </BoxLabel>
                                        <FormEditor
                                            validate={[Validator.required, Validator.maxLength(1000)]}
                                            fieldName='answer'
                                            placeholder={trans("answer")}
                                            height='150px'
                                            validationFE
                                            textTrans="faq_management.field_name"
                                        />
                                    </BoxRow>
                                </BoxBody>
                            </Box>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default UpdateFAQPopup