import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Validator from 'utils/Validator';
import PropTypes from 'prop-types';
import { CKEditor } from 'ckeditor4-react';
import ErrorMessage from '../form-input/error-message/ErrorMessage';
import _ from 'lodash';
import Constants from 'utils/Constants';
import ErrorMessageHandleFE from '../form-input/error-message/ErrorMessageHandleFE';
import ErrorMessageHandleRegex from '../form-input/error-message/ErrorMessageHandleRegex';

function FormEditor(props) {
    const { fieldName, validate = [], readOnly = false, height, textTrans, validationFE = false, isRegexLabel } = props;
    const {
        formState: { errors },
        control,
    } = useFormContext();
    
    const defaultFonts = 'Arial/Arial, Helvetica, sans-serif;' +
                         'Comic Sans MS;' +
                         'Courier New;' +
                         'Georgia;' +
                         'Lucida Sans Unicode;' +
                         'Tahoma;' +
                         'Times New Roman/Times New Roman, Times, serif;' +
                         'Trebuchet MS;' +
                         'Verdana/Verdana, Geneva, sans-serif;' + 
                         'Meiryo;' + 
                         'Hiragino Kaku Gothic Pro;' + 
                         'MS PGothic;' + 
                         'Osaka, sans-serif;';
    return (
        <div className="Input w-100">
            <div className="w-100">
                <Controller
                    control={control}
                    name={fieldName}
                    rules={{
                        validate: Validator.genValidate(validate, fieldName),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        const onChangeValue = (evt) => {
                            if (evt?.editor?.getData()) {
                                onChange(evt?.editor?.getData());
                            } else {
                                onChange(null);
                            }
                        };
                        const refEditor = useRef();
                        const [editor, setEditor] = useState();
                        useEffect(() => {
                            if (editor && value != editor?.getData()) {
                                editor.setData(value);
                            }
                        }, [editor, value]);

                        let showError = false;
                        let error = {
                            message: '',
                        };

                        let arr = fieldName?.split('.');
                        if (arr.length >= 1 && errors !== null) {
                            let result = arr.reduce((rs, e) => {
                                if (rs[e]) {
                                    return (rs = rs[e]);
                                }
                                return {};
                            }, errors);
                            error = result;
                            showError = !_.isEmpty(error);
                        }

                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 7,
                                }}
                                ref={ref}
                            >
                                <CKEditor
                                    initData={value}
                                    ref={refEditor}
                                    config={{
                                        font_names: defaultFonts,
                                        height: height ?? 500,
                                        language: localStorage.getItem("i18nextLng") == Constants.T001.JP.CODE.toLowerCase() ? 'ja' :
                                            localStorage.getItem("i18nextLng") == Constants.T001.EN.CODE.toLowerCase() ? 'en' : 'cn',
                                        fontStyle: {
                                            element: 'span',
                                            styles: { 'font-family': '#(family)' },
                                            overrides: [{ element: 'font', attributes: { 'face': null } }],
                                        },
                                        // extraPlugins: "justify,font,colorbutton",
                                        extraPlugins: "justify,font,colorbutton,easyimage",
                                        toolbarGroups: [
                                            { name: "document", groups: ["mode", "document", "doctools"] },
                                            { name: "clipboard", groups: ["clipboard", "undo", 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
                                            { name: "editing", groups: ["find", "selection", "spellchecker"] },
                                            { name: "forms" },
                                            "/",
                                            { name: "basicstyles" },
                                            {
                                                name: "paragraph",
                                                groups: ["list", "indent", "blocks", "align", "bidi"] // 'align' -> 'justify' plugin
                                            },
                                            { name: "links" },
                                            { name: "insert" },
                                            "/",
                                            { name: "styles" }, // 'font and fontsize' -> 'font' plugin
                                            { name: "colors" }, // 'colors' -> 'colorbutton' plugin
                                            { name: "tools" },
                                            { name: "others" },
                                            { name: "about" },
                                            '/',
                                        ],
                                        removeButtons: 'Subscript,Superscript',
                                        filebrowserImageUploadUrl: '/upload.html',
                                        removePlugins: 'image',
                                        cloudServices_uploadUrl: 'https://your-organization-id.cke-cs.com/easyimage/upload/'
                                    }}
                                    onFocus={onBlur}
                                    onBlur={() => { }}
                                    onChange={onChangeValue}
                                    onSelectionChange={() => { }}
                                    onInstanceReady={({ editor }) => {
                                        setEditor(editor);
                                    }}
                                    readOnly={readOnly}
                                />
                                {/* {showError ? (
                                    !validationFE ? (<ErrorMessage message={error?.message} />) : (<ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={fieldName} />)
                                ) : (
                                    <></>
                                )} */}
                                {showError ? (
                                    !validationFE ? (<ErrorMessage message={error?.message} />) : (
                                        isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} textTrans={textTrans} message={error?.message} fieldName={fieldName} /> : <ErrorMessageHandleFE textTrans={textTrans} message={error?.message} fieldName={fieldName} />
                                    )
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}
FormEditor.defaultProps = {
    placeholder: '',
    fieldName: 'fieldName',
};
FormEditor.propTypes = {
    fieldName: PropTypes.string,
    validate: PropTypes.array,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    iconSearch: PropTypes.element,
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    textTrans: PropTypes.string,
    readOnly: PropTypes.bool,
    validationFE: PropTypes.bool,
};
export default FormEditor;
