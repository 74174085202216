import classes from './CreateReceiver.module.scss';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import CustomHeader from '../custom-header/CustomHeader';
import FormInput from 'shared/components/custom-form/FormInput';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useFieldArray, useForm } from 'react-hook-form';
import Constants from 'utils/Constants';
import FormCheckbox from 'shared/components/custom-form/FormCheckbox';
import FormSelect from 'shared/components/custom-form/FormSelect';
import Button from 'shared/components/form-input/button/Button';
import Validator from 'utils/Validator';
import FormRadio from 'shared/components/custom-form/FormRadio';
import useTrans from 'hooks/use-trans';
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import FormFile from 'shared/components/custom-form/FormFile';
import FormImage from 'shared/components/custom-form/FormImage';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import Box from '../../box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxTitle from '../../box/BoxTitle';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import { convertRegexToOptions } from 'utils/Utils';

function CreateReceiverPopup(props) {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const [loading, setLoading] = useState(false)
    const methods = useForm();
    const control = methods.control;
    const dispatch = useDispatch();
    const { holonTemplateDetail } = payload;

    const renderInput = (value, index) => {
        switch (value?.elementType) {
            case Constants.ELEMENT_TYPE.TEXT.VALUE:
                let validate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validate.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.ADDRESS.VALUE:
                let validateAddreess = [Validator.email];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAddreess.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validateAddreess]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.EMAIL.VALUE:
                let validateEmail = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateEmail.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validateEmail]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.FILE.VALUE:
                let validateFile = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateFile.push(Validator.requiredInput)
                }
                return <FormFile validate={[Validator.customValidator(value?.regex, value?.msg), ...validateFile]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.IMAGE.VALUE:
                let validateImage = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateImage.push(Validator.requiredInput)
                }
                return <FormImage validate={[Validator.customValidator(value?.regex, value?.msg), ...validateImage]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.NUMBER.VALUE:
                let validateNumber = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateNumber.push(Validator.requiredInput)
                }
                return <FormInput format={Constants.FormInputFormat.NUMBER.VALUE} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateNumber]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.URL.VALUE:
                let validateUrl = [Validator.URL];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUrl.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validateUrl]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATE.VALUE:
                let validateDate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDate.push(Validator.requiredInput)
                }
                return <FormDatePicker validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.DATETIME.VALUE:
                let validateDateTime = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDateTime.push(Validator.requiredInput)
                }
                return <FormDatePicker validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDateTime]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.AUTOGEN.VALUE:
                let validateAutogen = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateAutogen.push(Validator.requiredInput)
                }
                return <FormInput disabled validate={[Validator.customValidator(value?.regex, value?.msg), ...validateAutogen]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.UNIQUE.VALUE:
                let validateUnique = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateUnique.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validateUnique]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE:
                let validateSelectTemplate = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateSelectTemplate.push(Validator.requiredInput)
                }
                return <FormSelectTemplate isMulti={value?.isMultiSelected == "1" ? true : false} validate={[Validator.customValidator(value?.regex, value?.msg), ...validateSelectTemplate]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.SELECT.VALUE:
                let optionSelect = convertRegexToOptions(value?.regex);
                if (value?.isMultiSelected == "1") {
                    return <FormSelect isMultiSelected options={optionSelect} validate={[]} fieldName={`attributes.${index}.${value?.key}`} />
                } else {
                    return <FormSelect options={optionSelect} validate={[]} fieldName={`attributes.${index}.${value?.key}`} />
                }
            case Constants.ELEMENT_TYPE.RADIO.VALUE:
                let validateRadio = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateRadio.push(Validator.requiredInput)
                }
                let optionRadio = convertRegexToOptions(value?.regex);
                return <FormRadio options={optionRadio} validate={[...validateRadio]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.TEL.VALUE:
                let validateTel = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateTel.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validateTel]} fieldName={`attributes.${index}.${value?.key}`} />
            case Constants.ELEMENT_TYPE.PASSWORD.VALUE:
                let validatePass = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validatePass.push(Validator.requiredInput)
                }
                return <div className={classes['attributes-password']}>
                    <FormInput type="password" validate={[Validator.customValidator(value?.regex, value?.msg), ...validatePass]} fieldName={`attributes.${index}.${value?.key}`} />
                    <FormInput type="password" validate={[Validator.confirmPassword(methods.watch(`attributes.${index}.${value?.key}`))]} fieldName={`attributes.${index}.confirmPassword`} />
                </div>
            case Constants.ELEMENT_TYPE.CHECKBOX.VALUE:
                let validateCheckbox = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateCheckbox.push(Validator.requiredCheckbox)
                }
                return <FormCheckbox validate={[...validateCheckbox]} fieldName={`attributes.${index}.${value?.key}`} />
            default:
                let validateDefault = [];
                if (value?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE) {
                    validateDefault.push(Validator.requiredInput)
                }
                return <FormInput validate={[Validator.customValidator(value?.regex, value?.msg), ...validateDefault]} fieldName={`attributes.${index}.${value?.key}`} />
        }
    }

    const {
        fields: fieldsHas,
    } = useFieldArray({
        control, name: "attributes"
    });

    const {
        fields: fieldsConnection,
    } = useFieldArray({
        control, name: "connections"
    });

    useEffect(() => {
        if (holonTemplateDetail?.attributes?.length > 0) {
            methods.setValue("attributes", holonTemplateDetail?.attributes?.map(x => {
                if (x?.elementType == Constants.ELEMENT_TYPE.AUTOGEN.VALUE) {
                    return {
                        ...x,
                        [x?.key]: ""
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.DATE.VALUE) {
                    return {
                        ...x,
                        [x?.key]: ""
                    }
                }
                if (x?.elementType == Constants.ELEMENT_TYPE.DATETIME.VALUE) {
                    return {
                        ...x,
                        [x?.key]: ""
                    }
                }
                return {
                    ...x,
                    [x?.key]: x?.defaultValue || ""
                }
            }))
            methods.setValue("connections", holonTemplateDetail?.connections?.map(x => {
                return {
                    ...x,
                }
            }))
        }
    }, [holonTemplateDetail])

    const onSubmit = (data) => {

        setLoading(true)
        let attributes = data?.attributes?.map(x => {
            if (x?.elementType == Constants.ELEMENT_TYPE.CHECKBOX.VALUE) {
                return {
                    value: x?.[x?.key] ? Constants.STATUS_CHECKBOX.YES.VALUE : Constants.STATUS_CHECKBOX.NO.VALUE,
                    name: x?.key,
                    elementType: x?.elementType
                }
            }
            if (x?.elementType == Constants.ELEMENT_TYPE.SELECT_TEMPLATE.VALUE) {
                return {
                    value: x?.[x?.key]?.length > 0 ? x?.[x?.key]?.join(",") : "",
                    name: x?.key,
                    elementType: x?.elementType
                }
            }
            return {
                value: x?.[x?.key],
                name: x?.key,
                elementType: x?.elementType
            }
        });

        // let connections = data?.connections?.map(x => {
        //     return {
        //         name: x?.key,
        //         type: x?.key,
        //         fromName: x?.fromName,
        //         toName: x?.toName,
        //         holons: x?.instance?.map(holon => {
        //             return {
        //                 id: holon?.id
        //             }
        //         })
        //     }
        // });
        let params = {
            name: holonTemplateDetail?.name,
            description: holonTemplateDetail?.description,
            templateId: holonTemplateDetail?.id,
            type: holonTemplateDetail?.type,
            attributes: attributes,
            connections: []
        }

        payload?.callback({
            data: params,
            success: () => {
                showVisible();
                setLoading(false)
            },
            failed: () => {
                setLoading(false)
            }
        });
    }
    return (
        <BoxContentPopup>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody title={payload?.title} showVisible={showVisible} payload={payload}
                        actionSectionBottom={<Button loading={loading} type='submit' title={t('save')} />}
                    >
                        <div className={classes['created-holon-instance']}>
                            <Box>
                                <BoxHeader>
                                    <BoxTitle>{t("receiver.basic_information")}</BoxTitle>
                                </BoxHeader>
                                <BoxBody className={classes['data-attributes-body']}>
                                    {
                                        fieldsHas?.map((x, index) => {
                                            return <BoxRow key={index}>
                                                <BoxLabel minWidth={140}>{x?.label}
                                                    {x?.isRequired == Constants.STATUS_CHECKBOX.YES.VALUE ? <span style={{ color: 'red' }}>*</span> : ''}
                                                </BoxLabel>
                                                {renderInput(x, index)}
                                            </BoxRow>
                                        })
                                    }
                                </BoxBody>
                            </Box>
                        </div>
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </BoxContentPopup>
    )
}

export default CreateReceiverPopup