import ApiOperation from 'adapter/ApiOperation';
import axios from 'axios';

const factory = {
    getPolicySetting: (payload) => {
        var url = '';
        if (payload?.lang) {
            url = `/rest/api/v1/management/policy/${payload?.lang}/${payload?.key}`;
        } else {
            url = `/rest/api/v1/management/policy`;
        }

        return ApiOperation.request({
            url: url
        })
    },
    updatePolicySetting: (payload) => {
        var url = `/rest/api/v1/management/policy`;

        return ApiOperation.request({
            url: url,
            method: 'PUT',
            data: payload
        })
    },
};

export default factory;
