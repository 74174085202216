import { getCoreAPI } from "shared/components/common/Util";
import ApiOperation from "adapter/ApiOperation";
import Constants from "utils/Constants";

const Factories = {
    getContinents: (data) => {
        const url = `${getCoreAPI()}/address/continent`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })
    },

    getCountriesByContinentId: (data) => {
        const url = `${getCoreAPI()}/address/country`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                continentId: data
            }
        })    
    },

    getAddressByPostalCodeAndCountryId: (data) => {
        const url = `${getCoreAPI()}/address`;
        let params = {};
        if (data[Constants.QueryParams.POSTAL_CODE.VALUE]) {
            params['postalCode'] = data[Constants.QueryParams.POSTAL_CODE.VALUE]
        }
        if (data[Constants.QueryParams.COUNTRY_ID.VALUE]) {
            params['countryId'] = data[Constants.QueryParams.COUNTRY_ID.VALUE]
        }
        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: params,
        })
    },

    getPortsByPortType: (data) => {
        const url = `${getCoreAPI()}/address/port`;

        return ApiOperation.request({
            url: url,
            method: 'GET',
            params: {
                portType: data?.portType,
                countryId: data?.countryId
            },
        })
    },

    getPrefecturesByCountryCode: (data) => {
        const url = `${getCoreAPI()}/address/country/${data}/prefecture`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })    
    },

    getCitiesByPrefectureId: (data) => {
        const url = `${getCoreAPI()}/address/prefecture/${data}/city`;
        return ApiOperation.request({
            url: url,
            method: 'GET',
        })    
    },
}

export default Factories;