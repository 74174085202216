import React, { useEffect, useState } from 'react'
import classes from './Product.module.scss';
import { useDispatch } from 'react-redux';
import actionProduct from 'redux/product/action';
import useTrans from 'hooks/use-trans';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import WrapContentBody from '../custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxRow from '../../box/BoxRow';
import BoxLabel from '../../box/BoxLabel';
import BoxContent from '../../box/BoxContent';
import IconEdit from 'assets/icon/IconEdit';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_CONFIRM_DELETE, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_UPDATE_PRODUCT_POPUP } from 'utils/EventRegister';
import { convertNumberToString } from 'utils/String';
import useRouter from 'hooks/use-router';
import { showPopupNotification } from 'utils/Utils';

function ProductDetailPopup(props) {
    const { payload, showVisible } = props;
    const router = useRouter();
    const dispatch = useDispatch();
    const { t } = useTrans();
    const [product, setProduct] = useState(null)
    const [loadingGetDetail, setLoadingGetDetail] = useState(false)

    useEffect(() => {
        if (payload?.productId) {
            setLoadingGetDetail(true)
            dispatch({
                type: actionProduct.GET_DETAIL_PRODUCT_POPUP,
                payload: payload?.productId,
                callback: (data) => {
                    setProduct(data);
                    setLoadingGetDetail(false)
                }
            })
        }
    }, [payload?.productId])

    const handleEdit = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_UPDATE_PRODUCT_POPUP,
            payload: {
                title: t("product.product_update"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                productId: payload?.productId,
                callback: () => {
                },
            }
        })
    }

    const handleDelete = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
            newWindow: true,
            payload: {
                title: t("Holon_Template.confirm_delete"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                isLoading: true,
                callback: (_props) => {
                    dispatch({
                        type: actionProduct.DELETE_PRODUCT,
                        payload: payload?.productId,
                        callback: {
                            success: () => {

                                _props?.hiddenPopup()
                                dispatch({
                                    type: actionProduct.GET_LIST_PRODUCT,
                                    payload: {
                                        ...router.getAll()
                                    }
                                });
                                showVisible();
                                showPopupNotification({
                                    callback: () => { },
                                    typePopup: 'message',
                                    newWindow: true,
                                    message: t("E0045")
                                })
                            },
                            failed: (message) => {
                                _props?.hiddenPopup();
                                EventRegister.emit(EVENT_SHOW_POPUP, {
                                    open: true,
                                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                                    newWindow: true,
                                    payload: {
                                        title: message,
                                        showHeader: false,
                                        customStyle: {
                                            padding: 0,
                                            borderTop: 0
                                        },
                                    }
                                })
                            }
                        },
                    })
                }
            }
        })
    }

    return (
        <BoxContentPopup minWidth="1000px">
            <WrapContentBody
                title={payload?.title} showVisible={showVisible} payload={payload}
                actionSectionTop={<>
                    <Button onClick={handleEdit} color='gray' startIcon={<IconEdit color="currentColor" />} /></>}
                actionSectionBottom={<Button onClick={handleDelete} loading={loadingGetDetail} type='button' title={t("Holon_Template.delete")} />}
            >
                <div className={classes['created-holon-instance']}>
                    <Box>
                        <BoxBody style={{ borderRadius: 10 }} className={classes['data-attributes-body']}>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.gs1_code")}</BoxLabel>
                                <BoxContent>
                                    {product?.jan_code}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.product_name")}</BoxLabel>
                                <BoxContent>
                                    {product?.item_name}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.category")}</BoxLabel>
                                <BoxContent>
                                    {product?.genre_name}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.product_price")}</BoxLabel>
                                <BoxContent>
                                    {convertNumberToString(product?.price, ",")} {product?.currency}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.origin")}</BoxLabel>
                                <BoxContent>
                                    {product?.origin}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.url")}</BoxLabel>
                                <BoxContent>
                                    <a href={product?.product_url} target='_blank' rel="noreferrer" >{product?.product_url}</a>
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.brand_name")}</BoxLabel>
                                <BoxContent>
                                    {product?.brand_name}
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.maker_name")}</BoxLabel>
                                <BoxContent>
                                    {product?.maker_name}
                                </BoxContent>
                            </BoxRow>
                            {/* <BoxRow>
                                <BoxLabel minWidth="140px">{t("product.remark")}</BoxLabel>
                                <BoxContent>
                                    {product?.note}
                                </BoxContent>
                            </BoxRow> */}
                        </BoxBody>
                    </Box>
                </div>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default ProductDetailPopup