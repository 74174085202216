import React, { useState, useEffect } from "react";
import useTrans from "hooks/use-trans";
import Box from "shared/components/form-input/box/Box";
import classes from "./SystemSettingContainer.module.scss";
import CustomTable from "shared/components/common/custom-table/CustomTable";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import { useForm } from "react-hook-form";
import Button from "shared/components/form-input/button/Button";
import FormUpdateInstance from 'shared/components/custom-form/FormUpdateInstance'
import { useDispatch, useSelector } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import actionHolonTemplate from 'redux/holon-template/action';
import systemSettingAction from 'redux/system-setting/action';
import Constants from "utils/Constants";
import FormSelectTemplate from 'shared/components/custom-form/FormSelectTemplate';
import { showPopupNotification } from 'utils/Utils';

const SystemSettingContainer = (props) => {
    const { t } = useTrans();
    const methods = useForm();
    const {
        loading: { loadingHolonInstanceSystem },
        holonInstanceSystem
    } = useSelector((state) => state.systemSetting);
    const [dataTable, setDataTable] = useState()
    const dispatch = useDispatch();

    const dataHeadCell = [
        {
            label: 'ID',
            field: "id",
            minWidth: "10px",
            width: "10px",
            maxWidth: "10px",
            alignHeader: "center",
            align: "center",
            // styleBody: {
            //     padding: "5px 20px",
            // },
            // styleHeader: {
            //     padding: "10px 20px",
            // },
        },
        {
            label: t("holon_system_setting.definition_code"),
            field: "code",
            minWidth: "100px",
            width: "100px",
            maxWidth: "100px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
        },
        {
            label: t("holon_system_setting.explanation"),
            field: "name",
            minWidth: "100px",
            width: "100px",
            maxWidth: "100px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
        },
        {
            label: t("holon_system_setting.classification"),
            field: "type",
            minWidth: "100px",
            width: "100px",
            maxWidth: "100px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (props) => {
                return (
                    <div>
                        {props?.data?.type === Constants.HOLON.INSTANCE ? t('holon.holon_instance') : t('Holon_Template.holon_template')}
                    </div>
                )
            }
        },
        {
            label: t("holon_system_setting.setting_data"),
            field: "id",
            minWidth: "100px",
            width: "100px",
            maxWidth: "100px",
            alignHeader: "left",
            align: "left",
            styleBody: {
                padding: "5px 20px",
            },
            styleHeader: {
                padding: "10px 20px",
            },
            component: (_props) => {
                return <div className={classes['buttonEdit']}>
                    {
                        _props?.data?.type === Constants.HOLON.INSTANCE
                            ? <FormUpdateInstance
                                disabled={false}
                                holonTemplate={_props?.data?.data}
                                fieldName={`attributes.${_props?.stt}.data`}
                            />
                            : <FormSelectTemplate fieldName={`attributes.${_props?.stt}.data`} />
                    }
                </div>

            },
        },
    ];

    useEffect(() => {
        getDataTableSystemSetting()
    }, [])

    useEffect(() => {
        if (holonInstanceSystem.length > 0) {
            setDataTable(holonInstanceSystem)
            methods.setValue('attributes', holonInstanceSystem)
        }
    }, [holonInstanceSystem])

    const onSubmit = (data) => {
        showPopupNotification({
            typePopup: 'update',
            message: t("E0042"),
            callback: () => {
                dispatch({
                    type: systemSettingAction.UPDATE_HOLON_MASTER_SYSTEM,
                    payload: data?.attributes,
                    onSuccess: onUpdateSuccess,
                    onFailure: onUpdateFailure,
                })
            },
        })

    }

    const onUpdateSuccess = () => {
        showPopupNotification({
            typePopup: 'message',
            newWindow: true,
            message: t("E0043")
        }),
        getDataTableSystemSetting()
    }

    const onUpdateFailure = (detail = {}) => {
        Object.keys(detail).forEach(key => {
            detail[key]?.map(msg => {
                showPopupNotification({
                    typePopup: 'message',
                    newWindow: true,
                    message: msg
                })
            })
        })
        getDataTableSystemSetting()
    }

    const getDataTableSystemSetting = () => {
        dispatch({
            type: systemSettingAction.GET_HOLON_MASTER_SYSTEM
        })
    }

    useEffect(() => {
        dispatch({
            type: actionsHolonInstance.HOLON_TEMPLATE_MASTER,
        })
        dispatch({
            type: actionHolonTemplate.GET_SEARCH_HOLON_TEMPLATE_MANAGEMENT,
        })
    }, [])

    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Box className={classes["SystemSetting"]}>
                    <div className={classes["HeaderSection"]}>
                        {t("holon_system_setting.holon_master_definition")}
                    </div>
                    <div className="tableSystemSetting">
                        <CustomTable
                            loading={loadingHolonInstanceSystem}
                            data={dataTable ?? []}
                            headCell={dataHeadCell}
                        />
                    </div>
                    <div className={classes['buttonSave']}>
                        <Button
                            loading={false}
                            className={classes['save']}
                            type="submit"
                            title={t('save')}
                        />
                    </div>
                </Box>
            </form>
        </CustomFormProvider>
    );
};

export default SystemSettingContainer;
