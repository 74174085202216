import useTrans from 'hooks/use-trans';
import { React, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import Box from '../../box/Box';
import BoxBody from '../../box/BoxBody';
import BoxContent from '../../box/BoxContent';
import BoxHeader from '../../box/BoxHeader';
import BoxLabel from '../../box/BoxLabel';
import BoxRow from '../../box/BoxRow';
import BoxTitle from '../../box/BoxTitle';
import WrapContentBody from '../custom-body/WrapContentBody';
import BoxContentPopup from '../custom-body/BoxContentPopup';
import CommonValueAttributes from 'shared/components/form-input/common-value-attributes/CommonValueAttributes';

function CompanyDetailPopup(props) {
    const { payload, showVisible } = props;
    const { t } = useTrans();
    const dispatch = useDispatch();
    const [dataHolonInstanceAttribute, setHolonInstanceAttribute] = useState([])
    const [holonInStanceDetail, setHolonInStanceDetail] = useState({})
    const [holonTemplateDetail, setHolonTemplateDetail] = useState({})

    useEffect(() => {
        if (holonTemplateDetail?.attributes?.length > 0 && holonInStanceDetail?.id) {
            setHolonInstanceAttribute(holonTemplateDetail?.attributes?.map(x => {
                let indexDetail = holonInStanceDetail?.attributes?.findIndex(attribute => attribute.name == x?.key);
                return {
                    ...x,
                    [x?.key]: holonInStanceDetail.attributes[indexDetail]?.value,
                    value: holonInStanceDetail.attributes[indexDetail]?.value,
                    name: holonInStanceDetail.attributes[indexDetail]?.name,
                }
            }))
        }
    }, [holonTemplateDetail, holonInStanceDetail])

    useEffect(() => {
        if (payload?.holonInstanceId) {
            dispatch({
                type: actionsHolonInstance.GET_HOLON_INSTANCE_DETAIL,
                payload: payload?.holonInstanceId,
                callback: (data) => {
                    setHolonInStanceDetail(data);
                }
            })
        }
        return () => {
            setHolonInStanceDetail({})
        }
    }, [payload?.holonInstanceId])

    useEffect(() => {
        if (payload?.holonTemplateId) {
            dispatch({
                type: actionsHolonInstance.HOLON_TEMPLATE_DETAIL_POPUP,
                payload: payload?.holonTemplateId,
                callback: (data) => {
                    setHolonTemplateDetail(data);
                }
            })
        }

        return () => {
            setHolonTemplateDetail({})
        }
    }, [payload?.holonTemplateId])

    const onClearHolonInstanceDetail = () => {
        showVisible(false);
        setHolonInStanceDetail(null);
        setHolonTemplateDetail(null)
    }

    return (
        <BoxContentPopup borderRadius='20px 0px 0px 20px' width='700px'>
            <WrapContentBody
                title={payload?.title}
                handleBtnBack={onClearHolonInstanceDetail}
                showVisible={showVisible}
                isUseBtnBottom={false}
            >
                <Box>
                    <BoxHeader>
                        <BoxTitle>{t("holon.basic_info")}</BoxTitle>
                    </BoxHeader>
                    <BoxBody>
                        {
                            dataHolonInstanceAttribute?.map((x, index) => {
                                return <BoxRow key={index}>
                                    <BoxLabel>
                                        {x?.label}
                                    </BoxLabel>
                                    <BoxContent>
                                        <CommonValueAttributes
                                            x={x}
                                        />
                                    </BoxContent>
                                </BoxRow>
                            })
                        }
                    </BoxBody>
                </Box>
            </WrapContentBody>
        </BoxContentPopup>
    )
}

export default CompanyDetailPopup