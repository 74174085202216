import useTrans from 'hooks/use-trans';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import FormSelect from 'shared/components/custom-form/FormSelect';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import BoxRow from 'shared/components/common/box/BoxRow';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import BoxContent from 'shared/components/common/box/BoxContent';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'shared/components/form-input/button/Button';
import OrcTable from './OcrTable';
import actionOCR from 'redux/ocr/action';
import actionBPFare from 'redux/bp-fare/action';
import Validator from 'utils/Validator';
import { getMessageCommon, showPopupNotification } from 'utils/Utils';
import FormUploadMultipartFile from 'shared/components/custom-form/FormUploadMultipartFile';
import classes from './BpFareTableManagementContainer.module.scss';

function BpFareTableManagementContainer() {
    const dispatch = useDispatch();
    const { t } = useTrans();
    const methods = useForm({
    });
    const [dataTemplate, setDataTemplate] = useState([])
    const [loading, setLoading] = useState(false)
    const [bpFareUnitPrices, setBPFareUnitPrices] = useState()
    const [price, setPrice] = useState();
    const document_id_pdf = 'pdf';

    useEffect(() => {
        dispatch({
            type: actionOCR.GET_TEMPLATE_OCR_TABLE,
            callback: {
                success: (data) => {
                    if (data?.length > 0) {
                        setDataTemplate(data?.map(x => {
                            return {
                                label: x?.document_name,
                                value: x?.document_id,
                                id: x?.document_id,
                                templateId: x?.holon_template_id,
                            }
                        }))
                    }
                }
            }
        })
    }, [])

    const onSubmit = (data) => {
        setLoading(true)
        dispatch({
            type: actionOCR.LOAD_DATA_BY_DOCUMENT_ID_AND_MULTIPART_FILE,
            payload: {
                file: data?.file,
                document_id: data?.ocr
            },
            callback: {
                success: (_data) => {
                    setLoading(false)
                    if (_data?.length > 0) {
                        setPrice(_data[0].mapData)
                        dispatch({
                            type: actionBPFare.GET_BP_FARE_BY_UNIT_PRICE_CODES,
                            payload: {
                                unitPriceCodes: Object.keys(_data[0].mapData)
                            },
                            callback: {
                                success: (_data) => {
                                    setLoading(false)
                                    setBPFareUnitPrices(_data.data)
                                },
                                failed: (msg) => {
                                    setLoading(false);
                                    showPopupNotification({
                                        typePopup: 'message',
                                        message: msg
                                    })
                                }
                            }
                        })
                    }
                },
                failed: (response) => {
                    setLoading(false);
                    showPopupNotification({
                        typePopup: 'message',
                        message: getMessageCommon({
                            response: response,
                            actionName: t("bp_fare_table_management.load_data")
                        })
                    })
                }
            }
        })
    }


    function getFileExtension(fileName) {
        // Split the file name into an array using the dot as a separator
        const parts = fileName.split('.');

        // Check if there's more than one part (i.e., there is an extension)
        if (parts.length > 1) {
            // Return the last part (which is the file extension)
            return parts[parts.length - 1].toLowerCase();
        } else {
            // If there's only one part, there's no extension
            return '';
        }
    }

    let fileExtension = null;
    if (methods.watch('file') && methods.watch('file')?.name) {
        fileExtension = getFileExtension(methods.watch('file')?.name);
    }
    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <ContentLayout>
                        <Breadcrumb>{t("bp_fare_table_management.title")}</Breadcrumb>
                        <BoxRow style={{ flex: 1 }}>
                            <BoxLabel minWidth={230}>{t("bp_fare_table_management.shipping_company_selection")}</BoxLabel>
                            <BoxContent>
                                <FormSelect
                                    fieldName='ocr'
                                    options={[...dataTemplate]}
                                    placeholder={t("bp_fare_table_management.shipping_company_selection")}
                                    validate={[Validator.required]}
                                    validationFE
                                    isRegexLabel={t("bp_fare_table_management.shipping_company_selection")}
                                />
                            </BoxContent>
                        </BoxRow>
                        <BoxRow style={{ flex: 1 }}>
                            <BoxLabel minWidth={230}>{t("bp_fare_table_management.estimate")}</BoxLabel>
                            <BoxContent>
                                <FormUploadMultipartFile fieldName='file' buttonComponent={<Button title={t("bp_fare_table_management.selection")} />} />
                                <div className={classes['text-waring']}>
                                    {
                                        fileExtension == document_id_pdf ? t("processing_may_take_some_time_please_wait") : ''
                                    }
                                </div>
                            </BoxContent>
                        </BoxRow>
                        <BoxRow style={{ flex: 1, justifyContent: "center" }}>
                            <Button title={t("bp_fare_table_management.load_data")} type='submit' loading={loading} />
                        </BoxRow>
                    </ContentLayout>
                </form>
            </FormProvider >
            {bpFareUnitPrices && price && <OrcTable bpFareUnitPrices={bpFareUnitPrices} price={price} />}
        </>
    )
}

export default BpFareTableManagementContainer