import React, { useEffect, useState } from "react";

import useTrans from "hooks/use-trans";
import useRouter from "hooks/use-router";
import { useSelector } from "react-redux";
import classes from "./PostDetail.module.scss";
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import { useForm } from "react-hook-form";
import Button from "shared/components/form-input/button/Button";
import IconClose from "assets/icon/IconClose";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_ESTIMATE_FARE, EVENT_SHOW_CREATE_INQUIRY_POPUP, EVENT_SHOW_POPUP_USER_INQUIRY } from 'utils/EventRegister';
import Cookies from 'js-cookie';
import { isRouteRoleUser } from 'utils/Utils';


const PostDetail = (props) => {
    const { showVisible } = props;
    const {
        fareServiceDetail,
    } = useSelector((state) => state.fareCalculation);
    const { t } = useTrans();
    const methods = useForm();
    const token = Cookies.get("accessToken");
    const router = useRouter()
    const [isShowInquiryPage, setShowInquiryPage] = useState(true);

    useEffect(() => {
        if (token) {
            setShowInquiryPage(isRouteRoleUser('/portal/inquiry'))
        }
    }, [isShowInquiryPage])

    const handleRegister = () => {
        if (token) {
            router.push({
                pathname: "/portal/inquiry"
            })
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                type: EVENT_SHOW_CREATE_INQUIRY_POPUP,
                payload: {
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0,
                    },
                    callback: () => { },
                },
            });
        } else {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                type: EVENT_SHOW_POPUP_USER_INQUIRY,
                open: true,
                payload: {
                    showHeader: true,
                    title: t("Inquiry.header_title"),
                    customStyle: {
                        maxHeight: 'calc(100vh - 150px)'
                    },
                    callback: () => { },
                }
            })
        }
    }

    const handleClose = () => {
        showVisible();
        if (!token) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                type: EVENT_SHOW_POPUP_ESTIMATE_FARE,
                open: true,
                payload: {
                    title: t("home.estimated_fare"),
                    customStyle: {
                        maxHeight: 'calc(100vh - 150px)'
                    },
                }
            })
        }
    };

    const renderHeaderPopup = () => {
        return (
            <div className={classes["Header"]}>
                <div className={classes["HeaderLeft"]}>
                    <div className={classes["Icon"]}>
                    </div>
                    <div className={classes["PopupTitle"]}>
                        <p>{t("fare_calculation.cross_border_courier_service")}</p>
                    </div>
                </div>
                <div className={classes["HeaderRight"]}>
                    <Button
                        onClick={() => handleClose()}
                        color="gray"
                        startIcon={
                            <IconClose
                                fontSize={14}
                                color="#106574"
                            />
                        }
                    />
                </div>
            </div>
        );
    };

    const renderInformationPopup = () => {
        return (
            <div className={classes['content']} dangerouslySetInnerHTML={{ __html: fareServiceDetail?.data?.content }}>
            </div>
        )
    }

    const renderContentPopup = () => {
        return (
            <div className={classes['contentPopup']}>
                {renderInformationPopup()}
                <div className={classes['contentFooter']}>
                    <div className={classes["btn-form"]}>
                        <button onClick={() => { handleClose() }} type="button" className={classes['btn-cancel']}>{t('Holon_Template.cancel')}</button>
                    </div>
                    {isShowInquiryPage && <div className={classes['postInquiry']}>
                        <p onClick={() => {
                            handleRegister()
                        }} >{t('fare_calculation.inquiry')}</p>
                    </div>}
                </div>
            </div>
        )
    }

    return (
        <CustomFormProvider {...methods}>
            <form>
                <div className={classes["CrossBorderCourierService"]}>
                    {renderHeaderPopup()}
                    {renderContentPopup()}
                </div>
            </form>
        </CustomFormProvider>
    );
};

export default PostDetail;
