import React from 'react'

function IconRowRight({ fontSize = 24 }) {
    return (
        <>
            <svg fontSize={fontSize} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.3536 4.64648L21.7071 12L14.3536 19.3536L13.6464 18.6465L19.7929 12.5H3V11.5H19.7929L13.6464 5.35359L14.3536 4.64648Z" fill="#333333" />
            </svg>
        </>
    )
}

export default IconRowRight