import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import MenuManagementAction from "./action";
import factory from "./factory";
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE } from "utils/EventRegister";

function* getMenuManagement() {
    yield takeLatest(MenuManagementAction.GET_MENU_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.getMenuManagement(payload?.payload));
            const responseTemplate = yield call(() => factory.getMasterHolonTemplate(payload?.payload));

            const data = response?.records?.map(x => {
                x.menuSettings = x?.menuSettings?.reduce((obj, cur) => ({ ...obj, [cur?.userRole]: cur }), {});
                return x;
            });

            yield put({
                type: MenuManagementAction.GET_MENU_MANAGEMENT_SUCCESS,
                payload: {
                    menu: {
                        ...response,
                        records: data,
                    },
                    templateHolon: responseTemplate
                }
            })
        } catch (error) {
            yield put({
                type: MenuManagementAction.GET_MENU_MANAGEMENT_FAILED
            })
        }
    })
}

function* getMasterHolonTemplate() {
    yield takeEvery(MenuManagementAction.GET_HOLON_TEMPLATE_MENU_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.getMasterHolonTemplate(payload?.payload));

            yield put({
                type: MenuManagementAction.GET_HOLON_TEMPLATE_MENU_MANAGEMENT_SUCCESS,
                payload: response
            })
        } catch (error) {
            yield put({
                type: MenuManagementAction.GET_HOLON_TEMPLATE_MENU_MANAGEMENT_FAILED
            })
        }
    })
}

function* updatePermission() {
    yield takeEvery(MenuManagementAction.UPDATE_PERMISTION_MENU_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.updatePermissin(payload?.payload));
            if (!response?.error) {
                yield put({
                    type: MenuManagementAction.UPDATE_PERMISTION_MENU_MANAGEMENT_SUCCESS,
                    payload: response
                })
                yield payload?.callback?.success && payload?.callback?.success()
            }else{
                yield payload?.callback?.failed && payload?.callback?.failed()
                EventRegister.emit(EVENT_SHOW_POPUP, {
                    open: true,
                    type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                    newWindow: true,
                    payload: {
                        title: response?.error?.detail?.exception[0],
                        showHeader: false,
                        customStyle: {
                            padding: 0,
                            borderTop: 0
                        },
                    }
                })
            }
        } catch (error) {
            yield put({
                type: MenuManagementAction.UPDATE_PERMISTION_MENU_MANAGEMENT_FAILED
            })
        }
    })
}

function* getMenuMasterByCode() {
    yield takeEvery(MenuManagementAction.GET_MENU_MANAGEMENT_BY_CODE, function* (payload) {
        try {
            const response = yield call(() => factory.getMenuMasterByCode(payload?.payload));

            if (response?.data) {
                yield put({
                    type: MenuManagementAction.GET_MENU_MANAGEMENT_BY_CODE_SUCCESS,
                    payload: response?.data
                })
                yield payload?.callback?.success && payload?.callback?.success()
            }
            else {
                yield put({
                    type: MenuManagementAction.GET_MENU_MANAGEMENT_BY_CODE_FAILURE,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            yield put({
                type: MenuManagementAction.GET_MENU_MANAGEMENT_BY_CODE_FAILURE,
            })
        }
    })
}

function* createMenuMaster() {
    yield takeEvery(MenuManagementAction.CREATE_MENU_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.createMenuMaster(payload?.payload));

            if (response?.data) {
                yield put({
                    type: MenuManagementAction.CREATE_MENU_MANAGEMENT_SUCCESS,
                    payload: response?.data
                })
                yield payload?.callback?.success && payload?.callback?.success()
            }
            else {
                yield put({
                    type: MenuManagementAction.CREATE_MENU_MANAGEMENT_FAILURE,
                    payload: response?.error?.detail,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
                // EventRegister.emit(EVENT_SHOW_POPUP, {
                //     open: true,
                //     type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
                //     newWindow: true,
                //     payload: {
                //         title: response?.error?.detail?.exception[0],
                //         showHeader: false,
                //         customStyle: {
                //             padding: 0,
                //             borderTop: 0
                //         },
                //     }
                // })
            }
        } catch (error) {
            yield put({
                type: MenuManagementAction.CREATE_MENU_MANAGEMENT_FAILURE,
            })
        }
    })
}

function* updateSortMenuMaster() {
    yield takeEvery(MenuManagementAction.UPDATE_SORT_MENU_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.updateSortMenuMaster(payload?.payload));

            if (response?.data) {
                yield put({
                    type: MenuManagementAction.UPDATE_SORT_MENU_MANAGEMENT_SUCCESS,
                    payload: response?.data
                })
                yield payload?.callback?.success && payload?.callback?.success()
            }
            else {
                yield put({
                    type: MenuManagementAction.UPDATE_SORT_MENU_MANAGEMENT_FAILURE,
                    payload: response?.error?.detail,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            yield put({
                type: MenuManagementAction.UPDATE_SORT_MENU_MANAGEMENT_FAILURE,
            })
        }
    })
}

function* deleteMenuMaster() {
    yield takeEvery(MenuManagementAction.DELETE_MENU_MANAGEMENT, function* (payload) {
        try {
            const response = yield call(() => factory.deleteMenuMaster(payload?.payload));
            if (response?.data) {
                yield put({
                    type: MenuManagementAction.DELETE_MENU_MANAGEMENT_SUCCESS,
                    payload: response?.data
                })
                yield payload?.callback?.success && payload?.callback?.success()
            }
            else {
                yield put({
                    type: MenuManagementAction.DELETE_MENU_MANAGEMENT_FAILURE,
                    payload: response?.error?.detail,
                })
                yield payload?.callback?.failed && payload?.callback?.failed()
            }
        } catch (error) {
            yield put({
                type: MenuManagementAction.DELETE_MENU_MANAGEMENT_FAILURE,
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getMenuManagement),
        fork(getMasterHolonTemplate),
        fork(updatePermission),
        fork(getMenuMasterByCode),
        fork(createMenuMaster),
        fork(updateSortMenuMaster),
        fork(deleteMenuMaster),
    ])
}