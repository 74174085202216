import useTrans from 'hooks/use-trans';
import React, { useEffect } from 'react'
import FaqManagementContainer from 'shared/container/faq/FaqManagementContainer'

function FaqManagementPage() {
    const { t } = useTrans();
    useEffect(() => {
        document.title = t("faq_management.faq_management");
    }, [])
    return (
        <FaqManagementContainer />
    )
}

export default FaqManagementPage