import React, { useCallback, useEffect, useState } from 'react'
import classes from './ListProductPopup.module.scss';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import { useForm, useFormContext } from 'react-hook-form';
import CustomPagination from '../../custom-pagination/CustomPagination';
import FormSearch from '../../form-search/FormSearch';
import FormItem from '../../form-search/FormItem';
import FormInput from 'shared/components/custom-form/FormInput';
import IconSearch from 'assets/icon/IconSearch';
import IconClose from 'assets/icon/IconClose';
import FormGroup from '../../form-search/FormGroup';
import WrapContentBody from '../custom-body/WrapContentBody';
import { useDispatch } from 'react-redux';
import actionCargo from 'redux/cargo/action';
import Constants from 'utils/Constants';
import { debounce } from 'lodash';
import IconPlusWhite from 'assets/icon/IconPlusWhite';
import EventRegister, { EVENT_SHOW_CREATE_PRODUCT_POPUP, EVENT_SHOW_POPUP } from 'utils/EventRegister';
import ButtonAdd from 'shared/components/form-input/button/ButtonAdd';
import { getMessageCommon, showPopupNotification } from 'utils/Utils';
import { v4 as uuidv4 } from 'uuid'
import TableLeft from './TableLeft';
import TableRight from './TableRight';

function ListCommon(props) {
    const isMulti = props?.isMulti == false ? props?.isMulti : true;
    const {
        data,
        onChangeSaveListSelected,
        listDataActive,
        loading,
        handleChangePageListData,
        onSearchListData,
        sectionTab,
        isGetFromProductMaster,
        isShowAddBtn,
        dataTemplate,
        keyword,
        errorMessage,
        cardboardType,
        listError
    } = props;
    const [currentListAll, setCurrentListAll] = useState([]);
    const [currentList, setCurrentList] = useState([]);
    const [paginationListSelected, setPaginationListSelected] = useState(1);
    const { t } = useTrans();
    const [listSelected, setListSelected] = useState([])
    const [listSelectedAll, setListSelectedAll] = useState([]);
    const [keySearchSelect, setKeySearchSelect] = useState(null);

    const methods = useFormContext({
        defaultValues: {
            listChoiceAll: [],
            listChoice: [],
        }
    });

    let fieldsChoice = methods.watch("listChoice");

    useEffect(() => {
        if (data?.records?.length > 0) {
            if (listDataActive?.length > 0) {
                let newList = [...data?.records];
                setCurrentListAll(data?.records)
                let temp = [];
                [...newList]?.map(x => {
                    if (listDataActive?.findIndex(v => v?.id == x?.id) == -1) {
                        temp.push(x);
                    }
                })
                setCurrentList(temp);
            } else {
                setCurrentList(data?.records);
                setCurrentListAll(data?.records);
            }
        } else {
            setCurrentList([]);
            setCurrentListAll([]);
        }
    }, [data?.records, listDataActive]);

    useEffect(() => {
        if (listDataActive?.length > 0) {
            setListSelectedAll(listDataActive);
            setListSelected(sliceArray(convertListSelect(listDataActive, keySearchSelect), paginationListSelected));

            methods.setValue("listChoiceAll", listSelected)
            methods.setValue("listChoice", sliceArray(convertListSelect(listDataActive, keySearchSelect), paginationListSelected))
        } else {
            setListSelected([]);
            setListSelectedAll([]);
            methods.setValue("listChoiceAll", [])
            methods.setValue("listChoice", [])
        }
    }, [listDataActive, keySearchSelect, paginationListSelected])

    let convertListSelect = (_listSelectTemplateAll, _keySearchSelect) => {
        if (_keySearchSelect) {
            let list = [..._listSelectTemplateAll]?.filter(item => item?.name?.toLowerCase().indexOf(_keySearchSelect?.toLowerCase()) > -1 || item?.category?.toLowerCase().indexOf(_keySearchSelect?.toLowerCase()) > -1);
            return list;
        }
        return _listSelectTemplateAll;
    }

    let sliceArray = (data = [], from) => {
        return [...data]?.slice((from - 1) * 10, from * 10);
    }

    const handleAdd = (item) => {
        item = {
            ...item,
            state: Constants.CARGO_MASTER_CODE.REGEX_PRODUCT.UPDATE,
            quantity: !isGetFromProductMaster ? item?.quantity : 1,
            maxQuantity: !isGetFromProductMaster ? item?.quantity : null
        }
        let newList = [...currentList];
        let indexRemove = newList?.findIndex(x => x?.id == item?.id);
        if (indexRemove != -1) {
            let newListChoiceTemplateAll = [...listSelectedAll]
            newListChoiceTemplateAll.push(item)
            onChangeSaveListSelected(newListChoiceTemplateAll);
            let temp = [];
            [...newList]?.map(x => {
                if (newListChoiceTemplateAll?.findIndex(v => v?.id == x?.id) == -1) {
                    temp.push(x);
                }
            })
            setCurrentList(temp)
        }
    }

    const handleRemove = (item) => {
        let newListChoiceTemplateAll = [...listSelectedAll];
        let indexRemove = newListChoiceTemplateAll?.findIndex(x => x?.id == item?.id);
        if (indexRemove != -1) {

            let newListTemplate = [...currentList];
            let indexTemplateAll = currentListAll?.findIndex(x => x?.id == item?.id);
            if (indexTemplateAll != -1) {
                newListTemplate.push(currentListAll[indexTemplateAll]);
                // setCurrentList(newListTemplate);
            } else {

            }

            let page = paginationListSelected;

            newListChoiceTemplateAll.splice(indexRemove, 1);
            if (paginationListSelected > Math.ceil(newListChoiceTemplateAll?.length / 10)) {
                page = page - 1;
                setPaginationListSelected(page == 0 ? 1 : page)
            }

            onChangeSaveListSelected(newListChoiceTemplateAll);

            let _pageListSelect = paginationListSelected;
            if (newListChoiceTemplateAll?.length == 0) {
                setPaginationListSelected(1)
            } else
                if (_pageListSelect > Math.ceil(newListChoiceTemplateAll?.length / 10)) {
                    _pageListSelect = _pageListSelect - 1;
                    setPaginationListSelected(_pageListSelect)
                }
        }
    }

    const handleChangePaginationListChoice = (e, keySearchSelect) => {
        setListSelected(sliceArray(convertListSelect(listSelectedAll, keySearchSelect), e));
        setPaginationListSelected(e);
    }

    const handleChangePaginationList = (e, keyword) => {
        handleChangePageListData(e, keyword);
    }

    const searchHolonTemplate = useCallback(
        debounce(async (e) => {
            onSearchListData(e);
        }, 500),
        [],
    );

    const onChangeList = (e) => {
        searchHolonTemplate(e)
    }

    const searchSelectedHolonTemplate = useCallback(
        debounce(async (e) => {
            if (e) {
                let list = [];
                list = [...listDataActive]?.filter(item => item?.name?.toLowerCase().indexOf(e?.toLowerCase()) > -1 || item?.category?.toLowerCase().indexOf(e?.toLowerCase()) > -1);
                setListSelected(list?.slice(0, 10));
                setPaginationListSelected(1);
            } else {
                let list = [...listDataActive];
                setListSelected(list?.slice(0, 10));
                setPaginationListSelected(1)
                setKeySearchSelect(null)
            }
        }, 500),
        [listDataActive],
    );

    const onChangeListChoice = (e) => {
        let search =  e?.trim();
        searchSelectedHolonTemplate(search);
        setKeySearchSelect(search)
    }

    const handleAddInstance = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_CREATE_PRODUCT_POPUP,
            newWindow: true,
            payload: {
                title: t("product.product_create"),
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                callbackResult: (product) => {
                    let result = {
                        id: uuidv4(),
                        hexId: null,
                        createProductInstance: null,
                        name: product?.item_name,
                        janCode: product?.jan_code,
                        price: product?.price,
                        quantity: 1,
                        productUrl: product?.product_url,
                        state: Constants.CARGO_MASTER_CODE.REGEX_PRODUCT.CREATE,
                        brandName: product?.genre_name,
                        genreId: product?.genre_id,
                        makerName: product?.origin,
                        note: product?.note,
                        productImage: "",
                        rakutenProductId: "",
                        warehouseId: null,
                        individualExportDeclaration: null
                    };

                    let _arr = [...listSelectedAll];
                    _arr.unshift(result);
                    onChangeSaveListSelected(_arr);
                },
            }
        })
    }

    const onChangeQuantity = (e, x) => {
        let index = listSelectedAll?.findIndex(v => x?.id == v?.id);
        if (index != -1) {
            listSelectedAll[index].quantity = e;
            onChangeSaveListSelected(listSelectedAll);
        }
    }

    const onChangePrice = (e, x) => {
        let index = listSelectedAll?.findIndex(v => x?.id == v?.id);
        if (index != -1) {
            listSelectedAll[index].price = e;
            onChangeSaveListSelected(listSelectedAll);
        }
    }

    const onChangeCheckbox = (e, x) => {
        let index = listSelectedAll?.findIndex(v => x?.id == v?.id);
        if (index != -1) {
            listSelectedAll[index].individualExportDeclaration = listSelectedAll[index].individualExportDeclaration ? false : true;
            onChangeSaveListSelected(listSelectedAll);
        }
    }

    const onChangeNote = (e, x) => {
        let index = listSelectedAll?.findIndex(v => x?.id == v?.id);
        if (index != -1) {
            listSelectedAll[index].note = e;
            onChangeSaveListSelected(listSelectedAll);
        }
    }

    return <>
        <div className={classes['wrap-content']}>
            {sectionTab}
            <div className={classes['selected-container']}>
                <div className={classes['list']}>
                    <div className={classes['content-list']}>
                        {
                            errorMessage ? <span style={{ color: 'FFC200', fontSize: 12, marginBottom: 5 }}>{errorMessage}</span> : <></>
                        }
                        <FormSearch className={classes['custom-form-search']}>
                            <FormGroup>
                                <FormItem >
                                    <FormInput
                                        fieldName='Search'
                                        background='white'
                                        className="custom-input"
                                        placeholder={t("search_by_word")}
                                        iconSearch={<IconSearch fontSize={14} />}
                                        onChangeValue={(e) => {
                                            onChangeList(e)
                                        }}
                                        isSearch
                                    />
                                </FormItem>
                            </FormGroup>
                        </FormSearch>
                        <TableLeft
                            isGetFromProductMaster={isGetFromProductMaster}
                            errorMessage={errorMessage}
                            currentList={currentList}
                            loading={loading}
                            handleAdd={handleAdd}
                        />
                    </div>
                    <div className={classes['custom-pagination-popup']}>
                        <CustomPagination page={data?.page ?? 1} limit={Constants.PAGINATION.LIMIT} total={data?.total ?? 0} onChange={(e) => handleChangePaginationList(e, keyword)} />
                    </div>
                </div>
                <div className={`${classes['list']} ${classes['list-selected']}`}>
                    <div className={classes['content-list']}>
                        {
                            listError?.length > 0 ? <div className={classes['content-error']}>
                                {
                                    listError?.map((x, index) => {
                                        return <span key={index} style={{ color: 'FFC200', fontSize: 12, marginBottom: 5 }}>{x}</span>
                                    })
                                }
                            </div> : <></>
                        }
                        <FormSearch className="custom-form-search">
                            <FormGroup>
                                <FormItem >
                                    <FormInput
                                        fieldName='Search_choice'
                                        background='white'
                                        className="custom-input"
                                        placeholder={t("search_by_word")}
                                        iconSearch={<IconSearch fontSize={14} />}
                                        onChangeValue={(e) => {
                                            onChangeListChoice(e)
                                        }}
                                        isSearch
                                    />
                                </FormItem>
                                {
                                    isShowAddBtn && <FormItem maxWidth={40}>
                                        <ButtonAdd
                                            onClick={handleAddInstance}
                                            color='yellow'
                                            size='large'
                                            startIcon={<IconPlusWhite />}
                                        />
                                    </FormItem>
                                }
                            </FormGroup>
                        </FormSearch>
                        <TableRight
                            errorMessage={errorMessage}
                            fieldsChoice={fieldsChoice}
                            cardboardType={cardboardType}
                            onChangeQuantity={onChangeQuantity}
                            onChangePrice={onChangePrice}
                            onChangeCheckbox={onChangeCheckbox}
                            onChangeNote={onChangeNote}
                            handleRemove={handleRemove}
                            isGetFromProductMaster={isGetFromProductMaster}
                        />
                    </div>
                    <div className={classes['custom-pagination-popup']}>
                        <CustomPagination page={paginationListSelected} limit={10} total={listSelectedAll?.length ?? 0} onChange={(e) => handleChangePaginationListChoice(e, keySearchSelect)} />
                    </div>
                </div>
            </div>
        </div>
    </>
}

function ListProductSelectionPopup(props) {
    const { payload, showVisible } = props;
    const methods = useForm();
    const [listSelectedAll, setListSelectedAll] = useState([]);
    const [listProductRight, setListProductRight] = useState([]);
    const [dataTemplate, setDataTemplate] = useState([]);
    const [data, setData] = useState({});
    const [isGetFromProductMaster, setIsGetFromProductMaster] = useState(false);
    const [loadingCreateInstance, setLoadingCreateInstance] = useState(false);
    const [cardboardType, setCardboardType] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const [listError, setListError] = useState([]);
    const [isShowAddBtn, setIShowAddBtn] = useState(false);
    const [loadingGetList, setLoadingGetList] = useState(false);
    const [keyword, setKeyword] = useState(null);
    const { t } = useTrans();
    const dispatch = useDispatch();

    const handleOke = () => {
        let dataProduct = [...listSelectedAll]?.map(x => {
            return {
                ...x,
                id: Number(x?.id) || 0
            }
        });
        if (listProductRight?.length == 0) {

        } else {
            listProductRight?.map(x => {
                let index = dataProduct?.findIndex(p => p?.id == x?.id);
                if (index == -1) {
                    dataProduct.push({
                        ...x,
                        state: Constants.CARGO_MASTER_CODE.REGEX_PRODUCT.DELETE
                    })
                }
            })
        }
        setLoadingCreateInstance(true)
        dispatch({
            type: actionCargo.CREATE_PRODUCT_DETAIL_CARGO,
            payload: {
                data: dataProduct,
                cargoId: parseInt(payload?.['holon_id']),
            },
            callback: {
                success: (response) => {
                    setLoadingCreateInstance(false)
                    showVisible(false)
                    if (payload?.callback) {
                        payload?.callback();
                    }
                    if (payload?.dataBox?.callback) {
                        payload?.dataBox?.callback()
                    }
                    setListError(response?.errors);

                    showPopupNotification({
                        callback: () => { },
                        typePopup: 'message',
                        newWindow: true,
                        message: t("E0041")
                    })
                },
                failed: (error) => {
                    setLoadingCreateInstance(false);
                    showPopupNotification({
                        typePopup: 'message',
                        message: getMessageCommon({
                            response: null,
                            error: error,
                        }),
                        newWindow: true
                    });
                }
            }
        })
    }

    const handleSaveHolonInstance = (_items) => {
        setListSelectedAll(_items);
    }

    const handleChangePageListData = (e, keyword) => {
        let params = {
            [Constants.QueryParams.OFFSET.VALUE]: e
        };
        if (keyword) {
            params[Constants.QueryParams.KEYWORD.VALUE] = keyword;
        }
        handleLoadData(params)
    }
    const onSearchListData = (e) => {
        setKeyword(e?.trim());
        handleLoadData({
            [Constants.QueryParams.KEYWORD.VALUE]: e?.trim()
        })
    }

    useEffect(() => {
        let params = {};
        if (keyword) {
            params[Constants.QueryParams.KEYWORD.VALUE] = keyword;
        }
        handleLoadData(params, (data) => {
            setListSelectedAll(data)
        });
        dispatch({
            type: actionCargo.GET_TEMPLATE_CARGO,
            payload: {
                masterCodes: Constants.CARGO_MASTER_CODE.PRODUCT.MASTER_CODE
            },
            callback: {
                success: (data) => {
                    setDataTemplate(data?.records)
                }
            }
        })

    }, [])

    const handleLoadData = (params, callback) => {
        setLoadingGetList(true);
        dispatch({
            type: actionCargo.GET_LIST_PRODUCT_CARGO_POPUP,
            payload: {
                [Constants.QueryParams.LIMIT.VALUE]: Constants.PAGINATION.LIMIT,
                [Constants.QueryParams.OFFSET.VALUE]: 1,
                holon_id: payload?.['holon_id'],
                ...params
            },
            callback: {
                success: (data) => {
                    setData({
                        ...data?.productInfoLeft, records: data?.productInfoLeft?.records?.map(x => {
                            return {
                                ...x,
                                price: Math.round(x?.price)
                            }
                        })
                    });
                    setLoadingGetList(false);
                    setIsGetFromProductMaster(data?.isGetFromProductMaster)
                    setIShowAddBtn(data?.isShowAddBtn)
                    setListProductRight(data?.productInfoRight);
                    setMessageError(data?.errorMessage);
                    setCardboardType(data?.isShowIndividualExportDeclaration);
                    callback && callback(data?.productInfoRight)
                },
                failed: () => {
                    setLoadingGetList(false);
                }
            }
        })
    }

    const onSubmit = () => {

    }

    return (
        <div className={classes['UserListPopup']}>
            <CustomFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WrapContentBody
                        actionSectionBottom={<Button disabled={messageError ? true : false} loading={loadingCreateInstance} onClick={handleOke} title={t("save")} />}
                        showVisible={showVisible} handleBtnBack={() => showVisible(false)}
                        title={t("product.product_section")}
                        isUseBtnBack={false}
                        useForm={false}
                        actionSectionTop={<div onClick={() => !loadingCreateInstance && showVisible()} className={classes['icon-close']}>
                            <IconClose color="#929292" fontSize={20} />
                        </div>}
                    >
                        <ListCommon
                            isMulti={payload?.isMulti}
                            onChangeSaveListSelected={handleSaveHolonInstance}
                            listDataActive={listSelectedAll ?? []}
                            loading={loadingGetList}
                            handleChangePageListData={handleChangePageListData}
                            onSearchListData={onSearchListData}
                            data={{
                                records: data?.records,
                                page: data?.page,
                                total: data?.total
                            }}
                            keyword={keyword}
                            isGetFromProductMaster={isGetFromProductMaster}
                            isShowAddBtn={isShowAddBtn}
                            dataTemplate={dataTemplate}
                            errorMessage={messageError}
                            cardboardType={cardboardType}
                            listError={listError}
                        />
                    </WrapContentBody>
                </form>
            </CustomFormProvider>
        </div>
    )
}

export default ListProductSelectionPopup