import React, { useEffect } from "react";

import useTrans from "hooks/use-trans";
import { useForm } from "react-hook-form";
import useRouter from 'hooks/use-router';

import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import Button from "shared/components/form-input/button/Button";
import FormSelect from "shared/components/custom-form/FormSelect";
import FormDatePicker from 'shared/components/custom-form/FormDatePicker';
import Utils from "utils/Utils";
import Constants from "utils/Constants";

import CustomHeader from "../custom-header/CustomHeader";
import classes from "./InquiryConditionSearchPopup.module.scss";
import BoxContentPopup from "../custom-body/BoxContentPopup";
import WrapContentBody from "../custom-body/WrapContentBody";
import Box from "../../box/Box";
import BoxHeader from "../../box/BoxHeader";
import BoxBody from "../../box/BoxBody";
import BoxTitle from "../../box/BoxTitle";

const InquiryConditionSearchPopup = (props) => {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const methods = useForm();
    const router = useRouter();

    const inquiryStatusList = Utils.convertObjectKeyToArr(Constants.INQUIRY_PROCESS_STATUS);

    useEffect(() => {
        const currentParamRouter = router.getAll()
        if (!currentParamRouter) {
            return;
        }

        const createdDateFrom = currentParamRouter.created_date_from ?? null
        const createdDateTo = currentParamRouter.created_date_to ?? null
        const status = currentParamRouter.status ?? null

        methods.setValue("created_date_from", createdDateFrom)
        methods.setValue("created_date_to", createdDateTo)
        methods.setValue("status", status)

    }, []);

    const onSubmit = (data) => {
        showVisible();
        payload?.callback((data));
    };

    return (
        <div>
            {/* <CustomHeader
                showVisible={showVisible}
                title={t("Holon_Template.add_search_conditions")}
            /> */}
            <BoxContentPopup>
                <CustomFormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <WrapContentBody
                            showVisible={showVisible}
                            title={t("Holon_Template.add_search_criteria")}
                            actionSectionBottom={<Button color='gray' type='submit' title={t("Holon_Template.search")} />}
                        >
                            <Box>
                                <BoxHeader>
                                    <BoxTitle>
                                        {t("Holon_Template.add_search_conditions")}
                                    </BoxTitle>
                                </BoxHeader>
                                <BoxBody>
                                    <div className={classes['InquiryConditionSearchPopup']}>
                                        <div className={classes["WrapContent"]}>
                                            <div className={classes["WrapContentLeft"]}>
                                                <p className={classes["WrapContentLeftTitle"]}>{t("inquiry.inquiry_status")}</p>
                                            </div>
                                            <div className={classes["WrapContentRight"]}>
                                                <FormSelect
                                                    options={inquiryStatusList}
                                                    placeholder={t("inquiry.inquiry_status")}
                                                    size="large"
                                                    fieldName={"status"}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes["WrapContent"]}>
                                            <div className={classes["WrapContentLeft"]}>
                                                <p className={classes["WrapContentLeftTitle"]}>{t("Holon_Template.from")}</p>
                                            </div>
                                            <div className={classes["WrapContentRight"]}>
                                                <FormDatePicker
                                                    withPortal
                                                    placeholder={t("inquiry.inquiry_date")}
                                                    size="large"
                                                    fieldName={"created_date_from"}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes["WrapContent"]}>
                                            <div className={classes["WrapContentLeft"]}>
                                                <p className={classes["WrapContentLeftTitle"]}>{t("Holon_Template.to")}</p>
                                            </div>
                                            <div className={classes["WrapContentRight"]}>
                                                <FormDatePicker
                                                    withPortal
                                                    placeholder={t("inquiry.inquiry_date")}
                                                    size="large"
                                                    fieldName={"created_date_to"}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className={classes["SubmitButton"]}>
                                            <Button type="submit" title={t("Holon_Template.close")} />
                                        </div> */}
                                    </div>
                                </BoxBody>
                            </Box>
                        </WrapContentBody>
                    </form>
                </CustomFormProvider>
            </BoxContentPopup>
        </div>
    );
};

export default InquiryConditionSearchPopup;
