import React, {
    useRef,
    useState,
} from "react";
import classes from "./UploadMultipartFile.module.scss";
import _ from "lodash";
import IconUpload from "assets/icon/IconUpload";
import ErrorMessage from "../error-message/ErrorMessage";
import ErrorMessageHandleRegex from "../error-message/ErrorMessageHandleRegex";
import ErrorMessageHandleFE from "../error-message/ErrorMessageHandleFE";
import $ from 'jquery';

const UploadMultipartFile = (props) => {
    const {
        name,
        disabled = false,
        value,
        refInput,
        onChange,
        placeholder,
        onFocus,
        style,
        maxLength,
        onBlur,
        errors,
        maximumUpload = 100,
        isRegexLabel,
        validationFE,
        buttonComponent,
        accept
    } = props;
    const [nameFile, setNameFile] = useState()
    const [dragActive, setDragActive] = React.useState(false);
    const [warningDrag, setWarningDrag] = React.useState(false);
    const refImport = useRef();

    const onChangeUpload = (data) => {
        onChange(data);
        setNameFile(data?.name)
    };
    const onChangeImport = (e) => {
        if (e.target.files?.length == 0) {
            return;
        }
        if (e.target.files) {
            onChangeUpload(e.target.files[0]);
        }
    };

    // useEffect(() => {
    //     if (!value) {
    //         refImport.current.value = null;
    //         setNameFile(null);
    //     } else {
    //         setNameFile(value);
    //     }
    // }, [value]);

    let showError = false;
    let error = {
        message: "",
    };

    let arr = name.split(".");
    if (arr.length >= 1 && errors !== null) {
        let result = arr.reduce((rs, e) => {
            if (rs[e]) {
                return (rs = rs[e]);
            }
            return {};
        }, errors);
        error = result;
        showError = !_.isEmpty(error);
    }

    const handleDrag = function (
        e
    ) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files) {
            if (e.dataTransfer.files.length == 1) {
                if (e.dataTransfer.files[0]) {
                    onChangeUpload(e.dataTransfer.files[0]);
                }
            } else {
                onChangeUpload(e.dataTransfer.files[0]);
            }
        }
    };


    let ComponentProps = buttonComponent ? buttonComponent : <></>;
    let buttonUploadWidth = $(".button-upload").width() + 7;
    return (
        <>
            <div
                ref={refInput !== null ? refInput : null}
                className={`${classes["Custom_Input"]} ${showError && classes["Custom_Input_Error"]} ${(value?.length ?? 0) == maximumUpload && classes["Custom_Input_Max"]}`}
                style={{
                    flexDirection: buttonComponent ? 'row' : 'column',
                }}
            >
                <label
                    id="dropReceiver"
                    onDragLeave={() => setWarningDrag(false)}
                    onDragEnter={(e) =>
                        handleDrag(e)
                    }
                    onDrop={(e) => console.log(e)}
                    htmlFor="import_file"
                    className={`${classes["Custom_Input_Import_File"]} ${value ? classes["CustomBorder_Error"] : ""} ${value ? classes["CustomBorder_Success"] : ""} ${dragActive && classes["hover_drag_input"]} ${warningDrag && classes["hover_drag_input_error"]}`}
                    style={{
                        alignItems: buttonComponent ? 'flex-start' : 'center',
                        width: buttonComponent ? `calc(100% - ${buttonUploadWidth}px)` : '100%'
                    }}
                >
                    {dragActive && (
                        <div
                            className={classes["drag-file-element"]}
                            onDragEnter={(e) =>
                                handleDrag(e)
                            }
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={(e) => handleDrop(e)}
                        ></div>
                    )}
                    {
                        buttonComponent ?
                            nameFile ? <p style={{
                                maxWidth: `100%`
                            }} className={classes["Placeholder_Value"]}>
                                {nameFile}
                            </p> : <></>
                            : <IconUpload fontSize={20} color="#FC2424" />
                    }
                </label>
                {ComponentProps ? <div className="button-upload" onClick={() => refImport.current.click()}>{ComponentProps}</div> : <></>}
                {
                    !buttonComponent && nameFile && <p className={classes["Placeholder_Value"]}>
                        {nameFile}
                    </p>
                }
                <input
                    name={name}
                    disabled={disabled}
                    ref={refImport}
                    type={"file"}
                    onChange={(e) => {
                        onChangeImport(e);
                    }}
                    style={style}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete="off"
                    hidden
                    id="import_file"
                    multiple={false}
                    accept={accept}
                />
            </div>
            {showError ? (
                !validationFE ? (<ErrorMessage message={error?.message} />) : (
                    isRegexLabel ? <ErrorMessageHandleRegex isRegexLabel={isRegexLabel} message={error?.message} fieldName={name} /> : <ErrorMessageHandleFE message={error?.message} fieldName={name} />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default UploadMultipartFile;
