import ApiOperation from "adapter/ApiOperation";

const factory = {
    getHexService: (payload) => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/hex-service`,
            method: 'GET',
            params: {
                lang: payload?.lang,
                id: payload?.id,
            }
        })
    },
    updateHexService: (payload) => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/hex-service`,
            method: 'POST',
            data: payload
        })
    },
    uploadImage: (payload) => {
        const formData = new FormData();
        formData.append("file", payload);
        return ApiOperation.request({
            url: `/rest/api/v1/storage/upload`,
            method: 'POST',
            data: formData,

        })
    },
    getListHexService: () => {
        return ApiOperation.request({
            url: `/rest/api/v1/management/hex-service/get-all`,
            method: 'GET'
        })
    },
}
export default factory;