import React, { useEffect } from 'react';
import Constants from 'utils/Constants';
import StepperComponent from '../../common/Stepper';
import EventRegister, { EVENT_SHOW_POPUP } from 'utils/EventRegister';
import { useStateMachine } from 'little-state-machine';
import { clearAction, persistAction } from 'utils/Helper';

import styles from "./Register.module.scss";
import useTrans from 'hooks/use-trans';

const UserRegisterResultPopup = (props) => {
    const { state } = useStateMachine({ persistAction });
    const { actions } = useStateMachine({ clearAction });
    const { trans } = useTrans();

    const onClick = () => {
        props?.showVisible();
        actions.clearAction();
    };

    return <div className={styles['register-container']}>
        <div className='mb-3 d-center'>
            <StepperComponent
                steps={Constants.REGISTER_STEP}
                activeStep={2}
                diameter={120}
            />
        </div>
        <div className={styles['notify']}>
            <div className={styles['notify-header']}>
                {trans("register_form.step3.title1")}
            </div>
            <div className={styles['notify-content']}>
                <div dangerouslySetInnerHTML={{ __html: trans("register_form.step3.content1") }} />
                <div className='d-flex mt-2'>
                    <div className='col-4 label fw-semibold'>{trans('hex_id')}</div>
                    <div className='col-6'>
                        {trans("common.message.check_your_email")}
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='col-4 label fw-semibold'>{trans('email')}</div>
                    <div className='col-6'>
                        {state?.email}
                    </div>
                </div>
            </div>
        </div>
        <div className='text-center'>
            <button onClick={onClick} className='btn btn-dark mx-auto'>{trans("common.home_btn")}</button>
        </div>
    </div>
}

export default UserRegisterResultPopup