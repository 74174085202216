import useTrans from 'hooks/use-trans'
import BlInstanceContainer from 'shared/container/bl-instance/BlInstanceContainer';

function BlInstancePage() {
    const { t } = useTrans();
    document.title = t("bl_instance");
    return (
        <BlInstanceContainer />
    ) 
}

export default BlInstancePage;